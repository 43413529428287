import { settings } from '@rhim/design';
import { CalendarIcon as CalendarIcon16 } from '@rhim/icons/16';
import { ArrowRightIcon as ArrowRightIcon16 } from '@rhim/icons/16';
import { CalendarIcon as CalendarIcon24 } from '@rhim/icons/24';
import { ArrowRightIcon as ArrowRightIcon24 } from '@rhim/icons/24';
import { followingItemButtonNavigation } from '@rhim/test-ids';
import { isDefined } from '@rhim/utils';
import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

import { FieldLabel } from '../../partials';

const ICON_COLOR = settings.colors.Primary.Grey_4;

interface Props {
  className?: string;
  label?: string;
  mode?: 'small40' | 'medium48';
  startDate: ReactElement | null;
  endDate?: ReactElement | null;
  isRangePickerMode?: boolean;
}
const LockedFieldDatePicker: React.ChildlessComponent<Props> = ({ className, label, mode = 'medium48', startDate, endDate, isRangePickerMode = false }) => {
  return (
    <SWrapper className={className}>
      {isDefined(label) && <FieldLabel text={label} />}
      <SContainer mode={mode}>
        <SDateContainer>{startDate}</SDateContainer>
        {isRangePickerMode && (
          <>
            <SArrowContainer>
              {mode === 'small40' ? <ArrowRightIcon16 dataTestId={followingItemButtonNavigation} fill={ICON_COLOR} /> : <ArrowRightIcon24 fill={ICON_COLOR} />}
            </SArrowContainer>
            <SDateContainer>{endDate}</SDateContainer>
          </>
        )}
        <SCalendarIconContainer>{mode === 'small40' ? <CalendarIcon16 fill={ICON_COLOR} /> : <CalendarIcon24 fill={ICON_COLOR} />}</SCalendarIconContainer>
      </SContainer>
    </SWrapper>
  );
};
LockedFieldDatePicker.whyDidYouRender = true;
export default React.memo(LockedFieldDatePicker);

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SContainer = styled.div<Pick<Props, 'mode'>>((props) => {
  const height: React.CSSProperties['height'] = (() => {
    switch (props.mode) {
      case 'small40':
        return 40;
      case 'medium48':
        return 48;
      default:
        return 48;
    }
  })();
  return css`
    display: flex;
    align-items: center;
    padding: 0 ${settings.Spacing.Spacing_200};
    height: ${height}px;
    background-color: ${settings.colors.Primary.Grey_2};
    border-radius: 3px;
  `;
});

const SDateContainer = styled.div`
  flex-grow: 1;
  color: ${settings.colors.Primary.Grey_8};
  white-space: no-wrap;
`;

const SArrowContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${settings.Spacing.Spacing_300};
`;

const SCalendarIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${settings.Spacing.Spacing_150};
`;
