import { i18nReact } from '@rhim/i18n';
import { RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto } from '@rhim/rest/customerManagement';
import { isDefined } from '@rhim/utils';
import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import React, { useEffect, useMemo } from 'react';

import { LocalizationSettings, useLocalization } from '../hooks/useLocalization';

export type UserSettingsProviderType = {
  saveSettings: UseMutateAsyncFunction<AxiosResponse<void>, unknown, LocalizationSettings, void>;
  userSettings: LocalizationSettings;
};

interface Props {
  onChange: (newSettings: LocalizationSettings) => Promise<AxiosResponse<void>>;
  onMutate: (userSettings: LocalizationSettings) => void;
  onError: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any,
    newSettings: LocalizationSettings,
    context: { previousUsersettings: RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto | undefined }
  ) => void;
  onSettled: () => void;
  user: RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto;
}

export const UserSettingsContext = React.createContext<UserSettingsProviderType | null>(null);

const parseSettings = (data: string): LocalizationSettings | null => {
  try {
    return JSON.parse(data) as LocalizationSettings;
  } catch {
    return null;
  }
};

export const UserSettingsProvider: React.FC<React.PropsWithChildren<Props>> = ({ children, onChange, onError, onMutate, onSettled, user }) => {
  const [localization] = useLocalization();

  const [userSettings, setUserSettings] = React.useState<LocalizationSettings | null>(isDefined(user.userSettings) ? parseSettings(user.userSettings) : null);

  const { mutateAsync: saveSettings, isLoading } = useMutation(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (settings: LocalizationSettings) => {
      return onChange(settings);
    },
    {
      onMutate: (userSettings: LocalizationSettings) => {
        setUserSettings(userSettings);

        return onMutate(userSettings);
      },
      onError: (_, __, context: { previousUsersettings: RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto | undefined }) => {
        if (context.previousUsersettings) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          setUserSettings(JSON.parse(context.previousUsersettings.userSettings!) as LocalizationSettings);
          onError(_, __, context);
        }
      },
      onSettled,
      onSuccess: (_, settings) => {
        setUserSettings(settings);
      },
    }
  );

  /**
   * Handles language change.
   */
  useEffect(() => {
    if (!isDefined(userSettings)) {
      return;
    }

    i18nReact.changeLanguage(userSettings.locale);
  }, [userSettings]);

  useEffect(() => {
    // If the user has no settings in the DB, we need to save the current settings
    if (userSettings === null && !isLoading) {
      saveSettings(localization);
    }
  }, [isLoading, localization, saveSettings, userSettings]);

  const value = useMemo(
    () => ({
      userSettings: userSettings || localization,
      saveSettings,
    }),
    [localization, saveSettings, userSettings]
  );

  return <UserSettingsContext.Provider value={value}>{children}</UserSettingsContext.Provider>;
};

UserSettingsProvider.whyDidYouRender = true;
