import { useMemoCompare } from '@rhim/react';
import { RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto, RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto } from '@rhim/rest/customerManagement';
import * as React from 'react';
import { createContext } from 'react';

interface Props {
  user: RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto;
  selectedCustomer: RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto;
  setSelectedCustomer: React.Dispatch<React.SetStateAction<RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto>>;
  onBeforeSelectedCustomerChange?: (newCustomerId: UUID) => Promise<boolean>;
  setOnBeforeSelectedCustomerChange: React.Dispatch<React.SetStateAction<((newCustomerId: UUID) => Promise<boolean>) | undefined>>;
  vessels: APO.VesselV2[];
}

/**
 * DANGER: Using {} as placeholders for real entities leads to exceptions because
 * the existence of certain properties (such as `customerId`) is often assumed.
 *
 * It would be better to:
 *
 *   • Be clear when entities are missing by using `null` or `undefined` instead of `{}`
 *   • Suspend until real entities are available and commited to AppContext. Use https://github.com/pmndrs/suspend-react/issues?q=is%3Aissue+is%3Aopen+sort%3Aupdated-desc if needed.
 *
 * It would also be useful to store vessels in `AppContext`. This way the subtree within
 * AppContext would have a guarante that the essential information is available at all times.
 */
const AppContext = createContext<Props>({
  user: {} as RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto,
  selectedCustomer: {} as RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto,
  setSelectedCustomer: () => {
    // placeholder
  },
  setOnBeforeSelectedCustomerChange: () => {
    // placeholder
  },
  vessels: [],
});

export const AppContextProvider: React.FunctionComponent<
  React.PropsWithChildren<{
    value: Props;
  }>
> = ({ value, children }) => {
  const memoizedValue = useMemoCompare(value);

  return <AppContext.Provider value={memoizedValue}>{children}</AppContext.Provider>;
};

export default AppContext;
