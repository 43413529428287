import {
  AccountInfo,
  AuthenticationResult,
  AuthError,
  EndSessionRequest,
  InteractionRequiredAuthError,
  ProtocolMode,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';
import { TemporaryCacheKeys } from '@azure/msal-browser/dist/utils/BrowserConstants';
import { CredentialEntity, CredentialType, StringDict } from '@azure/msal-common';
import { Logger } from '@rhim/logging';
import { assert, Deferred, ensure, isDefined, joinPaths } from '@rhim/utils';
import { AccessToken, AuthConfiguration, BearerAccessToken, RhimAccountInfo, TokenClaims } from 'typings';

const log = new Logger({
  context: 'Authentication',
  logLevel: 'info',
  format: (message, _, context) => {
    if (isDefined(context)) {
      return [`[${context}]`, ...message];
    }

    return message;
  },
});

/**
 * According to the typings of the AccountInfo interface, the username is a required property.
 * While this is the case in AAD and OIDC implementations, the restriction is only satisfied by setting the username to an empty string.
 * This renders this property completely useless, which is why we don't expose it anymore (see #33560).
 * If you want to display the user's name, use the `name` instead.
 *
 * **Update June 2nd, 2021**. It seems like `username` does contain the e-mail address now.
 */
export interface AuthInfo extends Omit<RhimAccountInfo, 'username'> {
  /**
   * Provides the bearer token for backend communication in the form of `Bearer <accessToken>`
   * Can be directly used as value for the Authorization header
   */
  bearerAuthentication: BearerAccessToken;

  /**
   * The user's name. If the user's name is not available, then it contains their e-mail address instead
   */
  name: string;
}

interface InterceptorsManager {
  add<T extends keyof InterceptorsMap>(event: T, interceptor: Unpack<InterceptorsMap[T]>): void;
  eject<T extends keyof InterceptorsMap>(event: T, interceptor: Unpack<InterceptorsMap[T]>): boolean;
}

interface InterceptorsMap {
  onBeforeLogout?: Set<(logoutRequest?: EndSessionRequest) => Promise<void>>;
  onBeforeAquireTokenRedirect?: Set<(request: RedirectRequest) => Promise<void>>;
  onBeforeLoginRedirect?: Set<(request?: RedirectRequest) => Promise<void>>;
}
/**
 * Read the basename under which the application is hosted
 */
export function getBasename(): string {
  if (isDefined(document)) {
    return new URL(document.baseURI).pathname;
  }

  return '/';
}

export const ACCESS_TOKEN_EXPIRY_THRESHOLD_MS = 30000;
export function canUseExistingAccessToken(accessToken: AccessToken) {
  const accessTokenExpirationDate = parseDateFromToken(accessToken.expiresOn);
  const threshold = accessTokenExpirationDate.getTime() - ACCESS_TOKEN_EXPIRY_THRESHOLD_MS;
  return threshold > Date.now(); // ensure that the token isn't expiring soon
}

export function getAccessToken(storage: Storage = localStorage): AccessToken {
  return Object.values(storage)
    .map((value: string) => {
      try {
        return JSON.parse(value);
      } catch (e) {
        return false; // error in the above string.
      }
    })
    .filter((entry: CredentialEntity) => Boolean(entry) && entry.credentialType === CredentialType.ACCESS_TOKEN) // FIX: #67867 fix unsafe property access when trying to read access token
    .find((entry: CredentialEntity) => entry.clientId === AuthClient.clientId); // due to the way our applications are hosted there can be multiple access tokens in the same storage. We need to find the one that belongs to the running application
}

/**
 * Converts a unix time stamp in seconds to a JavaScript Date object
 * @param unixTimestampInSeconds timestamp in seconds since unix time (note: JavaScript uses miliseconds, therefore it needs to be converted)
 * @returns
 */
export function parseDateFromToken(unixTimestampInSeconds: number | string): Date {
  const value = typeof unixTimestampInSeconds === 'string' ? parseInt(unixTimestampInSeconds) : unixTimestampInSeconds;
  return new Date(value * 1000); // multiply unix timestamp by 1000 since its in seconds instead of miliseconds
}

/**
 * @param type https://developer.mozilla.org/en-US/docs/Web/HTTP/Authentication#Authentication_schemes
 */
export function createAuthorizationHeader(type: 'Bearer', token: string): BearerAccessToken {
  return `${type} ${token}` as const;
}

const defaultRedirectUri = '/signedin';

export class AuthClient extends PublicClientApplication {
  static clientId: string;

  /**
   * as soon as the user is logged in we set up an automatic token renewal which runs right in time before the token expires. This allows silent renewal of the access token without requiring the user to login again
   */
  private automaticTokenRenewalTimer: NodeJS.Timeout | undefined;

  constructor(configuration: AuthConfiguration) {
    super({
      auth: {
        /**
         * MSAL normally redirects us to the requested page upon login. This happens in the `handleRedirectPromise` during rehydration.
         * This will cause a full pge reload though. The SPA conform approach is to disable this and rather have the React Router do the navigation internally
         * See https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/5694
         */
        navigateToLoginRequestUrl: false,
        clientId: configuration.clientId,
        authority: `${configuration.authority.startsWith('/') ? new URL(configuration.authority, window.location.origin).href : configuration.authority}${
          configuration.loginPolicy
        }`,
        redirectUri: joinPaths(getBasename(), configuration.authRedirectUri ?? defaultRedirectUri), // take basename into account
        knownAuthorities: [
          `${configuration.authority.startsWith('/') ? new URL(configuration.authority, window.location.origin).href : configuration.authority}${
            configuration.loginPolicy
          }`,
        ],
        protocolMode: configuration.authProtocolMode === 'OIDC' ? ProtocolMode.OIDC : ProtocolMode.AAD,
      },
      cache: {
        cacheLocation: 'localStorage',
        // When @azure/msal-browser@^2.8.0 is used and this is enabled, the login redirect never happens.
        storeAuthStateInCookie: false,
      },
      system: {
        // We need this for E2E tests and Storybook, where the app runs in an iframe.
        allowRedirectInIframe: true,
      },
    });

    AuthClient.clientId = configuration.clientId; // there can only be one instance of the AuthClient. The clientId indicates which application that is

    this.scopes = configuration.scopes;
    this.usePopup = configuration.usePopup ?? false;
    this.appName = configuration.appName;
    this.redirectUri = configuration.authRedirectUri ?? defaultRedirectUri;

    if (isDefined(configuration.debug)) {
      log.debug('Initializing authentication with the following configuration:', configuration);
    }
  }

  static defaultScopes: string[] = ['offline_access'];

  public scopes: string[];
  public appName?: string;
  public redirectUri: string;
  protected usePopup: boolean;
  private authenticationInProgress: undefined | Deferred<AuthInfo>;
  protected interceptorsMap: InterceptorsMap = {};
  public interceptors: InterceptorsManager = {
    add: <T extends keyof InterceptorsMap>(event: T, interceptor: Unpack<InterceptorsMap[T]>) => {
      // @ts-expect-error There is no way to model this in TypeScript.
      this.interceptorsMap[event] ??= new Set([interceptor]);
    },
    eject: (event, interceptor) => {
      // @ts-expect-error There is no way to model this in TypeScript.
      return this.interceptorsMap[event]?.delete(interceptor) ?? false;
    },
  };

  /**
   * Attempt to silently refresh the token without prompting the user to login
   * If a silent renewal is not possible, we actively authenticate the user
   */
  async renewToken(): Promise<AuthInfo> {
    const account = await this.getAccount();

    if (isDefined(account)) {
      // acquire and return the auth info for the given user
      return this.acquireAuthInfo(account);
    }

    // if there's no account it means that the user is not logged in yet.
    // In this case, we have to log in the user from scratch
    log.error(`AuthClient: Silent token renewal not possible. Login required.`);

    try {
      const authInfo = await this.authenticate();
      return authInfo;
    } catch (error) {
      log.error(error);
      throw error;
    }
  }

  async acquireAuthInfo(account: AccountInfo): Promise<AuthInfo> {
    const request: SilentRequest = {
      account,
      scopes: this.scopes,
      extraQueryParameters: this.getExtraQueryParameters(),
    };

    try {
      const token = await this.acquireTokenSilent(request);
      assert(isDefined(token), 'Silently acquiring token failed. User interaction is required to proceed');
      assert(token.tokenType === 'Bearer', 'Expected token type to be a "Bearer" token');

      const bearerAuthentication = `${token.tokenType} ${token.accessToken}` as const;

      // #33560: ensure that the account name is always set
      account.name = this.getAccountName(account);

      // set up automatic token renewal before the token expires
      if (isDefined(token.expiresOn)) {
        this.clearAutomaticTokenRenewalTimer();
        const timeUntilExpiryThreshold = token.expiresOn.getTime() - Date.now() - ACCESS_TOKEN_EXPIRY_THRESHOLD_MS;
        this.automaticTokenRenewalTimer = setTimeout(() => {
          this.renewToken();
        }, timeUntilExpiryThreshold); // schedule at the expiry threshold time
      }

      return {
        ...account,
        name: account.name,
        bearerAuthentication, // provide bearer token that can be used for backend authentication
      };
    } catch (error) {
      log.error(`AuthClient: silent renew failed. Error type: "${error instanceof Error ? error.constructor.name : 'unknown'}". Reason:`, error);
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/acquire-token.md#acquiring-an-access-token
        log.debug(
          'Calling acquireTokenSilent failed. Falling back to interaction now',
          'See https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/a4073eb20fcc7fb42f7858cf16998e7e9b74a411/lib/msal-browser/docs/acquire-token.md#acquiring-an-access-token'
        );

        return (await this.acquireTokenRedirect(request)) as unknown as AuthInfo; // when this is called we'll navigate away from the page and the return value will never be assigned (this is why void is used in the typings)
      }

      throw error; // if silent renew failed and the user wasn't redirected to the login page, then we need to throw an error since we couldn't login the user
    }
  }

  private getAccountName(account: RhimAccountInfo): string {
    // #33560: the primary display name of the user should be their "name".
    // If the account name is not set, then we fall back to the preferred_username, which will be the user's e-mail address.
    // As last fallback, we will use the username
    // eslint-disable-next-line dot-notation
    return account.name ?? account.idTokenClaims?.preferred_username ?? account.username;
  }

  private getLastAccountLoggedIn = (): RhimAccountInfo | undefined => {
    const accounts: RhimAccountInfo[] = this.getAllAccounts().filter((acc: RhimAccountInfo) => {
      const aud = acc.idTokenClaims?.aud;

      return Array.isArray(aud) ? isDefined(aud.find((item) => item === this.config.auth.clientId)) : aud === this.config.auth.clientId;
    });

    return accounts.reduce<RhimAccountInfo | undefined>((previous, current) => {
      const previousAuthTime = previous && previous.idTokenClaims && previous.idTokenClaims.auth_time;
      const currentAuthTime = ensure(current.idTokenClaims?.auth_time);

      if (!isDefined(previousAuthTime)) {
        return current;
      }

      return previousAuthTime < currentAuthTime ? previous : current;
    }, undefined);
  };

  private async getAccount(): Promise<AccountInfo | undefined> {
    const token: AuthenticationResult | null = await this.handleRedirectPromise();

    if (isDefined(token) && 'tfp' in token.idTokenClaims && (token.idTokenClaims as TokenClaims).tfp === 'B2C_1A_PasswordReset') {
      /**
       * When the user successfully restarts their password and comes back to the app,
       * B2C signs the token and acts like the user is signed in. However, a full login
       * is needed for different reasons, so we force the user to log-out and log-in again.
       *
       * @see https://dev.azure.com/RHIM/APO/_git/apo-light/pullrequest/5997?_a=files&path=%2Fsrc%2FRHIM.APO.ApoLight.Web%2FClientApp%2Fsrc%2Fauth%2Fauth.tsx&discussionId=33213
       */
      await this.logout();
    }

    const account = isDefined(token) ? token.account : this.getLastAccountLoggedIn();

    if (!isDefined(account)) {
      return undefined;
    }

    return account;
  }

  getExtraQueryParameters(): StringDict | undefined {
    const queryParams = new URLSearchParams(window.location.search);
    const loginHint = queryParams.get('login_hint');
    const domainHint = queryParams.get('domain_hint');
    const extraQueryParams: StringDict = {};

    if (isDefined(this.appName)) {
      extraQueryParams['appName'] = this.appName;
    }
    if (isDefined(domainHint)) {
      extraQueryParams['domain_hint'] = domainHint;
    }
    if (isDefined(loginHint)) {
      extraQueryParams['login_hint'] = loginHint;
    }

    return Object.keys(extraQueryParams).length === 0 ? undefined : extraQueryParams;
  }

  async authenticate(): Promise<AuthInfo> {
    if (isDefined(this.authenticationInProgress)) {
      return this.authenticationInProgress.promise;
    }

    this.authenticationInProgress = new Deferred();

    try {
      const account = await this.getAccount();

      const requestParameters: RedirectRequest = {
        scopes: this.scopes,
        extraQueryParameters: this.getExtraQueryParameters(),
      };

      if (isDefined(account)) {
        try {
          const authInfo = await this.acquireAuthInfo(account);
          return this.authenticationInProgress.resolve(authInfo);
        } catch {
          if (this.usePopup) {
            log.debug('Calling acquireAuthInfo failed. Popup flow was chosen as fallback. Redirecting now');

            await this.acquireTokenPopup(requestParameters); // await the popup
          } else {
            log.debug('Calling acquireAuthInfo failed. Redirect flow was chosen as fallback. Redirecting now');

            await this.acquireTokenRedirect(requestParameters); // we can't await this promise because this navigates away from the page
          }
        }
      } else {
        if (this.usePopup) {
          await this.loginPopup(requestParameters);
        } else {
          await this.loginRedirect(requestParameters);
        }
      }

      return this.authenticationInProgress.reject("User couldn't be logged in.");
    } catch (error: unknown) {
      log.error(`AuthClient: authentication failed. Reason`, error);
      assert(error instanceof AuthError, 'Assumed MSAL throws only instances of AuthError');
      return this.authenticationInProgress.reject(error.message);
    }
  }

  /**
   * Silently acquire the cached access token if available
   * @param onSuccess callback which is invoked once the auth info has been retrieved (only called if auth info is available)
   * @param onFailure error callback
   */
  async rehydrate(): Promise<AuthInfo> {
    try {
      const account = await this.getAccount();

      // If we have the user's account info we also try to acquire the authentication info, so that we can already utilize it
      if (isDefined(account)) {
        const authInfo = await this.acquireAuthInfo(account);
        return authInfo;
      } else {
        throw AuthError.createUnexpectedError('Could not acquire auth info because no account was found.');
      }
    } catch (error) {
      assert(error instanceof AuthError, 'Assumed MSAL throws only instances of AuthError');

      throw error;
    }
  }

  async logout(logoutRequest?: EndSessionRequest): Promise<void> {
    this.clearAutomaticTokenRenewalTimer();

    this.interceptorsMap.onBeforeLogout?.forEach(async (interceptor) => {
      await interceptor(logoutRequest);
    });

    await super.logout(logoutRequest);
  }

  async acquireTokenRedirect(request: RedirectRequest) {
    this.interceptorsMap.onBeforeAquireTokenRedirect?.forEach(async (interceptor) => {
      await interceptor(request);
    });

    log.info('Acquiring token via redirect');

    await super.acquireTokenRedirect(request);
  }

  async loginRedirect(request?: RedirectRequest): Promise<void> {
    this.interceptorsMap.onBeforeLoginRedirect?.forEach(async (interceptor) => {
      await interceptor(request);
    });

    await super.loginRedirect(request);
  }

  getTargetOriginUriAfterLogin() {
    return this.browserStorage.getTemporaryCache(TemporaryCacheKeys.ORIGIN_URI, true);
  }

  private clearAutomaticTokenRenewalTimer() {
    clearTimeout(this.automaticTokenRenewalTimer);
  }
}
