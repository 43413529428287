import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  foregroundColor?: Property.Color;
  backgroundColor?: Property.Color;
  title?: string;
  role?: string;
}

export const StripArrowMicroIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  size,
  backgroundColor,
  foregroundColor,
  title,
  role,
  ...rest
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g transform="translate(3 3)">
          <circle cx="5" cy="5" r="5" fill={backgroundColor} />
          <path fill={foregroundColor} fillRule="nonzero" d="M5.255 2.159L7.528 5 5.255 7.841 3.836 6.705 5.199 5 3.836 3.295z" />
        </g>
      </g>
    </svg>
  );
};

StripArrowMicroIcon.defaultProps = {
  size: '16',
  foregroundColor: settings.colors.Primary.Grey_1,
  backgroundColor: settings.colors.Primary.Grey_4,
};
