import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const VesselBFRIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 16V2h6v14h6v2h-8v-7.008c-.29-.035-.683-.19-1.07-.343-.338-.133-.671-.265-.93-.315V18H3v-2h6zm12 6H3v-2h18v2z"
      />
    </svg>
  );
};

VesselBFRIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
