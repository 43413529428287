import React, { useEffect, useState } from 'react';
import { flushSync } from 'react-dom';
import { useMedia } from 'react-use';
import styled, { createGlobalStyle } from 'styled-components';

interface Props {
  children: React.ReactNode;
  className?: string;
  preloadImages?: Array<string>;
  preRender?: boolean;
}

const PrintComponent: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { children, className } = props;

  return <PrintWrapper className={className}>{children}</PrintWrapper>;
};

export const Print = React.memo(PrintComponent);

const PrintOnlyComponent: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { children, className = '', preloadImages, preRender = false } = props;
  const [isPrint, setIsPrint] = useState<boolean>(false);

  // useMedia for debugging with print emulation, but doesn't work for firefox print dialog
  const isPrintMedia = useMedia('print');

  useEffect(() => {
    const onBeforePrint = () => {
      // state update need to be synchronously to work with firefox print dialog
      flushSync(() => {
        setIsPrint(true);
      });
    };
    const onAfterPrint = () => {
      setIsPrint(false);
    };

    window.addEventListener('beforeprint', onBeforePrint);
    window.addEventListener('afterprint', onAfterPrint);

    return () => {
      window.removeEventListener('beforeprint', onBeforePrint);
      window.removeEventListener('afterprint', onAfterPrint);
    };
  }, [setIsPrint]);

  return (
    <>
      <PreloadContainer>
        {preloadImages?.map((imageSrc, index) => (
          // eslint-disable-next-line jsx-a11y/alt-text
          <img src={imageSrc} key={index} />
        ))}
      </PreloadContainer>
      {isPrint || isPrintMedia || preRender ? (
        <PrintOnlyWrapper className={`print ${className}`}>
          <GlobalStyle />
          {children}
        </PrintOnlyWrapper>
      ) : null}
    </>
  );
};

export const PrintOnly = React.memo(PrintOnlyComponent);

const GlobalStyle = createGlobalStyle`
  @media print {
    html,
    body {
      height: 100%;
      margin: 0;
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
      background-color: unset;
      position: relative;
    }

    body > * {
      display: none;
    }

    #root {
      display: block;
    }

    main,
    #root,
    #root div:not(.print div) {
      height: 100%;
    }
  }
`;

const PrintWrapper = styled.div`
  @media print {
    visibility: initial;
  }
`;

export const PrintOnlyWrapper = styled(PrintWrapper)`
  /**
   * visibility: hidden; instead of display: none allows to measure width of elements
   * before print dialog is opened, which is needed for some components
   */
  @media screen {
    visibility: hidden;
    width: 0;
    height: 0;
    overflow: hidden;
  }
`;

const PreloadContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
`;
