import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
  dataTestId?: string;
}

export const ArrowCaretRightIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, dataTestId, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} data-test-id={dataTestId} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.293 11.793a1 1 0 1 0 1.414 1.414l4-4a1 1 0 0 0 0-1.414l-4-4a1 1 0 0 0-1.414 1.414L8.586 8.5l-3.293 3.293z"
        fill={fill}
      />
    </svg>
  );
};

ArrowCaretRightIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Grey_8,
};
