import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const ResetDefaultsIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.566 5.461a4.916 4.916 0 0 1 6.994.027 5.05 5.05 0 0 1 1.454 3.504c.01.867-.2 1.734-.628 2.51a1.014 1.014 0 0 0 .382 1.37.986.986 0 0 0 1.351-.388c.6-1.09.894-2.304.88-3.514a7.078 7.078 0 0 0-2.035-4.906 6.882 6.882 0 0 0-9.828 0l-.028.028L4 4.221V3a1 1 0 0 0-2 0v4a1 1 0 0 0 1 1h4a1 1 0 0 0 0-2H5.113l.453-.539zM3 11.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
        fill={fill}
      />
    </svg>
  );
};

ResetDefaultsIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
