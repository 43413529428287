import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const SegmentIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8a1 1 0 0 1 1-1h1.75a1 1 0 0 1 0 2H1a1 1 0 0 1-1-1zm5.25 0a1 1 0 0 1 1-1h3.5a1 1 0 1 1 0 2h-3.5a1 1 0 0 1-1-1zm7 0a1 1 0 0 1 1-1H15a1 1 0 1 1 0 2h-1.75a1 1 0 0 1-1-1z"
      />
    </svg>
  );
};

SegmentIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
