import { VesselType } from '@rhim/rest';
import { uploadPanelProcessDataUploadContainer } from '@rhim/test-ids';
import { isDefined, MIMEType, ProcessedDataFileType } from '@rhim/utils';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AppContext from '../../app/AppContext';
import { IFileUploadMetadata, MetadataType } from '../Uploader/types';
import Uploader, { Stage, UploadState } from '../Uploader/Uploader';
import LeavePageWhilstUploadingWarningModal from './LeavePageWhilstUploadingWarningModal';
import { useWarningModalOnCustomerSwitch } from './useWarningModalOnCustomerSwitch';

const INITIAL_UPLOAD_STATE: UploadState = {
  stage: Stage.noFileSelected,
};

/**
 * Warning: make sure there are no duplicate keys.
 *
 * @see https://github.com/microsoft/TypeScript/issues/41326
 */
const initialState = {
  [ProcessedDataFileType.ProcessedCSV]: INITIAL_UPLOAD_STATE,
} as const;

interface Props {
  vesselType: VesselType | undefined;
  onProgressChanged: (isInProgress: boolean) => void;
  onCanProceedToNextStepChanged: (canProceedToNextStep: boolean) => void;
}
const ProcessDataUploadPanel: React.ChildlessComponent<Props> = (props) => {
  const { vesselType, onProgressChanged, onCanProceedToNextStepChanged } = props;

  const { t } = useTranslation(['ingress']);
  const [state, setState] = useState<Record<ProcessedDataFileType, UploadState>>(initialState);
  const [shouldCancelUpload, setShouldCancelUpload] = useState(false);
  const { selectedCustomer: customer } = useContext(AppContext);
  /**
   * Checks if the uploading process is in progress.
   */
  const isUploading = Object.values(state).some((_state) => _state.stage === Stage.uploading);

  const warningModalOnCustomerSwitchTexts = useMemo(
    () => ({
      headline: t('ingress:leavePageWhilstUploadingWarningModal.title'),
      description: t('ingress:leavePageWhilstUploadingWarningModal.description'),
      buttonLeaveAndCancelLabel: t('ingress:leavePageWhilstUploadingWarningModal.buttonLeaveAndCancelUpload'),
      buttonStayOnPageLabel: t('ingress:leavePageWhilstUploadingWarningModal.buttonStayOnPage'),
    }),
    [t]
  );
  useWarningModalOnCustomerSwitch(isUploading, warningModalOnCustomerSwitchTexts);

  useEffect(() => {
    onProgressChanged(isUploading);
  }, [onProgressChanged, isUploading]);

  /**
   * Checks if we can proceed to the Next step
   */
  const canProceedToNextStep = React.useMemo(() => {
    const noFileUploaded = Object.values(state).every((_state) => _state.stage === Stage.noFileSelected);
    const fails = Object.values(state).some((_state) => _state.stage === Stage.failure);

    return !noFileUploaded && !isUploading && !fails;
  }, [state, isUploading]);

  useEffect(() => {
    onCanProceedToNextStepChanged(canProceedToNextStep);
  }, [onCanProceedToNextStepChanged, canProceedToNextStep]);

  const metadata: IFileUploadMetadata = useMemo(
    () => ({
      metadataType: MetadataType.Default,
      customerId: customer.customerId as UUID,
      vesselType: vesselType,
      fileType: ProcessedDataFileType.ProcessedCSV,
    }),
    [customer, vesselType]
  );

  const handleProcessedCSVChanged = useCallback((_state: UploadState) => {
    setState((prevState) => ({ ...prevState, [ProcessedDataFileType.ProcessedCSV]: _state }));
  }, []);

  const acceptProcessedCSV = useMemo(() => {
    return new Set([MIMEType.CSV]);
  }, []);

  const handleUserLeftPageAndCancelledUpload = useCallback(() => {
    setShouldCancelUpload(true);
  }, []);

  return (
    <>
      <LeavePageWhilstUploadingWarningModal isUploading={isUploading} onUploadingCancelled={handleUserLeftPageAndCancelledUpload} />
      <Uploader
        uploadMetadataPayload={metadata}
        dataTestId={uploadPanelProcessDataUploadContainer}
        title={isDefined(vesselType) ? t(`ingress:uploadProcessDataTitle.${vesselType as unknown as APO.IngressKnownParameterName}` as const) : ''}
        description={isDefined(vesselType) ? t(`ingress:processDataRequiredFile.${vesselType as unknown as APO.ProcessDataKnowParameterName}` as const) : ''}
        onChange={handleProcessedCSVChanged}
        shouldCancelUpload={shouldCancelUpload}
        disabled={false}
        accept={acceptProcessedCSV}
      />
    </>
  );
};

ProcessDataUploadPanel.whyDidYouRender = true;

export default React.memo(ProcessDataUploadPanel);
