import { settings } from '@rhim/design';
import { MinusTinyIcon, PlusTinyIcon } from '@rhim/icons/16';
import { MinusIcon, PlusIcon } from '@rhim/icons/24';
import { isDefined } from '@rhim/utils/is-defined';
import { FC, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import styled from 'styled-components';

import { IconButton } from '../IconButton';
import TextField from '../TextField/TextField';

interface Props {
  className?: string;
  value: number;
  decimalPlaces?: number;
  minValue?: number;
  stepSmall: number;
  stepLarge: number;
  label?: string;
  isDisabled?: boolean;
  onMinusSmallClicked: () => void;
  onMinusLargeClicked: () => void;
  onPlusSmallClicked: () => void;
  onPlusLargeClicked: () => void;
  onValueChanged: (newValue: number) => void;
}

const InputFineNumericStepper: FC<React.PropsWithChildren<Props>> = ({
  className,
  value,
  decimalPlaces,
  minValue = 0,
  stepSmall,
  stepLarge,
  label,
  isDisabled = false,
  onMinusSmallClicked,
  onMinusLargeClicked,
  onPlusSmallClicked,
  onPlusLargeClicked,
  onValueChanged,
}) => {
  const [labelTargetPortalDOM, setLabelTargetPortalDOM] = useState<HTMLDivElement>();
  const labelTargetPortalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!labelTargetPortalRef.current) {
      return;
    }
    setLabelTargetPortalDOM(labelTargetPortalRef.current);
  }, [labelTargetPortalRef]);

  const handleInputChanged = (input: string) => {
    const newValue = parseFloat(input);
    if (!isNaN(newValue)) {
      onValueChanged(newValue);
    }
  };

  const isButtonStepperMinusSmallDisabled = isDisabled || value < minValue + stepSmall || value <= minValue;
  const isButtonStepperMinusLargeDisabled = isDisabled || value < minValue + stepLarge || value <= minValue;

  return (
    <StyledContainer className={className}>
      {/* render TextField's label in the container below */}
      <div ref={labelTargetPortalRef} />
      <StyledWrapper isDisabled={isDisabled}>
        <IconButton
          className={`buttonStepper ${isButtonStepperMinusLargeDisabled && 'isDisabled'}`}
          icon={<MinusIcon />}
          isDisabled={isButtonStepperMinusLargeDisabled}
          onPress={onMinusLargeClicked}
        />
        <IconButton
          className={`buttonStepper ${isButtonStepperMinusSmallDisabled && 'isDisabled'}`}
          icon={<MinusTinyIcon />}
          isDisabled={isButtonStepperMinusSmallDisabled}
          onPress={onMinusSmallClicked}
        />
        <TextField
          label={label}
          variant="small"
          value={isDefined(decimalPlaces) ? value.toFixed(decimalPlaces) : value.toString()}
          onChange={handleInputChanged}
          isDisabled={isDisabled}
          labelTargetPortalDOM={labelTargetPortalDOM}
        />
        <IconButton className={`buttonStepper ${isDisabled && 'isDisabled'}`} icon={<PlusTinyIcon />} isDisabled={isDisabled} onPress={onPlusSmallClicked} />
        <IconButton className={`buttonStepper ${isDisabled && 'isDisabled'}`} icon={<PlusIcon />} isDisabled={isDisabled} onPress={onPlusLargeClicked} />
      </StyledWrapper>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
`;

type StyledWrapperProps = { isDisabled: boolean };
const StyledWrapper = styled.div<StyledWrapperProps>`
  display: flex;
  align-items: start;

  --borderColor: ${settings.colors.Primary.Grey_4};

  &:focus-within {
    --borderColor: ${settings.colors.Primary.Blue_9};

    div.rhim-textfield-input-wrapper {
      border-color: var(--borderColor);
    }
  }

  div.rhim-textfield-input-wrapper {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }

  .buttonStepper {
    user-select: none;
    outline: none;
    height: ${settings.Spacing.Spacing_500};
    cursor: pointer;
    border-style: ${(props) => (props.isDisabled ? 'dashed' : 'solid')};
    border-width: 1px;
    border-color: var(--borderColor);

    &:first-of-type {
      z-index: 0;

      &:hover:not(.isDisabled) {
        /**
         * the 1st button's right border is not visible, it is covered by the offseted 2nd button's left border.
         * but when hovered, bring the 1st button forward so that its right border can now be seen
         */
        z-index: 1;
      }
    }

    &:last-of-type {
      --radius: 3px;

      /**
       * both stepper buttons have a border but where they meet eachother the border should be width 1 pixel, not 2.
       * offset the 2nd button 1 pixel to the left, to cover the 1st button's right border
       */
      left: -1px;
      position: relative;
      border-top-right-radius: var(--radius);
      border-bottom-right-radius: var(--radius);
    }

    &.isDisabled {
      --color: ${settings.colors.Primary.Grey_3};
      --borderColor: var(--color);

      pointer-events: none;
    }

    &:hover:not(.isDisabled) {
      --color: ${settings.colors.Primary.Blue_8};
      --borderColor: var(--color);

      &:active {
        --color: ${settings.colors.Primary.Blue_7};
        --borderColor: var(--color);
      }
    }
  }
`;

export default React.memo(InputFineNumericStepper);
