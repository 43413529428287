import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  height?: Property.Height;
  width?: Property.Width;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const ChevronFilledRightIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ height, width, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.617 3.07619C5.243 3.23119 5 3.59619 5 4.00019L5 12.0001C5 12.4042 5.243 12.7691 5.617 12.9241C5.991 13.0781 6.421 12.9931 6.707 12.7071L10.707 8.70715C10.902 8.51215 11 8.25615 11 8.00015C11 7.74415 10.902 7.48815 10.707 7.29315L6.707 3.29319C6.421 3.00719 5.991 2.92119 5.617 3.07619Z"
        fill={fill}
      />
    </svg>
  );
};

ChevronFilledRightIcon.defaultProps = {
  height: '16',
  width: '16',
  fill: settings.colors.Primary.Blue_9,
};
