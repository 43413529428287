import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  height?: Property.Height;
  width?: Property.Width;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const ChevronFilledDownIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ height, width, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <path
              d="M13.731 2.617C13.567 2.243 13.18 2 12.75 2h-8.5c-.43 0-.817.243-.982.617-.164.374-.073.804.23 1.09l4.25 4c.208.195.48.293.752.293s.544-.098.75-.293l4.25-4c.305-.286.396-.716.231-1.09"
              transform="translate(-758 -216) translate(758.5 216)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

ChevronFilledDownIcon.defaultProps = {
  height: '16',
  width: '18',
  fill: settings.colors.Primary.Blue_9,
};
