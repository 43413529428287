import { assert, isDefined } from '@rhim/utils';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { TenantContext } from '../routes/routing/TenantProvider';
import { PARAMS } from '../utilities';
import { useCustomerIdHashmap } from './useCustomerIdHashmap';

export const useCustomerId = (): { customerId: string } => {
  const tenant = React.useContext(TenantContext);
  assert(isDefined(tenant), 'Attempted to access the tenant context outside of a TenantProvider');

  const { customer } = tenant;

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const queryCustomerHash = params.get(PARAMS.CUSTOMER_ID);
  const { getCustomerIdByHash } = useCustomerIdHashmap();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return { customerId: isDefined(queryCustomerHash) ? getCustomerIdByHash(queryCustomerHash) : customer.customerId };
};
