import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  height?: Property.Height;
  width?: Property.Width;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const ChevronFilledUpIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ height, width, fill, title, role, ...rest }) => {
  const titleText = 'triangleDown_8x6 Copy';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 9 6" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : <title>{titleText}</title>}
      <g id="QCK-light" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="VCP-MappingPanel" transform="translate(-131.000000, -423.000000)" fill={fill}>
          <g id="Group-5" transform="translate(48.000000, 103.000000)">
            <g id="Group-2" transform="translate(22.000000, 74.000000)">
              <g id="Group" transform="translate(25.000000, 41.000000)">
                <g transform="translate(36.500000, 205.000000)" id="Triangle-Copy-21">
                  <polygon
                    transform="translate(4.000000, 3.000000) scale(1, -1) rotate(180.000000) translate(-4.000000, -3.000000) "
                    points="4 0 8 6 0 6"
                  ></polygon>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

ChevronFilledUpIcon.defaultProps = {
  height: '6',
  width: '9',
  fill: settings.colors.Primary.Grey_8,
};
