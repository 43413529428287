import { isDefined } from '@rhim/utils';
import { useEffect, useState } from 'react';

export const useScript = (url: string, name?: string) => {
  const [lib, setLib] = useState<any>(null);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = false;
    if (isDefined(name)) {
      script.onload = () => setLib(window[name as keyof Window]);
    }

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [name, url]);

  return lib;
};
