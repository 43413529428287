import { settings } from '@rhim/design';
import { XMiniIcon } from '@rhim/icons/16';
import { isDefined } from '@rhim/utils';
import { notification } from 'antd';
import { ArgsProps } from 'antd/es/notification/interface';
import React from 'react';
import { SystemNotificationType } from 'typings';

import SvgInfo from '../../../../../../assets/icons-24/info-24.svg';
import SvgProgress from '../../../../../../assets/icons-24/loader-24.svg';
import SvgSuccess from '../../../../../../assets/icons-24/valid-24.svg';
import SvgAlert from '../../../../../../assets/icons-24/warning-24.svg';
import { Button } from '../Button/Button';

const MINIMUM_DEFAULT_DURATION = 4; // in seconds
const DURATION_PER_CHARACTER = 0.1; // in seconds

export const Notification = {
  open: (systemNotification: SystemNotificationType) => {
    const { key, type, message, description, userAcknowledgementButtonLabel, onCloseCallback, duration, getContainer, closeIcon } = systemNotification;
    const config: ArgsProps = {
      message,
      description,
      closeIcon: isDefined(closeIcon) ? closeIcon : <XMiniIcon />, //don't display the close icon if none set.
      key,
      ...(isDefined(userAcknowledgementButtonLabel) && {
        btn: (
          <Button
            label={userAcknowledgementButtonLabel}
            onClick={() => {
              notification.destroy(key);
              if (onCloseCallback) {
                onCloseCallback(key);
              }
            }}
            size="x-small-32"
            autoFocus
          />
        ),
      }),
      ...(onCloseCallback && { onClose: () => onCloseCallback(key) }),
    };
    const autoCloseDuration = Math.max(MINIMUM_DEFAULT_DURATION, (message + (description ?? '')).length * DURATION_PER_CHARACTER);
    config.duration = isDefined(userAcknowledgementButtonLabel) || type === 'progress' ? 0 : duration ?? autoCloseDuration;

    let icon;
    switch (type) {
      case 'info':
        icon = <SvgInfo />;
        break;
      case 'success':
        icon = <SvgSuccess />;
        break;
      case 'alert':
        icon = <SvgAlert />;
        break;
      case 'progress':
        icon = <SvgProgress />;
        break;
      default:
        throw new Error(`Unexpected type : '${type}'`);
    }
    notification.config({
      placement: 'bottomRight',
      getContainer,
    });
    notification.info({
      ...config,
      className: type,
      icon,
      description: (
        <React.Fragment>
          <StyleSheet />
          {description}
        </React.Fragment>
      ),
    });
  },
  destroy: (key?: string) => notification.destroy(key),
};

const StyleSheet = settings.globals.patchedCreateGlobalStyle`
  .ant-notification .ant-notification-notice-wrapper .ant-notification-notice {
    --border-radius: 2px;

    width: max-content;
    border-radius: var(--border-radius);
    box-shadow: 0 2px 16px 0 rgba(61, 85, 103, 0.1);
    padding: ${settings.Spacing.Spacing_150} ${settings.Spacing.Spacing_200};

    --marginLeft: calc(${settings.Spacing.Spacing_300} + ${settings.Spacing.Spacing_200}); /* icon width(24px) + 16px */
    --textColor: ${settings.colors.Primary.Grey_8};

    &.info {
      --leftSideColor: ${settings.colors.Primary.Grey_5};
    }

    &.success {
      --leftSideColor: ${settings.colors.Operational.State_Notif_Green_2};
    }

    &.alert {
      --leftSideColor: ${settings.colors.Operational.State_Notif_Magenta_2};
      --textColor: ${settings.colors.Operational.State_Notif_Magenta_4};

      background-color: ${settings.colors.Operational.State_Notif_Magenta_1};
    }

    &.progress {
      --leftSideColor: ${settings.colors.Operational.State_Blue_2};

      svg {
        animation: rotating 2s linear infinite;
      }

      @keyframes rotating {
        from {
          transform: rotate(0deg);
        }

        to {
          transform: rotate(360deg);
        }
      }

      .ant-notification-notice-close {
        display: none;
      }
    }

    &::before {
      content: '';
      width: 4px;
      height: 100%;
      background-color: var(--leftSideColor);
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
    }

    .ant-notification-notice-with-icon {
      .ant-notification-notice-message {
        font-size: ${settings.typography.FontSize.Medium};
        font-family: ${settings.typography.FontFamily.Bold};
        margin-left: var(--marginLeft);
        margin-bottom: 0;
      }

      .ant-notification-notice-description {
        font-size: ${settings.typography.FontSize.Small};
        font-family: ${settings.typography.FontFamily.Regular};
        margin-left: var(--marginLeft);

        &:not(:empty) {
          margin-top: ${settings.Spacing.Spacing_100};
        }
      }

      .ant-notification-notice-icon {
        margin: 0;

        & + .ant-notification-notice-message,
        & ~ .ant-notification-notice-description {
          color: var(--textColor);
        }
      }
    }

    &.ant-notification-notice .ant-notification-notice-close {
      top: 14px;
      right: ${settings.Spacing.Spacing_200};
    }

    .ant-notification-notice-btn {
      float: none;
      display: block;
      margin-top: ${settings.Spacing.Spacing_100};
      margin-left: var(--marginLeft);
    }
  }
`;
