import { FlattenSimpleInterpolation } from 'styled-components';

import { pick } from './objects';

type Stylesheet<K extends string> = Record<K, FlattenSimpleInterpolation>;

/**
 * CSS-in-JS equivalent for `classNames`.
 *
 * @example
 *
 *   ```ts
 *   const stylesheet = {
 *     base: css`
 *       font-weight: bold
 *     `,
 *     h1: css`
 *       margin-bottom: 40px;
 *     `,
 *   };
 *
 *   const styles = getStyles(stylesheet)({
 *     base: true,
 *     h1: false,
 *   })
 *   ```
 */
export function getStyles<K extends string>(stylesheet: Stylesheet<K>): (configuration: Slice<Record<K, boolean>>) => FlattenSimpleInterpolation[] {
  return (configuration) => Object.values(pick(stylesheet, configuration));
}
