import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const HomeViewIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M21 17c.552 0 1 .447 1 1v3c0 .553-.448 1-1 1h-3c-.552 0-1-.447-1-1 0-.553.448-1 1-1h2v-2c0-.553.448-1 1-1zM3 17c.552 0 1 .447 1 1v2h2c.552 0 1 .447 1 1 0 .553-.448 1-1 1H3c-.552 0-1-.447-1-1v-3c0-.553.448-1 1-1zm8.293-11.707c.391-.391 1.023-.391 1.414 0l6 6c.286.286.372.716.217 1.09-.155.374-.52.617-.924.617h-1v4c0 .553-.448 1-1 1h-3v-3c0-.553-.448-1-1-1s-1 .447-1 1v3H8c-.552 0-1-.447-1-1v-4H6c-.404 0-.769-.243-.924-.617-.155-.374-.069-.804.217-1.09zM6 2c.552 0 1 .447 1 1 0 .553-.448 1-1 1H4v2c0 .553-.448 1-1 1s-1-.447-1-1V3c0-.553.448-1 1-1h3zm15 0c.552 0 1 .447 1 1v3c0 .553-.448 1-1 1s-1-.447-1-1V4h-2c-.552 0-1-.447-1-1 0-.553.448-1 1-1h3z"
      />
    </svg>
  );
};

HomeViewIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
