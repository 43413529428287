import { settings } from '@rhim/design';
import { XIcon } from '@rhim/icons/16';
import { Modal as AntdModal } from 'antd';
import * as React from 'react';
import styled, { css } from 'styled-components';

export const MODAL_PADDING_PX = 64;

interface Props extends React.ComponentProps<typeof AntdModal> {
  children: React.ReactNode;
  footerButtonsHorizontalAlignment?: 'center' | 'right';
}

/**
 * RHIM wrapper around Antd Modal component.
 *
 * @see https://ant.design/components/modal/
 *
 * See the designs:
 *
 * @see https://app.zeplin.io/project/5eb293edf1f9a7763fec7596/screen/5ecdbb25d98e922939bd665f
 * @see https://app.zeplin.io/project/5eb293edf1f9a7763fec7596/screen/5ecdbb2b9a22fa46f5651324
 */
export const Modal: React.FunctionComponent<React.PropsWithChildren<Props>> = React.memo(function Modal({
  footerButtonsHorizontalAlignment = 'right',
  children,
  ...rest
}) {
  const styles = React.useMemo(
    () => css`
      .ant-modal-content {
        max-height: 90vh;
        overflow: auto;
        border: 1px solid ${settings.colors.Primary.Grey_2};
        padding: ${MODAL_PADDING_PX}px;
        border-radius: 3px;
        box-shadow: 0 2px 32px 0 rgba(0, 0, 0, 0.15);

        button.ant-modal-close {
          --offset: ${settings.Spacing.Spacing_200};

          top: var(--offset);
          right: var(--offset);
          width: auto;
          height: auto;

          span.ant-modal-close-x {
            --size: 32px;

            width: var(--size);
            height: var(--size);
          }
        }

        .ant-modal-header {
          border-bottom: none;
          padding-left: 0;
          padding-right: 0;
        }

        .ant-modal-body {
          padding: 0;
        }

        .ant-modal-footer {
          border-top: none;
          margin-top: ${settings.Spacing.Spacing_500};
          padding: 0;
          text-align: ${footerButtonsHorizontalAlignment};
        }
      }
    `,
    [footerButtonsHorizontalAlignment]
  );

  return (
    <>
      <StyleSheet />
      <AntdModal css={styles} closeIcon={<CloseIcon />} centered width="auto" {...rest}>
        {children}
      </AntdModal>
    </>
  );
});

Modal.whyDidYouRender = true;

const CloseIcon = styled(XIcon).attrs({
  fill: 'currentColor',
})`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${settings.colors.Primary.Blue_9};

  &:hover {
    color: ${settings.colors.Primary.Blue_8};

    &:active {
      color: ${settings.colors.Primary.Blue_7};
    }
  }
`;

const StyleSheet = settings.globals.patchedCreateGlobalStyle`
  .ant-modal-root .ant-modal-mask {
    /* Primary.Grey_8 with 0.8 opacity */
    background-color: rgba(61, 85, 103, 0.8);
  }
`;
