import { settings } from '@rhim/design';
import { RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto, RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel } from '@rhim/rest/operatorDisplay';
import { RHIMContractsMaintenanceTaskSeverity, RHIMContractsThresholdType } from '@rhim/rest/wearManagement';
import { ensure, hasElements, isDefined, isObject, isString, last } from '@rhim/utils';

export const calculateCurvePoints = (
  targetLifeTime: number,
  points: RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto[],
  curveData: APO.Curve | APO.TargetCurve,
  zoomRange?: number[]
): NonEmptyArray<RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto> => {
  const data: NonEmptyArray<RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto> =
    [] as unknown as NonEmptyArray<RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto>;

  const measuredpoints: NonEmptyArray<RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto> = hasElements(points)
    ? (points as NonEmptyArray<RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto>)
    : [{ heat: 0, liningThickness: 0 }];

  if (!zoomRange) {
    let heat = 0;

    if (isString(curveData.factor) || isString(curveData.offset)) {
      return data;
    }

    if (measuredpoints[0].heat > 0) {
      heat = 0;
      while (heat <= last(measuredpoints).heat) {
        data.push({
          heat,
          liningThickness: calculateLiningAtCurvePoint(heat, curveData),
        });
        heat++;
      }
    }

    heat = last(measuredpoints).heat + 1;
    while (heat <= targetLifeTime) {
      data.push({
        heat,
        liningThickness: calculateLiningAtCurvePoint(heat, curveData),
      });
      heat++;
    }
  }

  if (zoomRange) {
    let heat = ensure(zoomRange[0]);
    while (heat <= ensure(zoomRange[1])) {
      data.push({
        heat,
        liningThickness: calculateLiningAtCurvePoint(heat, curveData),
      });
      heat++;
    }
  }

  return data;
};

/**
 * Intersection Point between target curve and current measurement point.
 */
export const getTargetPoint = (
  targetCurvePoints: RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto[],
  currentMin: RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto
) => {
  return targetCurvePoints.find((point) => point.heat === currentMin.heat) || { liningThickness: 0, heat: 0 };
};

export const calculateLiningAtCurvePoint = (heat: number, curveData: APO.Curve | APO.TargetCurve) => {
  return curveData.factor * Math.exp(curveData.exponent * heat) + curveData.offset;
};

/**
 * Checks if the current min point is in the green area.
 */
export const isCurrentMinGreen = (
  targetPoint: RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto,
  nextPoint: RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto | number,
  lastMeasuredPoint: RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto
) => {
  return (
    targetPoint.liningThickness > lastMeasuredPoint.liningThickness &&
    (isObject(nextPoint) ? nextPoint.liningThickness : nextPoint) <= lastMeasuredPoint.liningThickness
  );
};

/**
 * Checks if the current min point is in the yellow area.
 */
export const isCurrentMinYellow = (
  firstThresholdAtCurrentHeat: RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto,
  nextPoint: RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto | number,
  lastMeasuredPoint: RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto
) => {
  return (
    firstThresholdAtCurrentHeat.liningThickness > lastMeasuredPoint.liningThickness &&
    (isObject(nextPoint) ? nextPoint.liningThickness : nextPoint) <= lastMeasuredPoint.liningThickness
  );
};
/**
 * Checks if the current min point is in the yellow area.
 */
export const isCurrentMinAboveUpperThreshold = (
  upperThresholdAtCurrentHeat: RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto,
  lastMeasuredPoint: RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto
) => {
  return upperThresholdAtCurrentHeat.liningThickness < lastMeasuredPoint.liningThickness;
};

/**
 * Checks if the current min point is in the orange area.
 */
export const isCurrentMinOrange = (
  secondThresholdAtCurrentHeat: RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto,
  criticalLiningThickness: number,
  lastMeasuredPoint: RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto
) => {
  return secondThresholdAtCurrentHeat.liningThickness > lastMeasuredPoint.liningThickness && criticalLiningThickness <= lastMeasuredPoint.liningThickness;
};

/**
 * Checks if the current min point is in the critical area.
 */
export const isCurrentMinCritical = (criticalLiningThickness: number, lastMeasuredPoint: RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto) => {
  return criticalLiningThickness > lastMeasuredPoint.liningThickness;
};

/**
 * Returns the circle colors based on the area it's in.
 * @param point Current Point.
 * @param data Regioun data.
 * @param lastMeasuredPoint Last Measured Point.
 */
export const getCircleColors = (
  point: RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto,
  data: RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel,
  lastMeasuredPoint: RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto,
  tableView = false
) => {
  const upperThresholdCurve = data.thresholdCurves.find(
    (curve) => curve.wearCurveType === RHIMContractsThresholdType.Upper && RHIMContractsMaintenanceTaskSeverity.Low === curve.severity
  );
  const firstLowerCurve = data.thresholdCurves.find(
    (curve) => curve.wearCurveType === RHIMContractsThresholdType.Lower && RHIMContractsMaintenanceTaskSeverity.Low === curve.severity
  );
  const secondLowerCurve = data.thresholdCurves.find(
    (curve) => curve.wearCurveType === RHIMContractsThresholdType.Lower && RHIMContractsMaintenanceTaskSeverity.Medium === curve.severity
  );

  const upperYellowLining = isDefined(upperThresholdCurve) ? calculateLiningAtCurvePoint(point.heat, upperThresholdCurve) : undefined;
  const greenLining = isDefined(data.targetLiningThicknessCurve)
    ? calculateLiningAtCurvePoint(point.heat, data.targetLiningThicknessCurve)
    : point.liningThickness;
  const yellowLining = isDefined(firstLowerCurve) ? calculateLiningAtCurvePoint(point.heat, firstLowerCurve) : undefined;
  const orangeLining = isDefined(secondLowerCurve) ? calculateLiningAtCurvePoint(point.heat, secondLowerCurve) : undefined;

  const criticalLiningThickness = data.criticalLiningThickness ?? 0;

  /* eslint-disable @typescript-eslint/strict-boolean-expressions */
  const isGreen = greenLining > point.liningThickness && (yellowLining || orangeLining || criticalLiningThickness) <= point.liningThickness;
  const isYellow = isDefined(yellowLining) ? yellowLining > point.liningThickness && (orangeLining || criticalLiningThickness) <= point.liningThickness : false;
  const isUpperYellow = isDefined(upperYellowLining) ? upperYellowLining < point.liningThickness : false;
  const isOrange = (orangeLining || criticalLiningThickness) > point.liningThickness && criticalLiningThickness <= point.liningThickness;
  const isCritical = criticalLiningThickness > point.liningThickness;

  if (isGreen) {
    return {
      border: settings.colors.Primary.Grey_8,
      background: settings.colors.Primary.Grey_8,
    };
  }

  if (isYellow || isUpperYellow) {
    return {
      border: settings.colors.Operational.State_Alert_Yellow_3,
      background: settings.colors.Operational.State_Alert_Yellow_1,
    };
  }

  if (isOrange) {
    return {
      border: settings.colors.Operational.State_Alert_Orange_3,
      background: settings.colors.Operational.State_Alert_Orange_1,
    };
  }

  if (isCritical && ((!tableView && lastMeasuredPoint.liningThickness >= criticalLiningThickness) || tableView)) {
    //it's not the last measured point
    // For table view mode we want to color all measured point.
    return {
      border: settings.colors.Operational.State_Alert_Red_3,
      background: settings.colors.Operational.State_Alert_Red_1,
    };
  }

  return {
    border: settings.colors.Primary.Grey_8,
    background: settings.colors.Primary.Grey_8,
  };
};
