import type { Icon } from '@rhim/icons';
import { isDefined } from '@rhim/utils';
import React from 'react';
import styled from 'styled-components';

import AxisLabel from './AxisLabel';
import { AXIS_BOTTOM_STYLES, AXIS_LABEL_HEIGHT, AXIS_LABEL_ICON_SIZE, AXIS_LABEL_OFFSET_VERTICAL, TICK_LABEL_COLOR } from './constants';

interface AxisBottomLabelProps {
  axisLabel?: string;
  AxisIcon?: React.FunctionComponent<Icon>;
  chartHeight: number;
}

const AxisBottomLabel: React.ChildlessComponent<AxisBottomLabelProps> = (props) => {
  const { AxisIcon, axisLabel, chartHeight } = props;

  return (
    <AxisLabelSticky
      top={chartHeight + AXIS_BOTTOM_STYLES.height - AXIS_LABEL_HEIGHT - AXIS_LABEL_OFFSET_VERTICAL}
      icon={isDefined(AxisIcon) ? <AxisIcon fill={TICK_LABEL_COLOR} size={`${AXIS_LABEL_ICON_SIZE}px`} /> : undefined}
      iconPlacement="right"
      label={axisLabel}
    />
  );
};

export default React.memo(AxisBottomLabel);

const AxisLabelSticky = styled(AxisLabel)<{ top: number }>`
  position: absolute;
  top: ${({ top }) => (isDefined(top) ? top : 0)}px;
  right: 0;
`;
