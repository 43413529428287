import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const VersionHistoryIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none" role={role} {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2a6 6 0 1 0 0 12v2a8 8 0 1 1 5.291-2H15a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1v-4a1 1 0 1 1 2 0v1.472A6 6 0 0 0 8 2zm0 1.5a1 1 0 0 1 1 1V9l-2.4 1.8a1 1 0 1 1-1.2-1.6L7 8V4.5a1 1 0 0 1 1-1z"
        fill={fill}
      />
    </svg>
  );
};

VersionHistoryIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
