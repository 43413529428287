import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const VesselCOUIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 1H7V4.17071C8.16519 4.58254 9 5.69378 9 7C9 5.69378 9.83481 4.58254 11 4.17071V1H13V4.17071C14.1652 4.58254 15 5.69378 15 7C15 5.69378 15.8348 4.58254 17 4.17071V1H19V4.17071C20.1652 4.58254 21 5.69378 21 7H22V20V22H21H20H19H16H15H14H10H9H8H5H4H3H2V20V7H3C3 5.69378 3.83481 4.58254 5 4.17071V1ZM5 20H7V7H5V20ZM11 20H13V7H11V20ZM17 20H19V7H17V20Z"
        fill={fill}
      />
    </svg>
  );
};

VesselCOUIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
