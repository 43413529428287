import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const LabelIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.6 16h12.8a1.6 1.6 0 0 0 1.6-1.6V1.6A1.6 1.6 0 0 0 14.4 0H1.6A1.6 1.6 0 0 0 0 1.6v12.8A1.6 1.6 0 0 0 1.6 16zm5.179-5.298L6.449 12H4.326L6.69 4.19h2.662l2.32 7.81H9.508l-.33-1.298H6.779zm1.22-4.697h-.021l-.814 3.157h1.628L8 6.005z"
      />
    </svg>
  );
};

LabelIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
