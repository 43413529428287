import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const FullscreenExitIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <path
              d="M8 15c.13 0 .26.026.382.077.245.102.439.296.54.541.052.122.078.252.078.382v4c0 .553-.448 1-1 1s-1-.447-1-1v-1.586l-3.293 3.293C3.512 21.902 3.256 22 3 22c-.256 0-.512-.098-.707-.293-.39-.39-.39-1.024 0-1.414L5.586 17H4c-.552 0-1-.447-1-1 0-.553.448-1 1-1zm12 0c.552 0 1 .447 1 1 0 .553-.448 1-1 1h-1.586l3.293 3.293c.391.391.391 1.023 0 1.414-.195.195-.45.293-.707.293-.256 0-.512-.098-.707-.293L17 18.414V20c0 .553-.448 1-1 1s-1-.447-1-1v-4c0-.553.448-1 1-1zM2.293 2.293c.391-.391 1.023-.391 1.414 0L7 5.586V4c0-.553.448-1 1-1s1 .447 1 1v4c0 .553-.448 1-1 1H4c-.552 0-1-.447-1-1 0-.553.448-1 1-1h1.586L2.293 3.707c-.391-.391-.391-1.023 0-1.414zm18 0c.39-.391 1.023-.391 1.414 0 .39.39.39 1.024 0 1.414L18.414 7H20c.552 0 1 .447 1 1 0 .553-.448 1-1 1h-4c-.13 0-.26-.026-.382-.077-.245-.102-.439-.296-.54-.541C15.025 8.26 15 8.13 15 8V4c0-.553.448-1 1-1s1 .447 1 1v1.586z"
              transform="translate(-1880 -11) translate(1880 11)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

FullscreenExitIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
