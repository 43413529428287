import config from '@rhim/config';
import { settings } from '@rhim/design';
import { AppStateAlertIcon } from '@rhim/icons/32';
import { AppStateAlertIcon as LargeAppStateAlertIcon } from '@rhim/icons/64';
import { isDefined } from '@rhim/utils';
import React, { ErrorInfo, ReactNode } from 'react';
import { Translation } from 'react-i18next';
import { useRouteError } from 'react-router-dom';
import styled from 'styled-components';

import { MaintenancePanel, Size } from '../MaintenancePanel';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

type Props = {
  size?: Size;
  children: ReactNode;
};

type State = {
  errorMessage: string | null;
  stack?: string;
};

const isDev = config.version === 'dev';

class ErrorBoundary extends React.Component<Props, State> {
  override state: State = { errorMessage: null };
  static getDerivedStateFromError(error: Error): State {
    return { errorMessage: error.message, stack: error.stack };
  }

  override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error('Uncaught error : ', error);
    // eslint-disable-next-line no-console
    console.error('Uncaught error info : ', errorInfo);
  }

  override render() {
    const { errorMessage, stack } = this.state;

    const error =
      isDev && isDefined(stack) ? (
        <>
          {errorMessage}
          <br />
          <StyledStackTrace>{stack}</StyledStackTrace>
        </>
      ) : (
        errorMessage
      );

    if (isDefined(errorMessage)) {
      return (
        <StyledWrapper>
          <Translation>
            {(t) => (
              <MaintenancePanel
                size={this.props.size}
                heading={t('errorBoundary:errorMessage')}
                subHeading={error}
                icon={this.props.size === 'large' ? <LargeAppStateAlertIcon /> : <AppStateAlertIcon />}
              />
            )}
          </Translation>
        </StyledWrapper>
      );
    }

    return this.props.children;
  }
}

const StyledStackTrace = styled.p`
  font-size: ${settings.typography.FontSize.Small};
  white-space: pre;
  text-align: left;
`;

/**
 * Propagate the error caught by React Router to the closest ErrorBoundary of our own.
 */
export function RethrowError() {
  const error = useRouteError();

  throw error;

  return null;
}

export default ErrorBoundary;
