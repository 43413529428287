import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const ErrorIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M12 19c-.83 0-1.5-.671-1.5-1.5S11.17 16 12 16c.829 0 1.5.671 1.5 1.5S12.829 19 12 19zM11 9c0-.552.448-1 1-1s1 .448 1 1v5c0 .552-.448 1-1 1s-1-.448-1-1V9zm10.767 9.317l-8.182-14.4C13.263 3.351 12.657 3 12 3c-.657 0-1.263.351-1.585.917l-8.182 14.4c-.316.558-.31 1.24.016 1.792S3.172 21 3.818 21h16.364c.646 0 1.243-.339 1.569-.891.326-.552.332-1.234.016-1.792z"
      />
    </svg>
  );
};

ErrorIcon.defaultProps = {
  size: '24',
  fill: '#AB0405',
};
