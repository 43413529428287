import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const GraphEyeIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <g>
              <path
                d="M2.618 2.076c.244-.101.52-.101.764 0 .123.05.233.124.326.216l.999 1c.39.39.39 1.023 0 1.414-.195.195-.451.293-.707.293v15h15c0-.256.098-.512.293-.707.39-.391 1.024-.391 1.414 0l1 .999c.092.092.165.203.216.325.1.245.1.52 0 .765-.051.122-.124.233-.216.325l-1 1c-.195.195-.451.293-.707.293-.256 0-.512-.098-.707-.293-.195-.196-.293-.451-.293-.707H3c-.552 0-1-.448-1-1v-16c-.256 0-.512-.098-.707-.293-.391-.391-.391-1.024 0-1.414l1-1c.092-.092.203-.165.325-.216zM13.5 5.999c3.412 0 6.405 2.082 7.448 5.18.07.208.07.432 0 .639-1.043 3.099-4.036 5.18-7.448 5.18-3.412 0-6.405-2.081-7.448-5.18-.07-.207-.07-.431 0-.638 1.043-3.1 4.036-5.181 7.448-5.181zm0 2c-2.432 0-4.572 1.393-5.434 3.5.862 2.107 3.002 3.5 5.434 3.5 2.432 0 4.572-1.393 5.434-3.5-.862-2.107-3.002-3.5-5.434-3.5zm0 1c1.381 0 2.5 1.119 2.5 2.5 0 1.38-1.119 2.5-2.5 2.5s-2.5-1.12-2.5-2.5c0-1.381 1.119-2.5 2.5-2.5z"
                transform="translate(-1463 -115) translate(1455 107) translate(8 8)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

GraphEyeIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
