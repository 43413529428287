import { Property } from 'csstype';
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const DataPointLineIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      <path fillRule="evenodd" clipRule="evenodd" d="M16 9H0V7h16v2z" fill={fill} />
      <path d="M10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" fill={fill} />
    </svg>
  );
};

DataPointLineIcon.defaultProps = {
  size: '16',
  fill: '#D30000',
};
