import { RHIMCementServiceV1ModelsKilnShellTemperatureProfileDto } from '@rhim/rest/cement';
import { assert, isDefined, specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api';

type Payload = RHIMCementServiceV1ModelsKilnShellTemperatureProfileDto;

export function useCrkTemperatureProfile(
  vesselId: string | null,
  campaign?: number,
  timestamp?: string,
  configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>
) {
  return useQuery(getKey(vesselId, campaign, timestamp), (context) => queryFn(context), configuration);
}

const getKey = (vesselId: string | null, campaign?: number, timestamp?: string) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'cement',
      scope: 'crk-temperature-profile',
      entity: 'detail',
      vesselId,
      campaign,
      timestamp,
    },
  ]);

const queryFn = (
  { queryKey: [{ vesselId, campaign, timestamp }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>,
  axiosConfiguration?: AxiosRequestConfig
) => {
  assert(
    isDefined(vesselId),
    `${useCrkTemperatureProfile.name} requires a vesselId, but value was ${vesselId}. Use a valid id, or use \`options.enabled\` to pause the query until all requirements are met. See https://react-query.tanstack.com/guides/disabling-queries for instructions.`
  );

  return API.kilnShellTemperatureProfiles
    .getVesselsVesselidKilnshelltemperatureprofiles(vesselId, campaign, timestamp, { signal, ...axiosConfiguration })
    .then((response) => response.data);
};

useCrkTemperatureProfile.getKey = getKey;
useCrkTemperatureProfile.queryFn = queryFn;
