import { RHIMCementServiceV1ModelsScanDto } from '@rhim/rest/cement';
import { assert, isDefined, specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api';

type Payload = RHIMCementServiceV1ModelsScanDto;

export function useCrkScan(
  scanId: string,
  configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>
) {
  return useQuery(getKey(scanId), (context) => queryFn(context), {
    ...configuration,
    select: (data) => {
      const { comment: commentEncoded, extractions } = data;
      const sorted = [...extractions].sort((a, b) => new Date(b.extractionDate).getTime() - new Date(a.extractionDate).getTime());

      let comment;
      try {
        comment = isDefined(commentEncoded) ? decodeURIComponent(commentEncoded) : commentEncoded;
      } catch (error) {
        comment = commentEncoded; // Fallback to original value
      }

      return { ...data, extractions: sorted, comment };
    },
  });
}

const getKey = (scanId: string) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'cement',
      scope: 'scans',
      entity: 'detail',
      scanId,
    },
  ]);

const queryFn = ({ queryKey: [{ scanId }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  assert(
    isDefined(scanId),
    `${useCrkScan.name} requires a scanId, but value was ${scanId}. Use a valid id, or use \`options.enabled\` to pause the query until all requirements are met. See https://react-query.tanstack.com/guides/disabling-queries for instructions.`
  );

  return API.scans.getScansScanid(scanId, { signal, ...axiosConfiguration }).then((response) => response.data);
};

useCrkScan.getKey = getKey;
useCrkScan.queryFn = queryFn;
