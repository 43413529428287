import { useMemoCompare } from '@rhim/react';
import { RHIMMeasurementServiceV1ModelsMeasurementViewDto } from '@rhim/rest/measurementService';
import { hasElements, head, isDefined } from '@rhim/utils';
import { join } from 'lodash';
import React from 'react';
import useSessionStorageState from 'use-session-storage-state';

import { STORAGE_KEYS } from '../utilities';
import { LOCAL_STORAGE_PREFIX_APO } from '../utilities/storage';

const logger = log.child('useSelectedMeasurement');

logger.mute();

function useSelectedMeasurementId(initialState: string | null) {
  return useSessionStorageState(join([LOCAL_STORAGE_PREFIX_APO, STORAGE_KEYS.SELECTED_MEASUREMENT_ID], ''), {
    defaultValue: initialState,
  });
}

export function useSelectedMeasurement(
  measurements?: RHIMMeasurementServiceV1ModelsMeasurementViewDto[]
): [string | null, React.Dispatch<React.SetStateAction<string | null>>] {
  const [persistedMeasurementId, setPersistedMeasurementId] = useSelectedMeasurementId(null);

  logger.debug(`Launched measurement id persistence. Initial value: ${persistedMeasurementId}`);

  React.useDebugValue(persistedMeasurementId);

  React.useEffect(() => {
    if (!isDefined(measurements)) {
      logger.debug('⛔️ No measurements available. Removing the persisted measurement id from local storage.');

      setPersistedMeasurementId(null);
      return;
    }
    const persistedMeasurement = measurements.find((measurement) => measurement.id === persistedMeasurementId);
    const isPersistedMeasurementIdValid = isDefined(persistedMeasurement);

    if (isPersistedMeasurementIdValid) {
      logger.debug(
        `✅ Persisted measurement id DOES belong to one of the availabe measurements. Returning the measurement id found in local storage: ${persistedMeasurement.id}. All measurements:`,
        measurements.map((measurement) => measurement.id)
      );

      return;
    } else if (hasElements(measurements)) {
      const firstAvailableMeasurement = head(measurements);

      logger.debug(
        `⚠️ Persisted measurement id DOES NOT belong to one of the available measurements. Returning the first measurement id available : ${firstAvailableMeasurement.id}. All measurements:`,
        measurements.map((measurement) => measurement.id)
      );

      setPersistedMeasurementId(firstAvailableMeasurement.id);
    } else {
      logger.debug('⛔️ No measurements available. Removing the persisted measurements id from local storage.');

      setPersistedMeasurementId(null);
    }
  }, [persistedMeasurementId, setPersistedMeasurementId, measurements]);

  const memoizedValue: [string | null, React.Dispatch<React.SetStateAction<string | null>>] = useMemoCompare(
    [persistedMeasurementId, setPersistedMeasurementId],
    ([previousId], [nextId]) => {
      return previousId === nextId;
    }
  );

  return memoizedValue;
}
