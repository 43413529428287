import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const XCircledIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        d="M8 0c4.411 0 8 3.589 8 8s-3.589 8-8 8-8-3.589-8-8 3.589-8 8-8zm0 2C4.691 2 2 4.691 2 8s2.691 6 6 6 6-2.691 6-6-2.691-6-6-6zM4.293 4.293a.999.999 0 0 1 1.414 0L8 6.586l2.293-2.293a.999.999 0 1 1 1.414 1.414L9.414 8l2.293 2.293a.999.999 0 1 1-1.414 1.414L8 9.414l-2.293 2.293a.997.997 0 0 1-1.414 0 .999.999 0 0 1 0-1.414L6.586 8 4.293 5.707a.999.999 0 0 1 0-1.414z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
};

XCircledIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Grey_8,
};
