import { VesselType } from '@rhim/rest';
import { assert, ensure, hasElements, head, isDefined, isUUID } from '@rhim/utils';
import React from 'react';
import { useLocation } from 'react-router';

import { usePersistedComparedVessel, useSelectedVessel } from '../../hooks';
import { useVesselLabel } from '../../lib';
import { useSortedVesselsGroupedByType } from '../../lib/vessels/useSortedVesselsGroupedByType';
import { useVesselsGroupedByType } from '../../lib/vessels/useVesselsGroupedByType';
import { Feature } from '../../settings/featureFlags';
import { ROUTES } from '../../utilities';
import { useSidebarItems } from './useSidebarItems';

const featuresForRoutes = new Map<string, Feature>([
  [ROUTES.MEASUREMENT_VIEW, Feature.MeasurementView],
  [ROUTES.DISPLAY, Feature.Display],
  [ROUTES.REPORT, Feature.Report],
  [ROUTES.RH_REPORT, Feature.RhReport],
  [ROUTES.FLEET_OVERVIEW, Feature.FleetOverview],
  [ROUTES.PARAMETERS_COMPARISON, Feature.ParameterComparison],
]);

type MainNavigationResult =
  // The full navigation is shown when the user has access to at least one vessel.
  | {
      type: 'full';
      items: {
        hasFeaturesToShow: boolean;
        label: string;
        active: boolean;
        destination: string;
        onClick: () => void;
      }[];
    }
  // The simplified navigation is shown when the user has no access to any vessels.
  | { type: 'simplified' };

/**
 * Obtains too-level navigation bar items.
 *
 * @example
 *
 * ```tsx
 * const mainNavigation = useMainNavigation(vessels);
 *
 * if (mainNavigation.type === 'simplified') {
 *  // Render simplified navigation bar without vessel types
 * } else {
 *  return mainNavigation.items.map(item => <NavLink active={item.active} onClick={item.onClick} label={item.label} />)
 * }
 * ```
 */
export function useMainNavigation(vessels: APO.VesselV2[]): MainNavigationResult {
  const vesselsGroupedByType = useVesselsGroupedByType(vessels);
  const sortedVesselsGroupedByType = useSortedVesselsGroupedByType(vesselsGroupedByType);
  const existingVesselsGroupedByType = sortedVesselsGroupedByType.filter(([, members]) => hasElements(members));
  const { selectedVesselId, setSelectedVesselId } = useSelectedVessel(vessels);
  const { setSelectedComparedVesselId } = usePersistedComparedVessel(vessels);

  const location = useLocation();
  const getVesselLabel = useVesselLabel();

  const sidebarItemsForVesselType = useSidebarItems();

  /**
   * undefined when the list of vessels is empty. This happens on static pages
   * (imprint, privacy etc.) and when the customer is not yet fully configured.
   *
   * In these scenarios, we don't have any vessel types to show in the main nav,
   * and so we should render alternative (simplified) navigation bar instead.
   */
  const selectedVesselType: VesselType | undefined = React.useMemo(() => {
    return vessels.find((vessel) => vessel.id === selectedVesselId)?.vesselType;
  }, [selectedVesselId, vessels]);

  const items = React.useMemo(() => {
    return existingVesselsGroupedByType
      .map(
        ([targetVesselType, vesselsOfThisType]) => {
          assert(hasElements(vesselsOfThisType));
          const firstVesselOfType = head(vesselsOfThisType);
          assert(isUUID(firstVesselOfType.id));

          const sidebarItemsForTargetVesselType = ensure(
            sidebarItemsForVesselType.get(targetVesselType),
            `Expected to find a destination for vessel type ${targetVesselType}`
          );

          // If at least one feature is available, then we can safely redirect to the first available feature.
          // Otherwise, no feature is available, and it doesn't matter much which feature we redirect to
          // because the user will see a message that no features are available anyway. So we can just redirect to the first feature.
          const firstAvailableFeature =
            sidebarItemsForTargetVesselType.find((item) => item.show && !item.disabled && item.destination === location.pathname) ??
            sidebarItemsForTargetVesselType.find((item) => item.show && !item.disabled) ??
            head(sidebarItemsForTargetVesselType);
          let destination: string = firstAvailableFeature.destination;

          // If target vessel type supports the same feature that is currently selected AND that same feature is enabled for target vessel,
          // then we can safely "redirect" to the same feature we're already in.
          //
          // (Meaning the destination stays the same, only the onClick handler selects a different vessel type under the hood.)
          if (targetVesselType !== selectedVesselType) {
            const currentFeature = featuresForRoutes.get(location.pathname);
            const canKeepCurrentFeature: boolean = isDefined(
              sidebarItemsForTargetVesselType.find((item) => item.feature === currentFeature && item.show && !item.disabled)
            );

            if (canKeepCurrentFeature) {
              destination = location.pathname;
            }
          }

          return {
            // Do not include vessel types that have no features to show in the main navigation
            // https://dev.azure.com/RHIM/UX/_git/UX/pullRequest/25103#1687180011
            hasFeaturesToShow: sidebarItemsForTargetVesselType.some((item) => item.show),
            label: getVesselLabel(targetVesselType),
            destination,
            active: selectedVesselType === targetVesselType,
            onClick: () => {
              try {
                log.debug(
                  `Selected vessel is of type ${targetVesselType}. First active feature for this vessel is this ${firstAvailableFeature.feature}`,
                  `The destination I can safely redirect is ${destination}`
                );

                setSelectedVesselId(firstVesselOfType.id);
                setSelectedComparedVesselId(firstVesselOfType.id);
              } catch {
                // Nothing to do here. It's just a workaround for a bug in the use-persisted-state library.
                // https://github.com/donavon/use-persisted-state/issues/56#issuecomment-892877563
              }
            },
          };
        },
        [existingVesselsGroupedByType]
      )
      .filter((item) => item.hasFeaturesToShow);
  }, [
    setSelectedComparedVesselId,
    existingVesselsGroupedByType,
    sidebarItemsForVesselType,
    selectedVesselType,
    getVesselLabel,
    location.pathname,
    setSelectedVesselId,
  ]);

  if (hasElements(vessels)) {
    return { type: 'full', items };
  } else {
    return { type: 'simplified' };
  }
}
