import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const ReorderIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" role={role} xmlns="http://www.w3.org/2000/svg" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path d="M3 10h19M3 14h19" stroke={fill} strokeWidth="2" />
    </svg>
  );
};

ReorderIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Grey_4,
};
