import { VesselType } from '@rhim/rest';
import type { RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto } from '@rhim/rest/customerManagement';
import { isDefined } from '@rhim/utils/is-defined';

/**
 * Checks if a customer has a vessel of a specific type.
 * If the customer object does not have the vesselTypes property or the desired vessel type, it will return false.
 */
export function hasVesselOfType(customer: RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto, vesselType: VesselType): boolean {
  return isDefined(customer.vesselTypes) && customer.vesselTypes.some((i) => i === vesselType);
}

export function hasCementVessel(customer: RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto): boolean {
  return hasVesselOfType(customer, VesselType.Crk);
}

export function hasLadleVessel(customer: RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto): boolean {
  return hasVesselOfType(customer, VesselType.Ladle);
}
