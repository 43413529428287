import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const MailIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      role={role}
      {...rest}
    >
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="nonzero"
        d="M62.788 23.85c.487-.388 1.212-.025 1.212.587V50c0 3.313-2.688 6-6 6H6c-3.313 0-6-2.688-6-6V24.45a.748.748 0 011.212-.587c2.8 2.175 6.513 4.937 19.263 14.2 2.637 1.924 7.087 5.974 11.525 5.95 4.462.037 9-4.1 11.538-5.95 12.75-9.263 16.45-12.038 19.25-14.213zM32 40c2.9.05 7.075-3.65 9.175-5.175 16.588-12.038 17.85-13.088 21.675-16.088A2.991 2.991 0 0064 16.376V14c0-3.313-2.688-6-6-6H6c-3.313 0-6 2.688-6 6v2.375a3.01 3.01 0 001.15 2.363c3.825 2.987 5.088 4.05 21.675 16.087C24.925 36.35 29.1 40.05 32 40z"
      />
    </svg>
  );
};

MailIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
