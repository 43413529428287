import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const XCircledIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <path
              d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 2c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8.293 8.293c.391-.391 1.023-.391 1.414 0L12 10.586l2.293-2.293c.391-.391 1.023-.391 1.414 0s.391 1.023 0 1.414L13.414 12l2.293 2.293c.391.391.391 1.023 0 1.414-.195.195-.451.293-.707.293-.256 0-.512-.098-.707-.293L12 13.414l-2.293 2.293C9.512 15.902 9.256 16 9 16c-.256 0-.512-.098-.707-.293-.391-.391-.391-1.023 0-1.414L10.586 12 8.293 9.707c-.391-.391-.391-1.023 0-1.414z"
              transform="translate(-381.000000, -971.000000) translate(381.000000, 971.000000)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

XCircledIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
