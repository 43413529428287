import { AXIS_BOTTOM_HEIGHT, AXIS_LEFT_MARGIN_TOP, AXIS_LEFT_WIDTH, AXIS_RIGHT_WIDTH } from '../axis/constants';

export const ACTION_ID = {
  viewZoomIn: 'viewZoomIn',
  viewZoomOut: 'viewZoomOut',
};

export const ZOOM_INIT = 1;
export const ZOOM_CENTER_DEFAULT = 0.5;
export const ZOOM_MIN = 1;
export const ZOOM_MAX = 8;
export const ZOOM_STEP = 1;

export const SCROLLBAR_PADDING_BOTTOM = 16;

export const DEFAULT_MARGIN = {
  top: AXIS_LEFT_MARGIN_TOP,
  right: AXIS_RIGHT_WIDTH,
  bottom: AXIS_BOTTOM_HEIGHT + SCROLLBAR_PADDING_BOTTOM,
  left: AXIS_LEFT_WIDTH,
};
