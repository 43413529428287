import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const Measurement3dIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        d="M8.5 2c3.616 0 6.363 1.637 6.495 3.834L15 6v12c0 2.28-2.794 4-6.5 4-3.614 0-6.36-1.635-6.494-3.83l-.005-.166V5.996C2.004 3.718 4.796 2 8.5 2zm9.793 1.293a.999.999 0 0 1 1.414 0l2 2a.999.999 0 1 1-1.414 1.414L20 6.414v11.172l.293-.293a.999.999 0 1 1 1.414 1.414l-2 2a.997.997 0 0 1-1.414 0l-2-2a.999.999 0 1 1 1.414-1.414l.293.293V6.414l-.293.293a.999.999 0 1 1-1.414-1.414zM13 8.933C11.849 9.6 10.282 10 8.5 10c-1.663 0-3.14-.348-4.264-.937L4 8.933V18l.006.083C4.118 18.893 5.845 20 8.5 20s4.382-1.107 4.494-1.917L13 18V8.933zM8.5 4C5.753 4 4 5.185 4 6s1.753 2 4.5 2S13 6.815 13 6s-1.753-2-4.5-2z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
};

Measurement3dIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Grey_2,
};
