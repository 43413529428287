import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const InformationIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.667 22.667h-5.333a1.333 1.333 0 1 1 0-2.667h1.333v-4h-1.333a1.333 1.333 0 1 1 0-2.667H16c.736 0 1.334.596 1.334 1.334V20h1.333a1.333 1.333 0 1 1 0 2.667M16 8a2 2 0 1 1 0 4 2 2 0 0 1 0-4m0-5.333C8.636 2.667 2.667 8.637 2.667 16S8.637 29.333 16 29.333c7.364 0 13.334-5.97 13.334-13.333S23.364 2.667 16 2.667"
      />
    </svg>
  );
};

InformationIcon.defaultProps = {
  size: '32',
  fill: settings.colors.Primary.Blue_9,
};
