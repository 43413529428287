import { Configuration, CrkPredictionsApi, CrkZoneSetApi, RegionsApi, WearExplorerViewApi } from '@rhim/rest/wearManagement';

import { axiosApiInstance } from '../../utilities';

/**
 * "https://apim-apo-dev.azure-api.net/wearmanagement/v1".replace(/\/+$/, "");
 */
const BASE_URL = '/api/wearmanagement/v1';

const configuration: Configuration = {} as Configuration;

export const regions = new RegionsApi(configuration, BASE_URL, axiosApiInstance);
export const wearExplorer = new WearExplorerViewApi(configuration, BASE_URL, axiosApiInstance);
export const crkPredictions = new CrkPredictionsApi(configuration, BASE_URL, axiosApiInstance);
export const crkZones = new CrkZoneSetApi(configuration, BASE_URL, axiosApiInstance);
