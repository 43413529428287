import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { GearIcon } from '@rhim/icons/24';
import { CtaButton, Hyperlink, IconButton, MaintenancePanel } from '@rhim/react';
import { noCampaignsFoundTitleErrorPanel } from '@rhim/test-ids';
import { isDefined, isDictionaryLike } from '@rhim/utils';
import i18next from 'i18next';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import SvgAlert64 from '../../../../../assets/icons-64/app-state-alert-64.svg';
import SvgMissing64 from '../../../../../assets/icons-64/app-state-missing-64.svg';
import { Invariants } from '../../errors';
import { ROUTES } from '../../utilities';

export const errorCodes = {
  common: {
    entityNotFound: 'common.entityNotFound',
  },
  operatorview: {
    onRegionsDefined: 'operatordisplayservice.noRegionsDefined',
    noRegionsSelected: 'operatordisplayservice.noRegionsSelected',
    noCampaignAvailable: 'operatordisplayservice.noCampaignAvailable',
    noLiningForRegion: 'operatordisplayservice.noLiningForRegion',
  },
  wearExplorer: {
    predictionNotFound: 'reporting.predictionNotFound',
  },
  processData: {
    vesselHasNoShortlist: 'processData.vesselHasNoShortlist',
  },
};

interface Props {
  className?: string;
  error: Error;
  resetErrorBoundary?: () => void;
}

/**
 * Error Panel for the reporting pages.
 */
const ErrorPanel: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ className, error, resetErrorBoundary }) => {
  const { t } = i18nReact.useTranslation(['ingress', 'operator-display', 'parameters-comparison', 'wear', 'reporting', 'shared', 'errorBoundary', 'app']);
  const navigate = useNavigate();
  const refresh = React.useCallback(() => {
    if (isDefined(resetErrorBoundary)) {
      resetErrorBoundary();
    } else {
      window.location.reload();
    }
  }, [resetErrorBoundary]);

  const goBack = React.useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const defaultCta: CtaButton = React.useMemo(() => ({ label: t('shared:refresh'), onClick: refresh }), [refresh, t]);

  return (
    <Container>
      <StyledMissingWrapper className={className}>
        {(() => {
          if (isWearManagementError(error)) {
            switch (error.errorCode) {
              case errorCodes.operatorview.onRegionsDefined:
                return (
                  <MaintenancePanel
                    size="large"
                    heading={t('operator-display:errors.missingRegions.heading')}
                    subHeading={
                      <Trans i18nKey="operator-display:errors.missingRegions.subHeading">
                        We detected that the region configuration has not been completed yet. Our operations teams has just been notified and will look into
                        this problem. Please come back at a later point. If the problem persists, contact{' '}
                        <Hyperlink href="mailto:onboarding.digital@rhimagnesita.com" text="onboarding.digital@rhimagnesita.com" />.
                      </Trans>
                    }
                    icon={<SvgMissing64 />}
                    headerColor={settings.colors.Operational.State_Notif_Grey_2}
                    subheaderColor={settings.colors.Operational.State_Notif_Grey_2}
                    secondaryButton={defaultCta}
                  />
                );

              case errorCodes.operatorview.noRegionsSelected:
                return (
                  <MaintenancePanel
                    size="large"
                    heading={t('operator-display:errors.noSelectedRegions.heading')}
                    subHeading={
                      <Trans i18nKey="operator-display:errors.noSelectedRegions.subHeading">
                        <FlexContainer>
                          Please use the display settings <IconButton icon={<GearIcon />} /> in the top right to open the region selection.
                        </FlexContainer>
                      </Trans>
                    }
                    icon={<SvgMissing64 />}
                    headerColor={settings.colors.Operational.State_Notif_Grey_2}
                    subheaderColor={settings.colors.Operational.State_Notif_Grey_2}
                  />
                );
              case errorCodes.operatorview.noLiningForRegion:
                return (
                  <MaintenancePanel
                    size="large"
                    heading={t('operator-display:errors.noLiningForRegion.heading')}
                    subHeading={
                      <Trans i18nKey="operator-display:errors.noLiningForRegion.subheading">
                        We detected that the lining configuration has not been completed yet. Our operations teams has just been notified and will look into
                        this problem. Please come back at a later point. If the problem persists, contact{' '}
                        <Hyperlink href="mailto:onboarding.digital@rhimagnesita.com" text="onboarding.digital@rhimagnesita.com" />.
                      </Trans>
                    }
                    icon={<SvgMissing64 />}
                    headerColor={settings.colors.Operational.State_Notif_Grey_2}
                    subheaderColor={settings.colors.Operational.State_Notif_Grey_2}
                    secondaryButton={defaultCta}
                  />
                );

              case errorCodes.operatorview.noCampaignAvailable:
                return (
                  <MaintenancePanel
                    size="large"
                    heading={t('operator-display:errors.missingCampaigns.heading')}
                    subHeading={t('operator-display:errors.missingCampaigns.subHeading')}
                    primaryButton={{
                      label: i18next.t('operator-display:errors.missingCampaigns.primaryButtonLabel'),
                      onClick: () => {
                        navigate(ROUTES.INGRESS_MEASUREMENT_DATA_UPLOAD);
                      },
                    }}
                    icon={<SvgMissing64 />}
                    headerColor={settings.colors.Operational.State_Notif_Grey_2}
                    subheaderColor={settings.colors.Operational.State_Notif_Grey_2}
                    secondaryButton={defaultCta}
                  />
                );
              case errorCodes.wearExplorer.predictionNotFound:
                return (
                  <MaintenancePanel
                    size="large"
                    heading={t('wear:errors.missingPredictions.heading')}
                    subHeading={t('wear:errors.missingPredictions.subHeading')}
                    icon={<SvgMissing64 />}
                    headerColor={settings.colors.Operational.State_Notif_Grey_2}
                    subheaderColor={settings.colors.Operational.State_Notif_Grey_2}
                    secondaryButton={defaultCta}
                  />
                );
              case errorCodes.common.entityNotFound:
                return (
                  <MaintenancePanel
                    size="large"
                    heading={t('reporting:errors.missingVessel.heading')}
                    subHeading={error.detail}
                    primaryButton={{ label: i18next.t('reporting:errors.missingVessel.primaryButtonLabel'), onClick: refresh }}
                    icon={<SvgMissing64 />}
                    headerColor={settings.colors.Operational.State_Notif_Grey_2}
                    subheaderColor={settings.colors.Operational.State_Notif_Grey_2}
                  />
                );
              case errorCodes.processData.vesselHasNoShortlist:
                return (
                  <MaintenancePanel
                    size="large"
                    heading={t('parameters-comparison:errors.missingParameters.heading')}
                    subHeading={error.detail}
                    secondaryButton={{ label: i18next.t('reporting:errors.missingVessel.primaryButtonLabel'), onClick: refresh }}
                    icon={<SvgMissing64 />}
                    headerColor={settings.colors.Operational.State_Notif_Grey_2}
                    subheaderColor={settings.colors.Operational.State_Notif_Grey_2}
                  />
                );
              default:
                return (
                  <MaintenancePanel
                    size="large"
                    heading={error.message || error.title}
                    subHeading={error.message || error.detail}
                    icon={<SvgMissing64 />}
                    headerColor={settings.colors.Operational.State_Notif_Grey_2}
                    subheaderColor={settings.colors.Operational.State_Notif_Grey_2}
                    secondaryButton={defaultCta}
                  />
                );
            }
          } else if (error instanceof Error) {
            switch (error.message) {
              case Invariants.NoVesselsFound:
                return (
                  <MaintenancePanel
                    size="large"
                    heading={t('reporting:errors.noVessels.heading')}
                    subHeading={t('reporting:errors.noVessels.subHeading')}
                    icon={<SvgMissing64 />}
                    headerColor={settings.colors.Operational.State_Notif_Grey_2}
                    subheaderColor={settings.colors.Operational.State_Notif_Grey_2}
                    secondaryButton={defaultCta}
                  />
                );
              case Invariants.NoMeasurementDataFound:
                return (
                  <MaintenancePanel
                    size="large"
                    heading={t('ingress:errors.noMeasurementData.heading')}
                    subHeading={t('ingress:errors.noMeasurementData.subheading')}
                    icon={<SvgMissing64 />}
                  />
                );
              case Invariants.NoRegionsSelected:
                return (
                  <MaintenancePanel
                    size="large"
                    heading={t('operator-display:errors.noSelectedRegions.heading')}
                    subHeading={
                      <Trans i18nKey="operator-display:errors.noSelectedRegions.subHeading">
                        <FlexContainer>Please talk to your technical marketing / PO to configure regions for you</FlexContainer>
                      </Trans>
                    }
                    icon={<SvgMissing64 />}
                    headerColor={settings.colors.Operational.State_Notif_Grey_2}
                    subheaderColor={settings.colors.Operational.State_Notif_Grey_2}
                  />
                );
              case Invariants.NoCampaignsFound:
                return (
                  <MaintenancePanel
                    size="large"
                    heading={t('parameters-comparison:errors.noCampaignsFound.heading')}
                    subHeading={t('parameters-comparison:errors.noCampaignsFound.subHeading')}
                    icon={<SvgMissing64 />}
                    headerColor={settings.colors.Operational.State_Notif_Grey_2}
                    subheaderColor={settings.colors.Operational.State_Notif_Grey_2}
                    secondaryButton={defaultCta}
                    data-test-id={noCampaignsFoundTitleErrorPanel}
                  />
                );
              case Invariants.NoPredictionsFound:
                return (
                  <MaintenancePanel
                    size="large"
                    heading={t('wear:errors.missingPredictions.heading')}
                    subHeading={t('wear:errors.missingPredictions.subHeading')}
                    icon={<SvgMissing64 />}
                    headerColor={settings.colors.Operational.State_Notif_Grey_2}
                    subheaderColor={settings.colors.Operational.State_Notif_Grey_2}
                    secondaryButton={defaultCta}
                  />
                );
              case Invariants.NoAccessToCustomer:
                return (
                  <MaintenancePanel
                    size="large"
                    heading={t('app:app.errors.noCustomerAccess')}
                    subHeading={error.message}
                    icon={<SvgMissing64 />}
                    headerColor={settings.colors.Operational.State_Notif_Grey_2}
                    subheaderColor={settings.colors.Operational.State_Notif_Grey_2}
                    secondaryButton={defaultCta}
                  />
                );
              case Invariants.NoPageFound:
                return (
                  <MaintenancePanel
                    size="large"
                    icon={<SvgAlert64 />}
                    heading={t('app:errors.404.heading')}
                    subHeading={t('app:errors.404.subHeading')}
                    primaryButton={{ label: t('app:errors.404.action'), onClick: goBack }}
                  />
                );
              default:
                return (
                  <MaintenancePanel
                    size="large"
                    heading={t('errorBoundary:errorMessage')}
                    subHeading={error.message}
                    icon={<SvgMissing64 />}
                    headerColor={settings.colors.Operational.State_Notif_Grey_2}
                    subheaderColor={settings.colors.Operational.State_Notif_Grey_2}
                    secondaryButton={defaultCta}
                  />
                );
            }
          } else {
            // This happens when non-error exceptions where thrown (e.g. 500/504 error codes).
            // Example: Test Company DEV, vessel CV03 mocked here: apps/apo-portal/proxy.conf.json.
            return (
              <MaintenancePanel
                size="large"
                heading={t('reporting:errors.unresponsiveBackend.heading')}
                subHeading={t('reporting:errors.unresponsiveBackend.subHeading')}
                // TODO: once https://dev.azure.com/RHIM/APO/_workitems/edit/46401 is fixed,
                // calling resetErrorBoundary() should be enough to retry the call that triggered the error boundary.
                // resetErrorBoundary();
                primaryButton={{ label: i18next.t('reporting:errors.unresponsiveBackend.primaryButtonLabel'), onClick: refresh }}
                icon={<SvgMissing64 />}
                headerColor={settings.colors.Operational.State_Notif_Grey_2}
                subheaderColor={settings.colors.Operational.State_Notif_Grey_2}
              />
            );
          }
        })()}
      </StyledMissingWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  flex: 2;
`;

const StyledMissingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const FlexContainer = styled.div`
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;
`;

function isWearManagementError(
  error: Error | APO.WearManagementAPI.WearExplorerView.ErrorResponse
): error is APO.WearManagementAPI.WearExplorerView.ErrorResponse {
  return !(error instanceof Error) && isDictionaryLike(error) && 'traceId' in error;
}

export default ErrorPanel;
