import { assert, isDefined } from '@rhim/utils';

import { LegendVisual } from '../legend';
import { ShapeType, ThemeConfig } from '../theme';
import Tooltip from './TooltipStyles';
import { TooltipValue } from './types';

export interface TooltipRowsProps<Datum> {
  belowThreshold?: (datum: Datum, key: string) => boolean;
  themeConfig: ThemeConfig;
  datum: Datum;
  keys: string[];
  label?: (datum: Datum, key: string, configLabel: string) => string;
  labelFormat?: (label: string, value: TooltipValue, key: string) => string | React.ReactNode;
  value: (datum: Datum, key: string) => TooltipValue;
  valueFormat?: (value: TooltipValue, key: string) => string | React.ReactNode;
}

function TooltipRowsComponent<Datum>(props: TooltipRowsProps<Datum>) {
  const { belowThreshold, datum, keys, label, labelFormat, themeConfig, value, valueFormat } = props;

  return (
    <>
      {keys.map((key) => {
        const config = themeConfig.datasets[key];
        assert(isDefined(config), `theme not found for ${key}`);

        const _value = isDefined(datum) ? value(datum, key) : '';
        const formattedValue = isDefined(valueFormat) ? valueFormat(_value, key) : _value;

        const configLabel = config.labelTooltip ?? config.label;
        const _label = isDefined(label) ? label(datum, key, configLabel) : configLabel;
        const formattedLabel = labelFormat ? labelFormat(_label, _value, key) : _label;

        const isBelowThreshold = isDefined(belowThreshold) && isDefined(datum) && belowThreshold(datum, key);
        const hasValue = isDefined(_value);

        return (
          (hasValue || isDefined(formattedValue)) && (
            <Tooltip.Row key={key} noData={!hasValue}>
              {config.type !== ShapeType.Text && <LegendVisual config={config} isAlert={isBelowThreshold} isDisabled={!hasValue} />}
              <Tooltip.Label>{formattedLabel}</Tooltip.Label>
              <Tooltip.Value>{formattedValue}</Tooltip.Value>
            </Tooltip.Row>
          )
        );
      })}
    </>
  );
}

export default TooltipRowsComponent;
