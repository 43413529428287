import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  backgroundColor?: Property.Color;
  foregroundColor?: Property.Color;
  strokeColor?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const QCKIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  backgroundColor,
  foregroundColor,
  strokeColor,
  size,
  title,
  role,
  ...rest
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill={backgroundColor} fillRule="evenodd" transform="translate(1 2)">
        <path d="M15 0L15 25.085 2.387 25.085 0 22.672 0 7.174 2.151 5 7.598 5 10.968 0z" />
        <path
          stroke={strokeColor}
          strokeWidth="2"
          d="M18.838 1c1.291 0 2.437.826 2.846 2.051L22.666 6H27c1.657 0 3 1.343 3 3v13c0 1.657-1.343 3-3 3H3c-1.657 0-3-1.343-3-3V9c0-1.657 1.343-3 3-3h4.333l.983-2.949C8.725 1.826 9.871 1 11.162 1h7.676z"
        />
        <path stroke={strokeColor} strokeLinecap="square" strokeWidth="2" d="M14 8c-3.866 0-7 2.91-7 6.5s3.134 6.5 7 6.5" />
        <circle cx="21.5" cy="15.5" r="1.5" fill={strokeColor} />
        <circle cx="19.5" cy="9.5" r="1.5" fill={strokeColor} />
        <circle cx="17.5" cy="13.5" r="1.5" fill={strokeColor} />
        <circle cx="19.5" cy="19.5" r="1.5" fill={strokeColor} />
        <path stroke={strokeColor} d="M15 9.5L19 9.5M15 13.5L19 13.5M15 19.5L19 19.5M15 17.5L17 17.5 19 15.5 21 15.5" />
        <circle cx="25" cy="9" r="1" fill={foregroundColor} />
      </g>
    </svg>
  );
};

QCKIcon.defaultProps = {
  size: '32',
  backgroundColor: 'none',
  foregroundColor: '#D90D0A',
  strokeColor: settings.colors.Primary.Grey_4,
};
