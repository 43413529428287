import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const SustainabilityFilledIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g id="Sustainability_Leaf_24">
        <path
          id="Vector"
          d="M21.788 4.70749C21.7652 4.56834 21.7073 4.43728 21.6199 4.32659C21.5326 4.21591 21.4185 4.12924 21.2884 4.0747C21.1547 4.01385 21.0072 3.98951 20.8609 4.00413C20.7147 4.01876 20.5749 4.07183 20.4558 4.15796C18.544 5.33794 16.3225 5.91797 14.0779 5.82321C8.96558 5.78158 6.81741 7.90477 6.77577 7.90477C5.93139 8.53078 5.2448 9.34527 4.77068 10.2834C4.29655 11.2215 4.04799 12.2574 4.04476 13.3085C4.03243 14.1863 4.19372 15.0578 4.51936 15.873C4.54811 15.947 4.55555 16.0276 4.54084 16.1056C4.52612 16.1836 4.48983 16.2559 4.4361 16.3143L2.24629 18.7289C2.16825 18.8063 2.10631 18.8984 2.06403 18.9999C2.02176 19.1013 2 19.2102 2 19.3201C2 19.43 2.02176 19.5388 2.06403 19.6403C2.10631 19.7417 2.16825 19.8338 2.24629 19.9112C2.39312 20.0506 2.58568 20.1317 2.78797 20.1394C2.99026 20.147 3.18841 20.0807 3.34536 19.9529C4.81078 18.6456 7.14213 14.7323 13.6782 11.993C13.7799 11.9503 13.889 11.9281 13.9993 11.9276C14.1096 11.9272 14.2188 11.9484 14.3209 11.9902C14.423 12.0319 14.5158 12.0933 14.5941 12.171C14.6724 12.2486 14.7347 12.3409 14.7773 12.4426C14.82 12.5443 14.8422 12.6534 14.8426 12.7636C14.8431 12.8739 14.8219 12.9832 14.7801 13.0852C14.7384 13.1873 14.6769 13.2801 14.5993 13.3584C14.5217 13.4368 14.4294 13.499 14.3277 13.5417C11.7111 14.6097 9.32719 16.1763 7.30866 18.1544L7.10883 18.3626C7.06633 18.4124 7.03595 18.4714 7.02007 18.535C7.00418 18.5985 7.00321 18.6649 7.01724 18.7289C7.02597 18.7906 7.04846 18.8496 7.08305 18.9015C7.11764 18.9534 7.16344 18.9968 7.21707 19.0287C8.28016 19.6945 9.50966 20.0466 10.7641 20.0445C12.4498 20.0111 14.1008 19.559 15.5683 18.7289C22.8371 14.7073 22.171 6.98056 21.788 4.70749Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

SustainabilityFilledIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
