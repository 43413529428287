import { SupportedLanguageUnicodeCode, toUnicodeLocale } from '@rhim/i18n';
import { FC } from 'react';

import { useLocalization } from '../hooks/useLocalization';

/**
 * @example
 *
 * ```js
 * const value = 23.324324;
 *
 * formatNumber(4)('en-US')(value); // => "23.32"
 * formatNumber(4)('de-DE')(value); // => "23,32"
 * ```
 */
export const formatNumber =
  (maximumFractionDigits: number, minimumFractionDigits?: number) =>
  (locale: SupportedLanguageUnicodeCode) =>
  (value: number): string =>
    new Intl.NumberFormat(locale, { maximumFractionDigits, minimumFractionDigits }).format(value);

/**
 * Returns a span containing a number formatted with the user's selected decimal separator (comma or dot)
 * @param value a number expressing length's value
 */
interface FormattedNumberProps {
  value: number;
}

export const FormattedNumber: FC<React.PropsWithChildren<FormattedNumberProps>> = ({ value }) => {
  const [localization] = useLocalization();
  const { locale } = localization;
  const unicodeLocale = toUnicodeLocale(locale);
  return <span>{formatNumber(3)(unicodeLocale)(value)}</span>;
};
