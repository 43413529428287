import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const FullscreenIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <g>
              <path
                d="M16 15c.256 0 .512.098.707.293L20 18.586V17c0-.553.448-1 1-1s1 .447 1 1v4c0 .553-.448 1-1 1h-4c-.552 0-1-.447-1-1 0-.553.448-1 1-1h1.586l-3.293-3.293c-.391-.391-.391-1.023 0-1.414.195-.195.45-.293.707-.293zm-8 0c.256 0 .512.098.707.293.39.39.39 1.024 0 1.414L5.414 20H7c.552 0 1 .447 1 1 0 .553-.448 1-1 1H3c-.13 0-.26-.026-.382-.077-.245-.102-.439-.296-.54-.541C2.025 21.26 2 21.13 2 21v-4c0-.553.448-1 1-1s1 .447 1 1v1.586l3.293-3.293C7.488 15.098 7.744 15 8 15zM7 2c.552 0 1 .447 1 1 0 .553-.448 1-1 1H5.414l3.293 3.293c.391.391.391 1.023 0 1.414s-1.023.391-1.414 0L4 5.414V7c0 .553-.448 1-1 1s-1-.447-1-1V3c0-.553.448-1 1-1zm14 0c.13 0 .26.026.382.077.245.102.439.296.54.541.052.122.078.252.078.382v4c0 .553-.448 1-1 1s-1-.447-1-1V5.414l-3.293 3.293c-.39.391-1.023.391-1.414 0-.39-.39-.39-1.024 0-1.414L18.586 4H17c-.552 0-1-.447-1-1 0-.553.448-1 1-1z"
                transform="translate(-1880 -68) translate(0 56) translate(1880 12)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

FullscreenIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
