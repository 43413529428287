import { RHIMLiningServiceV1ModelsLiningOverviewDto } from '@rhim/rest/linings';
import { specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api';

type Payload = RHIMLiningServiceV1ModelsLiningOverviewDto[];
type ErrorResponse = unknown;

export const useLinings = (
  vesselId: UUID,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, ErrorResponse, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) => {
  return useQuery(getKey(vesselId), (context) => queryFn(context, options?.axiosConfiguration), options?.configuration);
};

const getKey = (vesselId: UUID) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'linings',
      scope: 'all-linings',
      entity: 'list',
      vesselId,
    },
  ]);

const queryFn = ({ queryKey: [{ vesselId }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  return API.linings.getVesselsVesselidLinings(vesselId, { signal, ...axiosConfiguration }).then((response) => response.data);
};

useLinings.getKey = getKey;
useLinings.queryFn = queryFn;
