import { settings } from '@rhim/design';
import { Arc } from '@visx/shape';
import React from 'react';

const BACKGROUND_COLOR = settings.colors.Primary.Grey_1;
interface RegionArcProps {
  innerRadius?: number;
  outerRadius?: number;
  startAngle: number;
  endAngle: number;
  stroke: string;
}

/**
 * Draws the arc for the defined angles and radius.
 */
const ArcRegion: React.FunctionComponent<RegionArcProps> = ({ innerRadius, outerRadius, endAngle, startAngle, stroke }: RegionArcProps) => {
  return (
    <Arc
      startAngle={(startAngle * Math.PI) / 180}
      endAngle={startAngle > endAngle ? ((360 + endAngle) * Math.PI) / 180 : (endAngle * Math.PI) / 180}
      innerRadius={innerRadius ?? 0}
      outerRadius={outerRadius ?? 0}
      stroke={stroke}
      padAngle={0}
      fill={BACKGROUND_COLOR}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      {({ path }) => <path d={(path as any)()} fillOpacity={0.7} strokeWidth={2} fill={BACKGROUND_COLOR} stroke={stroke} />}
    </Arc>
  );
};

export default React.memo(ArcRegion);
