import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const TemperatureStability: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 5a5 5 0 0 1 10 0v9.277c.632.992 1 2.182 1 3.462C22 21.197 19.314 24 16 24s-6-2.803-6-6.26c0-1.28.368-2.471 1-3.463V11H8.732a2 2 0 0 1-3.464 0H3.732a2 2 0 1 1 0-2h1.536a2 2 0 0 1 3.464 0H11V5zm2 0a3 3 0 1 1 6 0v10.004c.622.743 1 1.718 1 2.786 0 2.325-1.79 4.21-4 4.21s-4-1.885-4-4.21c0-1.068.378-2.043 1-2.786V5zm3 4a1 1 0 0 0-1 1v6.268A2 2 0 0 0 16 20a2 2 0 0 0 1-3.732V10a1 1 0 0 0-1-1z"
        fill={fill}
      />
    </svg>
  );
};

TemperatureStability.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
