/* eslint-disable @typescript-eslint/no-empty-function */

/**
 * Used to denote an empty value in infrastructure/apo-portal/pipelines/apo-portal-cd.yml.
 */
export const PLACEHOLDER = '<placeholder>';

/**
 * Returns the LogRocket singleton or a no-op depending on the provided application ID.
 */
export function createLogRocket(appID: string): typeof import('logrocket') {
  if (appID === '' || appID === PLACEHOLDER) {
    const mock: typeof import('logrocket') = {
      init: function init() {},
      log: function log() {},
      info: function info() {},
      warn: function warn() {},
      error: function error() {},
      debug: function debug() {},
      identify: function identify() {},
      get sessionURL() {
        return '';
      },
      reduxMiddleware: function reduxMiddleware() {
        return function () {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return function (next: any) {
            return function (action: unknown) {
              return next(action);
            };
          };
        };
      },
      track: function track() {},
      getSessionURL: function getSessionURL() {},
      startNewSession: function startNewSession() {},
      captureMessage: function captureMessage() {},
      captureException: function captureException() {},
      version: undefined,
    };
    return mock;
  }

  return require('logrocket');
}
