import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const VesselAODIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.299 1.471a.997.997 0 0 0-1.31-.359L10.224 3.04A1 1 0 0 0 11 4.877v5.513a1 1 0 1 0 2 0V3.93c0-.021 0-.042-.002-.063l1.09-.558L18 9.586v5.217a9.985 9.985 0 0 1-1.84.157c-.946 0-2.42-.388-3.916-.782-1.542-.406-3.109-.818-4.144-.818-.888 0-1.59.083-2.1.178v-4.02L7.226 6.83l.681-.351a1 1 0 0 0-.916-1.778l-.99.51a.996.996 0 0 0-.467.51L4.09 8.884A1 1 0 0 0 4 9.3V12a1 1 0 0 0-2 0v3a1 1 0 1 0 2 0v1.79a1 1 0 0 0 .097.43l2.48 5.21a1 1 0 0 0 .903.57h9.04a1 1 0 0 0 .903-.57l2.48-5.21a1 1 0 0 0 .097-.43V15a1 1 0 1 0 2 0v-3a1 1 0 0 0-2 0V9.3a1 1 0 0 0-.151-.529l-4.55-7.3z"
      />
    </svg>
  );
};

VesselAODIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
