import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const ZoomOutIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M11 2c4.97 0 9 4.03 9 9 0 2.125-.736 4.078-1.968 5.617l3.675 3.676c.39.39.39 1.024 0 1.414-.39.39-1.024.39-1.414 0l-3.676-3.675C15.077 19.264 13.125 20 11 20c-4.97 0-9-4.03-9-9s4.03-9 9-9zm0 2c-3.866 0-7 3.134-7 7s3.134 7 7 7c1.89 0 3.606-.75 4.865-1.967l.078-.09c.028-.029.058-.055.089-.08C17.25 14.607 18 12.89 18 11c0-3.866-3.134-7-7-7zm3 6c.552 0 1 .448 1 1s-.448 1-1 1H8c-.552 0-1-.448-1-1s.448-1 1-1z"
      />
    </svg>
  );
};

ZoomOutIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
