import { useToggleState } from '@react-stately/toggle';
import { AriaToggleButtonProps } from '@react-types/button';
import type { AriaTextFieldProps } from '@react-types/textfield';
import { settings } from '@rhim/design';
import { EyeCrossedIcon, EyeIcon } from '@rhim/icons/24';
import { isDefined } from '@rhim/utils';
import { ConfigProvider } from 'antd';
import React, { cloneElement, ForwardedRef, useMemo } from 'react';
import { mergeProps, useFocus, useFocusRing, useHover, useId, useTextField, useToggleButton } from 'react-aria';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';

import { useAntdFormItemValidationState } from '../../hooks';
import { Feedback, FieldFeedback, FieldLabel } from '../../partials';
import { TooltipProps } from '../Tooltip';

interface Props extends Omit<AriaTextFieldProps, 'type'> {
  /**
   * Will be passed down to the outermost element.
   */
  className?: string;
  /**
   * The icon displayed on the left hand side.
   */
  icon?: React.ReactElement;
  /**
   * The IconButton displayed on the right hand side.
   */
  iconButton?: React.ReactElement;
  variant: 'x-small' | 'small' | 'medium' | 'big';
  /**
   * Will be passed down to the outermost element.
   */
  style?: React.CSSProperties;
  /**
   * Controls the title of the button displayed when type "password" is set.
   */
  visibilityToggleButtonTitle?: string;
  /**
   * Hint displayed below the field.
   */
  feedback?: Feedback;
  /**
   * If provided, the TextField's label will render instead within that DOM element
   */
  labelTargetPortalDOM?: HTMLDivElement;
  /**
   * If provided, the TextField's feedback will render instead within that DOM element
   */
  feedbackTargetPortalDOM?: HTMLDivElement;
  /**
   * If provided, the suffix will be shown.
   */
  suffix?: string;
  /**
   * If provided, a tooltip will be shown.
   */
  tooltip?: TooltipProps;
  /**
   * onBlur callback. used by antd for validateTrigger={'onBlur'} support
   */
  onBlur?: () => void;
  /**
   * onFocus callback
   */
  onFocus?: () => void;
  /**
   * Input's type
   */
  type?: 'password' | 'text' | 'number';
}

interface Referable {
  ref?: React.Ref<HTMLDivElement>;
}

const TextField: React.ChildlessComponent<Props & Referable> = React.forwardRef((props, forwardedRef: ForwardedRef<HTMLDivElement>) => {
  const {
    label,
    variant,
    isRequired = false,
    suffix,
    className,
    autoFocus,
    icon,
    iconButton,
    style,
    visibilityToggleButtonTitle,
    feedback,
    isDisabled = false,
    isReadOnly = false,
    labelTargetPortalDOM,
    feedbackTargetPortalDOM,
    tooltip,
    onBlur,
    onFocus,
    type = 'text',
  } = props;
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { labelProps, inputProps } = useTextField(props, inputRef);
  const { isFocused, focusProps: focusRingProps } = useFocusRing({ isTextInput: true, autoFocus });
  const { componentDisabled } = ConfigProvider.useConfig();
  const mergedDisabled = (componentDisabled ?? false) || isDisabled;
  const { focusProps } = useFocus({
    onFocusChange: (hasFocus) => {
      if (!hasFocus) {
        onBlur?.();
      } else {
        onFocus?.();
      }
    },
  });
  const [isMasked, setIsMasked] = React.useState(() => type === 'password');
  const feedbackElementId = useId();
  const mergedFocusRelatedProps = mergeProps(focusRingProps, focusProps);

  const toggleVisibility = React.useCallback(() => {
    setIsMasked(!isMasked);
  }, [isMasked]);

  const iconSize = React.useMemo(() => {
    switch (variant) {
      case 'big':
        return 24;
      case 'medium':
      case 'small':
      case 'x-small':
        return 16;
    }
  }, [variant]);

  const labelJSX = useMemo(() => {
    return isDefined(label) ? <FieldLabel text={label} isRequired={isRequired} isDisabled={isDisabled} labelProps={labelProps} tooltip={tooltip} /> : null;
  }, [labelProps, label, isRequired, isDisabled, tooltip]);

  const feedbackJSX = useMemo((): JSX.Element | null => {
    return isDefined(feedback) ? <FieldFeedback feedback={feedback} feedbackElementId={feedbackElementId} /> : null;
  }, [feedback, feedbackElementId]);

  const iconWithProps = isDefined(icon) ? cloneElement(icon, { fill: 'currentColor' }) : undefined;

  return (
    <ComponentContainer ref={forwardedRef} className={className} style={style}>
      {labelTargetPortalDOM ? ReactDOM.createPortal(labelJSX, labelTargetPortalDOM) : labelJSX}
      <InputWrapper
        className="rhim-textfield-input-wrapper"
        variant={variant}
        isFocused={isFocused}
        isDisabled={mergedDisabled}
        isReadOnly={isReadOnly}
        isInvalid={props.validationState === 'invalid'}
      >
        {isDefined(icon) && (
          <IdentityIconContainer variant={variant}>{isDefined(iconWithProps) && <IconWrapper>{iconWithProps}</IconWrapper>}</IdentityIconContainer>
        )}
        <Input
          {...inputProps}
          value={inputProps.value ?? ''} // the input field expends to have empty string instead of null/undefined values, otherwise won't update.
          {...mergedFocusRelatedProps}
          type={type}
          variant={variant}
          ref={inputRef}
          disabled={isReadOnly || mergedDisabled}
          aria-describedby={isDefined(feedback) ? feedbackElementId : undefined}
          aria-errormessage={props.validationState === 'invalid' && isDefined(feedback) ? feedbackElementId : undefined}
        />
        {isDefined(suffix) && <Suffix>{suffix}</Suffix>}
        {isMasked && (
          <ToggleVisibilityButton
            title={visibilityToggleButtonTitle ?? ''}
            onPress={toggleVisibility}
            isSelected={isMasked}
            css={css`
              margin-right: ${props.variant === 'x-small' ? 4 : iconSize}px;
            `}
          />
        )}
        {isDefined(iconButton) && <IconRightWrapper variant={variant}>{iconButton}</IconRightWrapper>}
      </InputWrapper>
      {feedbackTargetPortalDOM ? ReactDOM.createPortal(feedbackJSX, feedbackTargetPortalDOM) : feedbackJSX}
    </ComponentContainer>
  );
});

TextField.displayName = 'TextField';

const Suffix = styled.span`
  margin-right: ${settings.Spacing.Spacing_100};
  font-size: ${settings.typography.FontSize.Small};
  color: ${settings.colors.Primary.Grey_4};
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  color: ${settings.colors.Primary.Grey_8};
`;

const IconRightWrapper = styled(IconWrapper)<Pick<Props, 'variant'>>`
  padding: ${(props) => (props.variant === 'x-small' ? 0 : settings.Spacing.Spacing_50)};
  padding-left: 0;
`;

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const IdentityIconContainer = styled.div<Pick<Props, 'variant'>>((props) => {
  const size: React.CSSProperties['width'] & React.CSSProperties['height'] = (() => {
    switch (props.variant) {
      case 'big':
        return '24px';
      case 'medium':
      case 'small':
      case 'x-small':
        return '16px';
    }
  })();

  const marginLeft: React.CSSProperties['marginLeft'] = (() => {
    switch (props.variant) {
      case 'big':
        return settings.Spacing.Spacing_300;
      case 'medium':
      case 'small':
        return settings.Spacing.Spacing_200;
      case 'x-small':
        return settings.Spacing.Spacing_100;
    }
  })();

  return css`
    flex-shrink: 0;
    width: ${size};
    height: ${size};
    display: grid;
    place-items: center;
    margin-left: ${marginLeft};
  `;
});

const InputWrapper = styled.div<{ isFocused: boolean; isDisabled: boolean; isReadOnly: boolean; isInvalid: boolean } & Pick<Props, 'variant'>>((props) => {
  const height: React.CSSProperties['height'] = (() => {
    switch (props.variant) {
      case 'big':
        return '56px';
      case 'medium':
        return '48px';
      case 'small':
        return '40px';
      case 'x-small':
        return '32px';
    }
  })();

  const borderColor: React.CSSProperties['borderColor'] = (() => {
    if (props.isInvalid) {
      return settings.colors.Operational.State_Notif_Magenta_2;
    } else if (props.isReadOnly) {
      return settings.colors.Primary.Grey_2;
    }
    return settings.colors.Primary.Grey_3;
  })();

  const backgroundColor: React.CSSProperties['backgroundColor'] = (() => {
    if (props.isDisabled) {
      return settings.colors.Primary.Grey_1;
    } else if (props.isReadOnly) {
      return settings.colors.Primary.Grey_2;
    }
    return settings.colors.Monochromatic.White;
  })();

  return css`
    height: ${height};
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-width: 1px;
    border-style: ${props.isDisabled ? 'dashed' : 'solid'};
    border-color: ${borderColor};
    background-color: ${backgroundColor};
    border-radius: 3px;
    overflow: hidden;
    ${props.isFocused &&
    !props.isInvalid &&
    css`
      border-color: ${settings.colors.Primary.Blue_9};
    `};
  `;
});

const Input = styled.input<Pick<Props, 'variant'>>((props) => {
  const fontSize: settings.typography.FontSize = (() => {
    switch (props.variant) {
      case 'big':
        return settings.typography.FontSize.Large;
      case 'medium':
      case 'small':
        return settings.typography.FontSize.Medium;
      case 'x-small':
        return settings.typography.FontSize.Small;
    }
  })();

  const padding: React.CSSProperties['padding'] = (() => {
    switch (props.variant) {
      case 'big':
        return '24px';
      case 'medium':
      case 'small':
        return '16px';
      case 'x-small':
        return '8px';
    }
  })();

  return css`
    width: 100%;
    height: 100%;
    padding: ${padding};
    font-family: ${settings.typography.FontFamily.Regular};
    font-size: ${fontSize};
    color: ${settings.colors.Primary.Blue_9};
    background-color: inherit;
    outline: none;
    border: none;

    &::placeholder {
      color: ${settings.colors.Primary.Grey_4};
    }

    &:disabled {
      color: ${settings.colors.Primary.Grey_4};
      cursor: not-allowed;
    }
  `;
});

const ToggleVisibilityButton: React.FunctionComponent<
  React.PropsWithChildren<AriaToggleButtonProps & Pick<Require<React.HTMLAttributes<HTMLButtonElement>, 'title'>, 'className' | 'style' | 'title'>>
> = (props) => {
  const ref = React.useRef<HTMLButtonElement>(null);
  const state = useToggleState(props);
  const { buttonProps, isPressed } = useToggleButton(props, state, ref);
  const { hoverProps, isHovered } = useHover({ isDisabled: props.isDisabled });

  return (
    <button
      {...buttonProps}
      {...hoverProps}
      title={props.title}
      className={props.className}
      style={props.style}
      css={css`
        all: unset;
        height: 24px;
        width: 24px;
      `}
      ref={ref}
    >
      {state.isSelected ? (
        <EyeIcon fill={isPressed || isHovered ? settings.colors.Primary.Blue_8 : settings.colors.Primary.Blue_9} />
      ) : (
        <EyeCrossedIcon fill={isPressed || isHovered ? settings.colors.Primary.Blue_8 : settings.colors.Primary.Blue_9} />
      )}
    </button>
  );
};

TextField.whyDidYouRender = true;

const TextFieldWithValidationState: React.ChildlessComponent<React.ComponentProps<typeof TextField>> = (props) => {
  const validationState = useAntdFormItemValidationState();

  return <TextField validationState={validationState} {...props} />;
};
TextFieldWithValidationState.displayName = 'TextFieldWithValidationState';

export default React.memo(TextFieldWithValidationState);
export { TextField as TextFieldDefault };
