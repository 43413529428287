import { mergeRefs } from '@react-aria/utils';
import { settings } from '@rhim/design';
import { useMemoCompare } from '@rhim/react';
import { VesselType } from '@rhim/rest';
import { RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel } from '@rhim/rest/operatorDisplay';
import { RHIMAPOReportingWearManagementApiV1ModelsCampaignOverviewDto, RHIMContractsRegionLocation } from '@rhim/rest/wearManagement';
import { useVesselProperties } from '@rhim/sdk/customerManagement/hooks/useVesselProperties';
import { allRegionsContainerOperatorDisplay, operatorDisplayContainerOperatorDisplay } from '@rhim/test-ids';
import { assert, DivWithSpinnerWhenEmpty, hasElements, isDefined } from '@rhim/utils';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import axios from 'axios';
import * as React from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { useMeasure } from 'react-use';
import styled from 'styled-components';

import { AreasSelectionPanel } from '../../components/AreasSelectionPanel/AreasSelectionPanel';
import ErrorPanel from '../../components/ErrorPanel/ErrorPanel';
import MeasurementsTable from '../../components/MeasurementsTable/MeasurementsTable';
import OperatorDisplayStrip from '../../components/OperatorDisplayStrip/OperatorDisplayStrip';
import OperatorDisplayContainer from '../../components/WmsDisplayContainer/WmsDisplayContainer';
import { Invariants } from '../../errors';
import { useOperatorDisplayCampaigns, useRegionsOrder, useSelectedCampaign, useWMSData } from '../../hooks';
import { AreaContext } from './AreaContext';
import { HeatWarningModal } from './HeatWarningModal';
import Legend from './Legend';
import OperatorDisplayErrorPage from './OperatorDisplayErrorPage';
import { useAcknowledgment } from './useAcknowledgement';
import { useHeatWarning } from './useHeatWarning';
import { useHiddenNavigation } from './useHiddenNavigation';

interface Props {
  vessels: APO.VesselV2[];
  vessel: APO.VesselV2;
  setSelectedVesselId: (value: UUID) => void;
}

export const OperatorDisplay: React.FunctionComponent<Props> = ({ vessel, vessels, setSelectedVesselId }) => {
  const vesselId = vessel.id;
  const { reset } = useQueryErrorResetBoundary();

  const { data: campaigns } = useOperatorDisplayCampaigns(vesselId);
  //Format campaigns to match with the expected format in the useSelectedCampaign hook & select campaigns cmp.
  const formattedCampaigns: RHIMAPOReportingWearManagementApiV1ModelsCampaignOverviewDto[] = (campaigns ?? []).map((campaign) => ({
    id: campaign,
    displayText: campaign.toString(),
    vesselId: vesselId,
  }));

  const [selectedCampaign, setSelectedCampaign] = useSelectedCampaign(formattedCampaigns as RHIM.Campaign[]);

  const FallbackComponent: React.FunctionComponent<FallbackProps> = React.useCallback(
    ({ error, resetErrorBoundary }) => {
      return (
        <OperatorDisplayErrorPage
          error={axios.isAxiosError(error) ? error.response?.data : error}
          resetErrorBoundary={resetErrorBoundary}
          vessels={vessels}
          setSelectedVesselId={setSelectedVesselId}
          vessel={vessel}
          setSelectedCampaign={setSelectedCampaign}
          selectedCampaign={selectedCampaign}
          campaigns={formattedCampaigns}
        />
      );
    },
    [vessel, formattedCampaigns, selectedCampaign, setSelectedCampaign, vessels, setSelectedVesselId]
  );

  return (
    <ErrorBoundary FallbackComponent={FallbackComponent} resetKeys={[vesselId]} onReset={reset}>
      <Inner
        vessels={vessels}
        vessel={vessel}
        setSelectedVesselId={setSelectedVesselId}
        setSelectedCampaign={setSelectedCampaign}
        selectedCampaign={selectedCampaign}
        campaigns={formattedCampaigns}
      />
    </ErrorBoundary>
  );
};

interface PropsWithCampaigns extends Props {
  selectedCampaign: number;
  setSelectedCampaign: (value: number) => void;
  campaigns: RHIMAPOReportingWearManagementApiV1ModelsCampaignOverviewDto[];
}

const Inner: React.FunctionComponent<PropsWithCampaigns> = ({ vessel, vessels, setSelectedVesselId, selectedCampaign, setSelectedCampaign, campaigns }) => {
  const [hidden, hide] = useHiddenNavigation();
  const [compareMode, setCompareMode] = React.useState(false);

  const containerRef = React.useRef<HTMLElement>(document.body);
  const vesselId = vessel.id;
  const { vesselType, expectedHeats } = vessel;

  const [measureRef, { width }] = useMeasure();
  const { data } = useRegionsOrder(vesselId);

  // @ts-expect-error (These callbacks are contravariant)
  const combinedRef = mergeRefs(measureRef, containerRef);

  const [tableView, toggleTableView] = React.useState(false);
  const [graphView, toggleGraphView] = React.useState(true);

  const [comparedCampaign, setComparedCampaign] = React.useState<number | undefined>();

  const toggleFullscreen = React.useCallback(() => {
    hide(!hidden);
  }, [hide, hidden]);

  const { data: vesselProperties } = useVesselProperties(vesselType, vesselId);
  const { data: wmsData } = useWMSData(vesselId, isDefined(selectedCampaign) ? selectedCampaign.toString() : undefined);
  const { data: comparedData } = useWMSData(vesselId, isDefined(comparedCampaign) ? comparedCampaign.toString() : undefined, {
    configuration: { enabled: isDefined(comparedCampaign) && compareMode },
  });

  assert(isDefined(wmsData), 'In Suspense mode data should always be defined, according to react-query docs.');

  const [hasAcknowledged, setHasAcknowledged] = useAcknowledgment(vesselId);
  useHeatWarning(wmsData.isHeatMappingInvalidOrMissing, hasAcknowledged, vesselId, () => containerRef.current);

  const { setAreaSelectionsVisible, areaSelectionsVisible } = React.useContext(AreaContext);
  let regions = useMemoCompare(wmsData.regions, (previous, next) => {
    return JSON.stringify(previous) === JSON.stringify(next);
  });

  let comparedRegions = useMemoCompare(isDefined(comparedData) ? comparedData.regions : [], (previous, next) => {
    return JSON.stringify(previous) === JSON.stringify(next);
  });

  if ((vesselType === VesselType.Ladle || vesselType === VesselType.Eaf) && isDefined(regions)) {
    regions = regions.filter(
      (region) => region.regionLocation === RHIMContractsRegionLocation.Barrel || region.regionLocation === RHIMContractsRegionLocation.Bottom
    );
    comparedRegions = comparedRegions.filter(
      (region) => region.regionLocation === RHIMContractsRegionLocation.Barrel || region.regionLocation === RHIMContractsRegionLocation.Bottom
    );
  }

  const handleSelect = React.useCallback(
    (id: UUID) => {
      if (vesselId !== id) {
        setSelectedVesselId(id);
      }
    },
    [vesselId, setSelectedVesselId]
  );
  const getComparedData = React.useCallback(
    (index: number) => (comparedCampaign !== selectedCampaign && compareMode ? comparedRegions[index] ?? undefined : undefined),
    [comparedCampaign, selectedCampaign, compareMode, comparedRegions]
  );

  return hasElements(vessels) || isDefined(regions) || isDefined(wmsData) ? (
    <Container data-test-id={operatorDisplayContainerOperatorDisplay} ref={combinedRef as React.MutableRefObject<HTMLDivElement>} fullscreen={hidden}>
      {hasElements(vessels) && isDefined(wmsData) && (
        <OperatorDisplayStrip
          compareMode={compareMode}
          toggleCompareMode={setCompareMode}
          toggleGraphView={toggleGraphView}
          isGraphView={graphView}
          isTableView={tableView}
          toggleTableView={toggleTableView}
          settingsDisabled={!(isDefined(data) && data.length > 0)}
          toggleSettings={() => setAreaSelectionsVisible(!areaSelectionsVisible)}
          toggleFullscreen={toggleFullscreen}
          isFullscreen={hidden}
          data={wmsData}
          vessels={vessels}
          campaigns={campaigns}
          selectedVesselId={vesselId}
          onSelect={handleSelect}
          disabled={hasElements(vessels) && wmsData.lastHeat === null}
          campaignId={selectedCampaign}
          comparedCampaign={comparedCampaign}
          setSelectedCampaignsId={(first: number, second?: number) => {
            setSelectedCampaign(first);
            setComparedCampaign(second);
          }}
        />
      )}
      <div>
        {isDefined(data) && Boolean(data.length) && (
          <AreasSelectionPanel
            data={data}
            vesselId={vesselId}
            visible={areaSelectionsVisible}
            onClose={setAreaSelectionsVisible}
            campaignId={selectedCampaign}
            comparedCampaign={comparedCampaign}
          />
        )}
        {isDefined(data) && hasElements(data) && isDefined(regions) && !hasElements(regions) ? (
          <ErrorPanel error={new Error(Invariants.NoRegionsSelected)} />
        ) : (
          <>
            {!hidden && !tableView && <Legend comparedCampaign={comparedCampaign} compareMode={compareMode} selectedCampaign={selectedCampaign} />}
            <WmsContainer data-test-id={allRegionsContainerOperatorDisplay}>
              {isDefined(regions) && hasElements(regions) && isDefined(wmsData) && (
                <>
                  {graphView ? (
                    regions.map((area, index: number) => (
                      <OperatorDisplayContainer
                        enableKPIsInWms={isDefined(vesselProperties) && 'enableKPIsInWms' in vesselProperties ? vesselProperties.enableKPIsInWms : true}
                        expectedHeats={expectedHeats}
                        key={index}
                        region={area}
                        parentWidth={width}
                        targetLifeTime={wmsData.targetLifeTime}
                        lastHeat={wmsData.lastHeat ?? 0}
                        lastTotalHeat={wmsData.lastTotalHeat ?? undefined}
                        compareMode={compareMode}
                        comparedRegion={getComparedData(index)}
                      />
                    ))
                  ) : (
                    <MeasurementsTable
                      lastTotalHeat={wmsData.lastTotalHeat ?? undefined}
                      lastMeasurementTimestamp={wmsData.lastMeasurementTimestamp ?? undefined}
                      regions={regions as NonEmptyArray<RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel>}
                      lastHeat={wmsData.lastHeat ?? 0}
                    />
                  )}
                </>
              )}
            </WmsContainer>
          </>
        )}
        <HeatWarningModal visible={wmsData.isHeatMappingInvalidOrMissing && !hasAcknowledged} onConfirm={setHasAcknowledged} />
      </div>
    </Container>
  ) : (
    <DivWithSpinnerWhenEmpty />
  );
};

const Container = styled.div<{ fullscreen: boolean }>`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  background-color: ${settings.colors.Primary.Grey_1};
  height: ${(props) => (props.fullscreen ? '100%' : 'auto')};
`;

const WmsContainer = styled(DivWithSpinnerWhenEmpty)`
  display: flex;
  position: relative;
  justify-content: start;
  flex-wrap: wrap;
  background: ${settings.colors.Primary.Grey_1};
`;

OperatorDisplay.whyDidYouRender = true;

export default React.memo(OperatorDisplay);
