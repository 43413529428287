import { Color3, Scene } from '@babylonjs/core';
import { CustomMaterial } from '@babylonjs/materials';

export class VolumeMaterial extends CustomMaterial {
  private volumeLinePosition: number;

  public constructor(name: string, scene: Scene, volumeLinePosition: number) {
    super(name, scene);
    this.volumeLinePosition = volumeLinePosition;
    this.backFaceCulling = false;
    this.setupMaterial();
    this.backFaceCulling = false;
    this.diffuseColor = Color3.White();
  }

  private setupMaterial() {
    this.Fragment_Definitions(`
      #define HALF_LINE_WIDTH .005
    `);

    this.Fragment_Custom_Diffuse(`
        // vec4 underVolumeColor = mix(baseColor, vec4(0.79, 0.85, 0.93, 1.0), 0.4);
        vec4 underVolumeColor = vec4(0.79, 0.85, 0.93, 1.0);
        float isInVolumeZone = step(vPositionW.z, volumeLinePosition);
        baseColor = mix(baseColor, underVolumeColor, isInVolumeZone);

        float smoothness = fwidth(vPositionW.z);
        float e0 = volumeLinePosition - HALF_LINE_WIDTH;
        float e1 = volumeLinePosition + HALF_LINE_WIDTH;
        float lineFactor = smoothstep(e0 - smoothness, e0 + smoothness, vPositionW.z) -
                           smoothstep(e1 - smoothness, e1 + smoothness, vPositionW.z);
        baseColor = mix(baseColor, vec4(0.23, 0.33, 0.4, 1.0), lineFactor);
    `);

    this.AddUniform('volumeLinePosition', 'float', this.volumeLinePosition);
  }
}
