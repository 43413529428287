import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const PenIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <g>
              <path
                d="M19.403 7.444l-9.234 9.309-2.842-2.865 9.234-9.308c.76-.765 2.083-.765 2.842 0 .379.383.588.891.588 1.432 0 .54-.21 1.049-.588 1.432zM6.146 15.562l2.346 2.364-3.764 1.361 1.418-3.725zM20.824 3.148c-1.52-1.53-4.165-1.53-5.683 0L4.872 13.498c-.1.1-.177.22-.227.354l-2.578 6.773c-.141.37-.055.788.22 1.07.191.2.453.305.718.305.113 0 .228-.02.34-.06l6.84-2.476c.138-.05.266-.131.37-.237L20.824 8.876C21.582 8.11 22 7.093 22 6.012c0-1.082-.418-2.1-1.176-2.864z"
                transform="translate(-804.000000, -381.000000) translate(565.000000, 349.000000) translate(239.000000, 32.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

PenIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
