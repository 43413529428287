/**
 * TODO: move one level higher to constants
 */
export enum Defaults {
  Brand_Logo_Width = '110px',
  Brand_Logo_Height = '66px',
  Product_Logo_Height = '74px',
  Outer_Container_Margin = '64px',
}

/**
 * Standard color used by browsers to indicate focus position.
 */
export const FOCUS_RING_COLOR = '#005fcc';
