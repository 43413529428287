import { HttpTransportType, HubConnection, HubConnectionBuilder, RetryContext } from '@microsoft/signalr';
import { Logger } from '@rhim/logging';
import { ensure, isDefined } from '@rhim/utils';
import React, { useEffect, useRef, useState } from 'react';

type MessageHandler<T> = React.Dispatch<React.SetStateAction<T | undefined>>;

export enum WebSocketState {
  Cold,
  Active,
  Error,
  Stopped,
}

interface Subscribers<T> {
  subscribers: MessageHandler<T>[];
  lastMessage: T | undefined;
}

const webSockets: {
  [endpoint: string]: {
    ws: WebSocketConnection;
  };
} = {};

const loggerContext = 'useSignalR';
const logger = isDefined(globalThis.log)
  ? globalThis.log.child(loggerContext) // re-use the application's logger to inherit the same configuration like the log level threshold
  : new Logger({ context: loggerContext, logLevel: 'debug' }); // if no logger is available, all logs that are >=debug will be printed

/**
 * if there is no websocket connection yet, we instantiate and start it.
 * Note: we don't stop the connection as soon as the effect cleans up, but rather leave it running throughout the application
 * since the WebSocket connection is a crucial pillar of the data transfer and starting/stopping it would only result in a lag
 */
function getWebSocketConnection(endpoint: string): WebSocketConnection {
  if (isDefined(webSockets[endpoint])) {
    return ensure(webSockets[endpoint]).ws; // return existing websocket connection
  }

  // create a new websocket connection which will be used as a singleton for all connections to this endpoint
  const ws = new WebSocketConnection(endpoint);
  webSockets[endpoint] = { ws };

  // add re-connection behavior
  ws.addEventHandler('DisconnectSoon', () => {
    // most of the time when the backend is going to disconnect us, it is because the access token has expired (or is about to expire)!
    // In this case, we're forcing a token renewal. This has the effect that when the backend actually disconnects us a few moments later, the
    // automatic reconnection will already use thesde new token
    logger.debug('Socket will be disconnected soon (force-closed by the backend. This may have been caused by an expired access token');
  });

  // once a websocket connection has been established, it will stay open.
  // there's intentionally no behavior for closing it at a certain point, since websockets should be used as a constant stream of data instead of a one-time data transfer
  // given this assumption, it's just more efficient to leave the connection open instead of constantly starting/stoping it
  ws.start();

  return ws;
}

interface Hook<T> {
  lastMessage: T | undefined;
  sendMessage: (data: unknown) => void; // the data that is sent upstream is not required to be the same type that is sent downstream
  state: WebSocketState;
}

/**
 * Custom Hook for listening to WebSocket messages from the backend using SignalR
 * @param eventName the name of the event that should be subscribed to
 * @param endpoint the path to the SignalR/WebSocket endpoint that should be used for establishing the connection
 * @returns the last message that was received for the given event
 */
export const useSignalR = <T>(eventName: string, endpoint: string): Hook<T> => {
  const connection = useRef<WebSocketConnection>(getWebSocketConnection(endpoint));
  const [lastMessage, setLastMessage] = useState(connection.current.events[eventName]?.lastMessage);
  const sendMessage = React.useCallback(
    (data: unknown) => {
      connection.current.sendMessage(eventName, data);
    },
    [eventName]
  );

  useEffect(() => {
    // add event listener
    connection.current.addEventHandler<T>(eventName, setLastMessage);

    const subscribedConnection = connection.current;

    return () => {
      // cleanup event listener
      subscribedConnection.removeEventHandler(eventName, setLastMessage);
    };
  }, [eventName, endpoint]);

  return { lastMessage, sendMessage, state: connection.current.state };
};

class WebSocketConnection {
  connection: HubConnection;
  events: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [eventName: string]: Subscribers<any>;
  } = {};

  state = WebSocketState.Cold;

  constructor(path: string) {
    const buildConnectionWithTokenAndReconnect = () =>
      new HubConnectionBuilder()
        .withUrl(path, { transport: HttpTransportType.WebSockets })
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: (retryContext: RetryContext) => {
            logger.debug(`[WS] Automatically Reconnecting. Retry #: ${retryContext.previousRetryCount + 1}`);
            return 6000;
          },
        })
        .build();

    this.connection = buildConnectionWithTokenAndReconnect();
  }

  start() {
    this.connection
      .start()
      .then(() => {
        logger.debug('[WS] connection started');
        this.state = WebSocketState.Active;
      })
      .catch((error) => {
        logger.error(`[WS] ${error.toString()}`, error);
        this.state = WebSocketState.Error;
        return error;
      });
  }

  stop() {
    logger.debug(`[WS] stopped`);
    this.connection.stop();
    this.state = WebSocketState.Stopped;
  }

  addEventHandler<T>(eventName: string, handler: MessageHandler<T>) {
    if (!isDefined(this.events[eventName])) {
      // create a new event if it doesn't exist yet
      const event: Subscribers<T> = { lastMessage: undefined, subscribers: [] };
      this.events[eventName] = event;
      this.connection.on(eventName, (arg: T) => {
        logger.info(`[WS] received ${eventName} with payload`, arg);
        event.lastMessage = arg;
        event.subscribers.forEach((subscriber) => subscriber(arg));
      });
    }

    // register new event handler
    this.events[eventName]?.subscribers.push(handler);
  }

  removeEventHandler<T>(eventName: string, listener: MessageHandler<T>) {
    // upon cleanup we're going to remove the subscriber.
    // Note: upon removing the last subscriber, we're also going to remove the event handler for that event.

    const subscribers = this.events[eventName]?.subscribers ?? [];

    const subscriberIndex = subscribers.findIndex((s) => s === listener);
    if (subscriberIndex > -1) {
      subscribers.splice(subscriberIndex, 1);
      logger.info(`Cleaning up listener for event ${eventName}`);
    }

    // unsubscribe from event if no subscribers are left
    if (subscribers.length === 0) {
      logger.info(`Unsubscribing from event ${eventName}`);
      this.connection.off(eventName);
      delete this.events[eventName];
    }
  }

  sendMessage(eventName: string, payload: unknown) {
    if (this.state !== WebSocketState.Active) {
      logger.error(`[WS] attempting to send a message (${eventName}) before the connection has been established. This message will be discarded`);
    } else {
      logger.info(`[WS] sending ${eventName}`, payload);
      this.connection.send(eventName, payload);
    }
  }
}
