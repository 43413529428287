import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const InfoIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 13H6a1 1 0 1 1 0-2h1V9a1 1 0 1 1 0-2h1a1 1 0 0 1 1 1v3h1a1 1 0 1 1 0 2M8 3a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3m0-3a8 8 0 1 0 0 16A8 8 0 0 0 8 0"
        fill={fill}
      />
    </svg>
  );
};

InfoIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
