import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  height?: Property.Height;
  width?: Property.Width;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const ChevronUpIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ height, width, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 25" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M7.076 14.383c.155.374.52.617.924.617h8c.404 0 .77-.243.924-.617.154-.374.07-.804-.217-1.09l-4-4C12.512 9.098 12.257 9 12 9c-.256 0-.512.098-.707.293l-4 4c-.286.286-.372.716-.217 1.09"
      />
    </svg>
  );
};

ChevronUpIcon.defaultProps = {
  height: '25',
  width: '24',
  fill: settings.colors.Primary.Blue_9,
};
