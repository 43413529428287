import { settings } from '@rhim/design';
import { PlusIcon } from '@rhim/icons/16';
import { isDefined } from '@rhim/utils';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import * as Workarounds from '../../utils/workarounds';
import { Button } from '../Button/Button';
import { Tooltip } from '../Tooltip';

const DISABLED_COLOR = settings.colors.Primary.Grey_4;

interface Props {
  dataTestId?: string;
  isDisabled?: boolean;
  cardTooltip?: React.ReactNode;
  prefixIcon?: { icon: React.ReactElement; tooltip: string };
  icon: React.ReactNode;
  buttonText: string;
  title: string;
  contentText?: string;
  onClick: () => void;
  width?: number;
  height?: number;
}

const DataBox: React.ChildlessComponent<Props> = ({
  dataTestId,
  isDisabled = false,
  cardTooltip,
  prefixIcon,
  icon,
  buttonText,
  title,
  contentText,
  onClick,
  width,
  height,
}) => {
  const coloredPrefixIcon = useMemo(() => {
    if (!isDefined(prefixIcon)) {
      return undefined;
    }
    const clonedIconWithProps = React.cloneElement(prefixIcon.icon, {
      fill: isDisabled ? settings.colors.Primary.Grey_4 : settings.colors.Primary.Blue_9,
    });
    return clonedIconWithProps;
  }, [prefixIcon, isDisabled]);

  return (
    <Tooltip title={cardTooltip} overlayInnerStyle={{ width: 'fit-content' }}>
      <Container data-test-id={dataTestId} width={width} height={height}>
        <HeaderContainer>
          {isDefined(prefixIcon) && (
            <>
              <Tooltip title={prefixIcon.tooltip}>{coloredPrefixIcon}</Tooltip>
              <SIconMargin />
            </>
          )}
          <Header isDisabled={isDisabled}>{title}</Header>
          {icon}
        </HeaderContainer>
        <Content>
          <Paragraph isDisabled={isDisabled}>{contentText}</Paragraph>
        </Content>
        <SButton label={buttonText} icon={{ icon: <PlusIcon />, position: 'end' }} mode="filled" onClick={onClick} disabled={isDisabled} />
      </Container>
    </Tooltip>
  );
};

DataBox.whyDidYouRender = true;
export default React.memo(DataBox);

const Container = styled.div<{ width?: number; height?: number }>`
  width: ${(props) => props.width ?? 405}px;
  height: ${(props) => props.height ?? 232}px;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${settings.colors.Primary.Grey_5};
  background-color: ${settings.colors.Monochromatic.White};
  padding: ${settings.Spacing.Spacing_400} ${settings.Spacing.Spacing_500};
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SIconMargin = styled.span`
  width: ${settings.Spacing.Spacing_100};
`;

const Header = styled.h3<{ isDisabled: boolean }>`
  flex-grow: 1;
  ${settings.typography.fonts.h_xl}
  ${(props) =>
    props.isDisabled &&
    css`
      color: ${DISABLED_COLOR};
    `}
`;

const Content = styled.div`
  margin-top: ${settings.Spacing.Spacing_200};
  flex-grow: 1;
  line-height: 1.5;
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.Medium};
  color: ${settings.colors.Primary.Grey_8};
`;

const Paragraph = styled.p<{ isDisabled: boolean }>`
  ${Workarounds.Webkit.boxOrient}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  ${(props) =>
    props.isDisabled &&
    css`
      color: ${DISABLED_COLOR};
    `}
`;

const SButton = styled(Button)`
  align-self: flex-start;
  font-size: ${settings.typography.FontSize.Small};
`;
