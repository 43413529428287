/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { settings } from '@rhim/design';
import { RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto, RHIMProcessDataServiceV1ModelsProcessParameterHeatValueDto } from '@rhim/rest/processData';
import {
  violinChartFirstCampaignParametersComparison,
  violinChartFooterFirstCampaignLabelParametersComparison,
  violinChartFooterSecondCampaignLabelParametersComparison,
  violinChartSecondCampaignParametersComparison,
  violinChartTooltipParametersComparison,
} from '@rhim/test-ids';
import { isDefined } from '@rhim/utils';
import { defaultStyles, Tooltip } from '@vx/tooltip';
import * as d3 from 'd3';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

import { getParamRanges } from '../../utilities';
import ParamRangeIndicators from '../ParamRanges/ParamRangeIndicators';
import { AxisLeft } from './AxisLeft';
import TooltipInfo from './Tooltip';
import { getSummaryStats, VerticalBox } from './VerticalBox';
import VerticalBoxLimits from './VerticalBoxLimits';
import VerticalViolinShape from './VerticalViolinShape';

type Props = {
  paramRanges?: RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto;
  data?: RHIMProcessDataServiceV1ModelsProcessParameterHeatValueDto[];
  comparedData?: RHIMProcessDataServiceV1ModelsProcessParameterHeatValueDto[];
  width: number;
  height: number;
  name: string;
  campaignNr: number;
  comparedCampaignNr: number;
  unit: string;
  isTrimmed: boolean;
  sameCampaign: boolean;
  vesselName?: JSX.Element;
  comparedVesselName?: JSX.Element;
};

const MARGIN = { top: 20, right: 25, bottom: 50, left: 50 };

const Violin = ({
  width = 300,
  height = 200,
  data,
  isTrimmed,
  comparedData,
  name,
  vesselName,
  comparedVesselName,
  campaignNr,
  comparedCampaignNr,
  paramRanges,
  unit,
  sameCampaign,
}: Props) => {
  const yValues = (data || []).map((d) => d.value!).filter((value: number) => isDefined(value));
  const comparedYValues = (comparedData || []).map((d) => d.value!).filter((value: number) => isDefined(value));
  const halfWidth = width / 2;
  const firstHalfWidth = width / 4;
  const secondHalfWidth = width / 8;
  const { upperOuterLimit, lowerInnerLimit, upperInnerLimit, lowerUpperLimit } = getParamRanges(paramRanges);

  const values = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      isDefined(paramRanges) && isTrimmed
        ? yValues.filter((value: number) => isDefined(paramRanges) && value >= paramRanges.min! && value <= paramRanges.max!)
        : yValues,
    [yValues, paramRanges, isTrimmed]
  );

  const comparedValues = useMemo(
    () =>
      isDefined(paramRanges) && isTrimmed
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          comparedYValues.filter((value: number) => isDefined(paramRanges) && value >= paramRanges.min! && value <= paramRanges.max!)
        : comparedYValues,
    [isTrimmed, comparedYValues, paramRanges]
  );

  const [showTooltip, setShowTooltip] = useState(false);

  const boundsWidth = useMemo(() => {
    return width - MARGIN.right - MARGIN.left;
  }, [width]);

  // Compute everything derived from the dataset:
  const { chartMin, chartMax } = useMemo(() => {
    const limits = [lowerInnerLimit, lowerUpperLimit, upperOuterLimit, upperInnerLimit].filter((value) => isDefined(value)) as number[];
    const [chartMin, chartMax] = d3.extent([...yValues, ...comparedYValues, ...limits]) as [number, number];
    return { chartMin, chartMax };
  }, [yValues, comparedYValues, lowerInnerLimit, lowerUpperLimit, upperOuterLimit, upperInnerLimit]);

  const stats = getSummaryStats(yValues);
  const comparedStats = getSummaryStats(comparedYValues);

  const yMin = useMemo(() => (isDefined(paramRanges) && isTrimmed ? paramRanges.min! : chartMin), [isTrimmed, paramRanges, chartMin]);
  const yMax = useMemo(() => (isDefined(paramRanges) && isTrimmed ? paramRanges.max! : chartMax), [isTrimmed, paramRanges, chartMax]);

  const yCurrentMin = useMemo(
    () => (isDefined(paramRanges) && isTrimmed && paramRanges.min! > Math.min(...yValues) ? paramRanges.min! : Math.min(...yValues)),
    [isTrimmed, paramRanges, yValues]
  );
  const yCurrentMax = useMemo(
    () => (isDefined(paramRanges) && isTrimmed && paramRanges.max! < Math.max(...yValues) ? paramRanges.max! : Math.max(...yValues)),
    [isTrimmed, paramRanges, yValues]
  );

  const yCompareredMin = useMemo(
    () => (isDefined(paramRanges) && isTrimmed && paramRanges.min! > comparedStats.min ? paramRanges.min! : comparedStats.min),
    [isTrimmed, paramRanges, comparedStats]
  );
  const yCompareredMax = useMemo(
    () => (isDefined(paramRanges) && isTrimmed && paramRanges.max! < comparedStats.max ? paramRanges.max! : comparedStats.max),
    [isTrimmed, paramRanges, comparedStats]
  );

  // Scales
  const yScale = useMemo(() => {
    return d3.scaleLinear().domain([yMin, yMax]).range([height, 0]);
  }, [yMin, yMax, height]);

  const showUpArrow = useMemo(() => {
    return isDefined(paramRanges) && isTrimmed && paramRanges.max! < stats.max;
  }, [isTrimmed, paramRanges, stats.max]);

  const showDownArrow = useMemo(() => {
    return isDefined(paramRanges) && isTrimmed && paramRanges.min! > stats.min;
  }, [isTrimmed, paramRanges, stats.min]);

  const showPrevUpArrow = useMemo(() => {
    return isDefined(paramRanges) && isTrimmed && paramRanges.max! < comparedStats.max;
  }, [isTrimmed, paramRanges, comparedStats.max]);

  const showPrevDownArrow = useMemo(() => {
    return isDefined(paramRanges) && isTrimmed && paramRanges.min! > comparedStats.min;
  }, [isTrimmed, paramRanges, comparedStats.min]);

  const tooltipHandlers = {
    onMouseOver: () => setShowTooltip(true),
    onMouseLeave: () => setShowTooltip(false),
    onMouseMove: () => setShowTooltip(true),
  };

  return (
    <>
      <svg width={width + MARGIN.left} height={height + MARGIN.top}>
        <g width={boundsWidth} height={180} transform={`translate(${[MARGIN.left, 15].join(',')})`} {...tooltipHandlers}>
          <rect
            data-test-id={violinChartFirstCampaignParametersComparison}
            x={0}
            y={0}
            width={halfWidth}
            height={height}
            stroke={settings.colors.Primary.Grey_3}
            fill={settings.colors.Primary.Blue_1}
            strokeWidth={1}
          />
          {!sameCampaign && comparedCampaignNr !== 0 && (
            <rect
              data-test-id={violinChartSecondCampaignParametersComparison}
              x={halfWidth}
              y={0}
              width={halfWidth}
              height={height}
              stroke={settings.colors.Primary.Grey_3}
              fill={settings.colors.Monochromatic.White}
              strokeWidth={1}
            />
          )}
          {isDefined(data) && (
            <g>
              <VerticalBox
                stats={stats}
                min={yCurrentMin}
                max={yCurrentMax}
                yScale={yScale}
                width={halfWidth}
                fill={settings.colors.Monochromatic.White}
                stroke={settings.colors.Primary.Grey_6}
              />
              <g transform={`translate(${MARGIN.right},0)`}>
                <VerticalViolinShape width={firstHalfWidth} yScale={yScale} data={values} binNumber={30} fill={settings.colors.Primary.Blue_4} />
                {yValues.length > 0 && stats.median >= yCurrentMin && stats.median <= yCurrentMax && (
                  <line
                    x1={0}
                    x2={firstHalfWidth}
                    y1={yScale(stats.median)}
                    y2={yScale(stats.median)}
                    stroke={settings.colors.Primary.Blue_8}
                    strokeWidth={2}
                  />
                )}
              </g>
              {yCurrentMax <= yMax && yCurrentMax >= yMin && yValues.length > 0 && yCurrentMin <= yMax && yCurrentMin >= yMin && (
                <line
                  x1={firstHalfWidth}
                  x2={firstHalfWidth}
                  y1={yScale(yCurrentMin)}
                  y2={yScale(yCurrentMax)}
                  stroke={settings.colors.Primary.Grey_6}
                  strokeWidth={1}
                />
              )}
              {isFinite(yCurrentMin) &&
                isFinite(yCurrentMax) &&
                yCurrentMax >= yMin &&
                yCurrentMax <= yMax &&
                yValues.length > 0 &&
                yCurrentMin <= yMax &&
                yCurrentMin >= yMin && (
                  <VerticalBoxLimits
                    width={firstHalfWidth}
                    min={yCurrentMin}
                    max={yCurrentMax}
                    upArrow={showUpArrow}
                    downArrow={showDownArrow}
                    yScale={yScale}
                  />
                )}
            </g>
          )}
          {!sameCampaign && isDefined(comparedData) && (
            <g>
              <VerticalBox min={yMin} max={yMax} fill={settings.colors.Primary.Grey_2} stats={comparedStats} yScale={yScale} x0={width / 2} width={width} />
              <g transform={`translate(${halfWidth + MARGIN.right} ,0)`}>
                <VerticalViolinShape yScale={yScale} data={comparedValues} binNumber={30} fill={settings.colors.Primary.Grey_4} width={firstHalfWidth} />
                {comparedYValues.length > 0 && comparedStats.median >= yMin && comparedStats.median <= yMax && (
                  <line
                    x1={0}
                    x2={firstHalfWidth}
                    y1={yScale(comparedStats.median)}
                    y2={yScale(comparedStats.median)}
                    stroke={settings.colors.Primary.Grey_6}
                    strokeWidth={2}
                  />
                )}
                {comparedYValues.length > 0 && yCompareredMin >= yMin && yCompareredMax >= yMin && yCompareredMin <= yMax && yCompareredMax <= yMax && (
                  <line
                    x1={secondHalfWidth}
                    x2={secondHalfWidth}
                    y1={yScale(yCompareredMin)}
                    y2={yScale(yCompareredMax)}
                    stroke={settings.colors.Primary.Grey_6}
                    strokeWidth={1}
                  />
                )}
                {isFinite(comparedStats.min) &&
                  isFinite(comparedStats.max) &&
                  comparedYValues.length > 0 &&
                  yCompareredMin >= yMin &&
                  yCompareredMin <= yMax &&
                  yCompareredMax >= yMin && (
                    <VerticalBoxLimits
                      width={secondHalfWidth}
                      min={yCompareredMin}
                      max={yCompareredMax}
                      upArrow={showPrevUpArrow}
                      downArrow={showPrevDownArrow}
                      yScale={yScale}
                    />
                  )}
              </g>
            </g>
          )}
          <AxisLeft yScale={yScale} />
          {isDefined(upperOuterLimit) && (
            <line
              x1={0}
              // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
              y1={yScale(upperOuterLimit)}
              x2={sameCampaign || !isDefined(data) || !isDefined(comparedData) ? halfWidth : width}
              // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
              y2={yScale(upperOuterLimit)}
              stroke={settings.colors.Primary.Grey_4}
              strokeWidth={1}
              strokeDasharray={3}
              pointerEvents="none"
            />
          )}
          {isDefined(lowerUpperLimit) && (
            <line
              x1={0}
              // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
              y1={yScale(lowerUpperLimit)}
              x2={sameCampaign || !isDefined(data) || !isDefined(comparedData) ? halfWidth : width}
              // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
              y2={yScale(lowerUpperLimit)}
              stroke={settings.colors.Primary.Grey_4}
              strokeWidth={1}
              strokeDasharray={3}
              pointerEvents="none"
            />
          )}
          {isDefined(upperInnerLimit) && (
            <line
              x1={0}
              // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
              y1={yScale(upperInnerLimit)}
              x2={sameCampaign || !isDefined(data) || !isDefined(comparedData) ? halfWidth : width}
              // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
              y2={yScale(upperInnerLimit)}
              stroke={settings.colors.Primary.Grey_4}
              strokeWidth={1}
              strokeDasharray={3}
              pointerEvents="none"
            />
          )}
          {isDefined(lowerInnerLimit) && (
            <line
              x1={0}
              // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
              y1={yScale(lowerInnerLimit)}
              x2={sameCampaign || !isDefined(data) || !isDefined(comparedData) ? halfWidth : width}
              // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
              y2={yScale(lowerInnerLimit)}
              stroke={settings.colors.Primary.Grey_4}
              strokeWidth={1}
              strokeDasharray={3}
              pointerEvents="none"
            />
          )}
        </g>
      </svg>
      {showTooltip && (
        <TooltipContainer data-test-id={violinChartTooltipParametersComparison}>
          <TooltipInfo unit={unit} stats={stats} comparedStats={comparedStats} name={name} campaign={campaignNr} compareCampaign={comparedCampaignNr} />
        </TooltipContainer>
      )}
      {isDefined(upperInnerLimit) && (
        <Tooltip
          top={yScale(upperInnerLimit) - 10}
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          left={(sameCampaign || !isDefined(data) || !isDefined(comparedData) ? halfWidth : width) + 35}
          style={{
            ...defaultStyles,
            backgroundColor: 'transparent',
            boxShadow: 'none',
            zIndex: settings.Elevation.Tooltip,
          }}
        >
          <ParamRangeIndicators upper={true} />
        </Tooltip>
      )}
      {isDefined(lowerInnerLimit) && (
        <Tooltip
          top={yScale(lowerInnerLimit) - 10}
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          left={(sameCampaign || !isDefined(data) || !isDefined(comparedData) ? halfWidth : width) + 35}
          style={{
            ...defaultStyles,
            backgroundColor: 'transparent',
            boxShadow: 'none',
            zIndex: settings.Elevation.Tooltip,
          }}
        >
          <ParamRangeIndicators />
        </Tooltip>
      )}

      {isDefined(lowerUpperLimit) && (
        <Tooltip
          top={yScale(lowerUpperLimit) - 10}
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          left={(sameCampaign || !isDefined(data) || !isDefined(comparedData) ? halfWidth : width) + 35}
          style={{
            ...defaultStyles,
            backgroundColor: 'transparent',
            boxShadow: 'none',
            zIndex: settings.Elevation.Tooltip,
          }}
        >
          <ParamRangeIndicators outer={true} />
        </Tooltip>
      )}

      {isDefined(upperOuterLimit) && (
        <Tooltip
          top={yScale(upperOuterLimit) - 10}
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          left={(sameCampaign || !isDefined(data) || !isDefined(comparedData) ? halfWidth : width) + 35}
          style={{
            ...defaultStyles,
            backgroundColor: 'transparent',
            boxShadow: 'none',
            zIndex: settings.Elevation.Tooltip,
          }}
        >
          <ParamRangeIndicators upper={true} outer={true} />
        </Tooltip>
      )}
      <Footer>
        <Text data-test-id={violinChartFooterFirstCampaignLabelParametersComparison} color={settings.colors.Primary.Blue_8} width={width / 2}>
          {isDefined(data) && (
            <>
              {isDefined(vesselName) ? (
                <>
                  {vesselName}/{campaignNr}
                </>
              ) : (
                campaignNr
              )}
            </>
          )}
        </Text>
        {!sameCampaign && comparedCampaignNr !== 0 && (
          <Text data-test-id={violinChartFooterSecondCampaignLabelParametersComparison} color={settings.colors.Primary.Grey_8} width={width / 2}>
            {isDefined(comparedVesselName) ? (
              <>
                {comparedVesselName}/{comparedCampaignNr}
              </>
            ) : (
              comparedCampaignNr
            )}
          </Text>
        )}
      </Footer>
    </>
  );
};

export default Violin;

const TooltipContainer = styled.div`
  position: absolute;
  z-index: ${settings.Elevation.Tooltip};
`;

const Text = styled.div<{ color: string; width: number }>`
  color: ${(props) => props.color};
  font-size: ${settings.typography.FontSize.Small};
  font-family: ${settings.typography.FontFamily.Bold};
  width: ${(props) => props.width}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Footer = styled.div`
  display: inline-flex;
  width: 100%;
  margin-left: ${MARGIN.left}px;
`;
