import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const CloudErrorIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={17} viewBox="0 0 16 17" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.967 6.381c.013-.11.033-.217.033-.331a2.8 2.8 0 0 0-2.8-2.8c-.618 0-1.185.207-1.648.547A3.59 3.59 0 0 0 5.6 2.25 3.6 3.6 0 0 0 2 5.85c0 .577.148 1.114.389 1.597A3.488 3.488 0 0 0 0 10.75a3.5 3.5 0 0 0 3.5 3.5h8.167v-.034c.112.01.219.034.333.034a4 4 0 0 0 4-4 3.993 3.993 0 0 0-3.033-3.869z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 12.25a1 1 0 1 1-.001-1.999A1 1 0 0 1 8 12.25zM7 6.25a1 1 0 0 1 2 0v2a1 1 0 0 1-2 0v-2z"
        fill={settings.colors.Monochromatic.Black}
      />
    </svg>
  );
};

CloudErrorIcon.defaultProps = {
  fill: settings.colors.Operational.State_Notif_Magenta_2,
};
