/* eslint-disable @typescript-eslint/ban-types */

/**
 * Performs a shallow comparison of two objects. Uses `Object.is` under the hood.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/is
 */
export function isEqual<T extends object>(left: T, right: T): boolean {
  /**
   * If it's exactly the same object, they are the same.
   */
  if (Object.is(left, right)) {
    return true;
  }

  /**
   * If one has more properties than the other, they are not the same.
   */
  if (Object.keys(left).length !== Object.keys(right).length) {
    return false;
  }

  /**
   * If a property exists in one but not in another, or the values are different, return false.
   */
  for (const property in left) {
    if (!(property in right) || !Object.is(left[property], right[property])) {
      return false;
    }
  }

  for (const property in right) {
    if (!(property in left) || !Object.is(left[property], right[property])) {
      return false;
    }
  }

  return true;
}
