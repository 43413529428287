import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const AddUserIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5094 17.2387C9.52642 17.686 7.36261 17.202 5.73976 15.747C5.58015 15.6036 5.42761 15.4524 5.28294 15.2942C5.52354 14.1891 6.50952 13.4004 7.65039 13.4004H11.8679C12.8452 11.1037 15.138 9.49196 17.8105 9.49196C17.8742 9.49196 17.9371 9.49508 18 9.49664C17.7767 8.33697 17.2908 7.20847 16.5281 6.20078C13.8532 2.66566 8.79519 1.94944 5.22869 4.60078C1.66219 7.25212 0.940397 12.2657 3.61527 15.8C5.13984 17.8146 7.53166 19 10.0721 19C10.7436 19 11.4087 18.9166 12.0519 18.7553C11.8105 18.2807 11.6257 17.7717 11.5094 17.2387ZM10.0713 5.40039C11.8545 5.40039 13.2997 6.83283 13.2997 8.60039C13.2997 10.3679 11.8545 11.8004 10.0713 11.8004C8.28805 11.8004 6.84289 10.3679 6.84289 8.60039C6.84289 6.83283 8.28805 5.40039 10.0713 5.40039Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.0001 15H18.9999V13C18.9999 12.5 18.4999 12 18.0001 12C17.4999 12 16.9999 12.5 16.9999 13V15H14.9999C14.4999 15 14.0001 15.5 14.0001 16C13.9999 16.5 14.4999 17 14.9999 17H16.9999V19C16.9999 19.5 17.5004 20 18.0001 20C18.4999 20 18.9999 19.5 18.9999 19V17H21.0001C21.5001 17 22.0001 16.5 22.0001 16C22.0001 15.5 21.5001 15 21.0001 15Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

AddUserIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
