import { SidebarLeft } from '@rhim/react';
import { assert, ensure, hasElements, isDefined } from '@rhim/utils';
import React, { useContext } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { usePersistedMenuState } from '../../hooks';
import { useSelectedVessel } from '../../hooks/useSelectedVessel';
import { ROUTES } from '../../utilities';
import AppContext from '../AppContext';
import { DisabledFeatureText } from './DisabledFeatureText';
import { useIngressSidebarItems } from './useIngressSidebarItems';
import { useSidebarItems } from './useSidebarItems';

export const STEEL_SIDEBAR_DRAWER_CONTAINER_DOM_ID = 'SteelSidebarDrawerContainerDomId';

const SteelSidebar: React.FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = usePersistedMenuState();

  const { vessels } = useContext(AppContext);
  const { selectedVesselId } = useSelectedVessel(vessels);

  const selectedVesselType = vessels.find((vessel) => vessel.id === selectedVesselId)?.vesselType;

  const [, startTransition] = React.useTransition();

  const createProps = React.useCallback(
    (target: string) => {
      const match = matchPath(
        {
          path: target,
          // end: false,
        },
        location.pathname
      );

      return {
        isSelected: isDefined(match),
        // Exclude search params in the Fleet Overview page as no params are required there.
        onClick: () => {
          startTransition(() => {
            target !== ROUTES.FLEET_OVERVIEW ? navigate({ pathname: target, search: location.search }) : navigate(target);
          });
        },
        key: target,
      };
    },
    [location.pathname, location.search, navigate, startTransition]
  );

  const handleToggleExpandedState = React.useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    setOpen(!open);
  }, [open, setOpen]);

  let sidebarItems = useSidebarItems();
  const ingressSideBarItems = useIngressSidebarItems();

  if (isDefined(location.pathname) && location.pathname.includes(ROUTES.INGRESS)) {
    sidebarItems = ingressSideBarItems;
  }
  const { primaryItems, secondaryItems, drawerItems } = React.useMemo(() => {
    assert(isDefined(selectedVesselType), 'selectedVesselType must be defined');
    const sidebarItemsForThisVesselType = ensure(sidebarItems.get(selectedVesselType));

    return sidebarItemsForThisVesselType.reduce<{
      primaryItems: JSX.Element[];
      secondaryItems: JSX.Element[];
      drawerItems: JSX.Element[];
    }>(
      (accumulator, current) => {
        const { destination, label, disabled, position, feature, show, disabledText, ...rest } = current;

        if (!show) {
          // The customer lacks privileges, never even show them the feature.
          // Note: this is different from showing them the feature, but keeping it disabled.
          // The user may be allowed to use a feature in principle, but have it disabled for other reasons (e.g. they haven't purchased it yet)
          return accumulator;
        }

        const item = (
          <SidebarLeft.Item text={label} isDisabled={disabled} disabledText={disabledText ?? <DisabledFeatureText />} {...createProps(destination)} {...rest} />
        );

        switch (position) {
          case 'above-separator': {
            accumulator.primaryItems.push(item);
            break;
          }
          case 'below-separator': {
            accumulator.secondaryItems.push(item);
            break;
          }
          case 'drawer': {
            accumulator.drawerItems.push(item);
          }
        }

        return accumulator;
      },
      {
        primaryItems: [],
        secondaryItems: [],
        drawerItems: [],
      }
    );
  }, [createProps, selectedVesselType, sidebarItems]);

  return (
    <SWrapper>
      <SidebarLeft isExpanded={open} onToggleExpandedState={handleToggleExpandedState}>
        {primaryItems}
        {hasElements(primaryItems) && hasElements(secondaryItems) && <SidebarLeft.Separator />}
        {secondaryItems}
        <SidebarLeft.VerticalSpring />
        <SDrawerItemsContainer id={STEEL_SIDEBAR_DRAWER_CONTAINER_DOM_ID}>{drawerItems}</SDrawerItemsContainer>
      </SidebarLeft>
    </SWrapper>
  );
};

const SDrawerItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export default React.memo(SteelSidebar);

const SWrapper = styled.div`
  height: 100%;
`;
