import type { ArcRotateCamera } from '@babylonjs/core';
import { settings } from '@rhim/design';
import { LockedIcon, UnlockedIcon } from '@rhim/icons/16';
import { Tooltip, TooltipPlacement } from '@rhim/react';
import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHover } from 'react-aria';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  dataTestId?: string;
  isDisabled?: boolean;
  isLocked: boolean;
  onLockClicked?: () => void;
  tooltipLock: string;
  tooltipLabel: string;
  tooltipLockPlacement?: TooltipPlacement;
  tooltipLabelPlacement?: TooltipPlacement;
  camera: ArcRotateCamera;
  showsAlpha: boolean;
  className?: string;
}

const RotationDegreesWidget: FC<React.PropsWithChildren<Props>> = ({
  dataTestId,
  isDisabled = false,
  isLocked,
  onLockClicked,
  tooltipLock,
  tooltipLabel,
  tooltipLockPlacement = 'top',
  tooltipLabelPlacement = 'top',
  camera,
  showsAlpha,
  className,
}) => {
  const [rotationDegrees, setRotationDegrees] = useState(0);
  const { t } = useTranslation('visualization3d');

  useEffect(() => {
    const updateRotationDegrees = () => {
      let zeroBasedAngleInRadians = showsAlpha ? (-Math.PI / 2 - camera.alpha) % (2 * Math.PI) : (Math.PI / 2 - camera.beta) % (2 * Math.PI);
      if (zeroBasedAngleInRadians < 0) {
        zeroBasedAngleInRadians += 2 * Math.PI;
      }
      const angleInDegrees = (180 * zeroBasedAngleInRadians) / Math.PI;
      let degreesInteger = Math.round(angleInDegrees);
      degreesInteger = degreesInteger === -360 || degreesInteger === 360 ? 0 : degreesInteger;
      setRotationDegrees(degreesInteger);
    };
    const cameraRotationObserver = camera.onViewMatrixChangedObservable.add(updateRotationDegrees);
    updateRotationDegrees();

    return () => {
      cameraRotationObserver.remove();
    };
  }, [camera, showsAlpha]);

  const handleLockClicked = useCallback(() => {
    if (!isDisabled) {
      onLockClicked?.();
    }
  }, [isDisabled, onLockClicked]);

  const { hoverProps, isHovered } = useHover({ isDisabled: isDisabled });

  const classes = classNames({
    isDisabled,
  });

  const lockIconFill = isDisabled ? settings.colors.Primary.Grey_6 : isHovered ? settings.colors.Primary.Blue_8 : settings.colors.Primary.Blue_9;
  return (
    <StyledWrapper data-test-id={dataTestId} className={className}>
      <Tooltip title={tooltipLock} placement={tooltipLockPlacement}>
        <SIconContainer {...hoverProps} onClick={handleLockClicked}>
          {isLocked ? <SLockedIcon fill={lockIconFill} className={classes} /> : <SUnlockedIcon fill={lockIconFill} className={classes} />}
        </SIconContainer>
      </Tooltip>
      <Tooltip title={tooltipLabel} placement={tooltipLabelPlacement}>
        <SRotationDegreesLabel>
          {rotationDegrees}
          {t('units.degrees', { ns: 'visualization3d' })}
        </SRotationDegreesLabel>
      </Tooltip>
    </StyledWrapper>
  );
};

export default React.memo(RotationDegreesWidget);

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 74px;
  height: 24px;
  background-color: ${settings.colors.Monochromatic.White};
  border-radius: 3px;
  padding: 0 ${settings.Spacing.Spacing_100};
  color: ${settings.colors.Primary.Grey_8};
  font-family: ${settings.typography.FontFamily.Bold};
`;

const SRotationDegreesLabel = styled.span`
  position: absolute;
  left: 0;
  right: ${settings.Spacing.Spacing_100};
  text-align: right;
  z-index: -1;
  /* stylelint-disable-next-line */
  user-select: none;
`;

const SIconContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

/**
 * Regarding the "pointer-events: none" seen below : because the locked/unlocked icons are 2 separate components which render/toggle conditionally,
 * their onMouseEnter/Leave events - if allowed - can misbehave, causing a subtle bug where the icons may appear hovered when they are not.
 * See : https://dev.azure.com/RHIM/QCK/_workitems/edit/54117
 */
const SLockedIcon = styled(LockedIcon)`
  pointer-events: none;
`;

const SUnlockedIcon = styled(UnlockedIcon)`
  pointer-events: none;
`;
