import { AuthError } from '@azure/msal-browser';
import { AppStateAlertIcon as LargeAppStateAlertIcon } from '@rhim/icons/64';
import { CtaButton, MaintenancePanel } from '@rhim/react';
import axios from 'axios';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import styled from 'styled-components';

import { authClient } from '../../utilities';

/**
 * A special, childless error boundary to be used with React Router.
 *
 * @see https://reactrouter.com/en/main/route/error-element#throwing-responses
 */
export function RootBoundary() {
  const error = useRouteError();
  const { t } = useTranslation(['app', 'errorBoundary']);
  const primaryButton: CtaButton = useMemo(() => ({ label: t('app:errors.000.recoveryMessage'), onClick: () => window.location.reload() }), [t]);
  const secondaryButton: CtaButton = useMemo(
    () => ({ label: t('app:errors.000.acknowledgmentMessage'), onClick: () => authClient.logoutRedirect({ postLogoutRedirectUri: window.location.origin }) }),
    [t]
  );
  const authIssueButton: CtaButton = useMemo(
    () => ({ label: t('app:errors.000.recoveryMessage'), onClick: () => authClient.logoutRedirect({ postLogoutRedirectUri: window.location.origin }) }),
    [t]
  );

  const unrecoverableError = (
    <StyledWrapper>
      <MaintenancePanel
        size="large"
        icon={<LargeAppStateAlertIcon />}
        heading={t('app:errors.000.summary')}
        subHeading={t('app:errors.000.humanReadableMessage')}
        primaryButton={primaryButton}
        secondaryButton={secondaryButton}
      />
    </StyledWrapper>
  );

  const somethingWentWrong = (
    <StyledWrapper>
      <MaintenancePanel
        size="large"
        icon={<LargeAppStateAlertIcon />}
        heading={t('errorBoundary:errorMessage')}
        subHeading={t('app:errors.000.humanReadableMessage')}
        primaryButton={primaryButton}
        secondaryButton={secondaryButton}
      />
    </StyledWrapper>
  );

  if (error instanceof AuthError) {
    return (
      <StyledWrapper>
        <MaintenancePanel
          size="large"
          icon={<LargeAppStateAlertIcon />}
          heading={t('errorBoundary:errorMessage')}
          subHeading={t('app:app.errors.noCustomerAccess')}
          primaryButton={authIssueButton}
        />
      </StyledWrapper>
    );
  }

  if (isRouteErrorResponse(error)) {
    return unrecoverableError;
  }

  if (axios.isAxiosError(error)) {
    // Happens when the call is blocked
    if (error.message === 'Network Error') {
      return unrecoverableError;
    }

    if (error.response?.status === 400) {
      return somethingWentWrong;
    }
  }

  return somethingWentWrong;
}

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;
