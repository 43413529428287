import { AccountInfo } from '@azure/msal-browser';
import { assert, isDefined, isUUID } from '@rhim/utils';
import { isString } from 'lodash';

interface WithClaims extends Pick<AccountInfo, 'idTokenClaims'> {}

export function getCurrentUserId(account: WithClaims): UUID {
  assert(isDefined(account.idTokenClaims));
  assert(isDefined(account.idTokenClaims['apoId']));
  assert(isString(account.idTokenClaims['apoId']));
  assert(isUUID(account.idTokenClaims['apoId']));

  return account.idTokenClaims['apoId'];
}
