import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const PerformanceIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M21 17.5H4V5.34531C4 5 3.5 4.5 3 4.5C2.5 4.5 2 5 2 5.34531V18.25C2 18.9402 2.55977 19.5 3.25 19.5H21.1547C21.5 19.5 22 19 22 18.5C22 18 21.5 17.5 21 17.5ZM20.3754 6.5H15.7637C14.9285 6.5 14.5102 7.50977 15.1008 8.10039L16.3664 9.36602L13.5004 12.2324L10.6344 9.36641C10.1461 8.87813 9.35469 8.87813 8.8668 9.36641L6.18359 12.0496C5.93945 12.2938 5.93945 12.6895 6.18359 12.9336L7.06719 13.8172C7.31133 14.0613 7.70703 14.0613 7.95117 13.8172L9.75039 12.0176L12.6164 14.8836C13.1047 15.3719 13.8961 15.3719 14.384 14.8836L18.134 11.1336L19.3996 12.3992C19.9902 12.9898 21 12.5715 21 11.7363V7.125C21.0004 6.77969 20.7207 6.5 20.3754 6.5Z"
        fill={fill}
      />
    </svg>
  );
};

PerformanceIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
