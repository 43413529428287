import { type Location } from 'history';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

interface WithLocationProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  location: Location<any>;
}

export function withLocation<T extends WithLocationProps = WithLocationProps>(WrappedComponent: React.ComponentType<React.PropsWithChildren<T>>) {
  const displayName = WrappedComponent.displayName ?? WrappedComponent.name;

  const ComponentWithLocation = (props: Omit<T, keyof WithLocationProps>) => {
    const location = useLocation();

    // @ts-expect-error props should not include location since it's already omitted earlier
    return <WrappedComponent location={location} {...(props as T)} />;
  };

  ComponentWithLocation.displayName = `withLocation(${displayName})`;

  return ComponentWithLocation;
}
