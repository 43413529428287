import { RHIMOperatorDisplayServiceV1ModelsRegionOrderAndVisibilityDto } from '@rhim/rest/operatorDisplay';
import { specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api/operatorDisplay';

type Payload = RHIMOperatorDisplayServiceV1ModelsRegionOrderAndVisibilityDto[];

export const useRegionsOrder = (
  vesselId: UUID,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) => {
  return useQuery(getKey(vesselId), (context) => queryFn(context, options?.axiosConfiguration), {
    select: (data: RHIMOperatorDisplayServiceV1ModelsRegionOrderAndVisibilityDto[]) => {
      return data.slice().sort((itemA, itemB) => itemA.order - itemB.order);
    },
    ...options?.configuration,
  });
};

const getKey = (vesselId: UUID) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'operatorDisplay',
      scope: 'regions',
      entity: 'list',
      vesselId,
    },
  ]);

const queryFn = ({ queryKey: [{ vesselId }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  return API.vessel.getVesselVesselidOrder(vesselId, { signal, ...axiosConfiguration }).then((response) => response.data);
};

useRegionsOrder.getKey = getKey;
useRegionsOrder.queryFn = queryFn;
