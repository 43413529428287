import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const CopyIdClipboardIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 9.91667C12.4944 9.91667 12.9778 10.0633 13.3889 10.338C13.8 10.6127 14.1205 11.0031 14.3097 11.46C14.4989 11.9168 14.5484 12.4194 14.452 12.9044C14.3555 13.3893 14.1174 13.8348 13.7678 14.1844C13.4181 14.5341 12.9727 14.7722 12.4877 14.8686C12.0028 14.9651 11.5001 14.9156 11.0433 14.7264C10.5865 14.5371 10.196 14.2167 9.92132 13.8056C9.64662 13.3945 9.5 12.9111 9.5 12.4167C9.5 11.7536 9.76339 11.1177 10.2322 10.6489C10.7011 10.1801 11.337 9.91667 12 9.91667ZM16.1083 18.475C16.0732 18.5341 16.0232 18.583 15.9632 18.6166C15.9032 18.6503 15.8354 18.6675 15.7667 18.6667H8.26666C8.1979 18.6675 8.13012 18.6503 8.07014 18.6166C8.01017 18.583 7.96011 18.5341 7.925 18.475C7.89051 18.417 7.87231 18.3508 7.87231 18.2833C7.87231 18.2159 7.89051 18.1497 7.925 18.0917C8.34978 17.3786 8.95252 16.788 9.67416 16.3779C10.3958 15.9678 11.2116 15.7522 12.0417 15.7522C12.8717 15.7522 13.6875 15.9678 14.4092 16.3779C15.1308 16.788 15.7335 17.3786 16.1583 18.0917C16.1856 18.1541 16.1953 18.2227 16.1865 18.2903C16.1777 18.3578 16.1507 18.4217 16.1083 18.475Z"
        fill={fill}
      />
      <path
        d="M18.2499 4.5H16.1666C16.0561 4.5 15.9501 4.5439 15.872 4.62204C15.7938 4.70018 15.7499 4.80616 15.7499 4.91667V5.75C15.7499 5.86051 15.7938 5.96649 15.872 6.04463C15.9501 6.12277 16.0561 6.16667 16.1666 6.16667H17.8333C17.9438 6.16667 18.0497 6.21057 18.1279 6.28871C18.206 6.36685 18.2499 6.47283 18.2499 6.58333V19.9167C18.2478 20.0265 18.2032 20.1313 18.1255 20.2089C18.0478 20.2866 17.9431 20.3312 17.8333 20.3333H6.16659C6.05608 20.3333 5.9501 20.2894 5.87196 20.2113C5.79382 20.1332 5.74992 20.0272 5.74992 19.9167V6.58333C5.74992 6.47283 5.79382 6.36685 5.87196 6.28871C5.9501 6.21057 6.05608 6.16667 6.16659 6.16667H7.83325C7.94376 6.16667 8.04974 6.12277 8.12788 6.04463C8.20602 5.96649 8.24992 5.86051 8.24992 5.75V4.91667C8.24992 4.80616 8.20602 4.70018 8.12788 4.62204C8.04974 4.5439 7.94376 4.5 7.83325 4.5H5.74992C5.30789 4.5 4.88397 4.67559 4.57141 4.98816C4.25885 5.30072 4.08325 5.72464 4.08325 6.16667V20.3333C4.08325 20.7754 4.25885 21.1993 4.57141 21.5118C4.88397 21.8244 5.30789 22 5.74992 22H18.2499C18.6919 22 19.1159 21.8244 19.4284 21.5118C19.741 21.1993 19.9166 20.7754 19.9166 20.3333V6.16667C19.9166 5.72464 19.741 5.30072 19.4284 4.98816C19.1159 4.67559 18.6919 4.5 18.2499 4.5Z"
        fill={fill}
      />
      <path
        d="M9.49992 7C9.49992 7.33152 9.63161 7.64946 9.86603 7.88388C10.1005 8.1183 10.4184 8.25 10.7499 8.25H13.2499C13.5814 8.25 13.8994 8.1183 14.1338 7.88388C14.3682 7.64946 14.4999 7.33152 14.4999 7V4.5C14.4999 3.83696 14.2365 3.20107 13.7677 2.73223C13.2988 2.26339 12.663 2 11.9999 2C11.3369 2 10.701 2.26339 10.2322 2.73223C9.76331 3.20107 9.49992 3.83696 9.49992 4.5V7ZM11.1666 4.08333C11.1666 3.91852 11.2155 3.7574 11.307 3.62036C11.3986 3.48332 11.5287 3.37651 11.681 3.31343C11.8333 3.25036 12.0008 3.23386 12.1625 3.26601C12.3241 3.29817 12.4726 3.37753 12.5892 3.49408C12.7057 3.61062 12.7851 3.75911 12.8172 3.92076C12.8494 4.08241 12.8329 4.24996 12.7698 4.40224C12.7067 4.55451 12.5999 4.68466 12.4629 4.77622C12.3259 4.86779 12.1647 4.91667 11.9999 4.91667C11.7789 4.91667 11.5669 4.82887 11.4107 4.67259C11.2544 4.51631 11.1666 4.30435 11.1666 4.08333Z"
        fill={fill}
      />
    </svg>
  );
};

CopyIdClipboardIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
