import { HomeIcon, MeasurementIcon } from '@rhim/icons/24';
import { VesselType } from '@rhim/rest';
import { dataCenterFeature, measurementDataFeature } from '@rhim/test-ids';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-use';

import { CustomerFeatureFlag, Feature, FeatureFlag } from '../../settings/featureFlags';
import { ROUTES } from '../../utilities';

interface IngressSidebarItem {
  /**
   * `undefined` when the feature it not behind a feature flag.
   */
  feature?: Feature | CustomerFeatureFlag | FeatureFlag;
  /**
   * Relative path to the destination.
   */
  destination: ValueOf<typeof ROUTES>;
  /**
   * `true` if the customer has that feature amongst their allowed operations (privileges).
   */
  show: boolean;
  /**
   * Based on feature flags and features enabled for this particular customer.
   */
  disabled: boolean;
  icon: React.ReactElement;
  label: string;
  position: 'above-separator' | 'below-separator' | 'drawer';
  'data-test-id': string;
}

export function useIngressSidebarItems(): Map<VesselType, NonEmptyArray<IngressSidebarItem>> {
  const { t } = useTranslation(['app', 'ingress']);

  const vesselsAndItemsMapping = React.useMemo(() => {
    const allSidebarItems = {
      [ROUTES.INGRESS]: {
        position: 'above-separator',
        destination: ROUTES.INGRESS,
        show: true,
        disabled: false,
        icon: <HomeIcon />,
        label: t('app:appBar.ingress'),
        'data-test-id': dataCenterFeature,
      },
      [ROUTES.INGRESS_MEASUREMENT_DATA]: {
        feature: Feature.ProcessData,
        disabled: false,
        position: 'below-separator',
        destination: ROUTES.INGRESS_MEASUREMENT_DATA,
        show: true,
        icon: <MeasurementIcon />,
        label: t('ingress:measurementDataTitle'),
        'data-test-id': measurementDataFeature,
      },
    } satisfies Partial<Record<ValueOf<typeof ROUTES>, IngressSidebarItem>>;

    const allVessels = Object.values(VesselType) as NonEmptyArray<VesselType>;
    const mapping: NonEmptyArray<[VesselType, NonEmptyArray<IngressSidebarItem>]> = allVessels.map((vessel) => [
      vessel,
      [allSidebarItems[ROUTES.INGRESS], allSidebarItems[ROUTES.INGRESS_MEASUREMENT_DATA]],
    ]);
    return mapping;
  }, [t]);

  const [destinations] = useMap(new Map<VesselType, NonEmptyArray<IngressSidebarItem>>(vesselsAndItemsMapping));

  return destinations;
}
