import { RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelDto } from '@rhim/rest/wearManagement';
import { assert, isDefined, specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api/wearManagement';

type Payload = RHIMAPOReportingWearManagementApiV1ModelsCRKCrkPredictionModelDto;

export function useCrkPredictions(
  vesselId: string,
  predictionId: string,
  configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>
) {
  assert(
    isDefined(vesselId),
    `${useCrkPredictions.name} requires a vesselId, but value was ${vesselId}. Use a valid id, or use \`options.enabled\` to pause the query until all requirements are met. See https://react-query.tanstack.com/guides/disabling-queries for instructions.`
  );
  assert(
    isDefined(predictionId),
    `${useCrkPredictions.name} requires a predictionId, but value was ${predictionId}. Use a valid id, or use \`options.enabled\` to pause the query until all requirements are met. See https://react-query.tanstack.com/guides/disabling-queries for instructions.`
  );

  return useQuery(getKey(vesselId, predictionId), (context) => queryFn(context), configuration);
}

const getKey = (vesselId: string, predictionId: string) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'wearManagement',
      scope: 'predictions',
      entity: 'detail',
      vesselId,
      predictionId,
    },
  ]);

const queryFn = (
  { queryKey: [{ vesselId, predictionId }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>,
  axiosConfiguration?: AxiosRequestConfig
) => {
  return API.crkPredictions.getPredictionsCrkVesselidPredictionid(vesselId, predictionId, { signal, ...axiosConfiguration }).then((response) => response.data);
};

useCrkPredictions.getKey = getKey;
useCrkPredictions.queryFn = queryFn;
