/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  AbstractMesh,
  Mesh,
  MeshBuilder,
  Nullable,
  PointerEventTypes,
  Quaternion,
  Scalar,
  Scene,
  TransformNode,
  Vector2,
  Vector3,
  VertexBuffer,
  VertexData,
} from '@babylonjs/core';
import { AdvancedDynamicTexture, Button, Line, TextBlock } from '@babylonjs/gui/2D';
import { Logger } from '@rhim/logging';
import { assert, isDefined } from '@rhim/utils';
import { TFunction } from 'react-i18next';

import { MESH_CONTAINER_NAME } from './Scene3d/types';
import { CameraOrientation, Scene3dApiFacade } from './types';

const log = new Logger();

const MESH_BOUNDING_BOX_DIAGONAL_LENGTH_IN_METERS_THRESHOLD = 1000;
export const DEFAULT_3D_MODEL_ROTATION_AXIS_Y = Math.PI / 2;

export function calculateVertexData(mesh: Mesh): void {
  const transform = mesh.computeWorldMatrix(true);

  const length = mesh.getTotalVertices();
  const positions = mesh.getVerticesData(VertexBuffer.PositionKind)!;

  const hasNormals = mesh.isVerticesDataPresent(VertexBuffer.NormalKind);

  for (let i = 0; i < length; i++) {
    const position = Vector3.TransformCoordinates(Vector3.FromArray(positions, i * 3), transform);
    position.toArray(positions, i * 3);
  }
  mesh.setVerticesData(VertexBuffer.PositionKind, positions, true);
  if (!hasNormals) {
    mesh.createNormals(true);
  }

  if (!mesh.isVerticesDataPresent(VertexBuffer.UV2Kind)) {
    calculateUVWallAndBottom(mesh);
  }

  mesh.resetLocalMatrix();
  // FIXME calculate min and max during traversing positions then just set new bounding info
  mesh.refreshBoundingInfo();
}

/**
 * Performing cylindrical parameterization for the model for further unwrapping
 * of dynamic textures
 * The computed UVs are written into the 2'd UV channel
 *
 * !!!Note: it you know you don't need to apply dynamic textures or cylindrical
 * parameterization was done on backend, it is better to avoid using this function
 *
 * @param mesh
 */
function calculateUVWallAndBottom(mesh: Mesh) {
  const positions = mesh.getVerticesData(VertexBuffer.PositionKind)!;
  const length = mesh.getTotalVertices();
  mesh.refreshBoundingInfo();
  const bottomDepth: Nullable<number> = isDefined(mesh.metadata) && isDefined(mesh.metadata.bottomDepth) ? mesh.metadata.bottomDepth : null;
  const {
    minimum: { y: bottomHeight, x: edgeLeft, z: edgeFront },
    maximum: { y: topHeight, x: edgeRight, z: edgeBack },
  } = mesh.getBoundingInfo().boundingBox;
  const bottomLine = isDefined(bottomDepth)
    ? topHeight - bottomDepth
    : Math.sign(bottomHeight) * (Math.abs(bottomHeight) - ((topHeight - bottomHeight) * 0.15) / 2);
  // TODO get radius of the bottom part of the ladle during traversing positions
  const averageRadius = (edgeRight - edgeLeft + (edgeBack - edgeFront)) / 4;
  // allocate memory for UVs
  const uv = new Float32Array(length * 2);

  const tau = 2 * Math.PI;
  const offset = Math.PI / 2;
  for (let i = 0; i < length; i++) {
    const { x, y, z } = Vector3.FromArray(positions, i * 3);
    const angleFromCenter = (Math.atan2(z, x) + offset + tau) % tau;
    const verticalVector = new Vector2(x, z);

    if (y >= bottomLine || verticalVector.length() > averageRadius * 0.85) {
      const t = Scalar.Clamp((topHeight - y) / (topHeight - bottomLine), 0, 1);
      uv[i * 2] = angleFromCenter / tau; // applying U
      uv[i * 2 + 1] = 1 - t / 2; // applying V
    } else {
      const t = Scalar.Clamp(new Vector2(x, z).length() / (0.9 * averageRadius), 0, 1);
      uv[i * 2] = t * 0.25 * Math.cos(angleFromCenter - Math.PI / 2) + 0.25; // applying U
      const v = t * 0.25 * Math.sin(angleFromCenter - Math.PI / 2) + 0.75; // applying V
      uv[i * 2 + 1] = 1 - v;
    }
  }
  mesh.setVerticesData(VertexBuffer.UV2Kind, uv);
}

/**
 * Get the size of the mesh based on minimum and maximum world vectors.
 * Exclude from this calculation any children that may be attached to this mesh such as :
 * tapholes, trunnions and display-markers (compass at the bottom of the mesh)
 *
 * @param mesh
 */
export function getMeshSize(mesh: TransformNode): Vector3 {
  const { max, min } = mesh.getHierarchyBoundingVectors(false);
  return max.subtract(min);
}

/**
 * Calculates a center of the mesh
 *
 * @param mesh
 * @returns vector to center posint
 */
export function getCenter(mesh: TransformNode): Vector3 {
  const { max, min } = mesh.getHierarchyBoundingVectors();
  return max.add(min).scale(0.5);
}

/**
 * LEGACY CODE FOR REFERENCE : WE NO LONGER NORMALIZE THE SCALING OF THE MESH
 * Scales mesh to fit viewport
 *
 * @param mesh mesh to scale
 * @param scaleFactor scale factor, defaults to 2.0
 */
/*
export function normalizeNodeSize(mesh: TransformNode, scaleFactor = 2): void {
  const size = getMeshSize(mesh);
  const scale = 1 / (Math.max(size.x, size.y, size.z) * scaleFactor);
  const { x, y, z } = mesh.scaling;
  const persistedSignX = Math.sign(x);
  const persistedSignY = Math.sign(y);
  const persistedSignZ = Math.sign(z);
  mesh.scaling.set(persistedSignX * scale, persistedSignY * scale, persistedSignZ * scale);
}

/**
 * In the 3d views and in our calculations we expect the mesh vertices coordinates to be in meters.
 * However some meshes we get from the backend have their vertices coordinates set in millimeters and some others in meters.
 * To establish whether the coordinates are in millimeters or in meters, we measure the mesh bounding box's diagonal length.
 * If the length is rather small (less than the MESH_BOUNDING_BOX_DIAGONAL_LENGTH_IN_METERS_THRESHOLD ), we pressume the values to be in meters and do nothing.
 * If not - which means the values are in millimeters - we scale down the whole mesh 1000 times, effectively turning its vertices coordinates to meters.
 */
export function scaleMeshToMeters(mesh: Mesh) {
  const size = getMeshSize(mesh);
  log.debug('Mesh size : ', size);

  const { minimum, maximum } = mesh.getBoundingInfo().boundingBox;
  const boundingBoxDiagonalLength = Vector3.Distance(minimum, maximum);

  if (boundingBoxDiagonalLength < MESH_BOUNDING_BOX_DIAGONAL_LENGTH_IN_METERS_THRESHOLD) {
    // Mesh vertices positions appear to be in meters - as we want it to be - do nothing
    log.debug('Mesh vertices positions are in meters');
    return;
  }
  log.debug('Mesh vertices positions are in millimeters, converting to meters');
  // Mesh vertices positions  appear to be in millimeters, scale the mesh down 1000 times to convert them to meters.
  const MILLIMETERS_TO_METERS_SCALE = 1 / 1000;
  const { x, y, z } = mesh.scaling;
  const persistedSignX = Math.sign(x);
  const persistedSignY = Math.sign(y);
  const persistedSignZ = Math.sign(z);
  mesh.scaling.set(persistedSignX * MILLIMETERS_TO_METERS_SCALE, persistedSignY * MILLIMETERS_TO_METERS_SCALE, persistedSignZ * MILLIMETERS_TO_METERS_SCALE);
}

export function positionModelInCenter(mesh: TransformNode): void {
  const { y: heightCentre } = getCenter(mesh);
  mesh.position.subtractInPlace(new Vector3(0, heightCentre, 0));
}

/**
 * Removes a root node from the scene
 *
 * @param assets
 * @returns
 */
export function cleanUpGLTFAssets(loadedMeshes: [AbstractMesh, AbstractMesh]): Mesh {
  const [rootMesh, mainMesh] = loadedMeshes;

  mainMesh.setParent(null);
  rootMesh.dispose(true, true);

  // we know that we deal with Meshes instead of Abstract meshes
  return mainMesh as Mesh;
}

/**
 * Disable all meshes in the babylon scene
 * @param scene Babylon scene
 */
export function disableAllMeshes(scene: Scene): void {
  const ladleContainer = scene.getNodeByName(MESH_CONTAINER_NAME);
  ladleContainer?.getChildMeshes().forEach((mesh) => {
    mesh.setEnabled(false);
  });
}

export function disposeAllMeshes(scene: Scene): void {
  const ladleContainer = scene.getNodeByName(MESH_CONTAINER_NAME);
  ladleContainer?.getChildMeshes().forEach((mesh) => {
    // disposing a texture associated with the mesh by name
    scene.getTextureByName(mesh.name)?.dispose();
    mesh.dispose(false, false);
  });
}

export enum APPLIED_ENHANCERS {
  GEOMETRY = 'GEOMETRY_ENHANCER',
  THICKNESS_MAP = 'THICKNESS_MAP_ENHANCER',
}

/**
 * Applies thickness map on each vertex.
 * The indexes for thicknessMap array should be aligned with model indices
 *
 * @param thicknessMap
 * @returns
 */
export function withThicknessMapEnhancer(thicknessMap: Float32Array): (mesh: Mesh) => Mesh {
  return (mesh: Mesh) => {
    // mesh should react on click in case when RBL/wear values are present
    mesh.isPickable = true;
    if (isDefined(mesh.metadata[APPLIED_ENHANCERS.THICKNESS_MAP])) {
      // this mesh already has the Thickness-map related enhancements applied to it, just return the mesh as is
      return mesh;
    }
    const totalVerticesCount = mesh.getTotalVertices();
    assert(
      thicknessMap.length === totalVerticesCount,
      `Amount of vertices (${totalVerticesCount}) and length of thickness map (${thicknessMap.length}) are misaligned`
    );

    const thicknessBuffer = new VertexBuffer(mesh.getEngine(), thicknessMap, 'thickness', false, false, 1);
    mesh.setVerticesBuffer(thicknessBuffer);
    // mark this mesh as having its THICKNESS_MAP related enhancements set
    mesh.metadata = { ...mesh.metadata, [APPLIED_ENHANCERS.THICKNESS_MAP]: true };
    return mesh;
  };
}

export function prepareGeometryEnhancer(uniqueMeshName: string): (mesh: Mesh) => Mesh {
  return (mesh: Mesh) => {
    if (isDefined(mesh.metadata[APPLIED_ENHANCERS.GEOMETRY])) {
      // this mesh already has the Geometry related enhancements applied to it, just return the mesh as is
      return mesh;
    }

    mesh.name = uniqueMeshName;
    // if vertex color has alpha channel, babylon forces to use alpha blending
    // forcing hasVertexAlpha to false we make sure that depth buffer is used for renders
    mesh.hasVertexAlpha = false;

    // rotate the model to the initial position
    mesh.rotationQuaternion = Quaternion.RotationYawPitchRoll(DEFAULT_3D_MODEL_ROTATION_AXIS_Y, -Math.PI / 2, 0);

    // scale the mesh
    scaleMeshToMeters(mesh);

    positionModelInCenter(mesh);

    // computes positions and UV according to world matrix
    // resets world matrix
    calculateVertexData(mesh);

    // freezing WM and normals to avoid re computations
    mesh.freezeWorldMatrix();
    mesh.freezeNormals();

    // toggle mesh visibility
    mesh.setEnabled(false);
    // mark this mesh as having its GEOMETRY related enhancements set
    mesh.metadata = { ...mesh.metadata, [APPLIED_ENHANCERS.GEOMETRY]: true };

    return mesh;
  };
}

const SIZE = 1;
const BUTTON_OUTLINE_COLOR = '#b1bbc2';
const BUTTON_TEXT_COLOR = '#003262';
const BUTTON_TEXT_HOVERED_COLOR = '#1e5894';
const DASHED_LINE_WIDTH = 25; // pixels
const DASHED_LINE_TOP_MARGIN = 175; // pixels
const DASHED_LINE_BOTTOM_MARGIN = 125; // pixels

function createFace(
  orientation: CameraOrientation,
  text: string,
  line: Line | null,
  position: Vector3,
  rotation: Vector3,
  scene: Scene,
  onClicked: (orientation: CameraOrientation) => void,
  onEntered: () => void,
  onExited: () => void
) {
  const face = MeshBuilder.CreatePlane(`gizmo_${orientation}`, { size: SIZE }, scene);
  face.position = position;
  face.rotation = rotation;
  face.isPickable = true;

  const advancedTexture = AdvancedDynamicTexture.CreateForMesh(face);

  // Add a button
  const button = Button.CreateSimpleButton('face', '');
  button.color = BUTTON_OUTLINE_COLOR;
  button.background = 'white';
  button.thickness = 6;
  button.hoverCursor = 'pointer';
  let isHover = false;
  button.onPointerEnterObservable.add(() => {
    isHover = true;
    button.background = '#eceef0';
    button.color = BUTTON_TEXT_HOVERED_COLOR;
    onEntered();
  });
  let clicking = false;
  button.onPointerOutObservable.add(() => {
    isHover = false;
    button.background = 'white';
    button.color = BUTTON_TEXT_COLOR;
    onExited();
  });
  button.onPointerMoveObservable.add(() => {
    clicking = false;
  });

  /**
   * We need to handle pointer events manually because we want to avoid the hidden meshes
   * Currently when we exclude meshes from rendering, they are still picable on the gizmo
   * camera. For this we check if we are hovering the correct mesh (button) and when clicking/tapping
   * event happens, we can trigger a button pressing.
   */
  scene.onPointerObservable.add((eventData) => {
    if (eventData.type === PointerEventTypes.POINTERTAP) {
      if (isHover) {
        clicking = true;
      }
    } else if (eventData.type === PointerEventTypes.POINTERUP) {
      if (clicking) {
        onClicked(orientation);
        clicking = false;
      }
    }
  });

  button.pointerEnterAnimation = () => {
    /* override default animation */
  };
  button.pointerDownAnimation = () => {
    /* override default animation */
  };
  button.pointerUpAnimation = () => {
    /* override default animation */
  };
  advancedTexture.addControl(button);

  // Add dash line in the front/top/back faces
  if (line) {
    line.isPointerBlocker = true;
    advancedTexture.addControl(line);
  }

  // Add text
  const buttonText = new TextBlock();
  buttonText.text = text;
  buttonText.color = BUTTON_TEXT_COLOR;
  buttonText.fontSize = 200;
  buttonText.fontFamily = 'NortW05-Regular';
  buttonText.isHitTestVisible = false;
  advancedTexture.addControl(buttonText);

  return face;
}

function createDashedLine(x1: number, y1: number, x2: number, y2: number) {
  const line = new Line();
  line.x1 = x1;
  line.y1 = y1;
  line.x2 = x2;
  line.y2 = y2;
  line.lineWidth = DASHED_LINE_WIDTH;
  line.dash = [50, 50];
  line.color = BUTTON_OUTLINE_COLOR;
  return line;
}

export function createGizmoBox(
  scene3dApi: Scene3dApiFacade,
  onClicked: (orientation: CameraOrientation) => void,
  layerMask: number,
  translate: TFunction<'visualization3d'>
) {
  const FACE_SIZE_PIXELS = 1000;
  const buttons = [];
  const scene = scene3dApi.getScene();

  let buttonsEnteredCount = 0;

  function setIsGizmoHovering() {
    scene3dApi.isHoveringGizmo = buttonsEnteredCount !== 0;
  }

  function onButtonEntered() {
    buttonsEnteredCount++;
    setIsGizmoHovering();
  }
  function onButtonExited() {
    buttonsEnteredCount--;
    setIsGizmoHovering();
  }

  buttons.push(
    createFace(
      'FRONT',
      translate('ladle3dViewControls.navigationProxyDice.front', { ns: 'visualization3d' }),
      createDashedLine(FACE_SIZE_PIXELS / 2, 0, FACE_SIZE_PIXELS / 2, FACE_SIZE_PIXELS),
      new Vector3(0, 0, -SIZE / 2),
      new Vector3(0, 0, 0),
      scene,
      onClicked,
      onButtonEntered,
      onButtonExited
    )
  );
  buttons.push(
    createFace(
      'BACK',
      translate('ladle3dViewControls.navigationProxyDice.back', { ns: 'visualization3d' }),
      null,
      new Vector3(0, 0, SIZE / 2),
      new Vector3(0, Math.PI, 0),
      scene,
      onClicked,
      onButtonEntered,
      onButtonExited
    )
  );
  buttons.push(
    createFace(
      'RIGHT',
      translate('ladle3dViewControls.navigationProxyDice.right', { ns: 'visualization3d' }),
      null,
      new Vector3(SIZE / 2, 0, 0),
      new Vector3(0, -Math.PI / 2, 0),
      scene,
      onClicked,
      onButtonEntered,
      onButtonExited
    )
  );
  buttons.push(
    createFace(
      'LEFT',
      translate('ladle3dViewControls.navigationProxyDice.left', { ns: 'visualization3d' }),
      null,
      new Vector3(-SIZE / 2, 0, 0),
      new Vector3(0, Math.PI / 2, 0),
      scene,
      onClicked,
      onButtonEntered,
      onButtonExited
    )
  );
  buttons.push(
    createFace(
      'TOP',
      translate('ladle3dViewControls.navigationProxyDice.top', { ns: 'visualization3d' }),
      createDashedLine(FACE_SIZE_PIXELS / 2, FACE_SIZE_PIXELS / 2 + DASHED_LINE_TOP_MARGIN, FACE_SIZE_PIXELS / 2, FACE_SIZE_PIXELS),
      new Vector3(0, SIZE / 2, 0),
      new Vector3(Math.PI / 2, 0, 0),
      scene,
      onClicked,
      onButtonEntered,
      onButtonExited
    )
  );
  buttons.push(
    createFace(
      'BOTTOM',
      translate('ladle3dViewControls.navigationProxyDice.bottom', { ns: 'visualization3d' }),
      createDashedLine(FACE_SIZE_PIXELS / 2, 0, FACE_SIZE_PIXELS / 2, FACE_SIZE_PIXELS / 2 - DASHED_LINE_BOTTOM_MARGIN),
      new Vector3(0, -SIZE / 2, 0),
      new Vector3(-Math.PI / 2, 0, 0),
      scene,
      onClicked,
      onButtonEntered,
      onButtonExited
    )
  );

  const gizmo = new TransformNode('Gizmo', scene3dApi.getScene());
  buttons.forEach((button) => {
    button.layerMask = layerMask;
    button.parent = gizmo;
  });
}

export function createCircleVertexData(radius = 1, segments = 36): VertexData {
  if (segments < 3) {
    throw Error('circle cannot have less then 3 segments');
  }

  let pointerToLast = 1;
  const vertices = [0, 0, 0, radius, 0, 0];
  const uvs = [0, 0, 1, 0];
  const indices = [];
  const angle = (2 * Math.PI) / segments;

  for (let i = 1; i <= segments; i++) {
    const u = Math.cos(i * angle);
    const v = Math.sin(i * angle);
    const x = radius * u;
    const z = radius * v;
    vertices.push(x, 0, z);
    uvs.push(u, v);

    if (i === segments) {
      indices.push(0, pointerToLast, 1);
    } else {
      indices.push(0, pointerToLast, ++pointerToLast);
    }
  }

  for (let i = 0; i < uvs.length / 2; i++) {
    const u = uvs[2 * i];
    const v = uvs[2 * i + 1];

    uvs[2 * i] = (u! + 1) / 2;
    uvs[2 * i + 1] = (v! + 1) / 2;
  }

  const buffer = new VertexData();
  buffer.positions = vertices;
  buffer.indices = indices;
  buffer.uvs = uvs;
  buffer.normals = [];
  VertexData.ComputeNormals(vertices, indices, buffer.normals);

  return buffer;
}

/**
 * Takes 3 points of a triangle and a point which should be interpolated
 * Returns a vector with barycentric volumes of the point P in scope of
 * triangle v0-v1-v2
 *
 * Note: Calculations are done for left-handed system to align with BabylonJS
 *
 * @param v0 Vector3
 * @param v1 Vector3
 * @param v2 Vector3
 * @param p Vector3
 */
export function getBarycentricValues(v0: Vector3, v1: Vector3, v2: Vector3, p: Vector3): Vector3 {
  const v0v1 = v1.subtract(v0);
  const v0v2 = v2.subtract(v0);
  const v1v2 = v2.subtract(v1);
  const v0p = p.subtract(v0);
  const v1p = p.subtract(v1);

  const commonDoubleArea = Vector3.Cross(v0v1, v0v2).lengthSquared();
  const v0BarycentricArea = Vector3.Cross(v1p, v1v2).lengthSquared();
  const v1BarycentricArea = Vector3.Cross(v0p, v0v2).lengthSquared();
  const v2BarycentricArea = Vector3.Cross(v1p, v0v1).lengthSquared();

  return new Vector3(
    Math.sqrt(v0BarycentricArea / commonDoubleArea),
    Math.sqrt(v1BarycentricArea / commonDoubleArea),
    Math.sqrt(v2BarycentricArea / commonDoubleArea)
  );
}

export function applyBarycentricOnScalar(a: number, b: number, c: number, barycentricCoordinate: Vector3): number {
  const { x, y, z } = barycentricCoordinate;
  return a * x + b * y + c * z;
}

export function getUniqueNameForModel(modelType: 'mesh' | 'pointCloud', measurementId: string) {
  return `${modelType}-${measurementId}`;
}

export function getMeshWorldSize(mesh: AbstractMesh): Vector3 {
  const { minimumWorld, maximumWorld } = mesh.getBoundingInfo().boundingBox;
  return maximumWorld.subtract(minimumWorld);
}
