import { FC } from 'react';

type RangePart = number | string;
/**
 * @example
 *
 * ```js
 * const from = 23.3;
 * const to = 32.3;
 *
 * getFormattedRange(from, to); // => "23.3–32.3"
 * ```
 */
export const getFormattedRange = (from?: RangePart, to?: RangePart) => `${from}–${to}`;

/**
 * Returns a fragment containing a range separated by an en dash
 * @param from a number or a string
 * @param to a number or a string
 */
interface FormattedRangeProps {
  from?: RangePart;
  to?: RangePart;
}

export const FormattedRange: FC<React.PropsWithChildren<FormattedRangeProps>> = ({ from, to }) => {
  return <>{getFormattedRange(from, to)}</>;
};
