/* tslint:disable */
/* eslint-disable */
/**
 * External Customer Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMAPOCommonDataCustomerManagementContactType = {
    IngressSucessRecipient: 'IngressSucessRecipient',
    IngressFailureRecipient: 'IngressFailureRecipient',
    WearReportRecipient: 'WearReportRecipient',
    IngressMailSender: 'IngressMailSender',
    OperationsNotificationRecipient: 'OperationsNotificationRecipient',
    AlertRecipient: 'AlertRecipient'
} as const;

export type RHIMAPOCommonDataCustomerManagementContactType = typeof RHIMAPOCommonDataCustomerManagementContactType[keyof typeof RHIMAPOCommonDataCustomerManagementContactType];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMAPOCommonDataCustomerManagementFeature = {
    LegacyRhView: 'LegacyRhView',
    QckLight: 'QCKLight',
    WearExplorer: 'WearExplorer',
    ParameterComparison: 'ParameterComparison',
    OperatorDisplay: 'OperatorDisplay',
    VolumeCalculations: 'VolumeCalculations'
} as const;

export type RHIMAPOCommonDataCustomerManagementFeature = typeof RHIMAPOCommonDataCustomerManagementFeature[keyof typeof RHIMAPOCommonDataCustomerManagementFeature];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMAPOCoreWebRolesCustomerSpecificRole = {
    Operator: 'Operator',
    ProcessEngineer: 'ProcessEngineer',
    TechnicalMarketing: 'TechnicalMarketing',
    Onboarding: 'Onboarding',
    DataReader: 'DataReader'
} as const;

export type RHIMAPOCoreWebRolesCustomerSpecificRole = typeof RHIMAPOCoreWebRolesCustomerSpecificRole[keyof typeof RHIMAPOCoreWebRolesCustomerSpecificRole];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMAPOCoreWebRolesGlobalRole = {
    Administrator: 'Administrator',
    Onboarding: 'Onboarding',
    MasterDataApiReader: 'MasterDataApiReader',
    ProductOwner: 'ProductOwner'
} as const;

export type RHIMAPOCoreWebRolesGlobalRole = typeof RHIMAPOCoreWebRolesGlobalRole[keyof typeof RHIMAPOCoreWebRolesGlobalRole];


/**
 * 
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsContactPersonDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsContactPersonDto {
    /**
     * User\'s identifier.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsContactPersonDto
     */
    'id': string;
    /**
     * User\'s first name.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsContactPersonDto
     */
    'firstName'?: string | null;
    /**
     * User\'s last name.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsContactPersonDto
     */
    'lastName'?: string | null;
    /**
     * User\'s job.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsContactPersonDto
     */
    'job'?: string | null;
    /**
     * User\'s  email address.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsContactPersonDto
     */
    'emailAddress'?: string | null;
    /**
     * User\'s phone.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsContactPersonDto
     */
    'phone'?: string | null;
    /**
     * User\'s notes.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsContactPersonDto
     */
    'notes'?: string | null;
    /**
     * User\'s contact types.
     * @type {Array<RHIMAPOCommonDataCustomerManagementContactType>}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsContactPersonDto
     */
    'contactTypes'?: Array<RHIMAPOCommonDataCustomerManagementContactType> | null;
}
/**
 * Describe which feature is to be assigned to a customer.
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsCustomerAddFeatureRequestDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsCustomerAddFeatureRequestDto {
    /**
     * 
     * @type {RHIMAPOCommonDataCustomerManagementFeature}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerAddFeatureRequestDto
     */
    'feature': RHIMAPOCommonDataCustomerManagementFeature;
}


/**
 * 
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDto {
    /**
     * If this value is provided the Id is forced to be this value.  If it is null or omitted the Guid is generated randomly
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDto
     */
    'id'?: string | null;
    /**
     * The short unique name of the customer
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDto
     */
    'shortName': string;
    /**
     * This is the full name of the customer (not the unique short name)
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDto
     */
    'displayName': string;
    /**
     * 
     * @type {RHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDtoLocation}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDto
     */
    'location'?: RHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDtoLocation | null;
}
/**
 * Customer\'s location. Usually it is location of the plant or country.  Based on customer location is calculated customer time zone.                When not provided [0,0] is used as a default value.
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDtoLocation
 */
export interface RHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDtoLocation {
    /**
     * Gets or sets the latitude.  Value must be between -90 and 90 degrees.
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDtoLocation
     */
    'latitude': number;
    /**
     * Gets or sets the longitude.  Value must be between -180 and 180 degrees.
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDtoLocation
     */
    'longitude': number;
}
/**
 * 
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsCustomerDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsCustomerDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerDto
     */
    'shortName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerDto
     */
    'obfuscatedShortName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerDto
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {RHIMAPOCustomerManagementWebV2ModelsCustomerDtoLocation}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerDto
     */
    'location'?: RHIMAPOCustomerManagementWebV2ModelsCustomerDtoLocation | null;
    /**
     * The IANA Timezone name of the customer - calculated from the RHIM.APO.CustomerManagement.Web.V2.Models.CustomerDto.Location property
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerDto
     */
    'timeZoneName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerDto
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerDto
     */
    'regionCode'?: string | null;
}
/**
 * The customer location.
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsCustomerDtoLocation
 */
export interface RHIMAPOCustomerManagementWebV2ModelsCustomerDtoLocation {
    /**
     * Gets or sets the latitude.  Value must be between -90 and 90 degrees.
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerDtoLocation
     */
    'latitude': number;
    /**
     * Gets or sets the longitude.  Value must be between -180 and 180 degrees.
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerDtoLocation
     */
    'longitude': number;
}
/**
 * 
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto
     */
    'customerAssignments'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsCustomerGroupDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsCustomerGroupDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerGroupDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerGroupDto
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerGroupDto
     */
    'customerAssignments'?: Array<string> | null;
}
/**
 * The customer location.
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsCustomerLocationDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsCustomerLocationDto {
    /**
     * Gets or sets the latitude.  Value must be between -90 and 90 degrees.
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerLocationDto
     */
    'latitude': number;
    /**
     * Gets or sets the longitude.  Value must be between -180 and 180 degrees.
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerLocationDto
     */
    'longitude': number;
}
/**
 * 
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsCustomerOverviewDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsCustomerOverviewDto {
    /**
     * The id of the customer
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerOverviewDto
     */
    'id': string;
    /**
     * The short unique name of the customer
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerOverviewDto
     */
    'shortName'?: string | null;
    /**
     * This is the full name of the customer (not the unique short name)
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerOverviewDto
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {RHIMAPOCustomerManagementWebV2ModelsCustomerOverviewDtoLocation}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerOverviewDto
     */
    'location'?: RHIMAPOCustomerManagementWebV2ModelsCustomerOverviewDtoLocation | null;
    /**
     * The IANA Timezone name of the customer - calculated from the RHIM.APO.CustomerManagement.Web.V2.Models.CustomerOverviewDto.Location property
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerOverviewDto
     */
    'timeZoneName'?: string | null;
    /**
     * The fleets of the customer
     * @type {{ [key: string]: number; }}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerOverviewDto
     */
    'fleet'?: { [key: string]: number; } | null;
    /**
     * The amount of user which have a direct role assignment to this customer
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerOverviewDto
     */
    'userCount': number;
}
/**
 * The customer location
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsCustomerOverviewDtoLocation
 */
export interface RHIMAPOCustomerManagementWebV2ModelsCustomerOverviewDtoLocation {
    /**
     * Gets or sets the latitude.  Value must be between -90 and 90 degrees.
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerOverviewDtoLocation
     */
    'latitude': number;
    /**
     * Gets or sets the longitude.  Value must be between -180 and 180 degrees.
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerOverviewDtoLocation
     */
    'longitude': number;
}
/**
 * Describe which feature of is to be removed from a customer.
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsCustomerRemoveFeatureRequestDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsCustomerRemoveFeatureRequestDto {
    /**
     * 
     * @type {RHIMAPOCommonDataCustomerManagementFeature}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerRemoveFeatureRequestDto
     */
    'feature': RHIMAPOCommonDataCustomerManagementFeature;
}


/**
 * 
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsCustomerRoleAssignmentDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsCustomerRoleAssignmentDto {
    /**
     * Id of the User
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerRoleAssignmentDto
     */
    'userId': string;
    /**
     * Id of the customer
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerRoleAssignmentDto
     */
    'customerId': string;
    /**
     * 
     * @type {RHIMAPOCoreWebRolesCustomerSpecificRole}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerRoleAssignmentDto
     */
    'customerRole': RHIMAPOCoreWebRolesCustomerSpecificRole;
}


/**
 * 
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto {
    /**
     * Id of the customer to identify in customer management
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto
     */
    'customerId': string;
    /**
     * The short name of the customer
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto
     */
    'customerShortName'?: string | null;
    /**
     * The display name of the customer
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto
     */
    'customerDisplayName'?: string | null;
    /**
     * Distinct list of existing RHIM.Contracts.VesselTypes for that customer.
     * @type {Array<string>}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto
     */
    'vesselTypes'?: Array<string> | null;
    /**
     * The features which are enabled for this customer
     * @type {Array<RHIMAPOCommonDataCustomerManagementFeature>}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto
     */
    'features'?: Array<RHIMAPOCommonDataCustomerManagementFeature> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto
     */
    'operations'?: Array<string> | null;
    /**
     * An optional customerId from the customer portal. Can be used to identify the customer in the customer portal.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto
     */
    'customerPortalCustomerId'?: string | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsCustomerUpdateRequestDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsCustomerUpdateRequestDto {
    /**
     * This is the full name of the customer (not the unique short name)
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerUpdateRequestDto
     */
    'displayName': string;
    /**
     * 
     * @type {RHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDtoLocation}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsCustomerUpdateRequestDto
     */
    'location'?: RHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDtoLocation | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsExternalReferencesDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsExternalReferencesDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsExternalReferencesDto
     */
    'customerPortalId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsExternalReferencesDto
     */
    'crmNumber'?: string | null;
}
/**
 * APO roles.
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsRolesDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsRolesDto {
    /**
     * APO customer roles.
     * @type {Array<RHIMAPOCoreWebRolesCustomerSpecificRole>}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsRolesDto
     */
    'customerRoles'?: Array<RHIMAPOCoreWebRolesCustomerSpecificRole> | null;
    /**
     * APO global roles.
     * @type {Array<RHIMAPOCoreWebRolesGlobalRole>}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsRolesDto
     */
    'globalRoles'?: Array<RHIMAPOCoreWebRolesGlobalRole> | null;
}
/**
 * Describe which user\'s should be added as a contact person
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto {
    /**
     * User Id.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto
     */
    'userId': string;
    /**
     * User contact types.
     * @type {Array<RHIMAPOCommonDataCustomerManagementContactType>}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto
     */
    'contactTypes': Array<RHIMAPOCommonDataCustomerManagementContactType>;
}
/**
 * 
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsUpdateExternalReferencesDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsUpdateExternalReferencesDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUpdateExternalReferencesDto
     */
    'customerPortalId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUpdateExternalReferencesDto
     */
    'crmNumber'?: string | null;
}
/**
 * User data to create a new user.
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsUserCreateRequestDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsUserCreateRequestDto {
    /**
     * User\'s first name.  The field is required.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserCreateRequestDto
     */
    'firstName': string;
    /**
     * User\'s last name.  The field is required.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserCreateRequestDto
     */
    'lastName': string;
    /**
     * User\'s job.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserCreateRequestDto
     */
    'job'?: string | null;
    /**
     * User\'s  email address.  The field is required and must be unique.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserCreateRequestDto
     */
    'emailAddress': string;
    /**
     * User\'s phone.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserCreateRequestDto
     */
    'phone'?: string | null;
    /**
     * User\'s notes.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserCreateRequestDto
     */
    'notes'?: string | null;
    /**
     * Not active users are not allowed to login
     * @type {boolean}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserCreateRequestDto
     */
    'isActive': boolean;
}
/**
 * Current user\'s customer APO role.
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsUserCustomerGroupRoleDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsUserCustomerGroupRoleDto {
    /**
     * Customer group id.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserCustomerGroupRoleDto
     */
    'customerGroupId': string;
    /**
     * Customer Group name.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserCustomerGroupRoleDto
     */
    'customerGroupDisplayName'?: string | null;
    /**
     * Customer role.
     * @type {Array<RHIMAPOCoreWebRolesCustomerSpecificRole>}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserCustomerGroupRoleDto
     */
    'customerGroupRoles'?: Array<RHIMAPOCoreWebRolesCustomerSpecificRole> | null;
}
/**
 * Current user\'s customer APO role.
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsUserCustomerRoleDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsUserCustomerRoleDto {
    /**
     * Customer id.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserCustomerRoleDto
     */
    'customerId': string;
    /**
     * Customer name.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserCustomerRoleDto
     */
    'customerDisplayName'?: string | null;
    /**
     * Customer role.
     * @type {Array<RHIMAPOCoreWebRolesCustomerSpecificRole>}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserCustomerRoleDto
     */
    'customerRoles'?: Array<RHIMAPOCoreWebRolesCustomerSpecificRole> | null;
}
/**
 * User details.
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsUserDetailDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsUserDetailDto {
    /**
     * 
     * @type {RHIMAPOCustomerManagementWebV2ModelsUserDetailDtoRoles}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserDetailDto
     */
    'roles'?: RHIMAPOCustomerManagementWebV2ModelsUserDetailDtoRoles | null;
    /**
     * Latest date and time when user has accepted terms of use for apo portal.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserDetailDto
     */
    'termsOfUseAcceptedAtUtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserDetailDto
     */
    'lastLoggedIn'?: string | null;
    /**
     * User\'s active directory object identifier.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserDetailDto
     */
    'aadObjectId'?: string | null;
    /**
     * User\'s identifier.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserDetailDto
     */
    'id': string;
    /**
     * User\'s first name.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserDetailDto
     */
    'firstName'?: string | null;
    /**
     * User\'s last name.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserDetailDto
     */
    'lastName'?: string | null;
    /**
     * User\'s job.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserDetailDto
     */
    'job'?: string | null;
    /**
     * User\'s  email address.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserDetailDto
     */
    'emailAddress'?: string | null;
    /**
     * User\'s phone.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserDetailDto
     */
    'phone'?: string | null;
    /**
     * User\'s notes.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserDetailDto
     */
    'notes'?: string | null;
    /**
     * Not active users are not allowed to login
     * @type {boolean}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserDetailDto
     */
    'isActive': boolean;
}
/**
 * User roles.
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsUserDetailDtoRoles
 */
export interface RHIMAPOCustomerManagementWebV2ModelsUserDetailDtoRoles {
    /**
     * Global roles.
     * @type {Array<RHIMAPOCoreWebRolesGlobalRole>}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserDetailDtoRoles
     */
    'globalRoles'?: Array<RHIMAPOCoreWebRolesGlobalRole> | null;
    /**
     * Customer roles.
     * @type {Array<RHIMAPOCustomerManagementWebV2ModelsUserCustomerRoleDto>}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserDetailDtoRoles
     */
    'customerRoles'?: Array<RHIMAPOCustomerManagementWebV2ModelsUserCustomerRoleDto> | null;
    /**
     * Customer Group roles.
     * @type {Array<RHIMAPOCustomerManagementWebV2ModelsUserCustomerGroupRoleDto>}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserDetailDtoRoles
     */
    'customerGroupRoles'?: Array<RHIMAPOCustomerManagementWebV2ModelsUserCustomerGroupRoleDto> | null;
}
/**
 * Current user data.
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsUserDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsUserDto {
    /**
     * User\'s active directory object identifier.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserDto
     */
    'aadObjectId'?: string | null;
    /**
     * User\'s identifier.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserDto
     */
    'id': string;
    /**
     * User\'s first name.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserDto
     */
    'firstName'?: string | null;
    /**
     * User\'s last name.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserDto
     */
    'lastName'?: string | null;
    /**
     * User\'s job.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserDto
     */
    'job'?: string | null;
    /**
     * User\'s  email address.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserDto
     */
    'emailAddress'?: string | null;
    /**
     * User\'s phone.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserDto
     */
    'phone'?: string | null;
    /**
     * User\'s notes.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserDto
     */
    'notes'?: string | null;
    /**
     * Not active users are not allowed to login
     * @type {boolean}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserDto
     */
    'isActive': boolean;
}
/**
 * A brief information about the user
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto {
    /**
     * The customers that are assigned to the user. In case of !:GlobalRole.Administrator all plants will be returned.
     * @type {Array<RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto>}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto
     */
    'customers'?: Array<RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto
     */
    'globalOperations'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto
     */
    'userSettings'?: string | null;
}
/**
 * Current user APO roles.
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsUserRolesDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsUserRolesDto {
    /**
     * Global roles.
     * @type {Array<RHIMAPOCoreWebRolesGlobalRole>}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserRolesDto
     */
    'globalRoles'?: Array<RHIMAPOCoreWebRolesGlobalRole> | null;
    /**
     * Customer roles.
     * @type {Array<RHIMAPOCustomerManagementWebV2ModelsUserCustomerRoleDto>}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserRolesDto
     */
    'customerRoles'?: Array<RHIMAPOCustomerManagementWebV2ModelsUserCustomerRoleDto> | null;
    /**
     * Customer Group roles.
     * @type {Array<RHIMAPOCustomerManagementWebV2ModelsUserCustomerGroupRoleDto>}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserRolesDto
     */
    'customerGroupRoles'?: Array<RHIMAPOCustomerManagementWebV2ModelsUserCustomerGroupRoleDto> | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsUserSettingsDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsUserSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserSettingsDto
     */
    'uiSettings'?: string | null;
}
/**
 * User customer role to update.
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsUserUpdateCustomerGroupRoleRequestDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsUserUpdateCustomerGroupRoleRequestDto {
    /**
     * Customer id
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserUpdateCustomerGroupRoleRequestDto
     */
    'customerGroupId': string;
    /**
     * Customer roles
     * @type {Array<RHIMAPOCoreWebRolesCustomerSpecificRole>}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserUpdateCustomerGroupRoleRequestDto
     */
    'customerGroupRoles': Array<RHIMAPOCoreWebRolesCustomerSpecificRole>;
}
/**
 * User customer role to update.
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsUserUpdateCustomerRoleRequestDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsUserUpdateCustomerRoleRequestDto {
    /**
     * Customer id
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserUpdateCustomerRoleRequestDto
     */
    'customerId': string;
    /**
     * Customer roles
     * @type {Array<RHIMAPOCoreWebRolesCustomerSpecificRole>}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserUpdateCustomerRoleRequestDto
     */
    'customerRoles': Array<RHIMAPOCoreWebRolesCustomerSpecificRole>;
}
/**
 * User data to update.
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsUserUpdateRequestDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsUserUpdateRequestDto {
    /**
     * User\'s first name.  The field is required.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserUpdateRequestDto
     */
    'firstName': string;
    /**
     * User\'s last name.  The field is required.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserUpdateRequestDto
     */
    'lastName': string;
    /**
     * User\'s job.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserUpdateRequestDto
     */
    'job'?: string | null;
    /**
     * User\'s  email address.  The field is required and must be unique.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserUpdateRequestDto
     */
    'emailAddress': string;
    /**
     * User\'s phone.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserUpdateRequestDto
     */
    'phone'?: string | null;
    /**
     * User\'s notes.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserUpdateRequestDto
     */
    'notes'?: string | null;
    /**
     * Not active users are not allowed to login
     * @type {boolean}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserUpdateRequestDto
     */
    'isActive': boolean;
}
/**
 * Describe user\'s global and customer roles
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsUserUpdateRolesRequestDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsUserUpdateRolesRequestDto {
    /**
     * User\'s global roles.
     * @type {Array<RHIMAPOCoreWebRolesGlobalRole>}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserUpdateRolesRequestDto
     */
    'globalRoles'?: Array<RHIMAPOCoreWebRolesGlobalRole> | null;
    /**
     * User\'s customer roles.
     * @type {Array<RHIMAPOCustomerManagementWebV2ModelsUserUpdateCustomerRoleRequestDto>}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserUpdateRolesRequestDto
     */
    'customerRoles'?: Array<RHIMAPOCustomerManagementWebV2ModelsUserUpdateCustomerRoleRequestDto> | null;
    /**
     * User\'s customer group roles.
     * @type {Array<RHIMAPOCustomerManagementWebV2ModelsUserUpdateCustomerGroupRoleRequestDto>}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsUserUpdateRolesRequestDto
     */
    'customerGroupRoles'?: Array<RHIMAPOCustomerManagementWebV2ModelsUserUpdateCustomerGroupRoleRequestDto> | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsVesselCreateRequestDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsVesselCreateRequestDto {
    /**
     * Short unique vessel name.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselCreateRequestDto
     */
    'shortName': string;
    /**
     * Long not unique vessel name
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselCreateRequestDto
     */
    'displayName': string;
    /**
     * Expected vessel heats.
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselCreateRequestDto
     */
    'expectedHeats': number;
    /**
     * Vessel type.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselCreateRequestDto
     */
    'vesselType': string;
    /**
     * Customer id vessels belongs to.
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselCreateRequestDto
     */
    'customerId': string;
}
/**
 * 
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsVesselDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsVesselDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselDto
     */
    'shortName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselDto
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselDto
     */
    'expectedHeats': number;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselDto
     */
    'vesselType': string;
    /**
     * 
     * @type {RHIMAPOCustomerManagementWebV2ModelsCustomerDto}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselDto
     */
    'customer'?: RHIMAPOCustomerManagementWebV2ModelsCustomerDto | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesAodVesselPropertiesDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesAodVesselPropertiesDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesAodVesselPropertiesDto
     */
    'outerVesselHeight'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesAodVesselPropertiesDto
     */
    'bottomDepth'?: number | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBfrVesselPropertiesDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBfrVesselPropertiesDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBfrVesselPropertiesDto
     */
    'outerVesselHeight'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBfrVesselPropertiesDto
     */
    'outerVesselLength'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBfrVesselPropertiesDto
     */
    'outerVesselWidth'?: number | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto
     */
    'outerVesselHeight'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto
     */
    'outerVesselDiameter'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto
     */
    'bottomDepth'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto
     */
    'enableKPIsInWms': boolean;
}
/**
 * 
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCouVesselPropertiesDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCouVesselPropertiesDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCouVesselPropertiesDto
     */
    'outerVesselHeight'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCouVesselPropertiesDto
     */
    'outerVesselLength'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCouVesselPropertiesDto
     */
    'outerVesselWidth'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkRunningMeterZeroLocation = {
    Outlet: 'Outlet',
    Inlet: 'Inlet'
} as const;

export type RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkRunningMeterZeroLocation = typeof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkRunningMeterZeroLocation[keyof typeof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkRunningMeterZeroLocation];


/**
 * 
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkVesselPropertiesDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkVesselPropertiesDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkVesselPropertiesDto
     */
    'kilnLengthInMeters'?: number | null;
    /**
     * 
     * @type {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkRunningMeterZeroLocation}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkVesselPropertiesDto
     */
    'runningMeterZeroLocation': RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkRunningMeterZeroLocation;
    /**
     * The diameter of the kiln at different running meters. Between values the diameter is linear.  The list should have always at least 2 values. For the start and the end of the kiln.  Example: [0.0] = 12.0, [60.0] = 12.0 -> A 60m long kiln with a constant diameter of 12m  Example: [0.0] = 10.0, [5.0] = 10.0, [6.0] = 12.0, [10.0] = 12    -> Between 0 and 5m the kiln has 10m diameter. And between 6 and 10m 12m diameter.    In between the is a linear progression from 5 to 6m.
     * @type {Array<RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesInnerKilnDiameter>}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkVesselPropertiesDto
     */
    'innerKilnDiameterSegments'?: Array<RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesInnerKilnDiameter> | null;
}


/**
 * 
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto
     */
    'outerVesselHeight'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto
     */
    'outerVesselDiameter'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto
     */
    'bottomDepth'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto
     */
    'enableKPIsInWms': boolean;
}
/**
 * 
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesHmlVesselPropertiesDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesHmlVesselPropertiesDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesHmlVesselPropertiesDto
     */
    'outerVesselHeight'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesHmlVesselPropertiesDto
     */
    'outerVesselDiameter'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesHmlVesselPropertiesDto
     */
    'innerVesselDiameter'?: number | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesInnerKilnDiameter
 */
export interface RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesInnerKilnDiameter {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesInnerKilnDiameter
     */
    'rm': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesInnerKilnDiameter
     */
    'diameter': number;
}
/**
 * 
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesLadleVesselPropertiesDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesLadleVesselPropertiesDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesLadleVesselPropertiesDto
     */
    'outerVesselHeight'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesLadleVesselPropertiesDto
     */
    'outerVesselDiameter'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesLadleVesselPropertiesDto
     */
    'innerVesselDiameter'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesLadleVesselPropertiesDto
     */
    'bottomDepth'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesLadleVesselPropertiesDto
     */
    'enableKPIsInWms': boolean;
}
/**
 * 
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhVesselPropertiesDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhVesselPropertiesDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhVesselPropertiesDto
     */
    'outerVesselHeight'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhVesselPropertiesDto
     */
    'outerVesselDiameter'?: number | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto
     */
    'outerVesselHeight'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto
     */
    'outerVesselDiameter'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto
     */
    'innerVesselDiameter'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto
     */
    'bottomDepth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto
     */
    'snorkelHeight'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto
     */
    'throatHeight'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto
     * @deprecated
     */
    'throatInOuterDiameter'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto
     * @deprecated
     */
    'throatOutOuterDiameter'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto
     * @deprecated
     */
    'throatInInnerDiameter'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto
     * @deprecated
     */
    'throatOutInnerDiameter'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto
     */
    'snorkelInOuterDiameter'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto
     */
    'snorkelOutOuterDiameter'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto
     */
    'snorkelInInnerDiameter'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto
     */
    'snorkelOutInnerDiameter'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto
     */
    'throatDistance'?: number | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesSrfVesselPropertiesDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesSrfVesselPropertiesDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesSrfVesselPropertiesDto
     */
    'outerVesselHeight'?: number | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOCustomerManagementWebV2ModelsVesselUpdateRequestDto
 */
export interface RHIMAPOCustomerManagementWebV2ModelsVesselUpdateRequestDto {
    /**
     * Long not unique vessel name
     * @type {string}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselUpdateRequestDto
     */
    'displayName': string;
    /**
     * Expected vessel heats.
     * @type {number}
     * @memberof RHIMAPOCustomerManagementWebV2ModelsVesselUpdateRequestDto
     */
    'expectedHeats': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMContractsExternalSystem = {
    CustomerPortal: 'CustomerPortal',
    Crm: 'CRM'
} as const;

export type RHIMContractsExternalSystem = typeof RHIMContractsExternalSystem[keyof typeof RHIMContractsExternalSystem];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMContractsFileType = {
    PostMortem: 'PostMortem',
    ProcessData: 'ProcessData',
    ProcessMetrixFiltered: 'ProcessMetrixFiltered',
    ProcessMetrixRaw: 'ProcessMetrixRaw',
    ProcessMetrixVertex: 'ProcessMetrixVertex',
    ProcessMetrixSummary: 'ProcessMetrixSummary',
    ProcessMetrixZip: 'ProcessMetrixZip',
    Stl: 'STL',
    HarmonizedSummary: 'HarmonizedSummary',
    ProcessDataSplit: 'ProcessDataSplit',
    Pts: 'PTS',
    Clinker: 'Clinker',
    KilnFeed: 'KilnFeed',
    HotMeal: 'HotMeal',
    Lignite: 'Lignite',
    StopLogs: 'StopLogs',
    IrScan: 'IRScan',
    KilnShellCooling: 'KilnShellCooling',
    KilnProcessData: 'KilnProcessData',
    FerrotronSummary: 'FerrotronSummary'
} as const;

export type RHIMContractsFileType = typeof RHIMContractsFileType[keyof typeof RHIMContractsFileType];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMContractsVesselType = {
    Bof: 'BOF',
    Rh: 'RH',
    Eaf: 'EAF',
    Ladle: 'Ladle',
    Crk: 'CRK',
    Rhl: 'RHL',
    Aod: 'AOD',
    Bfr: 'BFR',
    Srf: 'SRF',
    Cou: 'COU',
    Hml: 'HML'
} as const;

export type RHIMContractsVesselType = typeof RHIMContractsVesselType[keyof typeof RHIMContractsVesselType];



/**
 * ContactTypesApi - axios parameter creator
 * @export
 */
export const ContactTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all contact types.
         * @summary Get all contact types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContacttypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ContactTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactTypesApi - functional programming interface
 * @export
 */
export const ContactTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all contact types.
         * @summary Get all contact types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContacttypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMAPOCommonDataCustomerManagementContactType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContacttypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactTypesApi - factory interface
 * @export
 */
export const ContactTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactTypesApiFp(configuration)
    return {
        /**
         * Get all contact types.
         * @summary Get all contact types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContacttypes(options?: any): AxiosPromise<Array<RHIMAPOCommonDataCustomerManagementContactType>> {
            return localVarFp.getContacttypes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactTypesApi - object-oriented interface
 * @export
 * @class ContactTypesApi
 * @extends {BaseAPI}
 */
export class ContactTypesApi extends BaseAPI {
    /**
     * Get all contact types.
     * @summary Get all contact types.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactTypesApi
     */
    public getContacttypes(options?: AxiosRequestConfig) {
        return ContactTypesApiFp(this.configuration).getContacttypes(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CustomerExternalReferencesApi - axios parameter creator
 * @export
 */
export const CustomerExternalReferencesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the external references of the customer
         * @summary Get the external references of the customer
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerexternalreferencesCustomerid: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCustomerexternalreferencesCustomerid', 'customerId', customerId)
            const localVarPath = `/CustomerExternalReferences/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the customerIds that are mapped to a given external reference
         * @summary Get the customerIds that are mapped to a given external reference
         * @param {string} externalSystem 
         * @param {string} foreignKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerexternalreferencesResolveExternalsystemForeignkey: async (externalSystem: string, foreignKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalSystem' is not null or undefined
            assertParamExists('getCustomerexternalreferencesResolveExternalsystemForeignkey', 'externalSystem', externalSystem)
            // verify required parameter 'foreignKey' is not null or undefined
            assertParamExists('getCustomerexternalreferencesResolveExternalsystemForeignkey', 'foreignKey', foreignKey)
            const localVarPath = `/CustomerExternalReferences/resolve/{externalSystem}/{foreignKey}`
                .replace(`{${"externalSystem"}}`, encodeURIComponent(String(externalSystem)))
                .replace(`{${"foreignKey"}}`, encodeURIComponent(String(foreignKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the external references of the customer
         * @summary Updates the external references of the customer
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {RHIMAPOCustomerManagementWebV2ModelsUpdateExternalReferencesDto} [rHIMAPOCustomerManagementWebV2ModelsUpdateExternalReferencesDto] The new external references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomerexternalreferencesCustomerid: async (customerId: string, rHIMAPOCustomerManagementWebV2ModelsUpdateExternalReferencesDto?: RHIMAPOCustomerManagementWebV2ModelsUpdateExternalReferencesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('putCustomerexternalreferencesCustomerid', 'customerId', customerId)
            const localVarPath = `/CustomerExternalReferences/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOCustomerManagementWebV2ModelsUpdateExternalReferencesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerExternalReferencesApi - functional programming interface
 * @export
 */
export const CustomerExternalReferencesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerExternalReferencesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get the external references of the customer
         * @summary Get the external references of the customer
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerexternalreferencesCustomerid(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsExternalReferencesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerexternalreferencesCustomerid(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the customerIds that are mapped to a given external reference
         * @summary Get the customerIds that are mapped to a given external reference
         * @param {string} externalSystem 
         * @param {string} foreignKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerexternalreferencesResolveExternalsystemForeignkey(externalSystem: string, foreignKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerexternalreferencesResolveExternalsystemForeignkey(externalSystem, foreignKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the external references of the customer
         * @summary Updates the external references of the customer
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {RHIMAPOCustomerManagementWebV2ModelsUpdateExternalReferencesDto} [rHIMAPOCustomerManagementWebV2ModelsUpdateExternalReferencesDto] The new external references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCustomerexternalreferencesCustomerid(customerId: string, rHIMAPOCustomerManagementWebV2ModelsUpdateExternalReferencesDto?: RHIMAPOCustomerManagementWebV2ModelsUpdateExternalReferencesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCustomerexternalreferencesCustomerid(customerId, rHIMAPOCustomerManagementWebV2ModelsUpdateExternalReferencesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerExternalReferencesApi - factory interface
 * @export
 */
export const CustomerExternalReferencesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerExternalReferencesApiFp(configuration)
    return {
        /**
         * Get the external references of the customer
         * @summary Get the external references of the customer
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerexternalreferencesCustomerid(customerId: string, options?: any): AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsExternalReferencesDto> {
            return localVarFp.getCustomerexternalreferencesCustomerid(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the customerIds that are mapped to a given external reference
         * @summary Get the customerIds that are mapped to a given external reference
         * @param {string} externalSystem 
         * @param {string} foreignKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerexternalreferencesResolveExternalsystemForeignkey(externalSystem: string, foreignKey: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getCustomerexternalreferencesResolveExternalsystemForeignkey(externalSystem, foreignKey, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the external references of the customer
         * @summary Updates the external references of the customer
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {RHIMAPOCustomerManagementWebV2ModelsUpdateExternalReferencesDto} [rHIMAPOCustomerManagementWebV2ModelsUpdateExternalReferencesDto] The new external references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomerexternalreferencesCustomerid(customerId: string, rHIMAPOCustomerManagementWebV2ModelsUpdateExternalReferencesDto?: RHIMAPOCustomerManagementWebV2ModelsUpdateExternalReferencesDto, options?: any): AxiosPromise<void> {
            return localVarFp.putCustomerexternalreferencesCustomerid(customerId, rHIMAPOCustomerManagementWebV2ModelsUpdateExternalReferencesDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerExternalReferencesApi - object-oriented interface
 * @export
 * @class CustomerExternalReferencesApi
 * @extends {BaseAPI}
 */
export class CustomerExternalReferencesApi extends BaseAPI {
    /**
     * Get the external references of the customer
     * @summary Get the external references of the customer
     * @param {string} customerId Format - uuid. Identifier of the customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerExternalReferencesApi
     */
    public getCustomerexternalreferencesCustomerid(customerId: string, options?: AxiosRequestConfig) {
        return CustomerExternalReferencesApiFp(this.configuration).getCustomerexternalreferencesCustomerid(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the customerIds that are mapped to a given external reference
     * @summary Get the customerIds that are mapped to a given external reference
     * @param {string} externalSystem 
     * @param {string} foreignKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerExternalReferencesApi
     */
    public getCustomerexternalreferencesResolveExternalsystemForeignkey(externalSystem: string, foreignKey: string, options?: AxiosRequestConfig) {
        return CustomerExternalReferencesApiFp(this.configuration).getCustomerexternalreferencesResolveExternalsystemForeignkey(externalSystem, foreignKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the external references of the customer
     * @summary Updates the external references of the customer
     * @param {string} customerId Format - uuid. Identifier of the customer
     * @param {RHIMAPOCustomerManagementWebV2ModelsUpdateExternalReferencesDto} [rHIMAPOCustomerManagementWebV2ModelsUpdateExternalReferencesDto] The new external references
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerExternalReferencesApi
     */
    public putCustomerexternalreferencesCustomerid(customerId: string, rHIMAPOCustomerManagementWebV2ModelsUpdateExternalReferencesDto?: RHIMAPOCustomerManagementWebV2ModelsUpdateExternalReferencesDto, options?: AxiosRequestConfig) {
        return CustomerExternalReferencesApiFp(this.configuration).putCustomerexternalreferencesCustomerid(customerId, rHIMAPOCustomerManagementWebV2ModelsUpdateExternalReferencesDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CustomerGroupsApi - axios parameter creator
 * @export
 */
export const CustomerGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete a customer group
         * @summary Delete a customer group
         * @param {string} customerGroupId Format - uuid. Identifier of the customer group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomergroupsCustomergroupid: async (customerGroupId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerGroupId' is not null or undefined
            assertParamExists('deleteCustomergroupsCustomergroupid', 'customerGroupId', customerGroupId)
            const localVarPath = `/CustomerGroups/{customerGroupId}`
                .replace(`{${"customerGroupId"}}`, encodeURIComponent(String(customerGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all customer groups and their assignments
         * @summary Retrieves all customer groups and their assignments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomergroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/CustomerGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves one customer group and its assignments
         * @summary Retrieves one customer group and its assignments
         * @param {string} customerGroupId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomergroupsCustomergroupid: async (customerGroupId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerGroupId' is not null or undefined
            assertParamExists('getCustomergroupsCustomergroupid', 'customerGroupId', customerGroupId)
            const localVarPath = `/CustomerGroups/{customerGroupId}`
                .replace(`{${"customerGroupId"}}`, encodeURIComponent(String(customerGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a customer group by id
         * @summary Creates a customer group by id
         * @param {RHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto} [rHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCustomergroups: async (rHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto?: RHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/CustomerGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a customer group by id
         * @summary Updates a customer group by id
         * @param {string} customerGroupId Format - uuid.
         * @param {RHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto} [rHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomergroupsCustomergroupid: async (customerGroupId: string, rHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto?: RHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerGroupId' is not null or undefined
            assertParamExists('putCustomergroupsCustomergroupid', 'customerGroupId', customerGroupId)
            const localVarPath = `/CustomerGroups/{customerGroupId}`
                .replace(`{${"customerGroupId"}}`, encodeURIComponent(String(customerGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerGroupsApi - functional programming interface
 * @export
 */
export const CustomerGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete a customer group
         * @summary Delete a customer group
         * @param {string} customerGroupId Format - uuid. Identifier of the customer group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomergroupsCustomergroupid(customerGroupId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomergroupsCustomergroupid(customerGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all customer groups and their assignments
         * @summary Retrieves all customer groups and their assignments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomergroups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMAPOCustomerManagementWebV2ModelsCustomerGroupDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomergroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves one customer group and its assignments
         * @summary Retrieves one customer group and its assignments
         * @param {string} customerGroupId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomergroupsCustomergroupid(customerGroupId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsCustomerGroupDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomergroupsCustomergroupid(customerGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a customer group by id
         * @summary Creates a customer group by id
         * @param {RHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto} [rHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCustomergroups(rHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto?: RHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsCustomerGroupDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCustomergroups(rHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates a customer group by id
         * @summary Updates a customer group by id
         * @param {string} customerGroupId Format - uuid.
         * @param {RHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto} [rHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCustomergroupsCustomergroupid(customerGroupId: string, rHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto?: RHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCustomergroupsCustomergroupid(customerGroupId, rHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerGroupsApi - factory interface
 * @export
 */
export const CustomerGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerGroupsApiFp(configuration)
    return {
        /**
         * Delete a customer group
         * @summary Delete a customer group
         * @param {string} customerGroupId Format - uuid. Identifier of the customer group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomergroupsCustomergroupid(customerGroupId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCustomergroupsCustomergroupid(customerGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all customer groups and their assignments
         * @summary Retrieves all customer groups and their assignments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomergroups(options?: any): AxiosPromise<Array<RHIMAPOCustomerManagementWebV2ModelsCustomerGroupDto>> {
            return localVarFp.getCustomergroups(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves one customer group and its assignments
         * @summary Retrieves one customer group and its assignments
         * @param {string} customerGroupId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomergroupsCustomergroupid(customerGroupId: string, options?: any): AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsCustomerGroupDto> {
            return localVarFp.getCustomergroupsCustomergroupid(customerGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a customer group by id
         * @summary Creates a customer group by id
         * @param {RHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto} [rHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCustomergroups(rHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto?: RHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto, options?: any): AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsCustomerGroupDto> {
            return localVarFp.postCustomergroups(rHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a customer group by id
         * @summary Updates a customer group by id
         * @param {string} customerGroupId Format - uuid.
         * @param {RHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto} [rHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomergroupsCustomergroupid(customerGroupId: string, rHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto?: RHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto, options?: any): AxiosPromise<void> {
            return localVarFp.putCustomergroupsCustomergroupid(customerGroupId, rHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerGroupsApi - object-oriented interface
 * @export
 * @class CustomerGroupsApi
 * @extends {BaseAPI}
 */
export class CustomerGroupsApi extends BaseAPI {
    /**
     * Delete a customer group
     * @summary Delete a customer group
     * @param {string} customerGroupId Format - uuid. Identifier of the customer group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerGroupsApi
     */
    public deleteCustomergroupsCustomergroupid(customerGroupId: string, options?: AxiosRequestConfig) {
        return CustomerGroupsApiFp(this.configuration).deleteCustomergroupsCustomergroupid(customerGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all customer groups and their assignments
     * @summary Retrieves all customer groups and their assignments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerGroupsApi
     */
    public getCustomergroups(options?: AxiosRequestConfig) {
        return CustomerGroupsApiFp(this.configuration).getCustomergroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves one customer group and its assignments
     * @summary Retrieves one customer group and its assignments
     * @param {string} customerGroupId Format - uuid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerGroupsApi
     */
    public getCustomergroupsCustomergroupid(customerGroupId: string, options?: AxiosRequestConfig) {
        return CustomerGroupsApiFp(this.configuration).getCustomergroupsCustomergroupid(customerGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a customer group by id
     * @summary Creates a customer group by id
     * @param {RHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto} [rHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerGroupsApi
     */
    public postCustomergroups(rHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto?: RHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto, options?: AxiosRequestConfig) {
        return CustomerGroupsApiFp(this.configuration).postCustomergroups(rHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a customer group by id
     * @summary Updates a customer group by id
     * @param {string} customerGroupId Format - uuid.
     * @param {RHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto} [rHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerGroupsApi
     */
    public putCustomergroupsCustomergroupid(customerGroupId: string, rHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto?: RHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto, options?: AxiosRequestConfig) {
        return CustomerGroupsApiFp(this.configuration).putCustomergroupsCustomergroupid(customerGroupId, rHIMAPOCustomerManagementWebV2ModelsCustomerGroupCreateOrUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CustomerOverviewApi - axios parameter creator
 * @export
 */
export const CustomerOverviewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves all data for the customer overview
         * @summary Retrieves all data for the customer overview
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomeroverview: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/CustomerOverview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerOverviewApi - functional programming interface
 * @export
 */
export const CustomerOverviewApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerOverviewApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves all data for the customer overview
         * @summary Retrieves all data for the customer overview
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomeroverview(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMAPOCustomerManagementWebV2ModelsCustomerOverviewDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomeroverview(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerOverviewApi - factory interface
 * @export
 */
export const CustomerOverviewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerOverviewApiFp(configuration)
    return {
        /**
         * Retrieves all data for the customer overview
         * @summary Retrieves all data for the customer overview
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomeroverview(options?: any): AxiosPromise<Array<RHIMAPOCustomerManagementWebV2ModelsCustomerOverviewDto>> {
            return localVarFp.getCustomeroverview(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerOverviewApi - object-oriented interface
 * @export
 * @class CustomerOverviewApi
 * @extends {BaseAPI}
 */
export class CustomerOverviewApi extends BaseAPI {
    /**
     * Retrieves all data for the customer overview
     * @summary Retrieves all data for the customer overview
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerOverviewApi
     */
    public getCustomeroverview(options?: AxiosRequestConfig) {
        return CustomerOverviewApiFp(this.configuration).getCustomeroverview(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CustomersApi - axios parameter creator
 * @export
 */
export const CustomersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete a customer by id
         * @summary Delete a customer by id
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomersCustomerid: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('deleteCustomersCustomerid', 'customerId', customerId)
            const localVarPath = `/Customers/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all customers
         * @summary Retrieves all customers
         * @param {string} [ingressContactMailAddress] Filter customers that have a contact person with mail address and type IngressMailSender
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomers: async (ingressContactMailAddress?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ingressContactMailAddress !== undefined) {
                localVarQueryParameter['ingressContactMailAddress'] = ingressContactMailAddress;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a customer by its id
         * @summary Retrieves a customer by its id
         * @param {string} customerId Format - uuid. Id of the customer to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomersCustomerid: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCustomersCustomerid', 'customerId', customerId)
            const localVarPath = `/Customers/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all customer contact persons by customer id
         * @summary Retrieves all customer contact persons by customer id
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomersCustomeridContactpersons: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCustomersCustomeridContactpersons', 'customerId', customerId)
            const localVarPath = `/Customers/{customerId}/contactpersons`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all direct role assignments of a customer.  Global assignments or customer group assignments are not counted
         * @summary Retrieves all direct role assignments of a customer.  Global assignments or customer group assignments are not counted
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomersCustomeridRoleassignments: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCustomersCustomeridRoleassignments', 'customerId', customerId)
            const localVarPath = `/Customers/{customerId}/roleassignments`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                GET /customers/00000000-0000-0000-0001-000000000000/vessels  GET /customers/00000000-0000-0000-0001-000000000000/vessels?vesselType=0
         * @summary Retrieves all vessels which are assigned to a customer
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {string} [vesselType] The optional vesselType to filter the vessels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomersCustomeridVessels: async (customerId: string, vesselType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCustomersCustomeridVessels', 'customerId', customerId)
            const localVarPath = `/Customers/{customerId}/vessels`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (vesselType !== undefined) {
                localVarQueryParameter['vesselType'] = vesselType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new customer
         * @summary Creates a new customer
         * @param {RHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDto} [rHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDto] The json body which contains info about the new customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCustomers: async (rHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDto?: RHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a customer by its id
         * @summary Updates a customer by its id
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {RHIMAPOCustomerManagementWebV2ModelsCustomerUpdateRequestDto} [rHIMAPOCustomerManagementWebV2ModelsCustomerUpdateRequestDto] The updated customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomersCustomerid: async (customerId: string, rHIMAPOCustomerManagementWebV2ModelsCustomerUpdateRequestDto?: RHIMAPOCustomerManagementWebV2ModelsCustomerUpdateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('putCustomersCustomerid', 'customerId', customerId)
            const localVarPath = `/Customers/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOCustomerManagementWebV2ModelsCustomerUpdateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update all customer contact persons by customer id
         * @summary Update all customer contact persons by customer id
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {Array<RHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto>} [rHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto] A list of customer contact persons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomersCustomeridContactpersons: async (customerId: string, rHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto?: Array<RHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('putCustomersCustomeridContactpersons', 'customerId', customerId)
            const localVarPath = `/Customers/{customerId}/contactpersons`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomersApi - functional programming interface
 * @export
 */
export const CustomersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomersApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete a customer by id
         * @summary Delete a customer by id
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomersCustomerid(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomersCustomerid(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all customers
         * @summary Retrieves all customers
         * @param {string} [ingressContactMailAddress] Filter customers that have a contact person with mail address and type IngressMailSender
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomers(ingressContactMailAddress?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMAPOCustomerManagementWebV2ModelsCustomerDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomers(ingressContactMailAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a customer by its id
         * @summary Retrieves a customer by its id
         * @param {string} customerId Format - uuid. Id of the customer to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomersCustomerid(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsCustomerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomersCustomerid(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all customer contact persons by customer id
         * @summary Retrieves all customer contact persons by customer id
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomersCustomeridContactpersons(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMAPOCustomerManagementWebV2ModelsContactPersonDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomersCustomeridContactpersons(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all direct role assignments of a customer.  Global assignments or customer group assignments are not counted
         * @summary Retrieves all direct role assignments of a customer.  Global assignments or customer group assignments are not counted
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomersCustomeridRoleassignments(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMAPOCustomerManagementWebV2ModelsCustomerRoleAssignmentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomersCustomeridRoleassignments(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                GET /customers/00000000-0000-0000-0001-000000000000/vessels  GET /customers/00000000-0000-0000-0001-000000000000/vessels?vesselType=0
         * @summary Retrieves all vessels which are assigned to a customer
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {string} [vesselType] The optional vesselType to filter the vessels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomersCustomeridVessels(customerId: string, vesselType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMAPOCustomerManagementWebV2ModelsVesselDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomersCustomeridVessels(customerId, vesselType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new customer
         * @summary Creates a new customer
         * @param {RHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDto} [rHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDto] The json body which contains info about the new customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCustomers(rHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDto?: RHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsCustomerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCustomers(rHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates a customer by its id
         * @summary Updates a customer by its id
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {RHIMAPOCustomerManagementWebV2ModelsCustomerUpdateRequestDto} [rHIMAPOCustomerManagementWebV2ModelsCustomerUpdateRequestDto] The updated customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCustomersCustomerid(customerId: string, rHIMAPOCustomerManagementWebV2ModelsCustomerUpdateRequestDto?: RHIMAPOCustomerManagementWebV2ModelsCustomerUpdateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCustomersCustomerid(customerId, rHIMAPOCustomerManagementWebV2ModelsCustomerUpdateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update all customer contact persons by customer id
         * @summary Update all customer contact persons by customer id
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {Array<RHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto>} [rHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto] A list of customer contact persons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCustomersCustomeridContactpersons(customerId: string, rHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto?: Array<RHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCustomersCustomeridContactpersons(customerId, rHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomersApi - factory interface
 * @export
 */
export const CustomersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomersApiFp(configuration)
    return {
        /**
         * Delete a customer by id
         * @summary Delete a customer by id
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomersCustomerid(customerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCustomersCustomerid(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all customers
         * @summary Retrieves all customers
         * @param {string} [ingressContactMailAddress] Filter customers that have a contact person with mail address and type IngressMailSender
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomers(ingressContactMailAddress?: string, options?: any): AxiosPromise<Array<RHIMAPOCustomerManagementWebV2ModelsCustomerDto>> {
            return localVarFp.getCustomers(ingressContactMailAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a customer by its id
         * @summary Retrieves a customer by its id
         * @param {string} customerId Format - uuid. Id of the customer to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomersCustomerid(customerId: string, options?: any): AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsCustomerDto> {
            return localVarFp.getCustomersCustomerid(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all customer contact persons by customer id
         * @summary Retrieves all customer contact persons by customer id
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomersCustomeridContactpersons(customerId: string, options?: any): AxiosPromise<Array<RHIMAPOCustomerManagementWebV2ModelsContactPersonDto>> {
            return localVarFp.getCustomersCustomeridContactpersons(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all direct role assignments of a customer.  Global assignments or customer group assignments are not counted
         * @summary Retrieves all direct role assignments of a customer.  Global assignments or customer group assignments are not counted
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomersCustomeridRoleassignments(customerId: string, options?: any): AxiosPromise<Array<RHIMAPOCustomerManagementWebV2ModelsCustomerRoleAssignmentDto>> {
            return localVarFp.getCustomersCustomeridRoleassignments(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                GET /customers/00000000-0000-0000-0001-000000000000/vessels  GET /customers/00000000-0000-0000-0001-000000000000/vessels?vesselType=0
         * @summary Retrieves all vessels which are assigned to a customer
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {string} [vesselType] The optional vesselType to filter the vessels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomersCustomeridVessels(customerId: string, vesselType?: string, options?: any): AxiosPromise<Array<RHIMAPOCustomerManagementWebV2ModelsVesselDto>> {
            return localVarFp.getCustomersCustomeridVessels(customerId, vesselType, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new customer
         * @summary Creates a new customer
         * @param {RHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDto} [rHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDto] The json body which contains info about the new customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCustomers(rHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDto?: RHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDto, options?: any): AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsCustomerDto> {
            return localVarFp.postCustomers(rHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a customer by its id
         * @summary Updates a customer by its id
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {RHIMAPOCustomerManagementWebV2ModelsCustomerUpdateRequestDto} [rHIMAPOCustomerManagementWebV2ModelsCustomerUpdateRequestDto] The updated customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomersCustomerid(customerId: string, rHIMAPOCustomerManagementWebV2ModelsCustomerUpdateRequestDto?: RHIMAPOCustomerManagementWebV2ModelsCustomerUpdateRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.putCustomersCustomerid(customerId, rHIMAPOCustomerManagementWebV2ModelsCustomerUpdateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Update all customer contact persons by customer id
         * @summary Update all customer contact persons by customer id
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {Array<RHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto>} [rHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto] A list of customer contact persons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomersCustomeridContactpersons(customerId: string, rHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto?: Array<RHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto>, options?: any): AxiosPromise<void> {
            return localVarFp.putCustomersCustomeridContactpersons(customerId, rHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomersApi - object-oriented interface
 * @export
 * @class CustomersApi
 * @extends {BaseAPI}
 */
export class CustomersApi extends BaseAPI {
    /**
     * Delete a customer by id
     * @summary Delete a customer by id
     * @param {string} customerId Format - uuid. Identifier of the customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public deleteCustomersCustomerid(customerId: string, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).deleteCustomersCustomerid(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all customers
     * @summary Retrieves all customers
     * @param {string} [ingressContactMailAddress] Filter customers that have a contact person with mail address and type IngressMailSender
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public getCustomers(ingressContactMailAddress?: string, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).getCustomers(ingressContactMailAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a customer by its id
     * @summary Retrieves a customer by its id
     * @param {string} customerId Format - uuid. Id of the customer to be retrieved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public getCustomersCustomerid(customerId: string, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).getCustomersCustomerid(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all customer contact persons by customer id
     * @summary Retrieves all customer contact persons by customer id
     * @param {string} customerId Format - uuid. Identifier of the customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public getCustomersCustomeridContactpersons(customerId: string, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).getCustomersCustomeridContactpersons(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all direct role assignments of a customer.  Global assignments or customer group assignments are not counted
     * @summary Retrieves all direct role assignments of a customer.  Global assignments or customer group assignments are not counted
     * @param {string} customerId Format - uuid. Identifier of the customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public getCustomersCustomeridRoleassignments(customerId: string, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).getCustomersCustomeridRoleassignments(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                GET /customers/00000000-0000-0000-0001-000000000000/vessels  GET /customers/00000000-0000-0000-0001-000000000000/vessels?vesselType=0
     * @summary Retrieves all vessels which are assigned to a customer
     * @param {string} customerId Format - uuid. Identifier of the customer
     * @param {string} [vesselType] The optional vesselType to filter the vessels
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public getCustomersCustomeridVessels(customerId: string, vesselType?: string, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).getCustomersCustomeridVessels(customerId, vesselType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new customer
     * @summary Creates a new customer
     * @param {RHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDto} [rHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDto] The json body which contains info about the new customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public postCustomers(rHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDto?: RHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDto, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).postCustomers(rHIMAPOCustomerManagementWebV2ModelsCustomerCreateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a customer by its id
     * @summary Updates a customer by its id
     * @param {string} customerId Format - uuid. Identifier of the customer
     * @param {RHIMAPOCustomerManagementWebV2ModelsCustomerUpdateRequestDto} [rHIMAPOCustomerManagementWebV2ModelsCustomerUpdateRequestDto] The updated customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public putCustomersCustomerid(customerId: string, rHIMAPOCustomerManagementWebV2ModelsCustomerUpdateRequestDto?: RHIMAPOCustomerManagementWebV2ModelsCustomerUpdateRequestDto, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).putCustomersCustomerid(customerId, rHIMAPOCustomerManagementWebV2ModelsCustomerUpdateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update all customer contact persons by customer id
     * @summary Update all customer contact persons by customer id
     * @param {string} customerId Format - uuid. Identifier of the customer
     * @param {Array<RHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto>} [rHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto] A list of customer contact persons
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public putCustomersCustomeridContactpersons(customerId: string, rHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto?: Array<RHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto>, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).putCustomersCustomeridContactpersons(customerId, rHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FeatureAssignmentsApi - axios parameter creator
 * @export
 */
export const FeatureAssignmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Remove feature from a customer
         * @summary Remove feature from a customer
         * @param {string} customerId Format - uuid. Customer id to identify the customer
         * @param {RHIMAPOCustomerManagementWebV2ModelsCustomerRemoveFeatureRequestDto} [rHIMAPOCustomerManagementWebV2ModelsCustomerRemoveFeatureRequestDto] Feature data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomersCustomeridFeatures: async (customerId: string, rHIMAPOCustomerManagementWebV2ModelsCustomerRemoveFeatureRequestDto?: RHIMAPOCustomerManagementWebV2ModelsCustomerRemoveFeatureRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('deleteCustomersCustomeridFeatures', 'customerId', customerId)
            const localVarPath = `/customers/{customerId}/features`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOCustomerManagementWebV2ModelsCustomerRemoveFeatureRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all feature assignments of a customer
         * @summary Retrieves all feature assignments of a customer
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomersCustomeridFeatures: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCustomersCustomeridFeatures', 'customerId', customerId)
            const localVarPath = `/customers/{customerId}/features`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add feature to a customer
         * @summary Add feature to a customer
         * @param {string} customerId Format - uuid. Customer id to identify the customer
         * @param {RHIMAPOCustomerManagementWebV2ModelsCustomerAddFeatureRequestDto} [rHIMAPOCustomerManagementWebV2ModelsCustomerAddFeatureRequestDto] Feature data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCustomersCustomeridFeatures: async (customerId: string, rHIMAPOCustomerManagementWebV2ModelsCustomerAddFeatureRequestDto?: RHIMAPOCustomerManagementWebV2ModelsCustomerAddFeatureRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('postCustomersCustomeridFeatures', 'customerId', customerId)
            const localVarPath = `/customers/{customerId}/features`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOCustomerManagementWebV2ModelsCustomerAddFeatureRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeatureAssignmentsApi - functional programming interface
 * @export
 */
export const FeatureAssignmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeatureAssignmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * Remove feature from a customer
         * @summary Remove feature from a customer
         * @param {string} customerId Format - uuid. Customer id to identify the customer
         * @param {RHIMAPOCustomerManagementWebV2ModelsCustomerRemoveFeatureRequestDto} [rHIMAPOCustomerManagementWebV2ModelsCustomerRemoveFeatureRequestDto] Feature data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomersCustomeridFeatures(customerId: string, rHIMAPOCustomerManagementWebV2ModelsCustomerRemoveFeatureRequestDto?: RHIMAPOCustomerManagementWebV2ModelsCustomerRemoveFeatureRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomersCustomeridFeatures(customerId, rHIMAPOCustomerManagementWebV2ModelsCustomerRemoveFeatureRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all feature assignments of a customer
         * @summary Retrieves all feature assignments of a customer
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomersCustomeridFeatures(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMAPOCommonDataCustomerManagementFeature>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomersCustomeridFeatures(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add feature to a customer
         * @summary Add feature to a customer
         * @param {string} customerId Format - uuid. Customer id to identify the customer
         * @param {RHIMAPOCustomerManagementWebV2ModelsCustomerAddFeatureRequestDto} [rHIMAPOCustomerManagementWebV2ModelsCustomerAddFeatureRequestDto] Feature data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCustomersCustomeridFeatures(customerId: string, rHIMAPOCustomerManagementWebV2ModelsCustomerAddFeatureRequestDto?: RHIMAPOCustomerManagementWebV2ModelsCustomerAddFeatureRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCustomersCustomeridFeatures(customerId, rHIMAPOCustomerManagementWebV2ModelsCustomerAddFeatureRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeatureAssignmentsApi - factory interface
 * @export
 */
export const FeatureAssignmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeatureAssignmentsApiFp(configuration)
    return {
        /**
         * Remove feature from a customer
         * @summary Remove feature from a customer
         * @param {string} customerId Format - uuid. Customer id to identify the customer
         * @param {RHIMAPOCustomerManagementWebV2ModelsCustomerRemoveFeatureRequestDto} [rHIMAPOCustomerManagementWebV2ModelsCustomerRemoveFeatureRequestDto] Feature data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomersCustomeridFeatures(customerId: string, rHIMAPOCustomerManagementWebV2ModelsCustomerRemoveFeatureRequestDto?: RHIMAPOCustomerManagementWebV2ModelsCustomerRemoveFeatureRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCustomersCustomeridFeatures(customerId, rHIMAPOCustomerManagementWebV2ModelsCustomerRemoveFeatureRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all feature assignments of a customer
         * @summary Retrieves all feature assignments of a customer
         * @param {string} customerId Format - uuid. Identifier of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomersCustomeridFeatures(customerId: string, options?: any): AxiosPromise<Array<RHIMAPOCommonDataCustomerManagementFeature>> {
            return localVarFp.getCustomersCustomeridFeatures(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add feature to a customer
         * @summary Add feature to a customer
         * @param {string} customerId Format - uuid. Customer id to identify the customer
         * @param {RHIMAPOCustomerManagementWebV2ModelsCustomerAddFeatureRequestDto} [rHIMAPOCustomerManagementWebV2ModelsCustomerAddFeatureRequestDto] Feature data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCustomersCustomeridFeatures(customerId: string, rHIMAPOCustomerManagementWebV2ModelsCustomerAddFeatureRequestDto?: RHIMAPOCustomerManagementWebV2ModelsCustomerAddFeatureRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.postCustomersCustomeridFeatures(customerId, rHIMAPOCustomerManagementWebV2ModelsCustomerAddFeatureRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeatureAssignmentsApi - object-oriented interface
 * @export
 * @class FeatureAssignmentsApi
 * @extends {BaseAPI}
 */
export class FeatureAssignmentsApi extends BaseAPI {
    /**
     * Remove feature from a customer
     * @summary Remove feature from a customer
     * @param {string} customerId Format - uuid. Customer id to identify the customer
     * @param {RHIMAPOCustomerManagementWebV2ModelsCustomerRemoveFeatureRequestDto} [rHIMAPOCustomerManagementWebV2ModelsCustomerRemoveFeatureRequestDto] Feature data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureAssignmentsApi
     */
    public deleteCustomersCustomeridFeatures(customerId: string, rHIMAPOCustomerManagementWebV2ModelsCustomerRemoveFeatureRequestDto?: RHIMAPOCustomerManagementWebV2ModelsCustomerRemoveFeatureRequestDto, options?: AxiosRequestConfig) {
        return FeatureAssignmentsApiFp(this.configuration).deleteCustomersCustomeridFeatures(customerId, rHIMAPOCustomerManagementWebV2ModelsCustomerRemoveFeatureRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all feature assignments of a customer
     * @summary Retrieves all feature assignments of a customer
     * @param {string} customerId Format - uuid. Identifier of the customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureAssignmentsApi
     */
    public getCustomersCustomeridFeatures(customerId: string, options?: AxiosRequestConfig) {
        return FeatureAssignmentsApiFp(this.configuration).getCustomersCustomeridFeatures(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add feature to a customer
     * @summary Add feature to a customer
     * @param {string} customerId Format - uuid. Customer id to identify the customer
     * @param {RHIMAPOCustomerManagementWebV2ModelsCustomerAddFeatureRequestDto} [rHIMAPOCustomerManagementWebV2ModelsCustomerAddFeatureRequestDto] Feature data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureAssignmentsApi
     */
    public postCustomersCustomeridFeatures(customerId: string, rHIMAPOCustomerManagementWebV2ModelsCustomerAddFeatureRequestDto?: RHIMAPOCustomerManagementWebV2ModelsCustomerAddFeatureRequestDto, options?: AxiosRequestConfig) {
        return FeatureAssignmentsApiFp(this.configuration).postCustomersCustomeridFeatures(customerId, rHIMAPOCustomerManagementWebV2ModelsCustomerAddFeatureRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FeaturesApi - axios parameter creator
 * @export
 */
export const FeaturesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all features which can be assigned to customers.
         * @summary Get all features which can be assigned to customers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatures: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Features`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeaturesApi - functional programming interface
 * @export
 */
export const FeaturesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeaturesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all features which can be assigned to customers.
         * @summary Get all features which can be assigned to customers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatures(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMAPOCommonDataCustomerManagementFeature>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatures(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeaturesApi - factory interface
 * @export
 */
export const FeaturesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeaturesApiFp(configuration)
    return {
        /**
         * Get all features which can be assigned to customers.
         * @summary Get all features which can be assigned to customers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatures(options?: any): AxiosPromise<Array<RHIMAPOCommonDataCustomerManagementFeature>> {
            return localVarFp.getFeatures(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeaturesApi - object-oriented interface
 * @export
 * @class FeaturesApi
 * @extends {BaseAPI}
 */
export class FeaturesApi extends BaseAPI {
    /**
     * Get all features which can be assigned to customers.
     * @summary Get all features which can be assigned to customers.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public getFeatures(options?: AxiosRequestConfig) {
        return FeaturesApiFp(this.configuration).getFeatures(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RolesApi - axios parameter creator
 * @export
 */
export const RolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all customer and global APO roles.
         * @summary Get all customer and global APO roles.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesApi - functional programming interface
 * @export
 */
export const RolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all customer and global APO roles.
         * @summary Get all customer and global APO roles.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsRolesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RolesApi - factory interface
 * @export
 */
export const RolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesApiFp(configuration)
    return {
        /**
         * Get all customer and global APO roles.
         * @summary Get all customer and global APO roles.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles(options?: any): AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsRolesDto> {
            return localVarFp.getRoles(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolesApi - object-oriented interface
 * @export
 * @class RolesApi
 * @extends {BaseAPI}
 */
export class RolesApi extends BaseAPI {
    /**
     * Get all customer and global APO roles.
     * @summary Get all customer and global APO roles.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public getRoles(options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).getRoles(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserInfoApi - axios parameter creator
 * @export
 */
export const UserInfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves information about the logged-in user.
         * @summary Retrieves information about the logged-in user.
         * @param {string} [xRHIMApoId] Format - uuid. The userId in Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserinfoMe: async (xRHIMApoId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/UserInfo/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xRHIMApoId != null) {
                localVarHeaderParameter['X-RHIM-ApoId'] = String(xRHIMApoId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserInfoApi - functional programming interface
 * @export
 */
export const UserInfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserInfoApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves information about the logged-in user.
         * @summary Retrieves information about the logged-in user.
         * @param {string} [xRHIMApoId] Format - uuid. The userId in Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserinfoMe(xRHIMApoId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserinfoMe(xRHIMApoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserInfoApi - factory interface
 * @export
 */
export const UserInfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserInfoApiFp(configuration)
    return {
        /**
         * Retrieves information about the logged-in user.
         * @summary Retrieves information about the logged-in user.
         * @param {string} [xRHIMApoId] Format - uuid. The userId in Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserinfoMe(xRHIMApoId?: string, options?: any): AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto> {
            return localVarFp.getUserinfoMe(xRHIMApoId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserInfoApi - object-oriented interface
 * @export
 * @class UserInfoApi
 * @extends {BaseAPI}
 */
export class UserInfoApi extends BaseAPI {
    /**
     * Retrieves information about the logged-in user.
     * @summary Retrieves information about the logged-in user.
     * @param {string} [xRHIMApoId] Format - uuid. The userId in Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInfoApi
     */
    public getUserinfoMe(xRHIMApoId?: string, options?: AxiosRequestConfig) {
        return UserInfoApiFp(this.configuration).getUserinfoMe(xRHIMApoId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete user
         * @summary Delete user
         * @param {string} userId Format - uuid. User id to identify the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsersUserid: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUsersUserid', 'userId', userId)
            const localVarPath = `/Users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the user UI settings
         * @summary Deletes the user UI settings
         * @param {string} [xRHIMApoId] Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsersUsersettings: async (xRHIMApoId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Users/usersettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xRHIMApoId != null) {
                localVarHeaderParameter['X-RHIM-ApoId'] = String(xRHIMApoId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get users
         * @summary Get users
         * @param {string} [customerId] Format - uuid. Optional filter to get users only for specific customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (customerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user
         * @summary Get user
         * @param {string} userId Format - uuid. User id to identify the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersUserid: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUsersUserid', 'userId', userId)
            const localVarPath = `/Users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user roles
         * @summary Get user roles
         * @param {string} userId Format - uuid. User id to identify the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersUseridRoles: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUsersUseridRoles', 'userId', userId)
            const localVarPath = `/Users/{userId}/roles`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the user UI settings
         * @summary Retrieves the user UI settings
         * @param {string} [xRHIMApoId] Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersUsersettings: async (xRHIMApoId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Users/usersettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xRHIMApoId != null) {
                localVarHeaderParameter['X-RHIM-ApoId'] = String(xRHIMApoId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create the new user
         * @summary Create the new user
         * @param {RHIMAPOCustomerManagementWebV2ModelsUserCreateRequestDto} [rHIMAPOCustomerManagementWebV2ModelsUserCreateRequestDto] The user data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUsers: async (rHIMAPOCustomerManagementWebV2ModelsUserCreateRequestDto?: RHIMAPOCustomerManagementWebV2ModelsUserCreateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOCustomerManagementWebV2ModelsUserCreateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update user
         * @summary Update user
         * @param {string} userId Format - uuid. User id to identify the user
         * @param {RHIMAPOCustomerManagementWebV2ModelsUserUpdateRequestDto} [rHIMAPOCustomerManagementWebV2ModelsUserUpdateRequestDto] The user data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUsersUserid: async (userId: string, rHIMAPOCustomerManagementWebV2ModelsUserUpdateRequestDto?: RHIMAPOCustomerManagementWebV2ModelsUserUpdateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('putUsersUserid', 'userId', userId)
            const localVarPath = `/Users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOCustomerManagementWebV2ModelsUserUpdateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update user\'s global and customer role
         * @summary Update user\'s global and customer role
         * @param {string} userId Format - uuid. User id to identify the user
         * @param {RHIMAPOCustomerManagementWebV2ModelsUserUpdateRolesRequestDto} [rHIMAPOCustomerManagementWebV2ModelsUserUpdateRolesRequestDto] User\&#39;s global and customer role data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUsersUseridRoles: async (userId: string, rHIMAPOCustomerManagementWebV2ModelsUserUpdateRolesRequestDto?: RHIMAPOCustomerManagementWebV2ModelsUserUpdateRolesRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('putUsersUseridRoles', 'userId', userId)
            const localVarPath = `/Users/{userId}/roles`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOCustomerManagementWebV2ModelsUserUpdateRolesRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upserts the usersettings
         * @summary Upserts the usersettings
         * @param {string} [xRHIMApoId] Format - uuid.
         * @param {RHIMAPOCustomerManagementWebV2ModelsUserSettingsDto} [rHIMAPOCustomerManagementWebV2ModelsUserSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUsersUsersettings: async (xRHIMApoId?: string, rHIMAPOCustomerManagementWebV2ModelsUserSettingsDto?: RHIMAPOCustomerManagementWebV2ModelsUserSettingsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Users/usersettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xRHIMApoId != null) {
                localVarHeaderParameter['X-RHIM-ApoId'] = String(xRHIMApoId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOCustomerManagementWebV2ModelsUserSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete user
         * @summary Delete user
         * @param {string} userId Format - uuid. User id to identify the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUsersUserid(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUsersUserid(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes the user UI settings
         * @summary Deletes the user UI settings
         * @param {string} [xRHIMApoId] Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUsersUsersettings(xRHIMApoId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUsersUsersettings(xRHIMApoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get users
         * @summary Get users
         * @param {string} [customerId] Format - uuid. Optional filter to get users only for specific customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(customerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMAPOCustomerManagementWebV2ModelsUserDetailDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get user
         * @summary Get user
         * @param {string} userId Format - uuid. User id to identify the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersUserid(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersUserid(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get user roles
         * @summary Get user roles
         * @param {string} userId Format - uuid. User id to identify the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersUseridRoles(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsUserRolesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersUseridRoles(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the user UI settings
         * @summary Retrieves the user UI settings
         * @param {string} [xRHIMApoId] Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersUsersettings(xRHIMApoId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsUserSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersUsersettings(xRHIMApoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create the new user
         * @summary Create the new user
         * @param {RHIMAPOCustomerManagementWebV2ModelsUserCreateRequestDto} [rHIMAPOCustomerManagementWebV2ModelsUserCreateRequestDto] The user data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUsers(rHIMAPOCustomerManagementWebV2ModelsUserCreateRequestDto?: RHIMAPOCustomerManagementWebV2ModelsUserCreateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUsers(rHIMAPOCustomerManagementWebV2ModelsUserCreateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update user
         * @summary Update user
         * @param {string} userId Format - uuid. User id to identify the user
         * @param {RHIMAPOCustomerManagementWebV2ModelsUserUpdateRequestDto} [rHIMAPOCustomerManagementWebV2ModelsUserUpdateRequestDto] The user data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUsersUserid(userId: string, rHIMAPOCustomerManagementWebV2ModelsUserUpdateRequestDto?: RHIMAPOCustomerManagementWebV2ModelsUserUpdateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUsersUserid(userId, rHIMAPOCustomerManagementWebV2ModelsUserUpdateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update user\'s global and customer role
         * @summary Update user\'s global and customer role
         * @param {string} userId Format - uuid. User id to identify the user
         * @param {RHIMAPOCustomerManagementWebV2ModelsUserUpdateRolesRequestDto} [rHIMAPOCustomerManagementWebV2ModelsUserUpdateRolesRequestDto] User\&#39;s global and customer role data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUsersUseridRoles(userId: string, rHIMAPOCustomerManagementWebV2ModelsUserUpdateRolesRequestDto?: RHIMAPOCustomerManagementWebV2ModelsUserUpdateRolesRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUsersUseridRoles(userId, rHIMAPOCustomerManagementWebV2ModelsUserUpdateRolesRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upserts the usersettings
         * @summary Upserts the usersettings
         * @param {string} [xRHIMApoId] Format - uuid.
         * @param {RHIMAPOCustomerManagementWebV2ModelsUserSettingsDto} [rHIMAPOCustomerManagementWebV2ModelsUserSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUsersUsersettings(xRHIMApoId?: string, rHIMAPOCustomerManagementWebV2ModelsUserSettingsDto?: RHIMAPOCustomerManagementWebV2ModelsUserSettingsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUsersUsersettings(xRHIMApoId, rHIMAPOCustomerManagementWebV2ModelsUserSettingsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * Delete user
         * @summary Delete user
         * @param {string} userId Format - uuid. User id to identify the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsersUserid(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUsersUserid(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the user UI settings
         * @summary Deletes the user UI settings
         * @param {string} [xRHIMApoId] Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsersUsersettings(xRHIMApoId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUsersUsersettings(xRHIMApoId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get users
         * @summary Get users
         * @param {string} [customerId] Format - uuid. Optional filter to get users only for specific customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(customerId?: string, options?: any): AxiosPromise<Array<RHIMAPOCustomerManagementWebV2ModelsUserDetailDto>> {
            return localVarFp.getUsers(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get user
         * @summary Get user
         * @param {string} userId Format - uuid. User id to identify the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersUserid(userId: string, options?: any): AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsUserDto> {
            return localVarFp.getUsersUserid(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get user roles
         * @summary Get user roles
         * @param {string} userId Format - uuid. User id to identify the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersUseridRoles(userId: string, options?: any): AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsUserRolesDto> {
            return localVarFp.getUsersUseridRoles(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the user UI settings
         * @summary Retrieves the user UI settings
         * @param {string} [xRHIMApoId] Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersUsersettings(xRHIMApoId?: string, options?: any): AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsUserSettingsDto> {
            return localVarFp.getUsersUsersettings(xRHIMApoId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create the new user
         * @summary Create the new user
         * @param {RHIMAPOCustomerManagementWebV2ModelsUserCreateRequestDto} [rHIMAPOCustomerManagementWebV2ModelsUserCreateRequestDto] The user data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUsers(rHIMAPOCustomerManagementWebV2ModelsUserCreateRequestDto?: RHIMAPOCustomerManagementWebV2ModelsUserCreateRequestDto, options?: any): AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsUserDto> {
            return localVarFp.postUsers(rHIMAPOCustomerManagementWebV2ModelsUserCreateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Update user
         * @summary Update user
         * @param {string} userId Format - uuid. User id to identify the user
         * @param {RHIMAPOCustomerManagementWebV2ModelsUserUpdateRequestDto} [rHIMAPOCustomerManagementWebV2ModelsUserUpdateRequestDto] The user data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUsersUserid(userId: string, rHIMAPOCustomerManagementWebV2ModelsUserUpdateRequestDto?: RHIMAPOCustomerManagementWebV2ModelsUserUpdateRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.putUsersUserid(userId, rHIMAPOCustomerManagementWebV2ModelsUserUpdateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Update user\'s global and customer role
         * @summary Update user\'s global and customer role
         * @param {string} userId Format - uuid. User id to identify the user
         * @param {RHIMAPOCustomerManagementWebV2ModelsUserUpdateRolesRequestDto} [rHIMAPOCustomerManagementWebV2ModelsUserUpdateRolesRequestDto] User\&#39;s global and customer role data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUsersUseridRoles(userId: string, rHIMAPOCustomerManagementWebV2ModelsUserUpdateRolesRequestDto?: RHIMAPOCustomerManagementWebV2ModelsUserUpdateRolesRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.putUsersUseridRoles(userId, rHIMAPOCustomerManagementWebV2ModelsUserUpdateRolesRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Upserts the usersettings
         * @summary Upserts the usersettings
         * @param {string} [xRHIMApoId] Format - uuid.
         * @param {RHIMAPOCustomerManagementWebV2ModelsUserSettingsDto} [rHIMAPOCustomerManagementWebV2ModelsUserSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUsersUsersettings(xRHIMApoId?: string, rHIMAPOCustomerManagementWebV2ModelsUserSettingsDto?: RHIMAPOCustomerManagementWebV2ModelsUserSettingsDto, options?: any): AxiosPromise<void> {
            return localVarFp.putUsersUsersettings(xRHIMApoId, rHIMAPOCustomerManagementWebV2ModelsUserSettingsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * Delete user
     * @summary Delete user
     * @param {string} userId Format - uuid. User id to identify the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUsersUserid(userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteUsersUserid(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the user UI settings
     * @summary Deletes the user UI settings
     * @param {string} [xRHIMApoId] Format - uuid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUsersUsersettings(xRHIMApoId?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteUsersUsersettings(xRHIMApoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get users
     * @summary Get users
     * @param {string} [customerId] Format - uuid. Optional filter to get users only for specific customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsers(customerId?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUsers(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user
     * @summary Get user
     * @param {string} userId Format - uuid. User id to identify the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsersUserid(userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUsersUserid(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user roles
     * @summary Get user roles
     * @param {string} userId Format - uuid. User id to identify the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsersUseridRoles(userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUsersUseridRoles(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the user UI settings
     * @summary Retrieves the user UI settings
     * @param {string} [xRHIMApoId] Format - uuid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsersUsersettings(xRHIMApoId?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUsersUsersettings(xRHIMApoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create the new user
     * @summary Create the new user
     * @param {RHIMAPOCustomerManagementWebV2ModelsUserCreateRequestDto} [rHIMAPOCustomerManagementWebV2ModelsUserCreateRequestDto] The user data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public postUsers(rHIMAPOCustomerManagementWebV2ModelsUserCreateRequestDto?: RHIMAPOCustomerManagementWebV2ModelsUserCreateRequestDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).postUsers(rHIMAPOCustomerManagementWebV2ModelsUserCreateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update user
     * @summary Update user
     * @param {string} userId Format - uuid. User id to identify the user
     * @param {RHIMAPOCustomerManagementWebV2ModelsUserUpdateRequestDto} [rHIMAPOCustomerManagementWebV2ModelsUserUpdateRequestDto] The user data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public putUsersUserid(userId: string, rHIMAPOCustomerManagementWebV2ModelsUserUpdateRequestDto?: RHIMAPOCustomerManagementWebV2ModelsUserUpdateRequestDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).putUsersUserid(userId, rHIMAPOCustomerManagementWebV2ModelsUserUpdateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update user\'s global and customer role
     * @summary Update user\'s global and customer role
     * @param {string} userId Format - uuid. User id to identify the user
     * @param {RHIMAPOCustomerManagementWebV2ModelsUserUpdateRolesRequestDto} [rHIMAPOCustomerManagementWebV2ModelsUserUpdateRolesRequestDto] User\&#39;s global and customer role data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public putUsersUseridRoles(userId: string, rHIMAPOCustomerManagementWebV2ModelsUserUpdateRolesRequestDto?: RHIMAPOCustomerManagementWebV2ModelsUserUpdateRolesRequestDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).putUsersUseridRoles(userId, rHIMAPOCustomerManagementWebV2ModelsUserUpdateRolesRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upserts the usersettings
     * @summary Upserts the usersettings
     * @param {string} [xRHIMApoId] Format - uuid.
     * @param {RHIMAPOCustomerManagementWebV2ModelsUserSettingsDto} [rHIMAPOCustomerManagementWebV2ModelsUserSettingsDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public putUsersUsersettings(xRHIMApoId?: string, rHIMAPOCustomerManagementWebV2ModelsUserSettingsDto?: RHIMAPOCustomerManagementWebV2ModelsUserSettingsDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).putUsersUsersettings(xRHIMApoId, rHIMAPOCustomerManagementWebV2ModelsUserSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VesselPropertiesApi - axios parameter creator
 * @export
 */
export const VesselPropertiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/aod
         * @summary Retrieves properties of the AOD vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidAod: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getVesselsVesselidAod', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/aod`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/bfr
         * @summary Retrieves properties of the BFR vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidBfr: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getVesselsVesselidBfr', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/bfr`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/bof
         * @summary Retrieves the BOF properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidBof: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getVesselsVesselidBof', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/bof`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/cou
         * @summary Retrieves properties of a Coke Oven
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidCou: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getVesselsVesselidCou', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/cou`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/crk
         * @summary Retrieves the CRK properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidCrk: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getVesselsVesselidCrk', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/crk`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/eaf
         * @summary Retrieves the EAF properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidEaf: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getVesselsVesselidEaf', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/eaf`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/ladle
         * @summary Retrieves the properties for Hot Metal Ladle vessel.
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidHml: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getVesselsVesselidHml', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/hml`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/ladle
         * @summary Retrieves the Ladle properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidLadle: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getVesselsVesselidLadle', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/ladle`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/rh
         * @summary Retrieves the RH properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidRh: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getVesselsVesselidRh', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/rh`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/rhl
         * @summary Retrieves the RHL properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidRhl: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getVesselsVesselidRhl', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/rhl`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/srf
         * @summary Retrieves properties of a SRF vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidSrf: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getVesselsVesselidSrf', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/srf`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/aod
         * @summary Updates the properties of the AOD vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesAodVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesAodVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselsVesselidAod: async (vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesAodVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesAodVesselPropertiesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('putVesselsVesselidAod', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/aod`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesAodVesselPropertiesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/bfr
         * @summary Updates the properties of the BFR vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBfrVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBfrVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselsVesselidBfr: async (vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBfrVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBfrVesselPropertiesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('putVesselsVesselidBfr', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/bfr`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBfrVesselPropertiesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/bof
         * @summary Updates the BOF properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselsVesselidBof: async (vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('putVesselsVesselidBof', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/bof`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/cou
         * @summary Updates the properties of a Coke Oven
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCouVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCouVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselsVesselidCou: async (vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCouVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCouVesselPropertiesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('putVesselsVesselidCou', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/cou`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCouVesselPropertiesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/crk
         * @summary Updates the CRK properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselsVesselidCrk: async (vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkVesselPropertiesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('putVesselsVesselidCrk', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/crk`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkVesselPropertiesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/eaf
         * @summary Updates the EAF properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselsVesselidEaf: async (vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('putVesselsVesselidEaf', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/eaf`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/ladle
         * @summary Updates the properties for a Hot Metal Ladle vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesHmlVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesHmlVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselsVesselidHml: async (vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesHmlVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesHmlVesselPropertiesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('putVesselsVesselidHml', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/hml`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesHmlVesselPropertiesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/ladle
         * @summary Updates the Ladle properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesLadleVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesLadleVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselsVesselidLadle: async (vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesLadleVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesLadleVesselPropertiesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('putVesselsVesselidLadle', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/ladle`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesLadleVesselPropertiesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/rh
         * @summary Updates the RH properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselsVesselidRh: async (vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhVesselPropertiesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('putVesselsVesselidRh', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/rh`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhVesselPropertiesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/rhl
         * @summary Updates the RHL properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselsVesselidRhl: async (vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('putVesselsVesselidRhl', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/rhl`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/srf
         * @summary Updates the properties of a SRF vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesSrfVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesSrfVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselsVesselidSrf: async (vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesSrfVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesSrfVesselPropertiesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('putVesselsVesselidSrf', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/srf`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesSrfVesselPropertiesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VesselPropertiesApi - functional programming interface
 * @export
 */
export const VesselPropertiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VesselPropertiesApiAxiosParamCreator(configuration)
    return {
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/aod
         * @summary Retrieves properties of the AOD vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVesselsVesselidAod(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesAodVesselPropertiesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselsVesselidAod(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/bfr
         * @summary Retrieves properties of the BFR vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVesselsVesselidBfr(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBfrVesselPropertiesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselsVesselidBfr(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/bof
         * @summary Retrieves the BOF properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVesselsVesselidBof(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselsVesselidBof(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/cou
         * @summary Retrieves properties of a Coke Oven
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVesselsVesselidCou(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCouVesselPropertiesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselsVesselidCou(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/crk
         * @summary Retrieves the CRK properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVesselsVesselidCrk(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkVesselPropertiesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselsVesselidCrk(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/eaf
         * @summary Retrieves the EAF properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVesselsVesselidEaf(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselsVesselidEaf(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/ladle
         * @summary Retrieves the properties for Hot Metal Ladle vessel.
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVesselsVesselidHml(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesHmlVesselPropertiesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselsVesselidHml(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/ladle
         * @summary Retrieves the Ladle properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVesselsVesselidLadle(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesLadleVesselPropertiesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselsVesselidLadle(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/rh
         * @summary Retrieves the RH properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVesselsVesselidRh(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhVesselPropertiesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselsVesselidRh(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/rhl
         * @summary Retrieves the RHL properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVesselsVesselidRhl(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselsVesselidRhl(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/srf
         * @summary Retrieves properties of a SRF vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVesselsVesselidSrf(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesSrfVesselPropertiesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselsVesselidSrf(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/aod
         * @summary Updates the properties of the AOD vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesAodVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesAodVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putVesselsVesselidAod(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesAodVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesAodVesselPropertiesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putVesselsVesselidAod(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesAodVesselPropertiesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/bfr
         * @summary Updates the properties of the BFR vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBfrVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBfrVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putVesselsVesselidBfr(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBfrVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBfrVesselPropertiesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putVesselsVesselidBfr(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBfrVesselPropertiesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/bof
         * @summary Updates the BOF properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putVesselsVesselidBof(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putVesselsVesselidBof(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/cou
         * @summary Updates the properties of a Coke Oven
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCouVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCouVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putVesselsVesselidCou(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCouVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCouVesselPropertiesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putVesselsVesselidCou(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCouVesselPropertiesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/crk
         * @summary Updates the CRK properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putVesselsVesselidCrk(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkVesselPropertiesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putVesselsVesselidCrk(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkVesselPropertiesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/eaf
         * @summary Updates the EAF properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putVesselsVesselidEaf(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putVesselsVesselidEaf(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/ladle
         * @summary Updates the properties for a Hot Metal Ladle vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesHmlVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesHmlVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putVesselsVesselidHml(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesHmlVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesHmlVesselPropertiesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putVesselsVesselidHml(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesHmlVesselPropertiesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/ladle
         * @summary Updates the Ladle properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesLadleVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesLadleVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putVesselsVesselidLadle(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesLadleVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesLadleVesselPropertiesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putVesselsVesselidLadle(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesLadleVesselPropertiesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/rh
         * @summary Updates the RH properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putVesselsVesselidRh(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhVesselPropertiesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putVesselsVesselidRh(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhVesselPropertiesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/rhl
         * @summary Updates the RHL properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putVesselsVesselidRhl(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putVesselsVesselidRhl(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/srf
         * @summary Updates the properties of a SRF vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesSrfVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesSrfVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putVesselsVesselidSrf(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesSrfVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesSrfVesselPropertiesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putVesselsVesselidSrf(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesSrfVesselPropertiesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VesselPropertiesApi - factory interface
 * @export
 */
export const VesselPropertiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VesselPropertiesApiFp(configuration)
    return {
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/aod
         * @summary Retrieves properties of the AOD vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidAod(vesselId: string, options?: any): AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesAodVesselPropertiesDto> {
            return localVarFp.getVesselsVesselidAod(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/bfr
         * @summary Retrieves properties of the BFR vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidBfr(vesselId: string, options?: any): AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBfrVesselPropertiesDto> {
            return localVarFp.getVesselsVesselidBfr(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/bof
         * @summary Retrieves the BOF properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidBof(vesselId: string, options?: any): AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto> {
            return localVarFp.getVesselsVesselidBof(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/cou
         * @summary Retrieves properties of a Coke Oven
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidCou(vesselId: string, options?: any): AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCouVesselPropertiesDto> {
            return localVarFp.getVesselsVesselidCou(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/crk
         * @summary Retrieves the CRK properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidCrk(vesselId: string, options?: any): AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkVesselPropertiesDto> {
            return localVarFp.getVesselsVesselidCrk(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/eaf
         * @summary Retrieves the EAF properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidEaf(vesselId: string, options?: any): AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto> {
            return localVarFp.getVesselsVesselidEaf(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/ladle
         * @summary Retrieves the properties for Hot Metal Ladle vessel.
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidHml(vesselId: string, options?: any): AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesHmlVesselPropertiesDto> {
            return localVarFp.getVesselsVesselidHml(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/ladle
         * @summary Retrieves the Ladle properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidLadle(vesselId: string, options?: any): AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesLadleVesselPropertiesDto> {
            return localVarFp.getVesselsVesselidLadle(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/rh
         * @summary Retrieves the RH properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidRh(vesselId: string, options?: any): AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhVesselPropertiesDto> {
            return localVarFp.getVesselsVesselidRh(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/rhl
         * @summary Retrieves the RHL properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidRhl(vesselId: string, options?: any): AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto> {
            return localVarFp.getVesselsVesselidRhl(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/srf
         * @summary Retrieves properties of a SRF vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidSrf(vesselId: string, options?: any): AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesSrfVesselPropertiesDto> {
            return localVarFp.getVesselsVesselidSrf(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/aod
         * @summary Updates the properties of the AOD vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesAodVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesAodVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselsVesselidAod(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesAodVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesAodVesselPropertiesDto, options?: any): AxiosPromise<void> {
            return localVarFp.putVesselsVesselidAod(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesAodVesselPropertiesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/bfr
         * @summary Updates the properties of the BFR vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBfrVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBfrVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselsVesselidBfr(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBfrVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBfrVesselPropertiesDto, options?: any): AxiosPromise<void> {
            return localVarFp.putVesselsVesselidBfr(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBfrVesselPropertiesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/bof
         * @summary Updates the BOF properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselsVesselidBof(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto, options?: any): AxiosPromise<void> {
            return localVarFp.putVesselsVesselidBof(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/cou
         * @summary Updates the properties of a Coke Oven
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCouVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCouVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselsVesselidCou(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCouVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCouVesselPropertiesDto, options?: any): AxiosPromise<void> {
            return localVarFp.putVesselsVesselidCou(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCouVesselPropertiesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/crk
         * @summary Updates the CRK properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselsVesselidCrk(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkVesselPropertiesDto, options?: any): AxiosPromise<void> {
            return localVarFp.putVesselsVesselidCrk(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkVesselPropertiesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/eaf
         * @summary Updates the EAF properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselsVesselidEaf(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto, options?: any): AxiosPromise<void> {
            return localVarFp.putVesselsVesselidEaf(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/ladle
         * @summary Updates the properties for a Hot Metal Ladle vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesHmlVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesHmlVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselsVesselidHml(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesHmlVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesHmlVesselPropertiesDto, options?: any): AxiosPromise<void> {
            return localVarFp.putVesselsVesselidHml(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesHmlVesselPropertiesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/ladle
         * @summary Updates the Ladle properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesLadleVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesLadleVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselsVesselidLadle(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesLadleVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesLadleVesselPropertiesDto, options?: any): AxiosPromise<void> {
            return localVarFp.putVesselsVesselidLadle(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesLadleVesselPropertiesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/rh
         * @summary Updates the RH properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselsVesselidRh(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhVesselPropertiesDto, options?: any): AxiosPromise<void> {
            return localVarFp.putVesselsVesselidRh(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhVesselPropertiesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/rhl
         * @summary Updates the RHL properties of a vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselsVesselidRhl(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto, options?: any): AxiosPromise<void> {
            return localVarFp.putVesselsVesselidRhl(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/srf
         * @summary Updates the properties of a SRF vessel
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesSrfVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesSrfVesselPropertiesDto] The properties to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselsVesselidSrf(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesSrfVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesSrfVesselPropertiesDto, options?: any): AxiosPromise<void> {
            return localVarFp.putVesselsVesselidSrf(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesSrfVesselPropertiesDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VesselPropertiesApi - object-oriented interface
 * @export
 * @class VesselPropertiesApi
 * @extends {BaseAPI}
 */
export class VesselPropertiesApi extends BaseAPI {
    /**
     * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/aod
     * @summary Retrieves properties of the AOD vessel
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselPropertiesApi
     */
    public getVesselsVesselidAod(vesselId: string, options?: AxiosRequestConfig) {
        return VesselPropertiesApiFp(this.configuration).getVesselsVesselidAod(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/bfr
     * @summary Retrieves properties of the BFR vessel
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselPropertiesApi
     */
    public getVesselsVesselidBfr(vesselId: string, options?: AxiosRequestConfig) {
        return VesselPropertiesApiFp(this.configuration).getVesselsVesselidBfr(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/bof
     * @summary Retrieves the BOF properties of a vessel
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselPropertiesApi
     */
    public getVesselsVesselidBof(vesselId: string, options?: AxiosRequestConfig) {
        return VesselPropertiesApiFp(this.configuration).getVesselsVesselidBof(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/cou
     * @summary Retrieves properties of a Coke Oven
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselPropertiesApi
     */
    public getVesselsVesselidCou(vesselId: string, options?: AxiosRequestConfig) {
        return VesselPropertiesApiFp(this.configuration).getVesselsVesselidCou(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/crk
     * @summary Retrieves the CRK properties of a vessel
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselPropertiesApi
     */
    public getVesselsVesselidCrk(vesselId: string, options?: AxiosRequestConfig) {
        return VesselPropertiesApiFp(this.configuration).getVesselsVesselidCrk(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/eaf
     * @summary Retrieves the EAF properties of a vessel
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselPropertiesApi
     */
    public getVesselsVesselidEaf(vesselId: string, options?: AxiosRequestConfig) {
        return VesselPropertiesApiFp(this.configuration).getVesselsVesselidEaf(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/ladle
     * @summary Retrieves the properties for Hot Metal Ladle vessel.
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselPropertiesApi
     */
    public getVesselsVesselidHml(vesselId: string, options?: AxiosRequestConfig) {
        return VesselPropertiesApiFp(this.configuration).getVesselsVesselidHml(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/ladle
     * @summary Retrieves the Ladle properties of a vessel
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselPropertiesApi
     */
    public getVesselsVesselidLadle(vesselId: string, options?: AxiosRequestConfig) {
        return VesselPropertiesApiFp(this.configuration).getVesselsVesselidLadle(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/rh
     * @summary Retrieves the RH properties of a vessel
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselPropertiesApi
     */
    public getVesselsVesselidRh(vesselId: string, options?: AxiosRequestConfig) {
        return VesselPropertiesApiFp(this.configuration).getVesselsVesselidRh(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/rhl
     * @summary Retrieves the RHL properties of a vessel
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselPropertiesApi
     */
    public getVesselsVesselidRhl(vesselId: string, options?: AxiosRequestConfig) {
        return VesselPropertiesApiFp(this.configuration).getVesselsVesselidRhl(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000/srf
     * @summary Retrieves properties of a SRF vessel
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselPropertiesApi
     */
    public getVesselsVesselidSrf(vesselId: string, options?: AxiosRequestConfig) {
        return VesselPropertiesApiFp(this.configuration).getVesselsVesselidSrf(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/aod
     * @summary Updates the properties of the AOD vessel
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesAodVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesAodVesselPropertiesDto] The properties to set
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselPropertiesApi
     */
    public putVesselsVesselidAod(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesAodVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesAodVesselPropertiesDto, options?: AxiosRequestConfig) {
        return VesselPropertiesApiFp(this.configuration).putVesselsVesselidAod(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesAodVesselPropertiesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/bfr
     * @summary Updates the properties of the BFR vessel
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBfrVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBfrVesselPropertiesDto] The properties to set
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselPropertiesApi
     */
    public putVesselsVesselidBfr(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBfrVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBfrVesselPropertiesDto, options?: AxiosRequestConfig) {
        return VesselPropertiesApiFp(this.configuration).putVesselsVesselidBfr(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBfrVesselPropertiesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/bof
     * @summary Updates the BOF properties of a vessel
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto] The properties to set
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselPropertiesApi
     */
    public putVesselsVesselidBof(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto, options?: AxiosRequestConfig) {
        return VesselPropertiesApiFp(this.configuration).putVesselsVesselidBof(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesBofVesselPropertiesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/cou
     * @summary Updates the properties of a Coke Oven
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCouVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCouVesselPropertiesDto] The properties to set
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselPropertiesApi
     */
    public putVesselsVesselidCou(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCouVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCouVesselPropertiesDto, options?: AxiosRequestConfig) {
        return VesselPropertiesApiFp(this.configuration).putVesselsVesselidCou(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCouVesselPropertiesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/crk
     * @summary Updates the CRK properties of a vessel
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkVesselPropertiesDto] The properties to set
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselPropertiesApi
     */
    public putVesselsVesselidCrk(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkVesselPropertiesDto, options?: AxiosRequestConfig) {
        return VesselPropertiesApiFp(this.configuration).putVesselsVesselidCrk(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesCrkVesselPropertiesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/eaf
     * @summary Updates the EAF properties of a vessel
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto] The properties to set
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselPropertiesApi
     */
    public putVesselsVesselidEaf(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto, options?: AxiosRequestConfig) {
        return VesselPropertiesApiFp(this.configuration).putVesselsVesselidEaf(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesEafVesselPropertiesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/ladle
     * @summary Updates the properties for a Hot Metal Ladle vessel
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesHmlVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesHmlVesselPropertiesDto] The properties to set
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselPropertiesApi
     */
    public putVesselsVesselidHml(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesHmlVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesHmlVesselPropertiesDto, options?: AxiosRequestConfig) {
        return VesselPropertiesApiFp(this.configuration).putVesselsVesselidHml(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesHmlVesselPropertiesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/ladle
     * @summary Updates the Ladle properties of a vessel
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesLadleVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesLadleVesselPropertiesDto] The properties to set
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselPropertiesApi
     */
    public putVesselsVesselidLadle(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesLadleVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesLadleVesselPropertiesDto, options?: AxiosRequestConfig) {
        return VesselPropertiesApiFp(this.configuration).putVesselsVesselidLadle(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesLadleVesselPropertiesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/rh
     * @summary Updates the RH properties of a vessel
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhVesselPropertiesDto] The properties to set
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselPropertiesApi
     */
    public putVesselsVesselidRh(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhVesselPropertiesDto, options?: AxiosRequestConfig) {
        return VesselPropertiesApiFp(this.configuration).putVesselsVesselidRh(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhVesselPropertiesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/rhl
     * @summary Updates the RHL properties of a vessel
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto] The properties to set
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselPropertiesApi
     */
    public putVesselsVesselidRhl(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto, options?: AxiosRequestConfig) {
        return VesselPropertiesApiFp(this.configuration).putVesselsVesselidRhl(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesRhlVesselPropertiesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                Put /vessels/00000000-0000-0000-0004-100000000000/srf
     * @summary Updates the properties of a SRF vessel
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesSrfVesselPropertiesDto} [rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesSrfVesselPropertiesDto] The properties to set
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselPropertiesApi
     */
    public putVesselsVesselidSrf(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesSrfVesselPropertiesDto?: RHIMAPOCustomerManagementWebV2ModelsVesselPropertiesSrfVesselPropertiesDto, options?: AxiosRequestConfig) {
        return VesselPropertiesApiFp(this.configuration).putVesselsVesselidSrf(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselPropertiesSrfVesselPropertiesDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VesselsApi - axios parameter creator
 * @export
 */
export const VesselsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Sample request:                DELETE /vessels/00000000-0000-0000-0004-100000000000
         * @summary Delete a vessel by id
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVesselsVesselid: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('deleteVesselsVesselid', 'vesselId', vesselId)
            const localVarPath = `/Vessels/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                GET /vessels  GET /vessels?vesselType=0
         * @summary Retrieves all vessels
         * @param {string} [vesselType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVessels: async (vesselType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Vessels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (vesselType !== undefined) {
                localVarQueryParameter['vesselType'] = vesselType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000
         * @summary Retrieves a vessel by id
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselid: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getVesselsVesselid', 'vesselId', vesselId)
            const localVarPath = `/Vessels/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all vessel contact persons by vessel id
         * @summary Retrieves all vessel contact persons by vessel id
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidContactpersons: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getVesselsVesselidContactpersons', 'vesselId', vesselId)
            const localVarPath = `/Vessels/{vesselId}/contactpersons`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new vessel
         * @summary Create a new vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselCreateRequestDto} [rHIMAPOCustomerManagementWebV2ModelsVesselCreateRequestDto] The json body which contains info about the new vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postVessels: async (rHIMAPOCustomerManagementWebV2ModelsVesselCreateRequestDto?: RHIMAPOCustomerManagementWebV2ModelsVesselCreateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Vessels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOCustomerManagementWebV2ModelsVesselCreateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                PUT /vessels/00000000-0000-0000-0004-100000000000
         * @summary Updates a vessel by id
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselUpdateRequestDto} [rHIMAPOCustomerManagementWebV2ModelsVesselUpdateRequestDto] The updated vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselsVesselid: async (vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselUpdateRequestDto?: RHIMAPOCustomerManagementWebV2ModelsVesselUpdateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('putVesselsVesselid', 'vesselId', vesselId)
            const localVarPath = `/Vessels/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOCustomerManagementWebV2ModelsVesselUpdateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update all vessel contact persons by vessel id
         * @summary Update all vessel contact persons by vessel id
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {Array<RHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto>} [rHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto] A list of vessel contact persons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselsVesselidContactpersons: async (vesselId: string, rHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto?: Array<RHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('putVesselsVesselidContactpersons', 'vesselId', vesselId)
            const localVarPath = `/Vessels/{vesselId}/contactpersons`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VesselsApi - functional programming interface
 * @export
 */
export const VesselsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VesselsApiAxiosParamCreator(configuration)
    return {
        /**
         * Sample request:                DELETE /vessels/00000000-0000-0000-0004-100000000000
         * @summary Delete a vessel by id
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVesselsVesselid(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVesselsVesselid(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                GET /vessels  GET /vessels?vesselType=0
         * @summary Retrieves all vessels
         * @param {string} [vesselType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVessels(vesselType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMAPOCustomerManagementWebV2ModelsVesselDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVessels(vesselType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000
         * @summary Retrieves a vessel by id
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVesselsVesselid(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsVesselDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselsVesselid(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all vessel contact persons by vessel id
         * @summary Retrieves all vessel contact persons by vessel id
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVesselsVesselidContactpersons(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMAPOCustomerManagementWebV2ModelsContactPersonDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselsVesselidContactpersons(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new vessel
         * @summary Create a new vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselCreateRequestDto} [rHIMAPOCustomerManagementWebV2ModelsVesselCreateRequestDto] The json body which contains info about the new vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postVessels(rHIMAPOCustomerManagementWebV2ModelsVesselCreateRequestDto?: RHIMAPOCustomerManagementWebV2ModelsVesselCreateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsVesselDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postVessels(rHIMAPOCustomerManagementWebV2ModelsVesselCreateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                PUT /vessels/00000000-0000-0000-0004-100000000000
         * @summary Updates a vessel by id
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselUpdateRequestDto} [rHIMAPOCustomerManagementWebV2ModelsVesselUpdateRequestDto] The updated vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putVesselsVesselid(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselUpdateRequestDto?: RHIMAPOCustomerManagementWebV2ModelsVesselUpdateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putVesselsVesselid(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselUpdateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update all vessel contact persons by vessel id
         * @summary Update all vessel contact persons by vessel id
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {Array<RHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto>} [rHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto] A list of vessel contact persons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putVesselsVesselidContactpersons(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto?: Array<RHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putVesselsVesselidContactpersons(vesselId, rHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VesselsApi - factory interface
 * @export
 */
export const VesselsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VesselsApiFp(configuration)
    return {
        /**
         * Sample request:                DELETE /vessels/00000000-0000-0000-0004-100000000000
         * @summary Delete a vessel by id
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVesselsVesselid(vesselId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteVesselsVesselid(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                GET /vessels  GET /vessels?vesselType=0
         * @summary Retrieves all vessels
         * @param {string} [vesselType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVessels(vesselType?: string, options?: any): AxiosPromise<Array<RHIMAPOCustomerManagementWebV2ModelsVesselDto>> {
            return localVarFp.getVessels(vesselType, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000
         * @summary Retrieves a vessel by id
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselid(vesselId: string, options?: any): AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsVesselDto> {
            return localVarFp.getVesselsVesselid(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all vessel contact persons by vessel id
         * @summary Retrieves all vessel contact persons by vessel id
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidContactpersons(vesselId: string, options?: any): AxiosPromise<Array<RHIMAPOCustomerManagementWebV2ModelsContactPersonDto>> {
            return localVarFp.getVesselsVesselidContactpersons(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new vessel
         * @summary Create a new vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselCreateRequestDto} [rHIMAPOCustomerManagementWebV2ModelsVesselCreateRequestDto] The json body which contains info about the new vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postVessels(rHIMAPOCustomerManagementWebV2ModelsVesselCreateRequestDto?: RHIMAPOCustomerManagementWebV2ModelsVesselCreateRequestDto, options?: any): AxiosPromise<RHIMAPOCustomerManagementWebV2ModelsVesselDto> {
            return localVarFp.postVessels(rHIMAPOCustomerManagementWebV2ModelsVesselCreateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                PUT /vessels/00000000-0000-0000-0004-100000000000
         * @summary Updates a vessel by id
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMAPOCustomerManagementWebV2ModelsVesselUpdateRequestDto} [rHIMAPOCustomerManagementWebV2ModelsVesselUpdateRequestDto] The updated vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselsVesselid(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselUpdateRequestDto?: RHIMAPOCustomerManagementWebV2ModelsVesselUpdateRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.putVesselsVesselid(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselUpdateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Update all vessel contact persons by vessel id
         * @summary Update all vessel contact persons by vessel id
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {Array<RHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto>} [rHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto] A list of vessel contact persons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselsVesselidContactpersons(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto?: Array<RHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto>, options?: any): AxiosPromise<void> {
            return localVarFp.putVesselsVesselidContactpersons(vesselId, rHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VesselsApi - object-oriented interface
 * @export
 * @class VesselsApi
 * @extends {BaseAPI}
 */
export class VesselsApi extends BaseAPI {
    /**
     * Sample request:                DELETE /vessels/00000000-0000-0000-0004-100000000000
     * @summary Delete a vessel by id
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselsApi
     */
    public deleteVesselsVesselid(vesselId: string, options?: AxiosRequestConfig) {
        return VesselsApiFp(this.configuration).deleteVesselsVesselid(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                GET /vessels  GET /vessels?vesselType=0
     * @summary Retrieves all vessels
     * @param {string} [vesselType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselsApi
     */
    public getVessels(vesselType?: string, options?: AxiosRequestConfig) {
        return VesselsApiFp(this.configuration).getVessels(vesselType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                GET /vessels/00000000-0000-0000-0004-100000000000
     * @summary Retrieves a vessel by id
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselsApi
     */
    public getVesselsVesselid(vesselId: string, options?: AxiosRequestConfig) {
        return VesselsApiFp(this.configuration).getVesselsVesselid(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all vessel contact persons by vessel id
     * @summary Retrieves all vessel contact persons by vessel id
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselsApi
     */
    public getVesselsVesselidContactpersons(vesselId: string, options?: AxiosRequestConfig) {
        return VesselsApiFp(this.configuration).getVesselsVesselidContactpersons(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new vessel
     * @summary Create a new vessel
     * @param {RHIMAPOCustomerManagementWebV2ModelsVesselCreateRequestDto} [rHIMAPOCustomerManagementWebV2ModelsVesselCreateRequestDto] The json body which contains info about the new vessel.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselsApi
     */
    public postVessels(rHIMAPOCustomerManagementWebV2ModelsVesselCreateRequestDto?: RHIMAPOCustomerManagementWebV2ModelsVesselCreateRequestDto, options?: AxiosRequestConfig) {
        return VesselsApiFp(this.configuration).postVessels(rHIMAPOCustomerManagementWebV2ModelsVesselCreateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                PUT /vessels/00000000-0000-0000-0004-100000000000
     * @summary Updates a vessel by id
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {RHIMAPOCustomerManagementWebV2ModelsVesselUpdateRequestDto} [rHIMAPOCustomerManagementWebV2ModelsVesselUpdateRequestDto] The updated vessel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselsApi
     */
    public putVesselsVesselid(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsVesselUpdateRequestDto?: RHIMAPOCustomerManagementWebV2ModelsVesselUpdateRequestDto, options?: AxiosRequestConfig) {
        return VesselsApiFp(this.configuration).putVesselsVesselid(vesselId, rHIMAPOCustomerManagementWebV2ModelsVesselUpdateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update all vessel contact persons by vessel id
     * @summary Update all vessel contact persons by vessel id
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {Array<RHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto>} [rHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto] A list of vessel contact persons
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselsApi
     */
    public putVesselsVesselidContactpersons(vesselId: string, rHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto?: Array<RHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto>, options?: AxiosRequestConfig) {
        return VesselsApiFp(this.configuration).putVesselsVesselidContactpersons(vesselId, rHIMAPOCustomerManagementWebV2ModelsUpdateContactPersonRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}



