import { GlyphDiamond } from '../../glyph';
import { GlyphDefinition } from '../../theme';

type Props = Pick<GlyphDefinition, 'fill' | 'name'>;

export const Glyph: React.FC<Props> = (props) => {
  const { fill, name } = props;

  return (
    <svg width={16} height={16}>
      {(() => {
        switch (name) {
          case 'GlyphDiamond':
            return <GlyphDiamond x={4} y={4} fill={fill} width={8} height={8} />;
            break;
          default:
            return null;
        }
      })()}
    </svg>
  );
};
