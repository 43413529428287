import { settings } from '@rhim/design';
import { ChevronLeftIcon, ChevronRightIcon } from '@rhim/icons/16';
import { Button, InputNumeric } from '@rhim/react';
import { currentPageNumberInputMeasurementData, nextButtonMeasurementData, previousButtonMeasurementData } from '@rhim/test-ids';
import { isDefined } from '@rhim/utils';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface PaginationProps {
  currentPage: number;
  itemsCount: number;
  onPageChange: (value: number) => void;
  isDisabled?: boolean;
}

const Pagination = ({ currentPage, itemsCount, onPageChange, isDisabled }: PaginationProps) => {
  const { t } = useTranslation(['app']);
  const totalPageNr = useMemo(() => Math.ceil(itemsCount / 10), [itemsCount]);
  return (
    <PaginationContainer>
      <PrevButton
        data-test-id={previousButtonMeasurementData}
        label={t('app:core.previous')}
        icon={{ icon: <ChevronLeftIcon />, position: 'start' }}
        mode="ghost"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1 || isDisabled}
      />
      <Pages>
        <SInputNumeric
          data-test-id={currentPageNumberInputMeasurementData}
          isDisabled={isDisabled}
          value={currentPage}
          min={1}
          max={totalPageNr}
          variant="small"
          onChange={(value) => {
            if (isDefined(value) && value > 0) {
              onPageChange(value);
            }
          }}
        />
        {Boolean(totalPageNr) && <PagesNr> / {totalPageNr}</PagesNr>}
      </Pages>
      <NextButton
        data-test-id={nextButtonMeasurementData}
        disabled={currentPage === totalPageNr || isDisabled}
        label={t('app:core.next')}
        icon={{ icon: <ChevronRightIcon />, position: 'end' }}
        mode="ghost"
        onClick={() => onPageChange(currentPage + 1)}
      />
    </PaginationContainer>
  );
};

const PaginationContainer = styled.div`
  margin-top: ${settings.Spacing.Spacing_400};
  display: inline-flex;
`;

const Pages = styled.div`
  display: inline-flex;
  justify-content: center;
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.Small};
  color: ${settings.colors.Primary.Grey_5};
  align-items: center;
`;

const PagesNr = styled.span`
  justify-content: center;
  display: flex;
  align-items: center;
`;

const SInputNumeric = styled(InputNumeric)`
  display: flex;
  color: ${settings.colors.Primary.Blue_8};
  justify-content: center;
  align-items: center;
  background-color: ${settings.colors.Monochromatic.White};
  height: ${settings.Spacing.Spacing_500};
  width: ${settings.Spacing.Spacing_700};
  margin-right: ${settings.Spacing.Spacing_50};
`;

const PrevButton = styled(Button)`
  align-self: flex-start;
  font-size: ${settings.typography.FontSize.Small};
  margin-right: auto;
`;

const NextButton = styled(PrevButton)`
  margin-right: 0;
  margin-left: auto;
`;

export default React.memo(Pagination);
