import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const ArrowRightIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M18.586 13l-3.293 3.293c-.391.39-.391 1.023 0 1.414.391.39 1.023.39 1.414 0l5-5c.391-.391.391-1.023 0-1.414l-5-5C16.512 6.098 16.256 6 16 6c-.256 0-.512.098-.707.293-.391.39-.391 1.023 0 1.414L18.586 11H3c-.552 0-1 .448-1 1s.448 1 1 1h15.586z"
      />
    </svg>
  );
};

ArrowRightIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
