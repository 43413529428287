import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const RawData: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg fillRule="evenodd" clipRule="evenodd" width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        d="M15.444 11.567a1 1 0 0 1-1.558.112L10.34 7.842l-1.412 3.53a.998.998 0 0 1-1.299.557 1 1 0 0 1-.558-1.3l2-5a.999.999 0 0 1 1.663-.308l3.772 4.082.67-.97a1 1 0 0 1 1.647 1.134l-1.379 2zM8 14a1 1 0 0 0-1 1v2a1 1 0 1 0 2 0v-2a1 1 0 0 0-1-1zM11 17v-2a1 1 0 1 1 2 0v2a1 1 0 1 1-2 0zM15 15v2a1 1 0 1 0 2 0v-2a1 1 0 1 0-2 0z"
      />
      <path
        fill={fill}
        d="M20 21a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h11c.011 0 .021.003.032.006.009.003.018.006.027.006.09.006.178.022.264.052l.027.01a.989.989 0 0 1 .357.22l3 2.999a.962.962 0 0 1 .224.37l.006.017c.028.084.045.17.051.258 0 .01.003.02.006.03.003.01.006.021.006.032v15zM6 4v16h12V7h-2a1 1 0 0 1-1-1V4H6z"
      />
    </svg>
  );
};

RawData.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
