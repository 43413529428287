import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const EditMarkerIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m14.354 9.06 4.95-4.95c.39-.39.39-1.024 0-1.415a1.003 1.003 0 0 0-1.414 0l-4.95 4.95 1.414 1.414zm-1.497 1.33L11.61 9.143l-.623 1.871 1.871-.624zm2.9-8.39-5.645 5.645a.994.994 0 0 0-.242.391L8.456 12.28a.996.996 0 0 0 .242 1.023.997.997 0 0 0 1.023.242l4.243-1.415a.998.998 0 0 0 .39-.242L20 6.242v8.304a1 1 0 0 1-.341.753l-7 6.125a1 1 0 0 1-1.318 0l-7-6.125A1 1 0 0 1 4 14.546V3a1 1 0 0 1 1-1h10.758z"
        fill={fill}
      />
    </svg>
  );
};

EditMarkerIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
