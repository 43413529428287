import { settings } from '@rhim/design';
import { assert, isDefined } from '@rhim/utils';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { TextField } from '../TextField';

const COLOR_PICKER_BOX_SIZE_PX = 32;

/**
 * Re-uses the shared Textfield and overlays a colored rectangle at the Textfield's input right-most side.
 * Clicking on this colored rectangle shows a color-picker.
 */
interface Props extends React.ComponentProps<typeof TextField> {
  className?: string;
  onValidityChanged?: (isValueValid: boolean) => void;
}
const InputColorPicker: React.ChildlessComponent<Props> = ({
  className,
  value = settings.colors.Monochromatic.White,
  onChange,
  onValidityChanged,
  ...rest
}) => {
  const textfieldRef = React.useRef<HTMLDivElement>(null);
  const [offsetY, setOffsetY] = useState(0);
  const textfieldLabel = rest.label;

  //Sets the right y offset for the color-picker's box in order for it to align vertically at the center of the Textfield's input
  useEffect(() => {
    if (!isDefined(textfieldRef.current)) {
      return;
    }
    const textfieldLabelContainerHeight = (() => {
      if (!isDefined(textfieldLabel)) {
        return 0;
      }
      const textfieldLabelContainer = textfieldRef.current.children[0];
      assert(isDefined(textfieldLabelContainer), "InputColorPicker, textfield's label container not set");
      const computedStyle = window.getComputedStyle(textfieldLabelContainer);
      return textfieldLabelContainer.clientHeight + parseInt(computedStyle.marginBottom);
    })();
    const textfieldInputContainerHeight = (() => {
      const textfieldInputContainer = textfieldRef.current.children[isDefined(textfieldLabel) ? 1 : 0];
      assert(isDefined(textfieldInputContainer), "InputColorPicker, textfield's input container not set");
      return textfieldInputContainer.clientHeight;
    })();
    const offsetYToCenterColorPickerBox = textfieldInputContainerHeight / 2 - COLOR_PICKER_BOX_SIZE_PX / 2;
    const offsetY = textfieldLabelContainerHeight + offsetYToCenterColorPickerBox;
    setOffsetY(offsetY);
  }, [textfieldLabel]);

  const handleValueChanged = useCallback(
    (newValue: string) => {
      onChange?.(newValue);
      const colorHexRegExp = /^#([A-F0-9]{3}|[A-F0-9]{6})$/i;
      const isValid = colorHexRegExp.test(newValue);
      onValidityChanged?.(isValid);
    },
    [onChange, onValidityChanged]
  );

  const handleColorPickerValueChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleValueChanged(event.target.value);
    },
    [handleValueChanged]
  );

  return (
    <SWrapper className={className}>
      <STextField ref={textfieldRef} value={value} onChange={handleValueChanged} {...rest} />
      <SColorPickerContainer style={{ top: offsetY }}>
        <SColoredBox fill={value} />
        <SColorPicker type="color" value={value} onChange={handleColorPickerValueChanged} />
      </SColorPickerContainer>
    </SWrapper>
  );
};

InputColorPicker.whyDidYouRender = true;
export default React.memo(InputColorPicker);

const SWrapper = styled.div`
  position: relative;
`;

const STextField = styled(TextField)`
  input {
    margin-right: calc(${COLOR_PICKER_BOX_SIZE_PX}px + ${settings.Spacing.Spacing_150});
  }
`;

const SColorPickerContainer = styled.div`
  position: absolute;
  right: calc(${COLOR_PICKER_BOX_SIZE_PX}px + ${settings.Spacing.Spacing_100});
`;

/**
 * HTML input with type "color" has an undesirable browser specific styling that we cannot override.
 * ( it displays a colored box with some margin around it )
 * To achieve a color-picker box without any margin we overlay :
 * a) SColoredBox : our own div rectangle filled with the selected color
 * b) on top of a) display SColorPickerthe which is an input with type "color" but with opacity set to zero to make it completely transparent.
 * The input "color" will still receive mouse-clicks and display its popup.
 */
const SColoredBox = styled.div<{ fill: string }>`
  position: absolute;
  width: ${COLOR_PICKER_BOX_SIZE_PX}px;
  height: ${COLOR_PICKER_BOX_SIZE_PX}px;
  background-color: ${(props) => props.fill};
  border: 1px solid ${settings.colors.Primary.Grey_3};
`;

const SColorPicker = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: ${COLOR_PICKER_BOX_SIZE_PX}px;
  height: ${COLOR_PICKER_BOX_SIZE_PX}px;
`;
