import { RHIMFleetOverviewServiceV1ModelsVesselOverviewDataDto } from '@rhim/rest/fleetOverview';
import { specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api/fleetOverview';

type Payload = RHIMFleetOverviewServiceV1ModelsVesselOverviewDataDto[];

export function useFleetOverviewVessels(
  customerId: UUID,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) {
  return useQuery(getKey(customerId), (context) => queryFn(context, options?.axiosConfiguration), {
    ...options?.configuration,
  });
}

const getKey = (customerId: UUID) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'fleetOverview',
      scope: 'ladles',
      entity: 'list',
      customerId,
    },
  ]);

const queryFn = ({ queryKey: [{ customerId }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  return API.fleetOverview.getFleetoverviewCustomeridVessels(customerId, { signal, ...axiosConfiguration }).then((response) => response.data);
};

useFleetOverviewVessels.getKey = getKey;
useFleetOverviewVessels.queryFn = queryFn;
