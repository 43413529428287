import { assert, isDefined } from '@rhim/utils';
import React from 'react';

import FilterContext from '../context/FilterContext';

interface Props {
  isEnabled: boolean;
}

export const useFilterContext = (props: Props) => {
  const filterContext = React.useContext(FilterContext);

  if (props.isEnabled) {
    assert(isDefined(filterContext), 'useFilterContext must be inside a FilterContext');
  }

  return filterContext;
};
