import { settings } from '@rhim/design';
import { CircleXIcon } from '@rhim/icons/24';
import { isDefined } from '@rhim/utils';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

type Size = 'large' | 'small';

export type EmptyStateProps = {
  dataTestIdMessage?: string;
  dataTestIdDescription?: string;
  className?: string;
  size?: Size;
  message: string;
  description?: string;
  footer?: React.ReactNode;
};

export const EmptyState: FC<React.PropsWithChildren<EmptyStateProps>> = ({
  dataTestIdMessage,
  dataTestIdDescription,
  className,
  size = 'large',
  message,
  description,
  footer,
}) => {
  return (
    <SWrapper className={className} size={size} hasFooter={isDefined(footer)}>
      <CircleXIcon fill={settings.colors.Operational.State_Notif_Grey_2} size={size === 'large' ? '64px' : '32px'} />
      <SMessage data-test-id={dataTestIdMessage} hasDescription={isDefined(description)}>
        {message}
      </SMessage>
      {isDefined(description) && <SDescription data-test-id={dataTestIdDescription}>{description}</SDescription>}
      {footer ?? null}
    </SWrapper>
  );
};

const SWrapper = styled.div<{ size: Size; hasFooter: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color);

  --color: ${settings.colors.Operational.State_Notif_Grey_2};
  --fontSizeMessage: ${(props) => (props.size === 'large' ? `${settings.typography.FontSize.XXXX_Large}` : `${settings.typography.FontSize.Large}`)};
  --fontSizeDescription: ${(props) => (props.size === 'large' ? `${settings.typography.FontSize.Medium}` : `${settings.typography.FontSize.Small}`)};
  --marginTopMessage: ${(props) => (props.size === 'large' ? settings.Spacing.Spacing_200 : settings.Spacing.Spacing_100)};
  --marginBottomDescription: ${(props) => {
    if (props.size === 'large') {
      return props.hasFooter ? settings.Spacing.Spacing_400 : settings.Spacing.Spacing_500;
    } else {
      return props.hasFooter ? settings.Spacing.Spacing_200 : settings.Spacing.Spacing_300;
    }
  }};
`;

const SMessage = styled.span<{ hasDescription: boolean }>`
  font-size: var(--fontSizeMessage);
  font-family: ${settings.typography.FontFamily.Bold};
  margin-top: var(--marginTopMessage);
  ${(props) =>
    !props.hasDescription &&
    css`
      margin-bottom: var(--marginBottomDescription);
    `}
`;

const SDescription = styled.span`
  margin-top: ${settings.Spacing.Spacing_100};
  font-size: var(--fontSizeDescription);
  margin-bottom: var(--marginBottomDescription);
`;
