import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  backgroundColor?: Property.Color;
  foregroundColor?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const OpsStateCriticalIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  backgroundColor,
  foregroundColor,
  size,
  title,
  role,
  ...rest
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <path
          fill={backgroundColor}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.557 2h-9.113c-.795 0-1.56.316-2.121.879L2.879 9.322a3.005 3.005 0 0 0-.88 2.122v9.112c0 .796.318 1.559.88 2.122l6.444 6.443a2.998 2.998 0 0 0 2.12.879h9.114c.795 0 1.559-.316 2.12-.879l6.444-6.443A3 3 0 0 0 30 20.556v-9.112a3 3 0 0 0-.88-2.122l-6.442-6.443A2.998 2.998 0 0 0 20.557 2z"
        />
        <path
          fill={foregroundColor}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 17a1 1 0 0 0 2 0V9a1 1 0 1 0-2 0v8zm1 5.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
        />
      </g>
    </svg>
  );
};

OpsStateCriticalIcon.defaultProps = {
  size: '32',
  backgroundColor: settings.colors.Operational.State_Alert_Red_3,
  foregroundColor: settings.colors.Monochromatic.White,
};
