import { VesselType } from '@rhim/rest';
import { RHIMAPOReportingWearManagementApiV1ModelsRHParameter } from '@rhim/rest/wearManagement';
import { flatMap, specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import type { AxiosError, AxiosRequestConfig } from 'axios';

import { API } from '../api';

type Payload = RHIMAPOReportingWearManagementApiV1ModelsRHParameter[];
type ErrorResponse = AxiosError<unknown>;

export const useParameterValuesForVessel = (
  vesselId: UUID,
  vesselType: VesselType,
  campaign?: string,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, ErrorResponse, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) => {
  return useQuery(getKey(vesselId, vesselType, campaign), (context) => queryFn(context, options?.axiosConfiguration), options?.configuration);
};

const getKey = (vesselId: UUID, vesselType: VesselType, campaign?: string) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'wearManagement',
      scope: 'process-parameter-values',
      entity: 'list',
      vesselId,
      vesselType,
      campaign,
    },
  ]);

const queryFn = (
  { queryKey: [{ vesselId, vesselType, campaign }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>,
  axiosConfiguration?: AxiosRequestConfig
) => {
  switch (vesselType) {
    case VesselType.Bof:
      return API.wearExplorer.getWearexplorerviewVesselsBofVesselid(vesselId, campaign, { signal, ...axiosConfiguration }).then((response) => {
        return flatMap(response.data.reportData?.hotSpots ?? [], (hotSpot) => hotSpot.parameters ?? []);
      });
    case VesselType.Rh:
      return API.wearExplorer.getWearexplorerviewVesselsRhVesselid(vesselId, campaign, { signal, ...axiosConfiguration }).then((response) => {
        return flatMap(response.data.reportData?.hotSpots ?? [], (hotSpot) => hotSpot.parameters ?? []);
      });
    default:
      throw new Error(`Vessel type "${vesselType}" is not supported`);
  }
};

useParameterValuesForVessel.getKey = getKey;
useParameterValuesForVessel.queryFn = queryFn;
