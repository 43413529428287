import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const FleetOverviewIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill={fill}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 9H5V4h4v5zm-5 2h3.338C8.216 9.785 9.482 8.773 11 8.07V3a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1zm0 2h2.315A6.51 6.51 0 0 0 6 15H5v5h3.193a9.663 9.663 0 0 0 2.457 1.76.996.996 0 0 1-.65.24H4a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1zM21 3v5.07a11.347 11.347 0 0 0-2-.704V4h-4v3.04c-.69.054-1.359.165-2 .326V3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1zm-5 16c2.582 0 4.877-1.603 5.875-4-.998-2.397-3.293-4-5.875-4s-4.877 1.603-5.875 4c.998 2.397 3.293 4 5.875 4zm0-10c3.746 0 6.919 2.52 8 6-1.081 3.48-4.254 6-8 6-3.746 0-6.919-2.52-8-6 1.081-3.48 4.254-6 8-6zm0 9a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
        />
      </svg>
    </svg>
  );
};

FleetOverviewIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
