import { settings } from '@rhim/design';
import { OpsStateCriticalIcon } from '@rhim/icons/24';
import {
  criticalIconLiningThicknessBarFleetOverview,
  maximumValueBarLiningThicknessBarFleetOverview,
  minimumValueBarLiningThicknessBarFleetOverview,
} from '@rhim/test-ids';
import { ColorScales, getColorUsingScale, head, last } from '@rhim/utils';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const WIDTH_PX = 112;

export const CRITICAL_THRESHOLD_VALUE = 50;

export const TRANSITION = {
  ease: 'easeOut',
  duration: 1.5, // in seconds
};

interface LiningThicknessBarProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  colorScales: ColorScales;
  minValue: number;
  maxValue: number;
  isActive?: boolean;
}
const LiningThicknessBar = React.forwardRef<HTMLDivElement, LiningThicknessBarProps>(
  ({ className, colorScales, minValue, maxValue, isActive = true, ...rest }, ref) => {
    const [upperValueBarWidth, setUpperValueBarWidth] = useState(WIDTH_PX);
    const [lowerValueBarWidth, setLowerValueBarWidth] = useState(WIDTH_PX);
    const [lowerValueBarColor, setLowerValueBarColor] = useState(head(colorScales).color);

    useEffect(() => {
      const minDomainValue = last(colorScales).threshold;
      const maxDomainValue = head(colorScales).threshold;
      setLowerValueBarWidth(Math.min((WIDTH_PX * (minValue - minDomainValue)) / (maxDomainValue - minDomainValue), WIDTH_PX));
      setUpperValueBarWidth(Math.min((WIDTH_PX * (maxValue - minDomainValue)) / (maxDomainValue - minDomainValue), WIDTH_PX));
      const color = getColorUsingScale(minValue, colorScales);
      setLowerValueBarColor(color);
    }, [colorScales, minValue, maxValue]);

    const isStateCritical = minValue < CRITICAL_THRESHOLD_VALUE;

    return (
      <SWrapper ref={ref} className={className} {...rest} style={{ width: WIDTH_PX }}>
        <SBarDottedOuter />
        <SBarUpperValue
          data-test-id={maximumValueBarLiningThicknessBarFleetOverview}
          isActive={isActive}
          initial={{ width: WIDTH_PX }}
          animate={{ width: upperValueBarWidth }}
          transition={TRANSITION}
        ></SBarUpperValue>
        <SBarLowerValue
          data-test-id={minimumValueBarLiningThicknessBarFleetOverview}
          isActive={isActive}
          isCritical={isStateCritical}
          background={lowerValueBarColor}
          initial={{ width: WIDTH_PX }}
          animate={{ width: lowerValueBarWidth }}
          transition={TRANSITION}
        ></SBarLowerValue>
        {isStateCritical && (
          <SCriticalStateIconContainer data-test-id={criticalIconLiningThicknessBarFleetOverview}>
            <OpsStateCriticalIcon />
          </SCriticalStateIconContainer>
        )}
      </SWrapper>
    );
  }
);

LiningThicknessBar.displayName = 'LiningThicknessBar';

const SWrapper = styled.div`
  --border-radius: 2px;

  height: 24px;
  position: relative;
`;

const SBarDottedOuter = styled.div`
  border: solid 1px ${settings.colors.Primary.Grey_6};
  border-style: dotted;
  border-radius: var(--border-radius);
  background-color: transparent;
  height: 100%;
`;

const SBarUpperValue = styled(motion.div)<{ isActive: boolean }>`
  --borderColor: ${(props) => (props.isActive ? settings.colors.Primary.Grey_5 : settings.colors.Primary.Grey_4)};

  border: solid 1px var(--borderColor);
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  background-color: ${(props) => (props.isActive ? settings.colors.Primary.Grey_2 : settings.colors.Primary.Grey_3)};
  position: absolute;
  height: 100%;
  top: 0;

  &::before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    right: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 7px solid var(--borderColor);
    transform: translate(50%, -100%);
  }
`;

const SBarLowerValue = styled(motion.div)<{ background: string; isActive: boolean; isCritical: boolean }>`
  --borderThickness: 2px;
  --borderColor: ${(props) => {
    if (props.isActive) {
      if (props.isCritical) {
        return settings.colors.Operational.State_Alert_Red_4;
      }
      return settings.colors.Primary.Grey_8;
    }
    return settings.colors.Primary.Grey_5;
  }};

  border: solid 2px var(--borderColor);
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  background-color: ${(props) => (props.isActive ? props.background : settings.colors.Primary.Grey_4)};
  position: absolute;
  height: calc(100% + var(--borderThickness));
  top: calc(-1 * var(--borderThickness) / 2);

  &::before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    bottom: 0;
    right: calc(-1 * var(--borderThickness) / 2);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 7px solid var(--borderColor);
    transform: translate(50%, 100%);
  }
`;

const SCriticalStateIconContainer = styled.span`
  display: flex;
  align-items: center;
  position: absolute;

  --offset: -12px;

  top: var(--offset);
  right: var(--offset);
`;

export default React.memo(LiningThicknessBar);
