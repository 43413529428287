import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { DataTableIcon, GraphIcon } from '@rhim/icons/24';
import { IconButton } from '@rhim/react';
import { graphViewButtonOperatorDisplay, tableViewButtonOperatorDisplay } from '@rhim/test-ids';
import styled from 'styled-components';

interface Props {
  toggleGraphView: (enable: boolean) => void;
  isGraphView: boolean;
  isTableView: boolean;
  toggleTableView: (enable: boolean) => void;
  compareMode: boolean;
}

const ViewModeSegment = ({ toggleGraphView, compareMode, isGraphView, isTableView, toggleTableView }: Props) => {
  const { t } = i18nReact.useTranslation(['operator-display']);

  return (
    <Container>
      <SSpan>{t('operator-display:legend.viewMode')}</SSpan>
      <IconButton
        data-test-id={graphViewButtonOperatorDisplay}
        mode="dark"
        isFilled={true}
        isSelected={isGraphView}
        icon={<GraphIcon />}
        onPress={() => {
          if (!isGraphView) {
            toggleTableView(false);
            toggleGraphView(true);
          }
        }}
      />
      <IconButton
        data-test-id={tableViewButtonOperatorDisplay}
        mode="dark"
        isFilled={true}
        icon={<DataTableIcon />}
        isDisabled={compareMode}
        isSelected={isTableView}
        onPress={() => {
          if (!isTableView) {
            toggleTableView(true);
            toggleGraphView(false);
          }
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  margin-right: 16px;
`;

const SSpan = styled.span`
  color: ${settings.colors.Primary.Grey_4};
  font-size: ${settings.typography.FontSize.X_Small};
  font-family: ${settings.typography.FontFamily.Medium};
  padding: 4px 16px;
`;

export default ViewModeSegment;
