import { RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCommonMetadataKeysDto } from '@rhim/rest/fileIngestion';
import { specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api';

type Payload = RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCommonMetadataKeysDto;

export const useCustomerFileTypeMetadataKeys = (
  customerId: UUID,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) => {
  return useQuery(getKey(customerId), (context) => queryFn(context, options?.axiosConfiguration), options?.configuration);
};

const getKey = (customerId: UUID) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'fileIngestion',
      scope: 'customerFileTypeMetadataKeys',
      entity: 'detail',
      customerId,
    },
  ]);
const queryFn = ({ signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  return API.customerFileTypes.getCustomerFileTypeConfigurationCommonMetadataKeys({ signal, ...axiosConfiguration }).then((response) => response.data);
};

useCustomerFileTypeMetadataKeys.getKey = getKey;
useCustomerFileTypeMetadataKeys.queryFn = queryFn;
