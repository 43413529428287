import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const MissingDataIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="4 4 24 24" {...rest} style={{ overflow: 'visible' }}>
      {isDefined(title) ? <title>{title}</title> : null}
      <rect x="4" y="4" width="24" height="24" rx="12" fill="#ECEEF0" />
      <path
        fill={fill}
        fillRule="evenodd"
        d="M10 16a6 6 0 0 1 9.477-4.89l-8.368 8.367A5.973 5.973 0 0 1 10 16zm2.524 4.89a6 6 0 0 0 8.367-8.367l-8.368 8.368zM16 8a8 8 0 1 0 0 16 8 8 0 0 0 0-16z"
      />
    </svg>
  );
};

MissingDataIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Grey_6,
};
