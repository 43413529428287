import { isDefined } from './is-defined';

export const sortAlphabeticallyBy =
  <K extends string>(field: K, backupField?: K) =>
  <T extends { [P in K]?: string | null }>(left: T, right: T) =>
    //eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/no-non-null-assertion
    (isDefined(backupField) ? left[field] || left[backupField] : left[field])!.localeCompare(
      //eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/no-non-null-assertion
      (isDefined(backupField) ? right[field] || right[backupField] : right[field])!,
      undefined,
      {
        numeric: true,
        sensitivity: 'base',
      }
    );

export const sortNumericallyBy =
  <K extends string>(field: K) =>
  <T extends Partial<Record<K, number | null>>>(left: T, right: T) =>
    (left[field] ?? 0) - (right[field] ?? 0);
