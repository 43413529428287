import { settings } from '@rhim/design';
import React from 'react';
import styled from 'styled-components';

import { LegendItemLabel } from './utils';

interface Props {
  dotFill?: settings.colors.Any;
  label: string;
}
const Dot: React.ChildlessComponent<Props> = ({ dotFill = settings.colors.Primary.Blue_9, label }) => {
  return (
    <SWrapper>
      <SDot dotFill={dotFill} />
      <LegendItemLabel>{label}</LegendItemLabel>
    </SWrapper>
  );
};
Dot.whyDidYouRender = true;
export default React.memo(Dot);

const SWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SDot = styled.div<Pick<Props, 'dotFill'>>`
  --size: 4px;

  border-radius: 50%;
  width: var(--size);
  height: var(--size);
  background-color: ${(props) => props.dotFill};
`;
