import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const GraphIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        d="M1 16h14a1 1 0 0 0 0-2H2V1a1 1 0 0 0-2 0v14a1 1 0 0 0 1 1zm5.293-4.293a.997.997 0 0 0 1.575-.211l3.351-5.863 2.074 2.074a.999.999 0 1 0 1.414-1.414l-3-3a1 1 0 0 0-1.575.211L6.781 9.367 4.707 7.293a.999.999 0 1 0-1.414 1.414l3 3z"
        fill={fill}
      />
    </svg>
  );
};

GraphIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
