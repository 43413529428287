import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const EyeOpenIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.875 8c-.998 2.397-3.293 4-5.875 4s-4.877-1.603-5.875-4C3.123 5.603 5.418 4 8 4s4.877 1.603 5.875 4zM16 8c-1.081-3.48-4.254-6-8-6-3.746 0-6.919 2.52-8 6 1.081 3.48 4.254 6 8 6 3.746 0 6.919-2.52 8-6zM5 8a3 3 0 1 0 6 0 3 3 0 0 0-6 0z"
      />
    </svg>
  );
};

EyeOpenIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Grey_1,
};
