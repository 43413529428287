import { RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductDto } from '@rhim/rest/wearManagement';
import { specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api';

type Payload = RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductDto[];

export const useVesselFunctionalProducts = (
  vesselId: UUID,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) => {
  return useQuery(getKey(vesselId), (context) => queryFn(context, options?.axiosConfiguration), options?.configuration);
};

const getKey = (vesselId?: UUID) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'wearManagement',
      scope: 'functional-products',
      entity: 'list',
      vesselId,
    },
  ]);

const queryFn = ({ queryKey: [{ vesselId }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  return API.vesselFunctionalProducts
    .getVesselfunctionalproductsVesselid(vesselId as string, { signal, ...axiosConfiguration })
    .then((response) => response.data);
};

useVesselFunctionalProducts.getKey = getKey;
useVesselFunctionalProducts.queryFn = queryFn;
