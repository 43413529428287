import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const CampaignConfigIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        d="m13.226 9 .176.85c.384.122.73.315 1.025.569l.847-.276.725 1.212-.662.563a2.69 2.69 0 0 1 0 1.161l.663.564-.725 1.212-.848-.277c-.294.254-.641.447-1.025.569l-.176.853h-1.449l-.178-.854a2.878 2.878 0 0 1-1.022-.569l-.852.278L9 13.643l.668-.567a2.718 2.718 0 0 1 0-1.154L9 11.355l.725-1.212.85.278a2.89 2.89 0 0 1 1.024-.57L11.777 9h1.45zm-6.014 5a5.472 5.472 0 0 0 1.036 1.988C8.165 15.991 8.084 16 8 16a7.961 7.961 0 0 1-5.28-2zm5.288-3a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM3 9v3H1.079a7.958 7.958 0 0 1-1.01-3H3zm5.257 0a5.469 5.469 0 0 0-1.232 3H5V9zM7 4v3H.069a7.958 7.958 0 0 1 1.01-3H7zm7.921 0a7.958 7.958 0 0 1 1.01 3H9V4zm-6.92-4c2.024 0 3.868.759 5.278 2H2.72A7.963 7.963 0 0 1 8 0z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
};

CampaignConfigIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
