import { VesselType } from '@rhim/rest';
import { RHIMAPOReportingWearManagementApiV1ModelsColorscaleDto } from '@rhim/rest/wearManagement';
import { specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api';

type Payload = RHIMAPOReportingWearManagementApiV1ModelsColorscaleDto;

export const useColorScale = (
  customerId: UUID,
  vesseltype: VesselType,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) => {
  return useQuery(getKey(customerId, vesseltype), (context) => queryFn(context, options?.axiosConfiguration), options?.configuration);
};

const getKey = (customerId: UUID, vesselType: VesselType) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'wearManagement',
      scope: 'color-scale',
      entity: 'detail',
      customerId,
      vesselType,
    },
  ]);

const queryFn = (
  { queryKey: [{ customerId, vesselType }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>,
  axiosConfiguration?: AxiosRequestConfig
) => {
  return API.colorScale.getColorscalesCustomeridVesseltype(customerId, vesselType, { signal, ...axiosConfiguration }).then((response) => response.data);
};

useColorScale.getKey = getKey;
useColorScale.queryFn = queryFn;
