import { isDefined } from '@rhim/utils';
import { AxisBottom as VXAxisBottom, TickLabelProps } from '@vx/axis';
import { ScaleInput, scaleLinear } from '@vx/scale';
import { ScaleLinear } from 'd3-scale';
import React, { useMemo } from 'react';

import { AXIS_STYLE_PROPS, LABELS_OFFSET_PX, MAJOR_TICK_LABELS_STYLE, MAJOR_TICKS_STYLE_PROPS, MINOR_TICKS_STYLE_PROPS } from '../utils';

const axisXMajorTickLabelProps: TickLabelProps<ScaleInput<ScaleLinear<number, number>>> = () => ({
  textAnchor: 'middle',
  dy: LABELS_OFFSET_PX,
  style: MAJOR_TICK_LABELS_STYLE,
});

const axisXMinorTickLabelProps: TickLabelProps<ScaleInput<ScaleLinear<number, number>>> = () => ({
  style: { display: 'none' },
});

interface Props {
  domainXMin: number;
  domainXMax: number;
  plotWidth: number;
  plotHeight: number;
  majorTickValues: number[];
  minorTickStep?: number;
}
const AxisBottom: React.ChildlessComponent<Props> = (props) => {
  const { domainXMin, domainXMax, plotWidth, plotHeight, majorTickValues, minorTickStep } = props;

  const axisProps = useMemo(() => {
    const scale = scaleLinear({ domain: [domainXMin, domainXMax], range: [0, plotWidth] });
    const majorTickFormat = scale.tickFormat(majorTickValues.length);
    return { scale, majorTickFormat };
  }, [domainXMin, domainXMax, plotWidth, majorTickValues]);

  const minorTicks = useMemo(() => {
    if (!isDefined(minorTickStep)) {
      return null;
    }
    const minorTickValues = [];
    let minorTickDomainX = domainXMin + minorTickStep;
    while (minorTickDomainX < domainXMax) {
      minorTickValues.push(minorTickDomainX);
      minorTickDomainX += minorTickStep;
    }
    const scale = scaleLinear({ domain: [domainXMin, domainXMax], range: [0, plotWidth] });
    const minorTickFormat = scale.tickFormat(minorTickValues.length);
    return (
      <VXAxisBottom
        top={plotHeight}
        scale={axisProps.scale}
        tickValues={minorTickValues}
        tickFormat={minorTickFormat}
        tickLabelProps={axisXMinorTickLabelProps}
        {...AXIS_STYLE_PROPS}
        {...MINOR_TICKS_STYLE_PROPS}
      />
    );
  }, [minorTickStep, domainXMin, domainXMax, plotWidth, plotHeight, axisProps.scale]);

  return (
    <>
      {minorTicks}
      <VXAxisBottom
        top={plotHeight}
        scale={axisProps.scale}
        tickValues={majorTickValues}
        tickFormat={axisProps.majorTickFormat}
        tickLabelProps={axisXMajorTickLabelProps}
        {...AXIS_STYLE_PROPS}
        {...MAJOR_TICKS_STYLE_PROPS}
      />
    </>
  );
};
AxisBottom.whyDidYouRender = true;
export default React.memo(AxisBottom);
