import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { ColumnTableType, DataTable, getFormattedDate } from '@rhim/react';
import { RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto, RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel } from '@rhim/rest/operatorDisplay';
import { tableContainerOperatorDisplay } from '@rhim/test-ids';
import { hasElements, isDefined, last } from '@rhim/utils';
import * as React from 'react';
import styled from 'styled-components';

import { getCircleColors } from '../WmsDisplayContainer/dataProcessor';

interface Props {
  regions: NonEmptyArray<RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel>;
  lastHeat: number;
  lastTotalHeat?: number;
  lastMeasurementTimestamp?: string;
}
const MeasurementsTable = ({ regions, lastHeat, lastMeasurementTimestamp }: Props) => {
  const { t } = i18nReact.useTranslation(['operator-display']);

  const getTableRowsData = React.useCallback(() => {
    const points: RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto[] = [] as RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto[];
    const measuredPointsArr = regions.map((region) => region.measuredPoints);
    // eslint-disable-next-line prefer-spread
    const concatenated: RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto[] = [].concat.apply(
      [],
      measuredPointsArr as unknown as [ConcatArray<never>, ...ConcatArray<never>[]]
    );

    concatenated.forEach((point: RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto) => {
      if (!points.find((item) => item.heat === point.heat)) {
        points.push(point);
      }
    });

    let measuredPoints = [
      ...points.map((point) => ({
        heat: point.heat,
        measurementTimestamp: isDefined(point.measurementTimestamp)
          ? getFormattedDate({ datetime: point.measurementTimestamp as Date.ISO_8601, showTime: true })
          : '-',
      })),
    ];

    const lastHeatPoint = {
      heat: lastHeat,
      measurementTimestamp: isDefined(lastMeasurementTimestamp)
        ? getFormattedDate({ datetime: lastMeasurementTimestamp as Date.ISO_8601, showTime: true })
        : '-',
    };

    measuredPoints = [...measuredPoints].sort((left, right) => right.heat - left.heat);
    return (hasElements(measuredPoints) && lastHeat !== measuredPoints[0].heat ? [lastHeatPoint, ...measuredPoints] : measuredPoints).map((point, index) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const regionsMeasurements: any = {};
      regions.forEach((region) => {
        const regionPoint = region.measuredPoints.find((item) => item.heat === point.heat);
        if (isDefined(regionPoint)) {
          regionsMeasurements[region.shortName] = regionPoint.liningThickness;
          regionsMeasurements.heatAtMeasurement = regionPoint.heat; //we want to store the actual heat, as the displayed heat may differ for the last measurement.
        }
      });
      return {
        heat: point.heat,
        date: point.measurementTimestamp,
        index,
        ...regionsMeasurements,
      };
    });
  }, [lastHeat, lastMeasurementTimestamp, regions]);

  const regionNamesCol = regions.map((region) => ({
    title: <Title>{region.displayName}</Title>,
    dataIndex: region.shortName,
    width: '150px',
    key: region.shortName,
    render: (value: number, record: Record<string, number>) => {
      const maxLining = Math.max(...region.measuredPoints.map((point) => point.liningThickness));
      const liningThickness = maxLining > region.initialLiningThickness ? maxLining : region.initialLiningThickness;
      const lastMeasuredPoint = region.measuredPoints.length
        ? last(region.measuredPoints as NonEmptyArray<RHIMOperatorDisplayServiceV1ModelsMeasuredPointDto>)
        : { heat: lastHeat, liningThickness };

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const color = getCircleColors({ heat: record['heatAtMeasurement']!, liningThickness: value }, region, lastMeasuredPoint, true);
      const style = { ...color, borderBottom: `1px solid ${color.border}` } as React.CSSProperties;
      return {
        props: {
          style:
            color.background !== settings.colors.Primary.Grey_8
              ? { ...style, border: `1px solid ${settings.colors.Primary.Grey_3}`, borderTop: 'none', borderRight: 'none' }
              : { border: `0.5px solid ${settings.colors.Primary.Grey_3}`, borderTop: 'none', borderRight: 'none' },
        },
        children: <CellValue index={record['index']}>{isDefined(value) ? value.toFixed(0) : '-'}</CellValue>,
      };
    },
  }));
  const columns: ColumnTableType<Record<string, number>>[] = [
    {
      title: t('operator-display:table.heat'),
      dataIndex: 'heat',
      key: 'heat',
      align: 'center',
      width: '100px',
      fixed: 'left',
      render: (value: number, record: Record<string, number>) => {
        return <Values index={record['index']}>{value}</Values>;
      },
    },
    {
      title: t('operator-display:table.date'),
      dataIndex: 'date',
      key: 'date',
      align: 'left',
      width: '200px',
      fixed: 'left',
      render: (value: number, record: Record<string, number>) => {
        return <Values index={record['index']}>{value}</Values>;
      },
    },
    ...regionNamesCol,
  ];

  return (
    <Container data-test-id={tableContainerOperatorDisplay}>
      <DataTable dataSource={getTableRowsData()} columns={columns} background="grey" sticky={true} pagination={false} scroll={{ x: 'fit-content', y: 600 }} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: ${settings.Spacing.Spacing_300};

  .ant-table-wrapper {
    width: 100%;
  }

  .ant-table-tbody > tr > td {
    height: 32px;
    padding: 0 16px;
    color: ${settings.colors.Primary.Grey_8};
    font-size: ${settings.typography.FontSize.Small};
    font-family: ${settings.typography.FontFamily.Regular};
  }

  .ant-table-thead > tr > th {
    background: ${settings.colors.Monochromatic.White};
    height: ${settings.Spacing.Spacing_800};
    min-width: 180px;

    &::before {
      display: none;
    }
  }
`;

const Title = styled.div`
  display: flex;
  text-align: right;
  justify-content: flex-end;
`;
const Values = styled.div<{ index?: number }>`
  color: ${settings.colors.Primary.Grey_8};
  font-size: ${settings.typography.FontSize.Small};
  font-family: ${(props) => (props.index === 0 ? settings.typography.FontFamily.Bold : settings.typography.FontFamily.Regular)};
`;
const CellValue = styled(Values)<{ index?: number }>`
  text-align: left;
  display: flex;
  justify-content: flex-end;
`;

export default React.memo(MeasurementsTable);
