import { RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto } from '@rhim/rest/measurementService';
import { specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api';

type Payload = RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto;

export const useMeasurementviewSettingsByVesselId = (
  vesselId: string,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) => {
  return useQuery(getKey(vesselId), (context) => queryFn(context, options?.axiosConfiguration), options?.configuration);
};

const getKey = (vesselId: string) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'measurementService',
      scope: 'settings',
      entity: 'detail',
      vesselId,
    },
  ]);

const queryFn = ({ queryKey: [{ vesselId }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  return API.measurementView.getMeasurementviewSettingsEditVesselid(vesselId, { signal, ...axiosConfiguration }).then((response) => response.data);
};

useMeasurementviewSettingsByVesselId.getKey = getKey;
useMeasurementviewSettingsByVesselId.queryFn = queryFn;
