import * as d3 from 'd3';

type VerticalViolinShapeProps = {
  data: number[];
  binNumber: number;
  yScale: d3.ScaleLinear<number, number>;
  width: number;
  fill: string;
};

const VerticalViolinShape = ({ data, yScale, width, binNumber, fill }: VerticalViolinShapeProps) => {
  const min = Math.min(...data);
  const max = Math.max(...data);

  const binBuilder = d3
    .bin()
    .domain([min, max])
    .thresholds(yScale.ticks(binNumber))
    .value((d) => d);

  const bins = binBuilder(data);

  const biggestBin = Math.max(...bins.map((b) => b.length));

  const wScale = d3.scaleLinear().domain([-biggestBin, biggestBin]).range([0, width]);

  const areaBuilder = d3
    .area<d3.Bin<number, number>>()
    .x0((d) => wScale(-d.length))
    .x1((d) => wScale(d.length))
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    .y((d) => yScale(d.x0 || 0))
    .curve(d3.curveStep);

  const areaPath = areaBuilder(bins);
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  return <path d={areaPath || undefined} fill={fill} />;
};

export default VerticalViolinShape;
