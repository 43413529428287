import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const VesselCasterIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.984 1.819a1 1 0 1 0-1.967.363l1.66 9.42a1 1 0 0 0 .983.818h.84v2.33a1 1 0 1 0 2 0v-3.33a1 1 0 0 0-1-1H3.493L1.983 1.82zM11.5 10.42h1.008l1.509-8.601a1 1 0 0 1 1.967.363l-1.66 9.42a1 1 0 0 1-.984.818h-.84v2.33a1 1 0 1 1-2 0v-3.33a1 1 0 0 1 1-1zm.472-5.361a7.627 7.627 0 0 1-1.005.063c-.676 0-1.73-.273-2.8-.549C7.067 4.289 5.95 4 5.211 4a8.64 8.64 0 0 0-1.359.1l.89 4.82H7V12a1 1 0 1 0 2 0V8.92h2.26l.712-3.861zM8 16a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
      />
    </svg>
  );
};

VesselCasterIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
