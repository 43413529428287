import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const CameraInactiveIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="m15.881 3.876-.594-.96C14.936 2.349 14.288 2 13.586 2h-3.172c-.702 0-1.35.349-1.701.916L7.423 5H7V4H5v1H4a2 2 0 0 0-2 2v10c0 .227.038.446.108.65l4.942-4.942a5 5 0 0 1 5.658-5.658l3.173-3.174zM11.292 16.95a5 5 0 0 0 5.658-5.658l4.942-4.941c.07.203.108.422.108.649v10a2 2 0 0 1-2 2H9.243l2.05-2.05zM21.707 2.293a1 1 0 0 1 0 1.414l-18 18a1 1 0 0 1-1.414-1.414l18-18a1 1 0 0 1 1.414 0z"
      />
    </svg>
  );
};

CameraInactiveIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Grey_6,
};
