import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const HexagonLadleInactiveIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <path
              d="M29.069 24.732c.534-.31.971-1.075.971-1.697V8.965c0-.622-.437-1.386-.971-1.697L16.99.233c-.534-.31-1.408-.31-1.942 0L2.97 7.268C2.437 7.58 2 8.343 2 8.966v14.069c0 .622.437 1.386.971 1.697l12.078 7.035c.534.31 1.408.31 1.942 0l12.078-7.035zM23.748 11.08l-.375 2.996c.367.148.627.505.627.925v2c0 .552-.448 1-1 1-.041 0-.075-.019-.114-.024l-.644 5.147c-.062.501-.488.876-.992.876H10.828l2-2h7.539l1.077-8.616 2.304-2.304zm-.041-2.787c.391.391.391 1.023 0 1.414l-14 14C9.512 23.902 9.256 24 9 24c-.256 0-.512-.098-.707-.293-.391-.391-.391-1.023 0-1.414l14-14c.391-.391 1.023-.391 1.414 0zm-4.24 7.068l-.52 5.306H14.16l5.306-5.306zM8.876 8.009c.545-.074 1.048.32 1.116.868l1.145 9.158-1.791 1.791-.232-1.848C9.075 17.982 9.041 18 9 18c-.552 0-1-.447-1-1v-1.999c0-.42.26-.776.627-.925l-.619-4.951c-.069-.548.32-1.048.868-1.116zm4.058.992c.876 0 1.645.154 2.535.332 1.046.21 2.229.446 3.687.446.08 0 .162-.005.243-.006l-6.676 6.676-.741-7.412c.294-.023.613-.036.952-.036z"
              transform="translate(-277 -1115) translate(277 1115)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

HexagonLadleInactiveIcon.defaultProps = {
  size: '32',
  fill: settings.colors.Primary.Grey_6,
};
