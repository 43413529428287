import { RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDto } from '@rhim/rest/measurementService';
import { ensure, isDefined, specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api/measurementService';

type Payload = RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDto;

export function useCampaignOverview(
  vesselId: string | null,
  campaign: number | undefined,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) {
  return useQuery(getKey(vesselId, campaign), (context) => queryFn(context, options?.axiosConfiguration), {
    ...options?.configuration,
    enabled: isDefined(vesselId) && isDefined(campaign),
  });
}

const getKey = (vesselId: string | null, campaign: number | undefined) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'measurementService',
      scope: 'campaign-overview',
      entity: 'detail',
      vesselId,
      campaign,
    },
  ]);

const queryFn = ({ queryKey: [{ vesselId, campaign }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  return API.campaignsApi
    .getCampaignsVesselidCampaignOverview(ensure(vesselId), ensure(campaign), { signal, ...axiosConfiguration })
    .then((response) => response.data);
};

useCampaignOverview.getKey = getKey;
useCampaignOverview.queryFn = queryFn;
