import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const PenIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <g>
              <path
                d="M13.293 5.293L7 11.586 4.414 9l6.293-6.293c.713-.713 1.873-.713 2.586 0 .713.713.713 1.873 0 2.586zm-9.855 5.559l1.71 1.711-2.567.856.857-2.567zm11.269-9.559c-1.492-1.492-3.922-1.492-5.414 0l-7 7c-.109.109-.192.243-.241.391l-2 6c-.12.359-.027.755.241 1.023.19.19.446.293.707.293.105 0 .213-.017.316-.052l6-2c.148-.049.282-.132.391-.241l7-7c1.493-1.492 1.493-3.922 0-5.414z"
                transform="translate(-369.000000, -124.000000) translate(353.000000, 112.000000) translate(16.000000, 12.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

PenIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
