import { addDays as addDaysOriginal, differenceInMinutes, maxTime, subDays as subDaysOriginal } from 'date-fns';
import format from 'date-fns/format';
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';
import { DateTime, DateTimeFormatOptions } from 'luxon';

import { assert, ensure } from './assert';
import { isDefined } from './is-defined';

export enum TimeUnit {
  second = 'second',
  minute = 'minute',
  hour = 'hour',
  day = 'day',
  month = 'month',
  year = 'year',
}

export enum DATE_FORMAT {
  SHORT_LOCALIZED = 'P',
  SHORT_LOCALIZED_WITH_TIME = 'P, p',
}

export enum Format {
  LocalTime = 'yyyy-MM-dd HH:mm:ss',
  ISO = "yyyy-MM-dd'T'HH:mm:ss'Z'",
}

export const LOCAL_DATE_TIME_PATTERN = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;

export function isLocalTime(candidate: string): candidate is Date.LocalTime {
  return LOCAL_DATE_TIME_PATTERN.test(candidate);
}

/**
 * Maximum date allowed by JavaScript Date object. @see https://stackoverflow.com/questions/11526504/minimum-and-maximum-date
 */
export const MAXIMUM_DATE: Date.ISO_8601 = DateTime.fromMillis(maxTime).toISO({ suppressMilliseconds: true }) as Date.ISO_8601;

export const dateTimeOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour12: false,
  hour: 'numeric',
  minute: 'numeric',
};

/**
 * Parses a utc date or datetime string (typically returned from the server) and returns a js Date converted to local time.
 * The string may be just date (e.g "2021-10-08"), or
 * a datetime (e.g "2021-10-08T07:24:23Z"), or
 * a ISO 8601 datetime with a time offset from UTC (e.g "2021-10-08T07:24:23+00:00").
 * In case it is a datetime string and that string does not end with 'Z', it appends 'Z' at the end automatically.
 * 'Z' is the zone designator for the zero UTC offset and is required by ISO 8601, otherwise the datetime will assumed to be in local time.
 * @param utcDatetimeString A date or datetime string in ISO 8601 format
 * @returns the corresponding js Date
 */
export function parseUTC(utcDatetimeString: string): Date {
  // if the string has a time component to it and does not end with 'Z', then automatically append 'Z' to it at the end
  const hasTimePart = utcDatetimeString.includes('T');
  const hasZoneDesignator = utcDatetimeString.endsWith('Z');

  // "2021-10-08T07:24:23" => "07:24:23"
  const timePart: string | undefined = utcDatetimeString.split('T').pop();
  const hasOffset = isDefined(timePart) && (timePart.includes('+') || timePart.includes('-'));

  if (hasTimePart && !hasZoneDesignator && !hasOffset) {
    utcDatetimeString = `${utcDatetimeString}Z`; // interpret date as UTC to prevent local timezone offsetting
  }

  const date = new Date(utcDatetimeString);
  assert(isFinite(date.getTime()), `Invalid date string: ${utcDatetimeString}`);
  return date;
}

export function convertDateToISOString(dateString: Date.DotSeparated): string {
  const date = dateString.split('.');
  const reversedDate = date.slice(0).reverse().join('-');

  return new Date(reversedDate).toISOString();
}

export function removeTimeFromUTCDatetime(utcDatetimeString: string): string {
  const dateWithoutTimestamp = utcDatetimeString.substring(0, utcDatetimeString.lastIndexOf('T'));
  assert(dateWithoutTimestamp !== '', `shared utils, removeTimeFromUTCDatetime : could not split input ${utcDatetimeString}`);
  return dateWithoutTimestamp;
}

/**
 * Takes a javascript Date and returns that Date as string formatted in ISO 8601 format without a time component.
 * The string will have 4 digits for the year, followed by 2 digits for month and 2 digits for day, dash separated.
 * E.g js Date : Fri Oct 29 2021 14:30:40 GMT+0200 will become "2021-10-29"
 * @param date a javascript Date
 * @returns The provided Date as ISO 8601 formatted string without time
 */
export function formatDateToISO8601StringWithoutTime(date: Date): string {
  return format(date, 'yyyy-MM-dd');
}

/**
 * Converts standard time (UTC) to local time in the designated time zone.
 *
 * @param timestamp Date time string
 * @param timeZoneName IANA timezone name
 *
 * @example
 *
 * ```ts
 * toLocalTime("2021-10-08T07:24:23Z", "Europe/Warsaw"); // => "2021-10-08 09:24:23"
 * ```
 */
export function toLocalTime(timestamp: Date.UTC | Date.ISO_8601, timeZoneName: Date.TimeZoneName): Date.LocalTime {
  return formatInTimeZone(new Date(timestamp), timeZoneName, Format.LocalTime) as Date.LocalTime;
}

/**
 * Represents local time in the designated time zone to ISO 8601 format with offset.
 *
 * @param localTime Date time string
 * @param timeZoneName IANA timezone name
 *
 * @example
 *
 * ```ts
 * toISOTime('2022-04-07 12:14:35', 'Europe/Warsaw'); // => "2022-04-07T12:14:35+01:00" (+01:00 instead of +02:00 because of Daylight Saving Tine)
 * ```
 */
export function toISOTime(localTime: Date.LocalTime, timeZoneName: Date.TimeZoneName): Date.ISO_8601 {
  const { year, month, day, hour, minute, second } = parseLocalTime(localTime);

  const dt = DateTime.local(parseInt(year), parseInt(month), parseInt(day), parseInt(hour), parseInt(minute), parseInt(second), { zone: timeZoneName });

  const isoDate = dt.toISO({ suppressMilliseconds: true, includeOffset: true }) as Date.ISO_8601;

  if (isoDate.endsWith('Z')) {
    /**
     * If timezone is UTC, `dt.toISO` uses the `Z` zone designator. However, our backend
     * prefers to use one format consistently, so we need to replace the `Z` with `+00:00`.
     *
     * @example `1970-01-01T01:00:00Z` becomes `1970-01-01T01:00:00+00:00`.
     */
    return (isoDate.slice(0, -1) + '+00:00') as Date.ISO_8601;
  } else {
    return isoDate;
  }
}

/**
 * Converts local time in the designated time zone to standard time (UTC).
 *
 * @param localTime Date time string
 * @param timeZoneName IANA timezone name
 *
 * @example
 *
 * ```ts
 * toStandardTime('2022-04-07 12:14:35', 'Europe/Warsaw'); // => "2022-04-07T11:14:35+01:00" (+01:00 instead of +02:00 because of Daylight Saving Tine)
 * ```
 */
export function toUTCTime(localTime: Date.LocalTime, timeZoneName: Date.TimeZoneName): Date.UTC {
  return zonedTimeToUtc(localTime, timeZoneName).toISOString();
}

interface LocalTimeComponents {
  year: string;
  month: string;
  day: string;
  hour: string;
  minute: string;
  second: string;
}

export function parseLocalTime(localTime: Date.LocalTime): LocalTimeComponents {
  const pattern = /^(\d{4})-(\d{2})-(\d{2})\s(\d{1,2}):(\d{2}):(\d{2})$/;
  const [, year, month, day, hour, minute, second] = ensure(pattern.exec(localTime), `The provided date time (${localTime}) is not valid local time`);

  return {
    year,
    month,
    day,
    hour,
    minute,
    second,
  } as LocalTimeComponents;
}

export function createLocalTime(components: LocalTimeComponents): Date.LocalTime {
  return `${components.year}-${components.month}-${components.day} ${components.hour}:${components.minute}:${components.second}` as Date.LocalTime;
}

export function isISODate(date: Date.ISO_8601 | Date.LocalTime): date is Date.ISO_8601 {
  return !isLocalTime(date);
}

export function addDays(localTime: Date.LocalTime, timeZone: string, days: number): Date.LocalTime {
  const originalISOTime: Date.ISO_8601 = toISOTime(localTime, timeZone);
  const originalDate: Date = new Date(originalISOTime);

  const newDate: Date = addDaysOriginal(originalDate, days);

  return toLocalTime(newDate.toISOString(), timeZone);
}

export function addOneDay(localTime: Date.LocalTime, timeZone: string): Date.LocalTime {
  return addDays(localTime, timeZone, 1);
}

export function subDays(localTime: Date.LocalTime, timeZone: string, days: number): Date.LocalTime {
  const originalISOTime: Date.ISO_8601 = toISOTime(localTime, timeZone);
  const originalDate: Date = new Date(originalISOTime);

  const newDate: Date = subDaysOriginal(originalDate, days);

  return toLocalTime(newDate.toISOString(), timeZone);
}

export function startOfDay(localTime: Date.LocalTime, timeZone: string): Date.LocalTime {
  const newDateISO: Date.ISO_8601 = DateTime.fromSQL(localTime, { zone: timeZone }).startOf('day').toISO({ suppressMilliseconds: true }) as Date.ISO_8601;

  return toLocalTime(newDateISO, timeZone);
}

// Converts a value in minutes to either minutes or hours or days
export function convertDuration(minutes: number): number {
  if (minutes < 60) {
    // less than 1 hour -> return the value as is (in minutes)
    return minutes;
  } else if (minutes < 24 * 60) {
    // less than 1 day -> return the value in hours
    return Math.round(minutes / 60);
  } else {
    // more than 1 day -> return the value in days
    return Math.round(minutes / 1440);
  }
}

export function differenceFromNowInMinutes(timestamp: Date.ISO_8601): number {
  return differenceInMinutes(new Date(), parseUTC(timestamp));
}

export function getToday() {
  const d = new Date();
  d.setUTCHours(0, 0, 0, 0);

  return d;
}

export function utcMidnightIso(date: string) {
  const d = new Date(date);
  d.setUTCHours(0, 0, 0, 0);

  return d.toISOString();
}

/**
 * Converts a defined date string to ISO string or returns the ISO string for the current date as a fallback
 *
 * @param date Date compatible string
 * @returns passed or current date as ISO string
 *
 */
export function definedOrNow(date: string | null | undefined): Date.ISO_8601 {
  return isDefined(date) ? new Date(date).toISOString() : new Date().toISOString();
}

/**
 * Extracts the time of a date and returns it as a string in 24h format
 *
 * @param date Javascript Date or Date compatible string
 * @returns time as 24h formatted string
 *
 */
export function toTime24(date: Date | Date.ISO_8601 | Date.UTC, timeZone?: Date.TimeZoneName): Date.Time24 {
  const d = typeof date === 'object' ? date : new Date(date);
  // en-GB ensures 00:00 instead of 24:00 for midnight
  const options = { hour12: false, hour: '2-digit', minute: '2-digit', timeZone };
  return d.toLocaleTimeString('en-Gb', options as DateTimeFormatOptions) as Date.Time24;
}
