import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const CameraIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.662 2.4H14.4A1.6 1.6 0 0 1 16 4v8a1.6 1.6 0 0 1-1.6 1.6H1.6A1.6 1.6 0 0 1 0 12V4a1.6 1.6 0 0 1 1.6-1.6h.8v-.8H4v.8h.338L5.37.733C5.651.279 6.17 0 6.731 0H9.27c.561 0 1.08.28 1.36.733L11.663 2.4zM10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm2 0a4 4 0 1 1-8 0 4 4 0 0 1 8 0z"
      />
    </svg>
  );
};

CameraIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
