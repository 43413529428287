import { settings } from '@rhim/design';
import { assert, isDefined } from '@rhim/utils';
import { RectClipPath } from '@visx/clip-path';
import React from 'react';
import styled from 'styled-components';

import { DataPoint } from '../glyph/DataPoint';
import Line from '../shape/Line';
import { ShapeType, ThemeConfig } from '../theme';
import { DATA_POINT_RADIUS, DATA_POINT_STROKE_WIDTH, TOOLTIP_BORDER_RADIUS, TOOLTIP_CHART_OFFSET } from './constants';
import { TooltipState } from './types';

const DATA_POINT_CLIP_WIDTH = DATA_POINT_RADIUS * 2 + DATA_POINT_STROKE_WIDTH * 2;

interface Props<Datum> {
  chartHeight: number;
  cy: (datum: Datum, key: string, index: number) => number | undefined;
  belowThreshold?: (datum: Datum, key: string) => boolean;
  height?: number;
  keys: string[];
  tooltipState: TooltipState<Datum>;
  themeConfig: ThemeConfig;
}

function HoverLineComponent<Datum>(props: Props<Datum>) {
  const { chartHeight, height, keys, themeConfig, tooltipState, belowThreshold, cy } = props;
  const { isHovered, nearestDatum } = tooltipState;

  if (!isHovered || !isDefined(nearestDatum)) {
    return null;
  }

  const x = nearestDatum.x;
  const y = height ?? chartHeight + TOOLTIP_CHART_OFFSET + TOOLTIP_BORDER_RADIUS;

  const dataPointsClipPath = `data-point-clip-zone-${Math.random()}`;
  const dataPointsClipPathUrl = `url(#${dataPointsClipPath})`;

  return (
    <>
      <HoverLine from={{ x, y: 0 }} to={{ x, y }} />;
      <RectClipPath id={dataPointsClipPath} x={x - DATA_POINT_CLIP_WIDTH / 2} y={0} width={DATA_POINT_CLIP_WIDTH} height={chartHeight} />
      <g clipPath={dataPointsClipPathUrl}>
        {keys.map((key) => {
          const _cy = isDefined(nearestDatum) && isDefined(nearestDatum.datum) ? cy(nearestDatum.datum, key, nearestDatum.index) : 0;
          const config = themeConfig.datasets[key];
          assert(isDefined(config), `theme config not found for key${key}`);

          const isBelowThreshold =
            isDefined(belowThreshold) && isDefined(nearestDatum) && isDefined(nearestDatum.datum) && belowThreshold(nearestDatum.datum, key);
          const strokeColor = 'strokeColor' in config ? config.strokeColor : undefined;
          const strokeColorOrFill = 'strokeColor' in config ? config.strokeColor : 'fill' in config ? config.fill : undefined;
          const fill = isBelowThreshold ? ('strokeColorAlert' in config ? config.strokeColorAlert : strokeColor) : strokeColorOrFill;

          return (
            isDefined(_cy) &&
            config.type !== ShapeType.Glyph && (
              <DataPoint
                key={key}
                r={DATA_POINT_RADIUS}
                strokeWidth={DATA_POINT_STROKE_WIDTH}
                stroke={settings.colors.Monochromatic.White}
                fill={fill}
                cx={x}
                cy={_cy}
              />
            )
          );
        })}
      </g>
    </>
  );
}

export default React.memo(HoverLineComponent) as typeof HoverLineComponent;

const HoverLine = styled(Line)`
  stroke: ${settings.colors.Primary.Grey_8};
  stroke-width: 2px;
  pointer-events: none;
`;
