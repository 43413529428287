/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable i18next/no-literal-string */
/* eslint-disable @nx/enforce-module-boundaries */

import { AxisLeft } from '@rhim/chart/v2/axis';
import { settings } from '@rhim/design';
import { isDefined } from '@rhim/utils';
import { Axis } from '@visx/axis';
import Orientation from '@visx/axis/lib/constants/orientation';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { Line } from '@vx/shape';
import { defaultStyles } from '@vx/tooltip';
import { Tooltip } from '@vx/vx';
import { max } from 'd3-array';
import React from 'react';
import styled from 'styled-components';

import ArcRegion from './ArcRegion';
import { PreviewRegion } from './utils';

const defaultTooltipStyle: React.CSSProperties = {
  ...defaultStyles,
  padding: '1px 4px',
  height: '16px',
  borderRadius: '3px',
  textAlign: 'center',
  fontFamily: `${settings.typography.FontFamily.Regular}`,
  fontSize: `${settings.typography.FontSize.X_Small}`,
  color: settings.colors.Primary.Grey_6,
  lineHeight: '1.17',
  transform: 'translateX(-50%)',
  boxShadow: 'none',
};

interface Props {
  width: number;
  height: number;
  rectArea?: PreviewRegion;
  circularArea?: PreviewRegion;
  circularAreas: NonEmptyArray<PreviewRegion>;
  rectAreas: NonEmptyArray<PreviewRegion>;
  yMax?: number;
  outerDiameter?: number;
  showAxis?: boolean;
}

const tickLabelProps = () =>
  ({
    fill: tickLabelColor,
    color: tickLabelColor,
    fontSize: settings.typography.FontSize.X_Small,
    fontFamily: settings.typography.FontFamily.Regular,
    textAnchor: 'middle',
  } as const);

const tickLabelColor = settings.colors.Primary.Grey_6;
export const labelColor = settings.colors.Primary.Grey_6;
const COL_LEFT_WIDTH = 50;
const PADDING_TOP = 50;
const MARGIN_TOP = 20;
const PADDING_BOTTOM = 10;
const MARGIN_LEFT = 35;
const ARC_PADDING = 1;

const CircularMap: React.FunctionComponent<Props> = ({
  width,
  showAxis = true,
  height,
  rectArea,
  circularArea,
  rectAreas,
  circularAreas,
  outerDiameter,
}: Props) => {
  const maxRadius = React.useMemo(() => {
    const vesselRadius = (outerDiameter ?? 0) / 2;
    let radius = isDefined(circularArea) ? Math.abs(circularArea[1].x) : 0;
    const getMaxDimension = (area: PreviewRegion) => Math.max(Math.abs(area[1].x), Math.abs(area[0].x), Math.abs(area[1].y), Math.abs(area[0].y));
    let maxWidth = isDefined(rectArea) ? getMaxDimension(rectArea) : 0;

    if (vesselRadius) {
      return vesselRadius;
    }
    if (isDefined(circularAreas)) {
      radius = max([...circularAreas.map((item) => Math.abs(item[1].x)), radius]) ?? 0;
    }

    if (isDefined(rectAreas)) {
      maxWidth = Math.max(...rectAreas.map(getMaxDimension), maxWidth) ?? 0;
    }

    return max([maxWidth, radius]) ?? 0;
  }, [circularAreas, circularArea, rectAreas, rectArea, outerDiameter]);

  /**
   * Scale for the right group.
   */
  const scale = scaleLinear({
    domain: [0, maxRadius],
    range: [0, width / 2],
  });

  /**
   * Scale for the left group.
   */
  const leftscale = scaleLinear({
    domain: [maxRadius, 0],
    range: [0, width / 2],
  });

  return (
    <GraphWrapper top={showAxis ? (settings.Spacing.Spacing_500 as string) : 0} left={showAxis ? MARGIN_LEFT : 0}>
      <Row>
        {showAxis && (
          <Left width={COL_LEFT_WIDTH}>
            <SvgOverflow width={COL_LEFT_WIDTH} height={height + PADDING_TOP + PADDING_BOTTOM}>
              <AxisLeft top={height / 2} left={0} scale={scale} numTicks={4} hideZero={true} tickFormat={(d) => `-${d}m`} />
              <AxisLeft top={0} left={0} scale={leftscale} numTicks={4} tickFormat={(d) => `${d}m`} />
            </SvgOverflow>
          </Left>
        )}
        <svg width={width + COL_LEFT_WIDTH} height={height + 2 * ARC_PADDING}>
          {!showAxis && (
            <rect width={width} height={height} x={0} y={0} fill={settings.colors.Primary.Grey_1} strokeWidth={1} stroke={settings.colors.Primary.Grey_3} />
          )}
          <Group top={height / 2 + ARC_PADDING} left={width / 2 + ARC_PADDING}>
            {isDefined(outerDiameter) && (
              <g key="arc-base">
                <ArcRegion innerRadius={0} outerRadius={scale(outerDiameter / 2)} endAngle={360} startAngle={0} stroke={settings.colors.Primary.Grey_3} />
              </g>
            )}
            {circularAreas.map((el, index) => (
              <g key={`arc-${index}`}>
                <ArcRegion
                  innerRadius={scale(el[0].x ?? 0)}
                  outerRadius={scale(el[1].x ?? 0)}
                  endAngle={el[1].y ?? 0}
                  startAngle={el[0].y ?? 0}
                  stroke={settings.colors.Primary.Grey_3}
                />
              </g>
            ))}
            {rectAreas.map((area, index) => {
              return (
                <g key={`rectangular-current-${index}`}>
                  <rect
                    width={scale(area[1].x - area[0].x)}
                    height={scale(area[1].y - area[0].y)}
                    x={scale(area[0].x ?? 0)}
                    y={scale(-area[1].y ?? 0)}
                    fillOpacity={0.7}
                    fill={settings.colors.Primary.Grey_1}
                    strokeWidth={2}
                    stroke={settings.colors.Primary.Grey_3}
                  />
                </g>
              );
            })}
            {isDefined(circularArea) && (
              <g key="arc-current">
                <ArcRegion
                  innerRadius={scale(circularArea[0].x ?? 0)}
                  outerRadius={scale(circularArea[1].x ?? 0)}
                  endAngle={circularArea[1].y ?? 0}
                  startAngle={circularArea[0].y ?? 0}
                  stroke={settings.colors.Primary.Blue_9}
                />
              </g>
            )}
            {isDefined(rectArea) && (
              <g key="rectangular-current">
                <rect
                  width={scale(rectArea[1].x - rectArea[0].x)}
                  height={scale(rectArea[1].y - rectArea[0].y)}
                  x={scale(rectArea[0].x ?? 0)}
                  y={scale(-rectArea[1].y ?? 0)}
                  fill={settings.colors.Primary.Grey_1}
                  fillOpacity={0.7}
                  strokeWidth={2}
                  stroke={settings.colors.Primary.Blue_9}
                />
              </g>
            )}
          </Group>
          {showAxis && (
            <>
              {' '}
              <Line
                from={{ x: width / 2 + ARC_PADDING, y: 0 }}
                to={{ x: width / 2 + ARC_PADDING, y: height }}
                stroke={settings.colors.Primary.Grey_3}
                strokeDasharray={2}
                strokeWidth={0.5}
                pointerEvents="none"
              />
              <Line
                from={{ x: 0, y: height }}
                to={{ x: width, y: 0 }}
                stroke={settings.colors.Primary.Grey_3}
                strokeWidth={0.5}
                strokeDasharray={2}
                pointerEvents="none"
              />
              <Line
                from={{ x: 0, y: height / 2 + ARC_PADDING }}
                to={{ x: width, y: height / 2 + ARC_PADDING }}
                stroke={settings.colors.Primary.Grey_3}
                strokeWidth={0.5}
                strokeDasharray={2}
                pointerEvents="none"
              />
              <Line
                from={{ x: 0, y: 0 }}
                to={{ x: width, y: height }}
                stroke={settings.colors.Primary.Grey_3}
                strokeWidth={0.5}
                strokeDasharray={2}
                pointerEvents="none"
              />
            </>
          )}
        </svg>
      </Row>
      {showAxis && (
        <Bottom height={COL_LEFT_WIDTH} top={height}>
          <SvgOverflow width={COL_LEFT_WIDTH} height={height}>
            <>
              {' '}
              <Axis
                orientation={Orientation.bottom}
                left={0}
                scale={leftscale}
                stroke={settings.colors.Primary.Grey_4}
                tickLength={4}
                tickLabelProps={tickLabelProps}
                numTicks={4}
                hideZero={true}
                tickFormat={(d) => `-${d}m`}
              />
              <Axis
                orientation={Orientation.bottom}
                left={width / 2}
                scale={scale}
                stroke={settings.colors.Primary.Grey_4}
                tickLength={4}
                tickStroke={settings.colors.Primary.Grey_6}
                tickLabelProps={tickLabelProps}
                numTicks={4}
                tickFormat={(d) => `${d}m`}
              />
            </>
          </SvgOverflow>
        </Bottom>
      )}
      {showAxis && (
        <>
          <Tooltip
            top={height / 2 - MARGIN_TOP}
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            left={0}
            style={{
              ...defaultTooltipStyle,
              paddingLeft: 0,
              backgroundColor: 'transparent',
              boxShadow: 'none',
              transform: 'none',
            }}
          >
            270°
          </Tooltip>
          <Tooltip
            top={height / 2 - MARGIN_TOP}
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            left={width + COL_LEFT_WIDTH}
            style={{
              ...defaultTooltipStyle,
              paddingLeft: 0,
              backgroundColor: 'transparent',
              boxShadow: 'none',
              transform: 'none',
            }}
          >
            90°
          </Tooltip>
          <Tooltip
            top={-MARGIN_TOP - PADDING_BOTTOM}
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            left={width / 2 + MARGIN_LEFT}
            style={{
              ...defaultTooltipStyle,
              paddingLeft: 0,
              backgroundColor: 'transparent',
              boxShadow: 'none',
              transform: 'none',
            }}
          >
            0°
          </Tooltip>
          <Tooltip
            top={height}
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            left={width / 2 + MARGIN_LEFT}
            style={{
              ...defaultTooltipStyle,
              paddingLeft: 0,
              backgroundColor: 'transparent',
              boxShadow: 'none',
              transform: 'none',
            }}
          >
            180°
          </Tooltip>
        </>
      )}
    </GraphWrapper>
  );
};

export const Left = styled.div<{ width: number }>`
  width: ${(props) => props.width}px;
  display: flex;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Bottom = styled.div<{ height: number; top: number }>`
  height: ${(props) => props.height}px;
  display: flex;
  padding-left: ${COL_LEFT_WIDTH}px;
`;

export const SvgOverflow = styled.svg`
  overflow: visible;
`;

export const GraphWrapper = styled.div<{ top: string | number; left: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.top};
  margin-left: ${(props) => props.left}px;
`;

export default CircularMap;
