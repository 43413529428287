import { NavigateOptions, useSearchParams } from 'react-router-dom';

export const useTabParam = ({ queryKey, defaultKey, navigateOpts }: { queryKey: string; defaultKey?: string; navigateOpts?: NavigateOptions }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const activeTabKey = searchParams.get(queryKey) ?? defaultKey;

  const handleTabClick = (tabKey: string) => {
    setSearchParams((current) => {
      current.set(queryKey, tabKey);
      return current;
    }, navigateOpts);
  };

  return [activeTabKey, handleTabClick] as const;
};
