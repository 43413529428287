import { isDefined } from '@rhim/utils';
import React from 'react';
import styled from 'styled-components';

import { TOOLTIP_CHART_OFFSET } from './constants';
import TooltipStandalone, { TooltipStandaloneProps } from './TooltipStandalone';
import { NearestDatum, NearestDatumRadial, TooltipHandlers, TooltipHandlersRadial, TooltipState, TooltipStateRadial } from './types';

interface TooltipProps<Datum> extends Omit<TooltipStandaloneProps<Datum>, 'datum'> {
  bisectX: (x: number, y: number) => NearestDatum<Datum> | NearestDatumRadial<Datum>;
  chartWidth: number;
  chartHeight: number;
  handlers: TooltipHandlers<Datum> | TooltipHandlersRadial<Datum>;
  state: TooltipState<Datum> | TooltipStateRadial<Datum>;
  y?: number;
}

function TooltipComponent<Datum>(props: TooltipProps<Datum>, ref: React.ForwardedRef<HTMLDivElement>) {
  const {
    belowThreshold,
    chartWidth,
    chartHeight,
    keys,
    handlers,
    label,
    labelFormat,
    HeaderIcon,
    state,
    themeConfig,
    subTitle,
    title,
    value,
    valueFormat,
    width,
    bisectX,
    y,
  } = props;
  const { position } = state;

  handlers.handleBisectXRef.current = bisectX;
  const pointerX = position?.pointerX ?? 0;
  const pointerY = position?.pointerY ?? 0;
  const nearestDatum = state.nearestDatum;

  if (!isDefined(nearestDatum) || !isDefined(nearestDatum.datum)) {
    return null;
  }

  const tooltipX = position?.tooltipX(pointerX);
  const tooltipY = isDefined(position?.tooltipY) ? position.tooltipY(pointerY) : null;
  const offsetTop = y ?? chartHeight + TOOLTIP_CHART_OFFSET;
  const top = tooltipY ?? offsetTop;

  return (
    <Svg width={chartWidth} height={chartHeight} style={{ visibility: isDefined(position?.tooltipWidth) ? 'visible' : 'hidden' }}>
      <ForeignObject width={chartWidth} height={chartHeight}>
        <TooltipStandalone
          ref={ref}
          style={{ left: tooltipX, top, width }}
          datum={nearestDatum.datum}
          belowThreshold={belowThreshold}
          keys={keys}
          label={label}
          labelFormat={labelFormat}
          HeaderIcon={HeaderIcon}
          themeConfig={themeConfig}
          subTitle={subTitle}
          title={title}
          value={value}
          valueFormat={valueFormat}
        />
      </ForeignObject>
    </Svg>
  );
}

TooltipComponent.displayName = 'TooltipComponent';

export default React.forwardRef(TooltipComponent) as <T>(p: TooltipProps<T> & { ref: React.Ref<HTMLElement | SVGAElement> }) => JSX.Element | null;

const Svg = styled.svg`
  position: absolute;
  z-index: 1;
  top: 0;
  overflow: visible;
  pointer-events: none;
`;

const ForeignObject = styled.foreignObject`
  pointer-events: none;
  overflow: visible;
`;
