import { RHIMCementServiceV1ModelsCampaignDto } from '@rhim/rest/cement';
import { assert, isDefined, specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api';

type Payload = RHIMCementServiceV1ModelsCampaignDto[];

export function useCrkCampaigns(
  vesselId: string | null,
  configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>
) {
  return useQuery(getKey(vesselId), (context) => queryFn(context), configuration);
}

const getKey = (vesselId: string | null) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'cement',
      scope: 'campaigns',
      entity: 'list',
      vesselId,
    },
  ]);

const queryFn = ({ queryKey: [{ vesselId }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  assert(
    isDefined(vesselId),
    `${useCrkCampaigns.name} requires a vesselId, but value was ${vesselId}. Use a valid id, or use \`options.enabled\` to pause the query until all requirements are met. See https://react-query.tanstack.com/guides/disabling-queries for instructions.`
  );

  return API.campaign.getVesselsVesselidCampaigns(vesselId, { signal, ...axiosConfiguration }).then((response) => response.data);
};

useCrkCampaigns.getKey = getKey;
useCrkCampaigns.queryFn = queryFn;
