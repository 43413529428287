import { ArcRotateCamera, Color3, Engine, Matrix, Mesh, MeshBuilder, Quaternion, Scalar, Scene, StandardMaterial, Vector3 } from '@babylonjs/core';

import { determinant, multiplyMatrices, transposeMatrixNxM } from '../mathUtils';
import { svdJacobi } from '../svd';

export type Triangle = [Vector3, Vector3, Vector3];
export type Segment = [Vector3, Vector3];

/* eslint-disable @typescript-eslint/no-non-null-assertion */

/**
 * Shuffles the array by sequentially go to N-th element and swap it with random element in the whole array
 * It is useful when we need to shuffle only some minor part of the array
 * The shuffling is done in place so the original array will be modified
 *
 * @param array
 * @param n - Number of elements to shuffle. Should be less than or equal to the array length.
 */
export function shuffleNValuesOfArray<T = number>(array: T[], n: number) {
  const numberOfElements = Math.min(n, array.length);
  for (let i = 0; i < numberOfElements; i++) {
    const shuffledIndex = Math.floor(Math.random() * array.length);
    const tmp = array[i]!;
    array[i] = array[shuffledIndex]!;
    array[shuffledIndex] = tmp;
  }
}

export function computeTransformationBySegments(source: Segment, target: Segment): Matrix {
  const a = source[1].subtract(source[0]);
  const aCenter = source[1].add(source[0]).scale(0.5);
  const b = target[1].subtract(target[0]);
  const bCenter = target[1].add(target[0]).scale(0.5);
  const { x, y, z } = aCenter.negate();
  const translationToOrigin = Matrix.Translation(x, y, z);

  const aInXY = new Vector3(a.x, a.y, 0).normalize();
  const bInXY = new Vector3(b.x, b.y, 0).normalize();

  const rotation = computeRotationMatrix(aInXY, bInXY);
  const translation = Matrix.Translation(bCenter.x, bCenter.y, bCenter.z);

  return translationToOrigin.multiply(rotation).multiply(translation);
}

/**
 * Computes a rotation matrix by 2 vectors source and target, which will
 * align source to target.
 * The method is agnostic to winding direction. After calculating the angle
 * between the vectors it'll check if the positive or negative vector should be
 * applied.
 *
 * @param source
 * @param target
 */
export function computeRotationMatrix(source: Vector3, target: Vector3): Matrix {
  const normSource = source.normalize();
  const normTarget = target.normalize();
  const dot = Vector3.Dot(normSource, normTarget);
  const angle = Math.acos(dot);
  const normal = Vector3.Cross(normSource, normTarget);

  const rot1 = Matrix.RotationAxis(normal, angle);
  const rot2 = Matrix.RotationAxis(normal, -angle);

  const rotSource1 = Vector3.TransformCoordinates(normSource, rot1);
  const rotSource2 = Vector3.TransformCoordinates(normSource, rot2);

  const dot1 = Vector3.Dot(rotSource1, normTarget);
  const dot2 = Vector3.Dot(rotSource2, normTarget);

  return dot1 > dot2 ? rot1 : rot2;
}

/**
 * Compute transformation matrix between two point clouds using SVD
 *
 * @param source
 * @param target
 */
export function evaluateTransformation(source: Vector3[], target: Vector3[]): Matrix {
  const meanSource = source.reduce((acc, vec) => acc.add(vec), Vector3.Zero()).scale(1 / source.length);
  const meanTarget = target.reduce((acc, vec) => acc.add(vec), Vector3.Zero()).scale(1 / target.length);

  const movedSource = source.map((vec) => vec.subtract(meanSource).asArray());
  const movedTarget = target.map((vec) => vec.subtract(meanTarget).asArray());

  const H = multiplyMatrices(transposeMatrixNxM(movedSource), movedTarget);

  const epsilon = Math.pow(2, -52);
  let tol = 1e-64; // tolerance = tol / epsilon
  let { U, Vh } = svdJacobi(H, epsilon, tol);
  let rotation3X3 = computeRotationByUandVh(U, Vh);
  let det = determinant(rotation3X3);

  // in a rare case the initial tolerance is not enough to find eigen vectors and
  // SVD algorithm ends up with zero singular values which adds a scaling factor
  // into a transformation matrix. In this case we need to try decreasing tolerance
  // and eventually fall back to the triangle alignment
  let attemptsCounter = 0;
  let needsCorrection = Math.abs(Math.abs(det) - 1) > 1e-6;
  while (needsCorrection && attemptsCounter < 5) {
    attemptsCounter++;
    tol /= 1e6;

    // console.warn(`SVD did not converge. Trying with tol: ${tol} / ${epsilon}`);

    const jacobi = svdJacobi(H, epsilon, tol);
    U = jacobi.U;
    Vh = jacobi.Vh;
    rotation3X3 = computeRotationByUandVh(U, Vh);
    det = determinant(rotation3X3);
    needsCorrection = Math.abs(Math.abs(det) - 1) > 1e-6;
  }

  // in case the SVD did not converge, fall back to the triangle alignment
  if (needsCorrection) {
    // console.warn('SVD did not converge. Falling back to the triangle alignment');
    return computeTransformationByTriangles([source[0]!, source[1]!, source[2]!], [target[0]!, target[1]!, target[2]!]);
  }

  if (det < 0) {
    const n = Vh.length;
    Vh[n - 1] = Vh[n - 1]!.map((el) => -el);
    rotation3X3 = computeRotationByUandVh(U, Vh);
  }

  const initTranslation = [
    [1, 0, 0, -meanSource.x],
    [0, 1, 0, -meanSource.y],
    [0, 0, 1, -meanSource.z],
    [0, 0, 0, 1],
  ];
  const rotation4X4: Array<Array<number>> = [
    [...rotation3X3[0]!, 0],
    [...rotation3X3[1]!, 0],
    [...rotation3X3[2]!, 0],
    [0, 0, 0, 1],
  ];
  const translation = [
    [1, 0, 0, meanTarget.x],
    [0, 1, 0, meanTarget.y],
    [0, 0, 1, meanTarget.z],
    [0, 0, 0, 1],
  ];

  const transformation = multiplyMatrices(translation, multiplyMatrices(rotation4X4, initTranslation));

  // BabylonJS matrix is column based, that is why a transpose is needed
  return Matrix.FromArray(transformation.flat()).transpose();
}

function computeRotationByUandVh(U: Array<Array<number>>, Vh: Array<Array<number>>): Array<Array<number>> {
  const V = transposeMatrixNxM(Vh);
  const Ut = transposeMatrixNxM(U);
  return multiplyMatrices(V, Ut);
}

/**
 * Compute transformation matrix between two triangles
 * Assuming that the triangles are not transform and its world matrix is identity matrix
 *
 * @param source
 * @param target
 */
export function computeTransformationByTriangles(source: Triangle, target: Triangle): Matrix {
  const [tp0, tp1, tp2] = target;
  const [sp0, sp1, sp2] = source;

  const tCentroid = tp0
    .add(tp1)
    .add(tp2)
    .scale(1 / 3);
  const sCentroid = sp0
    .add(sp1)
    .add(sp2)
    .scale(1 / 3);

  const translateToOrigin = Matrix.FromArray([1, 0, 0, -sCentroid.x, 0, 1, 0, -sCentroid.y, 0, 0, 1, -sCentroid.z, 0, 0, 0, 1]).transpose();

  const tNorm = Vector3.Cross(tp1.subtract(tp0), tp2.subtract(tp0)).normalize();
  const sNorm = Vector3.Cross(sp1.subtract(sp0), sp2.subtract(sp0)).normalize();
  const biNorm = Vector3.Cross(sNorm, tNorm).normalize();
  const angle = getAngleBetweenVectors(tNorm, sNorm);

  const rotMatrix = rotationMatrixByAxis(biNorm, angle);

  const movedSource: Triangle = [
    Vector3.TransformCoordinates(sp0.subtract(sCentroid), rotMatrix),
    Vector3.TransformCoordinates(sp1.subtract(sCentroid), rotMatrix),
    Vector3.TransformCoordinates(sp2.subtract(sCentroid), rotMatrix),
  ];

  const movedTarget: Triangle = [tp0.subtract(tCentroid), tp1.subtract(tCentroid), tp2.subtract(tCentroid)];
  const optimalAngle = findOptimalRotation(movedSource, movedTarget, tNorm, 1);

  const coplanarRotationMatrix = rotationMatrixByAxis(tNorm, optimalAngle);

  const translationToTarget = Matrix.FromArray([1, 0, 0, tCentroid.x, 0, 1, 0, tCentroid.y, 0, 0, 1, tCentroid.z, 0, 0, 0, 1]).transpose();

  return translateToOrigin.multiply(rotMatrix).multiply(coplanarRotationMatrix).multiply(translationToTarget);
}

/**
 * Iteratively rotates the source triangle by stepAngle. Eventually makes a full circle.
 * On each iteration the distances between corresponding points are registered and
 * compared with the min distance.
 * As a result function returns rotation angle when the min distances were detected.
 *
 * @param source
 * @param target
 * @param rotationAxis
 * @param stepAngle
 *
 * @throws Error if rotation stepAngle is out of range [1-30]
 */
export function findOptimalRotation(source: Triangle, target: Triangle, rotationAxis: Vector3, stepAngle = 1) {
  if (stepAngle < 1 || stepAngle > 30) {
    throw Error('Rotation step should be more then 1 and less them 30 degrees.');
  }

  const [sA, sB, sC] = source.map((vec) => vec.normalize());
  const [tA, tB, tC] = target.map((vec) => vec.normalize());
  const iterAngle = (stepAngle * Math.PI) / 180;
  let localMin = Infinity;
  let optimalAngle = 0;
  for (let i = 0; i < 2 * Math.PI; i += iterAngle) {
    const coplanarRotationMatrix = rotationMatrixByAxis(rotationAxis, i);
    const tmpA = Vector3.TransformCoordinates(sA, coplanarRotationMatrix);
    const tmpB = Vector3.TransformCoordinates(sB, coplanarRotationMatrix);
    const tmpC = Vector3.TransformCoordinates(sC, coplanarRotationMatrix);
    const distA = Vector3.Distance(tmpA, tA);
    const distB = Vector3.Distance(tmpB, tB);
    const distC = Vector3.Distance(tmpC, tC);
    const sum = distA + distB + distC;
    if (sum < localMin) {
      localMin = sum;
      optimalAngle = i;
    }
  }

  return optimalAngle;
}

/**
 * Computes a convex angle between 2 vectors
 *
 * @param vectorS
 * @param vectorT
 */
export function getAngleBetweenVectors(vectorS: Vector3, vectorT: Vector3): number {
  const vs = vectorS.normalizeToNew();
  const vt = vectorT.normalizeToNew();
  const dot = Vector3.Dot(vs, vt);

  return Math.acos(dot);
}

/**
 *
 * @param axis Vector around which the model should rotate
 * @param angle
 */
export function rotationMatrixByAxis(axis: Vector3, angle: number): Matrix {
  const qW = Math.cos(angle / 2);
  const qV = axis.normalize().scale(Math.sin(angle / 2));
  const quaternion = new Quaternion(qV.x, qV.y, qV.z, qW);
  const rotationMatrix = new Matrix();
  quaternion.toRotationMatrix(rotationMatrix);
  return rotationMatrix;
}

interface ArrowOptions {
  length?: number;
  color?: Color3;
  direction?: Vector3;
  origin?: Vector3;
  bold?: boolean;
}

export function createArrow(name: string, options: ArrowOptions, scene: Scene): Mesh {
  const defaultOptions = {
    length: 1,
    color: Color3.Red(),
    direction: new Vector3(1, 0, 0),
    origin: Vector3.Zero(),
    bold: false,
  };
  const { length, color, direction, origin, bold } = { ...defaultOptions, ...options };
  const boldFactor = bold ? 2 : 1;
  const arrowHeadLength = (length / 15) * boldFactor;
  const lineDiameter = (length / 50) * boldFactor;
  const arrowHeadDiameter = (length / 15) * boldFactor;
  const lineLength = length - arrowHeadLength;
  const normalizedDirection = direction.normalize();

  const line = MeshBuilder.CreateCylinder(
    `${name}_arrow_line`,
    {
      height: lineLength,
      diameter: lineDiameter,
    },
    scene
  );
  line.position = new Vector3(0, lineLength / 2, 0);
  line.setPivotPoint(Vector3.Zero());
  const arrowHead = MeshBuilder.CreateCylinder(
    `${name}_arrow_head`,
    {
      diameterTop: 0,
      diameterBottom: arrowHeadDiameter,
      height: arrowHeadLength,
    },
    scene
  );
  arrowHead.position = new Vector3(0, lineLength + arrowHeadLength / 2, 0);
  arrowHead.setPivotPoint(Vector3.Zero());

  const arrow = Mesh.MergeMeshes([line, arrowHead], true, true, undefined, false, false)!;
  arrow.name = name;
  const arrowMat = new StandardMaterial(`${name}_material`, scene);
  arrowMat.diffuseColor = color;
  arrowMat.emissiveColor = color;
  arrow.material = arrowMat;

  const initAxis = new Vector3(0, 1, 0);
  const dot = Vector3.Dot(initAxis, normalizedDirection);

  if (Scalar.WithinEpsilon(dot, -1)) {
    arrow.rotate(new Vector3(1, 0, 0), Math.PI);
  } else if (!Scalar.WithinEpsilon(dot, 1)) {
    const norm = Vector3.Cross(initAxis, direction).normalize();
    const angle = Math.acos(Vector3.Dot(initAxis, normalizedDirection));
    arrow.rotate(norm, angle);
  }

  arrow.position = origin;

  return arrow;
}

/**
 * Apply the transformation matrix on positions buffer
 * Function will go through all points and sequentially reposition them
 *
 * @param positions
 * @param transformation
 */
export function applyTransformationOnPositionBuffer(positions: Float32Array, transformation: Matrix): void {
  const len = positions.length / 3;
  const tmpVertex = Vector3.Zero();
  for (let i = 0; i < len; i++) {
    const x = positions[3 * i]!;
    const y = positions[3 * i + 1]!;
    const z = positions[3 * i + 2]!;
    Vector3.TransformCoordinatesFromFloatsToRef(x, y, z, transformation, tmpVertex);
    positions[3 * i] = tmpVertex.x;
    positions[3 * i + 1] = tmpVertex.y;
    positions[3 * i + 2] = tmpVertex.z;
  }
}

/**
 *
 * @param camera ArcRotateCamera
 * @param engine Engine
 * cameraMinZ
 * viewportRatio Defeines width/height viewport ratio
 */
export function rescaleOrthoCamera(camera: ArcRotateCamera, engine: Engine, viewportRatio = 1) {
  const halfHeight = camera.radius / 2;
  // take into account the primary perspective camera's viewport which may have an arbitrary width/height ratio (other than 1:1)
  const { width: viewportWidth, height: viewportHeight } = camera.viewport;
  const { width, height } = engine.getRenderingCanvasClientRect() as DOMRect;
  const ratio = (width * viewportWidth * viewportRatio) / (height * viewportHeight);
  const halfWidth = (ratio * camera.radius) / 2;
  camera.orthoBottom = -halfHeight;
  camera.orthoTop = halfHeight;
  camera.orthoLeft = -halfWidth;
  camera.orthoRight = halfWidth;
}

/* eslint-enable @typescript-eslint/no-non-null-assertion */
