/* eslint-disable @nx/enforce-module-boundaries */

import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { ZoomInIcon, ZoomOutIcon, ZoomResetIcon } from '@rhim/icons/16';
import { ButtonCircular, ClusteredCircularButtons, TooltipPlacement } from '@rhim/react';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { ZOOM_INIT, ZOOM_MAX, ZOOM_MIN } from './constants';
import { ACTION_ID } from './constants';

interface Props {
  className?: string;
  onZoom: (zoomButtonId: string) => void;
  onReset: () => void;
  zoomLevel: number;
}

const ZoomButtons: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ onReset, onZoom, zoomLevel, ...rest }: Props) => {
  const { t } = i18nReact.useTranslation(['chart']);

  const buttonsZoomInOut = useMemo(
    () => [
      {
        icon: <ZoomInIcon />,
        buttonId: ACTION_ID.viewZoomIn,
        isDisabled: zoomLevel >= ZOOM_MAX,
        tooltip: t('chart:zoom.zoomIn'),
        tooltipPlacement: 'top' as TooltipPlacement,
      },
      {
        icon: <ZoomOutIcon />,
        buttonId: ACTION_ID.viewZoomOut,
        isDisabled: zoomLevel <= ZOOM_MIN,
        tooltip: t('chart:zoom.zoomOut'),
        tooltipPlacement: 'top' as TooltipPlacement,
      },
    ],
    [t, zoomLevel]
  );

  return (
    <ButtonsWrapper {...rest}>
      {zoomLevel && zoomLevel !== ZOOM_INIT ? (
        <StyledButtonCircular icon={<ZoomResetIcon />} onPress={onReset} tooltip={t('chart:zoom.zoomReset')} tooltipPlacement="top" />
      ) : null}
      <StyledClusteredCircularButtons buttons={buttonsZoomInOut} horizontal onPress={onZoom} />
    </ButtonsWrapper>
  );
};

export default React.memo(ZoomButtons);

const ButtonsWrapper = styled.div`
  display: flex;
`;

const StyledButtonCircular = styled(ButtonCircular)`
  --size: 32px;

  margin-right: ${settings.Spacing.Spacing_100};
`;

const StyledClusteredCircularButtons = styled(ClusteredCircularButtons)`
  button {
    --size: 32px;

    width: 36px;

    &:first-of-type {
      padding-left: ${settings.Spacing.Spacing_150};
    }

    &:last-of-type {
      padding-right: ${settings.Spacing.Spacing_150};
    }
  }
`;
