import { isDefined, specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api/processData';

type Payload = number[];

export const useParametersCampaign = (
  vesselId: UUID,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) => {
  return useQuery(getKey(vesselId), (context) => queryFn(context, options?.axiosConfiguration), { ...options?.configuration, enabled: isDefined(vesselId) });
};

const getKey = (vesselId: UUID) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'processData',
      scope: 'parameters-campaigns-for-vessel-id',
      entity: 'list',
      vesselId,
    },
  ]);

const queryFn = ({ queryKey: [{ vesselId }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  return API.parameters.getParametersVesselidCampaigns(vesselId, { signal, ...axiosConfiguration }).then((response) => response.data);
};

useParametersCampaign.getKey = getKey;
useParametersCampaign.queryFn = queryFn;
