import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const EnvelopeIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        d="M4.6875 5C3.75586 5 3 5.78385 3 6.75C3 7.30052 3.24961 7.81823 3.675 8.15L11.325 14.1C11.7258 14.4099 12.2742 14.4099 12.675 14.1L20.325 8.15C20.7504 7.81823 21 7.30052 21 6.75C21 5.78385 20.2441 5 19.3125 5H4.6875ZM3 9.08333V16.6667C3 17.9536 4.00898 19 5.25 19H18.75C19.991 19 21 17.9536 21 16.6667V9.08333L13.35 15.0333C12.5484 15.6568 11.4516 15.6568 10.65 15.0333L3 9.08333Z"
        fill={fill}
      />
    </svg>
  );
};

EnvelopeIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
