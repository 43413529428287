import { RHIMContractsCoordinateSystem2D, RHIMLiningServiceV1ModelsLiningRegionDto } from '@rhim/rest/linings';
import { RHIMAPOCommonDataReportingCoordinateSystem2D, RHIMAPOReportingWearManagementApiV1ModelsRegionDto } from '@rhim/rest/wearManagement';
import { assert, ensure, isDefined } from '@rhim/utils';

export type LiningRegionArea =
  | { coordinateSystem: typeof RHIMContractsCoordinateSystem2D.ThetaZ; area: Quadruple<{ theta: number; z: number }> }
  | { coordinateSystem: typeof RHIMContractsCoordinateSystem2D.RTheta; area: Quadruple<{ r: number; theta: number }> }
  | { coordinateSystem: typeof RHIMContractsCoordinateSystem2D.Xy; area: Quadruple<{ x: number; y: number }> };

export type RegionArea =
  | { coordinateSystem: typeof RHIMContractsCoordinateSystem2D.ThetaZ; area: Quadruple<{ theta: number; z: number }>; displayName?: string | null }
  | { coordinateSystem: typeof RHIMContractsCoordinateSystem2D.RTheta; area: Quadruple<{ r: number; theta: number }>; displayName?: string | null }
  | { coordinateSystem: typeof RHIMContractsCoordinateSystem2D.Xy; area: Quadruple<{ x: number; y: number }>; displayName?: string | null };

export interface Point {
  x: number;
  y: number;
}

export type PreviewRegion = [Point, Point];

export function getAreas(foregroundRegion: RHIMLiningServiceV1ModelsLiningRegionDto, backgroundRegions: RHIMLiningServiceV1ModelsLiningRegionDto[]) {
  assert(backgroundRegions.every((region) => region.coordinateSystem === foregroundRegion.coordinateSystem));

  return {
    coordinateSystem: foregroundRegion.coordinateSystem,
    foregroundArea: getArea(foregroundRegion),
    backgroundAreas: backgroundRegions.map((region) => getArea(region)),
  };
}

export function getArea(region: RHIMLiningServiceV1ModelsLiningRegionDto): LiningRegionArea;
export function getArea(region: RHIMAPOReportingWearManagementApiV1ModelsRegionDto): RegionArea;
export function getArea(region: RHIMAPOReportingWearManagementApiV1ModelsRegionDto | RHIMLiningServiceV1ModelsLiningRegionDto): LiningRegionArea | RegionArea {
  if (isLiningRegion(region)) {
    return getAreaFromLiningRegion(region);
  } else {
    return getAreaFromRegion(region);
  }
}

function getAreaFromLiningRegion(region: RHIMLiningServiceV1ModelsLiningRegionDto): LiningRegionArea {
  const { minimumPoint, maximumPoint, coordinateSystem } = region;

  assert(isDefined(coordinateSystem));
  assert(isDefined(minimumPoint));
  assert(isDefined(maximumPoint));

  switch (coordinateSystem) {
    case RHIMContractsCoordinateSystem2D.ThetaZ:
      return {
        coordinateSystem,
        area: [
          {
            theta: ensure(minimumPoint.theta),
            z: ensure(minimumPoint.z),
          },
          {
            theta: ensure(maximumPoint.theta),
            z: ensure(minimumPoint.z),
          },
          {
            theta: ensure(maximumPoint.theta),
            z: ensure(maximumPoint.z),
          },
          {
            theta: ensure(minimumPoint.theta),
            z: ensure(maximumPoint.z),
          },
        ],
      };
    case RHIMContractsCoordinateSystem2D.RTheta:
      return {
        coordinateSystem,
        area: [
          {
            r: ensure(minimumPoint.r),
            theta: ensure(minimumPoint.theta),
          },
          {
            r: ensure(maximumPoint.r),
            theta: ensure(minimumPoint.theta),
          },
          {
            r: ensure(maximumPoint.r),
            theta: ensure(maximumPoint.theta),
          },
          {
            r: ensure(minimumPoint.r),
            theta: ensure(maximumPoint.theta),
          },
        ],
      };
    case RHIMContractsCoordinateSystem2D.Xy:
      return {
        coordinateSystem,
        area: [
          {
            x: ensure(minimumPoint.x),
            y: ensure(minimumPoint.y),
          },
          {
            x: ensure(maximumPoint.x),
            y: ensure(minimumPoint.y),
          },
          {
            x: ensure(maximumPoint.x),
            y: ensure(maximumPoint.y),
          },
          {
            x: ensure(minimumPoint.x),
            y: ensure(maximumPoint.y),
          },
        ],
      };
    default:
      throw new Error(`Unsupported coordinate system: ${coordinateSystem}`);
  }
}

function getAreaFromRegion(region: RHIMAPOReportingWearManagementApiV1ModelsRegionDto): RegionArea {
  const { area, coordinateSystem } = region;

  assert(isDefined(coordinateSystem));
  assert(isDefined(area));

  switch (coordinateSystem) {
    case RHIMAPOCommonDataReportingCoordinateSystem2D.ThetaZ:
      return {
        coordinateSystem,
        area: [
          {
            theta: ensure(area[0]?.theta),
            z: ensure(area[0]?.z),
          },
          {
            theta: ensure(area[1]?.theta),
            z: ensure(area[1]?.z),
          },
          {
            theta: ensure(area[2]?.theta),
            z: ensure(area[2]?.z),
          },
          {
            theta: ensure(area[3]?.theta),
            z: ensure(area[3]?.z),
          },
        ],
      };
    case RHIMAPOCommonDataReportingCoordinateSystem2D.RTheta:
      return {
        coordinateSystem,
        area: [
          {
            r: ensure(area[0]?.r),
            theta: ensure(area[0]?.theta),
          },
          {
            r: ensure(area[1]?.r),
            theta: ensure(area[1]?.theta),
          },
          {
            r: ensure(area[2]?.r),
            theta: ensure(area[2]?.theta),
          },
          {
            r: ensure(area[3]?.r),
            theta: ensure(area[3]?.theta),
          },
        ],
      };
    case RHIMAPOCommonDataReportingCoordinateSystem2D.Xy:
      return {
        coordinateSystem,
        area: [
          {
            x: ensure(area[0]?.x),
            y: ensure(area[0]?.y),
          },
          {
            x: ensure(area[1]?.x),
            y: ensure(area[1]?.y),
          },
          {
            x: ensure(area[2]?.x),
            y: ensure(area[2]?.y),
          },
          {
            x: ensure(area[3]?.x),
            y: ensure(area[3]?.y),
          },
        ],
      };
    default:
      throw new Error(`Unsupported coordinate system: ${coordinateSystem}`);
  }
}

function isLiningRegion(
  region: RHIMAPOReportingWearManagementApiV1ModelsRegionDto | RHIMLiningServiceV1ModelsLiningRegionDto
): region is RHIMLiningServiceV1ModelsLiningRegionDto {
  return !('area' in region);
}
