import axios, { AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, HeadersDefaults } from 'axios';

import { AuthClient, createAuthorizationHeader, getAccessToken } from '@rhim/react';
import { isDefined, isObject } from '@rhim/utils';
import type { BearerAccessToken } from 'typings';

/**
 * We should expose secrets only when such endpoints are called.
 */
const API_ENDPOINT_PREFIX = '/api/';

export enum HTTPStatusCode {
  Unauthorized = 401,
  /**
   * 449 is an arbitrary status code that is sent by the server when the client has not supplied all of the information required to complete the request.
   */
  PleaseRetry = 449,
}

/**
 * Axios API class.
 * Creates an Axios instance and adds interceptors for setting/renew the authorization token.
 */
export class AxiosApi {
  axiosApiInstance: AxiosInstance;
  authClient: AuthClient;
  bearerToken: BearerAccessToken | null;

  constructor(authClient: AuthClient, headers?: HeadersDefaults) {
    this.axiosApiInstance = axios.create();
    this.authClient = authClient;
    if (isDefined(headers)) {
      this.axiosApiInstance.defaults.headers ??= headers;
    }
    this.bearerToken = null;
    this.addInterceptors();
  }

  /**
   * Add interceptors for the axios instance.
   */
  addInterceptors() {
    /**
     * If a request to the API failed because of missing authorization header,
     * refresh the access token and try again. Note: this axios instance must be used to make the call.
     */
    this.axiosApiInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest: AxiosRequestConfig & { _retry?: boolean } = error.config;

        // 401 Unauthorized -> token expired. Let's renew the token and try again
        if (isDefined(error.response) && error.response.status === HTTPStatusCode.Unauthorized && !originalRequest._retry) {
          originalRequest._retry = true;

          this.bearerToken = (await this.authClient.renewToken()).bearerAuthentication;

          return this.axiosApiInstance(originalRequest);
        }

        // 449 Processing on server failed - let's retry once
        if (error.response?.status === HTTPStatusCode.PleaseRetry && !originalRequest._retry) {
          originalRequest._retry = true;
          return this.axiosApiInstance(originalRequest);
        }

        return Promise.reject(error);
      }
    );

    /**
     * Intercept calls to our API and add the `Authorization` header to them.
     *
     * @see https://thedutchlab.com/blog/using-axios-interceptors-for-refreshing-your-api-token
     * @see https://github.com/Flyrell/axios-auth-refresh#readme
     * @see https://gist.github.com/mkjiau/650013a99c341c9f23ca00ccb213db1c
     * @see https://medium.com/swlh/handling-access-and-refresh-tokens-using-axios-interceptors-3970b601a5da
     */
    this.axiosApiInstance.interceptors.request.use(
      async (config) => {
        if (config.url?.startsWith(API_ENDPOINT_PREFIX)) {
          // if we already have a token, we just need to set the authorization header
          if (this.bearerToken) {
            return this.setHeaders(config);
          }

          // as a fallback, we try to read it from local storage
          const token = getAccessToken(localStorage);

          if (isObject(token) && isDefined(token.secret)) {
            this.bearerToken = createAuthorizationHeader('Bearer', token.secret);
          } else {
            // if the user is not authenticated yet, we trigger authentication (could as well be a passive stalling until the user is logged in)

            try {
              const account = await this.authClient.authenticate();
              this.setBearerToken(account.bearerAuthentication);
            } catch (error) {
              return Promise.reject(error);
            }
          }
        }
        return this.setHeaders(config);
      },
      (error) => {
        Promise.reject(error);
      }
    );
  }

  /**
   * Sets the authorization token.
   * @param token Authorization token.
   */
  setBearerToken(token: BearerAccessToken) {
    this.bearerToken = token;
  }

  protected setHeaders(config: AxiosRequestConfig): AxiosRequestConfig {
    const headers: AxiosRequestHeaders = {
      ...config.headers,
      Authorization: isDefined(this.bearerToken) ? this.bearerToken : '',
      'Cookiebot-Tag': 'necessary',
    };

    return { ...config, headers };
  }
}
