import { settings } from '@rhim/design';
import { assert, hasElements, isDefined } from '@rhim/utils';
import styled from 'styled-components';

import { Area } from '../WallplotHeatmap/utils';
import { AreaItemGroup } from './types';

export const getAreaItemGroups = (wallplotAreas: Area[]): AreaItemGroup[] => {
  const ret: AreaItemGroup[] = [];
  if (!hasElements(wallplotAreas)) {
    return ret;
  }
  const groupNames = wallplotAreas[0].lines.map((line) => line.line);
  for (const groupName of groupNames) {
    const areas = wallplotAreas.map((area) => {
      const lineBelongingToGroupName = area.lines.find((line) => line.line === groupName);
      assert(isDefined(lineBelongingToGroupName), `Area ${area.name} does not have line belonging to group : ${groupName}`);
      return { areaName: area.name, region: lineBelongingToGroupName };
    });
    ret.push({ groupName: groupName, areaItems: areas });
  }
  return ret;
};

export const SNoAreasToDisplay = styled.p`
  margin: ${settings.Spacing.Spacing_300};
`;
