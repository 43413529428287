import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const PrinterIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <g>
              <g>
                <path
                  d="M20 6c0-1.105-.895-2-2-2h-2V2c0-1.105-.895-2-2-2H6C4.895 0 4 .895 4 2v2H2C.895 4 0 4.895 0 6v7.974c0 1.105.895 2 2 2h2V18c0 1.105.895 2 2 2h8c1.105 0 2-.895 2-2v-2.026h2c1.105 0 2-.895 2-2V6zM6 2.5c0-.276.224-.5.5-.5h7c.276 0 .5.224.5.5V6H6V2.5zm8 15c0 .276-.224.5-.5.5h-7c-.276 0-.5-.224-.5-.5V16h8v1.5zm1-4c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5 1.5.672 1.5 1.5-.672 1.5-1.5 1.5z"
                  transform="translate(-1404 -68) translate(0 56) translate(1404 12) translate(2 2)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

PrinterIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
