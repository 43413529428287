import { useMemoCompare } from '@rhim/react';
import { hasElements, head, isDefined } from '@rhim/utils';
import { join } from 'lodash';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useSessionStorageState from 'use-session-storage-state';

import { PARAMS, STORAGE_KEYS } from '../utilities';
import { LOCAL_STORAGE_PREFIX_APO } from '../utilities/storage';

const logger = log.child('useSelectedCampaign');

logger.mute();

function useSelectedCampaignId(initialState: number) {
  return useSessionStorageState(join([LOCAL_STORAGE_PREFIX_APO, STORAGE_KEYS.SELECTED_CAMPAIGN_ID], ''), {
    defaultValue: initialState,
  });
}

export const CAMPAIGN_ID_NOT_SET_ID = -1;
export const CAMPAIGN_UNASSIGNED_ID = -2;

export function useSelectedCampaign(campaigns: RHIM.CampaignOrUnassigned[], initialValue?: number): [number, React.Dispatch<React.SetStateAction<number>>] {
  // eslint-disable-next-line compat/compat
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const queryCampaignId = query.get(PARAMS.CAMPAIGN_ID);
  const [persistedCampaignId, setPersistedCampaignId] = useSelectedCampaignId(initialValue ?? CAMPAIGN_ID_NOT_SET_ID);

  // We are not trusting the persisted campaign, because it may not belong to the list of available campaigns.
  // In that case, we fall back to the first campaign available.
  // Otherwise we fall back to CAMPAIGN_ID_NOT_SET_ID.
  const selectedCampaignId = useMemo((): number => {
    if (isDefined(queryCampaignId) && campaigns.some((campaign) => campaign.id === parseInt(queryCampaignId))) {
      const queryCampaignIdNumber = parseInt(queryCampaignId);
      setPersistedCampaignId(queryCampaignIdNumber);

      return queryCampaignIdNumber;
    } else if (isDefined(campaigns)) {
      if (isDefined(persistedCampaignId) && campaigns.some((campaign) => campaign.id === persistedCampaignId)) {
        return persistedCampaignId;
      } else if (hasElements(campaigns)) {
        return head(campaigns).id ?? CAMPAIGN_ID_NOT_SET_ID;
      }
    }
    setPersistedCampaignId(CAMPAIGN_ID_NOT_SET_ID);

    return CAMPAIGN_ID_NOT_SET_ID;
  }, [queryCampaignId, campaigns, setPersistedCampaignId, persistedCampaignId]);

  const memoizedValue: [number, React.Dispatch<React.SetStateAction<number>>] = useMemoCompare(
    [selectedCampaignId, setPersistedCampaignId],
    ([previousId], [nextId]) => {
      return previousId === nextId;
    }
  );

  return memoizedValue;
}
