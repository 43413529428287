import { usePrivileges } from '@rhim/sdk/customerManagement';
import { useContext } from 'react';

import AppContext from '../app/AppContext';
import { PRIVILEGES } from '../utilities';

export const useCrkPriviledges = () => {
  const { user, selectedCustomer } = useContext(AppContext);
  const privileges = usePrivileges(user, selectedCustomer);

  const hasLesAdminAccess = privileges.global.has(PRIVILEGES.ManageCrkPointCloudReport) || privileges.customer.has(PRIVILEGES.ManageCrkPointCloudReport);

  const hasLesAccess =
    privileges.customer.has(PRIVILEGES.AccessCrkPointCloudReport) || privileges.global.has(PRIVILEGES.AccessCrkPointCloudReport) || hasLesAdminAccess;

  return { hasLesAdminAccess, hasLesAccess };
};
