import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const SearchIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M12.951 14.933c.12 0 .216-.036.288-.108l1.549-1.589c.168-.168.168-.337 0-.505l-3.062-3.106a5.504 5.504 0 0 0 .757-2.817c0-1.589-.559-2.943-1.675-4.062-1.117-1.12-2.455-1.68-4.016-1.68-1.56 0-2.905.56-4.034 1.68-1.128 1.12-1.693 2.473-1.693 4.062 0 1.59.565 2.943 1.693 4.063 1.129 1.12 2.473 1.679 4.034 1.679 1.009 0 1.969-.277 2.881-.83l3.026 3.105a.345.345 0 0 0 .252.108zm-6.159-4.622c-.96 0-1.783-.343-2.467-1.03a3.377 3.377 0 0 1-1.027-2.473c0-.963.343-1.787 1.027-2.473.684-.686 1.507-1.03 2.467-1.03.96 0 1.777.35 2.45 1.048.672.674 1.008 1.492 1.008 2.455 0 .963-.336 1.788-1.009 2.474a3.3 3.3 0 0 1-2.449 1.03z"
      />
    </svg>
  );
};

SearchIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
