import { settings } from '@rhim/design';
import { shortcutLabelVolumeExplorer } from '@rhim/test-ids';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

interface Props {
  measurementId: string;
  label: string;
  heat: number;
  isSelected: boolean;
  onShortcutMeasurementClicked: (measurementId: string) => void;
}
const MeasurementSelectionShortcut: React.ChildlessComponent<Props> = ({ measurementId, label, heat, isSelected, onShortcutMeasurementClicked }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  const handleClicked = useCallback(() => {
    onShortcutMeasurementClicked(measurementId);
  }, [measurementId, onShortcutMeasurementClicked]);

  return (
    <SWrapper
      isHovered={isHovered}
      isSelected={isSelected}
      onClick={handleClicked}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      data-test-id={shortcutLabelVolumeExplorer}
    >
      <SLabel>{label}</SLabel>
      <SHeat>{`Heat ${heat}`}</SHeat>
    </SWrapper>
  );
};

const SWrapper = styled.div<{ isHovered: boolean; isSelected: boolean }>`
  height: 32px;
  display: flex;
  align-items: center;
  column-gap: ${settings.Spacing.Spacing_50};
  color: ${(props) => (props.isSelected ? settings.colors.Monochromatic.White : settings.colors.Primary.Grey_8)};
  background-color: ${(props) =>
    props.isSelected ? settings.colors.Primary.Blue_9 : props.isHovered ? settings.colors.Primary.Grey_2 : settings.colors.Monochromatic.White};
  padding: 0 ${settings.Spacing.Spacing_150};
  cursor: pointer;

  &:not(:first-child) {
    border-top: 1px solid ${settings.colors.Primary.Grey_2};
  }
`;

const SLabel = styled.span`
  font-family: ${settings.typography.FontFamily.Bold};
  font-size: ${settings.typography.FontSize.Small};
  white-space: nowrap;
`;

const SHeat = styled(SLabel)`
  font-family: ${settings.typography.FontFamily.Regular};
`;

MeasurementSelectionShortcut.whyDidYouRender = true;
export default React.memo(MeasurementSelectionShortcut);
