import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const HistoricHourglassTinyIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="M8 8s3 1.343 3 3v1H5v-1c0-1.657 3-3 3-3zm3-4v1c0 1.657-3 3-3 3S5 6.657 5 5V4h6z"
                    transform="translate(-1000.000000, -2296.000000) translate(82.000000, 2280.000000) translate(64.000000, 0.000000) translate(852.000000, 16.000000) translate(2.000000, 0.000000)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

HistoricHourglassTinyIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Grey_10,
};
