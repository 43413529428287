import { de, enGB, enUS, ptBR, zhCN } from 'date-fns/locale';

export const locales: Record<string, Locale> = {
  en: enUS,
  'en-US': enUS,
  'en-GB': enGB,
  de,
  'zh-CN': zhCN,
  'pt-BR': ptBR,
};
