import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const DownloadIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M15 9c.552 0 1 .447 1 1v5c0 .553-.448 1-1 1H1c-.552 0-1-.447-1-1v-5c0-.553.448-1 1-1s1 .447 1 1v4h12v-4c0-.553.448-1 1-1zM8 0c.552 0 1 .447 1 1v6.586l1.293-1.293c.391-.391 1.023-.391 1.414 0s.391 1.023 0 1.414l-3 3C8.512 10.902 8.256 11 8 11c-.256 0-.512-.098-.707-.293l-3-3c-.391-.391-.391-1.023 0-1.414s1.023-.391 1.414 0L7 7.586V1c0-.553.448-1 1-1z"
      />
    </svg>
  );
};

DownloadIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
