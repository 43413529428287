import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const LadleIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M19.994 2l.116.007c.549.06.945.556.884 1.104l-.818 7.356c.179-.274.473-.466.824-.466.552 0 1 .448 1 1v2c0 .552-.448 1-1 1s-1-.448-1-1h-.105l-.901 8.11c-.057.507-.484.89-.994.89H6c-.51 0-.938-.383-.994-.89L4.105 13H4c0 .552-.448 1-1 1s-1-.448-1-1v-2c0-.552.448-1 1-1 .351 0 .645.192.824.466L3.006 3.11c-.061-.548.335-1.043.884-1.104.544-.064 1.043.334 1.104.884L6.895 20h10.21l1.901-17.11c.061-.55.557-.946 1.104-.884zM8.444 4c1.001 0 1.88.199 2.897.428 1.195.269 2.548.573 4.215.573.33 0 .657-.01.984-.026L15.315 18h-6.63L7.356 4.047C7.692 4.019 8.057 4 8.444 4z"
      />
    </svg>
  );
};

LadleIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Grey_8,
};
