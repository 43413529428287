import { isDefined } from '@rhim/utils';
import { useCallback, useContext, useMemo } from 'react';
import shortHash from 'shorthash2';

import AppContext from '../app/AppContext';
import { PARAMS } from '../utilities';

export function useCustomerIdHashmap() {
  const { user } = useContext(AppContext);

  const customerIdMap = useMemo(() => {
    const map = new Map<string, string>();

    if (!isDefined(user.customers)) {
      return map;
    }
    for (const customer of user.customers) {
      map.set(customer.customerId, shortHash(customer.customerId));
    }
    return map;
  }, [user.customers]);

  const getCustomerIdParam = useCallback((customerId: string) => `${PARAMS.CUSTOMER_ID}=${customerIdMap.get(customerId)}`, [customerIdMap]);

  const getCustomerIdByHash = useCallback(
    (hash: string): string => {
      for (const [key, value] of customerIdMap.entries()) {
        if (value === hash) return key;
      }
      return hash;
    },
    [customerIdMap]
  );

  return { getCustomerIdParam, getCustomerIdByHash };
}
