/**
 * @see https://stackoverflow.com/questions/136505/searching-for-uuids-in-text-with-regex
 */
export const UUID_PATTERN = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

export const isString = (candidate: unknown): candidate is string => typeof candidate === 'string';

export const isUUID = (candidate: string): candidate is UUID => UUID_PATTERN.test(candidate);

/**
 * @example
 *
 * ```ts
 * removePrefix('foo')('foobar'); // => "bar"
 * ```
 */
export const removePrefix =
  <T extends string>(prefix: T) =>
  (string: `${T}${string}`): string =>
    string.slice(prefix.length);

/**
 * Represents either a 3-digit or a 6-digit prefixed hex color.
 *
 * @example
 *   #ddd
 *
 * @example
 *   #dddddd
 *
 * @see https://stackoverflow.com/a/1636354/10325032
 */
export const HEX_PATTERN_PREFIXED = /^#(?:[0-9a-fA-F]{3}){1,2}$/;

/**
 * Represents either a 3-digit or a 6-digit unprefixed hex color.
 *
 * @example
 *   ddd
 *
 * @example
 *   dddddd
 *
 * @see https://stackoverflow.com/a/1636354/10325032
 */
export const HEX_PATTERN_UNPREFIXED = /^(?:[0-9a-fA-F]{3}){1,2}$/;

/**
 * Check multiple suffixes for a given string.
 * @param suffixes List of suffixes
 * @param string String to check.
 */
export const endsWithAny = (string: string, suffixes: string[]) => {
  return suffixes.some(function (suffix) {
    return string.endsWith(suffix);
  });
};

export const isEmpty = (candidate: string): candidate is '' => candidate.length === 0;
