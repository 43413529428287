import { Upload as UploadAntd } from 'antd';
import React from 'react';
import styled from 'styled-components';

export type { RcFile, UploadChangeParam, UploadFile, UploadProps } from 'antd/es/upload/interface';

const Upload = styled(UploadAntd)`
  .ant-upload.ant-upload-drag {
    background: none;
    border: none;

    .ant-upload-btn {
      padding: 0;
    }
  }
`;

Upload.whyDidYouRender = true;
export default React.memo(Upload);
