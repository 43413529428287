import { settings } from '@rhim/design';
import { isDefined } from '@rhim/utils';
import { Rate as AntdRate } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

import { Tooltip } from '../Tooltip';

interface Props {
  /**
   * Must be in the range [0, 1], inclusive.
   */
  value: number;
  /**
   * If specified, renders a label next to the rating.
   *
   * @see https://app.zeplin.io/project/5eb285b3a2756d736f228d80/screen/5f86f13790033d8f17ea13aa
   */
  renderLabel?: (value: number) => React.Node;
  /**
   * The number of dots displayed.
   */
  count?: number;
  style?: React.CSSProperties;
}

/**
 * @example
 *
 *   <Rate
 *     value={0.625}
 *     renderLabel={(value) => `${(value * 100).toFixed(2)}%`}
 *     count={4}
 *   />
 *
 * This will render:
 *
 *   🌑 🌑 🌓 🌕 62.5%
 *
 */
const Rate: React.ChildlessComponent<Props> = ({ value, renderLabel, count = 4 }) => {
  return (
    <Tooltip title={value.toFixed(2)} placement="bottom">
      <div>
        <Dots value={value * count} character={<Circle />} count={count} allowHalf disabled />
        {isDefined(renderLabel) ? <Label>{renderLabel(value)}</Label> : null}
      </div>
    </Tooltip>
  );
};

const Circle = styled.div.attrs({ role: 'presentation' })`
  border-radius: 50%;
  width: 12px;
  height: 12px;
`;

const Dots = styled(AntdRate)`
  height: 12px;
  font-size: 12px;
  line-height: 1em;

  .ant-rate-star {
    pointer-events: none; /* Disable the default hover effect supplied by Ant */
  }

  .ant-rate-star:not(:last-child) {
    margin-right: 5px;
  }

  /* stylelint-disable-next-line */
  .ant-rate-star-full ${Circle}, .ant-rate-star-half .ant-rate-star-first ${Circle} {
    background-color: ${settings.colors.Primary.Grey_8};
  }

  /* stylelint-disable-next-line */
  .ant-rate-star-zero ${Circle}, .ant-rate-star-half .ant-rate-star-second ${Circle} {
    background-color: ${settings.colors.Primary.Grey_3};
  }
`;

const Label = styled.span`
  font-size: ${settings.typography.FontSize.X_Small};
  font-family: ${settings.typography.FontFamily.Medium};
  color: ${settings.colors.Primary.Grey_7};
  display: inline-block;
  margin-left: 12px;
`;

export default React.memo(Rate);
