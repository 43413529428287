import { pageNotFoundContainer } from '@rhim/test-ids';
import styled from 'styled-components';

import ErrorPanel from '../components/ErrorPanel/ErrorPanel';
import { Invariants } from '../errors';

const PageNotFound = (): JSX.Element => {
  return (
    <Container data-test-id={pageNotFoundContainer}>
      <ErrorPanel error={new Error(Invariants.NoPageFound)} />
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  place-items: center;
  place-content: center;
  width: 100%;

  /* Note: do not use 100vh here because vertical stretching is already done by Layout */
  height: 100%;
`;

export default PageNotFound;
