import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  children: React.ReactNode;
}

const NoPrintComponent: React.FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  const { children, className } = props;

  return <NoPrintWrapper className={className}>{children}</NoPrintWrapper>;
};

export const NoPrintAppWrapper = React.memo(NoPrintComponent);

const NoPrintWrapper = styled.div`
  @media print {
    visibility: hidden;
  }
`;

export const NoPrint = styled.div`
  @media print {
    display: none;
  }
`;
