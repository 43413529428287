import { settings } from '@rhim/design';
import React from 'react';
import styled from 'styled-components';

type Severity = 'error' | 'info';

export interface Feedback {
  dataTestId?: string;
  severity: Severity;
  content: string | React.ReactElement;
}

interface Props {
  /**
   * Hint displayed below the field.
   */
  feedback: Feedback;
  feedbackElementId: string;
}
const FieldFeedback: React.ChildlessComponent<Props> = ({ feedback, feedbackElementId }) => {
  return (
    <ErrorMessageContainer>
      <ErrorMessage data-test-id={feedback.dataTestId} severity={feedback.severity} id={feedbackElementId}>
        {feedback.content}
      </ErrorMessage>
    </ErrorMessageContainer>
  );
};

export default React.memo(FieldFeedback);

const ErrorMessageContainer = styled.div`
  margin-top: 4px;
`;

const ErrorMessage = styled.span<{ severity: Severity }>`
  color: ${(props) => (props.severity === 'error' ? settings.colors.Operational.State_Notif_Magenta_4 : settings.colors.Primary.Grey_6)};
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.X_Small};
`;
