// Note: this information should be provided by the backend, but the backend is not there yet
const qckLightHostName = 'qcklight'; // e.g. https://qcklight-dev.westeurope.cloudapp.azure.com or https://qcklight.rhimagnesita.com/

export function isQckLightCustomer() {
  // TODO: this is temporarily during development
  return (
    window.location.port === '4443' || // local development environment
    window.location.host.startsWith(qckLightHostName)
  );
}
