import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const DatabaseIcon: React.FunctionComponent<Props> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 12c-4.547 0-7-1.42-7-2V8.6c1.736.922 4.374 1.4 7 1.4 2.626 0 5.264-.478 7-1.4V10c0 .58-2.453 2-7 2zm0 4c-4.547 0-7-1.42-7-2v-1.4c1.736.922 4.374 1.4 7 1.4 2.626 0 5.264-.478 7-1.4V14c0 .58-2.453 2-7 2zm0 4c-4.547 0-7-1.42-7-2v-1.4c1.736.922 4.374 1.4 7 1.4 2.626 0 5.264-.478 7-1.4V18c0 .58-2.453 2-7 2zm0-16c4.547 0 7 1.42 7 2 0 .58-2.453 2-7 2-4.547 0-7-1.42-7-2 0-.58 2.453-2 7-2zm0-2C7.527 2 3 3.374 3 6v12c0 2.626 4.527 4 9 4s9-1.374 9-4V6c0-2.626-4.527-4-9-4z"
      />
    </svg>
  );
};

DatabaseIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
