import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const FileGenericIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M14 2v4.001C14 7.105 14.895 8 15.999 8H20v12c0 1.105-.896 2-2 2H6c-1.104 0-2-.895-2-2V4c0-1.104.896-2 2-2h8zm2 0l4 4h-4V2z"
      />
    </svg>
  );
};

FileGenericIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
