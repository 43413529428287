import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const DetectionHistoricIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 15 15" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path d="M7.5 0 15 7.273 7.5 15 0 7.273 7.5 0z" fill={fill} />
    </svg>
  );
};

DetectionHistoricIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Operational.State_Blue_3,
};
