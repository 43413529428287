import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const ArrowDownIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 8 8" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <path d="M4 1L7 7 1 7z" transform="translate(-212 -1194) translate(212 1194) rotate(180 4 4)" />
          </g>
        </g>
      </g>
    </svg>
  );
};

ArrowDownIcon.defaultProps = {
  size: '8',
  fill: settings.colors.Primary.Grey_8,
};
