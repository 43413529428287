import { settings } from '@rhim/design';
import { RHIMOperatorDisplayServiceV1ModelsOperatorDataViewModel } from '@rhim/rest/operatorDisplay';
import { RHIMAPOReportingWearManagementApiV1ModelsCampaignOverviewDto } from '@rhim/rest/wearManagement';
import { hasElements, isDefined } from '@rhim/utils';
import * as React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useMount } from 'react-use';
import styled from 'styled-components';

import AreasSelectionPanel from '../../components/AreasSelectionPanel/AreasSelectionPanel';
import ErrorPanel, { errorCodes } from '../../components/ErrorPanel/ErrorPanel';
import OperatorDisplayStrip from '../../components/OperatorDisplayStrip/OperatorDisplayStrip';
import { useRegionsOrder } from '../../hooks';
import { AreaContext } from './AreaContext';
import { useHiddenNavigation } from './useHiddenNavigation';

interface Props extends FallbackProps {
  vessels: APO.VesselV2[];
  vessel: APO.VesselV2;
  setSelectedVesselId: (value: UUID) => void;
  setSelectedCampaign?: (value: number) => void;
  selectedCampaign?: number;
  campaigns?: RHIMAPOReportingWearManagementApiV1ModelsCampaignOverviewDto[];
}
const OperatorDisplayErrorPage: React.FunctionComponent<Props> = ({
  error,
  resetErrorBoundary,
  vessel,
  vessels,
  setSelectedVesselId,
  selectedCampaign,
  setSelectedCampaign,
  campaigns,
}) => {
  const [hidden, hide] = useHiddenNavigation();
  const { setAreaSelectionsVisible, areaSelectionsVisible } = React.useContext(AreaContext);
  const [compareMode, setCompareMode] = React.useState(false);

  const toggleFullscreen = React.useCallback(() => {
    hide(!hidden);
  }, [hidden, hide]);

  // Show the app bar in case error occurs on the fullscreen mode.
  useMount(() => {
    if (hidden) {
      toggleFullscreen();
    }
  });

  const { data: regions } = useRegionsOrder(vessel.id);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let newError = error as any;

  if (isDefined(regions) && regions.length > 0 && newError.errorCode === errorCodes.operatorview.onRegionsDefined) {
    newError = { ...error, errorCode: 'operatordisplayservice.noRegionsSelected' };
  }
  const handleSelect = React.useCallback(
    (id: UUID) => {
      if (id !== vessel.id) {
        resetErrorBoundary();
        setSelectedVesselId(id);
      }
    },
    [resetErrorBoundary, vessel.id, setSelectedVesselId]
  );

  const handleCampaignSelection = React.useCallback(
    (id: number) => {
      if (id !== selectedCampaign && isDefined(setSelectedCampaign)) {
        resetErrorBoundary();
        setSelectedCampaign(id);
      }
    },
    [resetErrorBoundary, selectedCampaign, setSelectedCampaign]
  );

  return (
    <Container fullscreen={hidden}>
      {hasElements(vessels) && (
        <OperatorDisplayStrip
          isTableView={true}
          compareMode={compareMode}
          toggleCompareMode={setCompareMode}
          settingsDisabled={!(isDefined(regions) && regions.length > 0)}
          toggleSettings={() => setAreaSelectionsVisible(!areaSelectionsVisible)}
          toggleFullscreen={toggleFullscreen}
          isFullscreen={hidden}
          data={{} as RHIMOperatorDisplayServiceV1ModelsOperatorDataViewModel}
          vessels={vessels}
          selectedVesselId={vessel.id}
          onSelect={handleSelect}
          campaigns={campaigns}
          campaignId={selectedCampaign}
          setSelectedCampaignsId={handleCampaignSelection}
          disabled
        />
      )}
      {isDefined(vessel.id) && isDefined(regions) && Boolean(regions.length) && (
        <AreasSelectionPanel
          data={regions}
          vesselId={vessel.id}
          callback={resetErrorBoundary}
          onClose={setAreaSelectionsVisible}
          visible={areaSelectionsVisible}
        />
      )}
      <Wrapper>
        <ErrorPanel error={newError} resetErrorBoundary={resetErrorBoundary} />
      </Wrapper>
    </Container>
  );
};

const Container = styled.div<{ fullscreen: boolean }>`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  background-color: ${settings.colors.Primary.Grey_1};
  height: ${(props) => (props.fullscreen ? '100%' : 'auto')};
`;

const Wrapper = styled.div`
  margin: 0 24px;
  flex: 2;
  display: flex;
`;

export default React.memo(OperatorDisplayErrorPage);
