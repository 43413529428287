import type { AxiosInstance } from 'axios';

import { API } from './api';

/**
 * Primes the API with an Axios instance designed to use under the hood.
 *
 * Example usage: adding authentication headers to every call made by the API client.
 */
export function initialize(axiosInstance: AxiosInstance): void {
  Object.values(API).forEach((api) => {
    // @ts-expect-error This is a protected property, but we override it anyway.
    // We cannot fix the issue upstream because the class is defined in autogenerated code
    // and each modification made to that file would be overriden each time the codegen runs.
    api.axios = axiosInstance;
  });
}
