import React from 'react';

import { ZOOM_INIT } from '../constants';
import ZoomContext from '../context/ZoomContext';
import { useZoom } from '../hooks/useZoom';

interface Props {
  initialZoom?: number;
}

export const ZoomProvider: React.FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  const { initialZoom = ZOOM_INIT, children } = props;
  const value = useZoom({ initialZoom });

  return <ZoomContext.Provider value={value}>{children}</ZoomContext.Provider>;
};
