import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const RedoIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M16 8c0 .552.448 1 1 1h4c.552 0 1-.448 1-1V4c0-.552-.448-1-1-1s-1 .448-1 1v1.796C18.099 3.454 15.144 2 12 2 6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10c0-.552-.448-1-1-1s-1 .448-1 1c0 4.411-3.589 8-8 8s-8-3.589-8-8 3.589-8 8-8c2.473 0 4.867 1.169 6.399 3H17c-.552 0-1 .448-1 1"
      />
    </svg>
  );
};

RedoIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
