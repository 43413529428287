import { AXIS_LEFT_STYLES, AxisBottom, AxisLeft } from '@rhim/chart/v2/axis';
import { Chart, DEFAULT_MARGIN } from '@rhim/chart/v2/chart';
import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto } from '@rhim/rest/fleetOverview';
import { ensure, hasElements, isDefined, last } from '@rhim/utils';
import { Group } from '@visx/group';
import { curveLinear } from '@vx/curve';
import { scaleLinear } from '@vx/scale';
import { Bar, Line, LinePath } from '@vx/shape';
import { defaultStyles, Tooltip, withTooltip } from '@vx/tooltip';
import { min } from 'd3';
import { max } from 'd3-array';
import { ScaleLinear } from 'd3-scale';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { scaleX as linearScaleX } from '../../../components/PredictionGraph/dataProcessor';
import { calculateLeftWithBoundries, calculateTicks, calculateTopWithBoundries, getTruncatedVolume } from '../utils';
import { usePositionContext } from './hooks/usePositionContext';
import VolumeTooltip, { Keys } from './VolumeTooltip';

interface Props {
  measurements: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto[];
  height: number;
  width: number;
  compareToMeasurement?: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto;
  maxHeat: number;
}

const PERCENTAGE_STEP = 5;
const TOOLTIPHEIGHT = 22;
const VolumeGraph = withTooltip<Props>(({ maxHeat, compareToMeasurement, width, height, measurements }) => {
  const { t } = i18nReact.useTranslation(['volume']);

  const xMax = width;
  const yMax = height;

  const maxVolume = useMemo(() => {
    return isDefined(measurements) && hasElements(measurements) ? max(measurements.map((item) => item.volume))! : 0;
  }, [measurements]);

  const minVolume = useMemo(() => {
    return isDefined(measurements) && hasElements(measurements) ? min(measurements.map((item) => item.volume))! : 0;
  }, [measurements]);

  const lastMeasurement = useMemo(
    () => (isDefined(measurements) && hasElements(measurements) ? last(measurements) : ({} as RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto)),
    [measurements]
  );

  const initialMeasurement = useMemo(
    () => (isDefined(measurements) && hasElements(measurements) ? measurements[0] : ({} as RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto)),
    [measurements]
  );

  /**
   * Gets the lining from a point.
   */
  const getVolume = useCallback((d: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto) => {
    return d.volume;
  }, []);

  /**
   * Gets the heat from a point.
   */
  const getHeat = useCallback((d: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto) => {
    return d.heat;
  }, []);

  const domainPercentage = useMemo(() => {
    const { volume } = initialMeasurement;
    let percentage = 20;
    let initialMaxValue = volume + (percentage / 100) * volume;

    while (initialMaxValue < maxVolume) {
      percentage = percentage + PERCENTAGE_STEP;
      initialMaxValue = volume + (volume * percentage) / 100;
    }

    let initialMinValue = volume - (percentage / 100) * volume;
    while (initialMinValue > minVolume) {
      percentage = percentage + PERCENTAGE_STEP;
      initialMinValue = volume - (volume * percentage) / 100;
    }
    return percentage;
  }, [maxVolume, initialMeasurement, minVolume]);

  const yDomain = useMemo(() => {
    const { volume } = initialMeasurement;
    const maxVolume = volume + (volume * domainPercentage) / 100;
    const minVolume = volume - (volume * domainPercentage) / 100;
    return [minVolume, maxVolume];
  }, [initialMeasurement, domainPercentage]);

  /**
   * X axis scale function.
   */
  const xScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [0, xMax],
        round: true,
        domain: [0, maxHeat],
      }),
    [xMax, maxHeat]
  );
  /**
   * Y axis scale function.
   */
  const yScale = scaleLinear({
    range: [yMax, 0],
    domain: yDomain,
  });

  const positionContext = usePositionContext();
  const markerX = isDefined(positionContext.position) ? xScale(positionContext.position) : null;

  const getScaleX = useCallback((point: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto): number => ensure(xScale(getHeat(point))), [xScale, getHeat]);
  const getScaleY = useCallback((point: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto): number => ensure(yScale(getVolume(point))), [yScale, getVolume]);

  const axisScale = linearScaleX(xMax, maxHeat);
  const yAxisTicks = useMemo(() => {
    const value = initialMeasurement.volume;

    const ticksValues = [];
    // Increase the tick values if needed.
    const step = domainPercentage / 5 >= 10 ? domainPercentage / 5 : PERCENTAGE_STEP;

    for (let percentage = -domainPercentage; percentage <= domainPercentage; percentage += step) {
      const result = value + (value * percentage) / 100;
      ticksValues.push(result);
    }

    return ticksValues;
  }, [initialMeasurement, domainPercentage]);

  const margin = { top: 15, left: 10 };
  const groupMargin = { left: 40, top: 20 };
  const volumeUnit = 'm³';

  return (
    <Container>
      <Chart width={width + DEFAULT_MARGIN.left} height={height + DEFAULT_MARGIN.top + DEFAULT_MARGIN.bottom + margin.top}>
        <Group left={margin.left} top={margin.top} transform={`translate(${groupMargin.left}, ${groupMargin.top})`}>
          {yAxisTicks.map((value) => (
            <Line
              key={value}
              from={{ x: 0, y: yScale(value) }}
              to={{ x: xMax, y: yScale(value) }}
              stroke={settings.colors.Primary.Grey_3}
              strokeWidth={0.5}
              pointerEvents="none"
            />
          ))}
          {isDefined(markerX) && <MarkerLine from={{ x: markerX, y: 0 }} to={{ x: markerX, y: yMax }} />}
          {isDefined(compareToMeasurement) && (
            <>
              <Line
                from={{ x: getScaleX(compareToMeasurement), y: 0 }}
                to={{ x: getScaleX(compareToMeasurement), y: yMax }}
                stroke={settings.colors.Miscellaneous.Green}
                strokeWidth={1}
                pointerEvents="none"
              />
              <g>
                <circle
                  cx={xScale(compareToMeasurement.heat)}
                  cy={getScaleY(compareToMeasurement)}
                  r={5}
                  fill={settings.colors.Monochromatic.White}
                  pointerEvents="none"
                />
                ;
                <circle
                  cx={xScale(compareToMeasurement.heat)}
                  cy={getScaleY(compareToMeasurement)}
                  r={4}
                  fill={settings.colors.Miscellaneous.Green}
                  pointerEvents="none"
                />
                ;
              </g>
            </>
          )}
          <Line
            from={{ x: 0, y: getScaleY(initialMeasurement) }}
            to={{ x: xMax, y: getScaleY(initialMeasurement) }}
            stroke={settings.colors.Primary.Grey_6}
            strokeWidth={1}
            pointerEvents="none"
          />
          <Line from={{ x: xMax, y: 0 }} to={{ x: xMax, y: yMax }} stroke={settings.colors.Primary.Grey_3} strokeWidth={1} pointerEvents="none" />
          <LinePath stroke={settings.colors.Primary.Grey_8} strokeWidth={2} data={measurements} x={getScaleX} y={getScaleY} curve={curveLinear} />
          <Bar x={0} y={0} width={width} height={height} fill="transparent" rx={14} />
          <AxisLeft
            axisLabel={
              <>
                <PercentageSpan>{t('volume:axisVolumePercentageLabel') + ' /'}</PercentageSpan>
                &nbsp;{t('volume:axisVolumeLabel')}
              </>
            }
            tickLabelProps={(value) =>
              value === initialMeasurement.volume
                ? { ...AXIS_LEFT_STYLES.tickLabelProps, fontFamily: settings.typography.FontFamily.Bold, textAnchor: 'end', dx: '-0.25em', dy: '0.25em' }
                : { ...AXIS_LEFT_STYLES.tickLabelProps, textAnchor: 'end', dx: '-0.25em', dy: '0.25em' }
            }
            tickFormat={yScale.tickFormat(yAxisTicks.length, '.2f')}
            left={0}
            top={0}
            scale={yScale as ScaleLinear<number, number>}
            tickValues={yAxisTicks}
          />
          <AxisBottom top={yMax} scale={axisScale} width={width} tickValues={calculateTicks(maxHeat, 6)} stroke={settings.colors.Primary.Grey_2} />
          <Line
            from={{ x: xScale(lastMeasurement.heat), y: 0 }}
            to={{ x: xScale(lastMeasurement.heat), y: yMax + 10 }}
            stroke={settings.colors.Primary.Blue_8}
            strokeWidth={1}
            pointerEvents="none"
          />
          <g>
            <circle cx={xScale(lastMeasurement.heat)} cy={getScaleY(lastMeasurement)} r={5} fill={settings.colors.Monochromatic.White} pointerEvents="none" />
            <circle cx={xScale(lastMeasurement.heat)} cy={getScaleY(lastMeasurement)} r={4} fill={settings.colors.Primary.Blue_8} pointerEvents="none" />;
          </g>
        </Group>
        <Group top={groupMargin.top} left={groupMargin.left}>
          <foreignObject x={0} y={0} width={width} height={height + DEFAULT_MARGIN.top + DEFAULT_MARGIN.bottom}>
            <Tooltip
              top={yMax + margin.left}
              left={xMax - groupMargin.left}
              style={{
                ...defaultStyles,
                padding: 0,
                boxShadow: 'none',
                fontSize: settings.typography.FontSize.X_Small,
                color: settings.colors.Primary.Grey_6,
              }}
            >
              {t('volume:heats')}
            </Tooltip>
            <Tooltip
              top={calculateTopWithBoundries(getScaleY(lastMeasurement) - 10, height, TOOLTIPHEIGHT)}
              left={calculateLeftWithBoundries(ensure(xScale(lastMeasurement.heat)) + 20, width, 40, 20)}
              style={{
                ...defaultStyles,
                backgroundColor: settings.colors.Primary.Blue_8,
                color: settings.colors.Monochromatic.White,
                padding: settings.Spacing.Spacing_50,
                boxShadow: 'none',
                zIndex: settings.Elevation.Tooltip + 1,
              }}
            >
              {/* eslint-disable-next-line i18next/no-literal-string */}
              {getTruncatedVolume(lastMeasurement.volume)}
            </Tooltip>

            <Tooltip
              top={yMax}
              left={calculateLeftWithBoundries(ensure(xScale(lastMeasurement.heat)), width, 40)}
              style={{
                ...defaultStyles,
                backgroundColor: settings.colors.Primary.Blue_8,
                color: settings.colors.Monochromatic.White,
                padding: settings.Spacing.Spacing_50,
                boxShadow: 'none',
                zIndex: 100,
              }}
            >
              {/* eslint-disable-next-line i18next/no-literal-string */}
              {lastMeasurement.heat}
            </Tooltip>
          </foreignObject>
        </Group>
        <Group top={groupMargin.top} left={groupMargin.left} style={{ zIndex: 100 }}>
          <VolumeTooltip
            chartWidth={width}
            chartHeight={height}
            data={measurements}
            keys={[Keys.VOLUME]}
            xScale={xScale as ScaleLinear<number, number>}
            yScale={yScale as ScaleLinear<number, number>}
            unit={volumeUnit}
          />
        </Group>
      </Chart>
      {yAxisTicks.map((value) => {
        const percentage = Math.round((100 * value) / initialMeasurement.volume - 100);
        return (
          <Tooltip
            key={percentage}
            top={ensure(yScale(value)) + 2}
            left={-(DEFAULT_MARGIN.left - margin.left)}
            style={{ ...defaultStyles, padding: 0, boxShadow: 'none' }}
          >
            {/* eslint-disable-next-line i18next/no-literal-string */}
            <PercentageSpan width={30}>{`${percentage}%`}</PercentageSpan>
          </Tooltip>
        );
      })}
    </Container>
  );
});

export default React.memo(VolumeGraph);

const Container = styled.div`
  display: flex;
`;

export const GraphWrapper = styled.div<{ $height: number }>`
  background-color: ${settings.colors.Monochromatic.White};
  position: relative;
  display: flex;
`;

export const Center = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
`;

const PercentageSpan = styled.span<{ width?: number }>`
  font-size: ${settings.typography.FontSize.X_Small};
  font-family: ${settings.typography.FontFamily.Bold};
  color: ${settings.colors.Primary.Grey_6};
  width: ${(props) => (isDefined(props.width) ? `${props.width}px` : 'auto')};
  display: flex;
  justify-content: end;
`;

const MarkerLine = styled(Line)`
  stroke: ${settings.colors.Primary.Grey_8};
  stroke-width: 2;
  pointer-events: none;
`;
