import { ArcRotateCamera, ArcRotateCameraMouseWheelInput, Observer, PointerEventTypes, PointerInfo, Vector3 } from '@babylonjs/core';

export const HEIGHT_REGISTRATION_FRONT_VIEW_WIDTH_RATIO = 2 / 3;
export const HEIGHT_REGISTRATION_SIDE_VIEWS_VERTICAL_HEIGHT_RATIO = 1 / 2;

export class HeightRegistrationMouseWheelInput extends ArcRotateCameraMouseWheelInput {
  private ZOOM_STEP = 0.05;
  private MINIMUM_ZOOM = 0.5;
  private onPointerObserver: Nullable<Observer<PointerInfo>> = null;
  private meshSize;
  private zoom = 1;
  private referenceHeight = 0;
  private onViewBoundsChanged;
  private currentOrthographicOffsetY = 0;
  private mouseOffsetY = 0;
  private currentMouseOrthographicY = 0;

  constructor(meshSize: Vector3, onViewBoundsChanged: (bounds: ViewBounds) => void) {
    super();
    this.meshSize = meshSize;
    this.onViewBoundsChanged = onViewBoundsChanged;
  }

  getClassName() {
    return 'HeightRegistrationMouseWheelInput';
  }

  getSimpleName() {
    return 'heightRegistrationCustomMouseWheelInput';
  }

  attachControl() {
    this.onPointerObserver = this.camera.getScene().onPointerObservable.add((eventData: PointerInfo) => {
      if (eventData.type !== PointerEventTypes.POINTERWHEEL) {
        return;
      }

      this.mouseOffsetY = eventData.event.offsetY;
      const { width: canvasWidth, height: viewportHeight } = this.camera.getEngine().getRenderingCanvasClientRect() as DOMRect;
      const viewportWidth = canvasWidth * HEIGHT_REGISTRATION_FRONT_VIEW_WIDTH_RATIO;
      const meshWidth = this.meshSize.y;
      const meshHeight = this.meshSize.z;
      const isFitByHeight = shouldFitByHeight(meshWidth, meshHeight, viewportWidth, viewportHeight);
      const viewportRatio = viewportWidth / viewportHeight;
      const orthographicHeight = isFitByHeight ? meshHeight / this.zoom : meshWidth / this.zoom / viewportRatio;
      // Calculate the current orthographic y of the mouse cursor (before the zoom event)
      this.currentMouseOrthographicY = orthographicHeight / 2 - (orthographicHeight * this.mouseOffsetY) / viewportHeight;

      const isZoomIn = Math.sign((eventData.event as WheelEvent).deltaY) < 0;
      this.zoomView(isZoomIn, false);
    });
  }

  detachControl() {
    this.camera.getScene().onPointerObservable.remove(this.onPointerObserver);
  }

  updateView(isFullScreenToggleUpdate = false) {
    const { width: canvasWidth, height: viewportHeight } = this.camera.getEngine().getRenderingCanvasClientRect() as DOMRect;
    const viewportWidth = canvasWidth * HEIGHT_REGISTRATION_FRONT_VIEW_WIDTH_RATIO;
    const meshWidth = this.meshSize.y;
    const meshHeight = this.meshSize.z;
    const { visibleMeshTop, visibleMeshBottom, visibleMeshTopPx, visibleMeshBottomPx, currentOrthographicOffsetY } = setOrthographicCoordinates(
      this.camera,
      meshWidth,
      meshHeight,
      this.zoom,
      viewportWidth,
      viewportHeight,
      {
        currentOrthographicOffsetY: this.currentOrthographicOffsetY,
        referenceHeightOffsetFromMeshCenter: meshHeight / 2 - this.referenceHeight,
        currentMouseOrthographicY: this.currentMouseOrthographicY,
        mouseOffsetY: this.mouseOffsetY,
        preserveOrthographicOffsetY: isFullScreenToggleUpdate,
      }
    );
    this.currentOrthographicOffsetY = currentOrthographicOffsetY;
    this.onViewBoundsChanged({ visibleMeshTop, visibleMeshBottom, visibleMeshTopPx, visibleMeshBottomPx });
  }

  zoomView(isZoomIn: boolean, isCenterZoom: boolean) {
    if (isZoomIn) {
      this.zoom += this.ZOOM_STEP;
    } else {
      this.zoom = Math.max(this.MINIMUM_ZOOM, this.zoom - this.ZOOM_STEP);
    }

    if (isCenterZoom) {
      this.currentMouseOrthographicY = 0;
      const { height: viewportHeight } = this.camera.getEngine().getRenderingCanvasClientRect() as DOMRect;
      this.mouseOffsetY = viewportHeight / 2;
    }
    this.updateView();
  }

  resetView() {
    this.currentOrthographicOffsetY = 0;
    this.zoom = 1;
    this.currentMouseOrthographicY = 0;
    const { height: viewportHeight } = this.camera.getEngine().getRenderingCanvasClientRect() as DOMRect;
    this.mouseOffsetY = viewportHeight / 2;
    this.updateView();
  }

  setHeight(height: number) {
    this.referenceHeight = height;
  }
}

function shouldFitByHeight(sourceWidth: number, sourceHeight: number, viewportWidth: number, viewportHeight: number) {
  return viewportHeight / sourceHeight < viewportWidth / sourceWidth;
}

interface ZoomToPoint {
  referenceHeightOffsetFromMeshCenter: number;
  currentOrthographicOffsetY: number;
  currentMouseOrthographicY: number;
  mouseOffsetY: number;
  preserveOrthographicOffsetY: boolean;
}

export interface ViewBounds {
  visibleMeshTop: number;
  visibleMeshBottom: number;
  visibleMeshTopPx: number;
  visibleMeshBottomPx: number;
}

interface ViewInfo extends ViewBounds {
  currentOrthographicOffsetY: number;
}

export function setOrthographicCoordinates(
  camera: ArcRotateCamera,
  sourceWidth: number,
  sourceHeight: number,
  zoom: number,
  viewportWidth: number,
  viewportHeight: number,
  zoomToPoint?: ZoomToPoint
): ViewInfo {
  const viewportRatio = viewportWidth / viewportHeight;
  const isFitByHeight = shouldFitByHeight(sourceWidth, sourceHeight, viewportWidth, viewportHeight);
  // Calculate the orthographic size : the larger the zoom, the smaller is the visible area
  const orthographicScaledHeight = sourceHeight / zoom;
  const orthographicScaledWidth = sourceWidth / zoom;

  const orthoWidth = isFitByHeight ? orthographicScaledHeight * viewportRatio : orthographicScaledWidth;
  const orthoHeight = isFitByHeight ? orthographicScaledHeight : orthographicScaledWidth / viewportRatio;
  // If no explicit zoomToPoint has been provided, use one that vertically centers the mesh
  zoomToPoint = zoomToPoint ?? {
    referenceHeightOffsetFromMeshCenter: 0,
    currentOrthographicOffsetY: 0,
    currentMouseOrthographicY: 0,
    mouseOffsetY: viewportHeight / 2,
    preserveOrthographicOffsetY: false,
  };

  // Calculate the new orthographic y of the mouse cursor (after the zoom event)
  let newMouseOrthographicY = orthoHeight / 2 - (orthoHeight * zoomToPoint.mouseOffsetY) / viewportHeight;
  const orthographicTopUsingCursor = orthoHeight / 2 + getOrthographicOffsetY(zoomToPoint);
  if (zoomToPoint.referenceHeightOffsetFromMeshCenter > orthographicTopUsingCursor) {
    // Attempting to zoom in the current mouse cursor y results in the chosen reference line to go off the view ( somewhere above it ).
    // Ammend the value to ensure that the reference line remains within view.
    newMouseOrthographicY -= zoomToPoint.referenceHeightOffsetFromMeshCenter - orthographicTopUsingCursor;
  } else {
    const orthographicBottomUsingCursor = -orthoHeight / 2 + getOrthographicOffsetY(zoomToPoint);
    const bottomPadding = orthoHeight / 10;
    if (zoomToPoint.referenceHeightOffsetFromMeshCenter - bottomPadding < orthographicBottomUsingCursor) {
      // Attempting to zoom in the current mouse cursor y results in the chosen reference line to go off the view ( somewhere below it ).
      // Ammend the value to ensure that the reference line remains within view and also add some padding to it so that
      // the reference line does not just end up at the very bottom of the view but somewhere above it
      newMouseOrthographicY -= zoomToPoint.referenceHeightOffsetFromMeshCenter - bottomPadding - orthographicBottomUsingCursor;
    }
  }

  function getOrthographicOffsetY(zoomToPoint: ZoomToPoint) {
    return (
      zoomToPoint.currentOrthographicOffsetY + (zoomToPoint.preserveOrthographicOffsetY ? 0 : zoomToPoint.currentMouseOrthographicY - newMouseOrthographicY)
    );
  }

  const currentOrthographicOffsetY = getOrthographicOffsetY(zoomToPoint);

  // Center the view vertically but also apply the calculated offset y to shift the view up/down
  camera.orthoTop = orthoHeight / 2 + currentOrthographicOffsetY;
  camera.orthoBottom = -orthoHeight / 2 + currentOrthographicOffsetY;
  // Center the view horizontally
  camera.orthoRight = orthoWidth / 2;
  camera.orthoLeft = -orthoWidth / 2;

  // Calculate the top/bottom of the mesh (in meters) that is currently visible in the view
  // If the whole mesh is visible on the view and the mesh height is e.g 5 , then
  // visibleMeshTop will be 0 and visibleMeshBottom will be 5
  const visibleMeshTop = Math.max(0, sourceHeight / 2 - camera.orthoTop);
  const visibleMeshBottom = Math.min(sourceHeight, sourceHeight / 2 - camera.orthoBottom);

  // Calculate the top/bottom of the mesh (in pixels) that is currently visible in the view
  const zoomedOrthoPadding = orthoHeight - sourceHeight;
  const zoomedMeshOrthoTop = zoomedOrthoPadding / 2 + currentOrthographicOffsetY;
  const visibleMeshTopPx = Math.max(0, (viewportHeight * zoomedMeshOrthoTop) / orthoHeight);
  const zoomedMeshOrthoBottom = zoomedMeshOrthoTop + sourceHeight;
  const visibleMeshBottomPx = Math.min(viewportHeight, (viewportHeight * zoomedMeshOrthoBottom) / orthoHeight);

  return {
    visibleMeshTop,
    visibleMeshBottom,
    visibleMeshTopPx,
    visibleMeshBottomPx,
    currentOrthographicOffsetY,
  };
}
