/**
 * Wrapper around Promise to allow resolving and rejecting outside of the constructor of the promise.
 * https://gist.github.com/jake-a-ward/3a3db1b66dd047e1f13a95d638ff374c
 */
export class Deferred<T> {
  private readonly _promise: Promise<T>;
  private _resolve!: (value: T | PromiseLike<T>) => void;
  private _reject!: (reason?: any) => void;

  constructor() {
    this._promise = new Promise<T>((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  public get promise(): Promise<T> {
    return this._promise;
  }

  public resolve(value: T | PromiseLike<T>) {
    this._resolve(value);
    return this.promise;
  }

  public reject(reason?: any) {
    this._reject(reason);
    return this.promise;
  }
}
