import { settings } from '@rhim/design';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, PinFilledIcon, PinOutlineIcon, XIcon } from '@rhim/icons/16';
import { withProps } from '@rhim/icons/utils';
import { isDefined } from '@rhim/utils';
import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const FOOTER_TOP_BORDER_SIZE = 1; // in px
const WRAPPER_BORDER_SIZE = 1; // in px

export interface ExpandedState {
  isExpanded: boolean;
  bodyHeight?: number;
}

interface PinnedView {
  panelId: string;
  isPinned: boolean;
  onCloseButtonClicked: (panelId: string) => void;
  onPinButtonToggled: (panelId: string, isPinned: boolean) => void;
}

interface SideFlyoutPanelProps {
  children: ReactElement<ExpandedState>;
  isDefaultExpanded?: boolean;
  onPanelExpanded?: (isExpanded: boolean) => void;
  pinnedView?: PinnedView;
}
export const SideFlyoutPanel: FC<React.PropsWithChildren<SideFlyoutPanelProps>> = ({ children, isDefaultExpanded = true, onPanelExpanded, pinnedView }) => {
  const domElement = useRef<HTMLDivElement>(null);
  const domBodyContainerElement = useRef<HTMLDivElement>(null);
  const domFooterContainerElement = useRef<HTMLDivElement>(null);
  const [isExpanded, setExpanded] = useState(isDefaultExpanded);
  const [isPinned, setPinned] = useState(isDefined(pinnedView) ? pinnedView.isPinned : false);
  const [bodyHeight, setBodyHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);

  useEffect(() => {
    if (!domBodyContainerElement.current || !domFooterContainerElement.current) {
      return;
    }
    setBodyHeight(domBodyContainerElement.current.clientHeight);
    const footerTotalHeight = domFooterContainerElement.current.clientHeight + FOOTER_TOP_BORDER_SIZE;
    setFooterHeight(footerTotalHeight);
  }, [isExpanded]);

  const handleExpandButtonClicked = () => {
    const currentExpandedState = !isExpanded;
    setExpanded(currentExpandedState);
    onPanelExpanded?.(currentExpandedState);
  };

  const handleCloseButtonClicked = () => {
    if (pinnedView) {
      pinnedView.onCloseButtonClicked(pinnedView.panelId);
    }
  };

  const handlePinButtonClicked = () => {
    const newPinnedState = !isPinned;
    setPinned(newPinnedState);
    if (pinnedView) {
      pinnedView.onPinButtonToggled(pinnedView.panelId, newPinnedState);
    }
  };

  const SXIcon = withProps(XIcon, { fill: 'currentColor' });
  const SChevronDoubleRightIcon = withProps(ChevronDoubleRightIcon, { fill: 'currentColor' });
  const SChevronDoubleLeftIcon = withProps(ChevronDoubleLeftIcon, { fill: 'currentColor' });
  const SPinFilledIcon = withProps(PinFilledIcon, { fill: 'currentColor' });
  const SPinOutlineIcon = withProps(PinOutlineIcon, { fill: 'currentColor' });

  return (
    <StyledWrapper ref={domElement}>
      <StyledBodyContainer ref={domBodyContainerElement} footerHeight={footerHeight}>
        <StyledBody>{React.cloneElement(children, { isExpanded: isExpanded, bodyHeight: bodyHeight })}</StyledBody>
      </StyledBodyContainer>
      <StyledFooter isExpanded={isExpanded} ref={domFooterContainerElement}>
        {isExpanded ? <SChevronDoubleRightIcon onClick={handleExpandButtonClicked} /> : <SChevronDoubleLeftIcon onClick={handleExpandButtonClicked} />}
        {pinnedView && (
          <>
            <StyledCloseIcon onClick={handleCloseButtonClicked} inExpandedPanel={isExpanded}>
              <SXIcon />
            </StyledCloseIcon>
            <StyledHorizontalSpring />
            {isPinned ? <SPinFilledIcon onClick={handlePinButtonClicked} /> : <SPinOutlineIcon onClick={handlePinButtonClicked} />}
          </>
        )}
      </StyledFooter>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: ${settings.colors.Monochromatic.White};
  box-shadow: 0 2px 16px 0 rgba(61, 85, 103, 0.1);
  border: ${(_) => `solid ${WRAPPER_BORDER_SIZE}px ${settings.colors.Primary.Grey_3}`};
  overflow-x: hidden;
  transition: width 0.2s ease-out;
`;

interface StyledBodyContainerProps {
  footerHeight: number;
}
const StyledBodyContainer = styled.div<StyledBodyContainerProps>`
  flex-grow: 1;
  height: ${(props) => `calc(100% - ${props.footerHeight}px)`};
`;

const StyledBody = styled.div`
  height: 100%;
`;

interface StyledFooterProps {
  isExpanded: boolean;
}

const StyledFooter = styled.div<StyledFooterProps>`
  align-self: stretch;
  border-top: ${(_) => `solid ${FOOTER_TOP_BORDER_SIZE}px ${settings.colors.Primary.Grey_3}`};
  display: flex;
  align-items: center;
  flex-direction: ${(props) => (props.isExpanded ? 'row' : 'column-reverse')};
  padding: ${settings.Spacing.Spacing_150} ${settings.Spacing.Spacing_200};

  svg {
    cursor: pointer;
    color: ${settings.colors.Primary.Blue_6};

    &:hover {
      color: ${settings.colors.Primary.Blue_9};
    }
  }
`;

interface StyledCloseIconProps {
  inExpandedPanel: boolean;
}
const StyledCloseIcon = styled.span<StyledCloseIconProps>`
  display: flex;
  align-items: center;
  margin-left: ${(props) => (props.inExpandedPanel ? settings.Spacing.Spacing_300 : '0')};

  --marginTopDown: ${(props) => (props.inExpandedPanel ? '0' : settings.Spacing.Spacing_200)};

  margin-top: var(--marginTopDown);
  margin-bottom: var(--marginTopDown);
`;

const StyledHorizontalSpring = styled.span`
  flex-grow: 1;
`;
