import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const SurfacePointCloudAxis: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.707 2.293 12 1.586l-.707.707-2 2 1.414 1.414.293-.293v6.968l-3.428 1.714a1.009 1.009 0 0 0-.037.018l-3.338 1.67.166-.396-1.843-.776-1.097 2.607-.388.922.922.388 2.607 1.097.776-1.844-.363-.152 3.451-1.726a.875.875 0 0 0 .037-.019L12 14.118l3.535 1.767.037.019 3.43 1.715-.39.164.775 1.843 2.607-1.097.922-.387-.388-.922-1.097-2.607-1.843.776.153.363-3.276-1.638a1.003 1.003 0 0 0-.037-.018L13 12.382V5.414l.293.293 1.414-1.414-2-2zM8 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-4 2a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm1 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1-7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm8-4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm1 11a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1-7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-3 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7-3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0-4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-7 11a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
      />
    </svg>
  );
};

SurfacePointCloudAxis.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
