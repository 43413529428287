import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const AddRangeMarkerIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        d="M7 3H2v5.075L4.5 11 7 8.075V3zM17 3h-5v5.075L14.5 11 17 8.075V3zM4 11h1v11H4V11zM5 12h1v1H5v-1zM5 16h1v1H5v-1zM5 14h1v1H5v-1zM5 18h1v1H5v-1zM5 20h1v1H5v-1zM6 13h1v1H6v-1zM6 17h1v1H6v-1zM6 15h1v1H6v-1zM6 19h1v1H6v-1zM6 21h1v1H6v-1zM8 13h1v1H8v-1zM8 17h1v1H8v-1zM8 15h1v1H8v-1zM8 19h1v1H8v-1zM8 21h1v1H8v-1zM10 13h1v1h-1v-1zM10 17h1v1h-1v-1zM10 15h1v1h-1v-1zM10 19h1v1h-1v-1zM10 21h1v1h-1v-1zM12 13h1v1h-1v-1zM12 17h1v1h-1v-1zM12 15h1v1h-1v-1zM12 19h1v1h-1v-1zM12 21h1v1h-1v-1zM7 12h1v1H7v-1zM7 16h1v1H7v-1zM7 14h1v1H7v-1zM7 18h1v1H7v-1zM7 20h1v1H7v-1zM9 12h1v1H9v-1zM9 16h1v1H9v-1zM9 14h1v1H9v-1zM9 18h1v1H9v-1zM9 20h1v1H9v-1zM11 12h1v1h-1v-1zM11 16h1v1h-1v-1zM11 14h1v1h-1v-1zM11 18h1v1h-1v-1zM11 20h1v1h-1v-1zM13 12h1v1h-1v-1zM13 16h1v1h-1v-1zM13 14h1v1h-1v-1zM13 18h1v1h-1v-1zM13 20h1v1h-1v-1zM14 11h1v11h-1V11z"
        fill={fill}
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M20 16h-2v2h-2v2h2v2h2v-2h2v-2h-2v-2z" fill={fill} />
    </svg>
  );
};

AddRangeMarkerIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
