import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const PredictionHeatIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 13c0 .449-.55 1-.998 1-.453 0-1.001-.446-1.001-1 0-.176.046-.349.133-.499a.999.999 0 1 0-1.73-1.002c-.085.146-.134.307-.193.464a.938.938 0 0 1-.462.004 1.012 1.012 0 0 1-.73-.769A1.065 1.065 0 0 1 3.004 11a3.004 3.004 0 0 0 2.995-3.113c-.022-.544-.462-.902-1.019-.923-.544.011-.979.492-.979 1.036 0 .551-.449 1-1.001 1a.97.97 0 0 1-.32-.06l-.007-.003A1 1 0 0 1 2 8a1 1 0 0 1 .673-.937.998.998 0 0 0 .498-1.504.997.997 0 0 1-.152-.757c.066-.359.366-.675.73-.769a.95.95 0 0 1 .462.003c.059.158.108.319.193.465a1 1 0 1 0 1.73-1.002A.996.996 0 0 1 6.001 3c0-.539.551-1 1.001-1C7.45 2 8 2.551 8 3v10zm6.5-6.572A1.49 1.49 0 0 0 13.33 7H10V5h1.386c.274.308.669.504 1.114.504a1.5 1.5 0 1 0 0-3c-.44 0-.833.194-1.107.496H10c0-1.542-1.457-3-2.998-3-1.254 0-2.387.863-2.817 2.007a2.942 2.942 0 0 0-.936.09C2.14 2.383 1.257 3.324 1.052 4.44c-.079.426-.067.855.031 1.262A2.99 2.99 0 0 0 0 8c0 .904.411 1.74 1.083 2.298-.098.408-.11.836-.031 1.263.205 1.115 1.088 2.057 2.198 2.343.31.079.624.108.935.089C4.615 15.137 5.748 16 7.002 16 8.543 16 10 14.542 10 13h1.389c.275.305.669.5 1.111.5a1.5 1.5 0 1 0 0-3c-.442 0-.836.195-1.111.5H10V9h3.453A1.5 1.5 0 1 0 14.5 6.428z"
        fill={fill}
      />
    </svg>
  );
};

PredictionHeatIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
