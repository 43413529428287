import { settings } from '@rhim/design';
import { treatmentIcon } from '@rhim/test-ids';
import { Property } from 'csstype';
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
}

export const TreatmentsIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest} data-test-id={treatmentIcon}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 8a1 1 0 1 1-2 0V5c0-2.757 2.243-5 5-5s5 2.243 5 5v.586l.293-.293a.999.999 0 1 1 1.414 1.414l-2 2a.997.997 0 0 1-1.414 0l-2-2a.999.999 0 1 1 1.414-1.414l.293.293V5c0-1.654-1.346-3-3-3s-3 1.346-3 3v3zm-5 3a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2h-2v4h-4v-4h-2v4h-4v-4h-2a1 1 0 0 1-1-1z"
        fill={fill}
      />
    </svg>
  );
};

TreatmentsIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Grey_4,
};
