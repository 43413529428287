import { settings } from '@rhim/design';
import * as d3 from 'd3';

interface Props {
  width: number;
  min: number;
  max: number;
  upArrow: boolean;
  downArrow: boolean;
  yScale: d3.ScaleLinear<number, number>;
}
const TICKWIDTH = 4;

const VerticalBoxLimits = ({ width, min, max, upArrow, downArrow, yScale }: Props) => {
  return (
    <g>
      {downArrow ? (
        <g>
          <line x1={width - TICKWIDTH / 2} x2={width} y1={yScale(min) - TICKWIDTH} y2={yScale(min)} stroke={settings.colors.Primary.Grey_6} strokeWidth={1} />
          <line x1={width + TICKWIDTH / 2} x2={width} y1={yScale(min) - TICKWIDTH} y2={yScale(min)} stroke={settings.colors.Primary.Grey_6} strokeWidth={1} />
        </g>
      ) : (
        <line x1={width - TICKWIDTH} x2={width + TICKWIDTH} y1={yScale(min)} y2={yScale(min)} stroke={settings.colors.Primary.Grey_6} strokeWidth={1} />
      )}
      {upArrow ? (
        <g>
          <line x1={width} x2={width + TICKWIDTH / 2} y1={yScale(max)} y2={yScale(max) + TICKWIDTH} stroke={settings.colors.Primary.Grey_6} strokeWidth={1} />
          <line x1={width} x2={width - TICKWIDTH / 2} y1={yScale(max)} y2={yScale(max) + TICKWIDTH} stroke={settings.colors.Primary.Grey_6} strokeWidth={1} />
        </g>
      ) : (
        <line x1={width - TICKWIDTH} x2={width + TICKWIDTH} y1={yScale(max)} y2={yScale(max)} stroke={settings.colors.Primary.Grey_6} strokeWidth={1} />
      )}
    </g>
  );
};

export default VerticalBoxLimits;
