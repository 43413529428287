import { isDefined, joinPaths } from '@rhim/utils';
import React, { useEffect } from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, useLocation, useNavigate } from 'react-router-dom';

import { PageLoadingScreen } from '../PageLoadingScreen';
import { AuthClient, getBasename } from './client';

interface Props {
  /**
   * This is the default path that we want to redirect the user to upon successful login
   * Note: if the user wanted to open a specific page before the login kicked in, this specific page will be used instead
   */
  defaultPathAfterLogin: string;

  /**
   * the application's instance of the Authentication Client
   */
  authClient: AuthClient;

  /**
   * the basename used for routing. The redirect will be relative to this path
   */
  basename: string;
}

/**
 * This component for the virtual route at `redirectUri` ensures that when the login has finished, we navigate to a proper start URL.
 * Note: since the finishing of the authentication depends on the hash in the URL, we need to preserve it to give the rehydration time to process it
 */
const RedirectUriNavigationHelper: React.FunctionComponent<React.PropsWithChildren<Props>> = React.memo(function RedirectUriNavigationHelper({
  defaultPathAfterLogin,
  authClient,
  basename,
}) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // MSAL's `navigateToLoginRequestUrl` would cause a full page reload when redirecting us to the requested page upon login.
    // To prevent this, we navigate internally within our SPA by having the React Router take care of the navigation instead
    const targetUri = authClient.getTargetOriginUriAfterLogin();
    if (isDefined(targetUri)) {
      const targetPathWithBasename = targetUri.replace(window.location.origin, '');
      const navigateTo = joinPaths('/', targetPathWithBasename.replace(basename, ''));
      if (navigateTo === '/') {
        navigate(defaultPathAfterLogin, { replace: true }); // use the default page by default
      } else {
        navigate(navigateTo, { replace: true }); // navigate to the path that the user wanted to navigate to
      }
    }
  }, [authClient, basename, defaultPathAfterLogin, navigate]);

  if (!location.hash) {
    // this page is used only during the login process. If the hash is not there anymore, it means that MSAL has processed the state in the URL already and it's safe to redirect internally, which will hapopen in the next tick
    return <PageLoadingScreen container="window" />;
  }

  // This is only a virtual route which is part of the login contract. There's nothing to show here, we're just redirecting to the default page after the login.
  // We have to keep the hash in the URL in place though, so that MSAL can pick it up and complete the authentication if it hasn't already.
  // If a specific target URL was requested during login, MSAL will redirect us to that page (by doing another replace) once the authentication is completed
  return <Navigate to={{ pathname: defaultPathAfterLogin, hash: location.hash, search: location.search }} replace={true} />;
});

RedirectUriNavigationHelper.whyDidYouRender = true;

// Note: this is a nasty side effect required for React Router if the application is run on a path that doesn't match the base name.
// This happens if an application is hosted under `base href="/app/"`, but the user visits the app using the path `/app` without the trailing slash.
// In this scenario, React Router complains with the following error message:
// <Router basename="/app/"> is not able to match the URL "/app" because it does not start with the basename, so the <Router> won't render anything.
// The solution is to do a redirect from "/app" to "/app/".
// See: https://github.com/remix-run/react-router/issues/8427#issuecomment-1056988913
if (isDefined(window) && getBasename() === `${window.location.pathname}/`) {
  // redirect to the same URL but with a trailing slash, e.g. from "/app" to "/app/"
  window.history.replaceState('', '', `${window.location.pathname}/`);
}

/**
 * Sets up the browser routes to conform with the login flow by adding a virtual route at the authClient's `redirectUri`
 * This virtual route is required to complete the login process
 * @param authClient Authentication Client
 * @param defaultPageAfterLogin The completion of the login takes place at the virtual route. As soon as it's done, there are two options: if the user requested a specific page (before the login prevented access and interferred), we will finally navigate to that specific page. If no specific page was requested, we navigate to this default page
 * @param opts the basename which should be used for routing. All links will be relative to this basename. By default, the document's base href will be used
 */
export const createAuthenticatedBrowserRouter = (
  authClient: AuthClient,
  defaultPageAfterLogin: string,
  opts?: {
    basename?: string;
    window?: Window;
  }
) => {
  const basename = opts?.basename ?? getBasename(); // by default, the base href is used to have the routing in sync with the hosting

  return {
    withRoutesFromElements: (children: React.ReactNode, parentPath?: number[]) => {
      const routes = createRoutesFromElements(
        <>
          {/* render the virtual signedin route required for completing the authentication */}
          <Route
            path={authClient.redirectUri}
            element={<RedirectUriNavigationHelper authClient={authClient} defaultPathAfterLogin={defaultPageAfterLogin} basename={basename} />}
          />
          {/* render all other routes */}
          {children}
        </>,
        parentPath
      );
      return createBrowserRouter(routes, { basename, ...opts });
    },
  };
};
