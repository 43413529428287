import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  backgroundColor?: Property.Color;
  foregroundColor?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const HexagonPredictionIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  backgroundColor,
  foregroundColor,
  size,
  title,
  role,
  ...rest
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    fill={backgroundColor}
                    d="M27.03 24.697c.533-.31.97-1.074.97-1.695V8.952c0-.62-.437-1.384-.97-1.694L14.97.233c-.533-.31-1.407-.31-1.94 0L.97 7.258C.437 7.568 0 8.331 0 8.953v14.049c0 .621.437 1.384.97 1.695l12.06 7.024c.533.31 1.407.31 1.94 0l12.06-7.024z"
                    transform="translate(-234 -350) translate(111 348) translate(123 2) translate(2)"
                  />
                  <g fill={foregroundColor}>
                    <path
                      d="M8 13c0 .449-.55 1-.998 1-.453 0-1.001-.446-1.001-1 0-.176.046-.349.133-.499.277-.477.115-1.089-.363-1.366-.477-.276-1.09-.114-1.367.364-.085.146-.134.307-.193.464-.15.036-.304.045-.462.004-.364-.093-.664-.409-.73-.769-.013-.071-.015-.135-.015-.198 1.653-.002 2.997-1.347 2.997-3l-.002-.113c-.022-.544-.462-.902-1.019-.923-.544.011-.979.492-.979 1.036 0 .551-.449 1-1.001 1-.11 0-.217-.024-.32-.06l-.007-.003C2.271 8.795 2 8.419 2 8c0-.419.271-.795.673-.937.293-.102.522-.334.619-.629.098-.295.053-.619-.121-.875-.105-.155-.216-.412-.152-.757.066-.359.366-.675.73-.769.158-.041.312-.032.462.003.059.158.108.319.193.465.185.32.521.499.866.499.17 0 .343-.043.501-.135.478-.277.64-.889.363-1.366-.087-.15-.133-.323-.133-.499 0-.539.551-1 1.001-1C7.45 2 8 2.551 8 3v10zm6.5-6.572c-.476 0-.896.226-1.17.572H10V5h1.386c.274.308.669.504 1.114.504.829 0 1.5-.671 1.5-1.5 0-.828-.671-1.5-1.5-1.5-.44 0-.833.194-1.107.496H10c0-1.542-1.457-3-2.998-3-1.254 0-2.387.863-2.817 2.007-.312-.021-.627.01-.936.09-1.109.286-1.992 1.227-2.197 2.343-.079.426-.067.855.031 1.262C.411 6.26 0 7.096 0 8c0 .904.411 1.74 1.083 2.298-.098.408-.11.836-.031 1.263.205 1.115 1.088 2.057 2.198 2.343.31.079.624.108.935.089C4.615 15.137 5.748 16 7.002 16 8.543 16 10 14.542 10 13h1.389c.275.305.669.5 1.111.5.829 0 1.5-.671 1.5-1.5s-.671-1.5-1.5-1.5c-.442 0-.836.195-1.111.5H10V9h3.453c.271.264.639.428 1.047.428.829 0 1.5-.672 1.5-1.5s-.671-1.5-1.5-1.5z"
                      transform="translate(-234 -350) translate(111 348) translate(123 2) translate(2) translate(6 8)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

HexagonPredictionIcon.defaultProps = {
  size: '32',
  backgroundColor: settings.colors.Primary.Blue_7,
  foregroundColor: settings.colors.Primary.Blue_1,
};
