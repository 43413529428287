import { settings } from '@rhim/design';
import styled from 'styled-components';

export const LegendItemLabel = styled.span`
  margin-left: ${settings.Spacing.Spacing_100};
  color: ${settings.colors.Primary.Grey_6};
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.X_Small};
  line-height: 1;
  white-space: nowrap;
`;
