/**
 * @see https://app.zeplin.io/project/5eb293edf1f9a7763fec7596/screen/5f68a94d500987184985b0b6
 */
export enum Primary {
  /**
   * Brand color blue.
   */
  Blue_1 = '#e9effa',
  Blue_2 = '#dee7f4',
  Blue_3 = '#ccdaee',
  Blue_4 = '#b5c9e5',
  Blue_5 = '#97b4da',
  Blue_6 = '#719bca',
  Blue_7 = '#487cb4',
  Blue_8 = '#1e5894',
  Blue_9 = '#003262',
  Blue_10 = '#001540',

  /**
   * Brand color grey.
   */
  Grey_1 = '#f5f6f7',
  Grey_2 = '#eceef0',
  Grey_3 = '#d8dde1',
  Grey_4 = '#b1bbc2',
  Grey_5 = '#9eaab3',
  Grey_6 = '#778894',
  Grey_7 = '#506676',
  Grey_8 = '#3d5567',
  Grey_9 = '#294152',
  Grey_10 = '#142e3e',
  Grey_11 = '#979797',
}

export enum Monochromatic {
  White = '#ffffff',
  Black = '#000000',
}

export enum Operational {
  /* Application states : deactivated */
  State_Notif_Grey_2 = '#b6b6b6',
  State_Notif_Grey_3 = '#a3a0a0',
  State_Notif_Grey_4 = '#6d6d6d',

  /* Application states : ok, ready, confirmed */
  State_Notif_Green_1 = '#d8f4e7',
  State_Notif_Green_2 = '#40c88a',
  State_Notif_Green_3 = '#2bb576',
  State_Notif_Green_4 = '#007b43',

  /* Application states : Unhealthy */
  State_Notif_Yellow_1 = '#fafbd7',
  State_Notif_Yellow_2 = '#e7ea2e',
  State_Notif_Yellow_3 = '#c5c800',
  State_Notif_Yellow_4 = '#727400',

  /* Application states : error, system alert */
  State_Notif_Magenta_1 = '#fbd7e6',
  State_Notif_Magenta_2 = '#ff61a5',
  State_Notif_Magenta_3 = '#e60a69',
  State_Notif_Magenta_4 = '#9b0043',

  /* Process/Operational states : in-process */
  State_Blue_1 = '#d0f2f8',
  State_Blue_2 = '#1dc1e1',
  State_Blue_3 = '#1aa4bf',
  State_Blue_4 = '#1e7384',

  /* Process/Operational states : ok/ready */
  State_Green_1 = '#d5f6d5',
  State_Green_2 = '#33d632',
  State_Green_3 = '#3cb63b',
  State_Green_4 = '#217c20',

  /* Process/Operational states : alert/unhealthy */
  State_Alert_Yellow_1 = '#fbf5cc',
  State_Alert_Yellow_2 = '#f0d107',
  State_Alert_Yellow_3 = '#cdb622',
  State_Alert_Yellow_4 = '#7c6e17',

  /* Process/Operational states : alert/severe */
  State_Alert_Orange_1 = '#ffe5d5',
  State_Alert_Orange_2 = '#ff8133',
  State_Alert_Orange_3 = '#ed6612',
  State_Alert_Orange_4 = '#af4c0e',

  /* Process/Operational states : alert/critical */
  State_Alert_Red_1 = '#fdcbcb',
  State_Alert_Red_2 = '#ff6161',
  State_Alert_Red_3 = '#ed0909',
  State_Alert_Red_4 = '#a30000',
  State_Alert_R = '#f60000',
}

export enum Miscellaneous {
  /* sets transparency to : --rhim_color_pr_grey_8: #3d5567; */
  Full_Screen_Overlay_80 = 'rgba(61, 85, 103, 0.8)',
  Green = '#30aeab',
  Purple = '#b488fc',
  Purple_Deep = '#8451cd',
  Blue = '#3ea5ea',
}

export enum Categorical {
  Purple_70 = '#6929c4',
  Cyan_50 = '#1192e8',
  Teal_70 = '#005d5d',
  Magenta_70 = '#9f1853',
  Red_50 = '#fa4d56',
  Red_90 = '#570408',
  Green_60 = '#198038',
  Blue_80 = '#002d9c',
  Magenta_50 = '#ee538b',
  Yellow_50 = '#b28600',
  Teal_50 = '#009d9a',
  Cyan_90 = '#012749',
  Orange_70 = '#8a3800',
  Purple_50 = '#a56eff',
}

export enum Comparison {
  Higher = '#65efea',
  Lower = '#f5c48e',
}

export enum Univariate {
  Purple = '#b488fc',
  Purple_Deep = '#8451cd',
}

/**
 * Any color defined in our design system.
 */
export type Any = Primary | Monochromatic | Operational | Miscellaneous | Categorical | Comparison;
