import { VesselType } from '@rhim/rest';
import { RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto } from '@rhim/rest/processData';
import { specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api/processData';

export const useParametersRange = (
  customerId: UUID,
  vesselType: VesselType,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) => {
  return useQuery(getKey(vesselType, customerId), (context) => queryFn(context, options?.axiosConfiguration), options?.configuration);
};

type Payload = RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto[];

const getKey = (vesselType: VesselType, customerId: UUID) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'processData',
      scope: 'parameters-ranges-for-vessel-type',
      entity: 'list',
      vesselType,
      customerId,
    },
  ]);

const queryFn = (
  { queryKey: [{ vesselType, customerId }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>,
  axiosConfiguration?: AxiosRequestConfig
) => {
  return API.parametersRange
    .getParameterrangesCustomeridVesseltype(customerId, vesselType, { signal, ...axiosConfiguration })
    .then((response) => response.data);
};

useParametersRange.getKey = getKey;
useParametersRange.queryFn = queryFn;
