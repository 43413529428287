import { Configuration, CustomerFileTypeConfigurationApi, HarmonizationConfigurationApi, ParserApi, ParserConfigurationApi } from '@rhim/rest/fileIngestion';

const BASE_URL = '/api/fileingestionservice/v1';

const configuration: Configuration = {} as Configuration;

export const customerFileTypes = new CustomerFileTypeConfigurationApi(configuration, BASE_URL);
export const parsers = new ParserApi(configuration, BASE_URL);
export const parserConfiguration = new ParserConfigurationApi(configuration, BASE_URL);
export const harmonization = new HarmonizationConfigurationApi(configuration, BASE_URL);
