import { Button, Hyperlink, ModalDialog, Paragraph } from '@rhim/react';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface Props extends Omit<React.ComponentProps<typeof ModalDialog>, 'mode' | 'maskClosable' | 'headline' | 'description' | 'buttonPrimary' | 'closable'> {
  visible: boolean;
  onConfirm: () => void;
}

export const HeatWarningModal: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ visible, onConfirm, ...rest }) => {
  const { t } = useTranslation(['operator-display']);

  return (
    <ModalDialog
      open={visible}
      mode="alert"
      maskClosable={false}
      headline={t('operator-display:errors.heatMappingInvalidOrMissing.heading')}
      closable={false}
      description={
        <Trans i18nKey="operator-display:errors.heatMappingInvalidOrMissing.description">
          <Paragraph style={{ marginBottom: '16px' }}>
            It seems that there is a problem with the heatmapping configuration. Heatnumbers shown may not be reliable. The issue was reported to our support.
          </Paragraph>
          <Paragraph>
            If you have any further questions, please contact our support under{' '}
            <Hyperlink href="mailto:support.digital@rhimagnesita.com" text="support.digital@rhimagnesita.com" />
          </Paragraph>
        </Trans>
      }
      buttonPrimary={<Button size="small-40" onClick={onConfirm} label={t('operator-display:errors.heatMappingInvalidOrMissing.primaryButtonLabel')} />}
      {...rest}
    />
  );
};
