import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  backgroundColor?: Property.Color;
  foregroundColor?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const AppStateErrorIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  backgroundColor,
  foregroundColor,
  size,
  title,
  role,
  ...rest
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <path
          fill={backgroundColor}
          d="m21.767 18.317-8.182-14.4A1.823 1.823 0 0 0 12 3c-.657 0-1.263.351-1.585.917l-8.182 14.4c-.316.558-.31 1.24.016 1.792A1.82 1.82 0 0 0 3.818 21h16.364a1.82 1.82 0 0 0 1.569-.891 1.788 1.788 0 0 0 .016-1.792z"
        />
        <path fill={foregroundColor} d="M12 16a1.5 1.5 0 1 1 .001 2.999A1.5 1.5 0 0 1 12 16zm0-8a1 1 0 0 1 1 1v5a1 1 0 0 1-2 0V9a1 1 0 0 1 1-1z" />
      </g>
    </svg>
  );
};

AppStateErrorIcon.defaultProps = {
  size: '24',
  backgroundColor: settings.colors.Operational.State_Notif_Magenta_2,
  foregroundColor: settings.colors.Monochromatic.Black,
};
