import { Configuration, MeasurementFilesApi, OperatorDataViewApi, RegionsOrderApi } from '@rhim/rest/operatorDisplay';

import { axiosApiInstance } from '../../utilities';

const BASE_URL = '/api/operatordisplayservice/v1';

const configuration: Configuration = {} as Configuration;

export const operatorDisplay = new OperatorDataViewApi(configuration, BASE_URL, axiosApiInstance);
export const vessel = new RegionsOrderApi(configuration, BASE_URL, axiosApiInstance);
export const measurementFiles = new MeasurementFilesApi(configuration, BASE_URL, axiosApiInstance);
