import { settings } from '@rhim/design';
import { Collapse, Panel } from '@rhim/react';
import { RHIMProcessDataServiceV1ModelsCampaignComparisonDto, RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto } from '@rhim/rest/processData';
import { parameterItemParametersComparison } from '@rhim/test-ids';
import { assert, ensure, isDefined, RelativeDivWithSpinnerWhenEmpty } from '@rhim/utils';
import * as React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import styled from 'styled-components';

import ParameterPanel from './ParameterPanel';

interface Props {
  activeKey: number[] | number | string | string[];
  setActiveKey: (value: number[] | number | string | string[]) => void;
  parametersRange?: RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto[];
  currentCampaign?: RHIMProcessDataServiceV1ModelsCampaignComparisonDto;
  prevCampaign?: RHIMProcessDataServiceV1ModelsCampaignComparisonDto;
  sameCampaign: boolean;
  vesselName?: JSX.Element;
  comparedVesselName?: JSX.Element;
}
const ParametersMenu: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  activeKey,
  setActiveKey,
  currentCampaign,
  prevCampaign,
  parametersRange,
  sameCampaign,
  vesselName,
  comparedVesselName,
}) => {
  //eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const params = React.useMemo(() => {
    if (isDefined(currentCampaign)) {
      return currentCampaign.parameters || [];
    }
    if (isDefined(prevCampaign)) {
      return prevCampaign.parameters || [];
    }
    return [];
  }, [currentCampaign, prevCampaign]);

  const onChange = (key: string | string[]) => {
    setActiveKey(key);
  };

  const scrollbarRefs = React.useMemo(
    () =>
      params.reduce((acc, value) => {
        const paramId = value.name;
        assert(isDefined(paramId), 'paramId is required to generate scrollBar refs');
        acc[paramId] = React.createRef();
        return acc;
      }, {} as Record<string, React.RefObject<Scrollbars>>),
    [params]
  );

  return (
    <>
      {params.map((param, index) => {
        //eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        const paramRanges = (parametersRange || []).find((item) => item.customerColumnName === param.name);
        //eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        return (
          <StyledCollapse activeKey={activeKey} expandIconPosition="start" key={index} onChange={onChange} destroyInactivePanel={true}>
            <Panel data-test-id={parameterItemParametersComparison} header={<H3>{param.name}</H3>} key={index}>
              <Loading>
                <ParameterPanel
                  vesselName={vesselName}
                  comparedVesselName={comparedVesselName}
                  sameCampaign={sameCampaign}
                  parameterRanges={paramRanges}
                  currentCampaign={currentCampaign}
                  prevCampaign={prevCampaign}
                  param={param}
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  scrollbarRef={ensure(scrollbarRefs[param.name!])}
                />
              </Loading>
              {/*)}*/}
            </Panel>
          </StyledCollapse>
        );
      })}
    </>
  );
};

export default React.memo(ParametersMenu);

const Loading = styled(RelativeDivWithSpinnerWhenEmpty)`
  width: 100%;
`;
const StyledCollapse = styled(Collapse)`
  margin-bottom: ${settings.Spacing.Spacing_50};

  > .ant-collapse-item {
    overflow: initial;

    .ant-collapse-header {
      height: ${settings.Spacing.Spacing_500};
    }
  }
`;

const H3 = styled.h3`
  ${settings.typography.fonts.h_m};
  margin-bottom: ${settings.Spacing.Spacing_150};
`;
