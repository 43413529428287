import { i18nReact, Trans } from '@rhim/i18n';
import React, { useEffect } from 'react';

import { Hyperlink } from '../Hyperlink';
import { Notification } from '../Notification';

interface Props {
  shouldActivate: boolean;
  setShouldActivate: (active: boolean) => void;
}

const CookieNoticeComponent: React.FC<React.PropsWithChildren<Props>> = ({ shouldActivate, setShouldActivate }) => {
  const { t } = i18nReact.useTranslation(['cookies']);

  // Note: the rendering of the cookie notice is done within this side effect as a notification, not within the actual render of the component
  useEffect(() => {
    if (shouldActivate) {
      Notification.open({
        key: 'cookie-banner',
        message: t('cookies:title'),
        type: 'info',
        description: (
          <Trans i18nKey="cookies:description">
            By clicking accept you agree to our use of functional cookies as stated in our <Hyperlink href="/terms-of-use">Terms&nbsp;of&nbsp;Use</Hyperlink>.
          </Trans>
        ),
        userAcknowledgementButtonLabel: t('cookies:buttonLabel'),
        onCloseCallback: () => {
          setShouldActivate(false);
        },
      });
    }
  }, [shouldActivate, setShouldActivate, t]);

  return null;
};

CookieNoticeComponent.whyDidYouRender = true;

/**
 * Displays the cookie notification.
 * Required only after first render.
 * Note: you might want to work with a persisted state for shouldActivate so that the information is maintained between application reloads. Suggested npm package: @see use-persisted-state
 */
export default React.memo(CookieNoticeComponent);
