import { DynamicTexture, Scene } from '@babylonjs/core';

import { TextureCoordinatesIndex } from './materials';

export interface Region {
  name: string;
  angleStart: number;
  angleEnd: number;
  yTop: number;
  yBottom: number;
  color: string;
  isClicked?: boolean;
  isWidget?: boolean;
  shouldRotateText?: boolean;
}

export const REGIONS_DOMAIN_HEIGHT_MAX = 100;
const DYNAMIC_TEXTURE_WIDTH = 2048;
const DYNAMIC_TEXTURE_HEIGHT = 2048;
const DYNAMIC_TEXTURE_SIDE_PART_HEIGHT = DYNAMIC_TEXTURE_HEIGHT / 2;
const REGION_LINE_WIDTH = 1; // width in pixels

/**
 * Create a transparent canvas with highlighted regions and its names
 *
 * @param scene
 * @param regions
 * @returns
 */
export const createTextureWithRegions = (scene: Scene, regions: Region[]): DynamicTexture => {
  const dynamicTexture = new DynamicTexture('dynamicTexture', { width: DYNAMIC_TEXTURE_WIDTH, height: DYNAMIC_TEXTURE_HEIGHT }, scene);
  dynamicTexture.coordinatesIndex = TextureCoordinatesIndex.CylindricalCoordinates;
  const dynamicTextureContext = dynamicTexture.getContext();

  // clear canvas
  dynamicTextureContext.fillStyle = 'transparent';
  dynamicTextureContext.fillRect(0, 0, DYNAMIC_TEXTURE_WIDTH, DYNAMIC_TEXTURE_SIDE_PART_HEIGHT);

  for (const region of regions) {
    const doesAreaWrap = region.angleStart > region.angleEnd;
    dynamicTextureContext.fillStyle = region.color;
    dynamicTextureContext.strokeStyle = region.color;
    dynamicTextureContext.lineWidth = REGION_LINE_WIDTH;
    const startX = regionAngleToCanvasX(region.angleStart);
    const startY = Math.max(regionYToCanvasY(region.yTop), REGION_LINE_WIDTH + 1);
    const bottomY = regionYToCanvasY(region.yBottom);
    if (doesAreaWrap) {
      // create a shape that "wraps" around the 360 degree mark
      dynamicTextureContext.beginPath();
      dynamicTextureContext.moveTo(startX, startY);
      dynamicTextureContext.lineTo(regionAngleToCanvasX(360), startY);
      dynamicTextureContext.stroke();
      dynamicTextureContext.moveTo(0, startY);
      dynamicTextureContext.lineTo(regionAngleToCanvasX(region.angleEnd), startY);
      dynamicTextureContext.lineTo(regionAngleToCanvasX(region.angleEnd), bottomY);
      dynamicTextureContext.lineTo(0, bottomY);
      dynamicTextureContext.stroke();
      dynamicTextureContext.moveTo(regionAngleToCanvasX(360), bottomY);
      dynamicTextureContext.lineTo(startX, bottomY);
      dynamicTextureContext.lineTo(startX, startY);
      dynamicTextureContext.stroke();
    } else {
      dynamicTextureContext.strokeRect(
        startX,
        startY,
        regionAngleToCanvasX(region.angleEnd - region.angleStart),
        regionYToCanvasY(region.yBottom - region.yTop)
      );
    }
    dynamicTextureContext.clearRect(0, DYNAMIC_TEXTURE_SIDE_PART_HEIGHT, DYNAMIC_TEXTURE_WIDTH, DYNAMIC_TEXTURE_SIDE_PART_HEIGHT);
    //displayRegionName(dynamicTexture, region);
  }
  // update dynamic texture
  dynamicTexture.update();

  return dynamicTexture;
};

/*
const REGION_NAME_FONT = 'sans-serif';
const IDEAL_FONT_SIZE_PX = 26;
const MAX_LINES_COUNT = 3;
const ROWS_DISTANCE_FONT_PERCENTAGE = 0.3; // percentage of font size
const REGION_LABEL_PADDING_PERCENTAGE = 10; // percentage of the region's size
*/

/*
interface ArrangeResult {
  fontSize: number;
  lines: string[];
  isRotatedText: boolean;
}
*/

/*
function displayRegionName(dynamicTexture: DynamicTexture, region: Region) {
  const ctx = dynamicTexture.getContext() as CanvasRenderingContext2D;
  ctx.textAlign = 'center';
  ctx.textBaseline = 'top';

  const doesAreaWrap = region.angleStart > region.angleEnd;
  let regionWidth = doesAreaWrap
    ? regionAngleToCanvasX(360 - region.angleStart) + regionAngleToCanvasX(region.angleEnd)
    : regionAngleToCanvasX(region.angleEnd) - regionAngleToCanvasX(region.angleStart);
  let regionHeight = regionYToCanvasY(region.yBottom) - regionYToCanvasY(region.yTop);
  regionWidth -= (REGION_LABEL_PADDING_PERCENTAGE * regionWidth) / 100;
  regionHeight -= (REGION_LABEL_PADDING_PERCENTAGE * regionHeight) / 100;

  // try to fit the text hozontally
  const resultsHorizontal = fitTextToBounds(ctx, regionWidth, regionHeight, false, region.name, IDEAL_FONT_SIZE_PX);
  // now try to fit it vertically
  const resultsVertical = fitTextToBounds(ctx, regionHeight, regionWidth, true, region.name, IDEAL_FONT_SIZE_PX);
  // and pick the one that can manage it using the largest fontSize
  const bestResult = resultsHorizontal.fontSize >= resultsVertical.fontSize ? resultsHorizontal : resultsVertical;
  drawRegionName(ctx, bestResult.fontSize, region, bestResult.lines, bestResult.isRotatedText);
}
*/

/**
 * Attempts to fit a text using given constraints ( area size and fontSize ) and break it into multiple lines if it has to.
 * If the text does not fit, it will try again recursively reducing the fontSize to use by 1 each time.
 * @param ctx canvas context
 * @param width the area width in pixels to fit the text into
 * @param height the area height in pixels to fit the text into
 * @param isRotatedText whether the text would fit horizontally or vertically
 * @param label the text to fit
 * @param fontSize the fontSize to try
 * @returns the result of the operation : the text split into lines, the fontSize used and whether this was a horizontal or vertical placement attempt
 */
/*
function fitTextToBounds(ctx: CanvasRenderingContext2D, width: number, height: number, isRotatedText: boolean, label: string, fontSize: number): ArrangeResult {
  const words = label.split(' ');
  let line = '';
  const lines: string[] = [];
  ctx.font = `${fontSize}px ${REGION_NAME_FONT}`;
  let maxWordWidth = 0;
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    assert(isDefined(word));
    const wordMetrics = ctx.measureText(word);
    maxWordWidth = Math.max(maxWordWidth, wordMetrics.width);
    const testLine = line + word + ' ';
    const testLineMetrics = ctx.measureText(testLine);
    if (testLineMetrics.width > width) {
      lines.push(line);
      line = word + ' ';
    } else {
      line = testLine;
    }
  }
  lines.push(line);

  const linesHeight = lines.length * (fontSize + fontSize * ROWS_DISTANCE_FONT_PERCENTAGE);
  const haveExceededAvailableWidth = maxWordWidth > width;
  const haveExceededAvailableHeight = linesHeight > height;
  const haveExceededMaximumAllowedLines = lines.length > MAX_LINES_COUNT;

  if (haveExceededMaximumAllowedLines || haveExceededAvailableWidth || haveExceededAvailableHeight) {
    // text does not fit with given constraints, try again with a lower font size
    return fitTextToBounds(ctx, width, height, isRotatedText, label, fontSize - 1);
  }
  return { fontSize, lines, isRotatedText };
}
*/

/*
function drawRegionName(ctx: CanvasRenderingContext2D, fontSize: number, region: Region, regionNameLines: string[], isRotated: boolean) {
  const doesAreaWrap = region.angleStart > region.angleEnd;
  const regionCenterAngle = doesAreaWrap
    ? region.angleStart + (360 + region.angleEnd - region.angleStart) / 2
    : region.angleStart + (region.angleEnd - region.angleStart) / 2;

  const regionCenterX = regionAngleToCanvasX(regionCenterAngle);
  const regionCenterY = regionYToCanvasY(region.yTop + (region.yBottom - region.yTop) / 2);

  const textboxTopX = regionCenterX - (regionNameLines.length * fontSize + (regionNameLines.length - 1) * (fontSize * ROWS_DISTANCE_FONT_PERCENTAGE)) / 2;
  const textboxTopY = regionCenterY - (regionNameLines.length * fontSize + (regionNameLines.length - 1) * (fontSize * ROWS_DISTANCE_FONT_PERCENTAGE)) / 2;

  for (const [index, regionNameLine] of regionNameLines.entries()) {
    paintText(ctx, regionNameLine, fontSize, index, isRotated, regionCenterX, regionCenterY, textboxTopX, textboxTopY);
    if (doesAreaWrap) {
      paintText(
        ctx,
        regionNameLine,
        fontSize,
        index,
        isRotated,
        regionAngleToCanvasX(regionCenterAngle - 360),
        regionCenterY,
        -(regionNameLines.length * fontSize + (regionNameLines.length - 1) * (fontSize * ROWS_DISTANCE_FONT_PERCENTAGE)) / 2,
        textboxTopY
      );
    }
  }
}
*/

/*
function paintText(
  ctx: CanvasRenderingContext2D,
  text: string,
  fontSize: number,
  lineIndex: number,
  isRotated: boolean,
  regionCenterX: number,
  regionCenterY: number,
  textboxTopX: number,
  textboxTopY: number
) {
  ctx.font = `${fontSize}px ${REGION_NAME_FONT}`;

  if (isRotated) {
    const textboxStartX = textboxTopX + lineIndex * (fontSize + fontSize * ROWS_DISTANCE_FONT_PERCENTAGE);
    const textboxStartY = regionCenterY;
    ctx.save();
    ctx.translate(textboxStartX, textboxStartY);
    ctx.rotate(-Math.PI / 2);
    ctx.fillText(text, 0, 0);
    ctx.restore();
  } else {
    const textboxStartY = textboxTopY + lineIndex * (fontSize + fontSize * ROWS_DISTANCE_FONT_PERCENTAGE);
    ctx.fillText(text, regionCenterX, textboxStartY);
  }
}
*/

const regionAngleToCanvasX = (regionAngle: number): number => {
  return Math.floor((DYNAMIC_TEXTURE_WIDTH * regionAngle) / 360);
};

const regionYToCanvasY = (regionY: number): number => {
  return Math.floor((DYNAMIC_TEXTURE_SIDE_PART_HEIGHT * regionY) / REGIONS_DOMAIN_HEIGHT_MAX);
};
