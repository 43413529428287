import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const VesselSlagSpotIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.129 6.034a1 1 0 0 0-.97.263l-.81.82a1 1 0 0 0-.255.964l.3 1.11a1 1 0 0 0 .705.704l1.11.3a1 1 0 0 0 .968-.258l.82-.82a1 1 0 0 0 .258-.968l-.3-1.11a1 1 0 0 0-.707-.705l-1.12-.3zm-9.6 6.083a1 1 0 0 1 1.353.413L6.26 17H17.74l2.378-4.47a1 1 0 1 1 1.765.94l-2.66 5a1 1 0 0 1-.883.53H5.66a1 1 0 0 1-.882-.53l-2.66-5a1 1 0 0 1 .413-1.353zm11.451-.927a1 1 0 0 0-.867.502l-.7 1.22a1 1 0 0 0 0 .996l.7 1.22a1 1 0 0 0 .867.502h1.41a1 1 0 0 0 .868-.502l.7-1.22a1 1 0 0 0 0-.996l-.7-1.22a1 1 0 0 0-.868-.502h-1.41zm-4.393-.567a1 1 0 0 0-.97-.258l-1.25.34a1 1 0 0 0-.704.71l-.33 1.25a1 1 0 0 0 .26.962l.91.91a1 1 0 0 0 .962.26l1.25-.33a1 1 0 0 0 .71-.705l.34-1.25a1 1 0 0 0-.258-.97l-.92-.92z"
      />
    </svg>
  );
};

VesselSlagSpotIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
