import { settings } from '@rhim/design';
import { Property } from 'csstype';
import styled, { css } from 'styled-components';

export const PrimaryLabel = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: ${settings.Spacing.Spacing_100};
`;

export const CampaignNamePlaceholder = styled.div`
  background-color: ${settings.colors.Primary.Grey_6};
  height: 22px;
  width: 140px;
  margin-left: ${settings.Spacing.Spacing_100};
`;

interface ItemRowColorsProps {
  backgroundColor: Property.Color;
  color: Property.Color;
  fill: Property.Color;
}

interface ItemRowColorInfo {
  rowPrimaryInfo: ItemRowColorsProps;
  rowSecondaryInfo: ItemRowColorsProps;
}

export const getItemRowColors = ({ isDisabled = false, isSelected = false, isRejected = false, isHover = false }): ItemRowColorInfo => {
  const itemRowColorInfo: ItemRowColorInfo = {
    rowPrimaryInfo: {
      backgroundColor: (() => {
        switch (true) {
          case isSelected:
            return settings.colors.Primary.Blue_9;
          case isHover && !isDisabled:
            return settings.colors.Primary.Grey_2;
          default:
            return 'inherit';
        }
      })(),
      color: (() => {
        switch (true) {
          case isDisabled:
            return settings.colors.Primary.Grey_4;
          case isRejected:
            return settings.colors.Primary.Grey_6;
          case isSelected:
            return settings.colors.Monochromatic.White;
          default:
            return settings.colors.Primary.Grey_8;
        }
      })(),
      fill: (() => {
        switch (true) {
          case isDisabled:
            return settings.colors.Primary.Grey_4;
          case isRejected:
            return settings.colors.Primary.Grey_6;
          case isSelected:
            return settings.colors.Monochromatic.White;
          default:
            return settings.colors.Primary.Grey_8;
        }
      })(),
    },
    rowSecondaryInfo: {
      backgroundColor: (() => {
        switch (true) {
          case isSelected:
            return settings.colors.Primary.Blue_9;
          case isHover && !isDisabled:
            return settings.colors.Primary.Grey_2;
          default:
            return 'inherit';
        }
      })(),
      color: isDisabled ? settings.colors.Primary.Grey_4 : settings.colors.Primary.Grey_6,
      fill: isDisabled ? settings.colors.Primary.Grey_4 : settings.colors.Primary.Grey_6,
    },
  };

  return itemRowColorInfo;
};

export const ItemRow = styled.tr<{ variant: 'medium-48' | 'small-40'; isDisabled?: boolean }>`
  height: ${(props) => (props.variant === 'medium-48' ? 48 : 40)}px;
  cursor: ${(props) => (props.isDisabled === true ? 'default' : 'pointer')};

  .td {
    vertical-align: middle;
  }
`;

export const CellWrapper = styled.div`
  padding: 0 ${settings.Spacing.Spacing_200};
  display: flex;
  align-items: center;
`;

export const PrimaryInfo = styled.div`
  display: flex;
  align-items: center;
  font-family: ${settings.typography.FontFamily.Medium};
  font-size: ${settings.typography.FontSize.Small};
`;

export const SecondaryInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.Small};
  color: ${settings.colors.Primary.Grey_6};
`;

export const HeatLineConnector = styled.div<{ isConnectingAbove: boolean }>`
  position: absolute;
  width: 2px;
  background-color: ${settings.colors.Primary.Grey_4};
  left: 7px;
  height: 10px;

  --yMargin: 0;

  ${(props) =>
    props.isConnectingAbove &&
    css`
      top: var(--yMargin);
    `}

  ${(props) =>
    !props.isConnectingAbove &&
    css`
      bottom: var(--yMargin);
    `}
`;
