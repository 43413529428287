import { isDefined } from '@rhim/utils';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useMeasure } from 'react-use';

interface GetLeftInBoundariesProps {
  isLTR?: boolean;
  labelWidth: number;
  scrollLeft: number;
  wrapperWidth: number;
  x: number;
}

export const getLeftInBoundaries = (props: GetLeftInBoundariesProps) => {
  const { isLTR = true, labelWidth, scrollLeft, wrapperWidth, x } = props;

  const offsetLeft = x - scrollLeft - labelWidth / 2;
  const leftBoundary = Math.min(Math.max(0, offsetLeft), wrapperWidth - labelWidth);

  return isLTR ? leftBoundary : wrapperWidth - leftBoundary - labelWidth;
};

interface UseLeftInBoundariesProps {
  scrollbarRef?: React.RefObject<Scrollbars>;
  x: number;
}

export const useLeftInBoundaries = (props: UseLeftInBoundariesProps) => {
  const { scrollbarRef, x } = props;

  const [labelRef, { left: labelLeft, right: labelRight }] = useMeasure<HTMLDivElement>();
  const [wrapperRef, { width: wrapperWidth }] = useMeasure<HTMLDivElement>();

  const scrollbar = scrollbarRef?.current;
  const scrollLeft = isDefined(scrollbar) ? scrollbar.getScrollLeft() : 0;

  const labelWidth = labelLeft + labelRight;

  const left =
    labelWidth > 0 && wrapperWidth > 0
      ? getLeftInBoundaries({
          labelWidth,
          scrollLeft,
          wrapperWidth,
          x,
        })
      : undefined;

  return {
    labelRef,
    wrapperRef,
    left,
  };
};
