import { settings } from '@rhim/design';
import { getBrowserLocale, i18nReact, SUPPORTED_LANGUAGES, SupportedLanguageIsoCode } from '@rhim/i18n';
import { ArrowLeftIcon } from '@rhim/icons/16';
import { assert, ensure, isDefined } from '@rhim/utils';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { DecimalSeparator, LanguageDirection, UnitSystem } from 'typings';

import { useUserSettings } from '../../hooks';
import { Radio, RadioChangeEvent, Space, SpaceDirection } from '../Radio';

export type AppConfig = {
  decimalSeparator: boolean;
  language: SupportedLanguageIsoCode[];
  languageDirection: boolean;
  unitSystem: boolean;
};

type SupportedLanguage = keyof typeof import('assets/locales/en/userSettingsPage.json')['userSettingsPage']['supportedLanguages'];
type SupportedLanguageDirections = keyof typeof import('assets/locales/en/userSettingsPage.json')['userSettingsPage']['languageDirections'];
type SupportedUnitSystems = keyof typeof import('assets/locales/en/userSettingsPage.json')['userSettingsPage']['unitSystems'];
type SupportedDecimalSeparators = keyof typeof import('assets/locales/en/userSettingsPage.json')['userSettingsPage']['decimalSeparators'];
interface SupportedLanguageWithTranslationKey {
  language: SupportedLanguageIsoCode;
  translationKey: `userSettingsPage:userSettingsPage.supportedLanguages.${SupportedLanguage}`;
}

const SUPPORTED_LANGUAGES_WITH_TRANSLATION_KEYS: Partial<Record<SupportedLanguageIsoCode, SupportedLanguageWithTranslationKey>> = {
  en: { language: 'en', translationKey: 'userSettingsPage:userSettingsPage.supportedLanguages.english' },
  de: { language: 'de', translationKey: 'userSettingsPage:userSettingsPage.supportedLanguages.german' },
  pt_BR: { language: 'pt_BR', translationKey: 'userSettingsPage:userSettingsPage.supportedLanguages.portuguese' },
};

// Ensure that all supported languages have their translation key set
const languagesWithUnsetTranslationKeys = SUPPORTED_LANGUAGES.filter(
  (supportedLanguage) =>
    !isDefined(
      Object.values(SUPPORTED_LANGUAGES_WITH_TRANSLATION_KEYS).find(
        (supportedLanguageWithTranslationKey) => supportedLanguageWithTranslationKey.language === supportedLanguage
      )
    )
);
assert(languagesWithUnsetTranslationKeys.length === 0, `The following languages have no translation key set : ${languagesWithUnsetTranslationKeys}`);

interface UnitSystemItem {
  unitSystem: UnitSystem;
  UILabel: string;
  translationKey: `userSettingsPage:userSettingsPage.unitSystems.${SupportedUnitSystems}`;
}
export const UNIT_SYSTEMS: UnitSystemItem[] = [
  { unitSystem: 'metric', UILabel: 'Metric (mm, kg,..)', translationKey: 'userSettingsPage:userSettingsPage.unitSystems.metric' },
  { unitSystem: 'imperial', UILabel: 'Imperial (inch, pounds,..)', translationKey: 'userSettingsPage:userSettingsPage.unitSystems.imperial' },
];

interface DecimalSeparatorItem {
  decimalSeparator: DecimalSeparator;
  translationKey: `userSettingsPage:userSettingsPage.decimalSeparators.${SupportedDecimalSeparators}`;
}
export const DECIMAL_SEPARATORS: DecimalSeparatorItem[] = [
  { decimalSeparator: 'comma', translationKey: 'userSettingsPage:userSettingsPage.decimalSeparators.comma' },
  { decimalSeparator: 'point', translationKey: 'userSettingsPage:userSettingsPage.decimalSeparators.point' },
];

interface LanguageDirectionItem {
  languageDirection: LanguageDirection;
  translationKey: `userSettingsPage:userSettingsPage.languageDirections.${SupportedLanguageDirections}`;
}
export const LANGUAGE_DIRECTIONS: LanguageDirectionItem[] = [
  { languageDirection: 'ltr', translationKey: 'userSettingsPage:userSettingsPage.languageDirections.ltr' },
  { languageDirection: 'rtl', translationKey: 'userSettingsPage:userSettingsPage.languageDirections.rtl' },
];

interface UserSettingsProps {
  appConfig: AppConfig;
}

const UserSettings: FC<React.PropsWithChildren<UserSettingsProps>> = ({ appConfig }) => {
  const { t } = i18nReact.useTranslation(['userSettingsPage']);
  const { userSettings, saveSettings } = useUserSettings();
  const navigate = useNavigate();
  const browserLocale = getBrowserLocale();

  const onBackBtnClick = () => {
    navigate(-1);
  };

  const { decimalSeparator, languageDirection, locale, unitSystem } = userSettings;

  const handleChange = (e: RadioChangeEvent) => {
    const newSettings = { ...userSettings, [ensure(e.target.name)]: e.target.value };
    saveSettings(newSettings);
  };

  return (
    <SWrapper>
      <SContainer>
        <SHeader>
          <ArrowButton onClick={onBackBtnClick}>
            <SArrowIcon />
          </ArrowButton>
          {t('userSettingsPage:userSettingsPage.localisationSettings')}
        </SHeader>
        <SSettingHeader>{t('userSettingsPage:userSettingsPage.language')}</SSettingHeader>
        <Radio.Group name="locale" onChange={handleChange} value={locale}>
          <Space direction={SpaceDirection.Vertical}>
            {appConfig.language.map((supportedLanguage) => {
              const config = ensure(SUPPORTED_LANGUAGES_WITH_TRANSLATION_KEYS[supportedLanguage]);

              return (
                <SRadio key={config.language} value={config.language}>
                  {`${t(config.translationKey)}${
                    browserLocale.startsWith(config.language.slice(0, 2)) ? ' (' + t('userSettingsPage:userSettingsPage.browserLanguage') + ')' : ''
                  }`}
                </SRadio>
              );
            })}
          </Space>
        </Radio.Group>
        {appConfig.languageDirection && (
          <>
            <SSettingHeader>{t('userSettingsPage:userSettingsPage.languageDirection')}</SSettingHeader>
            <Radio.Group name="languageDirection" onChange={handleChange} value={languageDirection}>
              <Space direction={SpaceDirection.Vertical}>
                {LANGUAGE_DIRECTIONS.map((direction) => (
                  <SRadio key={direction.languageDirection} value={direction.languageDirection}>
                    {t(direction.translationKey)}
                  </SRadio>
                ))}
              </Space>
            </Radio.Group>
          </>
        )}
        {appConfig.unitSystem && (
          <>
            <SSettingHeader>{t('userSettingsPage:userSettingsPage.units')}</SSettingHeader>
            <Radio.Group name="unitSystem" onChange={handleChange} value={unitSystem}>
              <Space direction={SpaceDirection.Vertical}>
                {UNIT_SYSTEMS.map((system) => (
                  <SRadio key={system.unitSystem} value={system.unitSystem}>
                    {t(system.translationKey)}
                  </SRadio>
                ))}
              </Space>
            </Radio.Group>
          </>
        )}
        {appConfig.decimalSeparator && (
          <>
            <SSettingHeader>{t('userSettingsPage:userSettingsPage.decimalSeparator')}</SSettingHeader>
            <Radio.Group name="decimalSeparator" onChange={handleChange} value={decimalSeparator}>
              <Space direction={SpaceDirection.Vertical}>
                {DECIMAL_SEPARATORS.map((separator) => (
                  <SRadio key={separator.decimalSeparator} value={separator.decimalSeparator}>
                    {t(separator.translationKey)}
                  </SRadio>
                ))}
              </Space>
            </Radio.Group>
          </>
        )}
      </SContainer>
    </SWrapper>
  );
};

export default UserSettings;

const ArrowIcon = styled(ArrowLeftIcon).attrs(() => ({
  fill: settings.colors.Primary.Blue_9,
}))`
  cursor: pointer;
`;

const ArrowButton = styled.button`
  position: absolute;
  left: -40px;
  background-color: transparent;
  border: none;
  padding: 0;
`;

const SWrapper = styled.div`
  background-color: ${settings.colors.Primary.Grey_1};
  display: flex;
`;

const SArrowIcon = styled(ArrowIcon)`
  display: flex;
`;

const SContainer = styled.div`
  padding: ${settings.Spacing.Spacing_700} ${settings.Spacing.Spacing_800};
  width: 100%;
`;

const SRadio = styled(Radio)`
  margin-bottom: ${settings.Spacing.Spacing_100};
`;

const SHeader = styled.div`
  color: ${settings.colors.Primary.Grey_8};
  font-family: ${settings.typography.FontFamily.Bold};
  font-size: ${settings.typography.FontSize.XX_Large};
  line-height: ${settings.typography.LineHeight.Line_Height_40};
  border-bottom: 1px solid ${settings.colors.Primary.Grey_4};
  padding-bottom: ${settings.Spacing.Spacing_400};
  position: relative;
  display: flex;
  align-items: center;
`;

const SSettingHeader = styled.div`
  margin-top: ${settings.Spacing.Spacing_300};
  margin-bottom: ${settings.Spacing.Spacing_200};
  color: ${settings.colors.Primary.Grey_8};
  font-family: ${settings.typography.FontFamily.Bold};
  font-size: ${settings.typography.FontSize.Large};
`;
