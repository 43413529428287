/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { dot, transposeMatrixNxM } from './mathUtils';

/**
 * Singular Value Decomposition
 * The code is taken from https://jamesmccaffrey.wordpress.com/2023/11/21/matrix-singular-value-decomposition-svd-from-scratch-using-javascript/
 * and slightly modified
 * As far as I found it is the closest solution to the one used in numpy.linalg.svd
 *
 * @param M
 * @param epsilon
 * @param tol
 */
export function svdJacobi(M: Array<Array<number>>, epsilon = Math.pow(2, -52), tol = 1e-64) {
  const A = matCopy(M); // working U
  const m = A.length;
  const n = A[0]!.length;
  const Q = matIdentity(n); // working V
  const t = new Array(n).fill(1); // working s

  // intialize the rotation and sweep counters
  let count = 1;
  let sweep = 0;

  const tolerance = tol / epsilon;
  // const tolerance = 10 * m * epsilon;

  // always do at least 12 sweeps
  const sweepMax = Math.max(5 * n, 12); // heuristic

  // store the column error estimates in t, for use
  // during orthogonalization

  for (let j = 0; j < n; ++j) {
    const cj = matGetColumn(A, j);
    const sj = vecNorm(cj);
    t[j] = epsilon * sj;
  }

  // orthogonalize A by plane rotations
  while (count > 0 && sweep <= sweepMax) {
    // initialize rotation counter
    count = (n * (n - 1)) / 2;

    for (let j = 0; j < n - 1; ++j) {
      for (let k = j + 1; k < n; ++k) {
        let cosine = 0;
        let sine = 0;

        const cj = matGetColumn(A, j);
        const ck = matGetColumn(A, k);

        const p = 2.0 * dot(cj, ck);
        const a = vecNorm(cj);
        const b = vecNorm(ck);

        const q = a * a - b * b;
        const v = hypot(p, q);

        // test for columns j,k orthogonal,
        // or dominant errors
        const abserr_a = t[j];
        const abserr_b = t[k];

        const sorted = a >= b;
        const orthog = Math.abs(p) <= tolerance * (a * b);
        const noisya = a < abserr_a;
        const noisyb = b < abserr_b;

        if (sorted && (orthog || noisya || noisyb)) {
          --count;
          continue;
        }

        // calculate rotation angles
        if (v === 0 || !sorted) {
          cosine = 0;
          sine = 1.0;
        } else {
          cosine = Math.sqrt((v + q) / (2.0 * v));
          sine = p / (2.0 * v * cosine);
        }

        // apply rotation to A (U)
        for (let i = 0; i < m; ++i) {
          const Aik = A[i]![k]!;
          const Aij = A[i]![j]!;
          A[i]![j] = Aij * cosine + Aik * sine;
          A[i]![k] = -Aij * sine + Aik * cosine;
        }

        // update singular values
        t[j] = Math.abs(cosine) * abserr_a + Math.abs(sine) * abserr_b;
        t[k] = Math.abs(sine) * abserr_a + Math.abs(cosine) * abserr_b;

        // apply rotation to Q (Vh)
        for (let i = 0; i < n; ++i) {
          const Qij = Q[i]![j]!;
          const Qik = Q[i]![k]!;
          Q[i]![j] = Qij * cosine + Qik * sine;
          Q[i]![k] = -Qij * sine + Qik * cosine;
        } // i
      } // k
    } // j

    ++sweep;
  } // while

  //  compute singular values
  let prevNorm = -1.0;

  for (let j = 0; j < n; ++j) {
    const column = matGetColumn(A, j);
    const norm = vecNorm(column);

    // determine if singular value is zero
    if (norm === 0 || prevNorm === 0 || (j > 0 && norm <= tolerance * prevNorm)) {
      t[j] = 0;
      for (let i = 0; i < m; ++i) A[i]![j] = 0;
      prevNorm = 0;
    } else {
      t[j] = norm;
      for (let i = 0; i < m; ++i) {
        A[i]![j] = A[i]![j]! / norm;
      }
      prevNorm = norm;
    }
  }

  let U = A;
  let s = t;
  let Vh = transposeMatrixNxM(Q);

  if (m < n) {
    U = matExtractFirstColumns(U, m);
    s = s.slice(0, m);
    Vh = matExtractFirstRows(Vh, m);
  }

  return { U, s, Vh };
}

function matMake(rows: number, cols: number, val = 0) {
  return new Array(rows).fill(0).map(() => new Array(cols).fill(val));
}

// ----------------------------------------------------------

function matCopy(m: Array<Array<number>>) {
  return m.map((row) => [...row]);
}

// ----------------------------------------------------------

function matIdentity(n: number): Array<Array<number>> {
  const result = matMake(n, n, 0);
  for (let i = 0; i < n; ++i) result[i]![i] = 1;
  return result;
}

function matGetColumn(m: Array<Array<number>>, j: number): number[] {
  const rows = m.length;
  const result = vecMake(rows, 0);
  for (let i = 0; i < rows; ++i) result[i] = m[i]![j];
  return result;
}

// ----------------------------------------------------------

function matExtractFirstColumns(src: Array<Array<number>>, n: number) {
  const nRows = src.length;

  const result = matMake(nRows, n, 0);
  for (let i = 0; i < nRows; ++i) for (let j = 0; j < n; ++j) result[i]![j] = src[i]![j];
  return result;
}

// ----------------------------------------------------------

function matExtractFirstRows(src: Array<Array<number>>, n: number) {
  const nCols = src[0]!.length;
  const result = matMake(n, nCols);
  for (let i = 0; i < n; ++i) {
    for (let j = 0; j < nCols; ++j) {
      result[i]![j] = src[i]![j];
    }
  }
  return result;
}

// ----------------------------------------------------------

function vecMake(n: number, val = 0) {
  return new Array(n).fill(val);
}

// ----------------------------------------------------------

function vecNorm(vec: number[]) {
  const squareProd = vec.reduce((acc, val) => acc + val ** 2, 0);
  return Math.sqrt(squareProd);
}

// ----------------------------------------------------------

function hypot(a: number, b: number): number {
  // wacky sqrt(a^2 + b^2) used by most SVD implementations
  const xabs = Math.abs(a);
  const yabs = Math.abs(b);
  const min = Math.min(xabs, yabs);
  const max = Math.max(xabs, yabs);

  if (min === 0) {
    return max;
  } else {
    const u = min / max;
    return max * Math.sqrt(1 + u ** 2);
  }
}
/* eslint-enable @typescript-eslint/no-non-null-assertion */
