import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const XIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M1.293 1.293c.39-.39 1.024-.39 1.414 0L8 6.585l5.293-5.292c.36-.36.928-.388 1.32-.083l.094.083c.39.39.39 1.024 0 1.414L9.415 8l5.292 5.293c.36.36.388.928.083 1.32l-.083.094c-.39.39-1.024.39-1.414 0L8 9.415l-5.293 5.292c-.36.36-.928.388-1.32.083l-.094-.083c-.39-.39-.39-1.024 0-1.414L6.585 8 1.293 2.707c-.36-.36-.388-.928-.083-1.32z"
      />
    </svg>
  );
};

XIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
