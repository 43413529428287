import config from '@rhim/config';
import { AuthInfo, createAuthenticationLoader, getCurrentUserId } from '@rhim/react';
import { RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto } from '@rhim/rest/customerManagement';
import { hasElements, isDefined, PLACEHOLDER } from '@rhim/utils';
import { LoaderFunction, LoaderFunctionArgs } from 'react-router-dom';

import { environment } from '../../environments/environment';
import { useUserInfo } from '../../hooks/useUserInfo';
import { authClient, LogRocket } from '../../utilities';
import { queryClient } from '../../utilities/queryClient';

/**
 * Used to identify the route in the useRouteLoaderData hook.
 */
export enum RouteId {
  Root = 'root',
}

export interface Tenant {
  account: AuthInfo;
  user: RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto;
  userId: UUID;
}

declare module 'react-router-dom' {
  // Overload the useRouteLoaderData hook to return the correct type depending on the routeId
  // eslint-disable-next-line @typescript-eslint/no-shadow
  export function useRouteLoaderData(routeId: RouteId.Root): Tenant;
}

export const authenticationLoader = createAuthenticationLoader({
  authClient,
  passwordResetPolicy: environment.passwordResetPolicy !== undefined ? environment.authority + environment.passwordResetPolicy : undefined,
});

interface TenantLoaderFunction extends LoaderFunction {
  (args: LoaderFunctionArgs): Promise<Tenant>;
}

export const getUser = (userId: UUID) => queryClient.fetchQuery(useUserInfo.getKey(userId), useUserInfo.queryFn);

export const tenantLoader: TenantLoaderFunction = async (args) => {
  const { account } = await authenticationLoader(args);
  const userId = getCurrentUserId(account);
  const user = await getUser(userId);

  // once the user is logged in, we activate user tracking (if the environment specifies a LogRocket ID, otherwise we skip tracking for this env)
  if (environment.logRocketId !== PLACEHOLDER) {
    LogRocket.init(environment.logRocketId, {
      release: config.version,
    });

    // associate the current session with the user
    LogRocket.identify(account.localAccountId, {
      name: account.name,
      homeAccountId: account.homeAccountId,
      tenantId: account.tenantId,
    });
  }

  if (!isDefined(user.customers) || !hasElements(user.customers)) {
    throw new Response('User has no customers', { status: 412 });
  }

  return {
    account,
    user,
    userId,
  };
};
