import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  backgroundColor?: Property.Color;
  foregroundColor?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const HexagonMeasurement2Icon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  backgroundColor,
  foregroundColor,
  size,
  title,
  role,
  ...rest
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    fill={backgroundColor}
                    d="M27.03 24.697c.533-.31.97-1.074.97-1.695V8.952c0-.62-.437-1.384-.97-1.694L14.97.233c-.533-.31-1.407-.31-1.94 0L.97 7.258C.437 7.568 0 8.331 0 8.953v14.049c0 .621.437 1.384.97 1.695l12.06 7.024c.533.31 1.407.31 1.94 0l12.06-7.024z"
                    transform="translate(-113 -350) translate(111 348) translate(2 2) translate(2)"
                  />
                  <g fill={foregroundColor}>
                    <path
                      d="M6 0c1.103 0 2 .897 2 2v12c0 1.103-.897 2-2 2H2c-1.103 0-2-.897-2-2V2C0 .897.897 0 2 0zm6.293.293c.391-.391 1.023-.391 1.414 0l2 2c.391.391.391 1.023 0 1.414-.195.195-.451.293-.707.293-.256 0-.512-.098-.707-.293L14 3.414v9.172l.293-.293c.391-.391 1.023-.391 1.414 0s.391 1.023 0 1.414l-2 2c-.195.195-.451.293-.707.293-.256 0-.512-.098-.707-.293l-2-2c-.391-.391-.391-1.023 0-1.414s1.023-.391 1.414 0l.293.293V3.414l-.293.293c-.391.391-1.023.391-1.414 0s-.391-1.023 0-1.414zM6 2H2v12h4.001v-1H5c-.552 0-1-.447-1-1 0-.553.448-1 1-1h1.001V9H5c-.552 0-1-.447-1-1 0-.553.448-1 1-1h1V5H5c-.552 0-1-.447-1-1 0-.553.448-1 1-1h1V2z"
                      transform="translate(-113 -350) translate(111 348) translate(2 2) translate(2) translate(6 8)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

HexagonMeasurement2Icon.defaultProps = {
  size: '32',
  backgroundColor: settings.colors.Primary.Grey_8,
  foregroundColor: settings.colors.Primary.Blue_1,
};
