import { RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto } from '@rhim/rest/fleetOverview';
import { isDefined } from '@rhim/utils';
import { bisector } from 'd3-array';
import React, { useCallback } from 'react';

export interface UsePosition {
  handlePosition: (position: number | null) => void;
  position: number | null;
}

export function usePosition(measurements: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto[]): UsePosition {
  const [position, setPosition] = React.useState<number | null>(null);

  const bisect = bisector(function (d: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto) {
    return d.heat;
  }).center;

  const handlePosition = useCallback(
    (_position: number | null) => {
      if (!isDefined(_position)) {
        setPosition(_position);
      } else {
        const index = bisect(measurements, _position);
        const measurement = measurements[index];
        if (isDefined(measurement)) {
          setPosition(measurement.heat);
        }
      }
    },
    [measurements, bisect]
  );

  return {
    handlePosition,
    position,
  };
}
