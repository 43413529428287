import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const ZoomResetIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M11 2a9 9 0 0 1 9 9 8.956 8.956 0 0 1-1.968 5.617l3.675 3.676a.999.999 0 1 1-1.414 1.414l-3.676-3.675A8.956 8.956 0 0 1 11 20a9 9 0 0 1-9-9 9 9 0 0 1 9-9zm0 2a7 7 0 1 0 4.865 12.033l.078-.09a.948.948 0 0 1 .089-.079A7 7 0 0 0 11 4zm-2 6V9h1.5V7H8a1 1 0 0 0-1 1v2h2zm0 2H7v2a1 1 0 0 0 1 1h2.5v-2H9v-1zm2.5 1v2H14a1 1 0 0 0 1-1v-2h-2v1h-1.5zm1.5-3h2V8a1 1 0 0 0-1-1h-2.5v2H13v1z"
      />
    </svg>
  );
};

ZoomResetIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
