import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const PrinterIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <g>
              <path
                d="M12 0c.553 0 1 .447 1 1v3h1c1.103 0 2 .897 2 2v4c0 1.103-.897 2-2 2h-1v3c0 .553-.447 1-1 1H4c-.553 0-1-.447-1-1v-3H2c-1.103 0-2-.897-2-2V6c0-1.103.897-2 2-2h1V1c0-.553.447-1 1-1zm-1 12H5v2h6v-2zm2-4c-.553 0-1 .447-1 1 0 .553.447 1 1 1 .553 0 1-.447 1-1 0-.553-.447-1-1-1zm-2-6H5v3h6V2z"
                transform="translate(-877.000000, -272.000000) translate(861.000000, 178.000000) translate(16.000000, 94.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

PrinterIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_1,
};
