import { isDefined } from '@rhim/utils';
import React from 'react';
import styled from 'styled-components';

import { useScrollToElement } from '../../hooks';
import CampaignSelectionItem from './CampaignSelectionItem';

const NULL_CAMPAIGN_ID = 'NULL_CAMPAIGN_ID';

interface Props {
  campaigns: RHIM.CampaignOrUnassigned[];
  selectedCampaignId?: number | null;
  onCampaignSelected: (campaignId: number | null) => void;
}
const CampaignSelectionDropdown: React.ChildlessComponent<Props> = ({ campaigns, selectedCampaignId, onCampaignSelected }) => {
  const objectsRefs = useScrollToElement<HTMLTableRowElement>(
    campaigns.map((campaign) => (isDefined(campaign.id) ? campaign.id.toString() : NULL_CAMPAIGN_ID)),
    isDefined(selectedCampaignId) ? selectedCampaignId.toString() : undefined
  );

  return (
    <STable cellPadding="0" cellSpacing="0">
      <tbody>
        {campaigns.map((campaign) => (
          <CampaignSelectionItem
            ref={objectsRefs[campaign.id ?? NULL_CAMPAIGN_ID]}
            key={campaign.id}
            campaign={campaign}
            isSelectedCampaign={campaign.id === selectedCampaignId}
            onCampaignClicked={onCampaignSelected}
          />
        ))}
      </tbody>
    </STable>
  );
};

export default React.memo(CampaignSelectionDropdown);

const STable = styled.table`
  width: 100%;
`;
