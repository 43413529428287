import { Color4 } from '@babylonjs/core/Maths/math.color';
import { threeDViewMeasurementView } from '@rhim/test-ids';
import React, { FC } from 'react';
import styled from 'styled-components';

import BabylonScene from './BabylonScene';
import IndicatorRenderLoopFps from './IndicatorRenderLoopFps';

const DEFAULT_CLEAR_COLOR = new Color4(0.961, 0.965, 0.969, 1);

const StyledWrapper = styled.div`
  position: relative;
`;

interface Props {
  className?: string;
  isProductionEnvironment: boolean;
  clearColor?: Color4;
}

const Ladle3dView: FC<React.PropsWithChildren<Props>> = ({
  className,
  isProductionEnvironment,
  // engineId,
  clearColor = DEFAULT_CLEAR_COLOR,
  ...rest
}) => {
  return (
    <StyledWrapper data-test-id={threeDViewMeasurementView} className={className} {...rest}>
      <BabylonScene />
      {!isProductionEnvironment && <IndicatorRenderLoopFps />}
    </StyledWrapper>
  );
};
Ladle3dView.whyDidYouRender = true;
export default React.memo(Ladle3dView);
