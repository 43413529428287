import { settings } from '@rhim/design';
import { assert, isDefined } from '@rhim/utils';
import { CircleClipPath } from '@visx/clip-path';
import { ScaleLinear } from 'd3-scale';
import React from 'react';
import styled from 'styled-components';

import { DataPoint } from '../glyph/DataPoint';
import Line from '../shape/Line';
import { ShapeType, ThemeConfig } from '../theme';
import { DATA_POINT_RADIUS, DATA_POINT_STROKE_WIDTH } from './constants';
import { CartesianCoordinate, TooltipStateRadial } from './types';
import { polarToCartesian } from './utils';

const LINE_PADDING = 10;

interface Props<Datum> {
  angleOffset?: number;
  belowThreshold?: (datum: Datum, key: string) => boolean;
  center: CartesianCoordinate;
  keys: string[];
  radius: number;
  range: number;
  themeConfig: ThemeConfig;
  tooltipState: TooltipStateRadial<Datum>;
  yAccessor: (datum: Datum, key: string, index: number) => number | undefined;
  yScale: ScaleLinear<number, number>;
}

function HoverLineComponent<Datum>(props: Props<Datum>) {
  const { angleOffset = 0, center, keys, themeConfig, tooltipState, belowThreshold, radius, range, yAccessor, yScale } = props;
  const { isHovered, nearestDatum } = tooltipState;

  if (!isHovered || !isDefined(nearestDatum)) {
    return null;
  }

  const dataPointsClipPath = `data-point-radial-clip-zone-${Math.random()}`;
  const dataPointsClipPathUrl = `url(#${dataPointsClipPath})`;

  const angle = nearestDatum.x + angleOffset;
  const fallbackRadius = yScale(range);
  const allKeysRadii = keys.map((key) => {
    return isDefined(nearestDatum) && isDefined(nearestDatum.datum) ? yAccessor(nearestDatum.datum, key, nearestDatum.index) ?? fallbackRadius : 0;
  });
  const smallestRadius = Math.min(...allKeysRadii, fallbackRadius);
  const cartesianLineFrom = polarToCartesian({ angle, radius: smallestRadius - LINE_PADDING });
  const cartesianLineTo = polarToCartesian({ angle, radius: radius + LINE_PADDING });

  return (
    <>
      <HoverLine
        from={{ x: cartesianLineFrom.x + center.x, y: cartesianLineFrom.y + center.y }}
        to={{ x: cartesianLineTo.x + center.x, y: cartesianLineTo.y + center.y }}
      />
      <CircleClipPath id={dataPointsClipPath} r={radius} cx={center.x} cy={center.y} />
      <g clipPath={dataPointsClipPathUrl}>
        {keys.map((key) => {
          const dpRadius = isDefined(nearestDatum) && isDefined(nearestDatum.datum) ? yAccessor(nearestDatum.datum, key, nearestDatum.index) : 0;
          const dpCartesian = polarToCartesian({ angle, radius: dpRadius ?? 0 });
          const dpCoordinates = { x: dpCartesian.x + center.x, y: dpCartesian.y + center.y };
          const config = themeConfig.datasets[key];
          assert(isDefined(config), `theme config not found for key${key}`);

          const isBelowThreshold =
            isDefined(belowThreshold) && isDefined(nearestDatum) && isDefined(nearestDatum.datum) && belowThreshold(nearestDatum.datum, key);
          const strokeColor = 'strokeColor' in config ? config.strokeColor : undefined;
          const strokeColorOrFill = 'strokeColor' in config ? config.strokeColor : 'fill' in config ? config.fill : undefined;
          const fill = isBelowThreshold ? ('strokeColorAlert' in config ? config.strokeColorAlert : strokeColor) : strokeColorOrFill;

          return (
            isDefined(dpRadius) &&
            config.type !== ShapeType.Glyph && (
              <DataPoint
                key={key}
                r={DATA_POINT_RADIUS}
                strokeWidth={DATA_POINT_STROKE_WIDTH}
                stroke={settings.colors.Monochromatic.White}
                fill={fill}
                cx={dpCoordinates.x}
                cy={dpCoordinates.y}
              />
            )
          );
        })}
      </g>
    </>
  );
}

export default React.memo(HoverLineComponent) as typeof HoverLineComponent;

const HoverLine = styled(Line)`
  stroke: ${settings.colors.Primary.Grey_8};
  stroke-width: 2px;
  pointer-events: none;
`;
