import { settings } from '@rhim/design';
import { AnimatePresence, motion } from 'framer-motion';
import React, { FC } from 'react';
import styled from 'styled-components';

const BORDER_WIDTH = '1px';

interface Props {
  className?: string;
  isExpanded: boolean;
  children: React.ReactNode;
}

const DropdownPanel: FC<React.PropsWithChildren<Props>> = (props) => {
  const { className, isExpanded, children } = props;

  return (
    <AnimatePresence>
      {isExpanded && (
        <SWrapper
          className={className}
          initial={{ height: 0, overflowY: 'hidden' }}
          animate={{
            height: 'auto',
            transitionEnd: {
              overflowY: 'auto',
            },
          }}
          exit={{ height: 0, overflowY: 'hidden' }}
          transition={{ ease: 'easeOut', duration: 0.25 }}
        >
          {children}
        </SWrapper>
      )}
    </AnimatePresence>
  );
};

const SWrapper = styled(motion.div)`
  display: flex;
  position: absolute;
  top: 100%;
  left: calc(-1 * ${BORDER_WIDTH});
  width: max-content;
  z-index: 999;
  background-color: white;
  border: ${BORDER_WIDTH} solid ${settings.colors.Primary.Grey_3};
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.05);
`;

DropdownPanel.whyDidYouRender = true;
export default React.memo(DropdownPanel);
