import { isDefined } from '@rhim/utils';
import { useEffect, useRef } from 'react';
import { positionValues } from 'react-custom-scrollbars-2';

import { TooltipPosition, TooltipPositionPrediction, TooltipPositionTempProfile } from './types';
import { ScrollbarRef } from './types';

export function useScrolling({
  hoverPosition,
  scrollbarRef,
  setScrollPositions,
  setHoverPosition,
  width,
}: {
  hoverPosition: TooltipPosition | null;
  scrollbarRef: ScrollbarRef;
  setHoverPosition: (hoverPosition: (TooltipPositionPrediction & TooltipPositionTempProfile) | null) => void;
  setScrollPositions: (scrollPosition: number | null) => void;
  width: number;
}) {
  const userScrollPos = useRef(scrollbarRef.current?.getValues().left ?? 0);

  useEffect(() => {
    const scrollbarRefCur = scrollbarRef.current;
    const scrollWidth = scrollbarRefCur?.getScrollWidth() ?? 0;
    const clientWidth = scrollbarRefCur?.getClientWidth() ?? 0;

    const left = userScrollPos.current * (scrollWidth - clientWidth);

    scrollbarRefCur?.scrollLeft(left);
  }, [scrollbarRef, width]);

  const handleScrollFrame = (values: positionValues) => {
    userScrollPos.current = values.left;

    setScrollPositions(values.scrollLeft);
  };

  const handleScrollStart = () => {
    if (isDefined(hoverPosition)) {
      setHoverPosition(null);
    }
    document.getSelection()?.empty();
  };

  return {
    handleScrollFrame,
    handleScrollStart,
  };
}
