import { settings } from '@rhim/design';
import { assert, DivWithSpinnerWhenEmpty, hasElements, isDefined } from '@rhim/utils';
import * as React from 'react';
import { FallbackProps } from 'react-error-boundary';
import styled from 'styled-components';

import ErrorPanel from '../../components/ErrorPanel/ErrorPanel';
import { usePersistedComparedVessel, useSelectedVessel, useVessels } from '../../hooks';
import ParametersComparisonStrip from './ParametersComparisonStrip';

const ParametersComparisonErrorPage: React.FunctionComponent<React.PropsWithChildren<FallbackProps & { customerId: UUID }>> = ({
  customerId,
  error,
  resetErrorBoundary,
}) => {
  const containerRef = React.useRef<HTMLElement>(document.body);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: vessels } = useVessels(customerId, undefined);

  assert.silent(isDefined(vessels), 'In Suspense mode data should always be defined, according to react-query docs.');

  const { selectedVesselId, navigateToSelectedVesselId } = useSelectedVessel(vessels);
  const { selectedComparedVesselId, setSelectedComparedVesselId } = usePersistedComparedVessel(vessels);
  const [currentVesselId] = React.useState(selectedVesselId);

  React.useEffect(() => {
    // upon switching to another customerId , reset the error boundary
    if (currentVesselId !== selectedVesselId) {
      resetErrorBoundary();
    }
  }, [selectedVesselId, currentVesselId, resetErrorBoundary]);

  const handleSelect = React.useCallback(
    (id: string) => {
      if ((id as UUID) !== selectedVesselId) {
        resetErrorBoundary();
        navigateToSelectedVesselId(id as UUID);
      }
    },
    [resetErrorBoundary, selectedVesselId, navigateToSelectedVesselId]
  );

  // Prevent unnecessary re-renders due to props being re-created on every render
  const memoizedProps = React.useMemo(
    () =>
      ({
        campaigns: [],
        comparedCampaigns: [],
        campaignsToCompare: [],
        setCampaignsToCompare: () => false,
      } satisfies Partial<React.ComponentProps<typeof ParametersComparisonStrip>>),
    []
  );

  return (
    <Container ref={containerRef as React.MutableRefObject<HTMLDivElement>}>
      {hasElements(vessels) && (
        <ParametersComparisonStrip
          selectedComparedVesselId={selectedComparedVesselId}
          setSelectedComparedVesselId={(id: UUID) => {
            resetErrorBoundary();
            setSelectedComparedVesselId(id);
          }}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedVesselId={selectedVesselId!}
          setSelectedVesselId={handleSelect}
          isCampaignLoading={false}
          isLoading={false}
          vessels={vessels}
          {...memoizedProps}
        />
      )}
      <Wrapper>
        <ErrorPanel error={error} resetErrorBoundary={resetErrorBoundary} />
      </Wrapper>
    </Container>
  );
};

const Container = styled(DivWithSpinnerWhenEmpty)`
  display: grid;
  height: 100%;
  background: ${settings.colors.Primary.Grey_1};
`;

const Wrapper = styled.div`
  margin: 0 ${settings.Spacing.Spacing_300};
`;

export default React.memo(ParametersComparisonErrorPage);
