import { assert, hasElements } from '@rhim/utils';

export type SupportedLanguageIsoCode = 'en' | 'de' | 'zh_CN' | 'pt_BR';
export type SupportedLocale = 'en' | 'de' | 'zh-CN' | 'pt-BR' | 'en-US' | 'en-GB';
export type SupportedLanguageUnicodeCode = 'en' | 'de' | 'zh-CN' | 'pt-BR';
export const SUPPORTED_LANGUAGES: NonEmptyArray<SupportedLanguageIsoCode> = ['en', 'pt_BR'];
export const SUPPORTED_LOCALES: NonEmptyArray<SupportedLocale> = ['en-US', 'en-GB', 'pt-BR', 'de', 'zh-CN', 'en'];
export const FALLBACK_LANGUAGE_CODE: SupportedLanguageIsoCode = 'en';
export const LOCALE_KEY = 'locale';

export const getAcceptedLanguages = function () {
  const hasNavigatorLanguages = typeof window !== 'undefined' && hasElements(window.navigator.languages);
  assert.silent(hasNavigatorLanguages, `window.navigator.languages not defined. Using [${FALLBACK_LANGUAGE_CODE}] instead.`);

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return hasNavigatorLanguages ? window.navigator.languages : [FALLBACK_LANGUAGE_CODE];
};

export const getBrowserLocale = () => getNavigatorLanguage(getAcceptedLanguages(), SUPPORTED_LOCALES, FALLBACK_LANGUAGE_CODE);

export const getNavigatorLanguage = (
  navigatorLanguages: readonly string[],
  supportedLanguages: NonEmptyArray<SupportedLanguageIsoCode | SupportedLocale>,
  fallbackLanguage: SupportedLanguageIsoCode
): SupportedLanguageIsoCode => {
  let matchingLanguage: SupportedLanguageIsoCode | null = null;

  for (const browserUserPreferredLanguage of navigatorLanguages) {
    const matchingSupportedLanguage = supportedLanguages.find((language) =>
      browserUserPreferredLanguage.startsWith(toUnicodeLocale(language as SupportedLanguageIsoCode))
    ) as SupportedLanguageIsoCode | undefined;

    if (matchingSupportedLanguage !== undefined) {
      matchingLanguage = matchingSupportedLanguage;
      break;
    }
  }

  return matchingLanguage || fallbackLanguage;
};

/**
 * Transform ISO locales to Unicode locales.
 *
 * ISO locales (such as the ones used by Lokalise) are commonly using underscores,
 * whereas the Unicode locales the Web platform rely on hyphens instead.
 *
 * @example
 *
 * ```ts
 * toUnicodeLocale('pt_BR'); // => "pt-BR"
 *
 * @example
 *
 * ```ts
 * toUnicodeLocale('en'); // => "en"
 * ```
 */
export function toUnicodeLocale(locale: SupportedLanguageIsoCode): SupportedLanguageUnicodeCode {
  return locale.replace('_', '-') as SupportedLanguageUnicodeCode;
}

export function setHtmlLang(locale: string): void {
  document.querySelector('html')?.setAttribute('lang', locale);
}
