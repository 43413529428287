import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const LastHeatFireIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <g>
              <g>
                <path
                  d="M12.278 19c-1.678 0-3.042-1.365-3.042-3.042 0-1.087.31-1.82.611-2.53.14-.33.272-.64.367-.977.039-.137.157-.236.298-.25.147-.011.276.063.34.19.035.07.525 1.047.882 1.753.621-1.424.535-3.25.534-3.27-.008-.146.077-.282.213-.337.135-.054.292-.018.388.092.042.048 1.045 1.194 1.701 2.227.673 1.063.751 2.279.751 3.102 0 1.677-1.365 3.042-3.043 3.042M6 21c-.552 0-1-.448-1-1s.448-1 1-1h1.19C5.842 17.7 5 15.875 5 13.858c0-2.656.801-4.085 1.577-5.466l.061-.11c.316-.566.607-1.187.835-1.71l.13-.302c.221-.525.358-.895.361-.902.068-.185.238-.312.435-.325.192-.005.382.09.474.265l.017.031.978 1.86.177.339.179.34.346.662c1.203-2.84 1.271-6.409 1.274-6.963v-.073c-.002-.21.128-.4.325-.472.197-.074.419-.015.554.145.019.021 1.411 1.668 2.802 3.535l.309.418.153.21.302.422c.2.282.393.562.576.838 1.627 2.44 2.418 4.815 2.418 7.258 0 2.017-.841 3.842-2.19 5.142H18c.552 0 1 .448 1 1s-.448 1-1 1H6z"
                  transform="translate(-446.000000, -294.000000) translate(80.000000, 282.000000) translate(350.000000, 0.000000) translate(16.000000, 12.000000)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

LastHeatFireIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Grey_2,
};
