import { assert, isDefined } from '@rhim/utils';
import { useCallback, useEffect, useState } from 'react';

import { ACTION_ID, ZOOM_CENTER_DEFAULT, ZOOM_INIT, ZOOM_MAX, ZOOM_STEP } from '../constants';
import { ScrollbarRef } from '../types';

export interface UseZoom {
  handleReset: () => void;
  handleZoom: (zoomButtonId: string) => void;
  registerScrollbar: (scrollbarRef: ScrollbarRef) => void;
  scrollbarRefs: ScrollbarRef[] | null;
  updateScrollPositions: (position: number | null) => void;
  unregisterScrollbar: (scrollbarRef: ScrollbarRef) => void;
  zoomLevel: number;
}

interface Props {
  initialZoom: number;
}

export function useZoom(props: Props): UseZoom {
  const { initialZoom = ZOOM_INIT } = props;

  const [zoomLevel, setZoomLevel] = useState<number>(initialZoom);
  const [scrollbarRefs, setScrollbarRefs] = useState<ScrollbarRef[] | null>(null);
  const [relativePos, setRelativePos] = useState<{ left: number; center: number } | undefined>(undefined);

  const registerScrollbar = useCallback(
    (scrollbarRef: ScrollbarRef) => {
      setScrollbarRefs((prevState) => {
        const isRegistered = isDefined(prevState?.find((item) => item === scrollbarRef));

        if (!isRegistered) {
          return isDefined(prevState) ? [...prevState, scrollbarRef] : [scrollbarRef];
        }

        return prevState;
      });
    },
    [setScrollbarRefs]
  );

  const unregisterScrollbar = useCallback(
    (scrollbarRef: ScrollbarRef) => {
      setScrollbarRefs((prevState) => {
        if (!isDefined(prevState)) {
          return prevState;
        }

        return prevState.filter((item) => item !== scrollbarRef);
      });
    },
    [setScrollbarRefs]
  );

  const updateScrollPositions = useCallback(
    (scrollPosition: number | null, emitter?: ScrollbarRef) => {
      assert(isDefined(scrollPosition), 'scrollPosition is required to sync scrollbars');
      assert(isDefined(scrollbarRefs), 'scrollbarRefs is required to sync scrollbars');

      scrollbarRefs.forEach((scrollbarRef) => {
        const isCurrentEmitter = isDefined(emitter) && scrollbarRef === emitter;

        if (isDefined(scrollbarRef.current) && !isCurrentEmitter) {
          scrollbarRef.current.scrollLeft(scrollPosition);
        }
      });
    },
    [scrollbarRefs]
  );

  const handleReset = useCallback(() => setZoomLevel(ZOOM_INIT), [setZoomLevel]);

  const handleZoom = useCallback(
    (zoomButtonId: string) => {
      const scrollbar = scrollbarRefs?.at(0)?.current;
      assert(isDefined(scrollbar), 'scrollbarRef is empty');

      const scrollLeft = scrollbar.getScrollLeft();
      const clientWidth = scrollbar.getClientWidth();
      const scrollWidth = scrollbar.getScrollWidth();

      const leftRelative = Math.ceil(scrollLeft) / (scrollWidth - clientWidth) || 0;
      const centerRelative = clientWidth === scrollWidth ? ZOOM_CENTER_DEFAULT : (scrollLeft + clientWidth / 2) / scrollWidth;
      setRelativePos({ left: leftRelative, center: centerRelative });

      setZoomLevel((current) => {
        const roundedCurrent = zoomButtonId === ACTION_ID.viewZoomOut ? Math.ceil(current) : Math.floor(current);
        const zoomOutLevel = roundedCurrent > ZOOM_MAX ? ZOOM_MAX : roundedCurrent - ZOOM_STEP;
        const zoomInLevel = roundedCurrent + ZOOM_STEP;
        return zoomButtonId === ACTION_ID.viewZoomOut ? zoomOutLevel : zoomInLevel;
      });
    },
    [scrollbarRefs, setZoomLevel]
  );

  /**
   * Scrolls to the right position after zoom level has been set and the scrollbar width got updated.
   * **Used only after the zoom in/out buttons have been clicked.
   */
  useEffect(() => {
    if (isDefined(relativePos)) {
      const scrollbar = scrollbarRefs?.at(0)?.current;
      assert(isDefined(scrollbar), 'scrollbarRef is empty');

      const scrollWidthAfter = scrollbar.getScrollWidth();
      const clientWidth = scrollbar.getClientWidth();
      const leftRelative = relativePos.left;
      const centerRelative = relativePos.center;

      const isBoundary = leftRelative % 1 === 0;

      updateScrollPositions(isBoundary ? leftRelative * (scrollWidthAfter - clientWidth) : centerRelative * scrollWidthAfter - clientWidth / 2);
      setRelativePos(undefined);
    }
  }, [scrollbarRefs, relativePos, updateScrollPositions, zoomLevel]);

  return {
    handleReset,
    handleZoom,
    registerScrollbar,
    scrollbarRefs,
    updateScrollPositions,
    unregisterScrollbar,
    zoomLevel,
  };
}
