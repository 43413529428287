import { SupportedLanguageIsoCode } from '@rhim/i18n';
import { isDefined, TimeUnit } from '@rhim/utils';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useEffect, useState } from 'react';

import { ElapsedTime } from '../partials';

type Props = {
  utcTime: string;
  targetTimezone?: string;
  unit?: TimeUnit;
  addSuffix?: boolean;
  locale: SupportedLanguageIsoCode;
};

export const useTimeAgoTextUpdatedEveryMinute = ({ utcTime, targetTimezone, unit, addSuffix, locale }: Props): JSX.Element => {
  const ONE_MINUTE_IN_MILLISECONDS = 60000;
  const [timeAgoText, setTimeAgoText] = useState<JSX.Element>(<span />);

  // update the time-ago text every 1 minute on the minute
  useEffect(() => {
    function getTimeAgoText(timeUtc: string) {
      const nowDate = isDefined(targetTimezone) ? zonedTimeToUtc(new Date(), targetTimezone) : new Date();
      return <ElapsedTime from={timeUtc} to={nowDate} unit={unit} addSuffix={addSuffix} locale={locale} />;
    }

    setTimeAgoText(getTimeAgoText(utcTime));

    const delay = ONE_MINUTE_IN_MILLISECONDS - (Date.now() % ONE_MINUTE_IN_MILLISECONDS);
    let nextScheduledTimeoutlId: number;
    function updateEveryMinute() {
      setTimeAgoText(getTimeAgoText(utcTime));
      nextScheduledTimeoutlId = window.setTimeout(updateEveryMinute, ONE_MINUTE_IN_MILLISECONDS);
    }
    const timeoutId = window.setTimeout(updateEveryMinute, delay);
    return () => {
      window.clearTimeout(timeoutId);
      window.clearTimeout(nextScheduledTimeoutlId);
    };
  }, [locale, utcTime, targetTimezone, unit, addSuffix]);

  return timeAgoText;
};
