import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  height?: Property.Height;
  width?: Property.Width;
  title?: string;
  role?: string;
}

export const QCKLogo: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ height, width, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 73 80" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none">
        <g fill="#E52F2D">
          <path
            d="M12.64 13.488l1.659 1.918h-2.91l-.512-.584c-1.002.52-2.17.813-3.42.813C3.42 15.635.222 12.3.222 7.92.223 3.5 3.338.204 7.456.204c4.16 0 7.294 3.275 7.294 7.716 0 2.252-.78 4.19-2.11 5.568zm-3.647-.855l-1.885-2.168h2.93l.737.855c.634-.855.985-2.023.985-3.4 0-2.941-1.68-4.943-4.304-4.943-2.541 0-4.22 2.043-4.22 4.943 0 2.856 1.722 4.963 4.22 4.963.552 0 1.066-.084 1.537-.25zM29.437 5.583c-.491-1.334-1.845-2.458-3.647-2.458-2.519 0-4.343 2.043-4.343 4.815 0 2.752 1.824 4.714 4.343 4.714 1.802 0 3.278-1.023 3.912-2.377l2.705 1.293c-1.108 2.502-3.647 4.025-6.62 4.025-4.015 0-7.313-3.4-7.313-7.652 0-4.252 3.298-7.696 7.314-7.696 4.446-.02 6.005 3.377 6.373 4.316l-2.724 1.02zM42.219 8.045L47.996 15.342 44.309 15.342 40.417 10.317 39.086 12.006 39.086 15.383 36.402 15.383 36.402.536 39.086.536 39.086 7.499 44.455.536 48.142.536z"
            transform="translate(12.093 64.186)"
          />
        </g>
        <circle cx="62.326" cy="19.535" r="2.791" fill="#E2332F" />
        <path
          fill="#FFF"
          d="M35.346.023C31.554.023 27.76.015 23.967 0c-2.029-.007-3.247.904-3.857 2.906-2.816 9.25-1.942 7.797-11.183 7.932-.327.005-.654 0-.98.005-5.154.07-7.914 2.846-7.926 8.091-.027 10.237-.03 20.475.002 30.711.015 5.083 2.756 7.987 7.679 8 9.216.02 18.43.03 27.647.03V.022h-.003z"
        />
        <path
          fill="#B0BAC2"
          d="M47.855 3.06c.581 0 .759 0 1.107 1.12l.09.29c1.145 3.677 1.776 5.704 2.943 7.154 1.859 2.306 4.474 2.306 6.205 2.306.463 0 .971-.007 1.51-.017.656-.01 1.4-.023 2.23-.025.232 0 .473 0 .72.002 3.011.028 4.855.422 5.639 1.203.784.779 1.167 2.608 1.172 5.586.005 2.925.033 23.5-.005 28.684-.015 1.872-.416 3.23-1.195 4.036-.751.779-2.024 1.175-3.774 1.178-9.526.02-19.034.027-28.257.027-9.518 0-19.028-.01-28.269-.03-3.363-.007-4.869-1.532-4.879-4.944-.03-9.747-.03-20.07-.002-30.686.01-3.529 1.508-4.989 5.17-5.036.11-.003.222-.003.333-.003h.25c.145 0 .29 0 .436-.002.456-.008.917-.01 1.405-.01.684 0 1.318.007 1.879.012.49.005.939.01 1.352.01 6.665 0 7.48-2.606 9.448-8.898l.371-1.186c.223-.704.298-.761 1.032-.761h.015c4.1.015 7.75.023 11.159.023 4.25 0 8.143-.013 11.892-.035l.023.002m0-3.06h-.04C43.857.025 39.9.035 35.942.035c-3.714 0-7.431-.01-11.149-.023h-.027c-2.084 0-3.336.914-3.963 2.906-2.317 7.353-2.219 7.942-6.89 7.942-.887 0-1.949-.02-3.229-.02-.453 0-.937.003-1.448.01-.13.002-.26.002-.39.002h-.25c-.126 0-.251 0-.377.003-5.33.07-8.185 2.845-8.198 8.09-.027 10.233-.03 20.469.003 30.702.015 5.082 2.85 7.985 7.942 7.997 9.426.02 18.849.03 28.274.03 9.42 0 18.841-.01 28.264-.027 5.278-.01 7.993-2.883 8.033-8.252.04-5.171.012-25.454.005-28.713-.015-7.6-2.227-9.772-9.854-9.844-.258-.003-.508-.003-.751-.003-1.538 0-2.756.042-3.74.042-3.998 0-4.153-.683-6.304-7.595C51.263 1.245 50.164 0 47.855 0z"
        />
        <path
          fill="#B0BAC2"
          d="M50.25 30.516c-1.642-.063-2.998 1.258-2.998 2.891 0 .569.168 1.123.48 1.593l-2.022 2.9h-9.896v-3.524h2.83c.494 1.042 1.534 1.709 2.704 1.709 1.655 0 3-1.349 3-3.007 0-1.659-1.345-3.008-3-3.008-1.168 0-2.21.67-2.704 1.792h-2.63v-4.781h4.729c.256 0 .472-.174.537-.408l2.886-4.376h2.89c.482 1.007 1.506 1.656 2.652 1.633 1.516-.027 2.787-1.283 2.832-2.803.05-1.636-1.263-2.982-2.885-2.982-1.123 0-2.125.642-2.6 1.636h-3.99c-.188-.003-.366.085-.474.251l-2.988 4.53h-3.588v-8.304h2.804c.56 1.147 1.826 1.824 3.16 1.565 1.167-.227 2.091-1.185 2.28-2.36.288-1.805-1.1-3.37-2.848-3.37-1.133 0-2.135.65-2.61 1.65h-2.736l-3.014-.145C22.907 13.58 14.87 21.754 14.884 32.01c.015 10.106 8.1 18.284 18.06 18.222h3.073v-2.769h6.1c.494 1.158 1.534 1.827 2.704 1.827 1.655 0 3-1.349 3-3.007 0-1.658-1.345-3.007-3-3.007-1.168 0-2.207.67-2.704 1.673h-6.102l-.201-4.53h10.89c.179 0 .347-.085.45-.228l2.82-3.89c1.697.102 3.093-1.282 3.048-2.965-.037-1.51-1.273-2.766-2.772-2.821z"
        />
        <path
          fill="#FFF"
          d="M35.349 18.741c-.594-.08-1.196-.128-1.811-.136-7.772-.078-14.014 5.96-14.003 13.544.01 7.434 6.229 13.45 13.887 13.432.655-.002 1.299-.048 1.927-.133V18.74z"
        />
      </g>
    </svg>
  );
};

QCKLogo.defaultProps = {
  height: '80',
  width: '73',
};
