/* tslint:disable */
/* eslint-disable */
/**
 * LiningService API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMContractsCoordinateSystem2D = {
    Xy: 'XY',
    RTheta: 'RTheta',
    ThetaZ: 'ThetaZ'
} as const;

export type RHIMContractsCoordinateSystem2D = typeof RHIMContractsCoordinateSystem2D[keyof typeof RHIMContractsCoordinateSystem2D];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMContractsFileType = {
    PostMortem: 'PostMortem',
    ProcessData: 'ProcessData',
    ProcessMetrixFiltered: 'ProcessMetrixFiltered',
    ProcessMetrixRaw: 'ProcessMetrixRaw',
    ProcessMetrixVertex: 'ProcessMetrixVertex',
    ProcessMetrixSummary: 'ProcessMetrixSummary',
    ProcessMetrixZip: 'ProcessMetrixZip',
    Stl: 'STL',
    HarmonizedSummary: 'HarmonizedSummary',
    ProcessDataSplit: 'ProcessDataSplit',
    Pts: 'PTS',
    Clinker: 'Clinker',
    KilnFeed: 'KilnFeed',
    HotMeal: 'HotMeal',
    Lignite: 'Lignite',
    StopLogs: 'StopLogs',
    IrScan: 'IRScan',
    KilnShellCooling: 'KilnShellCooling',
    KilnProcessData: 'KilnProcessData',
    FerrotronSummary: 'FerrotronSummary'
} as const;

export type RHIMContractsFileType = typeof RHIMContractsFileType[keyof typeof RHIMContractsFileType];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMContractsRegionLocation = {
    Bottom: 'Bottom',
    Barrel: 'Barrel',
    Cone: 'Cone',
    ThroatIn: 'ThroatIn',
    ThroatOut: 'ThroatOut'
} as const;

export type RHIMContractsRegionLocation = typeof RHIMContractsRegionLocation[keyof typeof RHIMContractsRegionLocation];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMContractsVesselType = {
    Bof: 'BOF',
    Rh: 'RH',
    Eaf: 'EAF',
    Ladle: 'Ladle',
    Crk: 'CRK',
    Rhl: 'RHL'
} as const;

export type RHIMContractsVesselType = typeof RHIMContractsVesselType[keyof typeof RHIMContractsVesselType];


/**
 * 
 * @export
 * @interface RHIMLiningServiceV1ModelsAggregatedLiningRegionDto
 */
export interface RHIMLiningServiceV1ModelsAggregatedLiningRegionDto {
    /**
     * DisplayName of the lining region  CANNOT be used to identify the lining region
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsAggregatedLiningRegionDto
     */
    'displayName'?: string | null;
    /**
     * Optional notes for this lining region
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsAggregatedLiningRegionDto
     */
    'notes'?: string | null;
    /**
     * The Supplier of the bricks. This will soon be another table instead of a free text value
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsAggregatedLiningRegionDto
     */
    'brickSupplier'?: string | null;
    /**
     * The Name of the type of brick. This will soon be another table instead of a free text value
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsAggregatedLiningRegionDto
     */
    'brickName'?: string | null;
    /**
     * Initial lining thickness in `mm`
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsAggregatedLiningRegionDto
     */
    'initialLiningThickness': number;
    /**
     * Critical lining thickness in `mm`
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsAggregatedLiningRegionDto
     */
    'criticalLiningThickness': number;
    /**
     * Part of the vessel in which this lining region is located
     * @type {RHIMContractsRegionLocation}
     * @memberof RHIMLiningServiceV1ModelsAggregatedLiningRegionDto
     */
    'regionLocation': RHIMContractsRegionLocation;
    /**
     * Coordinate system used by the points in this lining region
     * @type {RHIMContractsCoordinateSystem2D}
     * @memberof RHIMLiningServiceV1ModelsAggregatedLiningRegionDto
     */
    'coordinateSystem': RHIMContractsCoordinateSystem2D;
    /**
     * 
     * @type {RHIMLiningServiceV1ModelsAggregatedLiningRegionDtoMinimumPoint}
     * @memberof RHIMLiningServiceV1ModelsAggregatedLiningRegionDto
     */
    'minimumPoint'?: RHIMLiningServiceV1ModelsAggregatedLiningRegionDtoMinimumPoint | null;
    /**
     * 
     * @type {RHIMLiningServiceV1ModelsAggregatedLiningRegionDtoMaximumPoint}
     * @memberof RHIMLiningServiceV1ModelsAggregatedLiningRegionDto
     */
    'maximumPoint'?: RHIMLiningServiceV1ModelsAggregatedLiningRegionDtoMaximumPoint | null;
    /**
     * The date when this lining region was put into the vessel
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsAggregatedLiningRegionDto
     */
    'installationDate': string;
    /**
     * The color representation of the brick type to display in the frontend.  Represented as a hex color code, e.g. ```#fc30c2```
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsAggregatedLiningRegionDto
     */
    'displayColor'?: string | null;
}
/**
 * The second point which defines this lining region.  Has normally higher values than the maximum point.  Except if the region wraps around the 360° boundary.  Then the minimum point has a higher angle value than the maximum point.
 * @export
 * @interface RHIMLiningServiceV1ModelsAggregatedLiningRegionDtoMaximumPoint
 */
export interface RHIMLiningServiceV1ModelsAggregatedLiningRegionDtoMaximumPoint {
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsAggregatedLiningRegionDtoMaximumPoint
     */
    'r'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsAggregatedLiningRegionDtoMaximumPoint
     */
    'theta'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsAggregatedLiningRegionDtoMaximumPoint
     */
    'z'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsAggregatedLiningRegionDtoMaximumPoint
     */
    'x'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsAggregatedLiningRegionDtoMaximumPoint
     */
    'y'?: number | null;
}
/**
 * The first point which defines this lining region.  Has normally lower values than the maximum point.  Except if the region wraps around the 360° boundary.  Then the minimum point has a higher angle value than the maximum point.
 * @export
 * @interface RHIMLiningServiceV1ModelsAggregatedLiningRegionDtoMinimumPoint
 */
export interface RHIMLiningServiceV1ModelsAggregatedLiningRegionDtoMinimumPoint {
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsAggregatedLiningRegionDtoMinimumPoint
     */
    'r'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsAggregatedLiningRegionDtoMinimumPoint
     */
    'theta'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsAggregatedLiningRegionDtoMinimumPoint
     */
    'z'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsAggregatedLiningRegionDtoMinimumPoint
     */
    'x'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsAggregatedLiningRegionDtoMinimumPoint
     */
    'y'?: number | null;
}
/**
 * 
 * @export
 * @interface RHIMLiningServiceV1ModelsBrickInformationDto
 */
export interface RHIMLiningServiceV1ModelsBrickInformationDto {
    /**
     * The Supplier of the bricks. This will soon be another table instead of a free text value
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsBrickInformationDto
     */
    'brickSupplier'?: string | null;
    /**
     * The Name of the type of brick. This will soon be another table instead of a free text value
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsBrickInformationDto
     */
    'brickName'?: string | null;
}
/**
 * 
 * @export
 * @interface RHIMLiningServiceV1ModelsCreateLiningDto
 */
export interface RHIMLiningServiceV1ModelsCreateLiningDto {
    /**
     * Optional list of lining regions that should be created immediately with the lining
     * @type {Array<RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto>}
     * @memberof RHIMLiningServiceV1ModelsCreateLiningDto
     */
    'liningRegions'?: Array<RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto> | null;
    /**
     * DisplayName of the lining  CANNOT be used to identify the lining
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsCreateLiningDto
     */
    'displayName': string;
    /**
     * The date when this lining was put into the vessel
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsCreateLiningDto
     */
    'installationDate': string;
}
/**
 * 
 * @export
 * @interface RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto
 */
export interface RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto {
    /**
     * DisplayName of the lining region  CANNOT be used to identify the lining region
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto
     */
    'displayName': string;
    /**
     * Optional notes for this lining region
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto
     */
    'notes'?: string | null;
    /**
     * The Supplier of the bricks. This will soon be another table instead of a free text value
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto
     */
    'brickSupplier': string;
    /**
     * The Name of the type of brick. This will soon be another table instead of a free text value
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto
     */
    'brickName': string;
    /**
     * Initial lining thickness in `mm`
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto
     */
    'initialLiningThickness': number;
    /**
     * Critical lining thickness in `mm`
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto
     */
    'criticalLiningThickness': number;
    /**
     * Part of the vessel in which this lining region is located
     * @type {RHIMContractsRegionLocation}
     * @memberof RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto
     */
    'regionLocation': RHIMContractsRegionLocation;
    /**
     * Coordinate system used by the points in this lining region
     * @type {RHIMContractsCoordinateSystem2D}
     * @memberof RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto
     */
    'coordinateSystem': RHIMContractsCoordinateSystem2D;
    /**
     * 
     * @type {RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDtoMinimumPoint}
     * @memberof RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto
     */
    'minimumPoint': RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDtoMinimumPoint;
    /**
     * 
     * @type {RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDtoMaximumPoint}
     * @memberof RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto
     */
    'maximumPoint': RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDtoMaximumPoint;
}
/**
 * The second point which defines this lining region.  Has normally higher values than the maximum point.  Except if the region wraps around the 360 degree boundary.  Then the minimum point has a higher angle value than the maximum point.
 * @export
 * @interface RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDtoMaximumPoint
 */
export interface RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDtoMaximumPoint {
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDtoMaximumPoint
     */
    'r'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDtoMaximumPoint
     */
    'theta'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDtoMaximumPoint
     */
    'z'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDtoMaximumPoint
     */
    'x'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDtoMaximumPoint
     */
    'y'?: number | null;
}
/**
 * The first point which defines this lining region.  Has normally lower values than the maximum point.  Except if the region wraps around the 360 degree boundary.  Then the minimum point has a higher angle value than the maximum point.
 * @export
 * @interface RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDtoMinimumPoint
 */
export interface RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDtoMinimumPoint {
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDtoMinimumPoint
     */
    'r'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDtoMinimumPoint
     */
    'theta'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDtoMinimumPoint
     */
    'z'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDtoMinimumPoint
     */
    'x'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDtoMinimumPoint
     */
    'y'?: number | null;
}
/**
 * 
 * @export
 * @interface RHIMLiningServiceV1ModelsLiningDto
 */
export interface RHIMLiningServiceV1ModelsLiningDto {
    /**
     * The ordered list of the lining regions of this lining. Earlier linings in the list cover/overwrite linings that come later.
     * @type {Array<RHIMLiningServiceV1ModelsLiningRegionDto>}
     * @memberof RHIMLiningServiceV1ModelsLiningDto
     */
    'liningRegions'?: Array<RHIMLiningServiceV1ModelsLiningRegionDto> | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsLiningDto
     */
    'id': string;
    /**
     * References the vessel to which this lining belongs
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsLiningDto
     */
    'vesselId': string;
    /**
     * DisplayName of the lining  CANNOT be used to identify the lining
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsLiningDto
     */
    'displayName'?: string | null;
    /**
     * The date when this lining was put into the vessel
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsLiningDto
     */
    'installationDate': string;
    /**
     * List of all the brick suppliers used in this lining
     * @type {Array<string>}
     * @memberof RHIMLiningServiceV1ModelsLiningDto
     */
    'brickSuppliers'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface RHIMLiningServiceV1ModelsLiningOverviewDto
 */
export interface RHIMLiningServiceV1ModelsLiningOverviewDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsLiningOverviewDto
     */
    'id': string;
    /**
     * References the vessel to which this lining belongs
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsLiningOverviewDto
     */
    'vesselId': string;
    /**
     * DisplayName of the lining  CANNOT be used to identify the lining
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsLiningOverviewDto
     */
    'displayName'?: string | null;
    /**
     * The date when this lining was put into the vessel
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsLiningOverviewDto
     */
    'installationDate': string;
    /**
     * List of all the brick suppliers used in this lining
     * @type {Array<string>}
     * @memberof RHIMLiningServiceV1ModelsLiningOverviewDto
     */
    'brickSuppliers'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface RHIMLiningServiceV1ModelsLiningRegionDto
 */
export interface RHIMLiningServiceV1ModelsLiningRegionDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsLiningRegionDto
     */
    'id': string;
    /**
     * DisplayName of the lining region  CANNOT be used to identify the lining region
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsLiningRegionDto
     */
    'displayName'?: string | null;
    /**
     * Optional notes for this lining region
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsLiningRegionDto
     */
    'notes'?: string | null;
    /**
     * The Supplier of the bricks. This will soon be another table instead of a free text value
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsLiningRegionDto
     */
    'brickSupplier'?: string | null;
    /**
     * The Name of the type of brick. This will soon be another table instead of a free text value
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsLiningRegionDto
     */
    'brickName'?: string | null;
    /**
     * Initial lining thickness in `mm`
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsLiningRegionDto
     */
    'initialLiningThickness': number;
    /**
     * Critical lining thickness in `mm`
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsLiningRegionDto
     */
    'criticalLiningThickness': number;
    /**
     * Part of the vessel in which this lining region is located
     * @type {RHIMContractsRegionLocation}
     * @memberof RHIMLiningServiceV1ModelsLiningRegionDto
     */
    'regionLocation': RHIMContractsRegionLocation;
    /**
     * Coordinate system used by the points in this lining region
     * @type {RHIMContractsCoordinateSystem2D}
     * @memberof RHIMLiningServiceV1ModelsLiningRegionDto
     */
    'coordinateSystem': RHIMContractsCoordinateSystem2D;
    /**
     * 
     * @type {RHIMLiningServiceV1ModelsAggregatedLiningRegionDtoMinimumPoint}
     * @memberof RHIMLiningServiceV1ModelsLiningRegionDto
     */
    'minimumPoint'?: RHIMLiningServiceV1ModelsAggregatedLiningRegionDtoMinimumPoint | null;
    /**
     * 
     * @type {RHIMLiningServiceV1ModelsAggregatedLiningRegionDtoMaximumPoint}
     * @memberof RHIMLiningServiceV1ModelsLiningRegionDto
     */
    'maximumPoint'?: RHIMLiningServiceV1ModelsAggregatedLiningRegionDtoMaximumPoint | null;
}
/**
 * 
 * @export
 * @interface RHIMLiningServiceV1ModelsLiningRegionPointDto
 */
export interface RHIMLiningServiceV1ModelsLiningRegionPointDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsLiningRegionPointDto
     */
    'r'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsLiningRegionPointDto
     */
    'theta'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsLiningRegionPointDto
     */
    'z'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsLiningRegionPointDto
     */
    'x'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsLiningRegionPointDto
     */
    'y'?: number | null;
}
/**
 * 
 * @export
 * @interface RHIMLiningServiceV1ModelsLiningThicknessDto
 */
export interface RHIMLiningServiceV1ModelsLiningThicknessDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsLiningThicknessDto
     */
    'initialLiningThickness': number;
    /**
     * Critical lining thickness in `mm`
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsLiningThicknessDto
     */
    'criticalLiningThickness': number;
    /**
     * 
     * @type {RHIMLiningServiceV1ModelsLiningThicknessDtoBrickInformation}
     * @memberof RHIMLiningServiceV1ModelsLiningThicknessDto
     */
    'brickInformation': RHIMLiningServiceV1ModelsLiningThicknessDtoBrickInformation;
}
/**
 * 
 * @export
 * @interface RHIMLiningServiceV1ModelsLiningThicknessDtoBrickInformation
 */
export interface RHIMLiningServiceV1ModelsLiningThicknessDtoBrickInformation {
    /**
     * The Supplier of the bricks. This will soon be another table instead of a free text value
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsLiningThicknessDtoBrickInformation
     */
    'brickSupplier'?: string | null;
    /**
     * The Name of the type of brick. This will soon be another table instead of a free text value
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsLiningThicknessDtoBrickInformation
     */
    'brickName'?: string | null;
}
/**
 * 
 * @export
 * @interface RHIMLiningServiceV1ModelsRegionLiningThicknessDto
 */
export interface RHIMLiningServiceV1ModelsRegionLiningThicknessDto {
    /**
     * 
     * @type {RHIMLiningServiceV1ModelsRegionLiningThicknessDtoMinimumLiningThickness}
     * @memberof RHIMLiningServiceV1ModelsRegionLiningThicknessDto
     */
    'minimumLiningThickness'?: RHIMLiningServiceV1ModelsRegionLiningThicknessDtoMinimumLiningThickness | null;
    /**
     * Contains a list of all the lining thicknesses which appear inside the requested region (including the one under MinimumLiningThickness)
     * @type {Array<RHIMLiningServiceV1ModelsBrickInformationDto>}
     * @memberof RHIMLiningServiceV1ModelsRegionLiningThicknessDto
     */
    'brickInformation'?: Array<RHIMLiningServiceV1ModelsBrickInformationDto> | null;
}
/**
 * Contains the one lining thickness from the list of possible lining thicknesses with the lowest InitialLiningThickness.
 * @export
 * @interface RHIMLiningServiceV1ModelsRegionLiningThicknessDtoMinimumLiningThickness
 */
export interface RHIMLiningServiceV1ModelsRegionLiningThicknessDtoMinimumLiningThickness {
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsRegionLiningThicknessDtoMinimumLiningThickness
     */
    'initialLiningThickness': number;
    /**
     * Critical lining thickness in `mm`
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsRegionLiningThicknessDtoMinimumLiningThickness
     */
    'criticalLiningThickness': number;
    /**
     * 
     * @type {RHIMLiningServiceV1ModelsLiningThicknessDtoBrickInformation}
     * @memberof RHIMLiningServiceV1ModelsRegionLiningThicknessDtoMinimumLiningThickness
     */
    'brickInformation': RHIMLiningServiceV1ModelsLiningThicknessDtoBrickInformation;
}
/**
 * 
 * @export
 * @interface RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDto
 */
export interface RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDto {
    /**
     * 
     * @type {RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDtoMinimumPoint}
     * @memberof RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDto
     */
    'minimumPoint': RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDtoMinimumPoint;
    /**
     * 
     * @type {RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDtoMaximumPoint}
     * @memberof RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDto
     */
    'maximumPoint': RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDtoMaximumPoint;
    /**
     * Decides which linings to consider. Only lining older than this date are considered.  If the value is not provided all the linings are considered.
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDto
     */
    'requestedDate'?: string | null;
}
/**
 * The second point which defines this region.  Has normally higher values than the maximum point.  Except if the region wraps around the 360° boundary.  Then the minimum point has a higher angle value than the maximum point.
 * @export
 * @interface RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDtoMaximumPoint
 */
export interface RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDtoMaximumPoint {
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDtoMaximumPoint
     */
    'r'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDtoMaximumPoint
     */
    'theta'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDtoMaximumPoint
     */
    'z'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDtoMaximumPoint
     */
    'x'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDtoMaximumPoint
     */
    'y'?: number | null;
}
/**
 * The first point which defines this region.  Has normally lower values than the maximum point.  Except if the region wraps around the 360° boundary.  Then the minimum point has a higher angle value than the maximum point.
 * @export
 * @interface RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDtoMinimumPoint
 */
export interface RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDtoMinimumPoint {
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDtoMinimumPoint
     */
    'r'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDtoMinimumPoint
     */
    'theta'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDtoMinimumPoint
     */
    'z'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDtoMinimumPoint
     */
    'x'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDtoMinimumPoint
     */
    'y'?: number | null;
}
/**
 * 
 * @export
 * @interface RHIMLiningServiceV1ModelsUpdateLiningDto
 */
export interface RHIMLiningServiceV1ModelsUpdateLiningDto {
    /**
     * DisplayName of the lining  CANNOT be used to identify the lining
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsUpdateLiningDto
     */
    'displayName': string;
    /**
     * The date when this lining was put into the vessel
     * @type {string}
     * @memberof RHIMLiningServiceV1ModelsUpdateLiningDto
     */
    'installationDate': string;
}

/**
 * LiningRegionsApi - axios parameter creator
 * @export
 */
export const LiningRegionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete a lining region
         * @summary Delete a lining region
         * @param {string} liningRegionId Format - uuid. Identifier of the lining region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLiningregionsLiningregionid: async (liningRegionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'liningRegionId' is not null or undefined
            assertParamExists('deleteLiningregionsLiningregionid', 'liningRegionId', liningRegionId)
            const localVarPath = `/liningRegions/{liningRegionId}`
                .replace(`{${"liningRegionId"}}`, encodeURIComponent(String(liningRegionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new lining region
         * @summary Create a new lining region
         * @param {string} liningId Format - uuid. Identifier of the lining
         * @param {RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto} [rHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLiningsLiningid: async (liningId: string, rHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto?: RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'liningId' is not null or undefined
            assertParamExists('postLiningsLiningid', 'liningId', liningId)
            const localVarPath = `/linings/{liningId}`
                .replace(`{${"liningId"}}`, encodeURIComponent(String(liningId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Change the order of the regions according to the given list, earlier ordered regions overwrite later ordered regions
         * @summary Change the order of the regions according to the given list, earlier ordered regions overwrite later ordered regions
         * @param {string} liningId Format - uuid. Identifier of the lining
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLiningsLiningidLiningregionsorder: async (liningId: string, requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'liningId' is not null or undefined
            assertParamExists('postLiningsLiningidLiningregionsorder', 'liningId', liningId)
            const localVarPath = `/linings/{liningId}/liningRegionsOrder`
                .replace(`{${"liningId"}}`, encodeURIComponent(String(liningId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a lining region
         * @summary Update a lining region
         * @param {string} liningRegionId Format - uuid. Identifier of the lining region
         * @param {RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto} [rHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLiningregionsLiningregionid: async (liningRegionId: string, rHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto?: RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'liningRegionId' is not null or undefined
            assertParamExists('putLiningregionsLiningregionid', 'liningRegionId', liningRegionId)
            const localVarPath = `/liningRegions/{liningRegionId}`
                .replace(`{${"liningRegionId"}}`, encodeURIComponent(String(liningRegionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LiningRegionsApi - functional programming interface
 * @export
 */
export const LiningRegionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LiningRegionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete a lining region
         * @summary Delete a lining region
         * @param {string} liningRegionId Format - uuid. Identifier of the lining region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLiningregionsLiningregionid(liningRegionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLiningregionsLiningregionid(liningRegionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new lining region
         * @summary Create a new lining region
         * @param {string} liningId Format - uuid. Identifier of the lining
         * @param {RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto} [rHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLiningsLiningid(liningId: string, rHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto?: RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMLiningServiceV1ModelsLiningRegionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLiningsLiningid(liningId, rHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Change the order of the regions according to the given list, earlier ordered regions overwrite later ordered regions
         * @summary Change the order of the regions according to the given list, earlier ordered regions overwrite later ordered regions
         * @param {string} liningId Format - uuid. Identifier of the lining
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLiningsLiningidLiningregionsorder(liningId: string, requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLiningsLiningidLiningregionsorder(liningId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a lining region
         * @summary Update a lining region
         * @param {string} liningRegionId Format - uuid. Identifier of the lining region
         * @param {RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto} [rHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putLiningregionsLiningregionid(liningRegionId: string, rHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto?: RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMLiningServiceV1ModelsLiningRegionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putLiningregionsLiningregionid(liningRegionId, rHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LiningRegionsApi - factory interface
 * @export
 */
export const LiningRegionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LiningRegionsApiFp(configuration)
    return {
        /**
         * Delete a lining region
         * @summary Delete a lining region
         * @param {string} liningRegionId Format - uuid. Identifier of the lining region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLiningregionsLiningregionid(liningRegionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLiningregionsLiningregionid(liningRegionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new lining region
         * @summary Create a new lining region
         * @param {string} liningId Format - uuid. Identifier of the lining
         * @param {RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto} [rHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLiningsLiningid(liningId: string, rHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto?: RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto, options?: any): AxiosPromise<RHIMLiningServiceV1ModelsLiningRegionDto> {
            return localVarFp.postLiningsLiningid(liningId, rHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Change the order of the regions according to the given list, earlier ordered regions overwrite later ordered regions
         * @summary Change the order of the regions according to the given list, earlier ordered regions overwrite later ordered regions
         * @param {string} liningId Format - uuid. Identifier of the lining
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLiningsLiningidLiningregionsorder(liningId: string, requestBody?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.postLiningsLiningidLiningregionsorder(liningId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a lining region
         * @summary Update a lining region
         * @param {string} liningRegionId Format - uuid. Identifier of the lining region
         * @param {RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto} [rHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLiningregionsLiningregionid(liningRegionId: string, rHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto?: RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto, options?: any): AxiosPromise<RHIMLiningServiceV1ModelsLiningRegionDto> {
            return localVarFp.putLiningregionsLiningregionid(liningRegionId, rHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LiningRegionsApi - object-oriented interface
 * @export
 * @class LiningRegionsApi
 * @extends {BaseAPI}
 */
export class LiningRegionsApi extends BaseAPI {
    /**
     * Delete a lining region
     * @summary Delete a lining region
     * @param {string} liningRegionId Format - uuid. Identifier of the lining region
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiningRegionsApi
     */
    public deleteLiningregionsLiningregionid(liningRegionId: string, options?: AxiosRequestConfig) {
        return LiningRegionsApiFp(this.configuration).deleteLiningregionsLiningregionid(liningRegionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new lining region
     * @summary Create a new lining region
     * @param {string} liningId Format - uuid. Identifier of the lining
     * @param {RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto} [rHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiningRegionsApi
     */
    public postLiningsLiningid(liningId: string, rHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto?: RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto, options?: AxiosRequestConfig) {
        return LiningRegionsApiFp(this.configuration).postLiningsLiningid(liningId, rHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Change the order of the regions according to the given list, earlier ordered regions overwrite later ordered regions
     * @summary Change the order of the regions according to the given list, earlier ordered regions overwrite later ordered regions
     * @param {string} liningId Format - uuid. Identifier of the lining
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiningRegionsApi
     */
    public postLiningsLiningidLiningregionsorder(liningId: string, requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return LiningRegionsApiFp(this.configuration).postLiningsLiningidLiningregionsorder(liningId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a lining region
     * @summary Update a lining region
     * @param {string} liningRegionId Format - uuid. Identifier of the lining region
     * @param {RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto} [rHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiningRegionsApi
     */
    public putLiningregionsLiningregionid(liningRegionId: string, rHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto?: RHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto, options?: AxiosRequestConfig) {
        return LiningRegionsApiFp(this.configuration).putLiningregionsLiningregionid(liningRegionId, rHIMLiningServiceV1ModelsCreateOrUpdateLiningRegionDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LiningsApi - axios parameter creator
 * @export
 */
export const LiningsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete a lining
         * @summary Delete a lining
         * @param {string} liningId Format - uuid. Identifier of the lining
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLiningsLiningid: async (liningId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'liningId' is not null or undefined
            assertParamExists('deleteLiningsLiningid', 'liningId', liningId)
            const localVarPath = `/linings/{liningId}`
                .replace(`{${"liningId"}}`, encodeURIComponent(String(liningId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a lining by id
         * @summary Retrieves a lining by id
         * @param {string} liningId Format - uuid. Identifier of the lining
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiningsLiningid: async (liningId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'liningId' is not null or undefined
            assertParamExists('getLiningsLiningid', 'liningId', liningId)
            const localVarPath = `/linings/{liningId}`
                .replace(`{${"liningId"}}`, encodeURIComponent(String(liningId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the aggregated lining for a vessel at a given point in time. This currently only works for CRK vessels.  The API also returns a color mapping for each brick type. This color mapping is kept consistent per campaign.
         * @summary Retrieves the aggregated lining for a vessel at a given point in time. This currently only works for CRK vessels.  The API also returns a color mapping for each brick type. This color mapping is kept consistent per campaign.
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {string} [requestedDate] Format - date-time (as date-time in RFC3339). The date for which the current state of the lining should be returned. If no date is given all linings are use
         * @param {number} [campaign] Format - int32. The campaign which is used for the color mapping, if no campaign is given a mapping for all campaigns is used
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidAggregate: async (vesselId: string, requestedDate?: string, campaign?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getVesselsVesselidAggregate', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/aggregate`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (requestedDate !== undefined) {
                localVarQueryParameter['requestedDate'] = requestedDate;
            }

            if (campaign !== undefined) {
                localVarQueryParameter['campaign'] = campaign;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all linings for a given vessel id
         * @summary Retrieves all linings for a given vessel id
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidLinings: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getVesselsVesselidLinings', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/linings`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new lining
         * @summary Create a new lining
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMLiningServiceV1ModelsCreateLiningDto} [rHIMLiningServiceV1ModelsCreateLiningDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postVesselsVesselidLinings: async (vesselId: string, rHIMLiningServiceV1ModelsCreateLiningDto?: RHIMLiningServiceV1ModelsCreateLiningDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('postVesselsVesselidLinings', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/linings`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMLiningServiceV1ModelsCreateLiningDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all lining thicknesses for a given vessel id, region and a campaign start date.  If none are found a 204 status code is returned.
         * @summary Retrieves all lining thicknesses for a given vessel id, region and a campaign start date.  If none are found a 204 status code is returned.
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDto} [rHIMLiningServiceV1ModelsRegionLiningThicknessRequestDto] The region lining thickness request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postVesselsVesselidRegionthickness: async (vesselId: string, rHIMLiningServiceV1ModelsRegionLiningThicknessRequestDto?: RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('postVesselsVesselidRegionthickness', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/regionthickness`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMLiningServiceV1ModelsRegionLiningThicknessRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a lining
         * @summary Update a lining
         * @param {string} liningId Format - uuid. Identifier of the lining
         * @param {RHIMLiningServiceV1ModelsUpdateLiningDto} [rHIMLiningServiceV1ModelsUpdateLiningDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLiningsLiningid: async (liningId: string, rHIMLiningServiceV1ModelsUpdateLiningDto?: RHIMLiningServiceV1ModelsUpdateLiningDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'liningId' is not null or undefined
            assertParamExists('putLiningsLiningid', 'liningId', liningId)
            const localVarPath = `/linings/{liningId}`
                .replace(`{${"liningId"}}`, encodeURIComponent(String(liningId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMLiningServiceV1ModelsUpdateLiningDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LiningsApi - functional programming interface
 * @export
 */
export const LiningsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LiningsApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete a lining
         * @summary Delete a lining
         * @param {string} liningId Format - uuid. Identifier of the lining
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLiningsLiningid(liningId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLiningsLiningid(liningId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a lining by id
         * @summary Retrieves a lining by id
         * @param {string} liningId Format - uuid. Identifier of the lining
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLiningsLiningid(liningId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMLiningServiceV1ModelsLiningDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLiningsLiningid(liningId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the aggregated lining for a vessel at a given point in time. This currently only works for CRK vessels.  The API also returns a color mapping for each brick type. This color mapping is kept consistent per campaign.
         * @summary Retrieves the aggregated lining for a vessel at a given point in time. This currently only works for CRK vessels.  The API also returns a color mapping for each brick type. This color mapping is kept consistent per campaign.
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {string} [requestedDate] Format - date-time (as date-time in RFC3339). The date for which the current state of the lining should be returned. If no date is given all linings are use
         * @param {number} [campaign] Format - int32. The campaign which is used for the color mapping, if no campaign is given a mapping for all campaigns is used
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVesselsVesselidAggregate(vesselId: string, requestedDate?: string, campaign?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMLiningServiceV1ModelsAggregatedLiningRegionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselsVesselidAggregate(vesselId, requestedDate, campaign, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all linings for a given vessel id
         * @summary Retrieves all linings for a given vessel id
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVesselsVesselidLinings(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMLiningServiceV1ModelsLiningOverviewDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselsVesselidLinings(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new lining
         * @summary Create a new lining
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMLiningServiceV1ModelsCreateLiningDto} [rHIMLiningServiceV1ModelsCreateLiningDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postVesselsVesselidLinings(vesselId: string, rHIMLiningServiceV1ModelsCreateLiningDto?: RHIMLiningServiceV1ModelsCreateLiningDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMLiningServiceV1ModelsLiningOverviewDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postVesselsVesselidLinings(vesselId, rHIMLiningServiceV1ModelsCreateLiningDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all lining thicknesses for a given vessel id, region and a campaign start date.  If none are found a 204 status code is returned.
         * @summary Retrieves all lining thicknesses for a given vessel id, region and a campaign start date.  If none are found a 204 status code is returned.
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDto} [rHIMLiningServiceV1ModelsRegionLiningThicknessRequestDto] The region lining thickness request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postVesselsVesselidRegionthickness(vesselId: string, rHIMLiningServiceV1ModelsRegionLiningThicknessRequestDto?: RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMLiningServiceV1ModelsRegionLiningThicknessDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postVesselsVesselidRegionthickness(vesselId, rHIMLiningServiceV1ModelsRegionLiningThicknessRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a lining
         * @summary Update a lining
         * @param {string} liningId Format - uuid. Identifier of the lining
         * @param {RHIMLiningServiceV1ModelsUpdateLiningDto} [rHIMLiningServiceV1ModelsUpdateLiningDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putLiningsLiningid(liningId: string, rHIMLiningServiceV1ModelsUpdateLiningDto?: RHIMLiningServiceV1ModelsUpdateLiningDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMLiningServiceV1ModelsLiningDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putLiningsLiningid(liningId, rHIMLiningServiceV1ModelsUpdateLiningDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LiningsApi - factory interface
 * @export
 */
export const LiningsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LiningsApiFp(configuration)
    return {
        /**
         * Delete a lining
         * @summary Delete a lining
         * @param {string} liningId Format - uuid. Identifier of the lining
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLiningsLiningid(liningId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLiningsLiningid(liningId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a lining by id
         * @summary Retrieves a lining by id
         * @param {string} liningId Format - uuid. Identifier of the lining
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiningsLiningid(liningId: string, options?: any): AxiosPromise<RHIMLiningServiceV1ModelsLiningDto> {
            return localVarFp.getLiningsLiningid(liningId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the aggregated lining for a vessel at a given point in time. This currently only works for CRK vessels.  The API also returns a color mapping for each brick type. This color mapping is kept consistent per campaign.
         * @summary Retrieves the aggregated lining for a vessel at a given point in time. This currently only works for CRK vessels.  The API also returns a color mapping for each brick type. This color mapping is kept consistent per campaign.
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {string} [requestedDate] Format - date-time (as date-time in RFC3339). The date for which the current state of the lining should be returned. If no date is given all linings are use
         * @param {number} [campaign] Format - int32. The campaign which is used for the color mapping, if no campaign is given a mapping for all campaigns is used
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidAggregate(vesselId: string, requestedDate?: string, campaign?: number, options?: any): AxiosPromise<Array<RHIMLiningServiceV1ModelsAggregatedLiningRegionDto>> {
            return localVarFp.getVesselsVesselidAggregate(vesselId, requestedDate, campaign, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all linings for a given vessel id
         * @summary Retrieves all linings for a given vessel id
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidLinings(vesselId: string, options?: any): AxiosPromise<Array<RHIMLiningServiceV1ModelsLiningOverviewDto>> {
            return localVarFp.getVesselsVesselidLinings(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new lining
         * @summary Create a new lining
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMLiningServiceV1ModelsCreateLiningDto} [rHIMLiningServiceV1ModelsCreateLiningDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postVesselsVesselidLinings(vesselId: string, rHIMLiningServiceV1ModelsCreateLiningDto?: RHIMLiningServiceV1ModelsCreateLiningDto, options?: any): AxiosPromise<RHIMLiningServiceV1ModelsLiningOverviewDto> {
            return localVarFp.postVesselsVesselidLinings(vesselId, rHIMLiningServiceV1ModelsCreateLiningDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all lining thicknesses for a given vessel id, region and a campaign start date.  If none are found a 204 status code is returned.
         * @summary Retrieves all lining thicknesses for a given vessel id, region and a campaign start date.  If none are found a 204 status code is returned.
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDto} [rHIMLiningServiceV1ModelsRegionLiningThicknessRequestDto] The region lining thickness request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postVesselsVesselidRegionthickness(vesselId: string, rHIMLiningServiceV1ModelsRegionLiningThicknessRequestDto?: RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDto, options?: any): AxiosPromise<RHIMLiningServiceV1ModelsRegionLiningThicknessDto> {
            return localVarFp.postVesselsVesselidRegionthickness(vesselId, rHIMLiningServiceV1ModelsRegionLiningThicknessRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a lining
         * @summary Update a lining
         * @param {string} liningId Format - uuid. Identifier of the lining
         * @param {RHIMLiningServiceV1ModelsUpdateLiningDto} [rHIMLiningServiceV1ModelsUpdateLiningDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLiningsLiningid(liningId: string, rHIMLiningServiceV1ModelsUpdateLiningDto?: RHIMLiningServiceV1ModelsUpdateLiningDto, options?: any): AxiosPromise<RHIMLiningServiceV1ModelsLiningDto> {
            return localVarFp.putLiningsLiningid(liningId, rHIMLiningServiceV1ModelsUpdateLiningDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LiningsApi - object-oriented interface
 * @export
 * @class LiningsApi
 * @extends {BaseAPI}
 */
export class LiningsApi extends BaseAPI {
    /**
     * Delete a lining
     * @summary Delete a lining
     * @param {string} liningId Format - uuid. Identifier of the lining
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiningsApi
     */
    public deleteLiningsLiningid(liningId: string, options?: AxiosRequestConfig) {
        return LiningsApiFp(this.configuration).deleteLiningsLiningid(liningId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a lining by id
     * @summary Retrieves a lining by id
     * @param {string} liningId Format - uuid. Identifier of the lining
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiningsApi
     */
    public getLiningsLiningid(liningId: string, options?: AxiosRequestConfig) {
        return LiningsApiFp(this.configuration).getLiningsLiningid(liningId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the aggregated lining for a vessel at a given point in time. This currently only works for CRK vessels.  The API also returns a color mapping for each brick type. This color mapping is kept consistent per campaign.
     * @summary Retrieves the aggregated lining for a vessel at a given point in time. This currently only works for CRK vessels.  The API also returns a color mapping for each brick type. This color mapping is kept consistent per campaign.
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {string} [requestedDate] Format - date-time (as date-time in RFC3339). The date for which the current state of the lining should be returned. If no date is given all linings are use
     * @param {number} [campaign] Format - int32. The campaign which is used for the color mapping, if no campaign is given a mapping for all campaigns is used
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiningsApi
     */
    public getVesselsVesselidAggregate(vesselId: string, requestedDate?: string, campaign?: number, options?: AxiosRequestConfig) {
        return LiningsApiFp(this.configuration).getVesselsVesselidAggregate(vesselId, requestedDate, campaign, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all linings for a given vessel id
     * @summary Retrieves all linings for a given vessel id
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiningsApi
     */
    public getVesselsVesselidLinings(vesselId: string, options?: AxiosRequestConfig) {
        return LiningsApiFp(this.configuration).getVesselsVesselidLinings(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new lining
     * @summary Create a new lining
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {RHIMLiningServiceV1ModelsCreateLiningDto} [rHIMLiningServiceV1ModelsCreateLiningDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiningsApi
     */
    public postVesselsVesselidLinings(vesselId: string, rHIMLiningServiceV1ModelsCreateLiningDto?: RHIMLiningServiceV1ModelsCreateLiningDto, options?: AxiosRequestConfig) {
        return LiningsApiFp(this.configuration).postVesselsVesselidLinings(vesselId, rHIMLiningServiceV1ModelsCreateLiningDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all lining thicknesses for a given vessel id, region and a campaign start date.  If none are found a 204 status code is returned.
     * @summary Retrieves all lining thicknesses for a given vessel id, region and a campaign start date.  If none are found a 204 status code is returned.
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDto} [rHIMLiningServiceV1ModelsRegionLiningThicknessRequestDto] The region lining thickness request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiningsApi
     */
    public postVesselsVesselidRegionthickness(vesselId: string, rHIMLiningServiceV1ModelsRegionLiningThicknessRequestDto?: RHIMLiningServiceV1ModelsRegionLiningThicknessRequestDto, options?: AxiosRequestConfig) {
        return LiningsApiFp(this.configuration).postVesselsVesselidRegionthickness(vesselId, rHIMLiningServiceV1ModelsRegionLiningThicknessRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a lining
     * @summary Update a lining
     * @param {string} liningId Format - uuid. Identifier of the lining
     * @param {RHIMLiningServiceV1ModelsUpdateLiningDto} [rHIMLiningServiceV1ModelsUpdateLiningDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiningsApi
     */
    public putLiningsLiningid(liningId: string, rHIMLiningServiceV1ModelsUpdateLiningDto?: RHIMLiningServiceV1ModelsUpdateLiningDto, options?: AxiosRequestConfig) {
        return LiningsApiFp(this.configuration).putLiningsLiningid(liningId, rHIMLiningServiceV1ModelsUpdateLiningDto, options).then((request) => request(this.axios, this.basePath));
    }
}


