import { Policies } from '@rhim/requests';
import { assert, hasElements, isDefined } from '@rhim/utils';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import axios from 'axios';
import * as React from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';

import ErrorPanel from '../../components/ErrorPanel/ErrorPanel';
import { Invariants } from '../../errors';
import { useSelectedVessel, useVessels } from '../../hooks';
import useShortHashedSelectedCustomer from '../../hooks/useShortHashedSelectedCustomer';
import { Feature, ONE_MINUTE_IN_MILISECONDS } from '../../settings';
import { PARAMS } from '../../utilities';
import { OperatorDisplay } from './OperatorDisplay';
import OperatorDisplayErrorPage from './OperatorDisplayErrorPage';

interface Props {
  customerId: UUID;
}

/**
 * Operator Display is designed to be used in fullscreen mode, without user interaction.
 * With no triggers that would lead to refetching of data, we trigger it ourselves every 30 minutes.
 */
const THIRTY_MINUTES_IN_MILISECONDS = 30 * ONE_MINUTE_IN_MILISECONDS;

const OperatorDisplayGateway: React.FunctionComponent<Props> = ({ customerId }) => {
  const location = useLocation();
  const { reset } = useQueryErrorResetBoundary();

  // eslint-disable-next-line compat/compat
  const params = new URLSearchParams(location.search);
  const queryVesselId = params.get(PARAMS.VESSEL_ID);

  const { data: vessels } = useVessels(customerId, undefined, Feature.Display);
  assert(isDefined(vessels), 'In Suspense mode data should always be defined, according to react-query docs.');
  const { hasCustomerAccess } = useShortHashedSelectedCustomer(customerId);

  // Persisted vessel ID
  const { selectedVesselId, navigateToSelectedVesselId } = useSelectedVessel(vessels);

  // Choose the selected vessel ID.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const selectedVessel = React.useMemo(() => vessels.find((vessel) => vessel.id === selectedVesselId), [selectedVesselId, vessels])!;

  const FallbackComponent: React.FunctionComponent<FallbackProps> = React.useCallback(
    ({ error, resetErrorBoundary }) => {
      return (
        <OperatorDisplayErrorPage
          error={axios.isAxiosError(error) ? error.response?.data : error}
          resetErrorBoundary={resetErrorBoundary}
          vessels={vessels}
          setSelectedVesselId={navigateToSelectedVesselId}
          vessel={selectedVessel}
        />
      );
    },
    [vessels, navigateToSelectedVesselId, selectedVessel]
  );

  if (!hasElements(vessels) && isDefined(vessels)) {
    return <ErrorPanel error={new Error(Invariants.NoVesselsFound)} />;
  }

  if (isDefined(queryVesselId) && !vessels.some((vessel) => vessel.id === queryVesselId) && hasCustomerAccess()) {
    return <ErrorPanel error={new Error(Invariants.NoPageFound)} />;
  }

  return (
    <ErrorBoundary FallbackComponent={FallbackComponent} resetKeys={[customerId]} onReset={reset}>
      <Policies.RefetchPeriodically intervalMs={THIRTY_MINUTES_IN_MILISECONDS}>
        <OperatorDisplay vessels={vessels} vessel={selectedVessel} setSelectedVesselId={navigateToSelectedVesselId} />
      </Policies.RefetchPeriodically>
    </ErrorBoundary>
  );
};

OperatorDisplayGateway.whyDidYouRender = true;
export default React.memo(OperatorDisplayGateway);
