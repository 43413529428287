import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const EyeIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M12 4c4.574 0 8.576 3.1 9.958 7.713.056.187.056.387 0 .574C20.576 16.9 16.574 20 12 20s-8.576-3.1-9.958-7.713c-.056-.187-.056-.387 0-.574C3.424 7.1 7.426 4 12 4zm0 2c-3.589 0-6.749 2.398-7.951 6 1.202 3.602 4.362 6 7.951 6 3.589 0 6.75-2.398 7.951-6C18.75 8.398 15.589 6 12 6zm0 2c2.206 0 4 1.794 4 4s-1.794 4-4 4-4-1.794-4-4 1.794-4 4-4z"
      />
    </svg>
  );
};

EyeIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
