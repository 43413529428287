import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const InfoIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <path
              d="M14 17h-4c-.552 0-1-.447-1-1 0-.553.448-1 1-1h1v-3h-1c-.552 0-1-.447-1-1 0-.553.448-1 1-1h2c.552 0 1 .447 1 1v4h1c.552 0 1 .447 1 1 0 .553-.448 1-1 1M12 6c.829 0 1.5.672 1.5 1.5S12.829 9 12 9s-1.5-.672-1.5-1.5S11.171 6 12 6m0-4C6.477 2 2 6.478 2 12s4.477 10 10 10 10-4.478 10-10S17.523 2 12 2"
              transform="translate(-100 -1623) translate(100 1623)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

InfoIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
