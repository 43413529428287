import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const LinkIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={28} viewBox="0 0 16 28" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path d="M4 17.245v.584a4 4 0 1 0 8 0v-3a4 4 0 0 0-4-4v2a2 2 0 0 1 2 2v3a2 2 0 1 1-4 0v-.584H4z" fill={fill} />
      <path d="M12 9.829a4 4 0 1 0-8 0v3a4 4 0 0 0 4 4v-2a2 2 0 0 1-2-2v-3a2 2 0 1 1 4 0h2z" fill={fill} />
    </svg>
  );
};

LinkIcon.defaultProps = {
  fill: settings.colors.Primary.Grey_6,
};
