import { isDefined } from '@rhim/utils';
import { AreaStack as VisxAreaStack } from '@visx/shape';
import { Series } from 'd3-shape';
import React from 'react';

import { ShapeType, ThemeConfig } from '../theme';

export const AREASHAPE_COLORS = [
  '#8451cd',
  '#3ea5ea',
  '#307a7a',
  '#af4372',
  '#f86e75',
  '#763336',
  '#44965d',
  '#3054ad',
  '#ee729f',
  '#be9b30',
  '#30aeab',
  '#314f6a',
  '#9e5d30',
  '#b488fc',
];

export const getAreaShapeTheme = (keyLabels: Array<{ key: string; label: string }>, colors?: string[]): ThemeConfig => {
  const datasetObj = keyLabels.reduce((acc, cur, idx) => {
    return {
      ...acc,
      [cur.key]: {
        type: ShapeType.AreaShape,
        fill: (colors ?? AREASHAPE_COLORS).at(idx % AREASHAPE_COLORS.length) ?? '',
        label: cur.label,
      },
    };
  }, {});

  return {
    datasets: datasetObj,
  };
};

interface Props<T, StackKey> extends Omit<React.ComponentProps<typeof VisxAreaStack<T>>, 'children'> {
  colors?: string[];
  onStacks?: (stacks: Series<T, StackKey>[]) => void;
}

function AreaStack<T, StackKey>(props: Props<T, StackKey>) {
  const { colors, onStacks } = props;

  const fillColors = isDefined(colors) ? colors : AREASHAPE_COLORS;

  return (
    <VisxAreaStack {...props}>
      {({ stacks, path }) => {
        if (isDefined(onStacks)) {
          onStacks(stacks as Series<T, StackKey>[]);
        }

        return stacks.map((stack, i) => {
          const fillColor = fillColors.at(i % fillColors.length);

          return (
            <path
              key={`stack-${stack.key}`}
              className={`stack-${stack.key}-${i}`}
              d={path(stack) ?? undefined}
              fill={fillColor}
              filter={`drop-shadow(0 0.5px 0 ${fillColor})`}
            />
          );
        });
      }}
    </VisxAreaStack>
  );
}

AreaStack.whyDidYouRender = true;

export default React.memo(AreaStack) as typeof AreaStack;
