export interface ColorRGB {
  red: number;
  green: number;
  blue: number;
}

export interface ColorScale {
  threshold: number;
  color: string;
  rgb?: ColorRGB;
}

export type ColorScales = NonEmptyArray<ColorScale>;

/**
 * Heatmap color scale for Ladle lining
 * Threshold is the minimum remaining brick length it has to have in order to be colored with that scale
 */
export const colorScalesForLadle: ColorScales = [
  { threshold: 180, color: '#c0c0ff', rgb: { red: 192, green: 192, blue: 255 } },
  { threshold: 160, color: '#80ffff', rgb: { red: 128, green: 255, blue: 255 } },
  { threshold: 140, color: '#80ffc0', rgb: { red: 128, green: 255, blue: 192 } },
  { threshold: 120, color: '#80ff7f', rgb: { red: 128, green: 255, blue: 127 } },
  { threshold: 100, color: '#c0ff7f', rgb: { red: 192, green: 255, blue: 127 } },
  { threshold: 80, color: '#e0ff7f', rgb: { red: 224, green: 255, blue: 127 } },
  { threshold: 60, color: '#fcc189', rgb: { red: 252, green: 193, blue: 137 } },
  { threshold: 40, color: '#ffa07f', rgb: { red: 255, green: 160, blue: 127 } },
  { threshold: 20, color: '#ff5f5f', rgb: { red: 255, green: 95, blue: 95 } },
  { threshold: 0, color: '#ff3f3c', rgb: { red: 255, green: 63, blue: 60 } },
];

export const colorScalesForGOB: ColorScales = [
  { threshold: 700, color: '#C0C1F8' },
  { threshold: 650, color: '#9FDEFE' },
  { threshold: 600, color: '#81FEFE' },
  { threshold: 550, color: '#86FCC0' },
  { threshold: 500, color: '#7BFF9D' },
  { threshold: 450, color: '#86FD7D' },
  { threshold: 400, color: '#95FF75' },
  { threshold: 350, color: '#DFFF77' },
  { threshold: 300, color: '#FFFF7F' },
  { threshold: 250, color: '#FCDF76' },
  { threshold: 200, color: '#FFBD7E' },
  { threshold: 150, color: '#FEA07F' },
  { threshold: 100, color: '#FF5F63' },
  { threshold: 50, color: '#F15652' },
  { threshold: 0, color: '#FF3E41' },
];

/**
 * A default color scale for Wear data with some negative values (negative values imply "slag build up")
 */
export const colorScalesForWear: ColorScales = [
  { threshold: 120, color: '#800026' },
  { threshold: 110, color: '#980924' },
  { threshold: 100, color: '#af1b25' },
  { threshold: 90, color: '#c32e26' },
  { threshold: 80, color: '#d54228' },
  { threshold: 70, color: '#e5572c' },
  { threshold: 60, color: '#f16e31' },
  { threshold: 50, color: '#fb843a' },
  { threshold: 40, color: '#ff9d46' },
  { threshold: 30, color: '#ffb75a' },
  { threshold: 20, color: '#ffce74' },
  { threshold: 10, color: '#ffe49a' },
  { threshold: 0, color: '#f7f7f7' },
  { threshold: -10, color: '#d4ecdf' },
  { threshold: -20, color: '#b1e0d2' },
  { threshold: -30, color: '#8ed3c8' },
  { threshold: -40, color: '#74c3c3' },
];

/**
 * Heatmap color scale for BOF converter lining
 * Threshold is the minimum remaining brick length it has to have in order to be colored with that scale
 */
export const colorScalesForBOF: ColorScales = [
  { threshold: 700, color: '#c4befe', rgb: { red: 196, green: 190, blue: 254 } },
  { threshold: 650, color: '#9fdffe', rgb: { red: 159, green: 223, blue: 254 } },
  { threshold: 600, color: '#81fffe', rgb: { red: 129, green: 255, blue: 254 } },
  { threshold: 550, color: '#82fdc1', rgb: { red: 130, green: 253, blue: 193 } },
  { threshold: 500, color: '#7dffa2', rgb: { red: 125, green: 255, blue: 162 } },
  { threshold: 450, color: '#81ff81', rgb: { red: 129, green: 255, blue: 129 } },
  { threshold: 400, color: '#9eff80', rgb: { red: 158, green: 255, blue: 128 } },
  { threshold: 350, color: '#e0ff7d', rgb: { red: 224, green: 255, blue: 125 } },
  { threshold: 300, color: '#feff81', rgb: { red: 254, green: 255, blue: 129 } },
  { threshold: 250, color: '#fde278', rgb: { red: 253, green: 226, blue: 120 } },
  { threshold: 200, color: '#fcc089', rgb: { red: 252, green: 192, blue: 137 } },
  { threshold: 150, color: '#ffa07f', rgb: { red: 255, green: 160, blue: 127 } },
  { threshold: 100, color: '#ff6060', rgb: { red: 255, green: 96, blue: 96 } },
  { threshold: 50, color: '#ff5051', rgb: { red: 255, green: 80, blue: 81 } },
  { threshold: 0, color: '#ff3f3c', rgb: { red: 255, green: 63, blue: 60 } },
];

/**
 * Heatmap color scale for LCS
 * Threshold is the minimum remaining brick length it has to have in order to be colored with that scale
 */
export const colorScalesForLCS: ColorScales = [
  { threshold: 180, color: '#c0c0ff', rgb: { red: 192, green: 192, blue: 255 } },
  { threshold: 160, color: '#80ffff', rgb: { red: 128, green: 255, blue: 255 } },
  { threshold: 140, color: '#80ffc0', rgb: { red: 128, green: 255, blue: 192 } },
  { threshold: 120, color: '#80ff7f', rgb: { red: 128, green: 255, blue: 127 } },
  { threshold: 100, color: '#c0ff7f', rgb: { red: 192, green: 255, blue: 127 } },
  { threshold: 80, color: '#e0ff7f', rgb: { red: 224, green: 255, blue: 127 } },
  { threshold: 60, color: '#fcc189', rgb: { red: 252, green: 193, blue: 137 } },
  { threshold: 40, color: '#ffa07f', rgb: { red: 255, green: 160, blue: 127 } },
  { threshold: 20, color: '#ff5f5f', rgb: { red: 255, green: 95, blue: 95 } },
  { threshold: 0, color: '#ff3f3f', rgb: { red: 255, green: 63, blue: 63 } },
];

export const fallbackColor = 'white';
export const fallbackColorRGB = { red: 255, green: 255, blue: 255 }; // white

/**
 * Gets the color based on a color scale.
 * @param value Value to be mapped to a color
 * @param scale Scale to be used for determining the final color
 * @returns Colour HEX code.
 */
export function getColorUsingScale(value: number, colorScales: ColorScales, fallback = fallbackColor) {
  const scale = colorScales.find((colorScale) => value >= colorScale.threshold);
  return scale?.color ?? fallback;
}

export function getColorUsingScaleStartFromEnd(value: number, colorScales: ColorScales, nullThicknessColorAsHexCode: string) {
  if (Number.isNaN(value)) {
    return nullThicknessColorAsHexCode;
  }
  for (let i = colorScales.length - 1; i >= 0; i--) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const colorScale = colorScales[i]!;
    if (value >= colorScale.threshold) {
      return colorScale.color;
    }
  }
  return colorScales[0].color;
}

export function getColorRGBUsingScale(value: number, colorScales: ColorScales, fallback = fallbackColorRGB) {
  const scale = colorScales.find((colorScale) => value >= colorScale.threshold);
  return scale?.rgb || fallback;
}

export function rgbToRGBZeroToOneScale(color: ColorRGB): ColorRGB {
  return { red: color.red / 255, green: color.green / 255, blue: color.blue / 255 };
}

/**
 * @see https://stackoverflow.com/a/51567564/10325032
 */
export const isBrighterThan =
  (luminance: number) =>
  (color: Hex): boolean => {
    const rgb = color.substr(1);

    const reds = parseInt(rgb.substr(0, 2), 16);
    const greens = parseInt(rgb.substr(2, 2), 16);
    const blacks = parseInt(rgb.substr(4, 2), 16);

    const brightness = (reds * 299 + greens * 587 + blacks * 114) / 1000;

    return brightness > luminance;
  };
