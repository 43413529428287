import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  height?: Property.Height;
  width?: Property.Width;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const ChevronUpIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ height, width, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <path
              d="M4.293 13.293c-.39.39-.39 1.024 0 1.414.39.39 1.024.39 1.414 0l6-6c.39-.39.39-1.024 0-1.414l-6-6c-.39-.39-1.024-.39-1.414 0-.39.39-.39 1.024 0 1.414L9.586 8l-5.293 5.293z"
              transform="translate(-35 -968) matrix(1 0 0 -1 35 984) rotate(90 8 8)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

ChevronUpIcon.defaultProps = {
  height: '16',
  width: '16',
  fill: settings.colors.Primary.Blue_9,
};
