import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  backgroundColor?: Property.Color;
  foregroundColor?: Property.Color;
  title?: string;
  role?: string;
}

export const ValidIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, backgroundColor, foregroundColor, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <defs>
        <filter id="w12gaepqqa" width="114.6%" height="245.8%" x="-7.3%" y="-72.9%" filterUnits="objectBoundingBox">
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="8" />
          <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.239215686 0 0 0 0 0.333333333 0 0 0 0 0.403921569 0 0 0 0.1 0" />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g filter="url(#w12gaepqqa)" transform="translate(-672 -1515) translate(652 1479) translate(0 24)">
              <g>
                <path fill={backgroundColor} d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2" transform="translate(20 12)" />
                <path
                  fill={foregroundColor}
                  d="M17.8 9.6l-6 8c-.174.232-.44.377-.729.397-.023.002-.047.003-.071.003-.264 0-.519-.105-.707-.293l-4-4c-.39-.391-.39-1.024 0-1.414.391-.391 1.024-.391 1.414 0l3.185 3.184L16.2 8.4c.331-.441.958-.531 1.4-.2.442.331.532.958.2 1.4"
                  transform="translate(20 12)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

ValidIcon.defaultProps = {
  size: '24',
  foregroundColor: settings.colors.Monochromatic.Black,
  backgroundColor: settings.colors.Operational.State_Notif_Green_2,
};
