/* eslint-disable @typescript-eslint/ban-types */
/**
 * @see https://github.com/Microsoft/TypeScript/pull/12253#issuecomment-263132208
 */
export const UNSAFE_keys = <T extends object>(source: T): (keyof T)[] => Object.keys(source) as (keyof T)[];
export const UNSAFE_entries = <T extends Entry<K, V>, K extends PropertyKey, V>(source: Record<K, V>): T[] => Object.entries(source) as T[];
export const UNSAFE_values = <T extends object>(source: T): T[keyof T][] => Object.values(source) as T[keyof T][];

export const pick = <T extends object>(source: T, configuration: Partial<Record<keyof T, boolean>>): Partial<T> =>
  UNSAFE_keys(source).reduce(
    (cumulus, property) =>
      configuration[property] === true
        ? Object.assign(cumulus, {
            [property]: source[property],
          })
        : cumulus,
    {} as Partial<T>
  );

export const partial =
  <T = never>() =>
  <K extends keyof T>(source: Pick<T, K>) =>
    source;

/**
 * Smart identity function. It guarantees the provided argument is a subtype
 * of `T` while inferring its literal properties at the same time.
 */
export const specific =
  <T>() =>
  <U extends T>(argument: U) =>
    argument;

export const toFormData = (payload: Dictionary<string | Blob>): FormData =>
  Object.entries(payload).reduce<FormData>((accumulator, [key, value]) => {
    accumulator.append(key, value);

    return accumulator;
  }, new FormData());

// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
export const isObject = (candidate: unknown): candidate is object => typeof candidate === 'object' && candidate !== null;
export const isEmptyObject = (candidate: object) => Object.entries(candidate).length === 0;

export const isDictionaryLike = (candidate: unknown): candidate is DictionaryLike => isObject(candidate);

/**
 * @example
 *
 *   const map = new Map([
 *     ['a', 3],
 *     ['b', 1],
 *     ['c', 15],
 *     ['d', 3],
 *   ] as const);
 *
 *   flip(map).get(0); // Compile-time error (0 doesn't exist in map)
 *   flip(map).get(3); // ['a', 'd']
 */
export const flipMap = <T, U>(map: ReadonlyMap<T, U>): ReadonlyMap<U, [T, ...T[]]> =>
  Array.from(map.entries()).reduce((accumulator, [key, value]) => {
    if (accumulator.has(value)) {
      const current = accumulator.get(value);
      current.push(key);
    } else {
      accumulator.set(value, [key]);
    }

    return accumulator;
  }, new Map<U, [T, ...T[]]>());

export function assign<T extends object, U extends object>(target: T, source: U): asserts target is T & U {
  void Object.assign(target, source);
}

export function fromEntries<T>(entries: [keyof T, T[keyof T]][]): T {
  return entries.reduce<T>((accumulator, [key, value]) => ({ ...accumulator, [key]: value }), {} as T);
}
