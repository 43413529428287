import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const LadleIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M15.015 0l.109.01c.548.067.936.567.868 1.115l-.619 4.951c.367.148.627.505.627.924v2c0 .553-.448 1-1 1-.041 0-.075-.018-.114-.023l-.644 5.147c-.062.5-.488.876-.992.876H2.75c-.504 0-.93-.376-.992-.876l-.644-5.147C1.075 9.982 1.04 10 1 10c-.552 0-1-.447-1-1V7c0-.419.26-.776.627-.924l-.619-4.95C-.061.576.328.076.876.008c.545-.075 1.048.32 1.116.868L3.633 14h8.734L14.008.877c.068-.549.567-.943 1.116-.868zM4.934 1.001c.876 0 1.645.154 2.535.332 1.046.209 2.229.446 3.687.446.289 0 .576-.007.862-.02l-1.072 10.908H5.145L3.983 1.037c.294-.022.612-.036.95-.036z"
      />
    </svg>
  );
};

LadleIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Grey_8,
};
