import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const BackToOverviewIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <g>
              <path
                d="M13 14.242l2 2V20h4v-2.182c.415-.147.8-.377 1.121-.697.585-.585.877-1.353.877-2.12H21v6c0 .552-.448 1-1 1h-6c-.552 0-1-.448-1-1v-6.759zM10 13c.552 0 1 .447 1 1v7c0 .553-.448 1-1 1H4c-.552 0-1-.447-1-1v-7c0-.553.448-1 1-1h6zm-1 2H5v5h4v-5zm8.293-6.707c.391-.391 1.023-.391 1.414 0s.391 1.023 0 1.414L17.414 11H23c.552 0 1 .447 1 1 0 .553-.448 1-1 1h-5.586l1.293 1.293c.391.391.391 1.023 0 1.414-.195.195-.451.293-.707.293-.256 0-.512-.098-.707-.293l-3-3c-.391-.391-.391-1.023 0-1.414zM10 2c.552 0 1 .447 1 1v7c0 .553-.448 1-1 1H4c-.552 0-1-.447-1-1V3c0-.553.448-1 1-1h6zm10 0c.552 0 1 .447 1 1v6c-.001-.768-.294-1.536-.879-2.121-.321-.32-.705-.55-1.121-.697V4h-4v3.758l-.121.12L13 9.759V3c0-.553.448-1 1-1zM9 4H5v5h4V4z"
                transform="translate(-100.000000, -294.000000) translate(80.000000, 282.000000) translate(20.000000, 12.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

BackToOverviewIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_1,
};
