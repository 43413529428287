import { SupportedLanguageIsoCode, toUnicodeLocale } from '@rhim/i18n';
import { FC } from 'react';
import { UnitSystem } from 'typings';

import { formatNumber } from './FormattedNumber';

const METRIC_MM_TO_IMPERIAL_IN = 0.0393701;
const METRIC_M_TO_IMPERIAL_YD = 1.09361;

export enum MetricLengthUnit {
  mm = 'mm',
  cm = 'cm',
  m = 'm',
}
enum ImperialLengthUnit {
  inch = 'in',
  yard = 'yd',
}

export const convertUnit = (sourceUnit: MetricLengthUnit, targetUnitSystem: UnitSystem): string => {
  if (targetUnitSystem === 'metric') {
    return sourceUnit;
  }
  switch (sourceUnit) {
    case MetricLengthUnit.mm:
    case MetricLengthUnit.cm:
      return ImperialLengthUnit.inch;
    case MetricLengthUnit.m:
      return ImperialLengthUnit.yard;
    default:
      throw new Error(`Unhandled source unit : ${sourceUnit}`);
  }
};

export const convertLength = (
  lengthValue: number,
  sourceUnit: MetricLengthUnit,
  targetUnitSystem: UnitSystem,
  minimumFractionDigits: number | undefined,
  maximumFractionDigits: number,
  locale: SupportedLanguageIsoCode,
  showUnits: boolean
): string => {
  let convertedValue, convertedValueUnit;
  if (targetUnitSystem === 'metric') {
    convertedValue = lengthValue;
    convertedValueUnit = sourceUnit;
  } else {
    switch (sourceUnit) {
      case MetricLengthUnit.mm:
        convertedValue = lengthValue * METRIC_MM_TO_IMPERIAL_IN;
        convertedValueUnit = ImperialLengthUnit.inch;
        break;
      case MetricLengthUnit.cm:
        convertedValue = 10 * lengthValue * METRIC_MM_TO_IMPERIAL_IN;
        convertedValueUnit = ImperialLengthUnit.inch;
        break;
      case MetricLengthUnit.m:
        convertedValue = lengthValue * METRIC_M_TO_IMPERIAL_YD;
        convertedValueUnit = ImperialLengthUnit.yard;
        break;
      default:
        throw new Error(`Unhandled source-unit : ${sourceUnit}`);
    }
  }
  const unicodeLocale = toUnicodeLocale(locale);
  const formattedNumber = formatNumber(maximumFractionDigits, minimumFractionDigits)(unicodeLocale)(convertedValue);

  return `${formattedNumber}${showUnits ? convertedValueUnit : ''}`;
};

/**
 * Returns a span containing a length value converted to the user's selected Metric unit (Metric, Imperial)
 * @param lengthValue a number expressing length's value
 * @param sourceUnit the provided lengthValue's unit
 * @param minimumFractionDigits the number of digits after the decimal point
 * @param maximumFractionDigits the number of digits after the decimal point
 * @param showUnits whether to display the value's unit or not ( e.g "5mm" vs just "5")
 */
interface FormattedLengthProps {
  locale: SupportedLanguageIsoCode;
  lengthValue: number;
  sourceUnit: MetricLengthUnit;
  targetUnitSystem: UnitSystem;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  showUnits?: boolean;
}
export const FormattedLength: FC<React.PropsWithChildren<FormattedLengthProps>> = ({
  locale,
  lengthValue,
  sourceUnit,
  targetUnitSystem,
  minimumFractionDigits,
  maximumFractionDigits = 2,
  showUnits = true,
}) => {
  return <span>{convertLength(lengthValue, sourceUnit, targetUnitSystem, minimumFractionDigits, maximumFractionDigits, locale, showUnits)}</span>;
};
