import { settings } from '@rhim/design';
import { OpsStateCriticalIcon, UnhealthyIcon } from '@rhim/icons/32';
import { modalDialogCancelButton, modalDialogDescription, modalDialogHeader, modalDialogPrimaryButton, modalDialogSecondaryButton } from '@rhim/test-ids';
import React, { ChildlessComponent, ComponentProps, ReactElement } from 'react';
import styled from 'styled-components';

import { Button } from '../Button/Button';
import { Modal } from '../Modal';

type Mode = 'informational' | 'alert' | 'warning';

type FooterButton = ReactElement<ComponentProps<typeof Button>>;

interface Props extends Omit<ComponentProps<typeof Modal>, 'children' | 'footer' | 'footerButtonsHorizontalAlignment'> {
  mode: Mode;
  headline: string;
  description: React.ReactNode;
  /**
   * the rightmost "primary" button which will have "filled" mode ( filled background )
   */
  buttonPrimary: FooterButton;
  /**
   * optional, will appear to the left of the primary button and will have "ghost" mode ( transparent background )
   */
  buttonSecondary?: FooterButton;
  /**
   * optional, will appear to the left of the secondary button and will have "ghost" mode ( transparent background )
   */
  buttonCancel?: FooterButton;
}
export const ModalDialog: ChildlessComponent<Props> = ({ mode, headline, description, buttonPrimary, buttonSecondary, buttonCancel, ...rest }) => {
  return (
    <SModal
      width={640}
      footerButtonsHorizontalAlignment={mode === 'informational' ? 'right' : 'center'}
      footer={
        <SButtonsContainer>
          {buttonCancel && React.cloneElement(buttonCancel, { mode: 'ghost', dataTestId: modalDialogCancelButton })}
          {buttonSecondary && React.cloneElement(buttonSecondary, { mode: 'ghost', dataTestId: modalDialogSecondaryButton })}
          {React.cloneElement(buttonPrimary, { mode: 'filled', dataTestId: modalDialogPrimaryButton })}
        </SButtonsContainer>
      }
      {...rest}
    >
      {mode === 'alert' && <SWarningIcon />}
      {mode === 'warning' && <SUnhealthyIcon />}
      <SHeadline data-test-id={modalDialogHeader} mode={mode}>
        {headline}
      </SHeadline>
      <SDescription data-test-id={modalDialogDescription} mode={mode}>
        {description}
      </SDescription>
    </SModal>
  );
};
ModalDialog.whyDidYouRender = true;

const SModal = styled(Modal)`
  .ant-modal-content {
    .ant-modal-body {
      display: flex;
      flex-direction: column;
    }

    .ant-modal-footer {
      margin-top: 0;
    }
  }
`;

const SWarningIcon = styled(OpsStateCriticalIcon)`
  align-self: center;
  margin-bottom: ${settings.Spacing.Spacing_200};
`;

const SUnhealthyIcon = styled(UnhealthyIcon)`
  align-self: center;
  margin-bottom: ${settings.Spacing.Spacing_200};
`;

const SHeadline = styled.div<{ mode: Mode }>`
  align-self: ${(props) => (props.mode === 'informational' ? 'flex-start' : 'center')};
  color: ${settings.colors.Primary.Grey_8};
  font-family: ${settings.typography.FontFamily.Bold};
  font-size: ${settings.typography.FontSize.X_Large};
  line-height: 32px;
  text-align: ${(props) => (props.mode === 'informational' ? 'left' : 'center')};
`;

const SDescription = styled.div<{ mode: Mode }>`
  margin: ${(props) => (props.mode === 'informational' ? settings.Spacing.Spacing_500 : settings.Spacing.Spacing_200)} 0;
  color: ${settings.colors.Primary.Grey_8};
  text-align: ${(props) => (props.mode === 'informational' ? 'left' : 'center')};

  [class^='ant-typography'] {
    font-family: ${settings.typography.FontFamily.Regular};
    font-size: ${settings.typography.FontSize.Small};
  }
`;

const SButtonsContainer = styled.div`
  button:not(:first-of-type) {
    margin-left: ${settings.Spacing.Spacing_100};
  }
`;
