import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const HomeIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <g>
              <path d="M7.35.24a1 1 0 0 1 1.3 0l7 6A1 1 0 0 1 15 8h-1v8H9v-5H7v5H2V8H1a1 1 0 0 1-.65-1.76l7-6z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

HomeIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
