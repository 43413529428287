import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const HeatFireIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M12.169 1.032c.197-.074.419-.015.554.145.025.029 2.492 2.947 4.142 5.423 1.627 2.44 2.418 4.815 2.418 7.258 0 3.938-3.204 7.142-7.141 7.142C8.204 21 5 17.796 5 13.858c0-2.425.668-3.828 1.375-5.104l.202-.362.061-.11c.727-1.3 1.32-2.898 1.326-2.914.068-.185.238-.312.435-.325.192-.005.382.09.474.265 0 0 .976 1.853 1.697 3.232 1.3-3.07 1.275-6.992 1.274-7.036-.002-.21.128-.4.325-.472zm.7 9.597c-.096-.11-.253-.146-.388-.092-.136.055-.221.19-.213.337.001.02.087 1.846-.534 3.27-.357-.706-.847-1.683-.882-1.753-.064-.127-.193-.201-.34-.19-.141.014-.259.113-.298.25-.095.337-.227.648-.367.977-.301.71-.611 1.443-.611 2.53C9.236 17.635 10.6 19 12.278 19c1.678 0 3.043-1.365 3.043-3.042 0-.823-.078-2.04-.751-3.102-.656-1.033-1.659-2.18-1.701-2.227z"
      />
    </svg>
  );
};

HeatFireIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
