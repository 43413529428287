import { assert, enumerate, hasElements, isDefined, toArray } from '@rhim/utils';
import { autobind } from '@typed-decorators/autobind';
import * as React from 'react';
import type { GlobalRole, PlantID, PlantRole, PlantRoleIdentifier, RhimAccountInfo } from 'typings';

export class PermissionManager {
  static parseRoleIdentifier(identifier: PlantRoleIdentifier) {
    const matches = /(\w+)\((.+)\)$/.exec(identifier);
    assert(isDefined(matches) && isDefined(matches[1]) && isDefined(matches[2]), 'Expected role to follow the format: "<plant role name>(<plant ID>)".');

    return {
      plantRole: matches[1] as PlantRole,
      plantId: matches[2] as PlantID,
    };
  }

  static isPlantRoleIdentifier(candidate: string): candidate is PlantRoleIdentifier {
    return isDefined(/(\w+)\((.+)\)$/.exec(candidate));
  }

  static isGlobalRole(candidate: string): candidate is GlobalRole {
    const validGlobalRoles = enumerate<GlobalRole>()('Administrator', 'Operator', 'ONEDGE', 'Onboarding');

    return validGlobalRoles.has(candidate as GlobalRole);
  }

  protected globalRoles: Set<GlobalRole> = new Set();
  protected plantRoles: Map<PlantID, Set<PlantRole>> = new Map();

  constructor(protected account: DeepPartial<RhimAccountInfo>) {
    const roles = account.idTokenClaims?.role ?? account.idTokenClaims?.roles;

    if (isDefined(roles)) {
      for (const role of toArray(roles)) {
        if (PermissionManager.isGlobalRole(role)) {
          this.globalRoles.add(role);
        }

        if (PermissionManager.isPlantRoleIdentifier(role)) {
          const { plantRole, plantId } = PermissionManager.parseRoleIdentifier(role);

          if (this.plantRoles.has(plantId)) {
            this.plantRoles.get(plantId).add(plantRole);
          } else {
            this.plantRoles.set(plantId, new Set([plantRole]));
          }
        }
      }
    }
  }

  @autobind
  hasGlobalAccess(role: GlobalRole): boolean {
    return this.globalRoles.has(role);
  }

  @autobind
  getAssociatedPlants(): PlantID[] {
    return Array.from(this.plantRoles.keys());
  }

  @autobind
  getPlantsWithRole(role: PlantRole): PlantID[] {
    const ids: PlantID[] = [];

    this.plantRoles.forEach((roles, id) => {
      if (roles.has(role)) {
        ids.push(id);
      }
    });

    return ids;
  }

  /**
   * Check if the current user is a plant administrator
   * Note that we're currently missing the information which plant we're in, so all we check for now is if the user has an Administrator role for any plant
   */
  @autobind
  isPlantAdministrator(): boolean {
    return hasElements(this.getPlantsWithRole('Administrator'));
  }

  @autobind
  getRolesForPlant(id: PlantID): PlantRole[] {
    if (!this.plantRoles.has(id)) {
      return [];
    }

    return Array.from(this.plantRoles.get(id));
  }

  @autobind
  getGlobalRoles(): GlobalRole[] {
    return Array.from(this.globalRoles);
  }
}

/**
 * @example
 *
 * ```tsx
 *   import { useAccount, usePermissions } from '@rhim/react';
 *
 *   const account = useAccount();
 *   const { hasGlobalAccess } = usePermissions(account ?? {});
 *
 *   const shouldBeVisible = hasGlobalAccess('Operator');
 * ```
 */
export const usePermissions = (account: DeepPartial<RhimAccountInfo>): PermissionManager => {
  const manager = React.useMemo(() => {
    return new PermissionManager(account);
  }, [account]);

  return manager;
};
