import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const ZoomOutIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g clipPath="url(#vjzntlhcma)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 12A5 5 0 1 0 7 2a5 5 0 0 0 0 10zm0 2a6.97 6.97 0 0 0 4.21-1.406c.028.04.061.077.097.113l2.998 2.998a1 1 0 1 0 1.414-1.414l-2.998-2.998a1.01 1.01 0 0 0-.116-.1A7 7 0 1 0 7 14zM5 6a1 1 0 0 0 0 2h4.24a1 1 0 0 0 0-2H5z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="vjzntlhcma">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

ZoomOutIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
