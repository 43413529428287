import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const UploadIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M8 0a.997.997 0 0 0-.707.293l-3 3a.999.999 0 1 0 1.414 1.414L7 3.414V10a1 1 0 1 0 2 0V3.414l1.293 1.293a.999.999 0 1 0 1.414-1.414l-3-3A.997.997 0 0 0 8 0zm6 10a1 1 0 1 1 2 0v5a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-5a1 1 0 1 1 2 0v4h12v-4z"
      />
    </svg>
  );
};

UploadIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
