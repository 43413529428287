import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const ReportIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 512 512"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      role={role}
      {...rest}
    >
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="nonzero"
        d="M496,384L64,384L64,80C64,71.16 56.84,64 48,64L16,64C7.16,64 0,71.16 0,80L0,416C0,433.67 14.33,448 32,448L496,448C504.84,448 512,440.84 512,432L512,400C512,391.16 504.84,384 496,384ZM464,96L345.94,96C324.56,96 313.85,121.85 328.97,136.97L361.37,169.37L288,242.75L214.63,169.38C202.13,156.88 181.87,156.88 169.38,169.38L100.69,238.07C94.44,244.32 94.44,254.45 100.69,260.7L123.31,283.32C129.56,289.57 139.69,289.57 145.94,283.32L192,237.25L265.37,310.62C277.87,323.12 298.13,323.12 310.62,310.62L406.62,214.62L439.02,247.02C454.14,262.14 479.99,251.43 479.99,230.05L479.99,112C480,103.16 472.84,96 464,96Z"
      />
    </svg>
  );
};

ReportIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
