import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  title?: string;
  role?: string;
  className?: string;
}

export const CheckmarkStateIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, title, role, ...rest }) => {
  return (
    <svg width="32" height="34" viewBox="0 0 32 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="m22 28.2 2.8 2.8 4.2-7" stroke="#fff" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
      <path d="m22 28.2 2.8 2.8 4.2-7" stroke="#3CB63B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

CheckmarkStateIcon.defaultProps = {
  fill: settings.colors.Operational.State_Green_3,
};
