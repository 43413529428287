import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const VideoIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" role={role} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path fillRule="evenodd" clipRule="evenodd" d="M3 4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H3zm13 8L9 8v8l7-4z" fill="#ECEEF0" />
    </svg>
  );
};

VideoIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Grey_4,
};
