import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  stroke?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const CraneTrackIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ stroke, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0h16v2H0V0zm0 16v-2h16v2H0zm8-4a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm1.723-2.984A1.99 1.99 0 0 0 10 8c0-.37-.101-.718-.277-1.016L8.707 8l1.016 1.016zm-.707.707L8 8.707 6.984 9.723A1.99 1.99 0 0 0 8 10c.37 0 .718-.1 1.016-.277zM8 7.293l1.016-1.016A1.99 1.99 0 0 0 8 6a1.99 1.99 0 0 0-1.016.277L8 7.293zm-1.723-.309A1.99 1.99 0 0 0 6 8c0 .37.1.718.277 1.016L7.293 8 6.277 6.984z"
      />
    </svg>
  );
};

CraneTrackIcon.defaultProps = {
  size: '16',
  stroke: settings.colors.Primary.Blue_9,
};
