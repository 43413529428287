import { settings } from '@rhim/design';
import { isDefined } from '@rhim/utils';
import React, { SVGAttributes } from 'react';
import styled from 'styled-components';

import { LegendItemLabel } from './utils';

const STROKE_WIDTH = 2;
const SVG_HEIGHT = 2 * STROKE_WIDTH;

interface Props {
  strokeDasharray?: SVGAttributes<unknown>['strokeDasharray'];
  stroke?: settings.colors.Any;
  label?: string;
}
const Dashed: React.ChildlessComponent<Props> = ({ strokeDasharray = '4 2', stroke = settings.colors.Primary.Blue_9, label }) => {
  return (
    <SWrapper>
      <svg width="24" height={SVG_HEIGHT}>
        <g transform={`translate(0,${SVG_HEIGHT / 2})`}>
          <line x1="0" y1="0" x2="100%" y2="0" stroke={stroke} strokeWidth={STROKE_WIDTH} strokeDasharray={strokeDasharray} />
        </g>
      </svg>
      {isDefined(label) && <LegendItemLabel>{label}</LegendItemLabel>}
    </SWrapper>
  );
};
Dashed.whyDidYouRender = true;
export default React.memo(Dashed);

const SWrapper = styled.div`
  display: flex;
  align-items: center;
`;
