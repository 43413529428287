import { FC, useEffect, useRef } from 'react';

export type DrawFunction = (context: CanvasRenderingContext2D) => void;

const offscreenCanvas: HTMLCanvasElement = document.createElement('canvas');

interface CanvasProps {
  className?: string;
  width: number;
  height: number;
  draw: DrawFunction;
}
const Canvas: FC<React.PropsWithChildren<CanvasProps>> = ({ className, width, height, draw }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas || canvas.width === 0 || canvas.height === 0) {
      return;
    }

    // perform the painting in an off-screen canvas to avoid flickering
    offscreenCanvas.width = canvas.width;
    offscreenCanvas.height = canvas.height;
    const offscreenCanvasContext = offscreenCanvas.getContext('2d');
    if (!offscreenCanvasContext) {
      return;
    }
    draw(offscreenCanvasContext);
    const canvasContext = canvas.getContext('2d');
    if (!canvasContext) {
      return;
    }
    // copy the off-screen canvas to the live canvas in a single operation
    canvasContext.clearRect(0, 0, canvas.width, canvas.height);
    canvasContext.drawImage(offscreenCanvas, 0, 0); //Copy the cache canvas to the old canvas
  });

  return <canvas className={className} ref={canvasRef} width={width} height={height} />;
};

export default Canvas;
