import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const ProfileIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 64 64" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        d="M58.667 7.111H5.333A5.335 5.335 0 000 12.444v39.112a5.335 5.335 0 005.333 5.333h53.334A5.335 5.335 0 0064 51.556V12.444a5.335 5.335 0 00-5.333-5.333zM19.556 17.778a7.118 7.118 0 017.11 7.11A7.118 7.118 0 0119.557 32a7.118 7.118 0 01-7.112-7.111 7.118 7.118 0 017.112-7.111zM32 44.088c0 1.179-1.111 2.134-2.489 2.134H9.6c-1.378 0-2.489-.955-2.489-2.133v-2.133c0-3.534 3.345-6.4 7.467-6.4h.555c1.367.566 2.856.888 4.423.888 1.566 0 3.066-.322 4.422-.888h.555c4.123 0 7.467 2.866 7.467 6.4v2.133zm24.889-5.866c0 .49-.4.89-.89.89H40a.892.892 0 01-.889-.89v-1.778c0-.488.4-.888.889-.888h16c.489 0 .889.4.889.888v1.778zm0-7.11c0 .488-.4.888-.89.888H40a.892.892 0 01-.889-.889v-1.778c0-.489.4-.889.889-.889h16c.489 0 .889.4.889.89v1.777zm0-7.112c0 .489-.4.889-.89.889H40a.892.892 0 01-.889-.889v-1.778c0-.489.4-.889.889-.889h16c.489 0 .889.4.889.89V24z"
        fillRule="nonzero"
        fill={fill}
      ></path>
    </svg>
  );
};

ProfileIcon.defaultProps = {
  size: '64',
  fill: '#fff',
};
