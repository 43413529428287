import { isDefined } from '@rhim/utils';
import { useContext } from 'react';

import { UserSettingsContext, UserSettingsProviderType } from '../providers/UserSettingsProvider';

export const useUserSettings = (): UserSettingsProviderType => {
  const ctx = useContext(UserSettingsContext);

  if (!isDefined(ctx)) {
    throw new Error('useUserSettings used outside of UserSettingsProvider!');
  } else {
    return ctx;
  }
};
