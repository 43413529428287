import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const ReportIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g clipPath="url(#vypa164apa)">
        <path
          fill={fill}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.999 7.991a7.991 7.991 0 0 1-11.618 7.123L.001 16l.885-4.381A7.991 7.991 0 1 1 16 7.992zM7 3.857C7 3.384 7.448 3 8 3s1 .384 1 .857v4.286C9 8.616 8.552 9 8 9s-1-.384-1-.857V3.857zM8 13a1.5 1.5 0 1 1-.001-2.999A1.5 1.5 0 0 1 8 13z"
        />
      </g>
      <defs>
        <clipPath id="vypa164apa">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

ReportIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
