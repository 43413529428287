import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const VesselviewFloorIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <path d="M12.004 14c2.682 0 5.067.778 6.226 2.03.561.609.83 1.317.771 2.048C18.823 20.35 15.881 22 12.004 22c-3.863 0-6.806-1.647-6.996-3.916-.064-.754.201-1.476.765-2.09C6.903 14.764 9.291 14 12.004 14zm0 2c-2.432 0-4.143.677-4.759 1.348-.24.26-.255.449-.245.568.072.852 1.895 2.084 5.004 2.084 3.117 0 4.937-1.228 5.003-2.078.005-.061.02-.246-.246-.533-.618-.67-2.37-1.39-4.757-1.39zM6.21 14.459l.084 1-.996.084-.084-1 .996-.084zm11.588 0l.996.084-.084 1-.996-.084.084-1zM5.96 11.458l.084 1-.996.084-.084-1 .996-.084zm12.088 0l.996.084-.084 1-.996-.084.084-1zm-12.338-3l.083 1-.996.082-.083-1 .996-.082zm12.588 0l.996.082-.083 1-.996-.082.083-1zm-8.01-.05c.31.033.63.058.956.074l-.047.998c-.349-.016-.687-.043-1.019-.079zm3.821-.057l.146.99c-.328.048-.668.086-1.018.112l-.078-.996c.326-.026.643-.06.95-.106zm-6.577-.583c.276.109.573.207.887.292l-.262.965c-.353-.095-.685-.205-.992-.327zm9.29-.176l.42.908c-.3.139-.628.266-.979.38l-.308-.951c.311-.101.601-.214.867-.337zM5.502 5.959l.025.3.043-.015c.079.21.226.42.436.622l-.693.72c-.318-.306-.547-.64-.68-.99l.219-.082-.305.025-.041-.498.996-.082zm13.004-.003l.998.06c0 .008-.005.016-.006.025h.004l-.04.498-.084-.007c-.083.234-.195.458-.363.672l-.787-.617c.166-.212.26-.425.278-.63zM6.254 3.68l.467.885c-.29.152-.533.314-.726.484l-.658-.755c.25-.218.56-.425.917-.614zM17.3 3.462c.351.156.67.326.946.51l-.553.834c-.229-.152-.497-.297-.796-.428zm-8.08-.726l.175.984c-.322.058-.634.124-.931.2l-.248-.97c.32-.081.656-.153 1.005-.214zm5.089-.077c.349.05.686.11 1.01.18l-.211.978c-.301-.065-.616-.122-.94-.167zm-2.562-.16l.26.001.265.002-.012 1-.256-.002c-.243-.006-.475.005-.706.015l-.045-.998c.247-.012.486-.023.753-.017z" />
        </g>
      </g>
    </svg>
  );
};

VesselviewFloorIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
