import { settings } from '@rhim/design';
import { getBrowserLocale } from '@rhim/i18n';
import { ChevronFilledDownIcon, ChevronFilledUpIcon } from '@rhim/icons/8';
import { OpsStateCriticalOutlineIcon } from '@rhim/icons/16';
import React, { useEffect } from 'react';
import { useMeasure } from 'react-use';
import styled from 'styled-components';
import { UnitSystem } from 'typings';

import { FormattedLength, MetricLengthUnit } from '../../partials/FormattedLength';

export const CELL_WIDTH_PX = 32;
export const VALUE_INDICATOR_LINE_HEIGHT_PX = 2;
export const VALUES_INDICATOR_LEFT_EXTENDS_PX = 8;
const VALUES_INDICATOR_RIGHT_EXTENDS_PX = 4;
export const VALUES_INDICATOR_LABEL_HORIZONTAL_MARGIN_PX = 8;

interface Props {
  unitSystem: UnitSystem;
  topOffset: number;
  leftOffset: number;
  value: number;
  isArrowFacingUp: boolean;
  isCritical: boolean;
  isOutOfBounds: boolean;
  onIndicatorLabelWidthChanged: (width: number) => void;
}
const ValueIndicator: React.ChildlessComponent<Props> = ({
  unitSystem,
  topOffset,
  leftOffset,
  value,
  isArrowFacingUp,
  isCritical,
  isOutOfBounds,
  onIndicatorLabelWidthChanged,
}) => {
  const [labelRef, { width: labelWidth }] = useMeasure<HTMLSpanElement>();
  const locale = getBrowserLocale();

  useEffect(() => {
    onIndicatorLabelWidthChanged(labelWidth);
  }, [labelWidth, onIndicatorLabelWidthChanged]);

  return (
    <SValueIndicator top={topOffset} left={leftOffset}>
      <SIndicatorValue ref={labelRef} isCritical={isCritical}>
        <FormattedLength locale={locale} lengthValue={Math.round(value)} sourceUnit={MetricLengthUnit.mm} targetUnitSystem={unitSystem} showUnits={false} />
      </SIndicatorValue>
      <SValueIndicatorIcon isArrowFacingUp={isArrowFacingUp}>
        {isArrowFacingUp ? <ChevronFilledUpIcon fill={settings.colors.Primary.Grey_8} /> : <ChevronFilledDownIcon fill={settings.colors.Primary.Grey_8} />}
      </SValueIndicatorIcon>
      {isCritical && <SOpsStateCriticalOutlineIcon />}
      {isOutOfBounds && <SOutOfBoundsIcon isFacingUp={!isArrowFacingUp} />}
    </SValueIndicator>
  );
};

const SValueIndicator = styled.div<{
  top: number;
  left: number;
}>`
  pointer-events: none;
  position: absolute;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left - VALUES_INDICATOR_LEFT_EXTENDS_PX}px;
  transition: top;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  width: ${CELL_WIDTH_PX + VALUES_INDICATOR_LEFT_EXTENDS_PX + VALUES_INDICATOR_RIGHT_EXTENDS_PX}px;
  background-color: ${settings.colors.Primary.Grey_8};
  height: ${VALUE_INDICATOR_LINE_HEIGHT_PX}px;
`;

const SValueIndicatorIcon = styled.span<{ isArrowFacingUp: boolean }>`
  display: flex;
  align-items: center;
  position: absolute;
  top: ${(props) => (props.isArrowFacingUp ? '-5px' : '1px')};
  right: -1px;
`;

const SIndicatorValue = styled.span<{ isCritical: boolean }>`
  position: absolute;
  top: 50%;
  left: calc(-1px * ${VALUES_INDICATOR_LABEL_HORIZONTAL_MARGIN_PX});
  transform: translate(-100%, -50%);
  font-size: ${settings.typography.FontSize.X_Small};
  font-family: ${settings.typography.FontFamily.Bold};
  color: ${(props) => (props.isCritical ? settings.colors.Operational.State_Alert_Red_4 : settings.colors.Primary.Grey_8)};
`;

const SOpsStateCriticalOutlineIcon = styled(OpsStateCriticalOutlineIcon)`
  position: absolute;
  left: ${VALUES_INDICATOR_LEFT_EXTENDS_PX}px;
  top: 0;
  transform: translate(-50%, -50%);
`;

const SOutOfBoundsIcon = styled.div<{ isFacingUp: boolean }>`
  --borderLeftRightWidth: 5px;

  position: absolute;
  width: 0;
  height: 0;
  border-top-width: 0;
  border-bottom-width: 8px;
  border-left-width: var(--borderLeftRightWidth);
  border-right-width: var(--borderLeftRightWidth);
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: ${settings.colors.Operational.State_Notif_Magenta_3};
  border-style: solid;
  bottom: ${(props) => (props.isFacingUp ? '4px' : '-2px')};
  left: calc(${VALUES_INDICATOR_LEFT_EXTENDS_PX}px + ${CELL_WIDTH_PX / 2}px);
  transform-origin: bottom center;
  transform: translateX(-50%) rotate(${(props) => (props.isFacingUp ? 0 : '180deg')});
`;

export default React.memo(ValueIndicator);
