import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const LastHeatIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      role={role}
      {...rest}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      {isDefined(title) ? <title>{title}</title> : null}
      <g id="QCK-Nav" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="QCK-Nav-v2-LadleStrip-Interactions" transform="translate(-264.000000, -737.000000)" fill={fill}>
          <g id="Group-29-Copy" transform="translate(145.000000, 689.000000)">
            <g id="Icons/LastHeat_16" transform="translate(119.000000, 48.000000)">
              <path
                d="M8.5,14 C7.12151777,14 6,12.8564888 6,11.4505007 C6,9.88818456 6.79170669,8.66361432 6.82532821,8.61246993 C6.89257125,8.51114615 6.99503682,8.44552694 7.10310599,8.45421184 C7.21117515,8.45614182 7.31203971,8.51983105 7.37528018,8.62404979 C7.52017291,8.86047194 7.87239833,9.41341028 8.17659302,9.81967038 C8.44956772,9.0303099 8.53362152,7.86170898 8.54162664,7.40430449 C8.54482869,7.24411642 8.6232789,7.10226313 8.74575728,7.03664392 C8.86743516,6.97005973 9.00992635,6.99611441 9.11079091,7.09936817 C9.18764009,7.17849721 11,9.05829456 11,11.4505007 C11,12.8564888 9.87848223,14 8.5,14 M3,16 C2.44771525,16 2,15.5522847 2,15 C2,14.4477153 2.44771525,14 3,14 L3.51725962,14.0009455 C2.56515896,12.9076438 2,11.4383145 2,9.77790389 C2,7.71287199 2.67028571,6.51885354 3.37914286,5.253834 C3.82742857,4.45682169 4.29114286,3.63080893 4.67685714,2.47379106 C4.74714286,2.2627878 4.91685714,2.10278533 5.12257143,2.13278579 C5.31885714,2.14078592 5.48942857,2.28978822 5.54857143,2.50679157 C5.72257143,3.1498015 6.20771429,4.70682555 6.722,5.66484035 C7.55428571,3.49180679 7.63314286,0.550761356 7.63314286,0.518760862 C7.63828571,0.302757525 7.75485714,0.111754575 7.92971429,0.0347533851 C8.10371429,-0.0412477889 8.30085714,0.00975299895 8.42942857,0.164755393 C9.00714286,0.865766222 9.578,1.49777598 10.1197143,2.09878527 C12.2017143,4.40482089 14,6.39685166 14,10.0139075 C14,11.6009933 13.4999503,12.9723953 12.627674,14.0006146 L13,14 C13.5522847,14 14,14.4477153 14,15 C14,15.5522847 13.5522847,16 13,16 L3,16 Z"
                id="Fill-1"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

LastHeatIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Grey_6,
};
