import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const FileSTLIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <path
              d="M16 2l4 4v13.995C20 21.103 19.103 22 17.996 22H6.005C4.898 22 4 21.103 4 19.995V4.005C4 2.897 4.898 2 6.005 2H16zm1 5h-6.432l-2.821 3H7v7h7v-.524l3-3.068V7zm-5 5v3H9v-3h3zm3-1.568v2.159l-1 1.023v-2.197l1-.985zm-1.395-1.433L12.589 10H10.49l.941-1 2.174-.001z"
              transform="translate(-377 -536) translate(377 536)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

FileSTLIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
