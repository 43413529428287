import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const DoneIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 33 32" fill="none" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path fillRule="evenodd" clipRule="evenodd" d="M30.375 16c0 7.732-6.268 14-14 14s-14-6.268-14-14 6.268-14 14-14 14 6.268 14 14z" fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.375 22a1 1 0 0 1-.747-.335l-4-4.495a1 1 0 0 1 1.494-1.329l3.159 3.549 6.275-8.964a1 1 0 0 1 1.638 1.147l-7 10a1.001 1.001 0 0 1-.76.426l-.06.001z"
        fill="#fff"
      />
    </svg>
  );
};

DoneIcon.defaultProps = {
  size: '32',
  fill: settings.colors.Primary.Blue_9,
};
