import config from '@rhim/config';
import React from 'react';

/*
   https://www.npmjs.com/package/@welldone-software/why-did-you-render#tracking-components

   Helps to track down avoidable re-renders
*/

if (config.version === 'dev') {
  /* eslint-disable @typescript-eslint/no-var-requires */
  const whyDidYouRender: typeof import('@welldone-software/why-did-you-render').default = require('@welldone-software/why-did-you-render');

  const ReactRedux = require('react-redux/lib');

  whyDidYouRender(React, {
    trackAllPureComponents: true,
    collapseGroups: true,
    trackHooks: true,
    trackExtraHooks: [[ReactRedux, 'useSelector']],

    /**
     * Ignore components created by Ant
     */
    exclude: [/OperationNode/, /Body/, /Cell/, /Unknown/],
  });
}
