import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const LesIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      role={role}
      {...rest}
    >
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="nonzero"
        d="M32 57.6C17.955 57.6 6.4 46.045 6.4 32 6.4 17.955 17.955 6.4 32 6.4c14.045 0 25.6 11.555 25.6 25.6-.016 14.038-11.562 25.584-25.6 25.6zm0-44.8c-10.534 0-19.2 8.666-19.2 19.2 0 10.534 8.666 19.2 19.2 19.2 10.534 0 19.2-8.666 19.2-19.2-.01-10.528-8.672-19.19-19.2-19.2z"
      />
      <circle cx="2" cy="2" r="2" transform="matrix(3.2 0 0 3.2 51.2 44.8)" fill={fill} />
      <circle cx="2" cy="2" r="2" transform="matrix(3.2 0 0 3.2 0 44.8)" fill={fill} />
      <path
        fill={fill}
        d="M42.826 25.651l-11.2 14.935c-.324.432-.82.704-1.36.742h-.132c-.496 0-.97-.198-1.318-.547l-7.469-7.463a1.875 1.875 0 01-.55-1.324c0-1.03.848-1.879 1.878-1.879.496 0 .973.199 1.325.55l5.946 5.943 9.92-13.21c.352-.47.905-.745 1.49-.745 1.025 0 1.87.841 1.87 1.865 0 .404-.132.797-.375 1.12"
      />
    </svg>
  );
};

LesIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
