import { settings } from '@rhim/design';
import { colorScalesForLadle, getColorUsingScale, hasElements } from '@rhim/utils';
import { FC } from 'react';
import styled from 'styled-components';

import { useLocalization } from '../../hooks';
import { convertLength, MetricLengthUnit } from '../../partials';
import { Tooltip } from '../Tooltip';
import AreasMapCanvas from '../WallplotHeatmap/AreasMapCanvas';
import { Area } from '../WallplotHeatmap/utils';
import { AreaPanelTexts } from './domain';
import { getAreaItemGroups, SNoAreasToDisplay } from './utils';

const CANVAS_WIDTH_PX = 40;
const CANVAS_HEIGHT_PX = 20;

interface AreaSummaryProps {
  value: string;
  color: string;
}
const AreaSummary: FC<React.PropsWithChildren<AreaSummaryProps>> = ({ value, color }) => {
  return <SAreaSummaryWrapper color={color}>{value}</SAreaSummaryWrapper>;
};

export interface WallplotAreasMinifiedProps {
  domainXStart?: number;
  domainXWrap?: number;
  domainXMin: number;
  domainXMax: number;
  domainYMin: number;
  domainYMax: number;
  wallplotAreas: Area[];
  texts: AreaPanelTexts;
}
export const WallplotAreasMinified: FC<React.PropsWithChildren<WallplotAreasMinifiedProps>> = ({
  domainXStart,
  domainXWrap,
  domainXMin,
  domainXMax,
  domainYMin,
  domainYMax,
  wallplotAreas,
  texts,
}) => {
  const [localization] = useLocalization();
  const { unitSystem, locale } = localization;
  const areaItemGroups = getAreaItemGroups(wallplotAreas);

  if (!hasElements(wallplotAreas)) {
    return <SNoAreasToDisplay>{texts.noAreasToDisplay}</SNoAreasToDisplay>;
  }

  return (
    <SWrapper>
      {areaItemGroups.map((areaItemGroup, index) => (
        <SAreasGroupWrapper key={index}>
          <SAreasGroupTitle>{texts.zoneNames[areaItemGroup.groupName] ?? `Zone text not found for : '${areaItemGroup.groupName}'`}</SAreasGroupTitle>
          <SAreasGroupContainer>
            {areaItemGroup.areaItems.map((areaItem, id) => (
              <Tooltip
                key={id}
                title={
                  <>
                    <span>
                      <b>{texts.areaNames[areaItem.areaName] ?? `Area text not found for : '${areaItem.areaName}'`}</b>
                    </span>
                    <br />
                    <span>
                      {texts.minimumThickness} : {convertLength(areaItem.region.minimumThickness, MetricLengthUnit.mm, unitSystem, undefined, 2, locale, true)}
                    </span>
                  </>
                }
                placement="right"
              >
                <SAreasGroup>
                  <SAreasMapCanvas
                    texts={texts}
                    domainXStart={domainXStart}
                    domainXWrap={domainXWrap}
                    metrics={{
                      canvasWidth: CANVAS_WIDTH_PX,
                      canvasHeight: CANVAS_HEIGHT_PX,
                      plotWidth: CANVAS_WIDTH_PX,
                      plotHeight: CANVAS_HEIGHT_PX,
                      domainXMin,
                      domainXMax,
                      domainYMin,
                      domainYMax,
                    }}
                    areas={wallplotAreas}
                    shouldDisplayAreaLabels={false}
                    shouldPaintAreaEdgeOnMapBounds={true}
                    fillColor={{ regionId: areaItem.region.regionId, fillColor: settings.colors.Primary.Grey_8 }}
                  />
                  <AreaSummary
                    value={convertLength(areaItem.region.minimumThickness, MetricLengthUnit.mm, unitSystem, undefined, 2, locale, false)}
                    color={getColorUsingScale(areaItem.region.minimumThickness, colorScalesForLadle)}
                  />
                </SAreasGroup>
              </Tooltip>
            ))}
          </SAreasGroupContainer>
        </SAreasGroupWrapper>
      ))}
    </SWrapper>
  );
};

const SWrapper = styled.div`
  display: inline-block;

  --widthPx: 40px;
  --heightPx: 20px;
`;

const SAreasGroupWrapper = styled.div``;

const SAreasGroupTitle = styled.div`
  height: var(--heightPx);
  display: flex;
  align-items: center;
  user-select: none;
  background-color: ${settings.colors.Primary.Grey_2};
  color: ${settings.colors.Primary.Grey_8};
  font-size: ${settings.typography.FontSize.X_Small};
  padding: 0 6px;
`;

const SAreasGroupContainer = styled.div`
  display: flex;
  flex-direction: column;

  --marginHorizontal: ${settings.Spacing.Spacing_50};

  margin-right: var(--marginHorizontal);
  margin-bottom: ${settings.Spacing.Spacing_200};
  margin-left: var(--marginHorizontal);
`;

const SAreasGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${settings.Spacing.Spacing_100};
`;

interface SAreaSummaryWrapperProps {
  color: string;
}
const SAreaSummaryWrapper = styled.div<SAreaSummaryWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--widthPx);
  height: var(--heightPx);
  user-select: none;
  font: var(--rhim-font-family-regular);
  font-size: ${settings.typography.FontSize.X_Small};
  background-color: ${(props) => props.color};
  color: ${settings.colors.Primary.Grey_8};
`;

const SAreasMapCanvas = styled(AreasMapCanvas)`
  display: block;
`;
