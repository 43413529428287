/* stylelint-disable property-no-vendor-prefix, value-no-vendor-prefix */

import axios, { AxiosError } from 'axios';
import { RcFile } from 'rc-upload/lib/interface';
import { css } from 'styled-components';

export const Firefox = {
  /**
   * @see https://github.com/philipwalton/flexbugs
   */
  collapsibleFlexContainers: css`
    flex-basis: min-content;
  `,
};

export const Webkit = {
  /**
   * In webkit applications, -webkit-box-orient specifies whether a box lays out its contents horizontally or vertically.
   */
  boxOrient: css`
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
  `,
};

export const hideScrollbar = css`
  /* Hide scrollbar for IE, Edge and Firefox TODO: add to workarounds */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    /* Chrome */
    display: none;
  }
`;

export const truncateTextAfterLines = (n = 1) => css`
  /**
   * These prefixes are required (also for Firefox!)
   * https://css-tricks.com/almanac/properties/l/line-clamp/
   */
  display: -webkit-box;
  -webkit-line-clamp: ${n};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

/**
 * @see https://www.smashingmagazine.com/2013/02/setting-weights-and-styles-at-font-face-declaration/
 */
export const preventFauxFontStyle = css`
  /* stylelint-disable-next-line */
  font-style: normal;
  /* stylelint-disable-next-line */
  font-weight: normal;
`;

/**
 * Mocks the error in case axios request fails, to always return the mocked data.
 * @param error Error message.
 * @param file File instance.
 * @param sendResponse Function to be called to return the fake data.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const onErrorMock = (error: AxiosError, file: RcFile, sendResponse?: (_: unknown, xhr: XMLHttpRequest) => void) => {
  if (error instanceof axios.Cancel) return;
  // If the network request fails (for example because we exceeded the rate limit),
  // pretend that it didn't by returning the same set of fake data we always return.
  if (sendResponse) {
    sendResponse(file, {
      response: {
        errors: [],
        warnings: [],
        heat: '',
        campaign: '',
        converter: '',
        uploadDate: '',
      },
    } as XMLHttpRequest);
  }
};
