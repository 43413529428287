import { assert, isDefined } from '@rhim/utils';
import React from 'react';

import ZoomContext from '../context/ZoomContext';
import { UseZoom } from './useZoom';

type ResultType<T> = T extends { isEnabled: false | undefined } ? null : T extends { isEnabled: true } ? UseZoom : UseZoom;

interface Props {
  isEnabled?: boolean;
}
const DEFAULT_PROPS = { isEnabled: true } as const;

export function useZoomContext<T>(props?: T extends Props ? T : null): ResultType<T>;
export function useZoomContext(props?: Props) {
  const _props = { ...DEFAULT_PROPS, ...props };
  const zoomContext = React.useContext(ZoomContext);

  if (_props.isEnabled) {
    assert(isDefined(zoomContext), 'useZoomContext must be inside a ZoomContext');
  }

  return zoomContext;
}
