import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const MinusTinyIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <g>
              <path
                d="M5 7c-.552 0-1 .447-1 1 0 .553.448 1 1 1h6c.552 0 1-.447 1-1 0-.553-.448-1-1-1H5z"
                transform="translate(-345 -517) translate(298 505) translate(47 12)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

MinusTinyIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
