import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const EyeSettingsIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M10 18v-.3C7.3 17 5 14.9 4 12c1.2-3.6 4.4-6 8-6 2.9 0 5.5 1.6 7 4.1.9.1 1.8.4 2.7.8C20.1 6.7 16.3 4 12 4 7.4 4 3.4 7.1 2 11.7v.6c1.2 4 4.4 6.9 8.2 7.6a95 95 0 0 0-.038-.262C10.076 19.048 10 18.524 10 18zm5.6-7.6C15 9 13.6 8 12 8c-2.2 0-4 1.8-4 4 0 1.6 1 3 2.4 3.6.7-2.5 2.7-4.5 5.2-5.2zm7 6.2.9.2c.3.1.5.3.5.6v1.2c0 .3-.2.5-.5.6l-.9.2c-.05.15-.125.3-.2.45-.075.15-.15.3-.2.45l.5.8c.1.2.1.5-.1.7l-.8.8c-.1.1-.2.2-.4.2-.1 0-.2 0-.3-.1l-.8-.5c-.3.2-.6.3-.9.4l-.2.9c-.1.3-.3.5-.6.5h-1.2c-.3 0-.5-.2-.6-.5l-.2-.9c-.15-.05-.3-.125-.45-.2-.15-.075-.3-.15-.45-.2l-.8.5c-.1.1-.2.1-.3.1-.1 0-.3-.1-.4-.2l-.8-.8c-.2-.2-.3-.5-.1-.7l.5-.8c-.2-.3-.3-.6-.4-.9l-.9-.2c-.3-.1-.5-.3-.5-.6v-1.2c0-.3.2-.5.5-.6l.9-.2c.05-.15.125-.3.2-.45.075-.15.15-.3.2-.45l-.5-.8c-.1-.2-.1-.5.1-.7l.8-.8c.2-.2.5-.3.7-.1l.8.5c.3-.2.6-.3.9-.4l.2-.9c.1-.3.3-.5.6-.5h1.2c.3 0 .5.2.6.5l.2.9c.15.05.3.125.45.2.15.075.3.15.45.2l.8-.5c.2-.1.5-.1.7.1l.8.8c.2.2.3.5.1.7l-.5.8c.2.3.3.6.4.9zm-7 1.4c0 1.3 1.1 2.4 2.4 2.4 1.3 0 2.4-1.1 2.4-2.4 0-1.3-1.1-2.4-2.4-2.4-1.3 0-2.4 1.1-2.4 2.4z"
      />
    </svg>
  );
};

EyeSettingsIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
