import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  stroke?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const RotateIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ stroke, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        stroke={stroke}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 8a1.5 1.5 0 1 1 3.001.001A1.5 1.5 0 0 1 6.5 8M12.172 2.086a1 1 0 1 1 2 0v2.828a1 1 0 0 1-1 1h-2.829a1 1 0 0 1 0-2h1.829V2.086z"
      />
      <path
        stroke={stroke}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 13a5 5 0 0 0 5-5 1 1 0 1 1 2 0 7 7 0 1 1-1.502-4.333 1 1 0 0 1-1.57 1.239A5 5 0 1 0 8 13z"
      />
    </svg>
  );
};

RotateIcon.defaultProps = {
  size: '16',
  stroke: settings.colors.Primary.Blue_9,
};
