import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  backgroundColor?: Property.Color;
  foregroundColor?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const AppStateOKIcon = React.forwardRef<SVGSVGElement, Props>(({ backgroundColor, foregroundColor, size, title, role, ...rest }, forwardedRef) => {
  return (
    <svg ref={forwardedRef} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <path fill={backgroundColor} d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2" />
        <path fill={foregroundColor} d="m17.8 9.6-6 8a.998.998 0 0 1-1.507.107l-4-4a1 1 0 0 1 1.414-1.414l3.185 3.184L16.2 8.4a1.001 1.001 0 0 1 1.6 1.2" />
      </g>
    </svg>
  );
});

AppStateOKIcon.defaultProps = {
  size: '24',
  backgroundColor: settings.colors.Operational.State_Notif_Green_2,
  foregroundColor: settings.colors.Monochromatic.Black,
};
