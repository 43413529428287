import React from 'react';
import { useKeyPressEvent } from 'react-use';

export function useLogger() {
  const toggle = React.useCallback(() => {
    if (log.isMuted) {
      log.unmute();
    } else {
      log.mute();
    }
  }, []);

  useKeyPressEvent((event) => {
    return event.ctrlKey && event.key === 'l';
  }, toggle);
}
