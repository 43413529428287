import { settings } from '@rhim/design';
import React, { FC } from 'react';
import styled from 'styled-components';

import { IconButton } from '../IconButton';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 48px;
  align-items: center;
  background-color: ${settings.colors.Primary.Grey_2};
  border: 1px solid ${settings.colors.Primary.Grey_3};
`;

const StyledIconButton = styled(IconButton)`
  &:not(:first-of-type) {
    margin-top: ${settings.Spacing.Spacing_50};
  }
`;

type SidebarItemProps = {
  id: string;
  tooltip: string;
  icon: React.ReactElement;
  onItemClicked: (itemId: string) => void;
};
type SidebarItemType = FC<React.PropsWithChildren<SidebarItemProps>>;
const SidebarItem: SidebarItemType = ({ id, tooltip, icon, onItemClicked }) => {
  return <StyledIconButton icon={icon} tooltip={tooltip} tooltipPlacement="right" onPress={() => onItemClicked(id)} />;
};

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export const Sidebar: React.FunctionComponent<React.PropsWithChildren<Props>> & { Item: SidebarItemType } = ({ children, ...rest }) => {
  return <StyledWrapper {...rest}>{children}</StyledWrapper>;
};
Sidebar.Item = SidebarItem;
