import { Notification } from '@rhim/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useUpdateEffect } from 'react-use';

const NOTIFICATION_KEY = 'heat-mapping-invalid-or-missing-notification';

export function useHeatWarning(isHeatMappingInvalidOrMissing: boolean, hasAcknowledged: boolean, vesselId: UUID, getContainer?: () => HTMLElement) {
  const { t } = useTranslation(['operator-display']);
  const location = useLocation();

  React.useDebugValue(hasAcknowledged);

  useUpdateEffect(() => {
    if (!hasAcknowledged) {
      Notification.destroy(NOTIFICATION_KEY);
    }
  }, [vesselId, hasAcknowledged]);

  /**
   * Hide notification when the user leaves the Operator Display.
   */
  React.useEffect(() => {
    return () => {
      // Destroy the notification container when the user leaves Operator Display.
      //
      // This notification uses Operator Display's container as its DOM root. We do this for a good reason:
      // we must make the notification a descendant of Operator Display, because we want both to be rendered in full screen mode.
      //
      // If, instead, we mounted the notification in `document.body`, as Ant's notifications do by default, it would be hidden
      // when Operator Display is presented in full screen mode. That's how browsers work (stacking contexts and backdrop work.
      //
      // However, when the user leaves Operator Display, its container no longer exists and cannot be used as notification root.
      // That's why the old notification must not just be closed, but destroyed altogether.
      Notification.destroy(NOTIFICATION_KEY);
    };
  }, [location.pathname]);

  React.useEffect(() => {
    if (!isHeatMappingInvalidOrMissing) {
      Notification.destroy(NOTIFICATION_KEY);
    } else if (hasAcknowledged) {
      Notification.open({
        key: NOTIFICATION_KEY,
        message: t('operator-display:errors.heatMappingInvalidOrMissing.heading'),
        type: 'alert',
        description: t('operator-display:errors.heatMappingInvalidOrMissing.subHeading'),
        getContainer,

        // Hide the close icon by setting it to false.
        // We don't want the user to close this notification manually.
        closeIcon: false,

        // Never close automatically
        duration: 0,
      });
    }
  }, [getContainer, hasAcknowledged, isHeatMappingInvalidOrMissing, t]);
}
