import type { Icon } from '@rhim/icons';
import { isDefined } from '@rhim/utils';
import { AxisBottom as VisxAxisBottom } from '@visx/axis';
import { Group } from '@visx/group';
import React from 'react';
import styled from 'styled-components';

import AxisLabel from './AxisLabel';
import { AXIS_BOTTOM_STYLES, AXIS_LABEL_HEIGHT, AXIS_LABEL_ICON_SIZE, AXIS_LABEL_OFFSET_VERTICAL, TICK_LABEL_COLOR } from './constants';
import Ticks from './Ticks';

interface Props extends Omit<React.ComponentProps<typeof VisxAxisBottom>, 'children'> {
  AxisIcon?: React.FunctionComponent<Icon>;
  axisIconColor?: string;
  axisLabel?: string;
  axisLabelOffsetVertical?: number;
  backgroundColor?: string;
  width: number;
}

const AxisBottom: React.ChildlessComponent<Props> = (props) => {
  const {
    AxisIcon,
    axisLabel,
    axisLabelOffsetVertical = AXIS_LABEL_OFFSET_VERTICAL,
    backgroundColor,
    left = AXIS_BOTTOM_STYLES.left,
    width,
    scale,
    top = AXIS_BOTTOM_STYLES.top,
    ...rest
  } = props;

  // guarantee visibility of last tick (rounding issues)
  const min = Number(scale.range()[0]);
  const max = Number(scale.range()[1]);
  scale.range([min, max]);

  return (
    <Group left={left} top={top}>
      {isDefined(backgroundColor) && <Background fill={backgroundColor} width={width} height={AXIS_BOTTOM_STYLES.height} />}
      <VisxAxisBottom scale={scale} ticksComponent={Ticks} {...AXIS_BOTTOM_STYLES} {...rest} />
      {(isDefined(axisLabel) || isDefined(AxisIcon)) && (
        <foreignObject x="0" y={AXIS_BOTTOM_STYLES.height - AXIS_LABEL_HEIGHT - axisLabelOffsetVertical} width={width} height={AXIS_LABEL_HEIGHT}>
          <AxisLabelBottom
            iconPlacement="right"
            icon={isDefined(AxisIcon) ? <AxisIcon fill={TICK_LABEL_COLOR} size={`${AXIS_LABEL_ICON_SIZE}px`} /> : undefined}
            label={axisLabel}
          />
        </foreignObject>
      )}
    </Group>
  );
};

AxisBottom.whyDidYouRender = true;

export default React.memo(AxisBottom);

const Background = styled.rect`
  fill: ${(props) => props.fill};
`;

const AxisLabelBottom = styled(AxisLabel)`
  position: absolute;
  bottom: 0;
  right: 0;
`;
