import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const VesselRHLowerIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.999 7 11 9c-.232.008-1.818.104-3.055-.195-1.237-.3-2.683-.314-2.945-.3L4.999 7h6zM13 7v4.5a1 1 0 0 1-1 1h-1V15a1 1 0 0 1-1.993.117L9 15v-2.5H7V15a1 1 0 0 1-1.993.117L5 15v-2.5H4a1 1 0 0 1-.993-.883L3 11.5V8.414L3 8.412V7H13z"
      />
      <path
        fill={fill}
        d="M6 1a1 1 0 1 0-2 0 1 1 0 0 0 2 0zM9 1a1 1 0 1 0-2 0 1 1 0 0 0 2 0zM12 1a1 1 0 1 0-2 0 1 1 0 0 0 2 0zM12 5H4a1 1 0 0 0 0 2h8a1 1 0 1 0 0-2z"
      />
      <path
        fill={fill}
        d="M13 6a1 1 0 1 0-2 0v1a1 1 0 1 0 2 0V6zM14 2.5h-2a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2zM5 6a1 1 0 0 0-2 0v1a1 1 0 0 0 2 0V6zM4.707 2.793 3.293 1.378a1 1 0 0 0-1.414 1.415l1.414 1.414a1 1 0 0 0 1.414-1.414z"
      />
    </svg>
  );
};

VesselRHLowerIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Grey_6,
};
