import { RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto } from '@rhim/rest/fleetOverview';
import { isDefined } from '@rhim/utils';
import { useEffect } from 'react';

import { usePosition } from './hooks/usePosition';
import PositionContext from './PositionContext';

interface Props {
  measurements: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto[];
  defaultPosition?: number;
}

export const PositionProvider: React.FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  const { children, measurements, defaultPosition } = props;
  const value = usePosition(measurements);

  useEffect(() => {
    if (isDefined(defaultPosition) && !isDefined(value.position)) {
      value.handlePosition(defaultPosition);
    }
  }, [defaultPosition, value]);

  return <PositionContext.Provider value={value}>{children}</PositionContext.Provider>;
};
