import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const LockedIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 8 8" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path fillRule="evenodd" clipRule="evenodd" d="M3 3h2a1 1 0 0 0-2 0zm1 5H0V3h1a3 3 0 0 1 6 0h1v5H4z" fill={fill} />
    </svg>
  );
};

LockedIcon.defaultProps = {
  size: '8',
  fill: settings.colors.Primary.Grey_8,
};
