import { RHIMCementServiceV1ModelsScanOverviewDto } from '@rhim/rest/cement';
import { assert, isDefined, specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api';

type Payload = RHIMCementServiceV1ModelsScanOverviewDto[];

export function useCrkScans(
  vesselId: string | null,
  campaignNumber?: number,
  configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>
) {
  return useQuery(getKey(vesselId, campaignNumber), (context) => queryFn(context), configuration);
}

const getKey = (vesselId: string | null, campaignNumber?: number) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'cement',
      scope: 'scans',
      entity: 'list',
      vesselId,
      campaignNumber,
    },
  ]);

const queryFn = (
  { queryKey: [{ vesselId, campaignNumber }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>,
  axiosConfiguration?: AxiosRequestConfig
) => {
  assert(
    isDefined(vesselId),
    `${useCrkScans.name} requires a vesselId, but value was ${vesselId}. Use a valid id, or use \`options.enabled\` to pause the query until all requirements are met. See https://react-query.tanstack.com/guides/disabling-queries for instructions.`
  );

  return API.scans.getVesselsVesselidScans(vesselId, campaignNumber, { signal, ...axiosConfiguration }).then((response) => response.data);
};

useCrkScans.getKey = getKey;
useCrkScans.queryFn = queryFn;
