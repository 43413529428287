import { assert, isDefined } from '@rhim/utils';
import React from 'react';

import PositionContext from '../PositionContext';

export const usePositionContext = () => {
  const positionContext = React.useContext(PositionContext);
  assert(isDefined(positionContext), 'usePositionContext must be inside a PositionContext');

  return positionContext;
};
