import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  stroke?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const FloorTrackIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ stroke, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 16V0H0v16h2zM16 0v16h-2V0h2zm-4 2.667C12 3.403 11.377 4 10.609 4H5.39C4.623 4 4 3.403 4 2.667c0-.737.623-1.334 1.391-1.334h5.218c.768 0 1.391.597 1.391 1.334zm-1.391 12c.768 0 1.391-.597 1.391-1.334 0-.736-.623-1.333-1.391-1.333H5.39C4.623 12 4 12.597 4 13.333c0 .737.623 1.334 1.391 1.334h5.218zM12 8.007c0 .736-.623 1.333-1.391 1.333H5.39C4.623 9.34 4 8.743 4 8.007c0-.737.623-1.334 1.391-1.334h5.218c.768 0 1.391.597 1.391 1.334z"
      />
    </svg>
  );
};

FloorTrackIcon.defaultProps = {
  size: '16',
  stroke: settings.colors.Primary.Blue_9,
};
