import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const RHLowerIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g id="Icons/Vessels/RHL_24" stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
        <path d="M16 12v1.692c-.232.008-.464.011-.697.011-1.525 0-2.764-.214-3.858-.403-.931-.162-1.736-.3-2.651-.3-.267 0-.532.006-.794.02V12h8zm2 0v5a1 1 0 0 1-1 1h-1v2.5a1.5 1.5 0 1 1-3 0V18h-2v2.5a1.5 1.5 0 1 1-3 0V18H7a1 1 0 0 1-1-1v-5h12z" />
        <g transform="translate(1.464 1)">
          <rect x="9.536" width="2" height="2" rx="1" />
          <rect x="6.536" width="2" height="2" rx="1" />
          <rect x="4.536" y="2" width="2" height="2" rx="1" />
          <rect x="4.536" y="5" width="2" height="2" rx="1" />
          <rect transform="rotate(45 2.475 3.44)" x="-.025" y="2.439" width="5" height="2" rx="1" />
          <rect x="12.536" width="2" height="2" rx="1" />
          <rect x="14.536" y="2" width="2" height="2" rx="1" />
          <rect x="14.536" y="5" width="2" height="2" rx="1" />
          <rect x="17.536" y="5" width="3" height="2" rx="1" />
          <rect x="14.536" y="8" width="2" height="4" rx="1" />
          <rect x="4.536" y="8" width="2" height="4" rx="1" />
          <rect x="4.536" y="8" width="12" height="2" rx="1" />
        </g>
      </g>
    </svg>
  );
};

RHLowerIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Grey_8,
};
