import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const ChevronDownIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M16.924 9.617C16.769 9.243 16.404 9 16 9H8c-.404 0-.77.243-.924.617-.154.374-.07.804.217 1.09l4 4c.195.195.45.293.707.293.256 0 .512-.098.707-.293l4-4c.286-.286.372-.716.217-1.09"
      />
    </svg>
  );
};

ChevronDownIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
