import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { AppStateAlertIcon } from '@rhim/icons/64';
import { Hyperlink, MaintenancePanel } from '@rhim/react';
import { incompleteSetupPanel } from '@rhim/test-ids';
import { assert, hasElements, isDefined } from '@rhim/utils';
import React, { useCallback, useContext, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import AppContext from '../app/AppContext';
import { ROUTES } from '../utilities';

/**
 * A page that displays a "Your setup has not been yet completed. Please reach out to our Customer Onboarding Team for help." message.
 * Used when the selected customer has no vessels at all.
 */
const IncompleteSetupPage: React.ChildlessComponent = () => {
  const { t } = i18nReact.useTranslation(['reporting', 'shared']);
  const navigate = useNavigate();
  const { selectedCustomer: customer } = useContext(AppContext);
  assert(isDefined(customer), 'No customer selected');
  // If the selected customer has at least one vessel redirect to the "start" route.
  useEffect(() => {
    if (isDefined(customer.vesselTypes) && hasElements(customer.vesselTypes)) {
      navigate(ROUTES.START, { replace: true });
    }
  }, [customer, navigate]);

  const refresh = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <MaintenancePanel
      size="large"
      heading={t('reporting:errors.setupIncomplete.heading')}
      subHeading={
        <Trans i18nKey="reporting:errors.setupIncomplete.subHeading">
          Your setup has not been yet completed. Please reach out to our{' '}
          <Hyperlink href="mailto: onboarding.digital@rhimagnesita.com">Customer&nbsp;Onboarding&nbsp;Team</Hyperlink> for help.
        </Trans>
      }
      icon={<AppStateAlertIcon foregroundColor={settings.colors.Primary.Grey_1} backgroundColor={settings.colors.Operational.State_Notif_Grey_2} />}
      headerColor={settings.colors.Operational.State_Notif_Grey_2}
      subheaderColor={settings.colors.Operational.State_Notif_Grey_2}
      secondaryButton={{ label: t('shared:refresh'), onClick: refresh }}
      data-test-id={incompleteSetupPanel}
    />
  );
};

export default React.memo(IncompleteSetupPage);
