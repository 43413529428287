import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const ChevronUpDoubleIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M13.707 14.293a.997.997 0 0 1 0 1.414.999.999 0 0 1-1.414 0L8 11.414l-4.293 4.293a.999.999 0 1 1-1.414-1.414l5-5a.999.999 0 0 1 1.414 0l5 5zm0-8a.997.997 0 0 1 0 1.414.999.999 0 0 1-1.414 0L8 3.414 3.707 7.707a.999.999 0 1 1-1.414-1.414l5-5a.999.999 0 0 1 1.414 0l5 5z"
      />
    </svg>
  );
};

ChevronUpDoubleIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
