import { RHIMAPOCustomerManagementWebV2ModelsUserDto } from '@rhim/rest/customerManagement';
import { specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api/customerManagement';

type Payload = RHIMAPOCustomerManagementWebV2ModelsUserDto;

export const useUser = (
  userId: UUID,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
): UseQueryResult<Payload> => {
  return useQuery(getKey(userId), (context) => queryFn(context, options?.axiosConfiguration), options?.configuration);
};

const getKey = (userId: UUID) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'customerManagement',
      scope: 'users',
      entity: 'detail',
      userId,
    },
  ]);

const queryFn = ({ queryKey: [{ userId }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  return API.users.getUsersUserid(userId, { signal, ...axiosConfiguration }).then((response) => response.data);
};

useUser.getKey = getKey;
useUser.queryFn = queryFn;
