import { settings } from '@rhim/design';
import { LinePath } from '@vx/shape';
import React from 'react';

interface Point {
  x: number;
  y: number;
}

const OUTER_DOT_RADIUS_PX = 5;
const INNER_DOT_RADIUS_PX = 4;

interface Props {
  linePathData: Point[];
  xAccessor: (point: Point) => number;
  yAccessor: (point: Point) => number;
  lineStroke?: settings.colors.Any;
  dotFill?: settings.colors.Any;
}
const DottedLinePath: React.ChildlessComponent<Props> = (props) => {
  const { linePathData, xAccessor, yAccessor, lineStroke = settings.colors.Primary.Blue_9, dotFill = settings.colors.Primary.Blue_8 } = props;

  return (
    <>
      {/* LINE PATH */}
      <LinePath data={linePathData} x={xAccessor} y={yAccessor} stroke={lineStroke} strokeWidth={2} pointerEvents="none" />
      {/* LINE DOTS */}
      {linePathData.map((linePathPoint) => {
        const x = xAccessor(linePathPoint);
        const y = yAccessor(linePathPoint);
        return (
          <g key={linePathPoint.x}>
            {/* OUTER WHITE BACKGROUND CIRCLE */}
            <circle cx={x} cy={y} r={OUTER_DOT_RADIUS_PX} fill={settings.colors.Monochromatic.White} pointerEvents="none" />
            {/* INNER BLUE BACKGROUND CIRCLE ( a little smaller ) */}
            <circle cx={x} cy={y} r={INNER_DOT_RADIUS_PX} fill={dotFill} pointerEvents="none" />
          </g>
        );
      })}
    </>
  );
};
DottedLinePath.whyDidYouRender = true;
export default React.memo(DottedLinePath);
