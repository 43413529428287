import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const DustSegmentIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 2H2v2h12V2zM2 9.586V7.414L3.414 6h2.172L2 9.586zM4.414 10l4-4h2.172l-4 4H4.414zM2 12v2h12v-2H2zm9.586-2L14 7.586V6h-.586l-4 4h2.172zM2 0H0v16h16V0H2z"
      />
    </svg>
  );
};

DustSegmentIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Grey_6,
};
