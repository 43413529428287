import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  backgroundColor?: Property.Color;
  foregroundColor?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const OpsStateOkIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ backgroundColor, foregroundColor, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                fill={backgroundColor}
                d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2"
                transform="translate(-595 -370) translate(579 350) translate(16 20)"
              />
              <path
                fill={foregroundColor}
                d="M17.8 9.6l-6 8c-.174.232-.44.377-.729.397-.023.002-.047.003-.071.003-.264 0-.519-.105-.707-.293l-4-4c-.39-.391-.39-1.024 0-1.414.391-.391 1.024-.391 1.414 0l3.185 3.184L16.2 8.4c.331-.441.958-.531 1.4-.2.442.331.532.958.2 1.4"
                transform="translate(-595 -370) translate(579 350) translate(16 20)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

OpsStateOkIcon.defaultProps = {
  size: '24',
  backgroundColor: '#52DC51',
  foregroundColor: settings.colors.Monochromatic.Black,
};
