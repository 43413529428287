import { createGlobalStyle as _createGlobalStyle, ThemeProvider as _ThemeProvider } from 'styled-components';

import { Elevation } from './elevation';

/**
 * A childess React component used to inject global styles.
 *
 * @see https://styled-components.com/docs/api#createglobalstyle
 */
export const GlobalStyle = _createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    background-color: #f5f6f7; /* Primary.Grey_1 */
    margin: 0;
    font-size: 14px;
    line-height: 1.5715;
    font-family: 'NortW05-Regular', sans-serif;
    font-variant-numeric: tabular-nums !important; /* Without !important the style would be trumped by Antd */
    font-feature-settings: 'zero' 1, 'ss03' 1, 'ss06' 1;
    text-rendering: optimizeLegibility; /* stylelint-disable-line value-keyword-case */
    -moz-osx-font-smoothing: grayscale;
  }

  /* SET MESSAGES ORDER ON TOP OF LAYOUT */
  .ant-message.ant-message-top {
    z-index: ${Elevation.GlobalTooltip};
  }

  .ant-modal-root {
    position: relative;
    z-index: ${Elevation.ModalBackdrop};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    /* stylelint-disable-next-line property-disallowed-list */
    font-weight: 500;
  }

  p {
    margin-top: 0;
    margin-bottom: 1em;
  }

  /* RESPONSIVE */
  @media screen and (max-width: 576px) {
    :root {
      --rhim-brand-logo-width: 65px;
      --rhim-brand-logo-height: 39px;
      --rhim-outer-container-margin: 24px;
    }
  }

  @media print {
    a::after {
      content: ' (' attr(href) ')';
    }

    h1 {
      break-before: page;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      break-after: avoid-page;
    }

    table,
    img,
    svg {
      break-inside: avoid;
    }

    p {
      orphans: 3;
      widows: 3;
    }
  }

  /*
  We intentionally hide the React Refresh Overlay by default.
  If you want to see application errors, make sure that you add an <ErrorBoundary> in your outermost component, which we usually have as best practice
  */
  #react-refresh-overlay {
    display: none;
  }
`;

GlobalStyle.displayName = 'GlobalStyle';

export const patchedCreateGlobalStyle = _createGlobalStyle;
export const PatchedThemeProvider = _ThemeProvider;

PatchedThemeProvider.displayName = 'ThemeProvider';
