import { Trans } from '@rhim/i18n';
import { Heading, Hyperlink, Legal } from '@rhim/react';
import { headerTermsOfUse } from '@rhim/test-ids';
import { FC } from 'react';

const LegalTabTermsOfUse: FC<React.PropsWithChildren> = () => {
  return (
    <Trans i18nKey="static:legal.topics.terms-of-use.content">
      <Heading data-test-id={headerTermsOfUse} level="h2">
        Digital Service Terms of Usage
      </Heading>
      <Heading level="h3">General</Heading>
      <Legal.Paragraph>
        These Terms of Usage (<Legal.Strong>&quot;Terms&quot;</Legal.Strong>), apply to your usage of this homepage plus all sub-websites (
        <Legal.Strong>&quot;Website&quot;</Legal.Strong> or <Legal.Strong>&quot;Digital Service&quot;</Legal.Strong>), e.g. access, browsing, storage, uploads,
        communications or printing.
      </Legal.Paragraph>
      <Legal.Paragraph>
        The Digital Services are provided by RHI Magnesita GmbH, FN 442121 d, Kranichberggasse 6, 1120 Vienna, Austria (
        <Legal.Strong>&quot;We&quot;</Legal.Strong> or <Legal.Strong>&quot;Us&quot;</Legal.Strong> ). In case of questions thereto, please contact{' '}
        <Hyperlink href="mailto:support.digital@rhimagnesita.com" text="support.digital@rhimagnesita.com" />.
      </Legal.Paragraph>
      <Legal.Paragraph>
        When entering, browsing and using the Digital Services, the user engages with us regarding an omnichannel platform, which is an online destination to
        find relevant information about RHI Magnesita products/services and gain access to diverse digital applications (
        <Legal.Strong>&quot;Purpose&quot;</Legal.Strong>).
      </Legal.Paragraph>
      <Legal.Paragraph>
        We aim to provide uninterrupted access to the Digital Services, but cannot guarantee their availability, as access may be interrupted at any time, in
        particular due to maintenance and updating of the Digital Services, but also for reasons beyond our control (e.g. network or provider problems). It is
        within Our sole discretion to stop the provision of the Digital Services at all times and without stating any reason. We also reserve the right to carry
        out changes or additions to the Digital Services without prior notice.
      </Legal.Paragraph>
      <Legal.Paragraph>We are permitted to revise the Terms at any time as we see fit without further notification.</Legal.Paragraph>
      <Heading level="h3">Use of the Digital Services</Heading>
      <Legal.Paragraph>
        Access to the Digital Services is only provided to invited and registered users. We will register you and send an email to your business email address
        in order for you to then authenticate your account.
      </Legal.Paragraph>
      <Legal.Paragraph>
        You agree that the information you provide (<Legal.Strong>&quot;User Information&quot;</Legal.Strong>) entered by you may be stored, processed and
        transmitted by Us in accordance with the legal requirements. Please inform Us if your details change.
      </Legal.Paragraph>
      <Legal.Paragraph>
        It is your sole responsibility to (i) ensure the confidentiality of the user ID, account login and password and protect it from access by third parties,
        (ii) update and verify your password regularly and (iii) notify Us immediately if there has been any unauthorized use of your account or any breach of
        security.
      </Legal.Paragraph>
      <Legal.Paragraph>
        By using or registering on the Digital Services, you confirm to be over 18 years and expressly accept and adhere to the Terms. In case you disagree with
        the Terms, you will not be entitled to use the Digital Services, respectively will not gain access to their entire functionality.
      </Legal.Paragraph>
      <Legal.Paragraph>
        The use of the Digital Services requires you to have the necessary hardware and software. Please note that the technical structure of the internet does
        not allow for absolute security, availability and integrity in data transmission. Your use of the Digital Services, their content and any products,
        services or downloads you obtain through them, is at your sole risk. We do not guarantee that defects will be corrected, that there will be updates,
        that the Digital Services or the server that makes them available are free of viruses or other harmful components, or that they or any services and
        products obtained through (directly or indirectly) them will meet your requirements or expectations. Accordingly, we accept no responsibility for any
        errors, omissions, deletions, delays, malfunctions (e.g. due to viruses) in the communication channels, hardware and software not under our control or
        in the event of unauthorized use or possible deterioration of the content published on the Digital Services.
      </Legal.Paragraph>
      <Heading level="h3">Data</Heading>
      <Legal.Paragraph>
        In connection with the Purpose, you can, by upload, disclose to Us information and/or data regarding your business, production and/or business processes
        (“Process Data”), e.g. quantity and type of production, temperature, refractory visualization data, or treatment time, in order for Us to provide you
        with the services agreed upon, which We will treat as confidential, pursuant to the Terms. We will also upload certain data in order to fulfill the
        Purpose (<Legal.Strong>&quot;RHIM Data&quot;</Legal.Strong>), i.e. information on (i) open orders from Our ERP system (e.g. forecasts of delivery, stock
        information for consignment stock) including the RHI Magnesita product CO2 footprint; (ii) the performance of services (e.g. refractory consumption per
        ton produced); (iii) measurements with Our or third-party equipment (e.g. lining evaluation scans).
      </Legal.Paragraph>
      <Legal.Paragraph>
        We shall be entitled to process, analyze, diagnose, assemble, combine, decompile, use, calculate algorithms, transfer, and otherwise operate Process
        Data in order to create output for the Purpose without restrictions. This includes providing, compiling and calculating updates, reports, customer
        applications, or similar, also with relevant data from other customers or parties, as well as use it in machine learning tools in the form of aggregated
        data.
      </Legal.Paragraph>
      <Legal.Paragraph>
        After processing the Process Data for the Purpose, We will store such data. We may also use it in an anonymized way for further development of Our
        algorithms and to improve the Digital Services. If nothing else is agreed upon between you and Us, we may continue to use the Process Data even after
        you stop using / deregister from the Digital Services.
      </Legal.Paragraph>
      <Heading level="h3">Restrictions</Heading>
      <Legal.Paragraph>
        The Digital Services may not be used as a medium for racist, violence-glorifying, xenophobic, obscene, malicious or other illegal purposes. Please note
        that you are not entitled to and shall refrain at all times from the following at all times:
      </Legal.Paragraph>
      <Legal.List>
        <Legal.Bullet>
          uploading or posting of any content that is illegal under any jurisdiction, in particular to violate the copyrights of software, photos, images, text,
          video and trademark rights;
        </Legal.Bullet>
        <Legal.Bullet>
          carrying out any activities that could be considered offensive, defamatory, unauthorized, malicious, invasive of privacy, glorifying violence or
          racist or abusive or infringing on any rights of third parties;
        </Legal.Bullet>
        <Legal.Bullet>
          reproducing or publishing and/or using any content, information, intellectual property, data, component or similar (
          <Legal.Strong>&quot;Digital Services Material&quot;</Legal.Strong>), in particular in any media for any purpose (e.g. business purpose);
        </Legal.Bullet>
        <Legal.Bullet>selling, sublicensing, disclosing and/or otherwise commercializing Digital Services Material;</Legal.Bullet>
        <Legal.Bullet>public disclosure of Digital Services Material;</Legal.Bullet>
        <Legal.Bullet>
          using the Digital Services in any way that is, or may be, damaging to the Digital Services (e.g. use it for political, promotional or religious
          purposes or to publish any promotional or advertising content);
        </Legal.Bullet>
        <Legal.Bullet>
          using the Digital Services in any way that impacts user access to the Digital Services, whether your own or of third parties;
        </Legal.Bullet>
        <Legal.Bullet>
          using the Digital Services contrary to applicable laws and regulations, or in a way that causes, or may cause, harm to the Digital Services, or to any
          person or business entity;
        </Legal.Bullet>
        <Legal.Bullet>
          engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to the Digital Services, or while using the
          Digital Services;
        </Legal.Bullet>
        <Legal.Bullet>using the Digital Services to engage in any advertising or marketing; </Legal.Bullet>
        <Legal.Bullet>
          unauthorized accessing or interruption, damage, overloading or disruption of any part of the Digital Services, the server on which the Digital
          Services are stored or the servers, computers or databases connected to the Digital Services; or attack the Digital Services via a denial of service
          or distributed denial of service attack; or any other attempt to impair the functionality of the Digital Services;
        </Legal.Bullet>
        <Legal.Bullet>introducing viruses, Trojan horses, worms, logic bombs or other content that may be malicious or harmful;</Legal.Bullet>
        <Legal.Bullet>
          usage of deep-link, page-scrape, robot or spider techniques or any other automatic device, program, algorithm or methodology, or any equivalent manual
          process, to access, obtain, copy or monitor any portion of the Digital Services, or the usage of these methods to reproduce or circumvent the
          navigational structure or presentation of the Digital Services in order to obtain any content, documents or information not purposefully made
          available on the Digital Services.
        </Legal.Bullet>
      </Legal.List>
      <Legal.Paragraph>
        Certain areas of the Digital Services are restricted from access to you and We may further restrict access to any areas of the Digital Services at any
        time, in Our sole and absolute discretion.
      </Legal.Paragraph>
      <Heading level="h3">Intellectual Property</Heading>
      <Legal.Paragraph>
        You warrant that you have full ownership and IP-rights to the Process Data, which does not infringe any third party's rights. By uploading and/or
        displaying Process Data, you grant Us free and unlimited permission to access and process such Process Data to achieve the Purpose.
      </Legal.Paragraph>
      <Legal.Paragraph>
        Other than the content, information and data that you and/or your company own and you decide, at your sole discretion, to disclose to Us by uploading it
        for the Purpose, We, our subsidiaries and/or affiliated legal entities, and/or our licensors (if any) own all rights to the intellectual property and
        material contained in the Digital Services (e.g. company logos, photos, images, illustrations, texts, video clips, dashboards, design of the Digital
        Services, algorithms, machine learning) (<Legal.Strong>&quot;RHIM Material&quot;</Legal.Strong>) and all such rights are reserved. You are granted a
        limited right, subject to the restrictions provided in the Terms, to access and view the RHIM Material contained on the Digital Services. The use of
        corporate data such as logos or trademarks is permissible exclusively for media purposes, and only image data that has been provided by us in the
        download area of the Digital Services may be used for this purpose. No further right shall result from your use of the Digital Services regarding any
        intellectual property not already owned by you.
      </Legal.Paragraph>
      <Legal.Paragraph>
        If not specified otherwise, the ownership rights and copyright for information and documents provided on or through the Digital Services lie exclusively
        with Us. Use of the Digital Services is only permitted for the Purpose and any usage beyond this, in particular incorporation, storage or reproduction
        of the content in other websites, in other electronic, digital or further media or systems, as well as copying, processing or commercial use require
        explicit prior permission from Us in writing. Notwithstanding the generality of this 5.3, you are allowed to download and copy data as provided for in
        the Digital Services.
      </Legal.Paragraph>
      <Heading level="h3">Confidentiality</Heading>
      <Legal.Paragraph>
        We will keep User Information and Process Data as strictly confidential and will not disclose any of it to any third parties except to Our group
        companies and/or consultants and advisors who have a need to know of such information in connection with the Purpose, unless agreed upon otherwise in
        separate agreements concluded. We may however use aggregated data.
      </Legal.Paragraph>
      <Legal.Paragraph>
        You agree to keep RHIM Data and RHIM Material as strictly confidential and will not disclose any of it to any third parties unless agreed upon otherwise
        in separate agreements concluded.
      </Legal.Paragraph>
      <Heading level="h3">Warranties and Liabilities</Heading>
      <Legal.Paragraph>
        We apply the required care in business operations to ensure that the information on the Digital Services is correct and current. The Digital Services
        and its content are provided &quot;as is&quot; and &quot;as available&quot; and We make no express or implied representations or warranties, of any kind
        related to the Digital Services or the materials, services or products contained on the Digital Services, in particular not to any availability,
        completeness, reliability, safety, correctness or up-to-dateness or that the Digital Services or the server that makes it available are free of viruses
        or other harmful components including but not limited to any warranties of merchantability, non-infringement of copyright and fitness for a particular
        purpose.
      </Legal.Paragraph>
      <Legal.Paragraph>
        You understand that We cannot guarantee that files downloaded from the internet and in particular the Digital Services will be free of viruses or other
        destructive code, and you are responsible for implementing appropriate procedures and checks to meet your requirements for anti-virus protection,
        accuracy of data input and output, and a means of recovering lost data independent of the Digital Services. We shall not be liable for any loss or
        damage resulting from a distributed denial-of­ service-attack, viruses or other technologically harmful content that infect your computer equipment,
        programs, data or other property on account of your use of the Digital Services or any services obtained through the use of or downloaded from the
        Digital Services.
      </Legal.Paragraph>
      <Legal.Paragraph>
        Additionally, nothing contained on the Digital Services shall be construed as providing consult or advice to you unless otherwise agreed upon. The
        results provided in the Digital Services are a mere simulation and do not replace an assessment of your local business processes. We cannot therefore
        guarantee that the advice and results will meet your needs.
      </Legal.Paragraph>
      <Legal.Paragraph>
        Hyperlinks contained on the Digital Services, e.g. to facilitate navigation on the Internet, may lead you to websites published by third parties, the
        contents of which We do not control. In such cases you acknowledge that We undertake no liability as to their content, security measures or similar as
        this is outside Our area of responsibility.
      </Legal.Paragraph>
      <Legal.Paragraph>
        In no event shall We, nor any of Our group companies, nor any of Our/their directors or employees, be liable for any claim, loss or damage arising out
        of or in any way connected with your use of the Digital Services and/or their unavailability, whether such liability is under contract, tort or
        otherwise, and neither We nor any of Our group companies, nor Our directors or employees shall be liable for any indirect, consequential or special
        liability arising out of or in any way related to your use of the Digital Services, including, but not limited to, any losses and damages.
      </Legal.Paragraph>
      <Legal.Paragraph>
        You acknowledge that integrity and security of User Information and Process Data comprised and uploaded by you or your authorized users, is within your
        sole responsibility and liability and that it is legally permissibly transferred, reliable, correct, accurate, complete and not manipulated (entire raw
        data). In particular you assure that no trade or business secrets are transferred or revealed. We reserve the right to remove any User Information or
        Process Data at any time and for any reason, without further notice.
      </Legal.Paragraph>
      <Legal.Paragraph>
        You hereby indemnify Us and Our group companies (if affected) from and against any and all liabilities, costs, demands, causes of action, damages and
        expenses (including attorney's fees) arising out of or in any way related to your breach of any of the provisions of the Terms.
      </Legal.Paragraph>
      <Legal.Paragraph>
        The products and services advertised or presented on the Digital Services do not constitute an offer or a solicitation of an offer to buy. It is a
        general presentation of the range of products and services which we sell or provide.
      </Legal.Paragraph>
      <Heading level="h3">Miscellaneous</Heading>
      <Legal.Paragraph>
        None of the information contained on the Digital Services or in associated documents and files is or is intended to represent a solicitation to purchase
        or trade with you shares of RHI Magnesita N.V.
      </Legal.Paragraph>
      <Legal.Paragraph>
        Should individual provisions of the Terms be or become illegal, invalid, or unenforceable, in whole or in part, under any applicable enactment or rule
        of law, such illegality, invalidity or unenforceability shall not affect the validity of the remaining provisions. This provision shall be replaced by a
        term that closest reflects the intended purpose of the Terms.
      </Legal.Paragraph>
      <Legal.Paragraph>
        We are permitted to assign, transfer, and subcontract our rights and/or obligations under the Terms without any further notification.
      </Legal.Paragraph>
      <Legal.Paragraph>
        You shall observe the Code of Conduct available at{' '}
        <Hyperlink rel="nofollow noopener" href="https://www.rhimagnesita.com/suppliers/" text="https://www.rhimagnesita.com/suppliers/" />
      </Legal.Paragraph>
      <Legal.Paragraph>
        The Terms, including any legal notices and disclaimers contained on the Digital Services, constitute the entire agreement between Us in relation to your
        use of the Digital Services, and it shall prevail all other agreements and understandings with respect to the same. Excepted from this general rule
        shall be any specific terms agreed upon in a contract regarding fee-based provision of Digital Services.
      </Legal.Paragraph>
      <Legal.Paragraph>
        The Terms are governed by and construed in accordance with the Austrian law, with the exception of its conflict of law provisions and the UN Sales
        Convention. The place of jurisdiction is the responsible court in Vienna, Austria.
      </Legal.Paragraph>
    </Trans>
  );
};

export default LegalTabTermsOfUse;
