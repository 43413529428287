import type { FC } from 'react';
import React from 'react';

export enum TextPosition {
  Left = 'left',
  Right = 'right',
}

interface Props {
  id: string;
  textPosition: TextPosition;
}

const SvgRblValueComponent: FC<React.PropsWithChildren<Props>> = ({ id, textPosition }) => {
  return (
    <g id={id}>
      <circle r="3" fill="#003262" />
      <g transform={`translate(${textPosition === 'left' ? '-' : ''}35, 0)`}>
        <rect width="45" height="14" fill="#3d5567" x="-22" y="-7" rx="3" />
        <text dominantBaseline="middle" textAnchor="middle" fontSize="10" fill="white"></text>
      </g>
    </g>
  );
};

SvgRblValueComponent.whyDidYouRender = true;

export const SvgRblValue = React.memo(SvgRblValueComponent);
