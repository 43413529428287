import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto, RHIMFleetOverviewServiceV1ModelsVolumesDashboardDto } from '@rhim/rest/fleetOverview';
import { rateCalculationContainerVolumeExplorer } from '@rhim/test-ids';
import React from 'react';
import styled from 'styled-components';

import Heat from './Heat';
import PropertyWithValue from './PropertyWithValue';
import { SSectionContainer as SectionContainer } from './styles';

interface Props {
  initialMeasurement: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto;
  currentMeasurement: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto | undefined | null;
  campaignHavingVolumes: RHIMFleetOverviewServiceV1ModelsVolumesDashboardDto | undefined;
}
const SectionSummary: React.ChildlessComponent<Props> = ({ initialMeasurement, currentMeasurement, campaignHavingVolumes }) => {
  const { t } = i18nReact.useTranslation(['shared', 'volume']);

  return (
    <SSectionContainer style={{ alignSelf: 'flex-end' }} data-test-id={rateCalculationContainerVolumeExplorer}>
      <Heat heatFrom={initialMeasurement.heat} heatTo={currentMeasurement?.heat} />
      <PropertyWithValue
        label={t('volume:propertyVolumeGrowthRate')}
        value={campaignHavingVolumes?.volumeGrowthRate}
        unit={t('shared:units.cubicMetersPerHeat')}
        isComparisonMode={true}
      />
      <PropertyWithValue
        label={t('volume:propertyWearRate')}
        value={campaignHavingVolumes?.wearRate}
        unit={t('shared:units.millimetersPerHeat')}
        isComparisonMode={true}
      />
    </SSectionContainer>
  );
};

const SSectionContainer = styled(SectionContainer)`
  align-self: flex-end;
  margin-left: ${settings.Spacing.Spacing_200};
`;

SectionSummary.whyDidYouRender = true;
export default React.memo(SectionSummary);
