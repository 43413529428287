import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const MeasurementFreqIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <g>
              <path
                d="M8 2c1.103 0 2 .897 2 2v16c0 1.103-.897 2-2 2H4c-1.103 0-2-.897-2-2V4c0-1.103.897-2 2-2zm14 16v3H12v-3h10zM8 4H4v16h4.001v-1H7c-.552 0-1-.447-1-1 0-.553.448-1 1-1h1.001v-2H7c-.552 0-1-.447-1-1 0-.553.448-1 1-1h1.001L8 11H7c-.552 0-1-.447-1-1 0-.553.448-1 1-1h1V7H7c-.552 0-1-.447-1-1 0-.553.448-1 1-1h1V4zm10 9v3h-6v-3h6zm2-5v3h-8V8h8zm-4-5v3h-4V3h4z"
                transform="translate(-906.000000, -286.000000) translate(906.000000, 283.000000) translate(0.000000, 3.000000) translate(12.000000, 12.000000) scale(1, -1) translate(-12.000000, -12.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

MeasurementFreqIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Grey_8,
};
