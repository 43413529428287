import { settings } from '@rhim/design';
import { assert } from '@rhim/utils';
import { ColorScales, getColorRGBUsingScale } from '@rhim/utils/colorScales';
import { isDefined } from '@rhim/utils/is-defined';
import chroma from 'chroma-js';
import React, { FC } from 'react';

import { AreaPanelTexts } from '../WallplotAreasPanel/domain';
import Canvas from './Canvas';
import {
  Area,
  AreaLayout,
  AreaRegion,
  AreaWithLabelLayout,
  BasicMetrics,
  CellLayout,
  drawRect,
  fillRect,
  getGridCellLayoutByDomainX,
  getGridCellLayoutByDomainY,
  getLabelMetrics,
  LabelStyle,
  SETTINGS,
  WallplotLayout,
  WallplotMargin,
} from './utils';

const AREA_BORDER_WIDTH_PX = 1;
const HIGHLIGHTED_AREA_BORDER_WIDTH_PX = 2;

export const labelStyleForAreaLabels: LabelStyle = {
  textAlign: 'center',
  textBaseline: 'top',
  textFont: '12px nortw05-bold',
};

const drawAreasTicks = (imageDataBuffer: Uint32Array, wallplotMargin: WallplotMargin, metrics: BasicMetrics, areaWithLabelLayout: AreaWithLabelLayout) => {
  const tickColor = { red: 177, green: 187, blue: 194 }; // --rhim_color_pr_grey_4

  if (!areaWithLabelLayout.isAreaLabelRotated) {
    // top-horizonzal line ticks
    // lefthand side area tick
    fillRect(
      tickColor,
      metrics.canvasWidth,
      imageDataBuffer,
      areaWithLabelLayout.startX,
      wallplotMargin.top - SETTINGS.AXIS.TICK_LENGTH_PX,
      1,
      SETTINGS.AXIS.TICK_LENGTH_PX
    );
    // righthand side area tick
    fillRect(
      tickColor,
      metrics.canvasWidth,
      imageDataBuffer,
      areaWithLabelLayout.startX + areaWithLabelLayout.width,
      wallplotMargin.top - SETTINGS.AXIS.TICK_LENGTH_PX,
      1,
      SETTINGS.AXIS.TICK_LENGTH_PX
    );
  } else {
    // right-vertical line ticks
    // tophand side area tick
    fillRect(
      tickColor,
      metrics.canvasWidth,
      imageDataBuffer,
      wallplotMargin.left + metrics.plotWidth,
      areaWithLabelLayout.startY,
      SETTINGS.AXIS.TICK_LENGTH_PX,
      1
    );
    // bottomhand side area tick
    fillRect(
      tickColor,
      metrics.canvasWidth,
      imageDataBuffer,
      wallplotMargin.left + metrics.plotWidth,
      areaWithLabelLayout.startY + areaWithLabelLayout.height,
      SETTINGS.AXIS.TICK_LENGTH_PX,
      1
    );
  }
};

function isColorScale(fillColor: FillMode): fillColor is ColorScales {
  return Array.isArray(fillColor as ColorScales);
}

function isRegionId(fillColor: FillMode): fillColor is RegionId {
  return isDefined((fillColor as RegionId).regionId);
}

type RegionId = { regionId: number; borderColor?: settings.colors.Any; fillColor?: settings.colors.Any; label?: string; labelColor?: settings.colors.Any };

type FillMode = 'none' | ColorScales | RegionId;

interface AreasMapCanvasProps {
  className?: string;
  texts: AreaPanelTexts;
  domainXStart?: number;
  domainXWrap?: number;
  plotMarginTopPx?: number;
  plotMarginLeftPx?: number;
  metrics: BasicMetrics;
  wallplotLayout?: WallplotLayout;
  areas: Area[];
  onAreasLayoutUpdated?: (areasLayout: AreaLayout[]) => void;
  shouldDisplayAreaLabels?: boolean;
  shouldPaintAreaEdgeOnMapBounds?: boolean;
  backgroundColor?: settings.colors.Any;
  fillColor?: FillMode;
}
const AreasMapCanvas: FC<React.PropsWithChildren<AreasMapCanvasProps>> = ({
  className,
  texts,
  domainXStart = 0,
  domainXWrap = 0,
  plotMarginTopPx = 0,
  plotMarginLeftPx = 0,
  metrics,
  wallplotLayout,
  areas,
  onAreasLayoutUpdated,
  shouldDisplayAreaLabels = true,
  shouldPaintAreaEdgeOnMapBounds = false,
  backgroundColor,
  fillColor = 'none',
}) => {
  const plotAreasMap = (canvas2DContext: CanvasRenderingContext2D) => {
    const areasLayout: AreaLayout[] = [];

    const imageData = canvas2DContext.createImageData(metrics.canvasWidth, metrics.canvasHeight);
    const imageDataBuffer = new Uint32Array(imageData.data.buffer);

    canvas2DContext.save();

    const strokeColor = { red: 119, green: 136, blue: 148 }; // --rhim_color_pr_grey_6

    const wrapValue = (value: number) => {
      if (value < domainXStart) {
        return domainXWrap - domainXStart + value;
      }
      return value % metrics.domainXMax !== 0 ? value - domainXStart : value;
    };

    const addArea = (region: AreaRegion): CellLayout => {
      // to ensure perfect alignment with the primary wallplot grid, try to find whether the area values sit exactly on any of the grid coordinates
      // x
      let cellLayout = getGridCellLayoutByDomainX(wallplotLayout?.gridLayout, region.startX);
      const startX = isDefined(cellLayout)
        ? cellLayout.startX
        : plotMarginLeftPx + Math.round((metrics.plotWidth * wrapValue(region.startX)) / metrics.domainXMax);
      // y
      cellLayout = getGridCellLayoutByDomainY(wallplotLayout?.gridLayout, region.startY);
      const startY = isDefined(cellLayout) ? cellLayout.startY : plotMarginTopPx + Math.round((metrics.plotHeight * region.startY) / metrics.domainYMax);
      // width
      cellLayout = getGridCellLayoutByDomainX(wallplotLayout?.gridLayout, region.endX);
      const width = isDefined(cellLayout)
        ? cellLayout.startX - startX
        : Math.round((metrics.plotWidth * (wrapValue(region.endX) - wrapValue(region.startX))) / metrics.domainXMax);
      // height
      cellLayout = getGridCellLayoutByDomainY(wallplotLayout?.gridLayout, region.endY);
      const height = isDefined(cellLayout)
        ? (cellLayout.startY || 0) - startY
        : Math.round((metrics.plotHeight * (region.endY - region.startY)) / metrics.domainYMax);

      if (isDefined(backgroundColor)) {
        const backgroundColorRGB = chroma(backgroundColor).rgb();
        fillRect(
          { red: backgroundColorRGB[0], green: backgroundColorRGB[1], blue: backgroundColorRGB[2] },
          metrics.canvasWidth,
          imageDataBuffer,
          startX,
          startY,
          width,
          height
        );
      }
      // fill area's color
      if (isColorScale(fillColor)) {
        // used in the Expanded view : fills the area with a color based on its minimumValue and the provided color scale
        const areaFillColor = getColorRGBUsingScale(region.minimumThickness, fillColor);
        fillRect(areaFillColor, metrics.canvasWidth, imageDataBuffer, startX, startY, width, height);
      } else if (isRegionId(fillColor)) {
        // fills the area with a fixed color
        const asRegionId = fillColor;
        if (asRegionId.regionId === region.regionId && isDefined(asRegionId.fillColor)) {
          const fillColorRGB = chroma(asRegionId.fillColor).rgb();
          const areaFillColor = { red: fillColorRGB[0], green: fillColorRGB[1], blue: fillColorRGB[2] };
          fillRect(areaFillColor, metrics.canvasWidth, imageDataBuffer, startX, startY, width, height);
        }
      }
      // paint the top area edge unless it sits on the top side of the plot
      if (shouldPaintAreaEdgeOnMapBounds || region.startY !== metrics.domainYMin) {
        drawRect(strokeColor, metrics.canvasWidth, imageDataBuffer, startX, startY, width, AREA_BORDER_WIDTH_PX);
      }
      // paint the right area edge if it sits on the map's bounds
      if (shouldPaintAreaEdgeOnMapBounds && region.endX === metrics.domainXMax) {
        drawRect(
          strokeColor,
          metrics.canvasWidth,
          imageDataBuffer,
          plotMarginLeftPx + metrics.plotWidth - AREA_BORDER_WIDTH_PX,
          startY,
          AREA_BORDER_WIDTH_PX,
          height
        );
      }
      // paint the bottom area edge if its sits on the map's bounds
      if (shouldPaintAreaEdgeOnMapBounds && region.endY === metrics.domainYMax) {
        drawRect(
          strokeColor,
          metrics.canvasWidth,
          imageDataBuffer,
          startX,
          plotMarginTopPx + metrics.plotHeight - AREA_BORDER_WIDTH_PX,
          width,
          AREA_BORDER_WIDTH_PX
        );
      }
      // paint the left area edge unless it sits on the left side of the plot
      if (shouldPaintAreaEdgeOnMapBounds || region.startX !== domainXStart) {
        drawRect(strokeColor, metrics.canvasWidth, imageDataBuffer, startX, startY, AREA_BORDER_WIDTH_PX, height);
      }
      return { startX, startY, width, height, halfWidth: width / 2, halfHeight: height / 2 };
    };

    const displayAreaLabel = (areaLabelLayout: AreaWithLabelLayout) => {
      canvas2DContext.save();
      canvas2DContext.fillStyle = settings.colors.Primary.Grey_7;
      canvas2DContext.textAlign = labelStyleForAreaLabels.textAlign;
      canvas2DContext.font = labelStyleForAreaLabels.textFont;
      canvas2DContext.textBaseline = labelStyleForAreaLabels.textBaseline;
      if (areaLabelLayout.isAreaLabelRotated) {
        const labelCenterX = plotMarginLeftPx + metrics.plotWidth + SETTINGS.AREAS.AREA_LABEL_DISTANCE_PX;
        const labelCenterY = areaLabelLayout.startY + areaLabelLayout.halfHeight;
        canvas2DContext.translate(labelCenterX, labelCenterY);
        canvas2DContext.rotate(-Math.PI / 2);
        canvas2DContext.fillText(texts.zoneNames[areaLabelLayout.label] ?? 'Missing area name', 0, 0);
      } else {
        const labelCenterX = areaLabelLayout.startX + areaLabelLayout.halfWidth;
        const labelCenterY = 0;
        canvas2DContext.fillText(texts.areaNames[areaLabelLayout.label] ?? 'Missing zone name', labelCenterX, labelCenterY);
      }
      canvas2DContext.restore();
    };

    const areaLabelLayoutsToDisplay: AreaWithLabelLayout[] = [];
    // draw the area's bounds
    let areaId = 0;
    for (const [areaIndex, area] of areas.entries()) {
      for (const line of area.lines) {
        const isAreaAtTheTopOfThePlot = line.startY === metrics.domainYMin;
        if (line.startX < domainXStart && line.endX > domainXStart) {
          // this area wraps around the x-axis, we need to break this into 2 areas
          const areaRightMost: AreaRegion = {
            ...line,
            endX: metrics.domainXMax,
          };
          const areaLeftMost: AreaRegion = {
            ...line,
            startX: domainXStart,
          };
          const rightMostAreaGrid = addArea(areaRightMost);
          const leftMostAreaGrid = addArea(areaLeftMost);
          const rightMostAreaLayout = {
            ...rightMostAreaGrid,
            regionId: line.regionId,
            areaId: areaId,
            label: area.name,
            groupLabel: line.line,
            connectedToAreaId: areaId + 1,
          };
          areaId++;
          const leftMostAreaLayout = {
            ...leftMostAreaGrid,
            regionId: line.regionId,
            areaId: areaId,
            label: area.name,
            groupLabel: line.line,
            connectedToAreaId: areaId - 1,
          };
          areaId++;
          areasLayout.push(rightMostAreaLayout);
          areasLayout.push(leftMostAreaLayout);
          if (isAreaAtTheTopOfThePlot) {
            areaLabelLayoutsToDisplay.push({ ...rightMostAreaLayout, isAreaLabelRotated: false });
            areaLabelLayoutsToDisplay.push({ ...leftMostAreaLayout, isAreaLabelRotated: false });
          }
          if (areaIndex === 0) {
            areaLabelLayoutsToDisplay.push({ ...rightMostAreaLayout, isAreaLabelRotated: true, label: line.line });
          }
        } else {
          const areaGrid = addArea(line);
          const areaLayout = { ...areaGrid, regionId: line.regionId, areaId: areaId, label: area.name, groupLabel: line.line };
          areaId++;
          areasLayout.push(areaLayout);
          if (isAreaAtTheTopOfThePlot) {
            areaLabelLayoutsToDisplay.push({ ...areaLayout, isAreaLabelRotated: false, label: area.name });
          }
          if (areaIndex === 0) {
            areaLabelLayoutsToDisplay.push({ ...areaLayout, isAreaLabelRotated: true, label: line.line });
          }
        }
      }
    }
    if (shouldDisplayAreaLabels && wallplotLayout) {
      // draw area's ticks at the top-horizonzal and right-vertical lines of the canvas
      for (const areaLabelLayout of areaLabelLayoutsToDisplay) {
        drawAreasTicks(imageDataBuffer, wallplotLayout.wallplotMargin, metrics, areaLabelLayout);
      }
    }

    // display area border - if specified
    if (isRegionId(fillColor)) {
      const asRegionId = fillColor;
      if (isDefined(asRegionId.borderColor)) {
        const areaToDisplayValue = areasLayout.find((area) => area.regionId === asRegionId.regionId);
        assert(isDefined(areaToDisplayValue), `AreasMapCanvas, plotAreasMap, area with id : ${asRegionId.regionId} not found`);
        const borderColorRGB = chroma(asRegionId.borderColor).rgb();
        drawRect(
          { red: borderColorRGB[0], green: borderColorRGB[1], blue: borderColorRGB[2] },
          metrics.canvasWidth,
          imageDataBuffer,
          areaToDisplayValue.startX,
          areaToDisplayValue.startY,
          areaToDisplayValue.width,
          areaToDisplayValue.height,
          HIGHLIGHTED_AREA_BORDER_WIDTH_PX
        );
      }
    }

    canvas2DContext.putImageData(imageData, 0, 0);

    // display area value - if specified
    if (isRegionId(fillColor)) {
      const asRegionId = fillColor;
      if (isDefined(asRegionId.label)) {
        const areaToDisplayValue = areasLayout.find((area) => area.regionId === asRegionId.regionId);
        assert(isDefined(areaToDisplayValue), `AreasMapCanvas, plotAreasMap, area with id : ${asRegionId.regionId} not found`);
        canvas2DContext.save();
        canvas2DContext.fillStyle = asRegionId.labelColor ?? settings.colors.Monochromatic.Black;
        canvas2DContext.font = '12px nortw05-bold';
        canvas2DContext.textAlign = 'center';
        canvas2DContext.textBaseline = 'top';
        const labelMetrics = getLabelMetrics(canvas2DContext, asRegionId.label);
        const labelX = areaToDisplayValue.startX + areaToDisplayValue.halfWidth;
        const labelY = areaToDisplayValue.startY + areaToDisplayValue.halfHeight - labelMetrics.halfHeight;
        canvas2DContext.fillText(asRegionId.label, labelX, labelY);
        canvas2DContext.restore();
      }
    }

    if (shouldDisplayAreaLabels) {
      for (const areaLabelLayout of areaLabelLayoutsToDisplay) {
        displayAreaLabel(areaLabelLayout);
      }
    }

    canvas2DContext.restore();

    onAreasLayoutUpdated?.(areasLayout);
  };

  return <Canvas className={className} draw={plotAreasMap} width={metrics.canvasWidth} height={metrics.canvasHeight} />;
};

export default React.memo(AreasMapCanvas);
