import { settings } from '@rhim/design';
import { isDefined } from '@rhim/utils';
import React from 'react';
import styled from 'styled-components';

import { DatasetConfiguration, ShapeType } from '../theme';
import { AreaShape, Glyph, Line, Rect } from './items';

interface Props {
  className?: string;
  config: DatasetConfiguration[keyof DatasetConfiguration];
  isAlert?: boolean;
  isDisabled?: boolean;
}

const LegendVisualComponent: React.FC<Props> = (props) => {
  const { className, config, isAlert = false, isDisabled = false } = props;

  const strokeColor = isDisabled
    ? settings.colors.Primary.Grey_4
    : isAlert && 'strokeColorAlert' in config
    ? config.strokeColorAlert
    : 'strokeColor' in config
    ? config.strokeColor
    : undefined;

  const fill = isDisabled ? settings.colors.Primary.Grey_4 : isAlert && 'fillAlert' in config ? config.fillAlert : 'fill' in config ? config.fill : undefined;

  return (
    <Visual className={className}>
      {(() => {
        switch (config.type) {
          case ShapeType.Line:
          case ShapeType.LinePath:
          case ShapeType.Threshold:
            return <Line {...config} {...(isDefined(strokeColor) && { strokeColor })} />;
            break;
          case ShapeType.Glyph:
            return <Glyph {...config} {...(isDefined(fill) && { fill })} />;
            break;
          case ShapeType.Rect:
            return <Rect {...config} />;
            break;
          case ShapeType.AreaShape:
            return <AreaShape {...config} />;
            break;
          default:
            return null;
        }
      })()}
    </Visual>
  );
};

export default React.memo(LegendVisualComponent);

const Visual = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${settings.Spacing.Spacing_200};
  height: ${settings.Spacing.Spacing_200};
`;
