import { VesselType } from '@rhim/rest';
import { RHIMAPOReportingWearManagementApiV1ModelsOrientationLabelDto } from '@rhim/rest/wearManagement';
import { specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api';

type Payload = RHIMAPOReportingWearManagementApiV1ModelsOrientationLabelDto[];

export const useOrientationLabels = (
  customerId: UUID,
  vesseltype: VesselType,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) => {
  return useQuery(getKey(customerId, vesseltype), (context) => queryFn(context, options?.axiosConfiguration), options?.configuration);
};

const getKey = (customerId: UUID, vesselType: VesselType) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'wearManagement',
      scope: 'orientation-labels',
      entity: 'list',
      customerId,
      vesselType,
    },
  ]);

const queryFn = (
  { queryKey: [{ customerId, vesselType }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>,
  axiosConfiguration?: AxiosRequestConfig
) => {
  return API.orientationLabels
    .getOrientationlabelCustomeridVesseltype(customerId, vesselType, { signal, ...axiosConfiguration })
    .then((response) => response.data);
};

useOrientationLabels.getKey = getKey;
useOrientationLabels.queryFn = queryFn;
