import { isDefined } from '@rhim/utils';
import React, { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';

import { useZoomContext } from '../../chartZoom';
import { NearestDatum, Position, TooltipHandlers, TooltipState } from '../types';

export type UseTooltip<Datum> = [ref: React.RefObject<HTMLDivElement>, state: TooltipState<Datum>, handlers: TooltipHandlers<Datum>];

interface UseTooltipProps {
  scrollbarRef?: React.RefObject<Scrollbars> | null;
}

export default function useTooltip<Datum>(props?: UseTooltipProps): UseTooltip<Datum> {
  const [position, setPosition] = useState<Position | null>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [nearestDatum, setNearestDatum] = useState<NearestDatum<Datum> | null>(null);

  const handleBisectXRef = React.useRef<((x: number) => NearestDatum<Datum>) | null>(null);

  const ref = React.useRef<HTMLDivElement>(null);
  const tooltipWidth = ref.current?.getBoundingClientRect().width;

  const hasScrollbarRefProp = isDefined(props?.scrollbarRef);
  const zoomContext = useZoomContext({ isEnabled: props?.scrollbarRef !== null });
  const scrollbarRefContext = isDefined(zoomContext) && isDefined(zoomContext.scrollbarRefs) ? zoomContext.scrollbarRefs.at(0) : undefined;
  const scrollbarRef = hasScrollbarRefProp ? props.scrollbarRef : scrollbarRefContext;

  const onMouseMove = React.useCallback(
    (e: React.MouseEvent) => {
      const { left, top, width } = e.currentTarget.getBoundingClientRect();
      const pointerX = e.clientX - left;
      const pointerY = e.clientY - top;

      const values = scrollbarRef?.current?.getValues();
      const scrollLeft = values?.scrollLeft ?? 0;
      const clientWidth = isDefined(zoomContext) ? width / zoomContext.zoomLevel : width;

      if (typeof handleBisectXRef.current === 'function') {
        setNearestDatum(handleBisectXRef.current(pointerX));
      }

      setPosition((currentPosition) => {
        const _tooltipWidth = isDefined(tooltipWidth) ? tooltipWidth : currentPosition?.tooltipWidth;

        return {
          pointerX,
          pointerY,
          tooltipWidth: _tooltipWidth,
          tooltipX: (x) => {
            const leftBoundary = Math.max(0, x - scrollLeft - (_tooltipWidth ?? 0) / 2);
            const rightBoundary = clientWidth - (_tooltipWidth ?? 0);
            const tooltipX = Math.min(leftBoundary, rightBoundary);
            return tooltipX;
          },
        };
      });
    },
    [scrollbarRef, tooltipWidth, zoomContext]
  );

  const onMouseEnter = React.useCallback(() => {
    setIsHovered(true);
  }, []);

  const onMouseLeave = React.useCallback(() => {
    setIsHovered(false);
    setPosition(null);
    setNearestDatum(null);
  }, []);

  const state = {
    position,
    isHovered,
    nearestDatum,
  };

  const handlers = {
    mouseEvents: {
      onMouseMove,
      onMouseEnter,
      onMouseLeave,
    },
    handleBisectXRef,
  };

  return [ref, state, handlers];
}
