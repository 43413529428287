import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const HexagonRHIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill={fill} fillRule="evenodd">
        <path d="M29.03 24.697c.533-.31.97-1.074.97-1.695V8.952c0-.62-.437-1.384-.97-1.694L16.97.233c-.533-.31-1.407-.31-1.94 0L2.97 7.258C2.437 7.568 2 8.331 2 8.953v14.049c0 .621.437 1.384.97 1.695l12.06 7.024c.533.31 1.407.31 1.94 0l12.06-7.024z" />
        <path
          d="M18 8c.334 0 .646.167.832.445l2 3c.11.164.168.357.168.555v1h2a1 1 0 1 1 0 2h-2v4.5a1 1 0 0 1-1 1h-1V23a1 1 0 0 1-1.993.117L17 23v-2.5h-2V23a1 1 0 0 1-1.993.117L13 23v-2.5h-1a1 1 0 0 1-.993-.883L11 19.5v-3.086l-2.707-2.707a.999.999 0 1 1 1.414-1.414L11 13.586V12c0-.198.06-.391.168-.555l2-3C13.354 8.167 13.666 8 14 8zm-.5 2h-3L13 12.302v4.203c.262-.014 1.708 0 2.945.3 1.237.3 2.823.203 3.055.195v-4.698L17.5 10z"
          fill="#F5F6F7"
        />
      </g>
    </svg>
  );
};

HexagonRHIcon.defaultProps = {
  size: '32',
  fill: settings.colors.Primary.Grey_6,
};
