import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const LiningIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <defs>
        <filter id="sjq2n72tla" width="154.8%" height="282.1%" x="-27.4%" y="-87.5%" filterUnits="objectBoundingBox">
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="8" />
          <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.239215686 0 0 0 0 0.333333333 0 0 0 0 0.403921569 0 0 0 0.1 0" />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g filter="url(#sjq2n72tla)" transform="translate(-176 -1326) translate(80 1295)">
            <g>
              <path
                d="M.426 8.178c.266-.187.606-.233.914-.122l2.526.912 5.976-.959c.288-.046.584.036.807.226.222.19.35.468.35.761v6c0 .553-.446 1-1 1H1c-.552 0-1-.447-1-1v-6c0-.325.16-.631.427-.818zM15 6.996c.553 0 1 .448 1 1s-.447 1-1 1v5c.553 0 1 .448 1 1s-.447 1-1 1h-2c-.553 0-1-.448-1-1s.447-1 1-1v-5c-.553 0-1-.448-1-1s.447-1 1-1zm-14-3c.553 0 1 .448 1 1v1c0 .552-.447 1-1 1-.553 0-1-.448-1-1v-1c0-.552.447-1 1-1zm9-.325c.553 0 1 .448 1 1v1c0 .552-.447 1-1 1-.553 0-1-.448-1-1v-1c0-.552.447-1 1-1zm-5.854-1.97c.262-.486.87-.666 1.355-.404l.879.476c.485.263.666.87.402 1.356-.18.334-.524.524-.88.524-.161 0-.324-.04-.475-.121l-.88-.476c-.484-.263-.665-.87-.401-1.355zm5.265-.575c.539-.153 1.085.155 1.238.684.153.53-.153 1.085-.684 1.238l-.961.277c-.092.027-.186.04-.277.04-.435 0-.834-.285-.961-.723-.153-.531.153-1.085.684-1.238zM1.72.916c.29-.47.905-.618 1.375-.327.471.29.616.906.327 1.376l-.524.852c-.189.306-.517.475-.853.475-.178 0-.36-.047-.523-.148-.47-.29-.616-.906-.327-1.376z"
                transform="translate(96 31)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

LiningIcon.defaultProps = {
  size: '16',
  fill: 'currentColor',
};
