/* eslint-disable @typescript-eslint/ban-types */

import { isDefined, isDictionaryLike, UNSAFE_keys } from '@rhim/utils';
import { isObjectLike } from 'lodash';
import { useCallback, useState } from 'react';

export interface VersionedObject<T extends object> {
  version: number;
  data: T;
}

function isVersioned(candidate: unknown): candidate is VersionedObject<object> {
  return (
    isDefined(candidate) &&
    isDictionaryLike(candidate) &&
    'data' in candidate &&
    isObjectLike(candidate['data']) &&
    'version' in candidate &&
    typeof candidate['version'] === 'number'
  );
}

function mergeState<T extends object>(initialState: VersionedObject<T>, currentState: T) {
  // collect values from the persisted state when keys match with initialState
  const matchesInStore = UNSAFE_keys(initialState.data).reduce<Partial<T>>(function (acc, cur) {
    return Object.hasOwnProperty.call(currentState, cur) ? { ...acc, [cur]: currentState[cur] } : acc;
  }, {});

  return { ...initialState.data, ...matchesInStore };
}

function getVersionedState<T extends object>(initialState: VersionedObject<T>, currentState: VersionedObject<T> | T): T {
  const isVersionMismatch = (obj1: VersionedObject<T>, obj2: VersionedObject<T> | T) => {
    // persisted state not versioned yet is also considered a mismatch
    if (!isVersioned(obj2)) {
      return true;
    }

    return obj1.version !== obj2.version;
  };

  const currentStateData: T = isVersioned(currentState) ? currentState.data : currentState;

  if (isVersionMismatch(initialState, currentState)) {
    return mergeState(initialState, currentStateData);
  }

  return currentStateData;
}

function versionState<T extends object>(version: number, state: T): VersionedObject<T> {
  return {
    version,
    data: state,
  };
}

/**
 * To be used when persisting objects on localStorage using a persistence library.
 * This will ensure that changes in the data structure of the initial state are reflected in the state even when the state is already persisted on localStorage.
 * If a property is added to the initialState that property will now by part of a merged state, which includes values from the persisited state when keys match.
 * In order for the merge to take effect, this method need to be either introduced or the version needs to be bumped.
 * @param initialState VersionedObject wrapping the initial state object and providing the version number.
 * @param persistedState State hook provided by the persistence library.
 * @returns Returns a stateful value, and a function to update it.
 * @example
 * ```
 * import createPersistedState from 'use-persisted-state';
 *
 * interface MyObject {
 *   myFirstProp: string;
 * }
 *
 * type MyVersionedObject = VersionedObject<MyObject>;
 *
 * const initialState: MyVersionedObject {
 *   // bump version here to migrate exisiting data
 *   // when structure of MyObject changes
 *   version: 1,
 *   data: {
 *     myFirstProp: 'hello world',
 *   }
 * }
 *
 * const persistedState = createPersistedState('localization');
 *
 * // use the hook
 * const [state, setState] = useVersionedState(initialState, persistedState);
 * ```
 */
export function useVersionedState<T extends object>(initialState: VersionedObject<T>, persistedState: typeof useState) {
  const [state, setState] = persistedState(initialState);

  const versionedState = getVersionedState(initialState, state);

  const setVersionedState = useCallback(
    (newState: React.SetStateAction<T>) => {
      const newStateValue: T = newState instanceof Function ? newState(versionedState) : newState;

      setState(versionState(initialState.version, newStateValue));
    },
    [setState, initialState.version, versionedState]
  );

  return [versionedState, setVersionedState] as const;
}
