import { useMemoCompare } from '@rhim/react';
import { hasElements, head, isDefined } from '@rhim/utils';
import * as React from 'react';
import { useCallback, useContext, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import AppContext from '../app/AppContext';
import { PARAMS } from '../utilities';
import { useCustomerIdHashmap } from './useCustomerIdHashmap';
import { usePersistedVessel } from './usePersistedVessel';

interface Props {
  selectedVesselId: UUID | null;
  navigateToSelectedVesselId: (vesselId: UUID) => void; // Persists the vessel id into local storage & adds the selected vessel id search param to the link.
  setSelectedVesselId: React.Dispatch<React.SetStateAction<UUID | null>>; //Used for persisting the vessel ID into local storage.
}

export function useSelectedVessel(vessels: APO.VesselV2[]): Props {
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedCustomer } = useContext(AppContext);

  // eslint-disable-next-line compat/compat
  const query = new URLSearchParams(location.search);
  const queryVesselId = query.get(PARAMS.VESSEL_ID);
  const [persistedVesselId, setSelectedVesselId] = usePersistedVessel(vessels, queryVesselId as UUID | null);
  const { getCustomerIdParam } = useCustomerIdHashmap();
  /**
   * Returns the selected vessel ID.
   */
  const selectedVesselId = useMemo((): UUID | null => {
    if (isDefined(queryVesselId) && vessels.some((vessel) => vessel.id === queryVesselId)) {
      setSelectedVesselId(queryVesselId as UUID);
      return queryVesselId as UUID;
    }
    if (isDefined(vessels)) {
      if (isDefined(persistedVesselId) && vessels.some((vessel) => vessel.id === persistedVesselId)) {
        return persistedVesselId;
      }
      if (hasElements(vessels)) {
        return head(vessels).id;
      }
    }
    return null;
  }, [queryVesselId, vessels, setSelectedVesselId, persistedVesselId]);

  /**
   * Navigate to the recently selected vessel ID, which will trigger the below use effect to take place and persist the vessel in the local storage.
   */
  const navigateToSelectedVesselId = useCallback(
    (vesselId: UUID) => {
      if (vesselId === selectedVesselId) {
        // user selected the same vessel from the dropdown as the currently selected one
        return;
      }
      navigate(`${location.pathname}?${getCustomerIdParam(selectedCustomer.customerId)}&${PARAMS.VESSEL_ID}=${vesselId}`, { replace: true });
    },
    [location.pathname, selectedVesselId, navigate, selectedCustomer.customerId, getCustomerIdParam]
  );

  /**
   * Will have effect after the query vessel id from the path will change (once the user selects a new vessel Id).
   */
  React.useEffect(() => {
    if (queryVesselId === null) {
      return;
    }

    setSelectedVesselId(queryVesselId as UUID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryVesselId]);

  const memoizedValue: UUID | null = useMemoCompare(selectedVesselId, (previousId, nextId) => {
    return previousId === nextId;
  });

  return { selectedVesselId: memoizedValue, navigateToSelectedVesselId, setSelectedVesselId };
}
