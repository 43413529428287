import { settings } from '@rhim/design';
import { Icon8 } from '@rhim/icons';
import { ColorScales, head, isDefined, last } from '@rhim/utils';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

export const COLOR_CELL_HEIGHT_PX = 24;
export const VALUE_INDICATOR_HEIGHT_PX = 2;

interface ValueIndicatorProps {
  topY: number;
  value: number;
  isFacingDown: boolean;
}
const ValueIndicator: React.ChildlessComponent<ValueIndicatorProps> = ({ topY, value, isFacingDown }) => {
  return (
    <SValueIndicator top={topY}>
      <SValueIndicatorLabel>{value}</SValueIndicatorLabel>
      {isFacingDown ? <SChevronFilledDownIcon /> : <SChevronFilledUpIcon />}
    </SValueIndicator>
  );
};

type Props = {
  className?: string;
  colorScales: ColorScales;
  colorBandsCount: number;
  domainDataValueMin: number;
  domainDataValueMax: number;
  onColorScalesUpdated?: (colorScales: ColorScales) => void;
};

const ColorScale: React.ChildlessComponent<Props> = ({ className, colorScales, colorBandsCount, domainDataValueMin, domainDataValueMax }) => {
  const bodyDomElement = useRef<HTMLDivElement>(null);
  const [minValueIndicatorY, setMinValueIndicatorY] = useState(0);
  const [maxValueIndicatorY, setMaxValueIndicatorY] = useState(0);

  const colorScalesBarHeight = (colorBandsCount - 1) * COLOR_CELL_HEIGHT_PX;
  const colorScalesThresholdMin = head(colorScales).threshold;
  const colorScalesThresholdMax = last(colorScales).threshold;
  const colorBandsStep = (colorScalesThresholdMax - colorScalesThresholdMin) / (colorBandsCount - 1);

  /**
   * Sets domain data min & max values indicators positions
   */
  useEffect(() => {
    if (!isDefined(bodyDomElement.current)) {
      return;
    }
    const colorScalesValueMin = head(colorScales).threshold;
    const colorScalesValueMax = last(colorScales).threshold;
    const colorScalesValueRange = colorScalesValueMax - colorScalesValueMin;
    const bodyHeight = bodyDomElement.current.clientHeight - COLOR_CELL_HEIGHT_PX;
    setMinValueIndicatorY((bodyHeight * (domainDataValueMin - colorScalesValueMin)) / colorScalesValueRange);
    let y = 0;
    if (domainDataValueMax >= colorScalesValueMax) {
      // If the maximum domain value is above the colorScalesMaxThreshold, position the indicator in the middle of the bottom cell :
      // This bottom cell, unlike all other cells which have a defined range of values, covers all values from colorScalesMaxThreshold to Infinity and
      // we cannot interpolate to some y position in-between the height of this cell so we just position it at the middle of this bottom cell.
      y = bodyDomElement.current.clientHeight - COLOR_CELL_HEIGHT_PX / 2 - VALUE_INDICATOR_HEIGHT_PX / 2;
    } else {
      y = (bodyHeight * (domainDataValueMax - colorScalesValueMin)) / colorScalesValueRange;
    }
    setMaxValueIndicatorY(y);
  }, [colorScales, domainDataValueMin, domainDataValueMax]);

  /**
   * Generates the "from - to" value range label for each color band
   */
  const colorBandsLabels = useMemo(() => {
    const ret = [];
    let colorBandValueFrom = colorScalesThresholdMin;
    for (let i = 0; i < colorBandsCount; i++) {
      const colorBandValueTo = colorBandValueFrom + colorBandsStep - 1;
      ret.push(
        <SColorBandValueRange key={i}>
          {i !== colorBandsCount - 1 ? `${Math.round(colorBandValueFrom)} - ${Math.round(colorBandValueTo)}` : `>=${Math.round(colorBandValueFrom)}`}
        </SColorBandValueRange>
      );
      colorBandValueFrom += colorBandsStep;
    }
    return ret;
  }, [colorBandsStep, colorScalesThresholdMin, colorBandsCount]);

  return (
    <SWrapper className={className} ref={bodyDomElement}>
      <SColorScaleContainer>
        <SColorScaleBarContainer>
          {colorScales.map((colorScale, index) => {
            const colorScalesNextIndex = colorScales[index + 1];
            const nextColorBandThreshold = index !== colorScales.length - 1 && isDefined(colorScalesNextIndex) ? colorScalesNextIndex.threshold : 0;
            let cellHeight;
            if (index === colorScales.length - 1) {
              // the very last bottom cell - which represents values ranging from colorScaleMaxThreshold to Infinity - always has a fixed height
              cellHeight = COLOR_CELL_HEIGHT_PX;
            } else {
              cellHeight = (colorScalesBarHeight * (nextColorBandThreshold - colorScale.threshold)) / (colorScalesThresholdMax - colorScalesThresholdMin);
            }
            const nextColorBand = index <= colorScales.length - 1 ? colorScales[index + 1] : undefined;
            const colorBandMaxValue = isDefined(nextColorBand) ? nextColorBand.threshold - 1 : Number.POSITIVE_INFINITY;
            const isFilled = colorBandMaxValue >= domainDataValueMin && colorScale.threshold <= domainDataValueMax;
            return <SBlock key={index} height={cellHeight} color={colorScale.color} isFilled={isFilled} />;
          })}
          <ValueIndicator topY={minValueIndicatorY} value={domainDataValueMin} isFacingDown={true} />
          <ValueIndicator topY={maxValueIndicatorY} value={domainDataValueMax} isFacingDown={false} />
        </SColorScaleBarContainer>
        <SColorScaleBandLabelsContainer>{colorBandsLabels}</SColorScaleBandLabelsContainer>
      </SColorScaleContainer>
    </SWrapper>
  );
};
export default React.memo(ColorScale);
ColorScale.whyDidYouRender = true;

const SWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const SColorScaleContainer = styled.div`
  display: flex;
`;

const SColorScaleBarContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const SBlock = styled.div<{ height: number; color: string; isFilled: boolean }>`
  width: 24px;
  height: ${(props) => props.height}px;
  background-color: ${(props) => (props.isFilled ? props.color : 'transparent')};
  box-shadow: 0 0 0 2px ${(props) => props.color} inset;
`;

const SValueIndicator = styled.div<{ top: number }>`
  position: absolute;
  top: ${(props) => props.top}px;
  left: -8px;
  height: ${VALUE_INDICATOR_HEIGHT_PX}px;
  width: 40px;
  background-color: ${settings.colors.Primary.Grey_8};
`;

const SValueIndicatorLabel = styled.span`
  /* stylelint-disable-next-line */
  user-select: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 46px;
  color: ${settings.colors.Primary.Grey_8};
  font-size: ${settings.typography.FontSize.X_Small};
  font-family: ${settings.typography.FontFamily.Bold};
`;

const SChevronFilledDownIcon = styled(Icon8.ChevronFilledDownIcon)`
  position: absolute;
  right: -4px;
  top: 0;
`;

const SChevronFilledUpIcon = styled(Icon8.ChevronFilledUpIcon)`
  position: absolute;
  right: -4px;
  top: -4px;
`;

const SColorScaleBandLabelsContainer = styled.div`
  margin-left: ${settings.Spacing.Spacing_150};
  display: flex;
  flex-direction: column;
`;

const SColorBandValueRange = styled.span`
  height: ${COLOR_CELL_HEIGHT_PX}px;
  line-height: ${COLOR_CELL_HEIGHT_PX}px;
  color: ${settings.colors.Primary.Grey_8};
  font-size: ${settings.typography.FontSize.X_Small};
  font-family: ${settings.typography.FontFamily.Regular};
  user-select: none;
`;
