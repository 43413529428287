import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const ChevronDoubleLeftIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <path
              d="M8.293 2.293c.391-.391 1.023-.391 1.414 0l5 5c.391.391.391 1.023 0 1.414l-5 5C9.512 13.902 9.256 14 9 14c-.256 0-.512-.098-.707-.293-.391-.391-.391-1.023 0-1.414L12.586 8 8.293 3.707c-.391-.391-.391-1.023 0-1.414zm-8 0c.391-.391 1.023-.391 1.414 0l5 5c.391.391.391 1.023 0 1.414l-5 5C1.512 13.902 1.256 14 1 14c-.256 0-.512-.098-.707-.293-.391-.391-.391-1.023 0-1.414L4.586 8 .293 3.707c-.391-.391-.391-1.023 0-1.414z"
              transform="translate(-2032 -933) translate(2032 933) matrix(-1 0 0 1 15 0)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

ChevronDoubleLeftIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
