import { ChevronUpIcon } from '@rhim/icons/24';
import React from 'react';
import styled from 'styled-components';

interface Props extends React.ComponentPropsWithoutRef<typeof ChevronUpIcon> {
  orientation: 'up' | 'down';
}

const ChevronUpIconWithConfig = styled(ChevronUpIcon).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['isDisabled'].includes(prop) && defaultValidatorFn(prop),
})`
  display: inline-flex;
  transform: rotateZ(${(props) => (props.orientation === 'up' ? '0deg' : '180deg')});
  transition: transform 0.25s ease-out;
`;

/**
 * Wrapping the component in order to avoid the TS4023 error.
 *
 * @see https://github.com/ajv-validator/ajv/issues/1814#issuecomment-966988166
 */
function ChevronRotatable(props: Props) {
  return <ChevronUpIconWithConfig {...props} />;
}

export default ChevronRotatable;
