import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const BurgerIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M18.933 17c.59 0 1.067.448 1.067 1s-.478 1-1.067 1H5.067C4.477 19 4 18.552 4 18s.478-1 1.067-1zm0-6c.59 0 1.067.448 1.067 1s-.478 1-1.067 1H5.067C4.477 13 4 12.552 4 12s.478-1 1.067-1zm0-6C19.523 5 20 5.448 20 6s-.478 1-1.067 1H5.067C4.477 7 4 6.552 4 6s.478-1 1.067-1z"
      />
    </svg>
  );
};

BurgerIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
