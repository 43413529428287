import { toArray } from '@rhim/utils/collections';
import { isDefined } from '@rhim/utils/is-defined';
import { specific } from '@rhim/utils/objects';

export type Severity = 'info' | 'debug' | 'warn' | 'error';

export type LogLevel = Severity | 'off';

export interface Configuration {
  handlers: Record<Severity, Handler>;
  /**
   * Only log messages with a severity equal or higher than this value.
   */
  logLevel: LogLevel;
  /**
   * Identify the origin of the log message. For example: "Core", "Authentication", "Cache".
   */
  context?: Message;
  format?: Formatter;
}

export interface Handler {
  (...message: Message[]): void;
}

export interface Formatter {
  (message: Message[], severity: Severity, context?: Message): Message[];
}

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Console
 */
type Message = unknown;

export class Logger {
  public static defaults = specific<Configuration>()({
    handlers: console,
    logLevel: 'info',
    format: (message) => message,
  });

  protected status: 'active' | 'muted' = 'active';

  protected configuration: Configuration;

  constructor(configuration: DeepPartial<Configuration> = {}) {
    this.configuration = { ...Logger.defaults, ...configuration, handlers: Object.assign({}, Logger.defaults.handlers, configuration.handlers) };
  }

  protected report(message: Message[], severity: Severity): void {
    const isUnmuted = this.status === 'active';
    const isSeriousEnough = this.getPriority(severity) >= this.getPriority(this.configuration.logLevel);

    if (isUnmuted && isSeriousEnough) {
      if (isDefined(this.configuration.format)) {
        this.configuration.handlers[severity](...this.configuration.format(message, severity, this.configuration.context));
      } else {
        this.configuration.handlers[severity](...message);
      }
    }
  }

  protected getPriority(logLevel: LogLevel): number {
    switch (logLevel) {
      case 'off':
        return Infinity;
      case 'error':
        return 4;
      case 'warn':
        return 3;
      case 'debug':
        return 2;
      case 'info':
        return 1;
    }
  }

  /**
   * Used for everyday diagnostic information. Describes what the module is doing.
   *
   * @example
   *
   * ```ts
   * const logger = new Logger();
   *
   * logger.info(`Web server is listening on https://localhost:3000`)
   * ```
   */
  public info(...message: Message[]): void {
    this.report(message, 'info');
  }

  /**
   * Prefer `debug` when loging data (JSON-like structures, JavaScript objects) to the console.
   * Exposing raw data allows the console user to inspect and manipulate it.
   *
   * @example
   *
   * ```ts
   * const logger = new Logger();
   *
   * logger.debug(`Intercepted request:`, { request })
   * ```
   */
  public debug(...message: Message[]): void {
    this.report(message, 'debug');
  }

  /**
   * Brings attention to non-critical problems.
   *
   * @example
   *
   * ```ts
   * const logger = new Logger();
   *
   * logger.warn(`Request contains unexpected parameters: "campaign", "uid".`);
   * ```
   */
  public warn(...message: Message[]): void {
    this.report(message, 'warn');
  }

  /**
   * Brings attention to critical problems.
   *
   * @example
   *
   * ```ts
   * const logger = new Logger();
   *
   * logger.error(`WebSocket timed out after 30 seconds. The application will reboot now.`);
   * ```
   */
  public error(...message: Message[]): void {
    this.report(message, 'error');
  }

  /**
   * Creates a _child logger_ that inherits the contexts of its parent.
   *
   * @example
   *
   * ```ts
   * const parent = new Logger({ context: "Parent" });
   * const child = parent.child("Child");
   *
   * child.debug("Hello from the child");
   * ```
   */
  public child(context: Message): Logger {
    return new Logger({
      ...this.configuration,
      context,
      format: (message, severity): Message[] => {
        if (isDefined(this.configuration.format)) {
          return this.configuration.format(
            message,
            severity,
            isDefined(this.configuration.context) ? [this.configuration.context, ...toArray(context)] : [...toArray(context)]
          );
        }

        return Logger.defaults.format(message);
      },
    });
  }

  public mute(): void {
    this.status = 'muted';
  }
  public unmute(): void {
    this.status = 'active';
  }

  public get isMuted(): boolean {
    return this.status === 'muted';
  }
}
