/**
 * Maximum file size in bytes.
 * Does not accept a file bigger than 750 MB.
 * This should reject the file before it hits any server.
 */
export const MAXIMUM_FILE_SIZE_IN_BYTES = 750 * 1000000;

/**
 * Minimum file size in bytes.
 * Does not accept a file smaller than 5 bytes.
 * This should reject the file before it hits any server.
 */
export const MINIMUM_FILE_SIZE_IN_BYTES = 5;

export const ONE_MINUTE_IN_MILISECONDS = 60 * 1000;

/**
 * Maximum Heat number
 */
export const MAX_HEAT = 5000;

/**
 * Hotspot types
 */
export enum HOTSPOT_TYPE {
  PREDICTION = 'prediction',
  MEASUREMENT = 'measurement',
  PROCESS = 'process',
}
