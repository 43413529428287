import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const ExploreParametersIcon: React.FunctionComponent<Props> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 21a1 1 0 0 0-1-1H5V4h9v2a1 1 0 0 0 1 1h2v3a1 1 0 1 0 2 0V6a.115.115 0 0 0-.006-.032.133.133 0 0 1-.006-.03 1.035 1.035 0 0 0-.051-.258l-.006-.017a.962.962 0 0 0-.224-.37l-3-3a.989.989 0 0 0-.294-.196c-.02-.01-.041-.017-.063-.024l-.027-.009a.997.997 0 0 0-.264-.052.117.117 0 0 1-.027-.006A.109.109 0 0 0 15 2H4a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1zm2-3c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm5.707 2.293-2-2a.986.986 0 0 0-.311-.206A3.956 3.956 0 0 0 20 16c0-2.206-1.794-4-4-4s-4 1.794-4 4 1.794 4 4 4c.768 0 1.478-.228 2.087-.604a.986.986 0 0 0 .206.311l2 2a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414zm-7.263-8.726a1 1 0 0 1-1.558.112L9.34 7.842l-1.412 3.53a.998.998 0 0 1-1.299.557 1 1 0 0 1-.558-1.3l2-5a.999.999 0 0 1 1.663-.308l3.772 4.082.67-.97a1 1 0 0 1 1.647 1.134l-1.379 2zM7 14a1 1 0 0 0-1 1v2a1 1 0 1 0 2 0v-2a1 1 0 0 0-1-1zm2 3v-2a1 1 0 1 1 2 0v2a1 1 0 1 1-2 0z"
        fill={fill}
      />
    </svg>
  );
};

ExploreParametersIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
