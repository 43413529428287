import { AuthError } from '@azure/msal-browser';

export enum MSALErrorDescription {
  /**
   * @example
   * ServerError: access_denied: AADB2C90118: The user has forgotten their password.
   */
  PasswordForgotten = 'AADB2C90118',
  LoginCancelled = 'AADB2C90091',
}

export function isLoginCancelled(error: AuthError): boolean {
  return error.errorCode === 'access_denied' && error.errorMessage.includes(MSALErrorDescription.LoginCancelled);
}

export function isPasswordForgotten(error: AuthError): boolean {
  return error.errorMessage.includes(MSALErrorDescription.PasswordForgotten);
}

export function isLoginInProgress(error: AuthError): boolean {
  return error.message.includes('interaction_in_progress');
}

export function isUserDisabled(error: AuthError): boolean {
  return error.message.includes('is disabled');
}
