import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const DrillingMeasurementIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0a1 1 0 0 0-1 1 1 1 0 0 0-.447 1.894L1 3.118V6a1 1 0 0 0-.447 1.894L1 8.118V11a1 1 0 0 0 .2.6l3 4a1 1 0 0 0 1.6 0l2.772-3.696a1 1 0 0 0 .875-1.798L9 9.882V7a1 1 0 0 0 .447-1.894L9 4.882V1a1 1 0 0 0-1-1H2zm1 6.882V4.118l4 2v2.764l-4-2zM3.236 2 7 3.882V2H3.236zM3 9.118v1.549l2 2.666 1.754-2.338L3 9.118zM13 3v10a1 1 0 0 0-.707 1.707l1 1a1 1 0 0 0 1.414 0l1-1A1 1 0 0 0 15 13V3a1 1 0 0 0 .707-1.707l-1-1a1 1 0 0 0-1.414 0l-1 1A1 1 0 0 0 13 3z"
      />
    </svg>
  );
};

DrillingMeasurementIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Grey_6,
};
