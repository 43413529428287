import { isDefined } from '@rhim/utils';
import { useCallback, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import AppContext from '../app/AppContext';
import { Invariants } from '../errors';
import { useCustomerIdHashmap } from '../hooks/useCustomerIdHashmap';
import { PARAMS } from '../utilities';

const useShortHashedSelectedCustomer = (customerId: UUID) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const queryCustomerHash = params.get(PARAMS.CUSTOMER_ID);
  const { getCustomerIdByHash } = useCustomerIdHashmap();
  const { user } = useContext(AppContext);

  const hasCustomerAccess = useCallback(
    (): boolean => !isDefined(queryCustomerHash) || (isDefined(queryCustomerHash) && isDefined(getCustomerIdByHash(queryCustomerHash))),
    [getCustomerIdByHash, queryCustomerHash]
  );

  useEffect(() => {
    if (!isDefined(user.customers) || !isDefined(queryCustomerHash)) {
      return;
    }

    if (!hasCustomerAccess()) {
      throw new Error(Invariants.NoAccessToCustomer);
    }
  }, [customerId, hasCustomerAccess, user.customers, queryCustomerHash, getCustomerIdByHash]);

  return { hasCustomerAccess };
};

export default useShortHashedSelectedCustomer;
