import { i18nReact } from '@rhim/i18n';
import { Legal, Tabs } from '@rhim/react';
import { FC } from 'react';

import { ROUTES } from '../../utilities';
import LegalTabCodeOfConduct from './LegalTabCodeOfConduct';
import LegalTabImprint from './LegalTabImprint';
import LegalTabPrivacy from './LegalTabPrivacy';
import LegalTabTermsOfUse from './LegalTabTermsOfUse';

const LegalPage: FC<React.PropsWithChildren> = () => {
  const { t } = i18nReact.useTranslation(['static']);

  return (
    <Legal.LegalPage title={t('static:legal.title')}>
      <Tabs.TabPane tab={t('static:legal.topics.imprint.title')} key={ROUTES.IMPRINT}>
        <LegalTabImprint />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('static:legal.topics.privacy.title')} key={ROUTES.PRIVACY}>
        <LegalTabPrivacy />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('static:legal.topics.terms-of-use.title')} key={ROUTES.TERMS_OF_USE}>
        <LegalTabTermsOfUse />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('static:legal.topics.code-of-conduct.title')} key={ROUTES.CODE_OF_CONDUCT}>
        <LegalTabCodeOfConduct />
      </Tabs.TabPane>
    </Legal.LegalPage>
  );
};

export default LegalPage;
