import { LadleMaterialOptions } from '../types';

export const MESH_CONTAINER_NAME = 'MeshContainer';

export enum SceneName {
  MainScene = 'mainScene',
}

export interface Scene3dInitOptions {
  material: LadleMaterialOptions;
  withGizmo?: boolean;
  isProduction?: boolean;
  forceInit?: boolean;
}
