import { isDefined } from '@rhim/utils';
import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  fill: string | undefined;
  cx: number;
  cy: number;
  strokeWidth?: number;
  stroke?: string;
  r: number;
  onMouseEnter?: React.MouseEventHandler<SVGCircleElement>;
  onMouseLeave?: React.MouseEventHandler<SVGCircleElement>;
}

export const DataPoint: React.FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  const { fill, cx, cy, r, stroke, strokeWidth, onMouseEnter, onMouseLeave, ...rest } = props;
  const hasPointerEvent = isDefined(onMouseEnter) || isDefined(onMouseLeave);

  return (
    <Circle
      strokeWidth={strokeWidth}
      stroke={stroke}
      fill={fill}
      cx={cx}
      cy={cy}
      r={r}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      hasPointer={hasPointerEvent}
      {...rest}
    />
  );
};

const Circle = styled.circle<{ strokeWidth?: number; stroke?: string; hasPointer: boolean }>`
  stroke: ${(props) => (isDefined(props.stroke) ? props.stroke : 'none')};
  stroke-width: ${(props) => (isDefined(props.stroke) ? `${props.strokeWidth}px` : 0)};
  cursor: ${(props) => (props.hasPointer ? 'pointer' : 'default')};
`;
