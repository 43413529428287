import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const ExpandArrowsIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" role={role} {...rest} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.293 11.293 9 12.586V3.414l1.293 1.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3-3a.999.999 0 0 0-1.414 0l-3 3a.999.999 0 1 0 1.414 1.414L7 3.414v9.172l-1.293-1.293a.999.999 0 1 0-1.414 1.414l3 3a.997.997 0 0 0 1.414 0l3-3a.999.999 0 1 0-1.414-1.414z"
        fill={fill}
      />
    </svg>
  );
};

ExpandArrowsIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Grey_4,
};
