import { getVesselIcon } from '@rhim/react';
import { vesselsDropdownItem } from '@rhim/test-ids';
import React, { useCallback } from 'react';
import { useHover } from 'react-aria';
import styled from 'styled-components';

import { CellWrapper, getItemRowColors, ItemRow, PrimaryInfo, PrimaryLabel } from '../../pages/MeasurementView/MeasurementViewStrip/shared';

interface Referable {
  ref?: React.Ref<HTMLTableRowElement>;
}

interface Props {
  vessel: APO.VesselV2;
  isSelectedVessel: boolean;
  onVesselClicked: (vesselId: UUID) => void;
}
const VesselSelectionItem = React.forwardRef<HTMLTableRowElement, Props & Referable>(function VesselSelectionItem(
  { vessel, isSelectedVessel, onVesselClicked },
  ref
) {
  const { hoverProps, isHovered } = useHover({});

  const handleVesselClicked = useCallback(() => {
    onVesselClicked(vessel.id);
  }, [vessel, onVesselClicked]);

  const { rowPrimaryInfo } = getItemRowColors({ isSelected: isSelectedVessel, isHover: isHovered });

  return (
    <ItemRow
      variant="medium-48"
      ref={ref}
      data-test-id={vesselsDropdownItem}
      {...hoverProps}
      style={{ backgroundColor: rowPrimaryInfo.backgroundColor }}
      onClick={handleVesselClicked}
    >
      <td>
        <CellWrapper>
          <PrimaryInfo style={rowPrimaryInfo}>
            {getVesselIcon(vessel.vesselType, rowPrimaryInfo.fill)}
            <SPrimaryLabel>{vessel.displayName}</SPrimaryLabel>
          </PrimaryInfo>
        </CellWrapper>
      </td>
    </ItemRow>
  );
});

export default React.memo(VesselSelectionItem);

const SPrimaryLabel = styled(PrimaryLabel)`
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
