import type {
  RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto,
  RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto,
} from '@rhim/rest/customerManagement';
import { isDefined } from '@rhim/utils/is-defined';
import React from 'react';

/**
 * When only one argument is provided, the privileges belonging to the user are returned.
 * This includes the global privileges and the privileges belonging to _any_ customer assigned to this user.
 *
 * @example
 *
 * ```ts
 * const privileges = usePrivileges(user);
 * const hasAccess = privileges.global.has('AccessCockpit')
 * ```
 *
 * To check if the user has access to a feature, it's helpful to check for two things: the global privileges and the privileges belonging to any customer assigned to this user.
 *
 * @example
 *
 * ```ts
 * const privileges = usePrivileges(user);
 * const hasAccess = privileges.global.has('AccessCockpit') || privileges.anyCustomer.has('AccessCockpit')
 * ```
 *
 * When, additionally, a selected customer is provided, the privileges belonging to this customer are also available.
 *
 * @example
 *
 * ```ts
 * const privileges = usePrivileges(user, customer);
 * const hasAccess = privileges.customer.has('AccessMeasurementView');
 * ```
 */
export function usePrivileges(
  ...args: [user: RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto, customer: RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto]
): AllPrivileges;
export function usePrivileges(...args: [user: RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto]): UserPrivileges;
export function usePrivileges(
  ...args:
    | [user: RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto]
    | [user: RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto, customer: RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto]
): UserPrivileges | AllPrivileges {
  const [user, customer] = args;

  const privileges = React.useMemo(() => {
    if (isDefined(customer)) {
      return getPrivileges(user, customer);
    } else {
      return getPrivileges(user);
    }
  }, [user, customer]);

  return privileges;
}

/**
 * Privileges either global or belonging to any customer assigned to this user.
 */
export interface UserPrivileges {
  readonly global: ReadonlySet<string>;
  readonly anyCustomer: ReadonlySet<string>;
}

/**
 * Includes same privileges as {@link UserPrivileges} plus the privileges belonging to the currently selected customer.
 */
export interface AllPrivileges extends UserPrivileges {
  readonly customer: ReadonlySet<string>;
}

export function getPrivileges(
  ...args: [user: RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto, customer: RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto]
): AllPrivileges;
export function getPrivileges(...args: [user: RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto]): UserPrivileges;
export function getPrivileges(
  ...args:
    | [user: RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto]
    | [user: RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto, customer: RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto]
): UserPrivileges | AllPrivileges {
  const [user, customer] = args;
  const globalOperations = user.globalOperations ?? [];
  const anyCustomersOperations = user.customers?.flatMap((customer_) => customer_.operations ?? []) ?? [];

  const privileges = {
    get global() {
      return new Set(globalOperations) as unknown as ReadonlySet<string>;
    },
    get anyCustomer() {
      return new Set(anyCustomersOperations) as unknown as ReadonlySet<string>;
    },
  };

  if (isDefined(customer)) {
    (privileges as Writeable<AllPrivileges>).customer = new Set(customer.operations ?? []) as unknown as ReadonlySet<string>;
  }

  return privileges;
}
