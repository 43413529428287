import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const MicroServiceIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.445.168a1 1 0 0 1 1.11 0l6 4A1 1 0 0 1 15 5v6a1 1 0 0 1-.445.832l-6 4a1 1 0 0 1-1.11 0l-6-4A1 1 0 0 1 1 11V5a1 1 0 0 1 .445-.832l6-4zM3 10.465V6.869l4 2.666v3.597l-4-2.667zm6 2.667 4-2.667V6.869L9 9.535v3.597zM8 2.202 12.197 5 8 7.798 3.803 5 8 2.202z"
        fill={fill}
      />
    </svg>
  );
};

MicroServiceIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
