import React from 'react';

export function withProps<T extends React.ComponentType<React.PropsWithChildren>, U extends Partial<React.ComponentProps<T> & JSX.IntrinsicAttributes>, R>(
  Component: T,
  defaults: U
): React.ForwardRefExoticComponent<React.PropsWithoutRef<Omit<React.ComponentProps<T>, keyof U>> & React.RefAttributes<R>> {
  const renderFunction = React.forwardRef<R, Omit<React.ComponentProps<T>, keyof U>>((props, ref) =>
    React.createElement(Component, { ...defaults, ...props, ref })
  );

  renderFunction.displayName = `withProps(${Component.displayName ?? Component.name})`;

  return renderFunction;
}

export function isDefined<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null;
}
