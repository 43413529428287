import { head, isDefined, last } from '@rhim/utils';
import * as d3 from 'd3';
import { useMemo } from 'react';

const STROKE_WIDTH = 1;

export const getSummaryStats = (data: number[]) => {
  // eslint-disable-next-line etc/no-assign-mutated-array
  const sortedData = data.sort(function (a, b) {
    return a - b;
  }) as NonEmptyArray<number>;
  const q1 = d3.quantile(sortedData, 0.25) ?? 0;
  const median = d3.quantile(sortedData, 0.5) ?? 0;
  const q3 = d3.quantile(sortedData, 0.75) ?? 0;

  const average = d3.mean(sortedData);
  const max = last(sortedData);
  const min = head(sortedData);

  return { min, q1, median, q3, max, average };
};
export interface Stats {
  min: number;
  q1: number;
  median: number;
  q3: number;
  max: number;
  average?: number;
}
interface VerticalBoxProps {
  stats: Stats;
  width: number;
  stroke?: string;
  fill: string;
  x0?: number;
  yScale: d3.ScaleLinear<number, number>;
  min: number;
  max: number;
}

export const VerticalBox = ({ stats, x0 = 0, yScale, width, stroke, fill, max, min }: VerticalBoxProps) => {
  const { q1, q3, median } = stats;

  const medianLine = useMemo(() => {
    if (median >= min && median <= max) {
      return median;
    } else if (median < min) {
      return min;
    }
    return max;
  }, [median, min, max]);

  return (
    <g transform="translate(0,0)">
      <rect x={x0} y={yScale(q3 > max ? max : q3)} width={width} height={yScale(q1 < min ? min : q1 || 0) - yScale(q3 > max ? max : q3 || 0)} fill={fill} />
      {isDefined(min) && isDefined(max) && isFinite(max) && isFinite(min) && median >= min && median <= max && (
        <line x1={x0} x2={width} y1={yScale(medianLine)} y2={yScale(medianLine)} stroke={stroke} strokeDasharray={2} strokeWidth={STROKE_WIDTH} />
      )}
    </g>
  );
};
