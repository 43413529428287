import { Hyperlink } from '@rhim/react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const DisabledFeatureText: React.FunctionComponent = () => {
  const { t } = useTranslation(['app']);

  return (
    <div>
      {t('app:customerFeatures.apo.heading')}{' '}
      <Trans t={t} i18nKey="app:customerFeatures.apo.subHeadingInverted">
        <span>
          Please reach out to{' '}
          <Hyperlink href="mailto:onboarding.digital@rhimagnesita.com" mode="inverted">
            onboarding.digital@rhimagnesita.com
          </Hyperlink>{' '}
          for purchasing.
        </span>
      </Trans>
    </div>
  );
};

export const DisabledFeatureTextQCKlight: React.FunctionComponent = () => {
  const { t } = useTranslation(['app']);

  return (
    <div>
      {t('app:customerFeatures.qckLight.heading')}{' '}
      <Trans t={t} i18nKey="app:customerFeatures.qckLight.subHeadingInverted">
        <span>
          Please reach out to{' '}
          <Hyperlink href="mailto:romy-sophie.katz@rhimagnesita.com" mode="inverted">
            romy-sophie.katz@rhimagnesita.com
          </Hyperlink>{' '}
          for purchasing.
        </span>
      </Trans>
    </div>
  );
};
