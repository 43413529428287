import * as React from 'react';

export function withProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  C extends React.ComponentType<React.PropsWithChildren<any>>,
  D extends Partial<React.ComponentPropsWithoutRef<C>> & JSX.IntrinsicAttributes
>(
  Component: C,
  defaults: D
): React.ForwardRefExoticComponent<React.PropsWithoutRef<Omit<React.ComponentProps<C>, keyof D>> & React.RefAttributes<React.ElementRef<C>>> {
  const renderFunction: React.ForwardRefRenderFunction<React.ElementRef<C>, Omit<React.ComponentProps<C>, keyof D>> = (props, ref) => {
    return React.createElement(Component, { ...props, ...defaults, ref });
  };

  renderFunction.displayName = `withProps(${Component.displayName ?? Component.name})`;

  return React.forwardRef(renderFunction);
}
