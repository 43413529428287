import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const ServicesIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g id="Services_24">
        <g id="Vector">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.5011 13H4.50114C4.36854 13 4.24136 12.9473 4.14759 12.8536C4.05382 12.7598 4.00114 12.6326 4.00114 12.5C4.00114 12.3674 4.05382 12.2402 4.14759 12.1464C4.24136 12.0527 4.36854 12 4.50114 12H6.00114V11C6.00832 9.95175 6.28882 8.9235 6.81494 8.01681C7.34106 7.11013 8.09461 6.35637 9.00114 5.83V8C9.00114 8.26522 9.1065 8.51957 9.29404 8.70711C9.48157 8.89464 9.73593 9 10.0011 9C10.2664 9 10.5207 8.89464 10.7083 8.70711C10.8958 8.51957 11.0011 8.26522 11.0011 8V4H13.0011V8C13.0011 8.26522 13.1065 8.51957 13.294 8.70711C13.4816 8.89464 13.7359 9 14.0011 9C14.2664 9 14.5207 8.89464 14.7083 8.70711C14.8958 8.51957 15.0011 8.26522 15.0011 8V5.83C15.9077 6.35637 16.6612 7.11013 17.1874 8.01681C17.7135 8.9235 17.994 9.95175 18.0011 11V12H19.5011C19.6338 12 19.7609 12.0527 19.8547 12.1464C19.9485 12.2402 20.0011 12.3674 20.0011 12.5C20.0011 12.6326 19.9485 12.7598 19.8547 12.8536C19.7609 12.9473 19.6338 13 19.5011 13ZM19.9421 10.039C19.7636 8.62227 19.212 7.27836 18.3439 6.14461C17.4758 5.01085 16.3223 4.1279 15.0011 3.586V2H9.00114V3.586C7.68003 4.1279 6.52647 5.01085 5.65837 6.14461C4.79026 7.27836 4.23873 8.62227 4.06014 10.039C3.44498 10.149 2.89329 10.4853 2.51379 10.9818C2.13429 11.4783 1.95449 12.0989 2.00982 12.7214C2.06516 13.3438 2.35161 13.923 2.81273 14.3448C3.27386 14.7665 3.87623 15.0003 4.50114 15H19.5011C20.1261 15.0003 20.7284 14.7665 21.1896 14.3448C21.6507 13.923 21.9371 13.3438 21.9925 12.7214C22.0478 12.0989 21.868 11.4783 21.4885 10.9818C21.109 10.4853 20.5573 10.149 19.9421 10.039ZM15.0011 12H9.00114C8.73593 12 8.48157 11.8946 8.29404 11.7071C8.1065 11.5196 8.00114 11.2652 8.00114 11C8.00114 10.7348 8.1065 10.4804 8.29404 10.2929C8.48157 10.1054 8.73593 10 9.00114 10H15.0011C15.2664 10 15.5207 10.1054 15.7083 10.2929C15.8958 10.4804 16.0011 10.7348 16.0011 11C16.0011 11.2652 15.8958 11.5196 15.7083 11.7071C15.5207 11.8946 15.2664 12 15.0011 12Z"
            fill={fill}
          />
          <path
            d="M19.3341 16.01C19.2651 16.025 14.9701 16 12.0011 16C9.69013 16 4.60612 16.025 4.60712 16.01C4.53191 16.0027 4.45601 16.0109 4.3841 16.0341C4.31218 16.0573 4.24575 16.095 4.18897 16.1449C4.13218 16.1947 4.08624 16.2557 4.05393 16.324C4.02162 16.3923 4.00365 16.4665 4.00113 16.542C3.98141 17.3068 4.08265 18.0698 4.30112 18.803C4.42125 19.2914 4.60447 19.762 4.84613 20.203C5.00965 20.46 5.24028 20.6675 5.51312 20.803C6.97759 21.0193 8.46229 21.064 9.93711 20.936C10.1411 20.9032 10.3308 20.8106 10.4821 20.67L10.5431 20.604C10.8063 20.2998 11.0304 19.9639 11.2101 19.604C11.5524 18.9038 11.8166 18.168 11.9981 17.41C12.1406 18.1791 12.4067 18.92 12.7861 19.604C12.9658 19.9639 13.1899 20.2998 13.4531 20.604L13.5141 20.67C13.6579 20.8214 13.8513 20.9158 14.0591 20.936C15.534 21.0629 17.0186 21.0182 18.4831 20.803C18.6651 20.803 18.9071 20.603 19.1501 20.203C19.4174 19.7744 19.6023 19.2996 19.6951 18.803C19.8841 18.0638 19.9848 17.3049 19.9951 16.542C19.9638 16.3904 19.8807 16.2544 19.7601 16.1573C19.6395 16.0603 19.4889 16.0082 19.3341 16.01Z"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
};

ServicesIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
