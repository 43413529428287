import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const MaintenanceIcon: React.FunctionComponent<Props> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 13h-15a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5H6v-1c0-2.153 1.189-4.111 3-5.17V8a1 1 0 1 0 2 0V4h2v4a1 1 0 1 0 2 0V5.83c1.811 1.059 3 3.017 3 5.17v1h1.5c.275 0 .5.225.5.5s-.225.5-.5.5zm.441-2.961A8.065 8.065 0 0 0 15 3.586V2H9v1.586a8.065 8.065 0 0 0-4.941 6.453A2.503 2.503 0 0 0 2 12.5C2 13.879 3.121 15 4.5 15h15c1.379 0 2.5-1.121 2.5-2.5 0-1.229-.89-2.252-2.059-2.461zM15 12H9a1 1 0 1 1 0-2h6a1 1 0 1 1 0 2zm-3 6.4a2.4 2.4 0 0 0 2.4-2.4H18v.6a.601.601 0 0 1-.47.586l-.939.209a4.722 4.722 0 0 1-.359.865l.517.812a.6.6 0 0 1-.082.746l-.849.849a.6.6 0 0 1-.746.082l-.813-.517a4.737 4.737 0 0 1-.865.36l-.209.938a.6.6 0 0 1-.586.47H11.4a.6.6 0 0 1-.586-.47l-.209-.938a4.737 4.737 0 0 1-.865-.36l-.813.517a.596.596 0 0 1-.746-.082l-.848-.849a.597.597 0 0 1-.082-.746l.516-.812a4.866 4.866 0 0 1-.359-.865l-.939-.209A.6.6 0 0 1 6 16.6V16h3.6a2.4 2.4 0 0 0 2.4 2.4z"
        fill={fill}
      />
    </svg>
  );
};

MaintenanceIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Grey_10,
};
