import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const DoneIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="4 4 24 24" fill="none" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <rect x="5" y="5" width="22" height="22" rx="11" fill={fill} stroke={fill} strokeWidth="2" />
      <path d="m12 17.4 3.6 3.6 5.4-9" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

DoneIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Operational.State_Notif_Green_3,
};
