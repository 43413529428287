import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  stroke?: Property.Color;
  title?: string;
  role?: string;
}

export const IndicatorTriangleIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, stroke, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={15} viewBox="0 0 20 15" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path d="M10.416 1.723 10 1.099l-.416.624-8 12-.518.777H18.934l-.518-.777-8-12z" fill={fill} stroke={stroke} />
    </svg>
  );
};

IndicatorTriangleIcon.defaultProps = {
  fill: settings.colors.Primary.Blue_9,
  stroke: settings.colors.Monochromatic.White,
};
