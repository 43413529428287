import { AuthClient } from '@rhim/react';
import { AxiosApi as BaseAxiosApi } from '@rhim/requests';
import { assert, isDefined } from '@rhim/utils';
import { AxiosRequestConfig, HeadersDefaults } from 'axios';

/**
 * APO Axios API class.
 * Extends the base class to add an extra header for the LogRocket session URL.
 */
export class AxiosApi extends BaseAxiosApi {
  /**
   * Log Rocket Session URL.
   */
  private sessionURL: string | null = null;

  constructor(authClient: AuthClient, getSession?: () => Promise<string | null>, headers?: HeadersDefaults) {
    super(authClient, headers);

    if (getSession) {
      this.setSessionUrl(getSession);
    }
  }

  /**
   * Async Log Rocket Session URL retrieval.
   * @param getSession Getter fn.
   */
  private setSessionUrl = async (getSession: () => Promise<string | null>) => {
    this.sessionURL = await getSession();
  };

  /**
   * Sets the Log RocketSession URL to request headers.
   * @param config
   * @returns the new config.
   */
  protected setHeaders(config: AxiosRequestConfig): AxiosRequestConfig {
    const requestConfig: AxiosRequestConfig = super.setHeaders(config);

    if (isDefined(this.sessionURL)) {
      assert(isDefined(requestConfig.headers), 'requestConfig headers undefined');
      requestConfig.headers['X-LogRocket-URL'] = this.sessionURL;
    }

    return requestConfig;
  }
}
