import { FC } from 'react';
import styled, { css } from 'styled-components';

interface StickyHeaderShadowProps {
  isVisible: boolean;
}
const StickyHeaderShadow: FC<React.PropsWithChildren<StickyHeaderShadowProps>> = ({ isVisible }) => {
  return <SStickyHeaderShadow isVisible={isVisible} />;
};

interface SStickyHeaderShadowProps {
  isVisible: boolean;
}
const SStickyHeaderShadow = styled.div<SStickyHeaderShadowProps>`
  --shadowHeightPx: 16px;
  --animationDurationSecs: 0.25s;

  pointer-events: none;
  position: absolute;
  z-index: 999;
  background-image: linear-gradient(rgba(61, 85, 103, 0.2), rgba(61, 85, 103, 0));
  width: 100%;
  height: 0;
  bottom: 0;
  transition: bottom var(--animationDurationSecs) ease-out, height var(--animationDurationSecs) ease-out;
  ${(props) =>
    props.isVisible &&
    css`
      height: var(--shadowHeightPx);
      bottom: calc(-1 * var(--shadowHeightPx));
    `}
`;

export default StickyHeaderShadow;
