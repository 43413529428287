import { RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationListDto } from '@rhim/rest/fileIngestion';
import { specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api';

type Payload = RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationListDto;

export const useListHarmonizations = (
  customerId: string,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) => {
  return useQuery(getKey(customerId), (context) => queryFn(context, options?.axiosConfiguration));
};

const getKey = (customerId: string) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'harmonization',
      scope: 'listHarmonizations',
      entity: 'list',
      customerId,
    },
  ]);

const queryFn = ({ queryKey: [{ customerId }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  return API.harmonization.listHarmonizationConfiguration(customerId, { signal, ...axiosConfiguration }).then((response) => response.data);
};

useListHarmonizations.getKey = getKey;
useListHarmonizations.queryFn = queryFn;
