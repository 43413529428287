import type { AriaButtonProps } from '@react-types/button';
import { settings } from '@rhim/design';
import React from 'react';
import { useButton, useFocusRing, useHover } from 'react-aria';
import styled, { css } from 'styled-components';

import { Tooltip, TooltipPlacement } from '../Tooltip';

const theme = {
  dark: {
    fill: {
      disabled: settings.colors.Primary.Grey_7,
      filledPressed: settings.colors.Primary.Grey_10,
      filledHoveredNotPressed: settings.colors.Primary.Grey_10,
      filledNotHoveredNotPressed: settings.colors.Primary.Blue_1,
      notFilledPressed: settings.colors.Monochromatic.White,
      notFilledHoveredNotPressed: settings.colors.Monochromatic.White,
      notFilledNotHoveredNotPressed: settings.colors.Monochromatic.White,
      default: settings.colors.Monochromatic.White,
    },
    filledDisabled: settings.colors.Primary.Grey_10,
    filledNotDisabled: settings.colors.Primary.Grey_10,
    filledHoveredPressed: settings.colors.Monochromatic.White,
    filledHoveredNotPressed: settings.colors.Monochromatic.White,
  },
  default: {
    fill: {
      disabled: settings.colors.Primary.Grey_3,
      filledPressed: settings.colors.Monochromatic.White,
      filledHoveredNotPressed: settings.colors.Monochromatic.White,
      filledNotHoveredNotPressed: settings.colors.Primary.Blue_1,
      notFilledPressed: settings.colors.Primary.Blue_7,
      notFilledHoveredNotPressed: settings.colors.Primary.Blue_8,
      notFilledNotHoveredNotPressed: settings.colors.Primary.Blue_9,
      default: settings.colors.Primary.Blue_9,
    },
    filledDisabled: settings.colors.Primary.Grey_4,
    filledNotDisabled: settings.colors.Primary.Blue_9,
    filledHoveredPressed: settings.colors.Primary.Blue_7,
    filledHoveredNotPressed: settings.colors.Primary.Grey_4,
  },
};

export interface IconButtonProps extends Omit<AriaButtonProps, 'children'> {
  className?: string;
  icon: React.ReactElement<{ fill?: string }>;
  isFilled?: boolean;
  tooltip?: string;
  tooltipPlacement?: TooltipPlacement;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  mode?: keyof typeof theme;
  isSelected?: boolean;
}

const IconButton: React.ChildlessComponent<IconButtonProps> = (props) => {
  const { className, icon, isSelected = false, isFilled = false, tooltip, mode = 'default', tooltipPlacement, getPopupContainer, ...rest } = props;
  const ref = React.useRef<HTMLButtonElement>(null);
  const { buttonProps, isPressed } = useButton(rest, ref);
  const { hoverProps, isHovered } = useHover({ isDisabled: rest.isDisabled });
  const { isFocusVisible, focusProps } = useFocusRing({ autoFocus: rest.autoFocus });

  const iconWithProps = React.useMemo(
    () =>
      React.cloneElement(icon, {
        fill: (() => {
          switch (true) {
            case rest.isDisabled:
              return theme[mode].fill.disabled;
            case (isFilled && isPressed) || (isFilled && isSelected):
              return theme[mode].fill.filledPressed;
            case isFilled && !isPressed && isHovered:
              return theme[mode].fill.filledHoveredNotPressed;
            case isFilled && !isPressed && !isHovered:
              return theme[mode].fill.filledNotHoveredNotPressed;
            case !isFilled && isPressed:
              return theme[mode].fill.notFilledPressed;
            case !isFilled && !isPressed && isHovered:
              return theme[mode].fill.notFilledHoveredNotPressed;
            case !isFilled && !isPressed && !isHovered:
              return theme[mode].fill.notFilledNotHoveredNotPressed;
            default:
              return theme[mode].fill.default;
          }
        })(),
      }),
    [isSelected, mode, icon, rest.isDisabled, isFilled, isPressed, isHovered]
  );

  return (
    <Tooltip title={tooltip} placement={tooltipPlacement} getPopupContainer={getPopupContainer}>
      <SButton
        mode={theme[mode]}
        className={className}
        {...buttonProps}
        {...hoverProps}
        {...focusProps}
        isFilled={isFilled}
        isSelected={isSelected}
        isHovered={isHovered}
        isDisabled={rest.isDisabled === true}
        isPressed={isPressed}
        isFocusVisible={isFocusVisible && !(rest.isDisabled ?? false)}
        ref={ref}
      >
        <SIcon>{iconWithProps}</SIcon>
      </SButton>
    </Tooltip>
  );
};

IconButton.whyDidYouRender = true;
export default React.memo(IconButton);

const SButton = styled.button<{
  mode: typeof theme[keyof typeof theme];
  isFilled: boolean;
  isDisabled: boolean;
  isHovered: boolean;
  isPressed: boolean;
  isFocusVisible: boolean;
  isSelected: boolean;
}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: ${(props) => (props.isFilled ? (props.isDisabled ? props.mode.filledDisabled : props.mode.filledNotDisabled) : 'transparent')};
  border-radius: ${(props) => (props.isFilled ? '3px' : 0)};
  border: none;
  outline: none;
  cursor: pointer;
  padding: ${settings.Spacing.Spacing_100};

  &:disabled {
    cursor: auto;
  }

  ${(props) =>
    props.isFilled &&
    props.isHovered &&
    css`
      background-color: ${props.isPressed ? props.mode.filledHoveredPressed : props.mode.filledHoveredNotPressed};
    `}

  ${(props) =>
    props.isFilled &&
    props.isSelected &&
    css`
      background-color: ${props.mode.filledHoveredPressed};
    `}

  ${(props) =>
    props.isFocusVisible &&
    css`
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        box-shadow: 0 0 0 2px ${settings.FOCUS_RING_COLOR};
      }
    `}
`;

const SIcon = styled.span`
  display: flex;
  align-items: center;
`;
