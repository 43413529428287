import type { ICanvasRenderingContext } from '@babylonjs/core/Engines/ICanvas';
import { Control } from '@babylonjs/gui';

export class CenterPointerControl extends Control {
  private text: { tapHole: string };
  public constructor(name: string, text: { tapHole: string }) {
    super(name);
    this.text = text;
  }

  _draw(ctx: ICanvasRenderingContext) {
    const { width, height } = ctx.canvas;
    const hW = width / 2;
    const hH = height / 2;
    const offset = 30;
    const mainStrokeWidth = 4;
    const bgStrokeWidth = 8;
    const crossLength = Math.min(width, height) * 0.05;
    ctx.clearRect(0, 0, width, height);

    ctx.strokeStyle = 'white';
    ctx.lineWidth = bgStrokeWidth;
    ctx.beginPath();
    // horyzontal line
    ctx.moveTo(offset, hH);
    ctx.lineTo(hW - crossLength - offset, hH);
    ctx.moveTo(hW + crossLength + offset, hH);
    ctx.lineTo(width - offset, hH);

    // vertical line
    ctx.moveTo(hW, offset);
    ctx.lineTo(hW, hH - crossLength - offset);
    ctx.moveTo(hW, hH + crossLength + offset);
    ctx.lineTo(hW, height - offset);

    // dot in a middle
    ctx.moveTo(hW - bgStrokeWidth / 2, hH);
    ctx.lineTo(hW + bgStrokeWidth / 2, hH);

    // horysontal cross line
    ctx.moveTo(hW - crossLength, hH);
    ctx.lineTo(hW - bgStrokeWidth / 2, hH);
    ctx.moveTo(hW + bgStrokeWidth / 2, hH);
    ctx.lineTo(hW + crossLength, hH);

    // vertical cross line
    ctx.moveTo(hW, hH - crossLength);
    ctx.lineTo(hW, hH - bgStrokeWidth / 2);
    ctx.moveTo(hW, hH + bgStrokeWidth / 2);
    ctx.lineTo(hW, hH + crossLength);

    ctx.stroke();

    ctx.strokeStyle = '#003262';
    ctx.lineWidth = mainStrokeWidth;

    ctx.beginPath();
    // horyzontal line
    ctx.moveTo(offset, hH);
    ctx.lineTo(hW - crossLength - offset, hH);

    ctx.moveTo(hW + crossLength + offset, hH);
    ctx.lineTo(width - offset, hH);

    // vertical line
    ctx.moveTo(hW, offset);
    ctx.lineTo(hW, hH - crossLength - offset);

    ctx.moveTo(hW, hH + crossLength + offset);
    ctx.lineTo(hW, height - offset);

    // dot in a middle
    ctx.moveTo(hW - mainStrokeWidth / 2, hH);
    ctx.lineTo(hW + mainStrokeWidth / 2, hH);
    ctx.stroke();

    ctx.strokeStyle = '#ed2c2a';
    ctx.lineWidth = mainStrokeWidth;

    ctx.beginPath();
    // horysontal cross line
    ctx.moveTo(hW - crossLength, hH);
    ctx.lineTo(hW - bgStrokeWidth / 2, hH);
    ctx.moveTo(hW + bgStrokeWidth / 2, hH);
    ctx.lineTo(hW + crossLength, hH);

    // vertical cross line
    ctx.moveTo(hW, hH - crossLength);
    ctx.lineTo(hW, hH - bgStrokeWidth / 2);
    ctx.moveTo(hW, hH + bgStrokeWidth / 2);
    ctx.lineTo(hW, hH + crossLength);
    ctx.stroke();

    // Rectangle dimensions
    const fontSize = 24;
    ctx.font = `${fontSize}px nortw05-bold`; // Font size and family
    (ctx as CanvasRenderingContext2D).textBaseline = 'middle';
    const text = '← ' + this.text.tapHole;
    const textMetrics = ctx.measureText(text);
    const rectWidth = textMetrics.width + 30;
    const rectHeight = 2 * fontSize;
    const rectX = 20;
    const rectY = (height - rectHeight) / 2;
    const borderRadius = 15;

    // Draw rounded rectangle
    ctx.fillStyle = '#003262';
    ctx.beginPath();
    (ctx as CanvasRenderingContext2D).roundRect(rectX, rectY, rectWidth, rectHeight, borderRadius);
    ctx.fill();

    // Add text inside the rectangle
    ctx.fillStyle = 'white';
    const textX = rectX + (rectWidth - textMetrics.width) / 2;
    const textY = rectY + rectHeight / 2;

    ctx.fillText(text, textX, textY);
  }
}
