export enum ShapeType {
  Line = 'line',
  LinePath = 'linePath',
  Glyph = 'glyph',
  Rect = 'rect',
  AreaShape = 'areaShape',
  Threshold = 'threshold',
  Text = 'text',
}

type BaseDefinition = {
  label: string;
  labelTooltip?: string;
};

export type AreaProps = {
  fill: string;
  fillOpacity?: string | number;
};

export type ThresholdDefinition = BaseDefinition & {
  type: ShapeType.Threshold;
  legendStrokeWidth?: number;
  strokeColor: string;
  strokeColorAlert?: string;
  strokeWidth: number;
  strokeDasharray?: string | number;
  strokeDashoffset?: string | number;
  aboveAreaProps?: AreaProps;
  belowAreaProps?: AreaProps;
};

type LineBaseDefinition = BaseDefinition & {
  legendStrokeWidth?: number;
  strokeColor: string;
  strokeColorAlert?: string;
  strokeWidth: number;
  strokeDasharray?: string | number;
  strokeDashoffset?: string | number;
};

export type LinePathDefinition = LineBaseDefinition & {
  type: ShapeType.LinePath;
};

export type LineDefinition = LineBaseDefinition & {
  type: ShapeType.Line;
};

export type GlyphDefinition = BaseDefinition & {
  type: ShapeType.Glyph;
  name: string;
  fill: string;
  fillAlert?: string;
};

export type RectDefinition = BaseDefinition & {
  type: ShapeType.Rect;
  legendHeight?: number;
  fill: string;
};

export type AreaShapeDefinition = BaseDefinition & {
  type: ShapeType.AreaShape;
  fill: string;
};

export type TextDefinition = BaseDefinition & {
  type: ShapeType.Text;
};

export type DatasetConfiguration = {
  [dataKey: string]: ThresholdDefinition | GlyphDefinition | RectDefinition | LineDefinition | LinePathDefinition | AreaShapeDefinition | TextDefinition;
};

export type ThemeConfig = {
  datasets: DatasetConfiguration;
};
