import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const FileVertexIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <path
              d="M16 2l4 4v13.995C20 21.103 19.103 22 17.996 22H6.005C4.898 22 4 21.103 4 19.995V4.005C4 2.897 4.898 2 6.005 2H16zm-5 11H7v4h4v-4zm6-3h-4v4h4v-4zm-6-3H7v4h4V7z"
              transform="translate(-313 -392) translate(313 392)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

FileVertexIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
