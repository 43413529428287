import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const Surface2DIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}

      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 3H2v18h20V3zM4 19V5h16v14H4zM8.045 8c1.634 0 2.79 1.048 2.79 2.524 0 1.24-.608 1.994-3.151 3.944l.011.022h3.092V16H5.14v-1.409l.456-.348c2.252-1.722 3.465-2.649 3.465-3.685 0-.62-.444-1.082-1.039-1.082-.688 0-1.178.608-1.307 1.656L5 10.873C5.222 9.093 6.377 8 8.045 8zm6.86 1.555h-.852v4.89h.851c1.424 0 2.276-.913 2.276-2.445 0-1.544-.84-2.445-2.275-2.445zM14.893 16h-2.66V8h2.66C17.413 8 19 9.544 19 12c0 2.445-1.587 4-4.107 4z"
      />
    </svg>
  );
};

Surface2DIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Grey_2,
};
