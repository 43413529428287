import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const NewWindowIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...rest}>
      <path
        d="M10 1C10 0.447715 10.4477 0 11 0H15C15.5523 0 16 0.447715 16 1V5C16 5.55228 15.5523 6 15 6C14.4477 6 14 5.55228 14 5V3.41421L10.7071 6.70711C10.3166 7.09763 9.68342 7.09763 9.29289 6.70711C8.90237 6.31658 8.90237 5.68342 9.29289 5.29289L12.5858 2H11C10.4477 2 10 1.55228 10 1Z"
        fill={fill}
      />
      <path
        d="M3 2C2.44772 2 2 2.44772 2 3V13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V9C14 8.44772 13.5523 8 13 8C12.4477 8 12 8.44772 12 9V12H4V4H7C7.55228 4 8 3.55228 8 3C8 2.44772 7.55228 2 7 2H3Z"
        fill={fill}
      />
    </svg>
  );
};

NewWindowIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
