import { VesselType } from '@rhim/rest';
import { fromEntries, UNSAFE_values } from '@rhim/utils';
import { groupBy } from 'lodash';
import React from 'react';

export function useVesselsGroupedByType(vessels: APO.VesselV2[]): Record<VesselType, APO.VesselV2[]> {
  return React.useMemo(() => {
    return getVesselsGroupedByType(vessels);
  }, [vessels]);
}

/**
 * Note: this returns a record with all vessel types as keys, even if there are no vessels of that type.
 */
export function getVesselsGroupedByType(vessels: APO.VesselV2[]): Record<VesselType, APO.VesselV2[]> {
  const placeholders: Record<VesselType, APO.VesselV2[]> = fromEntries<Record<VesselType, APO.VesselV2[]>>(UNSAFE_values(VesselType).map((type) => [type, []]));
  const overrides: Partial<Record<VesselType, APO.VesselV2[]>> = groupBy(vessels, 'vesselType');

  return { ...placeholders, ...overrides };
}
