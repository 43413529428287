import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const VesselCRKIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        d="M12 1c5.523 0 10 4.477 10 10a9.956 9.956 0 0 1-2.032 6.044 2.5 2.5 0 1 1-2.963 2.614A9.944 9.944 0 0 1 12 21a9.954 9.954 0 0 1-5.006-1.341L7 19.5a2.5 2.5 0 1 1-2.967-2.457A9.948 9.948 0 0 1 2 11C2 5.477 6.477 1 12 1zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 1a7 7 0 0 1 6.995 7.27 6.5 6.5 0 0 1-6.157 6.722l-.065-.034A7 7 0 1 1 12 4zm0 2a5 5 0 0 0-2.76 9.17c.069-.15.146-.299.232-.447.603-1.045 1.66-1.659 2.882-2.369 1.436-.834 3.06-1.78 4.065-3.52l.047-.085A5 5 0 0 0 12 6z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
};

VesselCRKIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
