import { settings } from '@rhim/design';
import { ChevronDownMiniIcon } from '@rhim/icons/16';
import { isDefined } from '@rhim/utils/is-defined';
import { Collapse as AntdCollapse, CollapseProps } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

const Collapse: React.FunctionComponent<React.PropsWithChildren<CollapseProps>> = ({ children, expandIconPosition = 'end', ...rest }) => {
  return (
    <SAntdCollapse expandIconPosition={expandIconPosition} expandIcon={Icon} {...rest}>
      {children}
    </SAntdCollapse>
  );
};

const Icon: React.ChildlessComponent<{ isActive?: boolean }> = ({ isActive }) => {
  return (
    <span className="anticon anticon-caret-right ant-collapse-arrow">
      <SChevronDownMiniIcon fill={settings.colors.Primary.Blue_9} isFacingUp={isDefined(isActive) && isActive} />
    </span>
  );
};

const SAntdCollapse = styled(AntdCollapse)`
  background-color: white;
  border: none;
  border-radius: 2px;

  > .ant-collapse-item {
    overflow: auto;

    &:last-child {
      border-radius: 2px;
    }

    > .ant-collapse-header .ant-collapse-expand-icon {
      height: auto;
    }
  }

  &.ant-collapse-icon-position-right > .ant-collapse-item {
    border-bottom: 1px solid #d8dde1;

    &:first-of-type {
      border-top: 1px solid #d8dde1;
    }

    &.ant-collapse-item-active div.ant-collapse-content-active {
      border-top-style: dashed;
    }

    > .ant-collapse-header {
      margin: 3px 4px;
      padding-right: 29px;
      padding-left: 13px;
      border-radius: 3px;

      &:hover {
        background-color: ${settings.colors.Primary.Grey_2};
      }

      --paddingTopBottomPx: ${settings.Spacing.Spacing_50};

      padding-top: var(--paddingTopBottomPx);
      padding-bottom: var(--paddingTopBottomPx);

      .ant-collapse-arrow {
        padding: 0;
        right: 6px;
        top: 50%;
      }
    }
  }
`;

const SChevronDownMiniIcon = styled<React.FunctionComponent<React.ComponentProps<typeof ChevronDownMiniIcon> & { isFacingUp: boolean }>>(
  ChevronDownMiniIcon
).withConfig({
  shouldForwardProp: (prop) => prop !== 'isFacingUp',
})`
  transition: transform 0.25s;
  transform: ${(props) => `rotate(${props.isFacingUp ? '-180deg' : 0})`};
`;

export const { Panel } = AntdCollapse;

export default React.memo(Collapse);
