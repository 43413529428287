import { settings } from '@rhim/design';
import { HeatIcon } from '@rhim/icons/16';
import { getFormattedDate } from '@rhim/react';
import { RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto } from '@rhim/rest/fleetOverview';
import { dateLabelStripVolumeExplorer, heatLabelStripVolumeExplorer } from '@rhim/test-ids';
import React from 'react';
import styled from 'styled-components';

import { SDropdownLabel } from '../../../components/MeasurementSelectionDropdown/MeasurementSelection';

interface Props {
  measurement: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto;
}
const MeasurementItem: React.ChildlessComponent<Props> = ({ measurement }) => {
  const formattedTimestamp = getFormattedDate({
    datetime: measurement.measurementTaken as Date.ISO_8601,
    showTime: true,
  });

  return (
    <SWrapper>
      <HeatIcon fill={settings.colors.Primary.Grey_2} />
      <SDropdownLabel data-test-id={heatLabelStripVolumeExplorer}>{measurement.heat}</SDropdownLabel>
      <SDropdownLabel data-test-id={dateLabelStripVolumeExplorer}>{formattedTimestamp}</SDropdownLabel>
    </SWrapper>
  );
};

const SWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${settings.Spacing.Spacing_200};
`;

MeasurementItem.whyDidYouRender = true;
export default React.memo(MeasurementItem);
