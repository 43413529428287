import { VesselType } from '@rhim/rest';
import { RHIMAPOCommonDataReportingColorScaleType, RHIMAPOReportingWearManagementApiV1ModelsColorscaleDto } from '@rhim/rest/wearManagement';
import { specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api';

type Payload = RHIMAPOReportingWearManagementApiV1ModelsColorscaleDto;

export const useColorScaleOfType = (
  customerId: UUID,
  vesseltype: VesselType,
  colorScaleType: RHIMAPOCommonDataReportingColorScaleType,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) => {
  return useQuery(getKey(customerId, vesseltype, colorScaleType), (context) => queryFn(context, options?.axiosConfiguration), options?.configuration);
};

const getKey = (customerId: UUID, vesselType: VesselType, colorScaleType: RHIMAPOCommonDataReportingColorScaleType) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'wearManagement',
      scope: 'color-scale-of-type',
      entity: 'detail',
      customerId,
      vesselType,
      colorScaleType,
    },
  ]);

const queryFn = (
  { queryKey: [{ customerId, vesselType, colorScaleType }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>,
  axiosConfiguration?: AxiosRequestConfig
) => {
  return API.colorScale
    .getColorscalesCustomeridVesseltypeColorscaletype(customerId, vesselType, colorScaleType, { signal, ...axiosConfiguration })
    .then((response) => response.data);
};

useColorScaleOfType.getKey = getKey;
useColorScaleOfType.queryFn = queryFn;
