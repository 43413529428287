import { settings } from '@rhim/design';
import { InfoIcon } from '@rhim/icons/16';
import { getStyles, isDefined } from '@rhim/utils';
import * as React from 'react';
import styled, { css } from 'styled-components';

import { Tooltip, TooltipProps } from '../Tooltip';

interface Props extends Omit<React.HTMLProps<HTMLHeadingElement>, 'as'> {
  level: 'h1' | 'h2' | 'h3' | 'h4';
  children: React.ReactText | React.ReactElement;
  tooltip?: TooltipProps;
}

const HeadingComponent = React.forwardRef<HTMLHeadingElement, Props>(function HeadingComponent(
  { level, children, tooltip, ...rest },
  ref: React.Ref<HTMLHeadingElement>
) {
  const style = React.useMemo(
    () =>
      getStyles(styles)({
        base: true,
        h1: level === 'h1',
        h2: level === 'h2',
        h3: level === 'h3',
        h4: level === 'h4',
      }),
    [level]
  );

  return (
    <HeadingElement {...rest} ref={ref} as={level} css={style}>
      {children}
      {isDefined(tooltip) && (
        <Tooltip {...tooltip}>
          <SInfoIcon />
        </Tooltip>
      )}
    </HeadingElement>
  );
});

/**
 * The polymorphic blueprint used to render all types of HTMLHeadingElements.
 *
 * @see https://styled-components.com/docs/api#as-polymorphic-prop
 */
const HeadingElement = styled.h1``;

const SInfoIcon = styled(InfoIcon)`
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  margin: 0 0.3em;
`;

const styles = {
  base: settings.typography.bases.h_default,
  h1: css`
    ${settings.typography.h_sizes.xxl}
    margin-top: 64px;
    margin-bottom: 40px;
  `,
  h2: css`
    ${settings.typography.h_sizes.xl}
    margin-top: 40px;
    margin-bottom: 16px;
  `,
  h3: css`
    ${settings.typography.h_sizes.l}
    margin-top: 40px;
    margin-bottom: 12px;
  `,
  h4: css`
    ${settings.typography.h_sizes.m}
    margin-top: 40px;
    margin-bottom: 8px;
  `,
};

const Heading = React.memo(HeadingComponent);

Heading.displayName = 'Heading';

export default Heading;
