import { VesselType } from '@rhim/rest';
import { isDefined, specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api/customerManagement';
import { vesselsPerFeature } from '../lib';
import { Feature } from '../settings';

type Payload = APO.VesselV2[];

export function useVessels(
  customerId: UUID,
  vesselType?: VesselType,
  forFeature?: Feature,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) {
  const feature = forFeature;
  return useQuery(getKey(customerId, vesselType), (context) => queryFn(context, options?.axiosConfiguration), {
    ...options?.configuration,
    select: (data) => {
      const vessels = isDefined(feature) ? data.filter((vessel) => vesselsPerFeature[feature].includes(vessel.vesselType)) : data;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const returned = [...vessels].sort(
        (a, b) =>
          a.displayName.localeCompare(b.displayName, undefined, { numeric: true, sensitivity: 'base' }) ||
          (a.vesselType < b.vesselType ? -1 : a.vesselType > b.vesselType ? 1 : 0)
      );

      return returned;
    },
  });
}

const getKey = (customerId: UUID, vesselType?: VesselType) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'customerManagement',
      scope: 'customer-vessels',
      entity: 'list',
      customerId,
      vesselType,
    },
  ]);

const queryFn = (
  { queryKey: [{ customerId, vesselType }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>,
  axiosConfiguration?: AxiosRequestConfig
) => {
  return API.customers.getCustomersCustomeridVessels(customerId, vesselType, { signal, ...axiosConfiguration }).then((response) => {
    const vessels = response.data as Payload;

    return [...vessels].sort((a, b) => {
      const nameA = (a.displayName || a.shortName).toUpperCase();
      const nameB = (b.displayName || b.shortName).toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  });
};

useVessels.getKey = getKey;
useVessels.queryFn = queryFn;
