import { settings } from '@rhim/design';
import { comparisonLabelVolumeExplorer, comparisonValueWithUnitVolumeExplorer } from '@rhim/test-ids';
import { isDefined } from '@rhim/utils';
import React from 'react';
import styled from 'styled-components';

import { VOLUME_DECIMAL_POINTS } from '../utils';

interface Props {
  label: string;
  value: number | undefined;
  unit: string;
  isComparisonMode?: boolean;
}
const PropertyWithValue: React.ChildlessComponent<Props> = ({ label, value, unit, isComparisonMode = false }) => {
  const displayedValue = (() => {
    if (!isDefined(value)) {
      return '—';
    }
    return Math.abs(parseFloat(value.toFixed(VOLUME_DECIMAL_POINTS)));
  })();

  return (
    <SWrapper>
      <SLabel data-test-id={comparisonLabelVolumeExplorer}>{label}</SLabel>
      <SValueContainer data-test-id={comparisonValueWithUnitVolumeExplorer}>
        {isComparisonMode && isDefined(value) && displayedValue !== 0 && <SValueSign>{value > 0 ? '+' : '-'}</SValueSign>}
        <SValue>{displayedValue}</SValue>
        <SUnit>{unit}</SUnit>
      </SValueContainer>
    </SWrapper>
  );
};

const SWrapper = styled.div`
  margin-top: ${settings.Spacing.Spacing_200};
  display: flex;
  flex-direction: column;
`;

const SLabel = styled.span`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.Small};
  color: ${settings.colors.Primary.Grey_7};
`;

const SValue = styled.span`
  font-family: ${settings.typography.FontFamily.Bold};
  font-size: ${settings.typography.FontSize.XX_Large};
  color: ${settings.colors.Primary.Grey_8};
`;

const SUnit = styled.span`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.Medium};
  color: ${settings.colors.Primary.Grey_8};
`;

const SValueSign = styled(SValue)`
  width: 15px;
  text-align: right;
`;

const SValueContainer = styled.div`
  display: flex;
  column-gap: ${settings.Spacing.Spacing_50};
  align-items: baseline;
`;

PropertyWithValue.whyDidYouRender = true;
export default React.memo(PropertyWithValue);
