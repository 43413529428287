import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const OfferingsIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M13.5667 19.9167H10.4333C10.3627 19.9169 10.2933 19.9354 10.2319 19.9705C10.1706 20.0055 10.1194 20.0559 10.0833 20.1167C10.0482 20.1775 10.0297 20.2465 10.0297 20.3167C10.0297 20.3869 10.0482 20.4559 10.0833 20.5167L10.3583 21.075C10.495 21.3502 10.7052 21.5822 10.9654 21.7454C11.2257 21.9086 11.5261 21.9968 11.8333 22H12.1667C12.4782 22.0014 12.7838 21.9155 13.0489 21.752C13.3141 21.5885 13.5281 21.3539 13.6667 21.075L13.9417 20.5167C13.9768 20.4559 13.9953 20.3869 13.9953 20.3167C13.9953 20.2465 13.9768 20.1775 13.9417 20.1167C13.9035 20.0521 13.8484 19.9993 13.7822 19.964C13.7161 19.9288 13.6415 19.9124 13.5667 19.9167Z"
        fill={fill}
      />
      <path
        d="M12 5.75C12.221 5.75 12.433 5.6622 12.5893 5.50592C12.7455 5.34964 12.8333 5.13768 12.8333 4.91667V2.83333C12.8333 2.61232 12.7455 2.40036 12.5893 2.24408C12.433 2.0878 12.221 2 12 2C11.779 2 11.567 2.0878 11.4107 2.24408C11.2545 2.40036 11.1667 2.61232 11.1667 2.83333V4.91667C11.1667 5.13768 11.2545 5.34964 11.4107 5.50592C11.567 5.6622 11.779 5.75 12 5.75Z"
        fill={fill}
      />
      <path
        d="M19.6583 5.175C19.5022 5.01979 19.291 4.93267 19.0708 4.93267C18.8507 4.93267 18.6395 5.01979 18.4833 5.175L17 6.65C16.9219 6.72747 16.8599 6.81964 16.8176 6.92119C16.7753 7.02274 16.7535 7.13166 16.7535 7.24167C16.7535 7.35168 16.7753 7.4606 16.8176 7.56215C16.8599 7.6637 16.9219 7.75586 17 7.83333C17.0779 7.91057 17.1702 7.97167 17.2717 8.01314C17.3733 8.05461 17.482 8.07563 17.5917 8.075C17.8103 8.07408 18.0198 7.98729 18.175 7.83333L19.65 6.35833C19.7287 6.28142 19.7913 6.18969 19.8343 6.08843C19.8773 5.98718 19.8999 5.87841 19.9007 5.7684C19.9014 5.65839 19.8804 5.54932 19.8388 5.44747C19.7972 5.34562 19.7359 5.25302 19.6583 5.175Z"
        fill={fill}
      />
      <path
        d="M22 12.8333C22 12.6123 21.9122 12.4004 21.7559 12.2441C21.5996 12.0878 21.3877 12 21.1667 12H19.5C19.279 12 19.067 12.0878 18.9107 12.2441C18.7545 12.4004 18.6667 12.6123 18.6667 12.8333C18.6667 13.0543 18.7545 13.2663 18.9107 13.4226C19.067 13.5789 19.279 13.6667 19.5 13.6667H21.1667C21.3877 13.6667 21.5996 13.5789 21.7559 13.4226C21.9122 13.2663 22 13.0543 22 12.8333Z"
        fill={fill}
      />
      <path
        d="M7 6.65L5.51667 5.175C5.35725 5.03848 5.15219 4.96714 4.94246 4.97524C4.73273 4.98334 4.53378 5.07029 4.38536 5.2187C4.23695 5.36711 4.15001 5.56606 4.14191 5.77579C4.13381 5.98552 4.20514 6.19058 4.34167 6.35L5.81667 7.83333C5.97188 7.98729 6.18138 8.07408 6.4 8.075C6.51107 8.07674 6.62137 8.05626 6.7244 8.01476C6.82744 7.97326 6.92114 7.91157 7 7.83333C7.07811 7.75586 7.1401 7.6637 7.18241 7.56215C7.22472 7.4606 7.2465 7.35168 7.2465 7.24167C7.2465 7.13166 7.22472 7.02274 7.18241 6.92119C7.1401 6.81964 7.07811 6.72747 7 6.65Z"
        fill={fill}
      />
      <path
        d="M4.5 12H2.83333C2.61232 12 2.40036 12.0878 2.24408 12.2441C2.0878 12.4004 2 12.6123 2 12.8333C2 13.0543 2.0878 13.2663 2.24408 13.4226C2.40036 13.5789 2.61232 13.6667 2.83333 13.6667H4.5C4.72101 13.6667 4.93298 13.5789 5.08926 13.4226C5.24554 13.2663 5.33333 13.0543 5.33333 12.8333C5.33333 12.6123 5.24554 12.4004 5.08926 12.2441C4.93298 12.0878 4.72101 12 4.5 12Z"
        fill={fill}
      />
      <path
        d="M12 7C10.5546 7 9.16834 7.57419 8.14627 8.59627C7.12419 9.61834 6.55 11.0046 6.55 12.45C6.54482 13.2807 6.73344 14.1011 7.10088 14.8461C7.46832 15.5911 8.00445 16.2402 8.66667 16.7417C8.71795 16.7809 8.75961 16.8313 8.78848 16.8891C8.81736 16.9468 8.8327 17.0104 8.83333 17.075V17.45C8.83442 17.6116 8.86746 17.7713 8.93055 17.9201C8.99364 18.0689 9.08554 18.2037 9.20095 18.3167C9.31637 18.4298 9.45301 18.519 9.60302 18.579C9.75303 18.6391 9.91343 18.6689 10.075 18.6667H13.925C14.2448 18.6667 14.5518 18.5408 14.7795 18.3162C15.0072 18.0917 15.1373 17.7864 15.1417 17.4667C15.1417 17.4667 15.1417 17.4667 15.1417 17.1417C15.1383 17.0729 15.1518 17.0044 15.1808 16.9419C15.2098 16.8795 15.2536 16.8251 15.3083 16.7833C16.2202 16.0944 16.892 15.1358 17.2284 14.0436C17.5649 12.9514 17.5489 11.781 17.1828 10.6984C16.8167 9.61579 16.1191 8.67591 15.1888 8.01207C14.2585 7.34823 13.1428 6.99413 12 7ZM14.1583 15.55C13.9483 15.6977 13.7766 15.8935 13.6577 16.1211C13.5388 16.3488 13.4762 16.6015 13.475 16.8583C13.4762 16.8773 13.4734 16.8962 13.4667 16.914C13.46 16.9317 13.4496 16.9478 13.4362 16.9612C13.4228 16.9746 13.4067 16.985 13.389 16.9917C13.3712 16.9984 13.3523 17.0012 13.3333 17H10.6667C10.6481 17 10.6296 16.9963 10.6125 16.9892C10.5953 16.9821 10.5796 16.9717 10.5665 16.9585C10.5533 16.9454 10.5429 16.9297 10.5358 16.9125C10.5287 16.8954 10.525 16.8769 10.525 16.8583C10.5238 16.6015 10.4612 16.3488 10.3423 16.1211C10.2234 15.8935 10.0517 15.6977 9.84167 15.55C9.17984 15.0903 8.68208 14.4313 8.4209 13.669C8.15972 12.9066 8.14877 12.0808 8.38963 11.3118C8.6305 10.5429 9.1106 9.87088 9.76 9.39378C10.4094 8.91668 11.1942 8.65938 12 8.65938C12.8058 8.65938 13.5906 8.91668 14.24 9.39378C14.8894 9.87088 15.3695 10.5429 15.6104 11.3118C15.8512 12.0808 15.8403 12.9066 15.5791 13.669C15.3179 14.4313 14.8202 15.0903 14.1583 15.55Z"
        fill={fill}
      />
    </svg>
  );
};

OfferingsIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
