import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const TargetIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 8c0 2.206 1.794 4 4 4s4-1.794 4-4-1.794-4-4-4-4 1.794-4 4zm10-1h1a1 1 0 0 1 0 2h-1c-.017 0-.033-.005-.049-.01a.208.208 0 0 0-.038-.008 6.006 6.006 0 0 1-4.931 4.931.208.208 0 0 0 .009.038c.004.016.009.032.009.049v1a1 1 0 0 1-2 0v-1a.17.17 0 0 1 .01-.049.208.208 0 0 0 .008-.038 6.006 6.006 0 0 1-4.931-4.931c-.013.001-.026.005-.038.009C2.033 8.995 2.017 9 2 9H1a1 1 0 0 1 0-2h1a.17.17 0 0 1 .049.01c.012.003.025.007.038.008a6.006 6.006 0 0 1 4.931-4.931c-.001-.013-.005-.026-.009-.038A.17.17 0 0 1 7 2V1a1 1 0 0 1 2 0v1c0 .017-.005.033-.01.049-.003.012-.007.025-.008.038a6.006 6.006 0 0 1 4.931 4.931.208.208 0 0 0 .038-.009A.17.17 0 0 1 14 7zM6 8c0-1.103.897-2 2-2s2 .897 2 2-.897 2-2 2-2-.897-2-2z"
      />
    </svg>
  );
};

TargetIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Grey_6,
};
