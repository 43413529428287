import { isDefined } from '@rhim/utils';
import { FetchQueryOptions, PayloadType, PrefetchableHook, Query, useQueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import React from 'react';

import { useMemoCompare } from './useMemoComparable';

/**
 * Prefetches a query. Cancels any outgoing queries on unmount.
 *
 * @param hook Hook that returns a `UseQueryResult`.
 * @param dependencies List of parameters required to build the key for the hook.
 * @param options
 *
 * @example
 *
 * ```ts
 * usePrefetch(useCustomers, [])
 * usePrefetch(useColorScale, [customerId, vesselId])
 * usePrefetch(useColorScale, [customerId, vesselId], { staleTime: 60 * 1000 })
 * ```
 *
 * @see https://react-query.tanstack.com/reference/QueryClient#queryclientprefetchquery
 */
export function usePrefetch<T extends PrefetchableHook>(
  hook: T,
  dependencies: Parameters<T['getKey']>,
  options?: Omit<FetchQueryOptions<PayloadType<T>>, 'queryKey' | 'queryFn'>
): void {
  const queryClient = useQueryClient();
  const { getKey, queryFn } = hook;

  /**
   * This hook accepts arguments that are not primitives (dependencies, options).
   *
   * We memoize these arguments in order to avoid unnecessary renders.
   */
  const memoizedDependencies = useMemoCompare(dependencies, isEqual);
  const memoizedOptions = useMemoCompare(options, isEqual);

  React.useEffect(() => {
    const queryKey = getKey(...memoizedDependencies);

    queryClient.prefetchQuery({ queryKey, queryFn, ...memoizedOptions, meta: { ...memoizedOptions?.meta, [_prefetched]: true } });

    return () => {
      queryClient.cancelQueries(queryKey, { exact: true });
    };
  }, [getKey, memoizedDependencies, memoizedOptions, queryClient, queryFn]);
}

const _prefetched: unique symbol = Symbol.for(usePrefetch.name);

export function wasPrefetched(query: Query): query is Query & { meta: { [_prefetched]: true } } {
  return isDefined(query.meta) && _prefetched in query.meta;
}
