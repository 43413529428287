import { settings } from '@rhim/design';
import { ChevronRotatable, DropdownPanel, getVesselIcon, Spinner } from '@rhim/react';
import { vesselsDropdown } from '@rhim/test-ids';
import { hasElements, isDefined } from '@rhim/utils';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import VesselSelectionDropdown from './VesselSelectionDropdown';

interface Props {
  className?: string;
  vessels: APO.VesselV2[];
  isLoading: boolean;
  selectedVesselId: UUID | null | undefined;
  colorsInverted?: boolean;
  dataTestId?: string;
  onVesselSelected: (vesselId: UUID) => void;
}
const VesselSelection: React.ChildlessComponent<Props> = ({
  className,
  vessels,
  isLoading,
  selectedVesselId,
  colorsInverted = false,
  onVesselSelected,
  dataTestId,
}) => {
  const [vesselDropdownExpandedState, setVesselDropdownExpandedState] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const hasVessels = !isLoading && hasElements(vessels);

  const collapseDropdown = useCallback(() => {
    setVesselDropdownExpandedState(false);
  }, [setVesselDropdownExpandedState]);

  const handleMouseClick = useCallback(
    (event: MouseEvent) => {
      if (isDefined(ref.current) && !ref.current.contains(event.target as HTMLElement)) {
        collapseDropdown();
      }
    },
    [collapseDropdown]
  );

  useEffect(() => {
    document.addEventListener('click', handleMouseClick, true);
    return () => document.removeEventListener('click', handleMouseClick);
  }, [handleMouseClick]);

  const handleSelectorDropdownClicked = () => {
    setVesselDropdownExpandedState(!vesselDropdownExpandedState);
  };

  const handleVesselSelected = useCallback(
    (vesselId: UUID) => {
      collapseDropdown();
      onVesselSelected(vesselId);
    },
    [collapseDropdown, onVesselSelected]
  );

  const selectedVessel: APO.VesselV2 | undefined = useMemo(() => {
    if (!isDefined(vessels)) {
      return undefined;
    }
    return vessels.find((vessel) => vessel.id === selectedVesselId);
  }, [vessels, selectedVesselId]);

  const selectedVesselType = React.useMemo(() => {
    return vessels.find((vessel) => vessel.id === selectedVesselId)?.vesselType;
  }, [selectedVesselId, vessels]);

  const filteredVessels = React.useMemo(() => {
    return vessels.filter((vessel) => vessel.vesselType === selectedVesselType);
  }, [selectedVesselType, vessels]);

  const dropdownPanelVesselSelector = useMemo(() => {
    if (!isDefined(filteredVessels)) {
      return null;
    }

    return (
      <SDropdownPanel isExpanded={vesselDropdownExpandedState}>
        <VesselSelectionDropdown vessels={filteredVessels} selectedVesselId={selectedVesselId} onVesselSelected={handleVesselSelected} />
      </SDropdownPanel>
    );
  }, [vesselDropdownExpandedState, filteredVessels, selectedVesselId, handleVesselSelected]);

  return (
    <SDropdownContainer className={className} ref={ref} data-test-id={dataTestId} colorsInverted={colorsInverted}>
      <VesselSelectionLabel
        selectedVessel={selectedVessel}
        isLoading={isLoading}
        hasVessels={hasVessels}
        isExpanded={vesselDropdownExpandedState}
        colorsInverted={colorsInverted}
        handleDropdownClicked={handleSelectorDropdownClicked}
      />
      {dropdownPanelVesselSelector}
    </SDropdownContainer>
  );
};

export default React.memo(VesselSelection);

interface VesselSelectionLabelProps {
  selectedVessel?: APO.VesselV2;
  isLoading: boolean;
  hasVessels: boolean;
  colorsInverted: boolean;
  isExpanded: boolean;
  handleDropdownClicked: () => void;
}

const VesselSelectionLabel: React.ChildlessComponent<VesselSelectionLabelProps> = ({
  selectedVessel,
  isLoading,
  hasVessels,
  colorsInverted,
  isExpanded,
  handleDropdownClicked,
}) => {
  const { t } = useTranslation(['shared']);

  const loadingIndicator = (
    <>
      <Spinner size="24" inverted={true} rotationDuration={2.5} />
      <SDropdownLabel colorsInverted={colorsInverted}>{t('shared:strip.loadingIndicator')}</SDropdownLabel>
    </>
  );

  return (
    <SHoveredRegion onClick={handleDropdownClicked} data-test-id={vesselsDropdown} colorsInverted={colorsInverted}>
      {isLoading ? (
        loadingIndicator
      ) : (
        <>
          {isDefined(selectedVessel) && (
            <>
              {getVesselIcon(selectedVessel.vesselType, colorsInverted ? settings.colors.Primary.Blue_9 : settings.colors.Primary.Grey_2)}
              <SDropdownLabel colorsInverted={colorsInverted}>{selectedVessel.displayName}</SDropdownLabel>
              {hasVessels && <SChevronIconRotatable colorsInverted={colorsInverted} orientation={isExpanded ? 'up' : 'down'} isDisabled={false} />}
            </>
          )}
        </>
      )}
    </SHoveredRegion>
  );
};

const SDropdownLabel = styled.span<{ colorsInverted: boolean }>`
  margin-left: ${settings.Spacing.Spacing_100};
  font-family: ${settings.typography.FontFamily.Medium};
  font-size: ${settings.typography.FontSize.Small};
  width: auto;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => (props.colorsInverted ? settings.colors.Primary.Blue_9 : settings.colors.Primary.Grey_2)};
`;

const SDropdownContainer = styled.div<{ colorsInverted: boolean }>`
  display: flex;
  position: relative;
  color: ${settings.colors.Primary.Blue_9};
`;

const SDropdownPanel = styled(DropdownPanel)`
  overflow-x: hidden;
  min-width: 100%;
  max-height: 400px;
`;

const SHoveredRegion = styled.div<{ colorsInverted: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 ${settings.Spacing.Spacing_200};
  cursor: pointer;
  border-right: ${(props) => (props.colorsInverted ? `1px solid ${settings.colors.Primary.Grey_3}` : `1px solid ${settings.colors.Primary.Grey_7}`)};

  &.isDisabled {
    pointer-events: none;
  }

  &:hover:not(.isDisabled) {
    background-color: ${(props) => (props.colorsInverted ? '' : settings.colors.Primary.Grey_9)};
    border-color: ${(props) => (props.colorsInverted ? '' : settings.colors.Primary.Grey_7)};
    border-right-style: solid;

    &:active {
      background-color: ${(props) => (props.colorsInverted ? '' : settings.colors.Primary.Grey_8)};
    }
  }
`;

const SChevronIconRotatable = styled<React.FunctionComponent<React.ComponentProps<typeof ChevronRotatable> & { isDisabled: boolean; colorsInverted: boolean }>>(
  ChevronRotatable
).attrs((props) => ({
  fill: isDefined(props['fill'])
    ? props['fill']
    : props.isDisabled
    ? settings.colors.Primary.Grey_8
    : props.colorsInverted
    ? settings.colors.Primary.Blue_9
    : settings.colors.Primary.Grey_2,
}))`
  margin-left: ${settings.Spacing.Spacing_200};
`;
