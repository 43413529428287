import { useRef } from 'react';
import { useUpdate } from 'react-use';

const proto = Set.prototype;

/**
 * `useSet` from `react-use` returns a new reference on every render, which is not what we want.
 * The following implementation returns a stable reference.
 *
 * @see https://github.com/streamich/react-use/issues/1188
 *
 * This implementation is borrowed from @see https://github.com/react-hookz/web/blob/14ebfdc45c69e60da2233825caa21a40bc583fbf/src/useSet/index.ts
 */
export function useSet<T>(values?: readonly T[] | null): Set<T> {
  const setRef = useRef<Set<T>>();
  const rerender = useUpdate();

  if (!setRef.current) {
    const set = new Set<T>(values);

    setRef.current = set;

    set.add = (...args) => {
      proto.add.apply(set, args);
      rerender();
      return set;
    };

    set.clear = (...args) => {
      proto.clear.apply(set, args);
      rerender();
    };

    set.delete = (...args) => {
      const res = proto.delete.apply(set, args);
      rerender();

      return res;
    };
  }

  return setRef.current;
}
