import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const ZoomInIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g clipPath="url(#j1wvpofp9a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 7A5 5 0 1 1 2 7a5 5 0 0 1 10 0zm-.79 5.594a7 7 0 1 1 1.396-1.4c.04.03.079.062.115.099l2.998 2.998a1 1 0 0 1-1.414 1.414l-2.998-2.998a1.001 1.001 0 0 1-.098-.113zM4 7a1 1 0 0 1 1-1h1V5a1 1 0 0 1 2 0v1h1.24a1 1 0 0 1 0 2H8v1.24a1 1 0 0 1-2 0V8H5a1 1 0 0 1-1-1z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="j1wvpofp9a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

ZoomInIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
