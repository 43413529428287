import { settings } from '@rhim/design';
import { CloudUpIcon } from '@rhim/icons/48';
import { isDefined, MIMEType } from '@rhim/utils';
import * as React from 'react';
import styled from 'styled-components';

import { Button } from '../Button';
import { RcFile, Upload, UploadProps } from '../Upload';

const CloudIcon = <CloudUpIcon fill={settings.colors.Primary.Grey_4} />;

interface Props extends Pick<UploadProps, 'type' | 'action'> {
  accept?: Set<MIMEType>;
  placeholder: string;
  callToAction: string;
  onFileSelected: (file: RcFile) => void;
  width?: number;
}

export const Initial: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ accept, type, width, placeholder, callToAction, onFileSelected }: Props) => {
  const handleButtonCTAClicked = () => {
    // TODO - set a click-handler for this button
  };

  return (
    <Upload
      multiple={false}
      accept={isDefined(accept) ? [...accept].join(',') : undefined}
      beforeUpload={(file: RcFile) => {
        onFileSelected(file);
        // prevent auto-upload by returning false
        return false;
      }}
      showUploadList={false}
      type={type}
    >
      <Wrapper width={width}>
        <Centerer>
          <IconContainer>{CloudIcon}</IconContainer>
        </Centerer>
        <CentralContainer>
          <Placeholder>{placeholder}</Placeholder>
        </CentralContainer>
        <Centerer>
          <Button label={callToAction} size="medium-48" mode="ghost" style={{ height: '32px' }} onClick={handleButtonCTAClicked} />
        </Centerer>
      </Wrapper>
    </Upload>
  );
};

const Wrapper = styled.div<{ width?: number }>`
  padding: ${settings.Spacing.Spacing_150};
  border-radius: 3px;
  border: dashed 2px ${settings.colors.Primary.Grey_4};
  background-color: ${settings.colors.Primary.Grey_1};
  display: flex;
  justify-content: space-between;
  transition: border-color 0.3s;
  width: ${(props) => (isDefined(props.width) ? `${props.width}px` : 'auto')};

  .ant-upload-drag-hover & {
    border-color: ${settings.colors.Operational.State_Blue_2};
  }
`;

const Centerer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CentralContainer = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0;
  flex-grow: 1;
`;

const Placeholder = styled.div`
  color: ${settings.colors.Primary.Grey_8};
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.Small};
  text-align: start;
`;
