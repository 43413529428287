/* tslint:disable */
/* eslint-disable */
/**
 * FileIngestionService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMFileIngestionServiceFileTypesDataType = {
    Text: 'Text',
    Boolean: 'Boolean',
    Integer: 'Integer',
    Float: 'Float',
    Timestamp: 'Timestamp'
} as const;

export type RHIMFileIngestionServiceFileTypesDataType = typeof RHIMFileIngestionServiceFileTypesDataType[keyof typeof RHIMFileIngestionServiceFileTypesDataType];


/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCommonMetadataKeysDto
 */
export interface RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCommonMetadataKeysDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCommonMetadataKeysDto
     */
    'metadataKeys': Array<string>;
}
/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto
 */
export interface RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto
     */
    'expectedExtensions': Array<string>;
    /**
     * 
     * @type {Array<RHIMFileIngestionServiceV1CustomerFileTypeConfigurationEnrichmentRuleDto>}
     * @memberof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto
     */
    'enrichmentRules': Array<RHIMFileIngestionServiceV1CustomerFileTypeConfigurationEnrichmentRuleDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto
     */
    'requiredMetadataKeys': Array<string>;
}
/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationDto
 */
export interface RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationDto
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationDto
     */
    'expectedExtensions': Array<string>;
    /**
     * 
     * @type {Array<RHIMFileIngestionServiceV1CustomerFileTypeConfigurationEnrichmentRuleDto>}
     * @memberof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationDto
     */
    'enrichmentRules': Array<RHIMFileIngestionServiceV1CustomerFileTypeConfigurationEnrichmentRuleDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationDto
     */
    'requiredMetadataKeys': Array<string>;
}
/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationListDto
 */
export interface RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationListDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationListDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationListDto
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationListDto
     */
    'expectedExtensions': Array<string>;
    /**
     * 
     * @type {Array<RHIMFileIngestionServiceV1CustomerFileTypeConfigurationEnrichmentRuleDto>}
     * @memberof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationListDto
     */
    'enrichmentRules': Array<RHIMFileIngestionServiceV1CustomerFileTypeConfigurationEnrichmentRuleDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationListDto
     */
    'requiredMetadataKeys': Array<string>;
    /**
     * 
     * @type {RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationStatus}
     * @memberof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationListDto
     */
    'status': RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationStatus;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationStatus = {
    MissingParserConfiguration: 'MissingParserConfiguration',
    Ready: 'Ready'
} as const;

export type RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationStatus = typeof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationStatus[keyof typeof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationStatus];


/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1CustomerFileTypeConfigurationEnrichmentRuleDto
 */
export interface RHIMFileIngestionServiceV1CustomerFileTypeConfigurationEnrichmentRuleDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationEnrichmentRuleDto
     */
    'metadataKey': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationEnrichmentRuleDto
     */
    'pattern'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationEnrichmentRuleDto
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1CustomerFileTypeConfigurationParsedFileStructureDto
 */
export interface RHIMFileIngestionServiceV1CustomerFileTypeConfigurationParsedFileStructureDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationParsedFileStructureDto
     */
    'name': string;
    /**
     * 
     * @type {Array<RHIMFileIngestionServiceV1CustomerFileTypeConfigurationParsedFileStructureDtoField>}
     * @memberof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationParsedFileStructureDto
     */
    'fields': Array<RHIMFileIngestionServiceV1CustomerFileTypeConfigurationParsedFileStructureDtoField>;
    /**
     * 
     * @type {Array<RHIMFileIngestionServiceV1CustomerFileTypeConfigurationParsedFileStructureDtoTable>}
     * @memberof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationParsedFileStructureDto
     */
    'tables': Array<RHIMFileIngestionServiceV1CustomerFileTypeConfigurationParsedFileStructureDtoTable>;
}
/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1CustomerFileTypeConfigurationParsedFileStructureDtoColumn
 */
export interface RHIMFileIngestionServiceV1CustomerFileTypeConfigurationParsedFileStructureDtoColumn {
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationParsedFileStructureDtoColumn
     */
    'name': string;
    /**
     * 
     * @type {RHIMFileIngestionServiceFileTypesDataType}
     * @memberof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationParsedFileStructureDtoColumn
     */
    'dataType': RHIMFileIngestionServiceFileTypesDataType;
}


/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1CustomerFileTypeConfigurationParsedFileStructureDtoField
 */
export interface RHIMFileIngestionServiceV1CustomerFileTypeConfigurationParsedFileStructureDtoField {
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationParsedFileStructureDtoField
     */
    'name': string;
    /**
     * 
     * @type {RHIMFileIngestionServiceFileTypesDataType}
     * @memberof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationParsedFileStructureDtoField
     */
    'dataType': RHIMFileIngestionServiceFileTypesDataType;
}


/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1CustomerFileTypeConfigurationParsedFileStructureDtoTable
 */
export interface RHIMFileIngestionServiceV1CustomerFileTypeConfigurationParsedFileStructureDtoTable {
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationParsedFileStructureDtoTable
     */
    'name': string;
    /**
     * 
     * @type {Array<RHIMFileIngestionServiceV1CustomerFileTypeConfigurationParsedFileStructureDtoColumn>}
     * @memberof RHIMFileIngestionServiceV1CustomerFileTypeConfigurationParsedFileStructureDtoTable
     */
    'columns': Array<RHIMFileIngestionServiceV1CustomerFileTypeConfigurationParsedFileStructureDtoColumn>;
}
/**
 * Describes a column in table
 * @export
 * @interface RHIMFileIngestionServiceV1HarmonizationConfigurationColumnDefinitionDto
 */
export interface RHIMFileIngestionServiceV1HarmonizationConfigurationColumnDefinitionDto {
    /**
     * Identifier (internal name) of the column
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationColumnDefinitionDto
     */
    'id': string;
    /**
     * Display name of the column
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationColumnDefinitionDto
     */
    'displayName': string;
    /**
     * 
     * @type {RHIMFileIngestionServiceFileTypesDataType}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationColumnDefinitionDto
     */
    'dataType': RHIMFileIngestionServiceFileTypesDataType;
    /**
     * Description of the column\'s content
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationColumnDefinitionDto
     */
    'description'?: string | null;
    /**
     * Set of allowed values for this column. Only applicable to RHIM.FileIngestionService.FileTypes.DataType.Text columns.
     * @type {Array<string>}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationColumnDefinitionDto
     */
    'allowedValues'?: Array<string> | null;
    /**
     * Abbreviation of the unit of the column (optional) <br />  e.g.: kg, °C, m
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationColumnDefinitionDto
     */
    'unit'?: string | null;
    /**
     * Category used to group columns (optional)
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationColumnDefinitionDto
     */
    'category'?: string | null;
    /**
     * Sub category used to group columns together with RHIM.FileIngestionService.V1.HarmonizationConfiguration.ColumnDefinitionDto.Category (optional)
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationColumnDefinitionDto
     */
    'subCategory'?: string | null;
}


/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1HarmonizationConfigurationColumnSourceDto
 */
export interface RHIMFileIngestionServiceV1HarmonizationConfigurationColumnSourceDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationColumnSourceDto
     */
    'tableName': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationColumnSourceDto
     */
    'columnName': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationColumnSourceDto
     */
    'file': string;
}
/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1HarmonizationConfigurationColumnTargetDto
 */
export interface RHIMFileIngestionServiceV1HarmonizationConfigurationColumnTargetDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationColumnTargetDto
     */
    'tableName': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationColumnTargetDto
     */
    'columnName': string;
    /**
     * 
     * @type {RHIMFileIngestionServiceFileTypesDataType}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationColumnTargetDto
     */
    'dataType'?: RHIMFileIngestionServiceFileTypesDataType | null;
}
/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1HarmonizationConfigurationComplexMappingExpressionDto
 */
export interface RHIMFileIngestionServiceV1HarmonizationConfigurationComplexMappingExpressionDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationComplexMappingExpressionDto
     */
    'expression': string;
    /**
     * 
     * @type {RHIMFileIngestionServiceV1HarmonizationConfigurationMappingExpressionDtoTarget}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationComplexMappingExpressionDto
     */
    'target': RHIMFileIngestionServiceV1HarmonizationConfigurationMappingExpressionDtoTarget;
}
/**
 * Describes a field
 * @export
 * @interface RHIMFileIngestionServiceV1HarmonizationConfigurationFieldDefinitionDto
 */
export interface RHIMFileIngestionServiceV1HarmonizationConfigurationFieldDefinitionDto {
    /**
     * Identifier (internal name) of the field
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationFieldDefinitionDto
     */
    'id': string;
    /**
     * Display name of the field
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationFieldDefinitionDto
     */
    'displayName': string;
    /**
     * 
     * @type {RHIMFileIngestionServiceFileTypesDataType}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationFieldDefinitionDto
     */
    'dataType': RHIMFileIngestionServiceFileTypesDataType;
    /**
     * Description of the field\'s content
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationFieldDefinitionDto
     */
    'description'?: string | null;
    /**
     * Set of allowed values for this field. Only applicable to RHIM.FileIngestionService.FileTypes.DataType.Text columns.
     * @type {Array<string>}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationFieldDefinitionDto
     */
    'allowedValues'?: Array<string> | null;
    /**
     * Abbreviation of the unit of the field (optional) <br />  e.g.: kg, °C, m
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationFieldDefinitionDto
     */
    'unit'?: string | null;
    /**
     * Category used to group columns (optional)
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationFieldDefinitionDto
     */
    'category'?: string | null;
    /**
     * Sub category used to group columns together with RHIM.FileIngestionService.V1.HarmonizationConfiguration.FieldDefinitionDto.Category (optional)
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationFieldDefinitionDto
     */
    'subCategory'?: string | null;
}


/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1HarmonizationConfigurationFieldSourceDto
 */
export interface RHIMFileIngestionServiceV1HarmonizationConfigurationFieldSourceDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationFieldSourceDto
     */
    'fieldName': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationFieldSourceDto
     */
    'file': string;
}
/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1HarmonizationConfigurationFieldTargetDto
 */
export interface RHIMFileIngestionServiceV1HarmonizationConfigurationFieldTargetDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationFieldTargetDto
     */
    'fieldName': string;
    /**
     * 
     * @type {RHIMFileIngestionServiceFileTypesDataType}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationFieldTargetDto
     */
    'dataType'?: RHIMFileIngestionServiceFileTypesDataType | null;
}
/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1HarmonizationConfigurationFileByIndexDto
 */
export interface RHIMFileIngestionServiceV1HarmonizationConfigurationFileByIndexDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationFileByIndexDto
     */
    'index': number;
}
/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1HarmonizationConfigurationFileByNameDto
 */
export interface RHIMFileIngestionServiceV1HarmonizationConfigurationFileByNameDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationFileByNameDto
     */
    'file': string;
}
/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1HarmonizationConfigurationFileMappingDto
 */
export interface RHIMFileIngestionServiceV1HarmonizationConfigurationFileMappingDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationFileMappingDto
     */
    'alias': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationFileMappingDto
     */
    'fileType': string;
    /**
     * 
     * @type {RHIMFileIngestionServiceV1HarmonizationConfigurationScopeDto}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationFileMappingDto
     */
    'scope': RHIMFileIngestionServiceV1HarmonizationConfigurationScopeDto;
    /**
     * 
     * @type {Array<RHIMFileIngestionServiceV1HarmonizationConfigurationRowFilterExpressionDto>}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationFileMappingDto
     */
    'rowFilters': Array<RHIMFileIngestionServiceV1HarmonizationConfigurationRowFilterExpressionDto>;
    /**
     * 
     * @type {Array<RHIMFileIngestionServiceV1HarmonizationConfigurationFileMappingDtoMappingsInner>}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationFileMappingDto
     */
    'mappings': Array<RHIMFileIngestionServiceV1HarmonizationConfigurationFileMappingDtoMappingsInner>;
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationFileMappingDto
     */
    'passthroughContentFromInputFile'?: string | null;
}
/**
 * @type RHIMFileIngestionServiceV1HarmonizationConfigurationFileMappingDtoMappingsInner
 * @export
 */
export type RHIMFileIngestionServiceV1HarmonizationConfigurationFileMappingDtoMappingsInner = RHIMFileIngestionServiceV1HarmonizationConfigurationComplexMappingExpressionDto | RHIMFileIngestionServiceV1HarmonizationConfigurationOneToOneMappingExpressionDto;

/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto
 */
export interface RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto
     */
    'mergeOutputFiles': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto
     */
    'isActive': boolean;
    /**
     * 
     * @type {Array<RHIMFileIngestionServiceV1HarmonizationConfigurationFileMappingDto>}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto
     */
    'fileMappings': Array<RHIMFileIngestionServiceV1HarmonizationConfigurationFileMappingDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto
     */
    'inputFileTypeIds': Array<string>;
}
/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationDto
 */
export interface RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationDto
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationDto
     */
    'mergeOutputFiles': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationDto
     */
    'isActive': boolean;
    /**
     * 
     * @type {Array<RHIMFileIngestionServiceV1HarmonizationConfigurationFileMappingDto>}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationDto
     */
    'fileMappings': Array<RHIMFileIngestionServiceV1HarmonizationConfigurationFileMappingDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationDto
     */
    'inputFileTypeIds': Array<string>;
}
/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationListDto
 */
export interface RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationListDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationListDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationListDto
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationListDto
     */
    'isActive': boolean;
    /**
     * 
     * @type {Array<RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationListDtoInputFileTypeListDto>}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationListDto
     */
    'inputFileTypes': Array<RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationListDtoInputFileTypeListDto>;
    /**
     * 
     * @type {Array<RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationListDtoOutputFileTypeListDto>}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationListDto
     */
    'outputFileTypes': Array<RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationListDtoOutputFileTypeListDto>;
}
/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationListDtoInputFileTypeListDto
 */
export interface RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationListDtoInputFileTypeListDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationListDtoInputFileTypeListDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationListDtoInputFileTypeListDto
     */
    'customerFileTypeName': string;
}
/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationListDtoOutputFileTypeListDto
 */
export interface RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationListDtoOutputFileTypeListDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationListDtoOutputFileTypeListDto
     */
    'fileTypeName': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationListDtoOutputFileTypeListDto
     */
    'alias': string;
}
/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizedFileStructureDto
 */
export interface RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizedFileStructureDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizedFileStructureDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizedFileStructureDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizedFileStructureDto
     */
    'isExtensible': boolean;
    /**
     * 
     * @type {Array<RHIMFileIngestionServiceV1HarmonizationConfigurationFieldDefinitionDto>}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizedFileStructureDto
     */
    'fields': Array<RHIMFileIngestionServiceV1HarmonizationConfigurationFieldDefinitionDto>;
    /**
     * 
     * @type {Array<RHIMFileIngestionServiceV1HarmonizationConfigurationTableDefinitionDto>}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizedFileStructureDto
     */
    'tables': Array<RHIMFileIngestionServiceV1HarmonizationConfigurationTableDefinitionDto>;
}
/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1HarmonizationConfigurationMappingExpressionDto
 */
export interface RHIMFileIngestionServiceV1HarmonizationConfigurationMappingExpressionDto {
    /**
     * 
     * @type {RHIMFileIngestionServiceV1HarmonizationConfigurationMappingExpressionDtoTarget}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationMappingExpressionDto
     */
    'target': RHIMFileIngestionServiceV1HarmonizationConfigurationMappingExpressionDtoTarget;
}
/**
 * @type RHIMFileIngestionServiceV1HarmonizationConfigurationMappingExpressionDtoTarget
 * @export
 */
export type RHIMFileIngestionServiceV1HarmonizationConfigurationMappingExpressionDtoTarget = RHIMFileIngestionServiceV1HarmonizationConfigurationColumnTargetDto | RHIMFileIngestionServiceV1HarmonizationConfigurationFieldTargetDto;

/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1HarmonizationConfigurationOneToOneMappingExpressionDto
 */
export interface RHIMFileIngestionServiceV1HarmonizationConfigurationOneToOneMappingExpressionDto {
    /**
     * 
     * @type {RHIMFileIngestionServiceV1HarmonizationConfigurationFieldSourceDto | RHIMFileIngestionServiceV1HarmonizationConfigurationColumnSourceDto}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationOneToOneMappingExpressionDto
     */
    'source': RHIMFileIngestionServiceV1HarmonizationConfigurationFieldSourceDto | RHIMFileIngestionServiceV1HarmonizationConfigurationColumnSourceDto;
    /**
     * 
     * @type {RHIMFileIngestionServiceV1HarmonizationConfigurationMappingExpressionDtoTarget}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationOneToOneMappingExpressionDto
     */
    'target': RHIMFileIngestionServiceV1HarmonizationConfigurationMappingExpressionDtoTarget;
}
/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1HarmonizationConfigurationRowFilterExpressionDto
 */
export interface RHIMFileIngestionServiceV1HarmonizationConfigurationRowFilterExpressionDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationRowFilterExpressionDto
     */
    'tableName': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationRowFilterExpressionDto
     */
    'expression': string;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationRowFilterExpressionDto
     */
    'discardFileIfEmpty': boolean;
}
/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1HarmonizationConfigurationScopeDto
 */
export interface RHIMFileIngestionServiceV1HarmonizationConfigurationScopeDto {
    /**
     * 
     * @type {RHIMFileIngestionServiceV1HarmonizationConfigurationScopeDtoFileScope}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationScopeDto
     */
    'fileScope': RHIMFileIngestionServiceV1HarmonizationConfigurationScopeDtoFileScope;
    /**
     * 
     * @type {RHIMFileIngestionServiceV1HarmonizationConfigurationScopeDtoTableScope}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationScopeDto
     */
    'tableScope': RHIMFileIngestionServiceV1HarmonizationConfigurationScopeDtoTableScope;
}
/**
 * @type RHIMFileIngestionServiceV1HarmonizationConfigurationScopeDtoFileScope
 * @export
 */
export type RHIMFileIngestionServiceV1HarmonizationConfigurationScopeDtoFileScope = RHIMFileIngestionServiceV1HarmonizationConfigurationFileByIndexDto | RHIMFileIngestionServiceV1HarmonizationConfigurationFileByNameDto;

/**
 * @type RHIMFileIngestionServiceV1HarmonizationConfigurationScopeDtoTableScope
 * @export
 */
export type RHIMFileIngestionServiceV1HarmonizationConfigurationScopeDtoTableScope = RHIMFileIngestionServiceV1HarmonizationConfigurationTableByIndexDto | RHIMFileIngestionServiceV1HarmonizationConfigurationTableByNameDto;

/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1HarmonizationConfigurationSourceDto
 */
export interface RHIMFileIngestionServiceV1HarmonizationConfigurationSourceDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationSourceDto
     */
    'file': string;
}
/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1HarmonizationConfigurationTableByIndexDto
 */
export interface RHIMFileIngestionServiceV1HarmonizationConfigurationTableByIndexDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationTableByIndexDto
     */
    'index': number;
}
/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1HarmonizationConfigurationTableByNameDto
 */
export interface RHIMFileIngestionServiceV1HarmonizationConfigurationTableByNameDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationTableByNameDto
     */
    'tableName': string;
}
/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1HarmonizationConfigurationTableDefinitionDto
 */
export interface RHIMFileIngestionServiceV1HarmonizationConfigurationTableDefinitionDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationTableDefinitionDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationTableDefinitionDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<RHIMFileIngestionServiceV1HarmonizationConfigurationColumnDefinitionDto>}
     * @memberof RHIMFileIngestionServiceV1HarmonizationConfigurationTableDefinitionDto
     */
    'columns': Array<RHIMFileIngestionServiceV1HarmonizationConfigurationColumnDefinitionDto>;
}
/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1HarmonizationHarmonizedFileDto
 */
export interface RHIMFileIngestionServiceV1HarmonizationHarmonizedFileDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationHarmonizedFileDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationHarmonizedFileDto
     */
    'fileType': string;
    /**
     * 
     * @type {{ [key: string]: RHIMFileIngestionServiceV1HarmonizationHarmonizedFileDtoFieldValueDto; }}
     * @memberof RHIMFileIngestionServiceV1HarmonizationHarmonizedFileDto
     */
    'fields': { [key: string]: RHIMFileIngestionServiceV1HarmonizationHarmonizedFileDtoFieldValueDto; };
    /**
     * 
     * @type {RHIMFileIngestionServiceV1HarmonizationHarmonizedFileDtoTemporaryDownloadLinkDto}
     * @memberof RHIMFileIngestionServiceV1HarmonizationHarmonizedFileDto
     */
    'temporaryDownloadLink': RHIMFileIngestionServiceV1HarmonizationHarmonizedFileDtoTemporaryDownloadLinkDto;
}
/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1HarmonizationHarmonizedFileDtoFieldValueDto
 */
export interface RHIMFileIngestionServiceV1HarmonizationHarmonizedFileDtoFieldValueDto {
    /**
     * 
     * @type {RHIMFileIngestionServiceFileTypesDataType}
     * @memberof RHIMFileIngestionServiceV1HarmonizationHarmonizedFileDtoFieldValueDto
     */
    'type': RHIMFileIngestionServiceFileTypesDataType;
    /**
     * 
     * @type {any}
     * @memberof RHIMFileIngestionServiceV1HarmonizationHarmonizedFileDtoFieldValueDto
     */
    'value'?: any | null;
}


/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1HarmonizationHarmonizedFileDtoTemporaryDownloadLinkDto
 */
export interface RHIMFileIngestionServiceV1HarmonizationHarmonizedFileDtoTemporaryDownloadLinkDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationHarmonizedFileDtoTemporaryDownloadLinkDto
     */
    'uri': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1HarmonizationHarmonizedFileDtoTemporaryDownloadLinkDto
     */
    'expiresOn': string;
}
/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1ParserConfigurationParserConfigurationDto
 */
export interface RHIMFileIngestionServiceV1ParserConfigurationParserConfigurationDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1ParserConfigurationParserConfigurationDto
     */
    'parserType': string;
    /**
     * 
     * @type {any}
     * @memberof RHIMFileIngestionServiceV1ParserConfigurationParserConfigurationDto
     */
    'parserConfiguration': any;
}
/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1ParserParserListDto
 */
export interface RHIMFileIngestionServiceV1ParserParserListDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1ParserParserListDto
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1ParserParserListDto
     */
    'parserType': string;
}
/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1ParserParserSchemaDto
 */
export interface RHIMFileIngestionServiceV1ParserParserSchemaDto {
    /**
     * 
     * @type {any}
     * @memberof RHIMFileIngestionServiceV1ParserParserSchemaDto
     */
    'schema': any;
}
/**
 * 
 * @export
 * @interface RHIMFileIngestionServiceV1UploadUploadResponseDto
 */
export interface RHIMFileIngestionServiceV1UploadUploadResponseDto {
    /**
     * Can be used to track the uploaded file during the harmonization
     * @type {string}
     * @memberof RHIMFileIngestionServiceV1UploadUploadResponseDto
     */
    'fileIngressId': string;
}

/**
 * CustomerFileTypeConfigurationApi - axios parameter creator
 * @export
 */
export const CustomerFileTypeConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary CreateCustomerFileTypeConfiguration
         * @param {string} customerId Format - uuid.
         * @param {RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto} [rHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerFileTypeConfiguration: async (customerId: string, rHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto?: RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('createCustomerFileTypeConfiguration', 'customerId', customerId)
            const localVarPath = `/customerfiletypeconfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DeleteCustomerFileTypeConfiguration
         * @param {string} id Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerFileTypeConfiguration: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCustomerFileTypeConfiguration', 'id', id)
            const localVarPath = `/customerfiletypeconfiguration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetCustomerFileTypeConfiguration
         * @param {string} id Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerFileTypeConfiguration: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCustomerFileTypeConfiguration', 'id', id)
            const localVarPath = `/customerfiletypeconfiguration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetCustomerFileTypeConfigurationCommonMetadataKeys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerFileTypeConfigurationCommonMetadataKeys: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customerfiletypeconfiguration/metadatakeys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetCustomerFileTypeStructure
         * @param {string} id Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerFileTypeStructure: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCustomerFileTypeStructure', 'id', id)
            const localVarPath = `/customerfiletypeconfiguration/{id}/structure`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ListCustomerFileTypeConfiguration
         * @param {string} customerId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomerFileTypeConfiguration: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('listCustomerFileTypeConfiguration', 'customerId', customerId)
            const localVarPath = `/customerfiletypeconfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UpdateCustomerFileTypeConfiguration
         * @param {string} id Format - uuid.
         * @param {RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto} [rHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerFileTypeConfiguration: async (id: string, rHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto?: RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCustomerFileTypeConfiguration', 'id', id)
            const localVarPath = `/customerfiletypeconfiguration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerFileTypeConfigurationApi - functional programming interface
 * @export
 */
export const CustomerFileTypeConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerFileTypeConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary CreateCustomerFileTypeConfiguration
         * @param {string} customerId Format - uuid.
         * @param {RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto} [rHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomerFileTypeConfiguration(customerId: string, rHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto?: RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomerFileTypeConfiguration(customerId, rHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary DeleteCustomerFileTypeConfiguration
         * @param {string} id Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomerFileTypeConfiguration(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomerFileTypeConfiguration(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GetCustomerFileTypeConfiguration
         * @param {string} id Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerFileTypeConfiguration(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerFileTypeConfiguration(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GetCustomerFileTypeConfigurationCommonMetadataKeys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerFileTypeConfigurationCommonMetadataKeys(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCommonMetadataKeysDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerFileTypeConfigurationCommonMetadataKeys(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GetCustomerFileTypeStructure
         * @param {string} id Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerFileTypeStructure(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMFileIngestionServiceV1CustomerFileTypeConfigurationParsedFileStructureDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerFileTypeStructure(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ListCustomerFileTypeConfiguration
         * @param {string} customerId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCustomerFileTypeConfiguration(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCustomerFileTypeConfiguration(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UpdateCustomerFileTypeConfiguration
         * @param {string} id Format - uuid.
         * @param {RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto} [rHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerFileTypeConfiguration(id: string, rHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto?: RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerFileTypeConfiguration(id, rHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerFileTypeConfigurationApi - factory interface
 * @export
 */
export const CustomerFileTypeConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerFileTypeConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @summary CreateCustomerFileTypeConfiguration
         * @param {string} customerId Format - uuid.
         * @param {RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto} [rHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerFileTypeConfiguration(customerId: string, rHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto?: RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto, options?: any): AxiosPromise<RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationDto> {
            return localVarFp.createCustomerFileTypeConfiguration(customerId, rHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DeleteCustomerFileTypeConfiguration
         * @param {string} id Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerFileTypeConfiguration(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCustomerFileTypeConfiguration(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetCustomerFileTypeConfiguration
         * @param {string} id Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerFileTypeConfiguration(id: string, options?: any): AxiosPromise<RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationDto> {
            return localVarFp.getCustomerFileTypeConfiguration(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetCustomerFileTypeConfigurationCommonMetadataKeys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerFileTypeConfigurationCommonMetadataKeys(options?: any): AxiosPromise<RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCommonMetadataKeysDto> {
            return localVarFp.getCustomerFileTypeConfigurationCommonMetadataKeys(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetCustomerFileTypeStructure
         * @param {string} id Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerFileTypeStructure(id: string, options?: any): AxiosPromise<RHIMFileIngestionServiceV1CustomerFileTypeConfigurationParsedFileStructureDto> {
            return localVarFp.getCustomerFileTypeStructure(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ListCustomerFileTypeConfiguration
         * @param {string} customerId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomerFileTypeConfiguration(customerId: string, options?: any): AxiosPromise<Array<RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationListDto>> {
            return localVarFp.listCustomerFileTypeConfiguration(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UpdateCustomerFileTypeConfiguration
         * @param {string} id Format - uuid.
         * @param {RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto} [rHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerFileTypeConfiguration(id: string, rHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto?: RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto, options?: any): AxiosPromise<RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationDto> {
            return localVarFp.updateCustomerFileTypeConfiguration(id, rHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerFileTypeConfigurationApi - object-oriented interface
 * @export
 * @class CustomerFileTypeConfigurationApi
 * @extends {BaseAPI}
 */
export class CustomerFileTypeConfigurationApi extends BaseAPI {
    /**
     * 
     * @summary CreateCustomerFileTypeConfiguration
     * @param {string} customerId Format - uuid.
     * @param {RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto} [rHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerFileTypeConfigurationApi
     */
    public createCustomerFileTypeConfiguration(customerId: string, rHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto?: RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto, options?: AxiosRequestConfig) {
        return CustomerFileTypeConfigurationApiFp(this.configuration).createCustomerFileTypeConfiguration(customerId, rHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DeleteCustomerFileTypeConfiguration
     * @param {string} id Format - uuid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerFileTypeConfigurationApi
     */
    public deleteCustomerFileTypeConfiguration(id: string, options?: AxiosRequestConfig) {
        return CustomerFileTypeConfigurationApiFp(this.configuration).deleteCustomerFileTypeConfiguration(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetCustomerFileTypeConfiguration
     * @param {string} id Format - uuid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerFileTypeConfigurationApi
     */
    public getCustomerFileTypeConfiguration(id: string, options?: AxiosRequestConfig) {
        return CustomerFileTypeConfigurationApiFp(this.configuration).getCustomerFileTypeConfiguration(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetCustomerFileTypeConfigurationCommonMetadataKeys
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerFileTypeConfigurationApi
     */
    public getCustomerFileTypeConfigurationCommonMetadataKeys(options?: AxiosRequestConfig) {
        return CustomerFileTypeConfigurationApiFp(this.configuration).getCustomerFileTypeConfigurationCommonMetadataKeys(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetCustomerFileTypeStructure
     * @param {string} id Format - uuid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerFileTypeConfigurationApi
     */
    public getCustomerFileTypeStructure(id: string, options?: AxiosRequestConfig) {
        return CustomerFileTypeConfigurationApiFp(this.configuration).getCustomerFileTypeStructure(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ListCustomerFileTypeConfiguration
     * @param {string} customerId Format - uuid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerFileTypeConfigurationApi
     */
    public listCustomerFileTypeConfiguration(customerId: string, options?: AxiosRequestConfig) {
        return CustomerFileTypeConfigurationApiFp(this.configuration).listCustomerFileTypeConfiguration(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UpdateCustomerFileTypeConfiguration
     * @param {string} id Format - uuid.
     * @param {RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto} [rHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerFileTypeConfigurationApi
     */
    public updateCustomerFileTypeConfiguration(id: string, rHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto?: RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto, options?: AxiosRequestConfig) {
        return CustomerFileTypeConfigurationApiFp(this.configuration).updateCustomerFileTypeConfiguration(id, rHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationCreateOrUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HarmonizationConfigurationApi - axios parameter creator
 * @export
 */
export const HarmonizationConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary CreateHarmonizationConfiguration
         * @param {string} customerId Format - uuid.
         * @param {RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto} [rHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHarmonizationConfiguration: async (customerId: string, rHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto?: RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('createHarmonizationConfiguration', 'customerId', customerId)
            const localVarPath = `/harmonizationconfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DeleteHarmonizationConfiguration
         * @param {string} id Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHarmonizationConfiguration: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteHarmonizationConfiguration', 'id', id)
            const localVarPath = `/harmonizationconfiguration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetHarmonizationConfiguration
         * @param {string} id Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHarmonizationConfiguration: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getHarmonizationConfiguration', 'id', id)
            const localVarPath = `/harmonizationconfiguration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetHarmonizedFileTypes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHarmonizedFileTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/harmonizationconfiguration/harmonizedfiletypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ListHarmonizationConfiguration
         * @param {string} customerId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHarmonizationConfiguration: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('listHarmonizationConfiguration', 'customerId', customerId)
            const localVarPath = `/harmonizationconfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UpdateHarmonizationConfiguration
         * @param {string} id Format - uuid.
         * @param {RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto} [rHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHarmonizationConfiguration: async (id: string, rHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto?: RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateHarmonizationConfiguration', 'id', id)
            const localVarPath = `/harmonizationconfiguration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HarmonizationConfigurationApi - functional programming interface
 * @export
 */
export const HarmonizationConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HarmonizationConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary CreateHarmonizationConfiguration
         * @param {string} customerId Format - uuid.
         * @param {RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto} [rHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createHarmonizationConfiguration(customerId: string, rHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto?: RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createHarmonizationConfiguration(customerId, rHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary DeleteHarmonizationConfiguration
         * @param {string} id Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteHarmonizationConfiguration(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteHarmonizationConfiguration(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GetHarmonizationConfiguration
         * @param {string} id Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHarmonizationConfiguration(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHarmonizationConfiguration(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GetHarmonizedFileTypes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHarmonizedFileTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizedFileStructureDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHarmonizedFileTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ListHarmonizationConfiguration
         * @param {string} customerId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listHarmonizationConfiguration(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listHarmonizationConfiguration(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UpdateHarmonizationConfiguration
         * @param {string} id Format - uuid.
         * @param {RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto} [rHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateHarmonizationConfiguration(id: string, rHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto?: RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateHarmonizationConfiguration(id, rHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HarmonizationConfigurationApi - factory interface
 * @export
 */
export const HarmonizationConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HarmonizationConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @summary CreateHarmonizationConfiguration
         * @param {string} customerId Format - uuid.
         * @param {RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto} [rHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHarmonizationConfiguration(customerId: string, rHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto?: RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto, options?: any): AxiosPromise<RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationDto> {
            return localVarFp.createHarmonizationConfiguration(customerId, rHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DeleteHarmonizationConfiguration
         * @param {string} id Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHarmonizationConfiguration(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteHarmonizationConfiguration(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetHarmonizationConfiguration
         * @param {string} id Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHarmonizationConfiguration(id: string, options?: any): AxiosPromise<RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationDto> {
            return localVarFp.getHarmonizationConfiguration(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetHarmonizedFileTypes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHarmonizedFileTypes(options?: any): AxiosPromise<Array<RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizedFileStructureDto>> {
            return localVarFp.getHarmonizedFileTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ListHarmonizationConfiguration
         * @param {string} customerId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHarmonizationConfiguration(customerId: string, options?: any): AxiosPromise<Array<RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationListDto>> {
            return localVarFp.listHarmonizationConfiguration(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UpdateHarmonizationConfiguration
         * @param {string} id Format - uuid.
         * @param {RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto} [rHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHarmonizationConfiguration(id: string, rHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto?: RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto, options?: any): AxiosPromise<RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationDto> {
            return localVarFp.updateHarmonizationConfiguration(id, rHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HarmonizationConfigurationApi - object-oriented interface
 * @export
 * @class HarmonizationConfigurationApi
 * @extends {BaseAPI}
 */
export class HarmonizationConfigurationApi extends BaseAPI {
    /**
     * 
     * @summary CreateHarmonizationConfiguration
     * @param {string} customerId Format - uuid.
     * @param {RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto} [rHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HarmonizationConfigurationApi
     */
    public createHarmonizationConfiguration(customerId: string, rHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto?: RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto, options?: AxiosRequestConfig) {
        return HarmonizationConfigurationApiFp(this.configuration).createHarmonizationConfiguration(customerId, rHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DeleteHarmonizationConfiguration
     * @param {string} id Format - uuid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HarmonizationConfigurationApi
     */
    public deleteHarmonizationConfiguration(id: string, options?: AxiosRequestConfig) {
        return HarmonizationConfigurationApiFp(this.configuration).deleteHarmonizationConfiguration(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetHarmonizationConfiguration
     * @param {string} id Format - uuid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HarmonizationConfigurationApi
     */
    public getHarmonizationConfiguration(id: string, options?: AxiosRequestConfig) {
        return HarmonizationConfigurationApiFp(this.configuration).getHarmonizationConfiguration(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetHarmonizedFileTypes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HarmonizationConfigurationApi
     */
    public getHarmonizedFileTypes(options?: AxiosRequestConfig) {
        return HarmonizationConfigurationApiFp(this.configuration).getHarmonizedFileTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ListHarmonizationConfiguration
     * @param {string} customerId Format - uuid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HarmonizationConfigurationApi
     */
    public listHarmonizationConfiguration(customerId: string, options?: AxiosRequestConfig) {
        return HarmonizationConfigurationApiFp(this.configuration).listHarmonizationConfiguration(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UpdateHarmonizationConfiguration
     * @param {string} id Format - uuid.
     * @param {RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto} [rHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HarmonizationConfigurationApi
     */
    public updateHarmonizationConfiguration(id: string, rHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto?: RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto, options?: AxiosRequestConfig) {
        return HarmonizationConfigurationApiFp(this.configuration).updateHarmonizationConfiguration(id, rHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizationConfigurationCreateOrUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HarmonizedFilesApi - axios parameter creator
 * @export
 */
export const HarmonizedFilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary GetHarmonizedFile
         * @param {string} id Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHarmonizedFile: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getHarmonizedFile', 'id', id)
            const localVarPath = `/harmonizedfiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HarmonizedFilesApi - functional programming interface
 * @export
 */
export const HarmonizedFilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HarmonizedFilesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary GetHarmonizedFile
         * @param {string} id Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHarmonizedFile(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMFileIngestionServiceV1HarmonizationHarmonizedFileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHarmonizedFile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HarmonizedFilesApi - factory interface
 * @export
 */
export const HarmonizedFilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HarmonizedFilesApiFp(configuration)
    return {
        /**
         * 
         * @summary GetHarmonizedFile
         * @param {string} id Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHarmonizedFile(id: string, options?: any): AxiosPromise<RHIMFileIngestionServiceV1HarmonizationHarmonizedFileDto> {
            return localVarFp.getHarmonizedFile(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HarmonizedFilesApi - object-oriented interface
 * @export
 * @class HarmonizedFilesApi
 * @extends {BaseAPI}
 */
export class HarmonizedFilesApi extends BaseAPI {
    /**
     * 
     * @summary GetHarmonizedFile
     * @param {string} id Format - uuid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HarmonizedFilesApi
     */
    public getHarmonizedFile(id: string, options?: AxiosRequestConfig) {
        return HarmonizedFilesApiFp(this.configuration).getHarmonizedFile(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ParserApi - axios parameter creator
 * @export
 */
export const ParserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary GetParserConfigurationSchema
         * @param {string} parserType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParserConfigurationSchema: async (parserType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parserType' is not null or undefined
            assertParamExists('getParserConfigurationSchema', 'parserType', parserType)
            const localVarPath = `/parser/{parserType}`
                .replace(`{${"parserType"}}`, encodeURIComponent(String(parserType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ListParser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listParser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParserApi - functional programming interface
 * @export
 */
export const ParserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary GetParserConfigurationSchema
         * @param {string} parserType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParserConfigurationSchema(parserType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMFileIngestionServiceV1ParserParserSchemaDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParserConfigurationSchema(parserType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ListParser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listParser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMFileIngestionServiceV1ParserParserListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listParser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ParserApi - factory interface
 * @export
 */
export const ParserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParserApiFp(configuration)
    return {
        /**
         * 
         * @summary GetParserConfigurationSchema
         * @param {string} parserType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParserConfigurationSchema(parserType: string, options?: any): AxiosPromise<RHIMFileIngestionServiceV1ParserParserSchemaDto> {
            return localVarFp.getParserConfigurationSchema(parserType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ListParser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listParser(options?: any): AxiosPromise<Array<RHIMFileIngestionServiceV1ParserParserListDto>> {
            return localVarFp.listParser(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParserApi - object-oriented interface
 * @export
 * @class ParserApi
 * @extends {BaseAPI}
 */
export class ParserApi extends BaseAPI {
    /**
     * 
     * @summary GetParserConfigurationSchema
     * @param {string} parserType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParserApi
     */
    public getParserConfigurationSchema(parserType: string, options?: AxiosRequestConfig) {
        return ParserApiFp(this.configuration).getParserConfigurationSchema(parserType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ListParser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParserApi
     */
    public listParser(options?: AxiosRequestConfig) {
        return ParserApiFp(this.configuration).listParser(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ParserConfigurationApi - axios parameter creator
 * @export
 */
export const ParserConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary GetParserConfiguration
         * @param {string} id Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParserConfiguration: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getParserConfiguration', 'id', id)
            const localVarPath = `/customerfiletypeconfiguration/{id}/parserconfiguration`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary SetParserConfiguration
         * @param {string} id Format - uuid.
         * @param {RHIMFileIngestionServiceV1ParserConfigurationParserConfigurationDto} [rHIMFileIngestionServiceV1ParserConfigurationParserConfigurationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setParserConfiguration: async (id: string, rHIMFileIngestionServiceV1ParserConfigurationParserConfigurationDto?: RHIMFileIngestionServiceV1ParserConfigurationParserConfigurationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setParserConfiguration', 'id', id)
            const localVarPath = `/customerfiletypeconfiguration/{id}/parserconfiguration`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMFileIngestionServiceV1ParserConfigurationParserConfigurationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParserConfigurationApi - functional programming interface
 * @export
 */
export const ParserConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParserConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary GetParserConfiguration
         * @param {string} id Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParserConfiguration(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMFileIngestionServiceV1ParserConfigurationParserConfigurationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParserConfiguration(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary SetParserConfiguration
         * @param {string} id Format - uuid.
         * @param {RHIMFileIngestionServiceV1ParserConfigurationParserConfigurationDto} [rHIMFileIngestionServiceV1ParserConfigurationParserConfigurationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setParserConfiguration(id: string, rHIMFileIngestionServiceV1ParserConfigurationParserConfigurationDto?: RHIMFileIngestionServiceV1ParserConfigurationParserConfigurationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMFileIngestionServiceV1ParserConfigurationParserConfigurationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setParserConfiguration(id, rHIMFileIngestionServiceV1ParserConfigurationParserConfigurationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ParserConfigurationApi - factory interface
 * @export
 */
export const ParserConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParserConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @summary GetParserConfiguration
         * @param {string} id Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParserConfiguration(id: string, options?: any): AxiosPromise<RHIMFileIngestionServiceV1ParserConfigurationParserConfigurationDto> {
            return localVarFp.getParserConfiguration(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary SetParserConfiguration
         * @param {string} id Format - uuid.
         * @param {RHIMFileIngestionServiceV1ParserConfigurationParserConfigurationDto} [rHIMFileIngestionServiceV1ParserConfigurationParserConfigurationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setParserConfiguration(id: string, rHIMFileIngestionServiceV1ParserConfigurationParserConfigurationDto?: RHIMFileIngestionServiceV1ParserConfigurationParserConfigurationDto, options?: any): AxiosPromise<RHIMFileIngestionServiceV1ParserConfigurationParserConfigurationDto> {
            return localVarFp.setParserConfiguration(id, rHIMFileIngestionServiceV1ParserConfigurationParserConfigurationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParserConfigurationApi - object-oriented interface
 * @export
 * @class ParserConfigurationApi
 * @extends {BaseAPI}
 */
export class ParserConfigurationApi extends BaseAPI {
    /**
     * 
     * @summary GetParserConfiguration
     * @param {string} id Format - uuid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParserConfigurationApi
     */
    public getParserConfiguration(id: string, options?: AxiosRequestConfig) {
        return ParserConfigurationApiFp(this.configuration).getParserConfiguration(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary SetParserConfiguration
     * @param {string} id Format - uuid.
     * @param {RHIMFileIngestionServiceV1ParserConfigurationParserConfigurationDto} [rHIMFileIngestionServiceV1ParserConfigurationParserConfigurationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParserConfigurationApi
     */
    public setParserConfiguration(id: string, rHIMFileIngestionServiceV1ParserConfigurationParserConfigurationDto?: RHIMFileIngestionServiceV1ParserConfigurationParserConfigurationDto, options?: AxiosRequestConfig) {
        return ParserConfigurationApiFp(this.configuration).setParserConfiguration(id, rHIMFileIngestionServiceV1ParserConfigurationParserConfigurationDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UploadApi - axios parameter creator
 * @export
 */
export const UploadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Uploads a file to the hmd transfer storage of the customer.  The content has to be sent as multipart/form-data.  All the metadata has to be send first. Each metadata value has to be send in its own part,  where the name of a part is its key and the string content is its value.  The last part has to be the file. The fileName has to be set on the file part.  There always has to be a file part.  Also the order of the parts is important. Only the metadata before the first file is used.  If multiple files are send the first one is used.  There is a special metadata field called RHIM.FileIngestionService.V1.Upload.UploadController.TotalFileSizeKeyName which is used to  check if all of the content of the file is in this request or if the remaining content is send in chunks.  To upload these chunks the endpoint \"part/{fileIngressId}\" has to be used.
         * @summary Uploads a file to the hmd transfer storage of the customer.  The content has to be sent as multipart/form-data.  All the metadata has to be send first. Each metadata value has to be send in its own part,  where the name of a part is its key and the string content is its value.  The last part has to 
         * @param {string} customerId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploadCustomerid: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('postUploadCustomerid', 'customerId', customerId)
            const localVarPath = `/Upload/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint needs to be used if the upload happens in chunks.  The first chunk and metadata needs to be send to the other endpoint.  This endpoint then handles the chunks. The chunks are also send as multipart data request but  without any metadata and only a file section.
         * @summary This endpoint needs to be used if the upload happens in chunks.  The first chunk and metadata needs to be send to the other endpoint.  This endpoint then handles the chunks. The chunks are also send as multipart data request but  without any metadata and only a file section.
         * @param {string} fileIngressId Format - uuid. The file id to which this chunk should be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploadPartFileingressid: async (fileIngressId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileIngressId' is not null or undefined
            assertParamExists('postUploadPartFileingressid', 'fileIngressId', fileIngressId)
            const localVarPath = `/Upload/part/{fileIngressId}`
                .replace(`{${"fileIngressId"}}`, encodeURIComponent(String(fileIngressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UploadApi - functional programming interface
 * @export
 */
export const UploadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UploadApiAxiosParamCreator(configuration)
    return {
        /**
         * Uploads a file to the hmd transfer storage of the customer.  The content has to be sent as multipart/form-data.  All the metadata has to be send first. Each metadata value has to be send in its own part,  where the name of a part is its key and the string content is its value.  The last part has to be the file. The fileName has to be set on the file part.  There always has to be a file part.  Also the order of the parts is important. Only the metadata before the first file is used.  If multiple files are send the first one is used.  There is a special metadata field called RHIM.FileIngestionService.V1.Upload.UploadController.TotalFileSizeKeyName which is used to  check if all of the content of the file is in this request or if the remaining content is send in chunks.  To upload these chunks the endpoint \"part/{fileIngressId}\" has to be used.
         * @summary Uploads a file to the hmd transfer storage of the customer.  The content has to be sent as multipart/form-data.  All the metadata has to be send first. Each metadata value has to be send in its own part,  where the name of a part is its key and the string content is its value.  The last part has to 
         * @param {string} customerId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUploadCustomerid(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMFileIngestionServiceV1UploadUploadResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUploadCustomerid(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint needs to be used if the upload happens in chunks.  The first chunk and metadata needs to be send to the other endpoint.  This endpoint then handles the chunks. The chunks are also send as multipart data request but  without any metadata and only a file section.
         * @summary This endpoint needs to be used if the upload happens in chunks.  The first chunk and metadata needs to be send to the other endpoint.  This endpoint then handles the chunks. The chunks are also send as multipart data request but  without any metadata and only a file section.
         * @param {string} fileIngressId Format - uuid. The file id to which this chunk should be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUploadPartFileingressid(fileIngressId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUploadPartFileingressid(fileIngressId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UploadApi - factory interface
 * @export
 */
export const UploadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UploadApiFp(configuration)
    return {
        /**
         * Uploads a file to the hmd transfer storage of the customer.  The content has to be sent as multipart/form-data.  All the metadata has to be send first. Each metadata value has to be send in its own part,  where the name of a part is its key and the string content is its value.  The last part has to be the file. The fileName has to be set on the file part.  There always has to be a file part.  Also the order of the parts is important. Only the metadata before the first file is used.  If multiple files are send the first one is used.  There is a special metadata field called RHIM.FileIngestionService.V1.Upload.UploadController.TotalFileSizeKeyName which is used to  check if all of the content of the file is in this request or if the remaining content is send in chunks.  To upload these chunks the endpoint \"part/{fileIngressId}\" has to be used.
         * @summary Uploads a file to the hmd transfer storage of the customer.  The content has to be sent as multipart/form-data.  All the metadata has to be send first. Each metadata value has to be send in its own part,  where the name of a part is its key and the string content is its value.  The last part has to 
         * @param {string} customerId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploadCustomerid(customerId: string, options?: any): AxiosPromise<RHIMFileIngestionServiceV1UploadUploadResponseDto> {
            return localVarFp.postUploadCustomerid(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint needs to be used if the upload happens in chunks.  The first chunk and metadata needs to be send to the other endpoint.  This endpoint then handles the chunks. The chunks are also send as multipart data request but  without any metadata and only a file section.
         * @summary This endpoint needs to be used if the upload happens in chunks.  The first chunk and metadata needs to be send to the other endpoint.  This endpoint then handles the chunks. The chunks are also send as multipart data request but  without any metadata and only a file section.
         * @param {string} fileIngressId Format - uuid. The file id to which this chunk should be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploadPartFileingressid(fileIngressId: string, options?: any): AxiosPromise<void> {
            return localVarFp.postUploadPartFileingressid(fileIngressId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UploadApi - object-oriented interface
 * @export
 * @class UploadApi
 * @extends {BaseAPI}
 */
export class UploadApi extends BaseAPI {
    /**
     * Uploads a file to the hmd transfer storage of the customer.  The content has to be sent as multipart/form-data.  All the metadata has to be send first. Each metadata value has to be send in its own part,  where the name of a part is its key and the string content is its value.  The last part has to be the file. The fileName has to be set on the file part.  There always has to be a file part.  Also the order of the parts is important. Only the metadata before the first file is used.  If multiple files are send the first one is used.  There is a special metadata field called RHIM.FileIngestionService.V1.Upload.UploadController.TotalFileSizeKeyName which is used to  check if all of the content of the file is in this request or if the remaining content is send in chunks.  To upload these chunks the endpoint \"part/{fileIngressId}\" has to be used.
     * @summary Uploads a file to the hmd transfer storage of the customer.  The content has to be sent as multipart/form-data.  All the metadata has to be send first. Each metadata value has to be send in its own part,  where the name of a part is its key and the string content is its value.  The last part has to 
     * @param {string} customerId Format - uuid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public postUploadCustomerid(customerId: string, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).postUploadCustomerid(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint needs to be used if the upload happens in chunks.  The first chunk and metadata needs to be send to the other endpoint.  This endpoint then handles the chunks. The chunks are also send as multipart data request but  without any metadata and only a file section.
     * @summary This endpoint needs to be used if the upload happens in chunks.  The first chunk and metadata needs to be send to the other endpoint.  This endpoint then handles the chunks. The chunks are also send as multipart data request but  without any metadata and only a file section.
     * @param {string} fileIngressId Format - uuid. The file id to which this chunk should be added
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public postUploadPartFileingressid(fileIngressId: string, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).postUploadPartFileingressid(fileIngressId, options).then((request) => request(this.axios, this.basePath));
    }
}



