import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const ChartExpandIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g clipPath="url(#bnzt9n1f2a)" fill={fill}>
        <path d="M13 8.006a.961.961 0 0 0-.318-.707 1.137 1.137 0 0 0-.769-.293H4.087c-.288 0-.565.105-.769.293A.961.961 0 0 0 3 8.006c0 .265.115.52.318.707.204.188.48.293.769.293h7.826c.288 0 .565-.106.769-.293A.961.961 0 0 0 13 8.006zM8 10.004a.985.985 0 0 0-.663.25.82.82 0 0 0-.275.607v1.541c0 .046-.02.09-.055.122a.197.197 0 0 1-.133.05H5.75a.802.802 0 0 0-.416.117.702.702 0 0 0-.274.307.63.63 0 0 0-.045.396.668.668 0 0 0 .202.351l2.251 2.056c.07.064.153.115.244.15a.812.812 0 0 0 .822-.15l2.25-2.056a.668.668 0 0 0 .204-.351.63.63 0 0 0-.046-.396.702.702 0 0 0-.274-.307.802.802 0 0 0-.416-.117H9.126a.197.197 0 0 1-.133-.05.164.164 0 0 1-.055-.122v-1.541a.82.82 0 0 0-.275-.606.985.985 0 0 0-.663-.251zM8.533.203a.758.758 0 0 0-.244-.15.812.812 0 0 0-.822.15l-2.25 2.055a.668.668 0 0 0-.203.352.63.63 0 0 0 .045.395.702.702 0 0 0 .274.308.801.801 0 0 0 .416.117h1.125c.05 0 .098.018.133.05a.164.164 0 0 1 .055.122v1.541a.82.82 0 0 0 .275.606C7.513 5.91 7.75 6 8 6c.249 0 .487-.09.663-.25a.82.82 0 0 0 .275-.607V3.602c0-.046.02-.09.055-.122a.197.197 0 0 1 .133-.05h1.125a.801.801 0 0 0 .416-.117.702.702 0 0 0 .274-.308.63.63 0 0 0 .045-.395.669.669 0 0 0-.202-.352L8.533.203z" />
      </g>
      <defs>
        <clipPath id="bnzt9n1f2a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

ChartExpandIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
