import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const LoaderInvertedIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill={fill} fillRule="evenodd">
        <path
          d="M2.1 12.204a.799.799 0 0 1 1.13.062A6.389 6.389 0 0 1 1.6 8C1.6 4.47 4.471 1.6 8 1.6A.8.8 0 1 1 8 0C3.589 0 0 3.589 0 8c0 1.972.723 3.866 2.038 5.334a.8.8 0 0 1 .062-1.13"
          fill="#506676"
        />
        <path
          d="M8 16a8.01 8.01 0 0 1-5.962-2.666.8.8 0 1 1 1.192-1.068A6.41 6.41 0 0 0 8 14.4c3.529 0 6.4-2.871 6.4-6.4 0-3.529-2.871-6.4-6.4-6.4A.8.8 0 1 1 8 0c4.411 0 8 3.589 8 8s-3.589 8-8 8"
          fill="#1DC1E1"
        />
      </g>
    </svg>
  );
};

LoaderInvertedIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
