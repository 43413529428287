import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  backgroundColor?: Property.Color;
  foregroundColor?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const AppStateAlertIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  backgroundColor,
  foregroundColor,
  size,
  title,
  role,
  ...rest
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path
              fill={backgroundColor}
              d="M29.022 24.423l-10.909-19.2C17.683 4.468 16.876 4 16 4c-.876 0-1.684.468-2.114 1.223l-10.909 19.2c-.421.744-.413 1.653.021 2.389C3.433 27.548 4.23 28 5.09 28h21.82c.86 0 1.656-.452 2.091-1.188.435-.736.443-1.645.021-2.39z"
              transform="translate(-1080 -483) translate(1080 483)"
            />
            <path
              fill={foregroundColor}
              d="M16 21.333c1.105 0 2 .895 2 2 0 1.106-.895 2-2 2s-2-.894-2-2c0-1.105.895-2 2-2zm0-10.666c.736 0 1.333.597 1.333 1.333v6.667c0 .736-.597 1.333-1.333 1.333s-1.333-.597-1.333-1.333V12c0-.736.597-1.333 1.333-1.333z"
              transform="translate(-1080 -483) translate(1080 483)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

AppStateAlertIcon.defaultProps = {
  size: '32',
  backgroundColor: '#EB3785',
  foregroundColor: settings.colors.Monochromatic.Black,
};
