import { settings } from '@rhim/design';
import { BurgerIcon } from '@rhim/icons/16';
import { withProps } from '@rhim/icons/utils';
import { Dropdown as AntdDropdown, DropDownProps } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';

interface Props extends DropDownProps {
  children: React.ReactNode;
}

const Dropdown: FC<React.PropsWithChildren<Props>> = ({ children, ...props }) => {
  const handleDropdownTriggerClicked: React.MouseEventHandler<HTMLDivElement> = (event) => {
    // used if the dropdown trigger (e.g a sandwich-icon button) happens to sit on another
    // element that also has a onClick handler itself - in this case we do not want the click
    // on the trigger to propagate and also click its parent
    event.stopPropagation();
  };

  return (
    <div onClick={handleDropdownTriggerClicked}>
      <StyleSheet />
      <AntdDropdown {...props}>{children}</AntdDropdown>
    </div>
  );
};

Dropdown.whyDidYouRender = true;
export default React.memo(Dropdown);

const StyleSheet = settings.globals.patchedCreateGlobalStyle`
  &.ant-dropdown ul.ant-dropdown-menu {
    padding: 0;
    border-radius: 3px;

    --color: ${settings.colors.Primary.Grey_1};

    li.ant-dropdown-menu-item {
      padding: 0 ${settings.Spacing.Spacing_300} 0 ${settings.Spacing.Spacing_200};
      color: var(--color);
      background-color: ${settings.colors.Primary.Blue_9};

      --height: 40px;

      height: var(--height);
      line-height: var(--height);

      --borderRadius: 3px;

      &:not(:last-child) {
        border-bottom: solid 1px ${settings.colors.Primary.Grey_8};
      }

      &:first-child {
        border-top-left-radius: var(--borderRadius);
        border-top-right-radius: var(--borderRadius);
      }

      &:last-child {
        border-bottom-left-radius: var(--borderRadius);
        border-bottom-right-radius: var(--borderRadius);
      }

      &.ant-dropdown-menu-item-disabled {
        color: ${settings.colors.Primary.Grey_4};
        background-color: ${settings.colors.Primary.Grey_2};
      }

      &:hover:not(.ant-dropdown-menu-item-disabled) {
        background-color: ${settings.colors.Primary.Blue_8};

        &:active {
          background-color: ${settings.colors.Primary.Blue_7};
        }
      }
    }
  }
`;

/**
 * "Extracting" the interface and saving it under another name in order to avoid the TS4023 error.
 *
 * @see https://github.com/ajv-validator/ajv/issues/1814#issuecomment-966988166
 */
interface IconProps extends React.ComponentPropsWithoutRef<typeof BurgerIcon> {}

const Icon = withProps(BurgerIcon, { fill: 'currentColor' });

export const StyledBurgerIcon = styled<React.FunctionComponent<IconProps>>(Icon)`
  box-sizing: content-box;
  padding: ${settings.Spacing.Spacing_100};
  color: ${settings.colors.Primary.Blue_9};

  &:hover {
    color: ${settings.colors.Primary.Blue_8};
  }

  &:active {
    color: ${settings.colors.Primary.Blue_7};
  }
`;

export const Trigger = styled.button`
  all: unset;
`;
