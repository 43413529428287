import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const PlusTinyIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <g>
              <g>
                <path
                  d="M9 7V4c0-.553-.448-1-1-1s-1 .447-1 1v3H4c-.552 0-1 .447-1 1 0 .553.448 1 1 1h3v3c0 .553.448 1 1 1s1-.447 1-1V9h3c.552 0 1-.447 1-1 0-.553-.448-1-1-1H9z"
                  transform="translate(-441 -517) translate(298 505) translate(135) translate(8 12)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

PlusTinyIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
