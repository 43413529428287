import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const HelmetIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      role={role}
      {...rest}
    >
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="nonzero"
        d="M22.482 12.911H21.6l-.01-2.657a8.833 8.833 0 00-3.587-6.94v4.798c0 .659-.54 1.2-1.2 1.2a1.237 1.237 0 01-1.2-1.2l-.024-6.126c0-.694.079-1.072-.68-1.072h-5.68c-.694 0-.815.442-.815 1.2v5.998l.001.04c0 .637-.524 1.161-1.161 1.161h-.04a1.25 1.25 0 01-1.2-1.2V3.408a8.435 8.435 0 00-3.599 6.725v2.776h-.883a1.643 1.643 0 00-1.516 1.76c0 1.262 5.364 2.335 11.99 2.335s11.99-1.01 11.99-2.335a1.559 1.559 0 00-1.503-1.759zm-2.723 5.357a50.096 50.096 0 01-7.51.505h-.316a51.407 51.407 0 01-7.51-.505.567.567 0 00-.631.505c-.02.729.087 1.455.315 2.146.127.464.318.908.567 1.32.176.248.416.443.695.567a21.75 21.75 0 004.607.126c.209-.03.405-.116.567-.252l.064-.064c.271-.284.505-.602.695-.946.354-.66.63-1.358.82-2.083.151.737.429 1.44.82 2.083.19.344.424.662.696.946l.063.064a.933.933 0 00.568.252c1.535.12 3.08.078 4.607-.126.19 0 .441-.19.694-.568a3.65 3.65 0 00.568-1.32c.197-.698.303-1.419.315-2.145a.685.685 0 00-.694-.502v-.003z"
      />
    </svg>
  );
};

HelmetIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
