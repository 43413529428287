import {
  RHIMAPOReportingWearManagementApiV1ModelsCircleDto,
  RHIMAPOReportingWearManagementApiV1ModelsCrkCylinderDto,
  RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDtoArea,
} from '@rhim/rest/wearManagement';

export function isCrkVesselArea(
  area: RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDtoArea
): area is RHIMAPOReportingWearManagementApiV1ModelsCrkCylinderDto {
  return 'runningMeter' in area;
}

export function isSteelVesselArea(
  area: RHIMAPOReportingWearManagementApiV1ModelsVesselFunctionalProductCreateRequestDtoArea
): area is RHIMAPOReportingWearManagementApiV1ModelsCircleDto {
  return 'circleRadius' in area;
}
