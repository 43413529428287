import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { useMemoCompare } from '@rhim/react';
import { RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel } from '@rhim/rest/operatorDisplay';
import { RHIMContractsMaintenanceTaskSeverity } from '@rhim/rest/wearManagement/api';
import { mainGraphOperatorDisplay, regionTitleOperatorDisplay } from '@rhim/test-ids';
import { isDefined, last } from '@rhim/utils';
import React from 'react';
import styled from 'styled-components';

import { WmsPoint } from '../../../apo';
import { useDeferredRenderingWithProgressIndicator } from '../../hooks';
import Legend from '../PredictionGraph/Legend';
import { calculateCurvePoints, getTargetPoint } from './dataProcessor';
import WmsGraph from './WmsGraph';
import WmsHeader from './WmsHeader';

interface Props {
  region: RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel;
  parentWidth: number;
  targetLifeTime: number;
  lastHeat: number;
  expectedHeats: number;
  lastTotalHeat?: number;
  compareMode?: boolean;
  comparedRegion?: RHIMOperatorDisplayServiceV1ModelsOperatorDataViewRegionModel;
  enableKPIsInWms: boolean;
}

const WmsDisplayContainer: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  expectedHeats,
  region,
  parentWidth,
  targetLifeTime,
  lastHeat,
  lastTotalHeat,
  comparedRegion,
  compareMode,
  enableKPIsInWms,
}: Props) => {
  const { renderingInProgressIndicator, deferredContainerSize } = useDeferredRenderingWithProgressIndicator([parentWidth, 0]);
  const deferredParentWidth = deferredContainerSize[0];

  const wmsWidth: number = React.useMemo(() => {
    if (deferredParentWidth < 1600) {
      return deferredParentWidth * 0.75 - 60;
    }

    if (deferredParentWidth < 1700 && deferredParentWidth >= 1600) {
      return deferredParentWidth * 0.79 - 60;
    }

    if (deferredParentWidth < 1800) {
      return deferredParentWidth * 0.8 - 60;
    }

    return 587;
  }, [deferredParentWidth]);

  const wmsZoomWidth: number = React.useMemo(() => {
    const containerWidth = deferredParentWidth * 0.49;

    if (deferredParentWidth <= 1900 && deferredParentWidth > 1800) {
      return containerWidth * 0.28 - 60;
    }

    return window.scrollbars.visible ? 230 : 241;
  }, [deferredParentWidth]);

  const containerWidth = React.useMemo(() => {
    return parentWidth < 1800 ? '99%' : '49.5%';
  }, [parentWidth]);

  const maintenanceTasks = useMemoCompare(region.maintenanceTasks, (previous, next) => JSON.stringify(previous) === JSON.stringify(next));

  const { t } = i18nReact.useTranslation(['operator-display']);
  const currentMin = region.measuredPoints.length ? last(region.measuredPoints as NonEmptyArray<WmsPoint>) : undefined;

  const targetPoint =
    isDefined(currentMin) && isDefined(region.targetLiningThicknessCurve)
      ? getTargetPoint(calculateCurvePoints(targetLifeTime, region.measuredPoints, region.targetLiningThicknessCurve), currentMin)
      : undefined;
  const isCritical = maintenanceTasks.length !== 0 ? (maintenanceTasks[0] || {}).severity === RHIMContractsMaintenanceTaskSeverity.High : false;

  return (
    <Container
      critical={compareMode === true ? false : isCritical}
      width={containerWidth}
      compareMode={compareMode ?? false}
      data-test-id={`regionContainer_${region.name}`}
    >
      <Title data-test-id={regionTitleOperatorDisplay}>{region.name}</Title>
      <div style={{ display: 'inline-flex' }}>
        <StyledDiv>
          {compareMode !== true && (
            <WmsHeader
              enableKPIsInWms={enableKPIsInWms}
              width={wmsWidth}
              target={isDefined(targetPoint) ? Math.round(targetPoint.liningThickness) : undefined}
              maintenancetasks={maintenanceTasks}
              currentMin={isDefined(currentMin) ? Math.round(currentMin.liningThickness) : undefined}
              wear={region.wearSpeed}
              brickSuppliers={region.brickSupplier}
              compareMode={compareMode}
            />
          )}
          <GraphContainer width={expectedHeats <= 600 || compareMode === true ? wmsWidth + wmsZoomWidth : wmsWidth} data-test-id={mainGraphOperatorDisplay}>
            <Header>
              <Legend>{t('operator-display:legend.title')}</Legend>
            </Header>
            <WmsGraph
              compareMode={compareMode}
              comparedData={comparedRegion}
              data={region}
              hasZoom={expectedHeats > 600 && compareMode !== true}
              width={expectedHeats <= 600 || compareMode === true ? wmsWidth + wmsZoomWidth + 50 : wmsWidth} // for no zoomed graph, we need to add 50px more for the margin.
              height={130}
              targetLifeTime={targetLifeTime}
              lastHeat={lastHeat}
              lastTotalHeat={lastTotalHeat}
            />
          </GraphContainer>
        </StyledDiv>
        {expectedHeats > 600 && compareMode !== true && (
          <>
            <VerticalLine />
            <WmsGraph
              comparedData={comparedRegion}
              lastTotalHeat={lastTotalHeat}
              data={region}
              width={wmsZoomWidth}
              height={250}
              zoomed={true}
              targetLifeTime={targetLifeTime}
              lastHeat={lastHeat}
            />
          </>
        )}
      </div>
      {renderingInProgressIndicator}
    </Container>
  );
};

const GraphContainer = styled.div<{ width: number }>`
  margin-top: ${settings.Spacing.Spacing_200};
  margin-left: ${settings.Spacing.Spacing_200};
  width: ${(props) => props.width}px;
  display: grid;
  align-items: end;
`;

const Title = styled.div`
  color: ${settings.colors.Primary.Grey_8};
  height: 38px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-family: ${settings.typography.FontFamily.Bold};
  font-size: ${settings.typography.FontSize.Medium};
  white-space: nowrap;
  border-bottom: 1px solid ${settings.colors.Primary.Grey_2};
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const VerticalLine = styled.div`
  width: 1px;
  border: solid 0.5px ${settings.colors.Primary.Grey_3};
  margin: 0 8px 0 12px;
  height: 270px;
`;

const Header = styled.div`
  padding-left: ${settings.Spacing.Spacing_50};
`;

const Container = styled.div<{ critical: boolean; width: string; compareMode: boolean }>`
  cursor: pointer;
  position: relative;
  display: grid;
  width: ${(props) => props.width};
  height: ${(props) => (props.compareMode ? 280 : 345)}px;
  margin-left: 8px;
  margin-bottom: ${settings.Spacing.Spacing_50};
  padding: 12px 16px;
  border-radius: 3px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.05);
  border: ${(props) => (props.critical ? `solid 2px ${settings.colors.Operational.State_Alert_Red_2}` : `solid 1px ${settings.colors.Primary.Grey_2}`)};
  background-color: ${settings.colors.Monochromatic.White};

  &:last-child {
    margin-bottom: 8px;
  }
`;

WmsDisplayContainer.whyDidYouRender = true;

export default React.memo(WmsDisplayContainer);
