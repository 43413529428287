import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const HexagonBOFIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill={fill} fillRule="evenodd">
        <path d="M29.03 24.697c.533-.31.97-1.074.97-1.695V8.952c0-.62-.437-1.384-.97-1.694L16.97.233c-.533-.31-1.407-.31-1.94 0L2.97 7.258C2.437 7.568 2 8.331 2 8.953v14.049c0 .621.437 1.384.97 1.695l12.06 7.024c.533.31 1.407.31 1.94 0l12.06-7.024z" />
        <path
          d="M18.751 10.465a1 1 0 0 1 1.409.134l1.611 2.764c.148.179.229.405.229.637v2h1a1 1 0 1 1 0 2h-1v1.235C22 21.907 20.486 24 16 24s-6-2.093-6-4.765V18H9a1 1 0 1 1 0-2h1v-2c0-.227.077-.446.218-.623l1.5-2.765a1 1 0 0 1 1.564 1.246L12 14.35v4.345c.68-.108 1.05-.195 2-.195 1.55 0 3.251.5 4.29.5.95 0 1.03-.087 1.71-.196v-4.445l-1.382-2.487a1 1 0 0 1 .133-1.407zM16 8a1 1 0 0 1 1 1v7a1 1 0 1 1-2 0V9a1 1 0 0 1 1-1z"
          fill="#F5F6F7"
        />
      </g>
    </svg>
  );
};

HexagonBOFIcon.defaultProps = {
  size: '32',
  fill: settings.colors.Primary.Grey_6,
};
