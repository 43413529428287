import { RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto, RHIMFleetOverviewServiceV1ModelsVolumesDashboardDto } from '@rhim/rest/fleetOverview';
import { hasElements, isDefined, last } from '@rhim/utils';
import { useEffect, useMemo, useState } from 'react';

import { DropdownMeasurement } from '../../../components/MeasurementSelectionDropdown/MeasurementSelectionItem';
import { SORT_BY_DECREASING_MEASUREMENT_TAKEN } from './ComparisonPicker';

export function useCompareMeasurements(
  campaignHavingVolumes: RHIMFleetOverviewServiceV1ModelsVolumesDashboardDto | undefined,
  currentMeasurementId: string | undefined
) {
  const [selectedCompareToMeasurementId, setSelectedCompareToMeasurementId] = useState<string>();

  // Measurements we can use to compare to are all available measurements excluding the current measurement
  const measurementsToCompareTo: DropdownMeasurement[] = useMemo(() => {
    if (!isDefined(currentMeasurementId) || !isDefined(campaignHavingVolumes)) {
      return [];
    }
    return campaignHavingVolumes.measurements
      .filter((measurement) => measurement.measurementId !== currentMeasurementId)
      .map((measurement) => ({ id: measurement.measurementId, heat: measurement.heat, measurementTaken: measurement.measurementTaken }));
  }, [currentMeasurementId, campaignHavingVolumes]);

  // Sort measurements by decreasing measurementTaken (most recent measurement will appear first in the list)
  const sortedMeasurementsToCompareTo: DropdownMeasurement[] = useMemo(() => {
    const sorted = [...measurementsToCompareTo];
    sorted.sort(SORT_BY_DECREASING_MEASUREMENT_TAKEN);
    return sorted;
  }, [measurementsToCompareTo]);

  /**
   * Set the oldest measurement as the default selected compare-to measurement
   */
  useEffect(() => {
    if (!hasElements(sortedMeasurementsToCompareTo)) {
      return;
    }
    setSelectedCompareToMeasurementId(last(sortedMeasurementsToCompareTo).id);
  }, [sortedMeasurementsToCompareTo]);

  const selectedCompareToMeasurement: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto | undefined = useMemo(() => {
    if (!isDefined(selectedCompareToMeasurementId) || !isDefined(campaignHavingVolumes) || !hasElements(campaignHavingVolumes.measurements)) {
      return undefined;
    }
    return campaignHavingVolumes.measurements.find((measurement) => measurement.measurementId === selectedCompareToMeasurementId);
  }, [selectedCompareToMeasurementId, campaignHavingVolumes]);

  return {
    sortedMeasurementsToCompareTo,
    selectedCompareToMeasurement,
    setSelectedCompareToMeasurementId,
  };
}
