import { settings } from '@rhim/design';
import { DownloadIcon, PlusIcon, TrashIcon, VesselBOFIcon, VesselEAFIcon, VesselRHIcon, VesselRHLowerIcon } from '@rhim/icons/16';
import { Button, DataTable, getFormattedDate, IconButton, Tooltip } from '@rhim/react';
import { VesselType } from '@rhim/rest';
import { RHIMOperatorDisplayServiceV1ModelsMeasurementDataUploadFileInformationDto } from '@rhim/rest/operatorDisplay';
import { usePrivileges } from '@rhim/sdk/customerManagement';
import {
  addNewMeasurementButtonMeasurementData,
  deleteButtonMeasurementData,
  downloadButtonMeasurementData,
  headerMeasurementData,
  tableContainerMeasurementData,
} from '@rhim/test-ids';
import { assert, isDefined } from '@rhim/utils';
import { useMutation } from '@tanstack/react-query';
// eslint-disable-next-line no-restricted-imports
import { ConfigProvider } from 'antd';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { API } from '../../api/operatorDisplay';
import AppContext from '../../app/AppContext';
import ErrorPanel from '../../components/ErrorPanel/ErrorPanel';
import { Invariants } from '../../errors';
import { useMeasurementUploadFiles } from '../../hooks';
import { PRIVILEGES } from '../../utilities';
import { queryClient } from '../../utilities/queryClient';
import { DeleteWarningModal } from './DeleteWarningModal';
import Pagination from './Pagination';

interface Props {
  customerId: string;
}
const MeasurementUploads = ({ customerId }: Props) => {
  const [page, setPage] = React.useState(1);
  const [skip, setSkip] = React.useState(0);
  const { data, isLoading } = useMeasurementUploadFiles(customerId, 10, skip, {
    configuration: {
      suspense: false,
      useErrorBoundary: true,
    },
  });

  const { t } = useTranslation(['ingress']);
  const [fileId, setFileId] = React.useState<string | undefined>(undefined);
  const { selectedCustomer, user } = useContext(AppContext);
  const privileges = usePrivileges(user, selectedCustomer);
  const hasFullAccess = privileges.global.has(PRIVILEGES.ManageUploadedFiles) || privileges.customer.has(PRIVILEGES.ManageUploadedFiles);

  const navigate = useNavigate();

  const onPageChange = useCallback(
    (page: number) => {
      setPage(page);
      setSkip((page - 1) * 10);
    },
    [setSkip, setPage]
  );

  const onClick = useCallback(
    (pathname: string) => {
      navigate(pathname);
    },
    [navigate]
  );

  React.useEffect(() => {
    setPage(1);
    setSkip(0);
  }, [customerId, setPage]);

  const deleteMutation = useMutation(
    async (fileId: string) => {
      await API.measurementFiles.deleteMeasurementfilesFileid(fileId).then((response) => response.data);
    },
    {
      onSuccess: async () => {
        setFileId(undefined);
        let currentPage = page;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (data!.itemsCount % 10 === 1 && page !== 1) {
          currentPage = page - 1;
          onPageChange(currentPage);
        }
        await queryClient.invalidateQueries(useMeasurementUploadFiles.getKey(customerId, 10, skip));
      },
      onError: () => {
        setFileId(undefined);
      },
    }
  );

  const downloadMutation = useMutation(
    async (file: RHIMOperatorDisplayServiceV1ModelsMeasurementDataUploadFileInformationDto) => {
      return await API.measurementFiles.getMeasurementfilesFileid(file.fileId).then((response) => response.data);
    },
    {
      onSuccess: (data, file) => {
        const blob = data as unknown as BlobPart;
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${file.fileName}`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode?.removeChild(link);
      },
    }
  );

  return (
    <Container>
      <Header>
        <SHeader data-test-id={headerMeasurementData}>{t('ingress:measurementDataUploads')}</SHeader>
        <SButton
          data-test-id={addNewMeasurementButtonMeasurementData}
          label={t('ingress:measurementDataButton')}
          icon={{ icon: <PlusIcon />, position: 'start' }}
          mode="filled"
          onClick={() => onClick('upload')}
        />
      </Header>
      <TableContainer data-test-id={tableContainerMeasurementData}>
        <ConfigProvider
          renderEmpty={() =>
            !isLoading ? (
              <EmptyDiv>
                <ErrorPanel error={new Error(Invariants.NoMeasurementDataFound)} />
              </EmptyDiv>
            ) : undefined
          }
        >
          <DataTable<RHIMOperatorDisplayServiceV1ModelsMeasurementDataUploadFileInformationDto>
            dataSource={isDefined(data) ? data.items : []}
            pagination={false}
            loading={isLoading}
            size="middle"
            rowKey="id"
            columns={[
              {
                title: t('ingress:table.fileName'),
                key: 'fileName',
                dataIndex: 'fileName',
                width: '350px',
                ellipsis: {
                  showTitle: false,
                },
                render: (name: string) => {
                  return (
                    <Tooltip placement="topLeft" title={name}>
                      {name}
                    </Tooltip>
                  );
                },
              },
              {
                title: t('ingress:table.file'),
                key: 'fileExtension',
                dataIndex: 'fileExtension',
                width: '100px',
              },
              {
                title: t('ingress:table.type'),
                key: 'fileType',
                dataIndex: 'fileType',
                width: '100px',
              },
              {
                title: t('ingress:table.vessel'),
                key: 'vesselName',
                width: '100px',
                dataIndex: 'vesselName',
                render: (vesselName, file) => {
                  let icon = undefined;
                  switch (file.vesselType) {
                    case VesselType.Bof:
                      icon = <VesselBOFIcon fill={settings.colors.Primary.Blue_9} />;
                      break;
                    case VesselType.Rh:
                      icon = <VesselRHIcon fill={settings.colors.Primary.Blue_9} />;
                      break;
                    case VesselType.Rhl:
                      icon = <VesselRHLowerIcon fill={settings.colors.Primary.Blue_9} />;
                      break;
                    case VesselType.Eaf:
                      icon = <VesselEAFIcon fill={settings.colors.Primary.Blue_9} />;
                      break;
                    case VesselType.Ladle:
                      icon = <VesselRHLowerIcon fill={settings.colors.Primary.Blue_9} />;
                      break;
                  }
                  return (
                    <VesselName>
                      <IconContainer>{icon}</IconContainer>
                      <HeaderName>{vesselName}</HeaderName>
                    </VesselName>
                  );
                },
              },
              {
                title: t('ingress:table.campaign'),
                key: 'campaign',
                width: '80px',
                dataIndex: 'campaign',
              },
              {
                title: t('ingress:table.heat'),
                key: 'heat',
                width: '80px',
                dataIndex: 'heat',
              },
              {
                title: t('ingress:table.uploadedOn'),
                key: 'uploadedTime',
                width: '150px',
                dataIndex: 'uploadedTime',
                render: (date: Date.ISO_8601) => {
                  return getFormattedDate({ datetime: date, showTime: true });
                },
              },
              {
                title: '',
                key: 'actions',
                width: '150px',
                align: 'right',
                render: (_, file) => {
                  const id: string = file.fileId;

                  assert.silent(isDefined(id));

                  return (
                    <ButtonGroup>
                      <IconButton
                        data-test-id={downloadButtonMeasurementData}
                        tooltip={t('ingress:download')}
                        isDisabled={!hasFullAccess}
                        onPress={() => {
                          downloadMutation.mutate(file);
                        }}
                        icon={<DownloadIcon />}
                      />
                      <IconButton
                        data-test-id={deleteButtonMeasurementData}
                        isDisabled={!hasFullAccess}
                        icon={<TrashIcon />}
                        onPress={() => {
                          setFileId(id);
                        }}
                      />
                    </ButtonGroup>
                  );
                },
              },
            ]}
          />
        </ConfigProvider>
      </TableContainer>
      <DeleteWarningModal
        visible={isDefined(fileId)}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        onConfirm={() => deleteMutation.mutate(fileId!)}
        onCancel={() => {
          setFileId(undefined);
        }}
      />
      <Pagination
        currentPage={page}
        itemsCount={isLoading ? 0 : isDefined(data) ? data.itemsCount || 10 : 10}
        isDisabled={isLoading}
        onPageChange={onPageChange}
      />
    </Container>
  );
};

const TableContainer = styled.div`
  margin-top: ${settings.Spacing.Spacing_400};

  .ant-table-tbody > tr > td {
    height: 32px;
    padding: 0 16px;
    color: ${settings.colors.Primary.Grey_8};
    font-size: ${settings.typography.FontSize.Small};
    font-family: ${settings.typography.FontFamily.Regular};
    background-color: ${settings.colors.Primary.Grey_1};
    justify-content: center;
    align-items: center;
  }

  .ant-table-thead > tr > th,
  .ant-table-thead > tr > td {
    background: ${settings.colors.Monochromatic.White};
  }
`;

const HeaderName = styled.h4`
  font-size: 14px;
  line-height: 1.25;
  color: ${settings.colors.Primary.Blue_9};
  font-family: ${settings.typography.FontFamily.Bold};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;
const VesselName = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 32px;
`;

const IconContainer = styled(VesselName)`
  margin-right: ${settings.Spacing.Spacing_50};
`;

const ButtonGroup = styled.div`
  display: inline-flex;

  *:not(:last-child) {
    margin-right: 8px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${settings.Spacing.Spacing_400};
`;

const Header = styled.div`
  display: inline-flex;
  flex-direction: row;
  margin-top: ${settings.Spacing.Spacing_400};
`;

const SHeader = styled.h3`
  ${settings.typography.fonts.h_xl};
  flex: 2;
`;

const SButton = styled(Button)`
  align-self: flex-start;
  font-size: ${settings.typography.FontSize.Small};
  margin: auto;
`;

const EmptyDiv = styled.div`
  padding: ${settings.Spacing.Spacing_800} 0;
`;

export default React.memo(MeasurementUploads);
