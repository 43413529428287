import { settings } from '@rhim/design';
import { assert, isDefined } from '@rhim/utils';
import { Tooltip as AntdTooltip } from 'antd';
import type { AbstractTooltipProps, TooltipPlacement } from 'antd/es/tooltip';
import * as React from 'react';
import ReactIs from 'react-is';
import styled, { css } from 'styled-components';

export type { TooltipPlacement } from 'antd/es/tooltip';

/**
 * NOTES ON THE CustomTooltip component SEEN BELOW
 * Antd Tooltip injects the opened tooltip markup in the body of the DOM (A).
 * Since we rely on styled-components - which has scoped css - we cannot access & style that markup (A) in a straightforward way.
 * The earlier solution of rendering a <GlobalStyle /> within the Tooltip was causing a lot of overhead because in e.g the customer dropdown,
 * we would inject our tooltip style in the DOM once per tooltip instance. This became extremely slow.
 *
 * When using e.g :
 * styled(MyComponent)`
     .ant-tooltip: {
      color: red;
     }
 * `
 * styled-components adds a global css rule :
 * .[STYLED-COMPONENTS-AUTO-GENERATED-CLASSNAME] .ant-tooltip {
 *    color: red;
 * }
 *
 * Now what we need is (A) to have this [STYLED-COMPONENTS-AUTO-GENERATED-CLASSNAME] classname.
 * In the solution below, CustomTooltip receives this classname in its "className" prop and adds it to (A) via : overlayClassName={className} thus
 * allowing us to access it and style it.
 */

const TOOLTIP_APPEAR_DELAY = 1; // in secs

type Mode = 'default' | 'small';

export interface TooltipProps extends AbstractTooltipProps {
  className?: string;
  title: string | React.ReactNode;
  placement?: TooltipPlacement;
  isShowing?: boolean;
  mode?: Mode;
  offset?: [number, number];
}

const CustomTooltip: React.FC<TooltipProps> = ({ className, children, ...restProps }) => (
  <AntdTooltip overlayClassName={className} {...restProps}>
    {children}
  </AntdTooltip>
);

export function Tooltip({ title, mode = 'default', offset, placement = 'top', isShowing = true, children, ...props }: TooltipProps) {
  // avoid findDOMNode deprecation warnings
  validateRefForwarding(children);

  if (!isShowing) {
    return children as React.ReactElement;
  }

  return (
    <SCustomTooltip
      mode={mode}
      title={typeof title === 'string' ? <div data-test-id="tooltipContentLabel">{title}</div> : title}
      placement={placement}
      mouseEnterDelay={TOOLTIP_APPEAR_DELAY}
      align={isDefined(offset) ? { offset } : undefined}
      destroyTooltipOnHide={true}
      {...props}
    >
      {children}
    </SCustomTooltip>
  );
}

const ARROW_CONTAINER_SIZE_PX = '16px';
const ARROW_SIZE_PX = '8px';
export const CustomTooltipStyles = css<{ mode: Mode }>`
  &.ant-tooltip {
    /* Required so the tooltip is always on top of the Header strip */
    z-index: ${settings.Elevation.GlobalTooltip};

    .ant-tooltip-inner {
      color: ${settings.colors.Primary.Grey_2};
      font-size: ${(props) => (props.mode === 'default' ? settings.typography.FontSize.Small : settings.typography.FontSize.X_Small)};
      font-family: ${settings.typography.FontFamily.Regular};
      line-height: ${(props) => (props.mode === 'default' ? settings.typography.LineHeight.Line_Height_16 : settings.typography.LineHeight.Line_Height_14)};
      border-radius: 3px;
      background-color: ${settings.colors.Primary.Grey_9};
      box-shadow: 0 0 0 1px ${settings.colors.Primary.Grey_1};
      padding: ${(props) => (props.mode === 'default' ? `8px ${settings.Spacing.Spacing_150}` : `6px 10px`)};
      min-height: ${settings.typography.LineHeight.Line_Height_14};
    }

    &.ant-tooltip-placement-top,
    &.ant-tooltip-placement-topLeft,
    &.ant-tooltip-placement-topRight {
      .ant-tooltip-arrow {
        bottom: -${ARROW_CONTAINER_SIZE_PX} !important;
        transform: translateX(-50%) translateY(0) rotate(180deg) scaleX(1.1);
      }
    }

    &.ant-tooltip-placement-right,
    &.ant-tooltip-placement-rightTop,
    &.ant-tooltip-placement-rightBottom {
      .ant-tooltip-arrow {
        left: -${ARROW_CONTAINER_SIZE_PX} !important;
        transform: translateX(0) translateY(-50%) rotate(-90deg) scaleX(1.1);
      }
    }

    &.ant-tooltip-placement-bottom,
    &.ant-tooltip-placement-bottomLeft,
    &.ant-tooltip-placement-bottomRight {
      .ant-tooltip-arrow {
        top: -${ARROW_CONTAINER_SIZE_PX} !important;
        transform: translateX(-50%) translateY(0) rotate(0) scaleX(1.1);
      }
    }

    &.ant-tooltip-placement-left,
    &.ant-tooltip-placement-leftTop,
    &.ant-tooltip-placement-leftBottom {
      .ant-tooltip-arrow {
        right: -${ARROW_CONTAINER_SIZE_PX} !important;
        transform: translateX(0) translateY(-50%) rotate(90deg) scaleX(1.1);
      }
    }

    .ant-tooltip-arrow {
      &::before {
        display: none;
      }

      &::after {
        background-color: ${settings.colors.Primary.Grey_9};
        box-shadow: 0 0 0 1px ${settings.colors.Primary.Grey_1};
        border-radius: 0;
        width: ${ARROW_SIZE_PX};
        height: ${ARROW_SIZE_PX};
      }
    }
  }
`;

const SCustomTooltip = styled(CustomTooltip)<{ mode: Mode }>`
  ${CustomTooltipStyles}
`;

const validateRefForwarding = (children: string | React.ReactNode) => {
  const childrenType = isDefined(children) && typeof children === 'object' && 'type' in children ? children.type : undefined;
  const isValidChildren = typeof childrenType !== 'function' || ReactIs.isForwardRef(children) || ReactIs.isMemo(children);

  assert(isValidChildren, 'Tooltip requires functional components to support Ref forwarding to avoid findDOMNode errors');
};
