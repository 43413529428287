import { SupportedLanguageIsoCode } from '@rhim/i18n';
import { assert, ColorScales, isDefined } from '@rhim/utils';
import { scaleBand } from 'd3-scale';
import React, { FC, useEffect, useRef, useState } from 'react';
import useResizeAware from 'react-resize-aware';
import styled from 'styled-components';
import { UnitSystem } from 'typings';

import { useLocalization } from '../../hooks';
import { AreaPanelTexts } from '../WallplotAreasPanel/domain';
import AreaHighlightCanvas from './AreaHighlightCanvas';
import AreasMapCanvas, { labelStyleForAreaLabels } from './AreasMapCanvas';
import CellHighlightingCanvas from './CellHighlightingCanvas';
import CellHoveringCanvas from './CellHoveringCanvas';
import CellStatesCanvas from './CellStatesCanvas';
import CellTooltip, { CellTooltipTexts } from './CellTooltip';
import {
  Area,
  AreaLayout,
  CellLayout,
  DataItem,
  getLabelMetrics,
  LabelMetrics,
  LabelStyle,
  Metrics,
  SETTINGS,
  WallplotData,
  WallplotLayout,
  WallplotMargin,
} from './utils';
import WallplotCanvas, {
  labelStyleForHorizontalAxis,
  labelStyleForVerticalAxis,
  LabelWithUnitSetter,
  labelWithUnitSetterForHorizontalAxis,
  labelWithUnitSetterForVerticalAxis,
} from './WallplotCanvas';

export const sortNumbersInAscendingOrder = function (a: number, b: number) {
  return a - b;
};

interface DomainScales {
  xScaleDomain: number[];
  yScaleDomain: number[];
  domainXStep: number;
  domainYStep: number;
  domainXMin: number;
  domainXMax: number;
  domainYMin: number;
  domainYMax: number;
}
export const generateScales = (wallplotData: DataItem[]): DomainScales => {
  const xScale = scaleBand<number>().domain(wallplotData.map((d) => d.angle));
  const yScale = scaleBand<number>().domain(wallplotData.map((d) => d.depth));
  const sortedXScaleDomainValues = [...xScale.domain()].sort(sortNumbersInAscendingOrder);
  const sortedYScaleDomainValues = [...yScale.domain()].sort(sortNumbersInAscendingOrder);

  const xScaleItemZero = sortedXScaleDomainValues[0];
  const xScaleItemOne = sortedXScaleDomainValues[1];
  const yScaleItemZero = sortedYScaleDomainValues[0];
  const yScaleItemOne = sortedYScaleDomainValues[1];

  assert(isDefined(xScaleItemZero) && isDefined(xScaleItemOne), 'Expected the y scale to have at least 2 items');
  assert(isDefined(yScaleItemZero) && isDefined(yScaleItemOne), 'Expected the y scale to have at least 2 items');

  const domainXStep = xScaleItemOne - xScaleItemZero;
  const domainYStep = yScaleItemOne - yScaleItemZero;

  const domainXMin = sortedXScaleDomainValues[0] ?? 0;
  const domainXMax = (sortedXScaleDomainValues[xScale.domain().length - 1] ?? 0) + domainXStep;
  const domainYMin = sortedYScaleDomainValues[0] ?? 0;
  const domainYMax = (sortedYScaleDomainValues[yScale.domain().length - 1] ?? 0) + domainYStep;

  /**
   * In the X axis, the arrangement of values should be :
   * Starting at the center of the axis we have 0 degrees.
   * The values increase as we move to the right reaching 180 at the rightmost edge of the axis.
   * At the leftmost edge of the axis we have again 180
   * (because once we reach the rightmost edge we then "wrap" around and start again from the leftmost edge )
   * Moving to the right towards the center, the values increase until they reach 360 (but we display 0 instead at the center)
   * So the arrangement should be :
   * (left) 180 -> 270 -> 360 or 0 (center) -> 90 -> 180 (right)
   * @param sortedDomainValuesXAxis the domain values of the X axis sorted in ascending order
   * @returns an array of numbers ( the domain values of the X axis rearranged )
   */
  const getDomainValuesWithZeroAtCenter = (sortedDomainValuesXAxis: number[]) => {
    const centerIndex = sortedDomainValuesXAxis.length / 2;
    const valuesFromZeroTo180 = sortedDomainValuesXAxis.slice(centerIndex);
    const valuesFrom180To360 = sortedDomainValuesXAxis.slice(0, centerIndex);
    return [...valuesFromZeroTo180, ...valuesFrom180To360];
  };

  return {
    xScaleDomain: getDomainValuesWithZeroAtCenter(sortedXScaleDomainValues),
    yScaleDomain: sortedYScaleDomainValues,
    domainXStep,
    domainYStep,
    domainXMin,
    domainXMax,
    domainYMin,
    domainYMax,
  };
};

export const calculateDataItemsValuesRange = (wallplotData: DataItem[]): [number, number] => {
  const calculateDataItemValues = (mode: 'calculateMinimum' | 'calculateMaximum') => {
    return wallplotData.reduce(function (prev, curr) {
      if (!isDefined(prev.rbl)) {
        return curr;
      }
      if (!isDefined(curr.rbl)) {
        return prev;
      }
      return mode === 'calculateMinimum' ? (prev.rbl < curr.rbl ? prev : curr) : prev.rbl > curr.rbl ? prev : curr;
    });
  };
  const dataItemWithMinimumValue = calculateDataItemValues('calculateMinimum');
  const dataItemWithMaximumValue = calculateDataItemValues('calculateMaximum');
  assert(isDefined(dataItemWithMinimumValue.rbl), 'All items in the wallplot have null value');
  assert(isDefined(dataItemWithMaximumValue.rbl), 'All items in the wallplot have null value');
  return [dataItemWithMinimumValue.rbl, dataItemWithMaximumValue.rbl];
};

const calculateWallplotMargin = (
  domainScales: DomainScales,
  unitSystem: UnitSystem,
  locale: SupportedLanguageIsoCode,
  isCellHoveringEnabled: boolean,
  areas: Area[]
): WallplotMargin => {
  const margin: WallplotMargin = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  };

  const offscreenCanvas: HTMLCanvasElement = document.createElement('canvas');
  const offscreenCanvasContext = offscreenCanvas.getContext('2d');
  assert(isDefined(offscreenCanvasContext), 'Undefined canvas rendering context');

  type MarginDefinition = (labelMetrics: LabelMetrics) => number;

  const calculateMargin = (
    scaleDomain: number[],
    labelStyle: LabelStyle,
    labelWithUnitSetter: LabelWithUnitSetter,
    marginPropertyName: 'left' | 'bottom' | 'top',
    marginDefinition: MarginDefinition
  ) => {
    offscreenCanvasContext.textAlign = labelStyle.textAlign;
    offscreenCanvasContext.textBaseline = labelStyle.textBaseline;
    offscreenCanvasContext.font = labelStyle.textFont;

    const updateMargin = (domainValue: number) => {
      const labelWithUnit = labelWithUnitSetter(domainValue, unitSystem, locale);
      const labelMetrics = getLabelMetrics(offscreenCanvasContext, labelWithUnit);
      margin[marginPropertyName] = Math.ceil(Math.max(margin[marginPropertyName], marginDefinition(labelMetrics)));
    };

    const domainLength = scaleDomain.length;
    for (let i = 0; i < domainLength; i++) {
      const scaleDomainItem = scaleDomain[i];
      assert(isDefined(scaleDomainItem), 'Undefined scale domain item at index ' + i);
      updateMargin(scaleDomainItem);
      if (isCellHoveringEnabled && marginPropertyName === 'left') {
        // take also into account the width of a label having : domain's value plus half the domain's yStep.
        // this is used when hovering over a cell
        const scaleDomainItemFloatValueHalf = scaleDomainItem + domainScales.domainYStep / 2;
        updateMargin(scaleDomainItemFloatValueHalf);
      }
    }
    if (isCellHoveringEnabled && marginPropertyName === 'left') {
      // add a little extra left margin because the axis tooltips display their value within a rounded bordered rectangle
      margin[marginPropertyName] += SETTINGS.AXIS.ROUNDED_LABEL_PADDING_HORIZONTAL;
    }
  };

  // calculate required left margin
  calculateMargin(
    domainScales.yScaleDomain,
    labelStyleForVerticalAxis,
    labelWithUnitSetterForVerticalAxis,
    'left',
    (labelMetrics) => labelMetrics.width + SETTINGS.AXIS.TICK_LABEL_DISTANCE_PX + SETTINGS.AXIS.TICK_LENGTH_PX
  );
  // calculate required bottom margin
  calculateMargin(
    domainScales.xScaleDomain,
    labelStyleForHorizontalAxis,
    labelWithUnitSetterForHorizontalAxis,
    'bottom',
    (labelMetrics) => SETTINGS.AXIS.TICK_LENGTH_PX + SETTINGS.AXIS.TICK_LABEL_DISTANCE_PX + labelMetrics.height
  );
  // calculate required top margin
  offscreenCanvasContext.textAlign = labelStyleForAreaLabels.textAlign;
  offscreenCanvasContext.textBaseline = labelStyleForAreaLabels.textBaseline;
  offscreenCanvasContext.font = labelStyleForAreaLabels.textFont;
  // calculate required top margin due to the 1st top label in the x-axis
  const firstXAxisDomainItem = domainScales.yScaleDomain[0];
  assert(isDefined(firstXAxisDomainItem), 'Undefined y scale domain item at index 0');
  const labelWithUnit = labelWithUnitSetterForVerticalAxis(firstXAxisDomainItem, unitSystem, locale);
  const labelMetrics = getLabelMetrics(offscreenCanvasContext, labelWithUnit);
  margin.top = Math.ceil(labelMetrics.height / 2);
  // calculate any additional required top margin due to area's labels shown at the top
  for (const area of areas) {
    const areaLabelAtTopOfWallplotMetrics = getLabelMetrics(offscreenCanvasContext, area.name);
    margin.top = Math.ceil(Math.max(margin.top, areaLabelAtTopOfWallplotMetrics.height + SETTINGS.AREAS.AREA_LABEL_DISTANCE_PX));
  }
  // calculate required right margin
  const area = areas[0];
  if (isDefined(area)) {
    for (const areaLine of area.lines) {
      const areaLabelAtRightOfWallplotMetrics = getLabelMetrics(offscreenCanvasContext, areaLine.line);
      margin.right = Math.ceil(Math.max(margin.right, SETTINGS.AREAS.AREA_LABEL_DISTANCE_PX + areaLabelAtRightOfWallplotMetrics.height));
    }
  } else {
    margin.right = 1;
  }

  return margin;
};

// calculates various metrics ( e.g width, height, cell width & height e.t.c ) for the canvas and the plot inside the canvas depending
// on the available container dimensions and fit-mode
const calculateMetrics = (
  domainScales: DomainScales,
  wallplotMargin: WallplotMargin,
  canvasContainerWidth: number,
  canvasContainerHeight: number,
  canvasFitMode: CanvasFitMode,
  wallplotData: DataItem[]
): Metrics => {
  let canvasWidth, canvasHeight, plotWidth, plotHeight, cellWidth, cellHeight;
  const { xScaleDomain, yScaleDomain, domainXStep, domainYStep, domainXMin, domainXMax, domainYMin, domainYMax } = domainScales;

  switch (canvasFitMode) {
    case 'SQUARE_CELLS_FIT_BOTH': {
      cellWidth = (canvasContainerWidth - wallplotMargin.left - wallplotMargin.right) / xScaleDomain.length;
      cellHeight = (canvasContainerHeight - wallplotMargin.top - wallplotMargin.bottom) / yScaleDomain.length;
      const squareCellSize = Math.min(cellWidth, cellHeight);
      cellWidth = squareCellSize;
      cellHeight = squareCellSize;
      plotWidth = Math.ceil(squareCellSize * xScaleDomain.length);
      canvasWidth = plotWidth + wallplotMargin.left + wallplotMargin.right;
      plotHeight = Math.ceil(squareCellSize * yScaleDomain.length);
      canvasHeight = plotHeight + wallplotMargin.top + wallplotMargin.bottom;
      break;
    }
    case 'SQUARE_CELLS_FIT_WIDTH':
      canvasWidth = canvasContainerWidth;
      plotWidth = canvasWidth - wallplotMargin.left - wallplotMargin.right;
      cellWidth = plotWidth / xScaleDomain.length;
      cellHeight = cellWidth;
      plotHeight = Math.round(cellWidth * yScaleDomain.length);
      canvasHeight = plotHeight + wallplotMargin.top + wallplotMargin.bottom;
      break;
    case 'SQUARE_CELLS_FIT_HEIGHT':
      canvasHeight = canvasContainerHeight;
      plotHeight = canvasHeight - wallplotMargin.top - wallplotMargin.bottom;
      cellHeight = plotHeight / yScaleDomain.length;
      cellWidth = cellHeight;
      plotWidth = Math.round(cellHeight * xScaleDomain.length);
      canvasWidth = plotWidth + wallplotMargin.left + wallplotMargin.right;
      break;
    case 'RECTANGLE_CELLS_FIT_BOTH':
      canvasWidth = canvasContainerWidth;
      plotWidth = canvasWidth - wallplotMargin.left - wallplotMargin.right;
      canvasHeight = canvasContainerHeight;
      plotHeight = canvasHeight - wallplotMargin.top - wallplotMargin.bottom;
      cellWidth = plotWidth / xScaleDomain.length;
      cellHeight = plotHeight / yScaleDomain.length;
      break;
    default:
      throw new Error(`Unhandled canvasFit mode : '${canvasFitMode}'`);
  }

  return {
    canvasWidth: Math.max(0, canvasWidth),
    canvasHeight: Math.max(0, canvasHeight),
    plotWidth,
    plotHeight,
    cellWidth,
    cellHeight,
    xScaleDomain,
    yScaleDomain,
    domainXMin,
    domainXMax,
    domainYMin,
    domainYMax,
    domainXStep,
    domainYStep,
    dataLowestValue: calculateDataItemsValuesRange(wallplotData)[0],
  };
};

/*
 Generates the grid of the heatmap (x,y, width, height of all cells). This is later used by the various other canvasses.
 To ensure that the heatmap's blocks align perfectly to eachother without any tiny gaps due to fraction-of-pixel coordinates,
 we need to work with whole pixels. ( such tiny gaps manifest as anti-alliased horizontal & vertical lines on the grid and are undesirable ).
 Depending on whether the coordinates round up or down, this may lead however to the grid overshooting or underutilizing its available dimensions.

 For instance, consider 10 columns in a 100px available width. Then each column can have 100px/10 = 10px and all blocks line up perfectly.

 But what if we have 10 columns in a 103px available width? Then each column would need, ideally, 103px/10 = 10.3px. As we explained above,
 we cannot have such fraction-of-pixels coordinates. So, we round this (down) and it becomes 10px. But 10 columns times 10px is 100px so that leaves
 us with 3 pixels under-utilized width.

 Consider the alternative scenario of 10 columns in a 97px available width. Then each column can have 97px/10 = 9.7px. Rounding this (up) it gives
 us 10px per column. But 10 columns times 10px/columns is now 100px, that is , 3px more than we actually have and we just overshot our available width.

 To get around this we use accumulators,one per axis : they accumulate the delta ( positive or negative ) between the ideal fraction-of-pixel dimensions and
 the actual rounded one we use. Whenever the accumulated value is equal to or more than 1px (or -1px ) then we add ( or subtract ) 1 pixel from the next item.
 The side effect of this is : not all columns ( or rows ) have exactly the same width ( or height). Every now and then , a column or a row becomes 1px larger (or smaller)
 than the other ones, in order to compensate for the fact that we round up the pixels and to ensure that we cover the full width/height without any gaps.
 This irregularity in the grid's cells seems to be both unavoidable and,also, hardly noticeable.
*/
const generateGridLayout = (wallplotMargin: WallplotMargin, metrics: Metrics): CellLayout[] => {
  const gridLayout: CellLayout[] = [];

  const roundedCellWidth = Math.round(metrics.cellWidth);
  const roundedCellHeight = Math.round(metrics.cellHeight);
  const cellWidthDelta = metrics.cellWidth - roundedCellWidth;
  const cellHeightDelta = metrics.cellHeight - roundedCellHeight;

  let xDeltaAccumulator = 0;
  let yDeltaAccumulator = 0;
  let startX = wallplotMargin.left;
  let startY;
  let cellWidth;
  let cellHeight;
  let accumulatedWidth = 0;
  let accumulatedHeight = 0;

  const updateAccumulator = (accumulator: number, idealSize: number, delta: number) => {
    let size = idealSize;
    accumulator += delta;
    if (Math.abs(accumulator) >= 1) {
      // we now have at least 1px credit or debit and we should take action to compensate for the rounding of the sizes
      if (accumulator > 0) {
        // we have been giving a little less rounded size than the ideal float needed and have "credit".
        // Make this item 1px larger to compensate
        accumulator--;
        size++;
      } else {
        // we have been giving a little more rounded size than the ideal float needed and have "debit".
        // Make this item 1px less to compensate
        accumulator++;
        size--;
      }
    }
    return {
      accumulator,
      size,
    };
  };

  const domainXLength = metrics.xScaleDomain.length;
  const domainYLength = metrics.yScaleDomain.length;
  for (let column = 0; column < domainXLength; column++) {
    startY = wallplotMargin.top;
    yDeltaAccumulator = 0;

    const xAccumulator = updateAccumulator(xDeltaAccumulator, roundedCellWidth, cellWidthDelta);
    cellWidth = xAccumulator.size;
    xDeltaAccumulator = xAccumulator.accumulator;
    accumulatedWidth += cellWidth;
    if (column === domainXLength - 1) {
      // in the very last repetition of the x-axis, we may still have up to 1px "debit" or "credit" accumulated.
      // Ensure to make the cell as wide as it has to be to perfectly utilize all available plot width
      const remainingWidth = metrics.plotWidth - accumulatedWidth;
      cellWidth += remainingWidth;
      accumulatedWidth += remainingWidth;
    }
    accumulatedHeight = 0;
    for (let row = 0; row < domainYLength; row++) {
      const yAccumulator = updateAccumulator(yDeltaAccumulator, roundedCellHeight, cellHeightDelta);
      cellHeight = yAccumulator.size;
      yDeltaAccumulator = yAccumulator.accumulator;
      accumulatedHeight += cellHeight;
      if (row === domainYLength - 1) {
        // in the very last repetition of the y-axis, we may still have up to 1px "debit" or "credit" accumulated.
        // Ensure to make the cell as tall as it has to be to perfectly utilize all available plot height
        const remainingHeight = metrics.plotHeight - accumulatedHeight;
        cellHeight += remainingHeight;
        accumulatedHeight += remainingHeight;
      }
      gridLayout.push({
        startX,
        startY,
        width: cellWidth,
        height: cellHeight,
        halfWidth: cellWidth / 2,
        halfHeight: cellHeight / 2,
        domainValueX: metrics.xScaleDomain[column] ?? 0,
        domainValueY: metrics.yScaleDomain[row] ?? 0,
      });
      startY += cellHeight;
    }
    startX += cellWidth;
  }

  assert(accumulatedWidth === metrics.plotWidth, 'Plot underutilized or overshot available width');
  assert(accumulatedHeight === metrics.plotHeight, 'Plot underutilized or overshot available height');

  return gridLayout;
};

const calculateWallplotLayout = (
  unitSystem: UnitSystem,
  locale: SupportedLanguageIsoCode,
  canvasContainerWidth: number,
  canvasContainerHeight: number,
  canvasFitMode: CanvasFitMode,
  wallplotData: DataItem[],
  areas: Area[],
  isCellHoveringEnabled: boolean
): WallplotLayout => {
  const domainScales = generateScales(wallplotData);
  const wallplotMargin = calculateWallplotMargin(domainScales, unitSystem, locale, isCellHoveringEnabled, areas);
  const metrics = calculateMetrics(domainScales, wallplotMargin, canvasContainerWidth, canvasContainerHeight, canvasFitMode, wallplotData);
  const gridLayout = generateGridLayout(wallplotMargin, metrics);

  return { wallplotMargin, metrics, gridLayout };
};

export type CanvasFitMode = 'SQUARE_CELLS_FIT_BOTH' | 'SQUARE_CELLS_FIT_WIDTH' | 'SQUARE_CELLS_FIT_HEIGHT' | 'RECTANGLE_CELLS_FIT_BOTH';
export interface WallplotHeatmapProps {
  className?: string;
  areaPanelTexts: AreaPanelTexts;
  cellTooltipTexts: CellTooltipTexts;
  wallplotData: WallplotData;
  domainXStart?: number;
  domainXWrap?: number;
  colorScale: ColorScales;
  canvasFitMode?: CanvasFitMode;
  showAxis?: boolean;
  showCellValues?: boolean;
  showCellStates?: boolean;
  showCellHover?: boolean;
  showAreasMap?: boolean;
  onAreaEntered?: (areaId: number) => void;
  onAreasExited?: () => void;
  onRegionClicked?: (regionId: number) => void;
  highlightAreaId?: number | null;
  fillCells?: boolean;
  criticalValueThreshold?: number;
  highlightValueMinimum: number | null;
  highlightValueMaximum: number | null;
  highlightCellId: number | null;
  onContainerWidthChanged?: (newWidth: number) => void;
}
export const WallplotHeatmap: FC<React.PropsWithChildren<WallplotHeatmapProps>> = ({
  className,
  areaPanelTexts,
  cellTooltipTexts,
  wallplotData,
  domainXStart,
  domainXWrap,
  colorScale,
  canvasFitMode = 'SQUARE_CELLS_FIT_WIDTH',
  showAxis = true,
  showCellValues = true,
  showCellStates = true,
  showCellHover = true,
  showAreasMap = true,
  highlightAreaId,
  fillCells = true,
  criticalValueThreshold,
  highlightValueMinimum,
  highlightValueMaximum,
  highlightCellId,
  onAreaEntered,
  onAreasExited,
  onRegionClicked,
  onContainerWidthChanged,
}) => {
  const [localization] = useLocalization();
  const { unitSystem, locale } = localization;
  const [resizeListener, sizes] = useResizeAware();
  const [wallplotLayout, setWallplotLayout] = useState<WallplotLayout>(() =>
    calculateWallplotLayout(unitSystem, locale, 0, 0, canvasFitMode, wallplotData.wallplot, wallplotData.areas, showCellHover)
  );
  const [areasLayout, setAreasLayout] = useState<AreaLayout[]>([]);
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const [hoveredDataItem, setHoveredDataItem] = useState<DataItem | null>(null);
  const [hoveredCellLayout, setHoveredCellLayout] = useState<CellLayout | null>(null);
  const [mostRecentContainerWidth, setMostRecentContainerWidth] = useState(0);
  const domCanvassesContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const layout = calculateWallplotLayout(
      unitSystem,
      locale,
      sizes.width ?? 0,
      sizes.height ?? 0,
      canvasFitMode,
      wallplotData.wallplot,
      wallplotData.areas,
      showCellHover
    );
    setWallplotLayout(layout);
    if (isDefined(sizes.width) && sizes.width !== mostRecentContainerWidth) {
      setMostRecentContainerWidth(sizes.width);
      onContainerWidthChanged?.(sizes.width);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasFitMode, sizes.width, sizes.height, wallplotData]);

  useEffect(() => {
    if (!domCanvassesContainerRef.current) {
      return;
    }
    const canvassesContainer = domCanvassesContainerRef.current;
    canvassesContainer.addEventListener('mousemove', onMouseMove);
    canvassesContainer.addEventListener('mouseleave', onMouseLeave);

    function onMouseMove(evt: MouseEvent) {
      const rect = canvassesContainer.getBoundingClientRect();
      setMouseX(evt.clientX - rect.left);
      setMouseY(evt.clientY - rect.top);
    }

    function onMouseLeave() {
      setMouseX(Infinity);
      setMouseY(Infinity);
    }

    return () => {
      canvassesContainer.removeEventListener('mousemove', onMouseMove);
      canvassesContainer.removeEventListener('mouseleave', onMouseLeave);
    };
  }, []);

  const handleAreasLayoutUpdated = React.useCallback((updatedAreasLayout: AreaLayout[]) => {
    setAreasLayout(updatedAreasLayout);
  }, []);

  const handleCellHovered = (hoveredCellItem: DataItem | null, hoveredCellLayout: CellLayout | null) => {
    setHoveredDataItem(hoveredCellItem);
    setHoveredCellLayout(hoveredCellLayout);
  };

  return (
    <SWrapper className={className}>
      <SContainer>
        {resizeListener}
        <SCanvassesContainer ref={domCanvassesContainerRef}>
          <WallplotCanvas
            wallplotLayout={wallplotLayout}
            wallplotData={wallplotData.wallplot}
            colorScales={colorScale}
            showCellValues={showCellValues}
            fillCells={fillCells}
            showAxis={showAxis}
            criticalValueThreshold={criticalValueThreshold}
          />
          {((isDefined(highlightValueMinimum) && isDefined(highlightValueMaximum)) || isDefined(highlightCellId)) && (
            <CellHighlightingCanvas
              wallplotLayout={wallplotLayout}
              wallplotData={wallplotData.wallplot}
              highlightValueMinimum={highlightValueMinimum}
              highlightValueMaximum={highlightValueMaximum}
              highlightCellId={highlightCellId}
            />
          )}
          {showCellHover && (
            <CellHoveringCanvas
              wallplotLayout={wallplotLayout}
              wallplotData={wallplotData.wallplot}
              mouseX={mouseX}
              mouseY={mouseY}
              onCellHovered={handleCellHovered}
            />
          )}
          {showAreasMap && (
            <AreasMapCanvas
              texts={areaPanelTexts}
              domainXStart={domainXStart}
              domainXWrap={domainXWrap}
              plotMarginTopPx={wallplotLayout.wallplotMargin.top}
              plotMarginLeftPx={wallplotLayout.wallplotMargin.left}
              metrics={wallplotLayout.metrics}
              wallplotLayout={wallplotLayout}
              areas={wallplotData.areas}
              onAreasLayoutUpdated={handleAreasLayoutUpdated}
            />
          )}
          {showCellStates && (
            <CellStatesCanvas wallplotLayout={wallplotLayout} wallplotData={wallplotData.wallplot} criticalValueThreshold={criticalValueThreshold} />
          )}
          {showAreasMap && (
            <AreaHighlightCanvas
              texts={areaPanelTexts}
              canvasWidth={wallplotLayout.metrics.canvasWidth}
              canvasHeight={wallplotLayout.metrics.canvasHeight}
              wallplotWidth={wallplotLayout.metrics.plotWidth}
              wallplotMarginLeft={wallplotLayout.wallplotMargin.left}
              areasLayout={areasLayout}
              areas={wallplotData.areas}
              mouseX={mouseX}
              mouseY={mouseY}
              highlightAreaId={highlightAreaId}
              onAreaEntered={onAreaEntered}
              onRegionClicked={onRegionClicked}
              onAreasExited={onAreasExited}
            />
          )}
          <CellTooltip
            texts={cellTooltipTexts}
            wallplotMargin={wallplotLayout.wallplotMargin}
            metrics={wallplotLayout.metrics}
            dataItem={hoveredDataItem}
            cellLayout={hoveredCellLayout}
          />
        </SCanvassesContainer>
      </SContainer>
    </SWrapper>
  );
};

const SWrapper = styled.div`
  flex-grow: 1;
`;

const SContainer = styled.div`
  /* a non-default position for the parent element is a requirement of the react-resize-aware library that observes changes in the size of an element */
  position: relative;
  height: 100%;
`;

const SCanvassesContainer = styled.div`
  position: relative;

  canvas {
    position: absolute;
    top: 0;
    left: 0;
  }
`;
