import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const ChartCollapseIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g clipPath="url(#02625gbopa)" fill={fill}>
        <path d="M13 8.006a.961.961 0 0 0-.318-.707 1.137 1.137 0 0 0-.769-.293H4.087c-.288 0-.565.105-.769.293A.961.961 0 0 0 3 8.006c0 .265.115.52.318.707.204.188.48.293.769.293h7.826c.288 0 .565-.106.769-.293A.961.961 0 0 0 13 8.006zM8 16.004a.985.985 0 0 1-.663-.251.82.82 0 0 1-.275-.606v-1.542a.164.164 0 0 0-.055-.12.197.197 0 0 0-.133-.05H5.75a.802.802 0 0 1-.416-.118.702.702 0 0 1-.274-.308.63.63 0 0 1-.045-.395.668.668 0 0 1 .202-.352l2.251-2.055a.759.759 0 0 1 .244-.15.812.812 0 0 1 .822.15l2.25 2.055a.669.669 0 0 1 .204.352.63.63 0 0 1-.046.395.702.702 0 0 1-.274.308.802.802 0 0 1-.416.117H9.126a.197.197 0 0 0-.133.05.164.164 0 0 0-.055.121v1.542a.82.82 0 0 1-.275.606.985.985 0 0 1-.663.25zM8.533 5.797a.758.758 0 0 1-.244.15.812.812 0 0 1-.822-.15l-2.25-2.055a.668.668 0 0 1-.203-.352.63.63 0 0 1 .045-.395.702.702 0 0 1 .274-.308.801.801 0 0 1 .416-.117h1.125c.05 0 .098-.018.133-.05a.164.164 0 0 0 .055-.122V.857A.82.82 0 0 1 7.337.25.985.985 0 0 1 8 0c.249 0 .487.09.663.25a.82.82 0 0 1 .275.607v1.541c0 .046.02.09.055.122s.083.05.133.05h1.125c.148 0 .293.041.416.117a.702.702 0 0 1 .274.308.63.63 0 0 1 .045.395.669.669 0 0 1-.202.352L8.533 5.797z" />
      </g>
      <defs>
        <clipPath id="02625gbopa">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

ChartCollapseIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
