import { Configuration, ParameterRangesApi, ParametersApi } from '@rhim/rest/processData';

import { axiosApiInstance } from '../../utilities';

/**
 */
const BASE_URL = '/api/processdataservice/v1';

const configuration: Configuration = {} as Configuration;

export const parameters = new ParametersApi(configuration, BASE_URL, axiosApiInstance);
export const parametersRange = new ParameterRangesApi(configuration, BASE_URL, axiosApiInstance);
