import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const RotateIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <path d="M8.77 5.368c.535-.128 1.076.206 1.203.744.127.537-.206 1.076-.744 1.202C6.102 8.052 4 9.934 4 12c0 2.71 3.664 5 8 5 3.45 0 6.504-1.394 7.604-3.468.258-.489.863-.675 1.352-.416.488.26.674.864.416 1.352C19.935 17.178 16.168 19 12 19c-5.607 0-10-3.075-10-7 0-3.013 2.721-5.678 6.77-6.632zM12 0c.552 0 1 .448 1 1v11c0 .552-.448 1-1 1s-1-.448-1-1V1c0-.552.448-1 1-1zm9 5c.552 0 1 .448 1 1v4c0 .552-.448 1-1 1h-4c-.552 0-1-.448-1-1s.448-1 1-1h1.543c-.947-.756-2.258-1.353-3.763-1.693-.539-.121-.877-.657-.756-1.196.122-.539.66-.875 1.196-.755 1.898.429 3.569 1.228 4.78 2.259V6c0-.552.448-1 1-1z" />
        </g>
      </g>
    </svg>
  );
};

RotateIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
