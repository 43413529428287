import { i18nReact } from '@rhim/i18n';
import { useEffect, useState } from 'react';

import { Notification } from '../../lib/components/Notification';

/**
 * Monitors the online/offline connection status.
 * Displays a UI notification when the connection is lost or regained.
 *
 * @returns an object with a "isOnline" property
 */
export function useConnectionListener(showNotification = true) {
  const { t } = i18nReact.useTranslation(['sharedComponents']);
  const [isOnline, setIsOnline] = useState(true);
  const [hasPreviouslyLostConnection, setHasPreviouslyLostConnection] = useState(false);

  // Add online/offline listeners
  useEffect(() => {
    const handleConnectionChange = (event: Event) => {
      const isOnlineEvent = event.type === 'online';
      setIsOnline(isOnlineEvent);
    };

    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
  }, []);

  // Display notification whenever the connection status changes
  useEffect(() => {
    if (!showNotification) {
      return;
    }
    const NOTIFICATION_KEY_CONNECTION_ONLINE = 'notificationKeyConnectionOnline';
    const NOTIFICATION_KEY_CONNECTION_OFFLINE = 'notificationKeyConnectionOffline';
    const isOffline = !isOnline;

    // Display a "You are online" UI notification if we were not online before but we are online again now
    if (isOnline && hasPreviouslyLostConnection) {
      Notification.open({
        key: NOTIFICATION_KEY_CONNECTION_ONLINE,
        type: 'info',
        message: t('sharedComponents:useConnectionListener.online'),
        userAcknowledgementButtonLabel: t('sharedComponents:common.proceed'),
      });

      Notification.destroy(NOTIFICATION_KEY_CONNECTION_OFFLINE);

      setHasPreviouslyLostConnection(false);
    }
    // Display a "You are offline" UI notification if we were online before but we are offline now
    if (isOffline && !hasPreviouslyLostConnection) {
      Notification.open({
        key: NOTIFICATION_KEY_CONNECTION_OFFLINE,
        type: 'alert',
        message: t('sharedComponents:useConnectionListener.offline'),
        userAcknowledgementButtonLabel: t('sharedComponents:common.proceed'),
      });

      Notification.destroy(NOTIFICATION_KEY_CONNECTION_ONLINE);

      setHasPreviouslyLostConnection(true);
    }
  }, [t, showNotification, isOnline, hasPreviouslyLostConnection]);

  return { isOnline };
}
