import 'antd/es/date-picker/style/index';

import { settings } from '@rhim/design';
import { getBrowserLocale, SupportedLocale, toUnicodeLocale } from '@rhim/i18n';
import { CalendarIcon } from '@rhim/icons/24';
import { DATE_FORMAT, isDefined } from '@rhim/utils';
import generatePicker, { PickerProps } from 'antd/es/date-picker/generatePicker';
import { default as DatePickerLocaleGerman } from 'antd/es/date-picker/locale/de_DE';
import { default as DatePickerLocaleEnglishGB } from 'antd/es/date-picker/locale/en_GB';
import { default as DatePickerLocaleEnglishUS } from 'antd/es/date-picker/locale/en_US';
import { default as DatePickerLocalePortugese } from 'antd/es/date-picker/locale/pt_BR';
import { default as DatePickerLocaleChinese } from 'antd/es/date-picker/locale/zh_CN';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import React from 'react';
import { useId } from 'react-aria';
import styled from 'styled-components';

import IconChevronDoubleLeft from '../../../../../../assets/icons-16/chevron-double-left-16.svg?url';
import IconChevronDoubleRight from '../../../../../../assets/icons-16/chevron-double-right-16.svg?url';
import IconChevronLeft from '../../../../../../assets/icons-16/chevron-left-16.svg?url';
import IconChevronRight from '../../../../../../assets/icons-16/chevron-right-16.svg?url';
import IconClearSelectedDate from '../../../../../../assets/icons-24/x-circled-24.svg?url';
import { Feedback, FieldFeedback, FieldLabel } from '../../partials';
import { TooltipProps } from '../Tooltip';

DatePickerLocaleGerman.lang.locale = 'de'; // antd's DatePicker expects the locale to be 'de' instead of 'de_DE'

type IAntdDatepickerLocales = {
  [key in SupportedLocale]: PickerLocale;
};

export const ANTD_DATEPICKER_LOCALES: IAntdDatepickerLocales = {
  en: DatePickerLocaleEnglishUS,
  'en-GB': DatePickerLocaleEnglishGB,
  'en-US': DatePickerLocaleEnglishUS,
  de: DatePickerLocaleGerman,
  'pt-BR': DatePickerLocalePortugese,
  'zh-CN': DatePickerLocaleChinese,
};

// explicitly import the antd Datepicker's css style

const AntdDatePickerUsingDateFns = generatePicker<Date>(dateFnsGenerateConfig);

export type UISize = 'small-40' | 'medium-48';

type Props = PickerProps<Date> & {
  className?: string;
  uiSize: UISize;
  label?: string;
  isRequired?: boolean;
  feedback?: Feedback;
  tooltip?: TooltipProps;
};

const DatePicker: React.FC<Props> = ({ className, uiSize, label, isRequired = false, feedback, tooltip, ...rest }) => {
  const feedbackElementId = useId();
  const browserLocale = getBrowserLocale();

  return (
    <SWrapper className={className} uiSize={uiSize}>
      <GlobalDatePickerStyleSheet />
      {isDefined(label) && <FieldLabel text={label} isRequired={isRequired} tooltip={tooltip} />}
      <AntdDatePickerUsingDateFns
        inputReadOnly
        locale={ANTD_DATEPICKER_LOCALES[toUnicodeLocale(browserLocale)]}
        format={DATE_FORMAT.SHORT_LOCALIZED}
        suffixIcon={<CalendarIcon className="notification-type-info" fill="var(--color)" />}
        {...rest}
      />
      {isDefined(feedback) && <FieldFeedback feedback={feedback} feedbackElementId={feedbackElementId} />}
    </SWrapper>
  );
};

DatePicker.whyDidYouRender = true;

export default React.memo(DatePicker);

export const SWrapper = styled.div<{ uiSize: UISize }>`
  div.ant-picker {
    width: 100%;
    height: ${(props) => {
      switch (props.uiSize) {
        case 'small-40':
          return '40px';
        case 'medium-48':
          return '48px';
        default:
          return '40px';
      }
    }};
    box-shadow: none;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    border-color: ${settings.colors.Primary.Grey_3};
    padding: 0 ${settings.Spacing.Spacing_200};
    flex-shrink: 0;

    --color: ${settings.colors.Primary.Blue_9};

    &.ant-picker-disabled,
    &.ant-picker-disabled:hover {
      border-color: ${settings.colors.Primary.Grey_2};
      background-color: ${settings.colors.Primary.Grey_2};

      --color: ${settings.colors.Primary.Grey_4};
    }

    &:not(.ant-picker-disabled):hover.ant-picker-status-error,
    &.ant-picker-status-error {
      border-color: ${settings.colors.Operational.State_Notif_Magenta_2};
    }

    &.ant-picker-focused {
      border-color: ${settings.colors.Primary.Blue_9};
    }

    div.ant-picker-input {
      --inputTextColor: ${settings.colors.Primary.Blue_9};

      &.ant-picker-input-placeholder {
        --inputTextColor: ${settings.colors.Primary.Grey_4};
      }

      input {
        font-size: ${settings.typography.FontSize.Medium};
        font-family: ${settings.typography.FontFamily.Regular};

        &::placeholder {
          color: ${settings.colors.Primary.Grey_5};
        }
      }

      span.ant-picker-suffix {
        visibility: visible;
      }
    }

    span.ant-picker-suffix {
      margin-left: ${settings.Spacing.Spacing_200};
      flex-shrink: 0;
    }

    /* replace antd clear-selected-date icon with our own */
    span.ant-picker-clear {
      span.anticon-close-circle {
        svg {
          display: none;
        }

        &::after {
          content: url(${IconClearSelectedDate});
        }
      }
    }
  }
`;

export const GlobalDatePickerStyleSheet = settings.globals.patchedCreateGlobalStyle`
  .ant-picker-dropdown .ant-picker-panel-container {
    border-radius: 2px;
  }

  .ant-picker-panel-container {
    /* DATEPICKER DROPDOWN HEADER */
    .ant-picker-header {
      padding: 0 ${settings.Spacing.Spacing_200};
      border-bottom: 1px solid ${settings.colors.Primary.Grey_2};

      --buttonSize: 16px;

      button {
        min-width: auto;
        line-height: var(--buttonSize);
      }

      /* replace antd left "super previous" icon with our own */
      button.ant-picker-header-super-prev-btn span.ant-picker-super-prev-icon {
        transform: none;
        width: var(--buttonSize);
        height: var(--buttonSize);

        &::before {
          display: none;
        }

        &::after {
          content: url(${IconChevronDoubleLeft});
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 16px;
          height: 16px;
          border: none;
        }
      }

      /* replace antd left previous icon with our own */
      button.ant-picker-header-prev-btn span.ant-picker-prev-icon {
        margin-left: ${settings.Spacing.Spacing_100};
        transform: none;
        width: var(--buttonSize);
        height: var(--buttonSize);

        &::before {
          content: url(${IconChevronLeft});
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 16px;
          height: 16px;
          border: none;
        }
      }

      /* replace antd right next icon with our own */
      button.ant-picker-header-next-btn span.ant-picker-next-icon {
        margin-right: ${settings.Spacing.Spacing_100};
        transform: none;
        width: var(--buttonSize);
        height: var(--buttonSize);

        &::before {
          content: url(${IconChevronRight});
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 16px;
          height: 16px;
          border: none;
        }
      }

      /* replace antd right "super nex" icon with our own */
      button.ant-picker-header-super-next-btn span.ant-picker-super-next-icon {
        transform: none;
        width: var(--buttonSize);
        height: var(--buttonSize);

        &::before {
          display: none;
        }

        &::after {
          content: url(${IconChevronDoubleRight});
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 16px;
          height: 16px;
          border: none;
        }
      }

      .ant-picker-header-view {
        font-family: ${settings.typography.FontFamily.Bold};
        color: ${settings.colors.Primary.Grey_8};

        button {
          font-family: inherit;
          vertical-align: inherit;

          &:hover {
            color: ${settings.colors.Primary.Grey_8};
          }
        }
      }
    }

    /* DATEPICKER DROPDOWN BODY */
    .ant-picker-body {
      padding: 8px ${settings.Spacing.Spacing_200};
      font-family: ${settings.typography.FontFamily.Regular};
      font-size: ${settings.typography.FontSize.Small};

      table.ant-picker-content th {
        color: ${settings.colors.Primary.Grey_6};
      }

      .ant-picker-cell {
        color: ${settings.colors.Primary.Grey_5};

        &.ant-picker-cell-in-view {
          color: ${settings.colors.Primary.Grey_8};
        }
      }

      /* SELECTED DATE */
      .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
        background-color: ${settings.colors.Primary.Blue_9};
      }

      /* TODAY DATE CIRCULAR INDICATOR */
      .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
        font-family: ${settings.typography.FontFamily.Bold};

        &::before {
          border-radius: 50%;
          border-color: ${settings.colors.Primary.Blue_4};
        }
      }
    }
  }

  /* DATEPICKER DROPDOWN FOOTER */
  .ant-picker-footer {
    border-top: 1px solid ${settings.colors.Primary.Grey_2};

    a {
      font-family: ${settings.typography.FontFamily.Bold};
      font-size: ${settings.typography.FontSize.Small};
      color: ${settings.colors.Primary.Blue_9};
    }
  }
`;
