import { assert, isDefined } from '@rhim/utils';

import { DateRange, DragRange, ZoomRange } from './types';

export const sortRange = (range: DateRange | DragRange) => ({ from: Math.min(range.from, range.to ?? 0), to: Math.max(range.from, range.to ?? 0) });

export const getX = (zoomRange: ZoomRange | null) => {
  if (!isDefined(zoomRange)) return 0;

  const { dragRange } = zoomRange;
  const range = { from: dragRange.from, to: dragRange.to };
  const rangeDelta = range.to - range.from;

  return range.from + rangeDelta / 2;
};

export const localX = (e: React.MouseEvent | MouseEvent, ref: React.RefObject<HTMLDivElement>) => {
  assert(isDefined(ref.current), 'dragzoom wrapper dimensions require ref.current');
  const { left, width } = ref.current.getBoundingClientRect();

  return Math.min(Math.max(0, e.clientX - left), width);
};
