import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const PanoramaIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M6 2.2V.976l-1.2.244c-1.26.257-2.394.672-3.24 1.234C.74 3 0 3.821 0 4.911A2.4 2.4 0 0 0 0 5v6c0 .752.36 1.386.838 1.87.474.48 1.11.867 1.818 1.17C4.075 14.649 5.965 15 8 15s3.925-.352 5.344-.96c.707-.303 1.344-.69 1.818-1.17.478-.484.838-1.118.838-1.87V4.911c0-1.09-.74-1.912-1.56-2.457-.846-.562-1.98-.977-3.24-1.234L10 .976v5.891C9.376 6.953 8.705 7 8 7c-.705 0-1.376-.047-2-.133V2.2zM2 4.91c0-.106.078-.4.667-.79.339-.226.788-.44 1.333-.623v2.827c-.545-.184-.994-.397-1.333-.623-.59-.39-.667-.685-.667-.79zm11.333.791c-.339.226-.788.44-1.333.623V3.498c.545.183.994.397 1.333.622.59.391.667.685.667.791 0 .106-.078.4-.667.791zM2 11V7.722c.21.115.43.221.656.318C4.075 8.648 5.965 9 8 9s3.925-.352 5.344-.96c.227-.097.447-.203.656-.318V11c0 .076-.032.232-.262.465-.234.238-.623.498-1.182.737C11.44 12.68 9.83 13 8 13c-1.83 0-3.44-.32-4.556-.798-.56-.24-.948-.5-1.182-.737C2.032 11.232 2 11.076 2 11z"
      />
    </svg>
  );
};

PanoramaIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
