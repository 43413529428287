import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  backgroundColor?: Property.Color;
  foregroundColor?: Property.Color;
  stroke?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const OpsStateCriticalOutlineIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  backgroundColor,
  foregroundColor,
  stroke,
  size,
  title,
  role,
  ...rest
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                fill={backgroundColor}
                stroke={stroke}
                d="M11.107.5L15.5 4.893v6.215L11.107 15.5H4.893L.5 11.108V4.893L4.893.5h6.214z"
                transform="translate(-331 -135) translate(328 104) translate(3 31)"
              />
              <g fill={foregroundColor}>
                <path
                  d="M1.5 7C2.329 7 3 7.671 3 8.5S2.329 10 1.5 10 0 9.329 0 8.5.671 7 1.5 7zm0-7c.552 0 1 .512 1 1.143v3.714C2.5 5.488 2.052 6 1.5 6s-1-.512-1-1.143V1.143C.5.512.948 0 1.5 0z"
                  transform="translate(-331 -135) translate(328 104) translate(3 31) translate(6.5 3)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

OpsStateCriticalOutlineIcon.defaultProps = {
  size: '16',
  backgroundColor: settings.colors.Operational.State_Alert_Red_3,
  foregroundColor: settings.colors.Monochromatic.White,
  stroke: settings.colors.Operational.State_Alert_Red_4,
};
