import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const OpsStateUnhealthyIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path fillRule="evenodd" clipRule="evenodd" d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0z" fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3.5a1 1 0 0 0-1 1v3a1 1 0 0 0 2 0v-3a1 1 0 0 0-1-1zM6.5 11a1.5 1.5 0 1 0 2.999.001A1.5 1.5 0 0 0 6.5 11z"
        fill="#000"
      />
    </svg>
  );
};

OpsStateUnhealthyIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Operational.State_Alert_Yellow_2,
};
