import { settings } from '@rhim/design';
import { ChevronDownIcon, ChevronUpIcon } from '@rhim/icons/16';
import { graphContainerVolumeExplorer, graphMainContainerVolumeExplorer, graphNameLabelVolumeExplorer } from '@rhim/test-ids';
import * as React from 'react';
import styled from 'styled-components';

interface Props {
  name: string;
  children: React.ReactNode;
}
export const MARGIN_LEFT = 82;
export const PADDING_SIDES = 24;

export const COL_LEFT = 50;

const VolumeGraphsContainer: React.FunctionComponent<Props> = ({ children, name }: Props) => {
  const [expanded, setExpanded] = React.useState<boolean>(true);
  /**
   * Icon click  handler for handling expanded/collapse state.
   * @params index Clicked icon's index.
   */
  const onClick = () => {
    setExpanded(!expanded);
  };
  return (
    <Container data-test-id={graphMainContainerVolumeExplorer}>
      <MainHeader onClick={onClick}>
        <Centerer>
          <Button type="button">
            {expanded ? (
              <ChevronContainer>
                <ChevronUpIcon fill={settings.colors.Primary.Blue_9} />
              </ChevronContainer>
            ) : (
              <ChevronContainer>
                <ChevronDownIcon fill={settings.colors.Primary.Blue_9} />
              </ChevronContainer>
            )}
          </Button>
          <RegionName data-test-id={graphNameLabelVolumeExplorer}>
            {/* eslint-disable-next-line i18next/no-literal-string */}
            {name}
          </RegionName>
        </Centerer>
      </MainHeader>
      {expanded && <GraphContainer data-test-id={graphContainerVolumeExplorer}>{children}</GraphContainer>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${settings.colors.Primary.Grey_3};
  background-color: ${settings.colors.Monochromatic.White};
  margin: ${PADDING_SIDES}px;
  margin-top: 0;
`;

const ChevronContainer = styled.span`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

const RegionName = styled.span`
  font-family: ${settings.typography.FontFamily.Bold};
  font-size: ${settings.typography.FontSize.Medium};
  line-height: ${settings.typography.LineHeight.Line_Height_24};
  color: ${settings.colors.Primary.Grey_8};
`;
const MainHeader = styled.div`
  width: 100%;
  height: 40px;
  border-bottom: 1px solid ${settings.colors.Primary.Grey_2};
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const Centerer = styled.div`
  align-items: center;
  display: inline-flex;
`;

const GraphContainer = styled.div`
  margin-top: 39px;
  margin-left: ${MARGIN_LEFT}px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
`;

const Button = styled.button`
  padding: 0;
  margin: 0 8px 0 16px;
  border: none;
  background: none;
  outline: none;
`;

export default VolumeGraphsContainer;
