/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { settings } from '@rhim/design';
import { colorMappingPanelMeasurementView, visualizationPanelContainerMeasurementView } from '@rhim/test-ids';
import { ColorScales, getMinMax, head, isDefined, last, sortNumericallyBy, SpecialCharacter } from '@rhim/utils';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMeasure } from 'react-use';
import styled from 'styled-components';
import { UnitSystem } from 'typings';

import { convertUnit, MetricLengthUnit } from '../../partials/FormattedLength';
import { Option, Select } from '../Select';
import ValueIndicator, {
  CELL_WIDTH_PX,
  VALUE_INDICATOR_LINE_HEIGHT_PX,
  VALUES_INDICATOR_LABEL_HORIZONTAL_MARGIN_PX,
  VALUES_INDICATOR_LEFT_EXTENDS_PX,
} from './ValueIndicator';

export type ColorScalesMode = 'user' | 'default';

export enum ColorScalesId {
  user = 'user',
  default = 'default',
}

const CELL_MARGIN_BOTTOM_PX = 1;
const THRESHOLD_OUT_OF_BOUNDS_INDICATOR_OFFSET_PX = 1;
const COLOR_SCALES_CONTAINER_PADDING_TOP_PX = 16;
const LAST_THRESHOLD_END_RANGE_PERCENTAGE = 20;
const CELL_VALUE_FONT_SIZE_PX = 12;
const CELL_VALUE_MARGIN_HORIZONTAL_PX = 12;
const UNUSED_CELL_BORDER_THICKNESS_PX = 2;
const CELL_HEIGHT_MIN_PX = 2 * UNUSED_CELL_BORDER_THICKNESS_PX + 1;

export function sortColorScales(colorScales: ColorScales) {
  const sortedColorScales = [...colorScales];
  sortedColorScales.sort(sortNumericallyBy('threshold'));
  return sortedColorScales as ColorScales;
}

/**
 * Calculates the value shown at the very bottom of the color-scale which is derived by taking the bottom item from the color-scales and
 * increasing its value by 20%.
 * @param bottomScaleThreshold The threshold of the last item in the color-scale
 * @returns the provided threshold increased by 20%
 */
export function getBottomScaleEndRange(bottomScaleThreshold: number) {
  return bottomScaleThreshold + (Math.abs(bottomScaleThreshold) * LAST_THRESHOLD_END_RANGE_PERCENTAGE) / 100;
}

export function getCellHeight(colorScalesContainerHeight: number, colorScalesCount: number, thresholdDelta: number, overallThresholdDelta: number) {
  return ((colorScalesContainerHeight - (colorScalesCount - 1) * CELL_MARGIN_BOTTOM_PX) * thresholdDelta) / overallThresholdDelta;
}

export function getTopOffset(colorScales: ColorScales, colorScalesLayout: ColorScalesLayout, colorScalesContainerHeight: number, value: number) {
  const availableContainerHeight = colorScalesContainerHeight - (colorScalesLayout.colorScalesCount - 1) * CELL_MARGIN_BOTTOM_PX;
  let colorScalesTraversed = colorScales.findIndex((colorScale) => colorScale.threshold > value);
  if (colorScalesTraversed === -1) {
    colorScalesTraversed = colorScales.length;
  }
  return (
    COLOR_SCALES_CONTAINER_PADDING_TOP_PX +
    (colorScalesLayout.cellHeightScale * (availableContainerHeight * (value - colorScalesLayout.thresholdMin))) /
      (colorScalesLayout.thresholdEndRange - colorScalesLayout.thresholdMin) +
    (colorScalesTraversed - 1) * CELL_MARGIN_BOTTOM_PX
  );
}

export function getColorScalesLayout(
  colorScales: ColorScales | null,
  colorScalesContainerHeight: number,
  thicknessMapMin: number | undefined,
  thicknessMapMax: number | undefined
): ColorScalesLayout {
  if (!isDefined(colorScales) || colorScalesContainerHeight === 0) {
    return { colorScalesCount: 0, thresholdMin: 0, thresholdMax: 0, thresholdEndRange: 0, cellHeightScale: 1, cellsLayout: [] };
  }
  const ret = [];

  const colorScalesThresholds = colorScales.map((colorScale) => colorScale.threshold);
  const { min: thresholdMin, max: thresholdMax } = getMinMax(colorScalesThresholds);
  const actualThresholdMax = getBottomScaleEndRange(thresholdMax);
  const overallThresholdDelta = actualThresholdMax - thresholdMin;

  let maxHeightScale = 1;
  for (let index = 0; index < colorScales.length - 1; index++) {
    const currentColorScale = colorScales[index]!;
    const nextColorScale = colorScales[index + 1]!;
    const thresholdDelta = nextColorScale.threshold - currentColorScale.threshold;
    const cellHeight = getCellHeight(colorScalesContainerHeight, colorScales.length, thresholdDelta, overallThresholdDelta);
    if (cellHeight < CELL_HEIGHT_MIN_PX) {
      maxHeightScale = Math.max(maxHeightScale, CELL_HEIGHT_MIN_PX / cellHeight);
    }
  }

  for (let index = 0; index < colorScales.length; index++) {
    const currentColorScale = colorScales[index]!;

    const nextColorScaleThreshold = index !== colorScales.length - 1 ? colorScales[index + 1]!.threshold : getBottomScaleEndRange(currentColorScale.threshold);

    const isColorScaleUsed =
      !isDefined(thicknessMapMin) || !isDefined(thicknessMapMax)
        ? true
        : thicknessMapMin <= nextColorScaleThreshold && thicknessMapMax >= currentColorScale.threshold;

    const thresholdDelta = nextColorScaleThreshold - currentColorScale.threshold;
    const cellHeight = getCellHeight(colorScalesContainerHeight, colorScales.length, thresholdDelta, overallThresholdDelta);
    const cellHeightScaled = cellHeight * maxHeightScale;
    ret.push({ height: cellHeightScaled, color: currentColorScale.color, threshold: currentColorScale.threshold, isUsed: isColorScaleUsed });
  }
  return {
    colorScalesCount: colorScales.length,
    thresholdMin,
    thresholdMax,
    thresholdEndRange: actualThresholdMax,
    cellHeightScale: maxHeightScale,
    cellsLayout: ret,
  };
}

export function getTicksLayout(colorScalesLayout: ColorScalesLayout, colorScales: ColorScales | null, colorScalesContainerHeight: number): TicksLayout[] {
  if (!isDefined(colorScales) || !isDefined(colorScalesLayout) || colorScalesContainerHeight === 0) {
    return [];
  }
  const ret = [];
  // Always display the top tick
  const topScale = head(colorScales);
  const topTickOffset = getTopOffset(colorScales, colorScalesLayout, colorScalesContainerHeight, topScale.threshold);
  ret.push({ value: topScale.threshold, topOffset: topTickOffset });
  // Always display the very bottom tick
  const bottomScale = last(colorScales);
  const bottomScaleEndRange = getBottomScaleEndRange(bottomScale.threshold);
  const bottomTickOffset = getTopOffset(colorScales, colorScalesLayout, colorScalesContainerHeight, bottomScaleEndRange);
  // Add any other ticks can fit in between without them overlapping with its tick above nor below
  let previousTickLabelOffset = topTickOffset;
  for (let index = 1; index < colorScales.length; index++) {
    const currentTickScale = colorScales[index]!;
    const currentTickOffset = getTopOffset(colorScales, colorScalesLayout, colorScalesContainerHeight, currentTickScale.threshold);
    if (currentTickOffset > previousTickLabelOffset + CELL_VALUE_FONT_SIZE_PX && currentTickOffset < bottomTickOffset - CELL_VALUE_FONT_SIZE_PX) {
      ret.push({ value: currentTickScale.threshold, topOffset: currentTickOffset });
      previousTickLabelOffset = currentTickOffset;
    }
  }
  ret.push({ value: bottomScaleEndRange, topOffset: bottomTickOffset });
  return ret;
}

interface ColorScalesLayout {
  colorScalesCount: number;
  thresholdMin: number;
  thresholdMax: number;
  thresholdEndRange: number;
  cellHeightScale: number;
  cellsLayout: { color: string; height: number; threshold: number; isUsed: boolean }[];
}

interface TicksLayout {
  value: number;
  topOffset: number;
}

interface Texts {
  header: string;
  thicknessType: {
    wear: string;
    thickness: string;
    original: string;
  };
  originalModeDescription: string;
}

interface ModeSelectionTexts {
  selectLabel: string;
  selectOptionUser: string;
  selectOptionDefault: string;
}

interface ModeSelection {
  texts: ModeSelectionTexts;
  hasColorScalesUser: boolean;
  preferredColorScalesMode: ColorScalesId;
  setPreferredColorScalesMode: Dispatch<SetStateAction<ColorScalesId>>;
}

interface Props {
  className?: string;
  texts: Texts;
  unitSystem: UnitSystem;
  shouldLoadWearData: boolean;
  colorScales: ColorScales | null;
  thicknessMapMin: number | undefined;
  thicknessMapMax: number | undefined;
  modeSelection?: ModeSelection;
  onWidthChanged?: (width: number) => void;
}

const ColorScaleMappingPanel: React.ChildlessComponent<Props> = ({
  className,
  texts,
  unitSystem,
  shouldLoadWearData,
  colorScales,
  thicknessMapMin,
  thicknessMapMax,
  modeSelection,
  onWidthChanged,
}) => {
  const cellThresholdsRefs = useRef<HTMLSpanElement[]>([]);
  const [minimumValueIndicatorLabelWidth, setMinimumValueIndicatorLabelWidth] = useState(0);
  const [maximumValueIndicatorLabelWidth, setMaximumValueIndicatorLabelWidth] = useState(0);
  const [containerRef, { width }] = useMeasure<HTMLDivElement>();
  const [colorScalesContainerRef, { height }] = useMeasure<HTMLDivElement>();

  const addToRefs = (el: HTMLSpanElement | null) => {
    if (el && !cellThresholdsRefs.current.includes(el)) {
      cellThresholdsRefs.current.push(el);
    }
  };

  const paddingRight = (() => {
    let paddingRight = 0;
    for (const cellThresholdRef of cellThresholdsRefs.current) {
      paddingRight = Math.max(paddingRight, cellThresholdRef.getBoundingClientRect().width);
    }
    return paddingRight + 2 * CELL_VALUE_MARGIN_HORIZONTAL_PX;
  })();

  const colorScalesContainerHeight = Math.floor(height);

  const colorScalesLayout: ColorScalesLayout = useMemo(
    () => getColorScalesLayout(colorScales, colorScalesContainerHeight, thicknessMapMin, thicknessMapMax),
    [colorScales, colorScalesContainerHeight, thicknessMapMin, thicknessMapMax]
  );

  const ticksLayout: TicksLayout[] = useMemo(
    () => getTicksLayout(colorScalesLayout, colorScales, colorScalesContainerHeight),
    [colorScalesLayout, colorScales, colorScalesContainerHeight]
  );

  const valueIndicatorMinValueTopOffset = useMemo(() => {
    if (!isDefined(colorScales) || !isDefined(colorScalesLayout) || !isDefined(thicknessMapMin)) {
      return 0;
    }
    if (thicknessMapMin < colorScalesLayout.thresholdMin) {
      return (
        getTopOffset(colorScales, colorScalesLayout, colorScalesContainerHeight, colorScalesLayout.thresholdMin) -
        THRESHOLD_OUT_OF_BOUNDS_INDICATOR_OFFSET_PX -
        VALUE_INDICATOR_LINE_HEIGHT_PX
      );
    }
    return getTopOffset(colorScales, colorScalesLayout, colorScalesContainerHeight, thicknessMapMin);
  }, [colorScales, colorScalesLayout, colorScalesContainerHeight, thicknessMapMin]);

  const valueIndicatorMaxValueTopOffset = useMemo(() => {
    if (!isDefined(colorScales) || !isDefined(colorScalesLayout) || !isDefined(thicknessMapMax)) {
      return 0;
    }
    if (thicknessMapMax > colorScalesLayout.thresholdEndRange) {
      return (
        getTopOffset(colorScales, colorScalesLayout, colorScalesContainerHeight, colorScalesLayout.thresholdEndRange) +
        THRESHOLD_OUT_OF_BOUNDS_INDICATOR_OFFSET_PX
      );
    }
    return getTopOffset(colorScales, colorScalesLayout, colorScalesContainerHeight, thicknessMapMax);
  }, [colorScales, colorScalesLayout, colorScalesContainerHeight, thicknessMapMax]);

  const handleLabelWidthChangedMinimum = useCallback((width: number) => {
    setMinimumValueIndicatorLabelWidth(width);
  }, []);

  const handleLabelWidthChangedMaximum = useCallback((width: number) => {
    setMaximumValueIndicatorLabelWidth(width);
  }, []);

  const paddingLeft =
    VALUES_INDICATOR_LABEL_HORIZONTAL_MARGIN_PX +
    Math.max(minimumValueIndicatorLabelWidth, maximumValueIndicatorLabelWidth) +
    VALUES_INDICATOR_LABEL_HORIZONTAL_MARGIN_PX +
    VALUES_INDICATOR_LEFT_EXTENDS_PX;

  const handleColorScalesModeChanged = useCallback(
    (value: string | string[]) => {
      if (!isDefined(modeSelection)) {
        return;
      }
      modeSelection.setPreferredColorScalesMode(value as ColorScalesId);
    },
    [modeSelection]
  );

  useEffect(() => {
    onWidthChanged?.(width);
  }, [onWidthChanged, width]);

  const isMinimumThicknessValueCritical = isDefined(thicknessMapMin) && isDefined(colorScalesLayout) && thicknessMapMin < colorScalesLayout.thresholdMin;
  const isMaximumThicknessValueCritical = isDefined(thicknessMapMax) && isDefined(colorScalesLayout) && thicknessMapMax > colorScalesLayout.thresholdMax;
  const isMaximumThicknessValueOutOfBounds =
    isDefined(thicknessMapMax) && isDefined(colorScalesLayout) && thicknessMapMax > colorScalesLayout.thresholdEndRange;

  const thicknessLabel = isDefined(colorScales)
    ? shouldLoadWearData
      ? texts.thicknessType.wear
      : texts.thicknessType.thickness
    : texts.thicknessType.original;
  const thicknessUnits = isDefined(colorScales) ? convertUnit(MetricLengthUnit.mm, unitSystem) : null;

  return (
    <SWrapper className={className} data-test-id={visualizationPanelContainerMeasurementView} ref={containerRef}>
      {/* "Visualization" header */}
      <SHeader>{texts.header}</SHeader>
      {/* THICKNESS MODE ("Thickness"/"Wear"/"Original" */}
      <SThicknessModeContainer>
        <SThicknessMode>{thicknessLabel}</SThicknessMode>
        {isDefined(thicknessUnits) && (
          <>
            {SpecialCharacter.NonBreakingSpace}
            <SThicknessModeUnits>[{convertUnit(MetricLengthUnit.mm, unitSystem)}]</SThicknessModeUnits>
          </>
        )}
      </SThicknessModeContainer>
      {isDefined(colorScales) ? (
        <SColorScalesContainer
          ref={colorScalesContainerRef}
          data-test-id={colorMappingPanelMeasurementView}
          paddingLeft={paddingLeft}
          paddingRight={paddingRight}
        >
          {/* COLOR SCALES CELLS */}
          {colorScalesLayout.cellsLayout.map((cellLayout, index) => {
            return <SCell key={index} height={cellLayout.height} fill={cellLayout.color} isUsed={cellLayout.isUsed} />;
          })}
          {/* TICK LABELS */}
          {ticksLayout.map((tickLayout, index) => {
            return (
              <SCellThreshold ref={addToRefs} key={index} topOffset={tickLayout.topOffset} leftOffset={paddingLeft}>
                {tickLayout.value}
              </SCellThreshold>
            );
          })}
          {/* VALUE INDICATOR ARROW FOR MIN VALUE */}
          {isDefined(thicknessMapMin) && (
            <ValueIndicator
              onIndicatorLabelWidthChanged={handleLabelWidthChangedMinimum}
              unitSystem={unitSystem}
              topOffset={valueIndicatorMinValueTopOffset}
              leftOffset={paddingLeft}
              value={thicknessMapMin}
              isArrowFacingUp={false}
              isCritical={isMinimumThicknessValueCritical}
              isOutOfBounds={isMinimumThicknessValueCritical}
            />
          )}
          {/* VALUE INDICATOR ARROW FOR MAX VALUE */}
          {isDefined(thicknessMapMax) && (
            <ValueIndicator
              onIndicatorLabelWidthChanged={handleLabelWidthChangedMaximum}
              unitSystem={unitSystem}
              topOffset={valueIndicatorMaxValueTopOffset}
              leftOffset={paddingLeft}
              value={thicknessMapMax}
              isArrowFacingUp={true}
              isCritical={isMaximumThicknessValueCritical}
              isOutOfBounds={isMaximumThicknessValueOutOfBounds}
            />
          )}
        </SColorScalesContainer>
      ) : (
        <SOriginalMode>{texts.originalModeDescription}</SOriginalMode>
      )}
      {/* USER/DEFAULT COLORSCALES SELECTION */}
      {isDefined(modeSelection) && isDefined(colorScales) && modeSelection.hasColorScalesUser && (
        <SColorScaleSelectionContainer>
          <Select
            label={{ text: modeSelection.texts.selectLabel }}
            size="x-small-32"
            value={modeSelection.preferredColorScalesMode}
            onChange={handleColorScalesModeChanged}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ width: 'max-content' }}
          >
            <Option key={ColorScalesId.user} value={ColorScalesId.user}>
              {modeSelection.texts.selectOptionUser}
            </Option>
            <Option key={ColorScalesId.default} value={ColorScalesId.default}>
              {modeSelection.texts.selectOptionDefault}
            </Option>
          </Select>
        </SColorScaleSelectionContainer>
      )}
    </SWrapper>
  );
};

const SWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${settings.colors.Primary.Grey_3};
  background-color: ${settings.colors.Monochromatic.White};
  z-index: 1;
`;

const SHeader = styled.span`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 24px;
  background-color: ${settings.colors.Primary.Grey_1};
  color: ${settings.colors.Primary.Grey_6};
  font-family: ${settings.typography.FontFamily.Medium};
  font-size: ${settings.typography.FontSize.X_Small};
  padding-left: ${settings.Spacing.Spacing_200};
`;

const SThicknessModeContainer = styled.div`
  padding: ${settings.Spacing.Spacing_200} ${settings.Spacing.Spacing_200} 0;
`;

const SThicknessMode = styled.span`
  font-family: ${settings.typography.FontFamily.Medium};
  font-size: ${settings.typography.FontSize.Small};
  color: ${settings.colors.Primary.Grey_8};
`;

const SThicknessModeUnits = styled.span`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.Small};
  color: ${settings.colors.Primary.Grey_6};
`;

const SOriginalMode = styled.span`
  font-family: ${settings.typography.FontFamily.Medium};
  font-size: ${settings.typography.FontSize.Small};
  color: ${settings.colors.Primary.Grey_6};
  padding: ${settings.Spacing.Spacing_200};
`;

const SColorScalesContainer = styled.div<{ paddingLeft: number; paddingRight: number }>`
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  row-gap: ${CELL_MARGIN_BOTTOM_PX}px;
  padding-top: ${COLOR_SCALES_CONTAINER_PADDING_TOP_PX}px;
  padding-left: ${(props) => props.paddingLeft}px;
  padding-right: ${(props) => props.paddingRight}px;
  padding-bottom: ${settings.Spacing.Spacing_500};
  position: relative;
`;

const SCell = styled.div<{ height: number; fill: string; isUsed: boolean }>`
  width: ${CELL_WIDTH_PX}px;
  height: ${(props) => props.height}px;
  background-color: ${(props) => (props.isUsed ? props.fill : 'transparent')};
  border: ${(props) => (props.isUsed ? 'none' : `${UNUSED_CELL_BORDER_THICKNESS_PX}px solid ${props.fill}`)};
  flex-shrink: 0;
  position: relative;
`;

const SCellThreshold = styled.span<{ topOffset: number; leftOffset: number }>`
  position: absolute;
  top: ${(props) => props.topOffset}px;
  transform: translateY(-50%);
  left: ${(props) => props.leftOffset + CELL_WIDTH_PX + CELL_VALUE_MARGIN_HORIZONTAL_PX}px;
  white-space: nowrap;
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${CELL_VALUE_FONT_SIZE_PX}px;
  line-height: ${CELL_VALUE_FONT_SIZE_PX}px;
  color: ${settings.colors.Primary.Grey_8};
  user-select: none;
`;

const SColorScaleSelectionContainer = styled.div`
  border-top: 1px solid ${settings.colors.Primary.Grey_3};
  display: flex;
  flex-direction: column;
  padding: ${settings.Spacing.Spacing_200};
`;

ColorScaleMappingPanel.whyDidYouRender = true;
export default React.memo(ColorScaleMappingPanel);
