import { join } from 'lodash';

import { removePrefix } from './string';

/**
 * Automatically adds and removes prefixes to Storage key names.
 */
export function createStorage(storage: Storage, prefix: string): Storage {
  return new Proxy(storage, {
    get(target, name: string) {
      if (name === 'getItem') {
        const originalMethod = target[name].bind(target);

        return function (this: Storage, key: string) {
          return originalMethod.apply(this, [join([prefix, key], '')]);
        };
      }

      if (name === 'setItem') {
        const originalMethod = target[name].bind(target);

        return function (this: Storage, key: string, value: string) {
          return originalMethod.apply(this, [join([prefix, key], ''), value]);
        };
      }

      if (name === 'key') {
        const originalMethod = target[name].bind(target);

        return function (this: Storage, index: number) {
          const result = originalMethod.apply(this, [index]);

          if (result === null) {
            return null;
          }

          return removePrefix(prefix)(result);
        };
      }

      if (name === 'removeItem') {
        const originalMethod = target[name].bind(target);

        return function (this: Storage, key: string) {
          return originalMethod.apply(this, [join([prefix, key], '')]);
        };
      }

      if (typeof target[name] === 'function') {
        return function (...args: unknown[]) {
          return Reflect.apply(target[name] as AnyFunction, storage, args);
        };
      }

      return Reflect.get(target, name, target);
    },
  });
}
