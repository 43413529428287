import { RHIMAPOReportingWearManagementApiV1ModelsBOFBofReportModelDto } from '@rhim/rest/wearManagement';
import { assert, isDefined, specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError, AxiosRequestConfig } from 'axios';

import { API } from '../api/wearManagement';

type Payload = APO.Plot.Root | null;
type ErrorResponse = AxiosError<APO.WearManagementAPI.WearExplorerView.ErrorResponse>;

export function usePredictions(
  vesselId: UUID | null,
  campaign?: string,
  options?: {
    configuration?: Omit<
      UseQueryOptions<RHIMAPOReportingWearManagementApiV1ModelsBOFBofReportModelDto, ErrorResponse, Payload, ReturnType<typeof getKey>>,
      'queryKey' | 'queryFn'
    >;
    axiosConfiguration?: AxiosRequestConfig;
  }
) {
  return useQuery(getKey(vesselId, campaign), (context) => queryFn(context, options?.axiosConfiguration), {
    ...options?.configuration,
    enabled: isDefined(vesselId),
    select(data) {
      if (isDefined(data.reportData)) {
        (data.reportData.hotSpots || []).forEach((hotSpot) => {
          // Keep up to two brick suppliers, drop the rest
          // See https://dev.azure.com/RHIM/HMD/_workitems/edit/103525#8374823
          hotSpot.brickSuppliers = (hotSpot.brickSuppliers || []).slice(0, 2);
        });
      }
      return data as unknown as Payload;
    },
  });
}

const getKey = (vesselId: UUID | null, campaign?: string) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'wearManagement',
      scope: 'predictions',
      entity: 'detail',
      vesselId,
      campaign,
    },
  ]);

const queryFn = ({ queryKey: [{ vesselId, campaign }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  assert(isDefined(vesselId), 'This is only executed by react-query if vesselId was defined in the first place. See the `enabled` prop.');

  return API.wearExplorer.getWearexplorerviewVesselsBofVesselid(vesselId, campaign, { signal, ...axiosConfiguration }).then((response) => response.data);
};

usePredictions.getKey = getKey;
usePredictions.queryFn = queryFn;
