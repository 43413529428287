import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import axios from 'axios';
import React from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

import AppContext from '../app/AppContext';
import ParametersComparisonGetaway from '../pages/ParametersComparison/ParametersComparison';
import ParametersComparisonErrorPage from '../pages/ParametersComparison/ParametersComparisonErrorPage';

export default function ParameterComparisonRoute() {
  const { selectedCustomer } = React.useContext(AppContext);
  const customerId = selectedCustomer.customerId as UUID;
  const { reset } = useQueryErrorResetBoundary();

  const FallbackComponent: React.FunctionComponent<React.PropsWithChildren<FallbackProps>> = React.useCallback(
    ({ error, resetErrorBoundary }) => {
      return (
        <ParametersComparisonErrorPage
          error={axios.isAxiosError(error) ? error.response?.data : error}
          resetErrorBoundary={resetErrorBoundary}
          customerId={customerId}
        />
      );
    },
    [customerId]
  );
  return (
    <ErrorBoundary FallbackComponent={FallbackComponent} resetKeys={[customerId]} onReset={reset}>
      <ParametersComparisonGetaway customerId={customerId} />
    </ErrorBoundary>
  );
}

ParameterComparisonRoute.whyDidYouRender = true;
