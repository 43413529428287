import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  foregroundColor?: Property.Color;
  backgroundColor?: Property.Color;
  title?: string;
  role?: string;
}

export const HistoricHourglassTwoColorIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  size,
  foregroundColor,
  backgroundColor,
  title,
  role,
  ...rest
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g>
          <circle cx="8" cy="8" r="8" fill={foregroundColor} />
          <path
            fill={backgroundColor}
            d="M8 2c3.313 0 6 2.687 6 6s-2.687 6-6 6-6-2.687-6-6 2.687-6 6-6zm0 6s-3 1.343-3 3v1h6v-1c0-1.657-3-3-3-3zm3-4H5v1c0 1.657 3 3 3 3s3-1.343 3-3V4z"
          />
        </g>
      </g>
    </svg>
  );
};

HistoricHourglassTwoColorIcon.defaultProps = {
  size: '16',
  foregroundColor: settings.colors.Primary.Grey_10,
  backgroundColor: settings.colors.Operational.State_Notif_Yellow_2,
};
