import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const OpsStateErrorIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m15.814 12.913-6.546-11.2A1.466 1.466 0 0 0 8 1c-.526 0-1.01.273-1.268.713l-6.546 11.2c-.253.434-.248.965.013 1.394.26.43.738.693 1.255.693h13.092c.517 0 .994-.264 1.255-.693.26-.43.266-.96.013-1.394z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5a1 1 0 0 0-1 1v3a1 1 0 0 0 2 0V6a1 1 0 0 0-1-1zm-1.5 7.5a1.5 1.5 0 1 0 2.999.001A1.5 1.5 0 0 0 6.5 12.5z"
        fill="#000"
      />
    </svg>
  );
};

OpsStateErrorIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Operational.State_Notif_Magenta_3,
};
