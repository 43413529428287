import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const LimLower3rdIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path d="M1 12V5h14M5 6v6M9 6v6" stroke={fill} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

LimLower3rdIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
