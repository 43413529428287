import './init';

import { settings } from '@rhim/design';
import { Delay, ErrorBoundary, Frame, NoPrint, NoPrintAppWrapper, PageLoadingScreen } from '@rhim/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import App from './app/App';
import { environment } from './environments/environment';
import { HiddenNavigationProvider } from './pages/OperatorDisplay';
import { queryClient } from './utilities/queryClient';

if (environment.allowExtensions) {
  require('@rhim/react/side-effects/whyDidYouRender');
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!);
const { GlobalStyle } = settings.globals;

const Main: React.FunctionComponent = () => (
  <>
    <GlobalStyle />
    <HiddenNavigationProvider initialValue={false}>
      <QueryClientProvider client={queryClient}>
        <NoPrintAppWrapper>
          <NoPrint>{environment.allowExtensions && <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />}</NoPrint>
          <Suspense fallback={<PageLoadingScreen container="window" delay={Delay.Root} />}>
            <Frame>
              <ErrorBoundary>
                <App />
              </ErrorBoundary>
            </Frame>
          </Suspense>
        </NoPrintAppWrapper>
      </QueryClientProvider>
    </HiddenNavigationProvider>
  </>
);

/**
 * by default, we're rendering the application in strict mode.
 * during E2E testing, the strict mode is not used to reduce the CPU pressure on the CI agent
 * @see: https://react.dev/reference/react/StrictMode
 */
if (environment.isUnderTest === true) {
  root.render(<Main />);
} else {
  root.render(
    <React.StrictMode>
      <Main />
    </React.StrictMode>
  );
}
