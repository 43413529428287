import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { AppStateErrorIcon, AppStateOKIcon, CircleXIcon, RedoIcon } from '@rhim/icons/24';
import { uploadProgressContainerPointCloudScansDataUploadContainer } from '@rhim/test-ids';
import { isDefined } from '@rhim/utils';
import React from 'react';
import styled from 'styled-components';

import { IconButton } from '../IconButton';
import { ProgressBar } from '../ProgressBar';
import { Tooltip } from '../Tooltip';

export enum UploadProgressStage {
  uploading = 'uploading',
  success = 'success',
  failure = 'failure',
}

export type UploadProgressState =
  | { stage: UploadProgressStage.uploading; filename: string; progressPercent: number }
  | { stage: UploadProgressStage.success }
  | { stage: UploadProgressStage.failure };

interface Props {
  className?: string;
  state: UploadProgressState;
  onCancel?: () => void;
  onRetry?: () => void;
}
const UploadProgress: React.ChildlessComponent<Props> = ({ className, state, onCancel, onRetry }) => {
  const { t } = i18nReact.useTranslation(['sharedComponents']);

  return (
    <SWrapper data-test-id={uploadProgressContainerPointCloudScansDataUploadContainer} className={className}>
      <SLeftContainer>
        {state.stage === UploadProgressStage.uploading && <SProgressPercentage>{`${state.progressPercent}%`}</SProgressPercentage>}
        {state.stage === UploadProgressStage.success && <AppStateOKIcon fill={settings.colors.Operational.State_Notif_Green_2} />}
        {state.stage === UploadProgressStage.failure && <AppStateErrorIcon />}
      </SLeftContainer>
      <SProgressDetails>
        {state.stage === UploadProgressStage.uploading && (
          <>
            <Tooltip title={state.filename} placement="bottom">
              <SFilename>{state.filename}</SFilename>
            </Tooltip>
            <SProgressBar
              percent={state.progressPercent}
              showInfo={false}
              trailColor={settings.colors.Primary.Grey_3}
              strokeWidth={4}
              strokeColor={settings.colors.Operational.State_Blue_2}
            />
          </>
        )}
        {state.stage === UploadProgressStage.success && <SLabelSuccess>{t('sharedComponents:uploadProgress.uploadSuccess')}</SLabelSuccess>}
        {state.stage === UploadProgressStage.failure && <SLabelFailure>{t('sharedComponents:uploadProgress.uploadFailure')}</SLabelFailure>}
      </SProgressDetails>
      {state.stage === UploadProgressStage.uploading && isDefined(onCancel) && (
        <>
          <SVerticalDivider />
          <IconButton tooltip={t('sharedComponents:common.cancel')} tooltipPlacement="bottom" icon={<CircleXIcon />} onPress={onCancel} />
        </>
      )}
      {state.stage === UploadProgressStage.failure && isDefined(onRetry) && (
        <>
          <SVerticalDivider />
          <IconButton tooltip={t('sharedComponents:uploadProgress.retry')} tooltipPlacement="bottom" icon={<RedoIcon />} onPress={onRetry} />
        </>
      )}
    </SWrapper>
  );
};
UploadProgress.whyDidYouRender = true;
export default React.memo(UploadProgress);

const SWrapper = styled.div`
  border-radius: 3px;
  border: solid 1px ${settings.colors.Primary.Grey_4};
  display: flex;
  align-items: center;
  height: 40px;
`;

const SLeftContainer = styled.div`
  flex-shrink: 0;
  width: 80px;
  display: flex;
  justify-content: center;
`;

const SProgressPercentage = styled.span`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.Large};
  color: ${settings.colors.Primary.Grey_8};
`;

const SProgressDetails = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  margin-right: ${settings.Spacing.Spacing_100};
`;

const SFilename = styled.span`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.Small};
  color: ${settings.colors.Primary.Grey_6};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const SProgressBar = styled(ProgressBar)`
  margin-top: 1px;
  font-size: 0;
`;

const SVerticalDivider = styled.span`
  border-left: 1px solid ${settings.colors.Primary.Grey_2};
  align-self: stretch;
`;

const SLabel = styled.span`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.Small};
`;

const SLabelSuccess = styled(SLabel)`
  color: ${settings.colors.Operational.State_Notif_Green_4};
`;

const SLabelFailure = styled(SLabel)`
  color: ${settings.colors.Operational.State_Notif_Magenta_4};
`;
