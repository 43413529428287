import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Spinner } from '../Spinner';

interface Props {
  /**
   * @default 0
   */
  delay?: Delay;
  /**
   * If "window" is selected, the screen will stretch to fit the screen.
   * Otherwise the loading screen will fit the space provided by the parent.
   */
  container: 'window' | 'parent';
}

const PageLoadingScreenComponent: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ container, delay = 0 }) => {
  if (delay > 0) {
    return <DelayablePageLoadingScreen delay={delay} container={container} />;
  }

  return (
    <Background container={container}>
      <Wrapper>
        <Indicator>
          <Spinner size="24" />
          <Content />
        </Indicator>
      </Wrapper>
    </Background>
  );
};

const DelayablePageLoadingScreen: React.FunctionComponent<React.PropsWithChildren<Require<Props, 'delay' | 'container'>>> = ({ delay, container }) => {
  const [isShowing, setShowing] = useState(false);

  useEffect(() => {
    const timeoutId = window.setTimeout(() => setShowing(true), delay);
    return () => window.clearTimeout(timeoutId);
  }, [delay]);

  if (!isShowing) {
    return null;
  }

  return (
    <Background container={container}>
      <Wrapper>
        <Indicator>
          <Spinner size="24" />
          <Content />
        </Indicator>
      </Wrapper>
    </Background>
  );
};

const Content: React.FunctionComponent<React.PropsWithChildren> = () => {
  const { t } = i18nReact.useTranslation(['sharedComponents'], { useSuspense: false });

  return <Message>{t('sharedComponents:common.loading', 'Loading…')}</Message>;
};

const Message = styled.span`
  margin-left: ${settings.Spacing.Spacing_150};
  margin-right: ${settings.Spacing.Spacing_150};
`;

const Indicator = styled.div`
  display: flex;
  align-items: center;
  background-color: ${settings.colors.Monochromatic.White};
  border: 1px solid ${settings.colors.Primary.Grey_2};
  border-radius: 28px;
  box-shadow: 0 2px 16px 0 rgba(61, 85, 103, 0.1);
  font-family: ${settings.typography.FontFamily.Medium};
  font-size: ${settings.typography.FontSize.Small};
  color: ${settings.colors.Primary.Grey_8};
  padding: ${settings.Spacing.Spacing_200};
  margin-bottom: 0;
`;

const Background = styled.div<{ container: 'window' | 'parent' }>`
  position: ${(props) => (props.container === 'window' ? 'fixed' : 'initial')};
  height: ${(props) => (props.container === 'window' ? '100vh' : `100%`)};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${settings.colors.Primary.Grey_1};
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
`;

export enum Delay {
  /**
   * Top-level Suspense boundary.
   */
  Root = 0,
  /**
   * Loading screen used before the user's identity is known.
   */
  Authentication = Root,
  /**
   * The loading screen shown inside the app; below the main navigation bar.
   */
  Content = Root,
}

const PageLoadingScreen = React.memo(PageLoadingScreenComponent);

PageLoadingScreen.displayName = 'PageLoadingScreen';

export default React.memo(PageLoadingScreen);
