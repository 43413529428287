import { AnalyticsEventProperties, AnalyticsEvents } from './customevents';
import { initMixpanel, setCustomPropertiesForAllMixpanelEvents, trackMixpanelEvent, trackMixpanelPageView } from './mixpanel';

export const initAnalytics = (mixpanelToken: string, isProduction: boolean) => {
  initMixpanel(mixpanelToken, isProduction);

  registerToUrlChanges();
};

export const trackCustomEvent = (name: AnalyticsEvents, properties?: AnalyticsEventProperties, flush = false) => {
  trackMixpanelEvent(name, properties, flush);
};

export const trackPageViewEvent = (properties?: { [key: string]: string }) => {
  trackMixpanelPageView(properties);
};

export const setUserPropertiesForAnalytics = (
  email: string | undefined | null,
  customerId: string | undefined | null,
  customerDisplayName: string | undefined | null,
  customerRegion: string | undefined | null
) => {
  const customerIdAndName = `${customerDisplayName ?? 'unknown'}(${customerId ?? 'unknown'})`;

  const customPropertiesMixpanel: { [key: string]: string } = {
    customerRegion: customerRegion ?? 'unknown',
    customerId: customerIdAndName,
  };

  setCustomPropertiesForAllMixpanelEvents(email ?? 'unknown', false, customPropertiesMixpanel);
};

function registerToUrlChanges() {
  // Hook into the history object
  const history = window.history;
  const originalPushState = history.pushState;
  history.pushState = (state, unused, url) => {
    originalPushState.apply(history, [state, unused, url]);
    trackPageViewEvent();
  };

  const originalReplaceState = history.replaceState;
  history.replaceState = (state, unused, url) => {
    originalReplaceState.apply(history, [state, unused, url]);
    trackPageViewEvent();
  };

  // Also listen to popstate events
  window.addEventListener('popstate', (_) => {
    trackPageViewEvent();
  });
}
