import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Spinner } from '../Spinner';
export { Progress as ProgressAntd } from 'antd';

type StyledProps = {
  isDisruptive: boolean;
};
const StyledWrapper = styled.div<StyledProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: ${(props) => (props.isDisruptive ? 'center' : 'flex-end')};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => (props.isDisruptive ? 'rgba(245, 246, 247, 0.6)' : 'transparent')};
  z-index: 1;
  pointer-events: ${(props) => (props.isDisruptive ? 'auto' : 'none')};
`;

const StyledLoadingIndicator = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  background-color: ${settings.colors.Monochromatic.White};
  border: 1px solid ${settings.colors.Primary.Grey_2};
  border-radius: 28px;
  box-shadow: 0 2px 16px 0 rgba(61, 85, 103, 0.1);
  font-family: ${settings.typography.FontFamily.Medium};
  font-size: ${settings.typography.FontSize.Small};
  color: ${settings.colors.Primary.Grey_8};
  padding: ${settings.Spacing.Spacing_200};
  margin-bottom: ${(props) => (props.isDisruptive ? '0' : settings.Spacing.Spacing_400)};

  span:first-of-type {
    margin-left: ${settings.Spacing.Spacing_150};
  }

  span:nth-of-type(2) {
    width: 35px;
  }
`;

const SProgressPercentage = styled.span`
  white-space: nowrap;
`;

const APPEAR_DELAY_SECONDS = 1;
const ANIMATION_DURATION_SECONDS = 0.3;

interface ProgressMessageProps {
  isDisruptive: boolean;
  isDeterminate: boolean;
  progressPercentage: number;
}
const ProgressMessage: FC<React.PropsWithChildren<ProgressMessageProps>> = ({ isDisruptive, isDeterminate, progressPercentage }) => {
  const { t } = i18nReact.useTranslation(['sharedComponents']);
  return (
    <StyledWrapper isDisruptive={isDisruptive} role="progressbar">
      <StyledLoadingIndicator isDisruptive={isDisruptive}>
        <Spinner size="24" />
        <span>{t('sharedComponents:common.loading', 'Loading…')}</span>
        {isDeterminate && <SProgressPercentage>{`${progressPercentage}%`}</SProgressPercentage>}
      </StyledLoadingIndicator>
    </StyledWrapper>
  );
};

export interface ProgressProps {
  isVisible?: boolean;
  isDisruptive?: boolean;
  isDeterminate?: boolean;
  progressPercentage?: number;
  delay?: boolean;
}

export const Progress: FC<React.PropsWithChildren<ProgressProps>> = ({
  isVisible = true,
  delay = true,
  isDisruptive = true,
  isDeterminate = false,
  progressPercentage = 0,
}) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          key="progressIndicator"
          initial={{ opacity: 0 }} // INITIAL STATE BEFORE MOUNTING
          animate={{ opacity: 1, transition: { delay: delay ? APPEAR_DELAY_SECONDS : 0 } }} // TARGET STATE AFTER MOUNTING
          exit={{ opacity: 0 }} // TARGET STATE AFTER UN-MOUNTING
          transition={{ ease: 'easeOut', duration: ANIMATION_DURATION_SECONDS }}
        >
          <SuspenseWrapper isDisruptive={isDisruptive} isDeterminate={isDeterminate} progressPercentage={progressPercentage} />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const SuspenseWrapper: FC<React.PropsWithChildren<ProgressProps>> = ({ isDisruptive = true, isDeterminate = false, progressPercentage = 0 }) => {
  const { t } = useTranslation(['progress'], { useSuspense: false });

  return useMemo(() => {
    return (
      <Suspense fallback={t('progress:pleaseWait')}>
        <ProgressMessage isDisruptive={isDisruptive} isDeterminate={isDeterminate} progressPercentage={progressPercentage} />
      </Suspense>
    );
  }, [isDeterminate, isDisruptive, progressPercentage, t]);
};
