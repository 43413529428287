import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const MegaphoneIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 28 22" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="nonzero"
        d="M23.3333 12.3333C22.9555 12.3333 22.6391 12.2053 22.384 11.9493C22.128 11.6942 22 11.3778 22 11C22 10.6222 22.128 10.3053 22.384 10.0493C22.6391 9.7942 22.9555 9.66664 23.3333 9.66664H26C26.3777 9.66664 26.6942 9.7942 26.9493 10.0493C27.2053 10.3053 27.3333 10.6222 27.3333 11C27.3333 11.3778 27.2053 11.6942 26.9493 11.9493C26.6942 12.2053 26.3777 12.3333 26 12.3333H23.3333ZM22.5333 20.8666L20.4 19.2666C20.0888 19.0444 19.9111 18.7555 19.8666 18.4C19.8222 18.0444 19.9111 17.7111 20.1333 17.4C20.3555 17.0889 20.6444 16.9111 21 16.8666C21.3555 16.8222 21.6888 16.9111 22 17.1333L24.1333 18.7333C24.4444 18.9555 24.6222 19.2444 24.6666 19.6C24.7111 19.9555 24.6222 20.2889 24.4 20.6C24.1777 20.9111 23.8888 21.0889 23.5333 21.1333C23.1777 21.1778 22.8444 21.0889 22.5333 20.8666ZM22 4.86664C21.6888 5.08886 21.3555 5.17775 21 5.13331C20.6444 5.08886 20.3555 4.91108 20.1333 4.59997C19.9111 4.28886 19.8222 3.95553 19.8666 3.59997C19.9111 3.24442 20.0888 2.95553 20.4 2.73331L22.5333 1.13331C22.8444 0.911085 23.1777 0.822196 23.5333 0.866641C23.8888 0.911085 24.1777 1.08886 24.4 1.39997C24.6222 1.71109 24.7111 2.04442 24.6666 2.39997C24.6222 2.75553 24.4444 3.04442 24.1333 3.26664L22 4.86664ZM4.66663 20.3333V15H3.33329C2.59996 15 1.9724 14.7386 1.45063 14.216C0.927959 13.6942 0.666626 13.0666 0.666626 12.3333V9.66664C0.666626 8.93331 0.927959 8.30531 1.45063 7.78264C1.9724 7.26086 2.59996 6.99997 3.33329 6.99997H8.66663L13.3 4.19997C13.7444 3.93331 14.1946 3.93331 14.6506 4.19997C15.1057 4.46664 15.3333 4.85553 15.3333 5.36664V16.6333C15.3333 17.1444 15.1057 17.5333 14.6506 17.8C14.1946 18.0666 13.7444 18.0666 13.3 17.8L8.66663 15H7.33329V20.3333H4.66663ZM16.6666 15.4666V6.53331C17.2666 7.06664 17.7502 7.71686 18.1173 8.48397C18.4835 9.2502 18.6666 10.0889 18.6666 11C18.6666 11.9111 18.4835 12.7498 18.1173 13.516C17.7502 14.2831 17.2666 14.9333 16.6666 15.4666Z"
      />
    </svg>
  );
};

MegaphoneIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
