import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { isDefined } from '@rhim/utils';
import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { BottomProps, WallProps } from 'typings/internal/sections';

import { showFixedNumber } from '../utils';

const PopoverBodyComponent: FC<React.PropsWithChildren<WallProps | BottomProps>> = (props) => {
  const { t } = i18nReact.useTranslation(['visualization']);

  const { angle, depth, rbl, wear } = props;
  const depthMarker = isDefined(depth) ? t('visualization:sections.m', { size: showFixedNumber(depth, 1) }) : '–';
  const rblValue = isDefined(rbl) ? t('visualization:sections.mm', { size: showFixedNumber(rbl.min, 2) }) : '–';
  const wearSpeedValue = isDefined(wear) ? t('visualization:sections.mm', { size: showFixedNumber(wear.med, 2) }) : '–';

  return (
    <SWrapper>
      <SValues>
        <SMark>{t('visualization:popover.angle')}</SMark>
        {t('visualization:popover.degrees', { degrees: angle })}
        <SMarkWithLeftPadding>{t('visualization:popover.depth')}</SMarkWithLeftPadding>
        {depthMarker}
      </SValues>
      <STable>
        <tbody>
          <tr>
            <td>{t('visualization:popover.rbl')}</td>
            <td>{rblValue}</td>
          </tr>
          <tr>
            <td>{t('visualization:popover.wear')}</td>
            <td>{wearSpeedValue}</td>
          </tr>
        </tbody>
      </STable>
    </SWrapper>
  );
};

PopoverBodyComponent.whyDidYouRender = true;

export const PopoverBody = React.memo(PopoverBodyComponent);

const SWrapper = styled.div`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.X_Small};
  color: ${settings.colors.Primary.Grey_9};
`;

const SValues = styled.div`
  padding: 4px 4px 3px 7px;
  border-bottom: solid 1px #eceef0;
`;

const STable = styled.table`
  margin: 4px 4px 3px 7px;
  width: 100%;
  table-layout: fixed;
`;

const SMark = styled.span`
  color: ${settings.colors.Primary.Grey_6};
  padding-right: 10px;
  padding-left: 3px;
`;

const SMarkWithLeftPadding = styled.span`
  color: ${settings.colors.Primary.Grey_6};
  padding-right: 6px;
  padding-left: 15px;
`;
