import { settings } from '@rhim/design';
import { colorScalesForLadle, getColorUsingScale } from '@rhim/utils';
import { FC } from 'react';

const RECT_SIZE_SMALL = 16;
const RECT_SIZE_LARGE = 24;
const CIRCLE_RADIUS = 8;

interface IconMinimumThicknessProps {
  className?: string;
  value: number;
}
export const IconMinimumThickness: FC<React.PropsWithChildren<IconMinimumThicknessProps>> = ({ value, className }) => {
  return (
    <svg width={RECT_SIZE_SMALL} height={RECT_SIZE_SMALL} className={className}>
      <rect
        width={RECT_SIZE_SMALL}
        height={RECT_SIZE_SMALL}
        strokeWidth="2"
        stroke={settings.colors.Primary.Grey_8}
        strokeDasharray="2"
        fill={getColorUsingScale(value, colorScalesForLadle)}
      />
    </svg>
  );
};

interface IconAverageThicknessProps {
  className?: string;
  value: number;
}
export const IconAverageThickness: FC<React.PropsWithChildren<IconAverageThicknessProps>> = ({ value, className }) => {
  return (
    <svg width={RECT_SIZE_SMALL} height={RECT_SIZE_SMALL} className={className}>
      <circle cx={CIRCLE_RADIUS} cy={CIRCLE_RADIUS} r={CIRCLE_RADIUS} fill={getColorUsingScale(value, colorScalesForLadle)} />
    </svg>
  );
};

interface IconCombinedMinimumAndAverageThicknessProps {
  className?: string;
  valueMinimum: number;
  valueAverage: number;
}
export const IconCombinedMinimumAndAverageThickness: FC<React.PropsWithChildren<IconCombinedMinimumAndAverageThicknessProps>> = ({
  valueMinimum,
  valueAverage,
  className,
}) => {
  return (
    <svg width={RECT_SIZE_LARGE} height={RECT_SIZE_LARGE} className={className}>
      <rect width={RECT_SIZE_LARGE} height={RECT_SIZE_LARGE} fill={getColorUsingScale(valueAverage, colorScalesForLadle)} />
      <svg width={RECT_SIZE_SMALL} height={RECT_SIZE_SMALL} x={(RECT_SIZE_LARGE - RECT_SIZE_SMALL) / 2} y={(RECT_SIZE_LARGE - RECT_SIZE_SMALL) / 2}>
        <IconMinimumThickness value={valueMinimum} />
      </svg>
    </svg>
  );
};
