import { settings } from '@rhim/design';
import { CollapseArrowsIcon, ExpandArrowsIcon } from '@rhim/icons/16';
import { Button, getVesselIcon } from '@rhim/react';
import { VesselType } from '@rhim/rest';
import { RHIMMeasurementServiceV1ModelsCampaignDto } from '@rhim/rest/measurementService';
import { RHIMProcessDataServiceV1ModelsCampaignDisplayTextDto } from '@rhim/rest/processData';
import { collapseAllButtonParametersComparison, expandAllButtonParametersComparison, wrapperParametersComparison } from '@rhim/test-ids';
import { assert, hasElements, isDefined } from '@rhim/utils';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import ErrorPanel from '../../components/ErrorPanel/ErrorPanel';
import { Invariants } from '../../errors';
import {
  useCustomerDetails,
  useParametersComparison,
  useParametersRange,
  usePersistedComparedVessel,
  useSelectedComparedCampaigns,
  useSelectedVessel,
  useVesselDisplayCampaigns,
  useVessels,
} from '../../hooks';
import useShortHashedSelectedCustomer from '../../hooks/useShortHashedSelectedCustomer';
import { Feature } from '../../settings';
import { getCampaignDateWithOffset, getCampaignDisplayText, PARAMS } from '../../utilities';
import ParametersComparisonStrip from './ParametersComparisonStrip';
import ParametersMenu from './ParametersMenu';

interface PropsGetaway {
  customerId: UUID;
}

const ParametersComparisonGetaway = ({ customerId }: PropsGetaway) => {
  const { data: vessels } = useVessels(customerId, undefined, Feature.ParameterComparison);
  assert(isDefined(vessels), 'In Suspense mode data should always be defined, according to react-query docs.');

  if (!hasElements(vessels)) {
    return <ErrorPanel error={new Error(Invariants.NoVesselsFound)} />;
  }

  return <ParametersComparison vessels={vessels} customerId={customerId} />;
};

ParametersComparisonGetaway.whyDidYouRender = true;

interface Props {
  customerId: UUID;
  vessels: APO.VesselV2[];
}

const ParametersComparison = ({ customerId, vessels }: Props) => {
  const { t } = useTranslation(['parameters-comparison']);
  const { selectedVesselId, navigateToSelectedVesselId } = useSelectedVessel(vessels);
  assert(isDefined(selectedVesselId));

  const location = useLocation();
  // eslint-disable-next-line compat/compat
  const urlParams = new URLSearchParams(location.search);
  const queryVesselId = urlParams.get(PARAMS.VESSEL_ID);
  const { hasCustomerAccess } = useShortHashedSelectedCustomer(customerId);
  const vessel = React.useMemo(() => vessels.find((vessel) => vessel.id === selectedVesselId), [vessels, selectedVesselId]);
  const { selectedComparedVesselId, setSelectedComparedVesselId } = usePersistedComparedVessel(vessels);

  const comparedVessel = React.useMemo(() => vessels.find((vessel) => vessel.id === selectedComparedVesselId), [vessels, selectedComparedVesselId]);

  assert(isDefined(vessel));
  const { data: parametersRange } = useParametersRange(customerId, vessel.vesselType);

  const { data: campaigns, isLoading: isCampaignLoading } = useVesselDisplayCampaigns(selectedVesselId);
  const { data: comparedCampaigns, isLoading: isComparedCampaignLoading } = useVesselDisplayCampaigns(selectedComparedVesselId ?? selectedVesselId);

  const { data: customerDetails } = useCustomerDetails(customerId);

  const formatCampaigns = React.useCallback(
    (list: RHIMProcessDataServiceV1ModelsCampaignDisplayTextDto[] | undefined) => {
      return (list || []).map((campaign) => {
        // Take the measurement's createdOn datetime in UTC and convert it to a datetime in the specified timezone
        return {
          id: campaign.internalCampaign,
          displayText: getCampaignDisplayText(true, campaign, customerDetails),
          vesselId: selectedVesselId,
        };
      });
    },
    [selectedVesselId, customerDetails]
  );

  const tooltipFormatter = React.useCallback(
    (campaign: RHIMMeasurementServiceV1ModelsCampaignDto) => {
      const foundCampaign = [...(campaigns ?? []), ...(comparedCampaigns ?? [])].find((item) => campaign.id === item.internalCampaign);

      return isDefined(foundCampaign) ? getCampaignDateWithOffset(foundCampaign, customerDetails) : '';
    },
    [customerDetails, campaigns, comparedCampaigns]
  );

  const formattedCampaigns = formatCampaigns(campaigns);
  const formattedComparedCampaigns = formatCampaigns(comparedCampaigns);

  const [campaignsToCompare, setCampaignsToCompare] = useSelectedComparedCampaigns(
    formattedCampaigns,
    formattedComparedCampaigns,
    selectedVesselId === selectedComparedVesselId
  );
  const [activeKey, setActiveKey] = React.useState<number[] | number | string | string[]>([0]);

  const { data, isLoading } = useParametersComparison(selectedVesselId, [campaignsToCompare[0]], {
    configuration: {
      // Consider the data fresh if it is less than 10 seconds old
      staleTime: 10 * 1000,
    },
  });

  const { data: comparedData } = useParametersComparison(selectedComparedVesselId ?? selectedVesselId, [campaignsToCompare[1]], {
    configuration: {
      // Consider the data fresh if it is less than 10 seconds old
      staleTime: 10 * 1000,
    },
  });

  const currentCampaign = React.useMemo(
    () => (isDefined(data) ? data.find((item) => item.campaign === campaignsToCompare[0]) : undefined),
    [campaignsToCompare, data]
  );

  const prevCampaign = React.useMemo(
    () => (isDefined(comparedData) ? comparedData.find((item) => item.campaign === campaignsToCompare[1]) : undefined),
    [campaignsToCompare, comparedData]
  );
  const params = React.useMemo(() => (currentCampaign || {}).parameters || [], [currentCampaign]);

  const vesselName = React.useMemo(() => {
    return (
      <SDiv>
        {getVesselIcon(vessel.vesselType)}
        <Text>{vessel.displayName}</Text>
      </SDiv>
    );
  }, [vessel]);

  const comparedVesselName = React.useMemo(() => {
    return (
      <SDiv>
        {isDefined(comparedVessel) && (
          <>
            {getVesselIcon(comparedVessel.vesselType)}
            <Text>{comparedVessel.displayName}</Text>
          </>
        )}
      </SDiv>
    );
  }, [comparedVessel]);

  const sameCampaign = React.useMemo(
    () => selectedComparedVesselId === selectedVesselId && campaignsToCompare[0] === campaignsToCompare[1],
    [campaignsToCompare, selectedComparedVesselId, selectedVesselId]
  );

  if (isDefined(queryVesselId) && !vessels.some((vessel) => vessel.id === queryVesselId) && hasCustomerAccess()) {
    return <ErrorPanel error={new Error(Invariants.NoPageFound)} />;
  }

  return (
    <Wrapper data-test-id={wrapperParametersComparison}>
      {hasCustomerAccess() && (
        <Container>
          <ParametersComparisonStrip
            selectedComparedVesselId={selectedComparedVesselId}
            setSelectedComparedVesselId={setSelectedComparedVesselId}
            setCampaignsToCompare={setCampaignsToCompare}
            campaignsToCompare={campaignsToCompare}
            isLoading={isLoading}
            isCampaignLoading={isCampaignLoading || isComparedCampaignLoading}
            campaigns={formattedCampaigns}
            comparedCampaigns={formattedComparedCampaigns}
            selectedVesselId={selectedVesselId}
            vessels={vessels}
            setSelectedVesselId={navigateToSelectedVesselId}
            tooltipFormatter={vessel.vesselType !== VesselType.Bof ? tooltipFormatter : undefined}
          />
        </Container>
      )}
      {isDefined(campaigns) && !hasElements(campaigns) && isDefined(comparedCampaigns) && !hasElements(comparedCampaigns) && (
        <ErrorPanel error={new Error(Invariants.NoCampaignsFound)} />
      )}
      {((isDefined(campaigns) && hasElements(campaigns)) || (isDefined(comparedCampaigns) && hasElements(comparedCampaigns))) && (
        <ComparisonContainer>
          <TitleContainer>
            <H3>{t('parameters-comparison:title')}</H3>
            <Button
              data-test-id={expandAllButtonParametersComparison}
              mode="ghost-border"
              icon={{ icon: <ExpandArrowsIcon />, position: 'start' }}
              label={t('parameters-comparison:expandAll')}
              onClick={() => {
                setActiveKey(Array.from({ length: params.length }, (_, i) => i));
              }}
            />
            <Button
              data-test-id={collapseAllButtonParametersComparison}
              mode="ghost-border"
              icon={{ icon: <CollapseArrowsIcon />, position: 'start' }}
              label={t('parameters-comparison:collapseAll')}
              onClick={() => {
                setActiveKey('');
              }}
            />
          </TitleContainer>
          <ParametersMenu
            vesselName={selectedVesselId !== selectedComparedVesselId ? vesselName : undefined}
            comparedVesselName={selectedVesselId !== selectedComparedVesselId ? comparedVesselName : undefined}
            sameCampaign={sameCampaign}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            parametersRange={parametersRange}
            currentCampaign={currentCampaign}
            prevCampaign={prevCampaign}
          />
        </ComparisonContainer>
      )}
    </Wrapper>
  );
};

ParametersComparison.whyDidYouRender = true;

export default React.memo(ParametersComparisonGetaway);

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: ${settings.Spacing.Spacing_200};
`;
const ComparisonContainer = styled.div`
  padding: ${settings.Spacing.Spacing_500};
`;
const Container = styled.div`
  position: sticky;
  top: 0;
  z-index: ${settings.Elevation.Strip};
  display: inline-flex;
  height: 48px;
  background: ${settings.colors.Primary.Grey_10};
  width: 100%;
`;

const H3 = styled.h3`
  ${settings.typography.fonts.h_l};
  flex-grow: 1;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
`;

const SDiv = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.span`
  margin-left: ${settings.Spacing.Spacing_50};
`;
