import { settings } from '@rhim/design';
import { Button } from '@rhim/react';
import { uploadDoneContainer } from '@rhim/test-ids';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import MessagePanel from '../MessagePanel/rhim-message-panel';

interface UploadSuccessActionButtonProps {
  dataTestId: string;
  label: string;
  icon: React.ReactElement;
  onClick: () => void;
}

export const UploadSuccessActionButton: React.ChildlessComponent<UploadSuccessActionButtonProps> = ({ dataTestId, label, icon, onClick }) => {
  return <SButton data-test-id={dataTestId} label={label} icon={{ icon, position: 'start' }} size="small-40" mode="ghost" onClick={onClick} />;
};

interface Props {
  actionButtons: JSX.Element;
}

const UploadSuccess: FC<React.PropsWithChildren<Props>> = ({ actionButtons }) => {
  const { t } = useTranslation(['ingress']);

  return (
    <SWrapper data-test-id={uploadDoneContainer}>
      <MessagePanel type="success" title={t('ingress:successUploadTitle')} actionButtons={<SButtonsContainer>{actionButtons}</SButtonsContainer>} />
    </SWrapper>
  );
};

export default React.memo(UploadSuccess);

const SWrapper = styled.div`
  margin: ${settings.Spacing.Spacing_300} 0;
`;

const SButtonsContainer = styled.div`
  display: flex;
  margin-top: ${settings.Spacing.Spacing_300};
`;

const SButton = styled(Button)`
  &:not(:first-of-type) {
    margin-left: ${settings.Spacing.Spacing_200};
  }
`;
