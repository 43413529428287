import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const VesselCRKIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 8a6.968 6.968 0 0 1-1.335 4.113 2 2 0 1 1-2.64 2.202A6.972 6.972 0 0 1 8 15a6.973 6.973 0 0 1-3.025-.685 2 2 0 1 1-2.64-2.202A7 7 0 1 1 15 8zm-7 5A5 5 0 1 0 8 3a5 5 0 0 0 0 10zm4-5a4 4 0 1 1-8 0 4 4 0 0 1 8 0zm-2.217-.908c-.45.537-1.08.848-1.647 1.128-.581.286-1.083.534-1.348.993a2.3 2.3 0 0 0-.132.268 2 2 0 1 1 3.127-2.389z"
      />
    </svg>
  );
};

VesselCRKIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
