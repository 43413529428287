import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const EyeCrossedIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M20.707 3.293c.391.391.391 1.023 0 1.414l-16 16c-.195.195-.45.293-.707.293-.256 0-.512-.098-.707-.293-.39-.391-.39-1.023 0-1.414l16-16c.391-.391 1.023-.391 1.414 0zM19.488 7.34c1.106 1.205 1.965 2.686 2.47 4.373.056.187.056.387 0 .574C20.576 16.9 16.574 20 12 20c-1.495 0-2.927-.337-4.228-.943l1.534-1.534c.851.305 1.756.477 2.694.477 3.59 0 6.75-2.398 7.951-6-.414-1.241-1.074-2.326-1.89-3.232zM12 4c1.495 0 2.927.337 4.228.943l-1.533 1.534C13.843 6.172 12.938 6 12 6c-3.589 0-6.749 2.398-7.951 6 .414 1.241 1.074 2.326 1.891 3.232L4.512 16.66c-1.105-1.205-1.964-2.687-2.47-4.373-.056-.188-.056-.387 0-.574C3.425 7.1 7.427 4 12 4zm3.851 6.978c.087.328.15.667.15 1.022 0 2.206-1.795 4-4 4-.357 0-.695-.061-1.024-.15zM12.001 8c.355 0 .693.061 1.022.15L8.15 13.021C8.062 12.694 8 12.355 8 12c0-2.206 1.794-4 4-4z"
      />
    </svg>
  );
};

EyeCrossedIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
