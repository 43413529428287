import { settings } from '@rhim/design';
import { ArrowLeftIcon } from '@rhim/icons/16';
import { legalInformationContainer, tabContainerLegalInformationContainer, titleLegalInformationContainer } from '@rhim/test-ids';
import { isDefined } from '@rhim/utils';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import * as Workarounds from '../../utils/workarounds';
import { Heading } from '../Heading';
import { Print } from '../Print';
import { Tabs } from '../Tabs/Tabs';

interface Props {
  title: string;
  children: React.ReactNode;
}

export const LegalPage: FC<React.PropsWithChildren<Props>> = ({ title, children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleChange = React.useCallback(
    (pathname: string) => {
      navigate(pathname);
    },
    [navigate]
  );

  const headerLevel = 'h1';

  return (
    <Padding data-test-id={legalInformationContainer}>
      <Column>
        <Print>
          <Heading data-test-id={titleLegalInformationContainer} level={headerLevel} style={{ marginTop: 0 }}>
            {title}
          </Heading>
          <Tabs data-test-id={tabContainerLegalInformationContainer} activeKey={location.pathname} defaultChecked={false} onChange={handleChange} size="large">
            {children}
          </Tabs>
        </Print>
      </Column>
    </Padding>
  );
};

export const Paragraph = styled.p`
  font-family: ${settings.typography.FontFamily.Regular};
  color: ${settings.colors.Primary.Grey_8};
  font-size: 16px;
  line-height: 1.5;
`;

export const Padding = styled.div`
  padding: 64px;

  @media print {
    padding: 0;
  }
`;

export const Column = styled.div`
  width: 100%;
  position: relative; /* To position the arrow relative to the header */
`;

export const StyledArrowLeftIcon = styled(ArrowLeftIcon).attrs((props) => ({
  fill: isDefined(props['fill']) ? props['fill'] : settings.colors.Primary.Blue_9,
}))`
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const ArrowContainer = styled.div`
  position: absolute;
  height: 40px;
  width: 40px;
  left: -40px;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

export const List = styled.ul`
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const Bullet = styled.li`
  font-family: ${settings.typography.FontFamily.Regular};
  color: ${settings.colors.Primary.Grey_8};
  font-size: 16px;
  line-height: 1.5;
`;

export const Table = styled.table`
  border: 1px solid ${settings.colors.Primary.Grey_9};
  border-collapse: collapse;
  font-family: ${settings.typography.FontFamily.Regular};
  color: ${settings.colors.Primary.Grey_8};
  font-size: 16px;
  line-height: 1.5;
`;

export const Cell = styled.td`
  border: 1px solid ${settings.colors.Primary.Grey_9};
  padding: 5px;
`;

export const Strong = styled.span`
  /* Browsers apply bold font-weight to <strong> elements. Unset it. */
  ${Workarounds.preventFauxFontStyle}
  font-family: ${settings.typography.FontFamily.Bold};
`;
