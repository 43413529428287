import { AxisLeft as VXAxisLeft, TickLabelProps } from '@vx/axis';
import { ScaleInput, scaleLinear } from '@vx/scale';
import { ScaleLinear } from 'd3-scale';
import React, { useMemo } from 'react';

import { AXIS_STYLE_PROPS, LABELS_OFFSET_PX, MAJOR_TICK_LABELS_STYLE, MAJOR_TICKS_STYLE_PROPS } from '../utils';

const axisYTickLabelProps: TickLabelProps<ScaleInput<ScaleLinear<number, number>>> = () => ({
  textAnchor: 'end',
  verticalAnchor: 'middle',
  dx: -LABELS_OFFSET_PX,
  style: MAJOR_TICK_LABELS_STYLE,
});

interface Props {
  domainYMin: number;
  domainYMax: number;
  plotHeight: number;
  majorTickValues: number[];
}
const AxisLeft: React.ChildlessComponent<Props> = (props) => {
  const { domainYMin, domainYMax, plotHeight, majorTickValues } = props;

  const axisProps = useMemo(() => {
    const scale = scaleLinear({ domain: [domainYMax, domainYMin], range: [0, plotHeight] });
    const tickFormat = scale.tickFormat(majorTickValues.length);
    return { scale, tickFormat };
  }, [domainYMin, domainYMax, plotHeight, majorTickValues]);

  return (
    <VXAxisLeft
      top={0}
      scale={axisProps.scale}
      tickValues={majorTickValues}
      tickFormat={axisProps.tickFormat}
      tickLabelProps={axisYTickLabelProps}
      {...AXIS_STYLE_PROPS}
      {...MAJOR_TICKS_STYLE_PROPS}
    />
  );
};
AxisLeft.whyDidYouRender = true;
export default React.memo(AxisLeft);
