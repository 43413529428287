import React from 'react';
import styled from 'styled-components';

import { TOOLTIP_BORDER_RADIUS, TOOLTIP_CHART_OFFSET } from './constants';
import { TooltipMouseEventHandlers } from './types';

interface Props {
  className?: string;
  width: number;
  height: number;
  handlers: TooltipMouseEventHandlers;
}

const TooltipCaptureComponent: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { children, className, width, height, handlers } = props;

  return (
    <Svg className={className} width={width} height={height + TOOLTIP_CHART_OFFSET + TOOLTIP_BORDER_RADIUS} {...handlers}>
      <rect fill="transparent" width="100%" height={height} />
      {children}
    </Svg>
  );
};

const Svg = styled.svg`
  overflow: hidden;
`;

export default React.memo(TooltipCaptureComponent);
