import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
}

export const ResumeIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 16V0l13 8-13 8z" fill={fill} />
    </svg>
  );
};

ResumeIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
