import { SupportedLanguageIsoCode } from '@rhim/i18n';
import { useRolodex, useUserSettings } from '@rhim/react';
import { unique } from '@rhim/utils';
import React, { useEffect } from 'react';

export function useLanguageSwitcher() {
  const { saveSettings, userSettings } = useUserSettings();

  const { next: getNextLocale } = useRolodex<SupportedLanguageIsoCode>(unique([userSettings.locale, 'en', 'de', 'zh_CN', 'pt_BR']));

  const handler = React.useCallback(
    (event: KeyboardEvent) => {
      if (!event.ctrlKey) {
        return;
      }

      if (event.key === 'q') {
        const nextLocale = getNextLocale();
        const newSettings = { ...userSettings, locale: nextLocale };
        return saveSettings(newSettings);
      }
    },
    [getNextLocale, saveSettings, userSettings]
  );

  /**
   * Registers the following keyboard shortcuts:
   * CTRL+Q: to change the current language by pressing;
   */
  useEffect(() => {
    document.addEventListener('keydown', handler);

    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, [handler]);
}
