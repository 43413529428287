import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const VesselviewNormalIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <path d="M12.004 20C8.895 20 7.072 18.768 7 17.917l-.758-9.09C7.664 9.564 9.667 10 12.004 10c2.313 0 4.328-.451 5.762-1.202-.244 2.903-.606 7.218-.759 9.121-.068.851-1.89 2.081-5.003 2.081M6.184 5.561C6.893 4.785 9.12 4 12.003 4c2.886 0 5.114.785 5.823 1.562.073.079.192.229.181.348-.066.73-2.186 2.09-6.004 2.09C8.398 8 6.076 6.784 6 5.913c-.01-.121.11-.272.183-.352M12.004 2c-3.202 0-6.065.867-7.296 2.21-.65.71-.74 1.41-.7 1.873v.004l.001.004 1 11.993C5.198 20.353 8.14 22 12.003 22c3.87 0 6.812-1.648 6.997-3.92.24-2.979.988-11.864.998-11.985L20 6.09c.042-.464-.046-1.165-.696-1.878C18.075 2.868 15.21 2 12.004 2" />
        </g>
      </g>
    </svg>
  );
};

VesselviewNormalIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
