import type { AriaButtonProps } from '@react-types/button';
import type { PressEvent } from '@react-types/shared';
import { settings } from '@rhim/design';
import React from 'react';
import { useButton, useFocusRing, useHover } from 'react-aria';
import styled, { css } from 'styled-components';

import { Tooltip, TooltipPlacement } from '../Tooltip';

interface Props extends Omit<AriaButtonProps, 'children'> {
  className?: string;
  icon: React.ReactElement;
  tooltip: string;
  /**
   * @default "right"
   */
  tooltipPlacement?: TooltipPlacement;
  onPress: (event: PressEvent) => void;
}

const ButtonCircular: React.ChildlessComponent<Props> = (props) => {
  const { className, icon, tooltip, tooltipPlacement = 'right', ...rest } = props;
  const ref = React.useRef<HTMLButtonElement>(null);
  const { buttonProps, isPressed } = useButton(rest, ref);
  const { hoverProps, isHovered } = useHover({ isDisabled: rest.isDisabled });
  const { isFocusVisible, focusProps } = useFocusRing({ autoFocus: rest.autoFocus });

  const iconWithProps = React.cloneElement(icon, {
    fill: (() => {
      switch (true) {
        case rest.isDisabled:
          return settings.colors.Primary.Grey_3;
        case isHovered && !isPressed:
          return settings.colors.Primary.Blue_8;
        case isPressed:
          return settings.colors.Monochromatic.White;
        default:
          return settings.colors.Primary.Blue_9;
      }
    })(),
  });

  return (
    <Tooltip title={tooltip} placement={tooltipPlacement}>
      <Button
        className={className}
        {...buttonProps}
        {...hoverProps}
        {...focusProps}
        aria-label={tooltip}
        isPressed={isPressed}
        isHovered={isHovered}
        isFocusVisible={isFocusVisible}
        ref={ref}
      >
        {iconWithProps}
      </Button>
    </Tooltip>
  );
};

const Button = styled.button<{ isPressed: boolean; isHovered: boolean; isFocusVisible: boolean }>`
  --size: 40px;

  cursor: pointer;
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background-color: ${settings.colors.Monochromatic.White};
  box-shadow: 0 2px 16px 0 rgba(61, 85, 103, 0.1);
  border: solid 1px ${settings.colors.Primary.Grey_3};
  ${(props) =>
    props.isPressed &&
    css`
      border-color: ${settings.colors.Primary.Blue_9};
      background-color: ${settings.colors.Primary.Blue_9};
    `}
  ${(props) =>
    props.disabled === true &&
    css`
      pointer-events: none;
    `}

  ${(props) =>
    props.isFocusVisible &&
    css`
      /**
     * Make focus ring circular, just like the entire button.
     * @see https://css-tricks.com/having-a-little-fun-with-custom-focus-styles/#apply-a-box-shadow
     */
      box-shadow: 0 0 0 2px ${settings.FOCUS_RING_COLOR};
    `}

  &:disabled {
    cursor: auto;
  }
`;

ButtonCircular.whyDidYouRender = true;

export default React.memo(ButtonCircular);
