/**
 * Upload API class.
 */

import { assert, isDictionaryLike } from '@rhim/utils';
import { AxiosError, CancelToken } from 'axios';

import { axiosApiInstance } from '../utilities/login';

export enum ErrorCode {
  Default = 0,
  InvalidFile = 1,
  InvalidFileSize = 2,
  InvalidEmptyFile = 3,
  UnsuccessfulFileTransfer = 4,
}

interface Config {
  uploadEndpointURL: string;
  formData: FormData;
  cancelToken?: CancelToken;
}

export type SuccessResponseTypeDefault = { type: 'default'; campaign?: string; vesselId?: string; heat?: number };
export type SuccessResponseTypePointCloud = { type: 'point-cloud'; pointCloudFileId: string };
export type SuccessResponseType = SuccessResponseTypeDefault | SuccessResponseTypePointCloud;

export default class Api {
  /**
   * Executes API POST operation for given URL.
   * @param config Config object.
   * @param onProgress Progress callback function for progress upload.
   * @param onSuccess Success callback function.
   * @param onError Error callback function.
   * @returns Promise.
   */
  static postUpload(
    { uploadEndpointURL, formData, cancelToken }: Config,
    onProgress?: (percent: number) => void,
    onSuccess?: (data: SuccessResponseType) => void,
    onError?: (error: AxiosError<APO.ErrorResponse>) => void,
    headers?: { [key: string]: string }
  ): Promise<APO.AnyAjaxResponse> {
    return axiosApiInstance
      .post<SuccessResponseType>(uploadEndpointURL, formData, {
        headers: {
          'content-type': 'multipart/form-data',
          ...headers,
        },
        cancelToken,
        onUploadProgress: (event: ProgressEvent) => {
          const percent = Math.floor((event.loaded / event.total) * 100);
          onProgress?.(percent);
        },
      })
      .then((response) => (onSuccess ? onSuccess(response.data) : null))
      .catch((error) => {
        if (!isErrorResponse(error as AxiosError)) {
          // In case of a network error ( no "detail", "status","title","type" properties ), convert the error to an APO.ErrorResponse error
          error = {
            ...error,
            response: {
              ...error.response,
              data: {
                type: 'Unknown',
                status: ErrorCode.UnsuccessfulFileTransfer,
                title: error.message,
                detail: error.message,
              },
            },
          };
        }
        assert(isErrorResponse(error as AxiosError<unknown>), 'Expected the backend error to be an ErrorResponse');
        onError?.(error as AxiosError<APO.ErrorResponse>);
      });
  }
}

function isErrorResponse(error: AxiosError<unknown>): error is AxiosError<APO.ErrorResponse> {
  const data = error.response?.data;

  return isDictionaryLike(data) && 'detail' in data && 'status' in data && 'title' in data && 'type' in data;
}
