import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  backgroundColor?: Property.Color;
  foregroundColor?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const OpsStateDeactivatedIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  backgroundColor,
  foregroundColor,
  size,
  title,
  role,
  ...rest
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <path fillRule="evenodd" clipRule="evenodd" d="M30 16.5c0 7.732-6.268 14-14 14s-14-6.268-14-14 6.268-14 14-14 14 6.268 14 14z" fill={backgroundColor} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m17.414 16.5 4.293-4.293a.999.999 0 1 0-1.414-1.414L16 15.086l-4.293-4.293a.999.999 0 1 0-1.414 1.414l4.293 4.293-4.293 4.293a.999.999 0 1 0 1.414 1.414L16 17.914l4.293 4.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L17.414 16.5z"
          fill={foregroundColor}
        />
      </g>
    </svg>
  );
};

OpsStateDeactivatedIcon.defaultProps = {
  size: '32',
  backgroundColor: settings.colors.Operational.State_Notif_Grey_2,
  foregroundColor: settings.colors.Monochromatic.White,
};
