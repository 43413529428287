export enum Invariants {
  NoVesselsFound = 'No vessels were found for the current customer',
  ResourceNotFound = 'Resource not found',
  NoRegionsSelected = 'No regions were selected',
  NoCampaignsFound = 'No campaigns were found for the current vessel',
  NoPredictionsFound = 'No predictions were found for the current vessel',
  NoAccessToCustomer = "You don't have access to this customer",
  NoAccessToVessel = "You don't have access to this vessel",
  NoPageFound = 'No Page Found',
  NoMeasurementDataFound = 'No Measurement Data Available',
}
