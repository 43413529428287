/**
 * Note: Steps can be implemented with https://react-spectrum.adobe.com/react-aria/useBreadcrumbs.html
 */

import { settings } from '@rhim/design';
import { Steps as StepsAntd } from 'antd';
import React from 'react';
import styled from 'styled-components';

const Steps = styled(StepsAntd)`
  .ant-steps-item > .ant-steps-item-container {
    display: flex;

    & > .ant-steps-item-content > .ant-steps-item-title::after {
      height: 2px;
      background: ${settings.colors.Primary.Grey_4};
    }
  }

  &.ant-steps-small .ant-steps-item-title {
    font-family: ${settings.typography.FontFamily.Regular};
    font-size: ${settings.typography.FontSize.Medium};
  }

  .ant-steps-item-wait {
    --color: ${settings.colors.Primary.Grey_4};

    > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
      color: var(--color);
      font-family: ${settings.typography.FontFamily.Regular};
      font-size: ${settings.typography.FontSize.Medium};
    }

    .ant-steps-item-icon {
      background-color: transparent;
      border-color: rgba(0, 0, 0, 0.25);

      & > .ant-steps-icon {
        color: var(--color);
      }
    }
  }

  .ant-steps-item-process {
    --color: ${settings.colors.Primary.Grey_8};

    > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
      color: var(--color);
      font-family: ${settings.typography.FontFamily.Bold};
      font-size: ${settings.typography.FontSize.Medium};
    }

    .ant-steps-item-icon {
      background: var(--color);
      border-color: var(--color);
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-content > .ant-steps-item-title {
      color: ${settings.colors.Primary.Grey_8};
    }

    .ant-steps-item-icon {
      background: transparent;
      border-color: ${settings.colors.Primary.Grey_8};

      & > .ant-steps-icon {
        color: ${settings.colors.Primary.Grey_8};
      }
    }
  }

  .ant-steps-item-icon {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${settings.typography.FontFamily.Bold};
    border-width: 2px;
  }
`;

Steps.whyDidYouRender = true;
export default React.memo(Steps);
