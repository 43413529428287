import { settings } from '@rhim/design';
import { isDefined } from '@rhim/utils';
import React from 'react';
import styled from 'styled-components';

import { useLeftInBoundaries } from '../../hooks';
import { ScrollbarRef, ZoomRange } from './types';
import { getX, sortRange } from './utils';

interface Props {
  zoomRange: ZoomRange | null;
  formatRange: (from: number, to: number) => string;
  scrollbarRef: ScrollbarRef;
}

const DragZoomRange: React.FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  const { formatRange, zoomRange, scrollbarRef } = props;

  const { labelRef, left, wrapperRef } = useLeftInBoundaries({ scrollbarRef, x: getX(zoomRange) });

  if (!isDefined(zoomRange)) return null;
  const { dateRange } = zoomRange;
  if (!isDefined(dateRange.to)) return null;

  const sortedDateRange = sortRange(dateRange);

  return (
    <Wrapper ref={wrapperRef}>
      <TopLabel ref={labelRef} style={isDefined(left) ? { left } : { visibility: 'hidden' }}>
        {formatRange(sortedDateRange.from, sortedDateRange.to)}
      </TopLabel>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

const TopLabel = styled.div`
  position: relative;
  display: inline-block;
  font-size: ${settings.typography.FontSize.X_Small};
  line-height: ${settings.typography.LineHeight.Line_Height_16};
  background-color: ${settings.colors.Primary.Grey_8};
  padding: 0 ${settings.Spacing.Spacing_50};
  border-radius: 2px;
  color: ${settings.colors.Monochromatic.White};
  vertical-align: bottom;
`;

export default React.memo(DragZoomRange);
