import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const TrashIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <g>
              <path
                d="M13 6v7c0 1.657-1.12 3-2.5 3h-5C4.12 16 3 14.657 3 13V6h10zM9 0c1.654 0 3 1.346 3 3h2c.552 0 1 .447 1 1 0 .553-.448 1-1 1H2c-.552 0-1-.447-1-1 0-.553.448-1 1-1h2c0-1.654 1.346-3 3-3zm0 2H7c-.551 0-1 .448-1 1h4c0-.552-.449-1-1-1z"
                transform="translate(-462.000000, -124.000000) translate(446.000000, 112.000000) translate(16.000000, 12.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

TrashIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Grey_4,
};
