import { Button } from '@rhim/react';
import { ModalDialog } from '@rhim/react';
import { ensure, isDefined } from '@rhim/utils';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { Root } from 'react-dom/client';
import { createRoot } from 'react-dom/client';

import AppContext from '../../app/AppContext';

/**
 * Allows us to display a warning confirmation modal when the selected customer is about to change.
 * Used in the various pages of the Data-Center (Process-Data, Measurement-Data, Point-Cloud-Scans) :
 * If a file upload is in-progress and the user happens to try to change the currently selected customer via the top-left dropdown,
 * we can display a modal to warn the user that in doing this the uploading will be cancelled.
 *
 * @param shouldDisplayWarningModal True if a warning modal should appear when the selected customer is about to change
 * @param texts The various texts used in this modal
 */
export function useWarningModalOnCustomerSwitch(
  shouldDisplayWarningModal: boolean,
  texts: {
    headline: string;
    description: string;
    buttonLeaveAndCancelLabel: string;
    buttonStayOnPageLabel: string;
  }
) {
  const { setOnBeforeSelectedCustomerChange } = useContext(AppContext);
  const modalParentElementRef = useRef<HTMLDivElement>();
  const root = useRef<Root>();

  const closeModal = useCallback(() => {
    if (!isDefined(modalParentElementRef.current)) {
      return;
    }
    root.current?.unmount();
  }, [root]);

  const openModal = useCallback(() => {
    modalParentElementRef.current = document.createElement('div');

    return new Promise<boolean>((resolve) => {
      const handleStay = () => {
        closeModal();
        resolve(false);
      };
      const handleLeave = () => {
        closeModal();
        resolve(true);
      };

      root.current = createRoot(ensure(modalParentElementRef.current, 'Cannot render the modal because the parent element is missing'));
      root.current.render(
        <ModalDialog
          open={true}
          mode="informational"
          maskClosable={false}
          headline={texts.headline}
          description={texts.description}
          buttonPrimary={<Button size="small-40" onClick={handleStay} label={texts.buttonStayOnPageLabel} />}
          buttonCancel={<Button size="small-40" onClick={handleLeave} label={texts.buttonLeaveAndCancelLabel} />}
          onCancel={handleStay}
        />
      );
    });
  }, [closeModal, root, texts]);

  useEffect(() => {
    if (!shouldDisplayWarningModal) {
      closeModal();
      return;
    }

    // Set the App's context "onBeforeSelectedCustomerChange" used in the SelectCustomer.tsx so that
    // our warning modal may appear when the customer is about to change.
    setOnBeforeSelectedCustomerChange(() => openModal);

    return () => {
      // On unmount, reset the "onBeforeSelectedCustomerChange" to its default value of undefined
      setOnBeforeSelectedCustomerChange(undefined);
    };
  }, [closeModal, shouldDisplayWarningModal, setOnBeforeSelectedCustomerChange, openModal]);
}
