import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const OnlineReportIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g>
        <path
          fill={fill}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2zm0 2h12v10H2V3zm4.514.942a1 1 0 0 0-1.362.328l-2 3.2a1 1 0 1 0 1.696 1.06l1.48-2.367 3.157 1.894a1 1 0 0 0 1.255-.184l2-2.2a1 1 0 0 0-1.48-1.346L9.812 5.921 6.515 3.942zM3 11a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1zm8-1a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2h-1z"
        />
      </g>
    </svg>
  );
};

OnlineReportIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
