import type { FC } from 'react';
import React from 'react';

interface Props {
  id: string;
}

const SvgNodataComponent: FC<React.PropsWithChildren<Props>> = ({ id }) => {
  return (
    <g id={id} filter="url(#drop-shadow)">
      <circle r="7" fill="#ffffff" />
      <path stroke="#9b0043" strokeWidth="2" strokeLinecap="round" d="M-3,3L3,-3M-3,-3L3,3" />
    </g>
  );
};

SvgNodataComponent.whyDidYouRender = true;

export const SvgNodata = React.memo(SvgNodataComponent);
