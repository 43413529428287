import { settings } from '@rhim/design';
import { heatIcon } from '@rhim/test-ids';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const HeatIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest} data-test-id={heatIcon}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M8.5 14C7.122 14 6 12.856 6 11.45c0-1.562.792-2.786.825-2.838.068-.1.17-.166.278-.158.108.002.209.066.272.17.145.236.497.79.802 1.196.273-.79.357-1.958.365-2.416.003-.16.081-.302.204-.367.121-.067.264-.04.365.062C9.188 7.18 11 9.06 11 11.451 11 12.856 9.878 14 8.5 14m1.62-11.901C9.578 1.498 9.007.866 8.43.165c-.13-.155-.326-.206-.5-.13-.175.077-.292.268-.297.484 0 .032-.079 2.973-.911 5.146-.514-.958-1-2.515-1.173-3.158-.06-.217-.23-.366-.426-.374-.206-.03-.376.13-.446.34-.386 1.158-.85 1.984-1.298 2.78C2.67 6.52 2 7.714 2 9.779 2 13.325 4.58 16 8 16c3.533 0 6-2.461 6-5.986 0-3.617-1.798-5.61-3.88-7.915"
      />
    </svg>
  );
};

HeatIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Grey_6,
};
