import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const CameraNoTrackingIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="m10.587 2.584-.396-.64a1.33 1.33 0 0 0-1.134-.611H6.943c-.468 0-.9.233-1.134.61l-.86 1.39h-.282v-.667H3.333v.667h-.666c-.736 0-1.334.597-1.334 1.333v6.667c0 .152.026.297.072.433l2.73-2.73a4.004 4.004 0 0 1 4.9-4.9l1.552-1.552zM5.338 7.833a2.67 2.67 0 0 1 2.495-2.495L5.338 7.833zm5.323.334L8.167 10.66a2.67 2.67 0 0 0 2.494-2.494zm-3.697 3.697a4.004 4.004 0 0 0 4.9-4.9l2.73-2.73c.047.135.073.28.073.432v6.667c0 .736-.598 1.333-1.334 1.333H6.162l.802-.802zM14.471 1.528c.26.26.26.683 0 .943l-12 12a.667.667 0 0 1-.942-.943l12-12c.26-.26.682-.26.942 0z"
      />
    </svg>
  );
};

CameraNoTrackingIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Grey_6,
};
