import { RawData, SurfacePointCloudAxis } from '@rhim/icons/24';
import { SidebarLeft } from '@rhim/react';
import { isDefined } from '@rhim/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { usePersistedMenuState } from '../../../hooks';
import { useCrkPriviledges } from '../../../hooks/useCrkPriviledges';
import { ROUTES } from '../../../utilities';

interface LesItemsProps {
  createProps: (target: string) => {
    isSelected: boolean;
    onClick: () => void;
  };
  isExpanded?: boolean;
}

const LesItems: React.FC<LesItemsProps> = (props) => {
  const { t } = useTranslation(['aro-les']);
  const { createProps, isExpanded } = props;

  return (
    <>
      <SidebarLeft.Item
        icon={<RawData />}
        text={t('aro-les:sidebar.lesReport')}
        {...createProps(`${ROUTES.ARO_LES}/${ROUTES.ARO_REPORT}`)}
        isExpanded={isExpanded}
      />
      <SidebarLeft.Item
        icon={<SurfacePointCloudAxis />}
        text={t('aro-les:sidebar.lesPointCloudViewer')}
        {...createProps(`${ROUTES.ARO_LES}/${ROUTES.ARO_POINT_CLOUD}`)}
        isExpanded={isExpanded}
      />
    </>
  );
};

const CementSidebarComponent: React.FunctionComponent = () => {
  const [open, setOpen] = usePersistedMenuState();
  const location = useLocation();
  const navigate = useNavigate();

  const createProps = (target: string) => {
    const match = matchPath(
      {
        path: `${ROUTES.ARO_ROOT}/${target}`,
      },
      location.pathname
    );

    return {
      isSelected: isDefined(match),
      onClick: () => navigate({ pathname: target }),
    };
  };

  const toggleExpandedState = React.useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    setOpen(!open);
  }, [open, setOpen]);
  const { hasLesAccess } = useCrkPriviledges();

  return (
    <SWrapper>
      <SidebarLeft isExpanded={open} onToggleExpandedState={toggleExpandedState}>
        {hasLesAccess && <LesItems createProps={createProps} />}
      </SidebarLeft>
    </SWrapper>
  );
};

export const CementSidebar = React.memo(CementSidebarComponent);

const SWrapper = styled.div`
  height: 100%;
`;
