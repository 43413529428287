export class UnreachableCase extends Error {
  constructor(value: never, message = `This function requires all scenarios to be addressed explicitly. This case (${value}) was not accounted for.`) {
    super(message);

    Object.setPrototypeOf(this, UnreachableCase.prototype);

    if ('captureStackTrace' in Error) {
      Error.captureStackTrace(this, UnreachableCase);
    }
  }
}

export class NullDereferenced extends Error {
  static readonly message = 'This value was promised to never be undefined.';
  constructor(message = NullDereferenced.message) {
    super(message);

    Object.setPrototypeOf(this, NullDereferenced.prototype);

    if ('captureStackTrace' in Error) {
      Error.captureStackTrace(this, NullDereferenced);
    }
  }
}

export class ProgrammerError extends Error {
  static readonly message = 'This error should never be thrown. If it is, it is a bug in the code.';

  constructor(message = ProgrammerError.message) {
    super(message);

    Object.setPrototypeOf(this, ProgrammerError.prototype);

    if ('captureStackTrace' in Error) {
      Error.captureStackTrace(this, ProgrammerError);
    }
  }
}
