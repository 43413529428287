import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  backgroundColor?: Property.Color;
  foregroundColor?: Property.Color;
  title?: string;
  role?: string;
}

export const LoaderInvertedIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  size,
  backgroundColor,
  foregroundColor,
  title,
  role,
  ...rest
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <defs>
        <path id="u28y16x73a" d="M0 0L10 0 10 16.667 0 16.667z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-263 -377) translate(119 361) translate(144 16) translate(2 2)">
                  <mask id="gvekogl2zb" fill="#fff">
                    <use xlinkHref="#u28y16x73a" />
                  </mask>
                  <path
                    fill={backgroundColor}
                    d="M2.625 15.255c.412-.37 1.045-.334 1.412.078C2.724 13.866 2 11.972 2 10c0-4.411 3.589-8 8-8-.552 0-1-.447-1-1 0-.553.448-1 1-1C4.486 0 0 4.486 0 10c0 2.465.904 4.833 2.547 6.667-.368-.412-.334-1.044.078-1.412"
                    mask="url(#gvekogl2zb)"
                  />
                </g>
                <path
                  fill={foregroundColor}
                  d="M10 20c-2.841 0-5.558-1.215-7.453-3.333-.368-.412-.334-1.044.078-1.412.412-.369 1.045-.334 1.412.078C5.554 17.028 7.727 18 10 18c4.411 0 8-3.589 8-8s-3.589-8-8-8c-.552 0-1-.447-1-1 0-.553.448-1 1-1 5.514 0 10 4.486 10 10s-4.486 10-10 10"
                  transform="translate(-263 -377) translate(119 361) translate(144 16) translate(2 2)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

LoaderInvertedIcon.defaultProps = {
  size: '24',
  backgroundColor: settings.colors.Primary.Grey_7,
  foregroundColor: settings.colors.Operational.State_Blue_2,
};
