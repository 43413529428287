import { settings } from '@rhim/design';
import { assert, isDefined } from '@rhim/utils';
import { ScaleBand } from 'd3-scale';

interface WallPlotDefinition {
  xMax: number;
  xMin: number;
  yMin: number;
  yMax: number;
  area: number;
}

interface Point {
  x: number;
  y: number;
}

interface HeatmapPoint extends Point {
  value: number;
}

export interface IMapArea {
  areaNr: number;
  isCritical?: boolean;
  shape: 'poly' | 'rect' | 'circle';
  coords: number[];
  preFillColor: string;
  fillColor: string;
  strokeColor?: string;
  xMin: number;
  yMin: number;
  xMax: number;
  yMax: number;
}

type Scale = ScaleBand<string>;

export type Area = WallPlotDefinition & { isCritical?: boolean };

export const getWallPlotDef = (data: HeatmapPoint[], areas: Area[], xScale: Scale, yScale: Scale): Area[] => {
  const maxX = data[data.length - 1]?.x ?? 0;
  const maxY = data[data.length - 1]?.y ?? 0;

  const plots: Area[] = [];
  areas.forEach((area: Area, index) => {
    const { xMin, xMax, yMin, yMax, isCritical } = area;

    const defaultArea = {
      isCritical,
      xMin: xScale(xMin.toString()) ?? 0,
      xMax: xScale(xMax.toString()) ?? 0,
      yMin: yScale((maxY - yMin).toString()) ?? 0,
      yMax: yScale((maxY - yMax).toString()) ?? 0,
      area: index,
    };

    /**
     * If the xMax is lower than the xMin, then it means that the plot has to be split.
     * We split the plot in 2 other plots as following:
     * Plot 1. xMax => maximum length of the heatmap.
     * Plot 2. 0 => xMin.
     * E.g: xMax: 6, xMin: 66 (0-PLOT1-6-----66-PLOT2-72), plot 1: 66----72, plot 2: 0----6.
     */
    if (xMin > xMax) {
      plots.push({ ...defaultArea, xMax: xScale(maxX.toString()) ?? 0 });
      plots.push({ ...defaultArea, xMin: xScale('0') ?? 0 });
    } else {
      plots.push(defaultArea);
    }
  });

  return plots;
};

/**
 * Crops the specific parts from a canvas.
 * @param canvas HTML Canvas element.
 * @param data Wallplot data for extracting the points where the crop is needed.
 */
export const extractAreas = (canvas: HTMLCanvasElement, data: WallPlotDefinition[]): { area: number; image: ImageData }[] =>
  data.map(({ xMin, yMin, xMax, yMax, area }: WallPlotDefinition) => {
    const width = xMax - xMin;
    const height = yMax - yMin;
    const ctx = canvas.getContext('2d');

    assert(isDefined(ctx), 'We target browsers that support 2D rendering context.');

    return { area, image: ctx.getImageData(xMin, yMin, width, height) };
  });

/**
 * Clones a canvas element.
 * @param oldCanvas Old canvas element to clone.
 * @param filter Filter function to apply to the clone.
 * @returns Cloned canvas.
 */
export const cloneCanvas = (oldCanvas: HTMLCanvasElement, filter?: string): HTMLCanvasElement => {
  const newCanvas = createCanvas(oldCanvas.width, oldCanvas.height);
  const context = newCanvas.getContext('2d');

  assert(isDefined(context), 'We target browsers that support 2D rendering context.');

  if (isDefined(filter)) {
    context.filter = filter;
  }

  context.drawImage(oldCanvas, 0, 0);

  return newCanvas;
};

/**
 * Creates a canvas element.
 * @param width Given width.
 * @param height Given height.
 * @returns A canvas element.
 */
export const createCanvas = (width: number, height: number): HTMLCanvasElement => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  return canvas;
};
/**
 * Generates the area(s) definition.
 * @param points Heatmap poitns.
 * @param hotSpots hotspots array that matche an area.
 * @param xScale X scale band function.
 * @param yScale Y scale band function.
 * @returns Area definitions.
 */
export const getAreas = (data: Area[]): IMapArea[] => {
  return data.map(({ xMin, yMin, xMax, yMax, isCritical, area }: Area) => {
    return {
      areaNr: area,
      isCritical: isCritical,
      shape: 'poly',
      xMin: xMin,
      yMin: yMin,
      coords: [xMin, yMin, xMax, yMin, xMax, yMax, xMin, yMax, xMin, yMin],
      preFillColor: 'transparent',
      lineWidth: 0,
      fillColor: 'transparent',
      xMax: xMax,
      yMax: yMax,
    };
  });
};

/**
 * Sets the stroke for the areas based on the area state (highlighted or not).
 * @param areas Areas array.
 * @param [areaNr] Highlighted area number (optional).
 * @returns new area array.
 */
export const setAreaStroke = (areas: IMapArea[], areaNr = 0) => {
  const alertColor = settings.colors.Operational.State_Alert_Red_3;
  const highlightColor = settings.colors.Primary.Grey_8;

  if (areaNr) {
    const plots = areas.filter((mapArea) => mapArea.areaNr === areaNr - 1);
    return plots.map((plot) => ({ ...plot, strokeColor: plot.isCritical ?? false ? alertColor : highlightColor }));
  }

  return areas.map((mapArea: IMapArea) => ({ ...mapArea, strokeColor: mapArea.isCritical ?? false ? alertColor : highlightColor }));
};
