import { settings } from '@rhim/design';
import React, { FC } from 'react';
import styled from 'styled-components';

import { AreaLayout } from '../WallplotHeatmap/utils';

interface AreaLabelsCanvasProps {
  areasLayout: AreaLayout[];
}
const AreaLabelsCanvas: FC<React.PropsWithChildren<AreaLabelsCanvasProps>> = ({ areasLayout }) => {
  return (
    <>
      {areasLayout.map((areaLayout, index) => {
        const offsetLeft = areaLayout.startX + areaLayout.halfWidth + 'px';
        const offsetTop = areaLayout.startY + areaLayout.halfHeight + 'px';
        return (
          <SAreaLabel key={index} style={{ top: offsetTop, left: offsetLeft }}>
            {areaLayout.label}
          </SAreaLabel>
        );
      })}
    </>
  );
};

export default React.memo(AreaLabelsCanvas);

const SAreaLabel = styled.span`
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
  border-radius: 3px;
  color: white;
  background-color: ${settings.colors.Primary.Grey_8};
  padding: 1px ${settings.Spacing.Spacing_50};
  font-size: ${settings.typography.FontSize.X_Small};
  font-family: ${settings.typography.FontFamily.Bold};
`;
