import { useMemoCompare } from '@rhim/react';
import { hasElements, head, isDefined } from '@rhim/utils';
import { join } from 'lodash';
import * as React from 'react';
import useSessionStorageState from 'use-session-storage-state';

import { STORAGE_KEYS } from '../utilities';
import { LOCAL_STORAGE_PREFIX_APO } from '../utilities/storage';

type PersistedValue = UUID | null;

function useSelectedVesselId(initialState: PersistedValue) {
  return useSessionStorageState(join([LOCAL_STORAGE_PREFIX_APO, STORAGE_KEYS.SELECTED_VESSEL_ID], ''), {
    defaultValue: initialState,
  });
}

export function usePersistedVessel(
  vessels: APO.VesselV2[],
  initialValue?: PersistedValue
): [PersistedValue, React.Dispatch<React.SetStateAction<PersistedValue>>] {
  const [persistedVesselId, setPersistedVesselId] = useSelectedVesselId(initialValue ?? null);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const getNextCorrectValue = React.useCallback((persistedVesselId: PersistedValue, vessels: APO.VesselV2[]): PersistedValue => {
    if (!hasElements(vessels)) {
      return null;
    } else {
      const persistedVessel = vessels.find((vessel) => vessel.id === persistedVesselId);
      const isPersistedVesselIdValid = isDefined(persistedVessel);

      if (isPersistedVesselIdValid) {
        return persistedVesselId;
      } else {
        const firstAvailableVessel = head(vessels);

        return firstAvailableVessel.id;
      }
    }
  }, []);

  const currentValue = React.useMemo(() => {
    return getNextCorrectValue(persistedVesselId, vessels);
  }, [getNextCorrectValue, persistedVesselId, vessels]);

  React.useDebugValue(`Currently selected vessel: ${currentValue}`);

  React.useEffect(() => {
    const nextValue = getNextCorrectValue(persistedVesselId, vessels);

    if (nextValue !== persistedVesselId) {
      setPersistedVesselId(nextValue);
    }
  }, [persistedVesselId, setPersistedVesselId, getNextCorrectValue, vessels]);

  const memoizedValue: [PersistedValue, React.Dispatch<React.SetStateAction<PersistedValue>>] = useMemoCompare(
    [currentValue, setPersistedVesselId],
    ([previousId], [nextId]) => {
      return previousId === nextId;
    }
  );

  return memoizedValue;
}
