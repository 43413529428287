import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const UserIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M12 22c-2.563 0-4.9-.964-6.67-2.55l-.077-.069-.116-.108-.082-.078c-.136-.131-.268-.266-.397-.405l-.052-.057c-.115-.126-.227-.256-.336-.388l-.058-.072-.102-.128L4 18l-.076-.102-.057-.078c-.167-.234-.325-.475-.473-.723l-.062-.108-.089-.156-.042-.078c-.03-.055-.06-.11-.088-.166l-.024-.046-.056-.112-.063-.128-.026-.057c-.124-.262-.236-.53-.336-.805l-.03-.08c-.053-.151-.103-.304-.15-.458l-.05-.17-.032-.114-.037-.141-.037-.152c-.067-.283-.123-.57-.165-.86-.022-.149-.04-.297-.056-.447l-.013-.137-.01-.125c-.004-.056-.008-.11-.01-.166C2.005 12.395 2 12.198 2 12 2 6.477 6.477 2 12 2s10 4.477 10 10c0 .198-.006.395-.017.591l-.011.166-.01.125-.013.137c-.015.15-.034.298-.056.446-.029.198-.064.395-.104.589l-.032.147-.023.098c-.014.06-.028.12-.044.18l-.036.14-.035.126-.044.147c-.047.158-.099.314-.154.469l-.028.079c-.067.183-.14.363-.217.54l-.008.02-.102.226-.074.155-.04.08-.071.14c-.03.059-.061.117-.093.175l-.02.038-.1.175-.062.107c-.148.25-.307.492-.475.726l-.049.068-.12.162-.053.068-.121.154c-.02.024-.04.047-.058.071-.106.129-.216.255-.328.38-.108.118-.22.234-.333.348l-.122.12-.08.076-.121.113-.08.073-.154.134-.054.046-.156.13-.081.065-.141.11L18 20l-.01.008C16.32 21.26 14.247 22 12 22zm3-7H9c-1.44 0-2.642 1.014-2.933 2.366C7.531 18.984 9.647 20 12 20c2.353 0 4.469-1.016 5.933-2.633-.28-1.298-1.396-2.283-2.757-2.362L15 15zM12 5C9.79 5 8 6.79 8 9s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z"
      />
    </svg>
  );
};

UserIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
