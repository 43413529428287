import { settings } from '@rhim/design';
import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import type { CylindricalSectionCutsMetadata, PlotData } from 'typings/internal/sections';
import { WallProps } from 'typings/internal/sections';

import { HorizontalSlice } from './HorizontalSlice';
import { Legend } from './Legend';
import { VerticalSlice } from './VerticalSlice';

interface LadleSectionsProps {
  className?: string;
  plotData: PlotData;
  meta: CylindricalSectionCutsMetadata;
  heatNumber: number;
}

const LadleSectionsComponent: FC<React.PropsWithChildren<LadleSectionsProps>> = (props) => {
  const { plotData, meta, className, heatNumber } = props;

  const [horizontalSliceIndex, setHorizontalSliceIndex] = useState<number>(0);
  const [selectedAngle, setSelectedAngle] = useState<number>(0);
  const [horizontalIndex, setHorizontalIndex] = useState<number>(0);
  const [verticalIndex, setVerticalIndex] = useState<number>(0);

  const horizontalSliceData = useMemo<WallProps[]>(() => {
    return Object.entries(plotData).map((item) => {
      const data = item[1][horizontalSliceIndex];
      return {
        angle: Number(item[0]),
        depth: data ? data.depth : null,
        radius: data ? data.radius : null,
        rbl: data ? data.rbl : null,
        wear: data ? data.wear : null,
      } as WallProps;
    });
  }, [plotData, horizontalSliceIndex]);

  // const minMaxDepth: [number, number] = useMemo(() => [0, meta.maxDepth], [meta]);

  useEffect(() => {
    setSelectedAngle(meta.angleStep * horizontalIndex);
  }, [horizontalIndex, meta.angleStep]);

  useEffect(() => {
    setHorizontalSliceIndex(verticalIndex);
  }, [verticalIndex]);

  return (
    <SWrapper className={className}>
      <Legend heatNumber={heatNumber} referenceHeat={undefined} />
      <SSlicesWrapper>
        <VerticalSlice heatNumber={heatNumber} plotData={plotData} selectedAngle={selectedAngle} setVerticalIndex={setVerticalIndex} meta={meta} />
        <HorizontalSlice heatNumber={heatNumber} slicedData={horizontalSliceData} setHorizontalIndex={setHorizontalIndex} meta={meta} />
      </SSlicesWrapper>
    </SWrapper>
  );
};

LadleSectionsComponent.whyDidYouRender = true;

export const LadleSections = React.memo(LadleSectionsComponent);

const SWrapper = styled.div`
  width: 100%;
  height: 100%;
  font-family: ${settings.typography.FontFamily.Regular};
  color: ${settings.colors.Primary.Grey_8};
  padding: ${settings.Spacing.Spacing_700} ${settings.Spacing.Spacing_300} ${settings.Spacing.Spacing_300} ${settings.Spacing.Spacing_300};
  display: grid;
  grid-template-rows: auto 1fr;
`;

const SSlicesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
