import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const CampaignIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M19.085 19c-1.814 1.85-4.34 3-7.135 3-2.796 0-5.321-1.15-7.136-3zM5.949 13v4H3.3c-.696-1.2-1.155-2.552-1.3-4h3.95zm10.001 0v4h-8v-4h8zm5.95 0c-.145 1.448-.605 2.8-1.299 4H17.95v-4zM10.95 7v4H2c.143-1.448.603-2.8 1.299-4h7.65zm9.65 0c.695 1.2 1.155 2.552 1.3 4h-8.95V7zm-8.65-5c2.795 0 5.32 1.15 7.135 3H4.815c1.813-1.85 4.338-3 7.134-3z"
      />
    </svg>
  );
};

CampaignIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
