import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const CopyIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" role={role} {...rest} xmlns="http://www.w3.org/2000/svg">
      <rect x="6.5" y="5" width="6" height="9" rx="1" stroke={fill} fill={fill} strokeWidth="2" />
      <path fill={fill} d="M11.5 3a1 1 0 1 0 0-2v2zm-9 8a1 1 0 1 0 2 0h-2zm9-10h-7v2h7V1zm-9 2v8h2V3h-2zm2-2a2 2 0 0 0-2 2h2V1z" />
    </svg>
  );
};

CopyIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
