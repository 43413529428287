import { settings } from '@rhim/design';
import { isDefined } from '@rhim/utils';
import styled from 'styled-components';

import { DEFAULT_MARGIN } from './constants';

interface WrapperProps {
  width?: number;
  height?: number;
}

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  ${({ width }) => isDefined(width) && `width: ${width}px`};
  ${({ height }) => isDefined(height) && `height: ${height}px`};
`;

interface ContainerProps {
  top?: number;
  left?: number;
  width?: number;
  height?: number;
}

export const Group = styled.div<ContainerProps>`
  position: absolute;
  ${({ top }) => isDefined(top) && `top: ${top}px`};
  ${({ left }) => isDefined(left) && `left: ${left}px`};
  ${({ width }) => isDefined(width) && `width: ${width}px`};
  ${({ height }) => isDefined(height) && `height: ${height}px`};
`;

export const Svg = styled.svg<WrapperProps>`
  overflow: visible;
  display: block;
`;

interface AreaProps {
  width: number;
  height: number;
}

export const AreaBackground = styled.div<AreaProps & { fill?: string }>`
  position: absolute;
  background-color: ${settings.colors.Primary.Grey_1};
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  fill: ${(props) => (isDefined(props.fill) ? props.fill : settings.colors.Primary.Grey_1)};
`;

export const AreaFrame = styled.div<AreaProps>`
  position: absolute;
  top: -1px;
  border-width: 1px 1px 0 0;
  border-color: ${settings.colors.Primary.Grey_2};
  border-style: solid;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  pointer-events: none;
`;

export const TrackHorizontal = styled.div`
  background-color: ${settings.colors.Primary.Grey_2};
  height: 6px;
  right: 2px;
  bottom: 0;
  left: 2px;
  border-radius: 3px;
`;

export const ThumbHorizontal = styled.div`
  border-radius: inherit;
  background-color: ${settings.colors.Primary.Grey_4};
  cursor: pointer;
`;

export const ChartUI = styled.div`
  display: flex;
  padding-right: ${DEFAULT_MARGIN.right}px;
  padding-left: ${DEFAULT_MARGIN.left}px;
  justify-content: end;
`;
