import type { Icon } from '@rhim/icons/types';
import { AxisRight as VisxAxisRight } from '@visx/axis';
import { Group } from '@visx/group';
import React from 'react';

import { AXIS_RIGHT_STYLES } from './constants';
import Ticks from './Ticks';

interface Props extends Omit<React.ComponentProps<typeof VisxAxisRight>, 'children'> {
  AxisIcon?: React.FunctionComponent<Icon>;
}

const AxisRight: React.ChildlessComponent<Props> = (props) => {
  const { AxisIcon, left = 0, top = AXIS_RIGHT_STYLES.top, ...rest } = props;

  return (
    <Group top={top - AXIS_RIGHT_STYLES.top} left={left}>
      <VisxAxisRight ticksComponent={Ticks} {...AXIS_RIGHT_STYLES} {...rest} />
    </Group>
  );
};

AxisRight.whyDidYouRender = true;

export default React.memo(AxisRight);
