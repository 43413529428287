import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const SustainabilityIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      role={role}
      {...rest}
    >
      {isDefined(title) ? <title>{title}</title> : null}
      <mask id="mask0_608_10269" maskUnits="userSpaceOnUse" x="-1" y="-1" width="34" height="34">
        <rect x="-0.25" y="-0.25" width="32.5" height="32.5" fill={fill} stroke="black" strokeWidth="0.5" />
      </mask>
      <g mask="url(#mask0_608_10269)">
        <path
          fill={fill}
          stroke={fill}
          strokeWidth="0.5"
          d="M4.56712 14.3587L4.5671 14.3588C4.02302 15.7535 3.75 17.1678 3.75 18.6003C3.75 20.0352 4.04083 21.4244 4.62121 22.7662C5.19982 24.106 6.00071 25.2879 7.02322 26.3104C8.04781 27.335 9.26486 28.1365 10.6715 28.7155L10.6716 28.7156C12.0786 29.2938 13.5219 29.5837 15 29.5837C16.4117 29.5837 17.7941 29.3043 19.1458 28.7475C20.5027 28.189 21.7795 27.3078 22.9768 26.1104C23.8008 25.2864 24.4893 24.266 25.0446 23.0538L25.0447 23.0537C25.5988 21.8431 26.0259 20.434 26.3283 18.8293C26.6316 17.2259 26.8162 15.4298 26.8832 13.4421C26.9502 11.4537 26.8943 9.27778 26.716 6.91484L26.7 6.7035L26.4889 6.68465C23.9922 6.46173 21.732 6.38339 19.7091 6.45046C17.6868 6.51748 15.883 6.71286 14.2991 7.0381L14.2989 7.03813C12.7173 7.36397 11.3415 7.80884 10.1748 8.37544C9.00872 8.94128 8.02351 9.62326 7.22322 10.4235C6.0026 11.6442 5.11484 12.9557 4.56712 14.3587ZM12.251 18.5119C10.7975 20.0606 9.60878 21.6761 8.68663 23.3586C8.38347 22.9655 8.034 22.3842 7.63908 21.6035C7.16019 20.6559 6.91667 19.5461 6.91667 18.267C6.91667 17.391 7.09821 16.4771 7.46653 15.5238C7.82818 14.5877 8.49329 13.6273 9.47678 12.6438C10.2244 11.8961 11.1913 11.3028 12.3854 10.8686C13.591 10.4302 14.8634 10.112 16.2032 9.91431C17.5473 9.716 18.8971 9.61111 20.2526 9.59965C21.5207 9.58975 22.6679 9.60365 23.6943 9.64126C23.7927 11.8249 23.7667 13.7087 23.6178 15.2943C23.463 16.9408 23.2372 18.3376 22.9419 19.4873C22.6447 20.6417 22.2956 21.5637 21.8988 22.2606L21.8987 22.2607C21.4945 22.9712 21.1019 23.5115 20.7232 23.8902C19.8311 24.7823 18.9025 25.4229 17.9384 25.8197C16.9667 26.2187 15.9878 26.417 15 26.417C14.3087 26.417 13.6346 26.3358 12.9772 26.1736L12.9768 26.1735C12.3805 26.0274 11.7961 25.7959 11.2233 25.4779C11.8164 23.2232 12.6844 21.2502 13.8247 19.5559L13.8248 19.5559C15.0011 17.8076 16.381 16.2408 17.9646 14.8551L17.6863 14.4444C15.6168 15.5016 13.8046 16.8575 12.2511 18.5119L12.251 18.5119Z"
        />
      </g>
    </svg>
  );
};

SustainabilityIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
