import { settings } from '@rhim/design';
import { assert } from '@rhim/utils/assert';
import { isDefined } from '@rhim/utils/is-defined';
import React, { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ExpandedState, SideFlyoutPanel } from '../SideFlyoutPanel/SideFlyoutPanel';
import { Tooltip } from '../Tooltip';

const PRIMARY_SIDE_FLYOUT_CONTAINER_PANEL_ID = 'primarySideFlyoutContainerPanelId';

export interface SideContextBarItem {
  panelId: string;
  icon: React.ReactElement;
  label: string;
  tooltip: string;
  isDisabled?: boolean;
}

interface BarItemProps {
  isDisabled: boolean;
  isExpanded: boolean;
  isSelected: boolean;
  item: SideContextBarItem;
  onClicked: (itemId: string) => void;
}
const BarItem: FC<React.PropsWithChildren<BarItemProps>> = ({ isDisabled, isSelected, item, isExpanded = false, onClicked }) => {
  const iconWithProps = React.cloneElement(item.icon, {
    fill: isDisabled ? settings.colors.Primary.Grey_2 : isSelected ? settings.colors.Monochromatic.White : settings.colors.Primary.Blue_9,
    flexShrink: 0,
  });
  return (
    <Tooltip title={item.tooltip} isShowing={!isExpanded} placement="left">
      <StyledBarItemWrapper isDisabled={isDisabled} isSelected={isSelected} onClick={() => !isDisabled && onClicked(item.panelId)}>
        <StyledIcon>{iconWithProps}</StyledIcon>
        {isExpanded && <span>{item.label}</span>}
      </StyledBarItemWrapper>
    </Tooltip>
  );
};

export interface BarItemsContainerProps extends ExpandedState {
  barItems: SideContextBarItem[];
  selectedBarItemId: string | null;
  onBarItemClicked: (barItemId: string) => void;
}
const BarItemsContainer: FC<React.PropsWithChildren<BarItemsContainerProps>> = ({ isExpanded = false, selectedBarItemId, barItems, onBarItemClicked }) => {
  return (
    <StyledBarItemsContainer>
      {barItems.map((barItem) => (
        <BarItem
          key={barItem.panelId}
          item={barItem}
          isDisabled={barItem.isDisabled ?? false}
          isExpanded={isExpanded}
          isSelected={selectedBarItemId === barItem.panelId}
          onClicked={() => onBarItemClicked(barItem.panelId)}
        />
      ))}
    </StyledBarItemsContainer>
  );
};

export interface SidebarState {
  selectedPanelId: string | null;
  pinnedPanelsIds: string[];
  expandedPanelsIds: string[];
}

export interface SideContextPanel extends ExpandedState {
  sideContextBarItem: SideContextBarItem;
}

export interface SideContextBarProps {
  children: React.ReactElement<SideContextPanel> | React.ReactElement<SideContextPanel>[];
  defaultSelectedBarItemId?: string | null;
  defaultExpandedItemsIds?: string[];
  defaultPinnedItemsIds?: string[];
  onSidebarStateChanged?: (state: SidebarState) => void;
}
export const SideContextBar: FC<React.PropsWithChildren<SideContextBarProps>> = React.memo(function SideContextBar({
  children,
  defaultSelectedBarItemId = null,
  defaultExpandedItemsIds = [],
  defaultPinnedItemsIds = [],
  onSidebarStateChanged,
}) {
  const [selectedBarItemId, setSelectedBarItemId] = useState<string | null>(defaultSelectedBarItemId);
  const [expandedBarItemIds, setExpandedBarItemIds] = useState<string[]>(defaultExpandedItemsIds);
  const [pinnedBarItemIds, setPinnedBarItemIds] = useState<string[]>(defaultPinnedItemsIds);

  useEffect(() => {
    onSidebarStateChanged?.({
      selectedPanelId: selectedBarItemId,
      expandedPanelsIds: expandedBarItemIds,
      pinnedPanelsIds: pinnedBarItemIds,
    });
  }, [selectedBarItemId, expandedBarItemIds, pinnedBarItemIds, onSidebarStateChanged]);

  const removePinnedPanel = React.useCallback(
    (panelId: string) => {
      if (!pinnedBarItemIds.includes(panelId)) {
        return;
      }
      setPinnedBarItemIds(pinnedBarItemIds.filter((barItemId) => barItemId !== panelId));
    },
    [pinnedBarItemIds]
  );

  const handleFlyoutCloseButtonClicked = React.useCallback(
    (panelId: string) => {
      if (selectedBarItemId === panelId) {
        setSelectedBarItemId(null);
      }
      removePinnedPanel(panelId);
    },
    [removePinnedPanel, selectedBarItemId]
  );

  const handleFlyoutPinButtonToggled = React.useCallback(
    (panelId: string, isPinned: boolean) => {
      if (isPinned && !pinnedBarItemIds.includes(panelId)) {
        setPinnedBarItemIds([...pinnedBarItemIds, panelId]);
      } else {
        removePinnedPanel(panelId);
      }
    },
    [pinnedBarItemIds, removePinnedPanel]
  );

  const handleSideFlyoutPanelExpanded = useCallback(
    (panelId: string, isExpanded: boolean) => {
      const newExpandedBarItemsIds = isExpanded
        ? [...expandedBarItemIds, panelId]
        : expandedBarItemIds.filter((expandedBarItemId) => expandedBarItemId !== panelId);
      setExpandedBarItemIds(newExpandedBarItemsIds);
    },
    [expandedBarItemIds]
  );

  const handleBarItemClicked = useCallback(
    (barItemId: string) => {
      setSelectedBarItemId(barItemId);
      if (!expandedBarItemIds.includes(barItemId)) {
        handleSideFlyoutPanelExpanded(barItemId, true);
      }
    },
    [expandedBarItemIds, handleSideFlyoutPanelExpanded]
  );

  const shownBarItemPanels = ((): ReactElement<SideContextPanel>[] => {
    const ret: ReactElement<SideContextPanel>[] = [];
    // show all pinned panels
    const panelIdsToShow: string[] = [...pinnedBarItemIds];
    // if there is a bar-item selected and is not currently pinned-down, then include that as well
    const childrenArray = React.Children.toArray(children) as React.ReactElement<SideContextPanel>[];
    if (isDefined(selectedBarItemId) && !panelIdsToShow.includes(selectedBarItemId)) {
      const selectedBarItem = childrenArray.find((panel) => panel.props.sideContextBarItem.panelId === selectedBarItemId);
      assert(isDefined(selectedBarItem), `Selected bar item with id : "${selectedBarItemId}" was not found`);
      panelIdsToShow.push(selectedBarItem.props.sideContextBarItem.panelId);
    }
    panelIdsToShow.forEach((panelId) => {
      const panel: ReactElement<SideContextPanel> | undefined = childrenArray.find((item) => item.props.sideContextBarItem.panelId === panelId);
      assert(isDefined(panel), `Bar item with id : "${panelId}" was not found`);
      ret.push(
        <SideFlyoutPanel
          key={panelId}
          isDefaultExpanded={expandedBarItemIds.includes(panelId)}
          onPanelExpanded={(isExpanded: boolean) => handleSideFlyoutPanelExpanded(panelId, isExpanded)}
          pinnedView={{
            panelId,
            isPinned: pinnedBarItemIds.includes(panelId),
            onCloseButtonClicked: handleFlyoutCloseButtonClicked,
            onPinButtonToggled: handleFlyoutPinButtonToggled,
          }}
        >
          {panel}
        </SideFlyoutPanel>
      );
    });
    return ret;
  })();

  const barItems = React.useMemo(() => {
    const items: SideContextBarItem[] = [];

    React.Children.forEach(children, (child) => {
      if (React.isValidElement(child)) {
        items.push(child.props.sideContextBarItem as SideContextBarItem); // TODO: better type inference
      }
    });

    return items;
  }, [children]);

  return (
    <StyledWrapper>
      {shownBarItemPanels}
      <SideFlyoutPanel
        isDefaultExpanded={expandedBarItemIds.includes(PRIMARY_SIDE_FLYOUT_CONTAINER_PANEL_ID)}
        onPanelExpanded={(isExpanded: boolean) => handleSideFlyoutPanelExpanded(PRIMARY_SIDE_FLYOUT_CONTAINER_PANEL_ID, isExpanded)}
      >
        <BarItemsContainer isExpanded={false} barItems={barItems} selectedBarItemId={selectedBarItemId} onBarItemClicked={handleBarItemClicked} />
      </SideFlyoutPanel>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  display: flex;
  height: 100%;
`;

const StyledBarItemsContainer = styled.div`
  padding: ${settings.Spacing.Spacing_50};
`;

const StyledIcon = styled.span`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;
const StyledBarItemWrapper = styled.div<{ isSelected: boolean; isDisabled: boolean }>`
  display: flex;
  flex-wrap: none;
  align-items: center;
  color: ${(props) =>
    props.isDisabled ? settings.colors.Primary.Grey_2 : props.isSelected ? settings.colors.Monochromatic.White : settings.colors.Primary.Grey_6};
  padding: ${settings.Spacing.Spacing_100};
  border-radius: 2px;
  margin-bottom: ${settings.Spacing.Spacing_50};
  background-color: ${(props) => (props.isSelected ? settings.colors.Primary.Blue_9 : 'transparent')};

  &:hover {
    cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
    background-color: ${(props) => (props.isDisabled ? 'transparent' : props.isSelected ? settings.colors.Primary.Blue_9 : settings.colors.Primary.Grey_2)};
  }

  span:nth-child(2) {
    margin-left: ${settings.Spacing.Spacing_100};
    font-size: ${settings.typography.FontSize.X_Small};
    font-family: ${settings.typography.FontFamily.Medium};
    flex-shrink: 0;
  }
`;
