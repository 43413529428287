/**
 * These characters you can interpolate in React.
 */
export enum HTMLEntity {
  NonBreakingSpace = '\u00A0',
}

/**
 * These are encoded characters you can use in raw strings.
 */
export enum SpecialCharacter {
  NonBreakingSpace = '\xa0', // &nbsp;
  Degrees = '\u00B0', // °
}
