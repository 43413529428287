import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const EnterIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        d="M9.5 2a1 1 0 0 0 0 2H12v5H4.414l1.293-1.293a1 1 0 0 0-1.414-1.414l-3 3a1 1 0 0 0 0 1.414l3 3a1 1 0 0 0 1.414-1.414L4.414 11H13a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H9.5z"
        fill={fill}
      />
    </svg>
  );
};

EnterIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Monochromatic.White,
};
