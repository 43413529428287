import { Configuration, FleetOverviewApi, ReportsApi, VolumesDashboardApi } from '@rhim/rest/fleetOverview';

import { axiosApiInstance } from '../../utilities';

const BASE_URL = '/api/fleetoverviewservice/v1';

const configuration: Configuration = {} as Configuration;

export const fleetOverview = new FleetOverviewApi(configuration, BASE_URL, axiosApiInstance);
export const reportsApi = new ReportsApi(configuration, BASE_URL, axiosApiInstance);
export const volumesDashboardApi = new VolumesDashboardApi(configuration, BASE_URL, axiosApiInstance);
