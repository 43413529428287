import { settings } from '@rhim/design';
import { SupportedLanguageIsoCode } from '@rhim/i18n';
import { LiningIcon, OpsStateCriticalIcon, OpsStateCriticalOutlineIcon } from '@rhim/icons/16';
import { isDefined } from '@rhim/utils';
import { ColumnsType, ColumnType } from 'antd/es/table';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { UnitSystem } from 'typings';

import { useLocalization } from '../../hooks';
import { convertLength, convertUnit, FormattedLength, MetricLengthUnit } from '../../partials';
import { DataTable } from '../DataTable';
import { LinearScale, SPanelHeading } from '../MappingPanel';
import { SideContextPanel } from '../SideContextBar/SideContextBar';
import { Tooltip } from '../Tooltip';
import { DataItem } from '../WallplotHeatmap/utils';
import TableHeaderShadow, { HEADER_BORDER_SIZE } from './TableHeaderShadow';

const DOM_TOP_HEADER_LABEL_ID = 'topHeader';

const getTooltip = (
  unitSystem: UnitSystem,
  locale: SupportedLanguageIsoCode,
  texts: ThicknessWearPanelTexts,
  hasCriticalThickness: boolean,
  thicknessCriticalValue: number,
  hasCriticalWear?: boolean,
  wearCriticalValue?: number
): string => {
  let ret = '';
  if (hasCriticalThickness) {
    ret = `${texts.thicknessEqualOrLessThanCriticalValue} (${convertLength(
      thicknessCriticalValue,
      MetricLengthUnit.mm,
      unitSystem,
      undefined,
      3,
      locale,
      true
    )})`;
  }
  if ((hasCriticalWear ?? false) && isDefined(wearCriticalValue)) {
    if (hasCriticalThickness) {
      ret += ` ${texts.and} `;
    }
    ret += `${texts.wearDeltaEqualOrLargerThanCriticalValue} (${convertLength(
      wearCriticalValue,
      MetricLengthUnit.mm,
      unitSystem,
      undefined,
      3,
      locale,
      true
    )}/${texts.heat})`;
  }
  return ret;
};

const getScaleColor = (scale: LinearScale, value: number): string => {
  const scaleIndex = Math.floor((value - scale.minValue) / scale.step);
  return scale.colors[scaleIndex] ?? 'black';
};

interface ThicknessProps {
  isExpanded: boolean;
  scale: LinearScale;
  thickness: number;
  dataRecord: DataItem;
  minimumThicknessValue: number | null;
  criticalThicknessValue: number;
  criticalWearValue: number;
  texts: ThicknessWearPanelTexts;
}
const Thickness: FC<React.PropsWithChildren<ThicknessProps>> = React.memo(function Thickness({
  isExpanded,
  scale,
  thickness,
  dataRecord,
  minimumThicknessValue,
  criticalThicknessValue,
  criticalWearValue,
  texts,
}) {
  const [localization] = useLocalization();
  const { unitSystem, locale } = localization;

  const hasCriticalThickness = thickness <= criticalThicknessValue;
  const hasCriticalWear = dataRecord.wear >= criticalWearValue;

  return (
    <Tooltip
      title={`${dataRecord.angle}° / ${convertLength(dataRecord.depth, MetricLengthUnit.m, unitSystem, undefined, 3, locale, true)}`}
      placement="left"
      isShowing={isExpanded}
    >
      <StyledThicknessWrapper isViewExpanded={isExpanded}>
        <StyledThicknessCell
          color={getScaleColor(scale, thickness)}
          isViewExpanded={isExpanded}
          isLowestValue={thickness === minimumThicknessValue}
          isCritical={hasCriticalThickness}
          className="thicknessCell"
        />
        <StyledThicknessValueSpan isExpanded={isExpanded}>
          <FormattedLength
            locale={locale}
            lengthValue={thickness}
            sourceUnit={MetricLengthUnit.mm}
            targetUnitSystem={unitSystem}
            maximumFractionDigits={isExpanded ? 3 : 2}
            showUnits={false}
          />
        </StyledThicknessValueSpan>
        {isExpanded && hasCriticalThickness && (
          <Tooltip title={getTooltip(unitSystem, locale, texts, true, criticalThicknessValue)} placement="top">
            <StyledCriticalThicknessIcon>
              <OpsStateCriticalIcon />
            </StyledCriticalThicknessIcon>
          </Tooltip>
        )}
        {!isExpanded && (hasCriticalThickness || hasCriticalWear) && (
          <Tooltip
            title={getTooltip(unitSystem, locale, texts, hasCriticalThickness, criticalThicknessValue, hasCriticalWear, criticalWearValue)}
            placement="top"
          >
            <StyledCriticalIcon>
              <OpsStateCriticalOutlineIcon />
            </StyledCriticalIcon>
          </Tooltip>
        )}
      </StyledThicknessWrapper>
    </Tooltip>
  );
});

interface WearProps {
  wear: number;
  criticalWearValue: number;
  texts: ThicknessWearPanelTexts;
}
const Wear: FC<React.PropsWithChildren<WearProps>> = React.memo(function Wear({ wear, criticalWearValue, texts }) {
  const [localization] = useLocalization();
  const { unitSystem, locale } = localization;
  const [isHovered, setHovered] = useState(false);

  const handleMouseEnter = useCallback(() => setHovered(true), []);

  const handleMouseLeave = useCallback(() => setHovered(false), []);

  const Component = <OpsStateCriticalIcon />;

  return (
    <StyledWearContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <StyledDeltaCharacter />
      <StyledSpan>
        <FormattedLength
          locale={locale}
          lengthValue={wear}
          sourceUnit={MetricLengthUnit.mm}
          targetUnitSystem={unitSystem}
          maximumFractionDigits={2}
          showUnits={false}
        />
      </StyledSpan>
      {wear >= criticalWearValue && (
        <div>
          {isHovered ? (
            <Tooltip title={getTooltip(unitSystem, locale, texts, false, 0, true, criticalWearValue)} placement="top">
              {Component}
            </Tooltip>
          ) : (
            Component
          )}
        </div>
      )}
    </StyledWearContainer>
  );
});

export interface ThicknessWearPanelTexts {
  headerValues: string;
  tableColumnThickness: string;
  tableColumnWear: string;
  thicknessEqualOrLessThanCriticalValue: string;
  wearDeltaEqualOrLargerThanCriticalValue: string;
  and: string;
  heat: string;
}

export interface ThicknessWearPanelProps extends SideContextPanel {
  scale: LinearScale;
  data: DataItem[];
  criticalThicknessValue: number;
  criticalWearValue: number;
  onRowEntered: (rowData: DataItem) => void;
  onRowsExited: () => void;
  texts: ThicknessWearPanelTexts;
}

export const ThicknessWearPanel: FC<React.PropsWithChildren<ThicknessWearPanelProps>> = React.memo(function ThicknessWearPanel({
  isExpanded = false,
  bodyHeight = 0,
  scale,
  data,
  criticalThicknessValue,
  criticalWearValue,
  onRowEntered,
  onRowsExited,
  texts,
}) {
  const [tableHeight, setTableHeight] = useState(200);
  const antdTableContainerRef = useRef<HTMLDivElement>(null);
  const [localization] = useLocalization();
  const { unitSystem } = localization;

  useEffect(() => {
    const topHeaderLabelHeight = document.querySelector(`#${DOM_TOP_HEADER_LABEL_ID}`)?.clientHeight ?? 0;
    const tableHeaderHeight = 2 * HEADER_BORDER_SIZE + (document.querySelector('div.ant-table-header')?.clientHeight ?? 0);
    const tableHeight = bodyHeight - topHeaderLabelHeight - tableHeaderHeight;
    setTableHeight(tableHeight);
  }, [isExpanded, bodyHeight]);

  const minimumThicknessValue = React.useMemo(
    () =>
      data.reduce((accumulator, current) => {
        return isDefined(accumulator.rbl) && isDefined(current.rbl) ? (accumulator.rbl < current.rbl ? accumulator : current) : accumulator;
      }).rbl,
    [data]
  );

  const renderThickness = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (thickness: any, dataRecord: any) => {
      return (
        <Thickness
          isExpanded={isExpanded}
          scale={scale}
          thickness={thickness}
          dataRecord={dataRecord}
          minimumThicknessValue={minimumThicknessValue}
          criticalThicknessValue={criticalThicknessValue}
          criticalWearValue={criticalWearValue}
          texts={texts}
        />
      );
    },
    [isExpanded, scale, minimumThicknessValue, criticalThicknessValue, criticalWearValue, texts]
  );

  const renderWear = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (wear: any) => {
      return <Wear wear={wear} criticalWearValue={criticalWearValue} texts={texts} />;
    },
    [criticalWearValue, texts]
  );

  const columns = React.useMemo(() => {
    const thicknessColumn: ColumnType<DataItem> = {
      title: isExpanded ? (
        <StyledHeaderCell isExpanded={isExpanded}>
          <span>{texts.tableColumnThickness}</span>
          <span>{convertUnit(MetricLengthUnit.mm, unitSystem)}</span>
        </StyledHeaderCell>
      ) : (
        <StyledHeaderCell isExpanded={isExpanded}>
          <LiningIcon fill={settings.colors.Primary.Grey_8} />
          <span>{convertUnit(MetricLengthUnit.mm, unitSystem)}</span>
        </StyledHeaderCell>
      ),
      dataIndex: 'rbl',
      key: 'rbl',
      sortDirections: ['ascend', 'ascend', 'ascend'],
      defaultSortOrder: 'ascend',
      width: '50%',
      render: renderThickness,
    };
    if (isExpanded) {
      thicknessColumn.sorter = (row1, row2) => (isDefined(row1.rbl) && isDefined(row2.rbl) ? row1.rbl - row2.rbl : 0);
    }
    const columns: ColumnsType<DataItem> = [thicknessColumn];
    if (isExpanded) {
      columns.push({
        title: (
          <StyledHeaderCell isExpanded={isExpanded}>
            <span>{texts.tableColumnWear}</span>
            <span>
              {convertUnit(MetricLengthUnit.mm, unitSystem)}/{texts.heat}
            </span>
          </StyledHeaderCell>
        ),
        dataIndex: 'wear',
        key: 'wear',
        width: '50%',
        sorter: (row1, row2) => (isDefined(row1.wear) && isDefined(row2.wear) ? row1.wear - row2.wear : 0),
        sortDirections: ['descend', 'descend', 'descend'],
        render: renderWear,
      });
    }
    return columns;
  }, [unitSystem, isExpanded, texts, renderThickness, renderWear]);

  const onRow = useCallback(
    (record: DataItem) => {
      return {
        onMouseEnter: () => onRowEntered(record),
        onMouseLeave: () => onRowsExited(),
      };
    },
    [onRowEntered, onRowsExited]
  );

  return (
    <StyledWrapper isExpanded={isExpanded}>
      {isExpanded && (
        <SPanelHeading id={DOM_TOP_HEADER_LABEL_ID}>
          <span>{texts.headerValues}</span>
        </SPanelHeading>
      )}

      <SDataTableContainer ref={antdTableContainerRef}>
        <TableHeaderShadow tableDOMContainer={antdTableContainerRef.current} />
        <DataTable<DataItem> rowKey="id" dataSource={data} columns={columns} pagination={false} scroll={{ y: tableHeight }} tableLayout="auto" onRow={onRow} />
      </SDataTableContainer>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div<{ isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  color: ${settings.colors.Primary.Grey_8};
  font-family: ${settings.typography.FontFamily.Regular};
  width: ${(props) => (props.isExpanded ? '226px' : '66px')};

  div.ant-table-header {
    position: relative;
    border-top: ${(_) => `${HEADER_BORDER_SIZE}px solid ${settings.colors.Monochromatic.White}`};
    border-bottom: ${(_) => `${HEADER_BORDER_SIZE}px solid ${settings.colors.Primary.Grey_3}`};
  }

  .ant-table-thead {
    .ant-table-column-sorter-down,
    .ant-table-column-sorter-up {
      color: ${settings.colors.Primary.Grey_4};

      &.active {
        color: ${settings.colors.Primary.Blue_9};
      }
    }

    .ant-table-column-sorters {
      align-items: flex-start;

      .ant-table-column-sorter {
        display: flex;
      }
    }

    th.ant-table-column-has-sorters {
      transition: none;
    }

    > tr > th {
      background-color: ${settings.colors.Primary.Grey_2};
    }
  }

  .ant-table-tbody {
    > tr {
      &:hover {
        cursor: pointer;

        td > div {
          background-color: ${settings.colors.Primary.Grey_2};

          div.thicknessCell {
            border: 1px solid ${settings.colors.Primary.Grey_8};
          }
        }
      }

      > td {
        border-bottom: ${(props) => (props.isExpanded ? `1px solid ${settings.colors.Primary.Grey_3}` : 'none')};

        &:nth-of-type(1) {
          padding: ${(props) =>
            props.isExpanded
              ? `${settings.Spacing.Spacing_50} 0 ${settings.Spacing.Spacing_50} ${settings.Spacing.Spacing_50}`
              : `${settings.Spacing.Spacing_50} ${settings.Spacing.Spacing_50} 0 ${settings.Spacing.Spacing_50}`};
        }

        &:nth-of-type(2) {
          padding: ${(props) =>
            props.isExpanded
              ? `${settings.Spacing.Spacing_50} ${settings.Spacing.Spacing_50} ${settings.Spacing.Spacing_50} 0`
              : `${settings.Spacing.Spacing_50} ${settings.Spacing.Spacing_50} 0 ${settings.Spacing.Spacing_50}`};
        }
      }
    }

    td.ant-table-column-sort {
      background-color: transparent;
    }
  }
`;

interface StyledHeaderCellProps {
  isExpanded: boolean;
}
const StyledHeaderCell = styled.div<StyledHeaderCellProps>`
  font-size: ${settings.typography.FontSize.X_Small};
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isExpanded ? 'flex-start' : 'center')};

  span {
    font-family: ${settings.typography.FontFamily.Regular};

    &:nth-child(1) {
      font-family: ${settings.typography.FontFamily.Bold};
    }
  }
`;

interface StyledThicknessWrapperProps {
  isViewExpanded: boolean;
}
const StyledThicknessWrapper = styled.div<StyledThicknessWrapperProps>`
  position: relative;
  padding: ${(props) => (props.isViewExpanded ? `${settings.Spacing.Spacing_100} 0 ${settings.Spacing.Spacing_100} ${settings.Spacing.Spacing_150}` : 0)};
  border-radius: 3px 0 0 3px;
`;

interface StyledThicknessCellProps {
  color: string;
  isViewExpanded: boolean;
  isLowestValue: boolean;
  isCritical: boolean;
}
const StyledThicknessCell = styled.div<StyledThicknessCellProps>`
  --width: ${(props) => (props.isViewExpanded ? '16px' : '40px')};
  --height: ${(props) => (props.isViewExpanded ? '16px' : '32px')};

  width: var(--width);
  height: var(--height);
  background-color: ${(props) => props.color};
  border: ${(props) => {
    if (props.isCritical) {
      return props.isViewExpanded
        ? `1px ${settings.colors.Operational.State_Alert_Red_4} solid;`
        : `2px ${settings.colors.Operational.State_Alert_Red_4} solid;`;
    } else if (props.isLowestValue) {
      return `1px ${settings.colors.Primary.Grey_8} dashed;`;
    }
    return 'none';
  }};
`;

interface StyledThicknessValueSpanProps {
  isExpanded: boolean;
}
const StyledThicknessValueSpan = styled.span<StyledThicknessValueSpanProps>`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: ${(props) => (props.isExpanded ? '52px' : '50%')};
  color: ${settings.colors.Primary.Grey_8};
`;

const StyledCriticalThicknessIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 74px;
`;

const StyledCriticalIcon = styled.div`
  position: absolute;
  top: -3px;
  left: 28px;
  flex-shrink: 0;
`;

const StyledSpan = styled.span`
  color: ${settings.colors.Primary.Grey_8};
  font-size: ${settings.typography.FontSize.Small};
`;

const StyledDeltaCharacter = styled.span.attrs(() => ({
  children: 'Δ',
}))`
  color: ${settings.colors.Primary.Grey_5};
  margin-right: 2px;
`;

const StyledWearContainer = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  padding: ${settings.Spacing.Spacing_100} 0 ${settings.Spacing.Spacing_100} ${settings.Spacing.Spacing_200};
  border-radius: 0 3px 3px 0;

  svg {
    margin-left: ${settings.Spacing.Spacing_100};
  }
`;

const SDataTableContainer = styled.div`
  position: relative;
`;
