import { settings } from '@rhim/design';
import { MinusIcon, PlusIcon } from '@rhim/icons/24';
import { heatNumberTextBoxErrorMessagePointCloudScansDataUploadContainer } from '@rhim/test-ids';
import { isDefined } from '@rhim/utils';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { useAntdFormItemValidationState } from '../../hooks';
import { IconButton } from '../IconButton';
import { TextField } from '../TextField';

const STEPPER_BUTTON_BORDER_WIDTH_PX = 1;

interface Props extends Omit<React.ComponentProps<typeof TextField>, 'value' | 'onChange'> {
  dataTestIdMinusButton?: string;
  dataTestIdPlusButton?: string;
  className?: string;
  value?: number | null;
  minValue?: number;
  maxValue?: number;
  onDecrement: () => void;
  onIncrement: () => void;
  onChange?: (newValue: number | null) => void;
}

const InputNumericStepper: React.ChildlessComponent<Props> = (props) => {
  const {
    dataTestIdMinusButton,
    dataTestIdPlusButton,
    className,
    value,
    minValue = 0,
    maxValue = Infinity,
    onDecrement,
    onIncrement,
    onChange,
    isDisabled = false,
    isReadOnly = false,
    variant = 'medium',
    ...rest
  } = props;
  const [labelTargetPortalDOM, setLabelTargetPortalDOM] = useState<HTMLDivElement>();
  const [feedbackTargetPortalDOM, setFeedbackTargetPortalDOM] = useState<HTMLDivElement>();
  const labelTargetPortalRef = useRef<HTMLDivElement>(null);
  const feedbackTargetPortalRef = useRef<HTMLDivElement>(null);

  const hasValidationError = props.validationState === 'invalid';

  useEffect(() => {
    if (!labelTargetPortalRef.current || !feedbackTargetPortalRef.current) {
      return;
    }
    setLabelTargetPortalDOM(labelTargetPortalRef.current);
    setFeedbackTargetPortalDOM(feedbackTargetPortalRef.current);
  }, [labelTargetPortalRef, feedbackTargetPortalRef]);

  const handleInputChanged = (input: string) => {
    const newValue = parseInt(input);

    if (isNaN(newValue)) {
      onChange?.(null);
    } else {
      onChange?.(newValue);
    }
  };

  /**
   * If a min/max value has been defined and the value typed is out of those bounds, then,
   * on blur, set the value to be within those bounds
   */
  const handleInputBlurred = useCallback(() => {
    if (!isDefined(value)) {
      return;
    }
    if (isDefined(minValue) && value < minValue) {
      onChange?.(minValue);
    }
    if (isDefined(maxValue) && value > maxValue) {
      onChange?.(maxValue);
    }
  }, [value, minValue, maxValue, onChange]);

  const isButtonStepperMinusDisabled = isDisabled || (isDefined(value) && value <= minValue) || !isDefined(value);
  const isButtonStepperPlusDisabled = isDisabled || (isDefined(value) && value >= maxValue);

  return (
    <StyledContainer className={className}>
      {/* render TextField's label in the container below */}
      <div ref={labelTargetPortalRef} />
      <StyledWrapper hasValue={value !== null} isDisabled={isDisabled} hasError={hasValidationError}>
        <STextField
          isReadOnly={isReadOnly}
          isDisabled={isDisabled}
          value={isDefined(value) ? value.toString() : ''}
          onChange={handleInputChanged}
          onBlur={handleInputBlurred}
          labelTargetPortalDOM={labelTargetPortalDOM}
          feedbackTargetPortalDOM={feedbackTargetPortalDOM}
          variant={variant}
          {...rest}
        />
        {!isDisabled && !isReadOnly && (
          <>
            <SIconButton
              data-test-id={dataTestIdMinusButton}
              className={`buttonStepper ${isButtonStepperMinusDisabled && 'isDisabled'}`}
              variant={variant}
              icon={<MinusIcon />}
              isDisabled={isButtonStepperMinusDisabled}
              onPress={onDecrement}
            />
            <SIconButton
              data-test-id={dataTestIdPlusButton}
              className={`buttonStepper ${isButtonStepperPlusDisabled && 'isDisabled'}`}
              variant={variant}
              icon={<PlusIcon />}
              isDisabled={isButtonStepperPlusDisabled}
              onPress={onIncrement}
            />
          </>
        )}
      </StyledWrapper>
      {/* render TextField's feedback in the container below */}
      <div data-test-id={heatNumberTextBoxErrorMessagePointCloudScansDataUploadContainer} ref={feedbackTargetPortalRef} />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

type StyledWrapperProps = { hasValue: boolean; isDisabled: boolean; hasError: boolean };
const StyledWrapper = styled.div<StyledWrapperProps>`
  display: flex;

  --borderColor: ${(props) => {
    if (props.hasError) {
      return settings.colors.Operational.State_Notif_Magenta_2;
    }
    return settings.colors.Primary.Grey_3;
  }};

  &:focus-within {
    ${(props) =>
      !props.hasError &&
      css`
        --borderColor: ${settings.colors.Primary.Blue_9};
      `}

    div.rhim-textfield-input-wrapper {
      border-color: var(--borderColor);
    }
  }

  div.rhim-textfield-input-wrapper {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }

  .buttonStepper {
    border-style: ${(props) => (props.isDisabled ? 'dashed' : 'solid')};
    border-width: ${STEPPER_BUTTON_BORDER_WIDTH_PX}px;
    border-color: var(--borderColor);
    border-left-color: ${settings.colors.Primary.Grey_3};

    &:last-of-type {
      --radius: 3px;

      margin-left: -1px;
      border-top-right-radius: var(--radius);
      border-bottom-right-radius: var(--radius);
    }
  }
`;

const SIconButton = styled(IconButton)<Pick<Props, 'variant'>>((props) => {
  const size: React.CSSProperties['width'] = (() => {
    switch (props.variant) {
      case 'x-small':
        return '32px';
      case 'small':
        return '40px';
      case 'medium':
        return '48px';
      case 'big':
        return '56px';
      default:
        return '48px';
    }
  })();

  return css`
    width: ${size};
    height: ${size};
    padding: 0;
  `;
});

const STextField = styled(TextField)`
  flex-grow: 1;
`;

InputNumericStepper.whyDidYouRender = true;

const InputNumericStepperWithValidationStatus: React.ChildlessComponent<React.ComponentProps<typeof InputNumericStepper>> = (props) => {
  const validationState = useAntdFormItemValidationState();

  return <InputNumericStepper validationState={validationState} {...props} />;
};
InputNumericStepperWithValidationStatus.displayName = 'InputNumericStepperWithValidationStatus';

export default React.memo(InputNumericStepperWithValidationStatus);
export { InputNumericStepper as InputNumericStepperDefault };
