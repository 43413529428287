import { CartesianCoordinate, PolarCoordinate } from './types';

export const polarToCartesian = ({ radius, angle }: PolarCoordinate): CartesianCoordinate => {
  return {
    x: radius * Math.cos(angle),
    y: radius * Math.sin(angle),
  };
};

export const degreesToRadians = (degrees: number) => (Math.PI / 180) * degrees;
