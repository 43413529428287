import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const LoaderFullIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 64 64" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill={fill}>
        <g>
          <path
            d="M32 0c17.645 0 32 14.355 32 32S49.645 64 32 64c-9.09 0-17.783-3.888-23.85-10.665l-.013-.015C2.889 47.45 0 39.88 0 32 0 14.356 14.355 0 32 0zM11.13 50.667C16.439 56.599 24.046 60 32 60c15.439 0 28-12.561 28-28S47.439 4 32 4l-.462.003C16.31 4.251 4 16.715 4 32c0 6.9 2.532 13.53 7.13 18.667-.077-.087-.16-.165-.249-.235l-.136-.099c.14.093.269.204.385.334z"
            transform="translate(-688 -417) translate(688 417)"
          />
        </g>
      </g>
    </svg>
  );
};

LoaderFullIcon.defaultProps = {
  size: '64',
  fill: settings.colors.Operational.State_Green_2,
};
