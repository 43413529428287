import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const VesselBOFIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M15.251 3.465c.426-.353 1.057-.292 1.409.134l3.111 3.764c.148.179.229.405.229.637v4h1c.552 0 1 .447 1 1 0 .553-.448 1-1 1h-1v3.235C20 19.907 16.486 22 12 22c-4.486 0-8-2.093-8-4.765V14H3c-.552 0-1-.447-1-1 0-.553.448-1 1-1h1V8c0-.227.077-.446.218-.623l3-3.765c.344-.432.974-.502 1.405-.159.432.345.503.974.159 1.405L6 8.35v5.845C6.68 14.087 7.494 14 8.444 14c2.667 0 4.445 1 7.112 1 .95 0 1.764-.087 2.444-.196V8.359l-2.882-3.487c-.352-.426-.292-1.056.133-1.407zM12 1c.552 0 1 .447 1 1v10c0 .553-.448 1-1 1s-1-.447-1-1V2c0-.553.448-1 1-1z"
      />
    </svg>
  );
};

VesselBOFIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
