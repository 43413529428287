import { settings } from '@rhim/design';
import { isDefined } from '@rhim/utils';
import { Slider as SliderAntd, SliderSingleProps } from 'antd';
import React, { useCallback } from 'react';
import styled from 'styled-components';

export const DEFAULT = {
  handleBorderColor: settings.colors.Primary.Blue_9,
  railColor: settings.colors.Primary.Grey_3,
  trackColor: settings.colors.Primary.Blue_8,
};

interface Props {
  className?: string;
  hideTrack?: boolean;
  railColor?: string;
  trackColor?: string;
}

const Slider: React.ChildlessComponent<SliderSingleProps & Props> = ({
  className,
  hideTrack = false,
  onChange,
  onChangeComplete,
  railColor = DEFAULT.railColor,
  trackColor = DEFAULT.trackColor,
  ...rest
}) => {
  const handleOnChange = useCallback(
    (value: number) => {
      document.body.style.userSelect = 'none';
      if (isDefined(onChange)) {
        onChange(value);
      }
    },
    [onChange]
  );

  const handleOnChangeComplete = useCallback(
    (value: number) => {
      document.body.style.userSelect = 'auto';
      if (isDefined(onChangeComplete)) {
        onChangeComplete(value);
      }
    },
    [onChangeComplete]
  );

  return (
    <>
      <SliderTooltipStyle />
      <StyledSlider
        className={className}
        hideTrack={hideTrack}
        railColor={railColor}
        trackColor={trackColor}
        onChange={handleOnChange}
        onChangeComplete={handleOnChangeComplete}
        {...rest}
      />
    </>
  );
};

type StyledSliderProps = Required<Pick<Props, 'hideTrack' | 'railColor' | 'trackColor'>>;

const StyledSlider = styled(SliderAntd)<StyledSliderProps>`
  --thickness: 8px;
  --handleTotalSize: 20px;
  --handleBorderWidth: 2px;
  --handleBorderColor: ${settings.colors.Primary.Blue_9};
  --handleSize: calc(var(--handleTotalSize) - 2 * var(--handleBorderWidth));
  --centerOffset: calc(var(--handleSize) - var(--thickness)) / 2;
  --railBackgroundColor: ${(props) => props.railColor};
  --trackBackgroundColor: ${(props) => (props.hideTrack ? 'transparent' : props.trackColor)};

  margin: calc(var(--handleTotalSize) / 2);

  .ant-slider-rail,
  .ant-slider-track {
    border-radius: 5px;
  }

  .ant-slider-track {
    background-color: var(--trackBackgroundColor);
  }

  .ant-slider-rail {
    background-color: var(--railBackgroundColor);
  }

  &.ant-slider-horizontal {
    height: var(--thickness);
    padding-block: 0;

    .ant-slider-rail,
    .ant-slider-track {
      height: 100%;
    }
  }

  &.ant-slider-vertical {
    width: var(--thickness);
    padding-inline: 0;

    .ant-slider-rail,
    .ant-slider-track {
      width: 100%;
    }
  }

  &:hover {
    .ant-slider-rail {
      background-color: var(--railBackgroundColor);
    }

    .ant-slider-track {
      background-color: var(--trackBackgroundColor);
    }

    .ant-slider-handle::after {
      box-shadow: 0 0 0 var(--handleBorderWidth) var(--handleBorderColor);
    }
  }

  /* SLIDER HANDLER KNOB */
  .ant-slider-handle {
    width: var(--handleSize);
    height: var(--handleSize);
    inset-inline-start: 0;
  }

  .ant-slider-handle::after,
  .ant-slider-handle:focus::after,
  .ant-slider-handle:hover::after {
    width: var(--handleSize);
    height: var(--handleSize);
    box-shadow: 0 0 0 var(--handleBorderWidth) var(--handleBorderColor);
    inset-inline-start: 0;
    inset-block-start: 0;
  }

  &.ant-slider-vertical .ant-slider-handle {
    margin-left: calc(-1 * var(--centerOffset));
  }

  &.ant-slider-horizontal .ant-slider-handle {
    margin-top: calc(-1 * var(--centerOffset));
    inset-block-start: 0;
  }
`;

const SliderTooltipStyle = settings.globals.patchedCreateGlobalStyle`
  .ant-slider-tooltip.ant-tooltip {
    /* Required so the tooltip is always on top of the Header strip */
    z-index: ${settings.Elevation.GlobalTooltip};
    --sliderTooltipOffset: 6px;

    .ant-tooltip-inner {
      color: ${settings.colors.Monochromatic.White};
      font-size: ${settings.typography.FontSize.X_Small};
      font-family: ${settings.typography.FontFamily.Regular};
      line-height: ${settings.typography.LineHeight.Line_Height_16};
      border-radius: 3px;
      background-color: ${settings.colors.Primary.Grey_6};
      box-shadow: none;
      padding: 0 4px;
      min-height: ${settings.typography.LineHeight.Line_Height_16};
      min-width: auto;
    }

    .ant-tooltip-content {
      position: relative;
    }

    &.ant-tooltip-placement-top,
    &.ant-tooltip-placement-topLeft,
    &.ant-tooltip-placement-topRight {
      .ant-tooltip-content {
        top: var(--sliderTooltipOffset);
      }
    }

    &.ant-tooltip-placement-right,
    &.ant-tooltip-placement-rightTop,
    &.ant-tooltip-placement-rightBottom {
      .ant-tooltip-content { 
        right: 6px;
      }
    }

    &.ant-tooltip-placement-bottom,
    &.ant-tooltip-placement-bottomLeft,
    &.ant-tooltip-placement-bottomRight {
      .ant-tooltip-content { 
        bottom: 6px;
      }
    }

    &.ant-tooltip-placement-left,
    &.ant-tooltip-placement-leftTop,
    &.ant-tooltip-placement-leftBottom {
      .ant-tooltip-inner { 
        left: 6px;
      }
    }

    .ant-tooltip-arrow {
      display: none;
    }
  }
`;

Slider.whyDidYouRender = true;
export default React.memo(Slider);
