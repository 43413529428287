import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { MaintenancePanel, Tag, useUserSettings } from '@rhim/react';
import { VesselType } from '@rhim/rest';
import { useColorScale } from '@rhim/sdk/wearManagement';
import { fleetOverviewContainerFleetOverview, titleFleetOverview, vesselsCountLabelFleetOverview } from '@rhim/test-ids';
import { assert, colorScalesForGOB, hasElements, isDefined } from '@rhim/utils';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { useSelectedVessel, useVessels } from '../../hooks';
import { useFleetOverviewVessels } from '../../hooks/useFleetOverviewVessels';
import { vesselsPerFeature } from '../../lib';
import { Feature } from '../../settings';
import VesselCard, { CombinedVessel } from './VesselCard';

interface Props {
  customerId: UUID;
}
const FleetOverview: React.ChildlessComponent<Props> = ({ customerId }) => {
  const { t } = i18nReact.useTranslation(['fleet-overview', 'shared']);
  const { data: vessels } = useVessels(customerId, undefined, Feature.FleetOverview, { configuration: { suspense: true } });
  const { data: fleetOverviewVessels } = useFleetOverviewVessels(customerId, { configuration: { suspense: true } });
  const { data: ladleColorScale } = useColorScale(customerId, VesselType.Ladle);
  const {
    userSettings: { unitSystem, locale },
  } = useUserSettings();

  assert(isDefined(vessels), 'FleetOverview, in Suspense mode data (vessels) should always be defined, according to react-query docs.');
  assert(
    isDefined(fleetOverviewVessels),
    'FleetOverview, in Suspense mode data (fleetOverviewVessels) should always be defined, according to react-query docs.'
  );
  assert(isDefined(ladleColorScale), 'FleetOverview, in Suspense mode data (ladleColorScale) should always be defined, according to react-query docs.');

  usePredefinedVessel(vessels);

  const colorScales = hasElements(ladleColorScale.thresholdsAndColors) ? ladleColorScale.thresholdsAndColors : colorScalesForGOB;

  /**
   * Combine the data from 2 API calls.
   * To get all the information we need, we have to combine the response of 2 API calls :
   * a) the vessel list from the customer management API
   * b) the vessel list from the fleet overview API
   * The reason is : the vessel list from the fleet overview API lacks the vessel's "displayName" which
   * we need to display it at the top of the Vessel card.
   * We match the items from these 2 API calls by the vessel-id.
   */
  const combinedVessels: CombinedVessel[] = useMemo(
    () =>
      vessels.map((vessel) => {
        const correspondingFleetOverviewVessel = fleetOverviewVessels.find((fleetOverviewVessel) => fleetOverviewVessel.vesselId === vessel.id);
        const combinedVessel: CombinedVessel = isDefined(correspondingFleetOverviewVessel)
          ? ({ ...vessel, ...correspondingFleetOverviewVessel } as CombinedVessel)
          : { ...vessel, latestMeasurementId: null, latestMeasurementCampaign: null, latestMeasurementHeat: null };
        return combinedVessel;
      }),
    [vessels, fleetOverviewVessels]
  );

  const refresh = useCallback(() => {
    window.location.reload();
  }, []);

  if (combinedVessels.length === 0) {
    return (
      <MaintenancePanel
        data-test-id="fleetOverviewNoVessels"
        size="large"
        heading={t('fleet-overview:noVesselsToDisplayPanel.heading')}
        subHeading={t('fleet-overview:noVesselsToDisplayPanel.subheading', { shownVesselTypes: vesselsPerFeature.fleetOverview.join(', ') })}
        headerColor={settings.colors.Operational.State_Notif_Grey_2}
        subheaderColor={settings.colors.Operational.State_Notif_Grey_2}
        secondaryButton={{ label: t('shared:refresh'), onClick: refresh }}
      />
    );
  }

  return (
    <SWrapper data-test-id={fleetOverviewContainerFleetOverview}>
      <STitleContainer>
        <STitle data-test-id={titleFleetOverview}>{VesselType.Ladle}</STitle>
        <SVesselCountTag
          color={settings.colors.Primary.Grey_8}
          fill={settings.colors.Primary.Blue_2}
          size="small-16"
          dataTestId={vesselsCountLabelFleetOverview}
        >
          {combinedVessels.length}
        </SVesselCountTag>
      </STitleContainer>
      <SVesselsContainer>
        {combinedVessels.map((combinedVessel) => (
          <VesselCard key={combinedVessel.id} vessel={combinedVessel} colorScales={colorScales} unitSystem={unitSystem} locale={locale} />
        ))}
      </SVesselsContainer>
    </SWrapper>
  );
};
export default React.memo(FleetOverview);

/**
 * Preselect a ladle if the user lands on the fleet overview page and no ladle is selected.
 */
function usePredefinedVessel(vessels: APO.VesselV2[]): void {
  const { selectedVesselId, setSelectedVesselId } = useSelectedVessel(vessels);

  const selectedVesselType: VesselType | undefined = React.useMemo(() => {
    return vessels.find((vessel) => vessel.id === selectedVesselId)?.vesselType;
  }, [selectedVesselId, vessels]);

  if (selectedVesselType !== VesselType.Ladle) {
    const firstAvailableLadle = vessels.find((vessel) => vessel.vesselType === VesselType.Ladle);

    if (isDefined(firstAvailableLadle)) {
      setSelectedVesselId(firstAvailableLadle.id);
    }
  }
}

const SWrapper = styled.div`
  padding: 36px ${settings.Spacing.Spacing_400} ${settings.Spacing.Spacing_400} ${settings.Spacing.Spacing_400};
  height: 100%;
  overflow: auto;
`;

const STitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const STitle = styled.span`
  font-family: ${settings.typography.FontFamily.Bold};
  font-size: ${settings.typography.FontSize.Large};
  color: ${settings.colors.Primary.Grey_8};
`;

const SVesselCountTag = styled(Tag)`
  margin-left: ${settings.Spacing.Spacing_100};
`;

const SVesselsContainer = styled.div`
  margin-top: ${settings.Spacing.Spacing_400};
  display: flex;
  flex-wrap: wrap;
  gap: ${settings.Spacing.Spacing_200};
`;
