import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const RegressionIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g clipPath="url(#sn8qpdsy5a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 1a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm7-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 11a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-4-4a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-6 2a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm13.654-4.854a1 1 0 1 0-1.88.684l.142.39-10.82-5.046a1 1 0 1 0-.846 1.812l10.82 5.046-.388.142a1 1 0 1 0 .684 1.88l2.658-.968a1 1 0 0 0 .597-1.282l-.967-2.658z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="sn8qpdsy5a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

RegressionIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Miscellaneous.Purple,
};
