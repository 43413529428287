import { RHIMAPOCommonDataReportingRegionTag, RHIMAPOReportingWearManagementApiV1ModelsRegionDto } from '@rhim/rest/wearManagement';
import { isDefined, specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api/wearManagement';

type Payload = RHIMAPOReportingWearManagementApiV1ModelsRegionDto[];

export const useRegions = (
  vesselId: UUID,
  tag?: RHIMAPOCommonDataReportingRegionTag,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) => {
  return useQuery(getKey(vesselId), (context) => queryFn(context, options?.axiosConfiguration), {
    select: (data: Payload) => {
      return isDefined(tag) ? data.filter((region) => isDefined(region.tags) && region.tags.find((item) => item === tag)) : data;
    },
    ...options?.configuration,
  });
};

const getKey = (vesselId: UUID) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'wearManagement',
      scope: 'regions',
      entity: 'list',
      vesselId,
    },
  ]);

const queryFn = ({ queryKey: [{ vesselId }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  return API.regions.getRegions(vesselId, { signal, ...axiosConfiguration }).then((response) => response.data);
};

useRegions.getKey = getKey;
useRegions.queryFn = queryFn;
