import type { FC } from 'react';
import React from 'react';

const SvgFiltersComponent: FC<React.PropsWithChildren> = () => {
  return (
    <defs>
      <filter id="drop-shadow" filterUnits="userSpaceOnUse" width="200%" height="200%">
        <feGaussianBlur in="SourceGraphic" stdDeviation="4" result="blurResult"></feGaussianBlur>
        <feBlend in="SourceGraphic" in2="blurResult" mode="normal"></feBlend>
      </filter>
    </defs>
  );
};

SvgFiltersComponent.whyDidYouRender = true;

export const SvgFilters = React.memo(SvgFiltersComponent);
