import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const BookIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 1000 1000"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      role={role}
      {...rest}
    >
      {isDefined(title) ? <title>{title}</title> : null}
      <g transform="matrix(1.14286,0,0,1.14286,-71.4286,-71.4286)">
        <path
          fill={fill}
          fillRule="nonzero"
          d="M391.3,710.2C429.9,722.6 467.6,737.1 495.8,769.3L495.8,756.5C495.8,606.3 495.5,456.2 496.2,306C496.3,291.5 491.4,281.4 479.6,272.9C477.7,271.5 475.8,270 473.8,268.6C443.9,247.5 409.2,235.1 373.6,224.8C301.6,203.8 227.4,192.7 152.2,186.4C135.7,185 119.1,184.8 101.9,184.1L101.9,664.1C131,666.2 159.3,667.8 187.6,670.5C257.1,677.2 325.4,689 391.3,710.2ZM504.3,757L504.3,769.3C527.4,742.3 557.3,728.3 588.8,717.1C675.3,686.3 765.9,672.9 858,666.7C871.3,665.8 884.7,665.3 898,664.6L898,184.5C891.4,184.5 885.3,184 879.4,184.6C840.9,188.2 802.2,190.9 763.9,196.1C704.8,204 646.6,216.1 591,236.4C562.5,246.8 535.2,259 513.2,279.2C506.9,284.9 504,291.1 504,299.7C504.4,452.1 504.3,604.5 504.3,757ZM912.6,261.9L912.6,679.5C909.8,679.7 908.1,679.9 906.4,680C817.4,681.6 730,693.3 644.5,715.3C591.2,729 538.1,744.2 499.5,784.7C480.5,762 454.4,748.6 426.4,737.9C337.3,703.8 243.2,690.2 147.7,682.3C128,680.7 108.1,680.3 87.2,679.2L87.2,261.8L62.5,261.8L62.5,716.8C70.2,717.2 77.3,717.7 84.4,718C168.9,720.7 252.4,730.1 334.5,748.2C394.1,761.3 454.3,774.4 500.6,815.9C519.6,795.8 544.7,783.9 571.4,774.5C664.4,741.6 762,729 860.6,720.9C886,718.8 911.6,718.1 937.5,716.7L937.5,261.9L912.6,261.9Z"
        />
      </g>
    </svg>
  );
};

BookIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
