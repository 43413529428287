import { settings } from '@rhim/design';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

const AXIS_LABEL_OFFSET_PX = settings.Spacing.Spacing_150; // the offset of the axis icon/label from the axis
const COLOR = settings.colors.Primary.Grey_8;

interface Props {
  top: number;
  left: number;
  isHorizontalAxisLabel: boolean;
  icon: ReactElement<{ fill?: string }>;
  label?: string;
}
const AxisLabel: React.ChildlessComponent<Props> = ({ top, left, isHorizontalAxisLabel, icon, label }) => {
  const iconWithProps = React.useMemo(
    () =>
      React.cloneElement(icon, {
        fill: COLOR,
      }),
    [icon]
  );

  return (
    <SWrapper top={top} left={left} isHorizontalAxisLabel={isHorizontalAxisLabel}>
      {iconWithProps}
      <SLabel>{label}</SLabel>
    </SWrapper>
  );
};

export default React.memo(AxisLabel);

const SWrapper = styled.div<Pick<Props, 'top' | 'left' | 'isHorizontalAxisLabel'>>`
  position: absolute;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  transform: translate(
    ${(props) => (props.isHorizontalAxisLabel ? AXIS_LABEL_OFFSET_PX : 0)},
    ${(props) => (props.isHorizontalAxisLabel ? '-50%' : `calc(-100% - ${AXIS_LABEL_OFFSET_PX})`)}
  );
  display: flex;
  align-items: center;
`;

const SLabel = styled.span`
  margin-left: ${settings.Spacing.Spacing_50};
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.X_Small};
  line-height: 1;
  color: ${COLOR};
`;
