/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  AbstractMesh,
  Animation,
  AnimationGroup,
  ArcRotateCamera,
  BaseTexture,
  Color3,
  Color4,
  CubicEase,
  EasingFunction,
  Engine,
  HighlightLayer,
  LinesMesh,
  Matrix,
  Mesh,
  MeshBuilder,
  Nullable,
  Observable,
  Observer,
  PBRMaterial,
  PointerEventTypes,
  PointerInfo,
  Scene,
  StandardMaterial,
  Tags,
  TransformNode,
  Vector2,
  Vector3,
  VertexBuffer,
} from '@babylonjs/core';
import { PickingInfo } from '@babylonjs/core/Collisions/pickingInfo';
import { Logger } from '@rhim/logging';
import { RHIMAPOReportingWearManagementApiV1ModelsOrientationLabelDto } from '@rhim/rest/wearManagement';
import { assert, ColorScales, isDefined } from '@rhim/utils';
import { debounce } from 'lodash';
import { TFunction } from 'react-i18next';
import { TableLikeData } from 'typings/internal/sections';

import { CAMERA_CONFIG, getDefaultCameraState } from '../config';
import { UniversalLadleMaterial } from '../materials';
import { createGizmoBox, disableAllMeshes, disposeAllMeshes, getBarycentricValues, positionModelInCenter } from '../meshUtils';
import { VOXEL_SIZE } from '../PointCloud';
import { haveAnimationsStopped } from '../renderingUtils';
import {
  adaptCameraViewport,
  getDefaultCameraRadius,
  getDefaultCameraZoomConfig,
  load3dInspector,
  navigateCameraTo,
  setCameraBetaLimits,
  setupCameraView,
  setupLights,
  setupScene,
  takeScreenshot,
} from '../sceneUtils';
import { CameraOrientation, CameraRotation, PickedPoint, PointCloudData, Scene3dApiFacade } from '../types';
import { MESH_CONTAINER_NAME, Scene3dInitOptions } from './types';
import { create3dCompassAngleLines, create3dCompassCircle, create3dCompassPlane, throttle } from './utils';

const log = new Logger();

export const COMPASS_NODE_TAG = 'CompassNodeTag';

const gizmoViewportSize = 150; // viewport size in pixels
const gizmoCameraMask = 0x10000000;
const sceneColor = new Color4(0.961, 0.965, 0.969, 1);

/**
 * The Scene3dApi is a single point of truth for 3D world.
 * All components should get an access or trigger events through the context of current API.
 * Please follow the Scene3dApiFacade to communicate with 3D world.
 *
 * The API is designed to work only with 3D world. Please, keep away any business logic which is not related
 * to the 3D, like authorization, UI layout, etc. The API should not know at all where does it render or how does
 * it receive its models.
 *
 * Creating an instance of the API is as simple as calling its static method Scene3dApi.initAPI with a feature list which
 */
export class Scene3dApi implements Scene3dApiFacade {
  public onPointPickedObservable: Observable<PickedPoint>;
  public onPointerHoverObservable: Observable<boolean>;

  private engine: Engine;
  private engineAutoStopFeature = true;
  private pauseRenderObserver: Nullable<Observer<Scene>> = null;
  private isEnginePausedFlag = false;
  private scene: Scene;
  private mainCamera: ArcRotateCamera;
  private gizmoCamera: Nullable<ArcRotateCamera> = null;
  private meshContainer: TransformNode;
  private highlightedGeometriesContainer: TransformNode;
  private floorAnimation: Nullable<AnimationGroup> = null;
  private highlightLayer: HighlightLayer;
  private isFloorViewOn = false;
  private commonMaterial: UniversalLadleMaterial;
  private showNativeTexture = true;
  public isHoveringGizmo = false;

  public constructor(engine: Engine, options: Scene3dInitOptions, translate: TFunction<'visualization3d'>) {
    this.engine = engine;
    const { material, withGizmo = false } = options;

    this.scene = new Scene(engine, {
      useClonedMeshMap: true,
      useGeometryUniqueIdsMap: true,
      useMaterialMeshMap: true,
    });

    this.onPointPickedObservable = new Observable();
    this.registerPickingEvent();

    this.onPointerHoverObservable = new Observable();

    this.highlightLayer = new HighlightLayer('HighlightLayer', this.scene);
    this.highlightLayer.isEnabled = false;

    this.scene.createDefaultCamera(true, true, true);

    this.meshContainer = new TransformNode(MESH_CONTAINER_NAME, this.scene);
    this.highlightedGeometriesContainer = new TransformNode('HighlightedGeometriesContainer', this.scene);

    this.commonMaterial = new UniversalLadleMaterial('LadleMaterial', this.scene, material);
    this.commonMaterial.setClippingHeight(Infinity);
    this.commonMaterial.updateColorScale(null);

    setupScene(this.scene, sceneColor);
    this.mainCamera = setupCameraView(this.scene, getDefaultCameraState());
    setupLights(this.scene);

    if (withGizmo) {
      this.createGizmo(translate);
    }

    this.engine.runRenderLoop(() => {
      if (!this.isEnginePausedFlag) {
        this.scene.render();
      }
    });

    this.scene.onPrePointerObservable.add(() => {
      if (this.isEnginePausedFlag) {
        this.enableRenderer();
      }
    });

    this.enableRenderer();
  }

  private registerPickingEvent() {
    const moveF = throttle(() => {
      const pickInfo: Nullable<PickingInfo> = this.scene.pick(
        this.scene.pointerX,
        this.scene.pointerY,
        (hoveredMesh: AbstractMesh) => {
          // take into account only the "main" mesh (ignore, for instance, the gizmo box)
          return isDefined(hoveredMesh.parent) ? hoveredMesh.parent.name === MESH_CONTAINER_NAME && hoveredMesh.isPickable && !this.isHoveringGizmo : true;
        },
        undefined,
        this.getMainCamera()
      );

      const faceId = isDefined(pickInfo) ? pickInfo.faceId : undefined;

      if (isDefined(pickInfo) && isDefined(faceId) && faceId !== -1) {
        const pickedPoint = pickInfo.pickedPoint!;
        const mesh = pickInfo.pickedMesh!;
        const vertices = Vector3.FromArray(mesh.getIndices()!, 3 * faceId);
        const positions = mesh.getPositionData()!;
        const v0 = Vector3.FromArray(positions, 3 * vertices.x);
        const v1 = Vector3.FromArray(positions, 3 * vertices.y);
        const v2 = Vector3.FromArray(positions, 3 * vertices.z);

        this.onPointerHoverObservable.notifyObservers(true);
        this.onPointPickedObservable.notifyObservers({
          pickedPoint,
          mouseOnScreenPosition: new Vector2(this.scene.pointerX, this.scene.pointerY),
          mesh: mesh,
          indices: vertices,
          barycentricVolumes: getBarycentricValues(v0, v1, v2, pickedPoint),
        });
      } else {
        this.onPointerHoverObservable.notifyObservers(false);
      }
    }, 200);

    this.scene.onPointerObservable.add((pointerInfo: PointerInfo) => {
      switch (pointerInfo.type) {
        case PointerEventTypes.POINTERMOVE:
          moveF(pointerInfo);
          break;
      }
    });
  }

  public isEnginePaused() {
    return this.isEnginePausedFlag;
  }

  public setEngineAutoStopMode(mode: 'on' | 'off') {
    this.engineAutoStopFeature = mode === 'on';

    this.enableRenderer();
  }

  public updateMinThicknessValue(minThickness: number) {
    this.commonMaterial.updateMinThicknessValue(minThickness);
    this.enableRenderer();
  }

  public updateColorScale(colorScales: ColorScales | null) {
    // we need to update the material before the next render
    this.scene.onAfterRenderObservable.addOnce(() => {
      this.commonMaterial.updateColorScale(colorScales);
    });
    this.enableRenderer();
  }

  public updateClusters(sectionsData: TableLikeData, colorScales: ColorScales) {
    this.commonMaterial.updateClusters(sectionsData, colorScales);
    this.enableRenderer();
  }

  public setDiffuseTexture(texture: Nullable<BaseTexture>) {
    this.commonMaterial.setDiffuseTexture(texture);
    this.enableRenderer();
  }

  /**
   * Debouncing all resizing events so we make sure
   * that we don't fire a next resize at least withing next 200ms
   */
  public resize = debounce(() => {
    this.enableRenderer();
    this.engine.resize();
    if (this.gizmoCamera) {
      adaptCameraViewport(this.engine, this.gizmoCamera, gizmoViewportSize);
    }
  }, 200);

  public setCameraRotationState(cameraType: CameraRotation, toLock: boolean) {
    switch (cameraType) {
      case CameraRotation.Horizontal: {
        this.mainCamera.lowerAlphaLimit = toLock ? this.mainCamera.alpha : null;
        this.mainCamera.upperAlphaLimit = toLock ? this.mainCamera.alpha : null;
        break;
      }
      case CameraRotation.Vertical: {
        this.mainCamera.lowerBetaLimit = toLock ? this.mainCamera.beta : CAMERA_CONFIG.arcRotate.betaLowerLimit;
        this.mainCamera.upperBetaLimit = toLock ? this.mainCamera.beta : CAMERA_CONFIG.arcRotate.betaUpperLimit;
        break;
      }
    }
  }

  public setShowNativeTexture(state: boolean) {
    this.showNativeTexture = state;

    this.setDiffuseTexture(null);
    if (state) {
      const meshName = this.getActiveMesh()?.name;
      if (isDefined(meshName)) {
        const texture = this.scene.getTextureByName(meshName);
        this.setDiffuseTexture(texture);
      }
    }
  }

  public getBoundingBox() {
    const mesh = this.getActiveMesh();

    if (isDefined(mesh)) {
      const { minimum, maximum } = mesh.getBoundingInfo().boundingBox;

      return {
        min: minimum,
        max: maximum,
      };
    }

    return null;
  }

  public hasAssociatedTexture(mesh: Nullable<AbstractMesh>): boolean {
    const meshName = isDefined(mesh) ? mesh.name : this.getActiveMesh()?.name;
    if (isDefined(meshName)) {
      const texture = this.scene.getTextureByName(meshName);

      return isDefined(texture);
    }

    return false;
  }

  /**
   * Adds provided mesh to the scene.
   *
   * @param mesh
   * @param enable Force enabling the mesh.
   * @returns
   */
  public addModel(mesh: Mesh, enable = false) {
    mesh.getVerticesData(VertexBuffer.UV2Kind);
    mesh.freezeWorldMatrix();
    this.enableRenderer();
    const modelDefaultMaterial = mesh.material as Nullable<PBRMaterial>;
    const albedoTexture = modelDefaultMaterial?.albedoTexture;
    if (isDefined(albedoTexture)) {
      albedoTexture.name = mesh.name;
    }
    mesh.parent = this.meshContainer;
    mesh.material = this.commonMaterial;

    if (this.showNativeTexture && enable) {
      /**
       * If glb file contains a material for a model, the material will be an instance of PBR material.
       * This means that the base colors will be taken from albedo texture. Our common material uses
       * diffuse texture for these needs.
       */
      this.commonMaterial.setDiffuseTexture(albedoTexture || null);
    }

    this.scene.addMesh(mesh);

    if (enable) {
      if (this.isFloorViewOn) {
        this.setFloorView(mesh.name, 'on', false);
      }
      mesh.setEnabled(true);
    }
    return this;
  }

  public disableAllModels() {
    this.enableRenderer();
    this.cleanUpHighlight();
    disableAllMeshes(this.scene);
  }

  public disposeAllModels() {
    this.enableRenderer();
    disposeAllMeshes(this.scene);
  }

  public enableVolumeDepthLine(enable: boolean) {
    this.commonMaterial.enableVolumeDepthLine(enable);
  }

  public setVolumeStartingDepth(volumeStartDepth: number, topPosition: number) {
    this.commonMaterial.setVolumeDepthPosition(topPosition - volumeStartDepth);
  }

  public disposeAllTransformNodesByTag(tag: string) {
    this.enableRenderer();
    this.scene.getTransformNodesByTags(tag).forEach((node) => node.dispose());
  }

  public getMainCamera() {
    return this.mainCamera;
  }

  public setFloorView(modelName: string, view: 'on' | 'off', withAnimation = true) {
    this.enableRenderer();
    const mesh = this.scene.getMeshByName(modelName) as Nullable<Mesh>;
    const isDirectionToFloor = view === 'on';
    if (mesh) {
      if (isDirectionToFloor) {
        if (this.isFloorViewOn) {
          this.cleanUpHighlight();
        }
        let clonedMesh = this.scene.getMeshByName(`${modelName}_geometry`) as Nullable<Mesh>;
        if (!isDefined(clonedMesh)) {
          clonedMesh = new Mesh(`${modelName}_geometry`, this.scene, this.highlightedGeometriesContainer);
          mesh.geometry?.applyToMesh(clonedMesh);
        }
        clonedMesh.freezeWorldMatrix();
        clonedMesh.visibility = 0.035;
        clonedMesh.material = new StandardMaterial(`${modelName}_geometry`, this.scene);
        clonedMesh.material.backFaceCulling = false;

        this.highlightLayer.addMesh(clonedMesh, new Color3(0.769, 0.769, 0.773));
        this.highlightLayer.isEnabled = true;
      }

      const {
        min: { y: minHeight },
        max: { y: maxHeight },
      } = mesh.getHierarchyBoundingVectors();
      const cameraTargetY = isDirectionToFloor ? minHeight * 0.8 : 0;
      const clippingValueFrom = isDirectionToFloor ? maxHeight : minHeight * 0.8;
      const clippingValueTo = isDirectionToFloor ? minHeight * 0.8 : maxHeight;

      if (withAnimation) {
        const prevAnimationGroup: Nullable<AnimationGroup> = this.floorAnimation;
        const isPlaying = prevAnimationGroup?.isPlaying;
        if (isPlaying ?? false) {
          prevAnimationGroup!.onAnimationEndObservable.clear();
          prevAnimationGroup!.stop();
          prevAnimationGroup!.dispose();
        }
        this.floorAnimation = new AnimationGroup('FloorAnimation');
        const easingFunction = new CubicEase();
        easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEOUT);

        const cameraAnimation = Animation.CreateAnimation('target.y', Animation.ANIMATIONTYPE_FLOAT, 60, easingFunction);
        cameraAnimation.setKeys([
          { frame: 0, value: this.mainCamera.target.y },
          { frame: 45, value: cameraTargetY },
        ]);
        this.floorAnimation.addTargetedAnimation(cameraAnimation, this.mainCamera);

        const clippingFrom = isPlaying ?? false ? this.commonMaterial.clippingYLevel : clippingValueFrom;
        const clippingAnimation = Animation.CreateAnimation('clippingYLevel', Animation.ANIMATIONTYPE_FLOAT, 60, easingFunction);
        clippingAnimation.setKeys([
          { frame: 0, value: clippingFrom },
          { frame: 45, value: clippingValueTo },
        ]);
        this.floorAnimation.addTargetedAnimation(clippingAnimation, this.commonMaterial);

        this.floorAnimation.onAnimationEndObservable.addOnce(() => {
          if (!isDirectionToFloor) {
            this.cleanUpHighlight();
          }
          this.floorAnimation?.dispose();
        });

        this.floorAnimation.play(false);
      } else {
        this.mainCamera.target.y = cameraTargetY;
        this.commonMaterial.clippingYLevel = clippingValueTo;

        if (!isDirectionToFloor) {
          this.cleanUpHighlight();
        }
      }
    }

    this.isFloorViewOn = isDirectionToFloor;
  }

  public moveCamera(orientation: CameraOrientation | 'Home', withAnimation = true) {
    this.enableRenderer();
    if (orientation === 'Home') {
      const cameraTarget = Vector3.Zero();
      if (this.isFloorViewOn) {
        // using clipping level as a target height for camera
        cameraTarget.y = this.commonMaterial.clippingYLevel;
      }
      const { min, max } = this.scene.getWorldExtends();
      navigateCameraTo(this.scene, this.mainCamera, {
        orientation: 'TOP',
        radius: getDefaultCameraRadius(min, max),
        target: cameraTarget,
        withAnimation: withAnimation,
      });
    } else {
      navigateCameraTo(this.scene, this.mainCamera, {
        orientation: orientation,
        withAnimation: withAnimation,
      });
    }
  }

  public zoomCamera(direction: 'in' | 'out') {
    this.enableRenderer();
    const { min, max } = this.scene.getWorldExtends();
    const cameraZoomStep = getDefaultCameraZoomConfig(min, max).zoomStep;
    const zoomStep = direction === 'in' ? -cameraZoomStep : cameraZoomStep;

    const { radius } = this.mainCamera;
    const newRadius = radius + zoomStep;

    if (newRadius > 0) {
      navigateCameraTo(this.scene, this.mainCamera, {
        radius: newRadius,
      });
    }
  }

  public setCameraRotationMode(mode: 'rotate' | 'pan') {
    this.enableRenderer();
    const panning = mode === 'pan' ? 0 : 1;
    this.mainCamera.attachControl(true, true, panning);
  }

  // TODO try to encapsulate it
  public getScene() {
    return this.scene;
  }

  public get getSceneTransformMatrix() {
    return this.scene.getTransformMatrix();
  }

  public getEngine() {
    return this.engine;
  }

  public get getEngineOnResizeObservable() {
    return this.engine.onResizeObservable;
  }

  public get getEngineHardwareScalingLevel() {
    return this.engine.getHardwareScalingLevel();
  }

  public get getEngineRenderSize(): [number, number] {
    return [this.engine.getRenderWidth(), this.engine.getRenderHeight()];
  }

  // TODO utilize it
  public async takeScreenShots() {
    const activeMesh = this.getActiveMesh();

    if (isDefined(activeMesh)) {
      const screenShotCamera = new ArcRotateCamera('ScreenShotsCamera', 0, 0, 1, activeMesh.position, this.scene);
      screenShotCamera.minZ = 0.01;
      const radius = 1;
      const isFloorViewOn = this.isFloorViewOn;
      const screenShots = [];

      if (!isFloorViewOn) {
        await new Promise<void>((resolve) => {
          setTimeout(() => {
            this.setFloorView(activeMesh.name, 'on', false);
            resolve();
          }, 0);
        });
      }

      screenShots[0] = await takeScreenshot(screenShotCamera, -Math.PI / 2, Math.PI / 2 - Math.PI / 5, radius);

      await new Promise<void>((resolve) => {
        setTimeout(() => {
          this.setFloorView(activeMesh.name, 'off', false);
          resolve();
        }, 0);
      });

      screenShots[1] = await takeScreenshot(screenShotCamera, -Math.PI / 2, Math.PI / 2, radius);
      screenShots[2] = await takeScreenshot(screenShotCamera, -Math.PI, Math.PI / 2, radius);
      screenShots[3] = await takeScreenshot(screenShotCamera, (-3 * Math.PI) / 2, Math.PI / 2, radius);
      screenShots[4] = await takeScreenshot(screenShotCamera, -2 * Math.PI, Math.PI / 2, radius);

      if (isFloorViewOn) {
        this.setFloorView(activeMesh.name, 'on', false);
      }

      screenShotCamera.dispose();

      return screenShots as [string, string, string, string, string];
    }

    throw new Error('Active mesh was not found');
  }

  public onTranslationUpdate(translate: TFunction<'visualization3d'>) {
    this.enableRenderer();
    const gizmoBox = this.scene.getNodeByName('Gizmo');
    const gizmoCamera = this.scene.getCameraByName('GizmoCamera');

    if (isDefined(gizmoBox) && isDefined(gizmoCamera)) {
      gizmoBox.dispose(false, true);
      gizmoCamera.dispose(false, true);
      this.createGizmo(translate);
    }
  }

  private getActiveMesh() {
    return this.meshContainer.getChildMeshes().find((mesh) => mesh.isEnabled());
  }

  private pauseRenderWithDelay(delay = 1000) {
    this.scene.onAfterRenderObservable.remove(this.pauseRenderObserver);
    const time = performance.now();
    this.pauseRenderObserver = this.scene.onAfterRenderObservable.add((scene) => {
      if (performance.now() - time > delay && haveAnimationsStopped(scene)) {
        this.isEnginePausedFlag = true;
        this.scene.onAfterRenderObservable.remove(this.pauseRenderObserver);
      }
    });
  }

  private enableRenderer() {
    this.isEnginePausedFlag = false;

    if (this.engineAutoStopFeature) {
      this.pauseRenderWithDelay();
    }
  }

  private cleanUpHighlight() {
    this.highlightLayer.removeAllMeshes();
    this.highlightLayer.isEnabled = false;
    this.highlightedGeometriesContainer.getChildMeshes().forEach((mesh) => mesh.dispose(false, true));
  }

  private createGizmo(translate: TFunction<'visualization3d'>) {
    const activeCamera = this.scene.activeCamera as ArcRotateCamera;
    const gizmoCamera = new ArcRotateCamera('GizmoCamera', activeCamera.alpha, activeCamera.beta, 3, Vector3.Zero(), this.scene, false);
    setCameraBetaLimits(gizmoCamera);
    this.gizmoCamera = gizmoCamera;
    gizmoCamera.detachControl();
    this.scene.activeCameras = [activeCamera, gizmoCamera];

    activeCamera.onViewMatrixChangedObservable.add((camera) => {
      const { alpha, beta } = camera as ArcRotateCamera;
      gizmoCamera.alpha = alpha;
      gizmoCamera.beta = beta;
    });

    adaptCameraViewport(this.engine, gizmoCamera, gizmoViewportSize);
    gizmoCamera.layerMask = gizmoCameraMask;

    createGizmoBox(
      this,
      (orientation) => {
        navigateCameraTo(this.scene, activeCamera, {
          orientation: orientation,
        });
      },
      gizmoCameraMask,
      translate
    );
  }

  public static isInitialized() {
    return isDefined(Scene3dApi.apiSingleton);
  }

  // place for only one instance of the Scene3d context
  private static apiSingleton: Readonly<Nullable<Scene3dApi>> = null;

  /**
   * 1. Initializes a new engine.
   * 2. Creates an instance of Scene3dApi and returns it
   *
   * Set forceInit if you need to recreate the API. Beware, that the new engine is created
   */
  public static initAPI(options: Scene3dInitOptions, t: TFunction<'visualization3d'>) {
    const { isProduction = true, forceInit = false } = options;
    if (!forceInit && isDefined(Scene3dApi.apiSingleton)) {
      return Scene3dApi.apiSingleton;
    }

    // cleaning up the existing engine to free up memory in case force creation
    if (forceInit && Scene3dApi.apiSingleton !== null) {
      Scene3dApi.apiSingleton.getEngine().dispose();
      Scene3dApi.apiSingleton = null;
    }

    const canvas = document.createElement('canvas');
    const engine = new Engine(canvas, true, {
      preserveDrawingBuffer: true, // keep it in true for screen shots
      stencil: true, // keep it in true for highlighting layers
    });
    engine.setHardwareScalingLevel(0.5); // drawing x2 bigger image for better quality
    const scene3d = new Scene3dApi(engine, options, t);
    Scene3dApi.apiSingleton = scene3d;

    if (!isProduction) {
      load3dInspector(scene3d);
    }

    return Scene3dApi.apiSingleton;
  }

  /**
   * Adds a "compass" widget at the bottom of the provided mesh
   * @param mesh the mesh on which to add the compass at its bottom
   * @param orientationLabels an array of orientation-labels each having an angle and an optional label
   */
  public addOrientationCompass(mesh: Mesh, orientationLabels: NonEmptyArray<RHIMAPOReportingWearManagementApiV1ModelsOrientationLabelDto>) {
    const RADIUS_WEIGHT = 1.1;
    const DEGREE_LINE_LENGTH_PADDING_PERCENTAGE = 0.025;

    const meshBoundingBox = mesh.getBoundingInfo().boundingBox;
    const meshWidth = meshBoundingBox.maximumWorld.x - meshBoundingBox.minimumWorld.x;
    const meshDepth = meshBoundingBox.maximumWorld.z - meshBoundingBox.minimumWorld.z;
    const meshSize = Math.max(meshWidth, meshDepth);
    const compassRadius = (meshSize * RADIUS_WEIGHT) / 2;
    const meshBottomY = meshBoundingBox.minimum.y;
    const degreeLinePaddingHeight = compassRadius * DEGREE_LINE_LENGTH_PADDING_PERCENTAGE;

    const compassNode = new TransformNode('compassTransformNode', this.scene);
    Tags.AddTagsTo(compassNode, COMPASS_NODE_TAG);
    compassNode.parent = mesh;

    // Creates the compass 3d circle
    const compassCircle = create3dCompassCircle(this.scene, compassRadius, meshBottomY);
    // Creates multiple 3d lines from the center of the compass to its perimeter at different angles
    const degreeLineLength = compassRadius + degreeLinePaddingHeight;
    const linesMeshes: LinesMesh[] = create3dCompassAngleLines(this.scene, orientationLabels, degreeLineLength, meshBottomY);
    // Creates a 3d plane with dynamic texture where the angles & orientation-labels are painted on
    const plane = create3dCompassPlane(this.scene, orientationLabels, degreeLinePaddingHeight, compassRadius, meshBottomY);
    // Parent all generated 3d shapes to the mesh ( so that they will be removed from the scene when the mesh is removed )
    compassCircle.parent = compassNode;
    compassCircle.isPickable = false;
    linesMeshes.forEach((linesMesh) => {
      linesMesh.isPickable = false;
      linesMesh.parent = compassNode;
    });
    plane.parent = compassNode;
    plane.isPickable = false;
  }

  /**
   * When we switch between RBL and WEAR data we need to re-apply the thickness map.
   * This is done by the withThicknessMapEnhancer function of the meshUtils.ts.
   * But for this change to be seen, we need to temporarily enable the (paused) renderer.
   */
  public handleThicknessMapUpdated() {
    this.enableRenderer();
  }

  /**
   * Create a 3d point-cloud using babylonjs's "Thin Instances"
   */
  public addPointCloud(uniquePointCloudName: string, data: PointCloudData) {
    assert(data.vertex.length === data.colors.length, `Pointcloud vertices length(${data.vertex.length}) does not match colors length(${data.colors.length})`);

    const box = MeshBuilder.CreateBox(uniquePointCloudName, { size: VOXEL_SIZE }, this.getScene());
    box.isPickable = false;
    box.parent = this.meshContainer;

    const pointsCount = data.vertex.length / 3;
    log.debug('Point-cloud points count : ', pointsCount);
    const pointsMatricesData = new Float32Array(16 * pointsCount);
    const pointsColorData = new Float32Array(4 * pointsCount);

    // point clouds are computed in right-handed coordinate system, next matrix converts it to left-handed
    const convertingToLeftHandedMatrix = Matrix.Scaling(1, -1, 1);
    // FIXME we apply 2 rotations to align the PCDs with meshes. Evatually the rotation around Y axis should be 0.
    // rotations around X axis is needed to use Y axis pointing up
    const rotationMatrix = Matrix.RotationYawPitchRoll(Math.PI / 2, -Math.PI / 2, 0);
    const transformation = convertingToLeftHandedMatrix.multiply(rotationMatrix);

    for (let pointIndex = 0; pointIndex < pointsCount; pointIndex++) {
      const positionIndex = pointIndex * 3;
      const position = Vector3.FromArray(data.vertex, positionIndex);
      // transforming the position with scaling and rotation
      Vector3.TransformCoordinatesToRef(position, transformation, position);
      // Set the position of each point:
      // Use the point's x,y,z coordinates to create a translation matrix & copy that 16 items long array to our positions buffer at the right index
      // setting diagonal values to 1 to get a valid translation matrix
      pointsMatricesData[pointIndex * 16] = 1;
      pointsMatricesData[pointIndex * 16 + 5] = 1;
      pointsMatricesData[pointIndex * 16 + 10] = 1;
      pointsMatricesData[pointIndex * 16 + 15] = 1;
      pointsMatricesData[pointIndex * 16 + 12] = position.x;
      pointsMatricesData[pointIndex * 16 + 13] = position.y;
      pointsMatricesData[pointIndex * 16 + 14] = position.z;
      // Set red, green, blue and alpha for each point ( values have to be normalized )
      const colorDataIndex = pointIndex * 4;
      pointsColorData[colorDataIndex + 0] = data.colors[positionIndex]! / 255;
      pointsColorData[colorDataIndex + 1] = data.colors[positionIndex + 1]! / 255;
      pointsColorData[colorDataIndex + 2] = data.colors[positionIndex + 2]! / 255;
      pointsColorData[colorDataIndex + 3] = 1;
    }
    box.thinInstanceSetBuffer('matrix', pointsMatricesData, 16);
    box.thinInstanceSetBuffer('color', pointsColorData, 4);

    const mat = new StandardMaterial('ptsThinInstancedBoxMaterial', this.getScene());
    mat.disableLighting = true;
    mat.emissiveColor = Color3.White();
    box.material = mat;

    // Center the model
    positionModelInCenter(box);

    // freezing WM and normals to avoid re computations
    box.freezeWorldMatrix();
    box.freezeNormals();

    return box;
  }
}
