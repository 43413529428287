/**
 * @example
 *
 *  normalize(100, 200)(0.5) // => 50
 *
 * @example
 *
 *  [10, 20, 50].map(normalize(0, 1)); // => [0.1, 0.2, 0.5]
 *
 * @example
 *
 *   ```ts
 *   const input = [-0.15, 0.4, 0.1, 0.22];
 *
 *   input
 *     .map(Math.abs)
 *     .sort((left, right) => left - right)
 *     .map(normalize(0, Math.max(...input))); // => [0.25, 0.37, 0.54, 1]
 *   ```
 */
export function normalize(min: number, max: number) {
  const delta = max - min;

  return (value: number) => {
    return min + value * delta;
  };
}

export function getRandomInteger(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function roundFloatToDecimalPlaces(value: number, decimalPlaces: number) {
  const factor = 10 ** decimalPlaces;
  return Math.round(value * factor) / factor;
}

export function isNumber(candidate: unknown): candidate is number {
  return typeof candidate === 'number';
}

export function isNaturalNumber(candidate: unknown): candidate is number {
  return isNumber(candidate) && /^\d+$/.test(candidate.toString());
}

export function isNumeric(value: unknown) {
  return (typeof value === 'number' || (typeof value === 'string' && value.trim() !== '')) && !isNaN(value as number);
}

export function centimetersToMeters(valueInCentimeters: number) {
  return valueInCentimeters / 100;
}

export function toRadians(angleInDegrees: number) {
  return (Math.PI * angleInDegrees) / 180;
}

export function toDegrees(angleInRadians: number) {
  return (180 * angleInRadians) / Math.PI;
}
