import { settings } from '@rhim/design';
import { CampaignIcon } from '@rhim/icons/24';
import { ChevronRotatable, DropdownPanel, Spinner, Tooltip } from '@rhim/react';
import { campaignDropdown } from '@rhim/test-ids';
import { hasElements, isDefined } from '@rhim/utils';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import CampaignSelectionDropdown from './CampaignSelectionDropdown';

export enum ColorTheme {
  Dark = 'dark',
  Light = 'light',
  Medium = 'medium',
}

interface ColorThemeDefinition {
  bgColorActive?: Hex;
  bgColorHover?: Hex;
  borderColor: Hex;
  color: Hex;
  disabledColor: Hex;
}

type ColorThemes = {
  [key in ColorTheme]: ColorThemeDefinition;
};

const colorThemes: ColorThemes = {
  [ColorTheme.Dark]: {
    bgColorActive: settings.colors.Primary.Grey_8,
    bgColorHover: settings.colors.Primary.Grey_9,
    borderColor: settings.colors.Primary.Grey_7,
    color: settings.colors.Primary.Grey_2,
    disabledColor: settings.colors.Primary.Grey_8,
  },
  [ColorTheme.Light]: {
    borderColor: settings.colors.Primary.Grey_3,
    color: settings.colors.Primary.Grey_9,
    disabledColor: settings.colors.Primary.Grey_9,
  },
  [ColorTheme.Medium]: {
    borderColor: settings.colors.Primary.Grey_6,
    color: settings.colors.Primary.Grey_2,
    disabledColor: settings.colors.Primary.Grey_2,
  },
};

export type TooltipFormatter = (campaign: RHIM.CampaignOrUnassigned, campaigns: RHIM.CampaignOrUnassigned[]) => string;

interface Props {
  className?: string;
  isLoading: boolean;
  colorTheme: ColorTheme;
  campaigns: RHIM.CampaignOrUnassigned[] | undefined;
  selectedCampaignId: number | null | undefined;
  dataTestId?: string;
  onCampaignSelected: (campaignID: number | null) => void;
  tooltipFormatter?: TooltipFormatter;
}

const CampaignSelection: React.ChildlessComponent<Props> = ({
  className,
  campaigns,
  isLoading,
  selectedCampaignId,
  dataTestId,
  colorTheme,
  onCampaignSelected,
  tooltipFormatter,
}) => {
  const [campaignDropdownExpandedState, setCampaignDropdownExpandedState] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const hasCampaigns = !isLoading && hasElements(campaigns ?? []);

  const collapseDropdown = useCallback(() => {
    setCampaignDropdownExpandedState(false);
  }, [setCampaignDropdownExpandedState]);

  const handleMouseClick = useCallback(
    (event: MouseEvent) => {
      if (isDefined(ref.current) && !ref.current.contains(event.target as HTMLElement)) {
        collapseDropdown();
      }
    },
    [collapseDropdown]
  );

  useEffect(() => {
    document.addEventListener('click', handleMouseClick, true);
    return () => document.removeEventListener('click', handleMouseClick);
  }, [handleMouseClick]);

  const handleSelectorDropdownClicked = () => {
    setCampaignDropdownExpandedState(!campaignDropdownExpandedState);
  };

  const handleCampaignSelected = useCallback(
    (campaignId: number | null) => {
      collapseDropdown();
      onCampaignSelected(campaignId);
    },
    [collapseDropdown, onCampaignSelected]
  );

  const dropdownPanelCampaignSelector = useMemo(() => {
    if (!isDefined(campaigns)) {
      return null;
    }
    return (
      <SDropdownPanel isExpanded={campaignDropdownExpandedState}>
        <CampaignSelectionDropdown campaigns={campaigns} selectedCampaignId={selectedCampaignId} onCampaignSelected={handleCampaignSelected} />
      </SDropdownPanel>
    );
  }, [campaignDropdownExpandedState, campaigns, handleCampaignSelected, selectedCampaignId]);

  const selectedCampaign: RHIM.CampaignOrUnassigned | undefined = useMemo(() => {
    if (!isDefined(campaigns)) {
      return undefined;
    }
    return campaigns.find((campaign) => campaign.id === selectedCampaignId);
  }, [campaigns, selectedCampaignId]);

  return (
    <SDropdownContainer className={className} ref={ref} data-test-id={dataTestId}>
      <CampaignSelectionLabel
        selectedCampaign={selectedCampaign}
        campaigns={campaigns}
        hasCampaigns={hasCampaigns}
        isLoading={isLoading}
        colorTheme={colorTheme}
        isExpanded={campaignDropdownExpandedState}
        handleDropdownClicked={handleSelectorDropdownClicked}
        tooltipFormatter={tooltipFormatter}
      />
      {dropdownPanelCampaignSelector}
    </SDropdownContainer>
  );
};

export default React.memo(CampaignSelection);

interface CampaignSelectionLabelProps {
  dataTestId?: string;
  selectedCampaign: RHIM.CampaignOrUnassigned | undefined;
  campaigns?: RHIM.CampaignOrUnassigned[];
  hasCampaigns: boolean;
  isLoading: boolean;
  isExpanded: boolean;
  colorTheme: ColorTheme;
  handleDropdownClicked: () => void;
  tooltipFormatter?: TooltipFormatter;
}

const CampaignSelectionLabel: React.ChildlessComponent<CampaignSelectionLabelProps> = ({
  selectedCampaign,
  campaigns,
  hasCampaigns,
  isLoading,
  isExpanded,
  colorTheme,
  handleDropdownClicked,
  tooltipFormatter,
}) => {
  const { t } = useTranslation(['shared']);

  const loadingIndicator = (
    <>
      <Spinner size="24" inverted={true} rotationDuration={2.5} />
      <SDropdownLabel>{t('shared:strip.loadingIndicator')}</SDropdownLabel>
    </>
  );

  const campaignDropdownClasses = classNames({
    isDisabled: !hasCampaigns,
  });

  const theme = colorThemes[colorTheme];

  const tooltipText =
    isDefined(tooltipFormatter) && isDefined(selectedCampaign) && isDefined(campaigns) ? tooltipFormatter(selectedCampaign, campaigns) : undefined;

  return (
    <Tooltip title={tooltipText ?? ''} isShowing={isDefined(tooltipFormatter) && hasCampaigns} align={{ offset: [0, 0] }} overlayStyle={{ maxWidth: '500px' }}>
      <SHoveredRegion onClick={handleDropdownClicked} className={campaignDropdownClasses} data-test-id={campaignDropdown} theme={theme}>
        {isLoading ? (
          loadingIndicator
        ) : (
          <>
            {hasCampaigns ? (
              <>
                <CampaignIcon fill={theme.color} />
                <SDropdownLabel fill={theme.color}>
                  {isDefined(selectedCampaign) ? selectedCampaign.displayText : t('shared:strip.dropdownSelectCampaign')}
                </SDropdownLabel>
                <SHorizontalSpring />
                <SChevronIconRotatable orientation={isExpanded ? 'up' : 'down'} isDisabled={false} fill={theme.color} />
              </>
            ) : (
              <>
                <CampaignIcon fill={theme.disabledColor} />
                <SDropdownLabelDisabled color={theme.disabledColor}>{t('shared:strip.dropdownNoCampaigns')}</SDropdownLabelDisabled>
              </>
            )}
          </>
        )}
      </SHoveredRegion>
    </Tooltip>
  );
};

const SDropdownContainer = styled.div`
  display: flex;
  position: relative;
`;

const SDropdownPanel = styled(DropdownPanel)`
  overflow-x: hidden;
  min-width: 100%;
  max-height: 400px;
`;

const SHoveredRegion = styled.div<{ theme: ColorThemeDefinition }>`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 ${settings.Spacing.Spacing_200};
  cursor: pointer;
  border-style: solid;
  border-width: 0 1px 0 0;
  border-color: ${(props) => props.theme.borderColor};

  &.isDisabled {
    pointer-events: none;
  }

  &:hover:not(.isDisabled) {
    background-color: ${(props) => props.theme.bgColorHover ?? 'transparent'};
    border-right-style: solid;

    &:active {
      background-color: ${(props) => props.theme.bgColorActive ?? 'transparent'};
    }
  }
`;

const SDropdownLabel = styled.span<{ fill?: string }>`
  margin-left: ${settings.Spacing.Spacing_100};
  white-space: nowrap;
  font-family: ${settings.typography.FontFamily.Medium};
  font-size: ${settings.typography.FontSize.Small};
  color: ${(props) => props.fill ?? settings.colors.Primary.Grey_2};
`;

const SDropdownLabelDisabled = styled(SDropdownLabel)<{ color: string }>`
  color: ${(props) => props.color};
`;

const SChevronIconRotatable = styled<React.FunctionComponent<React.ComponentProps<typeof ChevronRotatable> & { isDisabled: boolean }>>(ChevronRotatable).attrs(
  (props) => ({
    fill: isDefined(props['fill']) ? props['fill'] : props.isDisabled ? settings.colors.Primary.Grey_8 : settings.colors.Primary.Blue_1,
  })
)`
  margin-left: ${settings.Spacing.Spacing_200};
`;

const SHorizontalSpring = styled.span`
  flex-grow: 1;
`;
