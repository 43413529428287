import { flipMap } from '../lib/objects';

/**
 * Enable experiments by activating feature flags.
 * @example
 *
 * ```ts
 * <FeatureToggles features={activateFeatures(environment.experimentalFeatures)}>
 * ```
 */
export function activateFeatures<T extends string>(experimentalFeatures: T[]) {
  const features = new Map<T, boolean>();

  experimentalFeatures.forEach((flag) => features.set(flag, true));

  const activeFeatures = flipMap<T, boolean>(features).get(true)!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return activeFeatures;
}
