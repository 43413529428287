import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const BrickIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.667 12.39V4.944L12 3.609v7.448l-1.333 1.334zM4 13.334h5.333v-8H4v8zM6.276 2.667h4.781L9.724 4H4.943l1.333-1.333zm7.006-.922a.665.665 0 0 0-.615-.412H6a.667.667 0 0 0-.471.196L2.863 4.195a.66.66 0 0 0-.196.472V14c0 .369.298.667.666.667H10a.66.66 0 0 0 .472-.196l2.666-2.666a.664.664 0 0 0 .195-.472V2a.66.66 0 0 0-.051-.255z"
      />
    </svg>
  );
};

BrickIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_1,
};
