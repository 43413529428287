import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const SortIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 1a1 1 0 0 0-2 0v11.586l-.293-.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l2-2a1 1 0 1 0-1.414-1.414L4 12.586V1zm2 0h9v2H6V1zm1 4H6v2h7V5H7zM6 9h5v2H6V9z"
        fill={fill}
      />
    </svg>
  );
};

SortIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
