import { VesselType } from '@rhim/rest';
import {
  RHIMMeasurementServiceContractsDataLiningCondition,
  RHIMMeasurementServiceContractsDataLiningMaintenanceType,
  RHIMMeasurementServiceContractsDataVesselLining,
} from '@rhim/rest/measurementService';

export enum MetadataType {
  Default = 'default',
  LesPointCloud = 'lesPointCloud',
  PointCloud = 'pointCloud',
}

export interface IFileUploadMetadata {
  metadataType: MetadataType.Default;
  customerId: UUID;
  vesselType?: VesselType;
  isPostMortem?: boolean;
  fileType: string;
  campaignName?: string;
  vesselId?: string;
  heatNumber?: number;
  measurementTaken?: string; // in ISO 8601 format i.e 2022-02-18T11:18:06+01:00
  vesselState?: string;
}

export interface ILesFileUploadMetadata {
  metadataType: MetadataType.LesPointCloud;
  customerId: UUID;
  vesselId: UUID;
  refractoryCondition?: string;
  measurementTaken: string; // in ISO 8601 format i.e 2022-02-18T11:18:06+01:00
  emailResponsible: string;
  scanResolution?: string;
  comment?: string;
  referencePointOpeningX?: number;
  referencePointOpeningY?: number;
  referencePointOpeningZ?: number;
}

/**
 * closely related to RHIMMeasurementServiceV1ModelsUploadPtsFileDto
 */
export interface IFilePointCloudUploadMetadata {
  metadataType: MetadataType.PointCloud;
  referenceMeasurementId?: string;
  customerId: UUID;
  vesselShortName: string;
  vesselId: string;
  vesselType: VesselType;
  campaign: number;
  measurementTaken: string; // in ISO 8601 format i.e 2022-02-18T11:18:06+01:00
  heat: number;
  vesselLining: RHIMMeasurementServiceContractsDataVesselLining;
  liningCondition: RHIMMeasurementServiceContractsDataLiningCondition;
  hotMeasurement: boolean;
  liningMaintenanceType?: RHIMMeasurementServiceContractsDataLiningMaintenanceType;
  notes?: string;
  isUploadModeViewOnly: boolean;
}
