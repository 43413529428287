import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const VesselBOFIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 1v7a1 1 0 1 1-2 0V1a1 1 0 1 1 2 0zm5 5v2h1a1 1 0 1 1 0 2h-1v1.235C14 13.907 12.486 16 8 16s-6-2.093-6-4.765V10H1a1 1 0 1 1 0-2h1V6c0-.227.077-.446.218-.623l1.5-2.765a1 1 0 0 1 1.564 1.246L4 6.35v4.345l.273-.044c.523-.087.912-.151 1.727-.151.852 0 1.75.151 2.559.287.661.112 1.263.213 1.73.213.691 0 .922-.046 1.259-.113.126-.025.267-.053.452-.083V6.359l-1.382-2.487a1 1 0 1 1 1.542-1.273l1.611 2.764c.148.179.229.405.229.637z"
      />
    </svg>
  );
};

VesselBOFIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
