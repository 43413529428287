import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const MeasurementConfigIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        d="M12.4 8a.4.4 0 0 1 .39.313l.14.626c.2.06.394.142.577.24l.541-.345a.4.4 0 0 1 .497.055l.566.566a.4.4 0 0 1 .055.497l-.345.541c.098.183.179.376.24.578l.626.139a.4.4 0 0 1 .313.39v.8a.4.4 0 0 1-.313.39l-.626.14a3.184 3.184 0 0 1-.24.577l.345.54a.4.4 0 0 1-.055.498l-.566.566a.398.398 0 0 1-.497.055l-.541-.344a3.23 3.23 0 0 1-.578.24l-.138.625A.4.4 0 0 1 12.4 16h-.8a.4.4 0 0 1-.39-.313l-.14-.626a3.23 3.23 0 0 1-.577-.24l-.541.345a.4.4 0 0 1-.497-.055l-.566-.566a.4.4 0 0 1-.055-.497l.345-.541a3.184 3.184 0 0 1-.24-.578l-.626-.138A.4.4 0 0 1 8 12.4v-.8a.4.4 0 0 1 .313-.39l.626-.14c.061-.2.142-.394.24-.577l-.345-.541a.4.4 0 0 1 .055-.497l.566-.566a.4.4 0 0 1 .497-.055l.541.345c.183-.098.376-.18.578-.24l.138-.626A.4.4 0 0 1 11.6 8zM6 1.333c.692 0 1.263.53 1.327 1.205l.006.129v6.75A5.31 5.31 0 0 0 6.667 12c0 .743.152 1.451.426 2.094a1.33 1.33 0 0 1-.965.567L6 14.667H3.333c-.692 0-1.262-.53-1.327-1.205L2 13.333V2.667c0-.692.53-1.263 1.205-1.328l.128-.006H6zm6 9.067a1.6 1.6 0 1 0 0 3.2 1.6 1.6 0 0 0 0-3.2zM6 2.667H3.333v10.666h2.668v-.666h-.668a.666.666 0 0 1-.098-1.326l.098-.008h.668V10h-.668a.666.666 0 0 1-.098-1.326l.098-.007h.668L6 7.333h-.667a.666.666 0 0 1-.098-1.326L5.333 6H6V4.667h-.667a.666.666 0 0 1-.098-1.326l.098-.008H6v-.666zm5.722-1.209.083.07 2 2a.666.666 0 0 1-.86 1.014l-.083-.07L12 3.608v3.058c-.46 0-.908.058-1.334.168V3.609l-.861.862a.666.666 0 0 1-1.013-.86l.07-.082 2-2a.666.666 0 0 1 .86-.07z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
};

MeasurementConfigIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_1,
};
