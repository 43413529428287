import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import styled from 'styled-components';
const RblLegend = () => {
  const { t } = i18nReact.useTranslation(['volume']);

  return (
    <Container>
      <LegendValue>
        <ColorLine color={settings.colors.Primary.Blue_9} />
        <Text>{t('volume:min')}</Text>
        <ColorLine color="#af7aa0" dashed={true} />
        <Text>{t('volume:median')}</Text>
        <Circle />
        <Text>{t('volume:additionalHeatsText')}</Text>
      </LegendValue>
    </Container>
  );
};
const Container = styled.div`
  display: inline-flex;
  padding-right: ${settings.Spacing.Spacing_300};
  justify-content: flex-end;
`;

const LegendValue = styled.div`
  display: inline-flex;
  align-items: center;
  gap: ${settings.Spacing.Spacing_50};
`;

const Circle = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 26px;
  background-color: ${settings.colors.Primary.Blue_8};
`;

const Text = styled.span`
  font-size: ${settings.typography.FontSize.X_Small};
  color: ${settings.colors.Primary.Grey_6};
  margin-right: ${settings.Spacing.Spacing_200};
`;
const ColorLine = styled.div.attrs({ role: 'presentation' })<{ color: string; dashed?: boolean }>`
  width: 24px;
  height: 2px;
  border-bottom: ${(props) => (props.dashed === true ? `2px dotted ${props.color}` : `2px solid ${props.color}`)};
  position: relative;
`;

export default RblLegend;
