import { settings } from '@rhim/design';
import { isDefined } from '@rhim/utils';
import styled from 'styled-components';

export const SSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${settings.Spacing.Spacing_200} ${settings.Spacing.Spacing_300};
`;

export const PrimaryLabel = styled.span<{ backgroundColor?: string }>`
  background-color: ${(props) => (isDefined(props.backgroundColor) ? props.backgroundColor : 'transparent')};
  font-family: ${settings.typography.FontFamily.Bold};
  font-size: ${settings.typography.FontSize.Medium};
  color: ${(props) => (isDefined(props.backgroundColor) ? settings.colors.Monochromatic.White : settings.colors.Primary.Grey_7)};
  padding: 0 ${settings.Spacing.Spacing_50};
  align-self: flex-start;
  border-radius: 3px;
`;
