import 'antd/es/date-picker/style/index';

import { settings } from '@rhim/design';
import { getBrowserLocale, toUnicodeLocale } from '@rhim/i18n';
import { CalendarRangeIcon } from '@rhim/icons/24';
import { closeButtonCalendarMeasurementView, dateFilterInputMeasurementView } from '@rhim/test-ids';
import { DATE_FORMAT } from '@rhim/utils';
import generatePicker from 'antd/es/date-picker/generatePicker';
import type { RangePickerProps } from 'antd/es/date-picker/generatePicker/interface.d.ts';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import React, { useCallback, useMemo, useState } from 'react';
import { Translation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../Button/Button';
import IconRightArrowIconSvg from './arrow-right-16.svg?url';
import { ANTD_DATEPICKER_LOCALES, GlobalDatePickerStyleSheet, SWrapper, UISize } from './DatePicker';
export type { RangeValueType as RangeValue } from 'rc-picker/lib/PickerInput/RangePicker';

// explicitly import the antd Datepicker's css style

const AntdDatePickerUsingDateFns = generatePicker<Date>(dateFnsGenerateConfig);
const { RangePicker: AntdRangePickerUsingDateFns } = AntdDatePickerUsingDateFns;

type Props = RangePickerProps<Date> & {
  className?: string;
  uiSize: UISize;
};

const RangePicker: React.FC<Props> = ({ className, uiSize, ...rest }) => {
  const browserLocale = getBrowserLocale();
  const [isPickerOpen, setPickerOpen] = useState(false);

  const handlePickerOpenStateChanged = useCallback((isOpen: boolean) => {
    setPickerOpen(isOpen);
  }, []);

  const handleClosePickerClicked = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setPickerOpen(false);
  }, []);

  const extraFooter = useMemo(() => {
    return (
      <SFooterWrapper>
        <Translation>
          {(t) => (
            <Button
              data-test-id={closeButtonCalendarMeasurementView}
              mode="ghost"
              size="small-40"
              onClick={handleClosePickerClicked}
              label={t('sharedComponents:common.close')}
            />
          )}
        </Translation>
      </SFooterWrapper>
    );
  }, [handleClosePickerClicked]);

  return (
    <SWrapper className={className} uiSize={uiSize} data-test-id={dateFilterInputMeasurementView}>
      <GlobalDatePickerStyleSheet />
      <GlobalRangePickerStyleSheet />
      <SAntdRangePickerUsingDateFns
        locale={ANTD_DATEPICKER_LOCALES[toUnicodeLocale(browserLocale)]}
        inputReadOnly
        allowEmpty={[true, true]}
        format={DATE_FORMAT.SHORT_LOCALIZED}
        suffixIcon={<CalendarRangeIcon className="notification-type-info" />}
        open={isPickerOpen}
        onOpenChange={handlePickerOpenStateChanged}
        renderExtraFooter={() => extraFooter}
        {...rest}
      />
    </SWrapper>
  );
};

RangePicker.whyDidYouRender = true;
export default React.memo(RangePicker);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SAntdRangePickerUsingDateFns = styled(AntdRangePickerUsingDateFns as any)`
  /* replace antd "swap right" icon that separates the 2 date fields with our own */
  .anticon.anticon-swap-right {
    svg {
      display: none;
    }

    &::before {
      display: inline-block;
      content: url(${IconRightArrowIconSvg});
    }
  }

  .ant-picker-range-separator {
    padding: 0 ${settings.Spacing.Spacing_300} 0 0;
  }

  .ant-picker-clear {
    right: ${settings.Spacing.Spacing_200};
  }

  .ant-picker-active-bar {
    bottom: 0;
    background: ${settings.colors.Primary.Blue_9};
  }
`;

export const GlobalRangePickerStyleSheet = settings.globals.patchedCreateGlobalStyle`
  .ant-picker-dropdown {
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
      background: ${settings.colors.Primary.Blue_9};
    }

    .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
      background: ${settings.colors.Primary.Grey_2};
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    height: 32px;
  }
`;

const SFooterWrapper = styled.div`
  margin: ${settings.Spacing.Spacing_150} 0;
  display: flex;
  justify-content: center;
`;
