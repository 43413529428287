import {
  ColorscalesApi,
  Configuration,
  CrkPredictionsApi,
  HostingModesApi,
  OrientationLabelApi,
  RegionsApi,
  VesselFunctionalProductsApi,
  WearExplorerViewApi,
} from '@rhim/rest/wearManagement';

/**
 * "https://apim-apo-dev.azure-api.net/wearmanagement/v1".replace(/\/+$/, "");
 */
const BASE_URL = '/api/wearmanagement/v1';

const configuration: Configuration = {} as Configuration;

export const hoistingModes = new HostingModesApi(configuration, BASE_URL);
export const regions = new RegionsApi(configuration, BASE_URL);
export const wearExplorer = new WearExplorerViewApi(configuration, BASE_URL);
export const crkPredictions = new CrkPredictionsApi(configuration, BASE_URL);
export const colorScale = new ColorscalesApi(configuration, BASE_URL);
export const vesselFunctionalProducts = new VesselFunctionalProductsApi(configuration, BASE_URL);
export const orientationLabels = new OrientationLabelApi(configuration, BASE_URL);
