import Editor, { EditorProps, useMonaco } from '@monaco-editor/react';
import { isDefined } from '@rhim/utils';
import { FormInstance } from 'antd';
import Monaco from 'monaco-editor';
import React from 'react';

import { FieldLabel } from '../../partials';
import { TooltipProps } from '../Tooltip';

type JSONValue = string | number | boolean | JSONObject | JSONArray;
interface JSONArray extends Array<JSONValue> {}

export interface DefaultProps extends EditorProps {
  label?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  schema?: JSONValue;
  tooltip?: TooltipProps;
}

interface JSONObject {
  [x: string]: JSONValue;
}

const CodeEditor: React.FC<React.PropsWithChildren<DefaultProps>> = ({ isDisabled, label, isRequired, schema, tooltip, ...rest }) => {
  const theme = 'vs-dark';
  const monaco = useMonaco();

  React.useEffect(() => {
    if (isDefined(schema)) {
      monaco?.languages.json.jsonDefaults.setDiagnosticsOptions({
        validate: true,
        schemas: [
          {
            uri: '',
            fileMatch: ['*'],
            schema,
          },
        ],
      });
    }
  }, [monaco, schema]);

  return (
    <>
      {isDefined(label) ? <FieldLabel text={label} isRequired={isRequired} isDisabled={isDisabled} tooltip={tooltip} /> : null}
      <Editor theme={theme} {...rest} />
    </>
  );
};

CodeEditor.whyDidYouRender = true;

export interface Props extends DefaultProps {
  form: FormInstance;
  name: string;
}

const CodeEditorWithValidationState = ({ onValidate: _onValidate, form, name, ...props }: Props) => {
  const onValidate = React.useCallback(
    (errors: Monaco.editor.IMarker[]) => {
      form.setFields([
        {
          name,
          errors: errors.map((error) => error.message),
        },
      ]);

      _onValidate?.(errors);
    },

    [form, name, _onValidate]
  );

  return <CodeEditor onValidate={onValidate} {...props} />;
};

CodeEditorWithValidationState.displayName = 'CodeEditorWithValidationState';

export default React.memo(CodeEditorWithValidationState);
export { CodeEditor as CodeEditorDefault };
