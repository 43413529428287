export function unique<T extends Primitive>(collection: NonEmptyArray<T>): NonEmptyArray<T>;
export function unique<T extends Primitive>(collection: T[]): T[] {
  return [...new Set(collection)];
}

export const hasElements = <T>(collection: T[] | readonly T[]): collection is NonEmptyArray<T> => collection.length > 0;

export const head = <T>(collection: NonEmptyArray<T>): T => collection['0'];

export const last = <T>(collection: NonEmptyArray<T>): T =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  collection[collection.length - 1]!;

/**
 * Find min and max value from the provided collection
 * If ignoreNegative is set to true, all negative values will be ignored and only positive minimum is returned
 *
 * @param collection
 * @param ignoreNegative
 * @returns
 */
export const getMinMax = (collection: Iterable<number>, ignoreNegative = false): { min: number; max: number } => {
  let min = Infinity;
  let max = -Infinity;
  let length = 0;

  for (const value of collection) {
    const skipNegative = ignoreNegative && value < 0;
    if (!skipNegative) {
      min = min > value ? value : min;
      max = max < value ? value : max;
    }

    length++;
  }

  if (length === 0 || (min === Infinity && max === -Infinity)) {
    return {
      min: -Infinity,
      max: Infinity,
    };
  }

  return { min, max };
};

type NumericKeys<T> = {
  [Key in keyof T]: T[Key] extends number ? Key : never;
}[keyof T];
/**
 * It allows to sort by group.
 * Example:
 *  data = [{x: 10, y: 5}, {x: 5, y: 5}, {x: 5, y: 0}]
 *  sortGroup(data, ['x', 'y']) // returns [{x: 5, y: 0}, {x: 5, y: 5}, {x: 10, y: 5}]
 *
 * TODO better typings / unit test
 *
 * @param data
 * @param sortingGroup
 * @returns
 */
export function sortGroup<T>(data: ReadonlyArray<T>, sortingGroup: NumericKeys<T>[]): T[] {
  return [...data].sort((a, b) => sortingGroup.reduce((acc, v) => acc || Number(a[v]) - Number(b[v]), 0));
}

/**
 * Use this until the contract regarding prediction data is known and modeled on our side.
 *
 * @deprecated
 */
last.UNSAFE = <T>(collection: T[]): T => last(collection as NonEmptyArray<T>);

export function intersperse<T, U>(collection: T[], separator: U): (T | U)[] {
  if (!hasElements(collection)) {
    return [];
  }

  return collection.slice(1).reduce<(T | U)[]>((elements, element) => elements.concat([separator, element]), [head(collection)]);
}

/**
 * Creates an exhaustive set made of all union members.
 *
 * @example
 *
 * ```ts
 * type Fruit = 'Apple' | 'Banana';
 *
 * const fruits = enumerate<Fruit>()('Apple', 'Banana');
 *
 * fruits.has('Apple'); // true
 * ```
 */
export function enumerate<T extends Primitive>() {
  return <U extends NonEmptyArray<T>>(...elements: [T] extends [ValueOf<U>] ? U : never) => new Set(elements);
}

export function toArray<T>(items: T | T[]): T[] {
  return Array.isArray(items) ? items : Array.from([items]);
}

export function findDuplicates<T extends Primitive>(collection: T[]): T[] {
  return collection.filter((item, index) => collection.indexOf(item) !== index);
}

export function flatMap<T, U>(array: T[], callbackfn: (value: T, index: number, array: T[]) => U[]): U[] {
  return Array.prototype.concat(...array.map(callbackfn));
}

/**
 * Helper function to get all elements from an async iterator into an array
 * @param generator The generator function to retrieve the items from
 * @returns
 */
export async function getArayFromAsyncIterator<T>(generator: AsyncIterable<T>): Promise<T[]> {
  const items: T[] = [];

  // iterate over all elements of the generator using "for of" in combination with await
  for await (const x of generator) {
    items.push(x);
  }
  return items;
}
