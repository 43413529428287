import { settings } from '@rhim/design';
import { LiningIcon } from '@rhim/icons/16';
import * as React from 'react';
import styled from 'styled-components';

interface Props {
  children: string | React.ReactText | React.ReactNode;
}

const Legend: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ children }) => (
  <Container>
    <IconContainer>
      <LiningIcon fill={settings.colors.Primary.Grey_6} />
    </IconContainer>
    <Wrapper>
      <Text>{children}</Text>
    </Wrapper>
  </Container>
);

const Text = styled.span`
  color: ${settings.colors.Primary.Grey_6};
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.X_Small};
`;

const Wrapper = styled.div`
  padding-left: 4px;
`;

const IconContainer = styled.div`
  color: ${settings.colors.Primary.Grey_6};
`;

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export default React.memo(Legend);
