import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const WearGraphIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M2.618 2.076a1.001 1.001 0 0 1 1.09.217l.999.999A.999.999 0 0 1 4 4.999v11a1 1 0 1 1 0 2v2h15a1 1 0 0 1 1.707-.707l.999 1a1.001 1.001 0 0 1 0 1.415l-1 .999a.993.993 0 0 1-.706.293.997.997 0 0 1-1-1H3a1 1 0 0 1-1-1v-16a.999.999 0 0 1-.707-1.707l.999-1a1 1 0 0 1 .326-.216zM16 15.999a1 1 0 1 1 0 2h-1a1 1 0 1 1 0-2zm4 0a1 1 0 1 1 0 2h-1a1 1 0 1 1 0-2zm-8 0a1 1 0 1 1 0 2h-1a1 1 0 1 1 0-2zm-4 0a1 1 0 1 1 0 2H7a1 1 0 1 1 0-2zM5.308 6.278a1 1 0 0 1 1.414.029l3.099 3.23 5.112 2.364 5.275 1.12c.54.115.885.646.77 1.186a1.002 1.002 0 0 1-1.186.77l-5.385-1.143a.986.986 0 0 1-.211-.07L8.81 11.273a.991.991 0 0 1-.302-.215l-3.23-3.366a1 1 0 0 1 .029-1.414z"
      />
    </svg>
  );
};

WearGraphIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Grey_10,
};
