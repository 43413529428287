import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const CampaignIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M13.708 13.6c-1.231 1.255-2.873 2.102-4.708 2.331v-2.33zM7 13.6v2.331c-1.835-.229-3.477-1.076-4.708-2.33H7zM3 9v3H1.079c-.523-.904-.874-1.917-1.01-3H3zm12.93 0c-.134 1.083-.485 2.096-1.01 3H13V9zM11 9v3H5V9h6zM7 4v3H.069c.136-1.083.487-2.096 1.01-3H7zm7.921 0c.523.904.874 1.917 1.01 3H9V4zm-6.92-4c2.024 0 3.87.759 5.278 2H2.72C4.13.759 5.974 0 8 0z"
      />
    </svg>
  );
};

CampaignIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
