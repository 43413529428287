import { isDefined } from '@rhim/utils';
import React, { FC, useCallback } from 'react';
import styled from 'styled-components';

import { ButtonCircular } from '../ButtonCircular';

interface ClusteredCircularButtonProps {
  button: ClusteredCircularButton;
  horizontal?: boolean;
  onPress: (buttonId: string) => void;
}
const ClusteredCircularButton: FC<React.PropsWithChildren<ClusteredCircularButtonProps>> = ({ button, horizontal, onPress }) => {
  const handleOnPress = useCallback(() => {
    onPress(button.buttonId);
  }, [button.buttonId, onPress]);

  return (
    <SButtonCircular
      key={button.buttonId}
      isDisabled={button.isDisabled}
      horizontal={horizontal}
      icon={button.icon}
      tooltip={button.tooltip}
      tooltipPlacement={button.tooltipPlacement}
      onPress={handleOnPress}
    />
  );
};

export interface ClusteredCircularButton extends Omit<React.ComponentProps<typeof ButtonCircular>, 'onPress'> {
  buttonId: string;
}

interface Props {
  className?: string;
  horizontal?: boolean;
  buttons: ClusteredCircularButton[];
  onPress: (pressedButtonId: string) => void;
}
const ClusteredCircularButtons: React.ChildlessComponent<Props> = ({ className, buttons, horizontal, onPress }) => {
  return (
    <StyledWrapper className={className} horizontal={horizontal}>
      {buttons.map((button) => (
        <ClusteredCircularButton key={button.buttonId} button={button} horizontal={horizontal} onPress={onPress} />
      ))}
    </StyledWrapper>
  );
};

ClusteredCircularButtons.whyDidYouRender = true;
export default React.memo(ClusteredCircularButtons);

const StyledWrapper = styled.div<{ horizontal?: boolean }>`
  display: flex;
  flex-direction: column;
  ${(props) => (isDefined(props.horizontal) ? 'flex-direction: row;' : '')}
`;

const SButtonCircular = styled(ButtonCircular)<{ horizontal?: boolean }>`
  border-radius: unset;
  ${(props) => isDefined(props.horizontal) && 'width: 48px'};

  &:hover:active:not(:disabled),
  &:focus {
    z-index: 1;
  }

  --borderRadius: 20px;

  &:first-of-type {
    border-top-left-radius: var(--borderRadius);
    border-top-right-radius: ${(props) => (isDefined(props.horizontal) ? 'unset' : 'var(--borderRadius)')};
    border-bottom-left-radius: ${(props) => (isDefined(props.horizontal) ? 'var(--borderRadius)' : 'unset')};
    ${(props) => isDefined(props.horizontal) && 'padding-left: 14px'};
  }

  &:not(:first-of-type) {
    margin-top: ${(props) => (isDefined(props.horizontal) ? '0' : '-1px;')};
    margin-left: ${(props) => (isDefined(props.horizontal) ? '-1px' : '0')};
  }

  &:last-of-type {
    border-bottom-left-radius: ${(props) => (isDefined(props.horizontal) ? 'unset' : 'var(--borderRadius)')};
    border-top-right-radius: ${(props) => (isDefined(props.horizontal) ? 'var(--borderRadius)' : 'unset')};
    border-bottom-right-radius: var(--borderRadius);
    ${(props) => isDefined(props.horizontal) && 'padding-right: 14px'};
  }
`;
