import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
}

export const UnhealthyIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 2.6665C8.63599 2.6665 2.66666 8.63584 2.66666 15.9998C2.66666 23.3638 8.63599 29.3332 16 29.3332C23.364 29.3332 29.3333 23.3638 29.3333 15.9998C29.3333 8.63584 23.364 2.6665 16 2.6665Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 9.3335C15.264 9.3335 14.6667 9.93083 14.6667 10.6668V17.3335C14.6667 18.0695 15.264 18.6668 16 18.6668C16.736 18.6668 17.3333 18.0695 17.3333 17.3335V10.6668C17.3333 9.93083 16.736 9.3335 16 9.3335ZM14 22.0002C14 23.1055 14.8947 24.0002 16 24.0002C17.1053 24.0002 18 23.1055 18 22.0002C18 20.8948 17.1053 20.0002 16 20.0002C14.8947 20.0002 14 20.8948 14 22.0002Z"
        fill="black"
      />
    </svg>
  );
};

UnhealthyIcon.defaultProps = {
  size: '32',
  fill: settings.colors.Operational.State_Alert_Yellow_2,
};
