import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const TrashIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M19 10v9c0 1.657-1.343 3-3 3H8c-1.657 0-3-1.343-3-3v-9h14zm-5-8c1.654 0 3 1.346 3 3v1h3c.552 0 1 .447 1 1 0 .553-.448 1-1 1H4c-.552 0-1-.447-1-1 0-.553.448-1 1-1h3V5c0-1.654 1.346-3 3-3zm0 2h-4c-.551 0-1 .448-1 1v1h6V5c0-.552-.449-1-1-1z"
      />
    </svg>
  );
};

TrashIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
