import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const FileCheckmarkIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M16.6 10l-4.8 6.6c-.174.232-.44.377-.73.397-.022.002-.046.003-.07.003-.264 0-.52-.104-.707-.293L7.586 14c-.39-.391-.39-1.023 0-1.414s1.024-.391 1.414 0l1.892 1.892L15 8.801c.33-.443.958-.53 1.4-.2.442.331.532.958.2 1.399zM16 2H6.005C4.898 2 4 2.897 4 4.005v15.99C4 21.103 4.898 22 6.005 22h11.99C19.104 22 20 21.103 20 19.995V6l-4-4z"
      />
    </svg>
  );
};

FileCheckmarkIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
