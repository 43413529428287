import { settings } from '@rhim/design';
import { ConfigProvider } from 'antd';
import { Popover as AntdPopover, PopoverProps } from 'antd';
import { FC } from 'react';
export type { PopoverProps } from 'antd';

export const Popover: FC<React.PropsWithChildren<PopoverProps>> = ({ children, ...props }) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Popover: {
            zIndexPopup: settings.Elevation.Popover,
          },
        },
      }}
    >
      <AntdPopover {...props}>{children}</AntdPopover>
    </ConfigProvider>
  );
};
