import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  backgroundColor?: Property.Color;
  foregroundColor?: Property.Color;
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const AppStateMissingIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  backgroundColor,
  foregroundColor,
  size,
  title,
  role,
  ...rest
}) => {
  const titleText = '25E46C60-AC13-4B9A-AA79-D2110C77A931';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width={size} height={size} viewBox="0 0 32 32" {...rest}>
      {isDefined(title) ? <title>{title}</title> : <title>{titleText}</title>}
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icon/Ops_State_Deactivated_32">
          <path
            d="M30,16 C30,23.732 23.732,30 16,30 C8.268,30 2,23.732 2,16 C2,8.268 8.268,2 16,2 C23.732,2 30,8.268 30,16"
            id="Fill-1"
            fill={backgroundColor}
          ></path>
          <path
            d="M17.41425,16.00025 L21.70725,11.70725 C22.09825,11.31725 22.09825,10.68425 21.70725,10.29325 C21.31625,9.90225 20.68425,9.90225 20.29325,10.29325 L16.00025,14.58625 L11.70725,10.29325 C11.31625,9.90225 10.68425,9.90225 10.29325,10.29325 C9.90225,10.68425 9.90225,11.31725 10.29325,11.70725 L14.58625,16.00025 L10.29325,20.29325 C9.90225,20.68425 9.90225,21.31625 10.29325,21.70725 C10.48825,21.90225 10.74425,22.00025 11.00025,22.00025 C11.25625,22.00025 11.51225,21.90225 11.70725,21.70725 L16.00025,17.41425 L20.29325,21.70725 C20.48825,21.90225 20.74425,22.00025 21.00025,22.00025 C21.25625,22.00025 21.51225,21.90225 21.70725,21.70725 C22.09825,21.31625 22.09825,20.68425 21.70725,20.29325 L17.41425,16.00025 Z"
            id="Fill-5"
            fill={foregroundColor}
          ></path>
        </g>
      </g>
    </svg>
  );
};

AppStateMissingIcon.defaultProps = {
  size: '32',
  backgroundColor: settings.colors.Operational.State_Notif_Grey_2,
  foregroundColor: settings.colors.Monochromatic.White,
};
