import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const KilnIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.333 7.333a6.667 6.667 0 1 1 11.979 4.03 1.667 1.667 0 1 1-1.975 1.743A6.636 6.636 0 0 1 8 14a6.636 6.636 0 0 1-3.337-.894 1.667 1.667 0 1 1-1.975-1.743 6.638 6.638 0 0 1-1.355-4.03zm12 0a5.333 5.333 0 1 0-10.666 0 5.333 5.333 0 0 0 10.666 0zm-10 0a4.667 4.667 0 1 1 9.33.17 4.333 4.333 0 0 1-4.104 4.491l-.042-.022a4.667 4.667 0 0 1-5.183-4.638zm7.645-1.5-.032.056c-.67 1.16-1.753 1.79-2.71 2.347-.815.474-1.52.883-1.921 1.58a4.02 4.02 0 0 0-.156.297 3.333 3.333 0 1 1 4.818-4.28z"
        fill={fill}
      />
    </svg>
  );
};

KilnIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
