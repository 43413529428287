import { settings } from '@rhim/design';
import { CircleXIcon as CircleXSVG, LoaderIcon as LoaderSVG } from '@rhim/icons/24';
import * as React from 'react';
import styled from 'styled-components';

import { ProgressBar } from '../ProgressBar';

const CircleXIcon = <CircleXSVG fill={settings.colors.Primary.Blue_9} />;
const LoaderIcon = <LoaderSVG fill={settings.colors.Primary.Blue_9} />;

interface Props {
  progress: number;
  onCancel: React.UIEventHandler<HTMLDivElement>;
  message: string;
}

export const Verifying: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ progress, onCancel, message }) => {
  return (
    <Wrapper>
      <Centerer>
        <SpinnerContainer>{LoaderIcon}</SpinnerContainer>
      </Centerer>
      <ProgressContainer>
        <Message>{message}</Message>
        <ProgressBar
          type="line"
          percent={progress}
          showInfo={false}
          strokeWidth={4}
          strokeColor={settings.colors.Operational.State_Blue_2}
          trailColor={settings.colors.Primary.Grey_3}
          style={{ fontSize: '8px' }}
        />
      </ProgressContainer>
      <Centerer>
        <Cancel onClick={onCancel} role="button">
          <Target>{CircleXIcon}</Target>
        </Cancel>
      </Centerer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${settings.colors.Primary.Grey_4};
`;

const Centerer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpinnerContainer = styled.div`
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProgressContainer = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0;
  flex-grow: 1;
`;

const Message = styled.span`
  color: ${settings.colors.Primary.Grey_8};
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.Small};
`;

const Cancel = styled.div`
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Target = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
