import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const TimeMarkerIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={24} viewBox="0 0 16 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path fillRule="evenodd" clipRule="evenodd" d="M16 0H0v16l8 8 8-8V0z" fill={fill} />
    </svg>
  );
};

TimeMarkerIcon.defaultProps = {
  fill: settings.colors.Primary.Blue_4,
};
