import { FALLBACK_LANGUAGE_CODE, getAcceptedLanguages, getNavigatorLanguage, SUPPORTED_LANGUAGES, SupportedLanguageIsoCode } from '@rhim/i18n';
import { UNSAFE_keys } from '@rhim/utils';
import { isEqual } from 'lodash';
import React from 'react';
import { DecimalSeparator, LanguageDirection, UnitSystem } from 'typings';
import useLocalStorageState from 'use-local-storage-state';

import { useMemoCompare } from './useMemoComparable';
import { useVersionedState, VersionedObject } from './useVersionedState';

export interface LocalizationSettings {
  decimalSeparator: DecimalSeparator;
  languageDirection: LanguageDirection;
  locale: SupportedLanguageIsoCode;
  unitSystem: UnitSystem;
}

type VersionedLocalizationSettings = VersionedObject<LocalizationSettings>;

// Any change to LocalizationSettings requires the version of versionedInitialState to be increased by 1
export const defaultInitialState: LocalizationSettings = {
  decimalSeparator: 'comma',
  languageDirection: 'ltr',
  locale: getNavigatorLanguage(getAcceptedLanguages(), SUPPORTED_LANGUAGES, FALLBACK_LANGUAGE_CODE),
  unitSystem: 'metric',
};

export const versionedInitialState: VersionedLocalizationSettings = {
  version: 4,
  data: defaultInitialState,
};

type allowedProps = keyof LocalizationSettings;

const localizationSettingsKeys = Object.keys(defaultInitialState) as allowedProps[];

export function pickLocalizationSettings(obj: Partial<LocalizationSettings> | Record<string, unknown>): Partial<LocalizationSettings> {
  return UNSAFE_keys(obj).reduce(
    (acc, cur) =>
      localizationSettingsKeys.includes(cur) // skip any keys that are not in LocalizationSettings
        ? { ...acc, [cur]: obj[cur] }
        : acc,
    {}
  );
}

const usePersistedState = <S>(arg: S | (() => S)): [S, React.Dispatch<React.SetStateAction<S>>] => {
  const [state, setState] = useLocalStorageState<S>('localization', {
    defaultValue: arg,
  });

  return [state, setState];
};

export const useLocalization = function (
  initialState: VersionedLocalizationSettings = versionedInitialState
): [LocalizationSettings, React.Dispatch<React.SetStateAction<LocalizationSettings>>] {
  const [get, set] = useVersionedState(initialState, usePersistedState as typeof React.useState);

  return useMemoCompare([get, set], ([previousObj], [nextObj]) => isEqual(previousObj, nextObj));
};
