import { ValidationState } from '@react-types/shared';
import { settings } from '@rhim/design';
import { isDefined } from '@rhim/utils';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input/TextArea';
import React from 'react';
import { useFocusRing, useId } from 'react-aria';
import styled, { css } from 'styled-components';

import { useAntdFormItemValidationState } from '../../hooks';
import { Feedback, FieldFeedback, FieldLabel, FieldLabelProps } from '../../partials';

const { TextArea: AntdTextArea } = Input;

interface Props extends TextAreaProps {
  className?: string;
  label?: FieldLabelProps;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  validationState?: ValidationState;
  feedback?: Feedback;
}

const TextArea: React.FC<React.PropsWithChildren<Props>> = ({ className, label, isDisabled = false, isReadOnly = false, feedback, ...props }) => {
  const { isFocused, focusProps } = useFocusRing({ isTextInput: true });
  const feedbackElementId = useId();

  return (
    <div className={className}>
      {isDefined(label) && <FieldLabel text={label.text} isRequired={label.isRequired} />}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-expect-error */}
      <SAntdTextArea
        readOnly={isReadOnly}
        isFocused={isFocused}
        isReadOnly={isReadOnly}
        isDisabled={isDisabled}
        isInvalid={props.validationState === 'invalid'}
        {...props}
        {...focusProps}
      />
      {isDefined(feedback) && <FieldFeedback feedback={feedback} feedbackElementId={feedbackElementId} />}
    </div>
  );
};

const SAntdTextArea = styled(AntdTextArea)<{ isFocused: boolean; isDisabled: boolean; isReadOnly: boolean; isInvalid: boolean } & TextAreaProps>((props) => {
  const borderColor: React.CSSProperties['borderColor'] = (() => {
    if (props.isReadOnly) {
      return settings.colors.Primary.Grey_2;
    } else if (props.isInvalid) {
      return settings.colors.Operational.State_Notif_Magenta_2;
    } else if (props.isFocused) {
      return settings.colors.Primary.Blue_9;
    }
    return settings.colors.Primary.Grey_3;
  })();

  const backgroundColor: React.CSSProperties['backgroundColor'] = (() => {
    if (props.isDisabled) {
      return settings.colors.Primary.Grey_1;
    } else if (props.isReadOnly) {
      return settings.colors.Primary.Grey_2;
    }
    return settings.colors.Monochromatic.White;
  })();

  return css`
    &.ant-input-affix-wrapper {
      border-color: ${borderColor};
      border-radius: 3px;
      transition: none;

      &.ant-input-affix-wrapper-disabled {
        border-style: dashed;
      }

      textarea.ant-input {
        transition: none;
        background-color: ${backgroundColor};
        border-radius: 3px;
        border-color: transparent;
        /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
        resize: none;
        color: ${settings.colors.Primary.Grey_8};
        line-height: 20px;
        box-shadow: none;
        font-family: ${settings.typography.FontFamily.Regular};
        font-size: ${settings.typography.FontSize.Medium};
        padding: ${settings.Spacing.Spacing_150} ${settings.Spacing.Spacing_200};

        &[readonly] {
          color: ${settings.colors.Primary.Grey_4};
          cursor: not-allowed;
        }

        &:focus:not[readonly] {
          color: ${settings.colors.Primary.Blue_9};
        }
      }

      &:hover,
      &:focus-within {
        border-color: ${borderColor};
        box-shadow: none;
      }
    }

    .ant-input-data-count {
      font-family: ${settings.typography.FontFamily.Regular};
      font-size: ${settings.typography.FontSize.X_Small};
      color: ${props.isFocused ? settings.colors.Primary.Blue_9 : settings.colors.Primary.Grey_4};
    }

    &:has([readonly]) .ant-input-data-count {
      color: ${settings.colors.Primary.Grey_4};
    }
  `;
});

TextArea.whyDidYouRender = true;

const TextAreaWithValidationState: React.FC<React.PropsWithChildren<React.ComponentProps<typeof TextArea>>> = (props) => {
  const validationState = useAntdFormItemValidationState();

  return <TextArea validationState={validationState} {...props} />;
};
TextAreaWithValidationState.displayName = 'TextAreaWithValidationState';

export default React.memo(TextAreaWithValidationState);
export { TextArea as TextAreaDefault };
