import { RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto, RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto } from '@rhim/rest/customerManagement';
import { AllPrivileges, usePrivileges } from '@rhim/sdk/customerManagement';
import { hasElements, isDefined } from '@rhim/utils';
import { intersection } from 'lodash';
import React, { useContext } from 'react';

import AppContext from '../app/AppContext';
import { Privilege, PRIVILEGES } from './constants';

/**
 * The logic is: if the user doesn't have access to neither of these HMD pages, they should be kicked out.
 *
 * Note that this list is a subject to change and should be kept in sync with UI.
 *
 * @see https://dev.azure.com/RHIM/UX/_git/UX/pullRequest/22322#1678453750
 */
export const HMD_PRIVILEGES: Privilege[] = [
  PRIVILEGES.AccessOperatorDisplay,
  PRIVILEGES.UpdateOperatorDisplaySettings,
  PRIVILEGES.AccessWearExplorer,
  PRIVILEGES.AccessMeasurementView,
  PRIVILEGES.AccessDataCenter,
  PRIVILEGES.AccessRhLegacyPrediction,
];

/**
 * Filters user's customers to only those who have *any* of the given permissions.
 */
export function useAllowedCustomers(
  user: RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto,
  customer: RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto
): RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto[] {
  const privileges = usePrivileges(user, customer);

  // If there are no customers, return no customers
  if (!isDefined(user.customers) || !hasElements(user.customers)) {
    return [];
  }

  // If the user has global privileges to any of the HMD pages, return all customers
  if (hasElements(intersection(Array.from(privileges.global), HMD_PRIVILEGES))) {
    return user.customers;
  }

  // Otherwise, return only customers who have access to at least one HMD page
  return user.customers.filter((customer) => {
    if (!isDefined(customer.operations) || !hasElements(customer.operations)) {
      return false;
    } else {
      return hasElements(intersection(Array.from(privileges.customer), customer.operations));
    }
  });
}

interface Props {
  children: (privileges: AllPrivileges) => React.ReactElement | null;
}

/**
 * Exposes privileges as a render prop. Useful when hooks cannot be used.z
 */
export const Privileges: React.FunctionComponent<Props> = ({ children }) => {
  const { user, selectedCustomer } = useContext(AppContext);
  const privileges = usePrivileges(user, selectedCustomer);

  return children(privileges);
};
