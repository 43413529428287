import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  backgroundColor?: Property.Color;
  foregroundColor?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const AppStateAlertIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  backgroundColor,
  foregroundColor,
  size,
  title,
  role,
  ...rest
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 64 64" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path
              fill={backgroundColor}
              d="M63.254 51.654L37.07 6.854C36.04 5.09 34.102 4 32 4c-2.102 0-4.038 1.09-5.07 2.854L.746 51.654c-1.012 1.734-.994 3.856.05 5.574C1.84 58.944 3.752 60 5.818 60h52.364c2.066 0 3.978-1.056 5.024-2.772 1.042-1.718 1.06-3.84.048-5.574"
              transform="translate(-413 -459) translate(413 459)"
            />
            <path
              fill={foregroundColor}
              d="M32 44c1.656 0 3 1.342 3 3s-1.344 3-3 3-3-1.342-3-3 1.344-3 3-3zm0-23c1.106 0 2 .896 2 2v16c0 1.104-.894 2-2 2-1.106 0-2-.896-2-2V23c0-1.104.894-2 2-2z"
              transform="translate(-413 -459) translate(413 459)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

AppStateAlertIcon.defaultProps = {
  size: '64',
  backgroundColor: '#EB3785',
  foregroundColor: settings.colors.Monochromatic.Black,
};
