import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const EventChangedIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m9 9.586 6.293-6.293c.358-.358.536-.828.535-1.297A3.008 3.008 0 0 0 14.004.172c-.47 0-.94.177-1.297.535L6.414 7 9 9.586zM10.586 0H3a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V5.414l-6.293 6.293a.996.996 0 0 1-.391.241l-6 2a1.001 1.001 0 0 1-1.264-1.264l2-6a.996.996 0 0 1 .241-.391L10.586 0zM7.148 10.563l-1.71-1.711-.857 2.567 2.567-.856z"
        fill={fill}
      />
    </svg>
  );
};

EventChangedIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_7,
};
