import React, { useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { Group, Svg, ThumbHorizontal, TrackHorizontal, Wrapper } from './Chart.styles';
import { useScrolling } from './hooks/useScrolling';
import { useZoomContext } from './hooks/useZoomContext';

export interface Props {
  children: React.Node;
  width?: number;
  height?: number;
}

const ChartZoom: React.FunctionComponent<Props> = (props) => {
  const { children } = props;

  return <Wrapper {...props}>{children}</Wrapper>;
};

ChartZoom.whyDidYouRender = true;

export default React.memo(ChartZoom);

interface AxisProps {
  width: number;
  left?: number;
  children: React.Node;
}

export const Axis: React.FunctionComponent<AxisProps> = (props) => {
  const { children, left, width } = props;

  return (
    <Group width={width} left={left}>
      <Svg width={width}>{children}</Svg>
    </Group>
  );
};

interface ScrollAreaProps {
  width: number;
  height: number;
  children: React.Node;
  zoomedWidth: number;
}

export const ScrollArea: React.FunctionComponent<ScrollAreaProps> = ({ children, height, zoomedWidth }) => {
  const scrollbarRef = React.useRef<Scrollbars>() as React.RefObject<Scrollbars>;
  const { registerScrollbar, unregisterScrollbar, updateScrollPositions } = useZoomContext();
  const { handleScrollFrame, handleScrollStart } = useScrolling({ scrollbarRef, updateScrollPositions });

  useEffect(() => {
    registerScrollbar(scrollbarRef);

    return () => unregisterScrollbar(scrollbarRef);
  }, [registerScrollbar, scrollbarRef, unregisterScrollbar]);

  return (
    <Scrollbars
      ref={scrollbarRef}
      style={{ height }}
      onScrollFrame={handleScrollFrame}
      onScrollStart={handleScrollStart}
      renderTrackHorizontal={(props) => <TrackHorizontal {...props} />}
      renderThumbHorizontal={(props) => <ThumbHorizontal {...props} />}
      hideTracksWhenNotNeeded
      customOnMac
    >
      <Svg width={zoomedWidth}>{children}</Svg>
    </Scrollbars>
  );
};
