import { RHIMAPOCommonDataReportingCoordinateSystem2D, RHIMContractsRegionLocation } from '@rhim/rest/wearManagement';
import { isDefined } from '@rhim/utils';
import * as React from 'react';

import CircularMap from './CircularMap';
import RectangularMap, { RectangularMapProps } from './RectangularMap';
import { LiningRegionArea, PreviewRegion, RegionArea } from './utils';

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
  /**
   * Highlighted region area.
   */
  foregroundArea?: LiningRegionArea | RegionArea;
  /**
   * Regions to be displayed grayed-out in the background.
   */
  backgroundAreas: (LiningRegionArea | RegionArea)[];
  /**
   * Region Location.
   */
  location?: RHIMContractsRegionLocation;

  /**
   * Outer vessel diameter.
   */
  outerVesselDiameter?: number;

  /**
   * Flag to identify the region type.
   */
  lining?: boolean;
}

/**
 * Regions map with axes and labels.
 *
 * @link https://app.zeplin.io/project/5eb285b3a2756d736f228d80/screen/62a849cf7ef41711ff0342d7
 */
const RegionsMap: React.FunctionComponent<React.PropsWithChildren<Props & RectangularMapProps>> = ({
  outerVesselDiameter,
  foregroundArea,
  backgroundAreas,
  yMax,
  width,
  height,
  showAxis,
  margin,
  regionsName,
  vesselType,
  fill,
  allowHover = false,
  onRegionClick,
  strokeColor,
  location,
  lining = false,
  ...rest
}) => {
  /**
   * Gets the numeric value of coordinates.
   * When editing, the form values are strings, because the form item is a text field, therefore they need to be converted to numbers.
   * @param value Value to be converted.
   */
  const getNumericValue = (value: string | number) => {
    return typeof value === 'number' ? value : parseFloat(value);
  };

  const validAreas: NonEmptyArray<PreviewRegion> = (
    isDefined(foregroundArea) ? backgroundAreas.filter((area) => area.coordinateSystem === foregroundArea.coordinateSystem) : backgroundAreas
  ).map((area) => {
    if (area.coordinateSystem === RHIMAPOCommonDataReportingCoordinateSystem2D.ThetaZ) {
      const min = (area.area as unknown as Quadruple<{ theta: number | string; z: number | string }>)[0];
      const max = (area.area as unknown as Quadruple<{ theta: number | string; z: number | string }>)[2];
      return [
        { x: getNumericValue(max.theta), y: getNumericValue(max.z) },
        { x: getNumericValue(min.theta), y: getNumericValue(min.z) },
      ] as PreviewRegion;
    }

    if (area.coordinateSystem === RHIMAPOCommonDataReportingCoordinateSystem2D.RTheta) {
      const min = (area.area as unknown as Quadruple<{ theta: number | string; r: number | string }>)[0];
      const max = (area.area as unknown as Quadruple<{ theta: number | string; r: number | string }>)[2];
      return [
        { x: getNumericValue(max.r), y: getNumericValue(max.theta) },
        { x: getNumericValue(min.r), y: getNumericValue(min.theta) },
      ] as PreviewRegion;
    }

    const min = (area.area as unknown as Quadruple<{ x: number | string; y: number | string }>)[0];
    const max = (area.area as unknown as Quadruple<{ x: number | string; y: number | string }>)[2];
    return [
      { x: getNumericValue(max.x), y: getNumericValue(max.y) },
      { x: getNumericValue(min.x), y: getNumericValue(min.y) },
    ];
  }) as NonEmptyArray<PreviewRegion>;

  const bottomCircularAreas: NonEmptyArray<PreviewRegion> = (
    isDefined(location) && location === RHIMContractsRegionLocation.Bottom
      ? backgroundAreas.filter((area) => area.coordinateSystem === RHIMAPOCommonDataReportingCoordinateSystem2D.RTheta)
      : backgroundAreas
  ).map((area) => {
    const min = (area.area as unknown as Quadruple<{ theta: number | string; r: number | string }>)[lining ? 0 : 3];
    const max = (area.area as unknown as Quadruple<{ theta: number | string; r: number | string }>)[lining ? 2 : 1];
    return [
      { x: getNumericValue(min.r), y: getNumericValue(min.theta) },
      { x: getNumericValue(max.r), y: getNumericValue(max.theta) },
    ] as PreviewRegion;
  }) as NonEmptyArray<PreviewRegion>;

  const bottomRectAreas: NonEmptyArray<PreviewRegion> = (
    isDefined(location) && location === RHIMContractsRegionLocation.Bottom
      ? backgroundAreas.filter((area) => area.coordinateSystem === RHIMAPOCommonDataReportingCoordinateSystem2D.Xy)
      : []
  ).map((area) => {
    const firstCoord = (area.area as unknown as Quadruple<{ x: number | string; y: number | string }>)[0];
    const lastCoord = (area.area as unknown as Quadruple<{ x: number | string; y: number | string }>)[2];
    return [
      {
        x: getNumericValue(firstCoord.x) <= getNumericValue(lastCoord.x) ? getNumericValue(firstCoord.x) : getNumericValue(lastCoord.x),
        y: getNumericValue(firstCoord.y) <= getNumericValue(lastCoord.y) ? getNumericValue(firstCoord.y) : getNumericValue(lastCoord.y),
      },
      {
        x: getNumericValue(firstCoord.x) >= getNumericValue(lastCoord.x) ? getNumericValue(firstCoord.x) : getNumericValue(lastCoord.x),
        y: getNumericValue(firstCoord.y) >= getNumericValue(lastCoord.y) ? getNumericValue(firstCoord.y) : getNumericValue(lastCoord.y),
      },
    ];
  }) as NonEmptyArray<PreviewRegion>;

  let area = undefined;
  if (isDefined(foregroundArea)) {
    if (foregroundArea.coordinateSystem === RHIMAPOCommonDataReportingCoordinateSystem2D.ThetaZ) {
      const maxPoint = (foregroundArea.area as unknown as Quadruple<{ theta: number | string; z: number | string }>)[2];
      const minPoint = (foregroundArea.area as unknown as Quadruple<{ theta: number | string; z: number | string }>)[0];
      area = [
        { x: getNumericValue(maxPoint.theta), y: getNumericValue(maxPoint.z) },
        { x: getNumericValue(minPoint.theta), y: getNumericValue(minPoint.z) },
      ] as PreviewRegion;
    } else if (foregroundArea.coordinateSystem === RHIMAPOCommonDataReportingCoordinateSystem2D.RTheta) {
      const maxPoint = (foregroundArea.area as unknown as Quadruple<{ theta: number | string; r: number | string }>)[lining ? 2 : 1];
      const minPoint = (foregroundArea.area as unknown as Quadruple<{ theta: number | string; r: number | string }>)[lining ? 0 : 3];
      area = [
        { x: getNumericValue(minPoint.r), y: getNumericValue(minPoint.theta) },
        { x: getNumericValue(maxPoint.r), y: getNumericValue(maxPoint.theta) },
      ] as PreviewRegion;
    } else {
      const firstCoord = (foregroundArea.area as unknown as Quadruple<{ x: number | string; y: number | string }>)[0];
      const lastCoord = (foregroundArea.area as unknown as Quadruple<{ x: number | string; y: number | string }>)[2];
      area = [
        {
          x: getNumericValue(firstCoord.x) <= getNumericValue(lastCoord.x) ? getNumericValue(firstCoord.x) : getNumericValue(lastCoord.x),
          y: getNumericValue(firstCoord.y) <= getNumericValue(lastCoord.y) ? getNumericValue(firstCoord.y) : getNumericValue(lastCoord.y),
        },
        {
          x: getNumericValue(firstCoord.x) >= getNumericValue(lastCoord.x) ? getNumericValue(firstCoord.x) : getNumericValue(lastCoord.x),
          y: getNumericValue(firstCoord.y) >= getNumericValue(lastCoord.y) ? getNumericValue(firstCoord.y) : getNumericValue(lastCoord.y),
        },
      ] as PreviewRegion;
    }
  }

  return (
    <div {...rest}>
      {!isDefined(location) || (isDefined(location) && location !== RHIMContractsRegionLocation.Bottom) ? (
        <RectangularMap
          vesselType={vesselType}
          yMax={yMax}
          areas={validAreas}
          area={area}
          width={width}
          height={height}
          showAxis={showAxis}
          strokeColor={strokeColor}
          margin={margin}
          regionsName={regionsName}
          onRegionClick={onRegionClick}
          allowHover={allowHover}
          fill={fill}
        />
      ) : (
        <CircularMap
          outerDiameter={outerVesselDiameter}
          width={width}
          height={height}
          yMax={yMax}
          rectAreas={bottomRectAreas}
          circularAreas={bottomCircularAreas}
          circularArea={isDefined(foregroundArea) && foregroundArea.coordinateSystem === RHIMAPOCommonDataReportingCoordinateSystem2D.RTheta ? area : undefined}
          rectArea={isDefined(foregroundArea) && foregroundArea.coordinateSystem === RHIMAPOCommonDataReportingCoordinateSystem2D.Xy ? area : undefined}
          showAxis={showAxis}
        />
      )}
    </div>
  );
};

export default React.memo(RegionsMap);
