import { settings } from '@rhim/design';
import { ChevronDownMiniIcon } from '@rhim/icons/16';
import { Tree as UnstyledTree } from 'antd';
import * as React from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';

interface Props extends React.ComponentProps<typeof UnstyledTree> {}

const Tree: React.FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  const switcherIcon = useMemo(
    () => (
      <span className="anticon">
        <ChevronDownMiniIcon />
      </span>
    ),
    []
  );

  return <StyledTree switcherIcon={switcherIcon} {...props} />;
};

const StyledTree = styled(UnstyledTree)`
  .ant-tree-title {
    font-family: ${settings.typography.FontFamily.Regular};
    font-size: ${settings.typography.FontSize.Small};
    color: ${settings.colors.Primary.Grey_8};
  }

  &.ant-tree-show-line .ant-tree-switcher {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default React.memo(Tree);
