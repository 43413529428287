import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const LadleConfigIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        d="M12.4 8a.4.4 0 0 1 .39.313l.14.626c.2.061.394.142.577.24l.541-.345a.398.398 0 0 1 .497.055l.566.565a.4.4 0 0 1 .055.498l-.345.542c.098.182.179.375.24.577l.626.14a.4.4 0 0 1 .313.39v.799a.4.4 0 0 1-.313.39l-.626.139a3.145 3.145 0 0 1-.24.577l.345.542a.4.4 0 0 1-.055.498l-.566.566a.397.397 0 0 1-.497.054l-.541-.344a3.18 3.18 0 0 1-.578.239l-.138.626A.4.4 0 0 1 12.4 16h-.8a.4.4 0 0 1-.39-.313l-.14-.626a3.18 3.18 0 0 1-.577-.239l-.541.344a.405.405 0 0 1-.497-.054l-.566-.566a.4.4 0 0 1-.055-.498l.345-.542a3.145 3.145 0 0 1-.24-.577l-.626-.139A.4.4 0 0 1 8 12.4v-.8a.4.4 0 0 1 .313-.39l.626-.14c.061-.201.142-.394.24-.576l-.345-.542a.4.4 0 0 1 .055-.498l.566-.565a.398.398 0 0 1 .497-.055l.541.344a3.23 3.23 0 0 1 .578-.24l.138-.625A.4.4 0 0 1 11.6 8zM2.593 1.337a.666.666 0 0 1 .736.59l1.268 11.406H6.84c.122.474.306.921.545 1.334H4a.666.666 0 0 1-.663-.594l-.6-5.406h-.07a.666.666 0 1 1-1.334 0V7.333c0-.368.3-.666.667-.666.234 0 .43.127.55.31l-.546-4.904a.667.667 0 0 1 .589-.736zM12 10.4a1.6 1.6 0 1 0 0 3.199 1.6 1.6 0 0 0 0-3.2zM5.63 2c.667 0 1.253.141 1.93.304.797.192 1.7.41 2.81.41.22 0 .439-.006.657-.018l-.364 4.146A5.33 5.33 0 0 0 6.667 12H5.79l-.886-9.967A7.91 7.91 0 0 1 5.63 2zM14 6.667c.368 0 .666.298.666.666v.053a5.302 5.302 0 0 0-1.144-.496.637.637 0 0 1 .478-.223zm-.691-5.334.098.004c.366.04.63.37.589.737l-.534 4.801a5.312 5.312 0 0 0-1.32-.201l.529-4.748a.667.667 0 0 1 .736-.589z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
};

LadleConfigIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_1,
};
