import { settings } from '@rhim/design';
import { getBrowserLocale, i18nReact, toUnicodeLocale } from '@rhim/i18n';
import { OpsStateCriticalInvertedIcon } from '@rhim/icons/16';
import { formatNumber } from '@rhim/react';
import { RHIMOperatorDisplayServiceV1ModelsMaintenanceTaskDto } from '@rhim/rest/operatorDisplay';
import { RHIMContractsMaintenanceTaskSeverity } from '@rhim/rest/wearManagement/api';
import {
  criticalBoxContainerOperatorDisplay,
  deltaValueLabelOperatorDisplay,
  maintenanceBoxContainerOperatorDisplay,
  minValueLabelOperatorDisplay,
  regionHeaderValuesContainerOperatorDisplay,
  supplierLabelOperatorDisplay,
  targetValueLabelOperatorDisplay,
  wearValueLabelOperatorDisplay,
} from '@rhim/test-ids';
import { hasElements, isDefined } from '@rhim/utils';
import * as React from 'react';
import styledFactory, { ThemedStyledInterface } from 'styled-components';

import CriticalBox from '../CriticalBox/CriticalBox';

const { PatchedThemeProvider } = settings.globals;

interface Props {
  currentMin?: number;
  target?: number;
  wear?: number | null;
  message?: string;
  maintenancetasks: RHIMOperatorDisplayServiceV1ModelsMaintenanceTaskDto[];
  width: number;
  brickSuppliers: string[];
  compareMode?: boolean;
  enableKPIsInWms: boolean;
}

const themes = {
  High: {
    color: settings.colors.Operational.State_Alert_Red_1,
    borderColor: settings.colors.Operational.State_Alert_Red_3,
    currentBorderColor: settings.colors.Operational.State_Alert_Red_3,
  },
  Medium: {
    color: settings.colors.Operational.State_Alert_Orange_1,
    borderColor: settings.colors.Operational.State_Alert_Orange_4,
    currentBorderColor: undefined,
  },
  Low: {
    color: settings.colors.Operational.State_Alert_Yellow_1,
    borderColor: settings.colors.Operational.State_Alert_Yellow_3,
    currentBorderColor: undefined,
  },
  None: {
    color: settings.colors.Primary.Grey_1,
    currentBorderColor: undefined,
    borderColor: undefined,
  },
};
export type Theme = ValueOf<typeof themes>;

const styled: ThemedStyledInterface<Theme> = styledFactory;

const WmsHeader: React.FunctionComponent<Props> = ({
  currentMin,
  enableKPIsInWms,
  target,
  compareMode = false,
  wear,
  maintenancetasks,
  width,
  brickSuppliers,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const { t } = i18nReact.useTranslation(['operator-display', 'shared']);
  const maintenance = maintenancetasks[0];
  const locale = getBrowserLocale();

  const theme: Theme = maintenance ? themes[maintenance.severity] : themes.None;

  const getMaintenanceBox = (maintenanceTask: RHIMOperatorDisplayServiceV1ModelsMaintenanceTaskDto) => {
    if (maintenanceTask.severity === RHIMContractsMaintenanceTaskSeverity.High) {
      return (
        <StyledCriticalBox data-test-id={criticalBoxContainerOperatorDisplay}>
          <CriticalBox
            height={53}
            width={24}
            compact={false}
            title={maintenanceTask.task ?? undefined}
            backgroundColor={theme.color}
            color={settings.colors.Primary.Grey_8}
            icon={<OpsStateCriticalInvertedIcon />}
            containerStyle={{ width: '186px' }}
            centered
          />
        </StyledCriticalBox>
      );
    }
    return (
      <MaintenanceBox data-test-id={maintenanceBoxContainerOperatorDisplay}>
        <Message>{maintenanceTask.task}</Message>
      </MaintenanceBox>
    );
  };

  return (
    <PatchedThemeProvider theme={theme}>
      <Container width={width} ref={ref}>
        <MetricsAndTitle>
          {hasElements(brickSuppliers) && !compareMode ? (
            <BrickSupplier data-test-id={supplierLabelOperatorDisplay}>
              {t('shared:supplier', { count: brickSuppliers.length })}: {brickSuppliers.join(', ')}
            </BrickSupplier>
          ) : (
            // Using this placeholder without content so that the layout stays intact when no brick suppliers are known
            <BrickSupplier />
          )}

          <Metrics data-test-id={regionHeaderValuesContainerOperatorDisplay}>
            {!compareMode && enableKPIsInWms && (
              <>
                {/*Current Min*/}
                <CurrentBox>
                  <Title>{t('operator-display:currentMin')}</Title>
                  <Value data-test-id={minValueLabelOperatorDisplay}>
                    <Text color={settings.colors.Primary.Grey_8} fontSize={settings.typography.FontSize.Medium} font={settings.typography.FontFamily.Bold}>
                      {isDefined(currentMin) ? formatNumber(2)(toUnicodeLocale(locale))(currentMin) : '-'}
                    </Text>
                    <Measurements>{t('operator-display:mm')}</Measurements>
                  </Value>
                </CurrentBox>
                {/*Target*/}
                <Box>
                  <TargetTitle>{t('operator-display:target')}</TargetTitle>
                  <Value data-test-id={targetValueLabelOperatorDisplay}>
                    <Text color={settings.colors.Primary.Grey_8} fontSize={settings.typography.FontSize.Medium} font={settings.typography.FontFamily.Bold}>
                      {!isDefined(target) ? '-' : formatNumber(2)(toUnicodeLocale(locale))(target)}
                    </Text>
                    <Measurements>{t('operator-display:mm')}</Measurements>
                  </Value>
                </Box>
                {/*Delta*/}
                <Box>
                  <Title>{t('operator-display:delta')}</Title>
                  <Value data-test-id={deltaValueLabelOperatorDisplay}>
                    <Text color={settings.colors.Primary.Grey_8} fontSize={settings.typography.FontSize.Medium} font={settings.typography.FontFamily.Bold}>
                      {(() => {
                        if (!isDefined(currentMin) || !isDefined(target)) {
                          return '-';
                        }
                        return formatNumber(2)(toUnicodeLocale(locale))(currentMin - target);
                      })()}
                    </Text>
                    <Measurements>{t('operator-display:mm')}</Measurements>
                  </Value>
                </Box>
                {/*Wear*/}
                <WearBox>
                  <Title>{t('shared:wear')}</Title>
                  <Value data-test-id={wearValueLabelOperatorDisplay}>
                    <Text color={settings.colors.Primary.Grey_8} fontSize={settings.typography.FontSize.Medium} font={settings.typography.FontFamily.Bold}>
                      {(() => {
                        if (!isDefined(wear)) {
                          return '-';
                        }
                        return wear < 0 ? 'N/A' : formatNumber(2)(toUnicodeLocale(locale))(wear);
                      })()}
                    </Text>
                    <Measurements>{t('operator-display:mmHeats')}</Measurements>
                  </Value>
                </WearBox>
              </>
            )}
          </Metrics>
        </MetricsAndTitle>
        {/*Maintenance Status*/}
        {!compareMode && maintenance && getMaintenanceBox(maintenance)}
      </Container>
    </PatchedThemeProvider>
  );
};

const Metrics = styled.div`
  display: flex;
  align-items: flex-end;
  height: 48px;
`;

const MetricsAndTitle = styled.div`
  display: flex;
  flex-direction: column;

  /* Stretch to fit parent width (so that brick suppliers can flush to the right border of their container) */
  width: 100%;
`;

const Container = styled.div<{ width: number }>`
  display: inline-flex;
  width: ${(props) => props.width + 30}px;
  height: 70px;
`;

const StyledCriticalBox = styled.div`
  margin-left: 8px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .critical-text {
    font-size: ${settings.typography.FontSize.Medium};
    margin: 0 auto;
    overflow: hidden;
    word-break: break-word;
  }

  .critical-area {
    border-radius: 3px;
  }
`;

const Value = styled.div``;

const Box = styled.div`
  height: 48px;
  width: 100px;
  background: ${settings.colors.Primary.Grey_1};
  margin: 0 2px 0 0;
  display: inline-grid;
  padding-bottom: 3px;
`;

const WearBox = styled(Box)`
  width: 108px;
`;

const Message = styled.h1`
  color: ${settings.colors.Primary.Grey_8};
  font-size: ${settings.typography.FontSize.Medium};
  font-family: ${settings.typography.FontFamily.Bold};
  padding: 0 24px;
  margin-top: 6px;
  line-height: 1.25;
  text-align: center;
  overflow: hidden;
  word-break: break-word;
`;

const ColoredBox = styled(Box)`
  width: auto;
  background: ${(props) => props.theme.color};
  border: ${(props) => `1px solid ${props.theme.borderColor}`};
`;

const MaintenanceBox = styled(ColoredBox)`
  height: unset;
  display: flex;
  flex-shrink: 0;
  width: 186px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin-left: 8px;
`;

const CurrentBox = styled(ColoredBox)`
  border: ${(props) => (isDefined(props.theme.currentBorderColor) ? `1px solid ${props.theme.currentBorderColor}` : 'none')};
`;

const Title = styled.span`
  color: ${settings.colors.Primary.Grey_6};
  height: 25px;
  padding: 6px 22px 5px 9px;
  font-family: ${settings.typography.FontFamily.Medium};
  font-size: ${settings.typography.FontSize.X_Small};
  white-space: nowrap;
`;

const TargetTitle = styled(Title)`
  &::after {
    content: '';
    position: absolute;
    width: 17px;
    height: 3px;
    margin-top: 5px;
    margin-left: 5px;
    background: ${settings.colors.Operational.State_Green_3};
  }
`;

const Text = styled.span<{ fontSize?: string; color?: string; font?: string }>`
  color: ${(props) => props.color ?? settings.colors.Primary.Grey_6};
  height: 16px;
  margin: 5px 0 0 9px;
  font-family: ${(props) => props.font ?? settings.typography.FontFamily.Regular};
  font-size: ${(props) => props.fontSize ?? settings.typography.FontSize.X_Small};
`;

const Measurements = styled(Text)`
  margin-left: 5px;
  margin-top: 9px;
`;

const BrickSupplier = styled.span`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.X_Small};
  line-height: ${settings.typography.LineHeight.Line_Height_14};
  color: ${settings.colors.Primary.Grey_8};
  display: inline-block;
  margin-bottom: 8px;
`;

export default React.memo(WmsHeader);
