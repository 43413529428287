import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { Tag } from '@rhim/react';
import { VesselType } from '@rhim/rest';
import { assert, isDefined } from '@rhim/utils';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { useScrollToElement } from '../../hooks';
import { getVesselLabel, useSortedVesselsGroupedByType, useVesselsGroupedByType } from '../../lib';
import VesselSelectionItem from './VesselSelectionItem';

interface Props {
  vessels: APO.VesselV2[];
  selectedVesselId: UUID | null | undefined;
  onVesselSelected: (vesselId: UUID) => void;
}
const VesselSelectionDropdown: React.ChildlessComponent<Props> = ({ vessels, selectedVesselId, onVesselSelected }) => {
  const { t } = i18nReact.useTranslation('shared');

  const groupedVessels: Record<VesselType, APO.VesselV2[]> = useVesselsGroupedByType(vessels);
  const sortedGroupedVessels = useSortedVesselsGroupedByType(groupedVessels).filter((element) => element[1].length !== 0);
  const objectsRefs = useScrollToElement<HTMLTableRowElement>(
    vessels.map((vessel) => vessel.id),
    selectedVesselId ?? undefined
  );

  const vesselsSelectionBody = useMemo(() => {
    const ret = [];
    if (sortedGroupedVessels.length > 1) {
      for (const [vesselType, vesselsOfType] of sortedGroupedVessels) {
        // Vessel type label with count tag

        ret.push(
          <tr key={vesselType}>
            <SVesselGroupContainer>
              {getVesselLabel(t, vesselType)}{' '}
              <SGroupLabelCountTag color={settings.colors.Primary.Grey_8} fill={settings.colors.Primary.Blue_2} size="small-16">
                {vesselsOfType.length}
              </SGroupLabelCountTag>
            </SVesselGroupContainer>
          </tr>
        );
        // Vessels list of given type
        for (const vessel of vesselsOfType) {
          ret.push(
            <VesselSelectionItem
              ref={objectsRefs[vessel.id]}
              key={vessel.id}
              vessel={vessel}
              isSelectedVessel={vessel.id === selectedVesselId}
              onVesselClicked={onVesselSelected}
            />
          );
        }
      }
    } else if (sortedGroupedVessels.length === 1) {
      const vesselsOfType = sortedGroupedVessels[0];
      assert(isDefined(vesselsOfType), `No vessels defined`);
      for (const vessel of vesselsOfType[1]) {
        ret.push(
          <VesselSelectionItem
            ref={objectsRefs[vessel.id]}
            key={vessel.id}
            vessel={vessel}
            isSelectedVessel={vessel.id === selectedVesselId}
            onVesselClicked={onVesselSelected}
          />
        );
      }
    }

    return ret;
  }, [objectsRefs, onVesselSelected, selectedVesselId, sortedGroupedVessels, t]);

  return (
    <STable cellPadding="0" cellSpacing="0">
      <tbody>{vesselsSelectionBody}</tbody>
    </STable>
  );
};

export default React.memo(VesselSelectionDropdown);

const STable = styled.table`
  width: 100%;
`;

const SVesselGroupContainer = styled.td`
  cursor: default;
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.X_Small};
  color: ${settings.colors.Primary.Grey_6};
  padding: ${settings.Spacing.Spacing_200};
`;

const SGroupLabelCountTag = styled(Tag)`
  margin-left: ${settings.Spacing.Spacing_100};
`;
