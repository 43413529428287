import { settings } from '@rhim/design';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Scene3dContext } from './Scene3d/Scene3dContext';

const UPDATES_PER_SECOND = 10;

const IndicatorRenderLoopFps: React.ChildlessComponent = () => {
  const [engineState, setEngineState] = useState('');

  const scene3d = useContext(Scene3dContext);

  useEffect(() => {
    const engine = scene3d.getEngine();
    const intervalId = setInterval(() => {
      const isEnginePaused = scene3d.isEnginePaused();
      const engineStatusDescription = isEnginePaused ? 'PAUSED' : `${engine.getFps().toFixed()} FPS`;
      setEngineState(engineStatusDescription);
    }, 1000 / UPDATES_PER_SECOND);

    return () => {
      clearInterval(intervalId);
    };
  }, [scene3d]);

  return <SWrapper>{engineState}</SWrapper>;
};

IndicatorRenderLoopFps.whyDidYouRender = true;
export default React.memo(IndicatorRenderLoopFps);

const SWrapper = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  font-size: ${settings.typography.FontSize.X_Small};
  font-family: ${settings.typography.FontFamily.Regular};
`;
