import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';

interface PopoverHeaderComponentProps {
  heatNumber: number;
}

const PopoverHeaderComponent: FC<React.PropsWithChildren<PopoverHeaderComponentProps>> = ({ heatNumber }) => {
  const { t } = i18nReact.useTranslation(['visualization']);

  return <SWrapper>{t('visualization:sections.heatNumber', { heatNumber: heatNumber })}</SWrapper>;
};

PopoverHeaderComponent.whyDidYouRender = true;

export const PopoverHeader = React.memo(PopoverHeaderComponent);

const SWrapper = styled.div`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.X_Small};
  color: ${settings.colors.Primary.Grey_8};
`;
