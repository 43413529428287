import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const RestartIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <g>
              <path
                d="M16 8c0 .552.448 1 1 1h4c.552 0 1-.448 1-1V4c0-.552-.448-1-1-1s-1 .448-1 1v1.796C18.099 3.454 15.144 2 12 2 6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10c0-.552-.448-1-1-1s-1 .448-1 1c0 4.411-3.589 8-8 8s-8-3.589-8-8 3.589-8 8-8c2.473 0 4.867 1.169 6.399 3H17c-.552 0-1 .448-1 1"
                transform="translate(-900.000000, -259.000000) translate(504.000000, 227.000000) translate(396.000000, 32.000000) translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

RestartIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
