import { settings } from '@rhim/design';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  children: ReactNode;
}
const Label: FC<React.PropsWithChildren<Props>> = ({ className, children }) => {
  return <SWrapper className={className}>{children}</SWrapper>;
};
export default React.memo(Label);

const SWrapper = styled.span`
  color: black;
  background-color: transparent;
  padding: 2px ${settings.Spacing.Spacing_50};
  border-radius: 3px;
  font-family: ${settings.typography.FontFamily.Bold};
  font-size: ${settings.typography.FontSize.X_Small};
  white-space: nowrap;
  line-height: 100%;
`;
