import { isDefined } from '@rhim/utils';
import React, { useState } from 'react';

import { NearestDatumRadial, Position, TooltipHandlersRadial, TooltipStateRadial } from '../types';

const TOOLTIP_CURSOR_OFFSET_X = 15;

export type UseTooltip<Datum> = [ref: React.RefObject<HTMLDivElement>, state: TooltipStateRadial<Datum>, handlers: TooltipHandlersRadial<Datum>];

export default function useTooltipRadial<Datum>(): UseTooltip<Datum> {
  const [position, setPosition] = useState<Position | null>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [nearestDatum, setNearestDatum] = useState<NearestDatumRadial<Datum> | null>(null);

  const handleBisectXRef = React.useRef<((x: number, y: number) => NearestDatumRadial<Datum>) | null>(null);

  const ref = React.useRef<HTMLDivElement>(null);
  const tooltipWidth = ref.current?.getBoundingClientRect().width;

  const onMouseMove = React.useCallback(
    (e: React.MouseEvent) => {
      const { left, top, width } = e.currentTarget.getBoundingClientRect();
      const pointerX = e.clientX - left;
      const pointerY = e.clientY - top;

      if (typeof handleBisectXRef.current === 'function') {
        setNearestDatum(handleBisectXRef.current(pointerX, pointerY));
      }

      setPosition((currentPosition) => {
        const _tooltipWidth = isDefined(tooltipWidth) ? tooltipWidth : currentPosition?.tooltipWidth;

        return {
          pointerX,
          pointerY,
          tooltipWidth: _tooltipWidth,
          tooltipX: (x) => {
            const offset = x > width / 2 ? TOOLTIP_CURSOR_OFFSET_X : -(tooltipWidth ?? 0) - TOOLTIP_CURSOR_OFFSET_X;
            const tooltipX = x + offset;
            return tooltipX;
          },
          tooltipY: (y) => {
            return y;
          },
        };
      });
    },
    [tooltipWidth]
  );

  const onMouseEnter = React.useCallback(() => {
    setIsHovered(true);
  }, []);

  const onMouseLeave = React.useCallback(() => {
    setIsHovered(false);
    setPosition(null);
    setNearestDatum(null);
  }, []);

  const state = {
    position,
    isHovered,
    nearestDatum,
  };

  const handlers = {
    mouseEvents: {
      onMouseMove,
      onMouseEnter,
      onMouseLeave,
    },
    handleBisectXRef,
  };

  return [ref, state, handlers];
}
