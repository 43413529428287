import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const CheckmarkIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 8 8" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.555.168a1 1 0 0 1 .277 1.387l-4 6a1 1 0 0 1-1.54.152l-2-2a1 1 0 1 1 1.415-1.414L2.845 5.43 6.168.445A1 1 0 0 1 7.555.168z"
        fill={fill}
      />
    </svg>
  );
};

CheckmarkIcon.defaultProps = {
  size: '8',
  fill: settings.colors.Primary.Blue_9,
};
