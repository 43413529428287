import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
  dataTestId?: string;
}

export const ArrowRightWithVerticalBarIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, dataTestId, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} data-test-id={dataTestId} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <path
                        d="M7.293 4.293c.391-.391 1.023-.391 1.414 0l3 3c.391.391.391 1.023 0 1.414-.195.195-.451.293-.707.293-.256 0-.512-.098-.707-.293L9 7.414V15c0 .553-.448 1-1 1s-1-.447-1-1V7.414L5.707 8.707c-.391.391-1.023.391-1.414 0s-.391-1.023 0-1.414zM15 0c.552 0 1 .447 1 1 0 .553-.448 1-1 1H1c-.552 0-1-.447-1-1 0-.553.448-1 1-1z"
                        transform="translate(-670.000000, -1949.000000) translate(82.000000, 1909.000000) translate(0.000000, 24.000000) translate(350.000000, 0.000000) translate(154.000000, 8.000000) translate(72.000000, 0.000000) translate(20.000000, 16.000000) rotate(90.000000) translate(-20.000000, -16.000000) translate(12.000000, 8.000000)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

ArrowRightWithVerticalBarIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_1,
};
