/**
 * Creates a memoized function that preserves reference equality based on a predicate function.
 *
 * @param fn Function which return value will be memoized.
 * @param isEqual Should return `true` when two objects are considered identical.
 *
 * @example
 *
 * ```ts
 *  const fn = memoize(() => ({ foo: 1, nonce: 2 }), (previous, next) => previous.foo === next.foo);
 *
 *  const first = fn();
 *  const second = fn();
 *
 *  first === second; // true
 * ```
 */
export function memoize<T extends AnyFunction>(
  fn: T,
  isEqual: (previous: ReturnType<T>, next: ReturnType<T>) => boolean
): (...params: Parameters<T>) => ReturnType<T> {
  const nothing: unique symbol = Symbol();

  let previous: ReturnType<T> | typeof nothing = nothing;

  return (...params: Parameters<T>) => {
    const result = fn(params);

    if (previous === nothing || !isEqual(previous, result as ReturnType<T>)) {
      previous = result;
      return result;
    }

    return previous;
  };
}
