import { i18nReact } from '@rhim/i18n';
import { Progress } from '@rhim/react';
import React, { createContext, useEffect, useMemo, useState } from 'react';

import { Scene3dApiFacade } from '../types';
import { Scene3dApi } from './Scene3dApi';
import { Scene3dInitOptions } from './types';

export const Scene3dContext = createContext<Scene3dApiFacade>({} as Scene3dApiFacade);

export function withScene3d<P extends object>(scene3dOptions: Scene3dInitOptions, waitForSceneIsLoaded = false) {
  return (ChildComponent: React.ComponentType<React.PropsWithChildren<P>>) =>
    function WithScene3dComponent(props: P) {
      const [isSceneLoaded, setIsSceneLoaded] = useState(!waitForSceneIsLoaded);
      const { t } = i18nReact.useTranslation('visualization3d');

      const scene3D = useMemo(() => {
        return Scene3dApi.initAPI(scene3dOptions, t);
      }, [t]);

      useEffect(() => {
        if (Scene3dApi.isInitialized()) {
          scene3D.onTranslationUpdate(t);
        }
      }, [scene3D, t]);

      useEffect(() => {
        if (!isSceneLoaded) {
          scene3D
            .getScene()
            .whenReadyAsync()
            .then(() => {
              setIsSceneLoaded(true);
            });
        }
      }, [scene3D, isSceneLoaded, setIsSceneLoaded]);

      return (
        <Scene3dContext.Provider value={scene3D}>
          {!isSceneLoaded && <Progress />}
          {isSceneLoaded && <ChildComponent {...props} />}
        </Scene3dContext.Provider>
      );
    };
}
