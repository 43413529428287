import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const GearIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M12 16c-2.209 0-4-1.791-4-4s1.791-4 4-4 4 1.791 4 4-1.791 4-4 4m9.217-5.976l-1.564-.347c-.153-.504-.354-.987-.599-1.444l.861-1.353c.252-.395.195-.912-.137-1.244l-1.414-1.414c-.331-.332-.849-.389-1.244-.137l-1.353.861c-.457-.245-.94-.446-1.444-.599l-.347-1.564C13.875 2.326 13.469 2 13 2h-2c-.469 0-.875.326-.976.783l-.347 1.564c-.504.153-.987.354-1.444.599L6.88 4.085c-.397-.252-.913-.195-1.244.137L4.222 5.636c-.332.332-.389.849-.137 1.244l.861 1.353c-.245.457-.446.94-.599 1.444l-1.564.347C2.326 10.125 2 10.531 2 11v2c0 .469.326.875.783.976l1.564.347c.153.504.354.987.599 1.444l-.861 1.353c-.252.395-.195.912.137 1.244l1.414 1.414c.193.194.449.293.708.293.185 0 .371-.051.536-.156l1.353-.861c.457.245.94.446 1.444.599l.347 1.564c.101.457.507.783.976.783h2c.469 0 .875-.326.976-.783l.347-1.564c.504-.153.987-.354 1.444-.599l1.353.861c.165.105.351.156.536.156.259 0 .515-.099.708-.293l1.414-1.414c.332-.332.389-.849.137-1.244l-.861-1.353c.245-.457.446-.94.599-1.444l1.564-.347c.457-.101.783-.507.783-.976v-2c0-.469-.326-.875-.783-.976"
      />
    </svg>
  );
};

GearIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
