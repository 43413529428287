import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const UnlockedIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M9 13c0 .553-.448 1-1 1s-1-.447-1-1v-2c0-.553.448-1 1-1s1 .447 1 1v2zm4-5.723V5c0-2.757-2.243-5-5-5-1.779 0-3.439.958-4.331 2.499-.276.479-.113 1.09.366 1.366.478.279 1.089.114 1.366-.364C5.936 2.575 6.932 2 8 2c1.654 0 3 1.346 3 3v2H4c-1.104 0-2 .896-2 2v5c0 1.104.896 2 2 2h8c1.104 0 2-.896 2-2V9c0-.738-.405-1.376-1-1.723z"
      />
    </svg>
  );
};

UnlockedIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
