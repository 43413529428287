import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  backgroundColor?: Property.Color;
  foregroundColor?: Property.Color;
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const AppStateMissingIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  const titleText = '5150089A-F2C5-4F5E-8E49-5F244030EAAD';
  return (
    <svg width={size} height={size} viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...rest}>
      {isDefined(title) ? <title>{title}</title> : <title>{titleText}</title>}
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icon/Missing_64" fill={fill}>
          <path d="M31.9997298,3 C48.0167298,3 60.9997298,15.984 60.9997298,32 C60.9997298,48.017 48.0167298,61 31.9997298,61 C15.9827298,61 2.99972979,48.017 2.99972979,32 C2.99972979,15.984 15.9827298,3 31.9997298,3 Z M20.5858298,20.586 C19.8048298,21.367 19.8048298,22.633 20.5858298,23.414 L29.1718298,32 L20.5858298,40.586 C19.8048298,41.367 19.8048298,42.633 20.5858298,43.414 C20.9758298,43.805 21.4878298,44 21.9998298,44 C22.5118298,44 23.0228298,43.805 23.4138298,43.414 L31.9998298,34.828 L40.5858298,43.414 C40.9758298,43.805 41.4878298,44 41.9998298,44 C42.5118298,44 43.0228298,43.805 43.4138298,43.414 C44.1948298,42.633 44.1948298,41.367 43.4138298,40.586 L34.8278298,32 L43.4138298,23.414 C44.1948298,22.633 44.1948298,21.367 43.4138298,20.586 C42.6328298,19.805 41.3668298,19.805 40.5858298,20.586 L31.9998298,29.172 L23.4138298,20.586 C22.6328298,19.805 21.3668298,19.805 20.5858298,20.586 Z"></path>
        </g>
      </g>
    </svg>
  );
};

AppStateMissingIcon.defaultProps = {
  size: '64',
  fill: settings.colors.Operational.State_Notif_Grey_2,
};
