import { settings } from '@rhim/design';
import { Tooltip } from '@rhim/react';
import { isDefined } from '@rhim/utils';
import styled from 'styled-components';

export const Header = styled.header`
  display: flex;
  flex-wrap: wrap;
`;

export const HeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-right: ${settings.Spacing.Spacing_800};
`;

export const Page = styled.div`
  padding: ${settings.Spacing.Spacing_400} ${settings.Spacing.Spacing_600} ${settings.Spacing.Spacing_800};
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;
`;

export const SubHeading = styled.p`
  display: flex;
  gap: ${settings.Spacing.Spacing_100};
  ${settings.typography.fonts.h_l};
`;

export const SubHeadings = styled.div`
  display: flex;
  margin-bottom: ${settings.Spacing.Spacing_400};
  gap: ${settings.Spacing.Spacing_300};
`;

export const Title = styled.h1`
  ${settings.typography.fonts.h_xxxl}
  margin: ${settings.Spacing.Spacing_100} 0;
`;

export const Pill = styled.span<{ color: settings.colors.Any; background: settings.colors.Any }>`
  display: inline-block;
  height: 16px;
  padding: 0 ${settings.Spacing.Spacing_100};
  border-radius: 8px;
  font-family: ${settings.typography.FontFamily.Medium};
  font-size: ${settings.typography.FontSize.X_Small};
  color: ${settings.colors.Monochromatic.White};
  background-color: ${(props) => props.background};
  line-height: ${settings.typography.LineHeight.Line_Height_16};
`;

export const Ellipsis = styled.div<{ maxWidth?: number }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: ${(props) => (isDefined(props.maxWidth) ? `${props.maxWidth}px` : '100%')};
  width: fit-content;
`;

export const Prewrap = styled.div`
  white-space: pre-wrap;
`;

export const TooltipComment = styled(Tooltip)`
  max-width: 500px;
`;

export const TooltipFilename = styled(Tooltip)`
  max-width: none;
`;

export const Bold = styled.span`
  font-family: ${settings.typography.FontFamily.Bold};
`;
