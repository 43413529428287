import { RHIMFileIngestionServiceV1ParserParserSchemaDto } from '@rhim/rest/fileIngestion';
import { specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api';

type Payload = RHIMFileIngestionServiceV1ParserParserSchemaDto;

export const useParserConfigurationSchema = (
  parserType: string,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) => {
  return useQuery(getKey(parserType), (context) => queryFn(context, options?.axiosConfiguration));
};

const getKey = (parserType: string) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'fileIngestion',
      scope: 'customerParserSchema',
      entity: 'detail',
      parserType,
    },
  ]);

const queryFn = ({ queryKey: [{ parserType }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  return API.parsers.getParserConfigurationSchema(parserType, { signal, ...axiosConfiguration }).then((response) => response.data);
};

useParserConfigurationSchema.getKey = getKey;
useParserConfigurationSchema.queryFn = queryFn;
