import { hasElements, head } from '@rhim/utils';

/* eslint-disable @typescript-eslint/no-non-null-assertion */

/**
 * Transpose a matrix NxM to MxN
 *
 * @param matrix
 */
export function transposeMatrixNxM(matrix: number[][]) {
  if (!hasElements(matrix)) {
    return matrix;
  }
  const tMatrix: number[][] = [];
  const n = matrix.length;
  const m = head(matrix).length;

  for (let i = 0; i < m; i++) {
    tMatrix[i] = [];
    for (let j = 0; j < n; j++) {
      tMatrix[i]![j] = matrix[j]![i]!;
    }
  }

  return tMatrix;
}

/**
 * Dot product of two vectors
 *
 * @param vectorA
 * @param vectorB
 */
export function dot(vectorA: number[], vectorB: number[]): number {
  if (vectorA.length !== vectorB.length) {
    throw new Error('Vectors should have the same length');
  }

  return vectorA.reduce((acc, val, index) => acc + val * vectorB[index]!, 0);
}

/**
 * Multiply two matrices NxM and MxK
 * Resul: NxM @ MxK = NxK
 *
 * @param matrixA
 * @param matrixB
 */
export function multiplyMatrices(matrixA: number[][], matrixB: number[][]) {
  if (matrixA.length === 0 || matrixB.length === 0) {
    new Error('Matrix is empty');
  }

  if (matrixA[0]!.length !== matrixB.length) {
    new Error('Matrix A columns should be equal to Matrix B rows');
  }

  const bTransposed = transposeMatrixNxM(matrixB);
  const reultingMatrix: number[][] = [];

  for (let i = 0; i < matrixA.length; i++) {
    reultingMatrix[i] = [];
    for (let j = 0; j < bTransposed.length; j++) {
      reultingMatrix[i]![j] = dot(matrixA[i]!, bTransposed[j]!);
    }
  }

  return reultingMatrix;
}

/**
 * Calculate the determinant of a squared matrix
 *
 * @param matrix
 */
export function determinant(matrix: Array<Array<number>>): number {
  if (!hasElements(matrix) || !hasElements(head(matrix))) {
    throw new Error('Matrix should be a square matrix');
  }

  const n = matrix.length;
  if (n !== head(matrix).length) {
    throw new Error('Matrix should be square');
  }

  if (n === 1) {
    return matrix[0][0]!;
  }

  if (n === 2) {
    return matrix[0][0]! * matrix[1]![1]! - matrix[0][1]! * matrix[1]![0]!;
  }

  let det = 0;
  for (let i = 0; i < n; i++) {
    const minor = matrix.slice(1).map((row) => row.filter((_, index) => index !== i));
    det += matrix[0][i]! * determinant(minor) * (i % 2 === 0 ? 1 : -1);
  }

  return det;
}

/* eslint-enable @typescript-eslint/no-non-null-assertion */
