import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const FilesFolderIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g id="Files_Folder_24">
        <g id="Vector">
          <path
            d="M21.6835 10.4175C21.5643 10.2715 21.4143 10.1538 21.2443 10.0729C21.0742 9.99209 20.8884 9.9501 20.7002 9.95H10.6468C10.5491 9.95002 10.4544 9.91619 10.3787 9.85423C10.303 9.79227 10.251 9.706 10.2315 9.61L9.99414 8.4285C9.93575 8.13968 9.78003 7.87979 9.55312 7.69251C9.32621 7.50522 9.04194 7.40193 8.74806 7.4H3.27211C3.08372 7.39889 2.89751 7.44034 2.72728 7.52128C2.55705 7.60221 2.40719 7.72054 2.28881 7.8675C2.16871 8.01202 2.08271 8.18186 2.0372 8.36436C1.9917 8.54686 1.98789 8.73731 2.02604 8.9215L4.22998 19.9715C4.28836 20.2603 4.44408 20.5202 4.67099 20.7075C4.8979 20.8948 5.18217 20.9981 5.47605 21H19.0388C19.3327 20.9981 19.6169 20.8948 19.8438 20.7075C20.0707 20.5202 20.2265 20.2603 20.2849 19.9715L21.9802 11.4715C22.0138 11.2848 22.0045 11.0928 21.9531 10.9102C21.9017 10.7275 21.8095 10.5591 21.6835 10.4175Z"
            fill={fill}
          />
          <path
            d="M4.54359 6.55C4.73954 6.5548 4.93109 6.49136 5.08564 6.37048C5.2402 6.2496 5.3482 6.07874 5.39126 5.887C5.39808 5.83983 5.41992 5.79614 5.45353 5.76244C5.48714 5.72874 5.53071 5.70684 5.57775 5.7H18.5301C18.6426 5.7 18.7504 5.74478 18.8298 5.82448C18.9093 5.90418 18.954 6.01228 18.954 6.125V8.25C18.954 8.47543 19.0433 8.69164 19.2023 8.85104C19.3612 9.01045 19.5768 9.1 19.8017 9.1C20.0265 9.1 20.2421 9.01045 20.401 8.85104C20.56 8.69164 20.6493 8.47543 20.6493 8.25V5.7C20.6493 5.24913 20.4707 4.81673 20.1528 4.49792C19.8348 4.17911 19.4036 4 18.954 4H5.39126C4.94163 4 4.51041 4.17911 4.19247 4.49792C3.87454 4.81673 3.69592 5.24913 3.69592 5.7C3.69592 5.92543 3.78523 6.14163 3.9442 6.30104C4.10317 6.46045 4.31877 6.55 4.54359 6.55Z"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
};

FilesFolderIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
