import { Configuration, LiningsApi } from '@rhim/rest/linings';

/**
 * "https://apim-apo-dev.azure-api.net/wearmanagement/v1".replace(/\/+$/, "");
 */
const BASE_URL = '/api/liningservice/v1';

const configuration: Configuration = {} as Configuration;

export const linings = new LiningsApi(configuration, BASE_URL);
