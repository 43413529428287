import { settings } from '@rhim/design';
import { SupportedLanguageIsoCode, toUnicodeLocale } from '@rhim/i18n';
import { ErrorIcon, ValidIcon, WarningIcon } from '@rhim/icons/24';
import { ValidIcon as ValidLargeIcon } from '@rhim/icons/64';
import { dateTimeOptions, isDefined } from '@rhim/utils';
import styled from 'styled-components';

const IconContainer = styled.div`
  display: flex;
`;

// Temp note : after the recent update in the color-scheme palette,
// the IconAlert and IconWarning icon colors ended up being the same - for the moment

export const TableRenderers = {
  IconAlert: (
    <IconContainer>
      <ErrorIcon fill={settings.colors.Operational.State_Notif_Magenta_2} />
    </IconContainer>
  ),

  IconWarning: (
    <IconContainer>
      <WarningIcon fill={settings.colors.Operational.State_Notif_Magenta_2} />
    </IconContainer>
  ),

  IconSuccess: (
    <IconContainer>
      <ValidIcon fill={settings.colors.Operational.State_Notif_Green_2} />
    </IconContainer>
  ),
  IconSuccess64: (
    <IconContainer>
      <ValidLargeIcon fill={settings.colors.Operational.State_Notif_Green_2} />
    </IconContainer>
  ),
  renderTimestamp: (value: number, locale: SupportedLanguageIsoCode): string => {
    const unicodeLocale = isDefined(locale) ? toUnicodeLocale(locale) : undefined;

    return value ? new Intl.DateTimeFormat(unicodeLocale, dateTimeOptions).format(new Date(value)) : '';
  },
  renderStringArray: (values: string[]): string => (Array.isArray(values) ? values.join(', ') : values),
};
