import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { ArrowsCompareIcon } from '@rhim/icons/24';
import { RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto, RHIMFleetOverviewServiceV1ModelsVolumesDashboardDto } from '@rhim/rest/fleetOverview';
import { compareToContainerVolumeExplorer } from '@rhim/test-ids';
import { isDefined } from '@rhim/utils';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { DropdownMeasurement } from '../../../components/MeasurementSelectionDropdown/MeasurementSelectionItem';
import { getTruncatedVolume } from '../utils';
import ComparisonPicker, { PICKER_WIDTH_PX } from './ComparisonPicker';
import PropertyWithValue from './PropertyWithValue';
import { PrimaryLabel, SSectionContainer } from './styles';

interface Props {
  currentMeasurement: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto | undefined;
  campaignHavingVolumes: RHIMFleetOverviewServiceV1ModelsVolumesDashboardDto | undefined;
  setSelectedCompareToMeasurementId: (value: string | undefined) => void;
  selectedCompareToMeasurement?: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto;
  sortedMeasurementsToCompareTo: DropdownMeasurement[];
}
const SectionCompareTo: React.ChildlessComponent<Props> = ({
  currentMeasurement,
  campaignHavingVolumes,
  setSelectedCompareToMeasurementId,
  selectedCompareToMeasurement,
  sortedMeasurementsToCompareTo,
}) => {
  const { t } = i18nReact.useTranslation(['shared', 'volume']);

  const deltaVolume: number | undefined = useMemo(() => {
    if (!isDefined(currentMeasurement) || !isDefined(selectedCompareToMeasurement)) {
      return undefined;
    }
    return parseFloat(getTruncatedVolume(currentMeasurement.volume)) - parseFloat(getTruncatedVolume(selectedCompareToMeasurement.volume));
  }, [currentMeasurement, selectedCompareToMeasurement]);

  const deltaRblMin: number | undefined = useMemo(() => {
    if (!isDefined(currentMeasurement) || !isDefined(selectedCompareToMeasurement)) {
      return undefined;
    }
    return currentMeasurement.rblMin - selectedCompareToMeasurement.rblMin;
  }, [currentMeasurement, selectedCompareToMeasurement]);

  if (isDefined(campaignHavingVolumes) && sortedMeasurementsToCompareTo.length <= 1) {
    return null;
  }

  return (
    <SSectionContainer style={{ position: 'relative' }} data-test-id={compareToContainerVolumeExplorer}>
      <PrimaryLabel backgroundColor={settings.colors.Miscellaneous.Green}>{t('volume:labelCompareTo')}</PrimaryLabel>
      <SComparisonPicker
        selectedCompareToMeasurement={selectedCompareToMeasurement}
        sortedMeasurementsToCompareTo={sortedMeasurementsToCompareTo}
        onMeasurementClicked={setSelectedCompareToMeasurementId}
      />
      <SPropertiesContainer>
        <PropertyWithValue label={t('volume:propertyVolume')} value={selectedCompareToMeasurement?.volume} unit={t('shared:units.cubicMeters')} />
        <PropertyWithValue label={t('volume:property∆Volume')} value={deltaVolume} unit={t('shared:units.cubicMeters')} isComparisonMode={true} />
        <PropertyWithValue label={t('volume:propertyMinRBL')} value={selectedCompareToMeasurement?.rblMin} unit={t('shared:units.millimetre')} />
        <PropertyWithValue label={t('volume:property∆RBL')} value={deltaRblMin} unit={t('shared:units.millimetre')} isComparisonMode={true} />
      </SPropertiesContainer>
      <SArrowsCompareIcon />
    </SSectionContainer>
  );
};

const SPropertiesContainer = styled.div`
  display: grid;
  grid-template-columns: ${PICKER_WIDTH_PX}px auto;
  grid-column-gap: ${settings.Spacing.Spacing_200};
`;

const SArrowsCompareIcon = styled(ArrowsCompareIcon)`
  position: absolute;
  left: 0;
  transform: translate(-50%);
`;

const SComparisonPicker = styled(ComparisonPicker)`
  margin-top: ${settings.Spacing.Spacing_100};
`;

SectionCompareTo.whyDidYouRender = true;
export default React.memo(SectionCompareTo);
