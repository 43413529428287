import { settings } from '@rhim/design';
import { getBrowserLocale, toUnicodeLocale } from '@rhim/i18n';
import { formatNumber } from '@rhim/react/partials';
import { isDefined } from '@rhim/utils';
import styled from 'styled-components';

interface Props {
  value?: number;
  outer?: boolean;
  upper?: boolean;
}

const ParamRangeIndicator = ({ value, outer, upper }: Props) => {
  const locale = getBrowserLocale();
  return (
    <Inline>
      {/* eslint-disable-next-line i18next/no-literal-string */}
      <Indicators>
        <HorizontalLine />
        <Inline>
          <VerticalLine up={upper} />
          {isDefined(outer) && <VerticalMiddleLine up={upper} />}
        </Inline>
      </Indicators>
      {isDefined(value) && <Limits>{formatNumber(2, 2)(toUnicodeLocale(locale))(value)}</Limits>}
    </Inline>
  );
};

export default ParamRangeIndicator;

const Limits = styled.div`
  margin-left: ${settings.Spacing.Spacing_50};
  border: 1px solid ${settings.colors.Primary.Grey_5};
  background-color: ${settings.colors.Primary.Grey_5};
  color: ${settings.colors.Monochromatic.White};
  border-radius: 3px;
`;

const VerticalLine = styled.div<{ up?: boolean }>`
  width: 1px;
  height: 4px;
  flex-grow: 0;
  margin: ${(props) => (props.up === true ? '-4px 0 0 7px' : '0 0 0 7px')};
  background-color: ${settings.colors.Primary.Grey_6};
`;

const VerticalMiddleLine = styled.div<{ up?: boolean }>`
  width: 1px;
  height: 4px;
  flex-grow: 0;
  margin: ${(props) => (props.up === true ? '-4px 0 0 -4px' : '0 0 0 -4px')};
  background-color: ${settings.colors.Primary.Grey_6};
`;

const Inline = styled.div`
  display: inline-flex;
`;

const Indicators = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
`;

const HorizontalLine = styled.div`
  width: 8px;
  height: 1px;
  flex-grow: 0;
  background-color: #778894;
`;
