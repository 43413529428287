import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const ExitIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <path
              d="M2 15c-.552 0-1-.448-1-1V2c0-.552.448-1 1-1h7c.552 0 1 .448 1 1v1c0 .552-.448 1-1 1s-1-.448-1-1H3v10h5c0-.552.448-1 1-1s1 .448 1 1v1c0 .552-.448 1-1 1H2zm10.586-8l-1.293-1.293c-.39-.39-.39-1.024 0-1.414.39-.39 1.024-.39 1.414 0l3 3c.195.195.293.451.293.707 0 .256-.098.512-.293.707l-3 3c-.39.39-1.024.39-1.414 0-.39-.39-.39-1.024 0-1.414L12.586 9H5c-.552 0-1-.448-1-1s.448-1 1-1h7.586z"
              transform="translate(-1129 -210) translate(1129 210)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

ExitIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Grey_4,
};
