import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { settings } from '@rhim/design';
import { DragIcon } from '@rhim/icons/16';
import { EyeCrossedIcon, EyeIcon } from '@rhim/icons/24';
import { Paragraph, Tooltip } from '@rhim/react';
import { RHIMOperatorDisplayServiceV1ModelsRegionOrderAndVisibilityDto } from '@rhim/rest/operatorDisplay';
import { regionItemRegionSelectionContainer } from '@rhim/test-ids';
import React from 'react';
import styled from 'styled-components';

interface Props {
  region: RHIMOperatorDisplayServiceV1ModelsRegionOrderAndVisibilityDto;
  callback: (region: RHIMOperatorDisplayServiceV1ModelsRegionOrderAndVisibilityDto, disable: boolean) => void;
}
export const Region: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ region, callback }: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: region.regionId,
  });
  const style = { transform: CSS.Transform.toString(transform), transition };
  const className = 'list-sort-demo';

  const { displayName, regionId, display } = region;
  const active = display;
  const [truncated, setTruncated] = React.useState(false);

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <ItemList data-test-id={regionItemRegionSelectionContainer} key={regionId} className={`${className}-list`}>
        <IconDiv>
          {active ? (
            <EyeIcon onClick={() => callback(region, true)} />
          ) : (
            <EyeCrossedIcon fill={settings.colors.Primary.Grey_4} onClick={() => callback(region, false)} />
          )}
        </IconDiv>
        <div className={`${className}-text`} {...listeners} style={{ cursor: 'grab' }}>
          <Tooltip title={truncated ? displayName : undefined}>
            <Paragraph className={active ? 'active' : ''} ellipsis={{ onEllipsis: setTruncated }}>
              {/* NOTE: Fragment is necessary to avoid showing the title */}
              <>{displayName}</>
            </Paragraph>
          </Tooltip>
        </div>
        <DragIcon style={{ cursor: 'grab', marginRight: settings.Spacing.Spacing_100 }} {...listeners} />
      </ItemList>
    </div>
  );
};

const IconDiv = styled.div`
  border: solid 1px ${settings.colors.Primary.Grey_3};
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;

  &:hover {
    background: ${settings.colors.Monochromatic.White};
  }
`;

const ItemList = styled.div`
  user-select: none;
  border: solid 1px ${settings.colors.Primary.Grey_3};
  border-radius: 2px;
  padding-left: ${settings.Spacing.Spacing_150};
  height: 40px;
  transition: box-shadow 0.5s, transform 0.5s;
  width: 100%;
  display: flex;
  align-items: center;
  background: ${settings.colors.Monochromatic.White};

  .ant-typography {
    margin-bottom: 0;
    font-family: ${settings.typography.FontFamily.Regular};
    font-size: ${settings.typography.FontSize.Small};
    color: ${settings.colors.Primary.Grey_8};

    &.active {
      color: ${settings.colors.Primary.Blue_9};
    }
  }

  &:hover {
    background: ${settings.colors.Primary.Grey_1};
    border: solid 1px ${settings.colors.Primary.Grey_4};
  }

  &:focus,
  &:active {
    box-shadow: 0 8px 20px #e6e6e6;
    border: solid 1px ${settings.colors.Primary.Blue_9};
    background: ${settings.colors.Primary.Grey_1};
  }
`;
