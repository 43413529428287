import { AbstractMesh, Color3, Color4, HighlightLayer, MeshBuilder, Observable, PointerDragBehavior, Scene, StandardMaterial, Vector3 } from '@babylonjs/core';
import { isDefined } from '@rhim/utils';

import { createArrow } from './utils';

const MOVABLE_LENGTH_LINE_NAME = 'right_line';

export function createDraggingBox(name: string, scene: Scene, pointingDirections: Array<'x' | 'y' | 'z'>, size = 1, highlightLayer: HighlightLayer) {
  const box = MeshBuilder.CreateBox(name, { size: size }, scene);
  box.enableEdgesRendering();
  box.edgesWidth = 4;
  box.edgesColor = new Color4(1, 0, 0, 1);
  const existingMat = scene.getMaterialByName('draggingBoxMat');
  if (isDefined(existingMat)) {
    box.material = existingMat;
  } else {
    const mat = new StandardMaterial('draggingBoxMat', scene);
    mat.diffuseColor = Color3.White();
    mat.alpha = 0.8;
    box.material = mat;
  }

  const arrowConfig = { length: 2 * size, color: Color3.Red() };

  for (const pointingDirection of pointingDirections) {
    let direction: Vector3;
    let offset: Vector3;

    if (pointingDirection === 'x') {
      direction = new Vector3(1, 0, 0);
      offset = new Vector3(size / 2, 0, 0);
    } else if (pointingDirection === 'y') {
      direction = new Vector3(0, 1, 0);
      offset = new Vector3(0, size / 2, 0);
    } else {
      direction = new Vector3(0, 0, 1);
      offset = new Vector3(0, 0, size / 2);
    }

    const arrow1 = createArrow(
      `${name}_${pointingDirection}_1`,
      {
        direction: direction,
        origin: offset,
        bold: true,
        ...arrowConfig,
      },
      scene
    );
    highlightLayer.addMesh(arrow1, Color3.White());
    // arrow1.position = offset;
    const arrow2 = createArrow(
      `${name}_${pointingDirection}_2`,
      {
        direction: direction.negate(),
        origin: offset.negate(),
        bold: true,
        ...arrowConfig,
      },
      scene
    );
    highlightLayer.addMesh(arrow2, Color3.White());
    // arrow2.position = offset.negate();
    arrow1.parent = box;
    arrow2.parent = box;
  }

  box.renderingGroupId = 1;
  box.getChildren().forEach((m) => {
    if (m instanceof AbstractMesh) {
      m.renderingGroupId = 1;
    }
  });

  return box;
}

/**
 * Installs length registering view in ortho camera
 * Returns observable to receive the position Left and Right which user has selected
 *
 * Default positions are set to bounding box of the mesh.
 * @param mesh
 */
export function lengthRegistration(mesh: AbstractMesh) {
  const scene = mesh.getScene();
  const { minimumWorld, maximumWorld } = mesh.getBoundingInfo().boundingBox;

  const { x: minX } = minimumWorld;
  const { x: maxX, z: maxZ } = maximumWorld;

  // some big number far-far beyond any real vessel size
  const pseudoInfinity = 1e5;

  const pointsLeftTopCamera = [new Vector3(minX, 0, maxZ), new Vector3(minX, 0, -pseudoInfinity)];
  const lineLeftTopCamera = MeshBuilder.CreateLines('left-top', { points: pointsLeftTopCamera, updatable: true }, scene);
  lineLeftTopCamera.color = Color3.Red();

  const pointsLeftBottomCamera = [new Vector3(minX, -pseudoInfinity, 0), new Vector3(minX, pseudoInfinity, 0)];
  const lineLeftBottomCamera = MeshBuilder.CreateLines('left-bottom', { points: pointsLeftBottomCamera, updatable: true }, scene);
  lineLeftBottomCamera.color = Color3.Red();

  const pointsRightTopCamera = [new Vector3(maxX, 0, maxZ), new Vector3(maxX, 0, -pseudoInfinity)];
  const lineRightTopCamera = MeshBuilder.CreateLines(MOVABLE_LENGTH_LINE_NAME, { points: pointsRightTopCamera, updatable: true }, scene);
  lineRightTopCamera.color = Color3.Red();

  const pointsRightBottomCamera = [new Vector3(maxX, -pseudoInfinity, maxZ), new Vector3(maxX, pseudoInfinity, maxZ)];
  const lineRightBottomCamera = MeshBuilder.CreateLines('right-bottom', { points: pointsRightBottomCamera, updatable: true }, scene);
  lineRightBottomCamera.color = Color3.Red();

  const pointsTop = [new Vector3(minX, 0, maxZ), new Vector3(maxX, 0, maxZ)];
  const lineTop = MeshBuilder.CreateLines('top', { points: pointsTop, updatable: true }, scene);
  lineTop.color = Color3.Red();

  const boxSize = Math.max(0.3, Vector3.Distance(minimumWorld, maximumWorld) / 50);

  const highlightLayer = new HighlightLayer('highlightLayer', scene, {});
  highlightLayer.innerGlow = false;
  highlightLayer.blurHorizontalSize = 1;
  highlightLayer.blurVerticalSize = 1;
  const draggingBoxLeft = createDraggingBox('draggingBoxLeft', scene, ['x', 'y', 'z'], boxSize, highlightLayer);
  draggingBoxLeft.position.copyFrom(pointsTop[0]!);

  const draggingBoxRight = createDraggingBox('draggingBoxRight', scene, ['x'], boxSize, highlightLayer);
  draggingBoxRight.position.copyFrom(pointsTop[1]!);

  const positionsObservable = new Observable<[Vector3, Vector3]>((observer) => {
    positionsObservable.notifyObserver(observer, [draggingBoxLeft.position, draggingBoxRight.position]);
  });

  const sliderDragBehaviorLeft = new PointerDragBehavior({});
  sliderDragBehaviorLeft.useObjectOrientationForDragging = true;
  sliderDragBehaviorLeft.moveAttached = false;
  draggingBoxLeft.addBehavior(sliderDragBehaviorLeft);
  const sliderDragBehaviorLeftObserver = sliderDragBehaviorLeft.onDragObservable.add((event) => {
    const { delta } = event;
    draggingBoxLeft.position.addInPlace(delta);
    draggingBoxRight.position.y = draggingBoxLeft.position.y;
    draggingBoxRight.position.z = draggingBoxLeft.position.z;
    positionsObservable.notifyObservers([draggingBoxLeft.position, draggingBoxRight.position]);
  });

  const sliderDragBehaviorRight = new PointerDragBehavior({ dragAxis: new Vector3(1, 0, 0) });
  // sliderDragBehaviorRight.useObjectOrientationForDragging = true;
  sliderDragBehaviorRight.moveAttached = false;
  draggingBoxRight.addBehavior(sliderDragBehaviorRight);
  const sliderDragBehaviorRightObserver = sliderDragBehaviorRight.onDragObservable.add((event) => {
    const { delta } = event;
    draggingBoxRight.position.addInPlace(delta);
    positionsObservable.notifyObservers([draggingBoxLeft.position, draggingBoxRight.position]);
  });

  // listening to changes of the positions to update the dependant elements (lines
  const draggingBoxPositionsChangeObserver = positionsObservable.add((positions) => {
    const [left, right] = positions;
    lineTop.updateMeshPositions((p) => {
      // update full position of the left
      p[0] = left.x;
      p[1] = left.y;
      p[2] = left.z;

      p[3] = right.x;
      p[4] = right.y;
      p[5] = right.z;
    });

    lineLeftTopCamera.updateMeshPositions((p) => {
      p[0] = left.x;
      p[2] = left.z;
      p[3] = left.x;
    });

    lineLeftBottomCamera.updateMeshPositions((p) => {
      p[0] = left.x;
      p[3] = left.x;
    });

    lineRightTopCamera.updateMeshPositions((p) => {
      p[0] = right.x;
      p[1] = right.y;
      p[2] = right.z;
      p[3] = right.x;
      p[4] = right.y;
    });

    lineRightBottomCamera.updateMeshPositions((p) => {
      p[0] = right.x;
      p[3] = right.x;
    });
  });

  let step = 0.01;
  const stepDelta = 0.001;
  const keyUpObserver = () => {
    step = 0.01;
  };

  const keyObserver = (event: KeyboardEvent) => {
    const cameraName = isDefined(scene.cameraToUseForPointers) ? scene.cameraToUseForPointers.name : '';

    // to handle the case when capslock is on, we convert the key to lower case
    const key = event.key.toLowerCase();

    // ignoring any event if the dragging box is not visible (outside of the vieport)
    const isLeftBoxVisible = scene.cameraToUseForPointers!.isInFrustum(draggingBoxLeft);
    const isRightBoxVisible = scene.cameraToUseForPointers!.isInFrustum(draggingBoxRight);
    if (['w', 's', 'a', 'd'].includes(key) && !isLeftBoxVisible) {
      return;
    }
    if (['arrowleft', 'arrowright'].includes(key) && !isRightBoxVisible) {
      return;
    }

    if (cameraName === 'top') {
      switch (key) {
        case 'w': {
          draggingBoxLeft.position.z += step;
          break;
        }
        case 's': {
          draggingBoxLeft.position.z -= step;
          break;
        }
        case 'd': {
          draggingBoxLeft.position.x += step;
          break;
        }
        case 'a': {
          draggingBoxLeft.position.x -= step;
          break;
        }
        case 'arrowright': {
          draggingBoxRight.position.x += step;
          break;
        }
        case 'arrowleft': {
          draggingBoxRight.position.x -= step;
          break;
        }
      }

      draggingBoxRight.position.y = draggingBoxLeft.position.y;
      draggingBoxRight.position.z = draggingBoxLeft.position.z;
      positionsObservable.notifyObservers([draggingBoxLeft.position, draggingBoxRight.position]);
    } else if (cameraName === 'bottom') {
      switch (key) {
        case 'w': {
          draggingBoxLeft.position.y += step;
          break;
        }
        case 's': {
          draggingBoxLeft.position.y -= step;
          break;
        }
        case 'd': {
          draggingBoxLeft.position.x += step;
          break;
        }
        case 'a': {
          draggingBoxLeft.position.x -= step;
          break;
        }
        case 'arrowright': {
          draggingBoxRight.position.x += step;
          break;
        }
        case 'arrowleft': {
          draggingBoxRight.position.x -= step;
          break;
        }
      }

      draggingBoxRight.position.y = draggingBoxLeft.position.y;
      draggingBoxRight.position.z = draggingBoxLeft.position.z;
      positionsObservable.notifyObservers([draggingBoxLeft.position, draggingBoxRight.position]);
    }

    // increase the step linearly in case of button is hold
    if (['w', 's', 'a', 'd', 'arrowleft', 'arrowright'].includes(key)) {
      step += stepDelta;
    }
  };

  document.addEventListener('keyup', keyUpObserver);
  document.addEventListener('keydown', keyObserver);

  return {
    positionsObservable,
    dispose: () => {
      sliderDragBehaviorLeftObserver.remove();
      sliderDragBehaviorRightObserver.remove();
      document.removeEventListener('keydown', keyObserver);
      document.removeEventListener('keyup', keyUpObserver);
      positionsObservable.clear();
      highlightLayer.dispose();
      draggingBoxPositionsChangeObserver.remove();
      [lineTop, lineLeftTopCamera, lineLeftBottomCamera, lineRightTopCamera, lineRightBottomCamera, draggingBoxLeft, draggingBoxRight].forEach((m) => {
        m.dispose(false, true);
        scene.removeMesh(m);
      });
    },
  };
}

export function setLengthLinePosition(mesh: AbstractMesh, position: number) {
  const scene = mesh.getScene();
  const {
    minimum: { y: minY },
  } = mesh.getBoundingInfo().boundingBox;

  const movableLine = scene.getMeshByName(MOVABLE_LENGTH_LINE_NAME);

  if (isDefined(movableLine)) {
    movableLine.position.y = minY + position;
  }
}
