import { RHIMOperatorDisplayServiceV1ModelsOperatorDataViewModel } from '@rhim/rest/operatorDisplay';
import { isDefined, isNaturalNumber, specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import type { AxiosError, AxiosRequestConfig } from 'axios';

import { API } from '../api/operatorDisplay';

type Payload = RHIMOperatorDisplayServiceV1ModelsOperatorDataViewModel;
type ErrorResponse = AxiosError<APO.WearManagementAPI.WearExplorerView.ErrorResponse>;

export function useWMSData(
  vesselId: UUID,
  campaign?: string,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, ErrorResponse, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) {
  return useQuery(getKey(vesselId, campaign), (context) => queryFn(context, options?.axiosConfiguration), options?.configuration);
}

const getKey = (vesselId: UUID, campaign?: string) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'wearManagement',
      scope: 'wms-data',
      entity: 'detail',
      vesselId,
      campaign,
    },
  ]);

const queryFn = ({ queryKey: [{ vesselId, campaign }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  const campaignNumber: number | undefined = (() => {
    if (!isDefined(campaign)) {
      return undefined;
    }

    const parsedCampaign: number = parseInt(campaign, 10);

    return isNaturalNumber(parsedCampaign) ? parsedCampaign : undefined;
  })();

  return API.operatorDisplay.getOperatordataviewVesselid(vesselId, campaignNumber, { signal, ...axiosConfiguration }).then((response) => {
    let data = response.data;
    // Defaulting to false when the property is unset for backwards compatibility.
    // This piece of code can be deleted once this change is deployed to all environments.
    if (!('isHeatMappingInvalidOrMissing' in response.data)) {
      data = { ...data, isHeatMappingInvalidOrMissing: false };
    }

    // Ensure that brick suppliers are always defined, even if empty.
    data.regions.forEach((region) => {
      if (!isDefined(region.brickSupplier)) {
        region.brickSupplier = [];
      } else {
        // Keep up to two brick suppliers, drop the rest
        // See https://dev.azure.com/RHIM/HMD/_workitems/edit/103525#8374823
        region.brickSupplier = region.brickSupplier.slice(0, 2);
      }
    });

    return data;
  });
};

useWMSData.getKey = getKey;
useWMSData.queryFn = queryFn;
