import { settings } from '@rhim/design';
import { isDefined } from '@rhim/utils';
import React, { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useLocalization } from '../../hooks';
import { convertLength, FormattedNumber, MetricLengthUnit } from '../../partials';
import { CellLayout, DataItem, Metrics, WallplotMargin } from './utils';

export interface CellTooltipTexts {
  averageThickness: string;
  averageWear: string;
  heat: string;
}

interface CellTooltipProps {
  wallplotMargin: WallplotMargin;
  metrics: Metrics;
  dataItem: DataItem | null;
  cellLayout: CellLayout | null;
  texts: CellTooltipTexts;
}
const CellTooltip: FC<React.PropsWithChildren<CellTooltipProps>> = ({ wallplotMargin, metrics, dataItem, cellLayout, texts }) => {
  const { t } = useTranslation(['wallplotHeatmap']);
  const [localization] = useLocalization();
  const { unitSystem, locale } = localization;
  const domTooltip = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const tooltipDom = domTooltip.current;
    if (!cellLayout || !tooltipDom) {
      return;
    }
    tooltipDom.style.left =
      cellLayout.startX >= wallplotMargin.left + metrics.plotWidth / 2
        ? cellLayout.startX - 8 - tooltipDom.clientWidth + 'px'
        : cellLayout.startX + cellLayout.width + 8 + 'px';
    tooltipDom.style.top = cellLayout.startY + cellLayout.halfHeight - tooltipDom.clientHeight / 2 + 'px';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cellLayout]);

  if (!dataItem) {
    return null;
  }

  return (
    <STooltipWrapper ref={domTooltip}>
      <div className="header">
        <SPropertyValue>
          <FormattedNumber value={dataItem.angle + metrics.domainXStep / 2} />
          {t('wallplotHeatmap:cellTooltip.separator')}
          {convertLength(dataItem.depth + metrics.domainYStep / 2, MetricLengthUnit.m, unitSystem, undefined, 2, locale, true)}
        </SPropertyValue>
      </div>
      <div className="body">
        <SPropertyLabel>{texts.averageThickness}</SPropertyLabel>
        <SPropertyValue>
          {isDefined(dataItem.rbl) ? convertLength(dataItem.rbl, MetricLengthUnit.mm, unitSystem, undefined, 2, locale, true) : '-'}
        </SPropertyValue>
        <SPropertyLabel>{texts.averageWear}</SPropertyLabel>
        <SPropertyValue>
          {convertLength(dataItem.wear, MetricLengthUnit.mm, unitSystem, undefined, 2, locale, true)}/{texts.heat}
        </SPropertyValue>
      </div>
    </STooltipWrapper>
  );
};

export default React.memo(CellTooltip);

const padding = `6px ${settings.Spacing.Spacing_150}`;
const STooltipWrapper = styled.div`
  position: absolute;
  pointer-events: none;
  color: ${settings.colors.Primary.Grey_8};

  --borderRadius: 3px;

  div.header {
    padding: ${padding};
    border-top-left-radius: var(--borderRadius);
    border-top-right-radius: var(--borderRadius);
    background-color: ${settings.colors.Primary.Grey_2};
  }

  div.body {
    padding: ${padding};
    border-bottom-left-radius: var(--borderRadius);
    border-bottom-right-radius: var(--borderRadius);
    display: grid;
    column-gap: ${settings.Spacing.Spacing_150};
    grid-template-columns: auto auto;
    background-color: white;
  }
`;

const SPropertyLabel = styled.span`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.Small};
  white-space: nowrap;
`;

const SPropertyValue = styled.span`
  font-family: ${settings.typography.FontFamily.Bold};
  font-size: ${settings.typography.FontSize.Small};
`;
