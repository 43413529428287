import { i18nReact } from '@rhim/i18n';
import { ChevronLeftIcon, ChevronRightIcon, HomeIcon, MeasurementIcon, RestartIcon } from '@rhim/icons/16';
import { VesselType } from '@rhim/rest';
import {
  backButtonProcessDataUploadContainer,
  doneButtonProcessDataUploadContainer,
  homeButtonUploadDoneContainer,
  newUploadButtonUploadDoneContainer,
  proceedToMeasurementDataButtonUploadDoneContainer,
  processDataUploadContainerDataCenter,
} from '@rhim/test-ids';
import { assert, isDefined } from '@rhim/utils';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ProcessDataUploadPanel from '../components/DataUploadPanel/ProcessDataUploadPanel';
import UploadFooter, { FooterActionButton } from '../components/DataUploadPanel/UploadFooter';
import UploadHeader from '../components/DataUploadPanel/UploadHeader';
import UploadSteps, { IStep, IStepTitle, StepWithSubtitle } from '../components/DataUploadPanel/UploadSteps';
import UploadSuccess, { UploadSuccessActionButton } from '../components/DataUploadPanel/UploadSuccess';
import { Body, Container } from '../components/DataUploadPanel/utils';
import VesselTypeSelection from '../components/DataUploadPanel/VesselTypeSelection';
import { getVesselLabel } from '../lib';
import { ROUTES } from '../utilities';

enum IngressStep {
  VesselTypeSelection,
  Uploading,
  Done,
}

/**
 * Process data upload page.
 */
const ProcessDataUpload: React.ChildlessComponent = () => {
  const { t } = i18nReact.useTranslation(['ingress', 'app']);
  const { t: tShared } = i18nReact.useTranslation('shared');
  const navigate = useNavigate();
  const [step, setStep] = useState(IngressStep.VesselTypeSelection);
  const [vesselType, setVesselType] = useState<VesselType | undefined>(undefined);
  const [isInProgress, setIsInProgress] = React.useState(false);
  const [canProceedToNextStep, setCanProceedToNextStep] = React.useState(false);

  const handleVesselTypeClicked = useCallback((clickedVesselType: VesselType) => {
    setStep(IngressStep.Uploading);
    setVesselType(clickedVesselType);
  }, []);

  const handleBackButtonClicked = useCallback(() => {
    setStep(IngressStep.VesselTypeSelection);
  }, []);

  const handleIsInProgressChanged = useCallback((inProgress: boolean) => {
    setIsInProgress(inProgress);
  }, []);

  const handleCanProceedToNextStepChanged = useCallback((proceedToNextStep: boolean) => {
    setCanProceedToNextStep(proceedToNextStep);
  }, []);

  const handleDoneButtonClicked = useCallback(() => {
    setStep(IngressStep.Done);
  }, []);

  const handleUploadSuccessfulButtonNewUploadedClicked = useCallback(() => {
    setStep(IngressStep.VesselTypeSelection);
    setVesselType(undefined);
  }, []);

  /**
   * Action buttons shown in the bottom footer section ( e.g Back and Done )
   */
  const footerActionButtons = React.useMemo((): JSX.Element => {
    return (
      <>
        <FooterActionButton
          mode="ghost"
          label={t('app:core.back')}
          disabled={isInProgress}
          dataTestId={backButtonProcessDataUploadContainer}
          icon={{ icon: <ChevronLeftIcon />, position: 'start' }}
          onClick={handleBackButtonClicked}
        />
        <FooterActionButton
          mode="ghost"
          label={t('ingress:doneButtonText')}
          disabled={isInProgress || !canProceedToNextStep}
          dataTestId={doneButtonProcessDataUploadContainer}
          icon={{ icon: <ChevronRightIcon />, position: 'end' }}
          onClick={handleDoneButtonClicked}
        />
      </>
    );
  }, [t, isInProgress, canProceedToNextStep, handleBackButtonClicked, handleDoneButtonClicked]);

  /**
   * Action buttons shown in the last "Upload Successful" step
   */
  const uploadSuccessActionButtons = React.useMemo((): JSX.Element => {
    return (
      <>
        <UploadSuccessActionButton
          dataTestId={newUploadButtonUploadDoneContainer}
          label={t('ingress:newUpload')}
          icon={<RestartIcon />}
          onClick={handleUploadSuccessfulButtonNewUploadedClicked}
        />
        <UploadSuccessActionButton
          dataTestId={proceedToMeasurementDataButtonUploadDoneContainer}
          label={t('ingress:newMeasurementData')}
          icon={<MeasurementIcon />}
          onClick={() => navigate(ROUTES.INGRESS_MEASUREMENT_DATA_UPLOAD)}
        />
        <UploadSuccessActionButton
          dataTestId={homeButtonUploadDoneContainer}
          label={t('ingress:homeButtonText')}
          icon={<HomeIcon />}
          onClick={() => navigate('/ingress')}
        />
      </>
    );
  }, [t, handleUploadSuccessfulButtonNewUploadedClicked, navigate]);

  /**
   * Steps list
   */
  const steps: IStep[] = useMemo(() => {
    return [
      // VESSEL-TYPE-SELECTION VIEW
      {
        title: isDefined(vesselType) ? (
          <StepWithSubtitle title={t('ingress:selectVesselStepTitle')} subtitle={getVesselLabel(tShared, vesselType)} />
        ) : (
          t('ingress:selectVesselStepTitle')
        ),
        content: <VesselTypeSelection vesselTypes={new Set([VesselType.Rh, VesselType.Bof])} onVesselClicked={handleVesselTypeClicked} />,
      },
      // FILE-UPLOAD VIEW
      {
        title: t('ingress:uploadStepTitle'),
        content: (
          <ProcessDataUploadPanel
            vesselType={vesselType}
            onProgressChanged={handleIsInProgressChanged}
            onCanProceedToNextStepChanged={handleCanProceedToNextStepChanged}
          />
        ),
      },
      // UPLOAD-SUCCESS VIEW
      {
        title: t('ingress:doneStepTitle'),
        content: <UploadSuccess actionButtons={uploadSuccessActionButtons} />,
      },
    ];
  }, [vesselType, t, tShared, handleVesselTypeClicked, handleIsInProgressChanged, handleCanProceedToNextStepChanged, uploadSuccessActionButtons]);

  const stepsTitles: IStepTitle[] = useMemo(() => steps.map((step) => step.title), [steps]);

  const currentStep = steps[step];
  assert(isDefined(currentStep), `ProcessDataUpload, unexpected flow : step : ${step} not set`);

  return (
    <Container data-test-id={processDataUploadContainerDataCenter}>
      <UploadHeader title={t('ingress:processDataTitle')} />
      <UploadSteps steps={stepsTitles} currentStep={step} />
      <Body>{currentStep.content}</Body>
      {step === IngressStep.Uploading && <UploadFooter actionButtons={footerActionButtons} />}
    </Container>
  );
};

export default React.memo(ProcessDataUpload);
