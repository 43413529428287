import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const BookmarkIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      <path
        d="M2 1C2 0.447715 2.44772 0 3 0H13C13.5523 0 14 0.447715 14 1V15C14 15.3501 13.8169 15.6747 13.5173 15.8558C13.2177 16.0369 12.8451 16.0481 12.5352 15.8854L8 13.5044L3.46483 15.8854C3.15485 16.0481 2.78234 16.0369 2.48272 15.8558C2.18309 15.6747 2 15.3501 2 15V1Z"
        fill="#003262"
      />
    </svg>
  );
};

BookmarkIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
