import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { isDefined } from '@rhim/utils';
import React from 'react';
import styled from 'styled-components';

import { useLeftInBoundaries } from '../../hooks';
import { ScrollbarRef } from './types';

interface Props {
  scrollbarRef: ScrollbarRef;
  x: number | undefined;
}

const DragZoomHint: React.FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  const { scrollbarRef, x } = props;
  const { t } = i18nReact.useTranslation(['aro-report']);
  const { labelRef, left, wrapperRef } = useLeftInBoundaries({ scrollbarRef, x: x ?? 0 });

  if (!isDefined(x)) return null;

  return (
    <Wrapper ref={wrapperRef}>
      <Label ref={labelRef} style={isDefined(left) ? { left } : { visibility: 'hidden' }}>
        {t('aro-report:chart.label_start_zoom')}
      </Label>
    </Wrapper>
  );
};

export default React.memo(DragZoomHint);

const Wrapper = styled.div`
  width: 100%;
`;

const Label = styled.span`
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  font-size: ${settings.typography.FontSize.XX_Small};
  line-height: ${settings.typography.LineHeight.Line_Height_12};
  background: ${settings.colors.Monochromatic.White};
`;
