import { settings } from '@rhim/design';
import { ColorRGB } from '@rhim/utils/colorScales';
import { isDefined } from '@rhim/utils/is-defined';

export const SETTINGS = {
  AXIS: {
    TICK_LENGTH_PX: 5,
    TICK_LABEL_DISTANCE_PX: 10,
    ROUNDED_LABEL_PADDING_HORIZONTAL: 8,
    ROUNDED_LABEL_PADDING_VERTICAL: 4,
    ROUNDED_LABEL_RADIUS: 3,
    ROUNDER_LABEL_LINE_CONNECTING_TO_AXIS_STROKE: 2,
  },
  AREAS: {
    AREA_LABEL_DISTANCE_PX: 6,
  },
  GRID_VALUE_TEXT: {
    MINIMUM_CELL_SIZE_TO_DISPLAY_TEXT_PX: 28,
    MINIMUM_CELL_SIZE_TO_DISPLAY_14PT_TEXT_PX: 40,
  },
  CELL_LOWEST_VALUE_DASH: [2],
  CELL_CRITICAL_BORDER_SIZE_LARGE_PX: 2,
  CELL_CRITICAL_BORDER_SIZE_SMALL_PX: 1,
  CELL_FILL_FOR_NULL_VALUE: { red: 255, green: 255, blue: 255 }, // white
  CELL_GRID_COLOR: { red: 0, green: 0, blue: 0 }, // black
};

export interface BasicMetrics {
  canvasWidth: number;
  canvasHeight: number;
  plotWidth: number;
  plotHeight: number;
  domainXMin: number;
  domainXMax: number;
  domainYMin: number;
  domainYMax: number;
}

export interface WallplotMargin {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export interface Metrics extends BasicMetrics {
  cellWidth: number;
  cellHeight: number;
  xScaleDomain: number[];
  yScaleDomain: number[];
  domainXStep: number;
  domainYStep: number;
  dataLowestValue: number;
}

export interface CellLayout {
  startX: number;
  startY: number;
  width: number;
  height: number;
  halfWidth: number;
  halfHeight: number;
  domainValueX?: number;
  domainValueY?: number;
}

export interface AreaLayout extends CellLayout {
  regionId: number;
  areaId: number;
  label: string;
  groupLabel: string;
  connectedToAreaId?: number;
}

export interface AreaWithLabelLayout extends AreaLayout {
  isAreaLabelRotated: boolean;
}

export interface WallplotLayout {
  wallplotMargin: WallplotMargin;
  metrics: Metrics;
  gridLayout: CellLayout[];
}

export interface DataItem {
  id: number;
  angle: number;
  depth: number;
  rbl: number | null;
  radius: number;
  wear: number;
}

export interface AreaRegion {
  regionId: number;
  line: string;
  startX: number;
  startY: number;
  endX: number;
  endY: number;
  minimumThickness: number;
  averageThickness: number;
  wear: number;
}

export interface Area {
  name: string;
  lines: AreaRegion[];
}

export interface WallplotData {
  wallplot: DataItem[];
  areas: Area[];
}

export const fillRect = (color: ColorRGB, canvasWidth: number, imageDataBuffer: Uint32Array, startX: number, startY: number, width: number, height: number) => {
  const colour =
    (255 << 24) | // alpha
    (color.blue << 16) | // blue
    (color.green << 8) | // green
    color.red; // red
  const cellEndY = startY + height;
  for (let y = startY; y < cellEndY; y++) {
    const i = y * canvasWidth;
    const cellEndX = startX + width;
    for (let x = startX; x < cellEndX; x++) {
      imageDataBuffer[i + x] = colour;
    }
  }
};

export const drawRect = (
  color: ColorRGB,
  canvasWidth: number,
  imageDataBuffer: Uint32Array,
  startX: number,
  startY: number,
  width: number,
  height: number,
  thickness = 1
) => {
  fillRect(color, canvasWidth, imageDataBuffer, startX, startY, width, thickness); // paint top edge
  fillRect(color, canvasWidth, imageDataBuffer, startX, startY + height - 1, width, thickness); // paint bottom edge
  fillRect(color, canvasWidth, imageDataBuffer, startX, startY, thickness, height); // paint left edge
  fillRect(color, canvasWidth, imageDataBuffer, startX + width - 1, startY, thickness, height); // paint right edge
};

export interface BorderRadius {
  topLeftRadius: number;
  topRightRadius: number;
  bottomRightRadius: number;
  bottomLeftRadius: number;
}

export function isBorderRadius(borderRadius: number | BorderRadius): borderRadius is BorderRadius {
  return isDefined((borderRadius as BorderRadius).bottomLeftRadius);
}

export const roundRect = (ctx: CanvasRenderingContext2D, startX: number, startY: number, endX: number, endY: number, borderRadius: number | BorderRadius) => {
  const rectBorderRadius: BorderRadius =
    typeof borderRadius === 'number'
      ? { topLeftRadius: borderRadius, topRightRadius: borderRadius, bottomRightRadius: borderRadius, bottomLeftRadius: borderRadius }
      : borderRadius;
  const r2d = Math.PI / 180;
  ctx.beginPath();
  ctx.moveTo(startX + rectBorderRadius.topLeftRadius, startY);
  ctx.lineTo(endX - rectBorderRadius.topRightRadius, startY);
  ctx.arc(endX - rectBorderRadius.topRightRadius, startY + rectBorderRadius.topRightRadius, rectBorderRadius.topRightRadius, r2d * 270, r2d * 360, false);
  ctx.lineTo(endX, endY - rectBorderRadius.bottomRightRadius);
  ctx.arc(endX - rectBorderRadius.bottomRightRadius, endY - rectBorderRadius.bottomRightRadius, rectBorderRadius.bottomRightRadius, r2d * 0, r2d * 90, false);
  ctx.lineTo(startX + rectBorderRadius.bottomRightRadius, endY);
  ctx.arc(startX + rectBorderRadius.bottomRightRadius, endY - rectBorderRadius.bottomLeftRadius, rectBorderRadius.bottomLeftRadius, r2d * 90, r2d * 180, false);
  ctx.lineTo(startX, startY + rectBorderRadius.topLeftRadius);
  ctx.arc(startX + rectBorderRadius.topLeftRadius, startY + rectBorderRadius.topLeftRadius, rectBorderRadius.topLeftRadius, r2d * 180, r2d * 270, false);
  ctx.closePath();
  ctx.fillStyle = settings.colors.Primary.Grey_8;
  ctx.fill();
};

export interface LabelMetrics {
  width: number;
  halfWidth: number;
  height: number;
  halfHeight: number;
}

export interface Dimensions {
  width: number;
  height: number;
}

export interface LabelStyle {
  textAlign: CanvasTextAlign;
  textBaseline: CanvasTextBaseline;
  textFont: string;
}

export const getLabelMetrics = (canvas2DContext: CanvasRenderingContext2D, label: string): LabelMetrics => {
  const fontMetrics = canvas2DContext.measureText(label);
  return {
    width: fontMetrics.width,
    halfWidth: fontMetrics.width / 2,
    height: fontMetrics.actualBoundingBoxAscent + fontMetrics.actualBoundingBoxDescent,
    halfHeight: (fontMetrics.actualBoundingBoxAscent + fontMetrics.actualBoundingBoxDescent) / 2,
  };
};

export const getGridCellLayoutByDomainX = (gridLayout: CellLayout[] | undefined, domainX: number): CellLayout | undefined => {
  if (!isDefined(gridLayout)) {
    return undefined;
  }
  return gridLayout.find((dataItem) => dataItem.domainValueX === domainX);
};

export const getGridCellLayoutByDomainY = (gridLayout: CellLayout[] | undefined, domainY: number): CellLayout | undefined => {
  if (!isDefined(gridLayout)) {
    return undefined;
  }
  return gridLayout.find((dataItem) => dataItem.domainValueY === domainY);
};

export const getGridCellLayout = (gridLayout: CellLayout[], domainX: number, domainY: number): CellLayout | undefined => {
  return gridLayout.find((dataItem) => dataItem.domainValueX === domainX && dataItem.domainValueY === domainY);
};
