import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const CameraQCKIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M13.971 2c.873 0 1.679.465 2.115 1.221L17.113 5H20c1.104 0 2 .895 2 2v11c0 1.104-.896 2-2 2H4c-1.105 0-2-.896-2-2V7c0-1.105.895-2 2-2h2.887l1.027-1.779C8.35 2.465 9.156 2 10.029 2zm-.677 5.17c-1.289-.345-2.637-.168-3.794.5s-1.984 1.746-2.33 3.036c-.345 1.29-.168 2.638.5 3.794.668 1.157 1.746 1.984 3.036 2.33.431.115.868.172 1.302.172.866 0 1.721-.227 2.492-.672.087-.05.159-.119.243-.173l.55.55c.195.195.451.293.707.293.256 0 .512-.098.707-.293.391-.391.391-1.023 0-1.414l-.55-.55c.291-.443.531-.922.673-1.449.345-1.29.168-2.638-.5-3.794-.668-1.157-1.746-1.984-3.036-2.33zm-1.299 1.828c.26 0 .523.035.781.104.774.208 1.421.704 1.822 1.398.4.694.507 1.502.3 2.276-.208.774-.705 1.421-1.398 1.822l-.192.102c-.65.315-1.381.385-2.085.198-.774-.208-1.42-.704-1.822-1.398-.4-.694-.506-1.502-.299-2.276.207-.774.704-1.42 1.398-1.822.462-.267.975-.404 1.495-.404z"
      />
    </svg>
  );
};

CameraQCKIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
