import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const BurgerIcon: React.ForwardRefExoticComponent<React.PropsWithoutRef<Props> & React.RefAttributes<SVGSVGElement>> = React.forwardRef(
  function BurgerIcon({ size, fill, title, role, ...rest }, ref) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest} ref={ref}>
        {isDefined(title) ? <title>{title}</title> : null}
        <g fill="none" fillRule="evenodd">
          <g fill={fill}>
            <g>
              <g>
                <g>
                  <path
                    d="M13 12c.552 0 1 .447 1 1 0 .553-.448 1-1 1H3c-.552 0-1-.447-1-1 0-.553.448-1 1-1h10zm0-5c.552 0 1 .447 1 1 0 .553-.448 1-1 1H3c-.552 0-1-.447-1-1 0-.553.448-1 1-1h10zm0-5c.552 0 1 .447 1 1 0 .553-.448 1-1 1H3c-.552 0-1-.447-1-1 0-.553.448-1 1-1h10z"
                    transform="translate(-434 -152) translate(48 144) translate(213) translate(173 8)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
);

BurgerIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
