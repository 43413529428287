import { convertDuration } from '@rhim/utils';
import { TFunction } from 'react-i18next';

export function getTimeAsText(t: TFunction<'fleet-overview'>, timeInMinutes: number): string {
  const displayValueAsNumber = convertDuration(timeInMinutes);
  if (timeInMinutes < 60) {
    return displayValueAsNumber === 1
      ? t('vesselCard.circularProgressIndicator.min', { ns: 'fleet-overview' })
      : t('vesselCard.circularProgressIndicator.mins', { ns: 'fleet-overview' });
  } else if (timeInMinutes < 60 * 24) {
    return displayValueAsNumber === 1
      ? t('vesselCard.circularProgressIndicator.hour', { ns: 'fleet-overview' })
      : t('vesselCard.circularProgressIndicator.hours', { ns: 'fleet-overview' });
  } else {
    return displayValueAsNumber === 1
      ? t('vesselCard.circularProgressIndicator.day', { ns: 'fleet-overview' })
      : t('vesselCard.circularProgressIndicator.days', { ns: 'fleet-overview' });
  }
}
