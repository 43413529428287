import { AreaShapeDefinition } from '../../theme/types';

type Props = Pick<AreaShapeDefinition, 'fill'>;

const SIZE = 10;
const CORNER_RADIUS = 2;

export const AreaShape: React.FC<Props> = (props) => {
  const { fill } = props;

  return (
    <svg width={SIZE} height={SIZE}>
      <rect x={0} y={0} width={SIZE} height={SIZE} fill={fill} rx={CORNER_RADIUS} ry={CORNER_RADIUS} />
    </svg>
  );
};
