import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  backgroundColor?: Property.Color;
  foregroundColor?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const AppStateUnhealthyIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  backgroundColor,
  foregroundColor,
  size,
  title,
  role,
  ...rest
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                fill={backgroundColor}
                d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2z"
                transform="translate(-516.000000, -594.000000) translate(503.000000, 564.000000) translate(13.000000, 30.000000)"
              />
              <path
                fill={foregroundColor}
                d="M12 15c.829 0 1.5.671 1.5 1.5S12.829 18 12 18s-1.5-.671-1.5-1.5.671-1.5 1.5-1.5zm0-8c.552 0 1 .448 1 1v5c0 .552-.448 1-1 1s-1-.448-1-1V8c0-.552.448-1 1-1z"
                transform="translate(-516.000000, -594.000000) translate(503.000000, 564.000000) translate(13.000000, 30.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

AppStateUnhealthyIcon.defaultProps = {
  size: '24',
  backgroundColor: settings.colors.Operational.State_Alert_Yellow_2,
  foregroundColor: settings.colors.Monochromatic.Black,
};
