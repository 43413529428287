/* tslint:disable */
/* eslint-disable */
/**
 * Process Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @enum {string}
 */

export const RHIMContractsDataType = {
    Text: 'Text',
    Integer: 'Integer',
    Float: 'Float',
    DateTime: 'DateTime',
    Time: 'Time',
    Boolean: 'Boolean'
} as const;

export type RHIMContractsDataType = typeof RHIMContractsDataType[keyof typeof RHIMContractsDataType];


/**
 *
 * @export
 * @enum {string}
 */

export const RHIMContractsFileType = {
    PostMortem: 'PostMortem',
    ProcessData: 'ProcessData',
    ProcessMetrixFiltered: 'ProcessMetrixFiltered',
    ProcessMetrixRaw: 'ProcessMetrixRaw',
    ProcessMetrixVertex: 'ProcessMetrixVertex',
    ProcessMetrixSummary: 'ProcessMetrixSummary',
    ProcessMetrixZip: 'ProcessMetrixZip',
    Stl: 'STL',
    HarmonizedSummary: 'HarmonizedSummary',
    ProcessDataSplit: 'ProcessDataSplit',
    Pts: 'PTS',
    Clinker: 'Clinker',
    KilnFeed: 'KilnFeed',
    HotMeal: 'HotMeal',
    Lignite: 'Lignite',
    StopLogs: 'StopLogs',
    IrScan: 'IRScan',
    KilnShellCooling: 'KilnShellCooling',
    KilnProcessData: 'KilnProcessData',
    FerrotronSummary: 'FerrotronSummary'
} as const;

export type RHIMContractsFileType = typeof RHIMContractsFileType[keyof typeof RHIMContractsFileType];


/**
 *
 * @export
 * @enum {string}
 */

export const RHIMContractsVesselType = {
    Bof: 'BOF',
    Rh: 'RH',
    Eaf: 'EAF',
    Ladle: 'Ladle',
    Crk: 'CRK',
    Rhl: 'RHL',
    Aod: 'AOD',
    Bfr: 'BFR',
    Srf: 'SRF'
} as const;

export type RHIMContractsVesselType = typeof RHIMContractsVesselType[keyof typeof RHIMContractsVesselType];


/**
 *
 * @export
 * @interface RHIMProcessDataServiceV1ModelsAddCustomerSpecificGobProcessDataDto
 */
export interface RHIMProcessDataServiceV1ModelsAddCustomerSpecificGobProcessDataDto {
    /**
     *
     * @type {number}
     * @memberof RHIMProcessDataServiceV1ModelsAddCustomerSpecificGobProcessDataDto
     */
    'campaign': number;
    /**
     *
     * @type {number}
     * @memberof RHIMProcessDataServiceV1ModelsAddCustomerSpecificGobProcessDataDto
     */
    'heat': number;
    /**
     *
     * @type {string}
     * @memberof RHIMProcessDataServiceV1ModelsAddCustomerSpecificGobProcessDataDto
     */
    'entryTimestamp': string;
    /**
     *
     * @type {string}
     * @memberof RHIMProcessDataServiceV1ModelsAddCustomerSpecificGobProcessDataDto
     */
    'lowerVesselNumber': string;
}
/**
 *
 * @export
 * @interface RHIMProcessDataServiceV1ModelsCampaignComparisonDto
 */
export interface RHIMProcessDataServiceV1ModelsCampaignComparisonDto {
    /**
     *
     * @type {number}
     * @memberof RHIMProcessDataServiceV1ModelsCampaignComparisonDto
     */
    'campaign': number;
    /**
     *
     * @type {Array<RHIMProcessDataServiceV1ModelsParameterCampaignValues>}
     * @memberof RHIMProcessDataServiceV1ModelsCampaignComparisonDto
     */
    'parameters'?: Array<RHIMProcessDataServiceV1ModelsParameterCampaignValues> | null;
}
/**
 *
 * @export
 * @interface RHIMProcessDataServiceV1ModelsCampaignDisplayTextDto
 */
export interface RHIMProcessDataServiceV1ModelsCampaignDisplayTextDto {
    /**
     *
     * @type {string}
     * @memberof RHIMProcessDataServiceV1ModelsCampaignDisplayTextDto
     */
    'campaignDisplayText'?: string | null;
    /**
     *
     * @type {number}
     * @memberof RHIMProcessDataServiceV1ModelsCampaignDisplayTextDto
     */
    'internalCampaign': number;
    /**
     *
     * @type {string}
     * @memberof RHIMProcessDataServiceV1ModelsCampaignDisplayTextDto
     */
    'campaignStartDate'?: string | null;
}
/**
 *
 * @export
 * @interface RHIMProcessDataServiceV1ModelsCustomerSpecificProcessDataDto
 */
export interface RHIMProcessDataServiceV1ModelsCustomerSpecificProcessDataDto {
    /**
     *
     * @type {number}
     * @memberof RHIMProcessDataServiceV1ModelsCustomerSpecificProcessDataDto
     */
    'heatNumber': number;
    /**
     *
     * @type {number}
     * @memberof RHIMProcessDataServiceV1ModelsCustomerSpecificProcessDataDto
     */
    'lowerVesselLife'?: number | null;
    /**
     *
     * @type {string}
     * @memberof RHIMProcessDataServiceV1ModelsCustomerSpecificProcessDataDto
     */
    'lowerVesselNumber'?: string | null;
    /**
     *
     * @type {string}
     * @memberof RHIMProcessDataServiceV1ModelsCustomerSpecificProcessDataDto
     */
    'ladleUnderRhTimeStart'?: string | null;
    /**
     *
     * @type {number}
     * @memberof RHIMProcessDataServiceV1ModelsCustomerSpecificProcessDataDto
     */
    'totalOxygenVolume'?: number | null;
    /**
     *
     * @type {number}
     * @memberof RHIMProcessDataServiceV1ModelsCustomerSpecificProcessDataDto
     */
    'vacuumDuration'?: number | null;
}
/**
 *
 * @export
 * @interface RHIMProcessDataServiceV1ModelsParameterCampaignValues
 */
export interface RHIMProcessDataServiceV1ModelsParameterCampaignValues {
    /**
     *
     * @type {string}
     * @memberof RHIMProcessDataServiceV1ModelsParameterCampaignValues
     */
    'name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof RHIMProcessDataServiceV1ModelsParameterCampaignValues
     */
    'unit'?: string | null;
    /**
     *
     * @type {Array<RHIMProcessDataServiceV1ModelsProcessParameterHeatValueDto>}
     * @memberof RHIMProcessDataServiceV1ModelsParameterCampaignValues
     */
    'values'?: Array<RHIMProcessDataServiceV1ModelsProcessParameterHeatValueDto> | null;
}
/**
 *
 * @export
 * @interface RHIMProcessDataServiceV1ModelsProcessDataParameterDto
 */
export interface RHIMProcessDataServiceV1ModelsProcessDataParameterDto {
    /**
     * Represents the Name of the parameter (kebab-cased)
     * @type {string}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterDto
     */
    'name'?: string | null;
    /**
     * Represents the internal ColumnName of the data file and the customer Column Name if available  Example: Heat / Corrida
     * @type {string}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterDto
     */
    'columnName'?: string | null;
    /**
     * The parameter name the customer is using, optional
     * @type {string}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterDto
     */
    'customerColumnName'?: string | null;
    /**
     *
     * @type {string}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterDto
     */
    'dataFamily'?: string | null;
    /**
     *
     * @type {string}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterDto
     */
    'subFamily'?: string | null;
    /**
     *
     * @type {string}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterDto
     */
    'description'?: string | null;
    /**
     *
     * @type {string}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterDto
     */
    'unit'?: string | null;
    /**
     *
     * @type {RHIMContractsDataType}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterDto
     */
    'dataType': RHIMContractsDataType;
    /**
     *
     * @type {boolean}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterDto
     */
    'isAdjustable': boolean;
}
/**
 *
 * @export
 * @interface RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto
 */
export interface RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto {
    /**
     * Represents the Name of the parameter (kebab-cased)
     * @type {string}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto
     */
    'name'?: string | null;
    /**
     * Represents the internal ColumnName of the data file
     * @type {string}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto
     */
    'columnName'?: string | null;
    /**
     * The parameter name the customer is using, optional
     * @type {string}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto
     */
    'customerColumnName'?: string | null;
    /**
     *
     * @type {string}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto
     */
    'dataFamily'?: string | null;
    /**
     *
     * @type {string}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto
     */
    'subFamily'?: string | null;
    /**
     *
     * @type {string}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto
     */
    'description'?: string | null;
    /**
     *
     * @type {string}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto
     */
    'unit'?: string | null;
    /**
     *
     * @type {RHIMContractsDataType}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto
     */
    'dataType': RHIMContractsDataType;
    /**
     *
     * @type {boolean}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto
     */
    'isAdjustable': boolean;
    /**
     *
     * @type {number}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto
     */
    'min'?: number | null;
    /**
     *
     * @type {number}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto
     */
    'max'?: number | null;
    /**
     * The OuterLimit is a percentage of the range between Min and Max and defines a limit region.  So if Min=100 and Max=200 and the OuterLimit=20, then the outer Limit is from 100 - 120 and from 180 - 200
     * @type {number}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto
     */
    'outerLimit'?: number | null;
    /**
     * The InnerLimit is a percentage of the range between Min and Max and defines a limit region. It lies inside of the OuterLimit.  So if Min=100 and Max=200 and the OuterLimit=10 and InnerLimit=8, then the inner limit is from 110 - 118 and from 182 - 190
     * @type {number}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto
     */
    'innerLimit'?: number | null;
    /**
     * Is true if this parameter is in the shortlist / parameter assignments
     * @type {boolean}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto
     */
    'isShortlisted': boolean;
}
/**
 *
 * @export
 * @interface RHIMProcessDataServiceV1ModelsProcessDataParameterRangeModifyDto
 */
export interface RHIMProcessDataServiceV1ModelsProcessDataParameterRangeModifyDto {
    /**
     * Represents the Name of the parameter (kebab-cased)
     * @type {string}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterRangeModifyDto
     */
    'name'?: string | null;
    /**
     *
     * @type {number}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterRangeModifyDto
     */
    'min': number;
    /**
     *
     * @type {number}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterRangeModifyDto
     */
    'max': number;
    /**
     *
     * @type {number}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterRangeModifyDto
     */
    'outerLimit': number;
    /**
     *
     * @type {number}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterRangeModifyDto
     */
    'innerLimit': number;
}
/**
 *
 * @export
 * @interface RHIMProcessDataServiceV1ModelsProcessDataParameterValueDto
 */
export interface RHIMProcessDataServiceV1ModelsProcessDataParameterValueDto {
    /**
     * Represents the Name of the parameter (kebab-cased)
     * @type {string}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterValueDto
     */
    'name'?: string | null;
    /**
     *
     * @type {RHIMContractsDataType}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterValueDto
     */
    'dataType': RHIMContractsDataType;
    /**
     *
     * @type {number}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterValueDto
     */
    'value'?: number | null;
    /**
     * A list of the last not null values for this parameter.  Earlier items in the list are newer. The last item is also the oldest.
     * @type {Array<number>}
     * @memberof RHIMProcessDataServiceV1ModelsProcessDataParameterValueDto
     */
    'historicValues'?: Array<number> | null;
}
/**
 *
 * @export
 * @interface RHIMProcessDataServiceV1ModelsProcessParameterHeatValueDto
 */
export interface RHIMProcessDataServiceV1ModelsProcessParameterHeatValueDto {
    /**
     *
     * @type {number}
     * @memberof RHIMProcessDataServiceV1ModelsProcessParameterHeatValueDto
     */
    'heat': number;
    /**
     *
     * @type {number}
     * @memberof RHIMProcessDataServiceV1ModelsProcessParameterHeatValueDto
     */
    'value'?: number | null;
}

/**
 * CustomerGobProcessDataApi - axios parameter creator
 * @export
 */
export const CustomerGobProcessDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This endpoint provides GOB Rh specific process data values
         * @summary This endpoint provides GOB Rh specific process data values
         * @param {string} vesselShortname
         * @param {number} campaign Format - int32.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomergobprocessdataVesselshortnameCampaign: async (vesselShortname: string, campaign: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselShortname' is not null or undefined
            assertParamExists('getCustomergobprocessdataVesselshortnameCampaign', 'vesselShortname', vesselShortname)
            // verify required parameter 'campaign' is not null or undefined
            assertParamExists('getCustomergobprocessdataVesselshortnameCampaign', 'campaign', campaign)
            const localVarPath = `/CustomerGobProcessData/{vesselShortname}/{campaign}`
                .replace(`{${"vesselShortname"}}`, encodeURIComponent(String(vesselShortname)))
                .replace(`{${"campaign"}}`, encodeURIComponent(String(campaign)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint should only be used from the campaign service the provide the lower vessel number for an RH vessel for a specific customer in the new campaign
         * @summary This endpoint should only be used from the campaign service the provide the lower vessel number for an RH vessel for a specific customer in the new campaign
         * @param {string} vesselId Format - uuid.
         * @param {RHIMProcessDataServiceV1ModelsAddCustomerSpecificGobProcessDataDto} [rHIMProcessDataServiceV1ModelsAddCustomerSpecificGobProcessDataDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomergobprocessdataVesselid: async (vesselId: string, rHIMProcessDataServiceV1ModelsAddCustomerSpecificGobProcessDataDto?: RHIMProcessDataServiceV1ModelsAddCustomerSpecificGobProcessDataDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('putCustomergobprocessdataVesselid', 'vesselId', vesselId)
            const localVarPath = `/CustomerGobProcessData/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMProcessDataServiceV1ModelsAddCustomerSpecificGobProcessDataDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerGobProcessDataApi - functional programming interface
 * @export
 */
export const CustomerGobProcessDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerGobProcessDataApiAxiosParamCreator(configuration)
    return {
        /**
         * This endpoint provides GOB Rh specific process data values
         * @summary This endpoint provides GOB Rh specific process data values
         * @param {string} vesselShortname
         * @param {number} campaign Format - int32.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomergobprocessdataVesselshortnameCampaign(vesselShortname: string, campaign: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMProcessDataServiceV1ModelsCustomerSpecificProcessDataDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomergobprocessdataVesselshortnameCampaign(vesselShortname, campaign, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint should only be used from the campaign service the provide the lower vessel number for an RH vessel for a specific customer in the new campaign
         * @summary This endpoint should only be used from the campaign service the provide the lower vessel number for an RH vessel for a specific customer in the new campaign
         * @param {string} vesselId Format - uuid.
         * @param {RHIMProcessDataServiceV1ModelsAddCustomerSpecificGobProcessDataDto} [rHIMProcessDataServiceV1ModelsAddCustomerSpecificGobProcessDataDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCustomergobprocessdataVesselid(vesselId: string, rHIMProcessDataServiceV1ModelsAddCustomerSpecificGobProcessDataDto?: RHIMProcessDataServiceV1ModelsAddCustomerSpecificGobProcessDataDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCustomergobprocessdataVesselid(vesselId, rHIMProcessDataServiceV1ModelsAddCustomerSpecificGobProcessDataDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerGobProcessDataApi - factory interface
 * @export
 */
export const CustomerGobProcessDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerGobProcessDataApiFp(configuration)
    return {
        /**
         * This endpoint provides GOB Rh specific process data values
         * @summary This endpoint provides GOB Rh specific process data values
         * @param {string} vesselShortname
         * @param {number} campaign Format - int32.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomergobprocessdataVesselshortnameCampaign(vesselShortname: string, campaign: number, options?: any): AxiosPromise<Array<RHIMProcessDataServiceV1ModelsCustomerSpecificProcessDataDto>> {
            return localVarFp.getCustomergobprocessdataVesselshortnameCampaign(vesselShortname, campaign, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint should only be used from the campaign service the provide the lower vessel number for an RH vessel for a specific customer in the new campaign
         * @summary This endpoint should only be used from the campaign service the provide the lower vessel number for an RH vessel for a specific customer in the new campaign
         * @param {string} vesselId Format - uuid.
         * @param {RHIMProcessDataServiceV1ModelsAddCustomerSpecificGobProcessDataDto} [rHIMProcessDataServiceV1ModelsAddCustomerSpecificGobProcessDataDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomergobprocessdataVesselid(vesselId: string, rHIMProcessDataServiceV1ModelsAddCustomerSpecificGobProcessDataDto?: RHIMProcessDataServiceV1ModelsAddCustomerSpecificGobProcessDataDto, options?: any): AxiosPromise<void> {
            return localVarFp.putCustomergobprocessdataVesselid(vesselId, rHIMProcessDataServiceV1ModelsAddCustomerSpecificGobProcessDataDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerGobProcessDataApi - object-oriented interface
 * @export
 * @class CustomerGobProcessDataApi
 * @extends {BaseAPI}
 */
export class CustomerGobProcessDataApi extends BaseAPI {
    /**
     * This endpoint provides GOB Rh specific process data values
     * @summary This endpoint provides GOB Rh specific process data values
     * @param {string} vesselShortname
     * @param {number} campaign Format - int32.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerGobProcessDataApi
     */
    public getCustomergobprocessdataVesselshortnameCampaign(vesselShortname: string, campaign: number, options?: AxiosRequestConfig) {
        return CustomerGobProcessDataApiFp(this.configuration).getCustomergobprocessdataVesselshortnameCampaign(vesselShortname, campaign, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint should only be used from the campaign service the provide the lower vessel number for an RH vessel for a specific customer in the new campaign
     * @summary This endpoint should only be used from the campaign service the provide the lower vessel number for an RH vessel for a specific customer in the new campaign
     * @param {string} vesselId Format - uuid.
     * @param {RHIMProcessDataServiceV1ModelsAddCustomerSpecificGobProcessDataDto} [rHIMProcessDataServiceV1ModelsAddCustomerSpecificGobProcessDataDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerGobProcessDataApi
     */
    public putCustomergobprocessdataVesselid(vesselId: string, rHIMProcessDataServiceV1ModelsAddCustomerSpecificGobProcessDataDto?: RHIMProcessDataServiceV1ModelsAddCustomerSpecificGobProcessDataDto, options?: AxiosRequestConfig) {
        return CustomerGobProcessDataApiFp(this.configuration).putCustomergobprocessdataVesselid(vesselId, rHIMProcessDataServiceV1ModelsAddCustomerSpecificGobProcessDataDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ParameterRangesApi - axios parameter creator
 * @export
 */
export const ParameterRangesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes/Clears a parameter range.
         * @summary Removes/Clears a parameter range.
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The vesselType
         * @param {string} parameterName The parameterName for which the range is cleared
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParameterrangesCustomeridVesseltypeClearParametername: async (customerId: string, vesselType: string, parameterName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('deleteParameterrangesCustomeridVesseltypeClearParametername', 'customerId', customerId)
            // verify required parameter 'vesselType' is not null or undefined
            assertParamExists('deleteParameterrangesCustomeridVesseltypeClearParametername', 'vesselType', vesselType)
            // verify required parameter 'parameterName' is not null or undefined
            assertParamExists('deleteParameterrangesCustomeridVesseltypeClearParametername', 'parameterName', parameterName)
            const localVarPath = `/ParameterRanges/{customerId}/{vesselType}/clear/{parameterName}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"vesselType"}}`, encodeURIComponent(String(vesselType)))
                .replace(`{${"parameterName"}}`, encodeURIComponent(String(parameterName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all parameters which can get a range assigned to them. (This currently only works for parameters with the data type \"Integer\" or \"Float\".)  Also the ranges of the parameters are returned (if they have one assigned)
         * @summary Returns all parameters which can get a range assigned to them. (This currently only works for parameters with the data type \"Integer\" or \"Float\".)  Also the ranges of the parameters are returned (if they have one assigned)
         * @param {string} customerId Format - uuid. The id of the customer for which parameters and ranges should be returned
         * @param {string} vesselType The vesselType for which parameters and ranges should be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParameterrangesCustomeridVesseltype: async (customerId: string, vesselType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getParameterrangesCustomeridVesseltype', 'customerId', customerId)
            // verify required parameter 'vesselType' is not null or undefined
            assertParamExists('getParameterrangesCustomeridVesseltype', 'vesselType', vesselType)
            const localVarPath = `/ParameterRanges/{customerId}/{vesselType}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"vesselType"}}`, encodeURIComponent(String(vesselType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Saves a parameter range.  This currently only works for parameters with the data type \"Integer\" or \"Float\".
         * @summary Saves a parameter range.  This currently only works for parameters with the data type \"Integer\" or \"Float\".
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The vesselType
         * @param {RHIMProcessDataServiceV1ModelsProcessDataParameterRangeModifyDto} [rHIMProcessDataServiceV1ModelsProcessDataParameterRangeModifyDto] The range information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putParameterrangesCustomeridVesseltype: async (customerId: string, vesselType: string, rHIMProcessDataServiceV1ModelsProcessDataParameterRangeModifyDto?: RHIMProcessDataServiceV1ModelsProcessDataParameterRangeModifyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('putParameterrangesCustomeridVesseltype', 'customerId', customerId)
            // verify required parameter 'vesselType' is not null or undefined
            assertParamExists('putParameterrangesCustomeridVesseltype', 'vesselType', vesselType)
            const localVarPath = `/ParameterRanges/{customerId}/{vesselType}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"vesselType"}}`, encodeURIComponent(String(vesselType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMProcessDataServiceV1ModelsProcessDataParameterRangeModifyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParameterRangesApi - functional programming interface
 * @export
 */
export const ParameterRangesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParameterRangesApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes/Clears a parameter range.
         * @summary Removes/Clears a parameter range.
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The vesselType
         * @param {string} parameterName The parameterName for which the range is cleared
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteParameterrangesCustomeridVesseltypeClearParametername(customerId: string, vesselType: string, parameterName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteParameterrangesCustomeridVesseltypeClearParametername(customerId, vesselType, parameterName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all parameters which can get a range assigned to them. (This currently only works for parameters with the data type \"Integer\" or \"Float\".)  Also the ranges of the parameters are returned (if they have one assigned)
         * @summary Returns all parameters which can get a range assigned to them. (This currently only works for parameters with the data type \"Integer\" or \"Float\".)  Also the ranges of the parameters are returned (if they have one assigned)
         * @param {string} customerId Format - uuid. The id of the customer for which parameters and ranges should be returned
         * @param {string} vesselType The vesselType for which parameters and ranges should be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParameterrangesCustomeridVesseltype(customerId: string, vesselType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParameterrangesCustomeridVesseltype(customerId, vesselType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Saves a parameter range.  This currently only works for parameters with the data type \"Integer\" or \"Float\".
         * @summary Saves a parameter range.  This currently only works for parameters with the data type \"Integer\" or \"Float\".
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The vesselType
         * @param {RHIMProcessDataServiceV1ModelsProcessDataParameterRangeModifyDto} [rHIMProcessDataServiceV1ModelsProcessDataParameterRangeModifyDto] The range information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putParameterrangesCustomeridVesseltype(customerId: string, vesselType: string, rHIMProcessDataServiceV1ModelsProcessDataParameterRangeModifyDto?: RHIMProcessDataServiceV1ModelsProcessDataParameterRangeModifyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putParameterrangesCustomeridVesseltype(customerId, vesselType, rHIMProcessDataServiceV1ModelsProcessDataParameterRangeModifyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ParameterRangesApi - factory interface
 * @export
 */
export const ParameterRangesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParameterRangesApiFp(configuration)
    return {
        /**
         * Removes/Clears a parameter range.
         * @summary Removes/Clears a parameter range.
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The vesselType
         * @param {string} parameterName The parameterName for which the range is cleared
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParameterrangesCustomeridVesseltypeClearParametername(customerId: string, vesselType: string, parameterName: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteParameterrangesCustomeridVesseltypeClearParametername(customerId, vesselType, parameterName, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all parameters which can get a range assigned to them. (This currently only works for parameters with the data type \"Integer\" or \"Float\".)  Also the ranges of the parameters are returned (if they have one assigned)
         * @summary Returns all parameters which can get a range assigned to them. (This currently only works for parameters with the data type \"Integer\" or \"Float\".)  Also the ranges of the parameters are returned (if they have one assigned)
         * @param {string} customerId Format - uuid. The id of the customer for which parameters and ranges should be returned
         * @param {string} vesselType The vesselType for which parameters and ranges should be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParameterrangesCustomeridVesseltype(customerId: string, vesselType: string, options?: any): AxiosPromise<Array<RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto>> {
            return localVarFp.getParameterrangesCustomeridVesseltype(customerId, vesselType, options).then((request) => request(axios, basePath));
        },
        /**
         * Saves a parameter range.  This currently only works for parameters with the data type \"Integer\" or \"Float\".
         * @summary Saves a parameter range.  This currently only works for parameters with the data type \"Integer\" or \"Float\".
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The vesselType
         * @param {RHIMProcessDataServiceV1ModelsProcessDataParameterRangeModifyDto} [rHIMProcessDataServiceV1ModelsProcessDataParameterRangeModifyDto] The range information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putParameterrangesCustomeridVesseltype(customerId: string, vesselType: string, rHIMProcessDataServiceV1ModelsProcessDataParameterRangeModifyDto?: RHIMProcessDataServiceV1ModelsProcessDataParameterRangeModifyDto, options?: any): AxiosPromise<void> {
            return localVarFp.putParameterrangesCustomeridVesseltype(customerId, vesselType, rHIMProcessDataServiceV1ModelsProcessDataParameterRangeModifyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParameterRangesApi - object-oriented interface
 * @export
 * @class ParameterRangesApi
 * @extends {BaseAPI}
 */
export class ParameterRangesApi extends BaseAPI {
    /**
     * Removes/Clears a parameter range.
     * @summary Removes/Clears a parameter range.
     * @param {string} customerId Format - uuid. The id of the customer
     * @param {string} vesselType The vesselType
     * @param {string} parameterName The parameterName for which the range is cleared
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParameterRangesApi
     */
    public deleteParameterrangesCustomeridVesseltypeClearParametername(customerId: string, vesselType: string, parameterName: string, options?: AxiosRequestConfig) {
        return ParameterRangesApiFp(this.configuration).deleteParameterrangesCustomeridVesseltypeClearParametername(customerId, vesselType, parameterName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all parameters which can get a range assigned to them. (This currently only works for parameters with the data type \"Integer\" or \"Float\".)  Also the ranges of the parameters are returned (if they have one assigned)
     * @summary Returns all parameters which can get a range assigned to them. (This currently only works for parameters with the data type \"Integer\" or \"Float\".)  Also the ranges of the parameters are returned (if they have one assigned)
     * @param {string} customerId Format - uuid. The id of the customer for which parameters and ranges should be returned
     * @param {string} vesselType The vesselType for which parameters and ranges should be returned
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParameterRangesApi
     */
    public getParameterrangesCustomeridVesseltype(customerId: string, vesselType: string, options?: AxiosRequestConfig) {
        return ParameterRangesApiFp(this.configuration).getParameterrangesCustomeridVesseltype(customerId, vesselType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Saves a parameter range.  This currently only works for parameters with the data type \"Integer\" or \"Float\".
     * @summary Saves a parameter range.  This currently only works for parameters with the data type \"Integer\" or \"Float\".
     * @param {string} customerId Format - uuid. The id of the customer
     * @param {string} vesselType The vesselType
     * @param {RHIMProcessDataServiceV1ModelsProcessDataParameterRangeModifyDto} [rHIMProcessDataServiceV1ModelsProcessDataParameterRangeModifyDto] The range information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParameterRangesApi
     */
    public putParameterrangesCustomeridVesseltype(customerId: string, vesselType: string, rHIMProcessDataServiceV1ModelsProcessDataParameterRangeModifyDto?: RHIMProcessDataServiceV1ModelsProcessDataParameterRangeModifyDto, options?: AxiosRequestConfig) {
        return ParameterRangesApiFp(this.configuration).putParameterrangesCustomeridVesseltype(customerId, vesselType, rHIMProcessDataServiceV1ModelsProcessDataParameterRangeModifyDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ParameterShortlistApi - axios parameter creator
 * @export
 */
export const ParameterShortlistApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a list of process parameters that are assigned to a vessel type.
         * @summary Returns a list of process parameters that are assigned to a vessel type.
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The type of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParametershortlistCustomeridVesseltype: async (customerId: string, vesselType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getParametershortlistCustomeridVesseltype', 'customerId', customerId)
            // verify required parameter 'vesselType' is not null or undefined
            assertParamExists('getParametershortlistCustomeridVesseltype', 'vesselType', vesselType)
            const localVarPath = `/ParameterShortlist/{customerId}/{vesselType}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"vesselType"}}`, encodeURIComponent(String(vesselType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of process parameters that are assigned to a specific vessel.
         * @summary Returns a list of process parameters that are assigned to a specific vessel.
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The type of the vessel
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParametershortlistCustomeridVesseltypeVesselid: async (customerId: string, vesselType: string, vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getParametershortlistCustomeridVesseltypeVesselid', 'customerId', customerId)
            // verify required parameter 'vesselType' is not null or undefined
            assertParamExists('getParametershortlistCustomeridVesseltypeVesselid', 'vesselType', vesselType)
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getParametershortlistCustomeridVesseltypeVesselid', 'vesselId', vesselId)
            const localVarPath = `/ParameterShortlist/{customerId}/{vesselType}/{vesselId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"vesselType"}}`, encodeURIComponent(String(vesselType)))
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of process parameters that are assigned to a specific region.
         * @summary Returns a list of process parameters that are assigned to a specific region.
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The type of the vessel
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {string} regionId Format - uuid. The id of the region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParametershortlistCustomeridVesseltypeVesselidRegionid: async (customerId: string, vesselType: string, vesselId: string, regionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getParametershortlistCustomeridVesseltypeVesselidRegionid', 'customerId', customerId)
            // verify required parameter 'vesselType' is not null or undefined
            assertParamExists('getParametershortlistCustomeridVesseltypeVesselidRegionid', 'vesselType', vesselType)
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getParametershortlistCustomeridVesseltypeVesselidRegionid', 'vesselId', vesselId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getParametershortlistCustomeridVesseltypeVesselidRegionid', 'regionId', regionId)
            const localVarPath = `/ParameterShortlist/{customerId}/{vesselType}/{vesselId}/{regionId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"vesselType"}}`, encodeURIComponent(String(vesselType)))
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)))
                .replace(`{${"regionId"}}`, encodeURIComponent(String(regionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of process parameters. Depending on whether assignments to a region, a vessel or only the vessel type are stored.
         * @summary Returns a list of process parameters. Depending on whether assignments to a region, a vessel or only the vessel type are stored.
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The type of the vessel
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {string} regionId Format - uuid. The id of the region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParametershortlistResolveCustomeridVesseltypeVesselidRegionid: async (customerId: string, vesselType: string, vesselId: string, regionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getParametershortlistResolveCustomeridVesseltypeVesselidRegionid', 'customerId', customerId)
            // verify required parameter 'vesselType' is not null or undefined
            assertParamExists('getParametershortlistResolveCustomeridVesseltypeVesselidRegionid', 'vesselType', vesselType)
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getParametershortlistResolveCustomeridVesseltypeVesselidRegionid', 'vesselId', vesselId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('getParametershortlistResolveCustomeridVesseltypeVesselidRegionid', 'regionId', regionId)
            const localVarPath = `/ParameterShortlist/resolve/{customerId}/{vesselType}/{vesselId}/{regionId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"vesselType"}}`, encodeURIComponent(String(vesselType)))
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)))
                .replace(`{${"regionId"}}`, encodeURIComponent(String(regionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the parameter assignments to a vessel type.
         * @summary Updates the parameter assignments to a vessel type.
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The type of the vessel
         * @param {Array<string>} [requestBody] The list of parameter names
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putParametershortlistCustomeridVesseltype: async (customerId: string, vesselType: string, requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('putParametershortlistCustomeridVesseltype', 'customerId', customerId)
            // verify required parameter 'vesselType' is not null or undefined
            assertParamExists('putParametershortlistCustomeridVesseltype', 'vesselType', vesselType)
            const localVarPath = `/ParameterShortlist/{customerId}/{vesselType}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"vesselType"}}`, encodeURIComponent(String(vesselType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the parameter assignments to a specific vessel.
         * @summary Updates the parameter assignments to a specific vessel.
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The type of the vessel
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {Array<string>} [requestBody] The list of parameter names
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putParametershortlistCustomeridVesseltypeVesselid: async (customerId: string, vesselType: string, vesselId: string, requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('putParametershortlistCustomeridVesseltypeVesselid', 'customerId', customerId)
            // verify required parameter 'vesselType' is not null or undefined
            assertParamExists('putParametershortlistCustomeridVesseltypeVesselid', 'vesselType', vesselType)
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('putParametershortlistCustomeridVesseltypeVesselid', 'vesselId', vesselId)
            const localVarPath = `/ParameterShortlist/{customerId}/{vesselType}/{vesselId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"vesselType"}}`, encodeURIComponent(String(vesselType)))
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the parameter assignments to a region.
         * @summary Updates the parameter assignments to a region.
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The type of the vessel
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {string} regionId Format - uuid. The id of the region
         * @param {Array<string>} [requestBody] The list of parameter names
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putParametershortlistCustomeridVesseltypeVesselidRegionid: async (customerId: string, vesselType: string, vesselId: string, regionId: string, requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('putParametershortlistCustomeridVesseltypeVesselidRegionid', 'customerId', customerId)
            // verify required parameter 'vesselType' is not null or undefined
            assertParamExists('putParametershortlistCustomeridVesseltypeVesselidRegionid', 'vesselType', vesselType)
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('putParametershortlistCustomeridVesseltypeVesselidRegionid', 'vesselId', vesselId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('putParametershortlistCustomeridVesseltypeVesselidRegionid', 'regionId', regionId)
            const localVarPath = `/ParameterShortlist/{customerId}/{vesselType}/{vesselId}/{regionId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"vesselType"}}`, encodeURIComponent(String(vesselType)))
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)))
                .replace(`{${"regionId"}}`, encodeURIComponent(String(regionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParameterShortlistApi - functional programming interface
 * @export
 */
export const ParameterShortlistApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParameterShortlistApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a list of process parameters that are assigned to a vessel type.
         * @summary Returns a list of process parameters that are assigned to a vessel type.
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The type of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParametershortlistCustomeridVesseltype(customerId: string, vesselType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMProcessDataServiceV1ModelsProcessDataParameterDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParametershortlistCustomeridVesseltype(customerId, vesselType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of process parameters that are assigned to a specific vessel.
         * @summary Returns a list of process parameters that are assigned to a specific vessel.
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The type of the vessel
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParametershortlistCustomeridVesseltypeVesselid(customerId: string, vesselType: string, vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMProcessDataServiceV1ModelsProcessDataParameterDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParametershortlistCustomeridVesseltypeVesselid(customerId, vesselType, vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of process parameters that are assigned to a specific region.
         * @summary Returns a list of process parameters that are assigned to a specific region.
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The type of the vessel
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {string} regionId Format - uuid. The id of the region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParametershortlistCustomeridVesseltypeVesselidRegionid(customerId: string, vesselType: string, vesselId: string, regionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMProcessDataServiceV1ModelsProcessDataParameterDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParametershortlistCustomeridVesseltypeVesselidRegionid(customerId, vesselType, vesselId, regionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of process parameters. Depending on whether assignments to a region, a vessel or only the vessel type are stored.
         * @summary Returns a list of process parameters. Depending on whether assignments to a region, a vessel or only the vessel type are stored.
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The type of the vessel
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {string} regionId Format - uuid. The id of the region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParametershortlistResolveCustomeridVesseltypeVesselidRegionid(customerId: string, vesselType: string, vesselId: string, regionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMProcessDataServiceV1ModelsProcessDataParameterDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParametershortlistResolveCustomeridVesseltypeVesselidRegionid(customerId, vesselType, vesselId, regionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the parameter assignments to a vessel type.
         * @summary Updates the parameter assignments to a vessel type.
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The type of the vessel
         * @param {Array<string>} [requestBody] The list of parameter names
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putParametershortlistCustomeridVesseltype(customerId: string, vesselType: string, requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putParametershortlistCustomeridVesseltype(customerId, vesselType, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the parameter assignments to a specific vessel.
         * @summary Updates the parameter assignments to a specific vessel.
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The type of the vessel
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {Array<string>} [requestBody] The list of parameter names
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putParametershortlistCustomeridVesseltypeVesselid(customerId: string, vesselType: string, vesselId: string, requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putParametershortlistCustomeridVesseltypeVesselid(customerId, vesselType, vesselId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the parameter assignments to a region.
         * @summary Updates the parameter assignments to a region.
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The type of the vessel
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {string} regionId Format - uuid. The id of the region
         * @param {Array<string>} [requestBody] The list of parameter names
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putParametershortlistCustomeridVesseltypeVesselidRegionid(customerId: string, vesselType: string, vesselId: string, regionId: string, requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putParametershortlistCustomeridVesseltypeVesselidRegionid(customerId, vesselType, vesselId, regionId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ParameterShortlistApi - factory interface
 * @export
 */
export const ParameterShortlistApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParameterShortlistApiFp(configuration)
    return {
        /**
         * Returns a list of process parameters that are assigned to a vessel type.
         * @summary Returns a list of process parameters that are assigned to a vessel type.
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The type of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParametershortlistCustomeridVesseltype(customerId: string, vesselType: string, options?: any): AxiosPromise<Array<RHIMProcessDataServiceV1ModelsProcessDataParameterDto>> {
            return localVarFp.getParametershortlistCustomeridVesseltype(customerId, vesselType, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of process parameters that are assigned to a specific vessel.
         * @summary Returns a list of process parameters that are assigned to a specific vessel.
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The type of the vessel
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParametershortlistCustomeridVesseltypeVesselid(customerId: string, vesselType: string, vesselId: string, options?: any): AxiosPromise<Array<RHIMProcessDataServiceV1ModelsProcessDataParameterDto>> {
            return localVarFp.getParametershortlistCustomeridVesseltypeVesselid(customerId, vesselType, vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of process parameters that are assigned to a specific region.
         * @summary Returns a list of process parameters that are assigned to a specific region.
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The type of the vessel
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {string} regionId Format - uuid. The id of the region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParametershortlistCustomeridVesseltypeVesselidRegionid(customerId: string, vesselType: string, vesselId: string, regionId: string, options?: any): AxiosPromise<Array<RHIMProcessDataServiceV1ModelsProcessDataParameterDto>> {
            return localVarFp.getParametershortlistCustomeridVesseltypeVesselidRegionid(customerId, vesselType, vesselId, regionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of process parameters. Depending on whether assignments to a region, a vessel or only the vessel type are stored.
         * @summary Returns a list of process parameters. Depending on whether assignments to a region, a vessel or only the vessel type are stored.
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The type of the vessel
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {string} regionId Format - uuid. The id of the region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParametershortlistResolveCustomeridVesseltypeVesselidRegionid(customerId: string, vesselType: string, vesselId: string, regionId: string, options?: any): AxiosPromise<Array<RHIMProcessDataServiceV1ModelsProcessDataParameterDto>> {
            return localVarFp.getParametershortlistResolveCustomeridVesseltypeVesselidRegionid(customerId, vesselType, vesselId, regionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the parameter assignments to a vessel type.
         * @summary Updates the parameter assignments to a vessel type.
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The type of the vessel
         * @param {Array<string>} [requestBody] The list of parameter names
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putParametershortlistCustomeridVesseltype(customerId: string, vesselType: string, requestBody?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.putParametershortlistCustomeridVesseltype(customerId, vesselType, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the parameter assignments to a specific vessel.
         * @summary Updates the parameter assignments to a specific vessel.
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The type of the vessel
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {Array<string>} [requestBody] The list of parameter names
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putParametershortlistCustomeridVesseltypeVesselid(customerId: string, vesselType: string, vesselId: string, requestBody?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.putParametershortlistCustomeridVesseltypeVesselid(customerId, vesselType, vesselId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the parameter assignments to a region.
         * @summary Updates the parameter assignments to a region.
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The type of the vessel
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {string} regionId Format - uuid. The id of the region
         * @param {Array<string>} [requestBody] The list of parameter names
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putParametershortlistCustomeridVesseltypeVesselidRegionid(customerId: string, vesselType: string, vesselId: string, regionId: string, requestBody?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.putParametershortlistCustomeridVesseltypeVesselidRegionid(customerId, vesselType, vesselId, regionId, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParameterShortlistApi - object-oriented interface
 * @export
 * @class ParameterShortlistApi
 * @extends {BaseAPI}
 */
export class ParameterShortlistApi extends BaseAPI {
    /**
     * Returns a list of process parameters that are assigned to a vessel type.
     * @summary Returns a list of process parameters that are assigned to a vessel type.
     * @param {string} customerId Format - uuid. The id of the customer
     * @param {string} vesselType The type of the vessel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParameterShortlistApi
     */
    public getParametershortlistCustomeridVesseltype(customerId: string, vesselType: string, options?: AxiosRequestConfig) {
        return ParameterShortlistApiFp(this.configuration).getParametershortlistCustomeridVesseltype(customerId, vesselType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of process parameters that are assigned to a specific vessel.
     * @summary Returns a list of process parameters that are assigned to a specific vessel.
     * @param {string} customerId Format - uuid. The id of the customer
     * @param {string} vesselType The type of the vessel
     * @param {string} vesselId Format - uuid. The id of the vessel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParameterShortlistApi
     */
    public getParametershortlistCustomeridVesseltypeVesselid(customerId: string, vesselType: string, vesselId: string, options?: AxiosRequestConfig) {
        return ParameterShortlistApiFp(this.configuration).getParametershortlistCustomeridVesseltypeVesselid(customerId, vesselType, vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of process parameters that are assigned to a specific region.
     * @summary Returns a list of process parameters that are assigned to a specific region.
     * @param {string} customerId Format - uuid. The id of the customer
     * @param {string} vesselType The type of the vessel
     * @param {string} vesselId Format - uuid. The id of the vessel
     * @param {string} regionId Format - uuid. The id of the region
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParameterShortlistApi
     */
    public getParametershortlistCustomeridVesseltypeVesselidRegionid(customerId: string, vesselType: string, vesselId: string, regionId: string, options?: AxiosRequestConfig) {
        return ParameterShortlistApiFp(this.configuration).getParametershortlistCustomeridVesseltypeVesselidRegionid(customerId, vesselType, vesselId, regionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of process parameters. Depending on whether assignments to a region, a vessel or only the vessel type are stored.
     * @summary Returns a list of process parameters. Depending on whether assignments to a region, a vessel or only the vessel type are stored.
     * @param {string} customerId Format - uuid. The id of the customer
     * @param {string} vesselType The type of the vessel
     * @param {string} vesselId Format - uuid. The id of the vessel
     * @param {string} regionId Format - uuid. The id of the region
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParameterShortlistApi
     */
    public getParametershortlistResolveCustomeridVesseltypeVesselidRegionid(customerId: string, vesselType: string, vesselId: string, regionId: string, options?: AxiosRequestConfig) {
        return ParameterShortlistApiFp(this.configuration).getParametershortlistResolveCustomeridVesseltypeVesselidRegionid(customerId, vesselType, vesselId, regionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the parameter assignments to a vessel type.
     * @summary Updates the parameter assignments to a vessel type.
     * @param {string} customerId Format - uuid. The id of the customer
     * @param {string} vesselType The type of the vessel
     * @param {Array<string>} [requestBody] The list of parameter names
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParameterShortlistApi
     */
    public putParametershortlistCustomeridVesseltype(customerId: string, vesselType: string, requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return ParameterShortlistApiFp(this.configuration).putParametershortlistCustomeridVesseltype(customerId, vesselType, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the parameter assignments to a specific vessel.
     * @summary Updates the parameter assignments to a specific vessel.
     * @param {string} customerId Format - uuid. The id of the customer
     * @param {string} vesselType The type of the vessel
     * @param {string} vesselId Format - uuid. The id of the vessel
     * @param {Array<string>} [requestBody] The list of parameter names
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParameterShortlistApi
     */
    public putParametershortlistCustomeridVesseltypeVesselid(customerId: string, vesselType: string, vesselId: string, requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return ParameterShortlistApiFp(this.configuration).putParametershortlistCustomeridVesseltypeVesselid(customerId, vesselType, vesselId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the parameter assignments to a region.
     * @summary Updates the parameter assignments to a region.
     * @param {string} customerId Format - uuid. The id of the customer
     * @param {string} vesselType The type of the vessel
     * @param {string} vesselId Format - uuid. The id of the vessel
     * @param {string} regionId Format - uuid. The id of the region
     * @param {Array<string>} [requestBody] The list of parameter names
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParameterShortlistApi
     */
    public putParametershortlistCustomeridVesseltypeVesselidRegionid(customerId: string, vesselType: string, vesselId: string, regionId: string, requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return ParameterShortlistApiFp(this.configuration).putParametershortlistCustomeridVesseltypeVesselidRegionid(customerId, vesselType, vesselId, regionId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ParameterValuesApi - axios parameter creator
 * @export
 */
export const ParameterValuesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns parameter values of the given vessel, campaign and heat.  This currently only works for parameters with the data type \"Integer\" or \"Float\"
         * @summary Returns parameter values of the given vessel, campaign and heat.  This currently only works for parameters with the data type \"Integer\" or \"Float\"
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {number} campaign Format - int32. The campaign for which data should be returned
         * @param {number} heat Format - int32.
         * @param {number} [maxHistoricValues] Format - int32. The maximum number of historic values. Given in the query. The default value is 5
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParametervaluesVesselidCampaignHeat: async (vesselId: string, campaign: number, heat: number, maxHistoricValues?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getParametervaluesVesselidCampaignHeat', 'vesselId', vesselId)
            // verify required parameter 'campaign' is not null or undefined
            assertParamExists('getParametervaluesVesselidCampaignHeat', 'campaign', campaign)
            // verify required parameter 'heat' is not null or undefined
            assertParamExists('getParametervaluesVesselidCampaignHeat', 'heat', heat)
            const localVarPath = `/ParameterValues/{vesselId}/{campaign}/{heat}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)))
                .replace(`{${"campaign"}}`, encodeURIComponent(String(campaign)))
                .replace(`{${"heat"}}`, encodeURIComponent(String(heat)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (maxHistoricValues !== undefined) {
                localVarQueryParameter['maxHistoricValues'] = maxHistoricValues;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParameterValuesApi - functional programming interface
 * @export
 */
export const ParameterValuesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParameterValuesApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns parameter values of the given vessel, campaign and heat.  This currently only works for parameters with the data type \"Integer\" or \"Float\"
         * @summary Returns parameter values of the given vessel, campaign and heat.  This currently only works for parameters with the data type \"Integer\" or \"Float\"
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {number} campaign Format - int32. The campaign for which data should be returned
         * @param {number} heat Format - int32.
         * @param {number} [maxHistoricValues] Format - int32. The maximum number of historic values. Given in the query. The default value is 5
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParametervaluesVesselidCampaignHeat(vesselId: string, campaign: number, heat: number, maxHistoricValues?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMProcessDataServiceV1ModelsProcessDataParameterValueDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParametervaluesVesselidCampaignHeat(vesselId, campaign, heat, maxHistoricValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ParameterValuesApi - factory interface
 * @export
 */
export const ParameterValuesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParameterValuesApiFp(configuration)
    return {
        /**
         * Returns parameter values of the given vessel, campaign and heat.  This currently only works for parameters with the data type \"Integer\" or \"Float\"
         * @summary Returns parameter values of the given vessel, campaign and heat.  This currently only works for parameters with the data type \"Integer\" or \"Float\"
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {number} campaign Format - int32. The campaign for which data should be returned
         * @param {number} heat Format - int32.
         * @param {number} [maxHistoricValues] Format - int32. The maximum number of historic values. Given in the query. The default value is 5
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParametervaluesVesselidCampaignHeat(vesselId: string, campaign: number, heat: number, maxHistoricValues?: number, options?: any): AxiosPromise<Array<RHIMProcessDataServiceV1ModelsProcessDataParameterValueDto>> {
            return localVarFp.getParametervaluesVesselidCampaignHeat(vesselId, campaign, heat, maxHistoricValues, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParameterValuesApi - object-oriented interface
 * @export
 * @class ParameterValuesApi
 * @extends {BaseAPI}
 */
export class ParameterValuesApi extends BaseAPI {
    /**
     * Returns parameter values of the given vessel, campaign and heat.  This currently only works for parameters with the data type \"Integer\" or \"Float\"
     * @summary Returns parameter values of the given vessel, campaign and heat.  This currently only works for parameters with the data type \"Integer\" or \"Float\"
     * @param {string} vesselId Format - uuid. The id of the vessel
     * @param {number} campaign Format - int32. The campaign for which data should be returned
     * @param {number} heat Format - int32.
     * @param {number} [maxHistoricValues] Format - int32. The maximum number of historic values. Given in the query. The default value is 5
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParameterValuesApi
     */
    public getParametervaluesVesselidCampaignHeat(vesselId: string, campaign: number, heat: number, maxHistoricValues?: number, options?: AxiosRequestConfig) {
        return ParameterValuesApiFp(this.configuration).getParametervaluesVesselidCampaignHeat(vesselId, campaign, heat, maxHistoricValues, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ParametersApi - axios parameter creator
 * @export
 */
export const ParametersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a list of all available parameters for a customer and vessel type.
         * @summary Returns a list of all available parameters for a customer and vessel type.
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The type of the vessel
         * @param {boolean} [onlyMandatoryParameters] Returns only parameters which are mandatory according to the manifest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParametersCustomeridVesseltype: async (customerId: string, vesselType: string, onlyMandatoryParameters?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getParametersCustomeridVesseltype', 'customerId', customerId)
            // verify required parameter 'vesselType' is not null or undefined
            assertParamExists('getParametersCustomeridVesseltype', 'vesselType', vesselType)
            const localVarPath = `/Parameters/{customerId}/{vesselType}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"vesselType"}}`, encodeURIComponent(String(vesselType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (onlyMandatoryParameters !== undefined) {
                localVarQueryParameter['onlyMandatoryParameters'] = onlyMandatoryParameters;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of all available campaigns for a vessel.
         * @summary Returns a list of all available campaigns for a vessel.
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParametersVesselidCampaigns: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getParametersVesselidCampaigns', 'vesselId', vesselId)
            const localVarPath = `/Parameters/{vesselId}/campaigns`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of process paramaters for the provided campaigns.
         * @summary Returns a list of process paramaters for the provided campaigns.
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {Array} [campaignsToCompare] The campaigns for the comparison - if nothing is provided the newest two campaigns will be taken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParametersVesselidDatacomparison: async (vesselId: string, campaignsToCompare?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getParametersVesselidDatacomparison', 'vesselId', vesselId)
            const localVarPath = `/Parameters/{vesselId}/datacomparison`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (campaignsToCompare !== undefined) {
                localVarQueryParameter['campaignsToCompare'] = campaignsToCompare;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the internal campaign value and display text campaign for a vessel.
         * @summary Returns the internal campaign value and display text campaign for a vessel.
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParametersVesselidDisplay: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getParametersVesselidDisplay', 'vesselId', vesselId)
            const localVarPath = `/Parameters/{vesselId}/display`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParametersApi - functional programming interface
 * @export
 */
export const ParametersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParametersApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a list of all available parameters for a customer and vessel type.
         * @summary Returns a list of all available parameters for a customer and vessel type.
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The type of the vessel
         * @param {boolean} [onlyMandatoryParameters] Returns only parameters which are mandatory according to the manifest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParametersCustomeridVesseltype(customerId: string, vesselType: string, onlyMandatoryParameters?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMProcessDataServiceV1ModelsProcessDataParameterDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParametersCustomeridVesseltype(customerId, vesselType, onlyMandatoryParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of all available campaigns for a vessel.
         * @summary Returns a list of all available campaigns for a vessel.
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParametersVesselidCampaigns(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParametersVesselidCampaigns(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of process paramaters for the provided campaigns.
         * @summary Returns a list of process paramaters for the provided campaigns.
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {Array} [campaignsToCompare] The campaigns for the comparison - if nothing is provided the newest two campaigns will be taken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParametersVesselidDatacomparison(vesselId: string, campaignsToCompare?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMProcessDataServiceV1ModelsCampaignComparisonDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParametersVesselidDatacomparison(vesselId, campaignsToCompare, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the internal campaign value and display text campaign for a vessel.
         * @summary Returns the internal campaign value and display text campaign for a vessel.
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParametersVesselidDisplay(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMProcessDataServiceV1ModelsCampaignDisplayTextDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParametersVesselidDisplay(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ParametersApi - factory interface
 * @export
 */
export const ParametersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParametersApiFp(configuration)
    return {
        /**
         * Returns a list of all available parameters for a customer and vessel type.
         * @summary Returns a list of all available parameters for a customer and vessel type.
         * @param {string} customerId Format - uuid. The id of the customer
         * @param {string} vesselType The type of the vessel
         * @param {boolean} [onlyMandatoryParameters] Returns only parameters which are mandatory according to the manifest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParametersCustomeridVesseltype(customerId: string, vesselType: string, onlyMandatoryParameters?: boolean, options?: any): AxiosPromise<Array<RHIMProcessDataServiceV1ModelsProcessDataParameterDto>> {
            return localVarFp.getParametersCustomeridVesseltype(customerId, vesselType, onlyMandatoryParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of all available campaigns for a vessel.
         * @summary Returns a list of all available campaigns for a vessel.
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParametersVesselidCampaigns(vesselId: string, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.getParametersVesselidCampaigns(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of process paramaters for the provided campaigns.
         * @summary Returns a list of process paramaters for the provided campaigns.
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {Array} [campaignsToCompare] The campaigns for the comparison - if nothing is provided the newest two campaigns will be taken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParametersVesselidDatacomparison(vesselId: string, campaignsToCompare?: Array<number>, options?: any): AxiosPromise<Array<RHIMProcessDataServiceV1ModelsCampaignComparisonDto>> {
            return localVarFp.getParametersVesselidDatacomparison(vesselId, campaignsToCompare, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the internal campaign value and display text campaign for a vessel.
         * @summary Returns the internal campaign value and display text campaign for a vessel.
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParametersVesselidDisplay(vesselId: string, options?: any): AxiosPromise<Array<RHIMProcessDataServiceV1ModelsCampaignDisplayTextDto>> {
            return localVarFp.getParametersVesselidDisplay(vesselId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParametersApi - object-oriented interface
 * @export
 * @class ParametersApi
 * @extends {BaseAPI}
 */
export class ParametersApi extends BaseAPI {
    /**
     * Returns a list of all available parameters for a customer and vessel type.
     * @summary Returns a list of all available parameters for a customer and vessel type.
     * @param {string} customerId Format - uuid. The id of the customer
     * @param {string} vesselType The type of the vessel
     * @param {boolean} [onlyMandatoryParameters] Returns only parameters which are mandatory according to the manifest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametersApi
     */
    public getParametersCustomeridVesseltype(customerId: string, vesselType: string, onlyMandatoryParameters?: boolean, options?: AxiosRequestConfig) {
        return ParametersApiFp(this.configuration).getParametersCustomeridVesseltype(customerId, vesselType, onlyMandatoryParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of all available campaigns for a vessel.
     * @summary Returns a list of all available campaigns for a vessel.
     * @param {string} vesselId Format - uuid. The id of the vessel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametersApi
     */
    public getParametersVesselidCampaigns(vesselId: string, options?: AxiosRequestConfig) {
        return ParametersApiFp(this.configuration).getParametersVesselidCampaigns(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of process paramaters for the provided campaigns.
     * @summary Returns a list of process paramaters for the provided campaigns.
     * @param {string} vesselId Format - uuid. The id of the vessel
     * @param {Array} [campaignsToCompare] The campaigns for the comparison - if nothing is provided the newest two campaigns will be taken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametersApi
     */
    public getParametersVesselidDatacomparison(vesselId: string, campaignsToCompare?: Array<number>, options?: AxiosRequestConfig) {
        return ParametersApiFp(this.configuration).getParametersVesselidDatacomparison(vesselId, campaignsToCompare, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the internal campaign value and display text campaign for a vessel.
     * @summary Returns the internal campaign value and display text campaign for a vessel.
     * @param {string} vesselId Format - uuid. The id of the vessel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametersApi
     */
    public getParametersVesselidDisplay(vesselId: string, options?: AxiosRequestConfig) {
        return ParametersApiFp(this.configuration).getParametersVesselidDisplay(vesselId, options).then((request) => request(this.axios, this.basePath));
    }
}


