import { i18nReact } from '@rhim/i18n';
import { GearIcon } from '@rhim/icons/24';
import { APOIcon, QCKIcon } from '@rhim/icons/32';
import { AppBar } from '@rhim/react';
import { usePrivileges } from '@rhim/sdk/customerManagement';
import { dataCenterLinkAppBar } from '@rhim/test-ids';
import { isDefined } from '@rhim/utils';
import React, { useContext } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { isQckLightCustomer, useLanguageSwitcher } from '../../lib';
import { PRIVILEGES, qckLightProductName, ROUTES } from '../../utilities';
import AppContext from '../AppContext';
import { ApplicationBarProps } from './ApplicationBarProps';
import { NavigationItemAccount } from './NavigationItems/NavigationItemAccount';
import { SelectCustomer } from './SelectCustomer';
import { useMainNavigation } from './useMainNavigation';

export const ApplicationBarSteel: React.FunctionComponent<React.PropsWithChildren<ApplicationBarProps>> = ({ authClient, selectCustomer }) => {
  const { t } = i18nReact.useTranslation(['app']);
  const { selectedCustomer, user, vessels } = useContext(AppContext);
  const isQckLight = isQckLightCustomer();
  const ref = React.useRef<HTMLDivElement>(null);

  const mainNavigation = useMainNavigation(vessels);

  useLanguageSwitcher();
  const privileges = usePrivileges(user, selectedCustomer);
  const hasMultipleCustomers = isDefined(user.customers) && user.customers.length > 1;

  // if the feature flag for QCK light is set, then we explicitly display the QCK light logo and title in the navigation bar instead of APO
  const appBarLogo = isQckLight ? (
    <AppBar.Logo productLogoIcon={<QCKIcon />} productLabel={qckLightProductName} productLogoHref={ROUTES.START} />
  ) : (
    <AppBar.Logo productLogoIcon={<APOIcon />} productLogoHref={ROUTES.START} />
  );

  const hasDataCenterAccess = privileges.global.has(PRIVILEGES.AccessDataCenter) || privileges.customer.has(PRIVILEGES.AccessDataCenter);

  const location = useLocation();
  const isDataCenter = isDefined(matchPath({ path: `${ROUTES.INGRESS}/*` }, location.pathname));
  const isUserSettings = isDefined(matchPath({ path: `${ROUTES.USER_SETTINGS}` }, location.pathname));

  const shouldUnderlineVesselType = !(isDataCenter || isUserSettings);

  return (
    <AppBar.Bar ref={ref}>
      {appBarLogo}
      <AppBar.NavigationItems>
        {hasMultipleCustomers && <SelectCustomer selectCustomer={selectCustomer} getPopupContainer={(node) => ref.current ?? node} />}
        {mainNavigation.type === 'full' &&
          mainNavigation.items.map((item) => (
            <AppBar.NavigationButton
              key={item.label}
              destination={item.destination}
              label={item.label}
              active={shouldUnderlineVesselType && item.active}
              onClick={item.onClick}
            />
          ))}
        <AppBar.HorizontalSpring />
        {hasDataCenterAccess && <AppBar.NavigationItem label={t('app:appBar.ingress')} href={ROUTES.INGRESS} dataTestId={dataCenterLinkAppBar} />}
      </AppBar.NavigationItems>
      <AppBar.FunctionItems>
        <AppBar.NavigationIconItem href={ROUTES.USER_SETTINGS} icon={<GearIcon />} />
        <NavigationItemAccount authClient={authClient} selectCustomer={selectCustomer} />
      </AppBar.FunctionItems>
    </AppBar.Bar>
  );
};
