import { settings } from '@rhim/design';
import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
}
const NecessityIndicator: React.ChildlessComponent<Props> = ({ className }) => {
  return <SNecessityIndicator className={className}>*</SNecessityIndicator>;
};

export default React.memo(NecessityIndicator);

const SNecessityIndicator = styled.span`
  font-family: ${settings.typography.FontFamily.Bold};
  font-size: ${settings.typography.FontSize.X_Large};
  line-height: 0;
  color: ${settings.colors.Primary.Grey_8};
  margin-left: ${settings.Spacing.Spacing_50};
`;
