import styled from 'styled-components';

/**
 * A container that is used to group elements without affecting the layout of the page.
 *
 * https://developer.mozilla.org/en-US/docs/Web/CSS/display#display_contents
 */
export const Bag = styled.div`
  display: contents;
`;
