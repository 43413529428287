import { assert, isDefined } from '@rhim/utils';
import { autobind } from '@typed-decorators/autobind';
import React from 'react';

class Rolodex<T> {
  index: number;
  length: number;

  constructor(private sequence: NonEmptyArray<T>) {
    this.sequence = sequence;
    this.index = 0;
    this.length = sequence.length;
  }

  @autobind
  next(): T {
    const seq = this.sequence[++this.index % this.length];
    assert(isDefined(seq), 'Sequence undefined');
    return seq;
  }
}

export function useRolodex<T>(initialSequence: NonEmptyArray<T>): Rolodex<T> {
  const slider = React.useRef(new Rolodex(initialSequence));

  React.useDebugValue(slider);

  return slider.current;
}
