import { settings } from '@rhim/design';
import { isDefined } from '@rhim/utils';
import * as React from 'react';
import styled from 'styled-components';

export interface PercentageBarProps {
  min: number;
  max: number;
  outerLimit?: number | null;
  innerLimit?: number | null;
  value?: number | null;
  historicValues?: number[] | null;
  className?: string;
  colored?: boolean;
}

/**
 * Arrow Height.
 */
const ARROW_HEIGHT = 12;
/**
 * Arrow WIDTH.
 */
const ARROW_WIDTH = 8;

/**
 * Percentage Bar component.
 */
export const PercentageBar = (props: PercentageBarProps) => {
  /**
   * Calculates the percentage value based on the given param current value.
   * @param value
   */
  const getPercentage = (value: number) => {
    const difference = props.max - props.min;
    const distance = difference - (props.max - value);
    const percentage = (distance * 100) / difference;
    // In case the value is outside the limit range.
    if (percentage > 100) {
      return 100;
    } else if (percentage < 0) {
      return 0;
    }
    return percentage;
  };

  const outerLimit = props.outerLimit ?? 0;
  const innerLimit = props.innerLimit ?? 0;
  const percentage = isDefined(props.value) ? getPercentage(props.value) : null;

  /**
   * Checks if the the value falls in the inner limit. The color of the area is colored to indicate the warning state.
   * @param right 'true' if it's for the right sight inner limit.
   */
  const isWarning = (right: boolean) => {
    if (isDefined(percentage)) {
      const leftLimit = percentage <= outerLimit + innerLimit && percentage > outerLimit;
      const rightLimit = percentage >= 100 - (outerLimit + innerLimit) && percentage < 100 - outerLimit;
      return right ? rightLimit : leftLimit;
    }
    return false;
  };

  /**
   * Checks if the value falls in the outer limit. The color of the area is colored to indicate the alert state.
   * @param right 'true' if it's for the right sight inner limit.
   */
  const isAlert = (right: boolean) => {
    if (isDefined(percentage)) {
      const leftLimit = percentage <= outerLimit + innerLimit && percentage <= outerLimit;
      const rightLimit = percentage >= 100 - (outerLimit + innerLimit) && percentage >= 100 - outerLimit;
      return right ? rightLimit : leftLimit;
    }
    return false;
  };

  return (
    <Container className={props.className}>
      <Bar>
        <Limits>
          <OuterLimit width={outerLimit * 2} alert={props.colored === true ? true : isAlert(false)} />
          <InnerLimit width={innerLimit * 2} warning={props.colored === true ? true : isWarning(false)} />
        </Limits>
        <RightLimits>
          <InnerLimit width={innerLimit * 2} warning={props.colored === true ? true : isWarning(true)} />
          <OuterLimit width={outerLimit * 2} alert={props.colored === true ? true : isAlert(true)} />
        </RightLimits>
        {(props.historicValues || [])
          .map((value, index) => {
            return (
              <Arrow
                key={index}
                percentage={getPercentage(value)}
                width={6}
                height={9}
                color={settings.colors.Primary[`Grey_${6 - index}` as keyof typeof settings.colors.Primary]}
              />
            );
          })
          .reverse()}
        {isDefined(props.value) && isDefined(percentage) && (
          <Arrow
            percentage={percentage}
            color={props.min > props.value || props.value > props.max ? settings.colors.Operational.State_Alert_Red_3 : settings.colors.Primary.Blue_9}
            width={ARROW_WIDTH}
            height={ARROW_HEIGHT}
          />
        )}
      </Bar>
    </Container>
  );
};

const Container = styled.div`
  width: 80px;
  height: 15px;
  position: relative;
`;

const Bar = styled(Container)`
  width: 80px;
  height: 8px;
  background-color: white;
  display: inline-flex;
  border: 1px solid ${settings.colors.Primary.Grey_5};
`;

const Limits = styled.div`
  width: 50%;
  display: inline-flex;
`;

const Arrow = styled.div<{ percentage: number; height: number; width: number; color?: string }>`
  position: absolute;
  margin-left: ${(props) => `calc(${props.percentage}% - ${props.height / 2}px)`};
  margin-top: 5px;
  width: 0;
  height: 0;
  transform-style: preserve-3d;
  border-left: ${(props) => `${props.height / 2}px solid transparent`};
  border-right: ${(props) => `${props.height / 2}px solid transparent`};
  border-bottom: ${(props) => `${props.width}px solid ${props.color ?? settings.colors.Primary.Blue_9}`};

  &::after {
    position: absolute;
    display: block;
    margin-top: -1px;
    margin-left: ${(props) => `-${(props.height + 1) / 2}px`};
    content: '';
    border-left: ${(props) => `${(props.height + 1) / 2}px solid transparent`};
    border-right: ${(props) => `${(props.height + 1) / 2}px solid transparent`};
    border-bottom: ${(props) => `${props.width + 1}px solid white`};
    transform: translateZ(-1px);
  }
`;

const RightLimits = styled(Limits)`
  justify-content: flex-end;
`;

const OuterLimit = styled.div<{ width: number; alert: boolean }>`
  width: ${(props) => props.width}%;
  background-color: ${(props) => (props.alert ? settings.colors.Operational.State_Alert_Red_2 : settings.colors.Primary.Grey_5)};
  color: grey;
`;

const InnerLimit = styled.div<{ width: number; warning: boolean }>`
  width: ${(props) => props.width}%;
  background-color: ${(props) => (props.warning ? settings.colors.Operational.State_Alert_Yellow_2 : settings.colors.Primary.Grey_3)};
  color: grey;
`;

export default React.memo(PercentageBar);
