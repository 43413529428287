import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const CheckmarkIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M7 13c-.264 0-.519-.104-.707-.293l-4-4c-.391-.391-.391-1.023 0-1.414s1.023-.391 1.414 0l3.185 3.185L12.2 3.4c.332-.442.957-.531 1.4-.2.442.33.531.958.2 1.4l-6 8c-.174.232-.44.377-.729.397L7 13"
      />
    </svg>
  );
};

CheckmarkIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
