import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const TrkAreaIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        d="M3 10.72c-.55 0-1-.44-1-1V5c0-1.65 1.35-3 3-3h5.1c.55 0 1 .45 1 1s-.45 1-1 1H5c-.55 0-1 .45-1 1v4.72c0 .55-.45 1-1 1zM21 13.28c.55 0 1 .45 1 1V19c0 1.65-1.35 3-3 3h-5.1c-.55 0-1-.45-1-1s.45-1 1-1H19c.55 0 1-.45 1-1v-4.72c0-.55.45-1 1-1zM10.72 21c0 .55-.45 1-1 1H5c-1.65 0-3-1.35-3-3v-5.1c0-.55.45-1 1-1s1 .45 1 1V19c0 .55.45 1 1 1h4.72c.55 0 1 .45 1 1zM13.28 3c0-.55.44-1 1-1H19c1.65 0 3 1.35 3 3v5.1c0 .55-.45 1-1 1s-1-.45-1-1V5c0-.55-.45-1-1-1h-4.72c-.55 0-1-.45-1-1z"
      />
    </svg>
  );
};

TrkAreaIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
