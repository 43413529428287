import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const PdfIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 40 40" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 2.5V6.501C22 7.605 22.895 8.5 23.999 8.5H28V20.5C28 21.605 27.104 22.5 26 22.5H14C12.896 22.5 12 21.605 12 20.5V4.5C12 3.396 12.896 2.5 14 2.5H22ZM23.9997 2.5002L28.0007 6.5002H23.9997V2.5002Z"
      />
      <path
        fill={fill}
        d="M14.209 33.8486H12.3584V32.877H14.209C14.5312 32.877 14.7917 32.8249 14.9902 32.7207C15.1888 32.6165 15.3337 32.4733 15.4248 32.291C15.5192 32.1055 15.5664 31.8939 15.5664 31.6562C15.5664 31.4316 15.5192 31.2217 15.4248 31.0264C15.3337 30.8278 15.1888 30.6683 14.9902 30.5479C14.7917 30.4274 14.5312 30.3672 14.209 30.3672H12.7344V36.5H11.5088V29.3906H14.209C14.7591 29.3906 15.2262 29.4883 15.6104 29.6836C15.9977 29.8757 16.2923 30.1426 16.4941 30.4844C16.696 30.8229 16.7969 31.2103 16.7969 31.6465C16.7969 32.1055 16.696 32.4993 16.4941 32.8281C16.2923 33.1569 15.9977 33.4092 15.6104 33.585C15.2262 33.7607 14.7591 33.8486 14.209 33.8486ZM19.9609 36.5H18.4424L18.4521 35.5283H19.9609C20.4004 35.5283 20.7682 35.4323 21.0645 35.2402C21.3639 35.0482 21.5885 34.7731 21.7383 34.415C21.8913 34.057 21.9678 33.6305 21.9678 33.1357V32.75C21.9678 32.3659 21.9238 32.0257 21.8359 31.7295C21.7513 31.4333 21.6243 31.1842 21.4551 30.9824C21.2891 30.7806 21.084 30.6276 20.8398 30.5234C20.599 30.4193 20.3206 30.3672 20.0049 30.3672H18.4131V29.3906H20.0049C20.4769 29.3906 20.9082 29.4704 21.2988 29.6299C21.6895 29.7861 22.0264 30.0124 22.3096 30.3086C22.596 30.6048 22.8158 30.9596 22.9688 31.373C23.1217 31.7865 23.1982 32.2487 23.1982 32.7598V33.1357C23.1982 33.6468 23.1217 34.109 22.9688 34.5225C22.8158 34.9359 22.596 35.2907 22.3096 35.5869C22.0231 35.8799 21.6813 36.1061 21.2842 36.2656C20.8903 36.4219 20.4492 36.5 19.9609 36.5ZM19.1211 29.3906V36.5H17.8955V29.3906H19.1211ZM25.6543 29.3906V36.5H24.4287V29.3906H25.6543ZM28.5547 32.501V33.4727H25.3418V32.501H28.5547ZM28.96 29.3906V30.3672H25.3418V29.3906H28.96Z"
      />
    </svg>
  );
};

PdfIcon.defaultProps = {
  size: '40',
  fill: settings.colors.Primary.Blue_9,
};
