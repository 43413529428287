import { CampaignApi, Configuration, ExtractionApi, KilnShellTemperatureProfilesApi, ScanApi } from '@rhim/rest/cement';

/**
 * "https://apim-apo-dev.azure-api.net/wearmanagement/v1".replace(/\/+$/, "");
 */
const BASE_URL = '/api/cementservice/v1';

const configuration: Configuration = {} as Configuration;

export const campaign = new CampaignApi(configuration, BASE_URL);
export const kilnShellTemperatureProfiles = new KilnShellTemperatureProfilesApi(configuration, BASE_URL);
export const scans = new ScanApi(configuration, BASE_URL);
export const extractions = new ExtractionApi(configuration, BASE_URL);
