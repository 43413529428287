import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const TargetIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1a1 1 0 0 0-1 1v2.062A8.004 8.004 0 0 0 4.062 11H2a1 1 0 1 0 0 2h2.062A8.004 8.004 0 0 0 11 19.938V22a1 1 0 1 0 2 0v-2.062A8.004 8.004 0 0 0 19.938 13H22a1 1 0 1 0 0-2h-2.062A8.004 8.004 0 0 0 13 4.062V2a1 1 0 0 0-1-1zm6 11a6 6 0 1 1-12 0 6 6 0 0 1 12 0zm-6 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
        fill={fill}
      />
    </svg>
  );
};

TargetIcon.defaultProps = {
  fill: settings.colors.Primary.Blue_9,
  size: '24',
};
