import { useEffect, useRef } from 'react';
import { positionValues } from 'react-custom-scrollbars-2';

import { ScrollbarRef } from '../types';

export function useScrolling({
  scrollbarRef,
  updateScrollPositions,
}: {
  scrollbarRef: ScrollbarRef;
  updateScrollPositions: (scrollPosition: number | null, emitter?: ScrollbarRef) => void;
}) {
  const userScrollPos = useRef(scrollbarRef.current?.getValues().left ?? 0);

  useEffect(() => {
    const scrollbarRefCur = scrollbarRef.current;
    const scrollWidth = scrollbarRefCur?.getScrollWidth() ?? 0;
    const clientWidth = scrollbarRefCur?.getClientWidth() ?? 0;

    const left = userScrollPos.current * (scrollWidth - clientWidth);

    scrollbarRefCur?.scrollLeft(left);
  }, [scrollbarRef]);

  const handleScrollFrame = (values: positionValues) => {
    userScrollPos.current = values.left;

    updateScrollPositions(values.scrollLeft, scrollbarRef);
  };

  const handleScrollStart = () => {
    document.getSelection()?.empty();
  };

  return {
    handleScrollFrame,
    handleScrollStart,
  };
}
