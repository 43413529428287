import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const LeftUTurnIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={33} fill="none" viewBox="0 0 16 33" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path fill={fill} d="M0 .5h16v32H0z" />
      <path d="M13 22.5v-11H5V19" stroke={settings.colors.Operational.State_Green_3} strokeWidth="2" />
      <path d="m5 22.5-4-5h8l-4 5z" fill={settings.colors.Operational.State_Green_3} />
    </svg>
  );
};

LeftUTurnIcon.defaultProps = {
  fill: settings.colors.Operational.State_Green_1,
};
