import { RestartIcon as RestartIcon16 } from '@rhim/icons/16';
import { RestartIcon as RestartIcon24 } from '@rhim/icons/24';
import { isDefined } from '@rhim/utils';
import React, { useEffect, useState } from 'react';

import { IconButton } from '../IconButton';
import { InputNumeric } from '../InputNumeric';
import { TextField } from '../TextField';

type ConditionalProps =
  | {
      isNumeric: true;
      min?: number;
      max?: number;
    }
  | {
      isNumeric?: false;
      min?: never;
      max?: never;
    };

interface Props extends Omit<React.ComponentProps<typeof TextField>, 'value' | 'onChange' | 'defaultValue' | 'type'> {
  className?: string;
  defaultValue: string | number;
  resetButtonTooltip: string;
  resetIcon?: React.ReactElement;
  value: string | number;
  onChange?: (value: string | number | null) => void;
}

const ResettableTextField: React.ChildlessComponent<Props & ConditionalProps> = ({
  defaultValue,
  resetButtonTooltip,
  value,
  onChange,
  resetIcon,
  variant = 'medium',
  isNumeric = false,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState<string | number | undefined | null>(value);
  const [isResetButtonShowing, setResetButtonShowing] = useState(false);

  const defaultResetIcon = React.useMemo(() => {
    switch (variant) {
      case 'small':
      case 'x-small':
        return <RestartIcon16 />;
      default:
        return <RestartIcon24 />;
    }
  }, [variant]);

  useEffect(() => {
    setInputValue(value);
    // hide the reset-icon-button if :
    // - the current value is the same as the default value or
    // - if the component is disabled or
    // - if the component is readOnly (locked)
    setResetButtonShowing(!(rest.isDisabled ?? false) && !(rest.isReadOnly ?? false) && value !== defaultValue);
  }, [value, defaultValue, rest.isDisabled, rest.isReadOnly]);

  const handleValueChanged = (newValue: string | number | null) => {
    setInputValue(newValue);
    setResetButtonShowing(newValue !== defaultValue);
    if (newValue === null) return;
    onChange?.(newValue);
  };

  const handleResetButtonPressed = () => {
    handleValueChanged(defaultValue);
  };

  const iconButton = isResetButtonShowing ? (
    <IconButton
      className="resetButton"
      icon={isDefined(resetIcon) ? resetIcon : defaultResetIcon}
      tooltip={resetButtonTooltip}
      onPress={handleResetButtonPressed}
    />
  ) : undefined;

  return isNumeric ? (
    <InputNumeric value={Number(inputValue)} iconButton={iconButton} onChange={handleValueChanged} variant={variant} {...rest} />
  ) : (
    <TextField value={inputValue?.toString()} iconButton={iconButton} onChange={handleValueChanged} variant={variant} {...rest} />
  );
};

ResettableTextField.whyDidYouRender = true;

export default React.memo(ResettableTextField);
