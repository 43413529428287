import { assert, isDefined } from '@rhim/utils';
import React from 'react';

import AppContext from '../../../app/AppContext';
import { useCustomerDetails } from '../../../hooks';

export function useCustomerTimezoneName() {
  const { selectedCustomer } = React.useContext(AppContext);
  const customerId = selectedCustomer.customerId as UUID;
  const { data: customerDetails } = useCustomerDetails(customerId);
  assert(isDefined(customerDetails), 'useCustomerTimezone, in Suspense mode data (customerDetails) should always be defined, according to react-query docs.');
  assert(isDefined(customerDetails.timeZoneName), 'useCustomerTimezone, timeZoneName not set in customerDetails');

  return customerDetails.timeZoneName;
}
