export const ZOOM_INIT = 1;
export const ZOOM_CENTER_DEFAULT = 0.5;
export const ZOOM_MIN = 1;
export const ZOOM_MAX = 8;
export const ZOOM_STEP = 1;

export const DRAG_ZOOM_DATE_FORMAT = 'dd.MM.';
export const DRAG_ZOOM_MAX = 52;

export const ACTION_ID = {
  viewZoomIn: 'viewZoomIn',
  viewZoomOut: 'viewZoomOut',
};
