import { settings } from '@rhim/design';
import { Steps } from '@rhim/react';
import { itemsStepsContainer, stepsContainer } from '@rhim/test-ids';
import { isDefined } from '@rhim/utils';
import React from 'react';
import styled from 'styled-components';

export type IStepTitle = string | JSX.Element;
export interface IStep {
  title: IStepTitle;
  content: JSX.Element;
}

interface StepWithSubtitleProps {
  title: string;
  subtitle?: string;
}
export const StepWithSubtitle: React.ChildlessComponent<StepWithSubtitleProps> = ({ title, subtitle }) => {
  return (
    <SStepWithSubtitleWrapper hasSubtitle={isDefined(subtitle)}>
      <SStepTitle>
        {title}
        {isDefined(subtitle) && <SStepSubtitle>{subtitle}</SStepSubtitle>}
      </SStepTitle>
    </SStepWithSubtitleWrapper>
  );
};

interface Props {
  steps: IStepTitle[];
  currentStep: number;
  maxWidth?: number;
}

const UploadSteps: React.ChildlessComponent<Props> = ({ steps, currentStep, maxWidth = 620 }) => {
  return (
    <Steps
      data-test-id={stepsContainer}
      current={currentStep}
      size="small"
      style={{ maxWidth }}
      items={steps.map((step, index) => {
        return {
          key: index,
          title: step,
          'data-test-id': itemsStepsContainer,
        };
      })}
    />
  );
};

export default React.memo(UploadSteps);

const SStepWithSubtitleWrapper = styled.div<{ hasSubtitle: boolean }>`
  margin-top: ${(props) => (props.hasSubtitle ? '-5px' : '0')};
`;

const SStepTitle = styled.span`
  color: ${settings.colors.Primary.Grey_8};
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.Medium};
`;

const SStepSubtitle = styled.div`
  color: ${settings.colors.Primary.Grey_6};
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.X_Small};
  line-height: 12px;
`;
