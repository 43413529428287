import { isDefined } from '@rhim/utils';
import { Glyph } from '@vx/vx';
import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  fill: string;
  cx: number;
  cy: number;
  strokeWidth?: number;
  stroke?: string;
  r: number;
  onMouseEnter?: React.MouseEventHandler<SVGCircleElement>;
  onMouseLeave?: React.MouseEventHandler<SVGCircleElement>;
}

export const CirclePoint: React.FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  const { fill, cx, cy, r, stroke, strokeWidth, onMouseEnter, onMouseLeave, ...rest } = props;

  return (
    <Circle strokeWidth={strokeWidth} stroke={stroke} fill={fill} cx={cx} cy={cy} r={r} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} {...rest} />
  );
};

const Circle = styled.circle<{ strokeWidth?: number; stroke?: string }>`
  stroke: ${(props) => (isDefined(props.stroke) ? props.stroke : 'none')};
  stroke-width: ${(props) => (isDefined(props.stroke) ? `${props.strokeWidth}px` : 0)};
  cursor: pointer;
`;
interface DiamondProps {
  className?: string;
  fill: string;
  x: number;
  y: number;
  width: number;
  height: number;
  onMouseEnter?: React.MouseEventHandler<SVGRectElement>;
  onMouseLeave?: React.MouseEventHandler<SVGRectElement>;
}
export const DiamondPoint: React.FunctionComponent<React.PropsWithChildren<DiamondProps>> = (props) => {
  const { fill, x, y, width, height, onMouseEnter, onMouseLeave, ...rest } = props;
  return (
    <Glyph left={x} top={y}>
      <Diamond width={width} height={height} fill={fill} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} {...rest} />
    </Glyph>
  );
};

const Diamond = styled.rect<{ width: number; height: number }>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.width}px;
  cursor: pointer;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
`;
