/* eslint-disable use-encapsulation/prefer-custom-hooks */

import { settings } from '@rhim/design';
import { Heading, Hyperlink, Legal } from '@rhim/react';
import { headerCodeOfConduct } from '@rhim/test-ids';
import { useState } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

const CodeOfConductLinks = [
  {
    id: 1,
    label: 'EN',
    URL: 'https://www.rhimagnesita.com/wp-content/uploads/2020/01/coc-2001-en-200121-mon.pdf',
    UrlTitle: 'EN',
  },
  {
    id: 2,
    label: 'DE',
    URL: 'https://www.rhimagnesita.com/wp-content/uploads/2020/03/coc-2001-de-200121-mon.pdf',
    UrlTitle: 'DE',
  },
  {
    id: 3,
    label: 'PT',
    URL: 'https://www.rhimagnesita.com/wp-content/uploads/2020/02/coc-2002_2-pt-200205-mon.pdf',
    UrlTitle: 'PT',
  },
  {
    id: 4,
    label: 'CN',
    URL: 'https://www.rhimagnesita.com/wp-content/uploads/2020/03/coc-2001-cn-200121-mon.pdf',
    UrlTitle: 'CN',
  },
  {
    id: 5,
    label: 'ES',
    URL: 'https://www.rhimagnesita.com/wp-content/uploads/2020/03/coc-2001-es-200121-mon.pdf',
    UrlTitle: 'ES',
  },
  {
    id: 6,
    label: 'FR',
    URL: 'https://www.rhimagnesita.com/wp-content/uploads/2020/03/coc-2001-fr-200121-mon.pdf',
    UrlTitle: 'FR',
  },
  {
    id: 7,
    label: 'IT',
    URL: 'https://www.rhimagnesita.com/wp-content/uploads/2020/03/coc-2001-it-200121-mon.pdf',
    UrlTitle: 'IT',
  },
  {
    id: 8,
    label: 'TR',
    URL: 'https://www.rhimagnesita.com/wp-content/uploads/2020/03/coc-2001-tk-200121-mon.pdf',
    UrlTitle: 'TR',
  },
  {
    id: 9,
    label: 'HI',
    URL: 'https://www.rhimagnesita.com/wp-content/uploads/2020/01/coc-2001-in-200121-mon.pdf',
    UrlTitle: 'HI',
  },
  {
    id: 10,
    label: 'RU',
    URL: 'https://www.rhimagnesita.com/wp-content/uploads/2020/03/coc-2001-ru-200121-mon.pdf',
    UrlTitle: 'RU',
  },
  {
    id: 11,
    label: 'NO',
    URL: 'https://www.rhimagnesita.com/wp-content/uploads/2020/03/coc-2001-no-200121-mon.pdf',
    UrlTitle: 'NO',
  },
];

const LegalTabCodeOfConduct = () => {
  const [items] = useState(CodeOfConductLinks);
  return (
    <Trans i18nKey="static:legal.topics.code-of-conduct.content">
      <Heading data-test-id={headerCodeOfConduct} level="h2">
        Code of Conduct
      </Heading>
      <Legal.Paragraph>
        The Code of Conduct is the foundation of good corporate governance. Building on our mission and our values, the Code of Conduct describes the principles
        of legally and ethically correct behavior both within the company and outside in all interactions with customers, suppliers, competitors, investors,
        government agencies or the public.
      </Legal.Paragraph>
      <Legal.Paragraph>
        The Code of Conduct applies to all employees of RHI Magnesita and its subsidiaries irrespective of employment or hierarchical position.
      </Legal.Paragraph>
      <Legal.Paragraph>Here you can download the Code of Conduct in different languages:</Legal.Paragraph>
      <Legal.Paragraph>
        <StyledLegalGroup>
          {items.map((item, index) => (
            <StyledHyperLink target="_blank" href={item.URL} text={item.label} key={index} />
          ))}
        </StyledLegalGroup>
      </Legal.Paragraph>
      <Legal.Paragraph>
        <Hyperlink
          target="_blank"
          href="https://www.rhimagnesita.com/wp-content/uploads/2022/04/uk-modern-slavery-act-signed.pdf"
          text="Joint statement of the RHI Magnesita Group in accordance with the UK Modern Slavery Act and the California Transparency in Supply Chains Act"
        />
      </Legal.Paragraph>
      <Legal.Paragraph>
        <Hyperlink
          target="_blank"
          href="https://www.rhimagnesita.com/wp-content/uploads/2017/10/CanTxt-data.pdf"
          text="Accessibility Standard for Customer Service – Ontario, Canada"
        />
      </Legal.Paragraph>
    </Trans>
  );
};

const StyledHyperLink = styled(Hyperlink)`
  margin-right: 5px;
`;

const StyledLegalGroup = styled.div`
  display: flex;
  align-items: center;

  .hyperlink {
    background-color: ${settings.colors.Primary.Grey_3};
    color: ${settings.colors.Primary.Blue_9};
    padding: 0 ${settings.Spacing.Spacing_150};
    margin: 0 ${settings.Spacing.Spacing_100} 0 0;
  }
`;

export default LegalTabCodeOfConduct;
