import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { CheckmarkIcon, XMiniIcon } from '@rhim/icons/16';
import { Button, message, Spin, Spinner } from '@rhim/react';
import {
  RHIMOperatorDisplayServiceV1ModelsRegionOrderAndVisibilityDto,
  RHIMOperatorDisplayServiceV1ModelsUpdateRegionsOrderAndVisibilityDto,
} from '@rhim/rest/operatorDisplay';
import {
  applyChangesButtonRegionSelectionContainer,
  cancelButtonRegionSelectionContainer,
  closeButtonRegionSelectionContainer,
  descriptionRegionSelectionContainer,
  regionSelectionContainerOperatorDisplay,
  titleDescriptionRegionSelectionContainer,
  titleRegionSelectionContainer,
} from '@rhim/test-ids';
import { assert, isDefined } from '@rhim/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';
import React from 'react';
import { useList } from 'react-use';
import styled from 'styled-components';

import { API } from '../../api/operatorDisplay';
import { useRegionsOrder, useWMSData } from '../../hooks';
import ListSortWrapper from './ListSort';

interface Props {
  vesselId: UUID;
  visible: boolean;
  callback?: () => void;
  data: RHIMOperatorDisplayServiceV1ModelsRegionOrderAndVisibilityDto[];
  onClose: (value: boolean) => void;
  campaignId?: number;
  comparedCampaign?: number;
}

export const AreasSelectionPanel = ({ vesselId, callback, onClose, visible, data, campaignId, comparedCampaign }: Props) => {
  const queryClient = useQueryClient();
  const { t } = i18nReact.useTranslation(['operator-display']);
  const [active, setActive] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [regions, actions] = useList((): RHIMOperatorDisplayServiceV1ModelsRegionOrderAndVisibilityDto[] =>
    data.map((region, index) => ({ ...region, order: index }))
  );

  React.useEffect(() => {
    actions.set(data);
  }, [data, actions]);

  React.useEffect(() => {
    if (!visible) {
      setActive(false);
      actions.set(data);
    }
  }, [data, visible, actions]);

  const mutation = useMutation(
    async (values: RHIMOperatorDisplayServiceV1ModelsUpdateRegionsOrderAndVisibilityDto) => {
      await API.vessel
        .putVesselVesselidOrder(vesselId, values, {
          headers: {
            'Content-Type': 'application/json',
          },
        } as AxiosRequestConfig)
        .then((response) => response.data)
        .catch((error: Error) => {
          assert(axios.isAxiosError(error), 'Expected an Axios error');
          assert(isDefined(error.response), 'Expected API errors to always contain a payload');

          const detail: string = error.response.data.detail;
          return message.error(detail || error.message);
        });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(useRegionsOrder.getKey(vesselId), { refetchType: 'all' });
        await queryClient.invalidateQueries(useWMSData.getKey(vesselId, isDefined(campaignId) ? campaignId.toString() : campaignId));
        if (isDefined(comparedCampaign)) {
          await queryClient.invalidateQueries(useWMSData.getKey(vesselId, comparedCampaign.toString()));
        }
      },
    }
  );

  const submitChanges = async () => {
    setLoading(true);
    const updateRegionOrderAndVisibilityDtos = regions.map((region, index) => ({
      ...region,
      order: index,
    }));

    const values = {
      updateRegionOrderAndVisibilityDtos,
    };

    try {
      await mutation.mutateAsync(values);
    } catch (error) {
      const msj = error instanceof Error ? error.message : 'Something went wrong. Please try again!';
      message.error(msj);
    }

    setLoading(false);
    setActive(false);

    if (isDefined(callback)) {
      callback();
    }
  };

  const onClick = () => {
    onClose(false);
    setActive(false);
    actions.set(data);
  };

  return (
    <Nest>
      <Banner visible={visible}>
        <Container data-test-id={regionSelectionContainerOperatorDisplay} loading={loading}>
          <DescriptionHeader>
            <HeaderBar tabIndex={1}>
              <Title data-test-id={titleRegionSelectionContainer}>{t('operator-display:sortList.title')}</Title>
              <XMiniIcon
                data-test-id={closeButtonRegionSelectionContainer}
                fill={settings.colors.Primary.Blue_9}
                style={{ cursor: 'pointer' }}
                onClick={onClick}
              />
            </HeaderBar>
            <DescriptionTitle active={true} data-test-id={titleDescriptionRegionSelectionContainer}>
              {t('operator-display:sortList.header')}
            </DescriptionTitle>
            <Description data-test-id={descriptionRegionSelectionContainer}>{t('operator-display:sortList.description')}</Description>
          </DescriptionHeader>
          <ListSortWrapper regions={regions} actions={actions} setActive={setActive} />
          <StyledButtonGroup>
            <StyledButton data-test-id={cancelButtonRegionSelectionContainer} mode="ghost" onClick={onClick} label={t('operator-display:sortList.cancel')} />
            <FullStyledButton
              data-test-id={applyChangesButtonRegionSelectionContainer}
              disabled={!active}
              onClick={() => submitChanges()}
              label={t('operator-display:sortList.applyChanges')}
              icon={{
                icon: loading ? <Spin indicator={<Spinner size="16" rotationDuration={1} />} /> : <CheckmarkIcon fill={settings.colors.Monochromatic.White} />,
                position: 'end',
              }}
            />
          </StyledButtonGroup>
        </Container>
      </Banner>
    </Nest>
  );
};

const Title = styled.div`
  color: ${settings.colors.Primary.Grey_7};
  font-size: 12px;
  font-family: ${settings.typography.FontFamily.Medium};
  flex: 1;
`;

const StyledButtonGroup = styled.section`
  display: flex;
  justify-content: end;
  min-width: 155px;
  margin: ${settings.Spacing.Spacing_200} 0;

  .btn-background-primary-9-brand.is-disabled {
    --color: ${settings.colors.Primary.Grey_4};

    color: ${settings.colors.Primary.Grey_4};
  }

  .btn-size-small-40 {
    padding: 0 ${settings.Spacing.Spacing_100};

    .icon-end {
      margin: 0 ${settings.Spacing.Spacing_100} 0 ${settings.Spacing.Spacing_100};
    }
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-color: ${settings.colors.Monochromatic.White};
  color: ${settings.colors.Primary.Blue_9};
  font-family: ${settings.typography.FontFamily.Bold};
  margin-right: ${settings.Spacing.Spacing_100};
  border-radius: 3px;
`;

const FullStyledButton = styled(StyledButton)`
  color: ${settings.colors.Primary.Grey_2};
`;

const HeaderBar = styled.div`
  height: 25px;
  border-bottom: 1px solid ${settings.colors.Primary.Grey_4};
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  padding: 0 ${settings.Spacing.Spacing_200} 0 ${settings.Spacing.Spacing_200};
`;

const Banner = styled.div<{ visible: boolean }>`
  position: absolute;
  width: 375px;
  display: flex;
  z-index: 1051;
  background: white;
  border: 1px solid ${settings.colors.Primary.Grey_3};
  flex-wrap: nowrap;
  color: ${settings.colors.Primary.Grey_8};
  align-items: flex-start;
  justify-content: center;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.visible ? 1 : 0)};

  .ant-btn[disabled] {
    cursor: initial;
  }
`;

const Name = styled.div<{ active: boolean }>`
  font-size: 14px;
  color: ${(props) => (props.active ? settings.colors.Primary.Blue_9 : settings.colors.Primary.Grey_8)};
`;

const DescriptionTitle = styled(Name)`
  color: ${settings.colors.Primary.Grey_8};
  font-family: ${settings.typography.FontFamily.Bold};
  margin: 10px ${settings.Spacing.Spacing_200} 6px ${settings.Spacing.Spacing_200};
`;

const DescriptionHeader = styled.div`
  background: ${settings.colors.Primary.Grey_1};
`;

const Description = styled.div`
  font-size: 12px;
  padding: 0 ${settings.Spacing.Spacing_200} ${settings.Spacing.Spacing_150} ${settings.Spacing.Spacing_200};
  color: ${settings.colors.Primary.Grey_8};
`;

const Container = styled('div').withConfig<{ loading: boolean }>({
  shouldForwardProp: (prop) => !['loading'].includes(prop),
})`
  pointer-events: ${(props) => (props.loading ? 'none' : 'auto')};
  max-width: 375px;

  .list-sort-demo-wrapper {
    position: relative;
    max-height: calc(100vh - 52px - 48px - 400px); /* Subtract navbar height, strip height, and allocate extra room for notifiacations */
    overflow-y: auto;
  }

  .list-sort-demo {
    width: 100%;
    cursor: pointer;
    position: relative;
    overflow: auto;
  }

  .list-sort-demo > div {
    overflow: hidden;
  }

  .list-sort-demo-icon {
    width: 20%;
    display: inline-block;
    text-align: center;
    font-size: 24px;
    line-height: 50px;
    vertical-align: top;
  }

  .list-sort-demo-text {
    flex: auto;
    padding: ${settings.Spacing.Spacing_150} ${settings.Spacing.Spacing_100};
    display: inline-block;
    color: ${settings.colors.Primary.Blue_9};
    max-width: 85%;
  }
`;

const Nest = styled.div`
  display: flex;
  justify-content: end;
`;

export default AreasSelectionPanel;
