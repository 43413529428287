import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const DetectionSimulatedIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none" {...rest}>
      <path fill={settings.colors.Operational.State_Blue_1} d="M.686 12 12 .686 23.313 12 12 23.313z" />
      <path
        fill={settings.colors.Operational.State_Blue_3}
        d="M2.1 13.414.684 12l1.414-1.415L3.513 12zM12 3.514 10.586 2.1 12 .686 13.414 2.1zM5.634 16.95l-2.12-2.121 1.413-1.414 2.122 2.121zM15.535 7.05l-2.12-2.122 1.413-1.414 2.122 2.122zM9.17 20.486l-2.122-2.122 1.414-1.414 2.122 2.121zM14.828 20.486l-1.414-1.414 2.121-2.122 1.414 1.414zM4.928 10.586 3.513 9.17l2.122-2.12 1.414 1.413zM18.363 16.95l-1.414-1.414 2.121-2.121 1.415 1.414zM8.463 7.05 7.049 5.635 9.17 3.514l1.414 1.414zM19.07 10.586l-2.12-2.121 1.413-1.414 2.122 2.12zM12 23.313 10.586 21.9 12 20.485l1.415 1.414zM21.899 13.414 20.484 12l1.415-1.414L23.313 12z"
      />
    </svg>
  );
};

DetectionSimulatedIcon.defaultProps = {
  size: '24',
};
