import { settings } from '@rhim/design';
import { Radio as AntdRadio, RadioChangeEvent, RadioGroupProps as RadioGroupPropsAntd, RadioProps, Space as SpaceAntd } from 'antd';
import { FC } from 'react';
import * as React from 'react';
import styled from 'styled-components';

import { FieldLabel, FieldLabelProps } from '../../partials';
import { TooltipProps } from '../Tooltip';

export type { RadioChangeEvent };

export const Space = SpaceAntd;

export enum SpaceDirection {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

interface Props extends RadioProps {}

interface RadioGroupProps extends RadioGroupPropsAntd {
  label?: FieldLabelProps;
  tooltip?: TooltipProps;
}

const Radio: React.FunctionComponent<React.PropsWithChildren<Props>> & { Group: typeof RadioGroup } = ({ children, ...props }) => {
  return <AntdRadio {...props}>{children}</AntdRadio>;
};

export const RadioGroup: FC<React.PropsWithChildren<RadioGroupProps>> = ({ children, ...props }) => {
  const { label, tooltip } = props;

  const StyledRadioGroup = <SAntdRadioGroup {...props}>{children}</SAntdRadioGroup>;

  return label ? (
    <SWrapper>
      <FieldLabel text={label.text} isRequired={label.isRequired} tooltip={tooltip} />
      <InputWrapper>{StyledRadioGroup}</InputWrapper>
    </SWrapper>
  ) : (
    StyledRadioGroup
  );
};

Radio.Group = RadioGroup;

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${settings.Spacing.Spacing_600};

  label.ant-radio-wrapper {
    margin-top: 0 !important;
  }
`;

const SAntdRadioGroup = styled(AntdRadio.Group)`
  label.ant-radio-wrapper {
    font-family: ${settings.typography.FontFamily.Regular};
    font-size: ${settings.typography.FontSize.Medium};
    color: ${settings.colors.Primary.Grey_7};

    &.ant-radio-wrapper-checked {
      color: ${settings.colors.Primary.Blue_9};
    }

    &:not(:first-of-type) {
      margin-top: ${settings.Spacing.Spacing_100};
    }

    span {
      line-height: 24px;
      vertical-align: middle;

      &.ant-radio {
        --color: ${settings.colors.Primary.Grey_6};

        .ant-radio-input:focus-visible + .ant-radio-inner {
          outline: 1px solid ${settings.colors.Primary.Grey_4};
        }

        .ant-radio-inner {
          border-color: var(--color);

          &::after {
            background-color: var(--color);
          }
        }

        &.ant-radio-checked {
          --color: ${settings.colors.Primary.Blue_9};

          .ant-radio-inner {
            background-color: ${settings.colors.Monochromatic.White};

            &::after {
              transform: scale(0.625);
            }
          }
        }
      }
    }
  }
`;

// Not using React.memo because we are using a static property (RadioGroup)
export default Radio;
