import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  height?: Property.Height;
  width?: Property.Width;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const DataTableIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ height, width, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 28 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <g>
              <path
                d="M23.457 2c.617 0 1.117.447 1.117 1v18c0 .553-.5 1-1.117 1H3.351c-.617 0-1.117-.447-1.117-1V3c0-.553.5-1 1.117-1zM22.34 8H12.287v12H22.34V8zM10.053 8H4.468v12h5.585V8zM7.82 16c.617 0 1.117.447 1.117 1 0 .553-.5 1-1.117 1H6.702c-.616 0-1.117-.447-1.117-1 0-.553.5-1 1.117-1zm11.17 0c.617 0 1.117.447 1.117 1 0 .553-.5 1-1.117 1h-3.35c-.617 0-1.118-.447-1.118-1 0-.553.5-1 1.117-1zM7.82 13c.617 0 1.117.447 1.117 1 0 .553-.5 1-1.117 1H6.702c-.616 0-1.117-.447-1.117-1 0-.553.5-1 1.117-1zm11.17 0c.618 0 1.117.447 1.117 1 0 .553-.5 1-1.117 1h-2.2c-.216 0-.406-.07-.577-.165-.05.026-.095.064-.15.085-.134.05-.279.08-.424.08-.145 0-.29-.03-.425-.08-.134-.05-.257-.12-.368-.21-.212-.19-.324-.44-.324-.71 0-.13.033-.26.09-.38.055-.12.133-.23.234-.33.352-.314.935-.345 1.366-.124.17-.096.362-.166.579-.166zm-1.117-3c.617 0 1.117.447 1.117 1 0 .553-.5 1-1.117 1h-1.117c-.207 0-.39-.064-.556-.151-.17.089-.355.151-.56.151-.302 0-.582-.101-.794-.29-.1-.101-.179-.21-.234-.33-.056-.12-.09-.25-.09-.38 0-.27.123-.52.324-.71.346-.309.924-.345 1.35-.136.166-.089.351-.154.56-.154zm-11.594.08c.413-.16.904-.07 1.217.21.201.19.324.45.324.71 0 .13-.033.26-.09.38-.055.12-.133.229-.234.33-.111.09-.234.16-.368.21-.134.05-.28.08-.425.08-.301 0-.58-.101-.793-.29-.1-.101-.179-.21-.235-.33-.067-.12-.089-.25-.089-.38 0-.26.123-.52.324-.71.112-.09.223-.16.369-.21zM10.053 4H4.468v2h5.585V4zM22.34 4H12.287v2H22.34V4z"
                transform="translate(-149 -159) translate(141 151) translate(8.936 8)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

DataTableIcon.defaultProps = {
  height: '24',
  width: '28',
  fill: settings.colors.Monochromatic.White,
};
