import { settings } from '@rhim/design';
import { InfoIcon } from '@rhim/icons/16';
import { isDefined } from '@rhim/utils';
import React from 'react';
import styled from 'styled-components';

import { Tooltip, TooltipProps } from '../components/Tooltip';
import NecessityIndicator from './NecessityIndicator';

export type FieldLabelProps = {
  text: React.ReactNode;
  isRequired?: boolean;
  isDisabled?: boolean;
  tooltip?: TooltipProps;
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
};

const FieldLabel: React.ChildlessComponent<FieldLabelProps> = ({ text, isRequired = false, isDisabled = false, tooltip, labelProps }) => {
  return (
    <SWrapper>
      <label {...labelProps}>
        {text}
        {isDefined(tooltip) && (
          <Tooltip {...tooltip}>
            <SInfoTooltipIconContainer>
              <InfoIcon />
            </SInfoTooltipIconContainer>
          </Tooltip>
        )}
      </label>
      {isRequired && !isDisabled && <NecessityIndicator />}
    </SWrapper>
  );
};
export default React.memo(FieldLabel);

const SWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${settings.Spacing.Spacing_300};
  white-space: nowrap;

  label {
    font-family: ${settings.typography.FontFamily.Medium};
    font-size: ${settings.typography.FontSize.X_Small};
    line-height: ${settings.typography.LineHeight.Line_Height_16};
    color: ${settings.colors.Primary.Grey_7};
    display: flex;
    align-items: center;
  }
`;

const SInfoTooltipIconContainer = styled.div`
  margin-left: ${settings.Spacing.Spacing_50};
  display: flex;
`;
