import { Progress } from '@rhim/react';
import { isEqual } from 'lodash';
import React, { useDeferredValue, useMemo } from 'react';

/**
 * Can be used in memoized components which are expensive/slow to render and which use as prop/dependency their parent's available size in order to
 * resize & render themselves ( e.g graphs ).
 * Returns a "renderingInProgressIndicator" which will either be a loading-spinner ( while rendering takes place ) or null ( when the rendering is done).
 * This "renderingInProgressIndicator" should be injected in the component's render function.
 * Returns a "deferredContainerSize" which should be used in the component as its size dependency.
 *
 * Briefly defers (postpones) the updating of the container's size, returning the old value to begin with in the initial render but
 * then follows up with a background re-render where the actual new value is used.
 *
 * When the component's parent resizes , the flow is :
 *
 * a) the component receives as a prop the new size of its parent
 * b) because of the useDeferredValue we briefly defer(postpone) using this new size and just use the old one
 * c) the (memoized) component's render function now executes fast because it "thinks" its parent's size has not changed
 *    (actually it has but because of the deferred value it still uses the "old" size) and
 *    since it is memoized it renders fast "as is"
 * d) during this fast render we take the opportunity to inject the loading-spinner in its DOM
 * e) React schedules one more background re-render but now uses the actual new value of the parent's size
 *    ( the "deferredContainerSize" will become identical to the "currentContainerSize")
 * f) the component now does a "proper" render (since its parent's size used as a dependency has now indeed changed and needs to resize itself) and
 *    it may take its time to complete ( but by now we have a loading-spinner shown for better UX )
 *
 * NOTE :
 *
 * Right after step e) the "shouldDisplayIndicator" below becomes false but this is OK :
 * the loading-spinner we initially injected will still be shown until the - slow - rendering completes and
 * its DOM gets updated (at which point the loading-spinner will get removed as well).
 */
export function useDeferredRenderingWithProgressIndicator(currentContainerSize: [number, number]) {
  const deferredContainerSize = useDeferredValue(currentContainerSize);

  const renderingInProgressIndicator: React.Node = useMemo(() => {
    const shouldDisplayIndicator = !isEqual(currentContainerSize, deferredContainerSize);
    return <Progress isVisible={shouldDisplayIndicator} />;
  }, [currentContainerSize, deferredContainerSize]);

  return { deferredContainerSize, renderingInProgressIndicator };
}
