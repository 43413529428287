import { RHIMAPOReportingWearManagementApiV1ModelsCrkZoneSetDto } from '@rhim/rest/wearManagement';
import { specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api/wearManagement';

type Payload = RHIMAPOReportingWearManagementApiV1ModelsCrkZoneSetDto;
type ErrorResponse = unknown;

export const useCrkZoneActiveSet = (
  vesselId: UUID,
  timestamp: string,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, ErrorResponse, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) => {
  return useQuery(getKey(vesselId, timestamp), (context) => queryFn(context, options?.axiosConfiguration), {
    select: (data) => ({
      ...data,
      crkZones: data.crkZones?.slice().sort((a, b) => a.rmFrom - b.rmFrom),
    }),
    ...options?.configuration,
  });
};

const getKey = (vesselId: UUID, timestamp: string) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'crkZones',
      scope: 'active-crkZoneSet',
      entity: 'detail',
      vesselId,
      timestamp,
    },
  ]);

const queryFn = ({ queryKey: [{ vesselId, timestamp }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  return API.crkZones.getCrkzonesetVesselsVesselidCrkzonesetsActive(vesselId, timestamp, { signal, ...axiosConfiguration }).then((response) => response.data);
};

useCrkZoneActiveSet.getKey = getKey;
useCrkZoneActiveSet.queryFn = queryFn;
