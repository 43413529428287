import { AbstractMesh, Scene, Vector3 } from '@babylonjs/core';
import { CustomMaterial } from '@babylonjs/materials';
import { isDefined } from '@rhim/utils';

const UNIFORM_NAME_NUMBER_OF_PLANES = 'numberOfPlanes';
const UNIFORM_NAME_PLANES_POSITION = 'planesPositions';
const UNIFORM_NAME_PLANES_NORMAL = 'planesNormals';

// defines the maximum number of planes that can be used to highlight the parts
const MAX_NUMBER_OF_PLANES = 4;

export class PointCloudHeightRegistrationMaterial extends CustomMaterial {
  public constructor(name: string, scene: Scene, private selectingUpperPart = true) {
    super(name, scene);
    this.setupMaterial();
    this.backFaceCulling = false;
  }

  public setHeight(heightInMeters: number) {
    const direction = this.selectingUpperPart ? 1 : -1;
    this.setPlanes([new Vector3(0, 0, heightInMeters)], [new Vector3(0, 0, direction)]);
  }

  /**
   * Set the plane that will be used to highlight the part defined by its normal
   *
   * @param position
   * @param normal
   */
  public setPlanes(positions: Vector3[], normals: Vector3[]) {
    const positionsFlat = positions.flatMap((position) => position.asArray());
    const normalsFlat = normals.flatMap((normal) => normal.asArray());
    this.onBindObservable.addOnce(() => {
      this.getEffect().setInt(UNIFORM_NAME_NUMBER_OF_PLANES, positions.length);
      this.getEffect().setArray3(UNIFORM_NAME_PLANES_POSITION, positionsFlat);
      this.getEffect().setArray3(UNIFORM_NAME_PLANES_NORMAL, normalsFlat);
    });
  }

  private setupMaterial() {
    this.Fragment_Definitions(`
        const vec4 HIGHLIGHT_COLOR = vec4(1.0, 0.0, 0.0, 1.0);
        const float HIGHLIGHT_COLOR_TRANSPARENCY = 0.25;

        float distanceToPlane(vec3 point, vec3 planeOrigin, vec3 planeNormal) {
            return dot(planeNormal, point - planeOrigin);
        }
    `);

    this.Fragment_Custom_Diffuse(`
        float highlightColorLevel = 0.0;

        for (int i = 0; i < ${UNIFORM_NAME_NUMBER_OF_PLANES}; i++) {
            vec3 origin = ${UNIFORM_NAME_PLANES_POSITION}[i];
            vec3 normal = ${UNIFORM_NAME_PLANES_NORMAL}[i];
            highlightColorLevel = max(highlightColorLevel, step(0.0, distanceToPlane(vPositionW, origin, normal)));
        }

        // float distance = distanceToPlane(vPositionW, origin, normal);
        // float highlightColorLevel = step(0.0, distance);
        baseColor = mix(baseColor, HIGHLIGHT_COLOR, highlightColorLevel * HIGHLIGHT_COLOR_TRANSPARENCY);
    `);

    // this.AddUniform(UNIFORM_NAME_HEIGHT, 'float', 0);
    this.AddUniform(UNIFORM_NAME_NUMBER_OF_PLANES, 'int', 0);
    this.AddUniform(UNIFORM_NAME_PLANES_POSITION, `vec3[${MAX_NUMBER_OF_PLANES}]`, []);
    this.AddUniform(UNIFORM_NAME_PLANES_NORMAL, `vec3[${MAX_NUMBER_OF_PLANES}]`, []);
  }
}

export function assignMaterialToMesh(mesh: AbstractMesh, scene: Scene, selectingUpperPart?: boolean) {
  let material = scene.getMaterialByName('pointCloudHeightRegistrationMaterial');
  if (!isDefined(material)) {
    material = new PointCloudHeightRegistrationMaterial('pointCloudHeightRegistrationMaterial', scene, selectingUpperPart);
  }
  mesh.material = material;
}
