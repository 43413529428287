import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const DataPointIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 8 8" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path fillRule="evenodd" clipRule="evenodd" d="M6 0H2v2H0v4h2v2h4V6h2V2H6V0z" fill="#fff" />
      <path fillRule="evenodd" clipRule="evenodd" d="M5 1H3v2H1v2h2v2h2V5h2V3H5V1z" fill={fill} />
    </svg>
  );
};

DataPointIcon.defaultProps = {
  size: '8',
  fill: settings.colors.Primary.Blue_8,
};
