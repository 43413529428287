import { settings } from '@rhim/design';
import { assert, isDefined } from '@rhim/utils';
import React, { FC } from 'react';

import Canvas from './Canvas';
import { DataItem, drawRect, getGridCellLayout, WallplotLayout } from './utils';

interface CellHighlightingCanvasProps {
  wallplotLayout: WallplotLayout;
  wallplotData: DataItem[];
  highlightValueMinimum: number | null;
  highlightValueMaximum: number | null;
  highlightCellId: number | null;
}
const CellHighlightingCanvas: FC<React.PropsWithChildren<CellHighlightingCanvasProps>> = ({
  wallplotLayout,
  wallplotData,
  highlightValueMinimum,
  highlightValueMaximum,
  highlightCellId,
}) => {
  const highlightCells = (canvas2DContext: CanvasRenderingContext2D) => {
    if (!isDefined(highlightValueMinimum) && !isDefined(highlightValueMaximum) && !isDefined(highlightCellId)) {
      return;
    }
    const { metrics, gridLayout } = wallplotLayout;

    const imageData = canvas2DContext.createImageData(metrics.canvasWidth, metrics.canvasHeight);
    const imageDataBuffer = new Uint32Array(imageData.data.buffer);

    canvas2DContext.save();
    canvas2DContext.strokeStyle = settings.colors.Primary.Blue_9;

    const strokeColor = { red: 0, green: 50, blue: 98 }; // --rhim_color_pr_blue_9
    if (isDefined(highlightValueMinimum) && isDefined(highlightValueMaximum)) {
      for (const dataItem of wallplotData) {
        if (!isDefined(dataItem.rbl) || dataItem.rbl < highlightValueMinimum || dataItem.rbl > highlightValueMaximum) {
          continue;
        }
        const cellLayoutItem = getGridCellLayout(gridLayout, dataItem.angle, dataItem.depth);
        if (!cellLayoutItem) {
          throw new Error(`Wallplot layout item not found for domainX : ${dataItem.angle} and domainY : ${dataItem.depth}`);
        }
        drawRect(strokeColor, metrics.canvasWidth, imageDataBuffer, cellLayoutItem.startX, cellLayoutItem.startY, cellLayoutItem.width, cellLayoutItem.height);
      }
    } else if (isDefined(highlightCellId)) {
      const dataItem = wallplotData.find((item) => item.id === highlightCellId);
      assert(isDefined(dataItem), `Cell with id ${highlightCellId} not found in the wallplot data`);
      const cellLayoutItem = getGridCellLayout(gridLayout, dataItem.angle, dataItem.depth);
      assert(isDefined(cellLayoutItem), `Cell with id ${highlightCellId} not found in the gridLayout`);
      drawRect(strokeColor, metrics.canvasWidth, imageDataBuffer, cellLayoutItem.startX, cellLayoutItem.startY, cellLayoutItem.width, cellLayoutItem.height);
    }

    canvas2DContext.putImageData(imageData, 0, 0);
    canvas2DContext.restore();
  };

  return <Canvas draw={highlightCells} width={wallplotLayout.metrics.canvasWidth} height={wallplotLayout.metrics.canvasHeight} />;
};

export default React.memo(CellHighlightingCanvas);
