import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const HomeIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M21.555 8.168l-9-6c-.336-.225-.773-.225-1.11 0l-9 6c-.366.245-.53.7-.402 1.123.128.421.517.71.957.71h1v11c0 .552.448 1 1 1h4v-8c0-.553.448-1 1-1h4c.553 0 1 .447 1 1v8h4c.553 0 1-.448 1-1V10h1c.44 0 .83-.288.957-.71.128-.423-.035-.879-.402-1.123"
      />
    </svg>
  );
};

HomeIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
