import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const HexagonLadleIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <path
              d="M29.03 24.732c.533-.31.97-1.075.97-1.697V8.965c0-.622-.437-1.386-.97-1.697L16.97.233c-.533-.31-1.407-.31-1.94 0L2.97 7.268C2.437 7.58 2 8.343 2 8.966v14.069c0 .622.437 1.386.97 1.697l12.06 7.035c.533.31 1.407.31 1.94 0l12.06-7.035zM23.015 8l.109.01c.548.067.936.567.868 1.115l-.619 4.951c.367.148.627.505.627.924v2c0 .553-.448 1-1 1-.041 0-.075-.018-.114-.023l-.644 5.147c-.062.5-.488.876-.992.876h-10.5c-.504 0-.93-.376-.992-.876l-.644-5.147C9.075 17.982 9.04 18 9 18c-.552 0-1-.447-1-1v-2c0-.419.26-.776.627-.924l-.619-4.95c-.069-.549.32-1.049.868-1.117.545-.075 1.048.32 1.116.868L11.633 22h8.734l1.641-13.123c.068-.549.567-.943 1.116-.868zM12.934 9.001c.876 0 1.645.154 2.535.332 1.046.209 2.229.446 3.687.446.289 0 .576-.007.862-.02l-1.072 10.908h-5.801l-1.162-11.63c.294-.022.612-.036.95-.036z"
              transform="translate(-229 -1115) translate(229 1115)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

HexagonLadleIcon.defaultProps = {
  size: '32',
  fill: settings.colors.Primary.Grey_6,
};
