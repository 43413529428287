import { RHIMAPOReportingWearManagementApiV1ModelsCampaignOverviewDto } from '@rhim/rest/wearManagement';
import { isDefined, specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api/wearManagement';

type Payload = RHIMAPOReportingWearManagementApiV1ModelsCampaignOverviewDto[];

export function useCampaignsOfCrk(
  vesselId: string | null,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) {
  return useQuery(getKey(vesselId), (context) => queryFn(context, options?.axiosConfiguration), {
    ...options?.configuration,
    enabled: isDefined(vesselId) && options?.configuration?.enabled,
  });
}

const getKey = (vesselId: string | null) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'wearManagement',
      scope: 'crk-campaigns',
      entity: 'list',
      vesselId,
    },
  ]);

const queryFn = ({ queryKey: [{ vesselId }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  return API.crkPredictions.getPredictionsCrkVesselidCampaigns(vesselId ?? '', { signal, ...axiosConfiguration }).then((response) => response.data);
};

useCampaignsOfCrk.getKey = getKey;
useCampaignsOfCrk.queryFn = queryFn;
