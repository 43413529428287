import { LeavePageWarningModal } from '@rhim/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  isUploading: boolean;
  onUploadingCancelled: () => void;
}
const LeavePageWhilstUploadingWarningModal: React.ChildlessComponent<Props> = ({ isUploading, onUploadingCancelled }) => {
  const { t } = useTranslation(['ingress']);

  const texts = useMemo(
    () => ({
      headline: t('ingress:leavePageWhilstUploadingWarningModal.title'),
      description: t('ingress:leavePageWhilstUploadingWarningModal.description'),
      buttonLeaveAndCancelLabel: t('ingress:leavePageWhilstUploadingWarningModal.buttonLeaveAndCancelUpload'),
      buttonStayOnPageLabel: t('ingress:leavePageWhilstUploadingWarningModal.buttonStayOnPage'),
    }),
    [t]
  );

  return <LeavePageWarningModal isOperationInProgress={isUploading} onOperationCancelled={onUploadingCancelled} texts={texts} />;
};

export default React.memo(LeavePageWhilstUploadingWarningModal);
