import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const LadleInactiveIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <g>
              <g>
                <path
                  d="M15.748 3.08l-.375 2.996c.367.148.627.505.627.925v2c0 .552-.448 1-1 1-.041 0-.075-.019-.114-.024l-.644 5.147c-.062.501-.488.876-.992.876H2.828l2-2h7.539l1.077-8.616 2.304-2.304zM15.707.293c.391.391.391 1.023 0 1.414l-14 14C1.512 15.902 1.256 16 1 16c-.256 0-.512-.098-.707-.293-.391-.391-.391-1.023 0-1.414l14-14c.391-.391 1.023-.391 1.414 0zm-4.24 7.068l-.52 5.306H6.16l5.306-5.306zM.876.009c.545-.074 1.048.32 1.116.868l1.145 9.158-1.791 1.791-.232-1.848C1.075 9.982 1.041 10 1 10c-.552 0-1-.447-1-1V7.001c0-.42.26-.776.627-.925L.008 1.125C-.061.577.328.077.876.009zm4.058.992c.876 0 1.645.154 2.535.332 1.046.21 2.229.446 3.687.446.08 0 .162-.005.243-.006L4.723 8.45l-.741-7.412c.294-.023.613-.036.952-.036z"
                  transform="translate(-587 -540) translate(563 245) translate(1 283) translate(23 12)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

LadleInactiveIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Grey_6,
};
