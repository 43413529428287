import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const VesselSlagSpotIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.297 3.033a1 1 0 0 0-.978.274l-.24.25a1 1 0 0 0-.243.956l.09.33a1 1 0 0 0 .702.702l.33.09a1 1 0 0 0 .955-.244l.25-.24a1 1 0 0 0 .275-.977l-.09-.34a1 1 0 0 0-.711-.71l-.34-.09zM.529 7.258a1 1 0 0 1 1.353.411L3.67 11.02h8.66l1.788-3.35a1 1 0 0 1 1.764.94l-2.07 3.88a1 1 0 0 1-.882.53H3.07a1 1 0 0 1-.882-.53L.118 8.61a1 1 0 0 1 .411-1.352zm9.272-.748a1 1 0 0 0-.866.5l-.41.71a1 1 0 0 0-.003.995l.41.72a1 1 0 0 0 .869.505h.83a1 1 0 0 0 .869-.505l.41-.72a1 1 0 0 0-.003-.995l-.41-.71a1 1 0 0 0-.866-.5H9.8zm-3.562-.335a1 1 0 0 0-.949-.253l-.62.16a1 1 0 0 0-.715.708l-.17.63a1 1 0 0 0 .258.967l.46.46a1 1 0 0 0 .971.257l.62-.17a1 1 0 0 0 .701-.703l.17-.63a1 1 0 0 0-.266-.976l-.46-.45z"
      />
    </svg>
  );
};

VesselSlagSpotIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
