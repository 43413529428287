import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const Surface3DIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.836 2.253A1 1 0 0 1 7.5 2H21a1 1 0 0 1 1 1v14.5a1 1 0 0 1-.341.753l-3.975 3.477A.997.997 0 0 1 17 22H3a1 1 0 0 1-1-1V7a1.01 1.01 0 0 1 .016-.18.996.996 0 0 1 .32-.567l4.5-4zM20 17.046l-3.377 2.955H4V8h13a1 1 0 0 0 .707-.293L20 5.415v11.631zM7.88 4h10.707l-2.001 2H5.629L7.88 4zm-.167 7.437c-.547 0-.963.397-1.105 1.071l-1.564-.397C5.36 10.796 6.378 10 7.757 10c1.477 0 2.516.895 2.516 2.177 0 .773-.372 1.348-1.072 1.68v.022c.81.364 1.247.994 1.247 1.834 0 1.337-1.116 2.287-2.68 2.287C6.324 18 5.23 17.182 5 15.912l1.619-.32c.12.596.558.972 1.138.972.601 0 1.028-.41 1.028-.973 0-.608-.47-.994-1.225-.994h-.394V13.27h.449c.612 0 .995-.365.995-.929 0-.54-.361-.905-.897-.905zm6.447.088h-.798v4.795h.798c1.335 0 2.133-.895 2.133-2.397 0-1.514-.787-2.398-2.133-2.398zm-.01 6.32h-2.495V10h2.494C16.512 10 18 11.514 18 13.923c0 2.397-1.488 3.922-3.85 3.922z"
      />
    </svg>
  );
};

Surface3DIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Grey_2,
};
