import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const TemperaturePrediciton: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5a5 5 0 0 1 10 0v9.277c.632.992 1 2.182 1 3.462C14 21.197 11.314 24 8 24s-6-2.803-6-6.26c0-1.28.368-2.471 1-3.463V5zm2 0a3 3 0 0 1 6 0v10.004c.622.743 1 1.718 1 2.786C12 20.114 10.21 22 8 22s-4-1.885-4-4.21c0-1.068.378-2.043 1-2.786V5z"
        fill={fill}
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M7 5a1 1 0 0 1 2 0v11.268A2 2 0 0 1 8 20a2 2 0 0 1-1-3.732V5z" fill={fill} />
      <circle cx="18" cy="5" r="2" fill={fill} />
      <circle cx="21" cy="10" r="2" fill={fill} />
      <circle cx="19" cy="15" r="2" fill={fill} />
      <path fill={fill} d="M12 4h6v2h-6zM12 9h9v2h-9zM12 14h7v2h-7z" />
    </svg>
  );
};

TemperaturePrediciton.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
