/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from 'react';

import { DefaultOptions, QueryClient, QueryClientProvider, useQueryClient } from '@tanstack/react-query';
import { merge } from 'lodash';

import { withProps } from '@rhim/react';

interface Props {
  overrides: DefaultOptions;
}

/**
 * `ReactQueryConfigProvider` is an optional provider component and can be used to define defaults for all instances of `useQuery` within it's sub-tree.
 *
 * It was removed in v3, hence the need to re-implement it.
 *
 * @example
 *
 * ```tsx
 * <ReactQueryConfigProvider overrides={{ staleTime: 60 * 1000 }}>
 *   <CustomerTimeZone />
 * </ReactQueryConfigProvider>
 * ```
 *
 * @see https://react-query-v2.tanstack.com/docs/api#reactqueryconfigprovider
 * @see https://codesandbox.io/s/react-query-config-provider-v3-lt00f
 */
export const ReactQueryConfigProvider: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ children, overrides }) => {
  const queryClient = useQueryClient();
  const defaultOptions = queryClient.getDefaultOptions();

  const [newClient] = React.useState(
    () =>
      new QueryClient({
        queryCache: queryClient.getQueryCache(),
        mutationCache: queryClient.getMutationCache(),
        defaultOptions: merge({}, defaultOptions, overrides),
      })
  );

  return React.createElement(QueryClientProvider, { client: newClient }, children);
};

/**
 * @example
 *
 * ```tsx
 * <Policies.RefetchPeriodically intervalMs={1000}>
 *   <ChatWidget />
 * </Policies.RefetchPeriodically>
 * ```
 */
export namespace Policies {
  export const RefetchPeriodically: React.FunctionComponent<React.PropsWithChildren<{ intervalMs: number; children: React.ReactNode }>> = ({
    children,
    intervalMs,
  }) => {
    return React.createElement(
      ReactQueryConfigProvider,
      {
        overrides: {
          queries: {
            refetchInterval: intervalMs,
          },
        },
      },
      children
    );
  };

  export const AlwaysRefetch = withProps(ReactQueryConfigProvider, {
    overrides: {
      queries: {
        refetchOnMount: 'always',
        refetchOnWindowFocus: 'always',
      },
    },
  });

  export const NeverRefetch = withProps(ReactQueryConfigProvider, {
    overrides: {
      queries: {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      },
    },
  });

  export const Suspend = withProps(ReactQueryConfigProvider, {
    overrides: {
      queries: {
        suspense: true,
      },
    },
  });
}
