import { settings } from '@rhim/design';
import { assert, isDefined } from '@rhim/utils';
import React, { FC, useCallback, useEffect, useRef } from 'react';

import { useLocalization } from '../../hooks';
import { convertLength, MetricLengthUnit } from '../../partials';
import { BorderRadius, CellLayout, DataItem, getLabelMetrics, isBorderRadius, LabelMetrics, roundRect, SETTINGS, WallplotLayout } from './utils';

export const fillRoundedRectWithLabel = (
  canvas2DContext: CanvasRenderingContext2D,
  labelMetrics: LabelMetrics,
  label: string,
  labelCenterX: number,
  labelCenterY: number,
  borderRadius: number | BorderRadius
) => {
  canvas2DContext.save();

  // paint a rounded rect that will contain the label
  roundRect(
    canvas2DContext,
    labelCenterX - labelMetrics.halfWidth - SETTINGS.AXIS.ROUNDED_LABEL_PADDING_HORIZONTAL,
    labelCenterY - labelMetrics.halfHeight - SETTINGS.AXIS.ROUNDED_LABEL_PADDING_VERTICAL,
    labelCenterX + labelMetrics.halfWidth + SETTINGS.AXIS.ROUNDED_LABEL_PADDING_HORIZONTAL,
    labelCenterY + labelMetrics.halfHeight + SETTINGS.AXIS.ROUNDED_LABEL_PADDING_VERTICAL,
    borderRadius
  );

  // draw the label
  canvas2DContext.textAlign = 'center';
  canvas2DContext.textBaseline = 'middle';
  canvas2DContext.fillStyle = 'white';
  const extraYOffset = isBorderRadius(borderRadius) ? borderRadius.topRightRadius / 2 : 0;
  canvas2DContext.fillText(label, labelCenterX, labelCenterY + extraYOffset);

  canvas2DContext.restore();
};

const fillRoundedRectWithLabelAndConnectToAxis = (
  canvas2DContext: CanvasRenderingContext2D,
  labelMetrics: LabelMetrics,
  label: string,
  labelCenterX: number,
  labelCenterY: number,
  connectingLineWidth: number,
  connectingLineHeight: number
) => {
  // draw the connecting line to the axis
  canvas2DContext.save();
  canvas2DContext.lineWidth = SETTINGS.AXIS.ROUNDER_LABEL_LINE_CONNECTING_TO_AXIS_STROKE;
  canvas2DContext.strokeRect(labelCenterX, labelCenterY, connectingLineWidth, connectingLineHeight);
  canvas2DContext.restore();

  // paint a rounded filled rectangle with a label insite it
  fillRoundedRectWithLabel(canvas2DContext, labelMetrics, label, labelCenterX, labelCenterY, SETTINGS.AXIS.ROUNDED_LABEL_RADIUS);
};

interface CellHoveringCanvasProps {
  wallplotLayout: WallplotLayout;
  wallplotData: DataItem[];
  mouseX: number;
  mouseY: number;
  onCellHovered: (dataItem: DataItem | null, cellLayout: CellLayout | null) => void;
}
const CellHoveringCanvas: FC<React.PropsWithChildren<CellHoveringCanvasProps>> = ({ wallplotLayout, wallplotData, mouseX, mouseY, onCellHovered }) => {
  const [localization] = useLocalization();
  const { unitSystem, locale } = localization;
  const domCanvasRef = useRef<HTMLCanvasElement>(null);

  const highlightHoveredCell = useCallback(
    (canvas2DContext: CanvasRenderingContext2D, mouseX: number, mouseY: number) => {
      const { wallplotMargin, metrics, gridLayout } = wallplotLayout;
      // clear canvas
      canvas2DContext.clearRect(0, 0, metrics.canvasWidth, metrics.canvasHeight);

      canvas2DContext.save();
      canvas2DContext.strokeStyle = settings.colors.Primary.Grey_8;
      let hoveredDataItem = null;
      let hoveredCell = null;
      for (const cellLayoutItem of gridLayout) {
        if (
          !(
            mouseX >= cellLayoutItem.startX &&
            mouseX <= cellLayoutItem.startX + cellLayoutItem.width &&
            mouseY >= cellLayoutItem.startY &&
            mouseY <= cellLayoutItem.startY + cellLayoutItem.height
          )
        ) {
          continue;
        }
        hoveredDataItem = wallplotData.find((dataItem) => dataItem.angle === cellLayoutItem.domainValueX && dataItem.depth === cellLayoutItem.domainValueY);
        hoveredCell = cellLayoutItem;
        assert(isDefined(hoveredDataItem), 'Wallplot data item not found');
        // draw a border around the hovered cell
        canvas2DContext.strokeRect(cellLayoutItem.startX, cellLayoutItem.startY, cellLayoutItem.width, cellLayoutItem.height);

        canvas2DContext.font = '12px nortw05-medium';

        // x axis rounder-rect with label and line connecting it to the x-axis
        const xAxisLabel = `${hoveredDataItem.angle + metrics.domainXStep / 2}°`;
        const xAxisLabelMetrics = getLabelMetrics(canvas2DContext, xAxisLabel);
        const xAxisLabelCenterX = cellLayoutItem.startX + cellLayoutItem.width / 2;
        const xAxisLabelCenterY = wallplotMargin.top + metrics.plotHeight + SETTINGS.AXIS.TICK_LENGTH_PX + SETTINGS.AXIS.TICK_LABEL_DISTANCE_PX;
        fillRoundedRectWithLabelAndConnectToAxis(
          canvas2DContext,
          xAxisLabelMetrics,
          xAxisLabel,
          xAxisLabelCenterX,
          xAxisLabelCenterY,
          1,
          -(xAxisLabelCenterY - (wallplotMargin.top + metrics.plotHeight))
        );

        // y axis rounder-rect with label and line connecting it to the y-axis
        const yAxisLabel = convertLength(hoveredDataItem.depth + metrics.domainYStep / 2, MetricLengthUnit.m, unitSystem, undefined, 2, locale, true);
        const yAxisLabelMetrics = getLabelMetrics(canvas2DContext, yAxisLabel);
        const yAxisLabelCenterX = wallplotMargin.left - SETTINGS.AXIS.TICK_LENGTH_PX - SETTINGS.AXIS.TICK_LABEL_DISTANCE_PX - yAxisLabelMetrics.halfWidth;
        const yAxisLabelCenterY = cellLayoutItem.startY + cellLayoutItem.halfHeight;
        fillRoundedRectWithLabelAndConnectToAxis(
          canvas2DContext,
          yAxisLabelMetrics,
          yAxisLabel,
          yAxisLabelCenterX,
          yAxisLabelCenterY,
          wallplotMargin.left - yAxisLabelCenterX,
          1
        );

        break;
      }
      canvas2DContext.restore();

      return { hoveredDataItem, hoveredCell };
    },
    [wallplotData, wallplotLayout, unitSystem, locale]
  );

  useEffect(() => {
    const canvasDom = domCanvasRef.current;
    if (!canvasDom) {
      return;
    }
    const canvas2DContext = canvasDom.getContext('2d');
    if (!canvas2DContext) {
      return;
    }
    const { hoveredDataItem, hoveredCell } = highlightHoveredCell(canvas2DContext, mouseX, mouseY);
    onCellHovered(hoveredDataItem, hoveredCell);
  }, [wallplotLayout, highlightHoveredCell, mouseX, mouseY, onCellHovered]);

  return <canvas ref={domCanvasRef} width={wallplotLayout.metrics.canvasWidth} height={wallplotLayout.metrics.canvasHeight}></canvas>;
};
export default React.memo(CellHoveringCanvas);
