import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const TimeWatchIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.93 6.299 8.596 8.965a.666.666 0 1 1-1.192-.596l1.333-2.667a.667.667 0 0 1 1.193.597zm3.208-1.16a.666.666 0 1 0-.943-.943l-.57.57a5.326 5.326 0 0 0-2.959-1.385v-.714h.667a.666.666 0 1 0 0-1.333H6.666a.666.666 0 1 0 0 1.333h.667v.714a5.326 5.326 0 0 0-2.958 1.385l-.57-.57a.666.666 0 1 0-.943.943l.653.652a5.302 5.302 0 0 0-.849 2.876A5.34 5.34 0 0 0 8 14.001a5.34 5.34 0 0 0 5.333-5.334c0-1.059-.314-2.045-.848-2.876l.653-.652z"
      />
    </svg>
  );
};

TimeWatchIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Grey_6,
};
