import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const VolumeDashboardIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" role={role} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h8.803A6 6 0 1 0 23 14.682V6a3 3 0 0 0-3-3H4zm17 9.803V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8.083A6 6 0 0 1 21 12.803zM5 16a1 1 0 0 1 1-1h4a1 1 0 0 1 0 2H6a1 1 0 0 1-1-1zm9.861-9.675a1 1 0 0 1 1.327-.133l2.4 1.75a1 1 0 0 1-1.178 1.616l-1.677-1.223-3.396 3.715a1 1 0 0 1-1.372.098l-2.28-1.87-1.814 3.214a1 1 0 0 1-1.742-.984l2.4-4.25a1 1 0 0 1 1.504-.281L11.501 10l3.36-3.676zm3.11 13.729-1.627-5.327h-1.967L16.831 22h2.34l2.454-7.273h-1.968l-1.626 5.327h-.06z"
        fill={fill}
      />
    </svg>
  );
};

VolumeDashboardIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Grey_4,
};
