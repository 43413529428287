import { RHIMLiningServiceV1ModelsAggregatedLiningRegionDto } from '@rhim/rest/linings';
import { assert, isDefined, specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api/linings';

type Payload = RHIMLiningServiceV1ModelsAggregatedLiningRegionDto[];

export function useLiningsAggregate(
  vesselId: string,
  requestedDate?: string,
  campaign?: number,
  // configuration?: Partial<QueryObserverOptions<RHIMLiningServiceV1ModelsAggregatedLiningRegionDto[]>>,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) {
  assert(
    isDefined(vesselId),
    `${useLiningsAggregate.name} requires a vesselId, but value was ${vesselId}. Use a valid id, or use \`options.enabled\` to pause the query until all requirements are met. See https://react-query.tanstack.com/guides/disabling-queries for instructions.`
  );

  return useQuery(getKey(vesselId, requestedDate, campaign), (context) => queryFn(context, options?.axiosConfiguration), options?.configuration);
}

const getKey = (vesselId: string, releaseDate?: string, campaignId?: number) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'linings',
      scope: 'aggregate',
      entity: 'list',
      vesselId,
      releaseDate,
      campaignId,
    },
  ]);

const queryFn = (
  { queryKey: [{ vesselId, releaseDate, campaignId }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>,
  axiosConfiguration?: AxiosRequestConfig
) => {
  return API.linings.getVesselsVesselidAggregate(vesselId, releaseDate, campaignId, { signal, ...axiosConfiguration }).then((response) => response.data);
};

useLiningsAggregate.getKey = getKey;
useLiningsAggregate.queryFn = queryFn;
