import { settings } from '@rhim/design';
import { FileCheckmarkIcon as FileCheckmarkSVG, TrashIcon as TrashSVG } from '@rhim/icons/24';
import { deleteButtonPointCloudScansDataUploadContainer, doneMessageUploadContainer } from '@rhim/test-ids';
import { isDefined } from '@rhim/utils';
import * as React from 'react';
import styled from 'styled-components';

const FileCheckmarkIcon = <FileCheckmarkSVG fill={settings.colors.Operational.State_Green_2} />;
const TrashIcon = <TrashSVG fill={settings.colors.Primary.Blue_9} />;

interface Props {
  description: string;
  fileName: string;
  onDelete: React.UIEventHandler<HTMLDivElement>;
  showDeleteIcon: boolean;
  width?: number;
}

export const Success: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fileName, description, width, onDelete, showDeleteIcon }) => {
  return (
    <Wrapper width={width}>
      <Centerer>
        <IconContainer>{FileCheckmarkIcon}</IconContainer>
      </Centerer>
      <Verdict>
        <Filename>{fileName}</Filename>
        <Confirmation data-test-id={doneMessageUploadContainer}>{description}</Confirmation>
      </Verdict>
      {showDeleteIcon && (
        <Centerer>
          <Delete onClick={onDelete} role="button" data-test-id={deleteButtonPointCloudScansDataUploadContainer}>
            <Target>{TrashIcon}</Target>
          </Delete>
        </Centerer>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ width?: number }>`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${settings.colors.Primary.Grey_4};
  width: ${(props) => (isDefined(props.width) ? `${props.width}px` : 'auto')};
`;

const Centerer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled.div`
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Verdict = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0;
  flex-grow: 1;
`;

const Filename = styled.span`
  color: ${settings.colors.Primary.Grey_8};
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.Small};
  word-break: break-all;
`;

const Confirmation = styled.span`
  color: ${settings.colors.Operational.State_Green_2};
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.Small};
`;

const Delete = styled.div`
  height: 100%;
  width: 72px;
  display: flex;
  border-left: 1px solid ${settings.colors.Primary.Grey_2};
  justify-content: center;
  align-items: center;
`;

const Target = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
