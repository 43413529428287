import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const VesselAODIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="m10.681.459 3.4 5.096a1 1 0 0 1 .169.555v.999A1 1 0 0 1 16 7.77v2.33a1 1 0 0 1-1.75.662v.588a1 1 0 0 1-.109.454l-1.86 3.65a1 1 0 0 1-.891.546H4.61a1 1 0 0 1-.891-.546l-1.86-3.65a1 1 0 0 1-.109-.454v-.588A1 1 0 0 1 0 10.1V7.77a1 1 0 0 1 1.75-.661V6.11a1 1 0 0 1 .102-.44l1.1-2.24a1 1 0 1 1 1.796.881L3.75 6.342v2.694c.35-.049.79-.086 1.32-.086.777 0 1.95.289 3.106.573 1.12.276 2.225.547 2.934.547.454 0 .833-.03 1.14-.073V6.413L9.543 2.356 9 2.69v4.18a1 1 0 1 1-2 0V2.35c0-.037.002-.074.006-.11a1 1 0 0 1 .47-.962L9.308.154a.996.996 0 0 1 1.374.305z"
      />
    </svg>
  );
};

VesselAODIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
