import { RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizedFileStructureDto } from '@rhim/rest/fileIngestion';
import { specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api';

type Payload = RHIMFileIngestionServiceV1HarmonizationConfigurationHarmonizedFileStructureDto[];

export const useHarmonizedFiletypes = (
  id: UUID,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) => {
  return useQuery(getKey(id), (context) => queryFn(context, options?.axiosConfiguration));
};

const getKey = (id: UUID) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'harmonization',
      scope: 'customerFileTypeStructure',
      entity: 'list',
      id,
    },
  ]);

const queryFn = ({ signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  return API.harmonization.getHarmonizedFileTypes({ signal, ...axiosConfiguration }).then((response) => response.data);
};

useHarmonizedFiletypes.getKey = getKey;
useHarmonizedFiletypes.queryFn = queryFn;
