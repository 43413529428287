import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const PlusIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M15 7H9V1c0-.553-.448-1-1-1S7 .447 7 1v6H1c-.552 0-1 .447-1 1 0 .553.448 1 1 1h6v6c0 .553.448 1 1 1s1-.447 1-1V9h6c.552 0 1-.447 1-1 0-.553-.448-1-1-1"
      />
    </svg>
  );
};

PlusIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
