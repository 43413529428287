import { settings } from '@rhim/design';
import { Popover, PopoverProps } from '@rhim/react';
import { FC } from 'react';
import styled from 'styled-components';

interface Props extends PopoverProps {
  className?: string;
}

const MyPopover: FC<React.PropsWithChildren<Props>> = ({ className, ...props }) => <Popover {...props} overlayClassName={className} />;

export const SPopover = styled(MyPopover)`
  width: 220px;

  --borderRadius: 3px;

  div.ant-popover-arrow {
    display: none;
  }

  div.ant-popover-inner {
    border: solid 1px ${settings.colors.Primary.Grey_3};
    border-top-left-radius: var(--borderRadius);
    border-top-right-radius: var(--borderRadius);
  }

  div.ant-popover-title {
    min-height: auto;
    padding: 4px 4px 3px 7px;
    background-color: ${settings.colors.Primary.Grey_2};
  }

  div.ant-popover-inner-content {
    padding: 0;
  }
`;
