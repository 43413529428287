import { settings } from '@rhim/design';
import { ArrowsCompareIcon } from '@rhim/icons/24';
import { RHIMMeasurementServiceV1ModelsCampaignDto } from '@rhim/rest/measurementService';
import { campaignCompareIconParametersComparison, headerParametersComparison } from '@rhim/test-ids';
import { hasElements, isDefined } from '@rhim/utils';
import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import CampaignSelection, { ColorTheme } from '../../components/CampaignSelectionDropdown/CampaignSelection';
import VesselSelection from '../../components/VesselSelectionDropdown/VesselSelection';
import { CAMPAIGN_NR_NOT_SET } from '../../hooks';

const FIRST_ROW_COLOR = settings.colors.Primary.Grey_10;
const SECOND_ROW_COLOR = settings.colors.Primary.Grey_9;

interface Props {
  setSelectedVesselId: (id: UUID) => void;
  selectedVesselId: UUID;
  vessels: APO.VesselV2[];
  isLoading: boolean;
  campaigns: RHIMMeasurementServiceV1ModelsCampaignDto[];
  comparedCampaigns: RHIMMeasurementServiceV1ModelsCampaignDto[];
  isCampaignLoading: boolean;
  campaignsToCompare: number[];
  setSelectedComparedVesselId: (id: UUID) => void;
  selectedComparedVesselId: UUID | null;
  setCampaignsToCompare: Dispatch<SetStateAction<Double<number>>>;
  tooltipFormatter?: (campaign: RHIMMeasurementServiceV1ModelsCampaignDto) => string;
}

const ParametersComparisonStrip = ({
  campaigns,
  comparedCampaigns,
  selectedVesselId,
  setSelectedVesselId,
  vessels,
  isLoading,
  isCampaignLoading,
  campaignsToCompare,
  setCampaignsToCompare,
  setSelectedComparedVesselId,
  selectedComparedVesselId,
  tooltipFormatter,
}: Props) => {
  const handleSelect = React.useCallback(
    (campaign: number | null, compareIndex: number) => {
      if (isDefined(campaign)) {
        const items: Double<number> = [campaign, CAMPAIGN_NR_NOT_SET];

        if (hasElements(campaignsToCompare) && isDefined(campaignsToCompare[compareIndex])) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          items[1] = campaignsToCompare[compareIndex]!;
        } else if (isDefined(campaigns[compareIndex])) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          items[1] = campaignsToCompare[compareIndex]!;
        }
        if (compareIndex === 0) {
          items.reverse();
        }
        if (hasElements(items)) {
          setCampaignsToCompare(items);
        }
      }
    },
    [campaigns, campaignsToCompare, setCampaignsToCompare]
  );

  const handleCampaignSelectedFrom = React.useCallback(
    (campaign: number | null) => {
      handleSelect(campaign, 1);
    },
    [handleSelect]
  );

  const handleCampaignSelectedTo = React.useCallback(
    (campaign: number | null) => {
      handleSelect(campaign, 0);
    },
    [handleSelect]
  );

  return (
    <SWrapper data-test-id={headerParametersComparison}>
      {/* First row : vessel-selection-dropdown, campaign-selection-dropdown-from & emptyFiller which takes remaining width */}
      <SVesselSelection vessels={vessels} isLoading={isLoading} selectedVesselId={selectedVesselId} onVesselSelected={setSelectedVesselId} />
      <SCampaignSelectionFrom
        campaigns={campaigns}
        colorTheme={ColorTheme.Dark}
        isLoading={isCampaignLoading}
        selectedCampaignId={hasElements(campaignsToCompare) ? campaignsToCompare[0] : campaigns[0]?.id}
        onCampaignSelected={handleCampaignSelectedFrom}
        tooltipFormatter={isDefined(tooltipFormatter) ? (campaign, _) => tooltipFormatter(campaign as RHIMMeasurementServiceV1ModelsCampaignDto) : undefined}
      />
      <CompareContainer>
        <ArrowsCompareIcon data-test-id={campaignCompareIconParametersComparison} color={settings.colors.Primary.Grey_6} />
      </CompareContainer>
      <SVesselSelectionTo vessels={vessels} isLoading={isLoading} selectedVesselId={selectedComparedVesselId} onVesselSelected={setSelectedComparedVesselId} />
      <SCampaignSelectionTo
        campaigns={comparedCampaigns}
        colorTheme={ColorTheme.Dark}
        isLoading={isCampaignLoading}
        selectedCampaignId={hasElements(campaignsToCompare) ? campaignsToCompare[1] : isDefined(campaigns[1]) ? campaigns[1].id : CAMPAIGN_NR_NOT_SET}
        onCampaignSelected={handleCampaignSelectedTo}
        tooltipFormatter={isDefined(tooltipFormatter) ? (campaign, _) => tooltipFormatter(campaign as RHIMMeasurementServiceV1ModelsCampaignDto) : undefined}
      />
      <SEmptyFillerCellA fill={FIRST_ROW_COLOR} />
    </SWrapper>
  );
};

const SWrapper = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  background-color: ${FIRST_ROW_COLOR};
`;

const SVesselSelection = styled(VesselSelection)`
  background-color: ${FIRST_ROW_COLOR};
`;

const SCampaignSelectionFrom = styled(CampaignSelection)`
  background-color: ${FIRST_ROW_COLOR};
`;

const CompareContainer = styled.div`
  background: ${SECOND_ROW_COLOR};
  align-items: center;
  display: flex;
  justify-content: center;
  border-right: 1px solid ${settings.colors.Primary.Grey_6};

  /* The new strips are supposed to be 48px tall. This icon should be 56px wide.
     https://app.zeplin.io/project/5eb293edf1f9a7763fec7596/screen/640ef3befa8a382213f3fe8c
     However, before we adapt every strip to the new design, let's keep this icon proportionate
     to the existing height of 40px. 56 * 40 / 48 = 46px. */
  width: 46px;
`;

const SCampaignSelectionTo = styled(CampaignSelection)`
  background-color: ${SECOND_ROW_COLOR};
`;

const SVesselSelectionTo = styled(VesselSelection)`
  background-color: ${SECOND_ROW_COLOR};
`;

const SEmptyFillerCell = styled.span<{ fill: string }>`
  background-color: ${(props) => props.fill};
`;

const SEmptyFillerCellA = styled(SEmptyFillerCell)`
  grid-area: emptyFillerCellA;
`;

export default React.memo(ParametersComparisonStrip);
