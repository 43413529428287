import { isEqual as isEqualLodash } from 'lodash';
import React from 'react';

type ComparisonFunction<T> = (left: T, right: T) => boolean;

/**
 * @see https://usehooks.com/useMemoCompare/
 */
export const useMemoCompare = <T>(next: T, isEqual: ComparisonFunction<T> = isEqualLodash): T => {
  const previousRef = React.useRef<T>(next);
  const previous = previousRef.current;
  const hasChanged = !isEqual(previous, next);

  React.useEffect(() => {
    if (hasChanged) {
      previousRef.current = next;
    }
  });

  return hasChanged ? next : previous;
};
