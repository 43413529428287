import { settings } from '@rhim/design';
import { Line } from '@vx/shape';
import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';

interface Props {
  top: number;
  left: number;
  mode: 'horizontal' | 'vertical';
  length: number;
  stroke?: settings.colors.Any;
  strokeWidth?: number;
  strokeDasharray?: number | string;
  children: ReactNode;
  contentAnchor: 'start' | 'middle' | 'end';
}
const ExtendedLineWithContent: FC<React.PropsWithChildren<Props>> = ({
  top,
  left,
  mode,
  length,
  stroke = settings.colors.Primary.Blue_9,
  strokeWidth = 1,
  strokeDasharray,
  children,
  contentAnchor,
}) => {
  return (
    <SWrapper>
      <svg width="100%" height="100%" shapeRendering="crispEdges">
        <Line
          from={{ x: left, y: top }}
          to={{
            x: mode === 'horizontal' ? left + length : left,
            y: mode === 'horizontal' ? top : top + length,
          }}
          stroke={stroke}
          strokeWidth={strokeWidth}
          strokeDasharray={strokeDasharray}
        />
      </svg>
      <SContentContainer top={top} left={left} length={length} mode={mode} contentAnchor={contentAnchor}>
        {children}
      </SContentContainer>
    </SWrapper>
  );
};
ExtendedLineWithContent.whyDidYouRender = true;
export default React.memo(ExtendedLineWithContent);

const SWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const SContentContainer = styled.div<Pick<Props, 'top' | 'left' | 'mode' | 'length' | 'contentAnchor'>>((props) => {
  const top: React.CSSProperties['top'] = (() => {
    switch (props.mode) {
      case 'horizontal':
        return props.top;
      case 'vertical': {
        switch (props.contentAnchor) {
          case 'start':
            return props.top;
          case 'middle':
            return props.top + props.length / 2;
          case 'end':
            return props.top + props.length;
        }
      }
    }
  })();
  const left: React.CSSProperties['left'] = (() => {
    switch (props.mode) {
      case 'horizontal': {
        switch (props.contentAnchor) {
          case 'start':
            return props.left;
          case 'middle':
            return props.left + props.length / 2;
          case 'end':
            return props.left + props.length;
        }
        break;
      }
      case 'vertical': {
        return props.left;
      }
    }
  })();
  const translate: React.CSSProperties['translate'] = (() => {
    switch (props.mode) {
      case 'horizontal': {
        switch (props.contentAnchor) {
          case 'start':
            return `translate(-100%, -50%)`;
          case 'middle':
            return `translate(-50%, -50%)`;
          case 'end':
            return `translate(0%, -50%)`;
          default:
            throw new Error(`Unsupported contentAnchor : ${props.contentAnchor}`);
        }
        break;
      }
      case 'vertical': {
        switch (props.contentAnchor) {
          case 'start':
            return `translate(-50%, -100%)`;
          case 'middle':
            return `translate(-50%, -50%)`;
          case 'end':
            return `translate(-50%, 0)`;
          default:
            throw new Error(`Unsupported contentAnchor : ${props.contentAnchor}`);
        }
        break;
      }
    }
  })();

  return css`
    display: flex;
    top: ${top}px;
    left: ${left}px;
    position: absolute;
    transform: ${translate};
  `;
});
