import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const HelpIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm.18 13.04c-.492 0-.89.148-1.18.444-.29.305-.438.68-.438 1.141 0 .46.149.844.438 1.156.29.32.688.477 1.18.477.476 0 .867-.156 1.156-.477.289-.312.437-.695.437-1.156 0-.46-.148-.836-.437-1.14-.29-.297-.68-.446-1.156-.446zm.172-8.993c-.704 0-1.313.148-1.836.445-.524.305-.922.703-1.196 1.196-.273.492-.406 1.023-.406 1.593 0 .156.016.328.047.508l2.11.29c-.055-.235-.079-.43-.079-.595 0-.39.11-.703.328-.945.22-.242.524-.36.907-.36.351 0 .625.102.835.298.211.203.313.484.313.835 0 .297-.078.563-.234.805-.157.242-.399.54-.727.89-.508.556-.875 1.048-1.094 1.477-.218.43-.328.93-.328 1.5v.11h2.18v-.172c0-.266.047-.516.148-.75.102-.227.266-.469.492-.719.227-.25.368-.414.43-.484.5-.555.86-1.055 1.086-1.5.227-.446.336-.922.336-1.43 0-.586-.14-1.11-.414-1.562-.281-.454-.672-.805-1.172-1.055-.5-.25-1.078-.375-1.726-.375z"
      />
    </svg>
  );
};

HelpIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
