import { settings } from '@rhim/design';
import { ChevronDoubleRightIcon } from '@rhim/icons/16';
import { expandToggleButtonLeftSidebar, wrapperLeftSidebar } from '@rhim/test-ids';
import { isDefined } from '@rhim/utils';
import { Layout, Menu } from 'antd';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import classNames from 'classnames';
import { MenuInfo } from 'rc-menu/lib/interface';
import React, { FC } from 'react';
import { usePress } from 'react-aria';
import { useToggle } from 'react-use';
import styled, { css } from 'styled-components';

import { Spinner } from '../Spinner';
import { CustomTooltipStyles, Tooltip } from '../Tooltip';

const { Sider } = Layout;

export const SIDEBAR_LEFT_Z_INDEX = 990;
const FOOTER_HEIGHT = '40px';

interface ItemProps extends React.HTMLAttributes<HTMLButtonElement> {
  icon: React.ReactElement;
  text: string;
  isSelected?: boolean;
  isExpanded?: boolean;
  isInProgress?: boolean;
  isDisabled?: boolean;
  /**
   * If provided, displays a small circle at the top right corner with specified color and tooltip
   */
  notification?: { color: settings.colors.Any; tooltip: string };
  onClick?: () => void;
  getPopupContainer?: (node: HTMLElement) => HTMLDivElement | HTMLElement;
  /**
   * Displayed as a tooltip when the item is disabled.
   *
   * @example "This feature requires QCK Light functionality."
   * @see https://app.zeplin.io/project/5eb293edf1f9a7763fec7596/screen/640ef3190df7c12157c5c64a
   */
  disabledText?: React.ReactNode;
}
const Item: FC<React.PropsWithChildren<ItemProps>> = (props) => {
  const {
    icon,
    getPopupContainer,
    text,
    disabledText,
    isSelected = false,
    isExpanded = false,
    isInProgress = false,
    isDisabled = false,
    notification,
    onClick,
    ...rest
  } = props;
  const { pressProps } = usePress({
    onPress: onClick,
  });

  const classes = classNames({ isDisabled });

  const iconColor = (() => {
    switch (true) {
      case isSelected:
        return settings.colors.Primary.Grey_9;
      case isDisabled:
        return settings.colors.Primary.Grey_7;
      default:
        return settings.colors.Primary.Grey_2;
    }
  })();
  const iconWithProps = React.cloneElement(icon, {
    fill: iconColor,
  });

  const tooltip = isDefined(disabledText) && isDisabled ? disabledText : text;

  return (
    <Tooltip title={tooltip} placement="right" overlayInnerStyle={isDisabled ? { width: '380px' } : {}} getPopupContainer={getPopupContainer}>
      <SItem className={classes} isSelected={isSelected} isInProgress={isInProgress} {...(isDisabled ? {} : pressProps)} {...rest}>
        {isDefined(notification) && (
          <Tooltip title={notification.tooltip}>
            <SNotification notificationColor={notification.color} />
          </Tooltip>
        )}
        {isInProgress ? (
          <SIconInProgress>
            <Spinner size="24" inverted={true} rotationDuration={2.5} />
          </SIconInProgress>
        ) : (
          <SItemIcon>{iconWithProps}</SItemIcon>
        )}
        {isExpanded && <SItemText>{text}</SItemText>}
      </SItem>
    </Tooltip>
  );
};

const Separator: React.ChildlessComponent = () => {
  return <SHorizontalSeparator />;
};

const VerticalSpring: React.ChildlessComponent = () => {
  return <SVerticalSpring />;
};

interface Props {
  className?: string;
  isExpanded: boolean;
  onToggleExpandedState: () => void;
}

interface ChildProps extends Pick<Props, 'isExpanded'> {}

function SidebarLeft({ className, isExpanded, onToggleExpandedState, children }: React.PropsWithChildren<Props>) {
  // Give each child the `isExpanded` prop
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement<ChildProps>(child)) {
      return React.cloneElement(child, { isExpanded });
    }
    return child;
  });

  return (
    <SWrapper className={className} data-test-id={wrapperLeftSidebar}>
      <SCollapsePanel>{childrenWithProps}</SCollapsePanel>
      <SFooter onClick={onToggleExpandedState} data-test-id={expandToggleButtonLeftSidebar}>
        <SToggleExpandIcon isExpanded={isExpanded}>
          <ChevronDoubleRightIcon fill={settings.colors.Primary.Grey_4} />
        </SToggleExpandIcon>
      </SFooter>
    </SWrapper>
  );
}

function Uncontrolled({ className, children, defaultActive }: Pick<React.PropsWithChildren<Props>, 'children' | 'className'> & { defaultActive?: boolean }) {
  const [isExpanded, setExpanded] = useToggle(defaultActive ?? false);

  const toggle = React.useCallback(() => {
    setExpanded(!isExpanded);
  }, [isExpanded, setExpanded]);

  // Give each child the `isExpanded` prop
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement<ChildProps>(child) && child.type === Item) {
      return React.cloneElement(child, { isExpanded });
    }
    return child;
  });

  return (
    <SWrapper className={className}>
      <SCollapsePanel>{childrenWithProps}</SCollapsePanel>
      <SFooter onClick={toggle}>
        <SToggleExpandIcon isExpanded={isExpanded}>
          <ChevronDoubleRightIcon fill={settings.colors.Primary.Grey_4} />
        </SToggleExpandIcon>
      </SFooter>
    </SWrapper>
  );
}
interface MenuProps {
  onOpen: (openKeys: string[]) => void;
  openKeys?: string;
  onClick: (item: MenuInfo) => void;
  selectedKeys?: string[];
  className?: string;
  menuItems?: (MenuItemType | undefined)[];
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
  dataTestIdSidebarStatus?: string;
  dataTestIdExpendToggle?: string;
  dataTestIdSider?: string;
}
function SideMenuBarLeft({
  className,
  menuItems,
  onClick,
  selectedKeys,
  onOpen,
  collapsed,
  setCollapsed,
  openKeys,
  dataTestIdSidebarStatus,
  dataTestIdExpendToggle,
  dataTestIdSider,
}: React.PropsWithChildren<MenuProps>) {
  return (
    <>
      <StyleSheet mode="default" />
      <MenuWrapper className={className} data-test-id={dataTestIdSider}>
        <Sider
          width={242}
          collapsedWidth={64}
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          trigger={
            <SToggleExpandIcon isExpanded={!collapsed} data-test-id={dataTestIdExpendToggle}>
              <ChevronDoubleRightIcon fill={settings.colors.Primary.Grey_4} />
            </SToggleExpandIcon>
          }
          data-test-id={dataTestIdSidebarStatus}
        >
          <Menu
            theme="dark"
            mode="inline"
            items={menuItems as MenuItemType[]}
            multiple={false}
            onOpenChange={onOpen}
            onClick={onClick}
            openKeys={isDefined(openKeys) ? [openKeys] : []}
            selectedKeys={selectedKeys}
          />
        </Sider>
      </MenuWrapper>
    </>
  );
}
const ITEM_MARGIN_HORIZONTAL = 8;

const SWrapper = styled.div`
  height: 100%;
  z-index: ${SIDEBAR_LEFT_Z_INDEX};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-shrink: 0;
  background-color: ${settings.colors.Primary.Grey_9};
`;

const StyleSheet = settings.globals.patchedCreateGlobalStyle`

  .ant-menu-submenu-popup {
    border-radius: 3px;
  }
  .ant-menu-dark.ant-menu-submenu-popup>.ant-menu {
    background-color: ${settings.colors.Primary.Grey_8};
    color: ${settings.colors.Monochromatic.White};
    border-radius: 3px;
  }

  .ant-menu-dark .ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
    background-color: ${settings.colors.Primary.Grey_8};
    color: ${settings.colors.Monochromatic.White};
    font-family: ${settings.typography.FontFamily.Bold};
  }

  .ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-selected {
    background-color: ${settings.colors.Monochromatic.White};
  }

  ${CustomTooltipStyles}
`;

const MenuWrapper = styled.div`
  height: 100%;
  z-index: ${SIDEBAR_LEFT_Z_INDEX};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  background-color: ${settings.colors.Primary.Grey_9};

  .ant-menu {
    font-family: ${settings.typography.FontFamily.Regular};
  }

  .ant-menu-dark {
    background-color: ${settings.colors.Primary.Grey_9};
  }

  .ant-menu-dark .ant-menu-item-selected,
  .ant-menu-submenu-popup .ant-menu-dark > .ant-menu .ant-menu-item-selected {
    background-color: ${settings.colors.Primary.Grey_9};
  }

  .ant-menu .ant-menu-submenu,
  .ant-menu .ant-menu-item {
    border-radius: 2px;
  }

  .ant-menu-submenu-title,
  .ant-menu-item {
    padding-left: 12px !important;
    padding-right: 12px;
    height: 48px;
    margin-inline: 0;
    width: 100%;
  }

  .ant-menu-dark.ant-menu-inline .ant-menu-item {
    padding-right: 12px;
  }

  .ant-menu-item .ant-menu-item-only-child {
    padding-left: 52px;
  }

  .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-inline-collapsed > .ant-menu-item {
    margin-inline: 0;
  }

  .ant-menu-dark.ant-menu-root.ant-menu-vertical {
    margin-left: 8px;
  }

  .ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-selected {
    background-color: ${settings.colors.Monochromatic.White};
  }

  .ant-menu-dark.ant-menu-root.ant-menu-inline {
    padding-left: 8px;
    padding-right: 8px;
  }

  .ant-menu-vertical .ant-menu-item,
  .ant-menu-vertical .ant-menu-submenu-title {
    width: 100%;
    margin-inline: 0;
    padding-top: 4px;
  }

  .ant-menu-vertical .ant-menu-item {
    padding-top: 8px;
  }

  .ant-menu-title-content {
    margin-left: ${settings.Spacing.Spacing_150};
  }

  .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-active,
  .ant-menu-dark .ant-menu-item:not(.ant-menu-item-selected):hover,
  .ant-menu-dark .ant-menu-item:not(.ant-menu-item-selected):active {
    background-color: ${settings.colors.Primary.Grey_10};
  }

  .ant-menu-inline .ant-menu-submenu-arrow::after {
    transform: rotate(45deg) translateY(2px) translateX(-2.5px);
    width: 10px;
    color: ${settings.colors.Primary.Grey_4};
    height: 2px;
  }

  .ant-menu-inline-collapsed {
    width: 48px;
  }

  .ant-menu .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
    transform: rotate(45deg) translateX(5.5px) translateY(-1px);
    color: ${settings.colors.Primary.Grey_10};
  }

  .ant-menu .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
    transform: rotate(-45deg) translateX(-3.5px) translateY(1px);
    color: ${settings.colors.Primary.Grey_10};
  }

  .ant-menu-inline .ant-menu-submenu-arrow::before {
    transform: rotate(-45deg) translateY(2px) translateX(2.5px);
    width: 10px;
    height: 2px;
    color: ${settings.colors.Primary.Grey_4};
  }

  .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title,
  .ant-menu-dark .ant-menu-item.ant-menu-item-selected:not(.ant-menu-item-only-child),
  .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-selected {
    background-color: ${settings.colors.Monochromatic.White};
    border-radius: 2px;
    color: ${settings.colors.Primary.Grey_9};
    margin-bottom: 0;
  }

  .ant-menu-dark.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child,
  .ant-menu-dark .ant-menu-item-selected,
  .ant-menu-dark > .ant-menu .ant-menu-item-selected {
    background-color: ${settings.colors.Primary.Grey_8};
    color: ${settings.colors.Monochromatic.White};
    font-family: ${settings.typography.FontFamily.Bold};
  }

  .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title,
  .ant-menu-submenu-selected .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-selected .ant-menu-submenu-arrow::after {
    color: ${settings.colors.Primary.Grey_9};
    font-family: ${settings.typography.FontFamily.Bold};
  }

  .ant-menu-submenu.ant-submenu-active,
  .ant-menu-item.ant-menu-item-active {
    background-color: ${settings.colors.Primary.Grey_10};
    background-color: #142e3e;
    border-radius: 2px;
  }

  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 48px;
    line-height: 48px;
  }

  .ant-menu-item {
    margin-bottom: 0;
  }

  .ant-menu-submenu .ant-menu-submenu-open,
  .ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline,
  .ant-menu-dark .ant-menu-item-selected {
    background-color: ${settings.colors.Primary.Grey_8};
  }

  .ant-layout-sider-trigger {
    width: 226px;
    position: fixed;
    bottom: 30px;
    z-index: 1;
    height: 48px;
    line-height: 48px;
    text-align: end;
    cursor: pointer;
    border-top: ${settings.colors.Primary.Grey_8} solid 1px;
  }
`;

const SCollapsePanel = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${settings.Spacing.Spacing_100};
  height: 100%;
  background-color: ${settings.colors.Primary.Grey_9};
  border-bottom: 1px solid ${settings.colors.Primary.Grey_8};
`;

const SItem = styled.button<{ isSelected: boolean; isInProgress: boolean }>`
  position: relative;

  --rowHeight: 48px;

  min-height: var(--rowHeight);
  height: var(--rowHeight);
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  border-radius: 2px;
  cursor: pointer;

  &:not(:first-of-type) {
    margin-top: ${settings.Spacing.Spacing_100};
  }

  ${(props) =>
    !props.isSelected &&
    css`
      color: ${settings.colors.Primary.Grey_4};
      &:hover {
        background-color: ${settings.colors.Primary.Grey_10};
      }
    `};
  ${(props) =>
    !props.isSelected &&
    props.isInProgress &&
    css`
      pointer-events: none;
      cursor: default;
    `};
  ${(props) =>
    props.isSelected &&
    css`
      background-color: ${settings.colors.Primary.Grey_1};
      --selectedColor: ${settings.colors.Primary.Grey_9};
      color: var(--selectedColor);
    `};

  &.isDisabled {
    --selectedColor: ${settings.colors.Primary.Grey_7};

    cursor: default;
    color: var(--selectedColor);
  }
`;

const SItemIcon = styled.span`
  display: flex;
  align-items: center;
  margin: ${settings.Spacing.Spacing_150};
  flex-shrink: 0;
`;

const SItemText = styled.span`
  display: inline-block;
  font-family: ${settings.typography.FontFamily.Medium};
  font-size: ${settings.typography.FontSize.X_Small};
  margin-right: ${settings.Spacing.Spacing_200};
`;

const SHorizontalSeparator = styled.div`
  --leftRightMargin: -${ITEM_MARGIN_HORIZONTAL}px;

  margin: ${settings.Spacing.Spacing_100} var(--leftRightMargin) 0 var(--leftRightMargin);
  border-top: 2px solid ${settings.colors.Primary.Grey_8};
`;

const SVerticalSpring = styled.div`
  flex-grow: 1;
`;

const SFooter = styled.button`
  height: ${FOOTER_HEIGHT};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
`;

const SIconInProgress = styled.span`
  display: flex;
  align-items: center;
  margin-left: ${settings.Spacing.Spacing_150};
  margin-right: ${settings.Spacing.Spacing_150};
  flex-shrink: 0;
`;

const SToggleExpandIcon = styled.span<{ isExpanded: boolean }>`
  margin-right: ${settings.Spacing.Spacing_300};
  display: inline-flex;
  transform: rotateZ(${(props) => (props.isExpanded ? '180deg' : '0deg')});
  transition: transform 0.25s ease-out;
`;

const SNotification = styled.div<{ notificationColor: settings.colors.Any }>`
  --size: 8px;
  --offset: 6px;

  position: absolute;
  top: var(--offset);
  right: var(--offset);
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background-color: ${(props) => props.notificationColor};
`;

const SGroupLabel = styled.div`
  border-top: 1px solid ${settings.colors.Primary.Grey_7};
  background-color: ${settings.colors.Primary.Grey_8};
  margin: ${settings.Spacing.Spacing_150} -${ITEM_MARGIN_HORIZONTAL}px ${settings.Spacing.Spacing_50};
  text-align: center;
  font-family: ${settings.typography.FontFamily.Medium};
  font-size: ${settings.typography.FontSize.X_Small};
  color: ${settings.colors.Primary.Grey_6};
  line-height: ${settings.typography.LineHeight.Line_Height_16};
`;

SidebarLeft.Item = Item;
SidebarLeft.Separator = Separator;
SidebarLeft.VerticalSpring = VerticalSpring;
SidebarLeft.Uncontrolled = Uncontrolled;
SidebarLeft.GroupLabel = SGroupLabel;
SidebarLeft.Menu = SideMenuBarLeft;

SidebarLeft.whyDidYouRender = true;

export default SidebarLeft;
