import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { codeOfConductLinkFooter, copyrightsLabelFooter, imprintLinkFooter, privacyLinkFooter, termsOfUseLinkFooter } from '@rhim/test-ids';
import { intersperse, partial } from '@rhim/utils';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

export const FOOTER_BAR_HEIGHT = '32px';

interface Props {
  transparent?: boolean;
  sticky?: boolean;
  style?: React.CSSProperties;
  children: React.ReactElement | React.ReactElement[];
}

export class Footer extends React.Component<Props> {
  static defaultProps = partial<Props>()({
    transparent: false,
    sticky: false,
  });

  static Link: React.FunctionComponent<React.PropsWithChildren<React.ComponentProps<typeof RouterLink>>> = (props) => (
    <ItemContainer>
      <Link {...props} />
    </ItemContainer>
  );

  static Text: React.FunctionComponent<React.PropsWithChildren<React.PropsWithoutRef<JSX.IntrinsicElements['span']>>> = (props) => (
    <ItemContainer>
      <Text {...props} />
    </ItemContainer>
  );

  render(): JSX.Element {
    const { transparent, style, sticky, children } = this.props;

    return (
      <Container transparent={transparent} sticky={sticky} style={style} data-test-id="footer">
        {Array.isArray(children)
          ? React.Children.map(intersperse([...children, <Spacer key="spacer" />], <Spacer />), (child, index) =>
              React.isValidElement(child)
                ? // eslint-disable-next-line @typescript-eslint/ban-types
                  React.createElement(child.type, { ...(child.props as object), key: child.key ?? index })
                : null
            )
          : children}
      </Container>
    );
  }
}

export const DefaultFooter = (props: Omit<React.ComponentProps<typeof Footer>, 'children'> & { hasCodeOfConduct?: boolean }) => {
  const { t } = i18nReact.useTranslation(['footer']);
  const { hasCodeOfConduct = false, ...rest } = props;

  return (
    <Footer {...rest}>
      <Footer.Link data-test-id={imprintLinkFooter} to="/imprint">
        {t('footer:links.imprint')}
      </Footer.Link>
      <Footer.Link data-test-id={privacyLinkFooter} to="/privacy">
        {t('footer:links.privacy')}
      </Footer.Link>
      <Footer.Link data-test-id={termsOfUseLinkFooter} to="/terms-of-use">
        {t('footer:links.terms-of-use')}
      </Footer.Link>
      {hasCodeOfConduct ? (
        <Footer.Link data-test-id={codeOfConductLinkFooter} to="/code-of-conduct">
          {t('footer:links.code-of-conduct')}
        </Footer.Link>
      ) : (
        <></>
      )}
      <Footer.Text data-test-id={copyrightsLabelFooter}>{t('footer:footnote', { year: new Date().getFullYear() })}</Footer.Text>
    </Footer>
  );
};

const Container = styled.footer<Pick<Props, 'transparent' | 'sticky'>>`
  z-index: 100;
  width: 100%;
  flex-shrink: 0;
  height: ${FOOTER_BAR_HEIGHT};
  ${(props) => (props.sticky ?? false ? { position: 'sticky', bottom: 0 } : {})}
  justify-content: flex-end;
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.transparent ?? false ? 'transparent' : settings.colors.Primary.Grey_8)};
`;

const ItemContainer = styled.section`
  height: 24px;
`;

const Link = styled(RouterLink)`
  text-decoration: none;
  font-family: ${settings.typography.FontFamily.Bold};
  font-size: 12px;
  color: ${settings.colors.Monochromatic.White};

  :hover {
    color: ${settings.colors.Primary.Grey_3};
  }

  :active {
    color: ${settings.colors.Primary.Grey_4};
  }
`;

const Text = styled.span`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: 12px;
  color: ${settings.colors.Primary.Grey_4};
`;

const Spacer = styled.div`
  width: 24px;
  height: 100%;
`;
