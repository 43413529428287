import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const BellIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.286 12H1.714C.77 12 0 11.327 0 10.5S.77 9 1.714 9c.201 0 .572-.38.572-1V5c0-2.757 2.563-5 5.714-5 3.15 0 5.714 2.243 5.714 5v3c0 .62.37 1 .572 1C15.23 9 16 9.673 16 10.5s-.77 1.5-1.714 1.5m-5.752 4C7.357 16 6.4 15.103 6.4 14c0-.553.478-1 1.067-1H9.6c.59 0 1.067.447 1.067 1 0 1.103-.957 2-2.133 2"
        fill={fill}
      />
    </svg>
  );
};

BellIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
