import { settings } from '@rhim/design';
import { SupportedLanguageIsoCode } from '@rhim/i18n';
import { assert, colorScalesForLadle, hasElements, isDefined } from '@rhim/utils';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import useResizeAware from 'react-resize-aware';
import styled from 'styled-components';
import { UnitSystem } from 'typings';

import { Area, AreaLayout, AreaRegion } from '../../components/WallplotHeatmap/utils';
import { useLocalization } from '../../hooks/useLocalization';
import { convertLength, convertUnit, MetricLengthUnit, StickyHeaderShadow } from '../../partials';
import { Checkbox } from '../Checkbox';
import AreaHighlightCanvas from '../WallplotHeatmap/AreaHighlightCanvas';
import AreasMapCanvas from '../WallplotHeatmap/AreasMapCanvas';
import AreaLabelsCanvas from './AreaLabelsCanvas';
import AreasInfoCards, { RegionPanelToFocus } from './AreasInfoCards';
import { AreaPanelTexts } from './domain';
import { SNoAreasToDisplay } from './utils';

const CANVAS_WIDTH = 246;
const CANVAS_HEIGHT = 125;

const getMinimumThicknessAnnotatedAreasLayout = (
  wallplotAreas: Area[],
  areasLayout: AreaLayout[],
  locale: SupportedLanguageIsoCode,
  unitSystem: UnitSystem
) => {
  const allRegions = wallplotAreas.reduce((prev, curr) => {
    return prev.concat(curr.lines);
  }, [] as AreaRegion[]);
  return areasLayout.map((areaLayout) => {
    const assosiatedRegion = allRegions.find((region) => region.regionId === areaLayout.regionId);
    assert(isDefined(assosiatedRegion), `Assosiated region of area-layout not found`);
    return { ...areaLayout, label: convertLength(assosiatedRegion.minimumThickness, MetricLengthUnit.mm, unitSystem, undefined, 2, locale, false) };
  });
};

export interface WallplotAreasExpandedProps {
  domainXStart?: number;
  domainXWrap?: number;
  domainXMin: number;
  domainXMax: number;
  domainYMin: number;
  domainYMax: number;
  wallplotAreas: Area[];
  onShowingAreasOnWallplotChanged: (isShowing: boolean) => void;
  highlightAreaId?: number | null;
  onAreaEntered: (areaId: number) => void;
  onAreasExited: () => void;
  texts: AreaPanelTexts;
  regionPanelToFocus: RegionPanelToFocus;
}
export const WallplotAreasExpanded: FC<React.PropsWithChildren<WallplotAreasExpandedProps>> = ({
  domainXStart,
  domainXWrap,
  domainXMin,
  domainXMax,
  domainYMin,
  domainYMax,
  wallplotAreas,
  onShowingAreasOnWallplotChanged,
  highlightAreaId,
  onAreaEntered,
  onAreasExited,
  texts,
  regionPanelToFocus,
}) => {
  const domWrapper = useRef<HTMLDivElement>(null);
  const domHeader = useRef<HTMLDivElement>(null);
  const domBody = useRef<HTMLDivElement>(null);
  const [isShowingAreasInWallplot, setShowingAreasInWallplot] = useState(true);
  const [isHeaderShadowVisible, setHeaderShadowVisible] = useState(false);
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const [focusedRegionPanel, setFocusedRegionPanel] = useState<RegionPanelToFocus>({ regionId: null });
  const domCanvassesContainerRef = useRef<HTMLDivElement>(null);
  const [areasLayout, setAreasLayout] = useState<AreaLayout[]>([]);
  const [resizeListener, sizes] = useResizeAware();
  const [localization] = useLocalization();
  const { unitSystem, locale } = localization;

  useEffect(() => {
    setFocusedRegionPanel(regionPanelToFocus);
  }, [regionPanelToFocus]);

  useEffect(() => {
    if (!domCanvassesContainerRef.current) {
      return;
    }
    const canvassesContainer = domCanvassesContainerRef.current;
    canvassesContainer.addEventListener('mousemove', onMouseMove);
    canvassesContainer.addEventListener('mouseleave', onMouseLeave);

    function onMouseMove(evt: MouseEvent) {
      if (!isShowingAreasInWallplot) {
        return;
      }
      const rect = canvassesContainer.getBoundingClientRect();
      setMouseX(evt.clientX - rect.left);
      setMouseY(evt.clientY - rect.top);
    }

    function onMouseLeave() {
      setMouseX(Infinity);
      setMouseY(Infinity);
    }

    return () => {
      canvassesContainer.removeEventListener('mousemove', onMouseMove);
      canvassesContainer.removeEventListener('mouseleave', onMouseLeave);
    };
  }, [isShowingAreasInWallplot]);

  useEffect(() => {
    const domWrapperElement = domWrapper.current;
    const domHeaderElement = domHeader.current;
    const domBodyElement = domBody.current;
    if (!domWrapperElement || !domHeaderElement || !domBodyElement) {
      return;
    }
    domBodyElement.style.height = `${domWrapperElement.clientHeight - domHeaderElement.clientHeight}px`;
    domBodyElement.style.maxHeight = `${domWrapperElement.clientHeight - domHeaderElement.clientHeight}px`;
  });

  useEffect(() => {
    if (!domBody.current) {
      return;
    }
    const clientHeight = domBody.current.clientHeight;
    const scrollHeight = domBody.current.scrollHeight;
    setHeaderShadowVisible(scrollHeight > clientHeight);
  }, [sizes.height]);

  const metrics = useMemo(() => {
    return {
      canvasWidth: CANVAS_WIDTH,
      canvasHeight: CANVAS_HEIGHT,
      plotWidth: CANVAS_WIDTH,
      plotHeight: CANVAS_HEIGHT,
      domainXMin,
      domainXMax,
      domainYMin,
      domainYMax,
    };
  }, [domainXMin, domainXMax, domainYMin, domainYMax]);

  const minimumThicknessAnnotatedAreasLayout: AreaLayout[] = useMemo(() => {
    return getMinimumThicknessAnnotatedAreasLayout(wallplotAreas, areasLayout, locale, unitSystem);
  }, [wallplotAreas, areasLayout, unitSystem, locale]);

  const handleShowAreasInWallplotClicked = () => {
    const isShowing = !isShowingAreasInWallplot;
    setShowingAreasInWallplot(isShowing);
    onShowingAreasOnWallplotChanged(isShowing);
  };

  const handleAreasLayoutUpdated = React.useCallback((updatedAreasLayout: AreaLayout[]) => {
    setAreasLayout(updatedAreasLayout);
  }, []);

  const handleRegionClicked = (regionId: number) => {
    setFocusedRegionPanel({ regionId });
  };

  if (!hasElements(wallplotAreas)) {
    return <SNoAreasToDisplay>{texts.noAreasToDisplay}</SNoAreasToDisplay>;
  }

  return (
    <StyledWrapper ref={domWrapper}>
      <SHeader ref={domHeader}>
        <SHeaderTitle>{texts.areas}</SHeaderTitle>
        <SHeaderMinimapContainer>
          <Checkbox isSelected={isShowingAreasInWallplot} onChange={handleShowAreasInWallplotClicked}>
            {texts.showAreasInWallplot}
          </Checkbox>
          <SCanvassesContainer ref={domCanvassesContainerRef}>
            <SAreasMapCanvas
              texts={texts}
              domainXStart={domainXStart}
              domainXWrap={domainXWrap}
              metrics={metrics}
              areas={wallplotAreas}
              onAreasLayoutUpdated={handleAreasLayoutUpdated}
              shouldDisplayAreaLabels={false}
              shouldPaintAreaEdgeOnMapBounds={true}
              fillColor={colorScalesForLadle}
            />
            {isShowingAreasInWallplot && (
              <SAreaHighlightCanvas
                texts={texts}
                canvasWidth={CANVAS_WIDTH}
                canvasHeight={CANVAS_HEIGHT}
                wallplotWidth={metrics.canvasWidth}
                wallplotMarginLeft={0}
                areasLayout={areasLayout}
                areas={wallplotAreas}
                mouseX={mouseX}
                mouseY={mouseY}
                isShowingAreaLabel={false}
                onAreaEntered={onAreaEntered}
                onAreasExited={onAreasExited}
                onRegionClicked={handleRegionClicked}
                highlightAreaId={highlightAreaId}
              />
            )}
            <SAreaLabelsCanvas areasLayout={minimumThicknessAnnotatedAreasLayout} />
          </SCanvassesContainer>
          <SHeaderMinimumThicknessLabel>
            {texts.minimumThickness} [{convertUnit(MetricLengthUnit.mm, unitSystem)}]
          </SHeaderMinimumThicknessLabel>
        </SHeaderMinimapContainer>
        <StickyHeaderShadow isVisible={isHeaderShadowVisible} />
      </SHeader>
      <SBody ref={domBody}>
        <SScrollableContentHeightObserver>
          {resizeListener}
          <AreasInfoCards wallplotAreas={wallplotAreas} texts={texts} focusedRegionPanel={focusedRegionPanel} />
        </SScrollableContentHeightObserver>
      </SBody>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: inline-block;
  height: 100%;
`;

const SHeader = styled.div`
  position: relative;
`;

const SHeaderTitle = styled.div`
  padding: ${settings.Spacing.Spacing_50} 0 ${settings.Spacing.Spacing_50} ${settings.Spacing.Spacing_200};
  font-family: ${settings.typography.FontFamily.Medium};
  font-size: ${settings.typography.FontSize.X_Small};
  background-color: ${settings.colors.Primary.Grey_1};
  color: ${settings.colors.Primary.Grey_7};
`;

const SHeaderMinimapContainer = styled.div`
  background-color: white;
  padding: ${settings.Spacing.Spacing_200};
  border-bottom: 1px solid ${settings.colors.Primary.Grey_3};
`;

const SCanvassesContainer = styled.div`
  position: relative;
  margin-top: ${settings.Spacing.Spacing_300};
  width: ${CANVAS_WIDTH}px;
  height: ${CANVAS_HEIGHT}px;
`;

const SAreasMapCanvas = styled(AreasMapCanvas)`
  position: absolute;
`;

const SAreaHighlightCanvas = styled(AreaHighlightCanvas)`
  position: absolute;
  cursor: pointer;
`;

const SAreaLabelsCanvas = styled(AreaLabelsCanvas)`
  position: absolute;
`;

const SHeaderMinimumThicknessLabel = styled.div`
  margin-top: ${settings.Spacing.Spacing_100};
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.X_Small};
  color: ${settings.colors.Primary.Grey_6};
`;

const SBody = styled.div`
  overflow-y: auto;
`;

const SScrollableContentHeightObserver = styled.div`
  /* a non initial position is a requirement of the react-resize-aware library that observes changes in the height of an element */
  position: relative;
`;
