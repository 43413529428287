import { assert, isDefined } from '@rhim/utils';
import { bisector } from 'd3-array';
import { ScaleLinear } from 'd3-scale';
import React, { useCallback } from 'react';

interface Point {
  x: number;
  y: number;
}

const MINIMUM_DISTANCE_PX = 30; // if mouse.x's distance to its nearest linepath node is less than this value, the tooltip will appear

const bisectorRight = bisector<Point, number>((d) => d.x).right;

interface Props {
  plotWidth: number;
  plotHeight: number;
  scaleX: ScaleLinear<number, number>;
  linePathData: Point[];
  onLinePathPointHovered: (nearestLinePathHoveredIndex: number | undefined) => void;
}
const LinePathTooltip: React.ChildlessComponent<Props> = (props) => {
  const { plotWidth, plotHeight, scaleX, linePathData, onLinePathPointHovered } = props;

  const handleMouseMove = useCallback(
    (event: React.MouseEvent) => {
      const targetElementBounds = (event.target as SVGRectElement).getBoundingClientRect();
      const relativeMouseXRangeValue = event.clientX - targetElementBounds.left;
      const relativeMouseXDomainValue = scaleX.invert(relativeMouseXRangeValue);
      const nearestToTheRightDomainXIndex = Math.min(linePathData.length - 1, bisectorRight(linePathData, relativeMouseXDomainValue));
      const nearestToTheLeftDomainXIndex = Math.max(0, nearestToTheRightDomainXIndex - 1);
      const nearestToTheRightDomainXPoint = linePathData[nearestToTheRightDomainXIndex];
      const nearestToTheLeftDomainXPoint = linePathData[nearestToTheLeftDomainXIndex];
      assert(isDefined(nearestToTheRightDomainXPoint), `LinePathTooltip, invalid index ${nearestToTheRightDomainXIndex}`);
      assert(isDefined(nearestToTheLeftDomainXPoint), `LinePathTooltip, invalid index ${nearestToTheLeftDomainXPoint}`);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const mouseXDistanceToPointRight = Math.abs((scaleX(nearestToTheRightDomainXPoint.x) || 0) - relativeMouseXRangeValue);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const mouseXDistanceToPointLeft = Math.abs(relativeMouseXRangeValue - (scaleX(nearestToTheLeftDomainXPoint.x) || 0));
      if (mouseXDistanceToPointRight > MINIMUM_DISTANCE_PX && mouseXDistanceToPointLeft > MINIMUM_DISTANCE_PX) {
        onLinePathPointHovered(undefined);
        return;
      }
      const nearestPointIndex = mouseXDistanceToPointRight > mouseXDistanceToPointLeft ? nearestToTheLeftDomainXIndex : nearestToTheRightDomainXIndex;
      onLinePathPointHovered(nearestPointIndex);
    },
    [linePathData, scaleX, onLinePathPointHovered]
  );

  const handleMouseOut = useCallback(() => {
    onLinePathPointHovered(undefined);
  }, [onLinePathPointHovered]);

  return <rect x={0} y={0} width={plotWidth} height={plotHeight} onMouseMove={handleMouseMove} onMouseOut={handleMouseOut} fill="transparent" />;
};
LinePathTooltip.whyDidYouRender = true;
export default React.memo(LinePathTooltip);
