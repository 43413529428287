import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const SnorkelOutIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M17.829 19c-.674-.416-2.692-1-5.829-1-3.137 0-5.155.584-5.829 1 .674.416 2.692 1 5.829 1 3.137 0 5.155-.584 5.829-1zM20 19c0 2.916-7.181 3-8 3s-8-.084-8-3V5a1 1 0 1 1 2 0v11.892c2.189-.85 5.462-.892 6-.892.538 0 3.811.042 6 .892V5a1 1 0 1 1 2 0v14zM13 8.586V3a1 1 0 1 0-2 0v5.586L9.707 7.293a.999.999 0 1 0-1.414 1.414l3 3a.999.999 0 0 0 1.414 0l3-3a.999.999 0 1 0-1.414-1.414L13 8.586z"
      />
    </svg>
  );
};

SnorkelOutIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Grey_6,
};
