import { settings } from '@rhim/design';
import { isDefined } from '@rhim/utils';
import * as React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

interface Props {
  /**
   * Top-level navigation bar. @see https://app.zeplin.io/project/5eb293edf1f9a7763fec7596/screen/62b96d887760f311aa6fba39
   */
  header?: React.ReactNode;
  /**
   * First-level navigation (vessel, campaign, heat/measurement strip). @see https://app.zeplin.io/project/5eb293edf1f9a7763fec7596/screen/62b96d887760f311aa6fba39
   */
  sidebar?: React.ReactNode;
  main: React.ReactNode;
  footer?: React.ReactNode;
  children?: never;
}

/**
 * @example
 *
 *   ```tsx
 *   <Layout
 *     header={<AppBar />}
 *     main={<MeasurementsPage />}
 *     footer={<Footer sticky />}
 *   />
 *   ```
 *
 * Implements the "holy grail" layout. @see https://web.dev/patterns/layout/holy-grail/
 */
const Layout: React.FunctionComponent<Props> = ({ footer, header, main, sidebar }) => (
  <Parent>
    <GlobalStyle />
    <Header>{header}</Header>
    {isDefined(sidebar) && <Sidebar>{sidebar}</Sidebar>}
    <Main>
      <ScrollableContent id="scrollable-content">{main}</ScrollableContent>
    </Main>
    <Footer>{footer}</Footer>
  </Parent>
);

/**
 * A container that stretches to the full height of the viewport.
 *
 * This is useful if you want the inner content centered vertically.
 */
export const Frame = styled.div`
  @media screen {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
`;

const GlobalStyle = createGlobalStyle`
  @media screen {
    html,
    body {
      height: 100%;
    }

    body {
      display: grid;
      align-items: center;
      overflow: hidden;
    }
  }
`;

const Parent = styled.div`
  @media screen {
    height: 100%;
    display: grid;
    grid-template: auto 1fr auto / auto 1fr auto;
    overflow: hidden;
  }
`;

const Header = styled.header`
  grid-column: 1 / 4;
  z-index: ${settings.Elevation.Header};

  @media print {
    display: none;
  }
`;

const Sidebar = styled.aside`
  grid-column: 1 / 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media print {
    display: none;
  }
`;

const Main = styled.main`
  @media screen {
    grid-column: 2 / 3;
    overflow-y: auto;
  }
`;

const Footer = styled.footer`
  grid-column: 1 / 4;
  z-index: 1;

  @media print {
    display: none;
  }
`;

const ScrollableContent = styled.div`
  @media screen {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
`;

export default React.memo(Layout);
