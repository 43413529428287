import { RHIMProcessDataServiceV1ModelsCampaignComparisonDto } from '@rhim/rest/processData';
import { specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api/processData';

type Payload = RHIMProcessDataServiceV1ModelsCampaignComparisonDto[];

export const useParametersComparison = (
  vesselId: UUID,
  campaignsToCompare?: number[],
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) => {
  return useQuery(getKey(vesselId, campaignsToCompare), (context) => queryFn(context, options?.axiosConfiguration), options?.configuration);
};

const getKey = (vesselId: UUID, campaignsToCompare: number[] | undefined) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'processData',
      scope: 'parameters-comparison-for-vessel-id',
      entity: 'list',
      vesselId,
      campaignsToCompare,
    },
  ]);

const queryFn = (
  { queryKey: [{ vesselId, campaignsToCompare }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>,
  axiosConfiguration?: AxiosRequestConfig
) => {
  return API.parameters.getParametersVesselidDatacomparison(vesselId, campaignsToCompare, { signal, ...axiosConfiguration }).then((response) => response.data);
};

useParametersComparison.getKey = getKey;
useParametersComparison.queryFn = queryFn;
