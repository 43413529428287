import { RHIMMeasurementServiceV1ModelsMeasurementMetadataDto } from '@rhim/rest/measurementService';
import { specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api/measurementService';

type Payload = RHIMMeasurementServiceV1ModelsMeasurementMetadataDto;

export function useMeasurementFileMetadata(
  measurementId: string,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) {
  return useQuery(getKey(measurementId), (context) => queryFn(context, options?.axiosConfiguration), options?.configuration);
}

const getKey = (measurementId: string) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'measurementService',
      scope: 'measurement-file-metadata',
      entity: 'detail',
      measurementId,
    },
  ]);

const queryFn = ({ queryKey: [{ measurementId }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  return API.measurementView.getMeasurementviewFileMeasurementidMeta(measurementId, { signal, ...axiosConfiguration }).then((response) => response.data);
};

useMeasurementFileMetadata.getKey = getKey;
useMeasurementFileMetadata.queryFn = queryFn;
