import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  backgroundColor?: Property.Color;
  foregroundColor?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const OpsStateCriticalInvertedIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  backgroundColor,
  foregroundColor,
  size,
  title,
  role,
  ...rest
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path
              fill={backgroundColor}
              d="M4.686 0L0 4.686 0 11.315 4.686 16 11.314 16 16 11.315 16 4.686 11.314 0z"
              transform="translate(-268 -492) translate(268 492)"
            />
            <g fill={foregroundColor}>
              <path
                d="M1.5 7C2.329 7 3 7.671 3 8.5S2.329 10 1.5 10 0 9.329 0 8.5.671 7 1.5 7zm0-7c.552 0 1 .512 1 1.143v3.714C2.5 5.488 2.052 6 1.5 6s-1-.512-1-1.143V1.143C.5.512.948 0 1.5 0z"
                transform="translate(-268 -492) translate(268 492) translate(6.5 3)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

OpsStateCriticalInvertedIcon.defaultProps = {
  size: '16',
  backgroundColor: settings.colors.Monochromatic.White,
  foregroundColor: settings.colors.Operational.State_Alert_Red_3,
};
