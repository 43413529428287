import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  backgroundColor?: Property.Color;
  foregroundColor?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const AppStateErrorIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  backgroundColor,
  foregroundColor,
  size,
  title,
  role,
  ...rest
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <path
          d="m14.511 12.211-5.454-9.6A1.215 1.215 0 0 0 8 2c-.438 0-.842.234-1.057.611l-5.454 9.6c-.211.372-.207.827.01 1.195.218.368.616.594 1.046.594h10.91c.43 0 .828-.226 1.046-.594.217-.368.22-.823.01-1.195z"
          fill={backgroundColor}
        />
        <path
          d="M8 10.667a1 1 0 1 1 .001 1.999 1 1 0 0 1-.001-2zm0-5.334c.368 0 .667.299.667.667v3.333a.667.667 0 0 1-1.334 0V6c0-.368.299-.667.667-.667z"
          fill={foregroundColor}
        />
      </g>
    </svg>
  );
};

AppStateErrorIcon.defaultProps = {
  size: '16',
  backgroundColor: settings.colors.Operational.State_Notif_Magenta_2,
  foregroundColor: settings.colors.Monochromatic.Black,
};
