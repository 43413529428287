import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const ArrowsCompareIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m18.466 3.293 3 3a.999.999 0 0 1 0 1.414l-3 3a.999.999 0 1 1-1.414-1.414L18.345 8H4.8a1 1 0 1 1 0-2h13.545l-1.293-1.293a.999.999 0 1 1 1.414-1.414zm-14.373 13a.999.999 0 0 0 0 1.414l3 3a1 1 0 1 0 1.414-1.414L7.214 18H20.76a1 1 0 1 0 0-2H7.214l1.293-1.293a.999.999 0 1 0-1.414-1.414l-3 3z"
        fill={fill}
      />
    </svg>
  );
};

ArrowsCompareIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Grey_8,
};
