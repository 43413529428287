import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  backgroundColor?: Property.Color;
  foregroundColor?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const OpsStateDebugIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  backgroundColor,
  foregroundColor,
  size,
  title,
  role,
  ...rest
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00024 0C6.34339 0 5.00024 1.34315 5.00024 3V4.17071C4.74865 4.25963 4.51246 4.38117 4.29675 4.53023L1.44743 3.10557C0.953454 2.85858 0.352781 3.05881 0.105792 3.55279C-0.141197 4.04676 0.059027 4.64744 0.553005 4.89443L3.11499 6.17542C3.04026 6.43741 3.00024 6.71403 3.00024 7V8H1.00022C0.447934 8 0.000219048 8.44771 0.000219048 9C0.000219048 9.55229 0.447934 10 1.00022 10H3.00024V11C3.00024 11.2885 3.02467 11.5712 3.07157 11.8463L0.553005 13.1056C0.059027 13.3526 -0.141197 13.9532 0.105792 14.4472C0.352781 14.9412 0.953454 15.1414 1.44743 14.8944L3.8022 13.717C4.6933 15.091 6.24062 16 8.00024 16C9.75986 16 11.3072 15.091 12.1983 13.7171L14.553 14.8944C15.047 15.1414 15.6477 14.9412 15.8946 14.4472C16.1416 13.9532 15.9414 13.3526 15.4474 13.1056L12.9289 11.8463C12.9758 11.5712 13.0002 11.2885 13.0002 11V10H15.0002C15.5525 10 16.0002 9.55229 16.0002 9C16.0002 8.44771 15.5525 8 15.0002 8H13.0002V7C13.0002 6.71402 12.9602 6.43739 12.8855 6.1754L15.4474 4.89443C15.9414 4.64744 16.1416 4.04676 15.8946 3.55279C15.6477 3.05881 15.047 2.85858 14.553 3.10557L11.7037 4.53022C11.488 4.38116 11.2518 4.25963 11.0002 4.17071V3C11.0002 1.34315 9.6571 0 8.00024 0Z"
        fill="#8451CD"
      />
    </svg>
  );
};

OpsStateDebugIcon.defaultProps = {
  size: '16',
};
