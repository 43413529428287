import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const MeasurementIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M9 2c1.103 0 2 .897 2 2v16c0 1.103-.897 2-2 2H5c-1.103 0-2-.897-2-2V4c0-1.103.897-2 2-2zm7.293.293c.391-.391 1.023-.391 1.414 0l3 3c.391.391.391 1.023 0 1.414-.195.195-.451.293-.707.293-.256 0-.512-.098-.707-.293L18 5.414v13.172l1.293-1.293c.391-.391 1.023-.391 1.414 0s.391 1.023 0 1.414l-3 3c-.195.195-.451.293-.707.293-.256 0-.512-.098-.707-.293l-3-3c-.391-.391-.391-1.023 0-1.414s1.023-.391 1.414 0L16 18.586V5.414l-1.293 1.293c-.391.391-1.023.391-1.414 0s-.391-1.023 0-1.414zM9 4H5v16h4.001v-1H8c-.552 0-1-.447-1-1 0-.553.448-1 1-1h1.001v-2H8c-.552 0-1-.447-1-1 0-.553.448-1 1-1h1.001L9 11H8c-.552 0-1-.447-1-1 0-.553.448-1 1-1h1V7H8c-.552 0-1-.447-1-1 0-.553.448-1 1-1h1V4z"
      />
    </svg>
  );
};

MeasurementIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Grey_6,
};
