import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const ArrowTopIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M11.293 8.293c.391-.391 1.023-.391 1.414 0l3 3c.391.391.391 1.023 0 1.414-.195.195-.451.293-.707.293-.256 0-.512-.098-.707-.293L13 11.414V19c0 .553-.448 1-1 1s-1-.447-1-1v-7.586l-1.293 1.293c-.391.391-1.023.391-1.414 0s-.391-1.023 0-1.414zM19 4c.552 0 1 .447 1 1 0 .553-.448 1-1 1H5c-.552 0-1-.447-1-1 0-.553.448-1 1-1z"
      />
    </svg>
  );
};

ArrowTopIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
