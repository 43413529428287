import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const CloudUpIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M35.95 18.193C35.55 14.708 32.593 12 29 12c-1.238 0-2.399.324-3.407.887C23.95 11.115 21.607 10 19 10c-4.97 0-9 4.03-9 9 0 .503.052.994.131 1.475-3.562 1.198-6.13 4.558-6.13 8.525 0 4.97 4.028 9 9 9h10V27.414l-1.294 1.293c-.39.39-1.024.39-1.414 0-.196-.196-.293-.45-.293-.707 0-.256.097-.512.293-.707l3-3c.39-.39 1.023-.39 1.414 0l3 3c.39.39.39 1.023 0 1.414-.39.39-1.024.39-1.414 0L25 27.414V38h9c5.523 0 10-4.477 10-10 0-4.856-3.46-8.9-8.05-9.807"
      />
    </svg>
  );
};

CloudUpIcon.defaultProps = {
  size: '48',
  fill: settings.colors.Primary.Blue_9,
};
