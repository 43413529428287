import type { Observer, Scene } from '@babylonjs/core';
import { ArcRotateCamera, Tools, Vector3 } from '@babylonjs/core';
import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto } from '@rhim/rest/fleetOverview';
import { volumeStartImageVolumeExplorer, volumeStartLabelVolumeExplorer } from '@rhim/test-ids';
import { assert, ensure, isDefined } from '@rhim/utils';
import { enhance3dRequestsWithCustomHeaders, loadMesh } from '@rhim/visualization3d';
import { VolumeMaterial } from '@rhim/visualization3d/volumeMaterial';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { useMeasurementFileMetadata } from '../../../hooks';
import { getAuthToken } from '../../../utilities';
import { Section3DContext } from './Section3DContext';

const CANVAS_WIDTH = 600;
const CANVAS_HEIGHT = 300;

interface Props {
  measurement: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto;
}

const Section3DView: React.ChildlessComponent<Props> = ({ measurement }) => {
  const { t } = i18nReact.useTranslation(['volume']);

  const [imageData, setImageData] = useState<string | null>(null);

  const imageSectionRef = useRef<HTMLDivElement>(null);

  const { scene, imageMap } = useContext(Section3DContext);

  const { data: metadata } = useMeasurementFileMetadata(measurement.measurementId, {
    configuration: {
      enabled: true,
    },
  });

  const measurementUrl = useMemo(() => {
    return `/api/measurementservice/v1/MeasurementView/file/${measurement.measurementId}/3d`;
  }, [measurement]);

  const cashedImageKey = useMemo(() => {
    return `${measurement.measurementId}_${measurement.volumeStartDepth}`;
  }, [measurement.measurementId, measurement.volumeStartDepth]);

  const invalidateCashedImage = useCallback(() => {
    const { measurementId } = measurement;

    const invalidKeys = [...imageMap.keys()].filter((key) => key.startsWith(measurementId));

    for (const invalidKey of invalidKeys) {
      imageMap.delete(invalidKey);
    }
  }, [measurement, imageMap]);

  useEffect(() => {
    if (!isDefined(scene) || !scene.isReady() || measurementUrl.length === 0) {
      return;
    }

    // checking required metadata
    if (!isDefined(metadata) || !isDefined(metadata.topDepth)) {
      return;
    }

    const measurementId = ensure(measurement.measurementId);
    if (imageMap.has(cashedImageKey)) {
      setImageData(imageMap.get(cashedImageKey));
      return;
    }

    // invalidating the image if it exists
    setImageData(null);

    const camera = scene.activeCamera as ArcRotateCamera;
    let onReadyObservable: Observer<Scene> | undefined;

    async function load3dModel() {
      try {
        assert(isDefined(scene), 'Scene should exist');
        assert(isDefined(metadata), 'Metadata should be defined');
        const token = await getAuthToken();
        enhance3dRequestsWithCustomHeaders(token);
        const { mesh } = await loadMesh(scene, measurementUrl, '', measurementId);
        scene.addMesh(mesh);
        const {
          boundingBox: { minimum, maximum },
        } = mesh.getBoundingInfo();
        const volumePosition = ensure(metadata.topDepth) - ensure(measurement.volumeStartDepth);
        mesh.material = new VolumeMaterial(`mat_${measurementId}`, scene, volumePosition);

        camera.setTarget(mesh, true);
        const radius = maximum.subtract(minimum).length() / 2;

        // recalculating distances to the bounds of the viewport in ORTHO mode
        const halfHeight = radius / 2;
        const { width: viewportWidth, height: viewportHeight } = camera.viewport;
        const width = CANVAS_WIDTH;
        const height = CANVAS_HEIGHT;
        const ratio = (width * viewportWidth) / (height * viewportHeight);
        const halfWidth = (ratio * radius) / 2;
        camera.orthoBottom = -halfHeight;
        camera.orthoTop = halfHeight;
        camera.orthoLeft = -halfWidth;
        camera.orthoRight = halfWidth;

        onReadyObservable = scene.onReadyObservable.addOnce((s) => {
          Tools.CreateScreenshot(s.getEngine(), camera, { height: CANVAS_HEIGHT, width: CANVAS_WIDTH }, (data) => {
            setImageData(data);
            invalidateCashedImage();
            imageMap.set(cashedImageKey, data);
          });
        });
      } catch (error) {
        // TODO make it handled
      }
    }
    load3dModel();

    return () => {
      onReadyObservable?.remove();
      [...scene.meshes].forEach((mesh) => mesh.dispose(false, true));
      camera.setTarget(Vector3.Zero());
    };
  }, [scene, measurement, measurementUrl, imageMap, metadata, cashedImageKey, invalidateCashedImage]);

  return (
    <SWrapper>
      <SImageContainer data-test-id={volumeStartImageVolumeExplorer} ref={imageSectionRef}>
        {isDefined(imageData) && <SImage src={imageData} key={cashedImageKey} />}
      </SImageContainer>
      <SLabel data-test-id={volumeStartLabelVolumeExplorer}>{`${t('volume:volumeLimitHeight')}: ${measurement.volumeStartDepth}m`}</SLabel>
    </SWrapper>
  );
};

const SWrapper = styled.div`
  margin-left: ${settings.Spacing.Spacing_600};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  row-gap: ${settings.Spacing.Spacing_50};
  padding: ${settings.Spacing.Spacing_400};
`;

const SImageContainer = styled.div`
  width: ${CANVAS_WIDTH / 2}px;
  height: ${CANVAS_HEIGHT / 2}px;
`;

const SImage = styled.img`
  width: 100%;
`;

const SLabel = styled.span`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.Small};
  color: ${settings.colors.Primary.Grey_8};
`;

Section3DView.whyDidYouRender = true;
export default React.memo(Section3DView);
