import { settings } from '@rhim/design';
import { ArrowRightIcon, HeatIcon } from '@rhim/icons/16';
import { heatLabelVolumeExplorer } from '@rhim/test-ids';
import { isDefined } from '@rhim/utils';
import React from 'react';
import styled from 'styled-components';

interface Props {
  heatFrom: number | undefined;
  heatTo?: number | undefined;
}
const Heat: React.ChildlessComponent<Props> = ({ heatFrom, heatTo }) => {
  return (
    <SWrapper>
      <HeatIcon />
      <SHeat data-test-id={heatLabelVolumeExplorer}>{heatFrom ?? '—'}</SHeat>
      {isDefined(heatTo) && (
        <>
          <ArrowRightIcon />
          <HeatIcon />
          <SHeat data-test-id={heatLabelVolumeExplorer}>{heatTo}</SHeat>
        </>
      )}
    </SWrapper>
  );
};

const SWrapper = styled.div`
  margin-top: ${settings.Spacing.Spacing_100};
  height: 32px;
  display: flex;
  align-items: center;
  column-gap: ${settings.Spacing.Spacing_50};
`;

const SHeat = styled.span`
  font-family: ${settings.typography.FontFamily.Bold};
  font-size: ${settings.typography.FontSize.Small};
  color: ${settings.colors.Primary.Grey_7};
`;

Heat.whyDidYouRender = true;
export default React.memo(Heat);
