import { settings } from '@rhim/design';
import { assert, isDefined } from '@rhim/utils';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useLocalization } from '../../hooks';
import { FormattedLength, MetricLengthUnit } from '../../partials';
import { Collapse, Panel } from '../Collapse';
import { Area, AreaRegion } from '../WallplotHeatmap/utils';
import { AreaPanelTexts } from './domain';
import { IconAverageThickness, IconCombinedMinimumAndAverageThickness, IconMinimumThickness } from './ThicknessIcons';
import { AreaItemGroup } from './types';
import { getAreaItemGroups } from './utils';

const ANTD_COLLAPSE_PANEL_ANIMATION_DELAY_MS = 250;

interface RegionBoundsSummaryProps {
  className?: string;
  startX: number;
  endX: number;
  startY: number;
  endY: number;
  xUnit: string;
  yUnit: MetricLengthUnit;
}
export const RegionBoundsSummary: FC<React.PropsWithChildren<RegionBoundsSummaryProps>> = ({ className, xUnit, yUnit, startX, endX, startY, endY }) => {
  const [localization] = useLocalization();
  const { unitSystem, locale } = localization;

  return (
    <SRegionBoundsSummary className={className}>
      {startX}
      {xUnit} - {endX}
      {xUnit} / <FormattedLength locale={locale} lengthValue={startY} sourceUnit={yUnit} targetUnitSystem={unitSystem} maximumFractionDigits={3} />-{' '}
      <FormattedLength locale={locale} lengthValue={endY} sourceUnit={yUnit} targetUnitSystem={unitSystem} maximumFractionDigits={3} />
    </SRegionBoundsSummary>
  );
};

const getAreasGroupMinimumThickness = (areaItemGroup: AreaItemGroup) => {
  return areaItemGroup.areaItems.reduce((cur, prev) => (cur.region.minimumThickness < prev.region.minimumThickness ? cur : prev)).region.minimumThickness;
};

interface AreaInfoCardProps {
  area: AreaRegion;
  xUnit: string;
  yUnit: MetricLengthUnit;
  texts: AreaPanelTexts;
}
const AreaInfoCard: FC<React.PropsWithChildren<AreaInfoCardProps>> = ({ area, xUnit, yUnit, texts }) => {
  const [localization] = useLocalization();
  const { unitSystem, locale } = localization;

  return (
    <SAreaInfoCardWrapper>
      <SAreaInfoCardGrid>
        <SIconMinimumThickness value={area.minimumThickness} />
        <SAreaInfoCardLabel>{texts.minimumThickness}</SAreaInfoCardLabel>
        <SAreaInfoCardValue>
          <FormattedLength
            locale={locale}
            lengthValue={area.minimumThickness}
            sourceUnit={MetricLengthUnit.mm}
            targetUnitSystem={unitSystem}
            maximumFractionDigits={3}
          />
        </SAreaInfoCardValue>
        <SIconAverageThickness value={area.averageThickness} />
        <SAreaInfoCardLabel>{texts.averageThickness}</SAreaInfoCardLabel>
        <SAreaInfoCardValue>
          <FormattedLength
            locale={locale}
            lengthValue={area.averageThickness}
            sourceUnit={MetricLengthUnit.mm}
            targetUnitSystem={unitSystem}
            maximumFractionDigits={3}
          />
        </SAreaInfoCardValue>
        <span></span>
        <SAreaInfoCardLabel>{texts.wear}</SAreaInfoCardLabel>
        <SAreaInfoCardValue>
          <FormattedLength locale={locale} lengthValue={area.wear} sourceUnit={MetricLengthUnit.mm} targetUnitSystem={unitSystem} maximumFractionDigits={3} />/
          {texts.heat}
        </SAreaInfoCardValue>
      </SAreaInfoCardGrid>
      <SAreaInfoCardFooterGrid>
        <span></span>
        <RegionBoundsSummary xUnit={xUnit} yUnit={yUnit} startX={area.startX} endX={area.endX} startY={area.startY} endY={area.endY} />
      </SAreaInfoCardFooterGrid>
    </SAreaInfoCardWrapper>
  );
};

interface AreaCollapseHeaderProps {
  title: string;
  valueMinimum: number;
  valueAverage: number;
}
const AreaCollapseHeader = React.forwardRef<HTMLDivElement, AreaCollapseHeaderProps>(function AreaInfoCard(
  { title, valueMinimum, valueAverage },
  forwardedRef
) {
  const [localization] = useLocalization();
  const { unitSystem, locale } = localization;

  return (
    <SAreaCollapseHeaderWrapper ref={forwardedRef}>
      <SIconCombinedMinimumAndAverageThickness valueMinimum={valueMinimum} valueAverage={valueAverage} />
      <SAreaCollapseHeaderTitle>{title}</SAreaCollapseHeaderTitle>
      <SHorizontalSpring />
      <SAreaCollapseHeaderMinimumValue>
        <FormattedLength
          locale={locale}
          lengthValue={valueMinimum}
          sourceUnit={MetricLengthUnit.mm}
          targetUnitSystem={unitSystem}
          maximumFractionDigits={3}
          showUnits={false}
        />
      </SAreaCollapseHeaderMinimumValue>
    </SAreaCollapseHeaderWrapper>
  );
});

export interface RegionPanelToFocus {
  regionId: number | null;
}

interface AreasInfoCardsProps {
  wallplotAreas: Area[];
  texts: AreaPanelTexts;
  focusedRegionPanel: RegionPanelToFocus;
}
const AreasInfoCards: FC<React.PropsWithChildren<AreasInfoCardsProps>> = ({ wallplotAreas, texts, focusedRegionPanel }) => {
  const areaItemGroups = getAreaItemGroups(wallplotAreas);
  const [localization] = useLocalization();
  const { unitSystem, locale } = localization;
  const [expandedRegionIds, setExpandedRegionIds] = useState<string[]>([]);
  const regionPanelsRefs = React.useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    const regionId = focusedRegionPanel.regionId;
    if (!isDefined(regionId)) {
      return;
    }
    if (!expandedRegionIds.includes(regionId.toString())) {
      setExpandedRegionIds([...expandedRegionIds, regionId.toString()]);
    }
    const timeoutId = setTimeout(() => {
      const regionPanel = regionPanelsRefs.current[regionId];
      assert(isDefined(regionPanel), `Region panel with id ${regionId} not set`);
      const antdCollapseItemContainer = regionPanel.parentElement?.parentElement;
      assert(isDefined(antdCollapseItemContainer), "Region panel's outmost collapse item not set");
      antdCollapseItemContainer.scrollIntoView({ behavior: 'smooth' });
    }, ANTD_COLLAPSE_PANEL_ANIMATION_DELAY_MS);

    return () => clearTimeout(timeoutId);
    // ignore the lint warning of including the "expandedRegionIds" as dependency.
    // (we do not want this effect to run when the user expands/collapses panels)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedRegionPanel]);

  const addToRegionPanelsRefs = (el: HTMLDivElement | null, regionId: number) => {
    if (el) {
      regionPanelsRefs.current[regionId] = el;
    }
  };

  const handleRegionToggled = (regionIds: string | string[]) => {
    if (typeof regionIds === 'string') {
      setExpandedRegionIds([regionIds]);
    } else {
      setExpandedRegionIds(regionIds);
    }
  };
  const xUnit = '°';
  return (
    <>
      {areaItemGroups.map((areaItemGroup, index) => (
        <SAreaItemGroup key={index}>
          <SAreaItemGroupHeader>
            <SAreaItemGroupTitle>
              {texts.zoneNames[areaItemGroup.groupName] ?? 'Zone name missing'} {texts.line}
            </SAreaItemGroupTitle>
            <SHorizontalSpring />
            <SAreaItemGroupMinimumValue>
              <FormattedLength
                locale={locale}
                lengthValue={getAreasGroupMinimumThickness(areaItemGroup)}
                sourceUnit={MetricLengthUnit.mm}
                targetUnitSystem={unitSystem}
                maximumFractionDigits={3}
                showUnits={false}
              />
            </SAreaItemGroupMinimumValue>
          </SAreaItemGroupHeader>
          <Collapse activeKey={expandedRegionIds} onChange={handleRegionToggled}>
            {areaItemGroup.areaItems.map((areaItem) => (
              <Panel
                header={
                  <AreaCollapseHeader
                    title={texts.areaNames[areaItem.areaName] ?? 'Area name missing'}
                    valueMinimum={areaItem.region.minimumThickness}
                    valueAverage={areaItem.region.averageThickness}
                    ref={(el) => addToRegionPanelsRefs(el, areaItem.region.regionId)}
                  />
                }
                key={areaItem.region.regionId.toString()}
              >
                <AreaInfoCard area={areaItem.region} xUnit={xUnit} yUnit={MetricLengthUnit.m} texts={texts} />
              </Panel>
            ))}
          </Collapse>
        </SAreaItemGroup>
      ))}
    </>
  );
};

export default React.memo(AreasInfoCards);

const SAreaItemGroup = styled.div`
  &:not(:first-of-type) {
    margin-top: -1px;
    border-top: 1px solid ${settings.colors.Primary.Grey_4};
  }
`;

const SAreaItemGroupHeader = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  background-color: ${settings.colors.Primary.Grey_1};
  color: ${settings.colors.Primary.Grey_8};
`;

const SAreaItemGroupTitle = styled.span`
  margin-left: ${settings.Spacing.Spacing_200};
  font-family: ${settings.typography.FontFamily.Bold};
  font-size: ${settings.typography.FontSize.Small};
`;

const SAreaItemGroupMinimumValue = styled.span`
  margin-right: 33px;
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.X_Small};
`;

const SAreaCollapseHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${settings.colors.Primary.Grey_8};
`;

const SAreaCollapseHeaderTitle = styled.span`
  font-family: ${settings.typography.FontFamily.Bold};
  font-size: ${settings.typography.FontSize.Small};
`;

const SHorizontalSpring = styled.span`
  flex-grow: 1;
`;

const SAreaCollapseHeaderMinimumValue = styled.span`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.X_Small};
`;

const SAreaInfoCardWrapper = styled.div`
  margin-left: 5px;

  --firstColumnWidthPx: 36px;
`;

const SAreaInfoCardGrid = styled.div`
  display: grid;
  grid-template-columns: var(--firstColumnWidthPx) auto auto;
  row-gap: ${settings.Spacing.Spacing_100};
`;

const SAreaInfoCardFooterGrid = styled.div`
  display: grid;
  grid-template-columns: var(--firstColumnWidthPx) auto;
  margin-top: ${settings.Spacing.Spacing_200};
`;

const SAreaInfoCardLabel = styled.span`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.Small};
  color: ${settings.colors.Primary.Grey_8};
`;

const SAreaInfoCardValue = styled.span`
  font-family: ${settings.typography.FontFamily.Bold};
  font-size: ${settings.typography.FontSize.Small};
  color: ${settings.colors.Primary.Grey_8};
`;

const SIconMinimumThickness = styled(IconMinimumThickness)`
  align-self: center;
`;

const SIconAverageThickness = styled(IconAverageThickness)`
  align-self: center;
`;

const SIconCombinedMinimumAndAverageThickness = styled(IconCombinedMinimumAndAverageThickness)`
  margin-right: 18px;
`;

const SRegionBoundsSummary = styled.div`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.X_Small};
  color: ${settings.colors.Primary.Grey_8};
`;
