import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const FolderFilledIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path fillRule="evenodd" clipRule="evenodd" d="M0 2h7.414l2 2H16v10H0V2z" fill={fill} />
    </svg>
  );
};

FolderFilledIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Grey_8,
};
