import { ChevronDownIcon, ChevronUpIcon } from '@rhim/icons/16';
import React, { FC, ReactNode, useCallback, useState } from 'react';
import styled from 'styled-components';

import { IconButton } from '../IconButton';

interface Props {
  className?: string;
  heightCollapsed: number;
  heightExpanded: number;
  children: ReactNode;
}
const CollapsePanel: FC<React.PropsWithChildren<Props>> = ({ className, heightCollapsed, heightExpanded, children }) => {
  const [isExpanded, setExpanded] = useState(false);

  const handleToggleCollapse = useCallback(() => {
    setExpanded(!isExpanded);
  }, [isExpanded]);

  return (
    <SWrapper className={className} height={isExpanded ? heightExpanded : heightCollapsed} onClick={handleToggleCollapse}>
      <IconButton icon={isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />} onPress={handleToggleCollapse} />
      {children}
    </SWrapper>
  );
};
CollapsePanel.whyDidYouRender = true;
export default React.memo(CollapsePanel);

const SWrapper = styled.div<{ height: number }>`
  display: flex;
  height: ${(props) => props.height}px;
`;
