import { DndContext, useDroppable } from '@dnd-kit/core';
import { DragEndEvent } from '@dnd-kit/core/dist/types';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { RHIMOperatorDisplayServiceV1ModelsRegionOrderAndVisibilityDto } from '@rhim/rest/operatorDisplay';
import React from 'react';
import { ListActions } from 'react-use/lib/useList';

import { Region } from './Region';

interface ListProps {
  regions: RHIMOperatorDisplayServiceV1ModelsRegionOrderAndVisibilityDto[];
  callback: (region: RHIMOperatorDisplayServiceV1ModelsRegionOrderAndVisibilityDto, disable: boolean) => void;
}

interface WrapperProps {
  regions: RHIMOperatorDisplayServiceV1ModelsRegionOrderAndVisibilityDto[];
  actions: ListActions<RHIMOperatorDisplayServiceV1ModelsRegionOrderAndVisibilityDto>;
  setActive: (value: boolean) => void;
}

const ListSortWrapper: React.FunctionComponent<React.PropsWithChildren<WrapperProps>> = ({ regions, actions, setActive }: WrapperProps) => {
  const className = 'list-sort-demo';

  const onClick = (region: RHIMOperatorDisplayServiceV1ModelsRegionOrderAndVisibilityDto, disable: boolean) => {
    const newRegions = regions.map((item: RHIMOperatorDisplayServiceV1ModelsRegionOrderAndVisibilityDto) => {
      if (item.regionId === region.regionId) {
        return { ...item, display: !disable };
      }
      return item;
    });

    actions.set(newRegions);
    setActive(true);
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (!over) {
      return;
    }

    if (active.id !== over.id) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const activeIndex = active.data.current?.sortable.index as number;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const overIndex = (over.data.current?.sortable.index ?? 0) as number;
      const activeItem = regions[activeIndex];
      actions.removeAt(activeIndex);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      actions.insertAt(overIndex, activeItem!);
      setActive(true);
    }
  };

  return (
    <div className={`${className}-wrapper`}>
      <div className={className}>
        <DndContext onDragEnd={onDragEnd}>
          <ListSort regions={regions} callback={onClick} />
        </DndContext>
      </div>
    </div>
  );
};

const ListSort: React.FunctionComponent<React.PropsWithChildren<ListProps>> = ({ regions, callback }: ListProps) => {
  const id = 'list-sort';
  const { setNodeRef } = useDroppable({ id: id });

  return (
    <SortableContext id={id} items={regions.map((region) => region.regionId)} strategy={verticalListSortingStrategy}>
      <div ref={setNodeRef}>
        {regions.map((region, index) => (
          <Region key={index} region={region} callback={callback} />
        ))}
      </div>
    </SortableContext>
  );
};

export default React.memo(ListSortWrapper);
