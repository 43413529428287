import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import axios from 'axios';
import * as React from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

import ErrorPanel from '../../components/ErrorPanel/ErrorPanel';
import MeasurementUploads from './MeasurementUploads';

interface Props {
  customerId: string;
}

const MeasurementDataGateway = ({ customerId }: Props) => {
  const { reset } = useQueryErrorResetBoundary();

  const FallbackComponent: React.FunctionComponent<FallbackProps> = React.useCallback(({ error, resetErrorBoundary }) => {
    return <ErrorPanel error={axios.isAxiosError(error) ? error.response?.data : error} resetErrorBoundary={resetErrorBoundary} />;
  }, []);

  return (
    <ErrorBoundary FallbackComponent={FallbackComponent} resetKeys={[customerId]} onReset={reset}>
      <MeasurementUploads customerId={customerId} />
    </ErrorBoundary>
  );
};

export default React.memo(MeasurementDataGateway);
