import { RHIMMeasurementServiceV1ModelsMeasurementDto } from '@rhim/rest/measurementService';
import { isDefined, specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api/measurementService';

type Payload = RHIMMeasurementServiceV1ModelsMeasurementDto | null;

export function useReferenceMeasurements(
  vesselId?: string,
  campaign?: number,
  heat?: number,
  measurementTaken?: string,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) {
  return useQuery(getKey(vesselId, campaign, heat, measurementTaken), (context) => queryFn(context, options?.axiosConfiguration), {
    ...options?.configuration,
    // Dependent query : to get the reference measurements, we have to have the vesselId and campaign.
    // Do not execute until this is provided.
    enabled: isDefined(vesselId) && isDefined(campaign),
  });
}

const getKey = (vesselId?: string, campaign?: number, heat?: number, measurementTaken?: string) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'measurementService',
      scope: 'referenceMeasurements',
      entity: 'detail',
      vesselId,
      campaign,
      heat,
      measurementTaken,
    },
  ]);

const queryFn = (
  { queryKey: [{ vesselId, campaign, heat, measurementTaken }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>,
  axiosConfiguration?: AxiosRequestConfig
) => {
  return API.referenceMeasurements
    .getReferencemeasurementsVesselidCampaign(vesselId ?? '', campaign ?? 0, heat, measurementTaken, { signal, ...axiosConfiguration })
    .then((response) => {
      // If the response's status is 204 (No Content) it means there is no reference measurement
      return response.status === 204 ? null : response.data;
    });
};

useReferenceMeasurements.getKey = getKey;
useReferenceMeasurements.queryFn = queryFn;
