import { MouseEventHandler, ReactNode, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';

interface Props {
  orientation?: 'vertical' | 'horizontal';
  fixedChild?: 'first' | 'second';
  fixedSizePx?: number;
  minSizePx?: number;
  separatorSizePx?: number;
  separatorDragColor?: string;
  children: [ReactNode, ReactNode];
}

const defaultProps: Omit<Props, 'children'> = {
  orientation: 'vertical',
  fixedChild: 'first',
  fixedSizePx: 500,
  minSizePx: 300,
  separatorSizePx: 4,
  separatorDragColor: '',
};

/**
 *  This split pane supports two children,
 *  and can be used to split a window vertically or horizontally.
 *
 * The behaviour is very similar to how VS Code handles the sidebar width vs the code area, when entire window is resized.
 * */
const SplitPane: React.FunctionComponent<Props> = (props) => {
  const parentDiv = useRef<HTMLDivElement>(null);
  const firstChild = useRef<HTMLDivElement>(null);
  const secondChild = useRef<HTMLDivElement>(null);
  const separator = useRef<HTMLDivElement>(null);
  const dragging = useRef<boolean>(false);

  const calculateAndSetFixedPart = useCallback(
    (mouseX?: number, mouseY?: number) => {
      if (!parentDiv.current || !firstChild.current || !secondChild.current) {
        return;
      }
      const parentBounds = parentDiv.current.getBoundingClientRect();
      const halfOfSeparatorSize = props.separatorSizePx !== undefined ? props.separatorSizePx / 2 : 0;

      if (props.fixedSizePx === undefined || props.separatorSizePx === undefined || props.minSizePx === undefined) {
        return;
      }

      if (props.orientation === 'horizontal') {
        if (props.fixedChild === 'first') {
          // calculate distance between the left border and the mouse pointer
          const size = mouseX === undefined ? props.fixedSizePx : mouseX - parentBounds.left - halfOfSeparatorSize;
          const sizeLeftForOtherChild = parentBounds.width - size - props.separatorSizePx;
          // only update size if there is enough space left for the other child
          if (sizeLeftForOtherChild > props.minSizePx) {
            firstChild.current.style.width = `${size}px`; // width since horizontal
          }
        } else {
          // calculate distance between the right border and the mouse pointer
          const size = mouseX === undefined ? props.fixedSizePx : parentBounds.right - mouseX - halfOfSeparatorSize;
          const sizeLeftForOtherChild = parentBounds.width - size - props.separatorSizePx;
          // only update size if there is enough space left for the other child
          if (sizeLeftForOtherChild > props.minSizePx) {
            secondChild.current.style.width = `${size}px`; // width since horizontal
          }
        }
      } else {
        // vertical orientation:
        if (props.fixedChild === 'first') {
          // calculate distance between the top and the mouse pointer
          const size = mouseY === undefined ? props.fixedSizePx : mouseY - parentBounds.top - halfOfSeparatorSize;
          const sizeLeftForOtherChild = parentBounds.height - size - props.separatorSizePx;
          // only update size if there is enough space left for the other child
          if (sizeLeftForOtherChild > props.minSizePx) {
            firstChild.current.style.height = `${size}px`; //  height since vertical
          }
        } else {
          // calculate distance between the bottom and the mouse pointer
          const size = mouseY === undefined ? props.fixedSizePx : parentBounds.bottom - mouseY - halfOfSeparatorSize;
          const sizeLeftForOtherChild = parentBounds.height - size - props.separatorSizePx;
          // only update size if there is enough space left for the other child
          if (sizeLeftForOtherChild > props.minSizePx) {
            secondChild.current.style.height = `${size}px`; //  height since vertical
          }
        }
      }
    },
    [props.separatorSizePx, props.fixedSizePx, props.minSizePx, props.orientation, props.fixedChild]
  );

  // Calculate fixed part size on mount:
  useEffect(() => calculateAndSetFixedPart(), [calculateAndSetFixedPart]);

  const updateSeparatorColor = useCallback(() => {
    if (separator.current === null || props.separatorDragColor === undefined) {
      return;
    }
    if (dragging.current) {
      separator.current.style.backgroundColor = props.separatorDragColor;
    } else {
      separator.current.style.backgroundColor = '';
    }
  }, [props.separatorDragColor]);

  // Let entire document trigger onMouseUp, onMouseLeave and onMouseMove:
  const mouseUp = useCallback(
    (_e: MouseEvent) => {
      dragging.current = false;
      updateSeparatorColor();
      document.body.style.userSelect = 'auto';
    },
    [updateSeparatorColor]
  );
  const mouseLeave = useCallback(
    (_e: MouseEvent) => {
      dragging.current = false;
      updateSeparatorColor();
      document.body.style.userSelect = 'auto';
    },
    [updateSeparatorColor]
  );
  const mouseMove = useCallback((e: MouseEvent) => dragging.current && calculateAndSetFixedPart(e.clientX, e.clientY), [calculateAndSetFixedPart]);

  useEffect(() => {
    document.addEventListener('mouseup', mouseUp);
    document.addEventListener('mouseleave', mouseLeave);
    document.addEventListener('mousemove', mouseMove);

    return () => {
      document.removeEventListener('mouseup', mouseUp);
      document.removeEventListener('mouseleave', mouseLeave);
      document.removeEventListener('mousemove', mouseMove);
      document.body.style.userSelect = 'auto';
    };
  }, [mouseLeave, mouseMove, mouseUp]);

  // Let only the draggable separator trigger onMouseDown:
  const mouseDown: MouseEventHandler<HTMLDivElement> = useCallback(() => {
    dragging.current = true;
    updateSeparatorColor();
    document.body.style.userSelect = 'none';
  }, [updateSeparatorColor]);

  return (
    <Wrapper ref={parentDiv} {...props}>
      <FirstChildWrapper ref={firstChild} {...props}>
        {props.children[0]}
      </FirstChildWrapper>
      <DraggableSeparator ref={separator} onMouseDown={mouseDown} orientation={props.orientation} separatorSizePx={props.separatorSizePx}></DraggableSeparator>
      <SecondChildWrapper ref={secondChild} {...props}>
        {props.children[1]}
      </SecondChildWrapper>
    </Wrapper>
  );
};
SplitPane.defaultProps = defaultProps;

const Wrapper = styled.div<Pick<Props, 'orientation'>>`
  height: 100%;
  display: flex;
  flex-direction: ${(props) => (props.orientation === 'vertical' ? 'column' : 'row')};
  overflow: hidden;
`;
Wrapper.defaultProps = defaultProps;

const ChildWrapper = styled.div<Pick<Props, 'minSizePx'>>`
  width: auto;
  height: auto;
  display: grid;
  min-width: ${(props) => props.minSizePx}px;
  min-height: ${(props) => props.minSizePx}px;
`;
ChildWrapper.defaultProps = defaultProps;

const FirstChildWrapper = styled(ChildWrapper)<Pick<Props, 'fixedChild'>>`
  flex: ${(props) => (props.fixedChild === 'first' ? '' : '1')};
`;
FirstChildWrapper.defaultProps = defaultProps;

const SecondChildWrapper = styled(ChildWrapper)<Pick<Props, 'fixedChild'>>`
  flex: ${(props) => (props.fixedChild === 'second' ? '' : '1')};
`;
SecondChildWrapper.defaultProps = defaultProps;

const DraggableSeparator = styled.div<Pick<Props, 'orientation' | 'separatorSizePx'>>`
  width: ${(props) => (props.orientation === 'horizontal' ? `${props.separatorSizePx}px` : 'auto')};
  height: ${(props) => (props.orientation === 'horizontal' ? 'auto' : `${props.separatorSizePx}px`)};
  cursor: ${(props) => (props.orientation === 'horizontal' ? 'col-resize' : 'row-resize')};
`;
DraggableSeparator.defaultProps = defaultProps;

SplitPane.whyDidYouRender = true;
export default SplitPane;
