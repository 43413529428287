import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const MeasurementConfigIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        d="M18.6 12a.6.6 0 0 1 .586.47l.208.938c.302.092.592.213.866.36l.812-.517a.6.6 0 0 1 .746.082l.849.849a.6.6 0 0 1 .082.746l-.517.812c.147.274.268.564.36.866l.938.209c.275.06.47.304.47.585v1.2a.6.6 0 0 1-.47.586l-.938.208a4.776 4.776 0 0 1-.36.866l.517.812a.6.6 0 0 1-.082.746l-.849.849a.596.596 0 0 1-.746.082l-.812-.516a4.845 4.845 0 0 1-.866.359l-.208.938a.6.6 0 0 1-.586.47h-1.2a.6.6 0 0 1-.586-.47l-.208-.938a4.845 4.845 0 0 1-.866-.36l-.812.517a.6.6 0 0 1-.746-.082l-.849-.85a.6.6 0 0 1-.082-.745l.517-.812a4.776 4.776 0 0 1-.36-.866l-.938-.208A.6.6 0 0 1 12 18.6v-1.2c0-.281.195-.524.47-.585l.938-.21c.092-.301.213-.591.36-.865l-.517-.812a.6.6 0 0 1 .082-.746l.849-.85a.599.599 0 0 1 .746-.081l.812.517c.274-.147.564-.268.866-.36l.208-.938A.6.6 0 0 1 17.4 12zM9 2c1.053 0 1.918.817 1.995 1.85L11 4v10.123A7.964 7.964 0 0 0 10 18c0 1.115.228 2.177.64 3.141-.333.48-.873.808-1.49.854L9 22H5a2.002 2.002 0 0 1-1.995-1.85L3 20V4c0-1.053.817-1.918 1.85-1.995L5 2h4zm9 13.6a2.4 2.4 0 1 0-.001 4.799 2.4 2.4 0 0 0 .001-4.8zM9 4H5v16h4.001v-1H8a1 1 0 0 1-.117-1.993L8 17h1.001v-2H8a1 1 0 0 1-.117-1.993L8 13h1.001L9 11H8a1 1 0 0 1-.117-1.993L8 9h1V7H8a1 1 0 0 1-.117-1.993L8 5h1V4zm8.613-1.79.094.083 3 3a.999.999 0 0 1-1.312 1.503l-.102-.089L18 5.414V10c-.69 0-1.36.087-2 .252V5.414l-1.293 1.293a.999.999 0 0 1-1.497-1.32l.083-.094 3-3a.999.999 0 0 1 1.32-.083z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
};

MeasurementConfigIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Grey_10,
};
