import { settings } from '@rhim/design';
import { ensure } from '@rhim/utils';

// Create a 2d canvas to use for measuring text dimensions
let ctx: CanvasRenderingContext2D | null;
function measureText(text: string, fontSize: number, fontFamily: settings.typography.FontFamily) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  ctx = ctx ?? window.document.createElement('canvas').getContext('2d')!;
  ctx.font = `${fontSize}px ${fontFamily}`;
  const textMetrics = ctx.measureText(text);
  return {
    width: textMetrics.actualBoundingBoxRight + textMetrics.actualBoundingBoxLeft,
    height: textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent,
  };
}

const FONT_MARGIN_WEIGHT = 0.4; // the larger the number, the larger the margin the text will have from its boundaries
export const getFontSizeThatFits = (cellSize: [number, number], textToFit: string, fontFamily: settings.typography.FontFamily, fontSize = 1): number => {
  const metrics = measureText(textToFit, fontSize, fontFamily);
  const textMargin = fontSize * FONT_MARGIN_WEIGHT;
  if (metrics.width + textMargin < cellSize[0] && metrics.height + textMargin < cellSize[1]) {
    return getFontSizeThatFits(cellSize, textToFit, fontFamily, ++fontSize);
  }
  return fontSize - 1;
};

/**
 * Scales the canvas by the given scale factors
 *
 * @param canvas
 * @param scaleX
 * @param scaleY
 *
 * @returns HTMLCanvasElement the scaled canvas
 */
export function scaleCanvas(canvas: HTMLCanvasElement, scaleX = 1, scaleY = 1): HTMLCanvasElement {
  const flippedCanvas = document.createElement('canvas');
  const flippedCtx = ensure(flippedCanvas.getContext('2d'));
  flippedCanvas.width = canvas.width;
  flippedCanvas.height = canvas.height;
  flippedCtx.save();
  flippedCtx.translate(flippedCanvas.width / 2, flippedCanvas.height / 2);
  flippedCtx.scale(scaleX, scaleY);
  flippedCtx.translate(-flippedCanvas.width / 2, -flippedCanvas.height / 2);
  flippedCtx.drawImage(canvas, 0, 0, flippedCanvas.width, flippedCanvas.height);
  flippedCtx.restore();

  return flippedCanvas;
}
