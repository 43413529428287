import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const GearIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g id="S-Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons" transform="translate(-366.000000, -1019.000000)" fill={fill}>
          <g id="Icons/Gear_16" transform="translate(366.000000, 1019.000000)">
            <path
              d="M8,11.2 C6.2328,11.2 4.8,9.7672 4.8,8 C4.8,6.2328 6.2328,4.8 8,4.8 C9.7672,4.8 11.2,6.2328 11.2,8 C11.2,9.7672 9.7672,11.2 8,11.2 M15.3736,6.4192 L14.1224,6.1416 C14,5.7384 13.8392,5.352 13.6432,4.9864 L14.332,3.904 C14.5336,3.588 14.488,3.1744 14.2224,2.9088 L13.0912,1.7776 C12.8264,1.512 12.412,1.4664 12.096,1.668 L11.0136,2.3568 C10.648,2.1608 10.2616,2 9.8584,1.8776 L9.5808,0.6264 C9.5,0.2608 9.1752,0 8.8,0 L7.2,0 C6.8248,0 6.5,0.2608 6.4192,0.6264 L6.1416,1.8776 C5.7384,2 5.352,2.1608 4.9864,2.3568 L3.904,1.668 C3.5864,1.4664 3.1736,1.512 2.9088,1.7776 L1.7776,2.9088 C1.512,3.1744 1.4664,3.588 1.668,3.904 L2.3568,4.9864 C2.1608,5.352 2,5.7384 1.8776,6.1416 L0.6264,6.4192 C0.2608,6.5 0,6.8248 0,7.2 L0,8.8 C0,9.1752 0.2608,9.5 0.6264,9.5808 L1.8776,9.8584 C2,10.2616 2.1608,10.648 2.3568,11.0136 L1.668,12.096 C1.4664,12.412 1.512,12.8256 1.7776,13.0912 L2.9088,14.2224 C3.0632,14.3776 3.268,14.4568 3.4752,14.4568 C3.6232,14.4568 3.772,14.416 3.904,14.332 L4.9864,13.6432 C5.352,13.8392 5.7384,14 6.1416,14.1224 L6.4192,15.3736 C6.5,15.7392 6.8248,16 7.2,16 L8.8,16 C9.1752,16 9.5,15.7392 9.5808,15.3736 L9.8584,14.1224 C10.2616,14 10.648,13.8392 11.0136,13.6432 L12.096,14.332 C12.228,14.416 12.3768,14.4568 12.5248,14.4568 C12.732,14.4568 12.9368,14.3776 13.0912,14.2224 L14.2224,13.0912 C14.488,12.8256 14.5336,12.412 14.332,12.096 L13.6432,11.0136 C13.8392,10.648 14,10.2616 14.1224,9.8584 L15.3736,9.5808 C15.7392,9.5 16,9.1752 16,8.8 L16,7.2 C16,6.8248 15.7392,6.5 15.3736,6.4192"
              id="Fill-1"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

GearIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
