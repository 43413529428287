import { Button, ModalDialog, Paragraph } from '@rhim/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends Omit<React.ComponentProps<typeof ModalDialog>, 'mode' | 'maskClosable' | 'headline' | 'description' | 'buttonPrimary' | 'closable'> {
  visible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const DeleteWarningModal: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ visible, onConfirm, onCancel, ...rest }) => {
  const { t } = useTranslation(['ingress', 'app']);

  return (
    <ModalDialog
      open={visible}
      mode="alert"
      maskClosable={false}
      onCancel={onCancel}
      headline={t('ingress:delete.headline')}
      closable={true}
      description={<Paragraph style={{ marginBottom: '16px' }}>{t('ingress:delete.description')}</Paragraph>}
      buttonCancel={<Button size="small-40" onClick={onCancel} label={t('app:core.cancel')} />}
      buttonPrimary={<Button size="small-40" onClick={onConfirm} label={t('ingress:delete.button')} />}
      {...rest}
    />
  );
};
