import { assert, isDefined } from '@rhim/utils';
import { bisector } from 'd3-array';

export interface Point {
  x: number;
  y: number;
}

export const bisectX = bisector<Point, number>((d) => d.x).right;

/**
 * Gets the index and x0 value for a given x coordinate
 * @param x0 Coordinate value.
 * @param points array of points.
 * @returns object Returns the index and the x0 value;
 */
export const getXCoordIndex = (x0: number, points: Point[]) => {
  const idx = bisectX(points, x0, 1);

  return { idx, x0 };
};

/**
 * Gets the point based on a x coordinate value.
 * @param x Coordinate value.
 * @param points array of points.
 * @returns point object from the domain range.
 */
export const getIntersectionPoint = (x: number, points: Point[]): Point => {
  const { idx, x0 } = getXCoordIndex(x, points);

  const d0 = points[idx - 1];
  const d1 = points[idx];

  assert(isDefined(d0));

  let d = d0;
  if (isDefined(d1) && isDefined(d.x)) {
    d = Math.abs(x0.valueOf() - d0.x.valueOf()) > Math.abs(x0.valueOf() - d1.x.valueOf()) ? d1 : d0;
  }

  return d;
};
