/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { AppStateMissingIcon } from '@rhim/icons/32';
import { MaintenancePanel, Switch } from '@rhim/react';
import {
  RHIMProcessDataServiceV1ModelsCampaignComparisonDto,
  RHIMProcessDataServiceV1ModelsParameterCampaignValues,
  RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto,
} from '@rhim/rest/processData';
import {
  chartContainerParametersComparison,
  legendFirstCampaignLabelParametersComparison,
  legendLabelParametersComparison,
  legendSecondCampaignLabelParametersComparison,
  trimToggleParametersComparison,
  violinChartParametersComparison,
} from '@rhim/test-ids';
import { assert, hasElements, isDefined } from '@rhim/utils';
import { ParentSize } from '@visx/responsive';
/* import-sort-ignore */
// eslint-disable-next-line no-restricted-imports
import { message } from 'antd';
import * as React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import styled from 'styled-components';

import Violin from '../../components/Violin/Violin';
import { WearParamsGraph } from '../../components/WearParamsGraph';
import { useZoom, ZOOM_INIT, ZoomUI } from '../../components/Zoom';

const VIOLIN_WIDTH = 200;

interface Props {
  vesselName?: JSX.Element;
  comparedVesselName?: JSX.Element;
  scrollbarRef: React.RefObject<Scrollbars>;
  param: RHIMProcessDataServiceV1ModelsParameterCampaignValues;
  parameterRanges?: RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto;
  currentCampaign?: RHIMProcessDataServiceV1ModelsCampaignComparisonDto;
  prevCampaign?: RHIMProcessDataServiceV1ModelsCampaignComparisonDto;
  sameCampaign: boolean;
}

const ParameterPanel = ({ parameterRanges, currentCampaign, prevCampaign, param, scrollbarRef, sameCampaign, vesselName, comparedVesselName }: Props) => {
  const { t } = i18nReact.useTranslation(['parameters-comparison']);

  const currentData = ((currentCampaign || {}).parameters || []).find((parameter) => parameter.name === param.name);
  const prevData = ((prevCampaign || {}).parameters || []).find((prevParam) => prevParam.name === param.name);
  const currentCampaignNr = isDefined(currentCampaign) ? currentCampaign.campaign : 0;
  const prevCampaignNr = isDefined(prevCampaign) ? prevCampaign.campaign : 0;
  const [toggle, setToggle] = React.useState(false);

  const setScrollPositions = React.useCallback(
    (scrollPosition: number | null) => {
      assert(isDefined(scrollPosition), 'scrollPosition is required to sync scrollbars');

      assert(isDefined(scrollbarRef), `scrollbarRef for parameter ${param.name} required to sync scrollbars`);
      scrollbarRef.current?.scrollLeft(scrollPosition);
    },
    [scrollbarRef, param]
  );
  const {
    setZoomLevel,
    handleZoom,
    handleZoomRangeChange,
    handleZoomRangeDragStart,
    handleZoomRangeDragEnd,
    hoverPosition,
    isDragTarget,
    setHoverPosition,
    resetZoom,
    zoomLevel,
    zoomRange,
    setZoomRange,
  } = useZoom({ scrollbarRef, setScrollPositions });

  React.useEffect(() => {
    setZoomLevel(ZOOM_INIT);
  }, [param, setZoomLevel]);

  const onToggle = (value: boolean) => {
    if (value) {
      if (
        isDefined(parameterRanges) &&
        isDefined(parameterRanges.min) &&
        isDefined(parameterRanges.max) &&
        (parameterRanges.outerLimit || parameterRanges.innerLimit)
      ) {
        setToggle(value);
      } else if (
        isDefined(parameterRanges) &&
        isDefined(parameterRanges.min) &&
        isDefined(parameterRanges.max) &&
        !parameterRanges.outerLimit &&
        !parameterRanges.innerLimit
      ) {
        setToggle(value);
        message.warning(t('parameters-comparison:noOutliers'));
      } else {
        message.error(t('parameters-comparison:noRanges'));
      }
    } else {
      setToggle(value);
    }
  };

  return (
    <ParentSize>
      {(parent) => {
        const parentWidth = parent.width;
        const initialChartWidth = getChartWidth(parentWidth);
        const chartWidth: number = getChartWidth(parentWidth) * zoomLevel;

        return isDefined(param) &&
          (!isDefined(param.values) || !hasElements(param.values) || (hasElements(param.values) && param.values.every((item) => !isDefined(item.value)))) &&
          (!isDefined(prevData) ||
            (isDefined(prevData) &&
              (!isDefined(prevData.values) ||
                (isDefined(prevData.values) && !hasElements(prevData.values)) ||
                (hasElements(prevData.values) && prevData.values.every((item) => !isDefined(item.value)))))) ? (
          <div data-test-id={chartContainerParametersComparison}>
            <MaintenancePanel
              size="small"
              heading={t('parameters-comparison:errors.noDataAvailable.heading')}
              subHeading={t('parameters-comparison:errors.noDataAvailable.subHeading')}
              headerColor={settings.colors.Operational.State_Notif_Grey_2}
              subheaderColor={settings.colors.Operational.State_Notif_Grey_2}
              icon={<AppStateMissingIcon />}
            />
          </div>
        ) : (
          <div data-test-id={chartContainerParametersComparison} style={{ display: 'inline-flex' }}>
            <GraphContainer width={initialChartWidth}>
              <LegendContainer width={initialChartWidth}>
                <Info>
                  <ZoomUI onReset={resetZoom} onZoom={handleZoom} zoomLevel={zoomLevel} isVisible={!isDefined(zoomRange)} />
                </Info>
                <Info>
                  {isDefined(currentData) && (
                    <>
                      <CampaignInfo data-test-id={legendFirstCampaignLabelParametersComparison} color={settings.colors.Primary.Blue_8}>
                        {isDefined(vesselName) ? (
                          <>
                            {vesselName}/{currentCampaignNr}
                          </>
                        ) : (
                          currentCampaignNr
                        )}
                      </CampaignInfo>
                      <Wrapper>
                        <Circle color={settings.colors.Primary.Blue_8} />
                        {/* eslint-disable-next-line i18next/no-literal-string */}
                        <Text data-test-id={legendLabelParametersComparison}>{t('parameters-comparison:infoBar.raw')}</Text>
                      </Wrapper>
                      <Wrapper>
                        <Line color={settings.colors.Primary.Blue_8} />
                        {/* eslint-disable-next-line i18next/no-literal-string */}
                        <Text data-test-id={legendLabelParametersComparison}>{t('parameters-comparison:infoBar.movingAvg')}</Text>
                      </Wrapper>
                    </>
                  )}
                  {isDefined(prevCampaign) && !sameCampaign && (
                    <>
                      <Wrapper>
                        <ColorDiv color={settings.colors.Comparison.Higher} />
                        {/* eslint-disable-next-line i18next/no-literal-string */}
                        <Text data-test-id={legendLabelParametersComparison}>{t('parameters-comparison:infoBar.higher')} /</Text>
                      </Wrapper>

                      <Wrapper>
                        <ColorDiv color="#f5c48e" />
                        {/* eslint-disable-next-line i18next/no-literal-string */}
                        <Text data-test-id={legendLabelParametersComparison}>
                          {t('parameters-comparison:infoBar.lowerThan')} {prevCampaignNr}
                        </Text>
                      </Wrapper>
                      <Separator data-test-id={legendLabelParametersComparison}>/</Separator>
                      <CampaignInfo data-test-id={legendSecondCampaignLabelParametersComparison} color={settings.colors.Primary.Grey_6}>
                        {isDefined(comparedVesselName) ? (
                          <>
                            {comparedVesselName}/{prevCampaignNr}
                          </>
                        ) : (
                          prevCampaignNr
                        )}
                      </CampaignInfo>
                      <Wrapper>
                        <Circle color={settings.colors.Primary.Grey_6} />
                        {/* eslint-disable-next-line i18next/no-literal-string */}
                        <Text data-test-id={legendLabelParametersComparison}>{t('parameters-comparison:infoBar.raw')}</Text>
                      </Wrapper>
                      <Wrapper>
                        <Line color={settings.colors.Primary.Grey_6} />
                        {/* eslint-disable-next-line i18next/no-literal-string */}
                        <Text data-test-id={legendLabelParametersComparison}>{t('parameters-comparison:infoBar.movingAvg')}</Text>
                      </Wrapper>
                    </>
                  )}
                </Info>
              </LegendContainer>
              <Graphs>
                <WearParamsGraph
                  initialChartWidth={initialChartWidth}
                  width={chartWidth}
                  height={180}
                  toggle={toggle}
                  paramRanges={parameterRanges}
                  name={param.name}
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  unit={param.unit!}
                  currentCampaignNr={currentCampaignNr}
                  prevCampaignNr={prevCampaignNr}
                  prevCampaign={prevData}
                  currentCampaign={currentData}
                  handleZoom={handleZoom}
                  handleZoomRangeDragEnd={handleZoomRangeDragEnd}
                  handleZoomRangeDragStart={handleZoomRangeDragStart}
                  handleZoomRangeChange={handleZoomRangeChange}
                  hoverPosition={hoverPosition}
                  isDragTarget={isDragTarget}
                  resetZoom={resetZoom}
                  setHoverPosition={setHoverPosition}
                  scrollbarRef={scrollbarRef}
                  setScrollPositions={setScrollPositions}
                  setZoomRange={setZoomRange}
                  zoomLevel={zoomLevel}
                  zoomRange={zoomRange}
                  sameCampaign={sameCampaign}
                />
              </Graphs>
            </GraphContainer>
            <ViolinContainers>
              <SwitchContainer>
                <Switch
                  data-test-id={trimToggleParametersComparison}
                  onChange={onToggle}
                  checked={toggle}
                  label={t('parameters-comparison:showOutliers')}
                  size="small"
                />
              </SwitchContainer>
              <Violins data-test-id={violinChartParametersComparison}>
                <Violin
                  vesselName={vesselName}
                  comparedVesselName={comparedVesselName}
                  isTrimmed={toggle}
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  data={isDefined(currentData) ? currentData.values! : undefined}
                  width={VIOLIN_WIDTH}
                  height={180}
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  comparedData={isDefined(prevData) ? prevData.values! : undefined}
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  name={param.name!}
                  sameCampaign={sameCampaign}
                  campaignNr={currentCampaignNr}
                  comparedCampaignNr={prevCampaignNr}
                  paramRanges={parameterRanges}
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  unit={param.unit!}
                />
              </Violins>
            </ViolinContainers>
          </div>
        );
      }}
    </ParentSize>
  );
};
export default React.memo(ParameterPanel);

const Violins = styled.div`
  margin-left: ${settings.Spacing.Spacing_800};
  position: relative;
`;

const Graphs = styled.div`
  display: inline-flex;
  width: 100%;
`;

const ViolinContainers = styled.div`
  margin-top: 50px;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-content: flex-end;
  flex-wrap: nowrap;
  margin-left: 65px;
`;

const SwitchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  height: ${settings.Spacing.Spacing_400};
  flex-direction: row;
  align-items: center;
  margin-right: ${settings.Spacing.Spacing_500};
`;

const GraphContainer = styled.div<{ width: number }>`
  margin: 16px 0;
  -webkit-box-flex: 1;
  flex-grow: 1;
  width: ${(props) => props.width}px;
`;

const LegendContainer = styled.div<{ width: number }>`
  width: ${(props) => props.width}px;
  margin-bottom: ${settings.Spacing.Spacing_200};
`;

const Info = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: flex-end;
`;

const CampaignInfo = styled.div<{ color: string }>`
  color: ${(props) => props.color};
  font-size: ${settings.typography.FontSize.Small};
  font-family: ${settings.typography.FontFamily.Bold};
  display: flex;
  align-items: center;
`;

const Circle = styled.div.attrs({ role: 'presentation' })<{ color: string }>`
  --size: 5px;

  border-radius: 50%;
  width: var(--size);
  height: var(--size);
  background: ${(props) => props.color};
`;

const ColorDiv = styled.div.attrs({ role: 'presentation' })<{ color: string }>`
  --size: 12px;

  border-radius: 2px;
  width: var(--size);
  height: var(--size);
  background: ${(props) => props.color};
`;

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: ${settings.Spacing.Spacing_150};
`;

const Line = styled.div.attrs({ role: 'presentation' })<{ color: string }>`
  width: 10px;
  height: 2px;
  background: ${(props) => props.color};
`;

const Text = styled.span`
  color: ${settings.colors.Primary.Grey_6};
  font-size: ${settings.typography.FontSize.Small};
  font-family: ${settings.typography.FontFamily.Regular};
  margin-left: ${settings.Spacing.Spacing_50};
`;

const Separator = styled(Text)`
  color: ${settings.colors.Primary.Grey_4};
  margin: 0 ${settings.Spacing.Spacing_150};
  font-size: ${settings.typography.FontSize.Large};
`;

function getChartWidth(containerWidth: number): number {
  const remainingWidth = containerWidth - VIOLIN_WIDTH;
  let sizeDivisor = 0.8;

  if (containerWidth < 1300) {
    sizeDivisor = 0.8;
  } else if (containerWidth < 1600) {
    sizeDivisor = 0.83;
  } else if (containerWidth < 1800) {
    sizeDivisor = 0.85;
  } else if (containerWidth > 1800) {
    sizeDivisor = 0.89;
  }

  return remainingWidth * sizeDivisor;
}
