import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const CollapseArrowsIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" role={role} {...rest} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7053 4.707L8.7053 7.707C8.3143 8.098 7.6823 8.098 7.2913 7.707L4.2913 4.707C3.9003 4.316 3.9003 3.684 4.2913 3.293C4.4863 3.098 4.7423 3 4.9983 3C5.2543 3 5.5103 3.098 5.7053 3.293L6.9983 4.586V1C6.9983 0.447 7.4463 0 7.9983 0C8.5503 0 8.9983 0.447 8.9983 1V4.586L10.2913 3.293C10.6823 2.902 11.3143 2.902 11.7053 3.293C12.0963 3.684 12.0963 4.316 11.7053 4.707ZM7.29375 9.29326C7.68475 8.90226 8.31675 8.90226 8.70775 9.29326L11.7078 12.2933C12.0988 12.6843 12.0988 13.3163 11.7078 13.7073C11.5128 13.9023 11.2568 14.0003 11.0008 14.0003C10.7448 14.0003 10.4888 13.9023 10.2938 13.7073L9.00075 12.4143V15.0003C9.00075 15.5533 8.55275 16.0003 8.00075 16.0003C7.44875 16.0003 7.00075 15.5533 7.00075 15.0003V12.4143L5.70775 13.7073C5.31675 14.0983 4.68475 14.0983 4.29375 13.7073C3.90275 13.3163 3.90275 12.6843 4.29375 12.2933L7.29375 9.29326Z"
        fill={fill}
      />
    </svg>
  );
};

CollapseArrowsIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Grey_4,
};
