import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const VesselCasterIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.984 2.819a1 1 0 1 0-1.967.363l2.66 14.42a1 1 0 0 0 .983.818H7.5v2.33a1 1 0 1 0 2 0v-3.33a1 1 0 0 0-1-1H6.493L3.983 2.82zM15.5 16.42h2.008L20.017 2.82a1 1 0 0 1 1.967.363l-2.66 14.42a1 1 0 0 1-.984.818H16.5v2.33a1 1 0 1 1-2 0v-3.33a1 1 0 0 1 1-1zm1.923-7.81-1.164 6.31H13v3.093c0 .026-.002.052-.004.078a1 1 0 0 1-1.996-.09V14.92H7.741L6.295 7.08c.385-.03.82-.05 1.303-.05 1.162 0 2.92.412 4.654.818 1.683.394 3.342.782 4.406.782.273 0 .528-.007.765-.02zM12 22a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
      />
    </svg>
  );
};

VesselCasterIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
