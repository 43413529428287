import { ValidationState } from '@react-types/shared';
import { Form } from 'antd';

/**
 * When this hook is placed within an antd Form.Item component, it will grab the validation state of its Form.Item and
 * translate it to `ValidationState`.
 */
export function useAntdFormItemValidationState(): ValidationState | undefined {
  const { status } = Form.Item.useStatus();

  const validationState: ValidationState | undefined = (() => {
    switch (status) {
      case 'error':
      case 'warning':
        return 'invalid';
      case 'success':
        return 'valid';
      case 'validating':
      default:
        return undefined;
    }
  })();

  return validationState;
}
