import config from '@rhim/config';
import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { BrandRhimIcon } from '@rhim/icons';
import { ArrowRightIcon } from '@rhim/icons/24';
import {
  cantSignInLabel,
  getInTouchLinkSplashScreen,
  splashScreenContainer,
  startButtonSplashScreen,
  subTitleSplashScreen,
  titleSplashScreen,
  versionLabelSplashScreen,
} from '@rhim/test-ids';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import hexagon from '../../../../../../assets/hexagon-slice.svg?url';
import { Button } from '../Button/Button';
import { Hyperlink } from '../Hyperlink/Hyperlink';

const StyledWrapper = styled.div<{ backgroundImage: string }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  background: ${settings.colors.Primary.Blue_9};
  width: 100%;
  height: 100%;
  background-image: url('${(props) => props.backgroundImage}');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
`;

const StyledIconHexagon = styled.span`
  position: fixed;
  height: 100vh;
  width: 100%;
  background-image: url(${hexagon});
  background-repeat: no-repeat;
  background-size: auto 100vh;

  @media screen and (max-width: 576px) {
    background-position: -200px;
  }
`;

const StyledInnerWrapper = styled.div`
  z-index: 1;
  flex-grow: 1;
  display: flex;
  position: relative;
  margin: ${settings.Defaults.Outer_Container_Margin};
`;

const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLogo = styled.span`
  display: block;
  align-self: start;
  height: ${settings.Defaults.Product_Logo_Height};
`;

const StyledMainContent = styled.div`
  flex-grow: 1;
  margin: ${settings.Spacing.Spacing_400} 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  button {
    @media screen and (max-width: 576px) {
      --rhim-button-height: 48px;
    }
  }
`;

const StyledMainHeading = styled.h1`
  ${settings.typography.fonts.h_xxl_inverted}
  margin: 0 0 ${settings.Spacing.Spacing_300} 0;

  @media screen and (max-width: 576px) {
    ${settings.typography.h_sizes.xl}
  }
`;

const StyledDescriptionParagraph = styled.p`
  color: ${settings.colors.Primary.Grey_2};
  font-size: ${settings.typography.FontSize.Medium};
  line-height: ${settings.typography.LineHeight.Line_Height_24};
  margin: 0 0 ${settings.Spacing.Spacing_400} 0;

  @media screen and (max-width: 576px) {
    font-size: ${settings.typography.FontSize.Small};
    line-height: ${settings.typography.LineHeight.Line_Height_20};
  }
`;

const StyledCannotSignInContainer = styled.div`
  margin-top: ${settings.Spacing.Spacing_200};
  font-size: ${settings.typography.FontSize.Small};

  span {
    color: ${settings.colors.Primary.Grey_4};
    margin-right: 6px;
  }
`;

const StyledBottomContent = styled.div`
  span {
    display: block;
    font-size: ${settings.typography.FontSize.Small};
    color: ${settings.colors.Primary.Grey_4};
    line-height: 1.43;
  }

  span:nth-child(2) {
    margin-top: ${settings.Spacing.Spacing_150};
  }
`;

const StyledIconBrand = styled(BrandRhimIcon)`
  position: absolute;
  top: 0;
  right: 0;
`;

interface Props {
  loginPath: string;
  productIcon: React.ReactElement;
  showGetInTouchLink?: boolean;
  showRhimBrandIcon?: boolean;
  backgroundImageHref: string;
}

export const Splash = ({ loginPath, productIcon, backgroundImageHref, showGetInTouchLink = true, showRhimBrandIcon = true }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = i18nReact.useTranslation(['rhim-splash']);

  const handleButtonSignInClicked = () => {
    navigate(loginPath + location.search); // ensure that query parameters are preserved which may be relevant during the login
  };

  return (
    <StyledWrapper data-test-id={splashScreenContainer} backgroundImage={backgroundImageHref}>
      <StyledIconHexagon />
      <StyledInnerWrapper>
        <StyledInfoContainer>
          <StyledLogo>{productIcon}</StyledLogo>
          <StyledMainContent>
            <StyledMainHeading data-test-id={titleSplashScreen}>
              {t('rhim-splash:heading_top')} <br />
              {t('rhim-splash:heading_middle')} <br />
              {t('rhim-splash:heading_bottom')}
            </StyledMainHeading>
            <StyledDescriptionParagraph data-test-id={subTitleSplashScreen}>
              {t('rhim-splash:description_top')} <br />
              {t('rhim-splash:description_middle')} <br />
              {t('rhim-splash:description_bottom')}
            </StyledDescriptionParagraph>
            <div>
              <Button
                data-test-id={startButtonSplashScreen}
                label={t('rhim-splash:buttonSignIn')}
                icon={{ icon: <ArrowRightIcon />, position: 'end' }}
                size="big-56"
                background="primary-9-brand-inverted"
                onClick={handleButtonSignInClicked}
              />
            </div>
            {showGetInTouchLink && (
              <StyledCannotSignInContainer>
                <span data-lokalise data-key="labelCanNotSignin" data-test-id={cantSignInLabel}>
                  {t('rhim-splash:labelCanNotSignin')}
                </span>
                <Hyperlink
                  dataTestId={getInTouchLinkSplashScreen}
                  href="mailto:support.digital@rhimagnesita.com"
                  mode="inverted"
                  text={t('rhim-splash:linkGetInTouch')}
                />
              </StyledCannotSignInContainer>
            )}
          </StyledMainContent>
          <StyledBottomContent data-test-id={versionLabelSplashScreen}>
            <span>{t('rhim-splash:version', { version: config.version })}</span>
          </StyledBottomContent>
        </StyledInfoContainer>
        {showRhimBrandIcon && (
          <StyledIconBrand width={settings.Defaults.Brand_Logo_Width} height={settings.Defaults.Brand_Logo_Height} fill={settings.colors.Monochromatic.White} />
        )}
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};
