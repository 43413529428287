import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const CheckmarkIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M11 18c-.264 0-.519-.104-.707-.293l-5-5c-.391-.391-.391-1.023 0-1.414s1.023-.391 1.414 0l4.157 4.157 6.317-9.023c.317-.452.941-.563 1.392-.246.453.316.563.94.246 1.392l-7 10c-.169.242-.437.398-.732.423-.029.003-.058.004-.087.004"
      />
    </svg>
  );
};

CheckmarkIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
