import { isDefined } from '@rhim/utils';
import { Glyph } from '@vx/vx';
import styled from 'styled-components';

interface DiamondProps {
  className?: string;
  fill: string;
  x?: number;
  y?: number;
  cx?: number;
  cy?: number;
  width: number;
  height: number;
  onMouseEnter?: React.MouseEventHandler<SVGRectElement>;
  onMouseLeave?: React.MouseEventHandler<SVGRectElement>;
  onClick?: React.MouseEventHandler<SVGRectElement>;
}
export const GlyphDiamond: React.FunctionComponent<React.PropsWithChildren<DiamondProps>> = (props) => {
  const { cx, cy, x, y, width, height, onMouseEnter, onMouseLeave, onClick, ...rest } = props;
  const hasPointerEvent = isDefined(onMouseEnter) || isDefined(onMouseLeave) || isDefined(onClick);
  const left = isDefined(cx) ? cx - width / 2 : x;
  const top = isDefined(cy) ? cy - height / 2 : y;

  return (
    <Glyph left={left} top={top}>
      <Diamond width={width} height={height} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} hasPointer={hasPointerEvent} {...rest} />
    </Glyph>
  );
};

const Diamond = styled.rect<{ width: number; height: number; hasPointer: boolean }>`
  stroke-width: 1px;
  stroke: white;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  cursor: ${(props) => (props.hasPointer ? 'pointer' : 'default')};
  transform-box: fill-box;
  transform-origin: center;
  transform: rotate(45deg);
`;
