import { settings } from '@rhim/design';
import styled from 'styled-components';

import ZoomButtons from './ZoomButtons';

export const GraphWrapper = styled.div<{ $width: string }>`
  background-color: ${settings.colors.Monochromatic.White};
  width: ${(props) => props.$width};
  position: relative;
  display: flex;
`;

export const Left = styled.div<{ width: number }>`
  width: ${(props) => props.width}px;
  display: flex;
`;

export const Center = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
`;

export const TrackHorizontal = styled.div`
  background-color: ${settings.colors.Primary.Grey_2};
  height: 6px;
  right: 2px;
  bottom: 0;
  left: 2px;
  border-radius: 3px;
`;

export const ThumbHorizontal = styled.div`
  border-radius: inherit;
  background-color: ${settings.colors.Primary.Grey_4};
  cursor: pointer;
`;

export const DragZoomLabels = styled.div`
  position: absolute;
  width: 100%;
  bottom: 100%;
  margin-bottom: 4px;
`;

interface HeightCollapseButtonProps {
  graphHeight: number;
  colWidth: number;
}

export const HeightCollapseButton = styled.button<HeightCollapseButtonProps>`
  position: absolute;
  left: ${(props) => props.colWidth / 2 - 8}px;
  top: ${(props) => props.graphHeight / 2 - 8}px;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const Right = styled.div<{ width: number }>`
  width: ${(props) => props.width}px;
  display: flex;
`;

export const ScrollView = styled.div`
  /* native scrollbars become visible at certain browser zoom levels */
  margin-right: ${(props) => (props.style?.marginRight as number) - 1}px !important;
  margin-bottom: ${(props) => (props.style?.marginBottom as number) - 1}px !important;
  padding-right: 1px;
  padding-bottom: 1px;
`;

export const ScrollViewNoMargin = styled.div`
  /* native scrollbars become visible at certain browser zoom levels */
  padding-right: 1px;
  padding-bottom: 1px;
`;

export const Svg = styled.svg`
  display: block;
`;

export const SvgOverflow = styled.svg`
  overflow: visible;
`;

export const SvgAxisY = SvgOverflow;

export const TitleWrapper = styled.div<{ marginRight: number; align: string }>`
  display: flex;
  justify-content: space-between;
  align-items: ${(props) => props.align};
  margin: ${(props) => `0 ${props.marginRight}px ${settings.Spacing.Spacing_200}`};
`;

interface ZoomUIProps {
  isVisible: boolean;
}

export const ZoomUI = styled(ZoomButtons)<ZoomUIProps>`
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
`;
