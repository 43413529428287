import { i18nReact } from '@rhim/i18n';
import { UserIcon } from '@rhim/icons/24';
import { AppBar, useAccount } from '@rhim/react';
import { logOutLinkAppBar, userLinkAppBar } from '@rhim/test-ids';
import { isDefined } from '@rhim/utils';
import React from 'react';
import { useBroadcastChannel } from 'react-broadcastchannel';

import { ApplicationBarProps } from '../ApplicationBarProps';

export const NavigationItemAccount: React.FunctionComponent<React.PropsWithChildren<ApplicationBarProps>> = ({ authClient }) => {
  const { t } = i18nReact.useTranslation(['app']);
  const account = useAccount();
  const [requestLogoutFromAllTabs] = useBroadcastChannel<null>('logout', () => logOut());

  const logOut = React.useCallback(() => {
    const root = window.location.origin;
    requestLogoutFromAllTabs(null);
    authClient.logout({ postLogoutRedirectUri: root });
  }, [authClient, requestLogoutFromAllTabs]);

  return isDefined(account) ? (
    <AppBar.FunctionItemMenu
      dataTestId={userLinkAppBar}
      menuLabel={account.name}
      icon={<UserIcon />}
      menuItems={[{ menuItemId: logOutLinkAppBar, menuItemLabel: t('app:appBar.logout') }]}
      onMenuItemClicked={(menuItemId) => {
        if (menuItemId === logOutLinkAppBar) {
          logOut();
        }
      }}
    />
  ) : null;
};
