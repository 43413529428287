import { isDefined, isNumeric } from '@rhim/utils';
import { FC, useCallback, useEffect, useState } from 'react';
import React from 'react';
import styled from 'styled-components';

import { TextField } from '../TextField';

type TextInputType = {
  type?: 'text';
  showArrows?: never;
};

type NumberInputType = {
  type?: 'number';
  showArrows?: boolean;
};

type InputType = TextInputType | NumberInputType;

type InputNumericProps = Omit<React.ComponentProps<typeof TextField>, 'value' | 'onChange' | 'type'> &
  InputType & {
    value?: number | undefined;
    onChange?: (value: number | null) => void;
    mode?: 'integer' | 'float';
    min?: number;
    max?: number;
    triggerOutOfRangeChange?: boolean;
  };

const InputNumeric: FC<InputNumericProps> = ({
  value,
  onChange,
  mode = 'integer',
  min,
  max,
  variant = 'medium',
  type = 'text',
  showArrows = false,
  triggerOutOfRangeChange = false,
  ...rest
}) => {
  const [textInput, setTextInput] = useState<string>(isDefined(value) ? value.toString() : '');

  useEffect(() => {
    setTextInput((currentTextInput) => {
      if (isDefined(value)) {
        return currentTextInput.endsWith('.') ? `${value.toString()}.` : value.toString();
      } else {
        return '';
      }
    });
  }, [value]);

  const toNumber = React.useCallback(
    (value: string): number | null => {
      if (!isNumeric(value)) {
        return null;
      }

      return mode === 'integer' ? parseInt(value) : parseFloat(value);
    },
    [mode]
  );

  const textInputAsNumber: number | null = React.useMemo(() => toNumber(textInput), [textInput, toNumber]);

  const handleChange = React.useCallback(
    (value: string) => {
      setTextInput(value);

      const valueAsNumber = toNumber(value);

      if (isDefined(valueAsNumber)) {
        const isOutOfRange = (isDefined(min) && valueAsNumber < min) || (isDefined(max) && valueAsNumber > max);
        if (isOutOfRange && !triggerOutOfRangeChange) {
          return;
        }
        onChange?.(valueAsNumber);
      } else if (value === '') {
        onChange?.(null);
      }
    },
    [toNumber, min, max, triggerOutOfRangeChange, onChange]
  );

  /**
   * When the input gets blurred, its text may or may not be a valid number.
   * (e.g the user types in "35BAR" and clicks away)
   * If it is a valid number then we only need to remove any possible whitespaces from it.
   * (e.g so that "    5  " will become just "5")
   * But if it is not then we need to clean up by replacing the invalid text with the current numeric value.
   * (e.g need to replace "35BAR" with just "35")
   */
  const handleInputBlur = useCallback(() => {
    if (isDefined(textInputAsNumber)) {
      // all good, the typed text is a valid number
      let cappedNumber = isDefined(min) ? Math.max(min, textInputAsNumber) : textInputAsNumber;
      cappedNumber = isDefined(max) ? Math.min(max, cappedNumber) : cappedNumber;
      setTextInput(cappedNumber.toString());
      if (cappedNumber !== textInputAsNumber) {
        onChange?.(cappedNumber);
      }
      return;
    }
    // the typed text is not a valid number, replace it with the current numeric value
    const validTextInputValue = isDefined(value) ? value.toString() : '';
    setTextInput(validTextInputValue);
  }, [textInputAsNumber, value, min, max, onChange]);

  return <STextField value={textInput} onBlur={handleInputBlur} onChange={handleChange} variant={variant} type={type} showArrows={showArrows} {...rest} />;
};

InputNumeric.whyDidYouRender = true;
export default React.memo(InputNumeric);

const STextField = styled(TextField)<{ showArrows?: boolean }>`
  ${(props) =>
    props.showArrows !== true &&
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type=number] {
        -moz-appearance: textfield;
      }
    `}
`;
