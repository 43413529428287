import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const FerrotronIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.035 14.734A1.16 1.16 0 0 0 3 15c0 1.657 4.03 3 9 3s9-1.343 9-3c0-.09-.012-.178-.035-.266l-1.327-10.11a1.608 1.608 0 0 0-.023-.176C19.25 2.5 16.205 1 12 1 7.794 1 4.75 2.5 4.385 4.448c-.012.06-.02.118-.023.176l-1.327 10.11zm14.611-9.93c-.029-.094-.23-.527-1.24-.996A6.275 6.275 0 0 0 16 3.637V9a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V3.637a6.271 6.271 0 0 0-.406.17c-1.01.47-1.211.903-1.24.996l-.002.024L5.28 13h.012c1.648-.614 4.043-1 6.708-1s5.06.386 6.708 1h.012l-1.072-8.173-.002-.024zM12 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm6.325 8a7.635 7.635 0 0 0-.593-.224C16.357 14.318 14.33 14 12 14s-4.357.318-5.732.776c-.226.075-.423.15-.593.224.17.073.367.149.593.224C7.643 15.682 9.67 16 12 16s4.357-.318 5.732-.776c.226-.075.423-.15.593-.224z"
        fill={fill}
      />
      <path d="M4 20a1 1 0 1 0 0 2h16a1 1 0 1 0 0-2H4z" fill={fill} />
    </svg>
  );
};

FerrotronIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Grey_6,
};
