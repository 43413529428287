import type { Icon } from '@rhim/icons/types';
import { isDefined } from '@rhim/utils';
import { AxisLeft as VisxAxisLeft } from '@visx/axis';
import { Group } from '@visx/group';
import React from 'react';
import styled from 'styled-components';

import AxisLabel from './AxisLabel';
import { AXIS_LABEL_HEIGHT, AXIS_LABEL_ICON_SIZE, AXIS_LABEL_OFFSET_VERTICAL, AXIS_LEFT_STYLES, TICK_LABEL_COLOR } from './constants';
import Ticks from './Ticks';

interface Props extends Omit<React.ComponentProps<typeof VisxAxisLeft>, 'children'> {
  axisLabel?: string | React.ReactNode;
  AxisIcon?: React.FunctionComponent<Icon>;
}

const AxisLeft: React.ChildlessComponent<Props> = (props) => {
  const { AxisIcon, axisLabel, left = AXIS_LEFT_STYLES.left, top = AXIS_LEFT_STYLES.top, ...rest } = props;

  return (
    <Group top={top - AXIS_LEFT_STYLES.top} left={left - AXIS_LEFT_STYLES.left}>
      <VisxAxisLeft ticksComponent={Ticks} {...AXIS_LEFT_STYLES} {...rest} />
      {(isDefined(axisLabel) || isDefined(AxisIcon)) && (
        <ForeignObject x={AXIS_LEFT_STYLES.left} y={-AXIS_LABEL_OFFSET_VERTICAL} width="100%" height={AXIS_LABEL_HEIGHT}>
          <AxisLabelLeft icon={isDefined(AxisIcon) ? AxisIcon({ fill: TICK_LABEL_COLOR, size: `${AXIS_LABEL_ICON_SIZE}px` }) : undefined} label={axisLabel} />
        </ForeignObject>
      )}
    </Group>
  );
};

AxisLeft.whyDidYouRender = true;

export default React.memo(AxisLeft);

const AxisLabelLeft = styled(AxisLabel)`
  position: absolute;
  left: 0;
  top: 0;
`;

const ForeignObject = styled.foreignObject`
  overflow: visible;
`;
