import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  foregroundColor?: Property.Color;
  backgroundColor?: Property.Color;
  title?: string;
  role?: string;
}

export const HexagonDataIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, foregroundColor, backgroundColor, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    fill={foregroundColor}
                    d="M27.03 24.697c.533-.31.97-1.074.97-1.695V8.952c0-.62-.437-1.384-.97-1.694L14.97.233c-.533-.31-1.407-.31-1.94 0L.97 7.258C.437 7.568 0 8.331 0 8.953v14.049c0 .621.437 1.384.97 1.695l12.06 7.024c.533.31 1.407.31 1.94 0l12.06-7.024z"
                    transform="translate(-600 -300) translate(598 298) translate(2 2) translate(2)"
                  />
                  <g fill={backgroundColor}>
                    <path
                      d="M12.967 6.13c.013-.11.033-.216.033-.33C13 4.254 11.747 3 10.2 3c-.618 0-1.185.207-1.648.547C7.902 2.613 6.824 2 5.6 2 3.612 2 2 3.612 2 5.6c0 .577.148 1.114.389 1.597-1.385.466-2.39 1.76-2.39 3.303C0 12.433 1.568 14 3.5 14H7V9.414l-1.292 1.293c-.39.39-1.023.39-1.414 0C4.098 10.512 4 10.256 4 10c0-.256.098-.512.293-.707l3-3c.39-.391 1.024-.391 1.414 0l3 3c.39.39.39 1.024 0 1.414-.39.39-1.023.39-1.414 0L9 9.414V14h2.667v-.034c.112.01.219.034.333.034 2.209 0 4-1.791 4-4 0-1.874-1.294-3.434-3.033-3.87"
                      transform="translate(-600 -300) translate(598 298) translate(2 2) translate(2) translate(6 8)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

HexagonDataIcon.defaultProps = {
  size: '32',
  foregroundColor: '#3D5567',
  backgroundColor: settings.colors.Monochromatic.White,
};
