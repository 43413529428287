import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const LadleOverviewIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        d="M21 10a.985.985 0 0 0-.824.467l.818-7.356a1 1 0 0 0-1.988-.22l-.811 7.302c1.402.252 2.693.739 3.805 1.406v-.598a1 1 0 0 0-1-1zM7.338 22C6.488 20.825 6 19.458 6 18c0-.798.146-1.568.418-2.295L4.994 2.89a1 1 0 1 0-1.988.22l.818 7.356A.985.985 0 0 0 3 10a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0h.105l.901 8.11A1 1 0 0 0 6 22h1.338zM16.067 10H16c-3.15 0-5.96 1.165-7.793 2.986l-.85-8.94a13.11 13.11 0 0 1 1.087-.045c1 0 1.88.198 2.896.427h.001c1.195.269 2.548.573 4.215.573.33 0 .657-.01.984-.026L16.067 10zM13 18a3 3 0 1 0 6 0 3 3 0 0 0-6 0z"
      />
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 18c-1.081-3.48-4.254-6-8-6-3.746 0-6.919 2.52-8 6 1.081 3.48 4.254 6 8 6 3.746 0 6.919-2.52 8-6zm-2.125 0c-.998 2.397-3.293 4-5.875 4s-4.877-1.603-5.875-4c.998-2.397 3.293-4 5.875-4s4.877 1.603 5.875 4z"
      />
    </svg>
  );
};

LadleOverviewIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
