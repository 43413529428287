import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const RestartIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <path
              d="M6.707 4.293c.39.39.39 1.024 0 1.414-.39.39-1.024.39-1.414 0l-2-2c-.39-.39-.39-1.024 0-1.414l2-2c.39-.39 1.024-.39 1.414 0 .39.39.39 1.024 0 1.414L6.414 2H8c3.785 0 6.87 3.005 6.996 6.76L15 9c0 3.866-3.134 7-7 7s-7-3.134-7-7c0-.552.448-1 1-1s1 .448 1 1c0 2.761 2.239 5 5 5s5-2.239 5-5-2.239-5-5-5H6.415z"
              transform="translate(-443 -1529) translate(443 1529)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

RestartIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
