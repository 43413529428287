import { getBrowserLocale } from '@rhim/i18n';
import { DATE_FORMAT, isDefined, parseUTC, TimeUnit } from '@rhim/utils';
import { format, formatDistanceStrict, isValid } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import React, { FC } from 'react';

import { locales } from '../utils/locales';

interface Props {
  from: Date | number | string;
  to: Date | number | string;
  unit?: TimeUnit;
  addSuffix?: boolean;
  locale: string;
  children?: never;
}

const parseFromTo = ({ from, to }: Pick<Props, 'from' | 'to'>) => {
  if (typeof from === 'string') {
    from = parseUTC(from);
  }
  if (typeof to === 'string') {
    to = parseUTC(to);
  }

  return [from, to] as const;
};

export const getElapsedTime = ({ from, locale, to, unit, addSuffix = true }: Props) => {
  const [fromParsed, toParsed] = parseFromTo({ from, to });
  const agoDate = formatDistanceStrict(fromParsed, toParsed, { addSuffix, locale: locales[locale], unit });

  const seperatorIdx = agoDate.indexOf(' ');
  const value = agoDate.substring(0, seperatorIdx).trim();
  const label = agoDate.substring(seperatorIdx).trim();

  return { value, label };
};

export const ElapsedTime: React.FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  const [fromParsed] = parseFromTo({ from: props.from, to: props.to });

  const { value, label } = getElapsedTime(props);

  return <span title={new Date(fromParsed).toUTCString()}>{`${value} ${label}`}</span>;
};

/**
 * Returns a span containing the localized date or datetime
 * @param dataTestId a string that will be set in the data-test-id attribute of the span, used for e2e testing
 * @param className a css class name string
 * @param datetime a UTC datetime string
 * @param short a boolean to indicate if the date should be short or not, e.g "21.10.2020" or "21/10/2020", depending on locale
 * @param showTime if true the returned value will include time e.g "21 October 2020, 15:46 PM"
 * @param timezone an optional target timezone to convert the given utcDatetime to. If none is given, the utcDatetime is converted to local time
 */
interface FormattedDateProps {
  dataTestId?: string;
  className?: string;
  datetime: Date.ISO_8601;
  showTime?: boolean;
  timezone?: string;
}

export const FormattedDate: FC<React.PropsWithChildren<FormattedDateProps>> = ({ dataTestId, className, datetime, showTime = false, timezone }) => {
  const formattedDate = getFormattedDate({ datetime, showTime, timezone });

  return (
    <span data-test-id={dataTestId} className={className}>
      {formattedDate}
    </span>
  );
};

export const getFormattedDate = ({ datetime, showTime = false, timezone }: FormattedDateProps) => {
  const browserLocale = getBrowserLocale();
  const date = isDefined(timezone) ? utcToZonedTime(datetime, timezone) : parseUTC(datetime);

  const formatType = showTime ? DATE_FORMAT.SHORT_LOCALIZED_WITH_TIME : DATE_FORMAT.SHORT_LOCALIZED;
  const formattedDate = isValid(date) ? format(date, formatType, { locale: locales[browserLocale] }) : date.toString();

  return formattedDate;
};

export const getFormattedDateWithOffset = (datetime: Date.ISO_8601, timezone: string) => {
  const browserLocale = getBrowserLocale();
  const date = parseUTC(datetime);
  const customerDate = utcToZonedTime(datetime, timezone);

  return isValid(date) ? format(customerDate, DATE_FORMAT.SHORT_LOCALIZED_WITH_TIME, { locale: locales[browserLocale] }) + ' ' + timezone : '';
};
