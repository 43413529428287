import { VesselType } from '@rhim/rest';

import { environment } from '../../environments/environment';
import { Feature, FeatureFlag } from '../../settings';

/**
 * Matrix specifying which feature supports which vessel types
 */
export const vesselsPerFeature = {
  [Feature.ProcessData]: [VesselType.Bof, VesselType.Rh],
  [Feature.MeasurementData]: [VesselType.Bof, VesselType.Rh],
  // TODO: remove experimental feature flag for BFR once it is ready for release to PROD
  [Feature.PointCloudData]: (environment.experimentalFeatures.includes(FeatureFlag.BfrRegistrationV1) ? [VesselType.Bfr] : []).concat([
    VesselType.Eaf,
    VesselType.Hml,
    VesselType.Ladle,
    VesselType.Rhl,
  ]),
  [Feature.Report]: [VesselType.Bof, VesselType.Rh],
  [Feature.MeasurementView]: [
    VesselType.Aod,
    VesselType.Bfr,
    VesselType.Bof,
    VesselType.Eaf,
    VesselType.Ladle,
    VesselType.Rhl,
    VesselType.Srf,
    VesselType.Cou,
    VesselType.Hml,
  ],
  [Feature.ConnectedMachines]: [VesselType.Eaf],
  [Feature.VolumeCalculations]: [VesselType.Eaf],
  [Feature.Display]: [VesselType.Bof, VesselType.Eaf, VesselType.Ladle],
  [Feature.RhReport]: [VesselType.Rh],
  [Feature.AroReport]: [VesselType.Crk],
  [Feature.FleetOverview]: [VesselType.Ladle],
  [Feature.ParameterComparison]: [VesselType.Bof, VesselType.Rh],
};

export function getFeaturesForVesselType(vesselType: VesselType): Feature[] {
  return Object.entries(vesselsPerFeature)
    .filter(([, vesselTypes]) => vesselTypes.includes(vesselType))
    .map(([feature]) => feature as Feature);
}
