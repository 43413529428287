import { settings } from '@rhim/design';
import { getBrowserLocale } from '@rhim/i18n';
import { ChevronFilledDownIcon, ChevronFilledUpIcon } from '@rhim/icons/8';
import { OpsStateCriticalOutlineIcon } from '@rhim/icons/16';
import { colorMappingPanelMeasurementView, colorScaleStepContainersMeasurementView } from '@rhim/test-ids';
import { assert, ColorScales, head, isDefined } from '@rhim/utils';
import React, { FC, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useMeasure } from 'react-use';
import styled, { css } from 'styled-components';
import { UnitSystem } from 'typings';

import { convertUnit, FormattedLength, MetricLengthUnit } from '../../partials';
import { SideContextPanel } from '../SideContextBar/SideContextBar';
import { Spinner } from '../Spinner';

const EXPANDED_CELL_HEIGHT_PX = 32;
const COLLAPSED_CELL_HEIGHT_PX = 24;
const CELL_VERTICAL_GAP_PX = 1;
const VALUE_INDICATOR_HEIGHT_PX = 2;
const VALUE_INDICATOR_ANIMATION_DURATION_SECS = 1;
const EXPANDED_PADDING_TOP = 16;
const EXPANDED_PADDING_LEFT = 56;
const ARROW_LEFT_RIGHT_EXTEND_PX = 4;

export interface LinearScale {
  minValue: number;
  step: number;
  colors: NonEmptyArray<string>;
}

export const COLOR_SCALE_LCS: LinearScale = {
  minValue: 0,
  step: 20,
  colors: ['#ff3f3f', '#ff5f5f', '#ffa07f', '#fcc189', '#e0ff7f', '#c0ff7f', '#80ff7f', '#80ffc0', '#80ffff', '#c0c0ff'],
};

export interface MappingPanelTexts {
  headerColorScale: string;
  labelThickness: string;
}

export interface MappingPanelProps extends SideContextPanel {
  className?: string;
  texts: MappingPanelTexts;
  data?: {
    colorScales: ColorScales;
    valueMin: number;
    valueMax: number;
  };
  unitSystem: UnitSystem;
  onThicknessZoneMouseEntered?: (zoneMinimumValue: number, zoneMaximumValue: number) => void;
  onThicknessZoneMouseLeft?: () => void;
}
const MappingPanel: FC<React.PropsWithChildren<MappingPanelProps>> = React.memo(function MappingPanel({
  className,
  isExpanded = false,
  texts,
  unitSystem,
  data,
  onThicknessZoneMouseEntered,
  onThicknessZoneMouseLeft,
}) {
  const locale = getBrowserLocale();
  const bodyDomElement = useRef<HTMLDivElement>(null);
  const [valueMinTopOffset, setValueMinTopOffset] = useState(0);
  const [valueMaxBottomOffset, setValueMaxBottomOffset] = useState(0);
  const [shouldPlayAnimation, setShouldPlayAnimation] = useState(true);
  const [cellSize, setCellSize] = useState(isExpanded ? EXPANDED_CELL_HEIGHT_PX : COLLAPSED_CELL_HEIGHT_PX);
  const [containerRef, size] = useMeasure<HTMLDivElement>();

  useEffect(() => {
    // let the value-indicators animate but only once, during mounting.
    // if later on the view toggles between the expanded/minified state, do not animate them anymore
    const timeoutId = setTimeout(() => {
      setShouldPlayAnimation(false);
    }, VALUE_INDICATOR_ANIMATION_DURATION_SECS * 1000);

    return () => window.clearTimeout(timeoutId);
  }, []);

  const colorScalesAscendingOrder: ColorScales | undefined = useMemo(() => {
    if (!isDefined(data)) {
      return undefined;
    }
    return ([...data.colorScales] as ColorScales).sort((colorScaleA, colorScaleB) => colorScaleA.threshold - colorScaleB.threshold);
  }, [data]);

  useEffect(() => {
    if (!isDefined(data) || !isDefined(bodyDomElement.current)) {
      return;
    }
    let cellHeight = COLLAPSED_CELL_HEIGHT_PX;
    if (isExpanded) {
      const clientHeight = bodyDomElement.current.clientHeight;
      const remainingHeight = clientHeight - EXPANDED_PADDING_TOP - data.colorScales.length * CELL_VERTICAL_GAP_PX;
      let cellHeightThatFits = Math.floor(remainingHeight / data.colorScales.length);
      cellHeightThatFits = Math.max(cellHeightThatFits, 14);
      cellHeightThatFits = Math.min(cellHeightThatFits, EXPANDED_CELL_HEIGHT_PX);
      cellHeight = cellHeightThatFits;
    }
    setCellSize(cellHeight);
  }, [data, isExpanded, size.height]);

  useLayoutEffect(() => {
    if (!bodyDomElement.current || !isDefined(data) || !isDefined(colorScalesAscendingOrder)) {
      return;
    }

    function getScaleCellStartY(scaleIndex: number) {
      return scaleIndex * cellSize + scaleIndex * CELL_VERTICAL_GAP_PX;
    }

    function getYValue(value: number) {
      assert(isDefined(colorScalesAscendingOrder), 'MappingPanel, colorScalesAscendingOrder not set');
      const previousScaleIndex = colorScalesAscendingOrder.findIndex((scale) => value > scale.threshold);
      if (value < 0 && head(colorScalesAscendingOrder).threshold < 0 && previousScaleIndex === -1) {
        return getScaleCellStartY(0) + cellSize / 2;
      }
      const nextScaleIndex = colorScalesAscendingOrder.findIndex((scale) => value < scale.threshold);
      if (nextScaleIndex === -1) {
        return getScaleCellStartY(colorScalesAscendingOrder.length - 1) + cellSize / 2;
      }
      const currentScaleIndex = Math.max(0, nextScaleIndex - 1);
      const currentScale = colorScalesAscendingOrder[currentScaleIndex];
      const nextScale = colorScalesAscendingOrder[nextScaleIndex];
      assert(isDefined(currentScale), 'current scale is undefined');
      const scaleMinValue = currentScale.threshold;
      const scaleMaxValue = nextScaleIndex < colorScalesAscendingOrder.length && isDefined(nextScale) ? nextScale.threshold : Number.POSITIVE_INFINITY;
      const scaleCellStartY = getScaleCellStartY(currentScaleIndex);
      const yValue = scaleCellStartY + (cellSize * (value - scaleMinValue)) / (scaleMaxValue - scaleMinValue);
      return yValue;
    }
    // set top offset of minimum value arrow
    const minimumValueY = getYValue(data.valueMin);
    setValueMinTopOffset(Math.max(0, minimumValueY) + (isExpanded ? EXPANDED_PADDING_TOP : 0));
    // set bottom offset of maximum value arrow
    const clientHeight = Math.floor(bodyDomElement.current.clientHeight) - (isExpanded ? EXPANDED_PADDING_TOP : 0);
    const maximumValueY = getYValue(data.valueMax) + 2 * CELL_VERTICAL_GAP_PX;
    setValueMaxBottomOffset(clientHeight - maximumValueY);
  }, [data, colorScalesAscendingOrder, cellSize, size.height, isExpanded]);

  const hasCriticalMinimumValue = isDefined(data) && isDefined(colorScalesAscendingOrder) ? data.valueMin < colorScalesAscendingOrder[0].threshold : false;

  return (
    <StyledWrapper ref={containerRef} data-test-id={colorMappingPanelMeasurementView} isExpanded={isExpanded} className={className}>
      {isExpanded && (
        <SHeader>
          <SPanelHeading>{texts.headerColorScale}</SPanelHeading>
          <SPanelSubHeading>
            {texts.labelThickness} [{convertUnit(MetricLengthUnit.mm, unitSystem)}]
          </SPanelSubHeading>
        </SHeader>
      )}
      <SBody ref={bodyDomElement} isExpanded={isExpanded}>
        {isDefined(data) && isDefined(colorScalesAscendingOrder) ? (
          <>
            {colorScalesAscendingOrder.map((colorScale, index) => {
              const nextColorScale = colorScalesAscendingOrder[index + 1];
              const zoneMinimumValue = colorScale.threshold;
              const zoneMaximumValue =
                index !== colorScalesAscendingOrder.length - 1 && isDefined(nextColorScale) ? nextColorScale.threshold - 1 : Number.POSITIVE_INFINITY;
              const isFilled = zoneMaximumValue >= data.valueMin && zoneMinimumValue <= data.valueMax;
              const isMouseEnteredHandlerDefined = isDefined(onThicknessZoneMouseEntered);
              return (
                <SCellRow
                  data-test-id={colorScaleStepContainersMeasurementView}
                  key={index}
                  isHoverable={isFilled && isMouseEnteredHandlerDefined}
                  onMouseEnter={() => isFilled && isMouseEnteredHandlerDefined && onThicknessZoneMouseEntered(zoneMinimumValue, zoneMaximumValue)}
                  onMouseLeave={onThicknessZoneMouseLeft ?? undefined}
                >
                  <SCell size={cellSize} color={colorScale.color} isViewExpanded={isExpanded} isFilled={isFilled} />
                  {isExpanded && (
                    <SColorRange size={cellSize}>
                      {/* If the very first item in the scale has a negative value, prefix it with a '<=' */}
                      {/* eslint-disable-next-line i18next/no-literal-string */}
                      {index === 0 && zoneMinimumValue < 0 && colorScalesAscendingOrder.length - 1 && <span>&#8804;</span>}
                      {/* Prefix the very last item in the scale with a '>=' */}
                      {/* eslint-disable-next-line i18next/no-literal-string */}
                      {index >= colorScalesAscendingOrder.length - 1 && <span>&#8805;</span>}
                      <FormattedLength
                        locale={locale}
                        lengthValue={zoneMinimumValue}
                        sourceUnit={MetricLengthUnit.mm}
                        targetUnitSystem={unitSystem}
                        maximumFractionDigits={3}
                        showUnits={false}
                      />
                    </SColorRange>
                  )}
                </SCellRow>
              );
            })}
            <SValueIndicator
              cellSize={cellSize}
              isViewExpanded={isExpanded}
              isArrowFacingUp={false}
              isCritical={hasCriticalMinimumValue}
              top={valueMinTopOffset}
              shouldAnimatePosition={shouldPlayAnimation}
            >
              <SIndicatorValue isViewExpanded={isExpanded}>
                <FormattedLength
                  locale={locale}
                  lengthValue={data.valueMin}
                  sourceUnit={MetricLengthUnit.mm}
                  targetUnitSystem={unitSystem}
                  maximumFractionDigits={3}
                  showUnits={false}
                />
              </SIndicatorValue>
              <SValueIndicatorIcon isArrowFacingUp={false}>
                <ChevronFilledDownIcon fill={settings.colors.Primary.Grey_8} />
              </SValueIndicatorIcon>
            </SValueIndicator>
            {hasCriticalMinimumValue && (
              <SCriticalIcon top={valueMinTopOffset} shouldAnimatePosition={shouldPlayAnimation}>
                <OpsStateCriticalOutlineIcon />
              </SCriticalIcon>
            )}
            <SValueIndicator
              cellSize={cellSize}
              isViewExpanded={isExpanded}
              isArrowFacingUp={true}
              isCritical={false}
              bottom={valueMaxBottomOffset}
              shouldAnimatePosition={shouldPlayAnimation}
            >
              <SIndicatorValue isViewExpanded={isExpanded}>
                <FormattedLength
                  locale={locale}
                  lengthValue={data.valueMax}
                  sourceUnit={MetricLengthUnit.mm}
                  targetUnitSystem={unitSystem}
                  maximumFractionDigits={3}
                  showUnits={false}
                />
              </SIndicatorValue>
              <SValueIndicatorIcon isArrowFacingUp={true}>
                <ChevronFilledUpIcon fill={settings.colors.Primary.Grey_8} />
              </SValueIndicatorIcon>
            </SValueIndicator>
          </>
        ) : (
          <SSpinner size="24" />
        )}
      </SBody>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div<{ isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: ${(props) => (props.isExpanded ? '200px' : '48px')};
  padding-top: ${(props) => (props.isExpanded ? '0' : '6px')};
  padding-bottom: 6px;
`;

export const SHeader = styled.div`
  --fontSize: ${settings.typography.FontSize.X_Small};

  display: flex;
  flex-direction: column;
  font-size: var(--fontSize);
  line-height: var(--fontSize);
  font-family: ${settings.typography.FontFamily.Medium};
  color: ${settings.colors.Primary.Grey_7};
`;

export const SPanelHeading = styled.span`
  font-size: ${settings.typography.FontSize.X_Small};
  font-family: ${settings.typography.FontFamily.Medium};
  padding: ${settings.Spacing.Spacing_50} ${settings.Spacing.Spacing_200};
  background-color: ${settings.colors.Primary.Grey_1};
`;

export const SPanelSubHeading = styled.span`
  margin-top: ${settings.Spacing.Spacing_200};
  margin-left: ${settings.Spacing.Spacing_200};
`;

const SBody = styled.div<{ isExpanded: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  align-self: ${(props) => (props.isExpanded ? 'stretch' : 'center')};
  padding-top: ${(props) => (props.isExpanded ? `${EXPANDED_PADDING_TOP}px` : '0')};
  padding-left: ${(props) => (props.isExpanded ? `${EXPANDED_PADDING_LEFT}px` : '0')};
  overflow-y: auto;
`;

const SCellRow = styled.div<{ isHoverable: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: ${settings.Spacing.Spacing_50};
  margin-bottom: ${`${CELL_VERTICAL_GAP_PX}px`};
  ${(props) =>
    props.isHoverable &&
    css`
      &:hover {
        cursor: pointer;
        background-color: ${settings.colors.Primary.Grey_2};
        --borderRadius: 3px;
        border-radius: 0 var(--borderRadius) var(--borderRadius) 0;

        div {
          outline-style: solid;
          outline-color: ${settings.colors.Primary.Grey_8};
          outline-width: 1px;
        }
      }
    `}
`;

const SColorRange = styled.div<{ size: number }>`
  --fontSize: ${settings.typography.FontSize.Small};

  position: absolute;
  left: ${(props) => props.size}px;
  top: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  color: ${settings.colors.Primary.Grey_8};
  margin-left: ${settings.Spacing.Spacing_150};
  font-size: var(--fontSize);
  line-height: var(--fontSize);
  white-space: nowrap;
  user-select: none;
`;

const SCell = styled.div<{ size: number; color: string; isViewExpanded: boolean; isFilled: boolean }>`
  --size: ${(props) => `${props.size}px`};

  width: var(--size);
  height: var(--size);
  min-width: var(--size);
  min-height: var(--size);
  background-color: ${(props) => (props.isFilled ? props.color : 'transparent')};
  border: ${(props) => (props.isFilled ? 'none' : `solid 2px ${props.color}`)};
`;

const SValueIndicator = styled.div<{
  cellSize: number;
  isViewExpanded: boolean;
  isArrowFacingUp: boolean;
  isCritical: boolean;
  top?: number;
  bottom?: number;
  shouldAnimatePosition: boolean;
}>`
  --color: ${settings.colors.Primary.Grey_8};
  --labelColor: ${(props) => (props.isCritical ? settings.colors.Operational.State_Alert_Red_4 : settings.colors.Primary.Grey_8)};
  --height: ${(_) => `${VALUE_INDICATOR_HEIGHT_PX}px`};

  pointer-events: none;
  position: absolute;
  ${(props) =>
    props.isArrowFacingUp &&
    css`
      bottom: ${props.bottom}px;
    `}
  ${(props) =>
    !props.isArrowFacingUp &&
    css`
      top: ${props.top}px;
    `}
  transition: top, bottom;
  transition-duration: ${(props) => (props.shouldAnimatePosition ? `${VALUE_INDICATOR_ANIMATION_DURATION_SECS}s` : '0s')};
  transition-timing-function: ease;
  left: ${(props) => (props.isViewExpanded ? `calc(-1 * ${ARROW_LEFT_RIGHT_EXTEND_PX}px + ${EXPANDED_PADDING_LEFT}px)` : '0')};
  width: ${(props) => (props.isViewExpanded ? `${props.cellSize + 2 * ARROW_LEFT_RIGHT_EXTEND_PX}px` : '28px')};
  background-color: var(--color);
  height: var(--height);
`;

const SValueIndicatorIcon = styled.span<{ isArrowFacingUp: boolean }>`
  display: flex;
  align-items: center;
  position: absolute;
  top: ${(props) => (props.isArrowFacingUp ? '-4px' : '0')};
  right: -5px;
`;

const SIndicatorValue = styled.span<{ isViewExpanded: boolean }>`
  display: ${(props) => (props.isViewExpanded ? 'block' : 'none')};
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(calc(-100% - ${settings.Spacing.Spacing_100}), -50%);
  font-size: ${settings.typography.FontSize.X_Small};
  font-family: ${settings.typography.FontFamily.Bold};
  color: var(--labelColor);
`;

const SCriticalIcon = styled.span<{ top: number; shouldAnimatePosition: boolean }>`
  position: absolute;
  left: ${`${EXPANDED_PADDING_LEFT}px`};
  display: flex;
  transform: ${(_) => `translate(-50%, calc(-50% + ${VALUE_INDICATOR_HEIGHT_PX / 2}px))`};
  top: ${(props) => `${props.top}px`};
  transition: top;
  transition-duration: ${(props) => (props.shouldAnimatePosition ? `${VALUE_INDICATOR_ANIMATION_DURATION_SECS}s` : '0s')};
`;

const SSpinner = styled(Spinner)`
  position: absolute;
  top: ${settings.Spacing.Spacing_200};
  left: 50%;
  transform: translateX(-50%);
`;

MappingPanel.whyDidYouRender = true;
export default React.memo(MappingPanel);
