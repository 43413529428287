import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const VesselEAFIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.268V1.022A1.01 1.01 0 0 0 9 0c-.552 0-1 .457-1 1.022v6.956A1.01 1.01 0 0 0 9 9c.552 0 1-.457 1-1.022V4.5l2 1V8c0 .552.41 1 .917 1H14v2.477c-.335.015-.687.023-1.055.023-1.667 0-3.056-.25-4.445-.5-1.39-.25-2.778-.5-4.445-.5-.753 0-1.438.035-2.055.088V5.5l2-1v3.478A1.01 1.01 0 0 0 5 9c.552 0 1-.457 1-1.022V1.022A1.01 1.01 0 0 0 5 0c-.552 0-1 .457-1 1.022v1.246L.536 4a.996.996 0 0 0-.44.523.85.85 0 0 0-.096.394V14c0 1.105 3.582 2 8 2s8-.895 8-2V7.917C16 7.41 15.552 7 15 7h-1V4.917a.85.85 0 0 0-.09-.38.996.996 0 0 0-.444-.537L10 2.268z"
        fill={fill ?? '#3D5567'}
      />
    </svg>
  );
};

VesselEAFIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Grey_8,
};
