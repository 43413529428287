import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { HomeViewIcon, MoveIcon, RotateIcon, VesselviewFloorIcon, VesselviewNormalIcon, ZoomInIcon, ZoomOutIcon } from '@rhim/icons/24';
import { ButtonCircular, ClusteredCircularButtons } from '@rhim/react';
import {
  modelViewButtonsMeasurementView,
  rotationXWidgetMeasurementView,
  rotationYWidgetMeasurementView,
  threeDVisualizationContainerMeasurementView,
} from '@rhim/test-ids';
import { isDefined } from '@rhim/utils';
import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import Ladle3dView from '../Ladle3dView';
import { Scene3dContext } from '../Scene3d';
import { CameraRotation, VesselView } from '../types';
import FloatingToggleToolbar, { Control } from './FloatingToggleToolbar';
import RotationDegreesWidget from './RotationDegreesWidget';

const ACTION_ID = {
  viewRotate: 'viewRotate',
  viewPan: 'viewPan',
  viewZoomIn: 'viewZoomIn',
  viewZoomOut: 'viewZoomOut',
  vesselViewNormal: 'vesselViewNormal',
  vesselViewFloor: 'vesselViewFloor',
};

let engineResizeIntervalId: number;

interface Props {
  className?: string;
  isProductionEnvironment: boolean;
  isProxyNavigationShowing?: boolean;
  isRotateMode: boolean;
  onRotateModeChanged: (isRotateMode: boolean) => void;
  vesselView: VesselView;
  onVesselViewChanged: (vesselView: VesselView) => void;
  isCameraHorizontalRotationDisabled?: boolean;
  isCameraHorizontalRotationLocked: boolean;
  onCameraHorizontalRotationLockClicked?: () => void;
  isCameraVerticalRotationDisabled?: boolean;
  isCameraVerticalRotationLocked: boolean;
  onCameraVerticalRotationLockClicked?: () => void;
  uniqueMeshName: string | null;
  withFloorExploration?: boolean;
  rotationDegreesWidgetXPositionRight?: number;
}

const Viz3dWithControls: FC<React.PropsWithChildren<Props>> = ({
  className,
  isProductionEnvironment,
  isRotateMode,
  onRotateModeChanged,
  vesselView,
  onVesselViewChanged,
  isCameraHorizontalRotationDisabled = false,
  isCameraHorizontalRotationLocked,
  onCameraHorizontalRotationLockClicked,
  isCameraVerticalRotationDisabled = false,
  isCameraVerticalRotationLocked,
  onCameraVerticalRotationLockClicked,
  uniqueMeshName,
  withFloorExploration = true,
  rotationDegreesWidgetXPositionRight = 0,
}) => {
  const { t } = i18nReact.useTranslation('visualization3d');
  const scene3d = useContext(Scene3dContext);

  // todo all camera manipulations should go to Scene3D API
  const camera = useMemo(() => scene3d.getMainCamera(), [scene3d]);

  useEffect(() => {
    scene3d.setCameraRotationState(CameraRotation.Horizontal, isCameraHorizontalRotationLocked);
  }, [scene3d, isCameraHorizontalRotationLocked]);

  useEffect(() => {
    scene3d.setCameraRotationState(CameraRotation.Vertical, isCameraVerticalRotationLocked);
  }, [scene3d, isCameraVerticalRotationLocked]);

  const handleCameraControlButtonClicked = useCallback(
    (pressedButtonId: string) => {
      switch (pressedButtonId) {
        case ACTION_ID.viewZoomIn:
          scene3d.zoomCamera('in');
          break;
        case ACTION_ID.viewZoomOut:
          scene3d.zoomCamera('out');
          break;
        case 'homeView': {
          // unlock camera horizontal/vertical locks if enabled
          if (isCameraHorizontalRotationLocked) {
            onCameraHorizontalRotationLockClicked?.();
          }
          if (isCameraVerticalRotationLocked) {
            onCameraVerticalRotationLockClicked?.();
          }

          scene3d.moveCamera('Home');

          // reset the control to be the "rotate" view
          handleCameraControlButtonClicked(ACTION_ID.viewRotate);
          break;
        }
        case ACTION_ID.viewRotate:
          onRotateModeChanged(true);
          scene3d.setCameraRotationMode('rotate');
          break;
        case ACTION_ID.viewPan:
          onRotateModeChanged(false);
          scene3d.setCameraRotationMode('pan');
          break;
        case 'toggleSplitView':
          engineResizeIntervalId = window.setInterval(() => scene3d.getEngine().resize(), 50);
          setTimeout(() => window.clearInterval(engineResizeIntervalId), 500);
          break;
        default:
          throw Error(`Unexpected buttonId : '${pressedButtonId}'`);
      }
    },
    [
      scene3d,
      onRotateModeChanged,
      onCameraHorizontalRotationLockClicked,
      onCameraVerticalRotationLockClicked,
      isCameraHorizontalRotationLocked,
      isCameraVerticalRotationLocked,
    ]
  );

  const handleButtonHomeViewPressed = useCallback(() => {
    handleCameraControlButtonClicked('homeView');
  }, [handleCameraControlButtonClicked]);

  const handleVesselViewControlButtonClicked = useCallback(
    (type: string) => {
      if (isDefined(uniqueMeshName)) {
        switch (type) {
          case ACTION_ID.vesselViewNormal:
            onVesselViewChanged('whole');
            scene3d.setFloorView(uniqueMeshName, 'off', true);
            break;
          case ACTION_ID.vesselViewFloor:
            onVesselViewChanged('floor');
            scene3d.setFloorView(uniqueMeshName, 'on', true);
            break;
          default:
            throw Error(`Unexpected type : '${type}'`);
        }
      }
    },
    [onVesselViewChanged, scene3d, uniqueMeshName]
  );

  const cameraModeControls: Control[] = useMemo(() => {
    return [
      { icon: <RotateIcon />, id: ACTION_ID.viewRotate, tooltip: t('ladle3dViewControls.tooltips.rotate3dView', { ns: 'visualization3d' }) },
      { icon: <MoveIcon />, id: ACTION_ID.viewPan, tooltip: t('ladle3dViewControls.tooltips.pan3dView', { ns: 'visualization3d' }) },
    ];
  }, [t]);

  const vesselViewControls: Control[] = useMemo(() => {
    return [
      {
        icon: <VesselviewNormalIcon />,
        id: ACTION_ID.vesselViewNormal,
        tooltip: t('ladle3dViewControls.tooltips.vesselViewNormal', { ns: 'visualization3d' }),
      },
      {
        icon: <VesselviewFloorIcon />,
        id: ACTION_ID.vesselViewFloor,
        tooltip: t('ladle3dViewControls.tooltips.vesselViewFloor', { ns: 'visualization3d' }),
      },
    ];
  }, [t]);

  const buttonsZoomInOut = useMemo(
    () => [
      { icon: <ZoomInIcon />, buttonId: ACTION_ID.viewZoomIn, tooltip: t('ladle3dViewControls.tooltips.zoomIn3dView', { ns: 'visualization3d' }) },
      { icon: <ZoomOutIcon />, buttonId: ACTION_ID.viewZoomOut, tooltip: t('ladle3dViewControls.tooltips.zoomOut3dView', { ns: 'visualization3d' }) },
    ],
    [t]
  );

  const iconHomeView = useMemo(() => <HomeViewIcon />, []);

  return (
    <StyledWrapper data-test-id={threeDVisualizationContainerMeasurementView} className={className}>
      <SFloatingToggleToolbarRotateAndPan
        controls={cameraModeControls}
        selectedControlId={isRotateMode ? ACTION_ID.viewRotate : ACTION_ID.viewPan}
        onToggleClicked={handleCameraControlButtonClicked}
      />
      {withFloorExploration && (
        <SFloatingToggleToolbarVesselNormalAndFloorView
          controls={vesselViewControls}
          selectedControlId={vesselView === 'whole' ? ACTION_ID.vesselViewNormal : ACTION_ID.vesselViewFloor}
          onToggleClicked={handleVesselViewControlButtonClicked}
        />
      )}
      <StyledFloatingFunctionButtons data-test-id={modelViewButtonsMeasurementView}>
        <ClusteredCircularButtons buttons={buttonsZoomInOut} onPress={handleCameraControlButtonClicked} />
        <StyledButtonCircularHomeView
          id="homeView"
          icon={iconHomeView}
          onPress={handleButtonHomeViewPressed}
          tooltip={t('ladle3dViewControls.tooltips.reset3dView', { ns: 'visualization3d' })}
        />
      </StyledFloatingFunctionButtons>
      <StyledLadle3dView isProductionEnvironment={isProductionEnvironment} />
      <StyledRotationDegreesWidgetY
        dataTestId={rotationYWidgetMeasurementView}
        isDisabled={isCameraHorizontalRotationDisabled}
        isLocked={isCameraHorizontalRotationLocked}
        onLockClicked={onCameraHorizontalRotationLockClicked}
        tooltipLock={t('ladle3dViewControls.tooltips.lockUnlockHorizontalCameraRotation', { ns: 'visualization3d' })}
        tooltipLabel={t('ladle3dViewControls.tooltips.horizontalCameraRotationDegrees', { ns: 'visualization3d' })}
        tooltipLockPlacement="left"
        tooltipLabelPlacement="right"
        camera={camera}
        showsAlpha={true}
      />
      <StyledRotationDegreesWidgetX
        dataTestId={rotationXWidgetMeasurementView}
        isDisabled={isCameraVerticalRotationDisabled}
        isLocked={isCameraVerticalRotationLocked}
        onLockClicked={onCameraVerticalRotationLockClicked}
        tooltipLock={t('ladle3dViewControls.tooltips.lockUnlockVerticalCameraRotation', { ns: 'visualization3d' })}
        tooltipLabel={t('ladle3dViewControls.tooltips.verticalCameraRotationDegrees', { ns: 'visualization3d' })}
        tooltipLockPlacement="left"
        tooltipLabelPlacement="left"
        camera={camera}
        showsAlpha={false}
        positionRight={rotationDegreesWidgetXPositionRight}
      />
    </StyledWrapper>
  );
};
Viz3dWithControls.whyDidYouRender = true;
export default React.memo(Viz3dWithControls);

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledLadle3dView = styled(Ladle3dView)`
  height: 100%;
`;

const StyledRotationDegreesWidgetY = styled(RotationDegreesWidget)`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  margin-bottom: ${settings.Spacing.Spacing_300};
`;

const StyledRotationDegreesWidgetX = styled(RotationDegreesWidget)<{ positionRight: number }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${(props) => props.positionRight}px;
  margin-right: ${settings.Spacing.Spacing_300};
`;

const StyledFloatingFunctionButtons = styled.div`
  position: absolute;
  bottom: ${settings.Spacing.Spacing_300};
  left: ${settings.Spacing.Spacing_300};
  z-index: 1;
`;

const StyledButtonCircularHomeView = styled(ButtonCircular)`
  margin-top: ${settings.Spacing.Spacing_100};
`;

const SFloatingToggleToolbarRotateAndPan = styled(FloatingToggleToolbar)`
  position: absolute;
  left: ${settings.Spacing.Spacing_300};
  top: 50%;
  transform: translateY(calc(-100% - 4px));
`;

const SFloatingToggleToolbarVesselNormalAndFloorView = styled(FloatingToggleToolbar)`
  position: absolute;
  top: 50%;
  left: ${settings.Spacing.Spacing_300};
  transform: translateY(4px);
`;
