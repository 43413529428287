import i18next from 'i18next';
import Backend from 'i18next-chained-backend';
import { initReactI18next, useTranslation } from 'react-i18next';

import { I18nJsonStrategy } from './I18nJsonStrategy';

export { Trans } from 'react-i18next';

class I18nJsonReactStrategy extends I18nJsonStrategy {
  i18next = i18next.use(Backend).use(initReactI18next); // passes i18n down to react-i18next

  useTranslation = useTranslation;

  protected initialize = () =>
    (this.initialized = this.i18next.init({
      ...this.options,
      react: {
        useSuspense: true,
      },
    }));
}

export const i18nReact = new I18nJsonReactStrategy();
