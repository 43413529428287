import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { firstCampaignLabelOperatorDisplay, legendContainerOperatorDisplay, secondCampaignLabelOperatorDisplay } from '@rhim/test-ids';
import * as React from 'react';
import styled from 'styled-components';

interface Props {
  selectedCampaign: number;
  compareMode: boolean;
  comparedCampaign?: number;
}
const Legend: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ selectedCampaign, compareMode, comparedCampaign }: Props) => {
  const { t } = i18nReact.useTranslation(['operator-display']);

  return (
    <LegendContainer data-test-id={legendContainerOperatorDisplay}>
      <CampaignName data-test-id={firstCampaignLabelOperatorDisplay}>{selectedCampaign}</CampaignName>
      <Circle />
      <LiningTitle>{t('operator-display:legend.minLiningThickness')}</LiningTitle>
      <Line dash={true} color={settings.colors.Operational.State_Alert_Yellow_3}>
        {t('operator-display:legend.upperThreshold')}
      </Line>
      <Line color={settings.colors.Operational.State_Green_3}>{t('operator-display:legend.target')}</Line>
      <Line dash={true} color={settings.colors.Operational.State_Alert_Yellow_3}>
        {t('operator-display:legend.firstLowerThreshold')}
      </Line>
      <Line dash={true} color={settings.colors.Operational.State_Alert_Orange_3}>
        {t('operator-display:legend.secondLowerThreshold')}
      </Line>
      <Line color={settings.colors.Operational.State_Alert_Red_3}>{t('operator-display:legend.critical')}</Line>
      {compareMode && (
        <>
          <Slash>/</Slash>
          <CampaignName data-test-id={secondCampaignLabelOperatorDisplay} color={settings.colors.Primary.Grey_6}>
            {comparedCampaign ?? '-'}
          </CampaignName>
          <Diamond />
          <LiningTitle>{t('operator-display:legend.minLiningThickness')}</LiningTitle>
        </>
      )}
    </LegendContainer>
  );
};

export default React.memo(Legend);

const Circle = styled.div`
  height: 5px;
  width: 5px;
  display: block;
  border: 1px solid ${settings.colors.Primary.Blue_9};
  background: ${settings.colors.Primary.Blue_9};
  border-radius: 5px;
`;

const Diamond = styled.div`
  height: 5px;
  width: 5px;
  display: block;
  border: 1px solid ${settings.colors.Primary.Grey_5};
  background: ${settings.colors.Primary.Grey_5};
  transform: rotate(45deg);
  margin: ${settings.Spacing.Spacing_150} 0;
`;

const Title = styled.span`
  color: ${settings.colors.Primary.Grey_6};
  height: 25px;
  padding: 6px 9px 5px 22px;
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.X_Small};
  white-space: nowrap;
  display: flex;
  align-items: center;
`;

const LegendContainer = styled.div`
  position: relative;
  margin: ${settings.Spacing.Spacing_100};
  margin-bottom: ${settings.Spacing.Spacing_50};
  display: inline-flex;
  width: 99%;
  height: 70px;
  padding: 12px 16px;
  border-radius: 3px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid ${settings.colors.Primary.Grey_2};
  background-color: ${settings.colors.Monochromatic.White};
  align-items: center;
  justify-content: center;
`;

const Slash = styled(Title)`
  color: ${settings.colors.Primary.Grey_4};
  font-size: ${settings.typography.FontSize.Large};
  padding: 0;
`;

const LiningTitle = styled(Title)`
  padding: ${settings.Spacing.Spacing_150};
`;

const CampaignName = styled(Title)<{ color?: string }>`
  padding: ${settings.Spacing.Spacing_150};
  color: ${(props) => props.color ?? settings.colors.Primary.Grey_8};
  font-size: ${settings.typography.FontSize.Small};
  font-family: ${settings.typography.FontFamily.Bold};
`;

const Line = styled(Title)<{ dash?: boolean; color: string }>`
  &::before {
    content: '';
    position: absolute;
    width: 17px;
    height: 3px;
    display: inline-block;
    transform: translateX(-1.3rem);
    border-bottom: ${(props) => (props.dash === true ? `2px dotted ${props.color}` : `2px solid ${props.color}`)};
  }
`;
