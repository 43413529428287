import { Line as LineShape } from '../../shape';
import { LineDefinition } from '../../theme/types';

const SIZE = 16;

type Props = Pick<LineDefinition, 'legendStrokeWidth' | 'strokeColor' | 'strokeDasharray' | 'strokeDashoffset'>;

export const Line: React.FC<Props> = (props) => {
  const { strokeColor, legendStrokeWidth = 2, strokeDasharray, strokeDashoffset } = props;

  return (
    <svg width={SIZE} height={SIZE}>
      <LineShape
        from={{ x: 0, y: SIZE / 2 }}
        to={{ x: SIZE, y: SIZE / 2 }}
        stroke={strokeColor}
        strokeWidth={legendStrokeWidth}
        strokeDasharray={strokeDasharray}
        strokeDashoffset={strokeDashoffset}
      />
    </svg>
  );
};
