import { assert, hasElements, isDefined } from '@rhim/utils';
import React, { useEffect, useMemo } from 'react';

interface ObjectsRefs<T> {
  [index: string]: React.RefObject<T>;
}

export function useScrollToElement<T extends HTMLElement>(ids: string[], selectedId?: string) {
  const refs = useMemo(
    () =>
      ids.reduce((acc, id) => {
        acc[id] = React.createRef<T>();
        return acc;
      }, {} as ObjectsRefs<T>),
    [ids]
  );

  // scroll the selected element into view
  useEffect(() => {
    // don't scroll if there's no selected element or if there are no elements at all (#97231)
    // don't scroll if the ref components have not been mounted yet.
    if (!isDefined(selectedId) || !hasElements(Object.keys(refs)) || Object.values(refs).every((ref) => !isDefined(ref.current))) {
      return;
    }
    const associatedObject = refs[selectedId];
    if (!isDefined(associatedObject)) {
      // don't scroll if the selected element is filtered out ( and therefore not visible)
      return;
    }
    const associatedRef = associatedObject.current;
    assert(isDefined(associatedRef), `Entry ref not found for objectId ${selectedId}`);
    associatedRef.scrollIntoView({ block: 'nearest' });
  }, [ids, refs, selectedId]);

  return refs;
}
