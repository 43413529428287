import React from 'react';
import ReactRouterPrompt from 'react-router-prompt';

import { Button } from '../Button';
import { ModalDialog } from '../ModalDialog';

interface Props {
  isOperationInProgress: boolean;
  onOperationCancelled?: () => void;
  texts: {
    headline: string;
    description: string;
    buttonLeaveAndCancelLabel: string;
    buttonStayOnPageLabel: string;
  };
}
const LeavePageWarningModal: React.ChildlessComponent<Props> = ({ isOperationInProgress, onOperationCancelled, texts }) => {
  return (
    <ReactRouterPrompt when={isOperationInProgress}>
      {({ isActive, onCancel, onConfirm }) => {
        const handleOnLeaveAndCancel = () => {
          onOperationCancelled?.();
          onConfirm();
        };

        return (
          <ModalDialog
            open={isActive}
            mode="informational"
            maskClosable={false}
            headline={texts.headline}
            description={texts.description}
            buttonPrimary={<Button size="small-40" onClick={onCancel} label={texts.buttonStayOnPageLabel} />}
            buttonCancel={<Button size="small-40" onClick={handleOnLeaveAndCancel} label={texts.buttonLeaveAndCancelLabel} />}
            onCancel={onCancel}
          />
        );
      }}
    </ReactRouterPrompt>
  );
};

export default React.memo(LeavePageWarningModal);
