import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const EtechIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 64 70"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      role={role}
      {...rest}
    >
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="nonzero"
        d="M63.267 17.693L32.72.193a1.445 1.445 0 00-1.442 0L.733 17.693C.28 17.952 0 18.436 0 18.958v32.084c0 .522.28 1.006.733 1.266l30.546 17.5a1.441 1.441 0 001.443 0l30.546-17.5c.453-.26.733-.744.733-1.266V18.958a1.461 1.461 0 00-.734-1.265zM47.779 46.13c-1.676 2.147-3.793 3.803-6.354 4.97-2.56 1.167-5.213 1.75-7.959 1.75-2.606 0-5.062-.42-7.366-1.26-2.304-.84-4.306-2.042-6.004-3.605-1.699-1.563-3.037-3.442-4.015-5.635-.977-2.193-1.466-4.643-1.466-7.35s.489-5.157 1.466-7.35c.978-2.193 2.316-4.072 4.015-5.635 1.698-1.563 3.7-2.765 6.004-3.605 2.304-.84 4.76-1.26 7.366-1.26 2.42 0 4.62.42 6.598 1.26 1.978.84 3.653 2.042 5.027 3.605 1.373 1.563 2.431 3.442 3.177 5.635.745 2.193 1.117 4.643 1.117 7.35v3.29H25.088c.419 2.007 1.326 3.605 2.723 4.795 1.396 1.19 3.119 1.785 5.166 1.785 1.723 0 3.177-.385 4.364-1.155 1.187-.77 2.222-1.762 3.107-2.975l7.33 5.39zm-8.867-14.98c.047-1.773-.536-3.29-1.746-4.55-1.21-1.26-2.77-1.89-4.677-1.89-1.164 0-2.188.187-3.072.56-.885.373-1.641.852-2.27 1.435a6.285 6.285 0 00-1.466 2.03 6.705 6.705 0 00-.593 2.415h13.824z"
      />
    </svg>
  );
};

EtechIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
