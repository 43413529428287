import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const MapMarkerIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.667 6.73c0 4.099-4.667 7.603-4.667 7.603S3.333 10.83 3.333 6.73C3.333 4.118 5.423 2 8 2s4.667 2.118 4.667 4.73zM8 8.937c1.203 0 2.178-.988 2.178-2.207 0-1.22-.975-2.207-2.178-2.207-1.203 0-2.178.988-2.178 2.207 0 1.219.975 2.207 2.178 2.207z"
      />
    </svg>
  );
};

MapMarkerIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Grey_6,
};
