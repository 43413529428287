import { createLogRocket } from '@rhim/utils';

import { environment } from '../environments/environment';

/**
 * Use this LogRocket instead of the one imported from node_modules.
 */
export const LogRocket = createLogRocket(environment.logRocketId);

/**
 * Gets the LogRocket Session URL.
 */
export const useSessionURL = (): Promise<string | null> => {
  return new Promise((resolve) => {
    LogRocket.getSessionURL((sessionURL) => {
      resolve(sessionURL);
    });
  });
};
