import { initAnalytics } from '@rhim/analytics';
import config from '@rhim/config';
import { i18nReact } from '@rhim/i18n';
import { useConnectionListener } from '@rhim/react/hooks';
import { UpdateInfo } from '@welldone-software/why-did-you-render';
import React, { useEffect } from 'react';
import { setI18n } from 'react-i18next';

import { environment } from '../environments/environment';
import AllRoutes from '../routes/AllRoutes';
import { LogRocket, useLogger } from '../utilities';

setI18n(i18nReact.i18next); // use React i18n

initAnalytics(environment.mixpanelToken!, environment.production);

export const App: React.FunctionComponent<React.PropsWithChildren> = () => {
  useConnectionListener();
  useLogger();

  const handler = React.useCallback((event: KeyboardEvent) => {
    if (!event.ctrlKey || config.version !== 'dev') {
      return;
    }

    // CTRL+Shift+Z to display the translations keys, by using lang code 'cimode'.
    // 'cimode' will cause the t function to always return the key.
    // https://www.i18next.com/overview/api#changelanguage
    if (event.key === 'Z') {
      i18nReact.toggleCimode();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handler);
    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, [handler]);

  return <AllRoutes />;
};

if (environment.allowExtensions) {
  App.whyDidYouRender = {
    collapseGroups: true,
    notifier: ({ displayName, reason }: UpdateInfo) => {
      LogRocket.captureMessage(`Top-level wasted render detected ${displayName}`, {
        extra: {
          reason: JSON.stringify(reason),
        },
      });
    },
  };
}

const AppComponent = React.memo(App);
AppComponent.displayName = 'App';

export default AppComponent;
