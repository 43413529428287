// Note: the following build error will appear if not suppressed: assets/config.json' is not under 'rootDir'. 'rootDir' is expected to contain all source files.
// We ignore this error because if assets is added to rootDirs, the typings stop working. The dist folder won't contain any .d.ts files anymore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import config from '@rhim/config';
import { isDefined } from '@rhim/utils';
import i18next, { InitOptions, TFunction } from 'i18next';
import Backend from 'i18next-chained-backend';
import HttpApi from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';

import { I18n } from './i18n';
import { FALLBACK_LANGUAGE_CODE, setHtmlLang } from './utils';

const oneSecond = 1000;

export class I18nJsonStrategy implements I18n {
  initialized!: Promise<TFunction>;

  get options(): InitOptions {
    return {
      lng: FALLBACK_LANGUAGE_CODE,
      fallbackLng: 'en',
      saveMissing: true,
      missingKeyHandler: (languages, ns, key, fallback) => {
        // eslint-disable-next-line no-console
        console.warn(`Missing keys: ${languages}/${ns}/${key}. "${fallback}" will be used instead.`);
      },
      debug: false,
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      ns: [...(this.namespaces ?? [])],
      defaultNS: 'app',
      // https://www.i18next.com/misc/json-format#i18next-json-v3
      compatibilityJSON: 'v3',
      backend: {
        backends: [
          LocalStorageBackend, // primary
          HttpApi, // fallback
        ],
        backendOptions: [
          {
            // use local storage as cache for translations
            prefix: 'rhim_i18n_json_',
            expirationTime: config.version === 'dev' ? oneSecond * 15 : oneSecond * 3600 * 24,
            defaultVersion: config.version, // use version for cache invalidation
          },
          {
            loadPath: 'assets/locales/{{lng}}/{{ns}}.json',
            // #34114 cache busting: adds parameters to resource URL, e.g. '/assets/locales/en/app.json' -> '/assets/locales/en/app.json?v=20210428.22'
            // this is required because if the local storage invalidates the cache and wants to retrieve the new translations, we must ensure that the
            // browser won't return cached results. This would lead to a mis-match in the expected and the actually received translations
            queryStringParams: { v: config.version },
          },
        ],
      },
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    };
  }

  i18next = i18next.use(Backend);

  constructor(public namespaces: string[] = []) {
    this.initialize();
  }

  addTranslations = (namespaces: string[]): Promise<TFunction> => {
    this.initialized = this.i18next.loadNamespaces(namespaces).then(() => this.t);
    return this.waitForTranslations();
  };

  waitForTranslations = (): Promise<TFunction> => {
    return this.initialized;
  };

  changeLanguage = (newLanguage: string): Promise<TFunction> => {
    setHtmlLang(newLanguage);

    /**
     * Calling this method will trigger a re-render of all components that use the `useTranslation` hook.
     * When `newLanguage` is the same as the currently selected language, calling `changeLanguage` causes a wasteful render.
     *
     * That's why we need to only do that when the language has actually changed and bail out otherwise.
     */
    const hasChanged = newLanguage !== this.i18next.language;

    if (hasChanged) {
      return (this.initialized = this.i18next.changeLanguage(newLanguage));
    } else {
      return this.initialized;
    }
  };

  /** Toggles between cimode and fallbackLng */
  toggleCimode = (): Promise<TFunction> => {
    return this.i18next.changeLanguage(this.i18next.language === 'cimode' ? FALLBACK_LANGUAGE_CODE : 'cimode');
  };

  t(key: string): string {
    return this.i18next.t(key);
  }

  protected initialize(): Promise<TFunction> {
    this.initialized = this.i18next.init(this.options, (err) => {
      if (isDefined(err)) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    });

    return this.initialized;
  }
}

export const i18nJson = new I18nJsonStrategy();
