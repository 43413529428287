import { useMemoCompare } from '@rhim/react';
import {
  RHIMAPOReportingWearManagementApiV1ModelsBOFAccumulatedValue,
  RHIMAPOReportingWearManagementApiV1ModelsBOFHotSpot,
  RHIMAPOReportingWearManagementApiV1ModelsBOFParameter,
  RHIMAPOReportingWearManagementApiV1ModelsBOFPredictedPoint,
  RHIMAPOReportingWearManagementApiV1ModelsBOFProcessParameter,
  RHIMAPOReportingWearManagementApiV1ModelsRegionDto,
} from '@rhim/rest/wearManagement';
import { hasElements, isDefined, last } from '@rhim/utils';
import { getIntersectionPoint } from '@rhim/visualization';
import * as d3 from 'd3';
import { max } from 'd3-array';

export interface Point {
  x: number;
  y: number;
}

export interface PredictedData {
  predictedLifeTime: number;
  remainingHeats: number;
  area?: string;
  remainingMm?: number;
  name?: string | null;
}

export interface IHotspots {
  name: string;
  displayName: string;
  area: string;
  measuredPoints: NonEmptyArray<Point>;
  targetLifeTime: NonEmptyArray<Point>;
  predictedPoints: RHIMAPOReportingWearManagementApiV1ModelsBOFPredictedPoint[];
  criticalPoints: Point[];
  remainingHeats: number;
  univariateRemainingHeats?: number;
  univariatePredictedLifetime: number;
  predictedLifetime: number;
  averageWearSpeed: number | null;
  threshold: number;
  parameters: RHIMAPOReportingWearManagementApiV1ModelsBOFParameter[];
  brickSuppliers: string[];
  univariatePredictedPoints: Point[];
  lowerThreshold: Point[];
  upperThreshold: Point[];
  univariatePredictedLifetimeOutlook: Point[];
}

export interface IProcessParams {
  name: string | undefined;
  measuredPoints: NonEmptyArray<Point>;
  predictedPoints: RHIMAPOReportingWearManagementApiV1ModelsBOFPredictedPoint[];
  criticalPoints: Point[];
  remainingHeats: number;
  predictedLifetime: number;
  threshold: number;
  forecastRate?: number | null;
  index: number;
}

type HotSpot = RHIMAPOReportingWearManagementApiV1ModelsBOFHotSpot;

/**
 * Gets the X value from a point.
 * @param d Point object.
 * @returns X value.
 */
export const getX = (d: Point | undefined): number => (d ? d.x : 0);

/**
 * Gets the Y value from a point.
 * @param d Point object.
 * @returns Y value.
 */
export const getY = (d: Point | undefined): number => (d ? d.y : 0);

/**
 * Linear Scale for X axis.
 */
export const scaleX = (xMax: number, maxXHeatNr: number) =>
  d3
    .scaleLinear()
    .domain([0, maxXHeatNr || 0])
    .range([0, xMax]);

/**
 * Creates hotspot data required for the prediction graph.
 * @param hotSpots Hotspots data array.
 * @param targetLifetime Target Lifetime.
 * @returns Object with new generated hotspot data.
 */
export const createHotSpotsData = (hotSpots: NonEmptyArray<HotSpot>, targetLifetime: number | undefined, isRh = false): NonEmptyArray<IHotspots> => {
  return hotSpots.map((hotSpot, index) => retrieveDataForPlotting(hotSpot, index, targetLifetime, isRh));
};

/**
 * Creates snorkel Kpis data required for the prediction graph.
 * @param processParameters Process parameters array.
 * @param targetLifetime Target Lifetime.
 * @returns Object with new generated process parameters data.
 */
export const createProcessParamsData = (
  processParameters: NonEmptyArray<RHIMAPOReportingWearManagementApiV1ModelsBOFProcessParameter>,
  targetLifetime: number | undefined
): NonEmptyArray<IProcessParams> => {
  return processParameters.map((param, index) => retrieveProcessParamDataForPlotting(param, index, targetLifetime));
};

/**
 * Gets the min prediction data data.
 * @param hotspots List of hotspots.
 * @param width width of the chart.
 * @param lastHeat Last heat number.

 * @returns Prediction data object.
 */
export const getMinPredictionStripData = (
  hotspots: NonEmptyArray<IHotspots>,
  width: number,
  lastHeat: number,
  regions?: RHIMAPOReportingWearManagementApiV1ModelsRegionDto[]
): PredictedData[] => {
  const data = hotspots.slice().sort((a, b) => a.predictedLifetime - b.predictedLifetime);

  const scaleLinearX = scaleX(getChartWidth(width, 280), getMaxXScalingNr(hotspots));

  const stripData = data.map((item) => {
    const processDataPoint = getIntersectionPoint(scaleLinearX.invert(scaleLinearX(lastHeat) || 0), [...item.measuredPoints, ...item.predictedPoints]);
    const remainingMm = last(item.measuredPoints).x > lastHeat ? last(item.measuredPoints).y : processDataPoint.y;

    const region = isDefined(regions) ? regions.find((regItem) => regItem.shortName === item.name) : undefined;
    return {
      predictedLifeTime: item.predictedLifetime,
      remainingHeats: item.remainingHeats,
      area: item.area,
      remainingMm: remainingMm,
      name: isDefined(region) ? region.displayName : item.displayName,
    };
  });
  return stripData[0].remainingHeats < 0 ? stripData.filter((item) => item.remainingHeats < 0) : [stripData[0]];
};

export function useMinPredictionStripData(
  hotspots: NonEmptyArray<IHotspots>,
  width: number,
  lastHeat: number,
  regions?: RHIMAPOReportingWearManagementApiV1ModelsRegionDto[]
): PredictedData[] {
  const value = getMinPredictionStripData(hotspots, width, lastHeat, regions);

  return useMemoCompare(value);
}

export const hasShortlistParams = (hotspots: NonEmptyArray<IHotspots>): boolean => {
  const shortlisted = hotspots.findIndex((hotspot) => hotspot.parameters.some((param) => param.isShortlisted));
  return shortlisted !== -1;
};

export const getChartWidth = (width: number, paramWidth = 280) => {
  const remainingWidth = width - paramWidth;
  let sizeDivisor = 3.5;

  if (width < 1300) {
    sizeDivisor = 3;
  } else if (width < 1400) {
    sizeDivisor = 2.5;
  } else if (width < 1500) {
    sizeDivisor = 2.8;
  } else if (width < 1600) {
    sizeDivisor = 3.2;
  } else if (width < 1800 && width >= 1700) {
    sizeDivisor = 4;
  } else if (width > 1800) {
    sizeDivisor = 4.5;
  }

  return remainingWidth - remainingWidth / sizeDivisor;
};

/**
 * Gets the maximum X value from all points.
 * Required for setting a general scaling.
 * @param hotspots Hotspots array.
 * @returns Max x value number.
 */
export const getMaxXScalingNr = (hotspots: (IHotspots | IProcessParams)[]): number => {
  return Math.max(
    ...hotspots.map((hotspot: IHotspots | IProcessParams) => {
      let points = hotspot.criticalPoints.length ? hotspot.criticalPoints : hotspot.predictedPoints;

      if (!points.length) {
        points = hotspot.measuredPoints;
      }

      const lastEntry = points[points.length - 1];
      const lastLimit =
        isDefined((hotspot as IHotspots).upperThreshold) && hasElements((hotspot as IHotspots).upperThreshold)
          ? last((hotspot as IHotspots).upperThreshold as NonEmptyArray<Point>)
          : lastEntry;
      const lastUnivariateLimit =
        isDefined((hotspot as IHotspots).univariatePredictedPoints) && hasElements((hotspot as IHotspots).univariatePredictedPoints)
          ? last((hotspot as IHotspots).univariatePredictedPoints as NonEmptyArray<Point>)
          : lastEntry;

      return lastEntry ? max([lastEntry.x, lastLimit?.x ?? 0, lastUnivariateLimit?.x ?? 0]) ?? 0 : 0;
    })
  );
};

/**
 * Retrieves the data from the given object.
 * @param hotSpot Data object from JSON file.
 * @param index Index number.
 * @param targetLifetime The target lifetime.
 * @returns new generated hotspot object.
 */
const retrieveDataForPlotting = (hotSpot: HotSpot, index: number, targetLifetime: number | undefined, isRh = false): IHotspots => {
  // we distinguish a special kind of predicted points: the one that are below the critical threshold
  // therefore, we determine where in the predicted points the critical points start
  let criticalIdx = -1;
  let predictedLifetimeIdx = -1;
  const predictions = hotSpot.predictedPoints || [];

  predictions.forEach((point: Point, id: number) => {
    if (point.y <= hotSpot.threshold && point.y >= 0) {
      criticalIdx = id;
    }
    if (isDefined(targetLifetime) && point.x <= targetLifetime) {
      predictedLifetimeIdx = id;
    }
  });

  let predictedPoints: RHIMAPOReportingWearManagementApiV1ModelsBOFPredictedPoint[] | undefined;
  let criticalPoints: RHIMAPOReportingWearManagementApiV1ModelsBOFPredictedPoint[] | undefined;

  if (criticalIdx === -1) {
    // if there is no critical point, we use the measured points
    predictedPoints = predictedLifetimeIdx !== -1 ? predictions.slice(0, predictedLifetimeIdx + 1) : predictions;
    criticalPoints = [];
  } else {
    // if there is a critical point, we separate predicted and critical points
    predictedPoints = predictions.slice(0, criticalIdx + 1);
    if (isRh && criticalIdx + 1 === predictions.length) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      criticalPoints = [predictions[criticalIdx]!];
    } else {
      criticalPoints = predictions
        .slice(criticalIdx + 1, predictedLifetimeIdx !== -1 ? predictedLifetimeIdx + 1 : predictions.length - 1)
        .filter((item: Point) => item.y >= 0);
    }
  }

  // Get the measured points based on the type.
  const measuredPoints = (hotSpot.measuredPoints || []).find((item) => item.type === 'min') || { values: [{ x: 0, y: 0 }] };

  return {
    name: hotSpot.name ?? '',
    displayName: hotSpot.displayName ?? '',
    area: `${index + 1}`,
    measuredPoints: measuredPoints.values as NonEmptyArray<Point>,
    targetLifeTime: (hotSpot.targetLifetimePoints || []) as NonEmptyArray<Point>,
    predictedPoints,
    criticalPoints: criticalPoints,
    univariateRemainingHeats: hotSpot.univariateRemainingHeats ?? undefined,
    univariatePredictedLifetime:
      isDefined(hotSpot.univariatePredictedPoints) && hasElements(hotSpot.univariatePredictedPoints) ? last(hotSpot.univariatePredictedPoints).x : 0,
    remainingHeats: hotSpot.remainingHeats,
    predictedLifetime: (hasElements(predictedPoints) ? last(predictedPoints).x : 0) || hotSpot.predictedLifetime,
    averageWearSpeed: hotSpot.averageWearSpeed ?? 0,
    threshold: hotSpot.threshold,
    parameters: hotSpot.parameters ?? [],
    brickSuppliers: hotSpot.brickSuppliers ?? [],
    univariatePredictedLifetimeOutlook: hotSpot.univariatePredictedLifetimeOutlook ?? [],
    univariatePredictedPoints: hotSpot.univariatePredictedPoints ?? [],
    lowerThreshold: isDefined(hotSpot.standardCampaign)
      ? (hotSpot.standardCampaign.lower_threshold ?? []).map((y, index) => ({ x: index, y: y < 0 ? 0 : y }))
      : [],
    upperThreshold: isDefined(hotSpot.standardCampaign)
      ? (hotSpot.standardCampaign.upper_threshold ?? []).map((y, index) => ({ x: index, y: y < 0 ? 0 : y }))
      : [],
  };
};

const retrieveProcessParamDataForPlotting = (
  param: RHIMAPOReportingWearManagementApiV1ModelsBOFProcessParameter,
  index: number,
  targetLifetime: number | undefined
): IProcessParams => {
  // we distinguish a special kind of predicted points: the one that are below the critical threshold
  // therefore, we determine where in the predicted points the critical points start
  let criticalIdx = -1;
  let predictedLifetimeIdx = -1;
  const predictions = param.predictedAccumulatedValues ?? [];

  predictions.forEach((point: Point, id: number) => {
    if (point.y >= param.threshold && point.y >= 0) {
      criticalIdx = id;
    }
    if (isDefined(targetLifetime) && point.x <= targetLifetime) {
      predictedLifetimeIdx = id;
    }
  });

  let predictedPoints: RHIMAPOReportingWearManagementApiV1ModelsBOFPredictedPoint[] | undefined;
  let criticalPoints: RHIMAPOReportingWearManagementApiV1ModelsBOFPredictedPoint[] | undefined;

  if (criticalIdx === -1) {
    // if there is no critical point, we use the measured points
    predictedPoints = predictedLifetimeIdx !== -1 ? predictions.slice(0, predictedLifetimeIdx + 1) : predictions;
    criticalPoints = [];
  } else {
    // if there is a critical point, we separate predicted and critical points
    predictedPoints = predictions.slice(0, criticalIdx + 1);
    if (criticalIdx + 1 === predictions.length) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      criticalPoints = [predictions[criticalIdx]!];
    } else {
      criticalPoints = predictions
        .slice(criticalIdx + 1, predictedLifetimeIdx !== -1 ? predictedLifetimeIdx + 1 : predictions.length - 1)
        .filter((item: Point) => item.y >= 0);
    }
  }

  // Get the measured points based on the type.
  const measuredPoints = param.accumulatedValues || [];

  return {
    name: param.name ?? '',
    measuredPoints: measuredPoints as NonEmptyArray<RHIMAPOReportingWearManagementApiV1ModelsBOFAccumulatedValue>,
    predictedPoints,
    criticalPoints: criticalPoints,
    remainingHeats: param.remainingHeats,
    predictedLifetime: (hasElements(predictedPoints) ? last(predictedPoints).x : 0) || param.predictedLifetime,
    threshold: param.threshold,
    forecastRate: param.forecastRate,
    index,
  };
};
