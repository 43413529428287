import { settings } from '@rhim/design';
import type { Icon } from '@rhim/icons';
import { isDefined } from '@rhim/utils';
import React, { Fragment } from 'react';
import { CSSProperties } from 'styled-components';

import TooltipRows, { TooltipRowsProps } from './TooltipRows';
import Tooltip from './TooltipStyles';
import TooltipStyles from './TooltipStyles';

function isStringArray(x: string[] | string[][]): x is string[] {
  return x.every((i) => typeof i === 'string');
}

export interface TooltipStandaloneProps<Datum> extends Omit<TooltipRowsProps<Datum>, 'keys'> {
  className?: string;
  keys: string[] | string[][];
  HeaderIcon?: React.FunctionComponent<Icon>;
  title?: ((d: Datum | undefined) => string | number) | string | number;
  subTitle?: ((d: Datum | undefined) => string | number) | string | number;
  width?: number;
  style?: CSSProperties;
}

function TooltipStandaloneComponent<Datum>(props: TooltipStandaloneProps<Datum>, ref: React.ForwardedRef<HTMLDivElement>) {
  const { belowThreshold, className, datum, keys, label, labelFormat, HeaderIcon, themeConfig, subTitle, title, value, valueFormat, style } = props;

  const tootipRowsProps = {
    belowThreshold,
    datum,
    label,
    labelFormat,
    themeConfig,
    value,
    valueFormat,
  };

  return (
    <Tooltip.Wrapper ref={ref} className={className} style={style}>
      {isDefined(title) && (
        <Tooltip.Header>
          {isDefined(HeaderIcon) && <HeaderIcon fill={settings.colors.Primary.Blue_9} />}
          <Tooltip.Title>{typeof title === 'function' ? title(datum) : title}</Tooltip.Title>
          <Tooltip.SubTitle>{typeof subTitle === 'function' ? subTitle(datum) : subTitle}</Tooltip.SubTitle>
        </Tooltip.Header>
      )}
      <Tooltip.Body>
        {isStringArray(keys) ? (
          <TooltipRows keys={keys} {...tootipRowsProps} />
        ) : (
          keys.map((group, i) => (
            <Fragment key={i}>
              <TooltipRows keys={group} {...tootipRowsProps} />
              {i < keys.length - 1 && <TooltipStyles.Separator />}
            </Fragment>
          ))
        )}
      </Tooltip.Body>
    </Tooltip.Wrapper>
  );
}

TooltipStandaloneComponent.displayName = 'TooltipStandalone';

export default React.forwardRef(TooltipStandaloneComponent) as <T>(
  p: TooltipStandaloneProps<T> & { ref?: React.Ref<HTMLElement | SVGAElement> }
) => JSX.Element;
