import { criticalHeatIcon } from '@rhim/test-ids';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const CriticalHeatIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest} data-test-id={criticalHeatIcon}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <g>
              <g>
                <path
                  d="M7.93.035c.174-.076.37-.025.5.13.577.7 1.148 1.333 1.69 1.934C12.202 4.405 14 6.397 14 10.014 14 13.539 11.533 16 8 16c-3.42 0-6-2.675-6-6.222 0-2.065.606-3.478 1.315-4.743.448-.797.685-1.408 1.362-2.561.107-.182.24-.371.446-.341.196.008.193.623.252.84.174.643.068 2.167.582 3.125C6.789 3.925 7.633.55 7.633.518c.005-.215.122-.406.297-.483zM8 10.5c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5 1.5-.672 1.5-1.5-.672-1.5-1.5-1.5zM8 5c-.552 0-1 .448-1 1v3c0 .552.448 1 1 1s1-.448 1-1V6c0-.552-.448-1-1-1z"
                  transform="translate(-983 -344) translate(292 334) translate(443) translate(248 10)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

CriticalHeatIcon.defaultProps = {
  size: '16',
  fill: '#D30000',
};
