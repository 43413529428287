import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const VesselRHIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M16 12.692c-.232.008-.464.011-.697.011-1.525 0-2.764-.214-3.858-.403-.931-.162-1.736-.3-2.651-.3-.267 0-.532.006-.794.02V6.302L9.535 4h4.93L16 6.302v6.39zM21 7h-3V6c0-.198-.059-.39-.168-.555l-2-3C15.646 2.167 15.334 2 15 2H9c-.334 0-.646.167-.832.445l-2 3C6.059 5.61 6 5.802 6 6v1.586L3.707 5.293c-.391-.39-1.023-.39-1.414 0s-.391 1.023 0 1.414L6 10.414V17c0 .552.448 1 1 1h1v2.5c0 .828.671 1.5 1.5 1.5s1.5-.672 1.5-1.5V18h2v2.5c0 .828.671 1.5 1.5 1.5s1.5-.672 1.5-1.5V18h1c.552 0 1-.448 1-1V9h3c.552 0 1-.448 1-1 0-.553-.448-1-1-1z"
      />
    </svg>
  );
};

VesselRHIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
