import { css } from 'styled-components';

import * as col from './colors';

export enum FontSize {
  XXXX_Large = '49px',
  XXX_Large = '39px',
  XX_Large = '31px',
  X_Large = '25px',
  Large = '20px',
  Medium = '16px',
  Small = '14px',
  X_Small = '12px',
  XX_Small = '10px',
  XXX_Small = '8px',
}

export enum LineHeight {
  Line_Height_60 = '60px',
  Line_Height_48 = '48px',
  Line_Height_40 = '40px',
  Line_Height_32 = '32px',
  Line_Height_28 = '28px',
  Line_Height_24 = '24px',
  Line_Height_20 = '20px',
  Line_Height_16 = '16px',
  Line_Height_14 = '14px',
  Line_Height_12 = '12px',
}

/**
 * TODO: Add fallback fonts.
 */

export enum FontFamily {
  Regular = 'nortw05-regular',
  Medium = 'nortw05-medium',
  Bold = 'nortw05-bold',
}

/**
 * Textsytle Definitions
 */

type BaseID = 'h_default' | 'h_inverted' | 'p_default' | 'p_inverted' | 'p_subdued' | 'p_subdued_inverted';
type Base = {
  fontFamily: FontFamily;
  color: col.Any;
};
type BaseType = {
  [base in BaseID]: Base;
};

export const Bases: BaseType = {
  h_default: {
    fontFamily: FontFamily.Bold,
    color: col.Primary.Grey_8,
  },
  h_inverted: {
    fontFamily: FontFamily.Bold,
    color: col.Primary.Grey_1,
  },
  p_default: {
    fontFamily: FontFamily.Regular,
    color: col.Primary.Grey_8,
  },
  p_inverted: {
    fontFamily: FontFamily.Regular,
    color: col.Primary.Grey_2,
  },
  p_subdued: {
    fontFamily: FontFamily.Regular,
    color: col.Primary.Grey_6,
  },
  p_subdued_inverted: {
    fontFamily: FontFamily.Regular,
    color: col.Primary.Blue_4,
  },
};

type Size = {
  fontSize: FontSize;
  lineHeight: LineHeight;
};
type HeadlineSizeID = 'xxxxl' | 'xxxl' | 'xxl' | 'xl' | 'l' | 'm' | 's';
type HeadlineSizeType = {
  [size in HeadlineSizeID]: Size;
};

export const HeadlineSizes: HeadlineSizeType = {
  xxxxl: {
    fontSize: FontSize.XXXX_Large,
    lineHeight: LineHeight.Line_Height_60,
  },
  xxxl: {
    fontSize: FontSize.XXX_Large,
    lineHeight: LineHeight.Line_Height_48,
  },
  xxl: {
    fontSize: FontSize.XX_Large,
    lineHeight: LineHeight.Line_Height_40,
  },
  xl: {
    fontSize: FontSize.X_Large,
    lineHeight: LineHeight.Line_Height_32,
  },
  l: {
    fontSize: FontSize.Large,
    lineHeight: LineHeight.Line_Height_24,
  },
  m: {
    fontSize: FontSize.Medium,
    lineHeight: LineHeight.Line_Height_20,
  },
  s: {
    fontSize: FontSize.Small,
    lineHeight: LineHeight.Line_Height_16,
  },
};

type Font = {
  fontFamily: FontFamily;
  color: col.Any;
  fontSize: FontSize;
  lineHeight: LineHeight;
};

type HeadlineID =
  | 'h_xxxxl'
  | 'h_xxxxl_inverted'
  | 'h_xxxl'
  | 'h_xxxl_inverted'
  | 'h_xxl'
  | 'h_xxl_inverted'
  | 'h_xl'
  | 'h_xl_inverted'
  | 'h_l'
  | 'h_l_inverted'
  | 'h_m'
  | 'h_m_inverted'
  | 'h_s'
  | 'h_s_inverted';
type HeadlineType = {
  [headline in HeadlineID]: Font;
};

export const Headlines: HeadlineType = {
  h_xxxxl: {
    ...Bases.h_default,
    ...HeadlineSizes.xxxxl,
  },
  h_xxxxl_inverted: {
    ...Bases.h_inverted,
    ...HeadlineSizes.xxxxl,
  },
  h_xxxl: {
    ...Bases.h_default,
    ...HeadlineSizes.xxxl,
  },
  h_xxxl_inverted: {
    ...Bases.h_inverted,
    ...HeadlineSizes.xxxl,
  },
  h_xxl: {
    ...Bases.h_default,
    ...HeadlineSizes.xxl,
  },
  h_xxl_inverted: {
    ...Bases.h_inverted,
    ...HeadlineSizes.xxl,
  },
  h_xl: {
    ...Bases.h_default,
    ...HeadlineSizes.xl,
  },
  h_xl_inverted: {
    ...Bases.h_inverted,
    ...HeadlineSizes.xl,
  },
  h_l: {
    ...Bases.h_default,
    ...HeadlineSizes.l,
  },
  h_l_inverted: {
    ...Bases.h_inverted,
    ...HeadlineSizes.l,
  },
  h_m: {
    ...Bases.h_default,
    ...HeadlineSizes.m,
  },
  h_m_inverted: {
    ...Bases.h_inverted,
    ...HeadlineSizes.m,
  },
  h_s: {
    ...Bases.h_default,
    ...HeadlineSizes.s,
  },
  h_s_inverted: {
    ...Bases.h_inverted,
    ...HeadlineSizes.s,
  },
};

// ----------------------------------
// STYLES
// ----------------------------------

export const h_sizes = {
  xxxxl: css(HeadlineSizes.xxxxl),
  xxxl: css(HeadlineSizes.xxxl),
  xxl: css(HeadlineSizes.xxl),
  xl: css(HeadlineSizes.xl),
  l: css(HeadlineSizes.l),
  m: css(HeadlineSizes.m),
  s: css(HeadlineSizes.s),
};

export const bases = {
  h_default: css(Bases.h_default),
  h_inverted: css(Bases.h_inverted),
};

export const fonts = {
  h_xxxxl: css(Headlines.h_xxxxl),
  h_xxxxl_inverted: css(Headlines.h_xxxxl_inverted),
  h_xxxl: css(Headlines.h_xxxl),
  h_xxxl_inverted: css(Headlines.h_xxxl_inverted),
  h_xxl: css(Headlines.h_xxl),
  h_xxl_inverted: css(Headlines.h_xxl_inverted),
  h_xl: css(Headlines.h_xl),
  h_xl_inverted: css(Headlines.h_xl_inverted),
  h_l: css(Headlines.h_l),
  h_l_inverted: css(Headlines.h_l_inverted),
  h_m: css(Headlines.h_m),
  h_m_inverted: css(Headlines.h_m_inverted),
  h_s: css(Headlines.h_s),
  h_s_inverted: css(Headlines.h_s_inverted),
};
