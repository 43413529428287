import { settings } from '@rhim/design';
import { isDefined } from '@rhim/utils';
import { AxisScale } from '@visx/xychart';
import { AxisLeft as YAxis, TickLabelProps } from '@vx/axis';
import { ScaleInput } from '@vx/scale';
import * as d3 from 'd3';
import { ScaleLinear } from 'd3-scale';
import { useMemo } from 'react';

import { ZOOM_INIT } from '../Zoom';

type AxisLeftProps = {
  yScale: d3.ScaleLinear<number, number>;
};

/**
 * Ticks values for the x axis. Dynamically calculated based on the graph width.
 */
export const getAxisTicksValues = (min: number, max: number, zoomLevel?: number): number[] => {
  if (min === max) {
    const mid = max / 2;
    return [0, mid / 2, mid, max - (max - mid) / 2, max];
  }
  const mid = max - (max - min) / 2;

  const divide = (num = 100, n = 4) => {
    const f = Math.floor(num / n);
    return [...Array(n)].map((_, i) => (i - n + 1 ? f : num - i * f));
  };
  const labels = [0];

  if (isDefined(zoomLevel)) {
    const dividerNr = zoomLevel !== ZOOM_INIT ? 4 * Math.round(zoomLevel) : 4;
    const result = divide(max, dividerNr > max ? max : dividerNr);
    result.forEach((item, index) => {
      if (index !== 0) {
        labels.push(item + item * index > max ? max : item + item * index);
      } else if (index === result.length - 1) {
        labels.push(max);
      } else {
        labels.push(item);
      }
    });
  }

  return isDefined(zoomLevel) && zoomLevel !== ZOOM_INIT ? labels : [min, min + (mid - min) / 2, mid, max - (max - mid) / 2, max];
};

export const AxisLeft = ({ yScale }: AxisLeftProps) => {
  const domain = yScale.domain();

  const axisLeftProps = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const tickValues = getAxisTicksValues(domain[0]!, domain[1]!);
    const numTicks = tickValues.length;

    /**
     * The `f` is used to remove decimal separators used by default.
     */
    const tickFormatter = yScale.tickFormat(numTicks, 'f');

    const tickLabelProps: TickLabelProps<ScaleInput<ScaleLinear<number, number>>> = () => ({
      x: -25,
      dy: 5,
      textAnchor: 'middle',
      style: {
        fontFamily: `${settings.typography.FontFamily.Regular}`,
        fontSize: `${settings.typography.FontSize.X_Small}`,
        fill: settings.colors.Primary.Grey_6,
      },
    });

    return { scale: yScale, numTicks, tickValues, tickFormatter, tickLabelProps };
  }, [domain, yScale]);
  return (
    <YAxis
      scale={yScale as AxisScale}
      tickValues={axisLeftProps.tickValues}
      stroke={settings.colors.Primary.Grey_4}
      tickLength={4}
      numTicks={axisLeftProps.numTicks}
      tickFormat={axisLeftProps.tickFormatter}
      tickLabelProps={axisLeftProps.tickLabelProps}
      tickStroke={settings.colors.Primary.Grey_4}
      hideZero={false}
    />
  );
};
