import { settings } from '@rhim/design';
import { headerRhimMessagePanelContainer, messageRhimMessagePanelContainer } from '@rhim/test-ids';
import { isDefined } from '@rhim/utils';
import React from 'react';
import styled from 'styled-components';

import IconSuccess64 from '../../../../../assets/icons-64/valid-64.svg';
import { TableRenderers } from '../../utilities/TableRenderer';

interface IMessagePanel {
  type: string;
  title: string;
  message?: string;
  actionButtons: JSX.Element;
}

const getIcon = (type: string) => {
  switch (type) {
    case 'warning':
      return TableRenderers.IconWarning;
    case 'error':
      return TableRenderers.IconAlert;
    default:
      return <IconSuccess64 width="64" height="64" color="yellow" fill="orange" style={{ fill: 'brown', color: 'purple' }} />;
  }
};

/**
 * Message Panel used for displaying different message types (error, success, warning).
 * @param type Message type.
 * @param title Message title.
 * @param [message] Message text.
 * @param actionButtons Action buttons.
 */
const MessagePanel: React.ChildlessComponent<IMessagePanel> = ({ type, title, message, actionButtons }) => {
  return (
    <Container>
      {getIcon(type)}
      <StyledHeader data-test-id={headerRhimMessagePanelContainer}>{title}</StyledHeader>
      {isDefined(message) && <Paragraph data-test-id={messageRhimMessagePanelContainer}>{message}</Paragraph>}
      {actionButtons}
    </Container>
  );
};

export default MessagePanel;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledHeader = styled.h1`
  ${settings.typography.fonts.h_xxxxl};
  text-align: center;
  margin-top: ${settings.Spacing.Spacing_300};
  margin-bottom: 10px;
`;

const Paragraph = styled.p`
  max-width: 600px;
  text-align: center;
  font-size: 16px;
  font-size: ${settings.typography.FontSize.Medium};
  color: ${settings.colors.Primary.Grey_6};
  font-family: ${settings.typography.FontFamily.Regular};
  margin-bottom: 0;
`;
