import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const SurfacePointCloud: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 4a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM9 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm0 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1-3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm4 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm1-9a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1 5a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm4 2a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm1-9a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1 5a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm5-7a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm1-9a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1 5a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm5-3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1 5a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm1-9a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-9 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
      />
    </svg>
  );
};

SurfacePointCloud.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
