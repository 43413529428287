import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  backgroundColor?: Property.Color;
  foregroundColor?: Property.Color;
  title?: string;
  role?: string;
}

export const ValidIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, backgroundColor, foregroundColor, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 64 64" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path
              fill={backgroundColor}
              d="M32 5.333C17.272 5.333 5.333 17.272 5.333 32c0 14.728 11.939 26.667 26.667 26.667 14.728 0 26.667-11.939 26.667-26.667C58.667 17.272 46.728 5.333 32 5.333"
              transform="translate(-710 -2606) translate(710 2606)"
            />
            <path
              fill={foregroundColor}
              d="M47.467 25.6l-16 21.333c-.464.619-1.174 1.006-1.944 1.059-.062.005-.126.008-.19.008-.704 0-1.384-.28-1.885-.781L16.781 36.552c-1.04-1.043-1.04-2.73 0-3.77 1.043-1.043 2.731-1.043 3.771 0l8.493 8.49L43.2 22.4c.883-1.176 2.555-1.416 3.733-.533 1.179.882 1.419 2.554.534 3.733"
              transform="translate(-710 -2606) translate(710 2606)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

ValidIcon.defaultProps = {
  size: '64',
  foregroundColor: settings.colors.Monochromatic.Black,
  backgroundColor: settings.colors.Operational.State_Notif_Green_2,
};
