import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  backgroundColor?: Property.Color;
  foregroundColor?: Property.Color;
  title?: string;
  role?: string;
}

export const LoaderIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, backgroundColor, foregroundColor, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 64 64" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path
              fill={backgroundColor}
              d="M8.306 50.51c.823-.736 2.087-.667 2.824.157C6.532 45.53 4 38.9 4 32 4 16.56 16.56 4 32 4c-1.105 0-2-.895-2-2 0-1.104.895-2 2-2C14.355 0 0 14.356 0 32c0 7.887 2.894 15.464 8.15 21.335-.737-.823-.667-2.088.156-2.824"
            />
            <path
              fill={foregroundColor}
              d="M32 64c-9.09 0-17.783-3.888-23.85-10.665-.737-.823-.667-2.088.156-2.824.823-.738 2.087-.668 2.824.156C16.439 56.599 24.046 60 32 60c15.439 0 28-12.561 28-28S47.439 4 32 4c-1.104 0-2-.896-2-2s.896-2 2-2c17.645 0 32 14.355 32 32S49.645 64 32 64"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

LoaderIcon.defaultProps = {
  size: '64',
  foregroundColor: settings.colors.Operational.State_Blue_2,
  backgroundColor: '#D7DCE0',
};
