import { settings } from '@rhim/design';
import React from 'react';
import styled from 'styled-components';

interface Props {
  icon?: React.ReactNode;
  menuItemLabel: string;
}
const MenuItem: React.ChildlessComponent<Props> = React.memo(function MenuItem(props) {
  const { icon, menuItemLabel } = props;
  return (
    <SMenuItemContainer>
      {icon}
      <SMenuItemLabel>{menuItemLabel}</SMenuItemLabel>
    </SMenuItemContainer>
  );
});
MenuItem.whyDidYouRender = true;
export default React.memo(MenuItem);

const SMenuItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SMenuItemLabel = styled.span`
  margin-left: ${settings.Spacing.Spacing_100};
`;
