import {
  Animation,
  AnimationGroup,
  ArcRotateCamera,
  Color3,
  Color4,
  CubicEase,
  EasingFunction,
  Engine,
  FxaaPostProcess,
  HemisphericLight,
  Scene,
  Tools,
  Vector3,
  Viewport,
} from '@babylonjs/core';
import { assert, isDefined } from '@rhim/utils';

import { CAMERA_CONFIG } from './config';
import { CameraOrientation, CameraState, Scene3dApiFacade } from './types';

/**
 *
 * @param engine
 * @param camera
 * @param size pixels
 */
export function adaptCameraViewport(engine: Engine, camera: ArcRotateCamera, size: number) {
  const canvas = engine.getRenderingCanvas();
  const scaling = engine.getHardwareScalingLevel();
  if (!isDefined(canvas)) {
    return;
  }
  const { width, height } = canvas;
  const scaledSize = size / scaling;

  const cWidth = scaledSize < width ? scaledSize / width : width;
  const cHeight = scaledSize < height ? scaledSize / height : height;
  // position the cube a bit further down to avoid overlapping with the top/left QCKLight icon
  const extraNormalizedTopMargin = 0.03;
  camera.viewport = new Viewport(0, 1 - cHeight - extraNormalizedTopMargin, cWidth, cHeight);
}

export function setupScene(scene: Scene, clearColor: Color4) {
  scene.clearColor = clearColor;
  scene.postProcessesEnabled = true;
}

export function setupLights(scene: Scene) {
  const arcRotateCamera = scene.activeCamera as ArcRotateCamera;
  const light = new HemisphericLight('followLight', Vector3.Down(), scene);
  light.groundColor = Color3.Gray();
  // adjust direction of hemispheric light to the view direction
  light.direction = arcRotateCamera.position;
}

export function setCameraBetaLimits(camera: ArcRotateCamera) {
  camera.lowerBetaLimit = CAMERA_CONFIG.arcRotate.betaLowerLimit;
  camera.upperBetaLimit = CAMERA_CONFIG.arcRotate.betaUpperLimit;
}

export function setupCameraView(scene: Scene, initialCameraState: CameraState): ArcRotateCamera {
  scene.createDefaultCamera(true, true, true);
  const camera = scene.activeCamera as ArcRotateCamera;
  setCameraBetaLimits(camera);
  camera.radius = initialCameraState.radius;
  camera.alpha = initialCameraState.alpha;
  camera.beta = initialCameraState.beta;

  // Keep the camera's beta within a [-Math.PI, Math.PI] range to prevent an odd sideeffect from happening ( https://dev.azure.com/RHIM/HMD/_workitems/edit/79887/ )
  camera.onViewMatrixChangedObservable.add((eventData) => {
    const eventCamera = eventData as ArcRotateCamera;
    if (eventCamera.beta > Math.PI) {
      eventCamera.beta = eventCamera.beta - 2 * Math.PI;
    } else if (eventCamera.beta < -Math.PI) {
      eventCamera.beta = eventCamera.beta + 2 * Math.PI;
    }
  });

  camera.upperRadiusLimit = 3.5; // cap the maximum zoom-out
  // the smaller the numbers below, the faster is the movement
  camera.panningSensibility = 13000; // pan
  camera.pinchPrecision = 0;
  camera.pinchDeltaPercentage = 0.01; // use a delta of 10% for pinching
  // applying an antialiasing filter on a final picture which is projected to the active camera
  new FxaaPostProcess('fxaa', 1, camera);

  return camera;
}

function findCloseAnglePath(startAngle: number, endAngle: number) {
  const tau = 2 * Math.PI;
  const startPositiveAngle = (startAngle % tau) + (Math.sign(startAngle) > 0 ? 0 : tau);
  const endPositiveAngle = (endAngle % tau) + (Math.sign(endAngle) > 0 ? 0 : tau);
  const diff = Math.abs(endPositiveAngle - startPositiveAngle);

  return {
    start: startPositiveAngle,
    end: diff < Math.PI ? endPositiveAngle : endPositiveAngle - tau,
  };
}

interface CameraToOptions {
  orientation?: CameraOrientation;
  radius?: number;
  target?: Vector3;
  withAnimation?: boolean;
}

export function navigateCameraTo(scene: Scene, camera: ArcRotateCamera, options: CameraToOptions) {
  const { orientation, radius, target, withAnimation = true } = options;
  const {
    cameraStates,
    animation: { fps, duration },
  } = CAMERA_CONFIG;

  const lastFrame = fps * duration;
  const animationGroup = new AnimationGroup('cameraAnimationGroup', scene);
  const easingFunction = new CubicEase();
  easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);

  if (isDefined(orientation)) {
    assert(cameraStates.has(orientation), 'Incorrectly set camera orientation');

    const { alpha, beta } = cameraStates.get(orientation);

    const alphaProps = findCloseAnglePath(camera.alpha, alpha);
    const betaProps = findCloseAnglePath(camera.beta, beta);

    const alphaAnimation = Animation.CreateAnimation('alpha', Animation.ANIMATIONTYPE_FLOAT, fps, easingFunction);
    alphaAnimation.setKeys([
      { frame: 0, value: alphaProps.start },
      { frame: lastFrame, value: alphaProps.end },
    ]);
    animationGroup.addTargetedAnimation(alphaAnimation, camera);

    const betaAnimation = Animation.CreateAnimation('beta', Animation.ANIMATIONTYPE_FLOAT, fps, easingFunction);
    betaAnimation.setKeys([
      { frame: 0, value: betaProps.start },
      { frame: lastFrame, value: betaProps.end },
    ]);
    animationGroup.addTargetedAnimation(betaAnimation, camera);
  }

  if (isDefined(radius) && radius !== camera.radius) {
    const radiusAnimation = Animation.CreateAnimation('radius', Animation.ANIMATIONTYPE_FLOAT, fps, easingFunction);
    radiusAnimation.setKeys([
      { frame: 0, value: camera.radius },
      { frame: lastFrame, value: radius },
    ]);
    animationGroup.addTargetedAnimation(radiusAnimation, camera);
  }

  if (isDefined(target) && !target.equals(camera.target)) {
    const targetAnimation = Animation.CreateAnimation('target', Animation.ANIMATIONTYPE_VECTOR3, fps, easingFunction);
    targetAnimation.setKeys([
      { frame: 0, value: camera.target.clone() },
      { frame: lastFrame, value: target.clone() },
    ]);
    animationGroup.addTargetedAnimation(targetAnimation, camera);
  }

  animationGroup.normalize(0, lastFrame);

  animationGroup.onAnimationEndObservable.addOnce(() => {
    animationGroup.dispose();
  });

  if (withAnimation) {
    animationGroup.play(false);
  } else {
    animationGroup.play();
    animationGroup.goToFrame(lastFrame);
  }
}

export function load3dInspector(scene3d: Scene3dApiFacade) {
  Promise.all([import('@babylonjs/inspector'), import('@babylonjs/core/Debug/debugLayer')]).then(() => {
    const scene = scene3d.getScene();
    document.addEventListener('keydown', (e: KeyboardEvent) => {
      const { shiftKey, metaKey, ctrlKey, code } = e;
      if (shiftKey && metaKey && ctrlKey && code === 'KeyI') {
        if (scene.debugLayer.isVisible()) {
          scene3d.setEngineAutoStopMode('on');
          scene.debugLayer.hide();
        } else {
          scene3d.setEngineAutoStopMode('off');
          scene.debugLayer.show({
            embedMode: true,
          });
        }
      }
    });
  });
}

export function takeScreenshot(camera: ArcRotateCamera, alpha: number, beta: number, radius: number): Promise<string> {
  return new Promise<string>((resolve) => {
    const engine = camera.getEngine();
    camera.alpha = alpha;
    camera.beta = beta;
    camera.radius = radius;

    Tools.CreateScreenshotUsingRenderTarget(
      engine,
      camera,
      800,
      (imageData: string) => {
        resolve(imageData);
      },
      'image/jpeg'
    );
  });
}

export function getDefaultCameraRadius(worldExtendsMin: Vector3, worldExtendsMax: Vector3) {
  const boundingBoxDiagonalLength = Vector3.Distance(worldExtendsMin, worldExtendsMax);
  // move the camera a bit further away (20% more) than the world's bounding box diagonal length
  return boundingBoxDiagonalLength * 1.2;
}

export function getDefaultCameraZoomConfig(worldExtendsMin: Vector3, worldExtendsMax: Vector3) {
  const defaultCameraRadius = getDefaultCameraRadius(worldExtendsMin, worldExtendsMax);

  return {
    // set the mouse-wheel zoom in/out sensitivity ( the larger the number, the slower the zoom in/out )
    mouseWheelPrecision: 3 * defaultCameraRadius,
    // set the zoom in/out buttons step ( the larger the number, the larger the zoom in/out step )
    zoomStep: defaultCameraRadius / 10,
  };
}

/**
 * Call this function AFTER the 3d model has been added to the scene.
 * It will :
 * a) set the camera's maximum zoom-out taking into account the 3d-mesh size
 * b) set the mouse-wheel's zoom in/out sensitivity taking into account the 3d-mesh size
 * If you want to "reset" the camera view provide the optional "viewConfig" which will :
 * - point the camera's target to the center of the scene's world
 * - set the camera's alpha & beta to the provided values
 * - set the camera's radius to the default distance
 * The "viewConfig" is optional because we do not always want to reset the camera's view.
 * For instance, when we switch to another measurement, we want to preserve the current camera state (rotation, radius & target)
 */
interface CameraViewConfig {
  cameraRotation: { alpha: number; beta: number };
}
export function initializeCameraView(scene: Scene, camera: ArcRotateCamera, viewConfig?: CameraViewConfig) {
  const { min, max } = scene.getWorldExtends();
  const defaultCameraRadius = getDefaultCameraRadius(min, max);
  if (isDefined(viewConfig)) {
    // Point the camera's target at the center of the scene's world
    const center = max.add(min).scale(0.5);
    camera.setTarget(center);
    // Set the camera's alpha & beta rotation to the provided values
    camera.alpha = viewConfig.cameraRotation.alpha;
    camera.beta = viewConfig.cameraRotation.beta;
    /**
     * Set the camera's radius (distance from the 3d mesh)
     * Avoiding heavy computations including projecting to the camera viewport and step by step alignment
     * I compute the distance between min and max points of the common binding box and assign the distance
     * as a camera radius.
     */
    camera.radius = defaultCameraRadius;
  }
  // limit maximum camera zoom-out ( the larger the number, the more we can zoom out )
  camera.upperRadiusLimit = 2 * defaultCameraRadius;
  // set the mouse-wheel zoom in/out sensitivity ( the larger the number, the slower the zoom in/out )
  camera.wheelPrecision = getDefaultCameraZoomConfig(min, max).mouseWheelPrecision;
  // set the camera panning sensibility ( the smaller the number, the faster the panning)
  camera.panningSensibility = 1000;
}
