import { RHIMFleetOverviewServiceV1ModelsVolumesDashboardDto } from '@rhim/rest/fleetOverview';
import { ensure, isDefined, specific } from '@rhim/utils';
import { QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { ExpressiveQueryKey } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api/fleetOverview';

type Payload = RHIMFleetOverviewServiceV1ModelsVolumesDashboardDto;

export function useCampaignHavingVolumes(
  vesselId?: string,
  campaignId?: number,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) {
  return useQuery(getKey(vesselId, campaignId), (context) => queryFn(context, options?.axiosConfiguration), {
    ...options?.configuration,
    // Dependent query : to get the details of a campaign, we have to have the vessel's id and the campaign's id in question.
    // Do not execute until this is provided.
    enabled: isDefined(vesselId) && isDefined(campaignId),
  });
}

const getKey = (vesselId?: string, campaignId?: number) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'volumesDashboard',
      scope: 'campaign',
      entity: 'detail',
      vesselId,
      campaignId,
    },
  ]);

const queryFn = (
  { queryKey: [{ vesselId, campaignId }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>,
  axiosConfiguration?: AxiosRequestConfig
) => {
  return API.volumesDashboardApi
    .getVolumesdashboardDetailsVesselidCampaign(ensure(vesselId), ensure(campaignId), { signal, ...axiosConfiguration })
    .then((response) => response.data);
};

useCampaignHavingVolumes.getKey = getKey;
useCampaignHavingVolumes.queryFn = queryFn;
