import { ArcRotateCamera, Camera, Color3, Color4, Engine, HemisphericLight, Scene, Vector3 } from '@babylonjs/core';
import React, { createContext, useMemo, useRef } from 'react';

interface IContext {
  scene: Scene | null;
  imageMap: Map<string, string>;
}

export const Section3DContext = createContext<IContext>({} as IContext);

export function withSection3D<P extends object>() {
  return (ChildComponent: React.ChildlessComponent<P>) =>
    function WithSection3DComponent(props: P) {
      const engine = useMemo<Engine>(() => {
        const canvas = document.createElement('canvas');
        canvas.width = 600;
        canvas.height = 300;
        const currentEngine = new Engine(canvas, true);
        return currentEngine;
      }, []);

      const imageMap = useRef<Map<string, string>>(new Map());

      const scene = useMemo<Scene>(() => {
        const currentScene = new Scene(engine, {});
        currentScene.clearColor = new Color4(0, 0, 0, 0);

        const camera = new ArcRotateCamera('camera', 0, 0, 50, Vector3.Zero(), currentScene);
        camera.upVector = new Vector3(0, 0, 1);

        camera.mode = Camera.ORTHOGRAPHIC_CAMERA;
        camera.alpha = (90 * Math.PI) / 180;
        camera.beta = (90 * Math.PI) / 180;

        const light = new HemisphericLight('followLight', Vector3.Down(), currentScene);
        light.groundColor = Color3.Gray();
        light.specular = Color3.Black();
        light.direction = camera.position;

        // for testing only
        camera.attachControl(engine.getRenderingCanvas(), true);

        engine.runRenderLoop(() => {
          currentScene.render();
        });
        return currentScene;
      }, [engine]);

      return (
        <Section3DContext.Provider value={{ scene, imageMap: imageMap.current }}>
          <ChildComponent {...props} />
        </Section3DContext.Provider>
      );
    };
}
