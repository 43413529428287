import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const GraphIcon: React.FunctionComponent<Props> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.706 20.292a.993.993 0 0 1 0 1.415l-.999.999a.993.993 0 0 1-.707.293.997.997 0 0 1-1-1H3a1 1 0 0 1-1-1v-16a.999.999 0 0 1-.707-1.707l1-1a1 1 0 0 1 1.415 0l1 1A.999.999 0 0 1 4 4.999v15h15a1 1 0 0 1 1.707-.707l1 1zm-10.992-3.436a.996.996 0 0 1-.707-.293l-3.856-3.857a.999.999 0 1 1 1.414-1.414l2.93 2.931L14.99 6.36a1 1 0 0 1 1.576-.21l3.856 3.856a1 1 0 1 1-1.414 1.414l-2.93-2.93-4.495 7.862a.997.997 0 0 1-.868.504z"
        fill={fill}
      />
    </svg>
  );
};

GraphIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
