import { AuthError } from '@azure/msal-browser';
import { Logger } from '@rhim/logging';
import { assert, hasElements, isDefined, isEqual, isString, memoize } from '@rhim/utils';
import { Location } from 'history';
import * as React from 'react';

import { withLocation } from '../../higher-order-components';
import { AuthClient, AuthInfo } from './client';

const log = new Logger({
  context: 'Authenticator',
  logLevel: 'info',
  format: (message, _, context) => {
    if (isDefined(context)) {
      return [`[${context}]`, ...message];
    }

    return message;
  },
});

interface Props {
  /**
   * MSAL client.
   */
  client: AuthClient;
  /**
   * @example
   *
   * ["/", "/imprint", /^\/public/]
   */
  whitelist: Array<string | RegExp>;
  children: (state: State) => React.ReactNode;
  location: Location;
}

type State =
  /**
   * Authenticator didn't have the chance to do anything yet.
   */
  | { status: 'initial' }
  /**
   * Authenticator has been initialized, but so far no full login was needed.
   * This happens when the user starts their journey on a public page and wanders
   * around the application without ever attempting to see any protected pages.
   * The optional account exists for cases where the user goes from protected pages
   * (already authenticated) to public pages.
   *
   * Think of this as a provisionary `success` state: like `success`, but without the account object.
   */
  | { status: 'cold'; account?: AuthInfo }
  /**
   * Authenticator is transitioning between states. For example, the user forced to login
   * goes from `initial` through `loading` to either `success` or `error` states when they
   * are being redirected away from the application and asked to authenticate via B2C.
   */
  | { status: 'loading' }
  /**
   * The user is fully authenticated and the `account` object is well-known.
   */
  | { status: 'success'; account: AuthInfo }
  /**
   * It wasn't possible to authenticate. There is a number of reasons why this could happen,
   * including configuration mismatch or any of the reasons enumerated by {@link MSALErrorDescription}.
   */
  | { status: 'error'; error: AuthError };

export const AuthenticationContext = React.createContext<AuthInfo | null>(null);

export const useAccount = (): AuthInfo | null => {
  const context: AuthInfo | null | undefined = React.useContext(AuthenticationContext);

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (context === undefined) {
    throw new Error('The useAccount hook can only be used inside AuthenticationContext.');
  }

  return context;
};

class Authenticator extends React.Component<React.PropsWithChildren<Props>, State> {
  static whyDidYouRender = true;

  state: State = {
    status: 'initial',
  };

  /**
   * Either obtains the token silently or by forcing a new login.
   *
   * Uses the result to set new state.
   */
  async renew() {
    const { status } = this.state;

    if (status === 'initial' || (status === 'cold' && !isDefined(this.state.account))) {
      log.info('Asked to obtain/renew the token for the first time. Switching state to loading');

      this.setState({ status: 'loading' });
    }

    try {
      const account = await this.props.client.authenticate();
      log.info(
        'Successfully authenticated after forcing full login by triggering redirect or popup',
        'This also happens when the already authenticated user navigates to another page'
      );

      this.setState({ status: 'success', account });
    } catch (error) {
      log.warn('Failed to login after triggering redirect or popup; setting up an error state');
      assert(error instanceof AuthError, 'Assumed MSAL throws only instances of AuthError');
      this.setState({ status: 'error', error });
    }
  }

  isWhitelisted(path: string): boolean {
    if (!hasElements(this.props.whitelist)) {
      return false;
    }

    return this.props.whitelist.some((pattern) => {
      if (isString(pattern)) {
        return path === pattern;
      } else {
        return pattern.test(path);
      }
    });
  }

  shouldComponentUpdate(previousProps: Props, previousState: State): boolean {
    const havePropsChanged = !isEqual(previousProps, this.props);
    const hasStateChanged = !isEqual(previousState, this.state);
    // To avoid unecessary re-renders, we first need to check if the account has changed at all before setting the new one.
    // We can assert this by checking if the bearer token has changed. This token contains all relevant account information.
    // So only if one of those relevant information has we do the state update.
    // Otherwise we would trigger unnecessary re-renders with no impact or visible effects.
    if (
      !havePropsChanged &&
      'account' in previousState &&
      'account' in this.state &&
      isDefined(this.state.account) &&
      isDefined(previousState.account) &&
      previousState.account.bearerAuthentication === this.state.account.bearerAuthentication
    ) {
      return false;
    }

    return havePropsChanged || hasStateChanged;
  }

  /**
   * After the component was mounted we ensure that unauthenticated users don't see protected pages.
   */
  async componentDidMount() {
    const { client } = this.props;
    const isWhitelisted = this.isWhitelisted(this.props.location.pathname);
    // first, check if there is already a cached access token

    try {
      const account = await client.rehydrate();

      log.info('Successfully rehydrated');

      if (isWhitelisted) {
        this.setState({ status: 'cold', account }); // in this case the user is already authenticated however we are accessing a whitelisted page
      } else {
        this.setState({ status: 'success', account }); // in this case the user is already authenticated and we can proceed with the full account info and without requiring a login
      }
    } catch (error) {
      // if there is no cached access token, it depends on the requested page on how we proceed

      // if a whitelisted page was requested it's safe to render it without requiring a login (they're accessible to anyone)
      if (isWhitelisted && this.state.status === 'initial') {
        log.info('This page is whitelisted. Skipping login altogether. Entering the cold mode.');
        return this.setState({ status: 'cold' }); // In this case we proceed without requiring a login
      }

      // protected page: we're not on a whitelisted page, which means we force a login before the user can proceed
      log.info('There was no account to rehydrate. Forcing a full login...');
      return this.renew();
    }
  }

  /**
   * When the component updates we trigger the authentication in case the user navigated from a whitelisted to a non-whitelisted route
   */
  async componentDidUpdate(prevProps: Props) {
    const hasPathnameChanged = prevProps.location.pathname !== this.props.location.pathname;
    const isWhitelisted = this.isWhitelisted(this.props.location.pathname);

    if (hasPathnameChanged && !isWhitelisted) {
      /**
       * The user navigated from a public page to a protected page.
       * We can't show the content of the protected page without a login, therefore
       * we temporarily trigger a loading indicator and wait for renewal to happen.
       *
       * Note: if we setState to loading here, it will likely hang there forever.
       */
      await this.renew();
    }
  }

  getMemoizedAccount = memoize(
    () => {
      switch (this.state.status) {
        case 'success':
          return this.state.account;
        case 'cold':
          return this.state.account ?? null;
        default:
          return null;
      }
    },
    (previous, next) => {
      if (isDefined(previous) && isDefined(next)) {
        return previous.bearerAuthentication === next.bearerAuthentication;
      }

      return false;
    }
  );

  render() {
    return <AuthenticationContext.Provider value={this.getMemoizedAccount()}>{this.props.children(this.state)}</AuthenticationContext.Provider>;
  }
}

export const AuthGuard = withLocation(Authenticator);
export default withLocation(Authenticator);
