import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const DeviceIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path stroke={fill} strokeWidth="2" strokeLinejoin="round" d="M1 4h11v11H1zM1 4l3-3h11l-3 3M12 15l3-3V1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.293.293A1 1 0 0 1 4 0h11a1 1 0 0 1 .866 1.5H16V12a1 1 0 0 1-.293.707l-3 3A.997.997 0 0 1 12 16H1a1 1 0 0 1-1-1V4c0-.276.112-.526.293-.707l3-3zM13 12.586l1-1V3.414l-1 1v8.172zM12.586 2l-1 1H3.414l1-1h8.172zM2 5v9h9V5H2z"
        fill={fill}
      />
    </svg>
  );
};

DeviceIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
