import type { RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto } from '@rhim/rest/customerManagement';
import type { AllPrivileges } from '@rhim/sdk/customerManagement/hooks/usePrivileges';
import { assert, hasElements, isDefined } from '@rhim/utils';

import { isQckLightCustomer } from '../../lib/customers/isQckLightCustomer';
import { hasCementVessel, hasLadleVessel } from '../../lib/vessels';
import { CustomerFeatureFlag } from '../../settings/featureFlags';
import { PRIVILEGES, ROUTES } from '../../utilities/constants';

/**
 * Retrieves the start destination based on the provided customer and privileges.
 *
 * @param customer - The customer object. Indicates which vessels and feature toggles are enabled for the given customer.
 * @param privileges - The privileges object. Depending on the role assigned to the user, they get different access levels / privileges.
 * @returns The start destination (path) as a string. E.g. /ingress
 */
export function getStartDestination(customer: RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto, privileges: AllPrivileges): string {
  assert(isDefined(customer.vesselTypes), `Customer's vessel types not set`);
  assert(isDefined(customer.features), "Customer's features not set");

  if (!hasElements(customer.vesselTypes)) {
    return ROUTES.INCOMPLETE_SETUP;
  }

  const isCement = hasCementVessel(customer);
  const hasLadles = hasLadleVessel(customer);

  if (isCement) {
    return ROUTES.ARO_ROOT;
  }

  // begin to distinct start route of a specific QCK light customer and a general HMD customer
  const hasFleetOverviewAccess = privileges.global.has(PRIVILEGES.AccessFleetOverview) || privileges.customer.has(PRIVILEGES.AccessFleetOverview);
  const hasQCKLightFeatureEnabled = customer.features.includes(CustomerFeatureFlag.QCKLight);

  // 1. if customer has ladles and we can show the fleet overview, show it as the start destination no matter which app flavor
  if (hasLadles && hasFleetOverviewAccess && hasQCKLightFeatureEnabled) {
    return ROUTES.FLEET_OVERVIEW;
  }

  // 2. for customers visiting QCK light directly, show a specific QCK light feature rather than the ingress page
  if (isQckLightCustomer()) {
    return getQcklightStartDestination(customer, privileges);
  }

  // 3. for all other use cases show the ingress page
  return ROUTES.INGRESS;
}

/**
 * Retrieves the start destination for QCK light customers.
 * Currently, it will always be the Measurement View, regardless of privileges.
 * If the user doesn't have access to the Measurement View, they will still see the purchasing text for "The requested functionality is part of QCK light"
 */
export function getQcklightStartDestination(_customer: RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto, _privileges: AllPrivileges): string {
  return ROUTES.MEASUREMENT_VIEW;
}
