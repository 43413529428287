import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const HexagonEAFIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill={fill} fillRule="evenodd">
        <path d="M29.03 24.697c.533-.31.97-1.074.97-1.695V8.952c0-.62-.437-1.384-.97-1.694L16.97.233c-.533-.31-1.407-.31-1.94 0L2.97 7.258C2.437 7.568 2 8.331 2 8.953v14.049c0 .621.437 1.384.97 1.695l12.06 7.024c.533.31 1.407.31 1.94 0l12.06-7.024z" />
        <path
          d="M17 8c.552 0 1 .457 1 1.022v1.246L21.466 12a.996.996 0 0 1 .445.538.85.85 0 0 1 .083.279l.006.1V15h1c.513 0 .935.353.993.81l.007.107V22c0 1.105-3.582 2-8 2-4.316 0-7.833-.854-7.994-1.923L8 22v-9.083a.85.85 0 0 1 .097-.394.995.995 0 0 1 .341-.459L8.536 12 12 10.267V9.022c0-.528.39-.96.891-1.016L13 8c.515 0 .94.398.994.91l.006.112v6.956A1.01 1.01 0 0 1 13 17c-.515 0-.94-.398-.994-.91L12 15.978V12.5L10 13.5v5.088a23.88 23.88 0 0 1 2.055-.088c3.334 0 5.556 1 8.89 1 .369 0 .721-.008 1.058-.023L22 17h-1.084c-.507 0-.917-.448-.917-1v-2.501l-2-.999v3.478c0 .528-.39.96-.891 1.016L17 17c-.552 0-1-.457-1-1.022V9.022A1.01 1.01 0 0 1 17 8z"
          fill="#F5F6F7"
        />
      </g>
    </svg>
  );
};

HexagonEAFIcon.defaultProps = {
  size: '32',
  fill: settings.colors.Primary.Grey_6,
};
