import { Feature } from '@paralleldrive/react-feature-toggles';
import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { MeasurementIcon, ProcessIcon } from '@rhim/icons/24';
import { HexagonQCKIcon } from '@rhim/icons/32';
import { DataBox, Hyperlink } from '@rhim/react';
import {
  dataCenterContainerDataCenter,
  infoPanelContainerDataCenter,
  measurementDataPanelContainerDataCenter,
  pointCloudDataPanelContainerDataCenter,
  processDataPanelContainerDataCenter,
} from '@rhim/test-ids';
import { useCallback } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { isQckLightCustomer } from '../lib';
import { CustomerFeatureFlag } from '../settings';
import { ROUTES } from '../utilities';

const Ingress = (): JSX.Element => {
  const { t } = i18nReact.useTranslation(['app', 'ingress', 'shared']);
  const navigate = useNavigate();

  const onClick = useCallback(
    (pathname: string) => {
      navigate(pathname);
    },
    [navigate]
  );

  const getPointCloudDataDataBox = useCallback(
    (isQckLightFeatureEnabled: boolean) => {
      return (
        <DataBox
          dataTestId={pointCloudDataPanelContainerDataCenter}
          isDisabled={!isQckLightFeatureEnabled}
          cardTooltip={
            !isQckLightFeatureEnabled ? (
              <>
                <span>{t('app:customerFeatures.qckLight.heading')}</span>{' '}
                <Trans t={t} i18nKey="app:customerFeatures.qckLight.subHeading">
                  <span>
                    PLEASE REACH OUT
                    <Hyperlink href="mailto:romy-sophie.katz@rhimagnesita.com" mode="inverted">
                      EMAIL
                    </Hyperlink>{' '}
                    FOR PURCHASING
                  </span>
                </Trans>
              </>
            ) : undefined
          }
          onClick={() => onClick('pointclouddata')}
          prefixIcon={isQckLightCustomer() ? undefined : { icon: <HexagonQCKIcon />, tooltip: t('shared:poweredByQCKLight') }}
          title={t('ingress:qckLightPointCloudUploadAndRegistration.dataCenterPanel.pointCloudScanDataTitle')}
          contentText={t('ingress:qckLightPointCloudUploadAndRegistration.dataCenterPanel.pointCloudScanDataContent')}
          buttonText={t('ingress:qckLightPointCloudUploadAndRegistration.dataCenterPanel.pointCloudScanDataButton')}
          icon={<MeasurementIcon fill={settings.colors.Primary.Grey_5} />}
        />
      );
    },
    [onClick, t]
  );

  return (
    <Container data-test-id={dataCenterContainerDataCenter}>
      <StyleDiv data-test-id={infoPanelContainerDataCenter}>
        <Trans i18nKey="ingress:content">
          <StyledHeader>Welcome to the Data Center!</StyledHeader>
          <Paragraph>
            In this Portal you can upload your data. For any questions or comments, don’t hesitate to{' '}
            <Hyperlink
              href="mailto:support.digital@rhimagnesita.com"
              mode="standard"
              css={`
                font-size: inherit;
              `}
            >
              contact us
            </Hyperlink>
          </Paragraph>
        </Trans>
      </StyleDiv>
      <SDataBoxesContainer>
        <DataBox
          dataTestId={processDataPanelContainerDataCenter}
          onClick={() => onClick('processdata')}
          title={t('ingress:processDataTitle')}
          contentText={t('ingress:processDataContent')}
          buttonText={t('ingress:processDataButton')}
          icon={<ProcessIcon fill={settings.colors.Primary.Grey_5} />}
        />
        <DataBox
          dataTestId={measurementDataPanelContainerDataCenter}
          onClick={() => onClick(ROUTES.INGRESS_MEASUREMENT_DATA_UPLOAD)}
          title={t('ingress:measurementDataTitle')}
          contentText={t('ingress:measurementDataContent')}
          buttonText={t('ingress:measurementDataButton')}
          icon={<MeasurementIcon fill={settings.colors.Primary.Grey_5} />}
        />
        <Feature
          name={CustomerFeatureFlag.QCKLight}
          activeComponent={() => getPointCloudDataDataBox(true)}
          inactiveComponent={() => getPointCloudDataDataBox(false)}
        />
      </SDataBoxesContainer>
    </Container>
  );
};

const StyleDiv = styled.div`
  --preferredWidth: 360px;

  width: var(--preferredWidth);
  min-width: var(--preferredWidth);
`;

const Paragraph = styled.p`
  font-family: ${settings.typography.FontFamily.Regular};
  color: ${settings.colors.Primary.Grey_8};
  font-size: 20px;
  line-height: 1.6;
`;

const Container = styled.div`
  display: flex;
  margin: ${settings.Spacing.Spacing_800};
`;

const StyledHeader = styled.h1`
  ${settings.typography.fonts.h_xxxl}
  margin-bottom: 40px;
`;

const SDataBoxesContainer = styled.div`
  margin-left: ${settings.Spacing.Spacing_400};
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  gap: ${settings.Spacing.Spacing_400};
`;

export default Ingress;
