import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { RcFile, UploaderStates as States, useEffectOnce } from '@rhim/react';
import { expectedFileFormatsDescriptionUploadPanel, titleUploadPanel } from '@rhim/test-ids';
import { isDefined, MIMEType } from '@rhim/utils';
import axios, { CancelTokenSource } from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { ErrorCode, SuccessResponseType, SuccessResponseTypeDefault, SuccessResponseTypePointCloud } from '../../api/Upload';
import { MAXIMUM_FILE_SIZE_IN_BYTES, MINIMUM_FILE_SIZE_IN_BYTES } from '../../settings';
import { uploadFile } from './fileUpload';
import { IFilePointCloudUploadMetadata, IFileUploadMetadata, ILesFileUploadMetadata } from './types';

export enum Stage {
  noFileSelected = 'noFileSelected',
  fileSelected = 'fileSelected',
  uploading = 'uploading',
  success = 'success',
  failure = 'failure',
}

export type UploadState =
  | { stage: Stage.noFileSelected }
  | { stage: Stage.fileSelected; selectedFile: RcFile }
  | { stage: Stage.uploading; selectedFile: RcFile; uploadProgressPercent: number }
  | { stage: Stage.success; selectedFile: RcFile; successData: SuccessResponseTypeDefault | SuccessResponseTypePointCloud }
  | { stage: Stage.failure; errorCode: ErrorCode; error?: { title: string; message?: string } };

const INITIAL_STATE: UploadState = {
  stage: Stage.noFileSelected,
};

interface Props {
  className?: string;
  /**
   * The uploadMetadataPayload controls when the file uploading will start.
   * If it is undefined, when the user selects/drag&drops a file, the Uploader will just move to the "fileSelected" stage/view and wait there.
   * Once the uploadMetadataPayload is set, the actual uploading will begin.
   */
  uploadMetadataPayload: IFileUploadMetadata | IFilePointCloudUploadMetadata | ILesFileUploadMetadata | undefined;
  accept: Set<MIMEType>;
  description?: string | React.ReactElement;
  descriptionPosition?: 'right' | 'bottom';
  disabled: boolean;
  onChange?: (state: UploadState) => void;
  shouldCancelUpload?: boolean;
  fileSizeMin?: number;
  fileSizeMax?: number;
  title: string | React.ReactElement;
  isRequired?: boolean;
  dataTestId: string;
  placeholder?: string;
  showDeleteIconAfterUpload?: boolean;
}

const Uploader: React.ChildlessComponent<Props> = React.memo((props) => {
  const {
    className,
    uploadMetadataPayload,
    title,
    isRequired = false,
    placeholder,
    description,
    descriptionPosition = 'right',
    disabled,
    accept,
    fileSizeMin = MINIMUM_FILE_SIZE_IN_BYTES,
    fileSizeMax = MAXIMUM_FILE_SIZE_IN_BYTES,
    dataTestId,
    onChange,
    showDeleteIconAfterUpload = true,
    shouldCancelUpload = false,
  } = props;
  const { t } = i18nReact.useTranslation(['ingress']);
  const [state, setState] = useState<UploadState>(INITIAL_STATE);
  const cancelTokenSourceRef = useRef<CancelTokenSource>(axios.CancelToken.source());

  useEffectOnce(() => {
    return () => {
      cancelTokenSourceRef.current.cancel();
      onChange?.({ stage: Stage.noFileSelected });
    };
  });

  useEffect(() => {
    onChange?.(state);
  }, [onChange, state]);

  useEffect(() => {
    if (!isDefined(uploadMetadataPayload) || state.stage !== 'fileSelected') {
      return;
    }
    const selectedFile = state.selectedFile;
    const onProgress = (uploadProgressPercent: number) => setState({ stage: Stage.uploading, selectedFile, uploadProgressPercent });
    const onSuccess = (successData: SuccessResponseType) =>
      setState({
        stage: Stage.success,
        selectedFile,
        successData,
      });
    const onError = (errorCode: ErrorCode, error?: { title: string; message?: string }) => setState({ stage: Stage.failure, errorCode, error });

    uploadFile(selectedFile, uploadMetadataPayload, cancelTokenSourceRef.current.token, onProgress, onSuccess, onError);
    setState({ stage: Stage.uploading, selectedFile, uploadProgressPercent: 0 });
  }, [state, uploadMetadataPayload, cancelTokenSourceRef.current.token]);

  const reset = useCallback(() => {
    cancelTokenSourceRef.current = axios.CancelToken.source();
    setState(INITIAL_STATE);
  }, []);

  const cancel = useCallback((): void => {
    cancelTokenSourceRef.current.cancel('The request was terminated by the user.');
    reset();
  }, [reset]);

  useEffect(() => {
    if (shouldCancelUpload) {
      cancel();
    }
  }, [shouldCancelUpload, cancel]);

  const handleDeletion = useCallback((): void => {
    /**
     * FUTURE This file has already been uploaded and we cannot delete it at this point.
     * This field will be cleared now though, in case the user want to start from scratch.;
     */
    reset();
  }, [reset]);

  const onFileSelected = useCallback(
    (selectedFile: RcFile) => {
      /**
       * Windows users can upload unwanted files despite of the formats defined in `accept`.
       * This will reject files of unwatned format before they are uploaded to the server.
       *
       * @see https://stackoverflow.com/questions/4328947/limit-file-format-when-using-input-type-file
       */
      const extension = '.' + selectedFile.name.split('.').pop();
      let errorCode: ErrorCode | null = null;
      if (!accept.has(extension as MIMEType)) {
        errorCode = ErrorCode.InvalidFile;
      } else if (selectedFile.size < fileSizeMin) {
        errorCode = ErrorCode.InvalidEmptyFile;
      } else if (selectedFile.size > fileSizeMax) {
        errorCode = ErrorCode.InvalidFileSize;
      }
      if (isDefined(errorCode)) {
        setState({
          stage: Stage.failure,
          errorCode: errorCode,
        });
        return;
      }
      // the selected file has the expected extension and its size is within bounds
      setState({ stage: Stage.fileSelected, selectedFile });
    },
    [accept, fileSizeMin, fileSizeMax]
  );

  const handleSelectedFileRemoved = useCallback(() => {
    setState(INITIAL_STATE);
  }, []);

  return (
    <Container className={className} disabled={disabled} data-test-id={dataTestId}>
      <Header>
        <Title data-test-id={titleUploadPanel}>{title}</Title>
        {isRequired && <SRequiredIndicator>*</SRequiredIndicator>}
      </Header>
      <Content description={description} descriptionPosition={descriptionPosition}>
        <DropArea>
          {state.stage === 'noFileSelected' && (
            <States.Initial
              accept={accept}
              type="drag"
              placeholder={placeholder ?? t('ingress:uploadPlaceholder')}
              callToAction={t('ingress:uploadCallToAction')}
              onFileSelected={onFileSelected}
            />
          )}
          {state.stage === 'fileSelected' && (
            <States.FileSelected
              description={t('ingress:fileSelectedReadyForUploadSuffix')}
              fileName={state.selectedFile.name}
              fileSize={state.selectedFile.size}
              onSelectedFileRemoved={handleSelectedFileRemoved}
            />
          )}
          {state.stage === 'failure' && (
            <States.Failure
              errorMessage={
                isDefined(state.error) ? (
                  <div>{state.error.message ?? state.error.title}</div>
                ) : (
                  <div>{t(`ingress:errors.${state.errorCode}.message` as const)}</div>
                )
              }
              onRetry={reset}
            />
          )}
          {state.stage === 'uploading' && (
            <States.Uploading fileName={state.selectedFile.name} fileSize={state.selectedFile.size} progress={state.uploadProgressPercent} onCancel={cancel} />
          )}
          {state.stage === 'success' && (
            <States.Success
              description={t('ingress:doneStepTitle')}
              fileName={state.selectedFile.name}
              onDelete={handleDeletion}
              showDeleteIcon={showDeleteIconAfterUpload}
            />
          )}
        </DropArea>
        {isDefined(description) && (
          <DescriptionContainer>
            <Description data-test-id={expectedFileFormatsDescriptionUploadPanel}>{description}</Description>
          </DescriptionContainer>
        )}
      </Content>
    </Container>
  );
});

Uploader.displayName = 'Uploader';
Uploader.whyDidYouRender = true;
export default Uploader;

const Container = styled.div<{ disabled: boolean }>`
  width: 100%;
  ${(props) => (props.disabled ? { opacity: '50%', pointerEvents: 'none' } : {})}
`;

const Header = styled.div`
  width: 100%;
  margin-bottom: 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.span`
  color: ${settings.colors.Primary.Grey_8};
  line-height: 1.25;
  font-size: ${settings.typography.FontSize.Medium};
  font-family: ${settings.typography.FontFamily.Bold};
`;

const Content = styled.div<Pick<Props, 'description' | 'descriptionPosition'>>((props) => {
  const gridColumnCount = isDefined(props.description) && props.descriptionPosition === 'right' ? 2 : 1;
  const gridGap = isDefined(props.description) && props.descriptionPosition === 'right' ? settings.Spacing.Spacing_300 : settings.Spacing.Spacing_150;

  return css`
    display: grid;
    grid-template-columns: ${`repeat(${gridColumnCount}, 1fr)`};
    grid-gap: ${gridGap};
  `;
});

const DropArea = styled.div``;

const DescriptionContainer = styled.div`
  max-width: 420px;
`;

const Description = styled.div`
  margin: 0;
  font-size: ${settings.typography.FontSize.Small};
  font-family: ${settings.typography.FontFamily.Regular};
  color: ${settings.colors.Primary.Grey_8};
  line-height: 1.5;
`;

const SRequiredIndicator = styled.span`
  font-size: ${settings.typography.FontSize.X_Large};
  font-family: ${settings.typography.FontFamily.Bold};
  color: ${settings.colors.Primary.Grey_8};
  line-height: ${settings.typography.FontSize.Small};
`;
