import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const FileFilterIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M16 2l4 4v13.995C20 21.103 19.103 22 17.996 22H6.005C4.898 22 4 21.103 4 19.995V4.005C4 2.897 4.898 2 6.005 2H16zm-.364 7H8.364c-.31 0-.477.348-.277.576l2.822 3.213v2.16c0 .133.078.254.201.314l1.455.7c.241.116.526-.053.526-.313v-2.861l2.822-3.213c.2-.228.032-.576-.277-.576z"
      />
    </svg>
  );
};

FileFilterIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
