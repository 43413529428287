import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const EAFIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g id="Icons/Vessels/EAF_24" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M15,2 C15.552,2 16,2.4567712 16,3.0218595 L16,3.0218595 L16,7.26794919 L19.4661524,9 C19.6829128,9.12514663 19.8349799,9.31891571 19.9109202,9.53787612 C19.9538276,9.62434804 19.9824813,9.71812982 19.9941264,9.81665517 L20,9.91666667 L20,12 L20.983,12 L21,12 C21.5125714,12 21.9354694,12.3533048 21.9932682,12.8096428 L22,12.9166667 L22,19 C22,20.6568542 17.5228475,22 12,22 C7.3415982,22 3.42716193,21.0444115 2.31589963,19.7509969 C2.14872278,19.6076042 2.03604529,19.4129388 2.0072635,19.1944411 L2,19.0833333 L2,9.91666667 C2,9.77568528 2.03465201,9.64221957 2.09655598,9.52299374 C2.16162357,9.34450089 2.27762673,9.18361638 2.43827999,9.06420653 L2.5360254,9 L6,7.26794919 L6,3.0218595 C6,2.4567712 6.448,2 7,2 C7.5152,2 7.93980444,2.39789847 7.99412859,2.91038932 L8,3.0218595 L8,12.9781405 C8,13.5432288 7.552,14 7,14 C6.4848,14 6.06019556,13.6021015 6.00587141,13.0896107 L6,12.9781405 L6,9.5 L4,10.5 L4.00097428,15.194884 C4.90744415,15.0869377 5.99245368,15 7.25866667,15 C10.8146667,15 13.1853333,16 16.7413333,16 C18.008,16 19.0933333,15.913 20,15.804 L19.9999852,16.0242465 L19.9999852,16.0242465 L20,16 L20,14 L18.9166667,14 C18.4459583,14 18.0590315,13.6137143 18.0061578,13.1165685 L18,13 L18,10.499 L16,9.5 L16,12.9781405 C16,13.5055562 15.6097422,13.9386158 15.1089114,13.994013 L15,14 C14.448,14 14,13.5432288 14,12.9781405 L14,12.9781405 L14,3.0218595 C14,2.4567712 14.448,2 15,2 Z M11,2 C11.552,2 12,2.4567712 12,3.0218595 L12,3.0218595 L12,12.9781405 C12,13.5432288 11.552,14 11,14 C10.448,14 10,13.5432288 10,12.9781405 L10,12.9781405 L10,3.0218595 C10,2.4567712 10.448,2 11,2 Z"
          id="Combined-Shape"
          fill={fill ?? '#003262'}
        ></path>
      </g>
    </svg>
  );
};

EAFIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Grey_8,
};
