import { settings } from '@rhim/design';
import { isDefined } from '@rhim/utils';
import * as React from 'react';
import { MutableRefObject } from 'react';
import { Annotation, ConnectorElbow, ConnectorLine, Note, Subject } from 'react-annotation';
import ImageMapper from 'react-image-mapper';
import styled from 'styled-components';

import { cloneCanvas, createCanvas, IMapArea, setAreaStroke } from './areas';
import { IHeatMapDetails } from './heatmap';

interface HeatmapImageMapperProps {
  details: IHeatMapDetails;
  area?: number;
  refs?: MutableRefObject<HTMLDivElement[]>;
}

/**
 * Heatmap image mapper component.
 */
const HeatMapImageMapper: React.ChildlessComponent<HeatmapImageMapperProps> = ({ details, area, refs }: HeatmapImageMapperProps): JSX.Element => {
  const { canvas, areas, areasCanvas } = details;

  const [parentWidth, setParentWidth] = React.useState(0);
  /**
   * Height of the Annotation Note element.
   */
  const NOTE_HEIGHT = 13;

  /**
   * WIDTH of the Annotation Note element.
   */
  const NOTE_WIDTH = 17;
  /**
   * Margin of the Annotation rect element.
   */
  const MARGIN_RECT = 5;

  const setRef = (el: HTMLDivElement | null) => {
    if (el) {
      setParentWidth(el.offsetWidth);
    }
  };

  /**
   * Map object to use in the image mapper.
   */
  const map = {
    name: area + 'map',
    areas: setAreaStroke(areas, area),
  };

  /**
   * Checks if a point is on a left side of the heatmap.
   * @param x X point.
   * @returns `true` if the point is on the left side, `false` otherwise.
   */
  const isLeftSide = (x: number) => {
    return x <= width / 2;
  };

  /**
   * Scrolls to clicked area.
   */
  const scrollToArea = (index: number) => {
    if (refs) {
      const el = refs.current[index];

      if (el) {
        el.scrollIntoView();
      }
    }
  };

  /**
   * Gets annotations for each highlighted area.
   * Handles also the annotations overlapping.
   * @returns Annotation elements.
   */
  const getAnnotations = (dx: number): JSX.Element[] => {
    const annotations: JSX.Element[] = [];
    const points: { y: number; left: boolean }[] = [];
    const startingY: number[] = [];
    let useElbow = false;

    areas.forEach((area: IMapArea) => {
      const areaNr = area.areaNr + 1;
      const leftSide = isLeftSide(area.xMax);
      const areaDx = leftSide ? -dx : dx;
      const x = leftSide ? area.xMin + dx : area.xMax + dx;
      const y = area.yMin - (area.yMin - area.yMax) / 2;
      let dy = 0;

      /**
       * In case 2 areas are situated on the same y Axes, the annotations will overlap.
       * To prevent that we will use an elbow connector instead of a line to draw the annotation.
       * This way there will be no overlapping.
       */
      const hasIntersectingPoints = isDefined(points.find((point) => point.y === y && point.left === leftSide));

      /**
       * In case 2 areas are situated very close on the y Axe, the annotations will overlap.
       * To prevent that we will use an elbow connector instead of a line to draw the annotation.
       * This way there will be no overlapping.
       */
      const hasOverlappingAnnotations = isDefined(points.find((point) => point.y - y <= NOTE_HEIGHT && point.left === leftSide));

      /**
       * Calculates the closest dy point so that the annotations won't overlap.
       */
      const findDy = () => {
        let find = true;
        let newDy = NOTE_HEIGHT;

        while (find) {
          // eslint-disable-next-line no-loop-func
          find = isDefined(startingY.find((point) => point === y - newDy));
          if (find) {
            newDy += NOTE_HEIGHT;
          }
        }
        startingY[areaNr] = newDy;
        return newDy;
      };

      if (hasIntersectingPoints || hasOverlappingAnnotations) {
        useElbow = true;
        dy = findDy();
      }

      points.push({ y, left: leftSide });
      startingY.push(y);

      annotations.push(
        <StyledAnnotation
          key={JSON.stringify(area.coords)}
          height={NOTE_HEIGHT}
          x={x}
          y={y}
          dy={dy}
          dx={areaDx}
          color={area.isCritical ?? false ? settings.colors.Operational.State_Alert_Red_3 : settings.colors.Primary.Grey_8}
          titleColor={area.isCritical ?? false ? settings.colors.Operational.State_Alert_Red_2 : settings.colors.Primary.Grey_8}
          className="anomaly"
          events={{
            onClick: () => {
              scrollToArea(areaNr - 1);
            },
          }}
        >
          <Subject />
          {useElbow ? <ConnectorElbow /> : <ConnectorLine />}
          <NoteRect width={NOTE_WIDTH} areaDx={areaDx} dy={dy} leftSide={leftSide} marginRect={MARGIN_RECT} noteHeight={NOTE_HEIGHT} noteWidth={NOTE_WIDTH} />
          <Note align="middle" orientation="leftRight" title={areaNr.toString()} />
        </StyledAnnotation>
      );
    });

    return annotations;
  };

  const clone = cloneCanvas(canvas);

  /**
   * We have to create a canvas containing only the areas image data objects; This canvas will be added on top of the
   * Annotations SVG and it will have a transparent background. This is required in order to be able to catch all `click`
   * events triggered on the map areas.
   * The order will be:
   *  1. Grayed out heatmap image.
   *  2. Svg Annotations
   *  3. Image Map Areas.
   */
  const mapCanvas = createCanvas(canvas.width, canvas.height);
  const mapCtx = mapCanvas.getContext('2d');
  const context = clone.getContext('2d');

  const width = canvas.width;
  let margin = 0;

  if (parentWidth) {
    margin = (parentWidth - width) / 2;
  }

  if (context && mapCtx) {
    if (isDefined(area)) {
      const canvas = areasCanvas.filter((item) => item.area === area - 1);
      const areaItems = areas.filter((item) => item.areaNr === area - 1);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      areaItems.forEach((item, index) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        context.putImageData(canvas[index]!.image, item.xMin, item.yMin);
      });
    } else {
      areasCanvas.forEach((areaCanva, index) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        mapCtx.putImageData(areaCanva.image, areas[index]!.xMin, areas[index]!.yMin);
      });
    }
  }

  return isDefined(area) ? (
    <ImageMapper src={clone.toDataURL()} map={map} />
  ) : (
    <Container ref={setRef}>
      <AnnotationSvg>{getAnnotations(margin)}</AnnotationSvg>
      <ImageContainer margin={margin} width={width}>
        <img alt="" src={clone.toDataURL()} />
        <AreasMapper>
          <ImageMapper
            src={mapCanvas.toDataURL()}
            map={map}
            onClick={(plot: IMapArea) => {
              scrollToArea(plot.areaNr);
            }}
          />
        </AreasMapper>
      </ImageContainer>
    </Container>
  );
};

const NoteRect: React.FunctionComponent<
  React.PropsWithChildren<{
    x?: number;
    y?: number;
    width: number;
    color?: string;
    leftSide: boolean;
    areaDx: number;
    noteHeight: number;
    noteWidth: number;
    marginRect: number;
    dy: number;
  }>
> = (props) => {
  return (
    <g transform={`translate(${props.areaDx}, ${props.dy - props.marginRect})`}>
      <rect x={props.leftSide ? -(props.width - 3) : -3} y={0} height={props.noteHeight} width={props.noteWidth} rx={6} fill={props.color} />;
    </g>
  );
};

const Container = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  margin: auto;

  svg {
    overflow: visible;
  }
`;

const AnnotationSvg = styled.svg`
  position: absolute;
  z-index: 2;

  /* Without it, the SVG overlaps the clickable area of region one */
  pointer-events: none;
`;

const ImageContainer = styled.div<{ margin: number; width: number }>`
  position: relative;
  margin-left: ${(props) => props.margin}px;
  width: ${(props) => props.width}px;
  display: inline-flex;
`;

const AreasMapper = styled.div`
  position: absolute;
  z-index: 101;
`;

const StyledAnnotation = styled(Annotation)<{ height: number }>`
  .annotation-note-title {
    fill: ${settings.colors.Primary.Grey_1};
    font-size: 9px;
  }

  .annotation-note-content {
    cursor: pointer;
  }
`;

HeatMapImageMapper.whyDidYouRender = true;
export default React.memo(HeatMapImageMapper);
