import { VesselType } from '@rhim/rest';
import { RHIMMeasurementServiceV1ModelsView3dSettingsDto } from '@rhim/rest/measurementService';
import { specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api';

type Payload = RHIMMeasurementServiceV1ModelsView3dSettingsDto;

export const useMeasurementviewSettings = (
  customerId: string,
  vesselType: VesselType,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) => {
  return useQuery(getKey(customerId, vesselType), (context) => queryFn(context, options?.axiosConfiguration), options?.configuration);
};

const getKey = (customerId: string, vesselType: VesselType) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'measurementService',
      scope: 'settings',
      entity: 'detail',
      customerId,
      vesselType,
    },
  ]);

const queryFn = (
  { queryKey: [{ customerId, vesselType }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>,
  axiosConfiguration?: AxiosRequestConfig
) => {
  return API.measurementView
    .getMeasurementviewSettingsCustomeridVesseltype(customerId, vesselType, { signal, ...axiosConfiguration })
    .then((response) => response.data);
};

useMeasurementviewSettings.getKey = getKey;
useMeasurementviewSettings.queryFn = queryFn;
