import { settings } from '@rhim/design';
import { isDefined } from '@rhim/utils';
import React from 'react';
import styled, { css } from 'styled-components';

import { AXIS_LABEL_HEIGHT, FIRST_LAST_LABEL_OFFSET, TICK_LABEL_PROPS } from './constants';

type IconPlacement = 'left' | 'right';

interface Props {
  className?: string;
  icon?: React.ReactNode;
  iconPlacement?: IconPlacement;
  label?: string | React.ReactNode;
}

const AxisLabel: React.FC<Props> = (props) => {
  const { className, icon, iconPlacement = 'left', label } = props;

  return (
    <Wrapper className={className} iconPlacement={iconPlacement}>
      {isDefined(icon) && <Icon>{icon}</Icon>}
      {isDefined(label) && <Label>{label}</Label>}
    </Wrapper>
  );
};

export default React.memo(AxisLabel);

interface WrapperProps {
  iconPlacement: IconPlacement;
}

const Wrapper = styled.div<WrapperProps>`
  flex-direction: ${(props) => (props.iconPlacement === 'left' ? 'row' : 'row-reverse')};
  height: ${AXIS_LABEL_HEIGHT}px;
  gap: ${settings.Spacing.Spacing_50};
  display: flex;
  padding-right: ${FIRST_LAST_LABEL_OFFSET}px;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  display: flex;
  line-height: 14px;
  align-items: center;
  white-space: nowrap;
  ${css(TICK_LABEL_PROPS)};
`;
