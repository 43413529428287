import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const AppIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M4.7273 7.4546C6.23355 7.4546 7.4546 6.23355 7.4546 4.7273C7.4546 3.22105 6.23355 2 4.7273 2C3.22105 2 2 3.22105 2 4.7273C2 6.23355 3.22105 7.4546 4.7273 7.4546Z"
          fill={fill}
        />
        <path
          d="M4.7273 14.727C6.23355 14.727 7.4546 13.506 7.4546 11.9997C7.4546 10.4935 6.23355 9.27243 4.7273 9.27243C3.22105 9.27243 2 10.4935 2 11.9997C2 13.506 3.22105 14.727 4.7273 14.727Z"
          fill={fill}
        />
        <path
          d="M4.7273 21.9999C6.23355 21.9999 7.4546 20.7788 7.4546 19.2725C7.4546 17.7663 6.23355 16.5452 4.7273 16.5452C3.22105 16.5452 2 17.7663 2 19.2725C2 20.7788 3.22105 21.9999 4.7273 21.9999Z"
          fill={fill}
        />
        <path
          d="M12.0006 7.4546C13.5069 7.4546 14.7279 6.23355 14.7279 4.7273C14.7279 3.22105 13.5069 2 12.0006 2C10.4944 2 9.27333 3.22105 9.27333 4.7273C9.27333 6.23355 10.4944 7.4546 12.0006 7.4546Z"
          fill={fill}
        />
        <path
          d="M12.0001 14.7268C13.5064 14.7268 14.7274 13.5057 14.7274 11.9995C14.7274 10.4932 13.5064 9.27217 12.0001 9.27217C10.4939 9.27217 9.27282 10.4932 9.27282 11.9995C9.27282 13.5057 10.4939 14.7268 12.0001 14.7268Z"
          fill={fill}
        />
        <path
          d="M12.0001 21.9997C13.5064 21.9997 14.7274 20.7787 14.7274 19.2724C14.7274 17.7662 13.5064 16.5451 12.0001 16.5451C10.4939 16.5451 9.27282 17.7662 9.27282 19.2724C9.27282 20.7787 10.4939 21.9997 12.0001 21.9997Z"
          fill={fill}
        />
        <path
          d="M19.2729 7.4546C20.7792 7.4546 22.0002 6.23355 22.0002 4.7273C22.0002 3.22105 20.7792 2 19.2729 2C17.7667 2 16.5456 3.22105 16.5456 4.7273C16.5456 6.23355 17.7667 7.4546 19.2729 7.4546Z"
          fill={fill}
        />
        <path
          d="M19.2729 14.7268C20.7792 14.7268 22.0002 13.5057 22.0002 11.9995C22.0002 10.4932 20.7792 9.27217 19.2729 9.27217C17.7667 9.27217 16.5456 10.4932 16.5456 11.9995C16.5456 13.5057 17.7667 14.7268 19.2729 14.7268Z"
          fill={fill}
        />
        <path
          d="M19.2729 21.9997C20.7792 21.9997 22.0002 20.7787 22.0002 19.2724C22.0002 17.7662 20.7792 16.5451 19.2729 16.5451C17.7667 16.5451 16.5456 17.7662 16.5456 19.2724C16.5456 20.7787 17.7667 21.9997 19.2729 21.9997Z"
          fill={fill}
        />
      </svg>
    </svg>
  );
};

AppIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
