import { settings } from '@rhim/design';
import { PatternLines } from '@vx/pattern';
import React from 'react';

const PATTERN_SIZE_PX = 15;

interface Props {
  id: string;
}
const PatternDiagonalStripes: React.ChildlessComponent<Props> = ({ id }) => {
  return (
    <PatternLines
      id={id}
      height={PATTERN_SIZE_PX}
      width={PATTERN_SIZE_PX}
      stroke={settings.colors.Monochromatic.White}
      background={settings.colors.Primary.Grey_2}
      strokeWidth={1}
      orientation={['diagonal']}
    />
  );
};
PatternDiagonalStripes.whyDidYouRender = true;
export default React.memo(PatternDiagonalStripes);
