import { settings } from '@rhim/design';

export const LABELS_OFFSET_PX = 4; // the distance in pixels between an axis tick and its label

export const MAJOR_TICK_LABELS_STYLE = {
  fontFamily: `${settings.typography.FontFamily.Regular}`,
  fontSize: `${settings.typography.FontSize.X_Small}`,
  fill: settings.colors.Primary.Grey_6,
};

export const AXIS_STYLE_PROPS = {
  stroke: settings.colors.Primary.Grey_2,
};

export const MAJOR_TICKS_STYLE_PROPS = {
  tickLength: 5,
  tickStroke: settings.colors.Primary.Grey_6,
};

export const MINOR_TICKS_STYLE_PROPS = {
  tickLength: 3,
  tickStroke: settings.colors.Primary.Grey_4,
};
