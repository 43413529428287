import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const DisableIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 8a6 6 0 0 1 9.477-4.89l-8.368 8.367A5.973 5.973 0 0 1 2 8zm2.523 4.89a6 6 0 0 0 8.367-8.367l-8.367 8.368zM8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0z"
        fill={fill}
      />
    </svg>
  );
};

DisableIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
