/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { getBrowserLocale, toUnicodeLocale } from '@rhim/i18n';
import { ChevronFilledDownIcon } from '@rhim/icons/16';
import { formatNumber, getFormattedRange } from '@rhim/react/partials';
import {
  RHIMProcessDataServiceV1ModelsParameterCampaignValues,
  RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto,
  RHIMProcessDataServiceV1ModelsProcessParameterHeatValueDto,
} from '@rhim/rest/processData';
import {
  lowerInnerLimitLabelParametersComparison,
  lowerOuterLimitLabelParametersComparison,
  medianLabelParametersComparison,
  parameterNameAndUnitLabelParametersComparison,
  upperInnerLimitLabelParametersComparison,
  upperOuterLimitLabelParametersComparison,
  wearParameterChartParametersComparison,
} from '@rhim/test-ids';
import { assert, hasElements, isDefined } from '@rhim/utils';
import { RectClipPath } from '@visx/clip-path';
import { AxisBottom, TickLabelProps } from '@vx/axis';
import { localPoint } from '@vx/event';
import { ScaleInput, scaleLinear } from '@vx/scale';
import { Bar, Circle, Line } from '@vx/shape';
import { defaultStyles, Tooltip, withTooltip } from '@vx/tooltip';
import * as d3 from 'd3';
import { bisector } from 'd3-array';
import { ScaleLinear } from 'd3-scale';
import React, { useCallback, useMemo, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useMeasure } from 'react-use';
import styled from 'styled-components';

import { DRAG_ZOOM_MAX, useScrolling } from '../../components/Zoom';
import { getParamRanges } from '../../utilities';
import {} from '../../utilities/constants';
import ParamRangeIndicators from '../ParamRanges/ParamRangeIndicators';
import { AxisLeft, getAxisTicksValues } from '../Violin/AxisLeft';
import { Center, DragZoomLabels, ScrollViewNoMargin, SvgAxisY, ThumbHorizontal, TrackHorizontal, ZoomRange } from '../Zoom';
import DragZoom from '../Zoom/DragZoom';
import DragZoomHint from '../Zoom/DragZoomHint';
import DragZoomRange from '../Zoom/DragZoomRange';
import TooltipInfo, { TooltipDataProps } from './TooltipInfo';

export interface BarsProps {
  width: number;
  height: number;
}

interface Props {
  initialChartWidth: number;
  name?: string | null;
  unit: string;
  toggle: boolean;
  paramRanges?: RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto;
  currentCampaignNr?: number;
  prevCampaignNr?: number;
  prevCampaign?: RHIMProcessDataServiceV1ModelsParameterCampaignValues;
  currentCampaign?: RHIMProcessDataServiceV1ModelsParameterCampaignValues;
  hoverPosition: APO.Plot.Point | null;
  handleZoom: (zoomButtonId: string) => void;
  handleZoomRangeChange: () => void;
  handleZoomRangeDragEnd: (range: ZoomRange | null) => void;
  handleZoomRangeDragStart: () => void;
  isDragTarget: boolean;
  resetZoom: () => void;
  scrollbarRef: React.RefObject<Scrollbars>;
  setHoverPosition: (hoverPosition: APO.Plot.Point | null) => void;
  setScrollPositions: (scrollPosition: number | null) => void;
  setZoomRange: React.Dispatch<React.SetStateAction<ZoomRange | null>>;
  zoomLevel: number;
  zoomRange: ZoomRange | null;
  sameCampaign: boolean;
}

const COL_LEFT_WIDTH = 50;
export const PADDING_LEFT = 25;
const PADDING_TOP = 50;

const MARGIN_LEFT = 75;
const PADDING_BOTTOM = 10;
const MARGIN_TOP = 15;

const TOOLTIP_WIDTH = 180;

const defaultTooltipStyle: React.CSSProperties = {
  ...defaultStyles,
  padding: '1px 4px',
  height: '16px',
  borderRadius: '3px',
  textAlign: 'center',
  fontFamily: `${settings.typography.FontFamily.Regular}`,
  fontSize: `${settings.typography.FontSize.X_Small}`,
  color: settings.colors.Primary.Grey_6,
  lineHeight: '1.17',
  transform: 'translateX(-50%)',
  boxShadow: 'none',
};

const WearParamsGraph = withTooltip<BarsProps & Props, TooltipDataProps>(
  ({
    initialChartWidth,
    height,
    currentCampaign,
    paramRanges,
    prevCampaign,
    unit,
    currentCampaignNr,
    prevCampaignNr,
    name,
    tooltipData,
    tooltipLeft,
    showTooltip,
    hideTooltip,
    toggle,
    handleZoomRangeDragStart,
    handleZoomRangeChange,
    handleZoomRangeDragEnd,
    hoverPosition,
    isDragTarget,
    zoomRange,
    scrollbarRef,
    setHoverPosition,
    setScrollPositions,
    setZoomRange,
    zoomLevel,
    sameCampaign,
  }) => {
    const { t } = i18nReact.useTranslation(['parameters-comparison']);
    const ranges = getParamRanges(paramRanges);
    const locale = getBrowserLocale();

    const currentCampaignData: RHIMProcessDataServiceV1ModelsProcessParameterHeatValueDto[] = React.useMemo(() => {
      return isDefined(currentCampaign) && isDefined(currentCampaign.values) ? currentCampaign.values.filter((item) => isDefined(item.value)) : [];
    }, [currentCampaign]);
    const prevCampaignData: RHIMProcessDataServiceV1ModelsProcessParameterHeatValueDto[] = React.useMemo(() => {
      return isDefined(prevCampaign) && isDefined(prevCampaign.values) ? prevCampaign.values.filter((item) => isDefined(item.value)) : [];
    }, [prevCampaign]);

    const [ref, { width: precisionWidth }] = useMeasure<HTMLDivElement>();

    const [isHoveringGraphArea, setIsHoveringGraphArea] = useState<boolean>(false);
    const values = currentCampaignData.concat(prevCampaignData).sort((right, left) => right.heat - left.heat);
    // round to avoid Scrollbars hideTracksWhenNotNeeded glitches at certain browser zoom levels
    const width = Math.round(precisionWidth);
    const graphContainerWidth = width - (COL_LEFT_WIDTH + PADDING_LEFT);
    const graphWidth = width ? graphContainerWidth * zoomLevel : 0; // avoid getting negative width when the component is not mounted yet

    // bounds
    const xMax = graphWidth;
    const yMax = height;

    const maxHeat = hasElements(values) ? Math.max(...(values.map((value) => value.heat) as number[])) : 0;
    const maxValue = hasElements(values) ? Math.max(...(values.map((item) => item.value) as number[])) : 0;
    const minValue = hasElements(values) ? Math.min(...(values.map((item) => item.value) as number[])) : 0;

    const median = React.useMemo(() => {
      return (
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        d3.quantile(
          currentCampaignData.map((d) => d.value),
          0.5
        ) || 0
      );
    }, [currentCampaignData]);

    const heatsStep = React.useMemo(() => {
      return currentCampaignData.length > 400 ? 6 : 1;
    }, [currentCampaignData]);

    const circleWidth = 800 / (maxHeat / heatsStep);

    // scales, memoize for performance
    const xScale = useMemo(
      () =>
        scaleLinear<number>({
          range: [0, xMax],
          round: true,
          domain: [0, maxHeat],
        }),
      [xMax, maxHeat]
    );

    const yDomain: [number, number] = React.useMemo((): [number, number] => {
      if (toggle && isDefined(paramRanges) && isDefined(paramRanges.min) && isDefined(paramRanges.max)) {
        const { min, max } = paramRanges;
        return [min === max ? 0 : min, max];
      }
      let chartMin = minValue;
      let chartMax = maxValue;

      if (isDefined(ranges)) {
        const { upperOuterLimit, lowerUpperLimit, upperInnerLimit, lowerInnerLimit } = ranges;
        chartMin = Math.min(...[minValue, lowerInnerLimit ?? minValue, lowerUpperLimit ?? minValue]);
        chartMax = Math.max(...[maxValue, upperInnerLimit ?? maxValue, upperOuterLimit ?? maxValue]);
      }
      return [chartMin === chartMax ? 0 : chartMin, chartMax];
    }, [toggle, paramRanges, minValue, maxValue, ranges]);

    // accessors
    const getTemp = useCallback(
      (d: RHIMProcessDataServiceV1ModelsProcessParameterHeatValueDto): number => {
        const domain = yDomain;
        if (d.value! > domain[1]) {
          return domain[1];
        }
        if (d.value! < domain[0]) {
          return domain[0];
        }
        return d.value!;
      },
      [yDomain]
    );

    const getHeat = useCallback((d: RHIMProcessDataServiceV1ModelsProcessParameterHeatValueDto): number => {
      return d.heat;
    }, []);

    const yScale = useMemo(
      () =>
        scaleLinear<number>({
          range: [yMax, 0],
          round: true,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          domain: yDomain,
        }),
      [yMax, yDomain]
    );

    const getMatchedItemIdx = React.useCallback(
      (point: RHIMProcessDataServiceV1ModelsProcessParameterHeatValueDto) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition,@typescript-eslint/strict-boolean-expressions
        return prevCampaignData.findIndex((item) => item.heat === (point || {}).heat && isDefined(item.value));
      },
      [prevCampaignData]
    );

    const scrollOffset = scrollbarRef.current?.getScrollLeft() ?? 0;
    const tooltipOffsetPosition = (hoverPosition?.x ?? 0) - scrollOffset;
    const tooltipXMin = TOOLTIP_WIDTH / 2;
    const tooltipXMax = graphContainerWidth - TOOLTIP_WIDTH / 2;
    const tooltipX = Math.max(tooltipXMin, Math.min(tooltipOffsetPosition, tooltipXMax));

    const getIntersectionPoint = (idx: number, data: RHIMProcessDataServiceV1ModelsProcessParameterHeatValueDto[], x0: number) => {
      const d0 = data[idx - 1];
      const d1 = data[idx];

      assert(isDefined(d0));

      let d = d0;
      if (isDefined(d1) && isDefined(d.heat)) {
        d = x0.valueOf() - d0.heat.valueOf() > d1.heat.valueOf() - x0.valueOf() ? d1 : d0;
      }
      // return undefined if heat difference is more than 2, otherwise the hovering is not accurate anymore.
      return Math.abs(x0.valueOf() - d.heat.valueOf()) > 2 ? undefined : d;
    };
    /**
     * Gets the computed points to draw the line and comparison.
     */
    const computedPoints: Double<RHIMProcessDataServiceV1ModelsProcessParameterHeatValueDto[]> = React.useMemo(() => {
      const points = [];
      const prevPoints = [];
      let i = 0;

      // Calculate the avg points for the current campaign
      while (i < currentCampaignData.length) {
        const point = currentCampaignData[i];

        assert(isDefined(point), 'Point should always be defined');
        points.push(point);

        let nextIndex = i + 1;
        while (
          nextIndex < currentCampaignData.length &&
          isDefined(currentCampaignData[nextIndex]) &&
          currentCampaignData[nextIndex]!.heat - point.heat < heatsStep
        ) {
          nextIndex++;
        }

        i = nextIndex;
      }

      // Calculate the avg points for previous campaign
      let matchedIndex = -1;
      while (matchedIndex < prevCampaignData.length) {
        let nextIndex = matchedIndex + 1;
        while (
          nextIndex < prevCampaignData.length &&
          isDefined(prevCampaignData[nextIndex]) &&
          isDefined(prevCampaignData[matchedIndex]) &&
          prevCampaignData[nextIndex]!.heat - prevCampaignData[matchedIndex]!.heat < heatsStep
        ) {
          nextIndex++;
        }

        matchedIndex = nextIndex;
        prevPoints.push(prevCampaignData[matchedIndex]!);
      }

      return [points, prevPoints];
    }, [heatsStep, currentCampaignData, prevCampaignData]);

    /**
     * Gets the heat range of a given point.
     */
    const getHeatsRange = React.useCallback(
      (
        point: RHIMProcessDataServiceV1ModelsProcessParameterHeatValueDto,
        currentPoints: RHIMProcessDataServiceV1ModelsProcessParameterHeatValueDto[]
      ): Double<number> | [number] | null => {
        const inBetweenIdx = currentPoints.findIndex(
          (item, idx) =>
            (item.heat <= point.heat && idx + 1 > currentPoints.length - 1) ||
            (item.heat <= point.heat && idx + 1 <= currentPoints.length - 1 && currentPoints[idx + 1]!.heat >= point.heat)
        );
        if (inBetweenIdx !== -1) {
          if (inBetweenIdx + 1 <= currentPoints.length - 1) {
            return [currentPoints[inBetweenIdx]!.heat, currentPoints[inBetweenIdx + 1]!.heat];
          }
          return [currentPoints[inBetweenIdx]!.heat];
        }
        return null;
      },
      []
    );

    /**
     * Tooltip handler.
     * @param event React touch event.
     */
    const handleTooltip = React.useCallback(
      (event: React.MouseEvent<SVGPathElement>) => {
        const { x } = localPoint(event) || { x: 0 };
        const valueX = xScale.invert(x);
        const bisect = bisector((d: RHIMProcessDataServiceV1ModelsProcessParameterHeatValueDto) => d.heat).right;
        const idx = bisect(currentCampaignData, valueX, 1);
        let currentPoint: RHIMProcessDataServiceV1ModelsProcessParameterHeatValueDto | undefined = hasElements(currentCampaignData)
          ? getIntersectionPoint(idx, currentCampaignData, valueX)
          : undefined;
        setIsHoveringGraphArea(true);

        const prevIdx = bisect(prevCampaignData, valueX, 1);
        let prevPoint: RHIMProcessDataServiceV1ModelsProcessParameterHeatValueDto | undefined = hasElements(prevCampaignData)
          ? getIntersectionPoint(prevIdx, prevCampaignData, valueX)
          : undefined;

        // if the heats are not equal, we display the closest point to the hovering point.
        currentPoint = isDefined(prevPoint) && isDefined(currentPoint) ? (prevPoint.heat <= currentPoint.heat ? currentPoint : undefined) : currentPoint;

        prevPoint = isDefined(prevPoint) && isDefined(currentPoint) ? (prevPoint.heat >= currentPoint.heat ? prevPoint : undefined) : prevPoint;

        const definedPoint = currentPoint || prevPoint;
        const data = {
          currentPoint,
          prevPoint,
          //eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          heatRange:
            isDefined(currentPoint) || isDefined(prevPoint)
              ? getHeatsRange(definedPoint!, isDefined(definedPoint) ? computedPoints[0] : computedPoints[1])
              : null,
        };

        if (isDefined(currentPoint) || isDefined(prevPoint)) {
          showTooltip({
            tooltipData: {
              ...data,
            },
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            tooltipLeft: xScale(definedPoint!.heat),
          });

          setHoverPosition({
            x: xScale(definedPoint!.heat) ?? 0,
            y: yScale(definedPoint?.value ?? 0) ?? 0,
          });
        }
      },
      [setHoverPosition, computedPoints, getHeatsRange, currentCampaignData, prevCampaignData, xScale, yScale, showTooltip]
    );

    const getColorBar = (point: RHIMProcessDataServiceV1ModelsProcessParameterHeatValueDto, width?: number) => {
      const lineEl: JSX.Element[] = [];
      const idx = getMatchedItemIdx(point);
      const matchItem = prevCampaignData[idx];

      if (idx !== -1 && isDefined(matchItem)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const color = point.value > matchItem.value ? settings.colors.Comparison.Higher : settings.colors.Comparison.Lower;
        lineEl.push(
          <g>
            <Line
              from={{ x: xScale(getHeat(point)), y: yScale(getTemp(point)) }}
              to={{ x: xScale(getHeat(point)), y: yScale(getTemp(matchItem)) }}
              stroke={color}
              strokeWidth={isDefined(width) ? width : circleWidth}
              pointerEvents="none"
            />
          </g>
        );
      }

      return <g>{lineEl}</g>;
    };

    const { handleScrollFrame, handleScrollStart } = useScrolling({
      hoverPosition,
      scrollbarRef,
      setScrollPositions,
      setHoverPosition,
      width,
    });
    const xBisectToChartDate = (x: number): number => {
      const valueX = xScale.invert(x);

      const bisect = bisector((d: RHIMProcessDataServiceV1ModelsProcessParameterHeatValueDto) => d.heat).right;
      const idx = bisect(values, valueX, 0);

      const currentPoint: RHIMProcessDataServiceV1ModelsProcessParameterHeatValueDto | undefined = values[idx === values.length ? idx - 1 : idx];
      return currentPoint ? currentPoint.heat : 0;
    };

    const formatDragRange = (from: number, to: number) => getFormattedRange(`${from.toFixed(0)}`, `${to.toFixed(0)}`);

    const hasDragZoomHint = isDefined(hoverPosition) && !isDefined(zoomRange) && isHoveringGraphArea && zoomLevel < DRAG_ZOOM_MAX;

    const axisBottomProps = useMemo(() => {
      const tickValues = getAxisTicksValues(0, maxHeat, zoomLevel);
      const numTicks = tickValues.length;

      /**
       * The `f` is used to remove decimal separators used by default.
       */
      const tickFormatter = xScale.tickFormat(numTicks, 'f');

      const tickLabelProps: TickLabelProps<ScaleInput<ScaleLinear<number, number>>> = () => ({
        y: 19,
        textAnchor: 'middle',
        style: {
          fontFamily: `${settings.typography.FontFamily.Regular}`,
          fontSize: `${settings.typography.FontSize.X_Small}`,
          fill: settings.colors.Primary.Grey_6,
        },
      });

      return { scale: xScale, numTicks, tickValues, tickFormatter, tickLabelProps };
    }, [xScale, maxHeat, zoomLevel]);

    const drawCurrentCampaign = () => {
      const points = computedPoints;
      const currentPoints = points[0];
      return currentPoints.map((point, index) => {
        return (
          <g key={`point-${point.heat}-${index}`}>
            {getColorBar(point)}
            {isDefined(currentPoints[index + 1]) && (
              <Line
                from={{ x: xScale(getHeat(point)), y: yScale(getTemp(point)) }}
                to={{
                  x: isDefined(currentPoints[index + 1]) ? xScale(getHeat(currentPoints[index + 1]!)) : undefined,
                  y: isDefined(currentPoints[index + 1]) ? yScale(getTemp(currentPoints[index + 1]!)) : undefined,
                }}
                stroke={settings.colors.Primary.Blue_8}
                strokeWidth={0.5}
                pointerEvents="none"
              />
            )}
          </g>
        );
      });
    };

    const drawNextCampaign = () => {
      const points = computedPoints;
      const prevPoints = points[1];
      return prevPoints.map((point, index) => {
        return (
          <g key={index}>
            {isDefined(prevPoints[index + 1]) && (
              <Line
                from={{ x: xScale(getHeat(point)), y: yScale(getTemp(point)) }}
                to={{
                  x: isDefined(prevPoints[index + 1]) ? xScale(getHeat(prevPoints[index + 1]!)) : undefined,
                  y: isDefined(prevPoints[index + 1]) ? yScale(getTemp(prevPoints[index + 1]!)) : undefined,
                }}
                stroke={settings.colors.Primary.Grey_4}
                strokeWidth={0.2}
                pointerEvents="none"
              />
            )}
          </g>
        );
      });
    };

    const drawRanges = () => {
      if (isDefined(ranges)) {
        const { upperOuterLimit, lowerUpperLimit, upperInnerLimit, lowerInnerLimit } = ranges;
        return (
          <g>
            {isDefined(upperOuterLimit) && isDefined(lowerUpperLimit) && (
              <>
                <rect width={xMax} height={yScale(upperOuterLimit)! - yScale(yDomain[1])!} x={0} y={yScale(yDomain[1])} fill={settings.colors.Primary.Grey_1} />
                <rect
                  width={xMax}
                  height={yScale(yDomain[0])! - yScale(lowerUpperLimit)!}
                  x={0}
                  y={yScale(lowerUpperLimit)}
                  fill={settings.colors.Primary.Grey_1}
                />
                <Line
                  from={{ x: 0, y: yScale(upperOuterLimit) }}
                  to={{ x: xScale(maxHeat), y: yScale(upperOuterLimit) }}
                  stroke={settings.colors.Primary.Grey_4}
                  strokeWidth={1}
                  strokeDasharray={3}
                  pointerEvents="none"
                />
                <Line
                  from={{ x: 0, y: yScale(lowerUpperLimit) }}
                  to={{ x: xScale(maxHeat), y: yScale(lowerUpperLimit) }}
                  stroke={settings.colors.Primary.Grey_4}
                  strokeWidth={1}
                  strokeDasharray={3}
                  pointerEvents="none"
                />
              </>
            )}

            {isDefined(upperInnerLimit) && isDefined(lowerInnerLimit) && (
              <>
                <Line
                  from={{ x: 0, y: yScale(upperInnerLimit) }}
                  to={{ x: xScale(maxHeat), y: yScale(upperInnerLimit) }}
                  stroke={settings.colors.Primary.Grey_4}
                  strokeWidth={1}
                  strokeDasharray={3}
                  pointerEvents="none"
                />
                <Line
                  from={{ x: 0, y: yScale(lowerInnerLimit) }}
                  to={{ x: xScale(maxHeat), y: yScale(lowerInnerLimit) }}
                  stroke={settings.colors.Primary.Grey_4}
                  strokeWidth={1}
                  strokeDasharray={3}
                  pointerEvents="none"
                />
              </>
            )}
          </g>
        );
      }
      return null;
    };

    const drawRangesTooltips = () => {
      const ranges = getParamRanges(paramRanges);

      if (isDefined(ranges)) {
        const { upperOuterLimit, lowerUpperLimit, upperInnerLimit, lowerInnerLimit } = ranges;
        return (
          <>
            {isDefined(upperOuterLimit) && isDefined(lowerUpperLimit) && (
              <>
                <Tooltip
                  data-test-id={upperOuterLimitLabelParametersComparison}
                  top={yScale(upperOuterLimit)! - MARGIN_TOP - PADDING_BOTTOM / 2}
                  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                  left={initialChartWidth - 10}
                  style={{
                    ...defaultTooltipStyle,
                    backgroundColor: 'transparent',
                    paddingLeft: 0,
                    boxShadow: 'none',
                    transform: 'none',
                  }}
                >
                  <ParamRangeIndicators upper={true} outer={true} value={upperOuterLimit} />
                </Tooltip>
                <Tooltip
                  data-test-id={lowerOuterLimitLabelParametersComparison}
                  top={yScale(lowerUpperLimit)! - MARGIN_TOP - PADDING_BOTTOM / 2}
                  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                  left={initialChartWidth - 10}
                  style={{
                    ...defaultTooltipStyle,
                    backgroundColor: 'transparent',
                    paddingLeft: 0,
                    boxShadow: 'none',
                    transform: 'none',
                  }}
                >
                  <ParamRangeIndicators outer={true} value={lowerUpperLimit} />
                </Tooltip>
              </>
            )}
            {isDefined(upperInnerLimit) && isDefined(lowerInnerLimit) && (
              <>
                <Tooltip
                  data-test-id={lowerInnerLimitLabelParametersComparison}
                  top={yScale(lowerInnerLimit)! - MARGIN_TOP - PADDING_BOTTOM / 2}
                  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                  left={initialChartWidth - 10}
                  style={{
                    ...defaultTooltipStyle,
                    paddingLeft: 0,
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    transform: 'none',
                  }}
                >
                  <ParamRangeIndicators value={lowerInnerLimit} />
                </Tooltip>
                <Tooltip
                  data-test-id={upperInnerLimitLabelParametersComparison}
                  top={yScale(upperInnerLimit)! - MARGIN_TOP - PADDING_BOTTOM / 2}
                  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                  left={initialChartWidth - 10}
                  style={{
                    ...defaultTooltipStyle,
                    paddingLeft: 0,
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    transform: 'none',
                  }}
                >
                  <ParamRangeIndicators upper={true} value={upperInnerLimit} />
                </Tooltip>
              </>
            )}
          </>
        );
      }
      return null;
    };
    const graphClipPath = `graph-clip-zone-${name}`;
    const graphClipPathUrl = `url(#${graphClipPath})`;

    return (
      <GraphWrapper data-test-id={wearParameterChartParametersComparison} $height={height} ref={ref}>
        <Left width={COL_LEFT_WIDTH}>
          <SvgAxisY width={COL_LEFT_WIDTH} height={height + PADDING_TOP + PADDING_BOTTOM}>
            <AxisLeft yScale={yScale as d3.ScaleLinear<number, number>} />
          </SvgAxisY>
        </Left>
        <Center>
          <DragZoomLabels>
            {isDragTarget && <DragZoomRange scrollbarRef={scrollbarRef} zoomRange={zoomRange} formatRange={formatDragRange} />}
            {hasDragZoomHint && isDefined(tooltipLeft) && <DragZoomHint scrollbarRef={scrollbarRef} x={tooltipLeft} />}
          </DragZoomLabels>
          <Scrollbars
            ref={scrollbarRef}
            renderTrackHorizontal={(props) => <TrackHorizontal {...props} />}
            renderThumbHorizontal={(props) => <ThumbHorizontal {...props} />}
            renderView={(props) => <ScrollViewNoMargin {...props} />}
            hideTracksWhenNotNeeded
            onScrollStart={handleScrollStart}
            onScrollFrame={handleScrollFrame}
            customOnMac
          >
            <DragZoom
              zoomRange={zoomRange}
              isDisabled={zoomLevel >= DRAG_ZOOM_MAX}
              onRangeDragStart={handleZoomRangeDragStart}
              onRangeChange={handleZoomRangeChange}
              onRangeDragEnd={handleZoomRangeDragEnd}
              setZoomRange={setZoomRange}
              xBisectToUnit={xBisectToChartDate}
              unitToX={(x: number) => xScale(x)!}
            >
              <Svg
                preserveAspectRatio="xMinYMin meet"
                width={graphWidth}
                height={height + PADDING_TOP}
                onMouseLeave={() => {
                  setHoverPosition(null);
                  setIsHoveringGraphArea(false);
                }}
              >
                <RectClipPath id={graphClipPath} x={0} y={0} width={graphWidth} height={height + PADDING_TOP} />
                <g clipPath={graphClipPathUrl} transform="translate(0, 0)">
                  <rect width={xMax} height={height} fill="none" stroke={settings.colors.Primary.Grey_4} />
                  {/*<Group transform={`translate(${MARGIN_LEFT}, ${MARGIN_TOP})`}>*/}
                  {drawRanges()}
                  {median >= yDomain[0] && median <= yDomain[1] && (
                    <Line
                      from={{ x: 0, y: yScale(median) }}
                      to={{ x: xScale(Math.max(...values.map(getHeat))), y: yScale(median) }}
                      stroke={settings.colors.Primary.Blue_6}
                      strokeWidth={1}
                      pointerEvents="none"
                    />
                  )}
                  <AxisBottom
                    top={yMax}
                    left={0}
                    scale={axisBottomProps.scale}
                    tickValues={axisBottomProps.tickValues}
                    stroke={settings.colors.Primary.Grey_4}
                    tickLength={4}
                    tickFormat={axisBottomProps.tickFormatter}
                    tickLabelProps={axisBottomProps.tickLabelProps}
                    tickStroke={settings.colors.Primary.Grey_4}
                    hideZero={false}
                  />
                  <Bar
                    x={0}
                    y={0}
                    width={xMax}
                    height={height}
                    fill="transparent"
                    rx={14}
                    onMouseMove={handleTooltip}
                    onMouseLeave={() => {
                      hideTooltip();
                    }}
                  />
                  {currentCampaignData.map((point, index) => {
                    return (
                      <g key={index}>
                        {getColorBar(point, 0.5)}
                        <Circle
                          key={`point-${point.heat}-${index}`}
                          className="dot"
                          cx={xScale(getHeat(point))}
                          cy={yScale(getTemp(point))}
                          r={0.7}
                          fill={settings.colors.Primary.Blue_8}
                        />
                      </g>
                    );
                  })}
                  {prevCampaignData.map((point, index) => {
                    return (
                      <g key={index}>
                        <Circle
                          key={`point-${point.heat}-${index}`}
                          className="dot"
                          cx={xScale(getHeat(point))}
                          cy={yScale(getTemp(point))}
                          r={0.5}
                          fill={settings.colors.Primary.Grey_6}
                        />
                      </g>
                    );
                  })}
                  {drawCurrentCampaign()}
                  {drawNextCampaign()}
                  {isDefined(tooltipData) && isDefined(tooltipLeft) && !isDragTarget && isDefined(hoverPosition) && (
                    <g>
                      <Line
                        from={{ x: tooltipLeft, y: -1 }}
                        to={{ x: tooltipLeft, y: height }}
                        stroke={settings.colors.Primary.Grey_8}
                        strokeWidth={2}
                        style={{ pointerEvents: 'none' }}
                      />
                      {hasDragZoomHint && <ChevronFilledDownIcon x={tooltipLeft - 9} y={-2} />}
                    </g>
                  )}
                  {/*</Group>*/}
                </g>
              </Svg>
            </DragZoom>
          </Scrollbars>
        </Center>
        <Tooltip
          top={-45}
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          left={MARGIN_LEFT - PADDING_LEFT}
          style={{ ...defaultTooltipStyle, transform: 'none', padding: 0, boxShadow: 'none' }}
        >
          {/* eslint-disable-next-line i18next/no-literal-string */}
          <Span data-test-id={parameterNameAndUnitLabelParametersComparison}>
            ↑ {name} {unit ? `[${unit}]` : ''}
          </Span>
        </Tooltip>
        <Tooltip
          top={yMax + 15}
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          left={initialChartWidth - 60}
          style={{ ...defaultTooltipStyle, padding: 0, boxShadow: 'none', transform: 'translateX(0)' }}
        >
          <Span>{`${t('parameters-comparison:heats')} →`} </Span>
        </Tooltip>

        {isDefined(tooltipData) && !isDragTarget && isDefined(tooltipLeft) && isDefined(hoverPosition) && (
          <Tooltip
            top={yMax}
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            left={(tooltipX > tooltipLeft ? tooltipLeft : tooltipX) + MARGIN_LEFT}
            style={{ ...defaultTooltipStyle, padding: 0, boxShadow: 'none', zIndex: settings.Elevation.Tooltip }}
          >
            <TooltipInfo
              currentPoint={tooltipData.currentPoint}
              prevPoint={!sameCampaign ? tooltipData.prevPoint : undefined}
              currentCampaignNr={currentCampaignNr}
              prevCampaignNr={prevCampaignNr}
              name={name}
              heatRange={tooltipData.heatRange}
            />
          </Tooltip>
        )}

        {drawRangesTooltips()}
        {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
        {median >= yDomain[0] && median <= yDomain[1] && (currentCampaignData.filter((d) => d.value) ?? []).length > 0 && (
          <Tooltip
            data-test-id={medianLabelParametersComparison}
            top={yScale(median)! - MARGIN_TOP}
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            left={initialChartWidth - 10}
            style={{
              ...defaultTooltipStyle,
              boxShadow: 'none',
              transform: 'none',
              backgroundColor: settings.colors.Primary.Blue_8,
              color: settings.colors.Monochromatic.White,
              padding: '1px',
              marginLeft: settings.Spacing.Spacing_150, //align this label with the param ranges labels.
            }}
          >
            <Span>{formatNumber(2, 2)(toUnicodeLocale(locale))(median)}</Span>
          </Tooltip>
        )}
      </GraphWrapper>
    );
  }
);

export const Svg = styled.svg`
  display: block;

  .vx-group.vx-axis.vx-axis-bottom {
    g:first-child text {
      text-anchor: start;
    }

    g:nth-last-child(2) text {
      text-anchor: end;
    }
  }
`;

export const Left = styled.div<{ width: number }>`
  width: ${(props) => props.width}px;
  display: flex;
  padding-left: ${MARGIN_LEFT}px;
`;

export const GraphWrapper = styled.div<{ $height: number }>`
  background-color: ${settings.colors.Monochromatic.White};
  position: relative;
  display: flex;
`;

const Span = styled.span`
  display: inline-flex;
  justify-content: center;
`;

export default React.memo(WearParamsGraph);
