import { settings } from '@rhim/design';
import { assert } from '@rhim/utils/assert';
import { isDefined } from '@rhim/utils/is-defined';
import React, { FC, useEffect, useRef, useState } from 'react';

import { AreaPanelTexts } from '../WallplotAreasPanel/domain';
import { fillRoundedRectWithLabel } from './CellHoveringCanvas';
import { Area, AreaLayout, getLabelMetrics, LabelMetrics, SETTINGS } from './utils';

const STROKE_WIDTH = 2;
const TOP_BORDER_RADIUS_PX = 3;

const showLabel = (
  canvas2DContext: CanvasRenderingContext2D,
  area: AreaLayout,
  labelMetrics: LabelMetrics,
  label: string,
  wallplotWidth: number,
  wallplotMarginLeft: number
) => {
  const areaLabelTotalWidth = labelMetrics.width + 2 * SETTINGS.AXIS.ROUNDED_LABEL_PADDING_HORIZONTAL;
  let areaX = area.startX;
  if (area.startX + areaLabelTotalWidth > wallplotWidth + wallplotMarginLeft) {
    // prevent the area label from overshooting the available plot width, position it instead so that it ends at the top right corner of the area
    const areaLabelWidthOverflowX = areaLabelTotalWidth - area.width - STROKE_WIDTH;
    areaX -= areaLabelWidthOverflowX;
  }
  fillRoundedRectWithLabel(
    canvas2DContext,
    labelMetrics,
    label,
    areaX + SETTINGS.AXIS.ROUNDED_LABEL_PADDING_HORIZONTAL + labelMetrics.halfWidth - STROKE_WIDTH / 2,
    area.startY - SETTINGS.AXIS.ROUNDED_LABEL_PADDING_VERTICAL - labelMetrics.halfHeight,
    { topLeftRadius: TOP_BORDER_RADIUS_PX, topRightRadius: TOP_BORDER_RADIUS_PX, bottomRightRadius: 0, bottomLeftRadius: 0 }
  );
};

const highlightArea = (
  wallplotWidth: number,
  wallplotMarginLeft: number,
  areasLayout: AreaLayout[],
  highlightedAreaLayout: AreaLayout,
  canvas2DContext: CanvasRenderingContext2D,
  isShowingAreaLabel: boolean,
  texts: AreaPanelTexts
) => {
  canvas2DContext.save();
  canvas2DContext.strokeStyle = settings.colors.Primary.Grey_8;

  canvas2DContext.lineWidth = STROKE_WIDTH;
  canvas2DContext.strokeRect(highlightedAreaLayout.startX, highlightedAreaLayout.startY, highlightedAreaLayout.width, highlightedAreaLayout.height);

  // show the area's label in its top-left corner
  canvas2DContext.font = '12px nortw05-medium';
  const label = `${texts.zoneNames[highlightedAreaLayout.groupLabel]} - ${texts.areaNames[highlightedAreaLayout.label]}`;
  const labelMetrics = getLabelMetrics(canvas2DContext, label);
  if (isShowingAreaLabel) {
    showLabel(canvas2DContext, highlightedAreaLayout, labelMetrics, label, wallplotWidth, wallplotMarginLeft);
  }

  /*
   check if this area is connected to another ( e.g the "Impact" area is divided into 2 "connected" areas because it starts close to the rightmost edge of the plot and,
   once it reaches the end of the plot to the right, it wraps "around" the x-axis and starts again from the leftmost edge of the plot)
   If so, then highlight that connected area as well
   */
  const connectedArea = areasLayout.find((area) => area.connectedToAreaId === highlightedAreaLayout.areaId);
  if (connectedArea) {
    canvas2DContext.strokeRect(connectedArea.startX, connectedArea.startY, connectedArea.width, connectedArea.height);
    if (isShowingAreaLabel) {
      showLabel(canvas2DContext, connectedArea, labelMetrics, label, wallplotWidth, wallplotMarginLeft);
    }
  }
};

interface AreaHighlightCanvasProps {
  className?: string;
  texts: AreaPanelTexts;
  canvasWidth: number;
  canvasHeight: number;
  wallplotWidth: number;
  wallplotMarginLeft: number;
  areasLayout: AreaLayout[];
  areas: Area[];
  mouseX: number;
  mouseY: number;
  isShowingAreaLabel?: boolean;
  onAreaEntered?: (areaId: number) => void;
  onAreasExited?: () => void;
  onRegionClicked?: (regionId: number) => void;
  highlightAreaId?: number | null;
}
const AreaHighlightCanvas: FC<React.PropsWithChildren<AreaHighlightCanvasProps>> = ({
  className,
  texts,
  canvasWidth,
  canvasHeight,
  wallplotWidth,
  wallplotMarginLeft,
  areasLayout,
  mouseX,
  mouseY,
  isShowingAreaLabel = true,
  onAreaEntered,
  onAreasExited,
  onRegionClicked,
  highlightAreaId,
}) => {
  const [mostRecentHoveredArea, setMostRecentHoveredArea] = useState<AreaLayout | null>(null);
  const domCanvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!domCanvasRef.current) {
      return;
    }
    const canvas = domCanvasRef.current;
    const canvas2DContext = canvas.getContext('2d');
    if (!canvas2DContext) {
      return;
    }
    const hoveredArea = highlightAreaByMouseCoordinates(canvas2DContext, mouseX, mouseY);
    if (isDefined(onAreaEntered) && isDefined(hoveredArea) && hoveredArea !== mostRecentHoveredArea) {
      setMostRecentHoveredArea(hoveredArea);
      onAreaEntered(hoveredArea.areaId);
    }
    if (isDefined(onAreasExited) && !isDefined(hoveredArea) && isDefined(mostRecentHoveredArea)) {
      setMostRecentHoveredArea(null);
      onAreasExited();
    }
    canvas.style.cursor = isDefined(hoveredArea) ? 'pointer' : 'default';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mouseX, mouseY]);

  useEffect(() => {
    if (!domCanvasRef.current) {
      return;
    }
    const canvas = domCanvasRef.current;
    const canvas2DContext = canvas.getContext('2d');
    if (!canvas2DContext) {
      return;
    }
    highlightAreaById(canvas2DContext, highlightAreaId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightAreaId]);

  const highlightAreaById = (canvas2DContext: CanvasRenderingContext2D, areaId: number | null | undefined) => {
    // clear canvas
    canvas2DContext.clearRect(0, 0, canvasWidth, canvasHeight);

    if (!isDefined(areaId)) {
      return;
    }

    const areaToHighlight = areasLayout.find((area) => area.areaId === areaId);
    assert(isDefined(areaToHighlight), `Area with id ${areaId} not found in the areaslayout`);
    highlightArea(wallplotWidth, wallplotMarginLeft, areasLayout, areaToHighlight, canvas2DContext, isShowingAreaLabel, texts);
  };

  const highlightAreaByMouseCoordinates = (canvas2DContext: CanvasRenderingContext2D, mouseX: number, mouseY: number): AreaLayout | null => {
    // clear canvas
    canvas2DContext.clearRect(0, 0, canvasWidth, canvasHeight);

    let hoveredArea = null;
    for (const areaLayout of areasLayout) {
      if (
        !(
          mouseX >= areaLayout.startX &&
          mouseX <= areaLayout.startX + areaLayout.width &&
          mouseY >= areaLayout.startY &&
          mouseY <= areaLayout.startY + areaLayout.height
        )
      ) {
        continue;
      }
      highlightArea(wallplotWidth, wallplotMarginLeft, areasLayout, areaLayout, canvas2DContext, isShowingAreaLabel, texts);
      hoveredArea = areaLayout;
      break;
    }
    canvas2DContext.restore();

    return hoveredArea;
  };

  const handleCanvasClicked = () => {
    if (isDefined(mostRecentHoveredArea)) {
      onRegionClicked?.(mostRecentHoveredArea.regionId);
    }
  };

  return <canvas className={className} ref={domCanvasRef} onClick={handleCanvasClicked} width={canvasWidth} height={canvasHeight}></canvas>;
};

export default React.memo(AreaHighlightCanvas);
