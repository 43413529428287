import React from 'react';

import { useZoomContext } from './hooks/useZoomContext';
import ZoomButtons from './ZoomButtons';

const ZoomUI: React.ChildlessComponent = () => {
  const { zoomLevel, handleReset, handleZoom } = useZoomContext();

  return <ZoomButtons onReset={handleReset} onZoom={handleZoom} zoomLevel={zoomLevel} />;
};

export default React.memo(ZoomUI);
