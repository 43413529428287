import { settings } from '@rhim/design';
import {
  errorPageHeadingLabelErrorPage,
  errorPageSubHeadingLabelErrorPage,
  errorPanelContainerErrorPage,
  primaryButtonErrorPage,
  secondaryButtonErrorPage,
} from '@rhim/test-ids';
import { isDefined } from '@rhim/utils';
import styled from 'styled-components';

import { Button } from '../Button/Button';

export type Size = 'large' | 'small';

const StyledContainer = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

type StyledIconProps = {
  size: Size;
};
const StyledIcon = styled.div<StyledIconProps>`
  margin-bottom: ${(props) => (props.size === 'large' ? settings.Spacing.Spacing_200 : settings.Spacing.Spacing_100)};

  svg {
    display: block;
  }
`;

type StyledHeadingProps = {
  size: Size;
  color?: string;
};
const StyledHeading = styled.div<StyledHeadingProps>`
  font-size: ${(props) => (props.size === 'large' ? `${settings.typography.FontSize.XXXX_Large}` : `${settings.typography.FontSize.Large}`)};
  line-height: ${(props) => (props.size === 'large' ? settings.typography.LineHeight.Line_Height_60 : settings.typography.LineHeight.Line_Height_24)};
  font-family: ${settings.typography.FontFamily.Bold};
  color: ${(props) => props.color ?? settings.colors.Primary.Grey_8};
  margin-bottom: ${settings.Spacing.Spacing_100};
`;

type StyledSubHeadingProps = {
  size: Size;
  buttonsFollow: boolean;
  color?: string;
};
const StyledSubHeading = styled.div<StyledSubHeadingProps>`
  --marginBottom: ${(props) => {
    if (props.size === 'large') {
      return props.buttonsFollow ? settings.Spacing.Spacing_400 : settings.Spacing.Spacing_500;
    } else {
      return props.buttonsFollow ? settings.Spacing.Spacing_200 : settings.Spacing.Spacing_300;
    }
  }};

  font-size: ${(props) => (props.size === 'large' ? `${settings.typography.FontSize.Medium}` : `${settings.typography.FontSize.Small}`)};
  font-family: ${settings.typography.FontFamily.Regular};
  color: ${(props) => props.color ?? settings.colors.Primary.Grey_6};
  margin-bottom: var(--marginBottom);
  max-width: 70vw;
  text-align: center;
`;

type StyledButtonsContainerProps = {
  size: Size;
};
const StyledButtonsContainer = styled.div<StyledButtonsContainerProps>`
  display: flex;
  margin-bottom: ${(props) => (props.size === 'large' ? settings.Spacing.Spacing_500 : settings.Spacing.Spacing_300)};

  button:not(:first-of-type) {
    margin-left: ${settings.Spacing.Spacing_100};
  }
`;

export interface CtaButton {
  label: string;
  onClick?: () => void;
}

interface Props {
  className?: string;
  size?: Size;
  heading: React.ReactNode;
  subHeading: React.ReactNode;
  primaryButton?: CtaButton;
  secondaryButton?: CtaButton;
  icon?: React.ReactElement;
  headerColor?: string;
  subheaderColor?: string;
}

export const MaintenancePanel: React.ChildlessComponent<Props> = ({
  className,
  size = 'large',
  heading,
  subHeading,
  primaryButton,
  secondaryButton,
  icon,
  subheaderColor,
  headerColor,
  ...rest
}) => {
  const buttonSize = size === 'large' ? 'small-40' : 'x-small-32';
  return (
    <StyledContainer className={className} {...rest} data-test-id={errorPanelContainerErrorPage}>
      <StyledWrapper>
        {isDefined(icon) && <StyledIcon size={size}>{icon}</StyledIcon>}
        <StyledHeading size={size} color={headerColor} data-test-id={errorPageHeadingLabelErrorPage}>
          {heading}
        </StyledHeading>
        <StyledSubHeading size={size} buttonsFollow={isDefined(primaryButton)} color={subheaderColor} data-test-id={errorPageSubHeadingLabelErrorPage}>
          {subHeading}
        </StyledSubHeading>
        {(isDefined(primaryButton) || isDefined(secondaryButton)) && (
          <StyledButtonsContainer size={size}>
            {isDefined(primaryButton) && (
              <Button label={primaryButton.label} size={buttonSize} onClick={primaryButton.onClick} data-test-id={primaryButtonErrorPage} />
            )}
            {isDefined(secondaryButton) && (
              <Button label={secondaryButton.label} size={buttonSize} mode="ghost" onClick={secondaryButton.onClick} data-test-id={secondaryButtonErrorPage} />
            )}
          </StyledButtonsContainer>
        )}
      </StyledWrapper>
    </StyledContainer>
  );
};
