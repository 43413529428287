import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const DefaultCursorIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" role={role} {...rest} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.239 2.15a2 2 0 0 1 2.182.442l9 9.08A2 2 0 0 1 17 15.082h-.647c.506 1.007 1.037 2.104 1.37 2.912.413.998.078 1.899-.192 2.375a3.589 3.589 0 0 1-1.102 1.183 2.7 2.7 0 0 1-1.26.435 3.69 3.69 0 0 1-.813-.02 4.724 4.724 0 0 1-.69-.143l-.056-.017-.02-.006-.008-.003h-.004l-.002-.002.611-1.904s.761.245 1.126 0c.281-.19.561-.527.598-.854a.589.589 0 0 0-.036-.28c-.402-.974-1.144-2.466-1.766-3.676-.575-1.121-1.046-2-1.046-2H17L8 4v13.62l3.375-3.405 2.813 5.675-.613 1.904a2 2 0 0 1-1.18-1.016l-1.57-3.168-1.404 1.418A2 2 0 0 1 6 17.62V4.001a2 2 0 0 1 1.239-1.85z"
        fill={fill}
      />
    </svg>
  );
};

DefaultCursorIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Grey_4,
};
