import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const OpsStateCancelledIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        d="M8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z M2,8 C2,11.3137085 4.6862915,14 8,14 C9.2954479,14 10.4950071,13.5894515 11.4756657,12.8913664 L3.1086336,4.52433431 C2.41054847,5.50499293 2,6.7045521 2,8 Z M8,2 C6.7045521,2 5.50499293,2.41054847 4.52433431,3.1086336 L12.8913664,11.4756657 C13.5894515,10.4950071 14,9.2954479 14,8 C14,4.6862915 11.3137085,2 8,2 Z"
        fillRule="nonzero"
      ></path>
    </svg>
  );
};

OpsStateCancelledIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Operational.State_Notif_Grey_2,
};
