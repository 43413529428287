import { settings } from '@rhim/design';
import { isDefined } from '@rhim/utils';
import React from 'react';
import styled from 'styled-components';

import { LegendItemLabel } from './utils';

const DOT_RADIUS_PX = 3;
const DOT_MARGIN_PX = 1;
const DOT_DIAMETER_PX = 2 * (DOT_RADIUS_PX + DOT_MARGIN_PX);
const LINE_STROKE_WIDTH_PX = 2;
const TOTAL_LENGTH_PX = 24;

interface Props {
  lineStroke?: settings.colors.Any;
  dotFill?: settings.colors.Any;
  label?: string;
}
const LineWithDot: React.ChildlessComponent<Props> = ({ lineStroke = settings.colors.Primary.Blue_9, dotFill = settings.colors.Primary.Blue_8, label }) => {
  const lineSegmentLeftEndX = TOTAL_LENGTH_PX / 2 - DOT_DIAMETER_PX / 2;
  const lineSegmentRightStartX = lineSegmentLeftEndX + DOT_DIAMETER_PX;
  const dotCenterX = lineSegmentLeftEndX + DOT_DIAMETER_PX / 2;

  return (
    <SWrapper>
      <svg width={TOTAL_LENGTH_PX} height={DOT_DIAMETER_PX}>
        <g transform={`translate(0,${DOT_DIAMETER_PX / 2})`}>
          <line x1="0" y1="0" x2={lineSegmentLeftEndX} y2="0" stroke={lineStroke} strokeWidth={LINE_STROKE_WIDTH_PX} />
          <circle cx={dotCenterX} cy="0" r={DOT_RADIUS_PX + DOT_MARGIN_PX} fill={settings.colors.Monochromatic.White} />
          <circle cx={dotCenterX} cy="0" r={DOT_RADIUS_PX} fill={dotFill} />
          <line x1={lineSegmentRightStartX} y1="0" x2={TOTAL_LENGTH_PX} y2="0" stroke={lineStroke} strokeWidth={LINE_STROKE_WIDTH_PX} />
        </g>
      </svg>
      {isDefined(label) && <LegendItemLabel>{label}</LegendItemLabel>}
    </SWrapper>
  );
};
LineWithDot.whyDidYouRender = true;
export default React.memo(LineWithDot);

const SWrapper = styled.div`
  display: flex;
  align-items: center;
`;
