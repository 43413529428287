import type { AbstractMesh, ArcRotateCamera, Color3, Engine, Matrix, Mesh, Observable, Scene, Texture, Vector2, Vector3 } from '@babylonjs/core';
import { RHIMAPOReportingWearManagementApiV1ModelsOrientationLabelDto } from '@rhim/rest/wearManagement';
import { ColorScales } from '@rhim/utils';
import { TFunction } from 'react-i18next';
import { TableLikeData } from 'typings/internal/sections';

export interface CameraState {
  target: Vector3;
  radius: number;
  alpha: number;
  beta: number;
}

export interface LadleMaterialOptions {
  withThicknessMap?: boolean;
  withMarkers?: boolean;
  noValueColor?: Color3;
  withVolumeLine?: boolean;
}

export type VesselView = 'whole' | 'floor';

export type CameraOrientation = 'FRONT' | 'BACK' | 'RIGHT' | 'LEFT' | 'TOP' | 'BOTTOM';

export interface PointCloudData {
  colors: number[];
  vertex: number[];
}

export interface Scene3dApiFacade {
  onPointPickedObservable: Observable<PickedPoint>;
  onPointerHoverObservable: Observable<boolean>;
  isEnginePaused: () => boolean;
  setEngineAutoStopMode: (mode: 'on' | 'off') => void;
  updateMinThicknessValue: (minThickness: number) => void;
  resize: () => void;
  getMainCamera: () => ArcRotateCamera;
  setFloorView: (modelName: string, view: 'on' | 'off', withAnimation?: boolean) => void;
  moveCamera: (target: CameraOrientation | 'Home', withAnimation?: boolean) => void;
  zoomCamera: (direction: 'in' | 'out') => void;
  setCameraRotationMode: (mode: 'rotate' | 'pan') => void;
  getEngine: () => Engine;
  getEngineOnResizeObservable: Observable<Engine>;
  getEngineHardwareScalingLevel: number;
  getEngineRenderSize: [number, number];
  getScene: () => Scene;
  getSceneTransformMatrix: Matrix;
  disableAllModels: () => void;
  disposeAllModels: () => void;
  enableVolumeDepthLine: (enable: boolean) => void;
  setVolumeStartingDepth: (volumeStartingDepth: number, topPosition: number) => void;
  getBoundingBox: () => { min: Vector3; max: Vector3 } | null;
  disposeAllTransformNodesByTag: (tag: string) => void;
  addModel: (mesh: Mesh, enable?: boolean) => Scene3dApiFacade;
  addPointCloud: (name: string, data: PointCloudData) => Mesh;
  setCameraRotationState: (cameraType: CameraRotation, toLock: boolean) => void;
  takeScreenShots: () => Promise<[string, string, string, string, string]>;
  onTranslationUpdate: (translate: TFunction<'visualization3d'>) => void;
  updateColorScale: (colorScales: ColorScales | null) => void;
  updateClusters: (sectionsData: TableLikeData, colorScales: ColorScales) => void;
  setDiffuseTexture: (texture: Nullable<Texture>) => void;
  addOrientationCompass: (mesh: Mesh, orientationLabels: NonEmptyArray<RHIMAPOReportingWearManagementApiV1ModelsOrientationLabelDto>) => void;
  handleThicknessMapUpdated: () => void;
  isHoveringGizmo: boolean;

  /**
   * Searches for a texture which has the same name as a provided mesh
   * In case no mesh is provided, an active mesh on the scene will be used
   * @param mesh
   */
  hasAssociatedTexture: (mesh: Nullable<AbstractMesh>) => boolean;

  /**
   * When we load a glb file it can have a native material with a texture
   * If we are not in a mode when we show color scales or regions we need to show this texture
   */
  setShowNativeTexture: (showNativeTexture: boolean) => void;
}

export enum CameraRotation {
  Horizontal,
  Vertical,
}

export interface PickedPoint {
  pickedPoint: Vector3;
  mouseOnScreenPosition: Vector2;
  mesh: AbstractMesh;
  indices: Vector3;
  barycentricVolumes: Vector3;
}
