import { RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationDto } from '@rhim/rest/fileIngestion';
import { specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api';

type Payload = RHIMFileIngestionServiceV1CustomerFileTypeConfigurationCustomerFileTypeConfigurationDto;

export const useCustomerFileType = (
  id: string,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) => {
  return useQuery(getKey(id), (context) => queryFn(context, options?.axiosConfiguration), options?.configuration);
};

const getKey = (id: string) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'fileIngestion',
      scope: 'customerFileType',
      entity: 'detail',
      id,
    },
  ]);

const queryFn = ({ queryKey: [{ id }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  return API.customerFileTypes.getCustomerFileTypeConfiguration(id, { signal, ...axiosConfiguration }).then((response) => response.data);
};

useCustomerFileType.getKey = getKey;
useCustomerFileType.queryFn = queryFn;
