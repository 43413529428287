export enum ChosenVesselType {
  BOF = 'bof',
  RHDegasser = 'rh',
  Ladle = 'ladle',
  EAF = 'eaf',
}

export enum MeasurementFileType {
  FilteredCSV = 'ProcessMetrixFiltered',
  VertexCSV = 'ProcessMetrixVertex',
  SummaryCSV = 'ProcessMetrixSummary',
  MPM_SPM = 'ProcessMetrixRaw', // Note: SPM and MPM resemble the same file type, they just have different extensions (this extension is configured in LCS based on the converter type)
  ZIP = 'ProcessMetrixZip',
  PostMortem = 'PostMortem',
  STL = 'STL',
}

export enum ProcessedDataFileType {
  ProcessedCSV = 'ProcessData',
}

export enum PointCloudScanFileType {
  PTS = 'PTS',
}

/**
 * Note: you may be tempted to use a more sophisticated MIME type such as
 * `application/zip` or `application/x-zip-compressed` instead of the extension.
 *
 * Don't. Stick to file extensions. They work across browsers.
 *
 * For example, a `zip` file is `application/zip` on macOS but `application/x-zip-compressed` on Windows.
 */
export enum MIMEType {
  CSV = '.csv',
  XLS = '.xls',
  XLSX = '.xlsx',
  ZIP = '.zip',
  MPM = '.mpm',
  SPM = '.spm',
  STL = '.stl',
  PTS = '.pts',
  JSON = '.json',
  GLB = '.glb',
  GLTF = '.gltf',
  E57 = '.e57',
}

//Define a dictionary mapping MIME types to file extensions
export const MimeToExtension = {
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'image/gif': 'gif',
  'image/svg+xml': 'svg',
  'image/webp': 'webp',
  'image/bmp': 'bmp',
  'text/html': 'html',
  'text/plain': 'txt',
  'text/css': 'css',
  'text/javascript': 'js',
  'text/markdown': 'md',
  'application/pdf': 'pdf',
  'application/zip': 'zip',
  'application/json': 'json',
  'application/xml': 'xml',
  'application/msword': 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'application/vnd.ms-powerpoint': 'ppt',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
  'application/x-7z-compressed': '7z',
  'application/x-rar-compressed': 'rar',
  'application/vnd.android.package-archive': 'apk',
  'application/x-tar': 'tar',
  'audio/mpeg': 'mp3',
  'audio/ogg': 'ogg',
  'audio/wav': 'wav',
  'audio/x-aac': 'aac',
  'audio/webm': 'weba',
  'video/mp4': 'mp4',
  'video/x-msvideo': 'avi',
  'video/webm': 'webm',
  'video/mpeg': 'mpeg',
  'application/octet-stream': 'bin',
};
