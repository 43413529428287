import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const LiningBricksIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0h7v4H0V0zm0 12h7v4H0v-4zm12-6H4v4h8V6zM0 6h2v4H0V6zm16 0h-2v4h2V6zM9 0h7v4H9V0zm7 12H9v4h7v-4z"
        fill={fill}
      />
    </svg>
  );
};

LiningBricksIcon.defaultProps = {
  size: '16',
  fill: 'currentColor',
};
