import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const CampaignConfigIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-20.000000, -238.000000) translate(0.000000, 96.000000) translate(8.000000, 130.000000)">
              <g>
                <path
                  fill={fill}
                  d="M18.6 12c.281 0 .525.195.586.47l.208.938c.302.092.592.213.866.36l.812-.517c.237-.151.548-.117.746.082l.849.849c.199.199.233.509.082.746l-.517.812c.147.274.268.564.36.866l.938.209c.275.06.47.304.47.585v1.2c0 .28-.195.525-.47.586l-.938.208c-.092.302-.213.592-.36.866l.517.812c.151.237.117.547-.082.746l-.849.849c-.115.116-.269.176-.424.176-.111 0-.223-.031-.322-.094l-.812-.516c-.274.146-.564.267-.866.359l-.208.938c-.061.275-.305.47-.586.47h-1.2c-.281 0-.525-.195-.586-.47l-.208-.938c-.302-.092-.592-.213-.866-.36l-.812.517c-.099.063-.211.094-.322.094-.155 0-.309-.06-.424-.176l-.849-.85c-.199-.198-.233-.508-.082-.745l.517-.812c-.147-.274-.268-.564-.36-.866l-.938-.208c-.275-.061-.47-.305-.47-.586v-1.2c0-.281.195-.524.47-.585l.938-.21c.092-.301.213-.591.36-.865l-.517-.812c-.151-.237-.117-.547.082-.746l.849-.85c.198-.198.508-.232.746-.081l.812.517c.274-.147.564-.268.866-.36l.208-.938c.061-.275.305-.47.586-.47zm-8.53 7c.132 1.064.472 2.062.98 2.954-2.435-.218-4.619-1.305-6.236-2.954zM18 15.6c-1.326 0-2.4 1.074-2.4 2.4 0 1.326 1.074 2.4 2.4 2.4 1.326 0 2.4-1.074 2.4-2.4 0-1.326-1.074-2.4-2.4-2.4zM5.95 13v4H3.299c-.695-1.2-1.155-2.552-1.3-4h3.95zm5.81 0c-.902 1.125-1.503 2.497-1.69 4H7.95v-4zm-.81-6v4H2c.144-1.448.604-2.8 1.299-4h7.65zM20.6 7c.695 1.2 1.154 2.552 1.298 4h-.029c-1.147-.636-2.466-1-3.87-1s-2.723.364-3.87 1h-1.18V7zM11.95 2c2.796 0 5.321 1.15 7.135 3H4.814c1.814-1.85 4.339-3 7.136-3z"
                  transform="translate(12.000000, 12.000000)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

CampaignConfigIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
