import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
  dataTestId?: string;
}

export const ArrowLeftIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, dataTestId, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} data-test-id={dataTestId} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M3.414 7l2.293-2.293c.391-.391.391-1.023 0-1.414s-1.023-.391-1.414 0l-4 4c-.391.391-.391 1.023 0 1.414l4 4c.195.195.451.293.707.293.256 0 .512-.098.707-.293.391-.391.391-1.023 0-1.414L3.414 9H15c.552 0 1-.448 1-1s-.448-1-1-1H3.414z"
      />
    </svg>
  );
};

ArrowLeftIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
