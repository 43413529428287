import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const ZoomInIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M11 2c4.971 0 9 4.029 9 9 0 2.125-.736 4.078-1.968 5.617l3.675 3.676c.391.391.391 1.023 0 1.414s-1.023.391-1.414 0l-3.676-3.675C15.078 19.264 13.125 20 11 20c-4.971 0-9-4.029-9-9s4.029-9 9-9zm0 2c-3.866 0-7 3.134-7 7s3.134 7 7 7c1.891 0 3.606-.75 4.865-1.967l.078-.09c.028-.028.058-.055.089-.079C17.25 14.606 18 12.891 18 11c0-3.866-3.134-7-7-7zm0 3c.552 0 1 .448 1 1v2h2c.513 0 .936.386.993.883L15 11c0 .552-.448 1-1 1h-2v2c0 .513-.386.936-.883.993L11 15c-.552 0-1-.448-1-1v-2H8c-.513 0-.936-.386-.993-.883L7 11c0-.552.448-1 1-1h2V8c0-.513.386-.936.883-.993L11 7z"
      />
    </svg>
  );
};

ZoomInIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
