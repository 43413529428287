import { settings } from '@rhim/design';
import { SupportedLanguageIsoCode } from '@rhim/i18n';
import { assert, ColorScales, getColorRGBUsingScale, isDefined } from '@rhim/utils';
import React, { FC } from 'react';
import { UnitSystem } from 'typings';

import { useLocalization } from '../../hooks';
import { convertLength, convertUnit, MetricLengthUnit } from '../../partials/FormattedLength';
import Canvas from './Canvas';
import { CellLayout, DataItem, drawRect, fillRect, getGridCellLayout, LabelStyle, SETTINGS, WallplotLayout } from './utils';

export type ShouldPaintTickAndLabel = (index: number) => boolean;
export const shouldPaintTickAndLabelForVerticalAxis: ShouldPaintTickAndLabel = (index) => index === 0 || index % 2 === 1;
export const shouldPaintTickAndLabelForHorizontalAxis: ShouldPaintTickAndLabel = (index) => index % 3 === 0;

export const labelStyleForVerticalAxis: LabelStyle = {
  textAlign: 'right',
  textBaseline: 'middle',
  textFont: '12px nortw05-medium',
};
export const labelStyleForHorizontalAxis: LabelStyle = {
  textAlign: 'center',
  textBaseline: 'top',
  textFont: '12px nortw05-medium',
};

export type LabelWithUnitSetter = (value: number, unitSystem: UnitSystem, locale: SupportedLanguageIsoCode) => string;
export const labelWithUnitSetterForVerticalAxis: LabelWithUnitSetter = (value, unitSystem, locale) => {
  const label = convertLength(value, MetricLengthUnit.m, unitSystem, undefined, 2, locale, false);
  const labelUnit = convertUnit(MetricLengthUnit.m, unitSystem);
  return `${label}${labelUnit}`;
};
export const labelWithUnitSetterForHorizontalAxis: LabelWithUnitSetter = (value) => {
  return `${value.toString()}°`;
};

interface CanvasWallplotProps {
  wallplotLayout: WallplotLayout;
  wallplotData: DataItem[];
  colorScales: ColorScales;
  showCellValues: boolean;
  showAxis: boolean;
  fillCells: boolean;
  criticalValueThreshold: number | undefined;
}
const CanvasWallplot: FC<React.PropsWithChildren<CanvasWallplotProps>> = ({
  wallplotLayout,
  wallplotData,
  colorScales,
  showCellValues,
  showAxis,
  fillCells,
  criticalValueThreshold,
}) => {
  const [localization] = useLocalization();
  const { unitSystem, locale } = localization;

  const drawCanvas = (canvas2DContext: CanvasRenderingContext2D) => {
    drawWallplot(canvas2DContext);
    if (showCellValues) {
      displayCellValues(canvas2DContext);
    }
    if (showAxis) {
      drawAxis(canvas2DContext);
    }
  };

  const drawWallplot = (canvas2DContext: CanvasRenderingContext2D) => {
    const { metrics, gridLayout } = wallplotLayout;
    if (metrics.canvasWidth === 0 || metrics.canvasHeight === 0) {
      return;
    }

    const imageData = canvas2DContext.createImageData(metrics.canvasWidth, metrics.canvasHeight);
    const imageDataBuffer = new Uint32Array(imageData.data.buffer);

    // clear canvas
    canvas2DContext.clearRect(0, 0, metrics.canvasWidth, metrics.canvasHeight);
    canvas2DContext.save();

    let fillColor;

    for (const wallplotDataItem of wallplotData) {
      const cellLayout = getGridCellLayout(gridLayout, wallplotDataItem.angle, wallplotDataItem.depth);
      assert(isDefined(cellLayout), `Cell layout for data-item with domain x: ${wallplotDataItem.angle} and y: ${wallplotDataItem.depth} not found`);
      if (fillCells) {
        fillColor = isDefined(wallplotDataItem.rbl) ? getColorRGBUsingScale(wallplotDataItem.rbl, colorScales) : SETTINGS.CELL_FILL_FOR_NULL_VALUE;
        fillRect(fillColor, metrics.canvasWidth, imageDataBuffer, cellLayout.startX, cellLayout.startY, cellLayout.width, cellLayout.height);
      } else {
        drawRect(SETTINGS.CELL_GRID_COLOR, metrics.canvasWidth, imageDataBuffer, cellLayout.startX, cellLayout.startY, cellLayout.width, cellLayout.height);
      }
    }
    // draw plot border
    drawPlotBorder(imageDataBuffer);

    canvas2DContext.putImageData(imageData, 0, 0);
    canvas2DContext.restore();
  };

  const displayCellValues = (canvas2DContext: CanvasRenderingContext2D) => {
    const { metrics, gridLayout } = wallplotLayout;
    if (
      metrics.cellWidth <= SETTINGS.GRID_VALUE_TEXT.MINIMUM_CELL_SIZE_TO_DISPLAY_TEXT_PX ||
      metrics.cellHeight <= SETTINGS.GRID_VALUE_TEXT.MINIMUM_CELL_SIZE_TO_DISPLAY_TEXT_PX
    ) {
      // do not display labels when the cell's size becomes too small
      return;
    }
    canvas2DContext.save();
    canvas2DContext.fillStyle = settings.colors.Primary.Grey_9;
    const fontSize =
      metrics.cellHeight >= SETTINGS.GRID_VALUE_TEXT.MINIMUM_CELL_SIZE_TO_DISPLAY_14PT_TEXT_PX
        ? settings.typography.FontSize.Small
        : settings.typography.FontSize.X_Small;

    canvas2DContext.textAlign = 'center';
    canvas2DContext.textBaseline = 'middle';

    for (const dataItem of wallplotData) {
      if (!isDefined(dataItem.rbl)) {
        continue;
      }
      const cellLayout = getGridCellLayout(gridLayout, dataItem.angle, dataItem.depth);
      if (!cellLayout) {
        throw new Error('Cell layout item not found');
      }
      const fontFamily =
        (isDefined(criticalValueThreshold) && dataItem.rbl <= criticalValueThreshold) || dataItem.rbl <= metrics.dataLowestValue
          ? settings.typography.FontFamily.Bold
          : settings.typography.FontFamily.Regular;
      canvas2DContext.font = `${fontSize} ${fontFamily}`;
      const formattedValue = convertLength(dataItem.rbl, MetricLengthUnit.mm, unitSystem, undefined, 2, locale, false);
      canvas2DContext.fillText(formattedValue, cellLayout.startX + cellLayout.halfWidth, cellLayout.startY + cellLayout.halfHeight);
    }
    canvas2DContext.restore();
  };

  const drawTicksAndLabelsForAxis = (
    canvas2DContext: CanvasRenderingContext2D,
    scaleDomain: number[],
    gridLayout: CellLayout[],
    getCellLayout: (index: number) => number,
    shouldPaintTickAndLabel: (index: number) => boolean,
    getTickStartX: (cellLayoutItem: CellLayout) => number,
    getTickStartY: (cellLayoutItem: CellLayout) => number,
    tickWidth: number,
    tickHeight: number,
    labelStyle: LabelStyle,
    labelWithUnitSetter: LabelWithUnitSetter,
    getTickLabelStartX: (tickStartX: number, tickStartY: number) => number,
    getTickLabelStartY: (tickStartX: number, tickStartY: number) => number,
    lastTickLabelOffsetX: (cellLayoutItem: CellLayout) => number,
    lastTickLabelOffsetY: (cellLayoutItem: CellLayout) => number,
    domainStep: number
  ) => {
    canvas2DContext.save();
    const domainLength = scaleDomain.length;

    const drawTickAndLabel = (paintTickAndLabel: boolean, startX: number, startY: number, width: number, height: number, labelWithUnit: string) => {
      canvas2DContext.save();
      // draw tick
      canvas2DContext.fillStyle = '#b1bbc2'; // --rhim_color_pr_grey_4
      canvas2DContext.fillRect(startX, startY, width, height);
      // draw tick label
      if (paintTickAndLabel) {
        canvas2DContext.textAlign = labelStyle.textAlign;
        canvas2DContext.textBaseline = labelStyle.textBaseline;
        canvas2DContext.fillStyle = '#506676'; // --rhim_color_pr_grey_7
        canvas2DContext.font = labelStyle.textFont;
        canvas2DContext.fillText(labelWithUnit, getTickLabelStartX(startX, startY), getTickLabelStartY(startX, startY));
      }
      canvas2DContext.restore();
    };

    for (let i = 0; i < domainLength; i++) {
      const cellLayoutItem = gridLayout[getCellLayout(i)];
      if (!cellLayoutItem) {
        throw new Error(`Gridlayout unknown index of : ${i}`);
      }
      // draw tick
      const tickStartX = getTickStartX(cellLayoutItem);
      const tickStartY = getTickStartY(cellLayoutItem);

      const scaleDomainItem = scaleDomain[i];
      assert(isDefined(scaleDomainItem), 'Undefined scale domain item at index ' + i);
      const labelWithUnit = labelWithUnitSetter(scaleDomainItem, unitSystem, locale);
      drawTickAndLabel(shouldPaintTickAndLabel(i), tickStartX, tickStartY, tickWidth, tickHeight, labelWithUnit);
      if (i === domainLength - 1) {
        // draw an extra tick & label at the very end
        const scaleDomainItemLast = scaleDomain[domainLength - 1];
        if (!isDefined(scaleDomainItemLast)) {
          throw new Error('Scale domain item last not found');
        }
        const lastValueLabelWithUnit = labelWithUnitSetter(scaleDomainItemLast + domainStep, unitSystem, locale);
        drawTickAndLabel(
          true,
          tickStartX + lastTickLabelOffsetX(cellLayoutItem),
          tickStartY + lastTickLabelOffsetY(cellLayoutItem),
          tickWidth,
          tickHeight,
          lastValueLabelWithUnit
        );
      }
    }
    canvas2DContext.restore();
  };

  const drawPlotBorder = (imageDataBuffer: Uint32Array) => {
    const { metrics } = wallplotLayout;

    const borderColor = { red: 177, green: 187, blue: 194 }; // --rhim_color_pr_grey_4
    // top border
    drawRect(borderColor, metrics.canvasWidth, imageDataBuffer, wallplotLayout.wallplotMargin.left, wallplotLayout.wallplotMargin.top, metrics.plotWidth, 1);
    // right border
    drawRect(
      borderColor,
      metrics.canvasWidth,
      imageDataBuffer,
      wallplotLayout.wallplotMargin.left + metrics.plotWidth,
      wallplotLayout.wallplotMargin.top,
      1,
      metrics.plotHeight
    );
    // bottom border
    drawRect(
      borderColor,
      metrics.canvasWidth,
      imageDataBuffer,
      wallplotLayout.wallplotMargin.left,
      wallplotLayout.wallplotMargin.top + metrics.plotHeight,
      metrics.plotWidth,
      1
    );
    // left border
    drawRect(borderColor, metrics.canvasWidth, imageDataBuffer, wallplotLayout.wallplotMargin.left, wallplotLayout.wallplotMargin.top, 1, metrics.plotHeight);
  };

  const drawAxis = (canvas2DContext: CanvasRenderingContext2D) => {
    const { metrics } = wallplotLayout;

    // vertical axis
    drawTicksAndLabelsForAxis(
      canvas2DContext,
      metrics.yScaleDomain,
      wallplotLayout.gridLayout,
      (index) => index,
      shouldPaintTickAndLabelForVerticalAxis,
      (_cellLayoutItem) => wallplotLayout.wallplotMargin.left - SETTINGS.AXIS.TICK_LENGTH_PX,
      (cellLayoutItem) => cellLayoutItem.startY,
      SETTINGS.AXIS.TICK_LENGTH_PX,
      1,
      labelStyleForVerticalAxis,
      labelWithUnitSetterForVerticalAxis,
      (tickStartX, _tickStartY) => tickStartX - SETTINGS.AXIS.TICK_LABEL_DISTANCE_PX,
      (_tickStartX, tickStartY) => tickStartY,
      (_cellLayoutItem) => 0,
      (cellLayoutItem) => cellLayoutItem.height,
      metrics.domainYStep
    );

    // horizontal axis
    drawTicksAndLabelsForAxis(
      canvas2DContext,
      metrics.xScaleDomain,
      wallplotLayout.gridLayout,
      (index) => index * metrics.yScaleDomain.length,
      shouldPaintTickAndLabelForHorizontalAxis,
      (cellLayoutItem) => cellLayoutItem.startX,
      (_cellLayoutItem) => wallplotLayout.wallplotMargin.top + metrics.plotHeight,
      1,
      SETTINGS.AXIS.TICK_LENGTH_PX,
      labelStyleForHorizontalAxis,
      labelWithUnitSetterForHorizontalAxis,
      (tickStartX, _tickStartY) => tickStartX,
      (_tickStartX, tickStartY) => tickStartY + SETTINGS.AXIS.TICK_LENGTH_PX + SETTINGS.AXIS.TICK_LABEL_DISTANCE_PX - 1,
      (cellLayoutItem) => cellLayoutItem.width,
      (_cellLayoutItem) => 0,
      metrics.domainXStep
    );
  };

  return <Canvas draw={drawCanvas} width={wallplotLayout.metrics.canvasWidth} height={wallplotLayout.metrics.canvasHeight} />;
};

export default React.memo(CanvasWallplot);
