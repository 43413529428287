/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { settings } from '@rhim/design';
import { VesselType } from '@rhim/rest';
import { leftRegionMapSectionLiningDetails, rightAxisLabelsLiningDetails, rightRegionMapSectionLiningDetails } from '@rhim/test-ids';
import { ensure, isDefined } from '@rhim/utils';
import { Axis, AxisRight, Orientation } from '@visx/axis';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { Line } from '@vx/shape';
import { max as d3max, min } from 'd3-array';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Tooltip } from '../Tooltip';
import { Point, PreviewRegion } from './utils';

const axisColor = settings.colors.Primary.Grey_3;
const tickLabelColor = settings.colors.Primary.Grey_6;
export const labelColor = settings.colors.Primary.Grey_6;
const svgMargin = {
  top: 40,
  right: 30,
  bottom: 20,
  left: 50,
};

export type RectangularMapProps = {
  width: number;
  height: number;
  showControls?: boolean;
  showAxis?: boolean;
  yMax?: number;
  vesselType?: VesselType;
  strokeColor?: string;
  fill?: string;
  onRegionClick?: (areaNr: number) => void;
  regionsName?: (string | undefined)[];
  allowHover?: boolean;
  margin?: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
};

interface RectangularMapPropsWithAreas extends RectangularMapProps {
  area?: PreviewRegion;
  areas: NonEmptyArray<PreviewRegion>;
}

const RectangularMap = ({
  regionsName,
  vesselType,
  fill,
  allowHover = false,
  onRegionClick,
  strokeColor,
  area,
  areas,
  yMax: topHeight,
  width: outerWidth,
  height: outerHeight,
  showAxis = true,
  margin = svgMargin,
}: RectangularMapPropsWithAreas) => {
  // in svg, margin is subtracted from total width/height
  const width = outerWidth - margin.right;
  const height = outerHeight - margin.top - margin.bottom;
  const [hoveredRegion, setHoveredRegion] = React.useState<number | undefined>(undefined);

  const isWearExplorerRH = vesselType === VesselType.Rh;

  const tickLabelProps = useMemo(
    () =>
      ({
        fill: tickLabelColor,
        fontSize: isWearExplorerRH ? settings.typography.FontSize.XX_Small : settings.typography.FontSize.XX_Small,
        fontFamily: settings.typography.FontFamily.Regular,
        textAnchor: 'middle',
      } as const),
    [isWearExplorerRH]
  );
  /**
   * Gets the maximum Y if one is not given.
   */
  const yMax = React.useMemo(() => {
    let max = isDefined(topHeight) ? topHeight : 0;

    if (!isDefined(topHeight)) {
      areas.forEach((item) => {
        max = d3max([item[0].y, item[1].y, max])!;
      });

      if (isDefined(area)) {
        max = d3max([area[0].y, area[1].y, max])!;
      }
    }
    return max;
  }, [areas, topHeight, area]);

  const yMin = React.useMemo(() => {
    let minHeight = yMax;

    if (!isDefined(topHeight)) {
      areas.forEach((item) => {
        minHeight = min([item[0].y, item[1].y, minHeight])!;
      });
      if (isDefined(area)) {
        minHeight = min([area[0].y, area[1].y, minHeight])!;
      }
    }
    return minHeight;
  }, [areas, yMax, topHeight, area]);

  /**
   * Scale for the right group.
   */
  const rightscale = scaleLinear({
    domain: [0, 180],
    range: [0, width / 2],
  });

  /**
   * Scale for the left group.
   */
  const leftscale = scaleLinear({
    domain: [180, 360],
    range: [0, width / 2],
  });

  /**
   * Scale for the right group.
   */
  const RHrightscale = scaleLinear({
    domain: [1, 7],
    range: [0, width / 2],
  });

  /**
   * Scale for the left group.
   */
  const RHleftscale = scaleLinear({
    domain: [7, 13],
    range: [0, width / 2],
  });

  const strokeWidth = isWearExplorerRH ? 0.5 : 2;

  /**
   * Scale for the Y axis.
   */
  const yScale = useMemo(
    () =>
      scaleLinear({
        range: [height, 0],
        domain: [yMax, isWearExplorerRH ? yMin : 0],
      }),
    [height, yMax, isWearExplorerRH, yMin]
  );

  const getScaleY = React.useCallback(
    (point: Point) => {
      return yMax > point.y ? yScale(point.y) : yScale(yMax);
    },
    [yMax, yScale]
  );

  const getYTickValues = React.useCallback(() => {
    const tickValues = [];
    const step: number = yMax % 2 === 1 ? 1 : 2;
    let value = 0;

    while (value < yMax) {
      tickValues.push(value);
      value = value + step;
    }

    tickValues.push(yMax);
    if (tickValues.length > 8) {
      return tickValues.filter(function (_, index) {
        return index % 4 === 0;
      });
    }
    return tickValues;
  }, [yMax]);

  const drawRightRegions = React.useCallback(
    (areamap: NonEmptyArray<Point>, color: string, index: number, fill?: string, className?: string) => {
      const hoverHandlers = allowHover
        ? {
            onMouseOver: () => setHoveredRegion(index),
            onMouseLeave: () => setHoveredRegion(undefined),
          }
        : {};

      return (
        <Tooltip title={isDefined(regionsName) ? regionsName[index] : undefined} placement="top">
          <rect
            {...hoverHandlers}
            onClick={() => (isDefined(onRegionClick) ? onRegionClick(index) : undefined)}
            className={`region-area ${className}`}
            x={rightscale(areamap[1]!.x)}
            y={yScale(ensure(min([areamap[0].y, areamap[1]!.y])))}
            width={rightscale(areamap[0].x) - rightscale(areamap[1]!.x)}
            height={Math.abs(getScaleY(areamap[0]) - getScaleY(areamap[1]!))}
            fill={isDefined(hoveredRegion) && hoveredRegion === index ? settings.colors.Primary.Blue_6 : fill ?? 'transparent'}
            stroke={color}
            strokeWidth={strokeWidth}
          />
        </Tooltip>
      );
    },
    [yScale, getScaleY, rightscale, strokeWidth, setHoveredRegion, hoveredRegion, onRegionClick, regionsName, allowHover]
  );

  const drawLeftRegions = React.useCallback(
    (areamap: NonEmptyArray<Point>, color: string, index: number, fill?: string, className?: string) => {
      const hoverHandlers = allowHover
        ? {
            onMouseOver: () => setHoveredRegion(index),
            onMouseLeave: () => setHoveredRegion(undefined),
          }
        : {};

      return (
        <Tooltip title={isDefined(regionsName) ? regionsName[index] : undefined} placement="top">
          <rect
            {...hoverHandlers}
            onClick={() => (isDefined(onRegionClick) ? onRegionClick(index) : undefined)}
            className={`region-area ${className}`}
            x={leftscale(areamap[1]!.x)}
            y={yScale(ensure(min([areamap[0].y, areamap[1]!.y])))}
            width={leftscale(areamap[0].x) - leftscale(areamap[1]!.x)}
            height={Math.abs(getScaleY(areamap[0]) - getScaleY(areamap[1]!))}
            fill={isDefined(hoveredRegion) && hoveredRegion === index ? settings.colors.Primary.Blue_6 : fill ?? 'transparent'}
            stroke={color}
            strokeWidth={strokeWidth}
          />
        </Tooltip>
      );
    },
    [yScale, getScaleY, leftscale, strokeWidth, hoveredRegion, setHoveredRegion, onRegionClick, regionsName, allowHover]
  );

  const drawRightCurrentArea = React.useCallback(() => {
    // We need to draw it in mirror, meaning an additional step of 180 will have to be used.
    const mirrorStep = 180;
    if (isDefined(area)) {
      if (area[0].x <= 180 && area[1].x <= 180 && area[0].x > area[1].x) {
        return drawRightRegions(
          [
            { x: mirrorStep + area[0].x, y: area[0].y },
            { x: area[1].x + mirrorStep, y: area[1].y },
          ],
          settings.colors.Primary.Blue_9,
          -1,
          fill,
          'current'
        );
      } else if (area[0].x <= 180 && area[1].x <= 180 && area[0].x < area[1].x) {
        return drawRightRegions(
          [
            { x: mirrorStep + 180, y: area[0].y },
            { x: area[1].x + mirrorStep, y: area[1].y },
          ],
          settings.colors.Primary.Blue_9,
          -1,
          fill,
          'current'
        );
      } else if (area[0].x > 180 && area[1].x <= 180 && area[0].x - area[1].x !== 360) {
        return drawRightRegions(
          [
            { x: mirrorStep + 180, y: area[0].y },
            { x: area[1].x + mirrorStep, y: area[1].y },
          ],
          settings.colors.Primary.Blue_9,
          -1,
          fill,
          'current'
        );
      }
    }
    return null;
  }, [area, drawRightRegions, fill]);

  const drawLeftCurrentArea = React.useCallback(() => {
    if (isDefined(area)) {
      // For the interval [180 - 360]
      if (area[0].x > 180 && area[1].x > 180 && area[0].x > area[1].x) {
        return drawLeftRegions(area, settings.colors.Primary.Blue_9, -1, fill, 'current');
      } else if (area[0].x > 180 && area[1].x > 180 && area[0].x < area[1].x) {
        return (
          <>
            {drawLeftRegions(
              [
                { x: 360 + 180, y: area[0].y },
                { x: area[1].x, y: area[1].y },
              ],
              strokeColor ?? settings.colors.Primary.Blue_9,
              -1,
              fill,
              'current'
            )}
            {drawLeftRegions([area[0], { x: 180, y: area[1].y }], settings.colors.Primary.Blue_9, -1, fill, 'current')}
          </>
        );
      } else if (area[0].x - area[1].x === 360) {
        // For the interval [360 - 0]
        return drawLeftRegions(
          [
            { x: 360 + 180, y: area[0].y },
            { x: 180, y: area[1].y },
          ],
          strokeColor ?? settings.colors.Primary.Blue_9,
          -1,
          fill,
          'current'
        );
      } else if (area[0].x > 180 && area[1].x <= 180) {
        return drawLeftRegions(
          [
            { x: area[0].x, y: area[0].y },
            { x: 180, y: area[1].y },
          ],
          strokeColor ?? settings.colors.Primary.Blue_9,
          -1,
          fill,
          'current'
        );
      } else if (area[0].x < area[1].x && area[1].x >= 180) {
        return drawLeftRegions([{ x: 360 + area[0].x, y: area[0].y }, area[1]], settings.colors.Primary.Blue_9, -1, fill, 'current');
      } else if (area[0].x < area[1].x && area[1].x < 180) {
        return drawLeftRegions(
          [
            { x: 360 + area[0].x, y: area[0].y },
            { x: 180, y: area[1].y },
          ],
          strokeColor ?? settings.colors.Primary.Blue_9,
          -1,
          fill,
          'current'
        );
      }
    }
    return null;
  }, [area, drawLeftRegions, strokeColor, fill]);

  const rightRegions = React.useCallback(() => {
    return areas.map((areaItem, index) => {
      if (areaItem[0].x <= 180 && areaItem[1].x <= 180 && areaItem[0].x > areaItem[1].x) {
        return drawRightRegions(areaItem, strokeColor ?? settings.colors.Primary.Grey_3, index);
      } else if (areaItem[0].x <= 180 && areaItem[1].x <= 180 && areaItem[0].x < areaItem[1].x) {
        return drawRightRegions(
          [
            { x: 180, y: areaItem[0].y },
            { x: areaItem[1].x, y: areaItem[1].y },
          ],
          strokeColor ?? settings.colors.Primary.Grey_3,
          index,
          undefined,
          'current'
        );
      } else if (areaItem[0].x > 180 && areaItem[1].x <= 180 && areaItem[0].x - areaItem[1].x !== 360) {
        return drawRightRegions([{ x: 180, y: areaItem[0].y }, areaItem[1]], strokeColor ?? settings.colors.Primary.Grey_3, index);
      }
      return null;
    });
  }, [drawRightRegions, areas, strokeColor]);

  const leftRegions = React.useCallback(() => {
    return areas.map((areaItem, index) => {
      if (areaItem[0].x > 180 && areaItem[1].x > 180 && areaItem[0].x > areaItem[1].x) {
        return drawLeftRegions(areaItem, strokeColor ?? settings.colors.Primary.Grey_3, index);
      } else if (areaItem[0].x > 180 && areaItem[1].x > 180 && areaItem[0].x < areaItem[1].x) {
        return (
          <>
            {drawLeftRegions(
              [
                { x: 360 + 180, y: areaItem[0].y },
                { x: areaItem[1].x, y: areaItem[1].y },
              ],
              strokeColor ?? settings.colors.Primary.Grey_3,
              index
            )}
            {drawLeftRegions([areaItem[0], { x: 180, y: areaItem[1].y }], strokeColor ?? settings.colors.Primary.Grey_3, index)}
          </>
        );
      } else if (areaItem[0].x - areaItem[1].x === 360) {
        return drawLeftRegions(
          [
            { x: 360 + 180, y: areaItem[0].y },
            { x: 180, y: areaItem[1].y },
          ],
          strokeColor ?? settings.colors.Primary.Grey_3,
          index
        );
      } else if (areaItem[0].x > 180 && areaItem[1].x <= 180) {
        return drawLeftRegions(
          [
            { x: areaItem[0].x, y: areaItem[0].y },
            { x: 180, y: areaItem[1].y },
          ],
          strokeColor ?? settings.colors.Primary.Grey_3,
          index
        );
      } else if (areaItem[0].x < areaItem[1].x && areaItem[1].x >= 180) {
        return drawLeftRegions(
          [
            { x: 360 + areaItem[0].x, y: areaItem[0].y },
            { x: areaItem[1].x, y: areaItem[1].y },
          ],
          strokeColor ?? settings.colors.Primary.Grey_3,
          index
        );
      } else if (areaItem[0].x < areaItem[1].x && areaItem[1].x < 180) {
        return drawLeftRegions(
          [
            { x: 360 + areaItem[0].x, y: areaItem[0].y },
            { x: 180, y: areaItem[1].y },
          ],
          strokeColor ?? settings.colors.Primary.Grey_3,
          index,
          fill,
          'current'
        );
      }
      return null;
    });
  }, [areas, drawLeftRegions, strokeColor, fill]);

  const stoke = strokeColor ?? settings.colors.Primary.Grey_3;
  return (
    <ContainerPreview>
      {isWearExplorerRH && (
        <svg width={outerWidth + margin.right} height={22}>
          <Group transform={`translate(${width / 2}, 0)`}>
            <Axis
              // force remount when this changes to see the animation difference
              key="axis"
              orientation={Orientation.bottom}
              top={0}
              left={0}
              tickFormat={(v) => v.toString()}
              scale={RHrightscale}
              stroke="transparent"
              tickLabelProps={tickLabelProps}
              tickValues={[1, 2, 3, 4, 5, 6, 7]}
              numTicks={7}
            />
          </Group>
          <g>
            {showAxis && (
              <Axis
                // force remount when this changes to see the animation difference
                key="axis"
                top={0}
                left={1.5}
                tickFormat={(v) => v.toString()}
                scale={RHleftscale}
                stroke="transparent"
                tickLabelProps={tickLabelProps}
                tickValues={[7, 8, 9, 10, 11, 12]}
                numTicks={6}
              />
            )}
          </g>
        </svg>
      )}
      <svg width={outerWidth + margin.right} height={outerHeight + margin.top} preserveAspectRatio="xMinYMin meet">
        <rect
          x={margin.left - margin.right < 0 ? 0 : margin.left - margin.right}
          y={margin.top - margin.bottom < 0 ? 0 : margin.top - margin.bottom}
          width={width}
          height={height}
          fill={settings.colors.Primary.Grey_1}
          stroke={stoke}
          strokeWidth={isWearExplorerRH ? 0.5 : 1}
        />
        <g data-test-id={rightAxisLabelsLiningDetails}>
          {showAxis && !isWearExplorerRH && (
            <AxisRight
              // force remount when this changes to see the animation difference
              orientation={Orientation.right}
              left={outerWidth - 10}
              top={margin.top - margin.bottom}
              scale={yScale}
              tickFormat={(v) => Math.round(((v as number) + Number.EPSILON) * 100) / 100 + 'm'} // It correctly rounds the numbers with more than 1 decimals.
              stroke={axisColor}
              tickStroke={axisColor}
              tickLabelProps={() => ({
                fill: tickLabelColor,
                fontSize: settings.typography.FontSize.X_Small,
                textAnchor: 'middle',
                dy: 5,
                dx: 10,
              })}
              tickValues={getYTickValues()}
            />
          )}
        </g>
        <Group
          data-test-id={rightRegionMapSectionLiningDetails}
          transform={showAxis && !isWearExplorerRH ? 'translate(230, 20)' : `translate(${width / 2}, 0)`}
        >
          {showAxis && !isWearExplorerRH && (
            <Axis
              // force remount when this changes to see the animation difference
              key="axis"
              orientation={Orientation.bottom}
              top={height}
              left={0}
              scale={rightscale}
              tickFormat={(v) => v + '°'}
              stroke="transparent"
              tickLabelProps={tickLabelProps}
              tickValues={[0, 60, 120, 180]}
              numTicks={4}
            />
          )}
          {isWearExplorerRH &&
            [1, 2, 3, 4, 5, 6, 7].map((point, index) => {
              const x = RHrightscale(point) || 0;
              return (
                <g key={index}>
                  <Line
                    from={{ x, y: 0 }}
                    to={{ x, y: height }}
                    stroke={settings.colors.Primary.Grey_3}
                    strokeOpacity={0.8}
                    strokeWidth={0.5}
                    pointerEvents="none"
                  />
                </g>
              );
            })}
          {rightRegions()}
        </Group>

        <Group data-test-id={leftRegionMapSectionLiningDetails} transform={showAxis && !isWearExplorerRH ? `translate(20, 20)` : 'translate(0, 0)'}>
          <g>
            {showAxis && !isWearExplorerRH && (
              <Axis
                // force remount when this changes to see the animation difference
                key="axis"
                top={height}
                left={0}
                scale={leftscale}
                tickFormat={(v) => v + '°'}
                stroke="transparent"
                tickLabelProps={tickLabelProps}
                tickValues={[180, 240, 300]}
                numTicks={3}
              />
            )}
            {isWearExplorerRH &&
              [7, 8, 9, 10, 11, 12].map((point, index) => {
                const x = RHleftscale(point) || 0;
                return (
                  <g key={index}>
                    <Line
                      from={{ x, y: 0 }}
                      to={{ x, y: height }}
                      stroke={settings.colors.Primary.Grey_3}
                      strokeOpacity={0.8}
                      strokeWidth={0.5}
                      pointerEvents="none"
                    />
                  </g>
                );
              })}
            {leftRegions()}
            {/* We always want to have the current selected area draw the last so it will be the first displayed in case of overlapping with
        other regions */}
            {drawLeftCurrentArea()}
            {drawRightCurrentArea()}
          </g>
        </Group>
      </svg>
    </ContainerPreview>
  );
};

export default React.memo(RectangularMap);

const ContainerPreview = styled.div`
  display: inline-grid;

  .visx-axis-tick .visx-line {
    display: none;
  }
`;
