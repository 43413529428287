import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const FilterIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M15 1H1c-.404 0-.77.243-.924.617-.154.374-.07.804.217 1.09L6 8.414V14c0 .404.244.77.617.924.124.051.254.076.383.076.26 0 .516-.102.707-.293l2-2c.188-.188.293-.442.293-.707V8.414l5.707-5.707c.286-.286.372-.716.217-1.09C15.769 1.243 15.404 1 15 1z"
      />
    </svg>
  );
};

FilterIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
