export enum Spacing {
  Spacing_50 = '4px',
  Spacing_100 = '8px',
  Spacing_150 = '12px',
  Spacing_200 = '16px',
  Spacing_250 = '20px',
  Spacing_300 = '24px',
  Spacing_400 = '32px',
  Spacing_500 = '40px',
  Spacing_600 = '48px',
  Spacing_700 = '56px',
  Spacing_800 = '64px',
}
