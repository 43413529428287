import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const LadleInactiveIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <path
              d="M20.653 6.176l-.477 4.291c.179-.275.473-.466.824-.466.552 0 1 .447 1 1v2c0 .552-.448 1-1 1s-1-.448-1-1h-.105l-.901 8.11c-.057.506-.484.89-.994.89H6c-.318 0-.592-.16-.776-.396l1.664-1.665.007.061h10.21L18.39 8.439l2.263-2.263zm1.054-3.883c.391.391.391 1.023 0 1.414l-18 18C3.512 21.902 3.256 22 3 22c-.256 0-.512-.098-.707-.293-.391-.391-.391-1.023 0-1.414l18-18c.391-.391 1.023-.391 1.414 0zm-5.768 8.819l-.624 7.11H8.828l7.111-7.11zM3.89 2.007c.544-.065 1.043.334 1.104.884L6.323 14.85l-1.811 1.81-.407-3.659H4c0 .552-.448 1-1 1s-1-.448-1-1v-2c0-.553.448-1 1-1 .351 0 .645.191.824.466l-.818-7.356c-.061-.548.335-1.044.884-1.104zm4.554 1.216c1 0 1.88.211 2.897.457 1.195.288 2.548.614 4.214.614.33 0 .657-.01.985-.028l-.057.645-8.248 8.248-.879-9.887c.336-.03.7-.049 1.088-.049z"
              transform="translate(-166 -786) translate(166 786)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

LadleInactiveIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Grey_6,
};
