import { specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api/operatorDisplay';

type Payload = number[];

export const useOperatorDisplayCampaigns = (
  vesselId: UUID,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) => {
  return useQuery(getKey(vesselId), (context) => queryFn(context, options?.axiosConfiguration), options?.configuration);
};

const getKey = (vesselId: UUID) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'wearManagement',
      scope: 'campaigns-for-vessel-id',
      entity: 'list',
      vesselId,
    },
  ]);

const queryFn = ({ queryKey: [{ vesselId }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  return API.operatorDisplay.getOperatordataviewVesselidCampaign(vesselId, { signal, ...axiosConfiguration }).then((response) => response.data);
};

useOperatorDisplayCampaigns.getKey = getKey;
useOperatorDisplayCampaigns.queryFn = queryFn;
