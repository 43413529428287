import { settings } from '@rhim/design';
import { isDefined } from '@rhim/utils';
import { Tabs as AntdTabs, TabsProps } from 'antd';
import styled from 'styled-components';

import { Spinner } from '../Spinner';

interface TabsLoadingIndicatorProps {
  className?: string;
  height?: number;
}

const TabsLoadingIndicator: React.ChildlessComponent<TabsLoadingIndicatorProps> = (props) => {
  const { className, height = 400 } = props;

  return (
    <SpinnerWrapper className={className} height={height}>
      <Spinner size="64" />
    </SpinnerWrapper>
  );
};

interface Props extends TabsProps {}

export const Tabs: React.FunctionComponent<React.PropsWithChildren<Props>> & { TabPane: typeof AntdTabs.TabPane } & {
  TabsLoadingIndicator: typeof TabsLoadingIndicator;
} = ({ children, ...props }) => {
  return <STabs {...props}>{children}</STabs>;
};

Tabs.TabPane = AntdTabs.TabPane;
Tabs.TabsLoadingIndicator = TabsLoadingIndicator;

const STabs = styled(AntdTabs)`
  .ant-tabs-nav::before {
    border-bottom: 1px solid ${settings.colors.Primary.Grey_3};
  }

  .ant-tabs-tab {
    color: ${settings.colors.Primary.Blue_9};
    font-size: ${settings.typography.FontSize.Medium};
    font-family: ${settings.typography.FontFamily.Regular};

    &.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${settings.colors.Primary.Blue_9};
      font-family: ${settings.typography.FontFamily.Bold};
    }

    &.ant-tabs-tab-disabled {
      color: ${settings.colors.Primary.Grey_4};
    }

    &:hover {
      color: ${settings.colors.Primary.Blue_8};
    }
  }

  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    height: 4px;
    background: ${settings.colors.Primary.Blue_9};
  }
`;

const SpinnerWrapper = styled.div<{ height?: number }>`
  ${(props) => isDefined(props.height) && `height: ${props.height}px`};
  display: flex;
  align-items: center;
  justify-content: center;
`;
