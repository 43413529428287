import { settings } from '@rhim/design';
import { AxisScale, Orientation, TicksRendererProps } from '@visx/axis';
import { Group } from '@visx/group';
import { Line } from '@visx/shape';
import { Text } from '@visx/text';
import cx from 'classnames';

import { FIRST_LAST_LABEL_OFFSET } from './constants';

type Props = {
  hasIndentation?: boolean;
};

/**
 *  Based on visx Ticks component:
 *  @see: https://github.com/airbnb/visx/blob/master/packages/visx-axis/src/axis/Ticks.tsx
 */
export default function Ticks<Scale extends AxisScale>({
  hideTicks = false,
  horizontal,
  hasIndentation = true,
  orientation,
  scale,
  tickClassName,
  tickComponent,
  tickLabelProps: allTickLabelProps,
  tickStroke,
  tickTransform,
  ticks,
  strokeWidth,
  tickLineProps,
}: TicksRendererProps<Scale> & Props) {
  return ticks.map(({ value, index, from, to, formattedValue }) => {
    const tickLabelProps = allTickLabelProps[index] ?? {};
    const tickLabelFontSize = Math.max(10, (typeof tickLabelProps.fontSize === 'number' && tickLabelProps.fontSize) || 0);

    const isFirst = index === 0;
    const isLast = index === ticks.length - 1;

    const indentationProps =
      horizontal && hasIndentation && ((isFirst && to.x === 0) || (isLast && to.x === scale.range()[1]))
        ? ({
            textAnchor: isFirst ? 'start' : isLast ? 'end' : 'middle',
            dx: isFirst ? FIRST_LAST_LABEL_OFFSET : isLast ? -FIRST_LAST_LABEL_OFFSET : 0,
          } as const)
        : {};

    const tickYCoord = to.y + (horizontal && orientation !== Orientation.top ? tickLabelFontSize : 0);

    const isMinorTick = formattedValue === '';
    const tickStrokeMajorMinor = isMinorTick ? settings.colors.Primary.Grey_4 : tickStroke;

    return (
      <Group key={`visx-tick-${value}-${index}`} className={cx('visx-axis-tick', tickClassName)} transform={tickTransform}>
        {!hideTicks && <Line from={from} to={to} stroke={tickStrokeMajorMinor} strokeWidth={strokeWidth} strokeLinecap="square" {...tickLineProps} />}
        {tickComponent ? (
          tickComponent({
            ...tickLabelProps,
            ...indentationProps,
            x: to.x,
            y: tickYCoord,
            formattedValue,
          })
        ) : (
          <Text x={to.x} y={tickYCoord} {...tickLabelProps} {...indentationProps}>
            {formattedValue}
          </Text>
        )}
      </Group>
    );
  });
}
