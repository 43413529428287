import { settings } from '@rhim/design';
import styled from 'styled-components';

import { Invariants } from '../../errors';
import ErrorPanel from '../ErrorPanel/ErrorPanel';

export const Container = styled.div`
  padding: ${settings.Defaults.Outer_Container_Margin};
  max-width: 1500px;
  margin: 0 auto;
  align-self: center;
  width: 100%;
`;

export const Body = styled.div`
  margin-top: ${settings.Spacing.Spacing_200};
  padding: ${settings.Spacing.Spacing_500} ${settings.Spacing.Spacing_600};
  background-color: ${settings.colors.Monochromatic.White};
  border-radius: 3px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${settings.colors.Primary.Grey_3};
  overflow: auto;
`;

export const ErrorNoVesselsFound: React.ChildlessComponent = () => {
  return <SErrorPanel error={new Error(Invariants.NoVesselsFound)} />;
};

const SErrorPanel = styled(ErrorPanel)`
  margin-top: 0;
  margin-bottom: -${settings.Spacing.Spacing_500};
`;
