import { settings } from '@rhim/design';
import { Button, SUPPORTED_VESSEL_TYPES } from '@rhim/react';
import { VesselType } from '@rhim/rest';
import { assert, hasElements, isDefined } from '@rhim/utils';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';

import AppContext from '../../app/AppContext';
import { useVesselLabel } from '../../lib';
import { ErrorNoVesselsFound } from './utils';

const logger = log.child('Data Center');

interface Props {
  className?: string;
  vesselTypes: Set<VesselType>;
  onVesselClicked: (vesselType: VesselType) => void;
  noVesselsFoundNotification?: React.ReactElement;
  dataTestId?: string;
}
const VesselTypeSelection: React.ChildlessComponent<Props> = ({
  className,
  vesselTypes,
  onVesselClicked,
  noVesselsFoundNotification = <ErrorNoVesselsFound />,
  dataTestId,
}) => {
  const { selectedCustomer: customer } = useContext(AppContext);
  const getVesselLabel = useVesselLabel();

  const vesselTypeButtons = useMemo(() => {
    const buttons: React.ReactElement[] = [];
    assert(isDefined(customer.vesselTypes), 'VesselTypes unavailable');

    for (const vesselType of vesselTypes) {
      if (!isDefined(customer.vesselTypes.find((customerVesselType) => customerVesselType.toUpperCase() === vesselType.toUpperCase()))) {
        // this customer has no vessels of this particular vessel type
        continue;
      }
      const supportedVesselTypeItem = SUPPORTED_VESSEL_TYPES[vesselType];
      if (!isDefined(supportedVesselTypeItem)) {
        logger.warn(`No UI configuration found for vessel type : ${vesselType}`);
      } else {
        buttons.push(
          <Button
            key={vesselType}
            data-test-id={supportedVesselTypeItem.dataTestId}
            label={getVesselLabel(vesselType)}
            icon={{ icon: supportedVesselTypeItem.getIcon(), position: 'start' }}
            size="big-56"
            mode="ghost"
            onClick={() => onVesselClicked(vesselType)}
          />
        );
      }
    }
    return buttons;
  }, [customer.vesselTypes, vesselTypes, getVesselLabel, onVesselClicked]);

  if (!hasElements(vesselTypeButtons)) {
    return noVesselsFoundNotification;
  }

  return (
    <SWrapper data-test-id={dataTestId} className={className}>
      {vesselTypeButtons}
    </SWrapper>
  );
};

export default React.memo(VesselTypeSelection);

const SWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${settings.Spacing.Spacing_200};
`;
