/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ArcRotateCamera, Scene } from '@babylonjs/core';
import { RHIMAPOWearManagementApiClientFunctionalProductType } from '@rhim/rest/measurementService';
import { isDefined } from '@rhim/utils';

/**
 * Checks whether the ArcRotateCamera in question is animating either due to inertia (#1) or
 * due to any animatables set on it which are currently in motion (#2).
 *
 * (#1) An ArcRotateCamera has some inertia by default and once the mouse keys have been released it
 * may animate its rotation/position/zoom and gradually slow down before coming to a halt.
 *
 * (#2) For instance, whenever we click the "Home" button in the 3d view,
 * we set a number of animatables on the camera that animate its rotation/target/zoom to their default values.
 * ( see function setCameraView in Viz3dWithControls )
 *
 * @param camera the scene's active ArcRotateCamera
 * @returns true if the camera's animation has completed, false if still animating
 */
export function haveAnimationsStopped(scene: Scene) {
  const cameras = (isDefined(scene.activeCameras) && scene.activeCameras.length > 0 ? scene.activeCameras : [scene.activeCamera]) as ArcRotateCamera[];

  return (
    scene.animatables.length === 0 &&
    cameras.every(
      (camera) =>
        camera.inertialAlphaOffset === 0 &&
        camera.inertialBetaOffset === 0 &&
        camera.inertialRadiusOffset === 0 &&
        camera.inertialPanningX === 0 &&
        camera.inertialPanningY === 0
    )
  );
}

export const isFunctionalProductOnTheSideOfVessel = (type: RHIMAPOWearManagementApiClientFunctionalProductType) =>
  type === RHIMAPOWearManagementApiClientFunctionalProductType.Taphole || type === RHIMAPOWearManagementApiClientFunctionalProductType.Trunnion;
