import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const CloudUpIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M17.96 9.097C17.755 7.356 16.293 6 14.5 6c-.621 0-1.195.176-1.701.459-.822-.891-1.99-1.46-3.298-1.46C7.014 5 5 7.016 5 9.5c0 .259.034.508.074.753C3.292 10.85 2 12.516 2 14.5 2 16.986 4.014 19 6.5 19H11v-4.586l-1.293 1.293c-.39.39-1.024.39-1.415 0-.194-.195-.291-.451-.291-.707 0-.256.097-.511.292-.707l3-3c.39-.39 1.024-.39 1.415 0l3 3c.39.39.39 1.024 0 1.414-.392.39-1.024.39-1.415 0L13 14.414V19h4c2.76 0 5-2.238 5-5 0-2.432-1.74-4.455-4.042-4.903"
      />
    </svg>
  );
};

CloudUpIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
