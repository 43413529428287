import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const BulletinIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 64 64" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        d="M61.714 16H50.286V2.286A2.286 2.286 0 0048 0H2.286A2.286 2.286 0 000 2.286v52.57A9.143 9.143 0 009.143 64h45.714A9.143 9.143 0 0064 54.857V18.286A2.286 2.286 0 0061.714 16zm-43.428-4.572H32A2.286 2.286 0 1132 16H18.286a2.286 2.286 0 110-4.572zm20.57 43.429H11.429a2.286 2.286 0 110-4.572h27.429a2.286 2.286 0 110 4.572zm0-9.143H11.429a2.286 2.286 0 110-4.571h27.429a2.286 2.286 0 110 4.571zm0-9.143H11.429a2.286 2.286 0 110-4.571h27.429a2.286 2.286 0 110 4.571zm0-9.143H11.429a2.286 2.286 0 110-4.571h27.429a2.286 2.286 0 110 4.571zm20.572 27.429a4.571 4.571 0 01-9.142 0V20.57h9.142v34.286z"
        fillRule="nonzero"
        fill={fill}
      ></path>
    </svg>
  );
};

BulletinIcon.defaultProps = {
  size: '64',
  fill: '#fff',
};
