import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
}

export const PauseIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6 1H3v14h3V1zm7 0h-3v14h3V1z" fill={fill} />
    </svg>
  );
};

PauseIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
