import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  backgroundColor?: Property.Color;
  foregroundColor?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const HexagonMeasurementIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  backgroundColor,
  foregroundColor,
  size,
  title,
  role,
  ...rest
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <path
          fill={backgroundColor}
          d="M27.03 24.697c.533-.31.97-1.074.97-1.695V8.952c0-.62-.437-1.384-.97-1.694L14.97.233c-.533-.31-1.407-.31-1.94 0L.97 7.258C.437 7.568 0 8.331 0 8.953v14.049c0 .621.437 1.384.97 1.695l12.06 7.024c.533.31 1.407.31 1.94 0l12.06-7.024z"
          transform="translate(2)"
        />
        <path
          fill={foregroundColor}
          d="M12 8c1.103 0 2 .897 2 2v12c0 1.103-.897 2-2 2H8c-1.103 0-2-.897-2-2V10c0-1.103.897-2 2-2zm6.293.293c.391-.391 1.023-.391 1.414 0l2 2c.391.391.391 1.023 0 1.414-.195.195-.451.293-.707.293-.256 0-.512-.098-.707-.293L20 11.414v9.172l.293-.293c.391-.391 1.023-.391 1.414 0s.391 1.023 0 1.414l-2 2c-.195.195-.451.293-.707.293-.256 0-.512-.098-.707-.293l-2-2c-.391-.391-.391-1.023 0-1.414s1.023-.391 1.414 0l.293.293v-9.172l-.293.293c-.391.391-1.023.391-1.414 0s-.391-1.023 0-1.414zM12 10H8v12h4.001v-1H11c-.552 0-1-.447-1-1 0-.553.448-1 1-1h1.001v-2H11c-.552 0-1-.447-1-1 0-.553.448-1 1-1h1v-2h-1c-.552 0-1-.447-1-1 0-.553.448-1 1-1h1v-1z"
          transform="translate(2)"
        />
      </g>
    </svg>
  );
};

HexagonMeasurementIcon.defaultProps = {
  size: '32',
  backgroundColor: settings.colors.Primary.Blue_7,
  foregroundColor: settings.colors.Primary.Blue_1,
};
