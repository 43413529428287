import { useMemoCompare } from '@rhim/react';
import { RHIMCementServiceV1ModelsScanOverviewDto } from '@rhim/rest/cement';
import { hasElements, head, isDefined } from '@rhim/utils';
import { join } from 'lodash';
import React from 'react';
import { useLocation } from 'react-router-dom';
import useSessionStorageState from 'use-session-storage-state';

import { PARAMS, STORAGE_KEYS } from '../utilities';
import { LOCAL_STORAGE_PREFIX_APO } from '../utilities/storage';

const logger = log.child('useSelectedScan');

function useSelectedScanId() {
  return useSessionStorageState<string | null>(join([LOCAL_STORAGE_PREFIX_APO, STORAGE_KEYS.SELECTED_SCAN_ID], ''), {
    defaultValue: null,
  });
}

export function useSelectedScan(scans?: RHIMCementServiceV1ModelsScanOverviewDto[]): [string | null, React.Dispatch<React.SetStateAction<string | null>>] {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const queryScanId = query.get(PARAMS.SCAN_ID);
  const [persistedScanId, setPersistedScanId] = useSelectedScanId();

  logger.debug(`Launched scan id persistence. Initial value: ${persistedScanId}`);

  React.useDebugValue(persistedScanId);

  React.useEffect(() => {
    if (!isDefined(scans)) {
      logger.debug('⛔️ No scans available. Removing the persisted scan id from local storage.');

      setPersistedScanId(null);
      return;
    }

    if (isDefined(queryScanId) && scans.some((scan) => scan.id === queryScanId)) {
      setPersistedScanId(queryScanId as UUID);
      return;
    }

    const persistedScan = scans.find((scan) => scan.id === persistedScanId);
    const isPersistedScanIdValid = isDefined(persistedScan);

    if (isPersistedScanIdValid) {
      logger.debug(
        `✅ Persisted scan id DOES belong to one of the availabe scans. Returning the scan id found in local storage: ${persistedScan.id}. All scans:`,
        scans.map((scan) => scan.id)
      );

      return;
    } else if (hasElements(scans)) {
      const firstAvailableScan = head(scans);

      logger.debug(
        `⚠️ Persisted scan id DOES NOT belong to one of the available scans. Returning the first scan id available : ${firstAvailableScan.id}. All scans:`,
        scans.map((scan) => scan.id)
      );

      setPersistedScanId(firstAvailableScan.id);
    } else {
      logger.debug('⛔️ No scans available. Removing the persisted scans id from local storage.');

      setPersistedScanId(null);
    }
  }, [persistedScanId, setPersistedScanId, scans, queryScanId]);

  const memoizedValue: [string | null, React.Dispatch<React.SetStateAction<string | null>>] = useMemoCompare(
    [persistedScanId, setPersistedScanId],
    ([previousId], [nextId]) => {
      return previousId === nextId;
    }
  );

  return memoizedValue;
}
