import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { TreatmentsIcon } from '@rhim/icons/16';
import { CalendarIcon, CampaignIcon, LastHeatFireIcon } from '@rhim/icons/24';
import { getFormattedDate, getFormattedDateWithOffset, IconLabel, Switch } from '@rhim/react';
import { VesselType } from '@rhim/rest';
import { RHIMAPOReportingWearManagementApiV1ModelsCampaignOverviewDto } from '@rhim/rest/wearManagement';
import {
  compareToggleOperatorDisplay,
  dateTimeLabelMeasurementStrip,
  heatNumberLabelMeasurementStrip,
  measurementStripContainerWearExplorer,
} from '@rhim/test-ids';
import { assert, isDefined } from '@rhim/utils';
import * as React from 'react';
import styled from 'styled-components';

import MicroArrowIcon from '../../../../../assets/icons-16/strip-arrow-micro-16.svg?url';
import { useCustomerDetails, useCustomerId } from '../../hooks';
import CampaignSelection, { ColorTheme } from '../CampaignSelectionDropdown/CampaignSelection';
import MeasurementIcon from '../MeasurementIcon/rhim-measurement-icon';
import VesselSelection from '../VesselSelectionDropdown/VesselSelection';

interface Props {
  heat: number;
  campaign?: number | null;
  date?: Date.UTC;
  vessels?: APO.VesselV2[];
  selectedVesselId: UUID | null | undefined;
  selectVessel: (value: UUID) => void;
  disabled?: boolean;
  campaigns?: RHIMAPOReportingWearManagementApiV1ModelsCampaignOverviewDto[];
  vesselType?: VesselType;
  isTableView?: boolean;
  selectCampaign?: (first: number, second?: number) => void;
  comparedCampaign?: number;
  toggleCompareMode: (value: boolean) => void;
  compareMode: boolean;
}

const MeasurementSegment: React.FunctionComponent<Props> = ({
  isTableView = false,
  vessels,
  disabled = false,
  selectedVesselId,
  selectVessel,
  campaign,
  selectCampaign,
  comparedCampaign,
  heat,
  date,
  vesselType,
  campaigns,
  toggleCompareMode,
  compareMode,
}) => {
  const data = isDefined(date) ? getFormattedDate({ datetime: date, showTime: true }) : '';
  const { t } = i18nReact.useTranslation(['wear', 'operator-display']);
  const isLoadingVessels = !isDefined(vessels);
  assert(isDefined(vessels), 'Vessels undefined');

  const translationVesselType = React.useMemo(() => {
    return (vesselType ?? VesselType.Bof) as unknown as APO.PredictionsKnowParameterName;
  }, [vesselType]);

  const selectedVesselType: VesselType | undefined = React.useMemo(() => {
    return vessels.find((vessel) => vessel.id === selectedVesselId)?.vesselType;
  }, [selectedVesselId, vessels]);

  const filteredVessels = React.useMemo(() => {
    return vessels.filter((vessel) => vessel.vesselType === selectedVesselType);
  }, [selectedVesselType, vessels]);

  const { customerId } = useCustomerId();
  const { data: customerDetails } = useCustomerDetails(customerId as UUID);

  return (
    <Container data-test-id={measurementStripContainerWearExplorer}>
      <HexagonIconContainer>
        <MeasurementIcon background={settings.colors.Primary.Blue_7} foreground={settings.colors.Primary.Grey_2} />
      </HexagonIconContainer>
      <Expander color={settings.colors.Primary.Grey_7} />
      <VesselSelection vessels={filteredVessels} isLoading={isLoadingVessels} selectedVesselId={selectedVesselId} onVesselSelected={selectVessel} />
      {isDefined(campaigns) && (
        <SCampaignSelection
          colorTheme={ColorTheme.Dark}
          isLoading={false}
          campaigns={campaigns.filter((item) => item.id)}
          selectedCampaignId={campaign}
          onCampaignSelected={(campaignNr: number | null) => {
            if (isDefined(selectCampaign) && isDefined(campaignNr)) {
              selectCampaign(campaignNr, comparedCampaign);
            }
          }}
        />
      )}
      {!disabled && !isTableView && (
        <StyledDiv>
          {!isDefined(campaigns) && (
            <>
              <VerticalDivider color={settings.colors.Primary.Grey_10} />
              <StyledIconLabel
                id="campaignNumber"
                label={campaign ?? '-'}
                color={settings.colors.Primary.Grey_2}
                tooltip={t(`wear:measurements.${translationVesselType as unknown as APO.MeasurementsKnowParameterName}.strip.campaignNumber`)}
                icon={<CampaignIcon fill={settings.colors.Primary.Grey_2} />}
              />
            </>
          )}
          <StyledIconLabel
            dataTestId={heatNumberLabelMeasurementStrip}
            label={heat || '-'}
            id="lastMeasurementHeat"
            color={settings.colors.Primary.Grey_2}
            tooltip={t(`wear:measurements.${translationVesselType as unknown as APO.MeasurementsKnowParameterName}.strip.lastMeasurementAtHeat`)}
            icon={
              vesselType === VesselType.Rh ? (
                <TreatmentsIcon fill={settings.colors.Primary.Grey_2} />
              ) : (
                <LastHeatFireIcon fill={settings.colors.Primary.Grey_2} />
              )
            }
          />
          <StyledIconLabel
            dataTestId={dateTimeLabelMeasurementStrip}
            label={data || '-'}
            color={settings.colors.Primary.Grey_2}
            id="dateOfLastMeasurement"
            tooltip={
              isDefined(customerDetails) && isDefined(customerDetails.timeZoneName) && isDefined(date)
                ? getFormattedDateWithOffset(date as Date.ISO_8601, customerDetails.timeZoneName)
                : t(`wear:measurements.${translationVesselType as unknown as APO.MeasurementsKnowParameterName}.strip.dateOfLastMeasurement`)
            }
            icon={<CalendarIcon fill={settings.colors.Primary.Grey_2} />}
          />
        </StyledDiv>
      )}
      {isDefined(campaigns) && (
        <>
          <IconContainer>
            <Switch
              data-test-id={compareToggleOperatorDisplay}
              toggleBefore={false}
              disabled={isTableView}
              onChange={toggleCompareMode}
              checked={compareMode}
              label={t('operator-display:header.compare')}
              size="small"
              mode="dark"
            />
          </IconContainer>
          <VerticalDivider color={settings.colors.Primary.Grey_7} margin={0} />
          {compareMode && (
            <ComparedCampaignSelection
              colorTheme={ColorTheme.Medium}
              isLoading={false}
              campaigns={campaigns}
              selectedCampaignId={comparedCampaign}
              onCampaignSelected={(comparedCampaignNr: number | null) => {
                if (isDefined(selectCampaign) && isDefined(comparedCampaignNr)) {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  selectCampaign(campaign!, comparedCampaignNr);
                }
              }}
            />
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  background: ${settings.colors.Primary.Grey_10};
  width: 48px;
  height: ${settings.Spacing.Spacing_600};
  width: fit-content; /* stylelint-disable -- It seems to work fine on Edge 16, 17, 18 */

  .vessel-selection {
    .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding-left: 0;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
      padding-right: 24px;
    }
    .ant-select-arrow svg {
      margin-top: 5px;
    }
  }
`;

const StyledDiv = styled.div`
  display: inline-flex;
  margin-left: ${settings.Spacing.Spacing_200};
  justify-content: center;
  align-items: center;
`;

const StyledIconLabel = styled(IconLabel)`
  margin-right: ${settings.Spacing.Spacing_200};
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 19px;
  background-color: ${settings.colors.Primary.Grey_9};
`;

const SCampaignSelection = styled(CampaignSelection)`
  grid-area: campaignSelectionDropdownFrom;
  color: ${settings.colors.Primary.Grey_10};
`;

const ComparedCampaignSelection = styled(SCampaignSelection)`
  background-color: ${settings.colors.Primary.Grey_9};
`;

const HexagonIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  margin-right: 0;
`;

const VerticalDivider = styled.div<{ color: string; margin?: number }>`
  display: inline-block;
  margin-right: ${(props) => (isDefined(props.margin) ? props.margin : settings.Spacing.Spacing_200)};
  width: 0;
  border: 0;
  border-right-width: 0.5px;
  border-style: solid;
  border-color: ${(props) => props.color};
`;

const Expander = styled(VerticalDivider)`
  margin-right: 0;
  &::after {
    content: url(${MicroArrowIcon});
    display: block;
    height: 16px;
    width: 16px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
`;

export default React.memo(MeasurementSegment);
