import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { OpsStateCriticalInvertedIcon } from '@rhim/icons/24';
import { criticalAreaContainerOperatorDisplay } from '@rhim/test-ids';
import * as React from 'react';
import styled from 'styled-components';

interface Props {
  children?: React.ReactNode;
  compact: boolean;
  height?: number;
  width?: number;
  title?: string;
  backgroundColor?: string;
  centered?: boolean;
  color?: string;
  icon?: React.ReactNode;
  containerStyle?: React.CSSProperties;
  iconBackgroundColor?: string;
}
const CriticalBox = ({ children, compact, centered = false, height, width, title, backgroundColor, color, icon, containerStyle }: Props) => {
  const { t } = i18nReact.useTranslation(['rhim-critical-box']);

  const Wrapper = centered ? Centerer : React.Fragment;

  return (
    <CriticalAreaContainer
      className="critical-area"
      compact={compact}
      backgroundColor={backgroundColor}
      style={containerStyle}
      data-test-id={criticalAreaContainerOperatorDisplay}
    >
      <CriticalIconContainer width={width ?? 36} height={height ?? 36}>
        {icon ?? <OpsStateCriticalInvertedIcon />}
      </CriticalIconContainer>
      <Wrapper>
        <Text className="critical-text" color={color ?? settings.colors.Primary.Grey_10} width={100} font={settings.typography.FontFamily.Bold}>
          {title ?? t('rhim-critical-box:criticalLining')}
        </Text>
      </Wrapper>
      {children}
    </CriticalAreaContainer>
  );
};

export const CriticalSmallBox = (props: { className?: string; icon: React.ReactNode; title?: string }) => {
  return (
    <Container className={props.className} data-test-id={criticalAreaContainerOperatorDisplay}>
      <IconContainer>{props.icon}</IconContainer>
      <Text className="critical-text" color={settings.colors.Primary.Grey_10} width={100} font={settings.typography.FontFamily.Bold}>
        {props.title}
      </Text>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${settings.colors.Operational.State_Alert_Red_1};
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  height: ${settings.Spacing.Spacing_400};
  margin: 0 auto;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${settings.Spacing.Spacing_50};
`;

const CriticalAreaContainer = styled.div<{ compact?: boolean; backgroundColor?: string }>`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 100%;
  background-color: ${(props) => props.backgroundColor ?? settings.colors.Monochromatic.White};
  border-color: ${(props) => (props.compact ?? false ? settings.colors.Primary.Grey_3 : settings.colors.Operational.State_Alert_Red_3)};
  border-width: 1px;
  border-style: ${(props) => (props.compact ?? false ? 'solid hidden solid solid' : 'solid')};
`;

const CriticalIconContainer = styled.div<{ width: number; height: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${settings.colors.Operational.State_Alert_Red_3};
  min-width: ${(props) => props.width}px;
  height: 100%;
`;

const Text = styled.span<{ color: string; font?: string; width?: number }>`
  color: ${(props) => props.color};
  font-size: ${settings.typography.FontSize.Small};
  font-family: ${(props) => props.font ?? settings.typography.FontFamily.Regular};
  margin: 0 12px;
`;

const Centerer = styled.div`
  text-align: center;
  width: 100%;
`;

export default React.memo(CriticalBox);
