import styled from 'styled-components';

export const DivWithSpinnerWhenEmpty = styled.div`
  &:empty {
    position: absolute;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 6em;
    height: 6em;
    border: 0.3em solid #1dc1e1;
    border-left: 0.3em solid #fff;
    border-radius: 50%;
    animation: load8 1.1s infinite linear;
  }

  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

export const RelativeDivWithSpinnerWhenEmpty = styled.div`
  &:empty {
    position: relative;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 3em;
    height: 3em;
    border: 0.3em solid #1dc1e1;
    border-left: 0.3em solid #fff;
    border-radius: 50%;
    animation: load8 1.1s infinite linear;
  }

  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
