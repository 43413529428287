import { Heading, Hyperlink, Legal } from '@rhim/react';
import { headerImprint } from '@rhim/test-ids';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

const LegalTabImprint = () => {
  return (
    <Trans i18nKey="static:legal.topics.imprint.content">
      <hgroup data-test-id={headerImprint}>
        <Heading level="h2">Disclosure pursuant to Sec. 25 of the Media Act and information pursuant to Sec. 5 of the E-Commerce Act (ECG)</Heading>
        <Heading level="h3">Media owner and publisher:</Heading>
      </hgroup>
      <SLegalParagraph as="address">
        RHI Magnesita GmbH
        <br />
        Kranichberggasse 6, A-1120 Vienna, Austria
        <br />
        Tel. <Hyperlink href="tel:+4350213-0" text="+43 502 13-0" />
        <br />
        {/* `tel:` is used for fax as well, see https://www.ietf.org/rfc/rfc3966.txt */}
        Fax <Hyperlink href="tel:+4350213-6213" text="+43 502 13-6213" />
        <br />
        E-mail: <Hyperlink href="mailto:headquarters@rhimagnesita.com" text="headquarters@rhimagnesita.com" />
      </SLegalParagraph>
      <Legal.Paragraph>
        Commercial register: Commercial Court of Vienna
        <br />
        Commercial register no: FN 442121 d
        <br />
        Domicile: Vienna
        <br />
        UID no: ATU72371523
      </Legal.Paragraph>
      <Legal.Paragraph>Chamber: RHI Magnesita GmbH and its Austrian subsidiaries are members of the Austrian economic chamber.</Legal.Paragraph>
      <Legal.Paragraph>
        Scope of corporate activities: The company’s scope of activities includes the acquisition of stakes in other enterprises and companies, in particular
        industrial enterprises, performance of the related management, representation, administration and consulting tasks (organisation, data processing,
        insurance, etc.), provision of transport services, and trade in goods of all kinds.
      </Legal.Paragraph>
      <Legal.Paragraph>
        Applicable legal regulations: In particular, the applicable legal regulations include the valid version of the Trade Regulation Act (GewO), which can be
        viewed at <Hyperlink rel="nofollow noopener" href="https://www.ris.bka.gv.at" text="https://www.ris.bka.gv.at" />.
      </Legal.Paragraph>
      <Legal.Paragraph>Responsible supervisory authority: Municipal district office for 1120 Vienna</Legal.Paragraph>
    </Trans>
  );
};

const SLegalParagraph = styled(Legal.Paragraph)`
  margin-bottom: 1em;
  /* stylelint-disable-next-line property-disallowed-list */
  font-style: normal;
`;

export default LegalTabImprint;
