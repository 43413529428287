import { LinePathDefinition, ShapeType, TextDefinition } from '@rhim/chart/v2/theme';
import { HoverLine, Tooltip, TooltipCapture, useTooltip } from '@rhim/chart/v2/tooltip';
import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { getFormattedDate } from '@rhim/react/partials';
import { RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto } from '@rhim/rest/fleetOverview';
import { isDefined } from '@rhim/utils';
import { bisector } from 'd3-array';
import { ScaleLinear } from 'd3-scale';
import * as React from 'react';
import { useState } from 'react';

import { Bold } from '../../AroReport/Aro.styles';
import { getTruncatedVolume } from '../utils';
import { usePositionContext } from './hooks/usePositionContext';

interface Props {
  chartWidth: number;
  chartHeight: number;
  data: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto[];
  keys: string[];
  xScale: ScaleLinear<number, number>;
  yScale: ScaleLinear<number, number>;
  unit: string;
}

export enum Keys {
  VOLUME = 'volume',
  RBLMIN = 'rblMin',
  RBLMED = 'rblMed',
}

interface DataKeys {
  [key: string]: LinePathDefinition | TextDefinition;
}

export const renderFormattedValue = (value: string | number | null | undefined, noDataLabel: string, unit = 'mm') => {
  return typeof value === 'number' ? (
    <>
      <Bold>{Math.floor(value) === value ? value : getTruncatedVolume(value)}</Bold> {unit}
    </>
  ) : typeof value === 'string' ? (
    value
  ) : (
    noDataLabel
  );
};

const VolumeTooltip: React.ChildlessComponent<Props> = (props) => {
  const { chartWidth, chartHeight, data, keys, unit, xScale, yScale } = props;
  const { t } = i18nReact.useTranslation(['volume']);
  const positionContext = usePositionContext();
  const { handlePosition } = positionContext;

  const dataKeys: DataKeys = {
    [Keys.VOLUME]: {
      type: ShapeType.Text,
      label: t('volume:propertyVolume'),
    },
    [Keys.RBLMIN]: {
      type: ShapeType.LinePath,
      strokeColor: settings.colors.Primary.Blue_9,
      strokeWidth: 1,
      label: t('volume:min'),
    },
    [Keys.RBLMED]: {
      type: ShapeType.LinePath,
      strokeColor: '#af7aa0',
      strokeWidth: 1,
      strokeDasharray: '3 2',
      strokeDashoffset: 1.5,
      label: t('volume:median'),
    },
  };

  const [tooltipRef, tooltipState, tooltipHandlers] = useTooltip<RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto>({ scrollbarRef: null });
  const [isHovered, setIsHovered] = useState(false);

  const bisectX = (pointerX: number) => {
    const x0 = xScale.invert(pointerX);
    const bisect = bisector(function (d: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto) {
      return d.heat;
    }).center;

    const index = bisect(data, x0.valueOf());
    const measurement = data[index]!;
    const valueX = xScale(measurement.heat || 0);

    handlePosition(measurement.heat);

    return {
      datum: measurement,
      x: valueX,
      index: index,
    };
  };

  React.useEffect(() => {
    if (isHovered && !tooltipState.isHovered) {
      handlePosition(null);
      setIsHovered(false);
    }
  }, [tooltipState, handlePosition, setIsHovered, isHovered]);

  React.useEffect(() => {
    if (tooltipState.isHovered) {
      setIsHovered(tooltipState.isHovered);
    }
  }, [tooltipState, setIsHovered, tooltipState.isHovered]);

  return (
    <>
      <TooltipCapture width={chartWidth} height={chartHeight} handlers={tooltipHandlers.mouseEvents}>
        <HoverLine
          keys={keys}
          tooltipState={tooltipState}
          chartHeight={chartHeight}
          cy={(d: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto, key: string) =>
            yScale(d[key as keyof RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto] as number)
          }
          themeConfig={{ datasets: Object.fromEntries(keys.map((key) => [key, dataKeys[key]!])) }}
        />
      </TooltipCapture>
      <Tooltip
        keys={keys}
        ref={tooltipRef}
        bisectX={bisectX}
        handlers={tooltipHandlers}
        value={(d, key) => d[key as keyof RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto] as string}
        valueFormat={(value) => renderFormattedValue(value, t('volume:noDataLabel'), unit)}
        chartWidth={chartWidth}
        chartHeight={chartHeight}
        width={200}
        state={tooltipState}
        title={(d) => `${t('volume:heatTooltipLabel')} ${d!.heat}`}
        subTitle={(d) => (isDefined(d) && isDefined(d.measurementTaken) ? getFormattedDate({ datetime: d.measurementTaken as Date.ISO_8601 }) : '')}
        themeConfig={{ datasets: Object.fromEntries(keys.map((key) => [key, dataKeys[key]!])) }}
      />
    </>
  );
};

export default React.memo(VolumeTooltip);
