import { RHIMOperatorDisplayServiceV1ModelsMeasurementPagedFileListDto } from '@rhim/rest/operatorDisplay';
import { specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api/operatorDisplay';

type Payload = RHIMOperatorDisplayServiceV1ModelsMeasurementPagedFileListDto;

export function useMeasurementUploadFiles(
  customerId: string,
  take?: number,
  skip?: number,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) {
  return useQuery(getKey(customerId, take, skip), (context) => queryFn(context, options?.axiosConfiguration), {
    ...options?.configuration,
    retry: 2,
  });
}

const getKey = (customerId: string, take?: number, skip?: number) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'measurementService',
      scope: 'measurementFiles',
      entity: 'detail',
      customerId,
      take,
      skip,
    },
  ]);

const queryFn = (
  { queryKey: [{ customerId, take, skip }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>,
  axiosConfiguration?: AxiosRequestConfig
) => {
  return API.measurementFiles.getMeasurementfilesCustomeridList(customerId, take, skip, { signal, ...axiosConfiguration }).then((response) => response.data);
};

useMeasurementUploadFiles.getKey = getKey;
useMeasurementUploadFiles.queryFn = queryFn;
