import { settings } from '@rhim/design';
import { appBar, appBarProductShortName, menuListItemName, navigationItemsContainerAppBar, userSettingsCogButtonAppBar } from '@rhim/test-ids';
import { isDefined } from '@rhim/utils';
import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useButton, useHover } from 'react-aria';
import { Link, Location, matchPath, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { IconButton } from '../IconButton';
import { Tooltip } from '../Tooltip';

const NAVIGATION_BAR_HEIGHT = '56px';

// APP BAR
interface BarProps {
  className?: string;
}

interface Referable {
  ref?: React.Ref<HTMLDivElement>;
}

export const Bar = React.forwardRef<HTMLDivElement, React.PropsWithChildren<BarProps> & Referable>(function Bar({ className, children }, ref) {
  return (
    <StyledWrapper data-test-id={appBar} className={className} ref={ref}>
      {children}
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  z-index: 999;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: ${NAVIGATION_BAR_HEIGHT};
  background: ${settings.colors.Primary.Blue_9};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
`;

const StyledLogoLink = styled(Link)`
  margin: 0 ${settings.Spacing.Spacing_100} 0 4px;
  display: flex;
  align-items: center;
  text-decoration: none;

  #verticalDivider {
    display: inline-block;
    width: 1px;
    height: 21px;
    background-color: ${settings.colors.Primary.Grey_8};
  }

  &:hover {
    border-radius: 3px;
    background-color: ${settings.colors.Primary.Blue_10};

    #verticalDivider {
      visibility: hidden;
    }
  }

  #svgProductIcon {
    display: flex;
    align-items: center;
    padding: ${settings.Spacing.Spacing_100} 0;
    margin: 0 ${settings.Spacing.Spacing_200} 0 ${settings.Spacing.Spacing_150};
  }

  #productShortName {
    color: ${settings.colors.Monochromatic.White};
    font-size: ${settings.typography.FontSize.Small};
    font-family: ${settings.typography.FontFamily.Bold};
  }
`;

const StyledSpan = styled.span<{ hasLogo: boolean }>`
  margin-right: ${(props) => (props.hasLogo ? settings.Spacing.Spacing_200 : settings.Spacing.Spacing_300)};
  margin-left: ${(props) => (props.hasLogo ? 'initial' : settings.Spacing.Spacing_300)};
`;

const StyledNavigationLink = styled(Link)`
  display: flex;
  text-decoration: none;
  padding: ${settings.Spacing.Spacing_150};

  path {
    fill: ${settings.colors.Primary.Grey_4};
  }

  &:hover {
    background-color: ${settings.colors.Primary.Blue_10};
    border-radius: 3px;

    path {
      fill: ${settings.colors.Primary.Grey_2};
    }
  }
`;

const StyledNavigationItemsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;

  a {
    padding: ${settings.Spacing.Spacing_200};
    font-size: ${settings.typography.FontSize.Small};
    color: ${settings.colors.Primary.Grey_4};
    text-decoration: none;

    span {
      padding-bottom: 1px;
    }

    &:hover,
    &:focus {
      color: ${settings.colors.Primary.Grey_2};
    }

    &:active {
      color: ${settings.colors.Monochromatic.White};
    }

    &.isSelected {
      color: ${settings.colors.Monochromatic.White};
      font-family: ${settings.typography.FontFamily.Medium};

      span {
        border-bottom: 1px solid ${settings.colors.Primary.Grey_4};
      }
    }

    &.isDisabled {
      color: ${settings.colors.Primary.Grey_7};
      pointer-events: none;
    }
  }
`;

const StyledFunctionItemsContainer = styled.div`
  display: flex;
  align-items: center;

  > *:last-child {
    margin-right: ${settings.Spacing.Spacing_100};
  }
`;

const StyledAppBarIcon = styled.span`
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;

  &:hover {
    background-color: ${settings.colors.Primary.Blue_10};
    border-radius: 3px;
  }
`;

const StyledFunctionItemMenuContainer = styled.span`
  position: relative;
  color: ${settings.colors.Primary.Grey_4};

  &:hover,
  &.isOpened {
    background-color: ${settings.colors.Primary.Blue_10};
    border-radius: 3px;
  }
`;

const StyledFunctionMenu = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  span {
    font-size: ${settings.typography.FontSize.Small};
  }

  &:active {
    color: ${settings.colors.Monochromatic.White};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:focus {
    outline: none;
  }
`;

const StyledFunctionMenuContentIcon = styled.span`
  display: flex;
  align-items: center;
  margin-right: ${settings.Spacing.Spacing_150};
`;

const StyledFunctionMenuContent = styled.span`
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 100%;
  cursor: default;
  background-color: ${settings.colors.Primary.Blue_10};
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  max-height: 0;
  transition: max-height 0.2s ease-out;
  overflow: hidden;
  z-index: 999;

  ul {
    list-style: none;
    padding: ${settings.Spacing.Spacing_200} 0;
    margin: 0;
    font-size: ${settings.typography.FontSize.Medium};

    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: ${settings.Spacing.Spacing_600};
      padding: 0 ${settings.Spacing.Spacing_300};
      white-space: nowrap;

      &:hover {
        --hoverColor: ${settings.colors.Primary.Grey_2};

        color: var(--hoverColor);
      }

      &:active {
        color: ${settings.colors.Monochromatic.White};
      }
    }
  }
`;

const SAppFunctionMenuLabel = styled.span`
  padding-left: ${settings.Spacing.Spacing_200};
`;

export const HorizontalSpring = styled.div`
  flex-grow: 1;
`;

// LOGO
type LogoProps = {
  productLogoIcon?: React.ReactNode;
  productLogoHref: string;
  productLabel?: string;
  isLogoRightDividerShowing?: boolean;
};
export const Logo: FC<React.PropsWithChildren<LogoProps>> = ({ productLogoIcon, productLogoHref, productLabel, isLogoRightDividerShowing = true }) => {
  return (
    <StyledLogoLink id="logoContainer" to={productLogoHref}>
      {isDefined(productLogoIcon) && <span id="svgProductIcon">{productLogoIcon}</span>}
      {isDefined(productLabel) && (
        <StyledSpan id="productShortName" hasLogo={isDefined(productLogoIcon)} data-test-id={appBarProductShortName}>
          {productLabel}
        </StyledSpan>
      )}
      {isLogoRightDividerShowing && <span id="verticalDivider" />}
    </StyledLogoLink>
  );
};

// NAVIGATION ICON ITEM
type NavigationIconItemProps = {
  href: string;
  icon: React.ReactNode;
  tooltipTitle?: string;
  getPopupContainer?: (node: HTMLElement) => HTMLDivElement | HTMLElement;
  dataTestId?: string;
};

export const NavigationIconItem: FC<React.PropsWithChildren<NavigationIconItemProps>> = ({
  icon,
  href,
  tooltipTitle,
  getPopupContainer,
  dataTestId = userSettingsCogButtonAppBar,
}) => {
  return (
    <Tooltip title={tooltipTitle} placement="bottom" overlayStyle={{ maxWidth: 'unset' }} getPopupContainer={getPopupContainer}>
      <StyledNavigationLink to={href} data-test-id={dataTestId}>
        {icon}
      </StyledNavigationLink>
    </Tooltip>
  );
};

// NAVIGATION ITEMS CONTAINER
export const NavigationItems: FC<React.PropsWithChildren> = ({ children }) => {
  return <StyledNavigationItemsContainer data-test-id={navigationItemsContainerAppBar}>{children}</StyledNavigationItemsContainer>;
};

// NAVIGATION ITEM
type NavigationItemProps = {
  label: string;
  href: string;
  isDisabled?: boolean;
  isActive?: (_matchPath: typeof matchPath, _location: Location) => boolean;
  dataTestId: string;
};
export const NavigationItem: FC<React.PropsWithChildren<NavigationItemProps>> = ({ label, href, isDisabled = false, dataTestId, isActive }) => {
  const location = useLocation();

  const isSelected = isDefined(isActive)
    ? isActive(matchPath, location)
    : isDefined(
        matchPath(
          {
            path: `${href}/*`,
          },
          location.pathname
        )
      );

  const classes = classNames({
    isSelected: isSelected,
    isDisabled: isDisabled,
  });

  return (
    <Link to={href} className={classes} data-test-id={dataTestId}>
      <span>{label}</span>
    </Link>
  );
};

export const AppBarHorizontalSpring: FC<React.PropsWithChildren> = () => {
  return <HorizontalSpring />;
};

// FUNCTION ITEMS CONTAINER
export const FunctionItems: FC<React.PropsWithChildren> = ({ children }) => {
  return <StyledFunctionItemsContainer>{children}</StyledFunctionItemsContainer>;
};
export interface MenuItemProps {
  menuItemId: string;
  menuItemLabel?: string;
  menuItemIcon?: React.ReactElement;
  onMenuItemClicked?: () => void;
}
// FUNCTION ITEM : MENU
type FunctionItemMenuProps = {
  menuLabel?: string;
  icon?: React.ReactElement;
  menuItems: MenuItemProps[];
  onMenuItemClicked: (menuItemId: string) => void;
  dataTestId: string;
  tooltipTitle?: string;
  getPopupContainer?: (node: HTMLElement) => HTMLDivElement | HTMLElement;
  dataTestIdLabel?: string;
};

export const FunctionItemMenu: FC<React.PropsWithChildren<FunctionItemMenuProps>> = ({
  menuLabel,
  icon,
  menuItems,
  onMenuItemClicked,
  dataTestId,
  tooltipTitle,
  getPopupContainer,
  dataTestIdLabel,
}) => {
  const [isExpanded, setExpanded] = useState(false);
  const htmlMenu = useRef<HTMLDivElement>(null);

  const handleClick = useCallback(() => {
    // collapse menu whenever there is a mouse click detected anywhere in the page
    if (isExpanded) setExpanded(false);
  }, [isExpanded]);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  useEffect(() => {
    const menuHTMLElement = htmlMenu.current;
    if (menuHTMLElement) {
      menuHTMLElement.style.maxHeight = isExpanded ? `${menuHTMLElement.scrollHeight}px` : '0px';
    }
  }, [isExpanded]);

  const handleMenuClicked = (event: React.MouseEvent<HTMLSpanElement>) => {
    setExpanded(!isExpanded);
    event.stopPropagation();
  };

  const classes = classNames({
    menuContainer: true,
    isOpened: isExpanded,
  });

  const ref = React.useRef<HTMLButtonElement>(null);
  const { buttonProps, isPressed } = useButton({ elementType: 'span' }, ref);
  const { hoverProps, isHovered } = useHover({ isDisabled: false });

  const iconWithProps = isDefined(icon)
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      React.cloneElement(icon, {
        fill: isPressed || isHovered ? settings.colors.Monochromatic.White : settings.colors.Primary.Grey_4,
      })
    : undefined;

  //hover on menuItem.menuItemIcon missing ( -> fill: settings.colors.Primary.Grey_2)
  return (
    <StyledFunctionItemMenuContainer className={classes} data-test-id={dataTestId}>
      <Tooltip title={tooltipTitle} getPopupContainer={getPopupContainer}>
        <StyledFunctionMenu {...hoverProps} {...buttonProps} onClick={handleMenuClicked}>
          {isDefined(menuLabel) && <SAppFunctionMenuLabel data-test-id={dataTestIdLabel}>{menuLabel}</SAppFunctionMenuLabel>}
          {isDefined(iconWithProps) && <StyledAppBarIcon>{iconWithProps}</StyledAppBarIcon>}
        </StyledFunctionMenu>
      </Tooltip>
      <StyledFunctionMenuContent ref={htmlMenu} data-test-id="menuItems">
        <ul>
          {menuItems.map((menuItem) => (
            <li
              data-test-id={`${menuItem.menuItemId}${menuListItemName}`}
              key={menuItem.menuItemId}
              onClick={() => (isDefined(menuItem.onMenuItemClicked) ? menuItem.onMenuItemClicked() : onMenuItemClicked(menuItem.menuItemId))}
            >
              {isDefined(menuItem.menuItemIcon) && <StyledFunctionMenuContentIcon>{menuItem.menuItemIcon}</StyledFunctionMenuContentIcon>}
              {menuItem.menuItemLabel}
            </li>
          ))}
        </ul>
      </StyledFunctionMenuContent>
    </StyledFunctionItemMenuContainer>
  );
};

// FUNCTION ITEM : ICONBUTTON
type FunctionItemIconButtonProps = {
  id: string;
  icon: React.ReactElement;
  tooltip: string;
  onIconButtonClicked: (iconButtonId: string) => void;
};
export const FunctionItemIconButton: FC<React.PropsWithChildren<FunctionItemIconButtonProps>> = ({ id, icon, tooltip, onIconButtonClicked }) => {
  return <IconButton className="appBarIcon" icon={icon} tooltip={tooltip} tooltipPlacement="bottom" onPress={() => onIconButtonClicked(id)} />;
};

interface AppBarNavigationButtonProps extends React.HTMLAttributes<HTMLAnchorElement> {
  label: string;
  onClick: () => void;
  active: boolean;
  destination: string;
}

export const NavigationButton: React.FunctionComponent<AppBarNavigationButtonProps> = ({ destination, label, onClick, active, ...rest }) => {
  const classes = classNames({
    isSelected: active,
  });

  const currentLocation = useLocation();

  return (
    // "Link will run your onClick event and then navigate to the route" https://stackoverflow.com/a/50221614
    // Active links are disabled https://stackoverflow.com/a/54926212/10325032
    <Link to={active ? currentLocation : destination} className={classes} data-test-id={`${label}VesselType`} onClick={onClick} {...rest}>
      <span>{label}</span>
    </Link>
  );
};
