import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const TruckIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      role={role}
      {...rest}
    >
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="nonzero"
        d="M62.4 41.6h-1.6V30.79a4.8 4.8 0 00-1.41-3.39l-9.99-9.99A4.8 4.8 0 0046.01 16H41.6v-4.8c0-2.65-2.15-4.8-4.8-4.8H11.2c-2.65 0-4.8 2.15-4.8 4.8V16H.8c-.44 0-.8.36-.8.8v1.6c0 .44.36.8.8.8H28c.44 0 .8.36.8.8v1.6c0 .44-.36.8-.8.8H4c-.44 0-.8.36-.8.8v1.6c0 .44.36.8.8.8h20.8c.44 0 .8.36.8.8V28c0 .44-.36.8-.8.8H.8c-.44 0-.8.36-.8.8v1.6c0 .44.36.8.8.8h20.8c.44 0 .8.36.8.8v1.6c0 .44-.36.8-.8.8H6.4V48c0 5.3 4.3 9.6 9.6 9.6s9.6-4.3 9.6-9.6h12.8c0 5.3 4.3 9.6 9.6 9.6s9.6-4.3 9.6-9.6h4.8c.88 0 1.6-.72 1.6-1.6v-3.2c0-.88-.72-1.6-1.6-1.6zM16 52.8a4.801 4.801 0 010-9.6 4.801 4.801 0 010 9.6zm32 0a4.801 4.801 0 010-9.6 4.801 4.801 0 010 9.6zM56 32H41.6V20.8h4.41L56 30.79V32z"
      />
    </svg>
  );
};

TruckIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
