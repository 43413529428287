import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { MaintenancePanel, Progress } from '@rhim/react';
import { RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto } from '@rhim/rest/fleetOverview';
import { assert, ensure, hasElements, head, isDefined } from '@rhim/utils';
import React, { Suspense, useMemo } from 'react';
import { useMeasure } from 'react-use';
import styled from 'styled-components';

import AppContext from '../../app/AppContext';
import CampaignSelection, { ColorTheme } from '../../components/CampaignSelectionDropdown/CampaignSelection';
import VesselSelection from '../../components/VesselSelectionDropdown/VesselSelection';
import { useCampaignHavingVolumes, useCampaignsHavingVolumes, useSelectedCampaign, useSelectedVessel, useVessels } from '../../hooks';
import { Feature } from '../../settings';
import { ComparisonPanel, useCompareMeasurements } from './ComparisonPanel';
import { SORT_BY_DECREASING_MEASUREMENT_TAKEN } from './ComparisonPanel/ComparisonPicker';
import MeasurementItem from './ComparisonPanel/MeasurementItem';
import { withSection3D } from './ComparisonPanel/Section3DContext';
import { PositionProvider } from './Graphs/PositionProvider';
import RblGraph from './Graphs/RblGraph';
import RblLegend from './Graphs/RblLegend';
import VolumeGraph from './Graphs/VolumeGraph';
import VolumeGraphsContainer, { MARGIN_LEFT, PADDING_SIDES } from './Graphs/VolumeGraphsContainer';

const MAXHEAT = 500;
const VolumeCalculationsPage: React.ChildlessComponent = () => {
  const { t } = i18nReact.useTranslation(['shared', 'volume']);
  const { selectedCustomer } = React.useContext(AppContext);
  const customerId = selectedCustomer.customerId as UUID;
  // For a given customer, get the list of vessels for feature VolumeCalculations
  const { data: vessels } = useVessels(customerId, undefined, Feature.VolumeCalculations);
  assert(isDefined(vessels), 'In Suspense mode data should always be defined, according to react-query docs.');
  // For a given list of vessels (if any), get the selected vessel ( the first available one by default)
  const { selectedVesselId, navigateToSelectedVesselId } = useSelectedVessel(vessels);
  // For a given vessel, get the list of campaigns having volumes
  const { data: campaignsHavingVolumes } = useCampaignsHavingVolumes(selectedVesselId ?? undefined, {
    configuration: {
      suspense: false,
    },
  });

  const selectedVessel = useMemo(() => vessels.find((vessel) => vessel.id === selectedVesselId), [vessels, selectedVesselId]);

  const [ref, { width: precisionWidth }] = useMeasure<HTMLDivElement>();
  const width = Math.round(precisionWidth) - (2 * PADDING_SIDES + 2 * MARGIN_LEFT);
  const [selectedCampaignId, setSelectedCampaignId] = useSelectedCampaign(campaignsHavingVolumes ?? []);
  // For a given vessel and campaign, get the details of that campaign
  const { data: campaignHavingVolumes, fetchStatus: campaignFetchStatus } = useCampaignHavingVolumes(selectedVesselId ?? undefined, selectedCampaignId, {
    configuration: {
      suspense: false,
    },
  });
  // Sort measurements by decreasing measurementTaken (most recent measurement will appear first in the list)
  const sortedMeasurements: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto[] | undefined = useMemo(() => {
    if (!isDefined(campaignHavingVolumes)) {
      return undefined;
    }
    const sorted = [...campaignHavingVolumes.measurements];
    sorted.sort(SORT_BY_DECREASING_MEASUREMENT_TAKEN);
    return sorted;
  }, [campaignHavingVolumes]);

  const initialMeasurement: undefined | RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto | null = useMemo(() => {
    if (!isDefined(sortedMeasurements)) {
      return undefined;
    }
    if (!hasElements(sortedMeasurements)) {
      return null;
    }
    // The initial measurement is always the very last item in the list of measurements (has the oldest measurementTaken timestamp)
    return ensure(sortedMeasurements[sortedMeasurements.length - 1]);
  }, [sortedMeasurements]);

  const currentMeasurement: undefined | RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto | null = useMemo(() => {
    if (!isDefined(sortedMeasurements)) {
      return undefined;
    }
    if (!hasElements(sortedMeasurements)) {
      return null;
    }
    // The current measurement is always the very first item in the list of measurements (has the most recent measurementTaken timestamp)
    return ensure(head(sortedMeasurements));
  }, [sortedMeasurements]);
  const { sortedMeasurementsToCompareTo, selectedCompareToMeasurement, setSelectedCompareToMeasurementId } = useCompareMeasurements(
    campaignHavingVolumes,
    currentMeasurement?.measurementId
  );

  const refresh = React.useCallback(() => {
    window.location.reload();
  }, []);

  const maxHeat = useMemo(
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions,@typescript-eslint/no-unnecessary-condition
    () =>
      Math.max(isDefined(campaignHavingVolumes) ? campaignHavingVolumes.maxHeat : 0, isDefined(selectedVessel) ? selectedVessel.expectedHeats : 0) || MAXHEAT,
    [campaignHavingVolumes, selectedVessel]
  );
  const noMeasurementsAvailablePanel = useMemo(() => {
    return initialMeasurement === null ? (
      <ErrorContainer>
        <MaintenancePanel
          size="large"
          heading={t('volume:noMeasurementsToDisplayPanel.header')}
          subHeading={t('volume:noMeasurementsToDisplayPanel.noMeasurements')}
          headerColor={settings.colors.Operational.State_Notif_Grey_2}
          subheaderColor={settings.colors.Operational.State_Notif_Grey_2}
          secondaryButton={{ label: t('shared:refresh'), onClick: refresh }}
        />
      </ErrorContainer>
    ) : null;
  }, [t, refresh, initialMeasurement]);

  return (
    <SContainer ref={ref}>
      <SStripContainer>
        <VesselSelection vessels={vessels} isLoading={false} selectedVesselId={selectedVesselId} onVesselSelected={navigateToSelectedVesselId} />
        <CampaignSelection
          colorTheme={ColorTheme.Dark}
          isLoading={!isDefined(campaignsHavingVolumes)}
          campaigns={campaignsHavingVolumes}
          selectedCampaignId={selectedCampaignId}
          onCampaignSelected={(campaignNr: number | null) => {
            if (isDefined(campaignNr)) {
              setSelectedCampaignId(campaignNr);
            }
          }}
        />
        {isDefined(currentMeasurement) && <MeasurementItem measurement={currentMeasurement} />}
      </SStripContainer>
      {initialMeasurement === null && noMeasurementsAvailablePanel}

      <SWrapper>
        {campaignFetchStatus === 'fetching' && <Progress />}
        {isDefined(initialMeasurement) && (
          <Suspense>
            <ComparisonPanel
              setSelectedCompareToMeasurementId={setSelectedCompareToMeasurementId}
              campaignHavingVolumes={campaignHavingVolumes}
              initialMeasurement={initialMeasurement}
              currentMeasurement={currentMeasurement}
              selectedCompareToMeasurement={selectedCompareToMeasurement}
              sortedMeasurementsToCompareTo={sortedMeasurementsToCompareTo}
            />
          </Suspense>
        )}
      </SWrapper>
      {isDefined(sortedMeasurements) && hasElements(sortedMeasurements) ? (
        <Suspense>
          <PositionProvider measurements={campaignHavingVolumes?.measurements || []}>
            <VolumeGraphsContainer name={t('volume:vesselVolumeLabel')}>
              <VolumeGraph
                compareToMeasurement={selectedCompareToMeasurement}
                width={width}
                measurements={campaignHavingVolumes?.measurements || []}
                height={250}
                maxHeat={maxHeat}
              />
            </VolumeGraphsContainer>
            <VolumeGraphsContainer name={t('volume:rblWallLabel')}>
              <>
                <RblLegend />
                <RblGraph
                  compareToMeasurement={selectedCompareToMeasurement}
                  width={width}
                  measurements={campaignHavingVolumes?.measurements || []}
                  height={140}
                  maxHeat={maxHeat}
                />
              </>
            </VolumeGraphsContainer>
          </PositionProvider>
        </Suspense>
      ) : null}
    </SContainer>
  );
};

const SContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SWrapper = styled.div`
  flex-grow: 1;
  background-color: ${settings.colors.Primary.Grey_1};
  padding: ${settings.Spacing.Spacing_300};
  position: relative;
  display: flex;
  flex-direction: column;
`;

const ErrorContainer = styled.div`
  height: 100vh;
`;

const SStripContainer = styled.div`
  flex-shrink: 0;
  height: 48px;
  background-color: ${settings.colors.Primary.Grey_10};
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
  display: inline-flex;
`;

VolumeCalculationsPage.whyDidYouRender = true;
export default React.memo(withSection3D()(VolumeCalculationsPage));
