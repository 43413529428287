import { settings } from '@rhim/design';
import { AbstractTooltipProps, TooltipPlacement } from 'antd/es/tooltip';
import * as React from 'react';
import styled from 'styled-components';

import { Tooltip } from './Tooltip';

export interface EllipsesTooltipProps extends AbstractTooltipProps {
  className?: string;
  children: string | React.ReactNode;
  placement?: TooltipPlacement;
  maxWidth: number;
  fontSize?: string;
  color?: string;
}

/**
 * Component which detects ellipses in a div and displays a tooltip.
 */
export const EllipsesTooltip: React.FC<React.PropsWithChildren<EllipsesTooltipProps>> = ({
  getPopupContainer,
  maxWidth,
  children,
  className,
  placement,
  fontSize,
  color,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [isEllipsisActive, setIsEllipsisActive] = React.useState(false);

  React.useEffect(() => {
    if (ref.current && ref.current.offsetWidth < ref.current.scrollWidth) {
      setIsEllipsisActive(true);
    }
  }, []);

  return (
    <Tooltip placement={placement} className={className} title={isEllipsisActive ? children : undefined} getPopupContainer={getPopupContainer}>
      <Title fontSize={fontSize} color={color} maxWidth={maxWidth} ref={ref}>
        {children}
      </Title>
    </Tooltip>
  );
};

const Title = styled.div<{ maxWidth: number; color: string | undefined; fontSize: string | undefined }>`
  max-width: ${(props) => props.maxWidth}px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(props) => props.color ?? settings.colors.Primary.Grey_8};
  font-size: ${(props) => props.fontSize ?? settings.typography.FontSize.Large};
  font-family: ${settings.typography.FontFamily.Bold};
`;
