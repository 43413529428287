/* eslint-disable no-storage/no-browser-storage */

import { isDefined } from '@rhim/utils';
import { join } from 'lodash';
import * as React from 'react';
import { useMap } from 'react-use';
import useSessionStorageState from 'use-session-storage-state';

import { LOCAL_STORAGE_PREFIX_APO } from '../../utilities';

const STORAGE_KEY = 'acknowledged-heat-mapping-invalid-or-missing';

function usePersistedAcknowledgment(initialState: Record<UUID, boolean>) {
  return useSessionStorageState<Record<UUID, boolean>>(join([LOCAL_STORAGE_PREFIX_APO, STORAGE_KEY], ''), {
    defaultValue: initialState,
  });
}

export function useAcknowledgment(vesselId: UUID) {
  const [acknowledgement, setAcknowledgement] = usePersistedAcknowledgment({ [vesselId]: false });
  const [map, { get, set }] = useMap<Record<UUID, boolean>>(acknowledgement);

  /**
   * When the user changes vessels and we don't have the next vessel in the map yet, initialize its value to `false`.
   */
  React.useEffect(() => {
    if (!isDefined(get(vesselId))) {
      set(vesselId, false);
    }
  }, [get, set, vesselId]);

  /**
   * When the map changes, commit the change to session storage
   */
  React.useEffect(() => {
    setAcknowledgement(map);
  }, [map, setAcknowledgement]);

  const hasAcknowledged = get(vesselId);

  const setHasAcknowledged = React.useCallback(() => {
    set(vesselId, true);
  }, [set, vesselId]);

  return React.useMemo(() => {
    return [hasAcknowledged, setHasAcknowledged] as const;
  }, [hasAcknowledged, setHasAcknowledged]);
}
