import { useCallback, useState } from 'react';

export interface UseFilter {
  addKey: (key: string) => void;
  removeKey: (key: string) => void;
  includesKey: (key: string) => boolean;
  filter: string[];
}

interface Props {
  initialFilter: string[];
}

export function useFilter(props: Props): UseFilter {
  const [filter, setFilter] = useState<string[]>(props.initialFilter);

  const addKey = useCallback(
    (key: string) =>
      setFilter((current) => {
        if (!current.includes(key)) {
          return [...current, key];
        }

        return current;
      }),
    [setFilter]
  );

  const removeKey = useCallback(
    (key: string) =>
      setFilter((current) => {
        return current.filter((item) => item !== key);
      }),
    [setFilter]
  );

  const includesKey = useCallback((key: string) => filter.includes(key), [filter]);

  return {
    addKey,
    removeKey,
    includesKey,
    filter,
  };
}
