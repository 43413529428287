import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const VesselCOUIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path d="M2 6a2 2 0 1 1 4 0v9H2V6zM6 6a2 2 0 1 1 4 0v9H6V6zM10 6a2 2 0 1 1 4 0v9h-4V6z" stroke={fill} strokeWidth="2" />
      <path d="M7 1a1 1 0 0 1 2 0v3H7V1zM3 1a1 1 0 0 1 2 0v3H3V1zM11 1a1 1 0 1 1 2 0v3h-2V1z" fill={fill} />
    </svg>
  );
};

VesselCOUIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
