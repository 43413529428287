import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const CircleXIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M16.707 15.293c.391.391.391 1.023 0 1.414-.195.195-.451.293-.707.293-.256 0-.512-.098-.707-.293L12 13.414l-3.293 3.293C8.512 16.902 8.256 17 8 17c-.256 0-.512-.098-.707-.293-.391-.391-.391-1.023 0-1.414L10.586 12 7.293 8.707c-.391-.391-.391-1.023 0-1.414s1.023-.391 1.414 0L12 10.586l3.293-3.293c.391-.391 1.023-.391 1.414 0s.391 1.023 0 1.414L13.414 12l3.293 3.293zM12 2C6.477 2 2 6.478 2 12s4.477 10 10 10 10-4.478 10-10S17.523 2 12 2z"
      />
    </svg>
  );
};

CircleXIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
