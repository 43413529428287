import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { getBrowserLocale, toUnicodeLocale } from '@rhim/i18n';
import { formatNumber } from '@rhim/react/partials';
import {
  averageRowFirstCampaignViolinTooltipParametersComparison,
  averageRowSecondCampaignViolinTooltipParametersComparison,
  firstCampaignViolinTooltipParametersComparison,
  maximumRowFirstCampaignViolinTooltipParametersComparison,
  maximumRowSecondCampaignViolinTooltipParametersComparison,
  medianRowFirstCampaignViolinTooltipParametersComparison,
  medianRowSecondCampaignViolinTooltipParametersComparison,
  minimumRowFirstCampaignViolinTooltipParametersComparison,
  minimumRowSecondCampaignViolinTooltipParametersComparison,
  parameterNameViolinTooltipParametersComparison,
  secondCampaignViolinTooltipParametersComparison,
} from '@rhim/test-ids';
import { isDefined } from '@rhim/utils';
import * as React from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';

import { Stats } from './VerticalBox';

interface Props {
  stats: Stats;
  comparedStats: Stats;
  name: string;
  campaign: number;
  compareCampaign: number;
  unit: string;
}
const TooltipInfo = ({ stats, comparedStats, name, campaign, compareCampaign, unit }: Props) => {
  const { min, max, median, average } = stats;
  const { t } = i18nReact.useTranslation(['parameters-comparison']);

  const hasComparedMedian = useMemo(() => isDefined(comparedStats.median) && isDefined(comparedStats.max) && isDefined(comparedStats.min), [comparedStats]);
  const hasMedian = useMemo(() => isDefined(median) && isDefined(max) && isDefined(min), [median, max, min]);
  const locale = getBrowserLocale();

  return (
    <Panel>
      <Container>
        <Header>
          <Title>
            <Name data-test-id={parameterNameViolinTooltipParametersComparison}>{name}</Name>
            {/* eslint-disable-next-line i18next/no-literal-string */}
            <Span data-test-id={firstCampaignViolinTooltipParametersComparison}>{campaign}</Span>
          </Title>
        </Header>
        <Grid>
          <Row data-test-id={maximumRowFirstCampaignViolinTooltipParametersComparison}>
            <Title>
              <RowSpan>{t('parameters-comparison:violin.tooltip.maximum')}</RowSpan>
            </Title>
            <Span>
              <Info>
                {isDefined(comparedStats.max) && isDefined(max) && comparedStats.max !== max && (
                  <ColorLine color={max > comparedStats.max ? settings.colors.Comparison.Higher : settings.colors.Comparison.Lower} />
                )}
                {isDefined(max) ? formatNumber(2, 2)(toUnicodeLocale(locale))(max) : '-'}
              </Info>
              <Unit>{unit}</Unit>
            </Span>
          </Row>
          <Row data-test-id={medianRowFirstCampaignViolinTooltipParametersComparison}>
            <Title>
              <RowSpan font={settings.typography.FontFamily.Bold}>{t('parameters-comparison:violin.tooltip.median')}</RowSpan>
            </Title>
            <Span>
              <Info>
                {isDefined(comparedStats.median) && hasMedian && hasComparedMedian && comparedStats.median !== median && (
                  <ColorLine color={median > comparedStats.median ? settings.colors.Comparison.Higher : settings.colors.Comparison.Lower} />
                )}
                {hasMedian ? formatNumber(2, 2)(toUnicodeLocale(locale))(median) : '-'}
              </Info>
              <Unit>{unit}</Unit>
            </Span>
          </Row>
          <Row data-test-id={minimumRowFirstCampaignViolinTooltipParametersComparison}>
            <Title>
              <RowSpan>{t('parameters-comparison:violin.tooltip.minimum')}</RowSpan>
            </Title>
            <Span>
              <Info>
                {isDefined(comparedStats.min) && isDefined(min) && comparedStats.min !== min && (
                  <ColorLine color={min > comparedStats.min ? settings.colors.Comparison.Higher : settings.colors.Comparison.Lower} />
                )}
                {isDefined(min) ? formatNumber(2, 2)(toUnicodeLocale(locale))(min) : '-'}
              </Info>
              <Unit>{unit}</Unit>
            </Span>
          </Row>
          <RowWithBorder data-test-id={averageRowFirstCampaignViolinTooltipParametersComparison}>
            <Title>
              <RowSpan>{t('parameters-comparison:violin.tooltip.average')}</RowSpan>
            </Title>
            <Span>
              <Info>
                {isDefined(average) && !isNaN(average) && isDefined(comparedStats.average) && average !== comparedStats.average && (
                  <ColorLine color={average > comparedStats.average ? settings.colors.Comparison.Higher : settings.colors.Comparison.Lower} />
                )}
                {isDefined(average) && !isNaN(average) ? formatNumber(2, 2)(toUnicodeLocale(locale))(average) : '-'}
              </Info>
              <Unit>{unit}</Unit>
            </Span>
          </RowWithBorder>
        </Grid>
      </Container>
      {campaign !== compareCampaign && compareCampaign !== 0 && (
        <ComparedContainer>
          <Header>
            <CampaignTitle>
              <Span data-test-id={secondCampaignViolinTooltipParametersComparison}>{compareCampaign}</Span>
            </CampaignTitle>
          </Header>
          <Grid>
            <Row data-test-id={maximumRowSecondCampaignViolinTooltipParametersComparison}>
              <Value>
                <Info>{isDefined(comparedStats.max) ? formatNumber(2, 2)(toUnicodeLocale(locale))(comparedStats.max) : '-'}</Info>
                <Unit>{unit}</Unit>
              </Value>
            </Row>
            <Row data-test-id={medianRowSecondCampaignViolinTooltipParametersComparison}>
              <Value>
                <Info>{hasComparedMedian ? formatNumber(2, 2)(toUnicodeLocale(locale))(comparedStats.median) : '-'}</Info>
                <Unit>{unit}</Unit>
              </Value>
            </Row>
            <Row data-test-id={minimumRowSecondCampaignViolinTooltipParametersComparison}>
              <Value>
                <Info>{isDefined(comparedStats.min) ? formatNumber(2, 2)(toUnicodeLocale(locale))(comparedStats.min) : '-'}</Info>
                <Unit>{unit}</Unit>
              </Value>
            </Row>
            <RowWithBorder data-test-id={averageRowSecondCampaignViolinTooltipParametersComparison}>
              <Value>
                <Info>
                  {isDefined(comparedStats.average) && !isNaN(comparedStats.average) ? formatNumber(2, 2)(toUnicodeLocale(locale))(comparedStats.average) : '-'}
                </Info>
                <Unit>{unit}</Unit>
              </Value>
            </RowWithBorder>
          </Grid>
        </ComparedContainer>
      )}
    </Panel>
  );
};

const Panel = styled.div`
  display: inline-flex;
`;

const Name = styled.div`
  font-family: ${settings.typography.FontFamily.Bold};
  color: ${settings.colors.Primary.Grey_8};
`;

const Grid = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => (isDefined(props.color) ? props.color : settings.colors.Monochromatic.White)};
`;

const Row = styled.div`
  display: inline-flex;
  align-items: center;
  height: 24px;
  justify-content: end;
`;

const RowWithBorder = styled(Row)`
  border-top: solid 1px ${settings.colors.Primary.Grey_3};
`;

const Container = styled(Grid)`
  width: 180px;
  border-radius: 3px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${settings.colors.Primary.Grey_3};
`;

const ComparedContainer = styled(Container)`
  width: min-content;
  display: inline-flex;
  border-top-right-radius: 3px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
`;

const Header = styled(Row)`
  height: 26px;
  background-color: ${settings.colors.Primary.Grey_2};
`;

const Span = styled.span`
  color: ${settings.colors.Primary.Grey_8};
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.X_Small};
  padding-right: 10px;
  display: inline-flex;
`;

const RowSpan = styled(Span)<{ font?: string }>`
  color: ${settings.colors.Primary.Grey_8};
  font-family: ${(props) => props.font ?? settings.typography.FontFamily.Regular};
`;

const Info = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: ${settings.typography.FontFamily.Bold};
`;

const ColorLine = styled.div.attrs({ role: 'presentation' })<{ color: string }>`
  width: 4px;
  height: 12px;
  background: ${(props) => props.color};
  margin-right: ${settings.Spacing.Spacing_50};
`;

const Title = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-left: ${settings.Spacing.Spacing_100};
  font-size: ${settings.typography.FontSize.X_Small};
`;

const CampaignTitle = styled(Title)`
  justify-content: end;
`;

const Unit = styled(Span)`
  margin-left: ${settings.Spacing.Spacing_50};
  padding: 0;
`;

const Value = styled(Span)`
  padding-left: ${settings.Spacing.Spacing_50};
`;

export default React.memo(TooltipInfo);
