import { RHIMAPOReportingWearManagementApiV1ModelsPredictionOverviewDto } from '@rhim/rest/wearManagement';
import { assert, isDefined, specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api/wearManagement';

type Payload = RHIMAPOReportingWearManagementApiV1ModelsPredictionOverviewDto[];

export function useCrkPredictionsOfCampaign(
  vesselId: string | null,
  campaign: number | null,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) {
  return useQuery(getKey(vesselId, campaign), (context) => queryFn(context, options?.axiosConfiguration), {
    ...options?.configuration,
    enabled: isDefined(vesselId) && isDefined(campaign) && options?.configuration?.enabled,
  });
}

const getKey = (vesselId: string | null, campaignId: number | null) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'wearManagement',
      scope: 'crk-predictions',
      entity: 'list',
      vesselId,
      campaignId,
    },
  ]);

const queryFn = (
  { queryKey: [{ campaignId, vesselId }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>,
  axiosConfiguration?: AxiosRequestConfig
) => {
  assert(
    isDefined(vesselId),
    `${useCrkPredictionsOfCampaign.name} requires a vesselId, but value was ${vesselId}. Use a valid id, or use \`options.enabled\` to pause the query until all requirements are met. See https://react-query.tanstack.com/guides/disabling-queries for instructions.`
  );
  assert(
    isDefined(campaignId),
    `${useCrkPredictionsOfCampaign.name} requires a campaign, but value was ${campaignId}. Use a valid campaign, or use \`options.enabled\` to pause the query until all requirements are met. See https://react-query.tanstack.com/guides/disabling-queries for instructions.`
  );

  return API.crkPredictions
    .getPredictionsCrkVesselidCampaignsCampaignPredictions(vesselId, campaignId, { signal, ...axiosConfiguration })
    .then((response) => response.data);
};

useCrkPredictionsOfCampaign.getKey = getKey;
useCrkPredictionsOfCampaign.queryFn = queryFn;
