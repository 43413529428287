import { createStateContext } from 'react-use';

/**
 * Used to hide navigation when Operator Display is used in full screen mode.
 *
 * If we didn't do this, changing the vessel while in full screen mode
 * would make the navigation bar visible again, which we don't want.
 *
 * @see https://dev.azure.com/RHIM/UX/_git/UX/pullRequest/21801#1677168903
 */
export const [useHiddenNavigation, HiddenNavigationProvider] = createStateContext(false);
