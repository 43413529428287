import { settings } from '@rhim/design';
import { AreasIcon, DataTableIcon, GraphEyeIcon } from '@rhim/icons/24';
import { colorScalesForLadle, colorScalesForLCS, hasElements } from '@rhim/utils';
import { FC, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { UnitSystem } from 'typings';

import { COLOR_SCALE_LCS, MappingPanel, MappingPanelTexts } from '../MappingPanel';
import { SidebarState, SideContextBar } from '../SideContextBar/SideContextBar';
import { ThicknessWearPanel, ThicknessWearPanelTexts } from '../ThicknessWearPanel/ThicknessWearPanel';
import { RegionPanelToFocus } from '../WallplotAreasPanel/AreasInfoCards';
import { AreaPanelTexts } from '../WallplotAreasPanel/domain';
import { WallplotAreasPanel } from '../WallplotAreasPanel/WallplotAreasPanel';
import { CellTooltipTexts } from './CellTooltip';
import { Area, DataItem } from './utils';
import { calculateDataItemsValuesRange, generateScales, WallplotHeatmap } from './WallplotHeatmap';

//import wallPlotCoarseData from './wallplotCoarseData.json';

const MARGIN_LEFT_RIGHT_PX = 24;

//const wallplotData: DataItem[] = wallPlotCoarseData.wallplot.map((item, index) => ({ id: index, ...item }));

//const wallplotDataValuesRange = calculateDataItemsValuesRange(wallplotData);

//const { domainXMin, domainXMax, domainYMin, domainYMax } = generateScales(wallplotData);

interface WallplotWithSidePanelsTexts {
  panel: string;
  panelNames: {
    mapping: string;
    thicknessWear: string;
    areas: string;
  };
  mappingPanelTexts: MappingPanelTexts;
  thicknessWearPanelTexts: ThicknessWearPanelTexts;
  areaPanelTexts: AreaPanelTexts;
  cellTooltipTexts: CellTooltipTexts;
}

interface WallplotWithSidePanelsProps {
  className?: string;
  wallplotAreas: Area[];
  wallplotBody: DataItem[];
  texts: WallplotWithSidePanelsTexts;
  onContainerWidthChanged?: (newWidth: number) => void;
  defaultSelectedBarItemId: string | null;
  defaultExpandedBarItemsIds: string[];
  defaultPinnedBarItemsIds: string[];
  onSidebarStateChanged: (state: SidebarState) => void;
  unitSystem: UnitSystem;
}
export const WallplotWithSidePanels: FC<React.PropsWithChildren<WallplotWithSidePanelsProps>> = ({
  className,
  wallplotAreas,
  wallplotBody,
  texts,
  onContainerWidthChanged,
  defaultSelectedBarItemId,
  defaultExpandedBarItemsIds,
  defaultPinnedBarItemsIds,
  onSidebarStateChanged,
  unitSystem,
}) => {
  const [cellHighlightValueMin, setCellHighlightValueMin] = useState<number | null>(null);
  const [cellHighlightValueMax, setCellHighlightValueMax] = useState<number | null>(null);
  const [cellHighlightId, setCellHighlightId] = useState<number | null>(null);
  const [showAreasInWalplot, setShowAreasInWalplot] = useState(true);
  const [highlightAreaId, setHighlightAreaId] = useState<number | null>(null);
  const [regionPanelToFocus, setRegionPanelToFocus] = useState<RegionPanelToFocus>({ regionId: null });

  const wallplotDataValuesRange = useMemo(() => {
    return calculateDataItemsValuesRange(wallplotBody);
  }, [wallplotBody]);

  const { domainXMin, domainXMax, domainYMin, domainYMax } = useMemo(() => {
    return generateScales(wallplotBody);
  }, [wallplotBody]);

  const sideContextBarItemMappingPanel = useMemo(
    () => ({
      panelId: 'mappingPanel',
      icon: <GraphEyeIcon />,
      label: texts.panelNames.mapping,
      tooltip: `${texts.panelNames.mapping} ${texts.panel}`,
    }),
    [texts]
  );

  const sideContextBarItemWallplotAreasPanel = useMemo(
    () => ({
      panelId: 'areasPanel',
      icon: <AreasIcon />,
      label: texts.panelNames.areas,
      tooltip: `${texts.panelNames.areas} ${texts.panel}`,
      isDisabled: !hasElements(wallplotAreas),
    }),
    [wallplotAreas, texts]
  );

  const sideContextBarItemThicknessWearPanel = useMemo(
    () => ({
      panelId: 'thicknessWearPanel',
      icon: <DataTableIcon />,
      label: texts.panelNames.thicknessWear,
      tooltip: `${texts.panelNames.thicknessWear} ${texts.panel}`,
    }),
    [texts]
  );

  const handleShowAreasOnWallplot = useCallback((isShowing: boolean) => {
    setShowAreasInWalplot(isShowing);
  }, []);

  const handleMinimapAreaEntered = useCallback((areaId: number) => {
    setHighlightAreaId(areaId);
  }, []);

  const handleMinimapAreasExited = useCallback(() => {
    setHighlightAreaId(null);
  }, []);

  const handleThicknessWearPanelRowEntered = useCallback((rowData: DataItem) => {
    setCellHighlightId(rowData.id);
  }, []);

  const handleThicknessWearPanelRowsExited = useCallback(() => {
    setCellHighlightId(null);
  }, []);

  const handleOnThicknessZoneMouseEntered = useCallback((zoneMinimumValue: number, zoneMaximumValue: number) => {
    setCellHighlightValueMin(zoneMinimumValue);
    setCellHighlightValueMax(zoneMaximumValue);
  }, []);

  const handleOnThicknessZoneMouseLeft = useCallback(() => {
    setCellHighlightValueMin(null);
    setCellHighlightValueMax(null);
  }, []);

  const handleContainerWidthChanged = useCallback(
    (newWidth: number) => {
      onContainerWidthChanged?.(newWidth + 2 * MARGIN_LEFT_RIGHT_PX);
    },
    [onContainerWidthChanged]
  );

  const handleRegionClicked = (regionId: number) => {
    setRegionPanelToFocus({ regionId });
  };

  return (
    <SWrapper className={className}>
      {/*<WallplotHeatmap  wallplotData={wallPlotDetailedData.wallplot} colorScale={colorScalesForBOF}  />*/}
      <SWallplotHeatmap
        areaPanelTexts={texts.areaPanelTexts}
        cellTooltipTexts={texts.cellTooltipTexts}
        wallplotData={{ areas: wallplotAreas, wallplot: wallplotBody }}
        colorScale={colorScalesForLadle}
        canvasFitMode="SQUARE_CELLS_FIT_BOTH"
        domainXStart={180}
        domainXWrap={360}
        showAxis={true}
        showCellStates={true}
        showCellValues={true}
        showAreasMap={showAreasInWalplot}
        highlightAreaId={highlightAreaId}
        onAreaEntered={handleMinimapAreaEntered}
        onAreasExited={handleMinimapAreasExited}
        onRegionClicked={handleRegionClicked}
        criticalValueThreshold={0}
        highlightValueMinimum={cellHighlightValueMin}
        highlightValueMaximum={cellHighlightValueMax}
        highlightCellId={cellHighlightId}
        onContainerWidthChanged={handleContainerWidthChanged}
      />
      <SideContextBar
        defaultSelectedBarItemId={defaultSelectedBarItemId}
        defaultExpandedItemsIds={defaultExpandedBarItemsIds}
        defaultPinnedItemsIds={defaultPinnedBarItemsIds}
        onSidebarStateChanged={onSidebarStateChanged}
      >
        <MappingPanel
          sideContextBarItem={sideContextBarItemMappingPanel}
          isExpanded={false}
          unitSystem={unitSystem}
          texts={texts.mappingPanelTexts}
          data={{ colorScales: colorScalesForLCS, valueMin: wallplotDataValuesRange[0], valueMax: wallplotDataValuesRange[1] }}
          onThicknessZoneMouseEntered={handleOnThicknessZoneMouseEntered}
          onThicknessZoneMouseLeft={handleOnThicknessZoneMouseLeft}
        />
        <WallplotAreasPanel
          sideContextBarItem={sideContextBarItemWallplotAreasPanel}
          isExpanded={false}
          domainXStart={180}
          domainXWrap={360}
          domainXMin={domainXMin}
          domainXMax={domainXMax}
          domainYMin={domainYMin}
          domainYMax={domainYMax}
          wallplotAreas={wallplotAreas}
          texts={texts.areaPanelTexts}
          onShowingAreasOnWallplotChanged={handleShowAreasOnWallplot}
          onAreaEntered={handleMinimapAreaEntered}
          onAreasExited={handleMinimapAreasExited}
          highlightAreaId={highlightAreaId}
          regionPanelToFocus={regionPanelToFocus}
        />
        <ThicknessWearPanel
          sideContextBarItem={sideContextBarItemThicknessWearPanel}
          isExpanded={false}
          scale={COLOR_SCALE_LCS}
          data={wallplotBody}
          criticalThicknessValue={5}
          criticalWearValue={3}
          onRowEntered={handleThicknessWearPanelRowEntered}
          onRowsExited={handleThicknessWearPanelRowsExited}
          texts={texts.thicknessWearPanelTexts}
        />
      </SideContextBar>
    </SWrapper>
  );
};

const SWrapper = styled.div`
  display: flex;
  height: 100%;
`;

const SWallplotHeatmap = styled(WallplotHeatmap)`
  flex-grow: 1;
  margin: ${settings.Spacing.Spacing_700} ${MARGIN_LEFT_RIGHT_PX}px ${settings.Spacing.Spacing_300} ${MARGIN_LEFT_RIGHT_PX}px;
`;
