import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const HexagonQCKIcon = React.forwardRef<SVGSVGElement, Props>(({ size, fill, title, role, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 25c2.206 0 4-1.794 4-4v-9c0-2.206-1.794-4-4-4h-1.619l-.385-.668A4.678 4.678 0 0 0 16.957 5h-1.914a4.678 4.678 0 0 0-4.039 2.332L10.619 8H9c-2.206 0-4 1.794-4 4v9c0 2.206 1.794 4 4 4h14zM17.019.276 28.98 7.247A2.059 2.059 0 0 1 30 9.029v13.942a2.06 2.06 0 0 1-1.02 1.783l-11.961 6.971a2.019 2.019 0 0 1-2.039 0L3.02 24.754A2.06 2.06 0 0 1 2 22.971V9.029c0-.735.388-1.415 1.02-1.782L14.98.276a2.02 2.02 0 0 1 2.039 0zM20.092 20A1.495 1.495 0 0 0 23 19.5a1.495 1.495 0 0 0-2.908-.5H17a.5.5 0 0 0 0 1h3.092zM10 16.5c0 3.033 2.467 5.5 5.5 5.5a1 1 0 0 0 0-2c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5a1 1 0 0 0 0-2 5.506 5.506 0 0 0-5.5 5.5zm8.5 1.5a1.5 1.5 0 1 0 0-3c-.652 0-1.202.419-1.408 1H16a.5.5 0 0 0 0 1h1.092c.206.581.756 1 1.408 1zm.592-5H17a.5.5 0 0 0 0 1h2.092A1.495 1.495 0 0 0 22 13.5a1.495 1.495 0 0 0-2.908-.5zm1.134-3H23c1.103 0 2 .897 2 2v9c0 1.103-.897 2-2 2H9c-1.103 0-2-.897-2-2v-9c0-1.103.897-2 2-2h2.774l.963-1.668A2.67 2.67 0 0 1 15.043 7h1.914c.948 0 1.832.51 2.306 1.332L20.226 10z"
      />
    </svg>
  );
});

HexagonQCKIcon.displayName = 'HexagonQCKIcon';

HexagonQCKIcon.defaultProps = {
  size: '32',
  fill: settings.colors.Primary.Blue_9,
};
