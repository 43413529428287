import 'd3-transition';

import { i18nReact } from '@rhim/i18n';
import { assert, isDefined } from '@rhim/utils';
import { bisector } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { drag } from 'd3-drag';
import { scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import { curveCardinal, line } from 'd3-shape';
import type { FunctionComponent, RefObject } from 'react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useResizeAware from 'react-resize-aware';
import styled from 'styled-components';
import type { BottomProps, CylindricalSectionCutsMetadata, PlotData, WallProps } from 'typings/internal/sections';

import { PopoverBody } from './popover/PopoverBody';
import { PopoverHeader } from './popover/PopoverHeader';
import { SPopover } from './popover/StyledPopover';
import { SvgFilters } from './SvgFilters';
import { SvgNodata } from './SvgNodata';
import { SvgRblValue, TextPosition } from './SvgRblValue';
import { showFixedNumber } from './utils';

interface VerticalSliceProps {
  plotData: PlotData;
  selectedAngle: number;
  meta: CylindricalSectionCutsMetadata;
  setVerticalIndex: (sliceIndex: number) => void;
  heatNumber: number;
}

enum PointType {
  Wallplot = 'wallplot',
  Bottom = 'bottom',
}
interface PointOfMergedPath {
  type: PointType;
  radius: number;
  depth: number;
  hoverRadius: number;
  data: WallProps | BottomProps;
}

// curve by which we connect the points. Using half of tangent length to get not too smooth angles
const curve = curveCardinal.tension(0.4);

const verticalSlicePathGenerator = line<PointOfMergedPath | null>()
  .curve(curve)
  .defined((d) => isDefined(d))
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  .x((d) => d!.radius)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  .y((d) => d!.depth);

const VerticalSliceComponent: FunctionComponent<React.PropsWithChildren<VerticalSliceProps>> = (props) => {
  const { plotData, selectedAngle, setVerticalIndex, meta, heatNumber } = props;
  const svgRef: RefObject<SVGSVGElement> = useRef<SVGSVGElement>(null);
  const [hoveredElement, setHoveredElement] = useState<PointOfMergedPath>();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [hoveredMarker, setHoveredMarker] = useState<{ x: number; y: number }>();
  const [resizeListener, sizes] = useResizeAware();
  const { t } = i18nReact.useTranslation(['visualization']);

  const handlePopupVIsibility = useCallback(
    (visible: boolean) => {
      if (isDefined(hoveredElement) && visible) {
        setHoveredMarker({
          x: hoveredElement.radius,
          y: hoveredElement.depth,
        });
      } else {
        setHoveredMarker(undefined);
      }
    },
    [setHoveredMarker, hoveredElement]
  );

  // the SVG for the vertical slice is a square, to fit it to a parent component we need to get a min of either width or height
  const sliceWidth = useMemo(() => Math.min(sizes.width ?? 500, sizes.height ?? 500), [sizes.width, sizes.height]);
  const canvasHalfWidth = useMemo(() => sliceWidth / 2 - 30, [sliceWidth]);
  // const graphPadding = useMemo(() => 0.1 * canvasHalfWidth, [canvasHalfWidth]);
  const scalePadding = 50;
  const leftPadding = 2 * scalePadding;
  const radiusScaleRange = useMemo(() => canvasHalfWidth - scalePadding, [canvasHalfWidth]);
  const depthScaleRange = useMemo(() => [scalePadding, 2 * canvasHalfWidth - scalePadding], [canvasHalfWidth]);

  const maxScale = useMemo(() => Math.max(meta.maxDepth, 2 * meta.maxRadius), [meta.maxRadius, meta.maxDepth]);
  const radiusScale = useMemo(() => {
    const maxScaleValue = maxScale / 2;
    return scaleLinear().domain([-maxScaleValue, maxScaleValue]).range([-radiusScaleRange, radiusScaleRange]);
  }, [maxScale, radiusScaleRange]);

  const depthScale = useMemo(() => {
    const domain = 2 * meta.maxRadius > meta.maxDepth ? [0, 2 * meta.maxRadius] : [0, meta.maxDepth];
    return scaleLinear().domain(domain).range(depthScaleRange);
  }, [meta.maxDepth, meta.maxRadius, depthScaleRange]);

  const yAxis = useMemo(() => axisLeft(depthScale), [depthScale]);
  const xAxis = useMemo(() => axisBottom(radiusScale).ticks(7), [radiusScale]);

  const slicedData = useMemo(() => {
    const leftAngle = selectedAngle;
    const rightAngle = (leftAngle + 180) % 360;

    return {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      left: plotData[`${leftAngle}`]!.map((d) => {
        return { ...d, angle: leftAngle };
      }),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      right: plotData[`${rightAngle}`]!.map((d) => {
        return { ...d, angle: rightAngle };
      }),
    };
  }, [plotData, selectedAngle]);

  const fullPath = useMemo<(PointOfMergedPath | null)[]>(() => {
    const scaledLeft = slicedData.left
      // .filter((point) => point.depth && point.radius)
      .map((point) => {
        if (isDefined(point.depth) && isDefined(point.radius)) {
          const isWallPoint = isDefined(point.depth) && point.depth <= meta.bottomDepth;
          return {
            radius: radiusScale(-point.radius),
            depth: depthScale(point.depth),
            type: isWallPoint ? PointType.Wallplot : PointType.Bottom,
            hoverRadius: isWallPoint ? 10 : 5,
            data: {
              ...point,
            },
          } as PointOfMergedPath;
        }
        return null;
      });

    const scaledRight = slicedData.right
      // .filter((point) => point.depth && point.radius)
      .map((point) => {
        if (isDefined(point.depth) && isDefined(point.radius)) {
          const isWallPoint = isDefined(point.depth) && point.depth <= meta.bottomDepth;
          return {
            radius: radiusScale(point.radius),
            depth: depthScale(point.depth),
            type: isWallPoint ? PointType.Wallplot : PointType.Bottom,
            hoverRadius: isWallPoint ? 10 : 5,
            data: {
              ...point,
            },
          } as PointOfMergedPath;
        }
        return null;
      })
      .reverse();

    return scaledLeft.concat(scaledRight);
  }, [depthScale, radiusScale, slicedData, meta.bottomDepth]);

  // effect for draggable elements
  useEffect(() => {
    const min = 0;
    const max = depthScale(meta.bottomDepth);
    const depthList: number[] = slicedData.left.filter((point) => isDefined(point.depth)).map((point) => depthScale(point.depth ?? 0));
    const pointBisect = bisector<number, number>((d) => d);

    const draggerHandler = drag<SVGGElement, unknown>().on('drag', (d) => {
      const sourceY = d.sourceEvent.offsetY;
      if (sourceY >= min && sourceY <= max) {
        const lIndex = pointBisect.left(depthList, sourceY as number);

        if (lIndex === 0) {
          setCurrentIndex(lIndex);
          return;
        }

        const depthListEntry = depthList[lIndex];
        const depthListNextEntry = depthList[lIndex + 1];
        assert(isDefined(depthListEntry) && isDefined(depthListNextEntry), 'VerticalSliceComponent: depthList-entries are possibly not defined');

        const t = (sourceY - depthListEntry) / (depthListNextEntry - depthListEntry);
        const newIndex = t > 0.8 ? lIndex : lIndex - 1;
        setCurrentIndex(newIndex);
      }
    });

    const draggerButton = select(svgRef.current).select<SVGGElement>('#slider-button');
    draggerButton.call(draggerHandler);
  }, [setCurrentIndex, depthScale, meta.bottomDepth, slicedData.left]);

  /**
   * Positioning of the draggable elements are handled in this effect
   *
   * The movable-group can be moved to a depth where we have holes
   * in this case no-data-(left|right) icons will be shown
   */
  useEffect(() => {
    const svg = select(svgRef.current);
    const movableGroup = svg.select('#movable-group');
    const correctedIndex = meta.depthStep * currentIndex > meta.bottomDepth ? Math.floor(meta.bottomDepth / meta.depthStep) : currentIndex;
    const currentDepth = meta.depthStep * correctedIndex;
    const dataLeft = slicedData.left[correctedIndex];
    const dataRight = slicedData.right[correctedIndex];
    const bottomMaxRadius = radiusScale(meta.maxRadius);

    assert(isDefined(dataLeft), 'VerticalSliceComponent: dataLeft not defined');
    assert(isDefined(dataRight), 'VerticalSliceComponent: dataRight not defined');

    const depthValue = depthScale(currentDepth);
    const leftRadiusValue = isDefined(dataLeft.radius) ? radiusScale(dataLeft.radius) : null;
    const rightRadiusValue = isDefined(dataRight.radius) ? radiusScale(dataRight.radius) : null;
    movableGroup.attr('transform', `translate(${-scalePadding}, ${depthValue})`);
    movableGroup.attr('y', depthValue);

    const pointerLeft = svg.select<SVGCircleElement>('#pointer-left');
    const pointerRight = svg.select<SVGCircleElement>('#pointer-right');

    if (isDefined(leftRadiusValue)) {
      pointerLeft.select('#no-data-left').attr('opacity', 0);
      pointerLeft.select('#pointer-left-value').attr('opacity', 1).attr('transform', `translate(${-leftRadiusValue}, 0)`);
      const text = isDefined(dataLeft.rbl) ? t('visualization:sections.mm', { size: showFixedNumber(dataLeft.rbl.min, 2) }) : '-';
      pointerLeft.select('text').text(text);
    } else {
      pointerLeft.select('#no-data-left').attr('opacity', 1).attr('transform', `translate(${-bottomMaxRadius}, 0)`);
      pointerLeft.select('#pointer-left-value').attr('opacity', 0);
    }

    if (isDefined(rightRadiusValue)) {
      pointerRight.select('#no-data-right').attr('opacity', 0);
      pointerRight.select('#pointer-right-value').attr('opacity', 1).attr('transform', `translate(${rightRadiusValue}, 0)`);
      const text = isDefined(dataRight.rbl) ? t('visualization:sections.mm', { size: showFixedNumber(dataRight.rbl.min, 2) }) : '-';
      pointerRight.select('text').text(text);
    } else {
      pointerRight.select('#no-data-right').attr('opacity', 1).attr('transform', `translate(${bottomMaxRadius}, 0)`);
      pointerRight.select('#pointer-right-value').attr('opacity', 0);
    }

    svg.select('#depth-value').text(showFixedNumber(currentDepth, 1));
    setVerticalIndex(correctedIndex);
  }, [
    setVerticalIndex,
    currentIndex,
    slicedData.left,
    slicedData.right,
    t,
    depthScale,
    radiusScale,
    scalePadding,
    meta.maxRadius,
    meta.depthStep,
    meta.bottomDepth,
  ]);

  useEffect(() => {
    const scaleGroup = select(svgRef.current).select<SVGGElement>('#left-scale');
    scaleGroup.call(yAxis);
    scaleGroup.select('.domain').attr('stroke', 'none');
  }, [yAxis]);

  useEffect(() => {
    const scaleGroup = select(svgRef.current).select<SVGGElement>('#bottom-scale');
    scaleGroup.call(xAxis);
    scaleGroup.select('.domain').attr('stroke', 'none');
  }, [xAxis]);

  // Drawing invisible circles on the places with data points and assign mouse over event to show a Popover block next to it
  useEffect(() => {
    const svg = select(svgRef.current);
    const points = svg
      .select('#graph-group')
      .selectAll('.vertex')
      .data(fullPath.filter((d) => isDefined(d)))
      .enter()
      .append('circle')
      .attr('class', 'vertex')
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .attr('cx', (d) => d!.radius)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .attr('cy', (d) => d!.depth)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .attr('r', (d) => d!.hoverRadius)
      .attr('opacity', 0)
      .on('mouseover', (_, d) => {
        if (isDefined(d)) {
          setHoveredElement(d);
        }
      });

    return () => {
      points.remove();
    };
  }, [fullPath]);

  // drawing all paths out of mergedPaths collections
  useEffect(() => {
    const svg = select(svgRef.current);
    const graphGroup = svg.select<SVGGElement>('#graph-group');

    const path = verticalSlicePathGenerator(fullPath);
    assert(isDefined(path), 'VerticalSliceComponent: path is not defined');
    graphGroup
      .append('path')
      .attr('d', path)
      .attr('class', 'main-slice-path')
      .attr('fill', 'none')
      .attr('stroke', '#003262')
      .attr('stroke-width', '2')
      .attr('stroke-linecap', 'round');

    return () => {
      svg.selectAll('.main-slice-path').remove();
    };
  }, [slicedData, fullPath]);

  return (
    <SWrapper>
      {resizeListener}
      <svg ref={svgRef} width="100%" height="100%" preserveAspectRatio="xMinYMin meet">
        <SvgFilters />
        <g transform={`translate(${leftPadding}, 6)`}>
          <rect width={2 * radiusScaleRange} height={depthScaleRange[1]} fill="none" stroke="#d8dde1" strokeWidth={1} />
          <g id="left-scale"></g>
          <g id="bottom-scale" transform={`translate(${canvasHalfWidth - scalePadding}, ${depthScaleRange[1]})`}></g>
          <g id="graph-group" transform={`translate(${canvasHalfWidth - scalePadding}, 0)`}></g>
          <g id="movable-group">
            <g id="rotation-value" transform="translate(-20, 0)">
              <rect width="34" height="28" fill="white" strokeWidth="1" stroke="#d8dde1" rx="3" transform="translate(-17, -14)" />
              <text id="depth-value" dominantBaseline="middle" textAnchor="middle"></text>
            </g>
            <path
              fill="none"
              stroke="#b1bbc2"
              strokeWidth="1"
              strokeLinejoin="round"
              strokeLinecap="round"
              d={`M${scalePadding},0H${2 * radiusScaleRange + scalePadding}`}
            />
            <path fill="none" stroke="#1dc1e1" strokeWidth="3" strokeLinejoin="round" strokeLinecap="round" d={`M${scalePadding},0H${canvasHalfWidth}`} />
            <g id="slider-button" cursor="pointer" transform={`translate(${scalePadding - 40}, -16)`}>
              <path
                fill="#003262"
                stroke="#ffffff"
                strokeWidth="2"
                strokeLinejoin="round"
                strokeLinecap="round"
                filter="url(#drop-shadow)"
                d="M0,0H25L40,16L25,32H0Z"
              />
              <path
                fill="#487CB4"
                fillRule="evenodd"
                transform="scale(0.9) translate(0, 5)"
                d="M14.293 17.293 13 18.586V5.414l1.293 1.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3-3a.999.999 0 0 0-1.414 0l-3 3a.999.999 0 1 0 1.414 1.414L11 5.414v13.172l-1.293-1.293a.999.999 0 1 0-1.414 1.414l3 3a.997.997 0 0 0 1.414 0l3-3a.999.999 0 1 0-1.414-1.414"
              />
            </g>
            <g transform={`translate(${canvasHalfWidth}, 0)`}>
              <g id="pointer-left">
                <SvgRblValue id="pointer-left-value" textPosition={TextPosition.Right} />
                <SvgNodata id="no-data-left" />
              </g>
              <g id="pointer-right">
                <SvgRblValue id="pointer-right-value" textPosition={TextPosition.Left} />
                <SvgNodata id="no-data-right" />
              </g>
            </g>
          </g>
        </g>
        <g transform={`translate(${canvasHalfWidth + scalePadding}, 6)`}>
          {hoveredMarker && <circle r="5" fill="#003262" stroke="#ffffff" strokeWidth="2" cx={hoveredMarker.x} cy={hoveredMarker.y} />}
        </g>
      </svg>
      {hoveredElement && (
        <SPopover
          content={<PopoverBody {...hoveredElement.data} />}
          title={<PopoverHeader heatNumber={heatNumber} />}
          mouseEnterDelay={0}
          mouseLeaveDelay={0}
          placement={hoveredElement.type === PointType.Wallplot ? 'rightTop' : 'bottom'}
          onOpenChange={handlePopupVIsibility}
        >
          <div
            style={{
              borderRadius: `${hoveredElement.hoverRadius}px`,
              width: `${2 * hoveredElement.hoverRadius}px`,
              height: `${2 * hoveredElement.hoverRadius}px`,
              backgroundColor: 'black',
              position: 'absolute',
              cursor: 'pointer',
              left: canvasHalfWidth + scalePadding + hoveredElement.radius - hoveredElement.hoverRadius,
              top: `${hoveredElement.depth - (hoveredElement.type === PointType.Wallplot ? hoveredElement.hoverRadius / 2 : 0)}px`,
              opacity: 0,
            }}
          ></div>
        </SPopover>
      )}
    </SWrapper>
  );
};

VerticalSliceComponent.whyDidYouRender = true;

export const VerticalSlice = React.memo(VerticalSliceComponent);

const SWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
