/* eslint-disable @typescript-eslint/no-unnecessary-condition */

import { settings } from '@rhim/design';
import { hasElements, isDefined, specific, unique } from '@rhim/utils';
import * as React from 'react';
import styled from 'styled-components';

interface Props {
  name: string;
  /**
   * Background color (the body of the swatch).
   */
  backgroundColor: settings.colors.Any;
  /**
   * Text overlaid on top of the background.
   */
  foreground?: React.ReactText | React.ReactElement;
  /**
   * For example: ["operations", "links"]
   */
  recommendations?: string[];
  /**
   * If provided, will be displayed right above the swatch.
   */
  badge?: React.ReactText | React.ReactElement;
  size?: {
    width?: React.CSSProperties['width'];
    height?: React.CSSProperties['height'];
  };
}

const Swatch: React.ChildlessComponent<Props> = ({ backgroundColor, name, badge, foreground, recommendations: useCases = [], size = defaults.size }) => {
  const hex = backgroundColor.startsWith('#') ? backgroundColor.substr(1) : backgroundColor;

  return (
    <Container width={size.width ?? defaults.size.width}>
      {isDefined(badge) ? <BadgeContainer color={backgroundColor}>{badge}</BadgeContainer> : <BadgeContainer color="transparent" />}
      <Background height={size.height ?? defaults.size.height} color={backgroundColor}>
        {isDefined(foreground) && <ForegroundContainer>{foreground}</ForegroundContainer>}
      </Background>
      <Legend>
        <div>
          <Name>{name}</Name>
        </div>
        <Annotations>
          <Annotation>{hex.toUpperCase()}</Annotation>
          {hasElements(useCases) && unique(useCases).map((useCase) => <Annotation key={useCase}>{useCase}</Annotation>)}
        </Annotations>
      </Legend>
    </Container>
  );
};

const defaults = specific<Partial<Props>>()({
  size: {
    height: '56px',
    width: '80px',
  },
});

const Container = styled.div<{ width: React.CSSProperties['width'] }>`
  width: ${(props) => props.width};
`;

const Background = styled.div<{ color: settings.colors.Any; height: React.CSSProperties['height'] }>`
  height: ${(props) => props.height};
  background-color: ${(props) => props.color};
`;

const ForegroundContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
`;

const Legend = styled.div`
  margin-top: 12px;
`;

const Name = styled.span`
  font-size: ${settings.typography.FontSize.X_Small};
  font-family: ${settings.typography.FontFamily.Bold};
  color: ${settings.colors.Primary.Grey_7};
  overflow-wrap: break-word;
  word-break: break-word;
  display: inline-block;
`;

const Annotation = styled.span`
  font-size: ${settings.typography.FontSize.X_Small};
  font-family: ${settings.typography.FontFamily.Regular};
  color: ${settings.colors.Primary.Grey_7};
  overflow-wrap: break-word;
  word-break: break-word;
  display: inline-block;
`;

const Annotations = styled.div`
  display: grid;
`;

const BadgeContainer = styled.div<{ color: React.CSSProperties['color'] }>`
  height: 26px;
  background-color: ${(props) => props.color};
  width: 100%;
  display: grid;
  place-items: center;
`;

Swatch.whyDidYouRender = true;

export default React.memo(Swatch);

export const Palette = styled.div`
  margin: 16px 0;
  display: inline-flex;
  flex-wrap: wrap;

  /**
   * If this doesn't work for us, emulate gap with this technique:
   * https://coryrylan.com/blog/css-gap-space-with-flexbox
   */
  gap: 10px;
`;
