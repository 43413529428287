import { LoaderIcon as SmallLoaderIcon, LoaderInvertedIcon as SmallLoaderInvertedIcon } from '@rhim/icons/16';
import { LoaderIcon as MiddleLoaderIcon, LoaderInvertedIcon } from '@rhim/icons/24';
import { LoaderIcon as LargeLoaderIcon } from '@rhim/icons/64';
import React from 'react';
import styled from 'styled-components';

interface Props {
  size: '16' | '24' | '64';
  inverted?: boolean;
  rotationDuration?: number;
}

const Spinner: React.FC<React.PropsWithChildren<Props>> = ({ size, inverted = false, rotationDuration = 1, ...rest }) => {
  let loaderIcon;

  switch (size) {
    case '16':
      loaderIcon = inverted ? <SmallLoaderInvertedIcon /> : <SmallLoaderIcon />;
      break;
    case '24':
      loaderIcon = inverted ? <LoaderInvertedIcon /> : <MiddleLoaderIcon />;
      break;
    case '64':
      loaderIcon = <LargeLoaderIcon />;
      break;
  }

  return (
    <SpinnerWrapper rotationDuration={rotationDuration} {...rest}>
      <IconWrapper>{loaderIcon}</IconWrapper>
    </SpinnerWrapper>
  );
};

const SpinnerWrapper = styled.div<{ rotationDuration: number }>`
  display: inline-block;
  vertical-align: middle;
  pointer-events: none;

  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }

  svg {
    animation: ${(props) => `rotate ${props.rotationDuration}s linear infinite`};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

Spinner.whyDidYouRender = true;
export default React.memo(Spinner);
