import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const PinOutlineIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <defs>
        <filter id="9dtn64rn6a" width="240%" height="108.4%" x="-70%" y="-4.2%" filterUnits="objectBoundingBox">
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="8" />
          <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.239215686 0 0 0 0 0.333333333 0 0 0 0 0.403921569 0 0 0 0.1 0" />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g filter="url(#9dtn64rn6a)" transform="translate(-1028 -869) translate(1011 104)">
            <g>
              <g>
                <g>
                  <path
                    d="M8.293 10.293C8.106 10.48 8 10.734 8 11v2.586L2.414 8H5c.265 0 .52-.105.707-.293L10 3.414 12.586 6l-4.293 4.293zm7.414-4l-6-6c-.391-.391-1.023-.391-1.414 0s-.391 1.023 0 1.414L8.586 2l-4 4H2c-.265 0-.519.106-.707.293l-1 1c-.391.391-.391 1.023 0 1.414L3.086 11.5.293 14.293c-.391.391-.391 1.023 0 1.414.195.195.451.293.707.293.256 0 .512-.098.707-.293L4.5 12.914l2.793 2.793c.195.195.451.293.707.293.256 0 .512-.098.707-.293l1-1c.188-.187.293-.441.293-.707v-2.586l4-4 .293.293c.195.195.451.293.707.293.256 0 .512-.098.707-.293.391-.391.391-1.023 0-1.414z"
                    transform="translate(0 736) translate(5 21) translate(12 8)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

PinOutlineIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_6,
};
