import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const FullScreenIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2h-.586l2.293 2.293a.999.999 0 1 1-1.414 1.414L2 3.414V4a1 1 0 1 1-2 0V1a1.003 1.003 0 0 1 1-1h3a1 1 0 1 1 0 2zm10 10a1 1 0 1 1 2 0v3a1.006 1.006 0 0 1-1 1h-3a1 1 0 1 1 0-2h.586l-2.293-2.293a.999.999 0 1 1 1.414-1.414L14 12.586V12zm-9.707-1.707L2 12.586V12a1 1 0 1 0-2 0v3a1.006 1.006 0 0 0 1 1h3a1 1 0 1 0 0-2h-.586l2.293-2.293a.999.999 0 1 0-1.414-1.414zM15 0a1.003 1.003 0 0 1 1 1v3a1 1 0 1 1-2 0v-.586l-2.293 2.293a.997.997 0 0 1-1.414 0 1 1 0 0 1 0-1.414L12.586 2H12a1 1 0 1 1 0-2h3z"
        fill={fill}
      />
    </svg>
  );
};

FullScreenIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
