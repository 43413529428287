import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const VesselRHIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="m11 9-.07.003c-.388.017-1.837.08-2.985-.198-1.237-.3-2.683-.314-2.945-.3V4.301L6.5 2h3L11 4.302V9zm2-4V4c0-.198-.059-.391-.168-.555l-2-3A1.001 1.001 0 0 0 10 0H6c-.334 0-.646.167-.832.445l-2 3A1.003 1.003 0 0 0 3 4v1.586L1.707 4.293A.999.999 0 1 0 .293 5.707L3 8.414V11.5a1 1 0 0 0 1 1h1V15l.007.117A1 1 0 0 0 7 15v-2.5h2V15l.007.117A1 1 0 0 0 11 15v-2.5h1a1 1 0 0 0 1-1V7h2a1 1 0 0 0 0-2h-2z"
      />
    </svg>
  );
};

VesselRHIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
