import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const PinFilledIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <g>
              <g>
                <path
                  d="M15.707 6.293l-6-6c-.391-.391-1.023-.391-1.414 0s-.391 1.023 0 1.414L8.586 2l-4 4H2c-.265 0-.52.106-.707.293l-1 1c-.391.391-.391 1.023 0 1.414L3.086 11.5.293 14.293c-.391.391-.391 1.023 0 1.414.195.195.451.293.707.293.256 0 .512-.098.707-.293L4.5 12.914l2.793 2.793c.195.195.451.293.707.293.256 0 .512-.098.707-.293l1-1c.188-.187.293-.441.293-.707v-2.586l4-4 .293.293c.195.195.451.293.707.293.256 0 .512-.098.707-.293.391-.391.391-1.023 0-1.414"
                  transform="translate(-978 -869) translate(962 104) translate(4 757) translate(12 8)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

PinFilledIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
