import { settings } from '@rhim/design';
import { Button, ButtonProps } from '@rhim/react';
import React, { FC } from 'react';
import styled from 'styled-components';

export const FooterActionButton: FC<React.PropsWithChildren<ButtonProps>> = (props) => {
  return <Button size="small-40" {...props} />;
};

interface Props {
  actionButtons: JSX.Element;
}
const UploadFooter: React.ChildlessComponent<Props> = ({ actionButtons }) => {
  return <SWrapper>{actionButtons}</SWrapper>;
};

export default React.memo(UploadFooter);

const SWrapper = styled.div`
  margin-top: ${settings.Spacing.Spacing_300};
  display: flex;
  justify-content: space-between;
`;
