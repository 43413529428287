import { RHIMAPOCommonDataReportingHostingMode } from '@rhim/rest/wearManagement';
import { specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api';

type Payload = RHIMAPOCommonDataReportingHostingMode[];

export const useHostingModes = (options?: {
  configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
  axiosConfiguration?: AxiosRequestConfig;
}) => {
  return useQuery(getKey(), (context) => queryFn(context, options?.axiosConfiguration), options?.configuration);
};

const getKey = () =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'wearManagement',
      scope: 'hosting-modes',
      entity: 'list',
    },
  ]);

const queryFn = ({ signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  return API.hoistingModes.getHostingmodes({ signal, ...axiosConfiguration }).then((response) => response.data);
};

useHostingModes.getKey = getKey;
useHostingModes.queryFn = queryFn;
