import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const TrkAreaIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        d="M1 6.72c-.55 0-1-.44-1-1V3c0-1.65 1.35-3 3-3h3.1c.55 0 1 .45 1 1s-.45 1-1 1H3c-.55 0-1 .45-1 1v2.72c0 .55-.45 1-1 1zM15 9.28c.55 0 1 .45 1 1V13c0 1.65-1.35 3-3 3H9.9c-.55 0-1-.45-1-1s.45-1 1-1H13c.55 0 1-.45 1-1v-2.72c0-.55.45-1 1-1zM6.72 15c0 .55-.45 1-1 1H3c-1.65 0-3-1.35-3-3V9.9c0-.55.45-1 1-1s1 .45 1 1V13c0 .55.45 1 1 1h2.72c.55 0 1 .45 1 1zM9.28 1c0-.55.44-1 1-1H13c1.65 0 3 1.35 3 3v3.1c0 .55-.45 1-1 1s-1-.45-1-1V3c0-.55-.45-1-1-1h-2.72c-.55 0-1-.45-1-1z"
      />
    </svg>
  );
};

TrkAreaIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
