import { VesselType } from '@rhim/rest';
import { UNSAFE_entries } from '@rhim/utils';
import React from 'react';

export function useSortedVesselsGroupedByType(vesselsGroupedByType: Record<VesselType, APO.VesselV2[]>): Entry<VesselType, APO.VesselV2[]>[] {
  return React.useMemo(() => {
    return getSortedVesselsGroupedByType(vesselsGroupedByType);
  }, [vesselsGroupedByType]);
}

/**
 * Note: this returns a record with all vessel types as keys, even if there are no vessels of that type.
 */
export function getSortedVesselsGroupedByType(vesselsGroupedByType: Record<VesselType, APO.VesselV2[]>): Entry<VesselType, APO.VesselV2[]>[] {
  return UNSAFE_entries(vesselsGroupedByType)
    .slice()
    .sort(([left], [right]) => {
      return left.localeCompare(right);
    });
}
