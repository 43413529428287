import { settings } from '@rhim/design';
import { VesselType } from '@rhim/rest';
import {
  filteredUploadPanelMeasurementDataUploadContainer,
  mpmUploadPanelMeasurementDataUploadContainer,
  postMortemUploadPanelMeasurementDataUploadContainer,
  stlUploadPanelMeasurementDataUploadContainer,
  summaryDataUploadPanelMeasurementDataUploadContainer,
  vertexUploadPanelMeasurementDataUploadContainer,
} from '@rhim/test-ids';
import { endsWithAny, isDefined, MeasurementFileType, MIMEType } from '@rhim/utils';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { SuccessResponseTypeDefault } from '../../api/Upload';
import AppContext from '../../app/AppContext';
import { IFileUploadMetadata, MetadataType } from '../Uploader/types';
import Uploader, { Stage, UploadState } from '../Uploader/Uploader';
import LeavePageWhilstUploadingWarningModal from './LeavePageWhilstUploadingWarningModal';
import { useWarningModalOnCustomerSwitch } from './useWarningModalOnCustomerSwitch';

const INITIAL_UPLOAD_STATE: UploadState = {
  stage: Stage.noFileSelected,
};

/**
 * Warning: make sure there are no duplicate keys.
 *
 * @see https://github.com/microsoft/TypeScript/issues/41326
 */
const initialState = {
  [MeasurementFileType.PostMortem]: INITIAL_UPLOAD_STATE,
  [MeasurementFileType.SummaryCSV]: INITIAL_UPLOAD_STATE,
  [MeasurementFileType.ZIP]: INITIAL_UPLOAD_STATE,
  [MeasurementFileType.FilteredCSV]: INITIAL_UPLOAD_STATE,
  [MeasurementFileType.VertexCSV]: INITIAL_UPLOAD_STATE,
  [MeasurementFileType.MPM_SPM]: INITIAL_UPLOAD_STATE,
  [MeasurementFileType.STL]: INITIAL_UPLOAD_STATE,
} as const;

interface Props {
  vesselType: VesselType | undefined;
  onProgressChanged: (isInProgress: boolean) => void;
  onCanProceedToNextStepChanged: (canProceedToNextStep: boolean) => void;
}
const MeasurementDataUploadPanel: React.ChildlessComponent<Props> = (props) => {
  const { vesselType, onProgressChanged, onCanProceedToNextStepChanged } = props;

  const { t } = useTranslation(['ingress']);
  const [state, setState] = useState<Record<MeasurementFileType, UploadState>>(initialState);
  const [shouldCancelUpload, setShouldCancelUpload] = useState(false);
  const { selectedCustomer: customer } = useContext(AppContext);
  const customerId = customer.customerId as UUID;
  /**
   * Checks if the uploading process is in progress.
   */
  const isUploading = Object.values(state).some((_state) => _state.stage === Stage.uploading);

  const warningModalOnCustomerSwitchTexts = useMemo(
    () => ({
      headline: t('ingress:leavePageWhilstUploadingWarningModal.title'),
      description: t('ingress:leavePageWhilstUploadingWarningModal.description'),
      buttonLeaveAndCancelLabel: t('ingress:leavePageWhilstUploadingWarningModal.buttonLeaveAndCancelUpload'),
      buttonStayOnPageLabel: t('ingress:leavePageWhilstUploadingWarningModal.buttonStayOnPage'),
    }),
    [t]
  );
  useWarningModalOnCustomerSwitch(isUploading, warningModalOnCustomerSwitchTexts);

  useEffect(() => {
    onProgressChanged(isUploading);
  }, [onProgressChanged, isUploading]);

  /**
   * Checks if we can proceed to the Next step
   */
  const canProceedToNextStep = React.useMemo(() => {
    const noFileUploaded = Object.values(state).every((_state) => _state.stage === Stage.noFileSelected);
    const fails = Object.values(state).some((_state) => _state.stage === Stage.failure);

    return !noFileUploaded && !isUploading && !fails;
  }, [state, isUploading]);

  useEffect(() => {
    onCanProceedToNextStepChanged(canProceedToNextStep);
  }, [onCanProceedToNextStepChanged, canProceedToNextStep]);

  /**
   * Translation component for recommended file uploads.
   */
  const recommendedTransCmp = useMemo(() => {
    return (
      <Trans t={t} i18nKey="ingress:measurementDataRecommendedFile">
        <TransParagraph>Recommended for File Upload According to specifications</TransParagraph>
      </Trans>
    );
  }, [t]);

  const measurementFilteredCSVMetadata = useCallback(
    (detailedLadle: boolean): IFileUploadMetadata => ({
      metadataType: MetadataType.Default,
      customerId,
      vesselType,
      isPostMortem: vesselType === VesselType.Rh || (vesselType === VesselType.Ladle && !detailedLadle),
      fileType: MeasurementFileType.FilteredCSV,
    }),
    [customerId, vesselType]
  );

  const measurementVertexCSVMetadata: IFileUploadMetadata = useMemo(
    () => ({
      metadataType: MetadataType.Default,
      customerId,
      vesselType,
      fileType: MeasurementFileType.VertexCSV,
    }),
    [customerId, vesselType]
  );

  const filteredCSVUploadSuccessData: SuccessResponseTypeDefault | undefined = useMemo(() => {
    const filteredCSVState = state[MeasurementFileType.FilteredCSV];
    return filteredCSVState.stage === Stage.success ? (filteredCSVState.successData as SuccessResponseTypeDefault) : undefined;
  }, [state]);

  const measurementMPMorSPMMetadata: IFileUploadMetadata = useMemo(() => {
    const metadata: IFileUploadMetadata = {
      metadataType: MetadataType.Default,
      customerId,
      vesselType,
      fileType: MeasurementFileType.MPM_SPM,
    };
    if (isDefined(filteredCSVUploadSuccessData)) {
      metadata.vesselId = filteredCSVUploadSuccessData.vesselId;
      metadata.campaignName = filteredCSVUploadSuccessData.campaign;
      metadata.heatNumber = filteredCSVUploadSuccessData.heat;
    }
    return metadata;
  }, [customerId, vesselType, filteredCSVUploadSuccessData]);

  const measurementSTLMetadata: IFileUploadMetadata = useMemo(() => {
    const metadata: IFileUploadMetadata = {
      metadataType: MetadataType.Default,
      customerId,
      vesselType,
      fileType: MeasurementFileType.STL,
    };
    if (isDefined(filteredCSVUploadSuccessData)) {
      metadata.vesselId = filteredCSVUploadSuccessData.vesselId;
      metadata.campaignName = filteredCSVUploadSuccessData.campaign;
      metadata.heatNumber = filteredCSVUploadSuccessData.heat;
    }
    return metadata;
  }, [customerId, vesselType, filteredCSVUploadSuccessData]);

  const measurementSummaryCSVMetadata: IFileUploadMetadata = useMemo(
    () => ({
      metadataType: MetadataType.Default,
      customerId,
      vesselType,
      fileType: MeasurementFileType.SummaryCSV,
    }),
    [customerId, vesselType]
  );

  const measurementDescriptionFilteredCSV = useCallback(
    (detailedLadle: boolean) => {
      const getTranslationKey = () => {
        switch (vesselType) {
          case VesselType.Rh:
            return 'ingress:measurementDataRhRequiredFile';
          case VesselType.Bof:
            return 'ingress:measurementDataBofRequiredFile';
          case VesselType.Eaf:
            return 'ingress:measurementDataEafRequiredFile';
          case VesselType.Ladle:
            return detailedLadle ? 'ingress:measurementDataLadleDetailedRequiredFile' : 'ingress:measurementDataLadleRequiredFile';
          default:
            return 'ingress:measurementDataLadleRequiredFile';
        }
      };

      return (
        <Trans t={t} i18nKey={getTranslationKey()}>
          <TransParagraph>
            <TransSpan>Required for prediction</TransSpan>
            <TransSpan>Mandatory Files</TransSpan>
            <TransSpan>Optional Files</TransSpan>
            All files may be combined into a single zip file for upload
          </TransParagraph>
        </Trans>
      );
    },
    [vesselType, t]
  );

  const measurementDescriptionSummaryCSV = useMemo(
    () => (
      <Trans t={t} i18nKey="ingress:measurementDataBOFSummaryFile">
        <TransParagraph>
          <TransSpan>Required for Operator Display</TransSpan>
        </TransParagraph>
      </Trans>
    ),
    [t]
  );

  const handleFilteredCSVChanged = useCallback((_state: UploadState) => {
    setState((prevState) => ({ ...prevState, [MeasurementFileType.FilteredCSV]: _state }));
  }, []);
  const handlePostMortemChanged = useCallback((_state: UploadState) => {
    setState((prevState) => ({ ...prevState, [MeasurementFileType.PostMortem]: _state }));
  }, []);
  const handleMeasurementVertexCSVChanged = useCallback((_state: UploadState) => {
    setState((prevState) => ({ ...prevState, [MeasurementFileType.VertexCSV]: _state }));
  }, []);
  const handleMeasurementMPMorSPMChanged = useCallback((_state: UploadState) => {
    setState((prevState) => ({ ...prevState, [MeasurementFileType.MPM_SPM]: _state }));
  }, []);
  const handleMeasurementSTLChanged = useCallback((_state: UploadState) => {
    setState((prevState) => ({ ...prevState, [MeasurementFileType.STL]: _state }));
  }, []);
  const handleMeasurementSummaryCSVChanged = useCallback((_state: UploadState) => {
    setState((prevState) => ({ ...prevState, [MeasurementFileType.SummaryCSV]: _state }));
  }, []);

  const measurementAcceptFilteredCSV = useCallback(
    (detailedLadle: boolean) => {
      if (vesselType === VesselType.Rh || (vesselType === VesselType.Ladle && !detailedLadle)) {
        return new Set([MIMEType.CSV, MIMEType.XLS, MIMEType.XLSX]);
      }

      return new Set([MIMEType.CSV, MIMEType.ZIP]);
    },
    [vesselType]
  );
  const measurementAcceptVertexCSV = useMemo(() => new Set([MIMEType.CSV]), []);
  const measurementAcceptMPMorSPM = useMemo(() => new Set([MIMEType.MPM, MIMEType.SPM]), []);
  const measurementAcceptSTL = useMemo(() => new Set([MIMEType.STL]), []);
  const measurementAcceptSummaryCSV = useMemo(() => new Set([MIMEType.CSV]), []);

  const handleUserLeftPageAndCancelledUpload = useCallback(() => {
    setShouldCancelUpload(true);
  }, []);

  const filteredCSVSelectedFileName: string | undefined = useMemo(() => {
    const filteredCSVState = state[MeasurementFileType.FilteredCSV];
    if (filteredCSVState.stage === Stage.fileSelected || filteredCSVState.stage === Stage.uploading || filteredCSVState.stage === Stage.success) {
      return filteredCSVState.selectedFile.name;
    }
    return undefined;
  }, [state]);

  return (
    <>
      <LeavePageWhilstUploadingWarningModal isUploading={isUploading} onUploadingCancelled={handleUserLeftPageAndCancelledUpload} />
      <SUploader
        uploadMetadataPayload={measurementFilteredCSVMetadata(false)}
        dataTestId={
          vesselType === VesselType.Rh || vesselType === VesselType.Ladle
            ? postMortemUploadPanelMeasurementDataUploadContainer
            : filteredUploadPanelMeasurementDataUploadContainer
        }
        placeholder={vesselType === VesselType.Rh || vesselType === VesselType.Ladle ? t('ingress:uploadPlaceholder') : t('ingress:uploadDetailedPlaceholder')}
        title={isDefined(vesselType) ? t(`ingress:uploadMeasurementsTitle.${vesselType as unknown as APO.IngressKnownParameterName}` as const) : ''}
        description={measurementDescriptionFilteredCSV(false)}
        onChange={vesselType === VesselType.Rh || vesselType === VesselType.Ladle ? handlePostMortemChanged : handleFilteredCSVChanged}
        shouldCancelUpload={shouldCancelUpload}
        disabled={false}
        accept={measurementAcceptFilteredCSV(false)}
      />
      {vesselType === VesselType.Ladle && (
        <>
          <HorizontalContainer />
          <SUploader
            uploadMetadataPayload={measurementFilteredCSVMetadata(true)}
            dataTestId={filteredUploadPanelMeasurementDataUploadContainer}
            placeholder={t('ingress:uploadDetailedPlaceholder')}
            title={t(`ingress:uploadMeasurementsTitle.LadleDetailed`)}
            description={measurementDescriptionFilteredCSV(true)}
            onChange={handleFilteredCSVChanged}
            shouldCancelUpload={shouldCancelUpload}
            disabled={false}
            accept={measurementAcceptFilteredCSV(true)}
          />
        </>
      )}
      {endsWithAny(filteredCSVSelectedFileName ?? '', [MIMEType.CSV, MIMEType.XLS, MIMEType.XLSX]) &&
        [Stage.uploading, Stage.success].includes(state[MeasurementFileType.FilteredCSV].stage) && (
          <>
            <SUploader
              uploadMetadataPayload={measurementVertexCSVMetadata}
              dataTestId={vertexUploadPanelMeasurementDataUploadContainer}
              title={t('ingress:uploadMeasurementsVertexTitle')}
              description={recommendedTransCmp}
              onChange={handleMeasurementVertexCSVChanged}
              shouldCancelUpload={shouldCancelUpload}
              disabled={state[MeasurementFileType.FilteredCSV].stage === Stage.uploading}
              accept={measurementAcceptVertexCSV}
            />
            <SUploader
              uploadMetadataPayload={measurementMPMorSPMMetadata}
              dataTestId={mpmUploadPanelMeasurementDataUploadContainer}
              title={t('ingress:uploadMeasurementsMpmTitle')}
              description={recommendedTransCmp}
              onChange={handleMeasurementMPMorSPMChanged}
              shouldCancelUpload={shouldCancelUpload}
              disabled={state[MeasurementFileType.FilteredCSV].stage === Stage.uploading}
              accept={measurementAcceptMPMorSPM}
            />
            <SUploader
              uploadMetadataPayload={measurementSTLMetadata}
              dataTestId={stlUploadPanelMeasurementDataUploadContainer}
              title={t('ingress:uploadMeasurementsStlTitle')}
              description={recommendedTransCmp}
              onChange={handleMeasurementSTLChanged}
              shouldCancelUpload={shouldCancelUpload}
              disabled={state[MeasurementFileType.FilteredCSV].stage === Stage.uploading}
              accept={measurementAcceptSTL}
            />
          </>
        )}
      {(vesselType === VesselType.Bof || vesselType === VesselType.Eaf || vesselType === VesselType.Ladle) && (
        <>
          <HorizontalContainer />
          <Uploader
            uploadMetadataPayload={measurementSummaryCSVMetadata}
            dataTestId={summaryDataUploadPanelMeasurementDataUploadContainer}
            placeholder={t('ingress:uploadSummaryPlaceholder')}
            title={t(`ingress:uploadMeasurementsSummaryFileTitle`)}
            description={measurementDescriptionSummaryCSV}
            onChange={handleMeasurementSummaryCSVChanged}
            shouldCancelUpload={shouldCancelUpload}
            disabled={false}
            accept={measurementAcceptSummaryCSV}
          />
        </>
      )}
    </>
  );
};

MeasurementDataUploadPanel.whyDidYouRender = true;

export default React.memo(MeasurementDataUploadPanel);

export const TransSpan = styled.span`
  color: ${settings.colors.Primary.Grey_8};
  font-family: ${settings.typography.FontFamily.Bold};
`;

export const TransParagraph = styled.p`
  margin-bottom: 0;
`;

const HorizontalContainer = styled.div`
  height: 1px;
  margin: ${settings.Spacing.Spacing_400} -${settings.Spacing.Spacing_600};
  border-top: 1px solid ${settings.colors.Primary.Grey_2};
`;

const SUploader = styled(Uploader)`
  &:not(:first-of-type) {
    margin-top: ${settings.Spacing.Spacing_500};
  }
`;
