/* import-sort-ignore */
// eslint-disable-next-line no-restricted-imports
import LogRocket from 'logrocket';

import { NullDereferenced } from './exceptions';

export function assert(condition: boolean, message = NullDereferenced.message): asserts condition {
  if (!condition) {
    throw new Error(message);
  }
}

assert.silent = (condition: boolean, message = NullDereferenced.message): asserts condition => {
  if (!condition) {
    // eslint-disable-next-line no-console
    console.warn(message);
    LogRocket.captureMessage(message);
  }
};

export function ensure<T>(argument: T | undefined | null, message?: string): T {
  if (argument === undefined || argument === null) {
    throw new NullDereferenced(message);
  }

  return argument;
}
