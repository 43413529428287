import { AxisBottom, AxisLeft } from '@rhim/chart/v2/axis';
import { Chart, DEFAULT_MARGIN } from '@rhim/chart/v2/chart';
import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto } from '@rhim/rest/fleetOverview';
import { ensure, hasElements, isDefined, last } from '@rhim/utils';
import { Group } from '@visx/group';
import { Threshold } from '@visx/threshold';
import { curveLinear } from '@vx/curve';
import { scaleLinear } from '@vx/scale';
import { Bar, Line, LinePath } from '@vx/shape';
import { defaultStyles, Tooltip, withTooltip } from '@vx/tooltip';
import { min } from 'd3';
import { max } from 'd3-array';
import { ScaleLinear } from 'd3-scale';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { scaleX as linearScaleX } from '../../../components/PredictionGraph/dataProcessor';
import { calculateLeftWithBoundries, calculateTicks } from '../utils';
import { usePositionContext } from './hooks/usePositionContext';
import VolumeTooltip, { Keys } from './VolumeTooltip';

interface Props {
  measurements: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto[];
  height: number;
  width: number;
  compareToMeasurement?: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto;
  maxHeat: number;
}

const RblGraph = withTooltip<Props>(({ maxHeat, compareToMeasurement, width, height, measurements }) => {
  const xMax = width;
  const yMax = height;
  const lastMeasurement = useMemo(
    () => (isDefined(measurements) && hasElements(measurements) ? last(measurements) : ({} as RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto)),
    [measurements]
  );

  const { t } = i18nReact.useTranslation(['volume']);

  /**
   * Gets the rbl from a point.
   */
  const getRblMin = useCallback((d: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto) => {
    return d.rblMin;
  }, []);

  const getRblMed = useCallback((d: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto) => {
    return d.rblMed;
  }, []);

  const maxY = max(measurements.map((point) => point.rblMed))!;

  /**
   * Gets the heat from a point.
   */
  const getHeat = useCallback((d: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto) => {
    return d.heat;
  }, []);

  const yDomain = useMemo(() => {
    return [0, maxY];
  }, [maxY]);
  const ref = React.useRef(null);

  /**
   * X axis scale function.
   */
  const xScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [0, xMax],
        round: true,
        domain: [0, maxHeat],
      }),
    [xMax, maxHeat]
  );

  /**
   * Y axis scale function.
   */
  const yScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax, 0],
        domain: yDomain,
      }),
    [yDomain, yMax]
  );
  const getScaleX = useCallback((point: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto): number => ensure(xScale(getHeat(point))), [getHeat, xScale]);
  const getScaleRblMinY = useCallback(
    (point: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto): number => ensure(yScale(getRblMin(point))),
    [getRblMin, yScale]
  );
  const getScaleRblMedY = useCallback(
    (point: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto): number => ensure(yScale(getRblMed(point))),
    [getRblMed, yScale]
  );
  const axisScale = linearScaleX(xMax, maxHeat);
  const positionContext = usePositionContext();
  const markerX = isDefined(positionContext.position) ? xScale(positionContext.position) : null;

  const margin = { top: 15, left: 10 };
  const groupMargin = { left: 40, top: 20 };

  const unit = 'mm';
  return (
    <Container>
      <Chart width={width + DEFAULT_MARGIN.left} height={height + DEFAULT_MARGIN.top + DEFAULT_MARGIN.bottom}>
        <Group left={margin.left} top={margin.top} transform="translate(40, 20)">
          <Line from={{ x: xMax, y: 0 }} to={{ x: xMax, y: yMax }} stroke={settings.colors.Primary.Grey_3} strokeWidth={1} pointerEvents="none" />
          <Line from={{ x: 0, y: 0 }} to={{ x: xMax, y: 0 }} stroke={settings.colors.Primary.Grey_3} strokeWidth={1} pointerEvents="none" />

          <Threshold
            id={`${Math.random()}`}
            data={measurements}
            x={getScaleX}
            y0={() => yScale(min(yDomain)!) ?? 0}
            y1={getScaleRblMinY}
            clipAboveTo={0}
            clipBelowTo={yMax}
            curve={curveLinear}
            belowAreaProps={{
              fill: settings.colors.Primary.Blue_3,
            }}
          />
          {isDefined(markerX) && <MarkerLine from={{ x: markerX, y: 0 }} to={{ x: markerX, y: yMax }} />}
          <AxisBottom top={yMax} scale={axisScale} width={width} tickValues={calculateTicks(maxHeat, 6)} stroke={settings.colors.Primary.Grey_2} />
          <LinePath stroke="#af7aa0" strokeWidth={2} strokeDasharray={2} data={measurements} x={getScaleX} y={getScaleRblMedY} curve={curveLinear} />
          <LinePath stroke={settings.colors.Primary.Blue_9} strokeWidth={2} data={measurements} x={getScaleX} y={getScaleRblMinY} curve={curveLinear} />
          <Bar x={0} y={0} width={width} height={height} fill="transparent" rx={14} />
          <AxisLeft
            axisLabel={t('volume:axisRblLabel')}
            tickFormat={yScale.tickFormat(6, 'f')}
            left={0}
            top={0}
            scale={yScale as ScaleLinear<number, number>}
          />
          <Line
            from={{ x: xScale(lastMeasurement.heat), y: 0 }}
            to={{ x: xScale(lastMeasurement.heat), y: yMax + 10 }}
            stroke={settings.colors.Primary.Blue_8}
            strokeWidth={1}
            pointerEvents="none"
          />
          <g>
            <circle
              cx={xScale(lastMeasurement.heat)}
              cy={yScale(lastMeasurement.rblMin)}
              r={5}
              fill={settings.colors.Monochromatic.White}
              pointerEvents="none"
            />
            ;
            <circle cx={xScale(lastMeasurement.heat)} cy={yScale(lastMeasurement.rblMin)} r={4} fill={settings.colors.Primary.Blue_8} pointerEvents="none" />;
          </g>
          {isDefined(compareToMeasurement) && (
            <>
              <Line
                from={{ x: getScaleX(compareToMeasurement), y: 0 }}
                to={{ x: getScaleX(compareToMeasurement), y: yMax }}
                stroke={settings.colors.Miscellaneous.Green}
                strokeWidth={1}
                pointerEvents="none"
              />
              <g>
                <circle
                  cx={xScale(compareToMeasurement.heat)}
                  cy={yScale(compareToMeasurement.rblMin)}
                  r={5}
                  fill={settings.colors.Monochromatic.White}
                  pointerEvents="none"
                />
                ;
                <circle
                  cx={xScale(compareToMeasurement.heat)}
                  cy={yScale(compareToMeasurement.rblMin)}
                  r={4}
                  fill={settings.colors.Miscellaneous.Green}
                  pointerEvents="none"
                />
                ;
              </g>
            </>
          )}
          {measurements.map((item, idx) => {
            const foundDuplicate = measurements.find((duplicated, duplicatedIdx) => duplicatedIdx !== idx && item.heat === duplicated.heat);

            return isDefined(foundDuplicate) ? (
              <g>
                <circle cx={xScale(item.heat)} cy={yScale(item.rblMin)} r={2} fill={settings.colors.Primary.Blue_8} pointerEvents="none" />;
                <circle cx={xScale(foundDuplicate.heat)} cy={yScale(foundDuplicate.rblMin)} r={2} fill={settings.colors.Primary.Blue_8} pointerEvents="none" />;
              </g>
            ) : null;
          })}
        </Group>
        <Group top={groupMargin.top} left={groupMargin.left}>
          <foreignObject x={0} y={0} width={width} height={height + DEFAULT_MARGIN.top + DEFAULT_MARGIN.bottom}>
            <Tooltip
              ref={ref}
              top={ensure(yScale(lastMeasurement.rblMin)) - groupMargin.top}
              left={calculateLeftWithBoundries(ensure(xScale(lastMeasurement.heat)) + 20, width, 40, 20)}
              style={{
                ...defaultStyles,
                backgroundColor: settings.colors.Primary.Blue_8,
                color: settings.colors.Monochromatic.White,
                padding: settings.Spacing.Spacing_50,
                boxShadow: 'none',
                zIndex: settings.Elevation.Tooltip,
              }}
            >
              {/* eslint-disable-next-line i18next/no-literal-string */}
              {lastMeasurement.rblMin}
            </Tooltip>
            <Tooltip
              top={yMax + margin.left}
              left={xMax - 40}
              style={{
                ...defaultStyles,
                padding: 0,
                boxShadow: 'none',
                fontSize: settings.typography.FontSize.X_Small,
                color: settings.colors.Primary.Grey_6,
              }}
            >
              {t('volume:heats')}
            </Tooltip>
            <Tooltip
              top={yMax}
              left={calculateLeftWithBoundries(ensure(xScale(lastMeasurement.heat)), width, 40)}
              style={{
                ...defaultStyles,
                backgroundColor: settings.colors.Primary.Blue_8,
                color: settings.colors.Monochromatic.White,
                padding: settings.Spacing.Spacing_50,
                boxShadow: 'none',
              }}
            >
              {/* eslint-disable-next-line i18next/no-literal-string */}
              {lastMeasurement.heat}
            </Tooltip>
          </foreignObject>
        </Group>
        <Group top={groupMargin.top} left={groupMargin.left}>
          <VolumeTooltip
            chartWidth={width}
            chartHeight={height}
            data={measurements}
            keys={[Keys.RBLMIN, Keys.RBLMED]}
            xScale={xScale as ScaleLinear<number, number>}
            yScale={yScale as ScaleLinear<number, number>}
            unit={unit}
          />
        </Group>
      </Chart>
    </Container>
  );
});

export default React.memo(RblGraph);

const Container = styled.div`
  display: flex;
`;

export const GraphWrapper = styled.div<{ $height: number }>`
  background-color: ${settings.colors.Monochromatic.White};
  position: relative;
  display: flex;
`;

export const Center = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
`;

const MarkerLine = styled(Line)`
  stroke: ${settings.colors.Primary.Grey_8};
  stroke-width: 2;
  pointer-events: none;
`;
