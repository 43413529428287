import { Tree as UnstyledTree } from 'antd';
import React from 'react';

export const ROOT_KEY = Symbol.for('ROOT_KEY').toString();

export function useTreeControls(allKeys: string[], initialExpandedKeys: string[] = [ROOT_KEY]) {
  const [expandedKeys, setExpandedKeys] = React.useState<React.Key[]>(initialExpandedKeys);
  const [checkedKeys, setCheckedKeys] = React.useState<React.Key[]>(allKeys);
  const [halfCheckedKeys, setHalfCheckedKeys] = React.useState<React.Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = React.useState<boolean>(true);

  const onExpand: React.ComponentProps<typeof UnstyledTree>['onExpand'] = React.useCallback((expandedKeysValue: React.Key[]) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  }, []);

  const onCheck: React.ComponentProps<typeof UnstyledTree>['onCheck'] = React.useCallback(
    (...[checkedKeysValue, checkInfo]: Parameters<NonNullable<React.ComponentProps<typeof UnstyledTree>['onCheck']>>) => {
      const halfCheckedKeys: React.Key[] = checkInfo.halfCheckedKeys ?? [];

      if (Array.isArray(checkedKeysValue)) {
        setCheckedKeys(checkedKeysValue);
        setHalfCheckedKeys(halfCheckedKeys);
      } else {
        setCheckedKeys(checkedKeysValue.checked);
        setHalfCheckedKeys(halfCheckedKeys);
      }
    },
    []
  );

  return React.useMemo(
    () => ({
      expandedKeys,
      halfCheckedKeys,
      setExpandedKeys,
      checkedKeys,
      setCheckedKeys,
      autoExpandParent,
      setAutoExpandParent,
      onExpand,
      onCheck,
    }),
    [autoExpandParent, checkedKeys, expandedKeys, halfCheckedKeys, onCheck, onExpand]
  );
}
