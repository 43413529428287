import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';
interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const ReportFeatureIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <g clipPath="url(#clip0_4119_34670)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 20H6V4H15V6C15 6.26522 15.1054 6.51957 15.2929 6.70711C15.4804 6.89464 15.7348 7 16 7H18V22H19C19.2652 22 19.5196 21.8946 19.7071 21.7071C19.8946 21.5196 20 21.2652 20 21V6C19.9995 5.9891 19.9975 5.97833 19.994 5.968C19.9909 5.95826 19.9888 5.9482 19.988 5.938C19.982 5.85018 19.9649 5.76348 19.937 5.68L19.931 5.663C19.9233 5.63662 19.9136 5.61088 19.902 5.586C19.8556 5.47717 19.7895 5.37784 19.707 5.293L16.707 2.293C16.6219 2.20912 16.5223 2.14136 16.413 2.093C16.3926 2.08359 16.3715 2.07557 16.35 2.069L16.323 2.06C16.2377 2.03085 16.1489 2.01336 16.059 2.008C16.0495 2.00715 16.0401 2.00514 16.031 2.002C16.0208 1.99986 16.0104 1.99918 16 2H5C4.73478 2 4.48043 2.10536 4.29289 2.29289C4.10536 2.48043 4 2.73478 4 3V21C4 21.2652 4.10536 21.5196 4.29289 21.7071C4.48043 21.8946 4.73478 22 5 22H18V20Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 18C14 18.2652 14.1054 18.5196 14.2929 18.7071C14.4804 18.8946 14.7348 19 15 19C15.2652 19 15.5196 18.8946 15.7071 18.7071C15.8946 18.5196 16 18.2652 16 18V10C16 9.73478 15.8946 9.48043 15.7071 9.29289C15.5196 9.10536 15.2652 9 15 9C14.7348 9 14.4804 9.10536 14.2929 9.29289C14.1054 9.48043 14 9.73478 14 10V18Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 18C11 18.2652 11.1054 18.5196 11.2929 18.7071C11.4804 18.8946 11.7348 19 12 19C12.2652 19 12.5196 18.8946 12.7071 18.7071C12.8946 18.5196 13 18.2652 13 18V15C13 14.7348 12.8946 14.4804 12.7071 14.2929C12.5196 14.1054 12.2652 14 12 14C11.7348 14 11.4804 14.1054 11.2929 14.2929C11.1054 14.4804 11 14.7348 11 15V18Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 18C8 18.2652 8.10536 18.5196 8.29289 18.7071C8.48043 18.8946 8.73478 19 9 19C9.26522 19 9.51957 18.8946 9.70711 18.7071C9.89464 18.5196 10 18.2652 10 18V12C10 11.7348 9.89464 11.4804 9.70711 11.2929C9.51957 11.1054 9.26522 11 9 11C8.73478 11 8.48043 11.1054 8.29289 11.2929C8.10536 11.4804 8 11.7348 8 12V18Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_4119_34670">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

ReportFeatureIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Grey_4,
};
