import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { ArrowLeftIcon } from '@rhim/icons/16';
import { headerUploadContainerDataCenter } from '@rhim/test-ids';
import { previousItemButtonNavigation } from '@rhim/test-ids';
import { isDefined } from '@rhim/utils';
import React from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ROUTES } from '../../utilities';

interface Props {
  className?: string;
  prefixIcon?: React.ReactElement;
  title: string;
}
const UploadHeader: React.ChildlessComponent<Props> = ({ className, prefixIcon, title }) => {
  const { t } = i18nReact.useTranslation(['ingress']);
  const navigate = useNavigate();
  const location = useLocation();
  const doesAnyHistoryEntryExist = location.key !== 'default';

  return (
    <SWrapper className={className}>
      <SLink onClick={() => (doesAnyHistoryEntryExist ? navigate(-1) : navigate(ROUTES.ARO_INGRESS))} aria-label={t('ingress:goBack')}>
        <ArrowLeftIcon dataTestId={previousItemButtonNavigation} fill={settings.colors.Primary.Blue_9} />
      </SLink>
      <SHeaderContainer>
        {isDefined(prefixIcon) && (
          <>
            {prefixIcon}
            <SIconMargin />
          </>
        )}
        <SHeader data-test-id={headerUploadContainerDataCenter}>{title}</SHeader>
      </SHeaderContainer>
    </SWrapper>
  );
};
UploadHeader.whyDidYouRender = true;
export default React.memo(UploadHeader);

const SWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${settings.Spacing.Spacing_400};
  margin-left: -${settings.Spacing.Spacing_500};
`;

const SLink = styled.div`
  display: flex;
  cursor: pointer;
`;

const SHeaderContainer = styled.div`
  margin-left: ${settings.Spacing.Spacing_300};
  display: flex;
  align-items: center;
`;

const SHeader = styled.h3`
  ${settings.typography.fonts.h_xl};
`;

const SIconMargin = styled.span`
  width: ${settings.Spacing.Spacing_100};
`;
