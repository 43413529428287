import { settings } from '@rhim/design';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Tooltip, TooltipPlacement } from '../Tooltip';

export type IconLabelProps = {
  dataTestId?: string;
  className?: string;
  id?: string;
  label: string | number | JSX.Element;
  color: settings.colors.Any;
  tooltip: string | ReactNode;
  tooltipPlacement?: TooltipPlacement;
  icon: React.ReactNode;
};
export const IconLabel: FC<React.PropsWithChildren<IconLabelProps>> = ({
  dataTestId,
  className,
  id,
  icon,
  label,
  color,
  tooltip,
  tooltipPlacement = 'bottom',
}) => {
  return (
    <Tooltip title={tooltip} placement={tooltipPlacement} overlayStyle={{ maxWidth: '500px' }}>
      <StyledWrapper className={className} id={id} color={color}>
        <SIconWrapper>{icon}</SIconWrapper>
        <span data-test-id={dataTestId}>{label}</span>
      </StyledWrapper>
    </Tooltip>
  );
};

const StyledWrapper = styled.div<{ color: settings.colors.Any }>`
  --color: ${(props) => props.color};

  flex-shrink: 0;
  display: flex;
  align-items: center;
  vertical-align: middle;
  color: var(--color);

  span {
    font-family: ${settings.typography.FontFamily.Medium};
    font-size: ${settings.typography.FontSize.Small};
    cursor: inherit;
    white-space: nowrap;
  }
`;

const SIconWrapper = styled.div`
  margin-right: ${settings.Spacing.Spacing_100};
  display: flex;
  flex-shrink: 0;
`;
