export const VOLUME_DECIMAL_POINTS = 3;

export const getTruncatedVolume = (volume: number) => {
  return volume.toFixed(VOLUME_DECIMAL_POINTS);
};

export const calculateTicks = (max: number, numTicks: number, min = 0) => {
  const rawStep = (max - min) / numTicks; // Number of intervals is numSteps, which is 6 in this case
  const step = Math.round(rawStep);

  // Generate ticks using the rounded step size
  const ticks = [];
  for (let i = 0; i <= numTicks; i++) {
    ticks.push(i * step);
  }

  // Ensure the last tick is exactly the max value, and adjust if necessary
  if (ticks[ticks.length - 1] !== max) {
    ticks[ticks.length - 1] = max; // Set the last tick to the max value
  }

  return ticks;
};

export const calculateLeftWithBoundries = (value: number, width: number, tooltipWidth: number, offset = 0) => {
  const leftBoundary = Math.max(0, value - tooltipWidth / 2);
  const rightBoundary = width - (tooltipWidth + offset);
  return Math.min(leftBoundary, rightBoundary);
};

export const calculateTopWithBoundries = (value: number, height: number, tooltipHeight: number, offset = 0) => {
  const topBoundary = Math.max(0, value - tooltipHeight / 2);
  const bottomBoundary = height - (tooltipHeight + offset);
  return Math.min(topBoundary, bottomBoundary);
};
