import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  backgroundColor?: Property.Color;
  foregroundColor?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const AppStateOKIcon: React.FunctionComponent<Props> = ({ backgroundColor, foregroundColor, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path fill={backgroundColor} fillRule="evenodd" clipRule="evenodd" d="M30 16c0 7.732-6.268 14-14 14S2 23.732 2 16 8.268 2 16 2s14 6.268 14 14" />
      <path
        fill={foregroundColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 22a1 1 0 0 1-.747-.335l-4-4.495a1 1 0 0 1 1.494-1.33l3.159 3.55 6.275-8.964a1 1 0 0 1 1.638 1.147l-7 10a1.001 1.001 0 0 1-.76.426h-.06"
      />
    </svg>
  );
};

AppStateOKIcon.defaultProps = {
  size: '32',
  backgroundColor: settings.colors.Operational.State_Notif_Green_2,
  foregroundColor: settings.colors.Monochromatic.Black,
};
