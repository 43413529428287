import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const MinusIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <g>
            <g>
              <path
                d="M20 13H4c-.552 0-1-.447-1-1 0-.553.448-1 1-1h16c.552 0 1 .447 1 1 0 .553-.448 1-1 1"
                transform="translate(-784 -572) translate(564 535) translate(220 37)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

MinusIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
