import { CustomersApi, UserInfoApi, UsersApi } from '@rhim/rest/customerManagement';
import { Configuration } from '@rhim/rest/wearManagement';

import { axiosApiInstance } from '../../utilities';

const configuration: Configuration = {} as Configuration;

const BASE_URL = '/api/customermanagement/v2';

export const customers = new CustomersApi(configuration, BASE_URL, axiosApiInstance);
export const userInfo = new UserInfoApi(configuration, BASE_URL, axiosApiInstance);
export const users = new UsersApi(configuration, BASE_URL, axiosApiInstance);
