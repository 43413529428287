import { settings } from '@rhim/design';
import { isDefined } from '@rhim/utils';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const CIRCULAR_PROGRESS_BANDS = [
  { progress: 40, color: settings.colors.Primary.Blue_8 }, // normal values
  { progress: 60, color: settings.colors.Operational.State_Alert_Yellow_2 }, // due values
  { progress: 80, color: settings.colors.Operational.State_Alert_Orange_2 }, // overdue values
  { progress: 100, color: settings.colors.Operational.State_Alert_Red_2 }, // critical values - out of range
];

export const LANE_STROKE_WIDTH_PX = 1;
export const ANIMATION_DURATION_SECS = 1.5;

interface Props {
  className?: string;
  size: number;
  progress: number;
  hasBands?: boolean;
  strokeColor?: string;
  strokeWidth?: number;
  isProgressLaneShowing?: boolean;
}
const CircularProgress: React.ChildlessComponent<Props> = ({
  className,
  size,
  progress,
  hasBands = true,
  strokeColor = settings.colors.Primary.Grey_8,
  strokeWidth = 8,
  isProgressLaneShowing = true,
}) => {
  const [progressLaneLength, setProgressLaneLength] = useState(0);

  const domElementLaneCicleRef = useRef(null);
  const center = size / 2;
  const radiusOuter = size / 2 - LANE_STROKE_WIDTH_PX / 2;
  const radiusInner = size / 2 - strokeWidth - LANE_STROKE_WIDTH_PX / 2;
  let radiusProgressLane = 0;
  let circumference = 0;
  let stroke: string | undefined = 'white';
  if (isProgressLaneShowing) {
    radiusProgressLane = size / 2 - strokeWidth / 2 - LANE_STROKE_WIDTH_PX / 2;
    circumference = 2 * Math.PI * radiusProgressLane;
    stroke = hasBands ? CIRCULAR_PROGRESS_BANDS.find((band) => progress <= band.progress)?.color : strokeColor;
  }

  useEffect(() => {
    if (isDefined(domElementLaneCicleRef.current) && isProgressLaneShowing) {
      setProgressLaneLength((circumference * progress) / 100);
    }
  }, [progress, circumference, isProgressLaneShowing]);

  return (
    <SCircularProgressSVG className={className} width={size} height={size} isProgressLaneShowing={isProgressLaneShowing}>
      <circle className="lane-outer-border" cx={center} cy={center} r={radiusOuter} strokeWidth={LANE_STROKE_WIDTH_PX} />
      <circle className="lane-inner-border" cx={center} cy={center} r={radiusInner} strokeWidth={LANE_STROKE_WIDTH_PX} />
      {isProgressLaneShowing && (
        <circle
          className="circularProgressLane"
          ref={domElementLaneCicleRef}
          cx={center}
          cy={center}
          r={radiusProgressLane}
          fill="none"
          stroke={stroke}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeDasharray={`${progressLaneLength} ${circumference - progressLaneLength}`}
          transform={`rotate(-90, ${center}, ${center})`}
        >
          {progressLaneLength !== 0 && (
            <animate
              attributeName="stroke-dasharray"
              begin="0s"
              dur={`${ANIMATION_DURATION_SECS}s`}
              from={`0 ${circumference}`}
              to={`${progressLaneLength} ${circumference - progressLaneLength}`}
            />
          )}
        </circle>
      )}
    </SCircularProgressSVG>
  );
};

export default React.memo(CircularProgress);

const SCircularProgressSVG = styled.svg<{ isProgressLaneShowing: boolean }>`
  circle {
    &.lane-inner-border {
      fill: ${(props) => (props.isProgressLaneShowing ? settings.colors.Monochromatic.White : settings.colors.Primary.Grey_1)};
    }

    &.lane-outer-border {
      fill: ${settings.colors.Primary.Grey_2};
    }
  }
`;
