import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const VesselSRFIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 4a1 1 0 0 0-1 1h-.8a1 1 0 0 0-.684.27l-3.2 3A1 1 0 0 0 1 9v4a1 1 0 0 0 .316.73l3.2 3A1 1 0 0 0 5.2 17H6a1 1 0 0 0 1 1H4v2h6v-2H7a1 1 0 0 0 1-1h8a1 1 0 0 0 1 1h-3v2h6v-2h-3a1 1 0 0 0 1-1h1a1 1 0 0 0 .707-.293l3-3A1 1 0 0 0 23 13V9a1 1 0 0 0-.293-.707l-3-3A1 1 0 0 0 19 5h-1a1 1 0 1 0-2 0H8a1 1 0 0 0-1-1zm11 9.035c1.1.033 1.919.038 2.519.032l.481-.481V9.414L18.586 7H18v6.035zm-2-.108V7H8v4.096c.804.135 1.738.398 2.764.687 1.578.444 3.37.95 5.236 1.144zM6 11.022V7h-.405L3 9.433V11.5c.616-.205 1.57-.41 3-.478z"
      />
    </svg>
  );
};

VesselSRFIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
