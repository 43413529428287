import { getFormattedDate, getFormattedDateWithOffset } from '@rhim/react';
import { RHIMAPOCustomerManagementWebV2ModelsCustomerDto } from '@rhim/rest/customerManagement';
import { RHIMProcessDataServiceV1ModelsCampaignDisplayTextDto, RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto } from '@rhim/rest/processData';
import { isDefined, parseUTC } from '@rhim/utils';

export const getParamRanges = (
  paramRanges?: RHIMProcessDataServiceV1ModelsProcessDataParameterRangeDto
): { upperOuterLimit?: number; lowerUpperLimit?: number; upperInnerLimit?: number; lowerInnerLimit?: number } => {
  let upperOuterLimit = undefined;
  let lowerUpperLimit = undefined;
  let upperInnerLimit = undefined;
  let lowerInnerLimit = undefined;

  if (isDefined(paramRanges)) {
    const { min, max, outerLimit, innerLimit } = paramRanges;

    if (isDefined(min) && isDefined(max)) {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (outerLimit) {
        const outerStep = (max - min) * ((outerLimit || 0) / 100);
        upperOuterLimit = max - outerStep;
        lowerUpperLimit = min + outerStep;
      }

      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (innerLimit) {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        const innerStep = (max - min) * ((innerLimit + (outerLimit || 0)) / 100);
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        upperInnerLimit = max - innerStep;
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        lowerInnerLimit = min + innerStep;
      }
    }
  }
  return { upperOuterLimit, lowerUpperLimit, upperInnerLimit, lowerInnerLimit };
};

/**
 * Returns campaign display text for dropdowns with the campaign start date if given.
 */
export const getCampaignDisplayText = (
  showDate = false,
  campaign: RHIMProcessDataServiceV1ModelsCampaignDisplayTextDto,
  customerDetails?: RHIMAPOCustomerManagementWebV2ModelsCustomerDto
) => {
  const displayText = campaign.campaignDisplayText ?? campaign.internalCampaign.toString();

  if (isDefined(campaign.campaignStartDate) && showDate) {
    const formattedDate =
      isDefined(customerDetails) && isDefined(customerDetails.timeZoneName)
        ? getFormattedDate({
            datetime: campaign.campaignStartDate as Date.ISO_8601,
            showTime: true,
          })
        : parseUTC(campaign.campaignStartDate);

    return `${displayText} (${formattedDate})`;
  }

  return displayText;
};

export const getCampaignDateWithOffset = (
  campaign: RHIMProcessDataServiceV1ModelsCampaignDisplayTextDto,
  customerDetails?: RHIMAPOCustomerManagementWebV2ModelsCustomerDto
) =>
  isDefined(campaign.campaignStartDate) && isDefined(customerDetails) && isDefined(customerDetails.timeZoneName)
    ? getFormattedDateWithOffset(campaign.campaignStartDate as Date.ISO_8601, customerDetails.timeZoneName)
    : '';
