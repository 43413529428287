import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  fill?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const ProcessIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fill, size, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M13.767 10.159c.564-1.278 2.378-1.278 2.941 0l.25.564c.282.641.947 1.025 1.644.95l.613-.068c1.39-.15 2.295 1.42 1.47 2.548l-.364.497c-.414.566-.414 1.334 0 1.899l.364.498c.825 1.127-.08 2.698-1.47 2.547l-.613-.067c-.697-.075-1.362.309-1.645.95l-.249.564c-.563 1.278-2.377 1.278-2.94 0l-.25-.564c-.282-.641-.947-1.025-1.644-.95l-.613.067c-1.389.151-2.296-1.42-1.47-2.547l.364-.498c.414-.565.414-1.333 0-1.899l-.365-.497c-.825-1.128.082-2.698 1.471-2.548l.613.068c.697.075 1.362-.309 1.644-.95zM3 11c.553 0 1 .447 1 1 0 3.006 1.66 5.73 4.333 7.111.49.254.683.858.429 1.348-.177.344-.527.541-.89.541-.153 0-.311-.036-.457-.111C4.075 19.162 2 15.757 2 12c0-.553.448-1 1-1zm12.238 2.2c-1.326 0-2.4 1.074-2.4 2.4 0 1.326 1.074 2.4 2.4 2.4 1.325 0 2.4-1.074 2.4-2.4 0-1.326-1.075-2.4-2.4-2.4zM6.352 2.749c.44-.999 1.856-.999 2.296 0l.195.44c.22.501.74.801 1.284.742l.479-.052c1.084-.118 1.793 1.109 1.148 1.989l-.284.389c-.324.441-.324 1.041 0 1.483l.284.389c.645.88-.064 2.106-1.148 1.988l-.479-.052c-.544-.058-1.064.242-1.284.742l-.195.441c-.44.998-1.856.998-2.296 0l-.195-.441c-.22-.5-.74-.8-1.284-.742l-.479.052c-1.084.118-1.793-1.108-1.148-1.988l.284-.389c.324-.442.324-1.042 0-1.483l-.284-.389c-.645-.88.064-2.107 1.148-1.989l.479.052c.544.059 1.064-.241 1.284-.742zm7.869-.502c3.314.753 6.077 3.213 7.21 6.42.184.521-.09 1.092-.61 1.276-.11.038-.223.057-.333.057-.412 0-.798-.256-.943-.667-.907-2.565-3.116-4.533-5.766-5.135-.54-.123-.876-.658-.754-1.197.123-.538.659-.873 1.196-.754zM7.5 4.998c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2z"
      />
    </svg>
  );
};

ProcessIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Grey_6,
};
