import { RectDefinition } from '../../theme/types';

type Props = Pick<RectDefinition, 'legendHeight' | 'fill'>;

const SIZE = 16;

export const Rect: React.FC<Props> = (props) => {
  const { legendHeight = 4, fill } = props;

  return (
    <svg width={SIZE} height={SIZE}>
      <rect x={0} y={SIZE / 2 - legendHeight / 2} width={SIZE} height={legendHeight} fill={fill} />
    </svg>
  );
};
