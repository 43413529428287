import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const ChevronDownMiniIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M4.707 5.293c-.39-.39-1.024-.39-1.414 0-.39.39-.39 1.024 0 1.414l4 4c.39.39 1.024.39 1.414 0l4-4c.39-.39.39-1.024 0-1.414-.39-.39-1.024-.39-1.414 0L8 8.586 4.707 5.293z"
      />
    </svg>
  );
};

ChevronDownMiniIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
