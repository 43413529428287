import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { FilterIcon } from '@rhim/icons/16';
import { Option, OptionContent, RangePicker, RangeValue, Select } from '@rhim/react';
import { RHIMMeasurementServiceContractsDataVesselLining, RHIMMeasurementServiceV1ModelsMeasurementViewDto } from '@rhim/rest/measurementService';
import {
  allMeasurementsFilteredOutLabelMeasurementView,
  vesselStateFiltersDropdownMeasurementView,
  vesselStateFiltersDropdownOptionsMeasurementView,
} from '@rhim/test-ids';
import { hasElements, isDefined } from '@rhim/utils';
import { set } from 'date-fns';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { useScrollToElement } from '../../hooks';
import { doesMeasurementPassDateRangeFilter, isSiblingMeasurementOfSameHeat } from '../../pages/MeasurementView/utils';
import { useVesselLiningLabel } from '../DataUploadPanel/QckLightPointCloudScanData/utils';
import MeasurementSelectionItem from './MeasurementSelectionItem';

type VesselLining = RHIMMeasurementServiceContractsDataVesselLining | 'All';

interface Props {
  datepickerPopupContainer: HTMLDivElement | null;
  measurements: RHIMMeasurementServiceV1ModelsMeasurementViewDto[];
  selectedMeasurementId: string;
  onMeasurementSelected: (measurementId: string) => void;
  customerTimezone: string;
}
const MeasurementSelectionDropdown: React.ChildlessComponent<Props> = ({
  datepickerPopupContainer,
  measurements,
  selectedMeasurementId,
  onMeasurementSelected,
  customerTimezone,
}) => {
  const { t } = i18nReact.useTranslation(['measurement-view', 'sharedComponents', 'ingress']);
  const [filterDateStart, setFilterDateStart] = useState<Date | null>(null);
  const [filterDateEnd, setFilterDateEnd] = useState<Date | null>(null);
  const [filterVesselLining, setFilterVesselLining] = useState<VesselLining>('All');

  const getVesselLiningLabel = useVesselLiningLabel();

  const filteredMeasurements: RHIMMeasurementServiceV1ModelsMeasurementViewDto[] = React.useMemo(() => {
    const passesFilterDateRange = (measurement: RHIMMeasurementServiceV1ModelsMeasurementViewDto) => {
      const areDateRangeFiltersEnabled = isDefined(filterDateStart) || isDefined(filterDateEnd);
      return isDefined(measurement.measurementTaken)
        ? doesMeasurementPassDateRangeFilter(measurement.measurementTaken, filterDateStart, filterDateEnd)
        : !areDateRangeFiltersEnabled;
    };
    const passesFilterVesselState = (measurement: RHIMMeasurementServiceV1ModelsMeasurementViewDto) => {
      if (filterVesselLining === 'All') {
        return true;
      }
      return measurement.vesselLining === filterVesselLining;
    };
    const filteredCampaignMeasurements = measurements.filter((measurement) => passesFilterDateRange(measurement) && passesFilterVesselState(measurement));
    return filteredCampaignMeasurements;
  }, [measurements, filterDateStart, filterDateEnd, filterVesselLining]);

  // create refs for the list of measurements to allow scrolling them into view. Note: we only do this for the filtered measurements
  // Note: #97231 we must only do this for the filtered measurements, because there there are measurements that don't match the criteria and therefore don't get rendered
  const objectsRefs = useScrollToElement<HTMLTableRowElement>(
    filteredMeasurements.map((measurement) => measurement.id),
    selectedMeasurementId
  );

  const handleDateRangeChanged = useCallback((dateRange: RangeValue<Date>) => {
    // as we only pick and are interested in start/end dates ( and not datetimes ), remove the local-time part from the picker's selected dates to have consistent results
    const removeTimeValues = { hours: 0, minutes: 0, seconds: 0 };
    const dateStart = isDefined(dateRange) && isDefined(dateRange[0]) ? set(dateRange[0], removeTimeValues) : null;
    const dateEnd = isDefined(dateRange) && isDefined(dateRange[1]) ? set(dateRange[1], removeTimeValues) : null;
    setFilterDateStart(dateStart);
    setFilterDateEnd(dateEnd);
  }, []);

  const handleFilterByVesselStateChanged = (state: string) => {
    setFilterVesselLining(state as VesselLining);
  };

  const filterByVesselLiningOptions: VesselLining[] = [
    'All',
    ...new Set(measurements.map((measurement) => measurement.vesselLining as RHIMMeasurementServiceContractsDataVesselLining)),
  ];

  return (
    <SWrapper>
      <SToolbarContainer>
        {/* TOOLBAR FILTER : DATE RANGE */}
        {isDefined(datepickerPopupContainer) && (
          <RangePicker
            uiSize="small-40"
            onCalendarChange={handleDateRangeChanged}
            placeholder={[t('sharedComponents:rangePicker.placeholderDateStart'), t('sharedComponents:rangePicker.placeholderDateEnd')]}
            value={[filterDateStart ?? null, filterDateEnd ?? null]}
            getPopupContainer={() => datepickerPopupContainer}
          />
        )}
        {/* TOOLBAR FILTER : VESSEL-LINING */}
        {isDefined(datepickerPopupContainer) && (
          <SVesselStateSelect
            data-test-id={vesselStateFiltersDropdownMeasurementView}
            size="small-40"
            prefixIcon={<FilterIcon />}
            value={filterVesselLining}
            onSelect={handleFilterByVesselStateChanged}
            dropdownMatchSelectWidth={false}
            getPopupContainer={() => datepickerPopupContainer}
          >
            {filterByVesselLiningOptions.map((filterOption) => (
              <Option data-test-id={vesselStateFiltersDropdownOptionsMeasurementView} key={filterOption} value={filterOption}>
                <OptionContent label={getVesselLiningLabel(filterOption)} />
              </Option>
            ))}
          </SVesselStateSelect>
        )}
      </SToolbarContainer>
      {/* MEASUREMENTS LIST */}
      {hasElements(filteredMeasurements) ? (
        <STable cellPadding="0" cellSpacing="0">
          <tbody>
            {filteredMeasurements.map((measurement) => (
              <MeasurementSelectionItem
                ref={objectsRefs[measurement.id]}
                isEarlierMeasurementOfSameHeat={isSiblingMeasurementOfSameHeat(measurement, 'earlier', filteredMeasurements)}
                isLaterMeasurementOfSameHeat={isSiblingMeasurementOfSameHeat(measurement, 'later', filteredMeasurements)}
                key={measurement.id}
                measurement={measurement}
                isSelectedMeasurement={measurement.id === selectedMeasurementId}
                onMeasurementClicked={onMeasurementSelected}
                customerTimezone={customerTimezone}
              />
            ))}
          </tbody>
        </STable>
      ) : (
        <SAllMeasurementsFilteredOut data-test-id={allMeasurementsFilteredOutLabelMeasurementView}>
          {t('measurement-view:strip.dropdownMeasurementSelection.messageFilterDoesNotMatchAnyMeasurement')}
        </SAllMeasurementsFilteredOut>
      )}
    </SWrapper>
  );
};

export default React.memo(MeasurementSelectionDropdown);

const SWrapper = styled.div`
  width: auto;
`;

const SToolbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${settings.Spacing.Spacing_100};
`;

const SVesselStateSelect = styled(Select)`
  margin-left: ${settings.Spacing.Spacing_100};
`;

const STable = styled.table`
  width: 100%;

  td {
    white-space: nowrap;
  }
`;

const SAllMeasurementsFilteredOut = styled.p`
  margin: ${settings.Spacing.Spacing_200} ${settings.Spacing.Spacing_150};
`;
