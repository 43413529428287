import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const SlagStartIcon = React.forwardRef<SVGSVGElement, Props>(({ size, fill, title, role, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      <circle cx="8" cy="8" r="8" fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 7a1 1 0 0 0 0 2h.126A4.007 4.007 0 0 0 7 11.874V12a1 1 0 1 0 2 0v-.126A4.007 4.007 0 0 0 11.874 9H12a1 1 0 1 0 0-2h-.126A4.007 4.007 0 0 0 9 4.126V4a1 1 0 1 0-2 0v.126A4.007 4.007 0 0 0 4.126 7H4zm4-1a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm0 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
        fill="#fff"
      />
    </svg>
  );
});

SlagStartIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_6,
};
