import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  backgroundColor?: Property.Color;
  foregroundColor?: Property.Color;
  size?: Property.Height;
  title?: string;
  role?: string;
}

export const OpsStateCriticalIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  backgroundColor,
  foregroundColor,
  size,
  title,
  role,
  ...rest
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                fill={backgroundColor}
                d="M21.121 6.98l-4.1-4.101C16.458 2.316 15.695 2 14.899 2H9.101c-.796 0-1.559.316-2.122.879l-4.1 4.101C2.316 7.542 2 8.305 2 9.101v5.798c0 .796.316 1.559.879 2.122l4.1 4.1c.563.563 1.326.879 2.122.879h5.798c.796 0 1.559-.316 2.122-.879l4.1-4.1c.563-.563.879-1.326.879-2.122V9.101c0-.796-.316-1.559-.879-2.121z"
                transform="translate(-196 -1652) translate(53 1489) translate(143 163)"
              />
              <g fill={foregroundColor}>
                <path
                  d="M1.5 9c.829 0 1.5.671 1.5 1.5S2.329 12 1.5 12 0 11.329 0 10.5.671 9 1.5 9zm0-9c.552 0 1 .512 1 1.143v5.714C2.5 7.488 2.052 8 1.5 8s-1-.512-1-1.143V1.143C.5.512.948 0 1.5 0z"
                  transform="translate(-196 -1652) translate(53 1489) translate(143 163) translate(10.5 6)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

OpsStateCriticalIcon.defaultProps = {
  size: '24',
  backgroundColor: settings.colors.Operational.State_Alert_Red_3,
  foregroundColor: settings.colors.Monochromatic.White,
};
