import { specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api/measurementService';

type Payload = File;

export function useMeasurementFiles(
  measurementFileId: string,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) {
  return useQuery(getKey(measurementFileId), (context) => queryFn(context, options?.axiosConfiguration), {
    ...options?.configuration,
  });
}

const getKey = (measurementFileId: string) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'measurementService',
      scope: 'measurementFiles',
      entity: 'detail',
      measurementFileId,
    },
  ]);

const queryFn = ({ queryKey: [{ measurementFileId }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  return API.measurementFiles.getMeasurementfilesMeasurementfileid(measurementFileId, { signal, ...axiosConfiguration }).then((response) => response.data);
};

useMeasurementFiles.getKey = getKey;
useMeasurementFiles.queryFn = queryFn;
