import type { ColorScales } from '@rhim/utils';
import { assert, fallbackColor, getColorUsingScale, isDefined, last } from '@rhim/utils';
import { max, min } from 'd3-array';
import { ScaleBand, scaleBand, scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import * as fc from 'd3fc';
import React, { useCallback, useEffect, useMemo } from 'react';

import { Area, cloneCanvas, extractAreas, getAreas, getWallPlotDef, IMapArea } from './areas';

interface Point {
  x: number;
  y: number;
}
interface HeatmapPoint extends Point {
  value: number;
}

interface CustomElement extends HTMLElement {
  requestRedraw: () => void;
}

interface HeatMapProps {
  onDraw: (data: IHeatMapDetails) => void;
  data: NonEmptyArray<HeatmapPoint>;
  colorScales: ColorScales;
  colorFallback?: string;
  hotSpots?: Area[];
}

export interface IHeatMapDetails {
  areas: IMapArea[];
  areasCanvas: { area: number; image: ImageData }[];
  canvas: HTMLCanvasElement;
}

/**
 * HeatMap component.
 */
const Heatmap: React.FunctionComponent<React.PropsWithChildren<HeatMapProps>> = ({
  onDraw,
  data,
  colorScales,
  hotSpots,
  colorFallback = fallbackColor,
}: HeatMapProps): JSX.Element => {
  const canvasRef = React.useRef(null);
  const lastPoint = last(data);

  const variables = useMemo(() => {
    const lastItem = data[data.length - 1];
    const xValues: string[] = [];
    const yValues: string[] = [];
    const colors: number[] = [];

    data.forEach((point: HeatmapPoint) => {
      xValues.push(point.x.toString());
      yValues.push(point.y.toString());
      colors.push(point.value);
    });

    return {
      maxX: lastItem?.x ?? 0,
      maxY: lastItem?.y ?? 0,
      xValues,
      yValues,
      colors,
    };
  }, [data]);

  const xScale: ScaleBand<string> = useMemo(() => scaleBand().range([0, variables.maxX]).domain(variables.xValues), [variables]);

  const yScale: ScaleBand<string> = useMemo(() => scaleBand().range([variables.maxY, 0]).domain(variables.yValues), [variables]);

  /**
   * Gets the colour based on a colour scale.
   * @param value Value to be mapped to a colour.
   * @returns Colour HEX code.
   */
  const getColour = useCallback(
    (value: number) => {
      return getColorUsingScale(value, colorScales, colorFallback);
    },
    [colorScales, colorFallback]
  );

  /**
   * Generates a heatmap and converts it into an image URL for further usage.
   * @param onDraw Callback to call on `drow` event.
   */
  const generateHeatMap = useCallback(
    (onDraw: (details: IHeatMapDetails) => void): void => {
      // eslint-disable-next-line no-console
      const currentEl = isDefined(canvasRef) ? (canvasRef.current as HTMLDivElement | null) : null;

      if (currentEl) {
        const container: CustomElement | null = currentEl.firstChild as CustomElement | null;
        if (container) {
          const canvas = container.querySelector('canvas');
          if (canvas) {
            canvas.setAttribute('width', lastPoint.x.toString());
            canvas.setAttribute('height', lastPoint.y.toString());
          }
          const { colors, maxY } = variables;
          const colorsScale = scaleLinear().domain([min(colors) ?? 0, max(colors) ?? 0]);
          const series = fc
            .autoBandwidth(fc.seriesCanvasHeatmap())
            .xValue((d: HeatmapPoint) => d.x)
            .yValue((d: HeatmapPoint) => maxY - d.y) // y axis needs to be inverted because SVG starts with (0,0) at the top instead of the bottom
            .colorInterpolate((value: number) => getColour(colorsScale.invert(value)))
            .colorValue((d: HeatmapPoint) => d.value)
            .xScale(xScale)
            .yScale(yScale)
            .xBandwidth(xScale.bandwidth)
            .yBandwidth(yScale.bandwidth)
            .xAlign('right')
            .yAlign('top');

          select(container)
            .on('draw', () => {
              series(data);

              if (canvas) {
                const wallPlotDef = getWallPlotDef(data, hotSpots || [], xScale, yScale);
                const areas: IMapArea[] = getAreas(wallPlotDef);
                const clone = cloneCanvas(canvas, 'grayscale(10)');
                const areasCanvas = extractAreas(canvas, wallPlotDef);

                onDraw({ areas, areasCanvas, canvas: clone });
              }
            })
            .on('measure', () => {
              const canvas = container.querySelector('canvas');

              if (canvas) {
                const ctx = canvas.getContext('2d');
                assert(isDefined(ctx), 'We target browsers that support 2D rendering context.');

                series.context(ctx);
              }
            });

          container.requestRedraw();
        }
      }
    },
    [lastPoint, variables, xScale, yScale, getColour, data, hotSpots]
  );

  useEffect(() => {
    if (isDefined(data)) {
      setTimeout(() => generateHeatMap(onDraw), 100);
    }
  }, [onDraw, generateHeatMap, data]);

  const template = { __html: `<d3fc-canvas style="height: ${lastPoint.y}px; width: ${lastPoint.x}" ></d3fc-canvas>` };

  return <div dangerouslySetInnerHTML={template} ref={canvasRef} style={{ width: lastPoint.x, height: lastPoint.y, position: 'relative' }} />;
};

export default React.memo(Heatmap);
