import { settings } from '@rhim/design';
import { isDefined } from '@rhim/utils';
import React from 'react';
import styled from 'styled-components';

export interface Props {
  className?: string;
  children: React.Node;
  width: number;
  height: number;
}

const Chart: React.FunctionComponent<Props> = (props) => {
  const { className, children, height, width } = props;

  return (
    <Svg width={width} height={height} className={className}>
      {children}
    </Svg>
  );
};

Chart.whyDidYouRender = true;

export default React.memo(Chart);

const Svg = styled.svg`
  overflow: visible;
`;

export const AreaBackground = styled.rect<{ fill?: string }>`
  fill: ${(props) => (isDefined(props.fill) ? props.fill : settings.colors.Primary.Grey_1)};
`;

export const AreaFrame = styled.rect`
  stroke-width: 1;
  stroke: ${settings.colors.Primary.Grey_2};
  fill: transparent;
  shape-rendering: crispedges;
  pointer-events: none;
`;
