import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const AreasIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <g fill={fill}>
          <path d="M16 22H2v-8h14v3h2.277c.347-.596.985-1 1.723-1 1.104 0 2 .896 2 2s-.896 2-2 2c-.738 0-1.376-.404-1.723-1H16v3zm-2-6H4v4h10v-4zm1-6.5c.552 0 1 .447 1 1v.5c0 .553-.448 1-1 1h-.5c-.552 0-1-.447-1-1 0-.405.241-.755.588-.912.157-.347.506-.588.912-.588zm-3.5.5c.552 0 1 .447 1 1 0 .553-.448 1-1 1h-1c-.552 0-1-.447-1-1 0-.553.448-1 1-1zm-4 0c.552 0 1 .447 1 1 0 .553-.448 1-1 1h-1c-.552 0-1-.447-1-1 0-.553.448-1 1-1zM3 9.5c.406 0 .755.24.912.588.347.157.588.507.588.912 0 .553-.447 1-1 1H3c-.552 0-1-.447-1-1v-.5c0-.553.448-1 1-1zM20 5c1.105 0 2 .896 2 2s-.895 2-2 2c-.738 0-1.376-.404-1.722-1h-2.431c-.174.294-.48.5-.847.5-.552 0-1-.447-1-1v-1c0-.553.448-1 1-1 .367 0 .673.206.847.5h2.43c.347-.596.985-1 1.723-1zM3 5.5c.552 0 1 .447 1 1v1c0 .553-.448 1-1 1s-1-.447-1-1v-1c0-.553.448-1 1-1zM3.5 2c.552 0 1 .447 1 1 0 .405-.241.755-.588.912-.157.347-.506.588-.912.588-.552 0-1-.447-1-1V3c0-.553.448-1 1-1zM15 2c.552 0 1 .447 1 1v.5c0 .553-.448 1-1 1-.406 0-.755-.24-.912-.588-.347-.157-.588-.507-.588-.912 0-.553.447-1 1-1zm-3.5 0c.552 0 1 .447 1 1 0 .553-.448 1-1 1h-1c-.552 0-1-.447-1-1 0-.553.448-1 1-1zm-4 0c.552 0 1 .447 1 1 0 .553-.448 1-1 1h-1c-.552 0-1-.447-1-1 0-.553.448-1 1-1z" />
        </g>
      </g>
    </svg>
  );
};

AreasIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
