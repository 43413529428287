import { assert, isDefined } from '@rhim/utils';
import React, { FC } from 'react';

import arrowDownIcon8Svg from '../../../../../../assets/icons-8/arrow-down-8.svg?url';
import criticalIcon16Svg from '../../../../../../assets/icons-16/ops-state-critical-outline-16.svg?url';
import criticalIcon24Svg from '../../../../../../assets/icons-24/ops-state-critical-outline-24.svg?url';
import Canvas from './Canvas';
import { DataItem, getGridCellLayout, SETTINGS, WallplotLayout } from './utils';

const createImageFromSVGIcon = (svgIcon: string) => {
  const img = new Image();
  img.src = svgIcon;
  return img;
};

const cellStateIconImages = {
  arrowDown8: createImageFromSVGIcon(arrowDownIcon8Svg),
  critical16: createImageFromSVGIcon(criticalIcon16Svg),
  critical24: createImageFromSVGIcon(criticalIcon24Svg),
};

interface CellStatesCanvasProps {
  wallplotLayout: WallplotLayout;
  wallplotData: DataItem[];
  criticalValueThreshold?: number;
}
const CellStatesCanvas: FC<React.PropsWithChildren<CellStatesCanvasProps>> = ({ wallplotLayout, wallplotData, criticalValueThreshold }) => {
  const displayCellStates = (canvas2DContext: CanvasRenderingContext2D) => {
    const { metrics, gridLayout } = wallplotLayout;

    // clear canvas
    canvas2DContext.clearRect(0, 0, metrics.canvasWidth, metrics.canvasHeight);

    canvas2DContext.save();

    // 1st step : draw cell borders depending on the value
    let criticalBorderThickness = SETTINGS.CELL_CRITICAL_BORDER_SIZE_SMALL_PX;
    if (metrics.cellWidth >= SETTINGS.GRID_VALUE_TEXT.MINIMUM_CELL_SIZE_TO_DISPLAY_14PT_TEXT_PX) {
      criticalBorderThickness = SETTINGS.CELL_CRITICAL_BORDER_SIZE_LARGE_PX;
    }
    interface DataItemDomain {
      x: number;
      y: number;
    }
    const dataItemsHavingCriticalValue: DataItemDomain[] = [];
    const dataItemsHavingLowestValue: DataItemDomain[] = [];
    for (const dataItem of wallplotData) {
      if (!isDefined(dataItem.rbl)) {
        continue;
      }
      const cellLayoutItem = getGridCellLayout(gridLayout, dataItem.angle, dataItem.depth);
      if (!cellLayoutItem) {
        throw new Error('Wallplot layout item not found');
      }
      if (isDefined(criticalValueThreshold) && dataItem.rbl <= criticalValueThreshold) {
        // critical state cell border
        dataItemsHavingCriticalValue.push({ x: dataItem.angle, y: dataItem.depth });
        canvas2DContext.beginPath();
        canvas2DContext.lineWidth = criticalBorderThickness;
        canvas2DContext.strokeRect(cellLayoutItem.startX, cellLayoutItem.startY, cellLayoutItem.width, cellLayoutItem.height);
        canvas2DContext.closePath();
      } else if (dataItem.rbl <= metrics.dataLowestValue) {
        // lowest value cell border
        dataItemsHavingLowestValue.push({ x: dataItem.angle, y: dataItem.depth });
        canvas2DContext.save();
        canvas2DContext.setLineDash(SETTINGS.CELL_LOWEST_VALUE_DASH);
        canvas2DContext.strokeRect(cellLayoutItem.startX, cellLayoutItem.startY, cellLayoutItem.width, cellLayoutItem.height);
        canvas2DContext.restore();
      }
    }

    if (metrics.cellWidth >= SETTINGS.GRID_VALUE_TEXT.MINIMUM_CELL_SIZE_TO_DISPLAY_TEXT_PX) {
      // 2st step : draw the critical-value icons
      if (dataItemsHavingCriticalValue.length !== 0) {
        let criticalIconImg, criticalIconOffsetX, criticalIconOffsetY;
        if (metrics.cellWidth >= SETTINGS.GRID_VALUE_TEXT.MINIMUM_CELL_SIZE_TO_DISPLAY_14PT_TEXT_PX) {
          criticalIconImg = cellStateIconImages.critical24;
          criticalIconOffsetX = 16;
          criticalIconOffsetY = 8;
        } else if (metrics.cellWidth >= SETTINGS.GRID_VALUE_TEXT.MINIMUM_CELL_SIZE_TO_DISPLAY_TEXT_PX) {
          criticalIconImg = cellStateIconImages.critical16;
          criticalIconOffsetX = 10;
          criticalIconOffsetY = 5;
        }
        if (isDefined(criticalIconImg) && isDefined(criticalIconOffsetX) && isDefined(criticalIconOffsetY)) {
          for (const dataItem of dataItemsHavingCriticalValue) {
            const cellLayoutItem = getGridCellLayout(gridLayout, dataItem.x, dataItem.y);
            if (!cellLayoutItem) {
              throw new Error('Wallplot layout item not found');
            }

            canvas2DContext.drawImage(
              criticalIconImg,
              cellLayoutItem.startX + cellLayoutItem.width - criticalIconOffsetX,
              cellLayoutItem.startY - criticalIconOffsetY
            );
          }
        }
      }
      // draw the lowest-value icons
      if (dataItemsHavingLowestValue.length !== 0) {
        const iconOffsetPx = 4;
        for (const dataItem of dataItemsHavingLowestValue) {
          const cellLayoutItem = getGridCellLayout(gridLayout, dataItem.x, dataItem.y);
          assert(isDefined(cellLayoutItem), 'Wallplot layout item for lowest-value icon not found');
          canvas2DContext.drawImage(cellStateIconImages.arrowDown8, cellLayoutItem.startX + iconOffsetPx, cellLayoutItem.startY + iconOffsetPx);
        }
      }
    }
    canvas2DContext.restore();
  };

  return <Canvas draw={displayCellStates} width={wallplotLayout.metrics.canvasWidth} height={wallplotLayout.metrics.canvasHeight} />;
};

export default React.memo(CellStatesCanvas);
