/* eslint-disable @nx/enforce-module-boundaries */

import { settings } from '@rhim/design';
import { Checkbox } from '@rhim/react';
import { legendWrapper } from '@rhim/test-ids';
import { assert, isDefined } from '@rhim/utils';
import React from 'react';
import styled from 'styled-components';

import { UseFilter, useFilterContext } from '../filter/hooks';
import { ThemeConfig } from '../theme/types';
import LegendVisual from './LegendVisual';

interface Props {
  className?: string;
  configuration: ThemeConfig;
  datakeys: string[];
  hasFilter?: boolean;
  filter?: UseFilter;
}

const LegendComponent: React.FC<Props> = (props) => {
  const { className, configuration, datakeys, hasFilter = false } = props;

  const hasFilterProps = isDefined(props.filter);
  const filterContext = useFilterContext({ isEnabled: hasFilter && !hasFilterProps });
  const filter = hasFilterProps ? props.filter : filterContext;

  return (
    <Wrapper className={className} data-test-id={legendWrapper}>
      {datakeys.map((key) => {
        const config = configuration.datasets[key];
        assert(isDefined(config), `theme not found for ${key}`);

        const LegendItem = (
          <Item key={key}>
            <LegendVisual config={config} />
            <Label>{config.label}</Label>
          </Item>
        );

        return hasFilter && isDefined(filter) ? (
          <Checkbox
            key={key}
            isDisabled={false}
            isSelected={filter.includesKey(key)}
            onChange={(isSelected) => (isSelected ? filter.addKey(key) : filter.removeKey(key))}
            size="x-small"
          >
            {LegendItem}
          </Checkbox>
        ) : (
          LegendItem
        );
      })}
    </Wrapper>
  );
};

export default React.memo(LegendComponent);

const Wrapper = styled.div`
  margin: 0;
  height: ${settings.Spacing.Spacing_200};
  display: flex;
  gap: ${settings.Spacing.Spacing_150};
  justify-content: flex-end;
`;

const Item = styled.div`
  display: flex;
  gap: ${settings.Spacing.Spacing_50};
  align-items: center;
`;

const Label = styled.div`
  font-size: ${settings.typography.FontSize.X_Small};
  line-height: ${settings.typography.LineHeight.Line_Height_14};
  color: ${settings.colors.Primary.Grey_6};
`;
